import React, { Fragment, useCallback, useState } from 'react';
import * as yup from 'yup';
import { Grid, makeStyles } from '@material-ui/core';
import BaseButton from '../../Components/Buttons/BaseButton';
import TextInput from '../../Components/Inputs/TextInput';
import ShowPassword from '../../Components/Adornments/ShowPassword';
import SimpleDivider from '../../Components/Dividers/SimpleDivider';
import { redirectOnAuthFailure } from '../../Utils/Errors/Errors';
import { useForm } from 'react-hook-form';
import { UpdateUserPasswordAPI } from '../../API/UserAPI';
import DeleteAccountDialog from './DeleteAccountDialog';
import { updateUser } from '../../Utils/User/Actions';
import UnlinkAppleAccountDialog from './UnlinkAppleAccountDialog';
import { logoutUser } from '../../Utils/User/Actions';
import { isMobileApp } from '../../Utils/Mobile/actions';

const passwordSchema = yup.object({
  currentPassword: yup
    .string()
    .required('Ingresa una contraseña')
    .min(8, 'Debes usar al menos 8 caracteres')
    .notOneOf(
      [yup.ref('newPassword')],
      'Las contraseña debe ser diferente a la nueva'
    ),
  newPassword: yup
    .string()
    .required('Ingresa una contraseña')
    .min(8, 'Debes usar al menos 8 caracteres')
    .notOneOf(
      [yup.ref('currentPassword')],
      'Las contraseña debe ser diferente a la actual'
    ),
  confirmPassword: yup
    .string()
    .required('Ingresa de nuevo la contraseña')
    .oneOf([yup.ref('newPassword')], 'Las contraseñas deben ser iguales')
});

const noPasswordSchema = yup.object({
  newPassword: yup
    .string()
    .required('Ingresa una contraseña')
    .min(8, 'Debes usar al menos 8 caracteres'),
  confirmPassword: yup
    .string()
    .required('Ingresa de nuevo la contraseña')
    .oneOf([yup.ref('newPassword')], 'Las contraseñas deben ser iguales')
});

const MyProfileUserPassword = props => {
  const {
    currentUser,
    setSelectedItem,
    setLoading,
    setAlert,
    setCurrentUser,
    setPasswordDialog
  } = props;

  const classes = useStyles();

  const [currentPasswordShown, setCurrentPasswordShown] = useState('');
  const [newPasswordShown, setNewPasswordShown] = useState('');
  const [confirmPasswordShown, setConfirmPasswordShown] = useState('');
  const [openDeleteAccountDialog, setOpenDeleteAccountDialog] = useState(false);
  const [
    openUnlinkAppleAccountDialog,
    setOpenUnlinkAppleAccountDialog
  ] = useState(false);
  const [isAppleLoading, setIsAppleLoading] = useState(false);

  const {
    handleSubmit: handleSubmitPassword,
    register: registerPassword,
    errors: errorsPassword,
    setError: setErrorPassword,
    formState: formStatePassword
  } = useForm({
    validationSchema: passwordSchema,
    mode: 'onChange'
  });

  const {
    handleSubmit: handleSubmitNoPassword,
    register: registerNoPassword,
    errors: errorsNoPassword,
    formState: formStateNoPassword
  } = useForm({
    validationSchema: noPasswordSchema,
    mode: 'onChange'
  });

  const onToggleCurrentPasswordShown = useCallback(() => {
    setCurrentPasswordShown(v => !v);
  }, []);

  const onToggleNewPasswordShown = useCallback(() => {
    setNewPasswordShown(v => !v);
  }, []);

  const onToggleConfirmPasswordShown = useCallback(() => {
    setConfirmPasswordShown(v => !v);
  }, []);

  const onSubmitPassword = useCallback(
    async values => {
      const { currentPassword, newPassword } = values;
      setLoading(true);
      const response = await UpdateUserPasswordAPI(
        currentUser.token,
        currentUser.id,
        {
          currentPassword,
          password: newPassword
        }
      );

      if (response.success) {
        const { token, appToken } = response.data.data;
        setAlert({
          type: 'success',
          message: 'Contraseña actualizada satisfactoriamente'
        });
        updateUser(setCurrentUser, token, appToken);
        setSelectedItem(0);
        setLoading(false);
        return;
      }

      if (
        redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
      ) {
        return;
      }

      setErrorPassword(
        'currentPassword',
        'notOneOf',
        'La contraseña no es válida'
      );
      setLoading(false);
    },
    [
      currentUser,
      setCurrentUser,
      setAlert,
      setErrorPassword,
      setLoading,
      setSelectedItem
    ]
  );

  const onDeleteAccountPress = () => {
    setOpenDeleteAccountDialog(true);
  };

  const onSuccessDeleteAccount = useCallback(() => {
    if (!isMobileApp()) {
      setAlert({
        type: 'success',
        message: 'Tu cuenta ha sido eliminada exitosamente'
      });
    }
    logoutUser(setCurrentUser, true);
  }, [setCurrentUser, setAlert]);

  if (!currentUser) {
    return <Fragment></Fragment>;
  }

  const handleSubmit = currentUser.hasPassword
    ? handleSubmitPassword
    : handleSubmitNoPassword;
  const register = currentUser.hasPassword
    ? registerPassword
    : registerNoPassword;
  const errors = currentUser.hasPassword ? errorsPassword : errorsNoPassword;
  const formState = currentUser.hasPassword
    ? formStatePassword
    : formStateNoPassword;

  return (
    <Fragment>
      {openDeleteAccountDialog && (
        <DeleteAccountDialog
          open={openDeleteAccountDialog}
          setDialog={setOpenDeleteAccountDialog}
          setPasswordDialog={setPasswordDialog}
          currentUser={currentUser}
          onSuccessDeleteAccount={onSuccessDeleteAccount}
          setOpenUnlinkAppleAccountDialog={setOpenUnlinkAppleAccountDialog}
        />
      )}
      {openUnlinkAppleAccountDialog && (
        <UnlinkAppleAccountDialog
          open={openUnlinkAppleAccountDialog}
          setDialog={setOpenUnlinkAppleAccountDialog}
          currentUser={currentUser}
          setIsAppleLoading={setIsAppleLoading}
          onSuccessDeleteAccount={onSuccessDeleteAccount}
          isLoading={isAppleLoading}
        />
      )}
      <form
        key={2}
        id="editPasswordForm"
        onSubmit={handleSubmit(onSubmitPassword)}
      >
        {currentUser.hasPassword && (
          <Grid
            item
            sm={12}
            className={`${classes.inputFieldContainer} ${errors.name &&
              classes.optionWithErrors}`}
          >
            <TextInput
              inputRef={register}
              id="MyProfile_input_currentPassword"
              required
              fullWidth
              margin="none"
              label="Contraseña actual"
              error={Boolean(errors.currentPassword)}
              helperText={
                errors.currentPassword && errors.currentPassword.message
              }
              name="currentPassword"
              type={currentPasswordShown ? 'text' : 'password'}
              InputProps={{
                autoComplete: 'off',
                endAdornment: (
                  <ShowPassword
                    togglePasswordShow={onToggleCurrentPasswordShown}
                    passwordShown={currentPasswordShown}
                  />
                )
              }}
            />
          </Grid>
        )}{' '}
        <Grid
          item
          sm={12}
          className={`${classes.inputFieldContainer} ${errors.name &&
            classes.optionWithErrors}`}
        >
          <TextInput
            inputRef={register}
            id="MyProfile_input_newPassword"
            required
            fullWidth
            margin="none"
            label="Nueva contraseña"
            error={Boolean(errors.newPassword)}
            helperText={errors.newPassword && errors.newPassword.message}
            name="newPassword"
            type={newPasswordShown ? 'text' : 'password'}
            InputProps={{
              autoComplete: 'off',
              endAdornment: (
                <ShowPassword
                  togglePasswordShow={onToggleNewPasswordShown}
                  passwordShown={newPasswordShown}
                />
              )
            }}
          />
        </Grid>
        <Grid
          item
          sm={12}
          className={`${classes.inputFieldContainer} ${errors.name &&
            classes.optionWithErrors}`}
        >
          <TextInput
            inputRef={register}
            id="MyProfile_input_confirmPassword"
            required
            fullWidth
            margin="none"
            label="Confirmar nueva contraseña"
            error={Boolean(errors.confirmPassword)}
            helperText={
              errors.confirmPassword && errors.confirmPassword.message
            }
            name="confirmPassword"
            type={confirmPasswordShown ? 'text' : 'password'}
            InputProps={{
              autoComplete: 'off',
              endAdornment: (
                <ShowPassword
                  togglePasswordShow={onToggleConfirmPasswordShown}
                  passwordShown={confirmPasswordShown}
                />
              )
            }}
          />
        </Grid>
        <Grid container className={classes.requestContainer}>
          <Grid item className={classes.fullWidth} sm={5}>
            <BaseButton
              color="primary"
              variant="contained"
              form="editPasswordForm"
              onClick={handleSubmit(onSubmitPassword)}
              disabled={!formState.isValid}
              fullWidth
            >
              Guardar
            </BaseButton>
          </Grid>
        </Grid>
      </form>
      <SimpleDivider />
      <Grid item xs={12} sm={5}>
        <BaseButton
          color="error"
          variant="outlined"
          onClick={onDeleteAccountPress}
          fullWidth
        >
          Eliminar cuenta
        </BaseButton>
      </Grid>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  requestContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start'
    },
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  optionWithErrors: {
    marginBottom: -17
  },
  inputFieldContainer: {
    width: '100%',
    marginBottom: theme.spacing(3)
  },
  linkButtonContainer: {
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(0)
  },
  fullWidth: {
    width: '100%'
  }
}));

export default MyProfileUserPassword;
