/* eslint-disable complexity */
import React, { useLayoutEffect, useRef } from 'react';
import clsx from 'clsx';
import { Checkbox, Grid, makeStyles, Typography } from '@material-ui/core';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SmsIcon from '@material-ui/icons/Message';
import EmailIcon from '@material-ui/icons/Email';

import BaseTooltip from '../../../../../Components/Tooltip/BaseTooltip';
import { SendingMethod } from '../../../../../Enums/invoices';

const ContractAssociationFormNotificationChannels = props => {
  const {
    digitalChannels,
    handleDigitalInvoiceChannels,
    showWhatsAppChannel = true
  } = props;

  // * OTHER HOOKS
  const classes = useStyles();

  // * REFS
  const rootRef = useRef(null);

  useLayoutEffect(() => {
    if (rootRef.current) {
      rootRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div className={classes.root} ref={rootRef}>
      <div className={clsx(classes.itemContainer, classes.lastItemContainer)}>
        <Grid container>
          <Grid container item xs={12} sm={6} md={7} alignItems="center">
            <Typography className={classes.itemText}>
              ¿Por cuáles de los canales digitales quieres recibir tu factura?
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={6}
            md={5}
            className={classes.channelsContainer}
          >
            <Grid container direction="column">
              <Grid
                item
                container
                justify="space-between"
                alignItems="baseline"
              >
                <Typography className={classes.channelItemText}>
                  <EmailIcon className={classes.channelOptionIcon} /> Correo
                  electrónico
                  <BaseTooltip
                    className={classes.emailTooltip}
                    tooltipText={'Para factura digital es obligatorio.'}
                    showTooltipOnClick
                    startWithBreakline
                  />
                </Typography>
                <Checkbox
                  color="primary"
                  checked={digitalChannels.email}
                  disabled
                  classes={{ root: classes.checkboxRoot }}
                />
              </Grid>
              {showWhatsAppChannel && (
                <Grid
                  item
                  container
                  justify="space-between"
                  alignItems="baseline"
                >
                  <Typography className={classes.channelItemText}>
                    <WhatsAppIcon className={classes.channelOptionIcon} />
                    WhatsApp
                  </Typography>
                  <Checkbox
                    color="primary"
                    checked={digitalChannels.whatsapp}
                    onChange={() =>
                      handleDigitalInvoiceChannels(SendingMethod.WhatsApp)
                    }
                    classes={{ root: classes.checkboxRoot }}
                  />
                </Grid>
              )}
              <Grid
                item
                container
                justify="space-between"
                alignItems="baseline"
              >
                <Typography className={classes.channelItemText}>
                  <SmsIcon className={classes.channelOptionIcon} />
                  SMS
                </Typography>
                <Checkbox
                  color="primary"
                  checked={digitalChannels.sms}
                  onChange={() =>
                    handleDigitalInvoiceChannels(SendingMethod.SMS)
                  }
                  classes={{ root: classes.checkboxRoot }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      flexDirection: 'row'
    }
  },
  lastItemContainer: {
    paddingBottom: theme.spacing()
  },
  itemText: {
    fontSize: 14,
    fontWeight: 500,
    paddingBottom: theme.spacing(),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingBottom: theme.spacing(2),
      paddingRight: 0,
      paddingLeft: 0
    }
  },
  channelsContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: theme.spacing()
    }
  },
  channelItemText: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    paddingLefT: theme.spacing(),
    paddingBottom: theme.spacing(2),
    '* > :last-child': {
      paddingBottom: 0
    }
  },
  channelOptionIcon: {
    color: theme.palette.primary.main,
    fontSize: 28,
    paddingRight: theme.spacing()
  },
  emailTooltip: {
    paddingLeft: theme.spacing(),
    cursor: 'pointer'
  },
  checkboxRoot: {
    padding: 0
  }
}));

export default ContractAssociationFormNotificationChannels;
