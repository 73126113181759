import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import CircularMenuGDC from './CircularMenu.GDC';
import CircularMenuGDG from './CircularMenu.GDG';

const CircularMenu = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <CircularMenuGDC {...props} />;
    case Portal.Gasguajira:
      return <CircularMenuGDG {...props} />;
    case Portal.Efigas:
      return <CircularMenuGDC {...props} />;
    default:
      return <></>;
  }
};

export default CircularMenu;
