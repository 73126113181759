import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const GameIframe = ({ loading, setLoading, url }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.gameWrapper}
      style={{
        width: '100%',
        paddingBottom: 'calc((4 / 8) * 100%)'
      }}
    >
      {loading && (
        <div className={classes.progress}>
          <CircularProgress color="secondary" thickness={4} size={60} />
        </div>
      )}
      <iframe
        title="Conectados"
        src={url}
        onLoad={() => {
          setLoading(false);
        }}
      />
    </div>
  );
};

const useStyles = makeStyles({
  progress: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: 'translate(50%, -50%)'
  },
  gameWrapper: {
    position: 'relative',
    height: '100%',
    overflow: 'auto',
    margin: '0 auto',

    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      border: 'none'
    }
  }
});

export default GameIframe;
