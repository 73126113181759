import React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Grid, Hidden } from '@material-ui/core';
import { Company } from '../../Configs/general';
const VisitCard = props => {
  const classes = useStyles();

  const {
    orderId,
    assignmentDate,
    contract,
    orderStatus,
    taskDescription,
    contractor,
    externalData,
    isMobileSize
  } = props;

  const { agentFullName } = externalData || {};

  return (
    <div className={clsx(classes.cardRoot)}>
      <div className={clsx(classes.cardRow)}>
        <Typography
          className={clsx(
            classes.title,
            isMobileSize ? classes.mobiletitle : ''
          )}
        >
          {taskDescription}
        </Typography>
        <Hidden xsDown>
          <Typography className={clsx(classes.status)}>
            {orderStatus}
          </Typography>
        </Hidden>
      </div>
      <Divider />
      <Grid container className={classes.infoContainer}>
        <Hidden smUp>
          <Grid item xs={12}>
            <Typography className={classes.subtitle}>Estado:</Typography>
            <Typography className={clsx(classes.data, classes.status)}>
              {orderStatus}
            </Typography>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={4}>
          <Typography className={classes.subtitle}>Número de orden:</Typography>
          <Typography className={classes.data}>{orderId}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography className={classes.subtitle}>
            {Company.contractConjugation.capitalized.singular.main}:
          </Typography>
          <Typography className={classes.data}>{contract}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography className={classes.subtitle}>
            Fecha de asignación:
          </Typography>
          <Typography className={classes.data}>{assignmentDate}</Typography>
        </Grid>
        {contractor && (
          <Grid item xs={12} sm={4}>
            <Typography className={classes.subtitle}>Contratista:</Typography>
            <Typography className={classes.data}>{contractor}</Typography>
          </Grid>
        )}
        {agentFullName && (
          <Grid item xs={12} sm={4}>
            <Typography className={classes.subtitle}>
              Nombre del agente:
            </Typography>
            <Typography className={classes.data}>{agentFullName}</Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  cardRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(1)
  },
  cardRoot: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.cardDark,
    border: 'solid 1px #dbdbdb',
    borderRadius: theme.custom.borderRadius,
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [`@media (max-width:${theme.breakpoints.values.sm - 1}px)`]: {
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
      paddingLeft: theme.spacing(1.25),
      paddingRight: theme.spacing(1.25)
    },
    [`@media (max-width:${theme.breakpoints.values.xm - 1}px)`]: {
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing(),
      paddingLeft: theme.spacing(1.25),
      paddingRight: theme.spacing(1.25)
    }
  },
  title: {
    fontSize: 18,
    fontWeight: 500,
    textTransform: 'capitalize'
  },
  mobiletitle: {
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'capitalize'
  },
  status: {
    fontSize: 18,
    fontWeight: 500,
    color: theme.palette.primary.main
  },

  subtitle: {
    fontSize: 12,
    fontWeight: 400,
    paddingTop: theme.spacing(1)
  },
  data: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.secondary
  },
  infoContainer: {
    [`@media (max-width:${theme.breakpoints.values.sm - 1}px)`]: {
      flexDirection: 'column'
    },
    [`@media (max-width:${theme.breakpoints.values.xm - 1}px)`]: {
      flexDirection: 'column'
    }
  }
}));

export default VisitCard;
