import React from 'react';
import clsx from 'clsx';

import { Tooltip, IconButton, TextField } from '@material-ui/core';
import {
  AddBoxOutlined as AddIcon,
  IndeterminateCheckBoxOutlined as MinusIcon
} from '@material-ui/icons';

import { makeStyles } from '@material-ui/styles';

import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';

const InstallmentsSelector = ({
  register,
  error,
  control,
  handleBillingsNumber,
  buttonsToTheRight,
  fullWidth,
  productId,
  helperText,
  inputName,
  customClasses = {}
}) => {
  const classes = useStyles();

  const decreaseInstallmentButton = (
    <Tooltip title="Disminuir cuotas">
      <IconButton
        className={clsx(classes.quotaIcons, {
          [classes.quotaIconsToTheRight]: buttonsToTheRight,
          [classes.marginRightIcon]: !buttonsToTheRight
        })}
        size="small"
        color="primary"
        onClick={() => handleBillingsNumber('minus', productId)}
      >
        <MinusIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );

  const increaseInstallmentButton = (
    <Tooltip title="Aumentar cuotas">
      <IconButton
        className={clsx(classes.quotaIcons, {
          [classes.quotaIconsToTheRight]: buttonsToTheRight,
          [classes.marginLeftIcon]: !buttonsToTheRight
        })}
        classes={{
          sizeSmall: clsx({ [classes.noPadding]: buttonsToTheRight })
        }}
        size="small"
        color="primary"
        onClick={() => handleBillingsNumber('plus', productId)}
      >
        <AddIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );

  const numberOfInstallmentField = (
    <Controller
      as={
        <NumberFormat
          customInput={TextField}
          FormHelperTextProps={{
            classes: { root: classes.formHelperText }
          }}
          type="tel"
          allowNegative={false}
          decimalSeparator={false}
          isAllowed={({ floatValue }) => floatValue <= 120 || !floatValue}
          ref={register}
          fullWidth={fullWidth}
          label="Cuotas"
          size="small"
          variant="outlined"
          error={error}
          helperText={helperText}
          margin="none"
        />
      }
      className={clsx(classes.quotaInput, {
        [classes.quotaInputRight]: buttonsToTheRight,
        [classes.quotaInputFullWidth]: fullWidth
      })}
      onChangeName="onValueChange"
      onChange={values => {
        if (values[0]) {
          return values[0].floatValue;
        }
        return 0;
      }}
      control={control}
      defaultValue={0}
      name={inputName}
    />
  );

  if (buttonsToTheRight) {
    return (
      <div className={classes.installmentsSelectorButtonsToTheRight}>
        {numberOfInstallmentField}

        <div className={classes.verticalButtons}>
          {increaseInstallmentButton}
          {decreaseInstallmentButton}
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(classes.installmentsSelector, customClasses.container)}
    >
      {decreaseInstallmentButton}
      {numberOfInstallmentField}
      {increaseInstallmentButton}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  installmentsSelector: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  installmentsSelectorButtonsToTheRight: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  verticalButtons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  helperText: {
    fontSize: 12,
    color: theme.palette.text.greyDark,
    fontWeight: 600,
    margin: 0,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      margin: theme.spacing(1, 0)
    }
  },
  quotaInput: {
    maxWidth: 128,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      maxWidth: '100%',
      width: '100%'
    }
  },
  quotaInputRight: {
    maxWidth: 80,
    backgroundColor: theme.palette.background.default
  },
  quotaInputFullWidth: {
    maxWidth: '100%'
  },
  formHelperText: {
    margin: theme.spacing(0.25, 0, 0),
    fontSize: 11,
    position: 'absolute',
    top: '100%'
  },
  quotaIcons: {
    fontSize: 48,
    color: theme.palette.primary.main
  },
  quotaIconsToTheRight: {
    fontSize: 20
  },
  marginRightIcon: {
    marginRight: theme.spacing(0.5)
  },
  marginLeftIcon: {
    marginLeft: theme.spacing(0.5)
  },
  noPadding: {
    padding: 0
  }
}));

export default InstallmentsSelector;
