import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ButtonBase from '@material-ui/core/ButtonBase';
import { history } from '../../../Routes/history';
import { ROUTE_NAMES } from '../../../Routes/Routes';
import BaseButton from '../../../Components/Buttons/BaseButton';
import LoginDialog from '../../Login/LoginDialog';

const NoAuthHeader = () => {
  // * STATE HOOKS
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  // * OTHER HOOKS
  const classes = useStyles();
  const smallScreen = useMediaQuery('(min-width:600px)');
  const currentPath = history.location.pathname;

  // * FUNCTIONS
  const redirectTo = route => {
    history.push(route);
  };

  const loginClickHandler = () => setOpenLoginDialog(true);

  return (
    <Fragment>
      <LoginDialog open={openLoginDialog} setOpen={setOpenLoginDialog} />
      <Grid container className={classes.root}>
        <Grid item>
          <ButtonBase
            id="NoAuthHeader_button_login"
            disableRipple={smallScreen}
            focusVisibleClassName={classes.focusVisible}
            className={classes.loginButton}
            onClick={
              currentPath === ROUTE_NAMES.login ? null : loginClickHandler
            }
          >
            Iniciar sesión
          </ButtonBase>
        </Grid>
        <Grid item>
          <BaseButton
            id="NoAuthHeader_button_signup"
            color="primary"
            variant="outlined"
            className={classes.button}
            onClick={() => redirectTo(ROUTE_NAMES.register)}
          >
            Registrarme
          </BaseButton>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  button: {
    fontSize: 12,
    marginLeft: theme.spacing(),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
      marginLeft: theme.spacing(2)
    }
  },
  loginButton: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    // * Text
    fontFamily: 'Montserrat',
    textAlign: 'center',
    color: theme.palette.color.black,
    // * Responsive
    [theme.breakpoints.up('xs')]: {
      fontSize: '0.8em'
    },
    [theme.breakpoints.up('xm')]: {
      fontSize: '0.9em'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1em',
      '&:hover, &$focusVisible': {
        color: theme.palette.color.primary
      }
    }
  },
  focusVisible: {}
}));

export default NoAuthHeader;
