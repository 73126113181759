/* eslint-disable max-nested-callbacks */
import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useCallback
} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Breadcrumbs,
  Container,
  Link,
  Typography,
  Grid,
  useMediaQuery
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import _get from 'lodash/get';

import { ROUTE_NAMES } from '../../Routes/Routes';
import { history } from '../../Routes/history';

import SlideShow from '../../Components/SlideShow/SlideShow';
import InsuranceProductCard from '../../Components/Cards/InsuranceProductCard';
import CallToActionDialog from '../../Components/Dialogs/CallToActionDialog';

import ActiveRequestIcon from '../../Components/CustomIcons/IcActiveRequest';

import { UserContext } from '../../Contexts/UserContext';
import { ContractSelectedContext } from '../../Contexts/ContractsContext';
import {
  GetAcquiredInsurancesAPI,
  GetInsurancePlanPricesAPI
} from '../../API/BrillaInsurances/BrillaInsurancesAPI';

import PractiSeguroLogo from '../../Assets/images/img_practiseguro@2x.png';
import ExequialesLogo from '../../Assets/images/img_exequiales.png';

import { AlertsDispatchContext } from '../../Contexts/AlertsContext';
import { extractErrorMessage } from '../../Utils/Errors/Errors';
import {
  InsurancePlans,
  InsuranceRequestMessage,
  INSURANCES_CARROUSEL_SLIDES,
  PractiSeguroProduct
} from '../../Enums/insurances';
import { moneyWithDots } from '../../Utils/Format/MoneyFormat';
import { InsurancePlan } from '../../Enums/insurances';
import { ALERT_TYPE } from '../../Components/Alerts/alert_enums';
import { Company } from '../../Configs/general';

const InsurancesHome = props => {
  const { location: locationParams = { state: null } } = props;
  const params = locationParams.state;

  // * GLOBAL VARS
  const newInsuranceAdquired =
    params && params.confirmation && params.contract && params.selectedPlanId;

  const classes = useStyles();
  const isMobileSize = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const selectedContract = useContext(ContractSelectedContext);
  const setAlert = useContext(AlertsDispatchContext);

  // * STATES
  const [loading, setLoading] = useState(false);
  const [acquiredProducts, setAcquiredProducts] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(newInsuranceAdquired);
  const [minimumPlanPrice, setMinimumPlanPrice] = useState(null);

  const authToken = _get(currentUser, 'token');

  useEffect(() => {
    let isCancelled = false;
    const fetchData = async ({ loggedIn = false }) => {
      setLoading(true);

      if (loggedIn) {
        const response = await GetAcquiredInsurancesAPI(
          authToken,
          selectedContract.id,
          parseInt(currentUser.identification, 10)
        );

        if (!isCancelled) {
          if (response.success) {
            setAcquiredProducts(
              response.data.map(product => ({
                ...product,
                plan: InsurancePlans[product.idPlan]
              }))
            );
          } else {
            setAlert({
              type: ALERT_TYPE.ERROR,
              message: extractErrorMessage(response).message
            });
          }
        }
      }

      const response = await GetInsurancePlanPricesAPI(authToken);

      if (!isCancelled) {
        if (response.success) {
          const responseData = response.data;

          if (!responseData || !responseData.valid) {
            return;
          }

          const minPlanPrice = responseData.prices
            .filter(({ id }) => id === InsurancePlan.Silver)
            .map(({ price }) => price)[0];

          setMinimumPlanPrice(minPlanPrice);
        } else {
          setAlert({
            type: ALERT_TYPE.ERROR,
            message: extractErrorMessage(response).message
          });
        }
        setLoading(false);
      }
    };

    fetchData({ loggedIn: currentUser && selectedContract });

    return () => {
      isCancelled = true;
    };
  }, [selectedContract, currentUser, setAlert, authToken]);

  // * FUNCTIONS
  const handleClickPractiseguro = useCallback(() => {
    //TODO: preguntar qué pasa cuando hay más de un seguro
    if (acquiredProducts.length > 0) {
      history.push(ROUTE_NAMES.managePractiseguro);
    } else {
      history.push(ROUTE_NAMES.practiseguroDetails);
    }
    return;
  }, [acquiredProducts]);

  const handleCloseDialog = () => {
    setDialogOpen(false);
    history.replace(locationParams.pathname, {});
  };

  return (
    <Fragment>
      {newInsuranceAdquired && (
        <CallToActionDialog
          open={dialogOpen}
          icon={ActiveRequestIcon}
          confirmButtonText={'Entendido'}
          handleClose={handleCloseDialog}
          handleConfirmClick={handleCloseDialog}
          {...InsuranceRequestMessage[params.confirmation.process](
            params.confirmation.applicationId
          )}
          nodeBetweenMessages={
            <Typography className={classes.subtitleText}>
              {Company.contractConjugation.capitalized.singular.main}: Casa -{' '}
              {params.contract.id} |{' '}
              {InsurancePlans[params.selectedPlanId].name}
            </Typography>
          }
          fullScreen={isMobileSize}
          paperClass={classes.paperClass}
        />
      )}
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          <Breadcrumbs
            aria-label="breadcrumb"
            className={classes.breadcrumbs}
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              color="primary"
              component={RouterLink}
              to="/"
              underline="always"
            >
              Inicio{' '}
            </Link>
            <Typography color="textPrimary">Seguros</Typography>
          </Breadcrumbs>
          <Typography className={clsx(classes.title)}>
            Bienvenido a Seguros {Company.name}
          </Typography>
          <Grid>
            <Typography className={clsx(classes.label)} display="inline">
              Porque tu bienestar y el de tus seres queridos es muy importante
              para nosotros, te invitamos a conocer los seguros que puedes pagar
              a través de tu factura de gas natural.{' '}
            </Typography>
            <Typography className={clsx(classes.labelBold)} display="inline">
              ¡Adquiere ya tu seguro!
            </Typography>
          </Grid>
          <Grid container className={clsx(classes.cardContainer)}>
            <Grid item sm={6}>
              <InsuranceProductCard
                logo={PractiSeguroLogo}
                title="Practi Seguro"
                loading={loading}
                descriptionNode={
                  minimumPlanPrice ? (
                    <Typography
                      className={clsx(classes.productCardDescription)}
                    >
                      Fortalece el futuro de tu familia con cómodas cuotas{' '}
                      <b>
                        desde {moneyWithDots(minimumPlanPrice)} mensuales y
                        hasta{' '}
                        {moneyWithDots(PractiSeguroProduct.maxMonthlyAllowance)}
                        .
                      </b>
                    </Typography>
                  ) : (
                    <Typography
                      className={clsx(classes.productCardDescription)}
                    >
                      Con tu seguro de vida Practiseguro, fortalece el futuro de
                      tu familia.
                    </Typography>
                  )
                }
                isLoggedIn={Boolean(currentUser)}
                productData={acquiredProducts.length && acquiredProducts[0]}
                buttonOnClick={handleClickPractiseguro}
              />
            </Grid>
            <Grid item sm={6}>
              <InsuranceProductCard
                disabled
                logo={ExequialesLogo}
                title="Seguro funerario"
                loading={loading}
                descriptionNode={
                  <Typography className={clsx(classes.productCardDescription)}>
                    Ampara hasta 5 habitantes de tu predio menores a 69 años
                    desde $13.913 mensuales.
                  </Typography>
                }
                isLoggedIn={Boolean(currentUser)}
                productData={acquiredProducts.funeralData}
                buttonOnClick={null}
              />
            </Grid>
          </Grid>
          {!loading && <SlideShow slides={INSURANCES_CARROUSEL_SLIDES} />}
        </Container>
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  container: {
    flex: 1,
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginTop: theme.spacing(3)
    },
    '& > *': {
      marginBottom: theme.spacing(3)
    },
    '& > *:last-child': {
      marginBottom: theme.spacing(5)
    }
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    '& > *': {
      marginRight: theme.spacing(2)
    },
    '& > *:last-child': {
      marginRight: 0
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'column',
      flexWrap: 'wrap',
      '& > *': {
        marginRight: 0,
        marginBottom: theme.spacing(2)
      }
    }
  },
  breadcrumbs: {
    fontSize: 15,
    fontWeight: theme.typography.fontWeightMedium,
    '& *': { fontSize: 'inherit' }
  },
  modalBackground: {
    backgroundColor: '#aaaaaa',
    opacity: '20%',
    height: '100%',
    width: '100%'
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1.2,
    justifyContent: 'center'
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.greyDark
  },
  labelBold: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.common.black
  },
  productCardDescription: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.greyDark
  },
  paperClass: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      width: '100%',
      borderRadius: 0
    }
  }
}));

export default InsurancesHome;
