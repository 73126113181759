import axios from 'axios';
import { config } from '../../Configs';

export const TransactionStatusAPI = async transactionId => {
  try {
    const response = await axios.get(
      `${config.api.url}/transactions/${transactionId}`
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const TransactionPDFAPI = async transactionId => {
  try {
    const response = await axios.get(
      `${config.api.url}/transactions/pdf/${transactionId}`
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const TransactionDetailAPI = async transactionId => {
  try {
    const response = await axios.get(
      `${config.api.url}/transactions/detail/${transactionId}`
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};
