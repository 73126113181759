import React, { Fragment, useState, useCallback, useContext } from 'react';
import * as yup from 'yup';
import { Grid, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { extractErrorMessage } from '../../../../Utils/Errors/Errors';

import { AlertsDispatchContext } from '../../../../Contexts/AlertsContext';

import { SlideUpTransition } from '../../../../Components/Transitions/Transitions';
import BaseDialog from '../../../../Components/Dialogs/BaseDialog';
import BaseButton from '../../../../Components/Buttons/BaseButton';
import NumberFormat from 'react-number-format';
import { Controller, useForm } from 'react-hook-form';
import { ProductType } from '../../myDebts_enums';
import { submitExternalForm } from '../../../../Utils/Misc/Form';
import { moneyWithDots } from '../../../../Utils/Format/MoneyFormat';
import Skeleton from '@material-ui/lab/Skeleton';

const couponValueSchema = maxValue => {
  const minSchema = yup
    .number()
    .min(100, 'Debe ser mayor que $100')
    .required('Ingresa el valor del cupón');

  const maxSchema = yup
    .number()
    .max(maxValue, `Debe ser menor o igual que ${moneyWithDots(maxValue)}`);

  const schema = yup.object({
    value: maxValue ? minSchema.concat(maxSchema) : minSchema
  });

  return schema;
};

const ValuePartialPaymentStep = props => {
  const {
    open,
    title,
    onClose,
    authToken,
    setNextModal,
    requestCallback,
    step,
    description
  } = props;

  const { maxValue, currentDebtValueExist, ...dataRest } = step.data;

  const isMobileSize = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  // * STATE HOOKS
  const { control, errors, handleSubmit } = useForm({
    validationSchema: couponValueSchema(maxValue),
    mode: 'onBlur'
  });

  // * CONTEXT HOOKS`
  const setAlert = useContext(AlertsDispatchContext);
  const [loading, setLoading] = useState(false);
  // * OTHER HOOKS
  const classes = useStyles();

  const generateButtonClickHandler = () => {
    submitExternalForm('Partial_payment_query_form');
  };

  // * FORM HANDLERS
  const onSubmit = useCallback(
    async values => {
      setLoading(true);
      const { value: couponValue } = values;

      const response = await requestCallback(authToken, {
        value: couponValue,
        productTypeIds: [ProductType.FinancingServices],
        ...dataRest
      });

      if (response.success) {
        if (setNextModal) {
          setNextModal({
            type: 'generation',
            data: {
              ...response.data.data,
              ...{ value: couponValue }
            }
          });
        }
        return;
      }
      setAlert({
        type: 'error',
        message: extractErrorMessage(response).message
      });
      setLoading(false);
      return;
    },
    [setAlert, setNextModal, authToken, requestCallback, dataRest]
  );

  //* COMPONENTS
  const renderActions = useCallback(() => {
    return (
      <Fragment>
        <BaseButton
          onClick={onClose}
          variant="outlined"
          color="primary"
          size="small"
        >
          Cancelar
        </BaseButton>
        <BaseButton
          color="primary"
          size="small"
          disabled={loading || Boolean(errors.value)}
          onClick={generateButtonClickHandler}
        >
          Generar
        </BaseButton>
      </Fragment>
    );
  }, [loading, onClose, errors.value]);

  const renderContent = useCallback(() => {
    return (
      <div id="ContractStep_div_container" className={classes.content}>
        <div className={classes.dialogDescription}>
          {description || (
            <Typography>
              Digita el valor que deseas abonar a la deuda:
            </Typography>
          )}
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          id="Partial_payment_query_form"
          className={classes.contractForm}
        >
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item className={classes.textHeight}>
              {maxValue > 0 && (
                <Typography className={classes.maxValueText}>
                  Valor <span className={classes.lightBold}>máximo</span> a
                  abonar:{' '}
                  <span className={classes.lightBold}>
                    {moneyWithDots(maxValue)}
                  </span>
                </Typography>
              )}
              {!maxValue && !currentDebtValueExist && (
                <Skeleton className={classes.skeleton} />
              )}
            </Grid>
            <Grid item>
              <Controller
                as={
                  <NumberFormat
                    customInput={TextField}
                    type="tel"
                    prefix="$ "
                    thousandSeparator="."
                    allowNegative={false}
                    decimalSeparator={false}
                    fullWidth={isMobileSize}
                    isAllowed={({ floatValue }) =>
                      floatValue <= 999999999 || !floatValue
                    }
                    label={'Valor a abonar'}
                    variant="outlined"
                    onBlur={() => {}}
                    error={errors && Boolean(errors.value)}
                    helperText={errors && errors.value && errors.value.message}
                    defaultValue={''}
                    FormHelperTextProps={{
                      classes: { root: classes.inputValueText }
                    }}
                  />
                }
                onChangeName="onValueChange"
                onChange={values => {
                  if (values[0]) {
                    return values[0].floatValue;
                  }
                  return 0;
                }}
                control={control}
                name={'value'}
                className={classes.inputValueContainer}
              />
            </Grid>
          </Grid>
          <Typography className={classes.textSmall}>
            El costo de generación es totalmente gratuito*
          </Typography>
        </form>
      </div>
    );
  }, [
    onSubmit,
    handleSubmit,
    errors,
    classes,
    control,
    isMobileSize,
    description,
    maxValue,
    currentDebtValueExist
  ]);

  // * RENDER
  return (
    <BaseDialog
      open={open}
      loading={loading}
      handleClose={onClose}
      title={title}
      actions={renderActions}
      content={renderContent}
      fullScreen={isMobileSize}
      contentSize="small"
      TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
    />
  );
};

const useStyles = makeStyles(theme => ({
  content: {
    display: 'center',
    flexDirection: 'column',
    alignItems: 'flex'
  },
  text: {
    fontSize: 14,
    color: theme.palette.text.primary,
    width: '100%',
    display: 'center'
  },
  contractForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  contractInput: {
    width: 250,
    marginTop: theme.spacing(4)
  },
  textSmall: {
    fontSize: 10,
    color: theme.palette.text.blackLight,
    marginTop: theme.spacing()
  },
  maxValueText: {
    marginBottom: theme.spacing(2)
  },
  lightBold: {
    fontWeight: 500
  },
  inputValueText: {
    fontSize: 11
  },
  dialogDescription: {
    fontSize: 14,
    color: theme.palette.text.primary,
    width: '100%'
  },
  skeleton: {
    margin: theme.spacing(0.5, 0, 0),
    height: 14,
    width: 200,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      margin: theme.spacing(1, 0)
    }
  },
  textHeight: {
    height: 35,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      height: 45,
      padding: theme.spacing(2, 0, 1.5)
    }
  }
}));

export default ValuePartialPaymentStep;
