/* eslint-disable complexity */
import React, {
  Fragment,
  useState,
  useCallback,
  useMemo,
  useContext
} from 'react';
import _get from 'lodash/get';
import { Container, Typography, makeStyles } from '@material-ui/core';

import { history } from '../../../Routes/history';
import { ROUTE_NAMES } from '../../../Routes/Routes';

import Stepper from '../../../Components/Stepper/Stepper';
import FullSizeProgress from '../../../Components/Progress/FullSize/FullSizeProgress';
import BaseDialog from '../../../Components/Dialogs/BaseDialog';
import BaseButton from '../../../Components/Buttons/BaseButton';
import { defaultAlertError } from '../../../Components/Alerts/AlertMessages';
import { ALERT_TYPE } from '../../../Components/Alerts/alert_enums';

import { extractErrorMessage } from '../../../Utils/Errors/Errors';

import {
  UserContext,
  UserDispatchContext
} from '../../../Contexts/UserContext';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';

import SecondStep from '../SecondStep/Second';
import FooterPayment from '../FooterPayment';
import PaymentOptions from '../FirstStep/PaymentOptions';
import {
  INDIVIDUAL_STATE,
  INDIVIDUAL_LOAD_INITIAL_STATE,
  MULTIPLE_LOAD_INITIAL_STATE,
  TABS_INITIAL_STATE,
  TRANSACTION_STATUS,
  TYPE_OF_PAYMENTS,
  PAYMENT_METHOD,
  PAYMENT_VALUE_OPTION,
  TRANSACTION_TYPES
} from '../payment_enums';
import { getTotalAmount } from '../helpers/helpers';

// * API
import {
  PayPseAPI,
  CreateGroupedPaymentAPI
} from '../../../API/Payments/PaymentsAPI';
import {
  CreateFinancingTransactionAPI,
  GetPartialPaymentCouponAPI
} from '../../../API/Debts/DebtsAPI';

import {
  DefaultParams,
  buildFinancingPSERequest,
  buildPSERequest
} from './Payment';
import { Company } from '../../../Configs/general';
import { strSearchParams2Object } from '../../TransactionsList/helpers/helpers';
import ModalProgress from '../../../Components/Progress/Modal/ModalProgress';
import {
  GenerateDuplicateCouponAPI,
  GetCouponPDFAPI
} from '../../../API/Coupons/CouponsAPI';
import { saveAsPDF } from '../../../Utils/Transform/Files';
import { InvoicePeriod } from '../../../Enums/invoices';
import { RecaptchaAction } from '../../../Enums/recaptcha';
import { getRecaptchaToken } from '../../../Utils/Recaptcha';

const PaymentEFG = props => {
  const { match, location = { state: null } } = props;

  const params = location.state ? location.state : DefaultParams;
  const parsedParams = strSearchParams2Object(window.location.search);

  const backToDeferredPayment = useCallback(() => {
    history.replace(ROUTE_NAMES.deferredPayment, {
      initialStepIndex: 2,
      couponId: params.value,
      addedDeferreds: params.addedDeferreds,
      selectedContract: params.selectedContract
    });
  }, [params.value, params.addedDeferreds, params.selectedContract]);

  if (match && match.params) {
    if (match.params.contractId) {
      params.value = match.params.contractId;
      params.type = TYPE_OF_PAYMENTS.contract;
    } else if (match.params.couponId) {
      params.value = match.params.couponId;
      params.type = TYPE_OF_PAYMENTS.coupon;
    }
  }

  const paramsPayments = (params || {}).payment;
  const paramsPayment = (paramsPayments || [])[0] || {};
  const defaultPaymentValueOption = paramsPayment.canBePaidPartially
    ? ''
    : PAYMENT_VALUE_OPTION.TotalCoupon;

  // * STATE HOOKS
  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const setAlert = useContext(AlertsDispatchContext);
  const authToken = _get(currentUser, 'token', null);
  const isIndividualTabParam =
    _get(parsedParams, 'individualTab', false) === 'true';
  const isMultiplePaymentsParam =
    _get(parsedParams, 'multiplePayments', false) === 'true';

  const [currentStep, setCurrentStep] = useState(params.currentStep || 0);
  const [number, setNumber] = useState(params.value);
  const [type, setType] = useState(params.type);
  const [loading, setLoading] = useState(false);
  const [disabledNext, setDisabledNext] = useState(
    params.disabledNext !== false
  );
  const [payments, setPayments] = useState(params.payment || []);
  const [methodSelected, setMethodSelected] = useState(PAYMENT_METHOD.PSE);
  const [isCardStep, setCardStep] = useState(false);
  const [payerInfo, setPayerInfo] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [cardIndividualLoad, setCardIndividualLoad] = useState({
    ...INDIVIDUAL_LOAD_INITIAL_STATE,
    toPay: params.payment || []
  });
  const [cardMultipleLoad, setCardMultipleLoad] = useState(
    MULTIPLE_LOAD_INITIAL_STATE
  );
  const [generatedGroupedCoupon, setGeneratedGroupedCoupon] = useState(null);
  const [paymentsCopy, setPaymentsCopy] = useState([]);
  const [totalCurrentCardsCopy, setTotalCurrentCardsCopy] = useState('');
  const [checkedMultiplePayments, setCheckedMultiplePayments] = useState(
    isMultiplePaymentsParam
  );
  const [currentTabIndex, setCurrentTabIndex] = useState(
    isIndividualTabParam ? INDIVIDUAL_STATE : TABS_INITIAL_STATE
  );
  const [paymentValueOption, setPaymentValueOption] = useState(
    defaultPaymentValueOption
  );
  const [loadingPDF, setLoadingPDF] = useState(false);

  const paymentData = useMemo(() => payments.length && payments[0], [payments]);
  const canPayPartially = paymentData.canBePaidPartially;

  // * OTHER HOOKS
  const classes = useStyles();

  // * FUNCTIONS

  const handleGoBack = useCallback(() => {
    setPayerInfo(null);
    if (payments.length && payments.type === TYPE_OF_PAYMENTS.couponless) {
      history.goBack();
    }
    setCurrentStep(currentStep - 1);
  }, [currentStep, setCurrentStep, payments]);

  const handleGoToCardStep = useCallback(() => {
    setCardStep(true);
  }, [setCardStep]);

  const totalIndividualCards = useMemo(() => {
    return [
      ...cardIndividualLoad.error,
      ...cardIndividualLoad.pending,
      ...cardIndividualLoad.paid,
      ...cardIndividualLoad.toPay,
      ...cardIndividualLoad.loading
    ];
  }, [cardIndividualLoad]);

  const totalMultipleLoadCards = useMemo(() => {
    return [
      ...cardMultipleLoad.error,
      ...cardMultipleLoad.pending,
      ...cardMultipleLoad.paid,
      ...cardMultipleLoad.toPay,
      ...cardMultipleLoad.loading
    ];
  }, [cardMultipleLoad]);

  const totalCurrentCards = useMemo(() => {
    return [...totalIndividualCards, ...totalMultipleLoadCards];
  }, [totalIndividualCards, totalMultipleLoadCards]);

  const handleSaveCopies = useCallback(() => {
    setPaymentsCopy(JSON.stringify(payments));
    setTotalCurrentCardsCopy(JSON.stringify(totalCurrentCards));
  }, [payments, totalCurrentCards]);

  const handleGoForward = useCallback(() => {
    handleSaveCopies();
    setCurrentStep(currentStep + 1);
  }, [currentStep, setCurrentStep, handleSaveCopies]);

  const handleGroupedCouponPayment = useCallback(async () => {
    if (!(payments.length > 0)) {
      return;
    }

    const totalPayment = getTotalAmount(payments);
    try {
      const { success, data } = await CreateGroupedPaymentAPI(
        payments,
        totalPayment
      );
      if (success) {
        setGeneratedGroupedCoupon(data.data.couponId);
        setGeneratedGroupedCoupon({
          type: 'coupon',
          couponId: data.data.couponId,
          detail: {
            expirationDate: '-',
            generationDate: '-',
            contractAddress: null,
            contractCity: null,
            isPaid: false,
            isPending: false,
            couponId: data.data.couponId,
            contractId: '-',
            value: totalPayment
          }
        });
        handleGoForward();
      }
    } catch (err) {
      setAlert({
        type: ALERT_TYPE.WARNING,
        message: `Ha ocurrido un error, por favor revise ${Company.contractConjugation.regular.plural.article} y cupones ingresados`,
        onClose: () => history.push(`${ROUTE_NAMES.payments}`)
      });
      return;
    }
  }, [handleGoForward, payments, setAlert]);

  const getPartialCoupon = useCallback(
    async payment => {
      const { partialData, detail } = payment;
      const {
        totalCurrentDebtValue,
        partialPayment,
        productsDetails
      } = partialData;

      const body = {
        totalCurrentDebtValue,
        partialPayment,
        productsDetails,
        referenceCouponId: detail.couponId
      };

      let recaptchaToken;
      if (!authToken) {
        recaptchaToken = await getRecaptchaToken(
          RecaptchaAction.PartialCouponPayments
        );
      }

      const { success, data, error } = await GetPartialPaymentCouponAPI(
        detail.contractId,
        body,
        authToken,
        recaptchaToken
      );

      if (success) {
        const { couponId } = data.data;
        return couponId;
      }

      defaultAlertError(error, setAlert);
    },
    [authToken, setAlert]
  );

  const getDuplicateCoupon = useCallback(
    async (contractId, period, origin) => {
      const { success, error, data } = await GenerateDuplicateCouponAPI(
        contractId,
        period,
        authToken,
        origin
      );

      if (success) {
        const { couponId } = data.data;
        if (paymentValueOption === PAYMENT_VALUE_OPTION.CurrentDebt) {
          setPayments([
            {
              ...paymentData,
              partialData: { ...paymentData.partialData, couponId }
            }
          ]);
        }
        return couponId;
      }

      defaultAlertError(error, setAlert);
    },
    [authToken, setAlert, paymentValueOption, paymentData]
  );

  const getCouponIdHandler = useCallback(
    async evt => {
      const { partialData, detail } = paymentData;
      const { couponId: generatedCouponId } = partialData || {};

      switch (paymentValueOption) {
        case PAYMENT_VALUE_OPTION.TotalCoupon:
          return detail.couponId;
        case PAYMENT_VALUE_OPTION.CurrentDebt: {
          // This happens when the user download the coupon payment
          // coupon first and then try to do the payment
          if (generatedCouponId) {
            return generatedCouponId;
          }

          const eventTarget = evt && evt.target;
          const componentId = (eventTarget && eventTarget.id) || '';
          const componentIdFromParent =
            (eventTarget &&
              eventTarget.offsetParent &&
              eventTarget.offsetParent.id) ||
            '';

          return await getDuplicateCoupon(
            detail.contractId,
            InvoicePeriod.Current,
            {
              componentId: componentId || componentIdFromParent,
              url: window.location.pathname
            }
          );
        }
        case PAYMENT_VALUE_OPTION.Partial: {
          // This happens when the user download the coupon first
          // and then try to do the payment
          if (generatedCouponId) {
            return generatedCouponId;
          }
          return await getPartialCoupon(paymentData);
        }

        default:
          return;
      }
    },
    [paymentValueOption, getPartialCoupon, paymentData, getDuplicateCoupon]
  );

  const handlePsePayment = useCallback(
    async couponId => {
      if (!(payments.length > 0) || !couponId) {
        return;
      }

      // pre-request states
      setLoading(true);
      setDisabledNext(true);

      // request to api
      const body = buildPSERequest(couponId, payerInfo);
      const { success, data, error } = await PayPseAPI(
        {
          ...body,
          product: {
            couponId,
            donationValue: 0
          },
          transactionType:
            paymentValueOption === PAYMENT_VALUE_OPTION.Partial
              ? TRANSACTION_TYPES.PartialPayment
              : null
        },
        _get(currentUser, 'token', null)
      );

      // update last bank only if there is logged user
      if (currentUser) {
        setCurrentUser(user => ({
          ...user,
          lastSelectedBank: payerInfo.currentBank
        }));
      }

      if (success) {
        const transaction = data.data;

        if (
          transaction.status === TRANSACTION_STATUS.Pending &&
          transaction.bankUrl
        ) {
          window.location.href = transaction.bankUrl;
          return;
        }

        if (data.status === 'warning') {
          setAlert({
            type: ALERT_TYPE.WARNING,
            message: data.message,
            onClose: () =>
              history.push(
                `${ROUTE_NAMES.transaction}/${transaction.transactionId}`
              )
          });

          return;
        }

        history.replace(
          `${ROUTE_NAMES.transaction}/${transaction.transactionId}`
        );

        return;
      }
      defaultAlertError(error, setAlert);
      setDisabledNext(false);
      setLoading(false);
    },
    [
      payments,
      setLoading,
      setDisabledNext,
      payerInfo,
      currentUser,
      setAlert,
      setCurrentUser,
      paymentValueOption
    ]
  );

  const handleFinancingPayment = useCallback(async () => {
    if (!(payments.length > 0)) {
      return;
    }

    setLoading(true);
    setDisabledNext(true);

    const paymentDetail = payments[0].detail;

    const financingInfo = {
      price: paymentDetail.totalInitialInstallmentValue,
      contractId: paymentDetail.contractId
    };

    const { requestId } = paymentDetail;

    const body = buildFinancingPSERequest(financingInfo, payerInfo, requestId);

    const transactionResponse = await CreateFinancingTransactionAPI(
      authToken,
      body
    );

    if (transactionResponse.success) {
      const transaction = transactionResponse.data.data;

      if (transaction.status === TRANSACTION_STATUS.Pending) {
        window.location.href = transaction.bankUrl;
        return;
      }
      history.replace(
        `${ROUTE_NAMES.transaction}/${transaction.transactionUuid}`
      );

      return;
    }

    const error = extractErrorMessage(transactionResponse);
    setAlert({
      type: ALERT_TYPE.ERROR,
      message: error.message
    });

    setDisabledNext(false);
    setLoading(false);
  }, [authToken, payments, payerInfo, setAlert]);

  const handleSetPayment = useCallback((value, scrollRef) => {
    setPayments(value);
    if (scrollRef) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, []);

  const handlePaymentByType = useCallback(
    async evt => {
      switch (type) {
        case TYPE_OF_PAYMENTS.financing:
          return handleFinancingPayment();
        case TYPE_OF_PAYMENTS.contract:
        case TYPE_OF_PAYMENTS.coupon:
          if (payments.length === 1) {
            setLoading(true);
            const couponId = await getCouponIdHandler(evt);
            setLoading(false);
            return handlePsePayment(couponId);
          }
          return handlePsePayment(generatedGroupedCoupon.couponId);
        default:
          return;
      }
    },
    [
      generatedGroupedCoupon,
      handleFinancingPayment,
      handlePsePayment,
      payments,
      type,
      getCouponIdHandler
    ]
  );

  const handleRemoveInvalidCardsAndForward = () => {
    setCardIndividualLoad(prev => ({
      ...prev,
      loading: [],
      paid: [],
      pending: [],
      error: []
    }));

    setCardMultipleLoad(prev => ({
      ...prev,
      loading: [],
      paid: [],
      pending: [],
      error: []
    }));

    setAlert({
      type: ALERT_TYPE.SUCCESS,
      timeout: 5000,
      toast: true,
      message: 'Han sido removidos exitosamente'
    });

    handleTransactionCase();
    setOpenDialog(false);
  };

  const handleTransactionCase = useCallback(() => {
    const currentPaymentsString = JSON.stringify(payments);
    const totalCurrentCardsString = JSON.stringify(totalCurrentCards);

    const allCardsEqual =
      currentPaymentsString === paymentsCopy &&
      totalCurrentCardsString === totalCurrentCardsCopy;

    if (payments.length === 1) {
      handleGoForward();
    } else if (allCardsEqual) {
      handleGoForward();
    } else {
      handleGroupedCouponPayment();
    }
  }, [
    handleGoForward,
    handleGroupedCouponPayment,
    payments,
    paymentsCopy,
    totalCurrentCards,
    totalCurrentCardsCopy
  ]);

  const handleElementStatus = useCallback(() => {
    const totalInvalidIndividual =
      cardIndividualLoad.loading.length +
      cardIndividualLoad.paid.length +
      cardIndividualLoad.pending.length +
      cardIndividualLoad.error.length;
    const totalInvalidMultiple =
      cardMultipleLoad.loading.length +
      cardMultipleLoad.paid.length +
      cardMultipleLoad.pending.length +
      cardMultipleLoad.error.length;

    if (totalInvalidMultiple + totalInvalidIndividual === 0) {
      return handleTransactionCase();
    }

    return setOpenDialog(true);
  }, [
    cardIndividualLoad.loading.length,
    cardIndividualLoad.paid.length,
    cardIndividualLoad.pending.length,
    cardIndividualLoad.error.length,
    cardMultipleLoad.loading.length,
    cardMultipleLoad.paid.length,
    cardMultipleLoad.pending.length,
    cardMultipleLoad.error.length,
    handleTransactionCase
  ]);

  const onDownloadCoupon = useCallback(
    async evt => {
      setLoadingPDF(true);
      const couponId = await getCouponIdHandler(evt);

      if (!couponId) {
        setLoadingPDF(false);
        return;
      }

      const { partialData } = paymentData;
      const { paymentValueOption: option, couponId: partialCouponId } =
        partialData || {};

      if (
        partialData &&
        option !== PAYMENT_VALUE_OPTION.TotalCoupon &&
        !partialCouponId
      ) {
        setPayments([
          { ...paymentData, partialData: { ...partialData, couponId } }
        ]);
      }

      const response = await GetCouponPDFAPI(couponId);
      if (!response.success) {
        setLoadingPDF(false);
        setAlert({
          type: 'error',
          message: 'Ocurrió un error, intenta de nuevo'
        });
        return;
      }
      const base64 = response.data.data.pdf;
      const pdfName = `pagos_${Company.shortName.toLowerCase()}_cupon_${couponId}.pdf`;
      saveAsPDF(base64, pdfName);
      setLoadingPDF(false);
    },
    [setAlert, getCouponIdHandler, paymentData]
  );

  const steps = useMemo(() => {
    const usedSteps = [
      {
        label: 'Referencia de pago',
        subtitle:
          'Selecciona el modo en el que deseas agregar la referencia de pago',
        onBack: params.fromDeferredPayment ? backToDeferredPayment : null,
        action: { text: 'Continuar', onCall: handleElementStatus }
      },
      {
        label: 'Método de pago',
        onBack: handleGoBack,
        action: {
          text: 'Ir a PSE',
          onCall: handlePaymentByType
        }
      },
      {
        label: 'Transacción'
      }
    ];
    if (methodSelected === PAYMENT_METHOD.CARD) {
      usedSteps[1] = {
        label: 'Método de pago',
        action: { text: 'Continuar', onCall: handleGoToCardStep }
      };
    }
    // WARNING: El pago con tarjeta no está habilitado
    // if (isCardStep) {
    //   usedSteps[1] = {
    //     label: 'Método de pago',
    //     action: { text: 'Ir al banco', onCall: handlePayment }
    //   };
    // }
    return usedSteps;
  }, [
    handleElementStatus,
    handlePaymentByType,
    handleGoBack,
    params.fromDeferredPayment,
    backToDeferredPayment,
    handleGoToCardStep,
    methodSelected
  ]);

  const renderDialogActions = () => {
    return (
      <Fragment>
        <BaseButton onClick={() => handleRemoveInvalidCardsAndForward()}>
          Entendido
        </BaseButton>
      </Fragment>
    );
  };
  const renderDialogContent = useCallback(() => {
    return (
      <>
        <Typography className={classes.dialogText}>
          Al avanzar al siguiente paso, la transacción será realizada solo con
          cupones y/o {Company.contractConjugation.regular.plural.main} en
          estado
          <span className={classes.paymentPending}> Por pagar</span>. Los items
          que no cumplan esta condición serán removidos.
        </Typography>
      </>
    );
  }, [classes.dialogText, classes.paymentPending]);

  const renderCurrentStep = () => {
    if (currentStep === 0) {
      return (
        <PaymentOptions
          currentStepIndex={currentStep}
          currentStep={steps[currentStep]}
          setDisabledNext={setDisabledNext}
          payments={payments}
          handleSetPayment={handleSetPayment}
          number={number}
          setNumber={setNumber}
          type={type}
          setType={setType}
          cardIndividualLoad={cardIndividualLoad}
          setCardIndividualLoad={setCardIndividualLoad}
          cardMultipleLoad={cardMultipleLoad}
          setCardMultipleLoad={setCardMultipleLoad}
          totalCurrentCards={totalCurrentCards}
          totalIndividualCards={totalIndividualCards}
          totalMultipleLoadCards={totalMultipleLoadCards}
          checkedMultiplePayments={checkedMultiplePayments}
          setCheckedMultiplePayments={setCheckedMultiplePayments}
          currentTabIndex={currentTabIndex}
          setCurrentTabIndex={setCurrentTabIndex}
          isMultiplePaymentsParam={isMultiplePaymentsParam}
          canPayPartially={canPayPartially}
          setMethodSelected={setMethodSelected}
          setPaymentValueOption={setPaymentValueOption}
        />
      );
    }

    if (currentStep === 1 && payments.length > 0) {
      return (
        <>
          <SecondStep
            currentStep={steps[currentStep]}
            setPayerInfo={setPayerInfo}
            payment={
              payments.length === 1 ? payments[0] : generatedGroupedCoupon
            }
            methodSelected={methodSelected}
            setMethodSelected={setMethodSelected}
            setDisabledNext={setDisabledNext}
            isCardStep={isCardStep}
            currentUser={currentUser}
            onDownloadCoupon={onDownloadCoupon}
            paymentValueOption={paymentValueOption}
            setPaymentValueOption={setPaymentValueOption}
            setPayment={handleSetPayment}
            setLoading={setLoading}
            isMultiplePayment={checkedMultiplePayments}
            paymentsCount={payments.length}
            isFinancing={
              payments.length && payments[0].type === TYPE_OF_PAYMENTS.financing
            }
          />
        </>
      );
    }
  };

  const isFinancingPayment =
    payments.length && payments[0].type === TYPE_OF_PAYMENTS.financing;
  const withGroupedCoupon = payments.length && payments[0].detail.couponId < 0;
  const disabledBackButton =
    currentStep === 1 && (isFinancingPayment || withGroupedCoupon);

  return (
    <Fragment>
      {loadingPDF && <ModalProgress message="Generando documento" />}
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.stepperContainer}>
          {openDialog && (
            <BaseDialog
              handleClose={() => setOpenDialog(false)}
              open={openDialog}
              title="Estimado cliente"
              actions={renderDialogActions}
              content={renderDialogContent}
              contentStyle={classes.dialogContent}
              contentSize="small"
            />
          )}
          <Stepper
            steps={steps}
            currentStep={currentStep}
            stepperBarClass={classes.stepperBar}
            stepperTextClass={classes.stepperText}
          />
        </Container>
        <div className={classes.currentStep}>{renderCurrentStep()}</div>
      </div>
      <FooterPayment
        id="Payment_FooterPayment_div"
        currentStep={steps[currentStep]}
        disabledNext={disabledNext || loading || loadingPDF}
        disabledBack={disabledBackButton}
      />
      {loading && <FullSizeProgress />}
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1
  },
  currentStep: {
    marginBottom: theme.custom.footerHeight.stepper
  },
  stepperContainer: {
    // * Mobile
    [theme.breakpoints.up('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.up('xm')]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5)
    },
    // * Web
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing()
    }
  },
  stepperBar: {
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginBottom: 4
    }
  },
  stepperText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      padding: 0
    }
  },
  dialogContent: {
    minHeight: 'unset'
  },
  dialogText: {
    fontSize: 14
  },
  paymentPending: {
    fontWeight: 600,
    color: theme.palette.warning.main
  }
}));

export default PaymentEFG;

// const dummyCreditBody = {
//   creditCard: {
//     ownerId: '12345',
//     ownerTel: '7563126',
//     ownerName: 'John Doe',
//     ownerEmail: 'payer_test@test.com',
//     ownerIdentificationNumber: '5415668464654',
//     paymentMethod: 'VISA',
//     number: '4111111111111111',
//     securityCode: '123',
//     expirationDate: '2022/12'
//   },
//   customer: {
//     id: '9992913912392',
//     fullName: 'APPROVED',
//     email: 'buyer_test@test.com',
//     contactPhone: '7563126',
//     identificationNumber: '5415668464654',
//     address: 'Cra 39 # 66 - 55',
//     city: 'Barranquilla'
//   },
//   product: {
//     couponId: 605051824
//   }
// };
