import moment from 'moment';
import { filterSchema } from '../static/schemas';

export const cleanFalsyValues = initialObject => {
  let cleanedObject = {};

  Object.keys(initialObject).forEach(key => {
    if (initialObject[key]) {
      cleanedObject = {
        ...cleanedObject,
        [key]: initialObject[key]
      };
    }
  });

  return cleanedObject;
};

export const validateFilters = unvalidatedFilterValues => {
  try {
    const existDates =
      Boolean(unvalidatedFilterValues.startDate) &&
      Boolean(unvalidatedFilterValues.finalDate);

    const validatedValues = filterSchema(existDates).validateSync(
      unvalidatedFilterValues
    );

    if (validatedValues.contract) {
      validatedValues.contract = validatedValues.contract.value;
    }

    if (validatedValues.startDate) {
      validatedValues.startDate = moment(
        validatedValues.startDate
      ).toISOString();
    }

    if (validatedValues.finalDate) {
      validatedValues.finalDate = moment(
        validatedValues.finalDate
      ).toISOString();
    }

    const requestFilterValues = cleanFalsyValues(validatedValues);

    return {
      success: true,
      validatedFilters: requestFilterValues
    };
  } catch (e) {
    return {
      success: false,
      message: e.message
    };
  }
};

export const strSearchParams2Object = urlSearchString => {
  const searchParams = new URLSearchParams(urlSearchString);
  const parsedParams = Object.fromEntries([...searchParams]);

  if (parsedParams.contract) {
    parsedParams.contract = {
      value: parsedParams.contract,
      label: ''
    };
  }

  return parsedParams;
};
