import React, { Fragment, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import { makeStyles } from '@material-ui/core/styles';

import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

import clsx from 'clsx';

const BaseTooltip = props => {
  const {
    tooltipText,
    text = '',
    onClick = null,
    className = '',
    textClass = '',
    iconClass = '',
    startWithBreakline = false,
    showTooltipOnClick = false
  } = props;
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      {startWithBreakline && <br />}
      <span
        className={clsx(className, classes.flexContainer)}
        onClick={onClick}
      >
        <span className={textClass}>{text}</span>
        <Tooltip
          open={open}
          title={tooltipText}
          onOpen={handleTooltipOpen}
          onClose={handleTooltipClose}
          onClick={showTooltipOnClick ? handleTooltipOpen : null}
          disableHoverListener={false}
        >
          <HelpOutlineOutlinedIcon
            className={clsx(iconClass, classes.tooltipIcon)}
          />
        </Tooltip>
      </span>
    </Fragment>
  );
};

const useStyles = makeStyles(() => ({
  tooltipIcon: {
    fontSize: '1.2em'
  },
  flexContainer: {
    display: 'inline-flex',
    alignItems: 'center'
  }
}));

export default BaseTooltip;
