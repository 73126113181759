import React, {
  Fragment,
  useContext,
  useEffect,
  useCallback,
  memo
} from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import SimpleDivider from '../../../../../Components/Dividers/SimpleDivider';
import Title from '../../../../../Components/Labels/Title';
import ProductAccordion from '../../../Components/Current/ProductAccordion';
import ContractSimpleCard from '../../../Cards/ContractSimpleCard';

import { UserContext } from '../../../../../Contexts/UserContext';
import {
  SetCurrentStepIndexContext,
  StepperDataContext,
  StepperDataDispatchContext,
  CurrentStepIndexContext
} from '../../../../../Contexts/StepperContext';

import { numberWithDots } from '../../../../../Utils/Format/MoneyFormat';
import { requestTypes } from '../../../../Requests/Utils/enums';
import {
  getPaymentPlanDetail,
  getFirstBillingValue
} from '../../../../../Utils/Financing/Financing';

import myDebtsBanner from '../../../../../Assets/images/my-debts-banner.png';
import { Company } from '../../../../../Configs/general';

const getInitialInstallmentValidation = ({ min, max }) => {
  return yup
    .number('Debe ser un número')
    .test('value', `Mínimo: ${numberWithDots(min)}`, val => {
      return val >= min;
    })
    .test('value', `Máximo: ${numberWithDots(max)}`, val => {
      return val <= max;
    })
    .required('Ingresa un valor');
};

const getInstallmentsValidation = ({ min, max }) => {
  return yup
    .number()
    .test('value', `Mínimo: ${min}`, val => {
      return val >= min;
    })
    .test('value', `Máximo: ${max}`, val => {
      return val <= max;
    })
    .required('Ingresa número de cuotas');
};

const PaymentPlanStep = props => {
  const {
    formId,
    setCanSubmit,
    setGoBack,
    setCurrentStep: setVisualStepperIndex,
    setNextButtonText,
    setPaymentPlan,
    paymentPlan,
    setFormErrors,
    financingPlan,
    punishedProductIds,
    setSidebarVisible,
    selectedContract
  } = props;

  const classes = useStyles();

  // * CONTEXTS
  const currentStepIndex = useContext(CurrentStepIndexContext);
  const setCurrentStep = useContext(SetCurrentStepIndexContext);
  const setData = useContext(StepperDataDispatchContext);
  const stepperData = useContext(StepperDataContext);

  const currentUser = useContext(UserContext);

  let schema;

  let validationShape = {};
  if (financingPlan.products.length) {
    let initialInstallmentsShape = {};
    let installmentsShape = {};

    financingPlan.products.forEach(p => {
      initialInstallmentsShape = {
        ...initialInstallmentsShape,
        [`p${p.productId}`]: getInitialInstallmentValidation({
          min: p.initialInstallmentValue,
          max: p.total - p.discountValue - 1
        })
      };
      installmentsShape = {
        ...installmentsShape,
        [`p${p.productId}`]: getInstallmentsValidation({
          min: p.minInstallments,
          max: p.maxInstallments
        })
      };
    });

    validationShape = {
      ...validationShape,
      numberOfInstallments: yup.object(installmentsShape),
      initialInstallments: yup.object(initialInstallmentsShape)
    };

    schema = yup.object(validationShape);
  }

  // * OTHER HOOKS

  const {
    register,
    getValues,
    watch,
    errors,
    setValue,
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm({
    validationSchema: schema,
    submitFocusError: true,
    mode: 'onChange',
    defaultValues: {
      ...stepperData
    }
  });

  const watchForm = watch(['initialInstallments', 'numberOfInstallments']);

  setFormErrors(errors);

  const handleBillingsNumber = (action, productId) => {
    const numberOfInstallments = watchForm.numberOfInstallments;
    const product = financingPlan.products.filter(
      currProduct => currProduct.productId === productId
    )[0];
    const currentValue = numberOfInstallments[`p${productId}`];

    switch (action) {
      case 'plus':
        if (currentValue < product.maxInstallments) {
          setValue('numberOfInstallments', {
            ...numberOfInstallments,
            [`p${productId}`]: currentValue + 1
          });
        }
        break;

      case 'minus':
        if (currentValue > product.minInstallments) {
          setValue('numberOfInstallments', {
            ...numberOfInstallments,
            [`p${productId}`]: currentValue - 1
          });
        }
        break;

      default:
        break;
    }
  };

  const goNext = values => {
    const deferredIdsCallback = d => d.deferredId;

    const products = financingPlan.products.map(product => {
      const deferredIds = product.deferreds.map(deferredIdsCallback);

      const financingProduct = {
        interestRate: product.monthlyInterestRate,
        billings: watchForm.numberOfInstallments[`p${product.productId}`],
        totalFinancingValue:
          product.financingValue -
            (watchForm.initialInstallments || {})[`p${product.productId}`] || 0
      };

      const {
        totalCapital,
        totalInterest,
        totalPayment
      } = getPaymentPlanDetail([financingProduct]);
      const monthlyInstallment = getFirstBillingValue([financingProduct]);

      return {
        paymentPlanDetail: {
          totalCapital,
          totalInterest,
          totalPayment,
          monthlyInstallment
        },
        productId: product.productId,
        productType: product.productTypeDescription,
        initialInstallmentValue:
          watchForm.initialInstallments[`p${product.productId}`],
        installments: watchForm.numberOfInstallments[`p${product.productId}`],
        interestRate: product.monthlyInterestRate,
        financingPlanId: product.financingPlanId,
        discountValue: product.discountValue,
        financeValue: product.totalConcepts + product.totalDeferreds,
        deferredIds
      };
    });

    setData(data => {
      return {
        ...data,
        ...values,
        usuryRate: financingPlan.usuryRate,
        initialInstallmentValue: Object.values(
          watchForm.initialInstallments
        ).reduce((sum, current) => sum + current, 0),
        totalFinanceValue: financingPlan.products.reduce((sum, current) => {
          return sum + current.totalConcepts + current.totalDeferreds;
        }, 0),
        totalDiscountValue: financingPlan.products.reduce((sum, current) => {
          return sum + current.discountValue;
        }, 0),
        products,
        requestType: requestTypes.debtNegotiation
      };
    });
    setCurrentStep(step => step + 1);
  };

  const onBackward = useCallback(() => {
    setData(data => ({ ...data, ...getValues() }));
    setCurrentStep(currentStep => currentStep - 1);
  }, [getValues, setCurrentStep, setData]);

  // props setters
  useEffect(() => {
    setSidebarVisible(true);
    setNextButtonText('Siguiente');
    setVisualStepperIndex(currentStepIndex);
    setGoBack({
      action: onBackward
    });
  }, [
    setGoBack,
    onBackward,
    setVisualStepperIndex,
    currentStepIndex,
    setNextButtonText,
    setSidebarVisible
  ]);

  useEffect(() => {
    setData(d => ({ ...d, financingPlan }));
  }, [setData, financingPlan]);

  useEffect(() => {
    setCanSubmit(isValid);
  }, [isValid, setCanSubmit]);

  useEffect(() => {
    if (!paymentPlan.initialInstallments) {
      setPaymentPlan(payPlan => ({
        ...payPlan,
        initialInstallments: watchForm.initialInstallments
      }));
    } else if (watchForm && watchForm.initialInstallments) {
      const planInitInstalls = Object.values(paymentPlan.initialInstallments);
      const watchInitInstalls = Object.values(watchForm.initialInstallments);
      const hasPlanChanged = planInitInstalls.some(
        (planInitInstall, index) => planInitInstall !== watchInitInstalls[index]
      );

      if (hasPlanChanged) {
        setPaymentPlan(payPlan => ({
          ...payPlan,
          initialInstallments: watchForm.initialInstallments
        }));
      }
    }
  }, [watchForm.initialInstallments, setPaymentPlan, paymentPlan, watchForm]);

  useEffect(() => {
    if (!paymentPlan.numberOfInstallments) {
      setPaymentPlan(payPlan => ({
        ...payPlan,
        numberOfInstallments: watchForm.numberOfInstallments
      }));
    } else if (watchForm && watchForm.numberOfInstallments) {
      const planInstallments = Object.values(paymentPlan.numberOfInstallments);
      const watchInstallments = Object.values(watchForm.numberOfInstallments);
      const hasPlanChanged = planInstallments.some(
        (planInstall, index) => planInstall !== watchInstallments[index]
      );

      if (hasPlanChanged) {
        setPaymentPlan(payPlan => ({
          ...payPlan,
          numberOfInstallments: watchForm.numberOfInstallments
        }));
      }
    }
  }, [watchForm.numberOfInstallments, setPaymentPlan, paymentPlan, watchForm]);

  const renderProducts = () => {
    return (
      financingPlan.products.length > 0 && (
        <div className={classes.productsContainer}>
          {financingPlan.products.map((p, idx) => {
            const isPunished = punishedProductIds.includes(p.productId);
            return (
              <ProductAccordion
                key={idx}
                typeDescription={p.productTypeDescription}
                id={p.productId}
                minimunInstallment={p.initialInstallmentValue}
                monthlyInterestRate={p.monthlyInterestRate}
                concepts={p.concepts}
                deferreds={p.deferreds}
                totalConcepts={p.totalConcepts}
                totalDeferreds={p.totalDeferreds}
                total={p.total}
                control={control}
                errors={errors}
                inputName={`initialInstallments.p${p.productId}`}
                punished={isPunished}
                initialInstallmentInputLabel="Valor cuota inicial"
                register={register}
                handleBillingsNumber={handleBillingsNumber}
              />
            );
          })}
        </div>
      )
    );
  };

  if (!currentUser) {
    return <Fragment></Fragment>;
  }

  return (
    <div className={classes.container}>
      <Title title="Financiación de la deuda" />
      <ContractSimpleCard
        banner={myDebtsBanner}
        contract={selectedContract}
        title={`Información ${Company.contractConjugation.regular.singular.contraction}`}
      />
      <form id={formId} onSubmit={handleSubmit(goNext)}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className={classes.subtitleContainer}
        >
          <Grid item>
            <Typography className={classes.subtitle}>
              Saldos a financiar
            </Typography>
            <Typography className={classes.helperText}>
              Ingresa el número de cuotas para financiar la deuda
            </Typography>
          </Grid>
        </Grid>
        {renderProducts()}
        <SimpleDivider className={classes.divider} />
        <div className={classes.totalWrapper}>
          <Typography className={classes.label}>Saldo total</Typography>
          <Typography className={classes.total}>
            {numberWithDots(
              financingPlan.products.reduce((sum, current) => {
                return sum + current.totalConcepts + current.totalDeferreds;
              }, 0)
            )}
          </Typography>
        </div>
      </form>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(5)
  },
  productsContainer: {
    '& > *': {
      marginBottom: theme.spacing(3)
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3)
    }
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 600
  },
  subtitleContainer: {
    margin: theme.spacing(3, 0)
  },
  helperText: {
    fontSize: 12,
    color: theme.palette.text.greyDark,
    fontWeight: 600,
    margin: 0,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      margin: theme.spacing(1, 0)
    }
  },
  divider: {
    marginBottom: theme.spacing(2)
  },
  totalWrapper: {
    marginRight: theme.spacing(6.25),
    textAlign: 'right',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginRight: theme.spacing(2)
    }
  },
  label: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.greyDark,
    lineHeight: 1.2
  },
  total: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 1
  }
}));

export default memo(PaymentPlanStep);
