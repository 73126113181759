import React from 'react';

const NoRequestsIcon = props => {
  return (
    <svg
      width={props.width || 300}
      height={props.height || 199}
      viewBox="0 0 300 199"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M262.513 97.3848H232.686C230.238 82.2931 223.866 68.1099 214.209 56.2571C204.551 44.4043 191.948 35.2983 177.662 29.8518C163.376 24.4053 147.909 22.8098 132.812 25.2251C117.715 27.6404 103.518 33.9817 91.6447 43.6133H61.5437C58.0309 43.6196 54.6637 45.0178 52.1798 47.5018C49.6958 49.9857 48.2976 53.3529 48.2914 56.8657V56.8657C48.3048 59.9944 49.4178 63.0188 51.4356 65.4098C53.4535 67.8009 56.2477 69.4064 59.3296 69.9456H80.7829C84.6162 69.9547 88.2898 71.4816 91.0003 74.1922C93.7107 76.9028 95.2374 80.5765 95.2464 84.4098V86.0361C95.2372 89.8692 93.7104 93.5428 91 96.2532C88.2895 98.9636 84.616 100.49 80.7829 100.5H37.8756C34.3627 100.506 30.9956 101.904 28.5116 104.388C26.0277 106.872 24.6294 110.239 24.6232 113.752V113.752C24.6294 117.265 26.0277 120.632 28.5116 123.116C30.9956 125.6 34.3627 126.998 37.8756 127.004H60.7479C63.4453 141.785 69.9152 155.618 79.5307 167.163C89.1462 178.707 101.581 187.572 115.63 192.898C129.679 198.224 144.866 199.83 159.718 197.56C174.57 195.291 188.584 189.223 200.402 179.945C201.539 180.266 202.715 180.429 203.896 180.43H237.668C241.181 180.423 244.548 179.025 247.032 176.541C249.516 174.057 250.914 170.69 250.92 167.178C250.914 163.665 249.515 160.298 247.031 157.814C244.548 155.33 241.18 153.931 237.668 153.925H217.737C213.987 153.915 210.394 152.42 207.742 149.769C205.09 147.117 203.596 143.523 203.585 139.773V137.905C203.596 134.155 205.09 130.561 207.742 127.909C210.394 125.257 213.987 123.763 217.737 123.753H249.744C250.511 123.75 251.276 123.819 252.029 123.96H262.444C265.957 123.953 269.324 122.555 271.808 120.071C274.292 117.587 275.691 114.22 275.697 110.707V110.707C275.712 108.965 275.382 107.238 274.726 105.624C274.071 104.01 273.102 102.541 271.877 101.303C270.652 100.065 269.193 99.081 267.586 98.4085C265.979 97.7361 264.255 97.3882 262.513 97.3848Z"
          fill="#EEF9FB"
        />
        <path
          d="M26.1597 70.1181H12.0066C8.82103 70.1027 5.77078 68.8281 3.52143 66.5722C1.27208 64.3164 0.00620432 61.2625 0 58.0768V55.5853C0.012862 52.4049 1.28197 49.3585 3.53088 47.1096C5.77978 44.8607 8.82625 43.5916 12.0066 43.5787H26.125C29.307 43.5864 32.3564 44.8538 34.6065 47.1039C36.8565 49.3539 38.1239 52.4033 38.1316 55.5853V58.0768C38.1392 61.2607 36.8825 64.3173 34.6376 66.5751C32.3928 68.8329 29.3435 70.1073 26.1597 70.1181V70.1181Z"
          fill="#EEF9FB"
        />
        <path
          d="M287.993 180.43H273.875C270.693 180.422 267.643 179.155 265.393 176.904C263.143 174.654 261.876 171.605 261.868 168.422V165.931C261.876 162.749 263.144 159.7 265.394 157.45C267.644 155.199 270.693 153.932 273.875 153.924H287.993C291.175 153.932 294.224 155.199 296.474 157.45C298.724 159.7 299.992 162.749 299.999 165.931V168.422C299.987 171.603 298.718 174.649 296.469 176.898C294.22 179.148 291.174 180.417 287.993 180.43V180.43Z"
          fill="#EEF9FB"
        />
        <path
          d="M75.6873 13.2556C78.6364 13.2556 81.0272 10.8649 81.0272 7.91576C81.0272 4.96663 78.6364 2.57588 75.6873 2.57588C72.7382 2.57588 70.3474 4.96663 70.3474 7.91576C70.3474 10.8649 72.7382 13.2556 75.6873 13.2556Z"
          stroke="#63D8F1"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M240.131 90.1287C243.081 90.1287 245.471 87.7379 245.471 84.7888C245.471 81.8397 243.081 79.4489 240.131 79.4489C237.182 79.4489 234.792 81.8397 234.792 84.7888C234.792 87.7379 237.182 90.1287 240.131 90.1287Z"
          stroke="#63D8F1"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M79.2874 89.6843H71.0337V81.4314C71.0337 80.9593 70.8462 80.5066 70.5124 80.1727C70.1786 79.8389 69.7258 79.6514 69.2538 79.6514C68.7817 79.6514 68.3289 79.8389 67.9951 80.1727C67.6613 80.5066 67.4738 80.9593 67.4738 81.4314V89.6843H59.1823C58.7102 89.6843 58.2575 89.8718 57.9237 90.2056C57.5899 90.5394 57.4023 90.9922 57.4023 91.4642C57.4023 91.9363 57.5899 92.389 57.9237 92.7229C58.2575 93.0567 58.7102 93.2442 59.1823 93.2442H67.4352V101.497C67.4352 101.969 67.6227 102.422 67.9565 102.756C68.2903 103.09 68.7431 103.277 69.2152 103.277C69.6872 103.277 70.14 103.09 70.4738 102.756C70.8076 102.422 70.9951 101.969 70.9951 101.497V93.2442H79.248C79.7188 93.2401 80.1692 93.0512 80.5021 92.7183C80.835 92.3854 81.0239 91.935 81.028 91.4642C81.0342 91.2321 80.9937 91.0011 80.909 90.7849C80.8242 90.5687 80.6969 90.3718 80.5345 90.2058C80.3722 90.0397 80.1781 89.908 79.9639 89.8184C79.7497 89.7289 79.5196 89.6832 79.2874 89.6843V89.6843Z"
          fill="#63D8F1"
        />
        <path
          d="M277.834 111.61L273.304 107.079L277.834 102.548C277.968 102.422 278.075 102.27 278.148 102.102C278.222 101.934 278.262 101.753 278.264 101.569C278.267 101.386 278.233 101.204 278.163 101.033C278.094 100.863 277.992 100.709 277.862 100.579C277.732 100.449 277.578 100.347 277.408 100.278C277.237 100.209 277.055 100.174 276.872 100.177C276.688 100.179 276.507 100.219 276.339 100.293C276.171 100.366 276.019 100.473 275.893 100.607L271.362 105.137L266.832 100.607C266.705 100.473 266.554 100.366 266.386 100.293C266.218 100.219 266.036 100.179 265.853 100.177C265.669 100.174 265.487 100.209 265.317 100.278C265.147 100.347 264.992 100.449 264.862 100.579C264.733 100.709 264.63 100.863 264.561 101.033C264.492 101.204 264.458 101.386 264.46 101.569C264.463 101.753 264.502 101.934 264.576 102.102C264.65 102.27 264.757 102.422 264.89 102.548L269.421 107.079L264.89 111.61C264.644 111.869 264.51 112.215 264.515 112.572C264.52 112.93 264.664 113.271 264.917 113.524C265.17 113.777 265.511 113.921 265.869 113.926C266.226 113.931 266.572 113.797 266.832 113.551L271.362 109.02L275.893 113.551C276.153 113.804 276.501 113.945 276.864 113.945C277.226 113.945 277.574 113.804 277.834 113.551C277.968 113.427 278.075 113.277 278.148 113.11C278.221 112.943 278.259 112.763 278.259 112.58C278.259 112.398 278.221 112.218 278.148 112.051C278.075 111.883 277.968 111.733 277.834 111.61V111.61Z"
          fill="#63D8F1"
        />
        <path
          d="M95.0124 156.138H198.794C201.387 156.138 201.387 152.115 198.794 152.115H95.0124C92.42 152.115 92.42 156.138 95.0124 156.138Z"
          fill="#63D8F1"
        />
        <path
          d="M212.204 156.138H219.579C222.171 156.138 222.171 152.115 219.579 152.115H212.204C209.612 152.115 209.612 156.138 212.204 156.138Z"
          fill="#63D8F1"
        />
        <path
          d="M59.3462 156.138H82.4982C85.0906 156.138 85.0906 152.115 82.4982 152.115H59.3462C56.7538 152.115 56.7538 156.138 59.3462 156.138Z"
          fill="#63D8F1"
        />
        <path
          d="M160.89 135.089H125.715C123.85 135.088 122.062 134.346 120.743 133.027C119.424 131.709 118.681 129.921 118.679 128.055V82.3265C118.681 80.461 119.423 78.6725 120.742 77.3534C122.061 76.0343 123.85 75.2923 125.715 75.2902H160.89C162.756 75.2925 164.544 76.0346 165.863 77.3537C167.182 78.6728 167.923 80.4612 167.925 82.3265V107.058C167.342 106.985 166.754 106.949 166.167 106.949V82.3265C166.167 81.6332 166.03 80.9467 165.765 80.3062C165.499 79.6657 165.111 79.0837 164.62 78.5935C164.13 78.1033 163.548 77.7144 162.908 77.4491C162.267 77.1838 161.581 77.0473 160.887 77.0473H125.715C124.315 77.0473 122.972 77.6035 121.982 78.5935C120.992 79.5836 120.436 80.9264 120.436 82.3265V128.055C120.436 129.456 120.992 130.798 121.982 131.788C122.972 132.778 124.315 133.335 125.715 133.335H159.349C160.513 133.982 161.764 134.46 163.064 134.753C162.361 134.979 161.628 135.092 160.89 135.089V135.089Z"
          fill="#104EB2"
        />
        <path
          d="M150.338 122.656C150.338 119.526 151.266 116.466 153.006 113.863C154.745 111.26 157.217 109.231 160.11 108.033C163.002 106.835 166.185 106.522 169.255 107.133C172.326 107.743 175.146 109.251 177.36 111.465C179.573 113.679 181.081 116.499 181.691 119.569C182.302 122.64 181.989 125.823 180.791 128.715C179.592 131.607 177.564 134.079 174.961 135.819C172.358 137.558 169.297 138.486 166.167 138.486C161.969 138.486 157.943 136.818 154.974 133.849C152.006 130.881 150.338 126.855 150.338 122.656V122.656ZM152.096 122.656C152.095 124.726 152.552 126.77 153.433 128.642C154.314 130.515 155.598 132.17 157.193 133.488C157.192 133.482 157.192 133.476 157.193 133.469C157.977 129.136 162.202 126.834 166.367 126.834C170.441 126.834 174.574 129.033 175.486 133.188C177.246 131.631 178.588 129.658 179.389 127.448C180.19 125.239 180.425 122.864 180.073 120.541C179.72 118.218 178.791 116.019 177.371 114.147C175.95 112.275 174.083 110.789 171.94 109.824C169.797 108.859 167.447 108.447 165.103 108.624C162.76 108.801 160.498 109.562 158.525 110.838C156.552 112.115 154.929 113.865 153.806 115.929C152.683 117.994 152.095 120.306 152.096 122.656V122.656ZM161.533 118.908C161.456 118.234 161.524 117.55 161.734 116.905C161.943 116.259 162.289 115.665 162.748 115.165C163.207 114.664 163.768 114.268 164.393 114.003C165.018 113.738 165.693 113.611 166.371 113.629C167.049 113.615 167.721 113.746 168.344 114.012C168.967 114.279 169.526 114.675 169.984 115.175C170.441 115.674 170.787 116.266 170.998 116.91C171.209 117.553 171.28 118.235 171.207 118.908C170.992 121.814 168.79 124.187 166.371 124.187C163.952 124.187 161.746 121.815 161.533 118.908V118.908Z"
          fill="#104EB2"
        />
        <path
          d="M136.752 98.7763C136.847 97.9829 137.098 97.2161 137.49 96.5198C137.882 95.8236 138.408 95.2117 139.037 94.7192C140.254 93.7754 141.759 93.2803 143.299 93.3175C144.36 93.2707 145.418 93.4527 146.403 93.8509C147.57 94.423 149.85 95.8145 149.85 98.7763C149.85 101.893 147.859 103.305 145.595 104.862C143.331 106.418 142.753 107.945 142.753 109.694"
          stroke="#104EB2"
          strokeWidth="3"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M142.757 117.063C143.963 117.063 144.94 116.085 144.94 114.879C144.94 113.674 143.963 112.696 142.757 112.696C141.551 112.696 140.573 113.674 140.573 114.879C140.573 116.085 141.551 117.063 142.757 117.063Z"
          fill="#104EB2"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="300" height="198.561" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NoRequestsIcon;
