import React from 'react';
import { portal, Portal } from '../../../../Configs/general';

import ContractStepGDC from './ContractStep.GDC';
import ContractStepGDG from './ContractStep.GDG';

const ContractStep = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <ContractStepGDC {...props} />;
    case Portal.Gasguajira:
      return <ContractStepGDG {...props} />;
    case Portal.Efigas:
      return <ContractStepGDC {...props} />;
    default:
      return <></>;
  }
};

export default ContractStep;
