import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import TextInput from '../../../Components/Inputs/TextInput';
import Title from '../../../Components/Labels/Title';

const CardInfoForm = props => {
  const { loading = false } = props;

  // * STATE HOOKS;

  // * OTHER HOOKS
  const classes = useStyles();

  // * FUNCTIONS

  return (
    <Container maxWidth="sm" className={classes.root}>
      <form>
        <Title text={'Información de tarjeta'} />
        <Grid container spacing={2} className={classes.formContainer}>
          <Grid item xs={12} className={classes.inputContainer}>
            <TextInput
              value={'4111 1111 1111 1111'}
              onChange={() => null}
              disabled={loading}
              label={'Número de tarjeta'}
              fullWidth
            />
          </Grid>
          <Grid item sm={5} xs={12} className={classes.inputContainer}>
            <TextInput
              value={'Juan Roca'}
              onChange={() => null}
              disabled={loading}
              label={'Nombre del titular'}
              fullWidth
            />
          </Grid>
          <Grid item sm={3} xs={12} className={classes.inputContainer}>
            <TextInput
              value={'12/20'}
              onChange={() => null}
              disabled={loading}
              label={'Vencimiento'}
              fullWidth
            />
          </Grid>
          <Grid item sm={2} xs={12} className={classes.inputContainer}>
            <TextInput
              value={'320'}
              onChange={() => null}
              disabled={loading}
              label={'CVV'}
              fullWidth
            />
          </Grid>
          <Grid item sm={2} xs={12} className={classes.inputContainer}>
            <TextInput
              value={'1'}
              onChange={() => null}
              disabled={loading}
              label={'Cuotas'}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} className={classes.inputContainer}>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                />
              }
              label="Acepto términos y condiciones"
            />
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  inputContainer: {
    [`@media (max-width:${theme.breakpoints.values.sm - 1}px)`]: {
      marginTop: theme.spacing(),
      paddingTop: '0 !important',
      paddingBottom: '0 !important'
    }
  },
  formContainer: {
    marginBottom: theme.spacing(4)
  }
}));

export default CardInfoForm;
