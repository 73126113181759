import React from 'react';

const BrillaVisitIcon = ({ width = 35, height = 35, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 56 56"
      {...rest}
    >
      <rect width="56" height="56" fill="none" />
      <path
        d="M28.615,49.748H5.853A5.859,5.859,0,0,1,0,43.895V5.854A5.859,5.859,0,0,1,5.853,0H35.116a5.859,5.859,0,0,1,5.852,5.853V27.483a12.884,12.884,0,0,0-1.463-.4V5.854a4.395,4.395,0,0,0-4.389-4.39H5.853a4.395,4.395,0,0,0-4.389,4.39V43.895a4.394,4.394,0,0,0,4.389,4.389H27.1a13.049,13.049,0,0,0,1.514,1.462Z"
        transform="translate(6.102 2.618)"
        fill="#333"
      />
      <path
        d="M13.305.963H.052A.661.661,0,0,1-.5.232.661.661,0,0,1,.052-.5H13.305a.661.661,0,0,1,.552.732A.661.661,0,0,1,13.305.963Z"
        transform="translate(15.382 31.649)"
        fill="#333"
      />
      <path
        d="M8.887.963H.052A.661.661,0,0,1-.5.232.661.661,0,0,1,.052-.5H8.887A.661.661,0,0,1,9.44.232.661.661,0,0,1,8.887.963Z"
        transform="translate(15.382 38.965)"
        fill="#333"
      />
      <path
        d="M21.32,154.891c0-2.247.009-4.495-.008-6.742,0-.417.093-.582.549-.576,1.84.024,3.68.006,5.52.011a6.056,6.056,0,0,1,3.207.794,3.662,3.662,0,0,1,1.373,4.641,2.872,2.872,0,0,1-1.3,1.338c-.1.055-.245.086-.25.231s.147.183.249.225c2.388.978,2.484,3.922,1.487,5.459a4.335,4.335,0,0,1-3.056,1.817,8.5,8.5,0,0,1-1.577.115q-2.783,0-5.566,0c-.63,0-.63,0-.63-.613Q21.319,158.24,21.32,154.891Zm5.071,4.391v0c.468,0,.936.015,1.4,0a1.481,1.481,0,0,0,1.526-1.16,1.5,1.5,0,0,0-1.356-1.908,25.987,25.987,0,0,0-3.028-.076c-.236-.007-.266.129-.266.318,0,.845.008,1.689,0,2.534,0,.249.1.306.323.3C25.456,159.275,25.923,159.283,26.391,159.283Zm-.116-8.772c-.483,0-.89.006-1.3,0-.2,0-.311.051-.309.275.006.724,0,1.448,0,2.171,0,.15.026.271.219.264a18.067,18.067,0,0,0,2.932-.088,1.217,1.217,0,0,0,1.086-1.293,1.263,1.263,0,0,0-1.131-1.264A9.524,9.524,0,0,0,26.275,150.51Z"
        transform="translate(-6.43 -137.032)"
        fill="#333"
      />
      <path
        d="M13.362,1A12.362,12.362,0,0,0,4.621,22.1,12.362,12.362,0,0,0,22.1,4.621,12.281,12.281,0,0,0,13.362,1m0-1A13.362,13.362,0,1,1,0,13.362,13.362,13.362,0,0,1,13.362,0Z"
        transform="translate(29.277 28.855)"
        fill="#333"
      />
      <g transform="translate(36.277 35.059)">
        <path
          d="M1.567-.5A2.07,2.07,0,0,1,3.635,1.567V3.135a2.067,2.067,0,0,1-4.135,0V1.567A2.07,2.07,0,0,1,1.567-.5Zm0,4.7A1.069,1.069,0,0,0,2.635,3.135V1.567a1.067,1.067,0,0,0-2.135,0V3.135A1.069,1.069,0,0,0,1.567,4.2Z"
          transform="translate(0 7.053)"
          fill="#333"
        />
        <path
          d="M1.567-.5A2.07,2.07,0,0,1,3.635,1.567V3.135a2.067,2.067,0,0,1-4.135,0V1.567A2.07,2.07,0,0,1,1.567-.5Zm0,4.7A1.069,1.069,0,0,0,2.635,3.135V1.567a1.067,1.067,0,0,0-2.135,0V3.135A1.069,1.069,0,0,0,1.567,4.2Z"
          transform="translate(9.405 7.053)"
          fill="#333"
        />
        <path
          d="M16.539,13.121a.5.5,0,0,1-.5-.5V10.27a5.77,5.77,0,1,0-11.539,0v2.351a.5.5,0,0,1-1,0V10.27a6.77,6.77,0,1,1,13.539,0v2.351A.5.5,0,0,1,16.539,13.121Z"
          transform="translate(-4 -4)"
          fill="#333"
        />
        <path
          d="M12,21.851a.5.5,0,1,1,0-1c2.476,0,4.2-.976,4.2-1.851a.5.5,0,0,1,1,0,2.51,2.51,0,0,1-1.654,2.11A8.118,8.118,0,0,1,12,21.851Z"
          transform="translate(-5.73 -7.244)"
          fill="#333"
        />
      </g>
    </svg>
  );
};

export default BrillaVisitIcon;
