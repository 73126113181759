import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import TransactionLabel from '../../Labels/TransactionLabel';
import {
  floatWithDots,
  numberWithDots
} from '../../../Utils/Format/MoneyFormat';
import { Company } from '../../../Configs/general';
import { Divider, Typography } from '@material-ui/core';
import RibbonIcon from '../../CustomIcons/RibbonIcon';
import InformationCircle from '../../CustomIcons/InformationCircle';
import { InsurancePlans } from '../../../Enums/insurances';

const TransactionCardGDC = props => {
  const classes = useStyles();
  const { transaction } = props;

  const companyName = `${Company.name} S.A. E.S.P.`;
  const companyNIT = Company.tributaryIdentifierNumber;

  return (
    <div className={clsx(classes.root)}>
      <div className={clsx(classes.content, classes.header)}>
        <TransactionLabel
          title={'Empresa'}
          description={companyName}
          classes={{
            title: classes.invoiceDetailTitle,
            description: classes.invoiceDetailDescription
          }}
        />
        <TransactionLabel
          title={'NIT'}
          description={companyNIT}
          classes={{
            root: classes.invoiceNit,
            title: classes.invoiceDetailTitle,
            description: classes.invoiceDetailDescription
          }}
        />
        <TransactionLabel
          title={'Total'}
          description={floatWithDots(transaction.value)}
          classes={{
            root: classes.invoicePrice,
            title: classes.invoiceDetailTitle,
            description: classes.invoiceDetailDescription
          }}
        />
      </div>
      <div className={clsx(classes.content, classes.body)}>
        <TransactionLabel
          title={'Descripción'}
          description={transaction.description}
          classes={{
            root: classes.labelRoot
          }}
        />
        <TransactionLabel
          title={'Banco'}
          description={transaction.bankName}
          classes={{
            root: classes.labelRoot
          }}
        />
        {transaction.donationValue > 0 && (
          <TransactionLabel
            title={'Valor de donación'}
            description={floatWithDots(transaction.donationValue)}
            classes={{
              root: classes.labelRoot
            }}
          />
        )}
        <TransactionLabel
          title={'Moneda'}
          description={'Peso colombiano COP'}
          classes={{
            root: classes.labelRoot
          }}
        />
        <TransactionLabel
          title={'Fecha  de la transacción'}
          description={moment(transaction.date).format('DD/MM/YYYY HH:mm')}
          classes={{
            root: classes.labelRoot
          }}
        />
        <TransactionLabel
          title={'Número de la transacción'}
          description={transaction.transactionNumber || '-'}
          classes={{
            root: classes.labelRoot
          }}
        />
        <TransactionLabel
          title={'Referencia transacción'}
          description={transaction.reference || '-'}
          classes={{
            root: classes.labelRoot
          }}
        />
        <TransactionLabel
          title={'Referencia pedido'}
          description={transaction.orderId || '-'}
          classes={{
            root: classes.labelRoot
          }}
        />
        <TransactionLabel
          title={'IP origen de la transacción'}
          description={transaction.ip || '-'}
          classes={{
            root: classes.labelRoot
          }}
        />
      </div>
      {transaction.insuranceData && (
        <>
          <Divider variant="middle"></Divider>
          <div className={clsx(classes.content, classes.insuranceContent)}>
            <div className={clsx(classes.insuranceLogoSection)}>
              <Typography className={classes.sectionTitle}>
                Plan de seguro seleccionado
              </Typography>
              <div className={classes.insuranceLogo}>
                <RibbonIcon></RibbonIcon>
                <Typography className={classes.insuranceLabel}>
                  {InsurancePlans[transaction.insuranceData.plan].name}
                </Typography>
              </div>
            </div>
            <div className={clsx(classes.valueSection)}>
              <Typography className={classes.insuranceLabel}>
                Valor total (COP)
              </Typography>
              <Typography className={classes.valueLabel}>
                {numberWithDots(transaction.insuranceData.value)}/ Mensual
              </Typography>
              <div className={clsx(classes.valueInfo)}>
                <Typography className={classes.insuranceLabel}>
                  En caso de ser aprobado, este valor se incluirá en tu factura
                  a partir del siguiente mes
                </Typography>
                <InformationCircle />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    border: 'solid 1px #dbdbdb',
    marginBottom: theme.spacing(3)
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      padding: [[theme.spacing(4), theme.spacing(3)]]
    }
  },
  header: {
    height: '100%',
    backgroundColor: '#f0f6ff',
    alignContent: 'space-between',
    paddingBottom: theme.spacing(2)
  },
  body: {
    height: '100%',
    alignContent: 'space-between'
  },
  invoiceNit: {
    width: '50%',
    [theme.breakpoints.up('sm')]: {
      width: '33.333%'
    }
  },
  invoicePrice: {
    textAlign: 'right',
    width: '50%',
    [theme.breakpoints.up('sm')]: {
      width: '33.333%',
      textAlign: 'left'
    }
  },
  invoiceDetailTitle: {
    fontSize: 11,
    [theme.breakpoints.up('xm')]: {
      fontSize: 12
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 11
    }
  },
  invoiceDetailDescription: {
    fontSize: 18,
    [theme.breakpoints.up('xm')]: {
      fontSize: 20
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 18
    }
  },
  divider: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  labelRoot: {
    marginBottom: '15px'
  },
  insuranceLabel: {
    fontSize: 11,
    color: theme.palette.text.greyDark
  },
  insuranceContent: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      alignSelf: 'flex-start',
      paddingBottom: theme.spacing(3)
    }
  },
  insuranceLogoSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      gap: '8px'
    }
  },
  insuranceLogo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.disabled,
    borderRadius: '4px',
    padding: theme.spacing(1)
  },
  valueSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '8px',
    justifyContent: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      textAlign: 'right'
    }
  },
  valueLabel: {
    fontSize: 16,
    fontWeight: 600,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 14
    }
  },
  valueInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  sectionTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.typography.color.default,
    [theme.breakpoints.up('sm')]: {
      fontSize: 11
    }
  }
}));

export default TransactionCardGDC;
