import React from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';
import HelpOutline from '@material-ui/icons/HelpOutline';

const QuestionMark = props => {
  const { onClick } = props;
  return (
    <InputAdornment position="end">
      <IconButton aria-label="help" onClick={onClick}>
        <HelpOutline fontSize="small" />
      </IconButton>
    </InputAdornment>
  );
};

export default QuestionMark;
