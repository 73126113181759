import React, { Fragment, useCallback, useContext, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid, Grow, useMediaQuery } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import NumberInput from '../../../../Components/Inputs/NumberInput';
import FlagColombia from '../../../../Components/CustomIcons/Flags/FlagColombia';
import BaseButton from '../../../../Components/Buttons/BaseButton';
import { submitExternalForm } from '../../../../Utils/Misc/Form';
import SweetAlert from '../../../../Components/Alerts/SweetAlert';
import PhoneValidation from '../../../Profile/PhoneValidation';
import BaseDialog from '../../../../Components/Dialogs/BaseDialog';
import { SlideUpTransition } from '../../../../Components/Transitions/Transitions';

import { AlertsDispatchContext } from '../../../../Contexts/AlertsContext';
import { UpdateContract } from '../../../../API/Contracts/ContractsAPI';

import * as yup from 'yup';
import _get from 'lodash/get';
import { ContractsRefreshContext } from '../../../../Contexts/ContractsContext';

const clientInfoSchema = yup.object({
  phone: yup
    .string()
    .trim()
    .required('*Requerido')
    .length(10, 'Debe tener 10 dígitos'),
  phoneCountryCode: yup
    .string()
    .trim()
    .required('*Requerido')
});

const CellphoneStep = props => {
  const {
    onClose,
    open,
    title,
    step,
    setNextModal: setStepData,
    currentUser
  } = props;

  const classes = useStyles();

  const setAlert = useContext(AlertsDispatchContext);
  const refreshContracts = useContext(ContractsRefreshContext);

  const [phoneUpdate, setPhoneUpdate] = useState(false);

  const { contractId, formId } = step.data;

  const authToken = _get(currentUser, 'token');

  const { handleSubmit, errors, control, watch } = useForm({
    defaultValues: {
      phone: currentUser.phone || '',
      phoneCountryCode: currentUser.phoneCountryCode || '57'
    },
    validationSchema: clientInfoSchema
  });

  const phone = watch('phone', currentUser.phone ? currentUser.phone : '');
  const phoneCountryCode = watch(
    'phoneCountryCode',
    currentUser.phoneCountryCode ? currentUser.phoneCountryCode : ''
  );

  const isMobileSize = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  const submitHandler = useCallback(
    async formData => {
      setStepData(previousValue => ({
        ...previousValue,
        data: {
          ...previousValue.data,
          phoneData: { ...formData, phoneValidated: false }
        }
      }));

      setPhoneUpdate({ submit: true });
    },
    [setStepData]
  );

  const onSuccessPhoneValidation = useCallback(async () => {
    setStepData(previousValue => ({
      ...previousValue,
      data: {
        ...previousValue.data,
        phoneData: { ...previousValue.data.phoneData, phoneValidated: true }
      }
    }));

    const editedAssociation = {
      digitalInvoice: false,
      sendInvoice: false,
      sendEmailInvoice: false,
      sendWhatsAppInvoice: false,
      sendSmsInvoice: false,
      origin: {
        url: window.location.pathname
      }
    };

    return UpdateContract(authToken, contractId, editedAssociation);
  }, [setStepData, authToken, contractId]);

  const onSuccessDigitalInvoiceDeactivation = useCallback(() => {
    onClose();
    refreshContracts();
    setAlert({
      type: 'success',
      message: 'La factura digital fue desactivada exitosamente'
    });
  }, [setAlert, onClose, refreshContracts]);

  const renderActions = useCallback(() => {
    return (
      <Fragment>
        <BaseButton
          onClick={onClose}
          variant="outlined"
          color="primary"
          size="small"
        >
          Cancelar
        </BaseButton>
        <BaseButton
          color="primary"
          size="small"
          onClick={() => submitExternalForm(formId)}
        >
          Validar
        </BaseButton>
      </Fragment>
    );
  }, [onClose, formId]);

  const renderContent = useCallback(() => {
    return (
      <div
        id="DeactivateDigitalInvoice_CellphoneStep_div_container"
        className={classes.content}
      >
        <Typography className={classes.text}>
          Para solicitar la desactivación de factura digital es necesario
          validar tu celular
        </Typography>
        <form id={formId} onSubmit={handleSubmit(submitHandler)}>
          <Grid container justify="center" className={classes.gridContainer}>
            <Grid item xs={5} sm={5} className={classes.gridItem}>
              <Controller
                as={
                  <NumberInput
                    id="DeactivateDigitalInvoice_CellphoneStep_input_countryCode"
                    autoComplete="tel-country-code"
                    required
                    InputProps={{
                      startAdornment: (
                        <FlagColombia size={20} style={{ flexShrink: 0 }} />
                      )
                    }}
                    format="+###"
                    placeholder="57"
                    fullWidth
                    label="Prefijo"
                    error={Boolean(errors.phoneCountryCode)}
                    helperText={
                      errors.phoneCountryCode && errors.phoneCountryCode.message
                    }
                    margin="none"
                  />
                }
                control={control}
                name="phoneCountryCode"
                onChangeName="onValueChange"
                onChange={values => {
                  if (values[0]) {
                    return values[0].value;
                  }
                  return '';
                }}
              />
            </Grid>
            <Grid item xs={7} sm={7} className={classes.gridItem}>
              <Controller
                as={
                  <NumberInput
                    id="DeactivateDigitalInvoice_CellphoneStep_input_phone"
                    autoComplete="tel-local"
                    required
                    fullWidth
                    margin="none"
                    label="Número celular"
                    error={Boolean(errors.phone)}
                    helperText={errors.phone && errors.phone.message}
                  />
                }
                control={control}
                name="phone"
                onChangeName="onValueChange"
                onChange={values => {
                  if (values[0]) {
                    return values[0].value;
                  }
                  return '';
                }}
              />
            </Grid>
            <Grow in={true}>
              <Grid className={classes.sweetAlert} item xs={12}>
                <SweetAlert
                  id="DeactivateDigitalInvoice_CellphoneStep_div_phoneValidation_info"
                  icon={false}
                  classes={{
                    root: classes.sweetAlert,
                    message: classes.sweetAlertText
                  }}
                  message={
                    <Fragment>
                      Validaremos tu número de celular antes de continuar.
                    </Fragment>
                  }
                />
              </Grid>
            </Grow>
          </Grid>
        </form>
      </div>
    );
  }, [handleSubmit, errors, classes, control, formId, submitHandler]);

  return (
    <>
      <PhoneValidation
        setAlert={setAlert}
        phoneUpdate={phoneUpdate}
        setPhoneUpdate={setPhoneUpdate}
        phone={phone}
        phoneCountryCode={phoneCountryCode}
        requestCallback={onSuccessPhoneValidation}
        successCallback={onSuccessDigitalInvoiceDeactivation}
      />
      <BaseDialog
        open={open}
        handleClose={onClose}
        title={title}
        actions={renderActions}
        content={renderContent}
        fullScreen={isMobileSize}
        contentSize="small"
        TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
      />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  text: {
    textAlign: 'center',
    fontSize: 14,
    color: theme.palette.text.primary,
    width: '100%',
    maxWidth: '100%',
    marginBottom: 0
  },
  gridContainer: {
    marginTop: theme.spacing(2)
  },
  gridItem: {
    padding: [[0, theme.spacing()]],
    '&:nth-child(odd)': {
      paddingLeft: 0
    },
    '&:nth-child(even)': {
      paddingRight: 0
    }
  },
  sweetAlert: {
    marginTop: theme.spacing()
  }
}));

export default CellphoneStep;
