import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography, Grid } from '@material-ui/core';

import clsx from 'clsx';

import ButtonWithIcon from '../Buttons/ButtonWithIcon';
import BackIcon from '../../Assets/svg/ic_back.svg';
import { InsurancePlanIcon } from '../../Enums/insurances';
import InsuranceProductSkeleton from './InsuranceProductSkeleton';

const InsuranceProductCard = props => {
  const {
    title,
    logo,
    descriptionNode,
    buttonOnClick,
    isLoggedIn,
    productData = {},
    disabled,
    loading = true
  } = props;

  const classes = useStyles();

  const productExists = Boolean(Object.keys(productData).length);
  const PlanIcon = productExists && InsurancePlanIcon[productData.idPlan];

  if (loading) {
    return <InsuranceProductSkeleton />;
  }

  return (
    <div className={classes.card}>
      <Grid container direction="column" justify="space-between">
        <Grid container item justify="flex-start" alignItems="baseline">
          <Grid container item justify="space-between">
            <Grid item className={clsx(classes.margin)}>
              <img src={logo} className={clsx(classes.image)} alt="" />
            </Grid>
            {isLoggedIn && !disabled && (
              <Grid item className={clsx(classes.margin)}>
                <Typography
                  className={clsx(classes.statusText, {
                    [classes.acquiredText]: productExists,
                    [classes.notAcquiredText]: !productExists
                  })}
                >
                  {productExists ? 'Adquirido' : 'Sin seguro'}
                </Typography>
              </Grid>
            )}
          </Grid>
          {!productExists && (
            <Grid container item direction="column">
              <Grid item className={clsx(classes.margin)}>
                <Typography className={clsx(classes.title)}>{title}</Typography>
              </Grid>
              <Grid item className={clsx(classes.margin)}>
                {descriptionNode}
              </Grid>
            </Grid>
          )}
          {productExists && (
            <Grid container item direction="column" justify="space-between">
              <Grid item className={clsx(classes.margin)}>
                <Typography className={clsx(classes.title)}>{title}</Typography>
              </Grid>
              <Grid
                container
                item
                direction="row"
                wrap="nowrap"
                className={clsx(classes.margin)}
              >
                <PlanIcon size={24} />
                <Typography className={clsx(classes.productName)}>
                  {productData.plan.name}
                </Typography>
              </Grid>
              <Grid item className={clsx(classes.margin)}>
                <Typography className={clsx(classes.description)}>
                  Cantidad de beneficiarios del plan:{' '}
                  {productData.asegurado &&
                    productData.asegurado.beneficiario.length}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid container item justify="flex-end">
          {disabled ? (
            <ButtonWithIcon buttonText="Próximamente" />
          ) : (
            <ButtonWithIcon
              buttonText={productExists ? 'Gestionar' : 'Conoce más'}
              onClick={() => buttonOnClick()}
            >
              <img src={BackIcon} alt="" className={classes.knowMoreIcon} />
            </ButtonWithIcon>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    borderRadius: theme.custom.borderRadius,
    border: `1px solid ${theme.palette.background.border}`,
    position: 'relative',
    padding: theme.spacing(2),
    height: '100%',
    width: '100%',
    minHeight: 230,
    '& > *': {
      marginBottom: theme.spacing(1.5),
      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  description: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.greyDark
  },
  productName: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.greyDark
  },
  margin: {
    marginBottom: theme.spacing(1)
  },
  alignRight: {
    textAlign: 'right'
  },
  image: {
    width: 'auto',
    height: 64,
    borderRadius: theme.spacing(1)
  },
  planIcon: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(1)
  },
  knowMoreIcon: {
    height: 16,
    width: 16,
    transform: 'scaleX(-1)'
  },
  statusText: { fontSize: 14, fontWeight: 600 },
  notAcquiredText: { color: theme.palette.warning.main },
  acquiredText: { color: theme.palette.success.main }
}));

export default InsuranceProductCard;
