import axios from 'axios';
import { config } from '../../Configs';

export const SendPhoneCodeAPI = async (
  phone,
  phoneCountryCode,
  token = null,
  recaptchaToken = null
) => {
  try {
    const configObj = {
      headers: {
        Authorization: token
      },
      params: { 'g-recaptcha-response': recaptchaToken }
    };

    const response = await axios.post(
      `${config.api.url}/phoneValidation/sendPhoneCode`,
      { phoneCountryCode, phone },
      configObj
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const VerifyPhoneCodeAPI = async (
  code,
  phoneCountryCode,
  phone,
  token = null,
  recaptchaToken = null
) => {
  try {
    const configObj = {
      headers: {
        Authorization: token
      },
      params: { 'g-recaptcha-response': recaptchaToken }
    };

    const response = await axios.post(
      `${config.api.url}/phoneValidation/verifyPhoneCode`,
      { code, phoneCountryCode, phone },
      configObj
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};
