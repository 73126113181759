import React from 'react';

const GasesLogoIcon = props => {
  const {
    classes = {
      animation: {
        flame: {
          bigBlue: undefined,
          left: undefined,
          right: undefined,
          smallBlue: undefined
        }
      }
    },
    ...rest
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 120}
      height={props.height || 170}
      viewBox="0 0 120 170"
      {...rest}
    >
      <defs>
        <pattern
          id="left-lightblue-green-flame"
          x="0"
          y="0"
          width="120"
          height="170"
          patternUnits="userSpaceOnUse"
        >
          <rect x="0" y="0" width="120" height="170" fill="#80ac45" />
          <path
            fill="#36abad"
            d="M 63,152
           Q -19,138  60,7
           C 60,54 96,40 93,97
           Q 88,137  63,152
           z"
          />
          <path
            fill="white"
            d="M 63,152
           Q -9,123  92,31
           C 81,66 115,65 114,103
           Q 104,153  63,152
           z"
          />
        </pattern>
        <pattern
          id="right-lightblue-green-flame"
          x="0"
          y="0"
          width="120"
          height="170"
          patternUnits="userSpaceOnUse"
        >
          <rect x="0" y="0" width="120" height="170" fill="#80ac45" />
          <path
            fill="#36abad"
            d="M 63,152
           Q -19,138  60,7
           C 60,54 96,40 93,97
           Q 88,137  63,152
           z"
          />
          <path
            fill="white"
            d="M 63,152
           Q -4,171  22,50
           C 29,84 72,60 80,105
           Q 85,136  63,152
           z"
          />
        </pattern>
        <pattern
          id="left-blue-right-white-flame"
          x="0"
          y="0"
          width="120"
          height="170"
          patternUnits="userSpaceOnUse"
        >
          <path
            fill="#1f4a96"
            d="M 63,152
           Q -19,138  60,7
           C 60,54 96,40 93,97
           Q 88,137  63,152
           z"
          />
          <path
            fill="white"
            d="M 63,152
           Q -9,123  92,31
           C 81,66 115,65 114,103
           Q 104,153  63,152
           z"
          />
        </pattern>
        <pattern
          id="big-blue-inner-white-flame"
          x="0"
          y="0"
          width="120"
          height="170"
          patternUnits="userSpaceOnUse"
        >
          <rect x="0" y="0" width="120" height="170" fill="#1f4a96" />
          <path
            fill="url(#left-blue-right-white-flame)"
            d="M 63,152
           Q -4,171  22,50
           C 29,84 72,60 80,105
           Q 85,136  63,152
           z"
          />
        </pattern>
      </defs>
      <g>
        <path
          className={classes.animation.flame.bigBlue}
          id="big-blue-flame"
          fill="url(#big-blue-inner-white-flame)"
          d="M 63,152
           Q -19,138  60,7
           C 60,54 96,40 93,97
           Q 88,137  63,152
           z"
        />

        <path
          className={classes.animation.flame.left}
          id="left-flame"
          fill="url(#left-lightblue-green-flame)"
          d="M 63,152
           Q -4,171  22,50
           C 29,84 72,60 80,105
           Q 85,136  63,152
           z"
        />
        <path
          className={classes.animation.flame.right}
          id="right-flame"
          fill="url(#right-lightblue-green-flame)"
          d="M 63,152
           Q -9,123  92,31
           C 81,66 115,65 114,103
           Q 104,153  63,152
           z"
        />
        <path
          className={classes.animation.flame.smallBlue}
          id="small-blue-flame"
          fill="#1f4a96"
          d="M 63,152
           Q 50,138  60,112
           z"
        />
      </g>
    </svg>
  );
};

export default GasesLogoIcon;
