import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const EmptyState = ({ title, message, icon: Icon }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.emptyStateContainer}
    >
      {Icon && (
        <Grid item>
          <Icon className={classes.emptyStateIcon} />
        </Grid>
      )}
      {(title || message) && (
        <Grid item className={classes.emptyStateText}>
          {title && (
            <Typography
              component="h6"
              variant="h6"
              className={classes.emptyStateTitle}
            >
              {title}
            </Typography>
          )}
          {message && (
            <Typography className={classes.emptyStateMessage}>
              {message}
            </Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  emptyStateContainer: {
    marginTop: theme.spacing(2),
    maxWidth: 350,
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  emptyStateIcon: {
    maxWidth: '100%'
  },
  emptyStateText: {
    textAlign: 'center'
  },
  emptyStateTitle: {
    margin: `${theme.spacing()}px 0`
  }
}));

export default EmptyState;
