import axios from 'axios';
import { config } from '../../Configs';

export const GetOrderAPI = async ({
  orderId,
  captchaValue,
  contractId,
  token
}) => {
  try {
    const configObj = {
      params: { contractId, 'g-recaptcha-response': captchaValue }
    };

    if (token) {
      configObj.headers = {
        Authorization: token
      };
    }
    const response = await axios.get(
      `${config.api.url}/orders/${orderId}`,
      configObj
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GetOrderPDFAPI = async ({ orderId, userToken }) => {
  try {
    const configObj = {};

    if (userToken) {
      configObj.headers = {
        Authorization: userToken
      };
    }
    const response = await axios.get(
      `${config.api.url}/orders/pdf/${orderId}`,
      configObj
    );
    return { success: true, data: response.data.data };
  } catch (err) {
    return { success: false, error: err };
  }
};
