import React, { useCallback, useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SlideUpTransition } from '../Transitions/Transitions';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

import CloseIcon from '../CustomIcons/CloseIcon';
import BaseButton from '../Buttons/BaseButton';
import SimpleDivider from '../Dividers/SimpleDivider';

import {
  CircularMenuContext,
  CircularMenuDispatchContext
} from '../../Contexts/CircularMenuContext';
import { Company, PortalCheck } from '../../Configs/general';
import { OpenNewTab } from '../../Utils/Misc/Links';
import { CustomerPortalLink } from '../../Configs/Links';

function HelpDialog(props) {
  const { open = true, onClose, onPqrButtonClick = null } = props;
  const classes = useStyles();
  const isMobileSize = useMediaQuery('(max-width:480px)');

  const menu = useContext(CircularMenuContext);
  const setMenu = useContext(CircularMenuDispatchContext);

  const openHelpCenter = useCallback(() => {
    if (!menu.open) {
      document.getElementById('circularMenu').classList.toggle('active');
    }

    setMenu({ open: true, backdrop: true });
    onClose();
  }, [menu, setMenu, onClose]);

  const pqrButtonClickHandler = useCallback(() => {
    if (onPqrButtonClick) {
      PortalCheck.isEfigas
        ? OpenNewTab(CustomerPortalLink)
        : onPqrButtonClick();
    } else {
      onClose();
    }
  }, [onPqrButtonClick, onClose]);

  const renderHelpButton = useCallback(() => {
    return (
      <Grid item xs={12} sm={6}>
        <BaseButton
          color="primary"
          variant="outlined"
          className={classes.dialogButton}
          onClick={openHelpCenter}
          fullWidth
        >
          Centro de Ayuda
        </BaseButton>
      </Grid>
    );
  }, [openHelpCenter, classes]);

  const renderPQRButton = useCallback(() => {
    return (
      <Grid item xs={12} sm={6}>
        <BaseButton
          color="primary"
          variant="outlined"
          className={classes.dialogButton}
          onClick={pqrButtonClickHandler}
          fullWidth
        >
          PQR
        </BaseButton>
      </Grid>
    );
  }, [pqrButtonClickHandler, classes]);

  const renderButtons = useCallback(() => {
    if (isMobileSize) {
      return (
        <Grid container spacing={3} className={classes.buttonsContainer}>
          {renderHelpButton()}
          {renderPQRButton()}
        </Grid>
      );
    }
    return (
      <Grid container spacing={3} className={classes.buttonsContainer}>
        {renderPQRButton()}
        {renderHelpButton()}
      </Grid>
    );
  }, [isMobileSize, renderPQRButton, renderHelpButton, classes]);

  return (
    <Dialog
      id={'PQRHelpDialog_div'}
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: clsx({
          [classes.paperFullScreen]: isMobileSize,
          [classes.paper]: !isMobileSize
        })
      }}
      fullScreen={isMobileSize}
      TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography className={classes.titleText}>Aviso Importante</Typography>
        <IconButton
          size="small"
          aria-label="cerrar diálogo"
          onClick={onClose}
          className={classes.titleButton}
        >
          <CloseIcon size={15} className={classes.titleIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography
          paragraph
          className={clsx(classes.dialogText, classes.contactDialogText)}
        >
          Si necesitas reportar un escape de gas o emergencia, por favor{' '}
          <span className={classes.textBold}>no</span> utilices este medio.
          Llama de inmediato al <span className={classes.textBold}>164</span> o
          al{' '}
          <span className={classes.textBold}>
            {Company.emergencyPhoneLine}.
          </span>
        </Typography>
        <SimpleDivider />
        <Typography paragraph className={classes.dialogText}>
          Te invitamos a que primero resuelvas tus dudas en nuestro{' '}
          <span className={classes.textBold}>Centro de Ayuda</span>, donde
          podrás:
        </Typography>
        {!PortalCheck.isGasguajira && (
          <Typography paragraph className={classes.dialogText}>
            <span className={classes.textBold}>• </span>Chatear con nuestros
            agentes que resolverán tus dudas rápidamente, dentro de los horarios
            establecidos.
          </Typography>
        )}
        <Typography paragraph className={classes.dialogText}>
          <span className={classes.textBold}>• </span>Consultar preguntas
          frecuentes sobre los procesos de {Company.name} y el uso del Portal.
        </Typography>
        <Typography paragraph className={classes.dialogText}>
          En caso de que sigas considerando necesario registrar una{' '}
          <span className={classes.textBold}>PQR</span>, ten en cuenta que el
          tiempo de respuesta es de 15 días hábiles.
        </Typography>
        {renderButtons()}
      </DialogContent>
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: theme.custom.borderRadius,
    overflowX: 'hidden'
  },
  paperFullscreen: {
    borderRadius: 0,
    overflowX: 'hidden'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 70,
    backgroundColor: '#A54CF3',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
    background: 'linear-gradient(280deg, #3C41DB, #A54CF3)'
  },
  titleText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 600
  },
  titleButton: {
    padding: theme.spacing(1.5),
    fill: 'white'
  },
  titleContent: {
    marginBottom: 14
  },
  titleIcon: {
    fill: 'white'
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 460,
    padding: '25px 32px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  buttonsContainer: {
    marginTop: theme.spacing(2.5),
    marginBottom: 1
  },
  dialogButton: {
    fontWeight: 500
  },
  dialogText: {
    color: theme.typography.color.grey,
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  textBold: {
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  contactDialogText: {
    marginBottom: 0
  },
  linkText: {
    fontWeight: 500,
    textDecoration: 'underline',
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  }
}));

export default HelpDialog;
