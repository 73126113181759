import * as React from 'react';

function SvgIcSupport(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || '22'}
      height={props.size || '22'}
      viewBox="0 0 22.833 25.5"
      {...props}
    >
      <g id="ic-support" transform="translate(124.083 -383.583)">
        <rect
          id="Rectángulo_115"
          data-name="Rectángulo 115"
          width="5.333"
          height="8"
          rx="2"
          transform="translate(-123.333 396.333)"
          fill="none"
          stroke={props.color || '#104EB2'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <rect
          id="Rectángulo_116"
          data-name="Rectángulo 116"
          width="5.333"
          height="8"
          rx="2"
          transform="translate(-107.333 396.333)"
          fill="none"
          stroke={props.color || '#104EB2'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Trazado_577"
          data-name="Trazado 577"
          d="M4,18.667v-4a10.667,10.667,0,1,1,21.333,0v4"
          transform="translate(-127.333 380.333)"
          fill="none"
          stroke={props.color || '#104EB2'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Trazado_578"
          data-name="Trazado 578"
          d="M20,19c0,2.209-3.582,4-8,4"
          transform="translate(-124.667 385.333)"
          fill="none"
          stroke={props.color || '#104EB2'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
}

export default SvgIcSupport;
