import * as React from 'react';

function SvgDislike(props) {
  return (
    <svg width={36.914} height={34.535} fill={'#183f8f'} {...props}>
      <g data-name="Grupo 771">
        <g data-name="Grupo 770">
          <path
            data-name="Trazado 748"
            d="M.942 15.996a3.689 3.689 0 002.749 6.152h7.165a23.859 23.859 0 00-1.012 6.235v1.23a4.928 4.928 0 004.922 4.922h1.23a1.23 1.23 0 001.194-.932l.67-2.682c.939-3.755 3.921-7.9 7.007-8.652a3.7 3.7 0 003.434 2.34h7.383a1.231 1.231 0 001.23-1.23V1.235a1.231 1.231 0 00-1.23-1.23h-7.383a3.7 3.7 0 00-3.332 2.1L20.741.696a13.505 13.505 0 00-4.28-.695H6.152A3.692 3.692 0 002.72 5.052a3.689 3.689 0 00-1.778 6.023 3.684 3.684 0 000 4.922zm26.128-12.3a1.232 1.232 0 011.23-1.23h6.153v19.682h-6.152a1.232 1.232 0 01-1.23-1.23zM6.152 14.765H3.691a1.23 1.23 0 010-2.461h2.461a1.23 1.23 0 000-2.461H3.691a1.23 1.23 0 010-2.461h2.461a1.23 1.23 0 000-2.461 1.23 1.23 0 010-2.461H16.46a11.05 11.05 0 013.5.568l4.654 1.55v15.226a9.915 9.915 0 00-5.525 3.59 17.954 17.954 0 00-3.612 6.931l-.437 1.75h-.27a2.464 2.464 0 01-2.461-2.461v-1.23a19.471 19.471 0 011.178-6.235h2.509a1.23 1.23 0 000-2.461h-12.3a1.23 1.23 0 110-2.461h2.456a1.23 1.23 0 000-2.461z"
          />
        </g>
      </g>
      <g data-name="Grupo 773">
        <g data-name="Grupo 772">
          <circle
            data-name="Elipse 207"
            cx={1}
            cy={1}
            r={1}
            transform="rotate(180 15.957 3.5)"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgDislike;
