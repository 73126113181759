import React from 'react';
import { portal, Portal } from '../../../../Configs/general';

import RemoveContractGDC from './RemoveContract.GDC';
import RemoveContractGDG from './RemoveContract.GDG';

const RemoveContract = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <RemoveContractGDC {...props} />;
    case Portal.TripleA:
    case Portal.Gasguajira:
      return <RemoveContractGDG {...props} />;
    case Portal.Efigas:
      return <RemoveContractGDC {...props} />;
    default:
      return <></>;
  }
};

export default RemoveContract;
