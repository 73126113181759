import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FilterListIcon from '@material-ui/icons/FilterList';

import BaseFilterModal from './BaseFilterModal';
import SvgIcCloseCircle from '../CustomIcons/IcCloseCircle';
import ButtonWithIcon from '../../Components/Buttons/ButtonWithIcon';

const BaseFilter = ({
  searchBar,
  filters,
  className,
  clearFilters,
  clearFiltersStyles,
  modalClearFiltersStyles,
  mobileBreakpoint,
  showClearFilters,
  filterModalTitle,
  filtersLabel,
  filtersLabelStyles,
  disableClearOutside = false,
  handleOpenMobileFilters,
  handleSetMobileFilters,
  handleCloseMobileFilters,
  avoidSpacing,
  filterButtonOn
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const smallScreen = useMediaQuery(
    theme.breakpoints.down(mobileBreakpoint || theme.breakpoints.values.sm)
  );

  useEffect(() => {
    if (!smallScreen) {
      setOpen(false);
    }
  }, [smallScreen]);

  const classes = useStyles();
  return (
    <div className={className}>
      {filters && smallScreen && (
        <Grid container justify="space-between" alignItems="center">
          <ButtonWithIcon
            id="filter"
            textClass={classes.filterText}
            badgeVariant="dot"
            hideBadge={!showClearFilters}
            buttonText={'Filtros'}
            onClick={() => {
              handleOpenMobileFilters
                ? handleOpenMobileFilters(setOpen)
                : setOpen(true);
            }}
          >
            <FilterListIcon
              color="primary"
              fontSize="small"
              className={classes.filterIcon}
            />
          </ButtonWithIcon>

          {showClearFilters && !disableClearOutside && (
            <Typography
              className={clsx(classes.textClearFilter, clearFiltersStyles)}
              onClick={() => {
                clearFilters();
              }}
            >
              Limpiar filtros
              <SvgIcCloseCircle className={classes.iconClearFilter} />
            </Typography>
          )}
        </Grid>
      )}
      {!smallScreen && (
        <Grid
          wrap="wrap"
          container
          spacing={avoidSpacing ? 0 : 1}
          className={classes.root}
          alignItems="center"
        >
          {searchBar && searchBar()}

          {filtersLabel && (
            <Grid
              item
              className={clsx(classes.filtersLabel, filtersLabelStyles)}
            >
              {filtersLabel}
            </Grid>
          )}

          {filters && filters()}

          {showClearFilters && !disableClearOutside && (
            <Typography
              className={clsx(classes.textClearFilter, clearFiltersStyles)}
              onClick={() => {
                clearFilters();
              }}
            >
              Limpiar filtros
              <SvgIcCloseCircle className={classes.iconClearFilter} />
            </Typography>
          )}
        </Grid>
      )}
      {open && (
        <BaseFilterModal
          open={open && smallScreen}
          clearFilters={clearFilters}
          modalClearFiltersStyles={modalClearFiltersStyles}
          showClearFilters={showClearFilters}
          filterModalTitle={filterModalTitle}
          disableClearOutside={disableClearOutside}
          filterButtonOn={filterButtonOn}
          filter={() => {
            handleSetMobileFilters
              ? handleSetMobileFilters(setOpen)
              : setOpen(false);
          }}
          handleClose={() => {
            handleCloseMobileFilters
              ? handleCloseMobileFilters(setOpen)
              : setOpen(false);
          }}
          content={filters}
          title="Filtros"
        />
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  filtersLabel: {
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'center',
    fontSize: '0.8em',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5)
  },
  filterContainer: {
    flexGrow: 1
  },
  textClearFilter: {
    fontSize: '0.7em',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: theme.spacing(1, 0.5)
  },
  iconClearFilter: {
    fill: theme.palette.text.greyDark,
    marginLeft: theme.spacing()
  },
  filterButton: {
    display: 'relative',
    color: theme.palette.primary.main
  },
  filterText: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.main
  },
  filterIcon: {
    height: 16,
    width: 16,
    color: theme.palette.primary.main
  }
}));

export default BaseFilter;
