import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import * as yup from 'yup';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { isPrintableAscii } from '../../../Utils/Misc/String';
import {
  extractErrorMessage,
  redirectOnAuthFailure
} from '../../../Utils/Errors/Errors';
import {
  AppleLoginAPI,
  GoogleLoginAPI,
  RevokeAppleTokenAPI,
  UnlinkAccountAPI
} from '../../../API/SocialUserAPI';
import capitalize from '../../../Utils/pipes/capitalize';
import { UpdateUserAPI } from '../../../API/UserAPI';
import { history } from '../../../Routes/history';
import { ROUTE_NAMES } from '../../../Routes/Routes';
import BaseButton from '../../../Components/Buttons/BaseButton';
import SimpleDivider from '../../../Components/Dividers/SimpleDivider';
import TextInput from '../../../Components/Inputs/TextInput';
import GoogleIcon from '../../../Components/CustomIcons/GoogleIcon';
import ConfirmationDialog from '../../../Components/Dialogs/ConfirmationDialog';
import {
  GoogleClientContext,
  GoogleClientDispatchContext
} from '../../../Contexts/GoogleClientContext';
import GoogleButton from '../../../Components/Buttons/GoogleButton';
import { logoutUser, updateUser } from '../../../Utils/User/Actions';
import {
  AppleClientContext,
  AppleClientDispatchContext,
  AppleClientSetAppleListenerState,
  AppleClientInitializedContext
} from '../../../Contexts/AppleClientContext';
import AppleButton from '../../../Components/Buttons/AppleButton';
import AppleLogo from '../../../Components/CustomIcons/AppleLogo';
import {
  getLocalAppleUserData,
  removeLocalAppleUserData
} from '../../../Enums/socialUser';
import {
  isUserLoggedInWithPassword,
  isUserLoggedInWithSocial
} from '../../../Enums/users';

const emailSchema = yup.object({
  email: yup
    .string()
    .trim()
    .required('Ingresa un correo electrónico')
    .email('Ingresa un email válido')
    .test(
      'is-ascii',
      'Este campo no puede contener tildes ni caracteres especiales',
      isPrintableAscii
    )
});

const MyProfileLinkedAccountsGDC = props => {
  const {
    currentUser,
    setLoading,
    authToken,
    setAlert,
    setCurrentUser,
    setPasswordDialog,
    setSelectedItem,
    isFacebookLoading,
    setIsFacebookLoading,
    setIsAppleLoading
  } = props;

  const classes = useStyles();

  const googleData = useContext(GoogleClientContext);
  const setGoogleData = useContext(GoogleClientDispatchContext);
  const appleData = useContext(AppleClientContext);
  const setAppleData = useContext(AppleClientDispatchContext);
  const setApplePopupListener = useContext(AppleClientSetAppleListenerState);
  const isAppleInitialized = useContext(AppleClientInitializedContext);

  const [email, setEmail] = useState(currentUser ? currentUser.email : '');
  const [emailErrors, setEmailErrors] = useState({});
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [openDialog, setOpenDialog] = useState(null);
  const [accountType, setAccountType] = useState('');

  const isFirefox = window.navigator.userAgent.indexOf('Firefox') !== -1;

  const handleGoogleLogin = useCallback(
    async data => {
      const { encodedToken } = data;

      setLoading(true);

      const authResponse = await GoogleLoginAPI(encodedToken, authToken);
      setLoading(false);

      if (!authResponse.success) {
        setAlert({
          type: 'error',
          message: extractErrorMessage(authResponse).message
        });
        setGoogleData(null);
        return;
      }

      const { token, appToken } = authResponse.data.data;

      setAlert({
        type: 'success',
        message: 'Cuenta vinculada satisfactoriamente'
      });
      setGoogleData(null);
      updateUser(setCurrentUser, token, appToken);
    },
    [authToken, setAlert, setCurrentUser, setLoading, setGoogleData]
  );

  const handleAppleLogin = useCallback(
    async data => {
      const {
        tokenId: sub,
        email: appleEmail,
        givenName,
        familyName,
        encodedToken,
        code
      } = data;

      setLoading(true);

      const localAppleUserData = getLocalAppleUserData(sub);

      let firstName = localAppleUserData ? localAppleUserData.firstName : '';
      let lastName = localAppleUserData ? localAppleUserData.lastName : '';

      if (givenName && familyName) {
        firstName = givenName;
        lastName = familyName;
      }

      const userData = {
        idToken: encodedToken,
        email: appleEmail,
        firstName,
        lastName,
        code
      };

      const authResponse = await AppleLoginAPI(userData, authToken);
      setLoading(false);

      if (!authResponse.success) {
        setAlert({
          type: 'error',
          message: extractErrorMessage(authResponse).message
        });
        setAppleData(null);
        return;
      }

      if (localAppleUserData) {
        removeLocalAppleUserData(sub);
      }

      const { token, appToken } = authResponse.data.data;

      setAlert({
        type: 'success',
        message: 'Cuenta vinculada satisfactoriamente'
      });
      setAppleData(null);
      updateUser(setCurrentUser, token, appToken);
    },
    [authToken, setAlert, setCurrentUser, setLoading, setAppleData]
  );

  const onSubmitUnlinkAccount = useCallback(
    async type => {
      setAccountType(type);

      if (isUserLoggedInWithPassword(currentUser)) {
        setOpenDialog(capitalize(type));
        return;
      }
      setLoading(true);
      const response = await UnlinkAccountAPI({ type }, authToken);

      if (response.success) {
        const { token, appToken } = response.data.data;
        setAlert({
          type: 'success',
          message: 'Cuenta desvinculada satisfactoriamente'
        });
        updateUser(setCurrentUser, token, appToken);
        setLoading(false);
        return;
      }

      if (
        redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
      ) {
        return;
      }

      setLoading(false);
      setAlert({
        type: 'error',
        message: extractErrorMessage(response).message
      });
    },
    [authToken, setAlert, setCurrentUser, setLoading, currentUser]
  );

  const onConfirmUnlinkAccount = useCallback(
    async modalPassword => {
      const response = await UnlinkAccountAPI(
        {
          type: accountType,
          currentPassword: modalPassword
        },
        authToken
      );
      return response;
    },
    [accountType, authToken]
  );

  const onSuccessUnlinkAccount = useCallback(
    async response => {
      if (response.success) {
        const { token, appToken } = response.data.data;
        setAlert({
          type: 'success',
          message: 'Cuenta desvinculada satisfactoriamente'
        });
        updateUser(setCurrentUser, token, appToken);
        return;
      }

      if (
        redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
      ) {
        return;
      }

      setAlert({
        type: 'error',
        message: extractErrorMessage(response).message
      });
    },
    [setAlert, setCurrentUser]
  );

  const onChangeEmail = useCallback(e => {
    setEmail(e.target.value);
  }, []);

  const onConfirmEmailUpdate = useCallback(
    async modalPassword => {
      const response = await UpdateUserAPI(currentUser.token, currentUser.id, {
        email,
        currentPassword: modalPassword
      });
      return response;
    },
    [email, currentUser]
  );

  const onSuccessConfirmEmailUpdate = useCallback(() => {
    logoutUser(setCurrentUser);
    history.push(ROUTE_NAMES.confirmEmail, { email });
  }, [setCurrentUser, email]);

  const onSubmitEmail = useCallback(
    async closed => {
      if (closed) {
        return;
      }

      if (isUserLoggedInWithPassword(currentUser)) {
        setPasswordDialog({
          title: 'Actualizar correo electrónico',
          requestCallback: onConfirmEmailUpdate,
          successCallback: onSuccessConfirmEmailUpdate
        });
      }

      if (isUserLoggedInWithSocial(currentUser) && currentUser.hasPassword) {
        setLoading(true);
        const response = await UpdateUserAPI(
          currentUser.token,
          currentUser.id,
          {
            email,
            currentPassword: null
          }
        );

        if (response.success) {
          onSuccessConfirmEmailUpdate();
        }
        setLoading(false);
      } else {
        setAlert({
          type: 'error',
          message: 'Necesitas crear una contraseña para actualizar tu correo'
        });

        setSelectedItem(2);
      }

      return { unmounting: false, closeDialog: true };
    },
    [
      currentUser,
      onConfirmEmailUpdate,
      onSuccessConfirmEmailUpdate,
      setAlert,
      setPasswordDialog,
      setSelectedItem,
      setLoading,
      email
    ]
  );

  useEffect(() => {
    if (isFacebookLoading) {
      /* Disable button when user is in Firefox Incognito mode
      because Facebook login doesn't work */
      if (isFirefox) {
        const disableFirefox = setTimeout(
          () => setIsFacebookLoading(false),
          20000
        );
        return () => {
          clearTimeout(disableFirefox);
        };
      }
    }
  }, [isFacebookLoading, setIsFacebookLoading, isFirefox]);

  useEffect(() => {
    if (!email) {
      setEmailErrors(errs => ({
        ...errs,
        email: 'Ingresa un correo electrónico'
      }));
      return;
    }

    try {
      emailSchema.validateSync({ email, currentEmail: currentUser.email });
      setEmailErrors(errs => ({ ...errs, email: null }));
    } catch (err) {
      setEmailErrors(errs => ({ ...errs, [err.path]: err.errors[0] }));
    }
  }, [email, currentUser]);

  useEffect(() => {
    if (openDialog) {
      setPasswordDialog({
        title: `Desvincular cuenta de ${openDialog}`,
        requestCallback: onConfirmUnlinkAccount,
        successCallback: onSuccessUnlinkAccount
      });
      setOpenDialog(null);
    }
  }, [
    openDialog,
    onConfirmUnlinkAccount,
    onSuccessUnlinkAccount,
    setPasswordDialog
  ]);

  useEffect(() => {
    if (googleData) {
      handleGoogleLogin(googleData);
    }
  }, [googleData, handleGoogleLogin]);

  useEffect(() => {
    if (appleData) {
      handleAppleLogin(appleData);
    }
  }, [appleData, handleAppleLogin]);

  const handleUnlinkAppleAccount = useCallback(async () => {
    setIsAppleLoading(true);
    setApplePopupListener(false);

    if (!isAppleInitialized) {
      setAlert({
        type: 'error',
        message:
          'No es posible desvincular tu cuenta en este momento. Intenta más tarde.'
      });

      setIsAppleLoading(false);
      setApplePopupListener(true);
      return;
    }

    try {
      const data = await window.AppleID.auth.signIn();

      setApplePopupListener(true);

      const { code, id_token: idToken } = data.authorization;

      const revokeAppleResponse = await RevokeAppleTokenAPI(
        { idToken, code },
        authToken
      );

      if (!revokeAppleResponse.success) {
        setAlert({
          type: 'error',
          message:
            'No pudimos desvincular tu cuenta de Apple. Inténtalo más tarde.'
        });
        setIsAppleLoading(false);
        return;
      }

      const unlinkResponse = await UnlinkAccountAPI(
        { type: 'apple' },
        authToken
      );

      if (unlinkResponse.success) {
        const { token, appToken } = unlinkResponse.data.data;
        setAlert({
          type: 'success',
          message: 'Cuenta desvinculada satisfactoriamente'
        });
        updateUser(setCurrentUser, token, appToken);
        setIsAppleLoading(false);
        return;
      }

      if (
        redirectOnAuthFailure(unlinkResponse, '/', () =>
          logoutUser(setCurrentUser)
        )
      ) {
        return;
      }

      setIsAppleLoading(false);
      setAlert({
        type: 'error',
        message: extractErrorMessage(unlinkResponse).message
      });
    } catch (err) {
      setAlert({
        type: 'info',
        message:
          'Debes iniciar sesión con tu cuenta de Apple para desvincularla. Inténtalo de nuevo.'
      });
      setApplePopupListener(true);
      setIsAppleLoading(false);
    }
  }, [
    authToken,
    setAlert,
    setIsAppleLoading,
    setCurrentUser,
    setApplePopupListener,
    isAppleInitialized
  ]);

  if (!currentUser) {
    return <Fragment></Fragment>;
  }

  const isGoogleUser = Boolean(currentUser.authenticationType.google);
  const isAppleUser = Boolean(currentUser.authenticationType.apple);
  const canSubmitEmail = email !== currentUser.email && !emailErrors.email;
  const canUnlinkAccount =
    currentUser.hasPassword ||
    Object.keys(currentUser.authenticationType).length > 1;

  return (
    <Fragment>
      {confirmationDialog && (
        <ConfirmationDialog
          open={confirmationDialog}
          title={'Actualizar correo electrónico'}
          content={
            <Typography className={classes.confirmationText}>
              Recuerda que debes validar este nuevo correo para volver a
              ingresar a tu cuenta. ¿Estás seguro?
            </Typography>
          }
          setDialog={setConfirmationDialog}
          requestCallback={onSubmitEmail}
        />
      )}
      <Grid container>
        <Grid container spacing={2}>
          <Grid
            item
            sm={8}
            className={`${classes.fullWidth} ${emailErrors.email &&
              classes.optionWithErrors}`}
          >
            <TextInput
              fullWidth
              margin="none"
              label="Correo electrónico"
              id="profile-new-email"
              required={true}
              type="email"
              value={email}
              error={Boolean(emailErrors.email)}
              helperText={emailErrors.email}
              onChange={onChangeEmail}
            />
          </Grid>
          {canSubmitEmail && (
            <Grid item className={classes.fullWidth} sm={4}>
              <BaseButton
                id="email-submit"
                color="primary"
                variant="outlined"
                size="medium"
                fullWidth
                className={classes.emailButton}
                onClick={() => setConfirmationDialog(true)}
              >
                Actualizar
              </BaseButton>
            </Grid>
          )}
        </Grid>
        <SimpleDivider />
        <Grid item sm={12} className={classes.fullWidth}>
          <Typography className={classes.googleText}>
            <GoogleIcon size={12} className={classes.socialIcon} />
            Cuenta de Google
          </Typography>

          {isGoogleUser ? (
            <Grid
              container
              justify="space-between"
              wrap="nowrap"
              alignItems="center"
            >
              <Grid item xs={8} sm={10}>
                <Typography className={classes.socialUserInfoText}>
                  {currentUser.authenticationType.google.email}
                </Typography>
              </Grid>
              {canUnlinkAccount && (
                <Grid item xs={4} sm={2}>
                  <Typography
                    onClick={() => onSubmitUnlinkAccount('google')}
                    className={classes.unlinkText}
                  >
                    Desvincular
                  </Typography>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid item xs={12} sm={8} className={classes.linkButtonContainer}>
              <Typography className={classes.linkText}>
                Vincula tu cuenta:
              </Typography>
              <GoogleButton type="continue_with" />
            </Grid>
          )}
        </Grid>

        <SimpleDivider />

        <Grid item sm={12} className={classes.fullWidth}>
          <Typography className={classes.googleText}>
            <AppleLogo size={12} className={classes.socialIcon} />
            Cuenta de Apple
          </Typography>

          {isAppleUser ? (
            <Grid
              container
              justify="space-between"
              wrap="nowrap"
              alignItems="center"
            >
              <Grid item xs={8} sm={10}>
                <Typography className={classes.socialUserInfoText}>
                  {currentUser.authenticationType.apple.email}
                </Typography>
              </Grid>
              {canUnlinkAccount && (
                <Grid item xs={4} sm={2}>
                  <Typography
                    onClick={handleUnlinkAppleAccount}
                    className={classes.unlinkText}
                  >
                    Desvincular
                  </Typography>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid item xs={12} sm={8} className={classes.linkButtonContainer}>
              <Typography className={classes.linkText}>
                Vincula tu cuenta:
              </Typography>
              <AppleButton
                customContainer={classes.appleButtomContainer}
                type="continue"
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  socialUserInfoText: {
    fontSize: 12,
    color: '#747B87',
    fontWeight: 500,
    wordBreak: 'break-all'
  },
  unlinkText: {
    textAlign: 'right',
    fontSize: 10,
    color: '#104EB2',
    fontWeight: 500,
    textDecoration: 'underline',
    '&:hover': {
      color: '#69d6d6',
      cursor: 'pointer'
    }
  },
  optionWithErrors: {
    marginBottom: -17
  },
  linkButtonContainer: {
    width: '100%',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(0)
  },
  googleText: {
    display: 'flex',
    fontSize: 10,
    fontWeight: 500,
    color: '#747B87',
    marginBottom: theme.spacing(2)
  },
  facebookText: {
    display: 'flex',
    fontSize: 10,
    fontWeight: 500,
    color: '#747B87',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  socialIcon: {
    marginRight: 5,
    marginTop: 1
  },
  facebookIcon: {
    marginRight: 5,
    fontSize: 16,
    color: '#475993'
  },
  emailButton: {
    minHeight: 58
  },
  fullWidth: {
    width: '100%'
  },
  confirmationText: {
    fontSize: 14,
    color: theme.palette.text.primary,
    maxWidth: '100%'
  },
  linkText: {
    fontSize: 10,
    fontWeight: 500,
    color: '#747B87',
    marginBottom: theme.spacing()
  },
  appleButtomContainer: {
    maxWidth: 321,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      maxWidth: '100%'
    }
  }
}));

export default MyProfileLinkedAccountsGDC;
