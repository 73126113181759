import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import PqrGDC from './PQR.GDC';

const PQR = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <PqrGDC {...props} />;
    case Portal.Gasguajira:
      return <PqrGDC {...props} />;
    default:
      return <></>;
  }
};

export default PQR;
