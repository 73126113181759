import React, { Fragment, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Grid, makeStyles } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import SweetAlert from '../../Components/Alerts/SweetAlert';
import BaseButton from '../../Components/Buttons/BaseButton';
import FlagColombia from '../../Components/CustomIcons/Flags/FlagColombia';
import CompositeSelectInput from '../../Components/Inputs/CompositeSelectInput';
import NumberInput from '../../Components/Inputs/NumberInput';
import TextInput from '../../Components/Inputs/TextInput';
import { UpdateUserDataAPI, ValidateUserDataAPI } from '../../API/UserAPI';
import { useCallback } from 'react';
import {
  extractErrorMessage,
  redirectOnAuthFailure
} from '../../Utils/Errors/Errors';
import PhoneValidation from './PhoneValidation';
import { logoutUser, updateUser } from '../../Utils/User/Actions';
import { doesPhoneNeedsRevalidation } from '../../Utils/User/Actions';
import {
  isUserLoggedInWithPassword,
  isUserLoggedInWithSocial
} from '../../Enums/users';

const IDENTIFICATION_OPTIONS = [
  { label: 'CC', value: 'CC' },
  { label: 'PP', value: 'PP' },
  { label: 'CE', value: 'CE' },
  { label: 'TI', value: 'TI' }
];

const editProfileSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .max(60, 'Debe tener 60 letras o menos')
    .required('Ingresa tu nombre'),
  lastName: yup
    .string()
    .trim()
    .max(60, 'Debe tener 60 letras o menos')
    .required('Ingresa tus apellidos'),
  identification: yup.mixed().when('identificationType', {
    is: 'PP',
    then: yup
      .string()
      .trim()
      .matches(/^[0-9a-zA-Z]*$/, 'Ingresa un número de pasaporte válido'),
    otherwise: yup
      .string()
      .trim()
      .matches(/^[0-9]*$/, 'Ingresa un número de identificación válido')
  }),
  identificationType: yup
    .string()
    .trim()
    .oneOf(['CC', 'PP', 'CE', 'TI', 'NIT'], 'Debes escoger una opción correcta')
    .required('Debes ingresar un tipo de identificación'),
  phone: yup
    .string()
    .trim()
    .length(10, 'Debe tener 10 dígitos')
    .required('Ingresa un teléfono'),
  phoneCountryCode: yup
    .string()
    .trim()
    .max(3, 'Debe tener máximo 3 dígitos')
    .required('Debe ser válido')
});

const MyProfileUserInfo = props => {
  const {
    currentUser,
    setAlert,
    setCurrentUser,
    setLoading,
    setPasswordDialog
  } = props;

  const [userNewData, setUserNewData] = useState(null);
  const [openDialog, setOpenDialog] = useState(null);
  const [phoneUpdate, setPhoneUpdate] = useState(false);

  const classes = useStyles();

  const { handleSubmit, register, errors, formState, watch, control } = useForm(
    {
      validationSchema: editProfileSchema,
      mode: 'onChange'
    }
  );

  const watchForm = watch([
    'firstName',
    'lastName',
    'identification',
    'identificationType',
    'phoneCountryCode',
    'phone'
  ]);

  const onSubmitUserData = useCallback(
    async values => {
      const updateData = {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        identification: values.identification.trim(),
        identificationType: values.identificationType.trim(),
        phone: values.phone.trim(),
        phoneCountryCode: values.phoneCountryCode.trim()
      };
      setUserNewData(updateData);

      if (isUserLoggedInWithSocial(currentUser)) {
        const response = await ValidateUserDataAPI(
          currentUser.token,
          currentUser.id,
          updateData
        );

        if (!response.success) {
          setLoading(false);
          setAlert({
            type: 'error',
            message: extractErrorMessage(response).message
          });
          return;
        }
      }

      if (
        currentUser.phoneCountryCode !== values.phoneCountryCode ||
        currentUser.phone !== values.phone ||
        !currentUser.phoneValidated
      ) {
        setPhoneUpdate({ submit: true });
        return;
      }

      if (isUserLoggedInWithPassword(currentUser)) {
        setOpenDialog(true);
        return;
      }

      setLoading(true);
      const response = await UpdateUserDataAPI(
        currentUser.token,
        currentUser.id,
        updateData
      );
      if (response.success) {
        const { token, appToken } = response.data.data;
        setAlert({
          type: 'success',
          message: 'Datos actualizados satisfactoriamente'
        });
        updateUser(setCurrentUser, token, appToken);
        setLoading(false);
        return;
      }

      if (
        redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
      ) {
        return;
      }

      setLoading(false);
      setAlert({
        type: 'error',
        message: extractErrorMessage(response).message
      });
    },
    [
      currentUser,
      setAlert,
      setCurrentUser,
      setUserNewData,
      setOpenDialog,
      setLoading,
      setPhoneUpdate
    ]
  );

  const onConfirmUserData = useCallback(
    async modalPassword => {
      const userValidationResponse = await ValidateUserDataAPI(
        currentUser.token,
        currentUser.id,
        userNewData
      );

      if (!userValidationResponse.success) {
        return userValidationResponse;
      }

      const response = await UpdateUserDataAPI(
        currentUser.token,
        currentUser.id,
        {
          ...userNewData,
          currentPassword: modalPassword
        }
      );
      return response;
    },
    [currentUser, userNewData]
  );

  const onSuccessUserData = useCallback(
    response => {
      if (response.success) {
        const { token, appToken } = response.data.data;
        setAlert({
          type: 'success',
          message: 'Datos actualizados satisfactoriamente'
        });
        updateUser(setCurrentUser, token, appToken);
        setUserNewData(null);
        return;
      }

      if (
        redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
      ) {
        return;
      }

      setAlert({
        type: 'error',
        message: extractErrorMessage(response).message
      });
      return;
    },
    [setCurrentUser, setAlert, setUserNewData]
  );

  const ifDataHasChanged = useCallback(() => {
    if (!currentUser) {
      return false;
    }
    const userData = {
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      identification: currentUser.identification || '',
      identificationType: currentUser.identificationType || 'CC',
      phoneCountryCode: currentUser.phoneCountryCode || '57',
      phone: currentUser.phone || ''
    };
    return Boolean(JSON.stringify(watchForm) !== JSON.stringify(userData));
  }, [watchForm, currentUser]);

  const openPhoneDialog = useCallback(() => {
    if (!phoneUpdate) {
      return;
    }
    return (
      <PhoneValidation
        setAlert={setAlert}
        phoneUpdate={phoneUpdate}
        setPhoneUpdate={setPhoneUpdate}
        phone={watchForm.phone}
        phoneCountryCode={watchForm.phoneCountryCode}
        requestCallback={ifDataHasChanged() ? onConfirmUserData : null}
        successCallback={ifDataHasChanged() ? onSuccessUserData : null}
      />
    );
  }, [
    ifDataHasChanged,
    onConfirmUserData,
    onSuccessUserData,
    phoneUpdate,
    setAlert,
    setPhoneUpdate,
    watchForm.phone,
    watchForm.phoneCountryCode
  ]);

  const showDataMissingBanner = useCallback(
    isUserDataMissing => {
      if (!isUserDataMissing) {
        return;
      }
      return (
        <SweetAlert
          id="MyProfile_form_error_alert"
          type="error"
          classes={{
            root: classes.sweetAlert,
            message: classes.sweetAlertText
          }}
          message={
            'Aún tienes datos faltantes. Recuerda llenar todos los campos para completar tu perfil.'
          }
        />
      );
    },
    [classes]
  );

  const showPhoneErrorBanner = useCallback(
    showPhoneError => {
      const needsRevalidation = doesPhoneNeedsRevalidation(
        currentUser.phoneValidationDate
      );
      if (!showPhoneError && !needsRevalidation) {
        return;
      }
      return (
        <SweetAlert
          id="MyProfile_phone_error_alert"
          type={needsRevalidation ? 'warning' : 'error'}
          classes={{
            root: classes.phoneSweetAlert,
            message: classes.sweetAlertText
          }}
          message={
            <Fragment>
              {needsRevalidation ? 'Queremos seguir en contacto contigo. ' : ''}
              Presiona <strong>aquí</strong> para validar tu número celular (+
              {currentUser.phoneCountryCode} {currentUser.phone}).{' '}
              {!needsRevalidation &&
                'Debes realizarlo para actualizar tus datos.'}
            </Fragment>
          }
          onClick={() => setPhoneUpdate({ submit: true })}
        />
      );
    },
    [classes, currentUser, setPhoneUpdate]
  );

  useEffect(() => {
    if (openDialog) {
      setPasswordDialog({
        title: 'Actualizar datos de usuario',
        requestCallback: onConfirmUserData,
        successCallback: onSuccessUserData
      });
      setOpenDialog(false);
    }
  }, [
    openDialog,
    onConfirmUserData,
    onSuccessUserData,
    setOpenDialog,
    setPasswordDialog
  ]);

  if (!currentUser) {
    return <Fragment></Fragment>;
  }

  const phoneHasChanged =
    watchForm.phoneCountryCode !== currentUser.phoneCountryCode ||
    watchForm.phone !== currentUser.phone;
  const showPhoneError = !currentUser.phoneValidated && !phoneHasChanged;
  const isUserDataMissing = !currentUser.identification || !currentUser.phone;

  return (
    <Fragment>
      {openPhoneDialog()}
      {showDataMissingBanner(isUserDataMissing)}
      {showPhoneErrorBanner(showPhoneError)}
      <form
        key={1}
        id="editProfileForm"
        onSubmit={handleSubmit(onSubmitUserData)}
      >
        <Grid
          item
          sm={12}
          className={`${classes.inputFieldContainer} ${errors.name &&
            classes.optionWithErrors}`}
        >
          <TextInput
            inputRef={register}
            id="MyProfile_input_firstName"
            required
            fullWidth
            margin="none"
            label="Nombres"
            error={Boolean(errors.firstName)}
            helperText={errors.firstName && errors.firstName.message}
            name="firstName"
            defaultValue={currentUser.firstName}
          />
        </Grid>
        <Grid
          item
          sm={12}
          className={`${classes.inputFieldContainer} ${errors.lastName &&
            classes.optionWithErrors}`}
        >
          <TextInput
            inputRef={register}
            id="MyProfile_input_lastName"
            required
            fullWidth
            margin="none"
            label="Apellidos"
            error={Boolean(errors.lastName)}
            helperText={errors.lastName && errors.lastName.message}
            name="lastName"
            defaultValue={currentUser.lastName}
          />
        </Grid>
        <Grid item className={classes.inputFieldContainer} sm={12}>
          <CompositeSelectInput
            inputId="MyProfile_input_identification"
            selectId="MyProfile_input_identificationType"
            options={IDENTIFICATION_OPTIONS}
            TextInputProps={{
              label: 'Identificación',
              name: 'identification',
              defaultValue: currentUser.identification || '',
              inputRef: register,
              fullWidth: true,
              margin: 'none'
            }}
            SelectInputProps={{
              name: 'identificationType',
              defaultValue: currentUser.identificationType || 'CC',
              control
            }}
            error={Boolean(errors.identification)}
            helperText={errors.identification && errors.identification.message}
          />
        </Grid>
        <Grid container justify="center" spacing={2}>
          <Grid item sm={5} xs={4} className={classes.inputFieldContainer}>
            <Controller
              as={
                <NumberInput
                  id="MyProfile_input_countryCode"
                  autoComplete="tel-country-code"
                  required
                  InputProps={{
                    startAdornment: (
                      <FlagColombia size={20} style={{ flexShrink: 0 }} />
                    )
                  }}
                  format="+###"
                  placeholder="57"
                  fullWidth
                  label="Prefijo"
                  error={Boolean(errors.phoneCountryCode)}
                  helperText={
                    errors.phoneCountryCode && errors.phoneCountryCode.message
                  }
                  margin="none"
                />
              }
              control={control}
              name="phoneCountryCode"
              defaultValue={currentUser.phoneCountryCode || '57'}
              onChangeName="onValueChange"
              onChange={values => {
                if (values[0]) {
                  return values[0].value;
                }
                return '';
              }}
            />
          </Grid>
          <Grid item sm={7} xs={8} className={classes.inputFieldContainer}>
            <Controller
              as={
                <NumberInput
                  id="MyProfile_input_phone"
                  autoComplete="tel-local"
                  required
                  fullWidth
                  margin="none"
                  label="Número celular"
                  error={Boolean(errors.phone) || showPhoneError}
                  helperText={
                    (errors.phone && errors.phone.message) ||
                    (showPhoneError && 'Valida tu número celular')
                  }
                />
              }
              control={control}
              name="phone"
              defaultValue={currentUser.phone || ''}
              onChangeName="onValueChange"
              onChange={values => {
                if (values[0]) {
                  return values[0].value;
                }
                return '';
              }}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.requestContainer}>
          <Grid item className={classes.fullWidth} sm={5}>
            <BaseButton
              color="primary"
              variant="contained"
              form="editProfileForm"
              onClick={handleSubmit(onSubmitUserData)}
              disabled={!formState.isValid || !ifDataHasChanged()}
              fullWidth
            >
              Guardar
            </BaseButton>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  requestContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start'
    },
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  optionWithErrors: {
    marginBottom: -17
  },
  inputFieldContainer: {
    width: '100%',
    marginBottom: theme.spacing(3)
  },
  sweetAlert: {
    marginBottom: 16
  },
  sweetAlertText: {
    fontSize: 12,
    color: '#333333'
  },
  phoneSweetAlert: {
    marginBottom: 16,
    cursor: 'pointer'
  },
  fullWidth: {
    width: '100%'
  }
}));

export default MyProfileUserInfo;
