import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';

const logoFlame = require('../../../../Assets/images/gases_logo_flame.png');
const logoEfigas = require('../../../../Assets/images/efg/efigas_logo_completo.svg');

const LogoHeaderEFG = props => {
  // * OTHER HOOKS
  const classes = useStyles();

  // * FUNCTIONS
  const screen = props.width;
  const renderLogo = () => {
    if (screen === 'xs' || screen === 'xm' || screen === 'sm') {
      return (
        <img src={logoFlame} alt={'flame'} className={classes.imageSmall} />
      );
    }

    return (
      <Fragment>
        <img
          src={logoEfigas}
          alt="logo_Efigas_portal"
          className={classes.logo}
        />
      </Fragment>
    );
  };

  return (
    <a href={'/'} className={classes.centerLogo}>
      {renderLogo()}
    </a>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: 'auto',
    justifyContent: 'flex-start'
  },
  imageSmall: {
    cursor: 'pointer',
    width: 40
  },
  logo: {
    cursor: 'pointer',
    width: '128.3px',
    height: 56
  },
  biglogo: {
    cursor: 'pointer',
    width: '128.3px',
    height: 56
  },
  imageText: {
    marginTop: theme.spacing(0.5),
    height: '42px',
    width: '180px'
  },
  imageFlame: {
    height: '50px',
    width: '40px'
  },
  centerLogo: {
    justifyContent: 'center',
    display: 'flex'
  }
}));

export default withWidth()(LogoHeaderEFG);
