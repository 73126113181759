import { makeStyles } from '@material-ui/styles';
import React from 'react';
import SidebarContainer from './Components/SidebarContainer';
import BaseDialog from '../../../../Components/Dialogs/BaseDialog';

const Sidebar = props => {
  const classes = useStyles();

  const {
    title = 'Título',
    itemsContent,
    detailsContent,
    actionsContent,
    isMobileSize = false,
    openDialog = false,
    onCloseDialog,
    sidebarDialogProps
  } = props;

  const sidebarContent = () => (
    <>
      {itemsContent && (
        <div className={classes.itemsContent}>{itemsContent}</div>
      )}
      <div>{detailsContent}</div>
      <div>{actionsContent}</div>
    </>
  );

  if (isMobileSize) {
    return (
      <>
        <BaseDialog
          open={openDialog}
          handleClose={onCloseDialog}
          title={title}
          content={sidebarContent}
          fullScreen={isMobileSize}
          contentStyle={classes.sidebarDialog}
          {...sidebarDialogProps}
        />
      </>
    );
  }

  return <SidebarContainer title={title}>{sidebarContent()}</SidebarContainer>;
};

const useStyles = makeStyles({
  sidebarDialog: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0
  },
  itemsContent: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden'
  }
});

export default Sidebar;
