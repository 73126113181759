import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import FaqGDC from './Faq.GDC';

const Faq = () => {
  switch (portal) {
    case Portal.Gascaribe:
      return <FaqGDC />;
    case Portal.Efigas:
      return <FaqGDC />;
    default:
      return <></>;
  }
};

export default Faq;
