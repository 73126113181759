/* eslint-disable complexity */
import React, {
  useContext,
  useCallback,
  useEffect,
  useState,
  useRef
} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Container,
  Grid,
  Typography,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormHelperText
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import moment from 'moment';

import FlagColombia from '../../../../../../Components/CustomIcons/Flags/FlagColombia';
import TextInput from '../../../../../../Components/Inputs/TextInput';
import CompositeSelectInput from '../../../../../../Components/Inputs/CompositeSelectInput';
import NumberInput from '../../../../../../Components/Inputs/NumberInput';
import PhoneVerificationDialog from '../../../../../../Components/Dialogs/PhoneVerificationDialog';
import ModalProgress from '../../../../../../Components/Progress/Modal/ModalProgress';
import Recaptcha from '../../../../../../Components/Captcha/Recaptcha';

import { UserContext } from '../../../../../../Contexts/UserContext';
import {
  SetCurrentStepIndexContext,
  StepperDataContext,
  StepperDataDispatchContext,
  CurrentStepIndexContext
} from '../../../../../../Contexts/StepperContext';

import {
  ValidateApplicantIdentificationAPI,
  ValidateIdentificationsAPI
} from '../../../../../../API/BrillaInsurances/BrillaInsurancesAPI';

import { extractErrorMessage } from '../../../../../../Utils/Errors/Errors';
import { isPrintableAscii } from '../../../../../../Utils/Misc/String';
import {
  IDENTIFICATION_OPTIONS,
  EXPEDITION_PLACES,
  InsurancePlan
} from '../../../../../../Enums/insurances';
import { config } from '../../../../../../Configs';

const PersonalDataStep = props => {
  const {
    formId,
    setCanSubmit,
    setGoBack,
    setCurrentStep: setVisualStepperIndex,
    setNextButtonText,
    setAlert,
    planId
  } = props;

  const classes = useStyles();

  // * CONTEXTS
  const currentStepIndex = useContext(CurrentStepIndexContext);
  const setCurrentStep = useContext(SetCurrentStepIndexContext);
  const setData = useContext(StepperDataDispatchContext);
  const stepperData = useContext(StepperDataContext);
  const currentUser = useContext(UserContext);

  // * STATE HOOKS
  const [loading, setLoading] = useState(false);
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const [
    openSecondVerificationDialog,
    setOpenSecondVerificationDialog
  ] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);

  // * REFS
  const recaptchaRef = useRef(null);
  const inputRef = useRef(null);

  // * GLOBAL VARS
  const isGoldPlanSelected = planId === InsurancePlan.Gold;
  const { beneficiaries } = stepperData;

  const secondInsuredSchema = yup.object({
    secondInsuredFirstName: yup
      .string()
      .max(150, 'Máximo 150 carácteres')
      .required('Ingresa los nombres'),
    secondInsuredLastName: yup
      .string()
      .max(150, 'Máximo 150 carácteres')
      .required('Ingresa los apellidos'),
    secondInsuredIdentificationType: yup
      .string()
      .oneOf(IDENTIFICATION_OPTIONS.map(idType => idType.value))
      .required('Elija un tipo valido'),
    secondInsuredIdentification: yup
      .number()
      .typeError('El número de identificación debe ser válido')
      .positive('El número de identificación debe ser válido')
      .required('Ingresa el número de identificación')
      .test('integer', 'El número de identificación debe ser válido', val => {
        return val && val % 1 === 0;
      })
      .test(
        'idPresentBeneficiaries',
        'El numero de identificación ya está presente en los beneficiarios',
        val => {
          return beneficiaries.length > 0
            ? !beneficiaries.some(s => s.identification === val.toString())
            : true;
        }
      )
      .notOneOf(
        [yup.ref('identification')],
        'Este número de identificación pertenece al primer asegurado'
      ),
    secondInsuredDateOfBirth: yup
      .date()
      .max(new Date(), 'Fecha no valida')
      .typeError('La fecha  debe ser válida')
      .required('Elija una fecha valida')
      .test('minAge', 'Debe ser mayor de edad', val => {
        return val && moment(val).isSameOrBefore(moment().subtract(18, 'y'));
      })
      .test('maxAge', 'Debe ser menor de 65 años', val => {
        return val && moment(val).isSameOrAfter(moment().subtract(65, 'y'));
      }),
    secondInsuredExpeditionDate: yup
      .date()
      .max(new Date(), 'Fecha no valida')
      .typeError('La fecha  debe ser válida')
      .required('Elija una fecha valida'),
    secondInsuredExpeditionPlace: yup
      .string()
      .required('Elija un lugar valido'),
    secondInsuredPhone: yup
      .number()
      .typeError('El número de teléfono debe ser válido')
      .positive('El número de teléfono debe ser válido')
      .test('integer', 'El número de teléfono debe ser válido', val => {
        return val && val % 1 === 0;
      })
      .test(
        'length',
        'El número de teléfono debe ser de 7 o 10 dígitos',
        val => {
          return (
            (val && val.toString().length === 7) ||
            (val && val.toString().length === 10)
          );
        }
      )
      .required('Ingresa el número de teléfono')
      .notOneOf(
        [yup.ref('phone')],
        'Este número de teléfono pertenece al primer asegurado'
      ),
    secondInsuredGender: yup.string().required('Elija un sexo'),
    secondInsuredEmail: yup
      .string()
      .trim()
      .lowercase()
      .email('Debes ingresar un correo válido')
      .required('Debes ingresar un correo')
      .test(
        'is-ascii',
        'Este campo no puede contener tildes ni caracteres especiales',
        isPrintableAscii
      )
  });

  const firstInsuredSchema = yup.object({
    firstName: yup
      .string()
      .max(150, 'Máximo 150 carácteres')
      .required('Ingresa los nombres'),
    lastName: yup
      .string()
      .max(150, 'Máximo 150 carácteres')
      .required('Ingresa los apellidos'),
    identificationType: yup
      .string()
      .oneOf(IDENTIFICATION_OPTIONS.map(idType => idType.value))
      .required('Elija un tipo valido'),
    identification: yup
      .number()
      .typeError('El número de identificación debe ser válido')
      .positive('El número de identificación debe ser válido')
      .test('integer', 'El número de identificación debe ser válido', val => {
        return val && val % 1 === 0;
      })
      .test(
        'idPresentBeneficiaries',
        'El numero de identificación ya está presente en los beneficiarios',
        val => {
          return beneficiaries.length > 0
            ? !beneficiaries.some(s => s.identification === val.toString())
            : true;
        }
      )
      .required('Ingresa el número de identificación'),
    dateOfBirth: yup
      .date()
      .max(new Date(), 'Fecha no valida')
      .typeError('La fecha  debe ser válida')
      .required('Elija una fecha valida')
      .test('minAge', 'Debe ser mayor de edad', val => {
        return val && moment(val).isSameOrBefore(moment().subtract(18, 'y'));
      })
      .test('maxAge', 'Debe ser menor de 65 años', val => {
        return val && moment(val).isSameOrAfter(moment().subtract(65, 'y'));
      }),
    expeditionDate: yup
      .date()
      .typeError('La fecha debe ser válida')
      .max(new Date(), 'Fecha no valida')
      .required('Elija una fecha valida')
      .when(['dateOfBirth'], (dateOfBirth, currentSchema, node) => {
        if (moment(node.value).isBefore(moment(dateOfBirth).add(18, 'y'))) {
          return yup
            .date()
            .min(moment(dateOfBirth).add(18, 'y'), 'Fecha no valida');
        }
      }),
    expeditionPlace: yup.string().required('Elija un lugar valido'),
    phone: yup
      .number()
      .typeError('El número de teléfono debe ser válido')
      .positive('El número de teléfono debe ser válido')
      .test('integer', 'El número de teléfono debe ser válido', val => {
        return val && val % 1 === 0;
      })
      .test(
        'length',
        'El número de teléfono debe ser de 7 o 10 dígitos',
        val => {
          return (
            (val && val.toString().length === 7) ||
            (val && val.toString().length === 10)
          );
        }
      )
      .required('Ingresa el número de teléfono'),
    gender: yup.string().required('Elija un sexo'),
    email: yup
      .string()
      .trim()
      .lowercase()
      .email('Debes ingresar un correo válido')
      .required('Debes ingresar un correo')
      .test(
        'is-ascii',
        'Este campo no puede contener tildes ni caracteres especiales',
        isPrintableAscii
      )
  });

  const insuredsSchema = isGoldPlanSelected
    ? firstInsuredSchema.concat(secondInsuredSchema)
    : firstInsuredSchema;

  const initialCurrentUserValues = currentUser
    ? {
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        identificationType: currentUser.identificationType,
        identification: currentUser.identification,
        phone: currentUser.phone,
        email: currentUser.email
      }
    : {};

  const initialFirstInsuredStepperValues = stepperData.insuredData
    ? {
        firstName: stepperData.insuredData.firstName,
        lastName: stepperData.insuredData.lastName,
        identificationType: stepperData.insuredData.identificationType,
        identification: stepperData.insuredData.identification,
        expeditionDate: moment(
          stepperData.insuredData.expeditionDate,
          'DD-MM-YYYY'
        ).format('YYYY-MM-DD'),
        expeditionPlace: stepperData.insuredData.expeditionPlace,
        phone: stepperData.insuredData.phone,
        dateOfBirth: moment(
          stepperData.insuredData.dateOfBirth,
          'DD-MM-YYYY'
        ).format('YYYY-MM-DD'),
        gender: stepperData.insuredData.gender,
        email: stepperData.insuredData.email
      }
    : {};

  const initualSecondInsuredStepperValues =
    isGoldPlanSelected && stepperData.insuredData
      ? {
          secondInsuredFirstName:
            stepperData.insuredData.secondInsuredFirstName,
          secondInsuredLastName: stepperData.insuredData.secondInsuredLastName,
          secondInsuredIdentificationType:
            stepperData.insuredData.secondInsuredIdentificationType,
          secondInsuredIdentification:
            stepperData.insuredData.secondInsuredIdentification,
          secondInsuredExpeditionDate: moment(
            stepperData.insuredData.secondInsuredExpeditionDate,
            'DD-MM-YYYY'
          ).format('YYYY-MM-DD'),
          secondInsuredExpeditionPlace:
            stepperData.insuredData.secondInsuredExpeditionPlace,
          secondInsuredPhone: stepperData.insuredData.secondInsuredPhone,
          secondInsuredDateOfBirth: moment(
            stepperData.insuredData.secondInsuredDateOfBirth,
            'DD-MM-YYYY'
          ).format('YYYY-MM-DD'),
          secondInsuredGender: stepperData.insuredData.secondInsuredGender,
          secondInsuredEmail: stepperData.insuredData.secondInsuredEmail
        }
      : {};

  const initialValues = {
    ...initialCurrentUserValues,
    ...initialFirstInsuredStepperValues,
    ...initualSecondInsuredStepperValues
  };

  const { register, getValues, errors, control, handleSubmit } = useForm({
    validationSchema: insuredsSchema,
    submitFocusError: true,
    defaultValues: initialValues
  });

  const validateIdentification = useCallback(
    async values => {
      const captchaValue = captcha;
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
        setCaptcha(null);
      }
      const insuredIdentification = {
        identification: values.identification,
        identificationType: values.identificationType,
        expeditionDate: values.expeditionDate
      };

      const insuredIdentifications = [insuredIdentification];

      if (isGoldPlanSelected) {
        const secondInsuredIdentification = {
          identification: values.secondInsuredIdentification,
          identificationType: values.secondInsuredIdentificationType,
          expeditionDate: values.secondInsuredExpeditionDate
        };
        insuredIdentifications.push(secondInsuredIdentification);
      }

      const validationResponse = await ValidateIdentificationsAPI(
        currentUser.token,
        insuredIdentifications,
        captchaValue
      );

      if (!validationResponse.success) {
        setAlert({
          type: 'error',
          message: extractErrorMessage(validationResponse).message
        });
        setLoading(false);
        setCanSubmit(true);
        return false;
      }

      const aniDataResponse = validationResponse.data.data;

      const realNames = aniDataResponse.map(({ info }) => ({
        firstName: info.firstName,
        middleName: info.middleName,
        lastName: info.lastName,
        secondLastName: info.secondLastName
      }));

      setData(data => ({ ...data, realNames }));

      const response = await ValidateApplicantIdentificationAPI(
        currentUser.token,
        values.identificationType,
        values.identification.toString()
      );
      if (!response.success) {
        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
        setLoading(false);
        setCanSubmit(true);
        return false;
      }
      return true;
    },
    [currentUser, setAlert, setCanSubmit, isGoldPlanSelected, captcha, setData]
  );

  const onSuccessUserData = useCallback(
    values => {
      setData(data => ({
        ...data,
        validatedPhone: true,
        insuredData: { ...values }
      }));
      setCurrentStep(step => step + 1);
    },
    [setData, setCurrentStep]
  );

  const openPhoneVerification = async values => {
    setLoading(true);
    setCanSubmit(false);
    const {
      phone: currentPhone,
      phoneCountryCode: currentCountryCode
    } = values;
    const alreadyValidatedPhone = stepperData.validatedPhone;
    const previousPhone =
      stepperData.insuredData && stepperData.insuredData.phone.toString();
    const previousCountryCode =
      stepperData.insuredData &&
      stepperData.insuredData.phoneCountryCode.toString();
    const phoneHasChanged = previousPhone !== currentPhone.toString();
    const countryCodeHasChanged =
      previousCountryCode !== currentCountryCode.toString();
    const validIdentification = await validateIdentification(getValues());
    if (
      !phoneHasChanged &&
      !countryCodeHasChanged &&
      validIdentification &&
      alreadyValidatedPhone &&
      !isGoldPlanSelected
    ) {
      onSuccessUserData(values);
      return;
    }
    if (validIdentification) {
      setOpenVerificationDialog(true);
      setLoading(false);
      setCanSubmit(true);
    }
  };

  const openSecondPhoneVerification = () => {
    setLoading(true);
    setCanSubmit(false);
    setOpenSecondVerificationDialog(true);
    setLoading(false);
    setCanSubmit(true);
  };

  const onBackward = useCallback(() => {
    const values = getValues();
    const {
      phone: currentPhone,
      phoneCountryCode: currentCountryCode
    } = values;
    const dateOfBirthFormatted = moment(values.dateOfBirth).format(
      'DD/MM/YYYY'
    );
    const expeditionDateFormatted = moment(values.expeditionDate).format(
      'DD/MM/YYYY'
    );
    const secondInsuredDateOfBirthFormatted = moment(
      values.secondInsuredDateOfBirth
    ).format('DD/MM/YYYY');
    const secondInsuredExpeditionDateFormatted = moment(
      values.secondInsuredExpeditionDate
    ).format('DD/MM/YYYY');
    const alreadyValidatedPhone = stepperData.validatedPhone;
    const previousPhone =
      stepperData.insuredData && stepperData.insuredData.phone.toString();
    const previousCountryCode =
      stepperData.insuredData &&
      stepperData.insuredData.phoneCountryCode.toString();
    const phoneHasChanged = previousPhone !== currentPhone.toString();
    const countryCodeHasChanged =
      previousCountryCode !== currentCountryCode.toString();
    setData(data => ({
      ...data,
      validatedPhone:
        alreadyValidatedPhone && !(phoneHasChanged || countryCodeHasChanged),
      insuredData: {
        ...values,
        dateOfBirth: dateOfBirthFormatted,
        expeditionDate: expeditionDateFormatted,
        ...(isGoldPlanSelected && {
          secondInsuredDateOfBirth: secondInsuredDateOfBirthFormatted,
          secondInsuredExpeditionDate: secondInsuredExpeditionDateFormatted
        })
      }
    }));
    setCurrentStep(currentStep => currentStep - 1);
  }, [setCurrentStep, setData, getValues, stepperData, isGoldPlanSelected]);

  useEffect(() => {
    if (!captcha || loadingCaptcha) {
      setCanSubmit(false);
    } else {
      setCanSubmit(true);
    }
    setNextButtonText('Siguiente');
    setVisualStepperIndex(currentStepIndex);
    setGoBack({
      action: onBackward
    });
  }, [
    setGoBack,
    onBackward,
    setVisualStepperIndex,
    currentStepIndex,
    setNextButtonText,
    setCanSubmit,
    captcha,
    loadingCaptcha
  ]);

  const onCaptchaResolved = useCallback(
    token => {
      setCaptcha(token);
    },
    [setCaptcha]
  );

  const onCaptchaExpired = useCallback(() => {
    setCaptcha(null);
  }, [setCaptcha]);

  const onCaptchaLoaded = useCallback(() => {
    setLoadingCaptcha(false);
  }, [setLoadingCaptcha]);

  return (
    <Container className={classes.root}>
      {openVerificationDialog && (
        <PhoneVerificationDialog
          open={openVerificationDialog}
          setDialog={setOpenVerificationDialog}
          setAlert={setAlert}
          title={
            isGoldPlanSelected
              ? 'Validar número celular del primer asegurado'
              : 'Validar número celular'
          }
          successCallback={
            isGoldPlanSelected
              ? () => openSecondPhoneVerification()
              : () => onSuccessUserData(getValues())
          }
          formPhone={getValues().phone}
          formPhoneCountryCode={getValues().phoneCountryCode}
        />
      )}
      {openSecondVerificationDialog && (
        <PhoneVerificationDialog
          open={openSecondVerificationDialog}
          setDialog={setOpenSecondVerificationDialog}
          setAlert={setAlert}
          title={'Validar número celular del segundo asegurado'}
          successCallback={() => onSuccessUserData(getValues())}
          formPhone={getValues().secondInsuredPhone}
          formPhoneCountryCode={getValues().secondInsuredPhoneCountryCode}
        />
      )}
      {loading && <ModalProgress message="Validando" />}
      <Typography className={classes.title}>
        {isGoldPlanSelected
          ? 'Agrega los datos personales de los asegurados'
          : 'Agrega tus datos personales'}
      </Typography>
      <form id={formId} onSubmit={handleSubmit(openPhoneVerification)}>
        <Grid container direction="column">
          {isGoldPlanSelected && (
            <Grid item>
              <Typography className={classes.subtitle}>
                Primer Asegurado
              </Typography>
            </Grid>
          )}
          <Grid container item wrap="nowrap" className={classes.rowContainer}>
            <Grid item xs={12} sm={6}>
              <TextInput
                label="Nombres"
                name="firstName"
                inputRef={register}
                fullWidth={true}
                error={Boolean(errors.firstName)}
                helperText={errors.firstName && errors.firstName.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInput
                label="Apellidos"
                name="lastName"
                inputRef={register}
                fullWidth={true}
                error={Boolean(errors.lastName)}
                helperText={errors.lastName && errors.lastName.message}
              />
            </Grid>
          </Grid>
          <Grid container item wrap="nowrap" className={classes.rowContainer}>
            <Grid item xs={12} sm={6}>
              <CompositeSelectInput
                label="Identificación"
                options={IDENTIFICATION_OPTIONS}
                TextInputProps={{
                  label: 'Identificación',
                  name: 'identification',
                  defaultValue: '',
                  inputRef: register,
                  fullWidth: true
                }}
                SelectInputProps={{
                  name: 'identificationType',
                  defaultValue: 'CC',
                  inputRef: register,
                  control
                }}
                error={Boolean(errors.identification)}
                helperText={
                  errors.identification && errors.identification.message
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container className={classes.phoneContainer}>
                <Grid item xs={5}>
                  <Controller
                    as={
                      <NumberInput
                        id="MyProfile_input_countryCode"
                        autoComplete="tel-country-code"
                        required
                        InputProps={{
                          startAdornment: (
                            <FlagColombia size={20} style={{ flexShrink: 0 }} />
                          )
                        }}
                        format="+###"
                        placeholder="57"
                        fullWidth
                        label="Prefijo"
                        error={Boolean(errors.phoneCountryCode)}
                        helperText={
                          errors.phoneCountryCode &&
                          errors.phoneCountryCode.message
                        }
                        margin="none"
                      />
                    }
                    control={control}
                    name="phoneCountryCode"
                    defaultValue={'57'}
                    onChangeName="onValueChange"
                    onChange={values => {
                      if (values[0]) {
                        return values[0].value;
                      }
                      return '';
                    }}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Controller
                    as={
                      <NumberInput
                        id="MyProfile_input_phone"
                        autoComplete="tel-local"
                        fullWidth
                        margin="none"
                        label="Teléfono"
                        error={Boolean(errors.phone)}
                        helperText={errors.phone && errors.phone.message}
                      />
                    }
                    control={control}
                    name="phone"
                    onChangeName="onValueChange"
                    onChange={values => {
                      if (values[0]) {
                        return values[0].value;
                      }
                      return '';
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item wrap="nowrap" className={classes.rowContainer}>
            <Grid item xs={12} sm={6}>
              <TextInput
                label="Fecha de expedición"
                name="expeditionDate"
                type="date"
                inputRef={register}
                fullWidth={true}
                InputLabelProps={{ shrink: true }}
                error={Boolean(errors.expeditionDate)}
                helperText={
                  errors.expeditionDate && errors.expeditionDate.message
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                rules={{ required: true }}
                name="expeditionPlace"
                options={EXPEDITION_PLACES}
                onChange={values => {
                  if (values[1]) {
                    return values[1].value;
                  }
                  return '';
                }}
                as={
                  <Autocomplete
                    autoHighlight
                    getOptionLabel={option => option.label}
                    getOptionSelected={(option, value) =>
                      option.value === value && value.value
                    }
                    inputRef={register}
                    inputValue={getValues().expeditionPlace}
                    renderInput={params => (
                      <TextInput
                        {...params}
                        label="Lugar de expedición"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password'
                        }}
                        error={Boolean(errors.expeditionPlace)}
                        helperText={
                          errors.expeditionPlace &&
                          errors.expeditionPlace.message
                        }
                      />
                    )}
                  />
                }
              />
            </Grid>
          </Grid>
          <Grid container item wrap="nowrap" className={classes.rowContainer}>
            <Grid item xs={12} sm={6}>
              <TextInput
                label="Fecha de nacimiento"
                name="dateOfBirth"
                type="date"
                inputRef={register}
                fullWidth={true}
                InputLabelProps={{ shrink: true }}
                error={Boolean(errors.dateOfBirth)}
                helperText={errors.dateOfBirth && errors.dateOfBirth.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl sm={12} error={Boolean(errors.gender)}>
                <FormLabel>Sexo</FormLabel>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name="gender"
                  as={
                    <RadioGroup row inputRef={register}>
                      <FormControlLabel
                        value="M"
                        control={<Radio color="primary" />}
                        label="Masculino"
                      />
                      <FormControlLabel
                        value="F"
                        control={<Radio color="primary" />}
                        label="Femenino"
                      />
                    </RadioGroup>
                  }
                />
                <FormHelperText>
                  {errors.gender && errors.gender.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container item wrap="nowrap" className={classes.rowContainer}>
            <Grid item xs={12} sm={6}>
              <TextInput
                label="Correo electrónico"
                name="email"
                inputRef={register}
                fullWidth={true}
                InputLabelProps={{ shrink: true }}
                error={Boolean(errors.email)}
                helperText={errors.email && errors.email.message}
              />
            </Grid>
            {!isGoldPlanSelected && (
              <Grid item xs={12} sm={6} innerRef={inputRef}>
                <Recaptcha
                  captchaRef={recaptchaRef}
                  referenceEl={inputRef}
                  heightScale={0.75}
                  locale={'es'}
                  sitekey={config.recaptcha.siteKey}
                  onResolved={onCaptchaResolved}
                  onExpired={onCaptchaExpired}
                  onLoaded={onCaptchaLoaded}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {isGoldPlanSelected && (
          <Grid
            container
            direction="column"
            className={classes.secondInsuredForm}
          >
            <Grid item>
              <Typography className={classes.subtitle}>
                Segundo Asegurado
              </Typography>
            </Grid>
            <Grid container item wrap="nowrap" className={classes.rowContainer}>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label="Nombres"
                  name="secondInsuredFirstName"
                  inputRef={register}
                  fullWidth={true}
                  error={Boolean(errors.secondInsuredFirstName)}
                  helperText={
                    errors.secondInsuredFirstName &&
                    errors.secondInsuredFirstName.message
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label="Apellidos"
                  name="secondInsuredLastName"
                  inputRef={register}
                  fullWidth={true}
                  error={Boolean(errors.secondInsuredLastName)}
                  helperText={
                    errors.secondInsuredLastName &&
                    errors.secondInsuredLastName.message
                  }
                />
              </Grid>
            </Grid>
            <Grid container item wrap="nowrap" className={classes.rowContainer}>
              <Grid item xs={12} sm={6}>
                <CompositeSelectInput
                  label="Identificación"
                  options={IDENTIFICATION_OPTIONS}
                  TextInputProps={{
                    label: 'Identificación',
                    name: 'secondInsuredIdentification',
                    defaultValue: '',
                    inputRef: register,
                    fullWidth: true
                  }}
                  SelectInputProps={{
                    name: 'secondInsuredIdentificationType',
                    defaultValue: 'CC',
                    inputRef: register,
                    control
                  }}
                  error={Boolean(errors.secondInsuredIdentification)}
                  helperText={
                    errors.secondInsuredIdentification &&
                    errors.secondInsuredIdentification.message
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container className={classes.phoneContainer}>
                  <Grid item xs={5}>
                    <Controller
                      as={
                        <NumberInput
                          id="MyProfile_input_countryCode"
                          autoComplete="tel-country-code"
                          required
                          InputProps={{
                            startAdornment: (
                              <FlagColombia
                                size={20}
                                style={{ flexShrink: 0 }}
                              />
                            )
                          }}
                          format="+###"
                          placeholder="57"
                          fullWidth
                          label="Prefijo"
                          error={Boolean(errors.secondInsuredPhoneCountryCode)}
                          helperText={
                            errors.secondInsuredPhoneCountryCode &&
                            errors.secondInsuredPhoneCountryCode.message
                          }
                          margin="none"
                        />
                      }
                      control={control}
                      name="secondInsuredPhoneCountryCode"
                      defaultValue={'57'}
                      onChangeName="onValueChange"
                      onChange={values => {
                        if (values[0]) {
                          return values[0].value;
                        }
                        return '';
                      }}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <Controller
                      as={
                        <NumberInput
                          id="MyProfile_input_phone"
                          autoComplete="tel-local"
                          fullWidth
                          margin="none"
                          label="Teléfono"
                          error={Boolean(errors.secondInsuredPhone)}
                          helperText={
                            errors.secondInsuredPhone &&
                            errors.secondInsuredPhone.message
                          }
                        />
                      }
                      control={control}
                      name="secondInsuredPhone"
                      onChangeName="onValueChange"
                      onChange={values => {
                        if (values[0]) {
                          return values[0].value;
                        }
                        return '';
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item wrap="nowrap" className={classes.rowContainer}>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label="Fecha de expedición"
                  name="secondInsuredExpeditionDate"
                  type="date"
                  inputRef={register}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(errors.secondInsuredExpeditionDate)}
                  helperText={
                    errors.secondInsuredExpeditionDate &&
                    errors.secondInsuredExpeditionDate.message
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="secondInsuredExpeditionPlace"
                  options={EXPEDITION_PLACES}
                  onChange={values => {
                    if (values[1]) {
                      return values[1].value;
                    }
                    return '';
                  }}
                  as={
                    <Autocomplete
                      autoHighlight
                      getOptionLabel={option => option.label}
                      getOptionSelected={(option, value) =>
                        option.value === value && value.value
                      }
                      inputRef={register}
                      inputValue={getValues().secondInsuredExpeditionPlace}
                      renderInput={params => (
                        <TextInput
                          {...params}
                          label="Lugar de expedición"
                          variant="outlined"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password'
                          }}
                          error={Boolean(errors.secondInsuredExpeditionPlace)}
                          helperText={
                            errors.secondInsuredExpeditionPlace &&
                            errors.secondInsuredExpeditionPlace.message
                          }
                        />
                      )}
                    />
                  }
                />
              </Grid>
            </Grid>
            <Grid container item wrap="nowrap" className={classes.rowContainer}>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label="Fecha de nacimiento"
                  name="secondInsuredDateOfBirth"
                  type="date"
                  inputRef={register}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(errors.secondInsuredDateOfBirth)}
                  helperText={
                    errors.secondInsuredDateOfBirth &&
                    errors.secondInsuredDateOfBirth.message
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  sm={12}
                  error={Boolean(errors.secondInsuredGender)}
                >
                  <FormLabel>Sexo</FormLabel>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name="secondInsuredGender"
                    as={
                      <RadioGroup row inputRef={register}>
                        <FormControlLabel
                          value="M"
                          control={<Radio color="primary" />}
                          label="Masculino"
                        />
                        <FormControlLabel
                          value="F"
                          control={<Radio color="primary" />}
                          label="Femenino"
                        />
                      </RadioGroup>
                    }
                  />
                  <FormHelperText>
                    {errors.secondInsuredGender &&
                      errors.secondInsuredGender.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item wrap="nowrap" className={classes.rowContainer}>
              <Grid item xs={12} sm={6}>
                <TextInput
                  label="Correo electrónico"
                  name="secondInsuredEmail"
                  inputRef={register}
                  fullWidth={true}
                  error={Boolean(errors.secondInsuredEmail)}
                  helperText={
                    errors.secondInsuredEmail &&
                    errors.secondInsuredEmail.message
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} innerRef={inputRef}>
                <Recaptcha
                  captchaRef={recaptchaRef}
                  referenceEl={inputRef}
                  heightScale={0.75}
                  locale={'es'}
                  sitekey={config.recaptcha.siteKey}
                  onResolved={onCaptchaResolved}
                  onExpired={onCaptchaExpired}
                  onLoaded={onCaptchaLoaded}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </form>
    </Container>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    [theme.breakpoints.down(713)]: {
      width: '100%'
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      '& > *:last-child': {
        marginBottom: theme.spacing(5)
      }
    }
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.2
  },
  rowContainer: {
    paddingBottom: theme.spacing(1),
    '& > *:first-child': {
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'column',
      '& > *:first-child': {
        paddingRight: 0
      }
    }
  },
  phoneContainer: {
    '& > *:first-child': {
      paddingRight: theme.spacing(2)
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingTop: theme.spacing(2)
    }
  },
  secondInsuredForm: {
    paddingBottom: theme.spacing(5)
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.greyDark,
    margin: theme.spacing(1, 0)
  }
}));

export default PersonalDataStep;
