import React, { useState } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import _isEmpty from 'lodash/isEmpty';

import BackIcon from '../../../Components/CustomIcons/BackIcon';
import Sidebar from './Sidebar/Sidebar';

import { numberWithDots } from '../../../Utils/Format/MoneyFormat';
import PlanDetailsDialog from '../Refinancing/PlanDetailsDialog';
import SimpleDivider from '../../../Components/Dividers/SimpleDivider';
import ProductItem from './ProductItem';

const CurrentRefinancingSidebar = ({
  showPaymentPlan = false,
  paymentPlan,
  errors,
  isMobileSize,
  openDialog,
  onCloseDialog,
  punishedProductIds,
  sidebarDialogProps
}) => {
  const classes = useStyles();

  // * STATE HOOKS
  const [openDetails, setOpenDetails] = useState(false);
  const [currentProductPlan, setCurrentProduct] = useState(
    paymentPlan.products[0]
  );

  // * FUNCTIONS
  const handleOpenDetails = () => {
    setOpenDetails(true);
  };

  // * GLOBAL VARS
  const productsData = [currentProductPlan].map(
    ({ productId, monthlyInterestRate, financingValue }) => {
      return {
        interestRate: monthlyInterestRate,
        billings:
          (paymentPlan &&
            paymentPlan.numberOfInstallments &&
            paymentPlan.numberOfInstallments[`p${productId}`]) ||
          0,
        totalFinancingValue:
          financingValue -
            (paymentPlan.initialInstallments || {})[
              `p${currentProductPlan.productId}`
            ] || 0
      };
    }
  );

  const productOptions = paymentPlan.products.map(product => {
    return {
      label: `${product.productTypeDescription} · ${product.productId}`,
      value: product.productId
    };
  });

  const planDetailsIsDisabled =
    !paymentPlan.numberOfInstallments ||
    Object.values(paymentPlan.numberOfInstallments).some(ins => ins <= 0) ||
    Boolean(errors.numberOfInstallments);

  const handleSelectedProduct = event => {
    const productId = event.target.value;
    const newCurrentProduct = paymentPlan.products.filter(
      product => product.productId === productId
    )[0];
    setCurrentProduct(newCurrentProduct);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  const renderItemList = () => {
    return (
      <div className={classes.itemsContainer}>
        {paymentPlan.products &&
          paymentPlan.products.length > 0 &&
          paymentPlan.products.map((product, idx) => {
            const isPunished = punishedProductIds.includes(product.productId);
            return <ProductItem punished={isPunished} key={idx} {...product} />;
          })}
      </div>
    );
  };

  const renderDetails = () => {
    return (
      <div className={classes.paymentPlanContainer}>
        <PlanDetailsDialog
          open={openDetails}
          isMobileSize={isMobileSize}
          handleClose={handleCloseDetails}
          productsData={productsData}
          currentProductId={currentProductPlan.productId}
          productOptions={productOptions}
          handleProducts={handleSelectedProduct}
          billings={productsData[0].billings}
          multipleProducts
        />
        <Grid container item direction="column" justify="flex-end">
          <Grid item className={classes.paymentPlanDataMargin}>
            <Typography className={classes.label}>Saldo total</Typography>
            <Typography className={classes.data}>
              {numberWithDots(paymentPlan.totalProducts)}
            </Typography>
          </Grid>
          <Grid item className={classes.paymentPlanDataMargin}>
            <Typography className={classes.label}>
              Descuento por mora
            </Typography>
            <Typography className={clsx(classes.data, classes.redText)}>
              -{numberWithDots(paymentPlan.discountValue)}
            </Typography>
          </Grid>
          <Grid item className={classes.paymentPlanDataMargin}>
            <Typography className={classes.label}>Cuota inicial</Typography>
            <Typography className={classes.data}>
              {numberWithDots(paymentPlan.initialInstallmentValue)}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justify="flex-end"
            alignItems="center"
            className={classes.paymentPlanDataMargin}
          >
            <Grid item>
              <Button
                color="primary"
                variant="text"
                size="small"
                className={classes.planDetailsButton}
                onClick={handleOpenDetails}
                disabled={planDetailsIsDisabled}
              >
                Ver plan de pagos
                <BackIcon size={14} className={classes.iconRightStyle} />
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Typography className={classes.footNote}>
              * La tasa de interés es variable y definida mensualmente por la
              Superfinanciera
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div
        className={clsx(classes.bottomContainer, classes.alignRight, {
          [classes.mobileBottomContainer]: showPaymentPlan && isMobileSize
        })}
      >
        {isMobileSize && showPaymentPlan && (
          <SimpleDivider withoutMargin classes={classes.divider} />
        )}
        <Typography className={classes.footerLabel}>
          Total {showPaymentPlan ? 'a financiar' : 'seleccionado'}
        </Typography>
        <Typography className={classes.footerTotal}>
          {_isEmpty(errors)
            ? numberWithDots(
                showPaymentPlan
                  ? paymentPlan.totalProducts -
                      paymentPlan.discountValue -
                      paymentPlan.initialInstallmentValue
                  : paymentPlan.totalProducts
              )
            : '-'}
        </Typography>
      </div>
    );
  };

  return (
    <Sidebar
      title="Items seleccionados"
      isMobileSize={isMobileSize}
      itemsContent={renderItemList()}
      detailsContent={showPaymentPlan && renderDetails()}
      actionsContent={renderBottom()}
      openDialog={openDialog}
      onCloseDialog={onCloseDialog}
      sidebarDialogProps={sidebarDialogProps}
    />
  );
};

const useStyles = makeStyles(theme => ({
  alignRight: {
    textAlign: 'right'
  },
  label: {
    fontSize: 10,
    fontWeight: 500,
    color: theme.palette.text.greyDark,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: 11
    }
  },
  footerLabel: {
    fontSize: 11,
    padding: theme.spacing(0.5, 2, 0),
    color: theme.palette.text.greyDark,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: 12
    }
  },
  footerTotal: {
    fontSize: 14,
    padding: theme.spacing(0, 2, 2),
    fontWeight: 600,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: 20
    }
  },
  data: {
    fontSize: 11,
    fontWeight: 600,
    lineHeight: 1.2,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: 12
    }
  },
  paymentPlanContainer: {
    margin: theme.spacing(0, 2, 2),
    paddingTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.background.border}`,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      borderStyle: 'none',
      padding: theme.spacing(2),
      margin: 0,
      boxShadow: '0px -4px 2px #0000000D',
      borderRadius: '8px 8px 0px 0px'
    }
  },
  paymentPlanDataMargin: {
    marginTop: theme.spacing(1)
  },
  divider: {
    margin: theme.spacing(0.5, 0)
  },
  itemsContainer: {
    flexGrow: 1,
    margin: theme.spacing(0, 2),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      margin: 0
    }
  },
  planDetailsButton: {
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.primary.main,
    textTransform: 'unset'
  },
  iconRightStyle: {
    transform: 'rotate(180deg)',
    fill: 'currentColor',
    marginLeft: theme.spacing(0.5)
  },
  mobileBottomContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      boxShadow: 'none'
    }
  },
  footNote: {
    fontSize: 10,
    lineHeight: 1.2,
    color: theme.palette.text.greyDark
  }
}));

export default CurrentRefinancingSidebar;
