import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import SimpleDivider from '../../Components/Dividers/SimpleDivider';

const SkeletonQuotaDetail = () => {
  // * STATE HOOKS

  // * OTHER HOOKS
  const classes = useStyles();

  // * FUNCTIONS

  return (
    <div className={classes.noQuotaDetailContainer}>
      <div className={classes.noQuotaTitleContainer}>
        <Skeleton className={classes.noQuotaTitle} />
        <SimpleDivider withoutMargin classes={classes.mobileDivider} />
        <Skeleton className={classes.noQuotaButton} />
      </div>
      <SimpleDivider withoutMargin classes={classes.preReasonsDivider} />
      <Skeleton width="100%" />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  preReasonsDivider: {
    marginBottom: '4.7%',
    marginTop: '4.7%'
  },
  noQuotaDetailContainer: {
    display: 'flex',
    marginTop: '9%',
    flexDirection: 'column',
    paddingBottom: '9%',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      alignItems: 'flex-start'
    }
  },
  noQuotaTitleContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    marginRight: '8%',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start'
    }
  },
  mobileDivider: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  noQuotaTitle: {
    fontSize: 20,
    width: '35%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  noQuotaButton: {
    fontSize: 40,
    width: '60%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  button: {
    alignSelf: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'center',
      width: '100%'
    }
  }
}));

export default SkeletonQuotaDetail;
