import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { SlideUpTransition } from '../../Transitions/Transitions';
import BaseDialog from '../BaseDialog';

const CouponHelp = require('../../../Assets/images/gdg/invoice_coupon_help.jpg');
const ContractHelp = require('../../../Assets/images/gdg/invoice_subscription_help.png');

function InvoiceHelpDialogGDG(props) {
  const { open = true, onClose, type, title = 'Título' } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState(true);

  const onImageLoaded = useCallback(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
  }, [type]);

  const renderContent = () => {
    return (
      <Fragment>
        <img
          className={classes.image}
          src={type === 'contract' ? ContractHelp : CouponHelp}
          alt={`${type} help`}
          onLoad={onImageLoaded}
        />
      </Fragment>
    );
  };

  const isMobileSize = isWidthDown('xs', props.width);
  return (
    <BaseDialog
      open={open}
      loading={loading}
      handleClose={onClose}
      title={title}
      actions={() => null}
      content={renderContent}
      fullScreen={isMobileSize}
      TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
    />
  );
}

const useStyles = makeStyles(theme => ({
  image: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 536,
      height: 236
    }
  }
}));

export default withWidth()(InvoiceHelpDialogGDG);
