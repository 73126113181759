const IDENTIFICATION_TYPES = {
  CC: 'CC',
  PP: 'PP',
  CE: 'CE'
};

export const FORM_ID = 'Deferred_payment_form';

export const DEFERRED_PAYMENT_STEPS = [
  {
    label: 'Validar datos personales'
  },
  {
    label: 'Acuerdo'
  },
  {
    label: 'Resumen'
  }
];

export const IDENTIFICATION_OPTIONS = [
  { label: 'CC', value: IDENTIFICATION_TYPES.CC },
  { label: 'PP', value: IDENTIFICATION_TYPES.PP },
  { label: 'CE', value: IDENTIFICATION_TYPES.CE }
];
