// this is to acknowledge that the user indeed finish
// its session on iOS app
export const logoutIOSSession = ({ isAccountDeleted }) => {
  if (window.ReactNativeWebView) {
    const data = { action: 'LOGOUT', payload: { isAccountDeleted } };
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  }
};

// this is to trigger the file download on
// the iOS app
export const downloadPdfOnIOS = (base64, name) => {
  if (window.ReactNativeWebView) {
    const data = JSON.stringify({
      action: 'DOWNLOAD',
      payload: {
        base64Str: base64,
        filename: name
      }
    });
    window.ReactNativeWebView.postMessage(data);
  }
};

// this is to update token on iOS app
export const updateIOSToken = (token, appToken) => {
  if (window.ReactNativeWebView) {
    const data = {
      action: 'TOKEN_UPDATE',
      payload: {
        token: appToken,
        tokenWeb: token
      }
    };
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  }
};

// function to trigger back to main menu action
export const backToIOSWelcome = () => {
  if (window.ReactNativeWebView) {
    const data = { action: 'MENU_BACK' };
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  }
};
