import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import CurrentDebtSummaryGDC from './CurrentDebtSummary.GDC';

const CurrentDebtSummary = () => {
  switch (portal) {
    case Portal.Gascaribe:
      return <CurrentDebtSummaryGDC />;
    default:
      return <></>;
  }
};

export default CurrentDebtSummary;
