import { joinNames } from '../Utils/Format/Names';

export const InvoicePeriod = {
  Current: 0,
  Previous: 1
};

export const SendingMethod = {
  WhatsApp: 'whatsapp',
  Email: 'email',
  SMS: 'sms'
};

export const SendingMethodText = {
  [SendingMethod.Email]: 'correo electrónico',
  [SendingMethod.WhatsApp]: 'WhatsApp',
  [SendingMethod.SMS]: 'SMS'
};

export const getSendingMethodText = (
  email = false,
  whatsApp = false,
  sms = false
) => {
  const methodTexts = [
    email && SendingMethodText[SendingMethod.Email],
    whatsApp && SendingMethodText[SendingMethod.WhatsApp],
    sms && SendingMethodText[SendingMethod.SMS]
  ].filter(Boolean);

  return joinNames(methodTexts, ', ', ' y ');
};

export const DigitalInvoiceParam = 'fd';

export const DigitalInvoiceBannerDateKey = 'fdBannerDate';

export const InvoiceHelpType = {
  Contract: 'contract',
  Coupon: 'coupon',
  Subrogation: 'subrogation',
  Invoice: 'invoice'
};
