import React, { useEffect, useCallback, useContext } from 'react';
import axios from 'axios';
import clsx from 'clsx';
import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import CalendarIcon from '../../../../../../Components/CustomIcons/CalendarIcon';
import SimpleDivider from '../../../../../../Components/Dividers/SimpleDivider';
import ButtonWithIcon from '../../../../../../Components/Buttons/ButtonWithIcon';
import CircleMinus from '../../../../../../Components/CustomIcons/IcCircleMinus';
import ModalProgress from '../../../../../../Components/Progress/Modal/ModalProgress';

import { moneyWithDots } from '../../../../../../Utils/Format/MoneyFormat';
import { GetCouponAPI } from '../../../../../../API/Coupons/CouponsAPI';
import { GetInvoicesAPI } from '../../../../../../API/Invoices/InvoicesAPI';

import { AlertsDispatchContext } from '../../../../../../Contexts/AlertsContext';

import { ALERT_TYPE } from '../../../../../../Components/Alerts/alert_enums';
import { CARD_STATUS, TRANSACTION_CARD_TYPES } from '../../../../payment_enums';
import { updateItem, updatePayment } from '../../../../helpers/helpers';
import { Company } from '../../../../../../Configs/general';

const PaymentCardGDC = props => {
  const {
    generationDate,
    contractCity,
    contractAddress,
    contractId,
    couponId,
    expirationDate,
    couponValue,
    cardLoading,
    setCardPayloads,
    setPayments,
    transactionType,
    captchaValue: captcha,
    cardError,
    status,
    authToken
  } = props;

  const classes = useStyles();

  const setAlert = useContext(AlertsDispatchContext);

  const handleRemoveContract = useCallback(() => {
    const isContract = transactionType === 'contract';
    setCardPayloads(currentCards => {
      let filteredCards = [];
      if (status === CARD_STATUS.ERROR) {
        filteredCards = currentCards[status].filter(
          // eslint-disable-next-line max-nested-callbacks
          card =>
            String(card.detail[isContract ? 'contractId' : 'couponId']) !==
            String(isContract ? contractId : couponId)
        );
      } else {
        filteredCards = currentCards[status].filter(
          // eslint-disable-next-line max-nested-callbacks
          card => String(card.detail.couponId) !== String(couponId)
        );
      }
      return {
        ...currentCards,
        [status]: filteredCards
      };
    });

    setPayments(currentPayments => {
      const filteredPayments = currentPayments.filter(
        // eslint-disable-next-line max-nested-callbacks
        payment => String(payment.detail.couponId) !== String(couponId)
      );
      return filteredPayments;
    });
  }, [
    contractId,
    couponId,
    setCardPayloads,
    setPayments,
    status,
    transactionType
  ]);

  const transactionStatus = () => {
    switch (status) {
      case CARD_STATUS.LOADING:
        return {
          label: 'Cargando',
          css: classes.paymentPending
        };

      case CARD_STATUS.PAID:
        return {
          label: 'Pagado',
          css: classes.paymentApproved
        };

      case CARD_STATUS.TOPAY:
        return {
          label: 'Por pagar',
          css: classes.paymentPending
        };

      case CARD_STATUS.PENDING:
        return {
          label: 'En proceso',
          css: classes.paymentPending
        };

      default:
        return {
          label: 'Error',
          css: classes.loadingError
        };
    }
  };

  const transactionStyles = transactionStatus();

  const getCardInfo = useCallback(
    async (captchaValue, axiosSource) => {
      switch (transactionType) {
        case TRANSACTION_CARD_TYPES.COUPON:
          return await GetCouponAPI(couponId, captchaValue, axiosSource);

        case TRANSACTION_CARD_TYPES.CONTRACT:
          return await GetInvoicesAPI(
            contractId,
            captchaValue,
            authToken,
            axiosSource
          );

        default:
          return {};
      }
    },
    [transactionType, contractId, couponId, authToken]
  );

  const buildCardBody = useCallback(
    data => {
      switch (transactionType) {
        case TRANSACTION_CARD_TYPES.COUPON: {
          const couponData = data.data;
          return {
            requestData: couponData,
            cardBody: {
              type: TRANSACTION_CARD_TYPES.COUPON,
              detail: {
                ...couponData,
                couponId: couponData.id,
                couponValue: couponData.value,
                loading: false
              }
            }
          };
        }

        case TRANSACTION_CARD_TYPES.CONTRACT: {
          const contractData = data.data[0];
          return {
            requestData: contractData,
            cardBody: {
              type: TRANSACTION_CARD_TYPES.CONTRACT,
              detail: {
                ...contractData,
                loading: false
              }
            }
          };
        }

        default:
          return {};
      }
    },
    [transactionType]
  );

  const getCardStatus = cardData => {
    if (cardData.isPaid) {
      return CARD_STATUS.PAID;
    }

    if (cardData.isPending) {
      return CARD_STATUS.PENDING;
    }

    return CARD_STATUS.TOPAY;
  };

  const loadPaymentCardInfo = useCallback(
    async axiosSource => {
      // api fetch
      const captchaValue = captcha;

      const { success, data, error, isCancel } = await getCardInfo(
        captchaValue,
        axiosSource
      );

      if (isCancel) {
        return;
      }

      const couponOrContract = couponId ? 'couponId' : 'contractId';

      if (success) {
        const { requestData, cardBody } = buildCardBody(data);
        const actualCardStatus = getCardStatus(requestData);

        setCardPayloads(oldPayload => {
          return updateItem(
            oldPayload,
            CARD_STATUS.LOADING,
            couponOrContract,
            couponId || contractId,
            actualCardStatus,
            cardBody
          );
        });

        if (!requestData.isPaid && !requestData.isPending) {
          setPayments(oldPayments => {
            return updatePayment(oldPayments, transactionType, requestData);
          });
        }
      } else {
        // Clean cards in loading state
        // eslint-disable-next-line no-lonely-if
        if (error.response) {
          const cardBody = {
            status: CARD_STATUS.ERROR,
            type: couponId
              ? TRANSACTION_CARD_TYPES.COUPON
              : TRANSACTION_CARD_TYPES.CONTRACT,
            detail: {
              [couponOrContract]: couponId || contractId,
              loading: false,
              error:
                error.response.data.errors[couponOrContract] || 'Hubo un error'
            }
          };
          setCardPayloads(oldPayload => {
            return updateItem(
              oldPayload,
              CARD_STATUS.LOADING,
              couponOrContract,
              couponId || contractId,
              CARD_STATUS.ERROR,
              cardBody
            );
          });
        } else {
          setAlert({
            type: ALERT_TYPE.WARNING,
            message: 'Ha ocurrido un error intente más tarde'
          });
          handleRemoveContract();
        }
      }
    },
    [
      captcha,
      setCardPayloads,
      setPayments,
      transactionType,
      couponId,
      contractId,
      setAlert,
      handleRemoveContract,
      getCardInfo,
      buildCardBody
    ]
  );

  useEffect(() => {
    const { CancelToken } = axios;
    const source = CancelToken.source();

    if (cardLoading) {
      loadPaymentCardInfo(source);
    }

    return () => {
      source.cancel();
    };
  }, [cardLoading, loadPaymentCardInfo]);

  return (
    <Card className={classes.root}>
      {cardLoading && (
        <ModalProgress
          id={'CardPayment_progress_loading'}
          modalProgressClass={classes.modalProgressContainer}
          message={`Consultando ${Company.contractConjugation.regular.singular.main}`}
        />
      )}
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item xs={6} sm={6} className={classes.cardHeader}>
            <CalendarIcon className={classes.icon} />
            <Typography
              className={classes.cardHeaderText}
              color="textSecondary"
              gutterBottom
            >
              {transactionType === TRANSACTION_CARD_TYPES.COUPON
                ? 'Cupón'
                : (generationDate &&
                    moment(generationDate)
                      .tz('America/Bogota')
                      .format('MMM - YYYY')) ||
                  '-'}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} className={classes.cardHeader}>
            <Typography
              className={clsx([
                classes.cardHeaderStatusText,
                transactionStyles.css
              ])}
            >
              {transactionStyles.label}
            </Typography>
          </Grid>
        </Grid>
        {cardError && (
          <Grid container className={classes.bodyGrid}>
            <Grid item xs={12} sm={12}>
              <Typography className={classes.errorText}>
                Ha ocurrido un error al consultar el{' '}
                {couponId
                  ? 'cupón'
                  : Company.contractConjugation.regular.singular.main}
                :{' '}
                <span className={classes.informationText}>
                  {couponId || contractId}
                </span>
              </Typography>
              <Typography className={classes.errorText}>
                <span className={classes.informationText}>
                  Información adicional
                </span>
                : {cardError}
              </Typography>
            </Grid>
          </Grid>
        )}
        {!cardError && (
          <Grid container className={classes.bodyGrid}>
            <Grid item xs={12} sm={12} className={classes.pairGridItem}>
              <Typography className={classes.informationText}>
                {contractCity && `${contractCity},`} {contractAddress || '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.pairGridItem}>
              <Typography className={classes.labelText}>
                {Company.contractConjugation.capitalized.singular.main} No.
              </Typography>
              <Typography className={classes.informationText}>
                {contractId || '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.pairGridItem}>
              <Typography className={classes.labelText}>Cupón No.</Typography>
              <Typography className={classes.informationText}>
                {couponId || '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.pairGridItem}>
              <Typography className={classes.labelText}>
                Fecha de vencimiento
              </Typography>
              <Typography className={classes.informationText}>
                {expirationDate
                  ? moment(expirationDate)
                      .tz('America/Bogota')
                      .format('DD/MM/YYYY')
                  : '-'}
              </Typography>
            </Grid>
          </Grid>
        )}
        <SimpleDivider withoutMargin className={classes.simpleBottomDivider} />
        <Box className={classes.invoiceValueContainer}>
          <Typography className={classes.labelText}>
            Valor de factura
          </Typography>
          <Typography className={classes.invoiceValueText}>
            {moneyWithDots(Number(couponValue))}
          </Typography>
        </Box>
        <SimpleDivider withoutMargin />
      </CardContent>
      <CardActions className={classes.cardActionContainer}>
        <ButtonWithIcon
          id="filter"
          textClass={classes.cardActionText}
          hideBadge={true}
          buttonText={`Remover ${Company.contractConjugation.regular.singular.main}`}
          onClick={handleRemoveContract}
        >
          <CircleMinus className={classes.cardActionIcon} />
        </ButtonWithIcon>
      </CardActions>
    </Card>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: 224,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 auto',
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: 0
    }
  },
  cardContent: {
    padding: theme.spacing(3, 2, 0)
  },
  bodyGrid: {
    height: 192,
    overflow: 'auto'
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
    '&:nth-child(2)': {
      fontWeight: 600,
      justifyContent: 'flex-end'
    }
  },
  cardHeaderText: {
    fontSize: 11,
    margin: 0
  },
  cardHeaderStatusText: {
    fontSize: 11,
    fontWeight: 600,
    margin: 0
  },
  title: {
    fontSize: 14
  },
  icon: {
    height: 18,
    width: 18,
    marginRight: theme.spacing(1)
  },
  paymentApproved: {
    color: theme.palette.color.gasesGreen
  },
  paymentPending: {
    color: theme.palette.warning.main
  },
  paymentRejected: {
    color: 'black'
  },
  loadingError: {
    color: 'red'
  },
  pairGridItem: {
    marginBottom: theme.spacing(1)
  },
  labelText: {
    color: theme.palette.color.default,
    fontSize: 11
  },
  informationText: {
    fontSize: 12,
    fontWeight: 600
  },
  invoiceValueText: {
    fontSize: 16,
    fontWeight: 600
  },
  invoiceValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    margin: theme.spacing(2, 0)
  },
  cardActionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2)
  },
  cardActionText: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.color.primary
  },
  cardActionIcon: {
    color: theme.palette.color.primary
  },
  modalProgressContainer: {
    position: 'absolute',
    opacity: 0.8
  },
  simpleBottomDivider: {
    marginTop: 'auto'
  },
  errorText: {
    fontSize: 12,
    '&:nth-child(2)': {
      marginTop: theme.spacing(2)
    }
  }
}));

export default PaymentCardGDC;
