import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Typography, Grid } from '@material-ui/core';
import * as yup from 'yup';

import InsuranceSelectedPlanCard from '../../../../../../Components/Cards/InsuranceSelectedPlanCard';
import BeneficiariesList from './BeneficiariesList';

import makeStyles from '@material-ui/core/styles/makeStyles';

import TextInput from '../../../../../../Components/Inputs/TextInput';
import CompositeSelectInput from '../../../../../../Components/Inputs/CompositeSelectInput';
import SelectInput from '../../../../../../Components/Inputs/SelectInput';
import BaseButton from '../../../../../../Components/Buttons/BaseButton';
import SweetAlert from '../../../../../../Components/Alerts/SweetAlert';

import { Add as AddIcon } from '@material-ui/icons';

import {
  SetCurrentStepIndexContext,
  StepperDataContext,
  StepperDataDispatchContext,
  CurrentStepIndexContext
} from '../../../../../../Contexts/StepperContext';

import { IDENTIFICATION_OPTIONS } from '../../../../../../Enums/insurances';
import SimpleDivider from '../../../../../../Components/Dividers/SimpleDivider';

const SelectionPlanStep = props => {
  const {
    formId,
    setCanSubmit,
    setGoBack,
    setCurrentStep: setVisualStepperIndex,
    setNextButtonText,
    setAlert,
    planId,
    setPlanId,
    plans,
    relationshipOptions
  } = props;

  const classes = useStyles();

  const currentStepIndex = useContext(CurrentStepIndexContext);
  const setCurrentStep = useContext(SetCurrentStepIndexContext);
  const setData = useContext(StepperDataDispatchContext);
  const stepperData = useContext(StepperDataContext);

  const [beneficiaries, setBeneficiaries] = useState(
    stepperData.beneficiaries || []
  );
  const [errors, setErrors] = useState({});
  const [placeholderPercentage, setPlaceholderPercentage] = useState('');

  const checkPercentage = useCallback(() => {
    const percentageSum = beneficiaries.reduce(
      (acc, obj) => acc + parseInt(obj.percentage, 10),
      0
    );
    if (percentageSum < 100) {
      setPlaceholderPercentage(`${100 - percentageSum}`);
    } else {
      setPlaceholderPercentage('');
    }
  }, [beneficiaries, setPlaceholderPercentage]);

  useEffect(() => {
    checkPercentage();
  }, [checkPercentage]);

  const schema = yup.object({
    fullName: yup.string().required('Escriba el nombre del beneficiario'),
    identification: yup
      .string()
      .test(
        'sameId',
        'El numero de identificación ya está presente en los beneficiarios',
        val => {
          return beneficiaries.length > 0
            ? !beneficiaries.some(s => s.identification === val)
            : true;
        }
      )
      .required('Escriba el numero de documento del beneficiario'),
    relationship: yup
      .object({
        id: yup.number().integer(),
        nombre: yup.string()
      })
      .required('Elija un parentesco válido'),
    percentage: yup
      .number()
      .integer()
      .positive('El porcentaje debe ser positivo')
      .typeError('Porcentaje debe de tener un valor')
      .test(
        'totalPercentage',
        'La suma del porcentaje de los beneficiarios debe ser igual a 100%',
        val => {
          const percentageSum = beneficiaries
            .map(item => parseInt(item.percentage, 10))
            .reduce((prev, curr) => prev + curr, 0);
          return val && percentageSum + parseInt(val, 10) <= 100;
        }
      )
      .test(
        'minValue',
        'El porcentaje debe ser positivo',
        val => val && val > 0
      )
      .test(
        'maxValue',
        'El porcentaje debe ser menor a 100%',
        val => val && val <= 100
      )
      .required('Porcentaje debe de tener un valor')
  });

  const { register, watch, control, handleSubmit, reset } = useForm({
    submitFocusError: true,
    defaultValues: {
      relationship: relationshipOptions[0].value,
      ...stepperData
    }
  });
  const watchForm = watch(['fullName', 'identification']);

  const buttonDisabled = !watchForm.fullName && !watchForm.identification;

  const addBeneficiary = values => {
    try {
      schema.validateSync(values);
      const array = beneficiaries;
      array.push(values);
      setBeneficiaries(array);
      setAlert({
        type: 'success',
        message: 'Beneficiario agregado correctamente',
        toast: true
      });
      setErrors({});
      checkPercentage();
      reset();
    } catch (err) {
      setErrors({
        [err.path]: err.errors[0]
      });
    }
  };
  const editBeneficiary = (values, index) => {
    try {
      const array = beneficiaries;
      array[index] = values;
      setBeneficiaries(array);
      setAlert({
        type: 'success',
        message: 'Beneficiario editado correctamente',
        toast: true
      });
      setErrors({});
      checkPercentage();
      setCanSubmit(true);
    } catch (err) {
      setErrors({
        [err.path]: err.errors[0]
      });
    }
  };
  const deleteBeneficiary = index => {
    try {
      const array = beneficiaries.slice(0);
      array.splice(index, 1);
      setBeneficiaries(array);
      setAlert({
        type: 'success',
        message: 'Beneficiario eliminado correctamente',
        toast: true
      });
      setErrors({});
      checkPercentage();
      setCanSubmit(true);
    } catch (err) {
      setErrors({
        [err.path]: err.errors[0]
      });
    }
  };

  const goNext = useCallback(() => {
    const percentageSum = beneficiaries.reduce(
      (acc, obj) => acc + parseInt(obj.percentage, 10),
      0
    );
    if (beneficiaries.length !== 0 && percentageSum < 100) {
      setErrors({
        percentage:
          'La suma del porcentaje de los beneficiarios debe ser igual a 100%'
      });
      setCanSubmit(false);
    } else {
      setData(data => ({
        ...data,
        beneficiaries,
        planSelected: plans[planId],
        selectedPlanId: planId
      }));
      setCurrentStep(step => step + 1);
    }
  }, [setData, setCurrentStep, planId, beneficiaries, setCanSubmit, plans]);

  const onBackward = useCallback(() => {
    setData(data => ({
      ...data,
      beneficiaries,
      planSelected: plans[planId],
      selectedPlanId: planId
    }));
    setCurrentStep(currentStep => currentStep - 1);
  }, [setCurrentStep, setData, planId, beneficiaries, plans]);

  useEffect(() => {
    setCanSubmit(buttonDisabled);
  }, [buttonDisabled, setCanSubmit]);

  useEffect(() => {
    setNextButtonText('Siguiente');
    setVisualStepperIndex(currentStepIndex);
    setCanSubmit(true);
    setGoBack({
      action: onBackward
    });
  }, [
    setGoBack,
    onBackward,
    setVisualStepperIndex,
    currentStepIndex,
    setNextButtonText,
    setCanSubmit
  ]);

  return (
    <div className={classes.root}>
      <form id={formId} onSubmit={handleSubmit(goNext)}>
        <InsuranceSelectedPlanCard
          plans={plans}
          setPlanId={setPlanId}
          planId={planId}
          monthlyPay={plans[planId].monthlyPay}
        />
        <Typography className={classes.addBeneficiariesText}>
          Agregar los beneficiarios del plan (máx. 4)
        </Typography>
        <Grid container direction="column">
          <Grid container className={classes.inputsContainer}>
            <Grid
              item
              xs={12}
              sm={7}
              md={4}
              className={classes.inputFieldContainer}
            >
              <TextInput
                label="Nombre completo"
                name="fullName"
                inputRef={register}
                fullWidth={true}
                error={Boolean(errors.fullName)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              md={3}
              className={classes.identificationField}
            >
              <CompositeSelectInput
                label="Identificación"
                options={IDENTIFICATION_OPTIONS}
                TextInputProps={{
                  label: 'Identificación',
                  name: 'identification',
                  defaultValue: '',
                  type: 'number',
                  inputRef: register,
                  fullWidth: true
                }}
                SelectInputProps={{
                  name: 'identificationType',
                  defaultValue: 'CC',
                  inputRef: register,
                  control
                }}
                error={Boolean(errors.identification)}
              />
            </Grid>
            <Grid
              item
              xs={8}
              sm={9}
              md={3}
              className={classes.inputFieldContainer}
            >
              <Controller
                as={SelectInput}
                label="Parentesco"
                name="relationship"
                inputRef={register}
                fullWidth={true}
                control={control}
                options={relationshipOptions}
                error={Boolean(errors.relationship)}
              />
            </Grid>
            <Grid
              item
              xs={4}
              sm={3}
              md={2}
              className={classes.inputFieldContainer}
            >
              <TextInput
                label="Porcentaje"
                name="percentage"
                placeholder={placeholderPercentage}
                type="number"
                inputRef={register}
                fullWidth={true}
                error={Boolean(errors.percentage)}
              />
            </Grid>
          </Grid>
          <Grid container justify="flex-end">
            <BaseButton
              className={classes.addBeneficiaryButton}
              size="small"
              color="default"
              variant="text"
              onClick={handleSubmit(addBeneficiary)}
              startIcon={<AddIcon />}
              disabled={beneficiaries.length >= 4 || buttonDisabled}
            >
              Agregar
            </BaseButton>
          </Grid>
          <SimpleDivider className={classes.divider} />
          {Object.keys(errors).length > 0 && (
            <div className={classes.sweetAlert}>
              <SweetAlert
                type="error"
                message={
                  errors.percentage ||
                  errors.identification ||
                  errors.relationship ||
                  errors.fullName
                }
              />
            </div>
          )}
          {beneficiaries.length === 0 && (
            <div className={classes.sweetAlert}>
              <SweetAlert message="Los beneficiarios no son requeridos, en caso de no agregar ninguno aplicarán los beneficiarios de ley." />
            </div>
          )}
          {beneficiaries.length > 0 && (
            <Typography className={classes.beneficiariesCount}>
              Beneficiarios ({beneficiaries.length} / 4)
            </Typography>
          )}
          <BeneficiariesList
            beneficiaries={beneficiaries}
            deleteBeneficiary={deleteBeneficiary}
            editBeneficiary={editBeneficiary}
            relationshipOptions={relationshipOptions}
          />
        </Grid>
      </form>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    '& > :last-child': {
      marginBottom: theme.spacing(10)
    }
  },
  content: {
    marginTop: theme.spacing(4)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  inputsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      '& > *': {
        paddingRight: theme.spacing(2)
      },
      '& > *:last-child': {
        paddingRight: 0
      }
    },
    [theme.breakpoints.between(
      theme.breakpoints.values.sm,
      theme.breakpoints.values.md
    )]: {
      '& > :nth-child(odd)': {
        paddingRight: theme.spacing(1)
      },
      '& > :nth-child(even)': {
        paddingRight: theme.spacing(0)
      }
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      '& > :nth-child(3)': {
        paddingRight: theme.spacing(1)
      }
    }
  },
  inputFieldContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      '& > *': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
      }
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      '& > *': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
      }
    }
  },
  identificationField: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      margin: theme.spacing(0)
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginBottom: theme.spacing(1)
    }
  },
  grid: { marginBottom: theme.spacing(4) },
  label: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.greyDark
  },
  data: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.2,
    textTransform: 'capitalize',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 13
    }
  },
  sweetAlert: {
    marginTop: theme.spacing(2)
  },
  addBeneficiariesText: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginBottom: theme.spacing(1)
    }
  },
  beneficiariesCount: {
    margin: theme.spacing(1, 0)
  },
  addBeneficiaryButton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      width: '100%'
    }
  }
}));

export default SelectionPlanStep;
