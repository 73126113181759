/* eslint-disable complexity */
import React, {
  Fragment,
  useCallback,
  useState,
  useContext,
  useEffect
} from 'react';
import clsx from 'clsx';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Typography,
  Card,
  Grid,
  IconButton,
  Badge,
  Chip
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { history } from '../../../Routes/history';
import { ROUTE_NAMES } from '../../../Routes/Routes';

import SimpleDivider from '../../../Components/Dividers/SimpleDivider';
import TextInput from '../../../Components/Inputs/TextInput';
import YesNoButton from '../../../Components/Buttons/YesNoButton';
import FileInput from '../../../Components/Inputs/FileInput';
import Title from '../../../Components/Labels/Title';
import CompositeSelectInput from '../../../Components/Inputs/CompositeSelectInput';
import BaseButton from '../../../Components/Buttons/BaseButton';
import SweetAlert from '../../../Components/Alerts/SweetAlert';
import RequestDialog from '../../../Components/Dialogs/RequestDialog';
import WithRequestIcon from '../../../Components/CustomIcons/IcActiveRequest';
import FullSizeProgress from '../../../Components/Progress/FullSize/FullSizeProgress';
import FileToBase64 from '../../../Utils/Transform/FileToBase64';
import AlertFullView from '../../../Components/Alerts/AlertFullView';
import {
  UserContext,
  UserDispatchContext
} from '../../../Contexts/UserContext';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';
import { ContractSelectedContext } from '../../../Contexts/ContractsContext';
import {
  OwnershipRequestAPI,
  HasOwnershipRequestAPI
} from '../../../API/ChangeOfOwnership/OwnershipAPI';
import {
  redirectOnAuthFailure,
  extractErrorMessage
} from '../../../Utils/Errors/Errors';
import { formatAddress } from '../../../Utils/Format/Address';
import _get from 'lodash/get';
import QuestionMark from '../../../Components/Adornments/QuestionMark';
import FooterPayment from '../../Payment/FooterPayment';
import CertificatePinHelpDialog from '../../../Components/Dialogs/CertificatePinHelpDialog';
import { HELP_TEXT } from '../../../Utils/enums';
import { Company } from '../../../Configs/general';
import { logoutUser } from '../../../Utils/User/Actions';

const IDENTIFICATION_OPTIONS = [
  { label: 'CC', value: 'CC' },
  { label: 'PP', value: 'PP' },
  { label: 'CE', value: 'CE' },
  { label: 'TI', value: 'TI' },
  { label: 'NIT', value: 'NIT' }
];

const ownershipSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .max(60, 'Debe tener 60 letras o menos')
    .required('Ingresa tu nombre'),
  lastName: yup.mixed().when('identificationType', {
    is: 'NIT',
    then: yup.string().trim(),
    otherwise: yup
      .string()
      .trim()
      .max(60, 'Debe tener 60 letras o menos')
      .required('Ingresa tus apellidos')
  }),
  identification: yup.mixed().when('identificationType', {
    is: 'PP',
    then: yup
      .string()
      .trim()
      .matches(/^[0-9a-zA-Z]+$/, 'Ingresa un número de pasaporte válido')
      .required('Ingresa el número de tu pasaporte'),
    otherwise: yup
      .string()
      .trim()
      .matches(/^[0-9]+$/, 'Ingresa un número de identificación válido')
      .required('Ingresa tu número de identificación')
  }),
  identificationType: yup
    .string()
    .trim()
    .oneOf(['CC', 'PP', 'CE', 'TI', 'NIT'], 'Debes escoger una opción correcta')
    .required('Debes ingresar un tipo de identificación'),
  userIdDocument: yup
    .mixed()
    .test(
      'notFile',
      'Ingresa un documento',
      file => [file] && file.length === 1
    )
    .test(
      'notPDF',
      'Único formato permitido: PDF',
      ([file]) => file && file.type === 'application/pdf'
    )
    .test(
      'filesize',
      'Tamaño máximo: 1 MB',
      ([file]) => file && file.size <= 1024000
    ),
  pin: yup
    .string()
    .trim()
    .required('Debes ingresar un número de pin')
    .matches(/^[0-9]+$/, 'Debes ingresar un número de pin válido'),
  userPdf: yup
    .mixed()
    .test(
      'notFile',
      'Ingresa el documento original',
      file => [file] && file.length === 1
    )
    .test(
      'notPDF',
      'Único formato permitido: PDF',
      ([file]) => file && file.type === 'application/pdf'
    )
    .test(
      'filesize',
      'Tamaño máximo: 1 MB. Documento original',
      ([file]) => file && file.size <= 1024000
    ),
  isLeasing: yup.boolean(),
  userLeasing: yup.mixed().when('isLeasing', {
    is: true,
    then: yup
      .mixed()
      .test(
        'notFile',
        'Ingresa el documento original',
        file => [file] && file.length === 1
      )
      .test(
        'notPDF',
        'Único formato permitido: PDF',
        ([file]) => file && file.type === 'application/pdf'
      )
      .test(
        'filesize',
        'Tamaño máximo: 1 MB. Documento original',
        ([file]) => file && file.size <= 1024000
      ),
    otherwise: yup.string()
  })
});

const ChangeOfOwnershipGDC = () => {
  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const selectedContract = useContext(ContractSelectedContext);
  const setAlert = useContext(AlertsDispatchContext);
  const authToken = _get(currentUser, 'token');

  // * STATE HOOKS
  const [infoDialog, setInfoDialog] = useState(false);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [previousRequest, setPreviousRequest] = useState(null);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);

  // * OTHER HOOKS
  const classes = useStyles();
  const isMobileSize = useMediaQuery('(max-width:800px)');

  // * FUNCTIONS

  const {
    handleSubmit,
    register,
    errors,
    formState,
    watch,
    setValue,
    control
  } = useForm({
    validationSchema: ownershipSchema,
    mode: 'onChange'
  });

  useEffect(() => {
    if (!authToken) {
      history.replace('/');
      return;
    }

    if (!selectedContract) {
      history.replace(ROUTE_NAMES.contracts);
      return;
    }

    let ignoreRequest = false;

    setLoading(true);

    if (ignoreRequest) {
      return;
    }

    const validateRequest = async () => {
      const response = await HasOwnershipRequestAPI(
        authToken,
        selectedContract.id
      );

      setLoading(false);
      if (!response.success) {
        if (
          redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
        ) {
          return;
        }

        setPreviousRequest(null);
        return;
      }

      const { id, date } = response.data.data;
      const formattedDate = moment(date).format('DD-MM-YYYY');

      setPreviousRequest({ id, date: formattedDate });
    };

    validateRequest();

    return () => {
      ignoreRequest = true;
    };
  }, [selectedContract, authToken, setCurrentUser]);

  const watchForm = watch([
    'userPdf',
    'userIdDocument',
    'userLeasing',
    'identificationType',
    'isLeasing'
  ]);
  const isUserPdfValid =
    watchForm.userPdf && watchForm.userPdf.length === 1 && !errors.userPdf;
  const isUserDocumentValid =
    watchForm.userIdDocument &&
    watchForm.userIdDocument.length === 1 &&
    !errors.userIdDocument;
  const isLeasing = watchForm.isLeasing;
  const isLeasingValid =
    watchForm.userLeasing &&
    watchForm.userLeasing.length === 1 &&
    !errors.userLeasing;
  const canSubmitRequest =
    formState.isValid && isUserDocumentValid && isUserDocumentValid;
  const isNaturalPerson = watchForm.identificationType !== 'NIT';

  const handlePdfDelete = () => {
    setValue('userPdf', '', true);
  };

  const handleDocumentDelete = () => {
    setValue('userIdDocument', '', true);
  };

  const handleLeasingDelete = () => {
    setValue('userLeasing', '', true);
  };

  const openInfoDialog = useCallback(async values => {
    const userPdf = await FileToBase64(values.userPdf[0]);
    const userIdDocument = await FileToBase64(values.userIdDocument[0]);
    let userLeasing = null;
    if (values.userLeasing) {
      userLeasing = await FileToBase64(values.userLeasing[0]);
    }
    const data = {
      firstName: values.firstName.trim(),
      lastName: values.lastName ? values.lastName.trim() : '',
      identification: values.identification.trim(),
      identificationType: values.identificationType.trim(),
      userIdDocument,
      pin: values.pin.trim(),
      userPdf,
      userLeasing
    };
    setInfoDialog(true);
    setUserData(data);
  }, []);

  const onHelpClick = useCallback(() => {
    setHelpDialogOpen(true);
  }, []);

  const ownershipRequest = useCallback(async () => {
    const response = await OwnershipRequestAPI(
      authToken,
      selectedContract.id,
      userData
    );
    if (response.success) {
      setAlert({
        type: 'success',
        message: 'Solicitud de cambio de titular generada exitosamente'
      });

      history.replace('/');
      return { unmounting: true, closeDialog: false };
    }

    if (
      redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
    ) {
      return { unmounting: true, closeDialog: false };
    }

    setAlert({
      type: 'error',
      message: extractErrorMessage(response).message
    });
    return { unmounting: false, closeDialog: false };
  }, [authToken, selectedContract, setCurrentUser, setAlert, userData]);

  if (!currentUser) {
    return <Fragment></Fragment>;
  }

  const renderRequestDialog = () => {
    if (!infoDialog) {
      return;
    }

    return (
      <RequestDialog
        open={infoDialog}
        setDialog={setInfoDialog}
        requestTitle={'Solicitud de Cambio de Titular'}
        requestCallback={ownershipRequest}
        withoutObservation={true}
        requestNodeAfterForm={
          <Fragment>
            <Typography className={classes.requestText} paragraph>
              Por este medio realizarás la solicitud de Cambio de Titular. La
              solicitud se realizará para{' '}
              {Company.contractConjugation.regular.singular.article}{' '}
              <span className={classes.textBold}>#{selectedContract.id}</span>{' '}
              con dirección:{' '}
              <span className={clsx([classes.textBold, classes.textCaps])}>
                {formatAddress(selectedContract, true)}
              </span>
              . Una vez realizada, se te informará por correo electrónico y
              celular el estado de tu solicitud.
            </Typography>
          </Fragment>
        }
        disclaimer="A través de esta solicitud únicamente podremos tramitar tu solicitud de cambio de titular. Peticiones, quejas o recursos diferentes al cambio de titular no serán gestionadas por este medio."
      />
    );
  };

  const renderRequestAlert = () => {
    if (!previousRequest) {
      return;
    }
    return (
      <AlertFullView
        icon={WithRequestIcon}
        title={
          <Fragment>
            Ya cuentas con una solicitud para <strong>cambio de titular</strong>
            . <br /> Creada el {previousRequest.date} con número de solicitud{' '}
            {previousRequest.id}.
          </Fragment>
        }
        subtitle={
          'Te invitamos a estar atento a tu celular y correo electrónico para cualquier novedad.'
        }
        redirectText={'Volver al Inicio'}
        redirectTo={ROUTE_NAMES.home}
      />
    );
  };

  const renderNaturalPerson = () => {
    if (isNaturalPerson) {
      return (
        <Grid
          spacing={2}
          container
          className={`${classes.fullNameInput} ${classes.optionRow}`}
        >
          <Grid
            item
            sm={6}
            className={`${classes.inputFieldContainer} ${errors.name &&
              classes.optionWithErrors}`}
          >
            <TextInput
              inputRef={register}
              id="Ownership_input_firstName"
              required
              fullWidth
              margin="none"
              label="Nombres"
              error={Boolean(errors.firstName)}
              helperText={errors.firstName && errors.firstName.message}
              name="firstName"
            />
          </Grid>
          <Grid
            item
            sm={6}
            className={`${classes.inputFieldContainer} ${errors.lastName &&
              classes.optionWithErrors}`}
          >
            <TextInput
              inputRef={register}
              id="Ownership_input_lastName"
              required
              fullWidth
              margin="none"
              label="Apellidos"
              error={Boolean(errors.lastName)}
              helperText={errors.lastName && errors.lastName.message}
              name="lastName"
            />
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid
        spacing={2}
        container
        className={`${classes.fullNameInput} ${classes.optionRow}`}
      >
        <Grid
          item
          sm={12}
          className={`${classes.inputFieldContainer} ${errors.name &&
            classes.optionWithErrors}`}
        >
          <TextInput
            inputRef={register}
            id="Ownership_input_firstName"
            required
            fullWidth
            margin="none"
            label="Razón Social"
            error={Boolean(errors.firstName)}
            helperText={
              errors.firstName &&
              ((errors.firstName.type === 'required' &&
                'Ingresa el nombre de tu razón social') ||
                errors.firstName.message)
            }
            name="firstName"
          />
        </Grid>
      </Grid>
    );
  };

  const renderLeasing = () => {
    return (
      <>
        {isLeasing && (
          <SweetAlert
            classes={{
              root: classes.sweetAlert,
              message: classes.sweetAlertText
            }}
            noIcon={isMobileSize}
            message={
              <Fragment>
                La persona que aparecerá como{' '}
                {
                  <strong>
                    titular{' '}
                    {Company.contractConjugation.regular.singular.contraction}
                  </strong>
                }{' '}
                deberá aparecer como {<strong>firmante</strong>} en el contrato
                de Leasing Habitacional.
              </Fragment>
            }
          />
        )}
        <Grid spacing={2} container>
          <Grid item sm={6} className={classes.inputYesNoContainer}>
            <Typography className={classes.inputYesNoText}>
              ¿Tu predio tiene un Contrato de Leasing Habitacional?
            </Typography>
            <Controller
              control={control}
              name="isLeasing"
              as={() => {
                return (
                  <YesNoButton
                    className={classes.inputYesNoButton}
                    checked={Boolean(isLeasing)}
                    yesChecked={() => setValue('isLeasing', true)}
                    noChecked={() => setValue('isLeasing', false)}
                    color="primary"
                    small="small"
                  />
                );
              }}
            />
          </Grid>
          {isLeasing && (
            <Grid item sm={6} className={classes.documentButtonContainer}>
              <Grid item sm={12} className={classes.inputFileWrapper}>
                {isLeasingValid ? (
                  <IconButton
                    className={classes.clipButtonWithFile}
                    aria-label="paperclip"
                    disableTouchRipple={true}
                  >
                    <Badge
                      classes={{
                        anchorOriginTopRightCircle: classes.badgePosition
                      }}
                      badgeContent={1}
                      overlap="circle"
                      color="primary"
                    >
                      <AttachFileIcon
                        classes={{
                          root: classes.clipButtonSize
                        }}
                      />
                    </Badge>
                  </IconButton>
                ) : (
                  <IconButton
                    className={classes.clipButton}
                    aria-label="paperclip"
                    disableTouchRipple={true}
                  >
                    <AttachFileIcon
                      classes={{
                        root: classes.clipButtonSize
                      }}
                    />
                  </IconButton>
                )}
                <Grid item sm={12} className={classes.inputFileContainer}>
                  <FileInput
                    name="userLeasing"
                    accept=".pdf"
                    id="Ownership_userLeasing_File"
                    ref={register}
                    error={Boolean(errors.userLeasing)}
                    text={'Contrato Leasing Habitacional (PDF)'}
                    helperText={
                      (errors.userLeasing && errors.userLeasing.message) || ' '
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {isLeasing && isLeasingValid && (
            <Grid container className={classes.chipContainer}>
              <Grid item className={classes.fullWidth} sm={12}>
                <Chip
                  classes={{
                    root: classes.chipRoot,
                    label: classes.chipLabel,
                    deleteIcon: classes.chipIcon
                  }}
                  label={`Archivo adjunto: ${watchForm.userLeasing[0].name}`}
                  onDelete={handleLeasingDelete}
                  color="primary"
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </>
    );
  };

  const renderRequestForm = () => {
    if (previousRequest) {
      return;
    }

    return (
      <div className={classes.root}>
        <Container
          maxWidth="md"
          className={clsx({
            [classes.containerDesktop]: !isMobileSize
          })}
        >
          <Title
            className={classes.title}
            title="Solicitud de Cambio de Titular"
          />
          <Card className={classes.card}>
            <Typography className={classes.cardInfo}>
              Ingresa los datos completos de la{' '}
              <span className={classes.textBold}>
                persona {isNaturalPerson ? 'natural' : 'jurídica'}
              </span>{' '}
              que aparecerá como el titular{' '}
              {Company.contractConjugation.regular.singular.contraction}{' '}
              <span className={classes.textBold}>
                @{selectedContract.alias} #{selectedContract.id}
              </span>
              , el cual deberá aparecer como el actual propietario en el
              certificado de tradición:
            </Typography>
            <div className={classes.cardContent}>
              <form id="ownershipForm" onSubmit={handleSubmit(openInfoDialog)}>
                <Grid spacing={2} container className={classes.optionRow}>
                  <Grid item className={classes.inputFieldContainer} sm={6}>
                    <CompositeSelectInput
                      inputId="Ownership_input_identification"
                      selectId="Ownership_input_identificationType"
                      options={IDENTIFICATION_OPTIONS}
                      TextInputProps={{
                        label: 'Identificación',
                        name: 'identification',
                        required: true,
                        inputRef: register,
                        fullWidth: true,
                        margin: 'none'
                      }}
                      SelectInputProps={{
                        name: 'identificationType',
                        defaultValue: 'CC',
                        control
                      }}
                      error={Boolean(errors.identification)}
                      helperText={
                        errors.identification && errors.identification.message
                      }
                    />
                  </Grid>
                  <Grid item sm={6} className={classes.documentButtonContainer}>
                    <Grid item sm={12} className={classes.inputFileWrapper}>
                      {isUserDocumentValid ? (
                        <IconButton
                          className={classes.clipButtonWithFile}
                          aria-label="paperclip"
                          disableTouchRipple={true}
                        >
                          <Badge
                            classes={{
                              anchorOriginTopRightCircle: classes.badgePosition
                            }}
                            badgeContent={1}
                            overlap="circle"
                            color="primary"
                          >
                            <AttachFileIcon
                              classes={{
                                root: classes.clipButtonSize
                              }}
                            />
                          </Badge>
                        </IconButton>
                      ) : (
                        <IconButton
                          className={classes.clipButton}
                          aria-label="paperclip"
                          disableTouchRipple={true}
                        >
                          <AttachFileIcon
                            classes={{
                              root: classes.clipButtonSize
                            }}
                          />
                        </IconButton>
                      )}
                      <Grid item sm={12} className={classes.inputFileContainer}>
                        <FileInput
                          accept=".pdf"
                          name="userIdDocument"
                          ref={register}
                          id="Ownership_userDocument_File"
                          text={
                            isNaturalPerson
                              ? 'Documento de Identidad (PDF)'
                              : 'Certificado de la Cámara de Comercio (PDF)'
                          }
                          error={Boolean(errors.userIdDocument)}
                          helperText={
                            (errors.userIdDocument &&
                              errors.userIdDocument.message) ||
                            (watchForm.userIdDocument &&
                              watchForm.userIdDocument.length < 1 &&
                              `${
                                isNaturalPerson
                                  ? ''
                                  : '. Antigüedad no mayor a 30 días'
                              }`) ||
                            `${
                              isNaturalPerson
                                ? ''
                                : '. Antigüedad no mayor a 30 días'
                            }`
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {isUserDocumentValid && (
                    <Grid container className={classes.chipContainer}>
                      <Grid item className={classes.documentChip} sm={12}>
                        <Chip
                          classes={{
                            root: classes.chipRoot,
                            label: classes.chipLabel,
                            deleteIcon: classes.chipIcon
                          }}
                          label={`Archivo adjunto: ${watchForm.userIdDocument[0].name}`}
                          onDelete={handleDocumentDelete}
                          color="primary"
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                {renderNaturalPerson()}
                {renderLeasing()}
                <SimpleDivider withoutMargin={false} />
                <Typography className={classes.libertyText}>
                  Ingresa la información del{' '}
                  <span className={classes.textBold}>
                    certificado de tradición y libertad
                  </span>{' '}
                  del predio:
                </Typography>
                <SweetAlert
                  classes={{
                    root: classes.sweetAlert,
                    message: classes.sweetAlertText
                  }}
                  noIcon={isMobileSize}
                  message={
                    <Fragment>
                      El Certificado de Tradición a adjuntar debe ser original,
                      descargado de la página oficial de la{' '}
                      {
                        <strong>
                          Superintendencia de Notariado y Registro
                        </strong>
                      }{' '}
                      y contar con una{' '}
                      {<strong>antigüedad no mayor a 30 días</strong>}.{' '}
                      {<strong>No puede ser un documento escaneado</strong>}.
                    </Fragment>
                  }
                />
                <Grid spacing={2} container className={classes.pinRow}>
                  <Grid
                    item
                    sm={6}
                    className={`${classes.inputFieldContainer} ${errors.pin &&
                      classes.optionWithErrors}`}
                  >
                    <TextInput
                      inputRef={register}
                      id="Ownership_input_pin"
                      required
                      fullWidth
                      margin="none"
                      label="PIN"
                      InputProps={{
                        endAdornment: <QuestionMark onClick={onHelpClick} />,
                        inputProps: {
                          inputMode: 'numeric'
                        }
                      }}
                      error={Boolean(errors.pin)}
                      helperText={errors.pin && errors.pin.message}
                      name="pin"
                    />
                  </Grid>
                  <Grid item sm={6} className={classes.documentButtonContainer}>
                    <Grid item sm={12} className={classes.inputFileWrapper}>
                      {isUserPdfValid ? (
                        <IconButton
                          className={classes.clipButtonWithFile}
                          aria-label="paperclip"
                          disableTouchRipple={true}
                        >
                          <Badge
                            classes={{
                              anchorOriginTopRightCircle: classes.badgePosition
                            }}
                            badgeContent={1}
                            overlap="circle"
                            color="primary"
                          >
                            <AttachFileIcon
                              classes={{
                                root: classes.clipButtonSize
                              }}
                            />
                          </Badge>
                        </IconButton>
                      ) : (
                        <IconButton
                          className={classes.clipButton}
                          aria-label="paperclip"
                          disableTouchRipple={true}
                        >
                          <AttachFileIcon
                            classes={{
                              root: classes.clipButtonSize
                            }}
                          />
                        </IconButton>
                      )}
                      <Grid item sm={12} className={classes.inputFileContainer}>
                        <FileInput
                          name="userPdf"
                          accept=".pdf"
                          id="Ownership_userPdf_File"
                          ref={register}
                          error={Boolean(errors.userPdf)}
                          text={'Certificado de Tradición (PDF)'}
                          helperText={
                            (errors.userPdf && errors.userPdf.message) || ' '
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {isUserPdfValid && (
                    <Grid container className={classes.chipContainer}>
                      <Grid item className={classes.fullWidth} sm={12}>
                        <Chip
                          classes={{
                            root: classes.chipRoot,
                            label: classes.chipLabel,
                            deleteIcon: classes.chipIcon
                          }}
                          label={`Archivo adjunto: ${watchForm.userPdf[0].name}`}
                          onDelete={handlePdfDelete}
                          color="primary"
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <SimpleDivider withoutMargin />
              </form>
            </div>
            {isMobileSize && (
              <Grid container className={classes.requestContainer}>
                <Grid item className={classes.requestButton} sm={5}>
                  <BaseButton
                    color="primary"
                    variant="contained"
                    form="ownershipForm"
                    onClick={handleSubmit(openInfoDialog)}
                    disabled={!canSubmitRequest}
                    fullWidth
                  >
                    Crear Solicitud
                  </BaseButton>
                </Grid>
              </Grid>
            )}
          </Card>
        </Container>
        {!isMobileSize && (
          <FooterPayment
            currentStep={{
              label: 'Solicitud',
              action: {
                text: 'Crear Solicitud',
                onCall: handleSubmit(openInfoDialog)
              }
            }}
            disabledNext={!canSubmitRequest}
          />
        )}
      </div>
    );
  };

  if (loading) {
    return <FullSizeProgress />;
  }

  return (
    <Fragment>
      <CertificatePinHelpDialog
        open={helpDialogOpen}
        onClose={() => setHelpDialogOpen(false)}
        title={HELP_TEXT.pin}
      />
      {renderRequestDialog()}
      {renderRequestAlert()}
      {renderRequestForm()}
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  containerDesktop: {
    marginBottom: theme.custom.footerHeight.stepper
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing()
  },
  ownershipText: {
    fontSize: 20,
    fontWeight: 1000,
    [theme.breakpoints.up('sm')]: {
      textAlign: 'justify'
    },
    textAlign: 'center'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.custom.borderRadius,
    boxShadow: 'none',
    paddingBottom: theme.spacing(4)
  },
  cardInfo: {
    fontSize: 14,
    paddingTop: theme.spacing(1),
    textAlign: 'justify'
  },
  libertyText: {
    fontSize: 14,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    textAlign: 'justify'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  optionRow: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  pinRow: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing()
  },
  optionWithErrors: {
    marginBottom: -17
  },
  requestContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end'
    },
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  inputFieldContainer: {
    width: '100%',
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(0)
    }
  },
  inputYesNoContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 72,
    marginBottom: theme.spacing(),
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
      flexDirection: 'row'
    }
  },
  inputYesNoText: {
    fontSize: 14,
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: 0
    }
  },
  inputYesNoButton: {
    marginTop: 0,
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start'
    }
  },
  chipContainer: {
    width: '100%',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(1)
    }
  },
  fileButtonContainer: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  documentButtonContainer: {
    width: '100%'
  },
  inputFileWrapper: {
    display: 'flex',
    width: '100%'
  },
  inputFileContainer: {
    display: 'flex',
    width: '100%'
  },
  fullNameInput: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  sweetAlert: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing()
  },
  sweetAlertText: {
    fontSize: 14,
    color: 'black'
  },
  fullWidth: {
    width: '100%'
  },
  fullNameContainer: {
    width: '100%',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(0)
    },
    marginBottom: theme.spacing(2)
  },
  requestButton: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'initial'
    }
  },
  clipButton: {
    padding: 0,
    cursor: 'initial',
    '&:hover': {
      backgroundColor: 'white'
    },
    color: '#77959e',
    width: 30,
    height: 50
  },
  clipButtonWithFile: {
    padding: 0,
    cursor: 'initial',
    '&:hover': {
      backgroundColor: 'white'
    },
    color: '#77959e',
    width: 30,
    height: 50,
    marginRight: theme.spacing(1)
  },
  clipButtonSize: {
    fontSize: '2.1rem'
  },
  textBold: {
    fontWeight: 500
  },
  textCaps: {
    textTransform: 'capitalize'
  },
  chipRoot: {
    width: '100%',
    borderRadius: 6
  },
  chipLabel: {
    display: 'inline',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    textAlign: 'center',
    fontSize: 14
  },
  documentChip: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  requestText: {
    maxWidth: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  badgePosition: {
    top: '14%',
    right: '20%'
  }
}));

export default ChangeOfOwnershipGDC;
