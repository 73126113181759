import React from 'react';

const TransactionsIcon = props => {
  return (
    <svg
      width={props.size || 20}
      height={props.size || 20}
      viewBox="0 0 20 16.608"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        data-name="Sustracci\xF3n 1"
        d="M11.123 13.674h-9.21A1.9 1.9 0 010 11.795V1.878A1.9 1.9 0 011.913 0h14.075A1.9 1.9 0 0117.9 1.878v6.275a4.479 4.479 0 00-.637-.47V1.878A1.265 1.265 0 0015.988.626H1.913A1.265 1.265 0 00.638 1.878v9.917a1.265 1.265 0 001.275 1.253H10.8a4.341 4.341 0 00.32.625z"
      />
      <g
        data-name="Elipse 196"
        transform="translate(10.502 7.11)"
        fill="none"
        stroke="currentColor"
        strokeWidth={0.5}
      >
        <circle cx={4.749} cy={4.749} r={4.749} stroke="none" />
        <circle cx={4.749} cy={4.749} r={4.499} />
      </g>
      <path
        d="M16.705 11.271l1.175 1.175-1.175 1.175m1.012-1.175h-2.382"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
      />
      <path
        data-name="arrow-forward-outline"
        d="M13.797 10.097l-1.175 1.175 1.175 1.175m-1.012-1.175h2.381"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.5}
      />
      <path
        data-name="Trazado 606"
        d="M.258 4.071h17.639M2.47 7.516h2.035v.848H2.47z"
        fill="#104eb2"
        stroke="currentColor"
        strokeLinejoin="round"
        strokeWidth={0.8}
      />
    </svg>
  );
};

export default TransactionsIcon;
