import React, { useContext } from 'react';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import { UserContext } from '../../../Contexts/UserContext';

import MobileFooter from './MobileFooter';
import WebFooter from './WebFooter/WebFooter';

const Footer = props => {
  const currentUser = useContext(UserContext);
  const isWebSize = isWidthUp('sm', props.width);
  if (isWebSize || !currentUser) {
    return <WebFooter />;
  }
  return <MobileFooter />;
};

export default withWidth()(Footer);
