import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import MyContractsGDC from './MyContracts.GDC';
import MyContractsGDG from './MyContracts.GDG';

const MyContracts = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <MyContractsGDC {...props} />;
    case Portal.TripleA:
    case Portal.Gasguajira:
      return <MyContractsGDG {...props} />;
    case Portal.Efigas:
      return <MyContractsGDC {...props} />;
    default:
      return <></>;
  }
};

export default MyContracts;
