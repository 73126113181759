import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { ButtonBase, Tooltip, Typography } from '@material-ui/core';

const ImageTextButton = props => {
  const {
    tooltipText = '',
    text,
    backgroundImage,
    Icon,
    iconColor = '#FFFFFF',
    disabled = false,
    onClick
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Tooltip title={tooltipText}>
        <ButtonBase
          focusRipple
          className={clsx(classes.image, {
            [classes.disabled]: disabled
          })}
          focusVisibleClassName={classes.focusVisible}
          onClick={onClick}
          disabled={disabled}
        >
          <span
            className={classes.imageSrc}
            style={{
              backgroundImage: `url(${backgroundImage})`
            }}
          />
          <span className={classes.imageBackdrop} />
          <span className={classes.imageButton}>
            {Icon && <Icon className={classes.icon} color={iconColor} />}
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              className={classes.imageTitle}
            >
              {text}
              <span className={classes.imageMarked} />
            </Typography>
          </span>
        </ButtonBase>
      </Tooltip>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    borderRadius: theme.spacing(1)
  },
  icon: {
    color: '#FFFFFF',
    height: 28,
    width: 28,
    marginBottom: theme.spacing(1.5)
  },
  image: {
    width: 290,
    maxWidth: 290,
    height: 123,
    position: 'relative',
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15
      },
      '& $imageMarked': {
        opacity: 0
      }
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      height: 100
    }
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white
  },
  focusVisible: {},
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%'
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity')
  },
  imageTitle: {
    position: 'relative',
    padding: theme.spacing(2),
    color: theme.palette.common.white,
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.2
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      visibility: 'hidden'
    }
  },
  disabled: { filter: 'grayscale(100%)' }
}));

export default ImageTextButton;
