import React, {
  Fragment,
  useState,
  useContext,
  useCallback,
  useEffect
} from 'react';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

import { UpdateContract } from '../../../../API/Contracts/ContractsAPI';
import {
  UserContext,
  UserDispatchContext
} from '../../../../Contexts/UserContext';
import { ContractsRefreshContext } from '../../../../Contexts/ContractsContext';

import BaseButton from '../../../../Components/Buttons/BaseButton';
import BaseDialog from '../../../../Components/Dialogs/BaseDialog';
import TextInput from '../../../../Components/Inputs/TextInput';
import SelectInput from '../../../../Components/Inputs/SelectInput';

import {
  redirectOnAuthFailure,
  extractErrorMessage
} from '../../../../Utils/Errors/Errors';
import { ICON_SELECT_OPTIONS } from '../../contract_enums';
import { SlideUpTransition } from '../../../../Components/Transitions/Transitions';
import * as yup from 'yup';
import { Company } from '../../../../Configs/general';
import { logoutUser } from '../../../../Utils/User/Actions';

const editContractSchema = yup.object({
  aliasName: yup
    .string()
    .trim()
    .max(12, 'Debe tener 12 letras o menos.')
    .required('Ingresa un alias'),
  aliasIcon: yup.number().required('Selecciona un ícono')
});

const EditContractGDG = props => {
  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const refreshContracts = useContext(ContractsRefreshContext);

  const { contract, open, setDialog, setAlert } = props;

  // * STATE HOOKS
  const [aliasName, setAliasName] = useState(contract.alias);
  const [aliasIcon, setAliasIcon] = useState(contract.icon);
  const [sendInvoice, setSendInvoice] = useState(contract.sendInvoice);
  const [canSubmit, setCanSubmit] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // * OTHER HOOKS
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  // * FUNCTIONS
  const editContractAPI = useCallback(
    async event => {
      event.preventDefault();
      setLoading(true);
      const body = {
        alias: aliasName.trim(),
        icon: aliasIcon,
        sendInvoice,
        origin: {
          url: window.location.pathname
        }
      };
      const response = await UpdateContract(
        currentUser.token,
        contract.id,
        body
      );
      setLoading(false);
      if (response.success) {
        setDialog(false);
        setAlert({
          type: 'success',
          message: `Se ha actualizado ${Company.contractConjugation.regular.singular.article} "${aliasName}"`
        });
        refreshContracts();
      } else {
        if (
          redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
        ) {
          return;
        }
        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
      }
    },
    [
      currentUser,
      contract,
      aliasIcon,
      aliasName,
      sendInvoice,
      refreshContracts,
      setAlert,
      setDialog,
      setCurrentUser
    ]
  );

  const handleClose = useCallback(() => {
    setDialog(false);
  }, [setDialog]);

  const onChangeAliasName = useCallback(event => {
    setAliasName(event.target.value);
    setErrors(errs => ({ ...errs, aliasName: null }));
  }, []);

  const onChangeAliasIcon = useCallback(event => {
    setAliasIcon(event.target.value);
  }, []);

  useEffect(() => {
    try {
      editContractSchema.validateSync({ aliasName, aliasIcon });
      setErrors({});
      setCanSubmit(true);
      setSendInvoice(false);
    } catch (err) {
      setCanSubmit(false);
      setErrors({
        [err.path]: err.errors[0]
      });
    }
  }, [aliasName, aliasIcon]);

  const renderActions = () => {
    return (
      <Fragment>
        <BaseButton
          onClick={handleClose}
          variant="outlined"
          color="primary"
          size="small"
        >
          Cancelar
        </BaseButton>
        <BaseButton
          onClick={editContractAPI}
          type="submit"
          form="editContractForm"
          color="primary"
          disabled={!canSubmit || loading}
          size="small"
          autoFocus
        >
          Editar
        </BaseButton>
      </Fragment>
    );
  };

  const renderContent = () => {
    return (
      <div className={classes.content}>
        <form id="editContractForm" onSubmit={editContractAPI}>
          <div className={classes.alias}>
            <SelectInput
              className={classes.aliasIcon}
              value={aliasIcon}
              onChange={onChangeAliasIcon}
              label={'Icono'}
              options={ICON_SELECT_OPTIONS}
            />
            <TextInput
              autoFocus
              className={classes.aliasText}
              value={aliasName}
              error={Boolean(errors.aliasName)}
              helperText={errors.aliasName}
              onChange={onChangeAliasName}
              label={`Alias de ${Company.contractConjugation.regular.singular.main}`}
            />
          </div>
        </form>
      </div>
    );
  };

  return (
    <Fragment>
      <BaseDialog
        open={open}
        handleClose={handleClose}
        title={`Editar ${Company.contractConjugation.regular.singular.main}`}
        actions={renderActions}
        content={renderContent}
        fullScreen={smallScreen}
        TransitionComponent={smallScreen ? SlideUpTransition : undefined}
      />
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 14,
    color: theme.palette.text.primary
  },
  content: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  alias: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  aliasIcon: {
    minWidth: 67
  },
  aliasText: {
    minWidth: 150,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      minWidth: 250
    }
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      flexDirection: 'row'
    }
  },
  switchText: {
    fontSize: 14,
    paddingRight: 12,
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      paddingRight: 12
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginBottom: theme.spacing(3)
    }
  }
}));

export default EditContractGDG;
