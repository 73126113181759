const config = {
  general: {
    portal: process.env.REACT_APP_PORTAL
  },
  api: {
    url: process.env.REACT_APP_API_DOMAIN
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    relaseVersion: process.env.REACT_APP_RELEASE_VERSION
  },
  google: {
    analyticsId: process.env.REACT_APP_ANALYTICS_ID,
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    maps: {
      key: process.env.REACT_APP_GOOGLE_MAPS_KEY
    }
  },
  bulletTrain: {
    id: process.env.REACT_APP_BULLET_TRAIN_ID
  },
  campaign: {
    name: process.env.REACT_APP_CAMPAIGN_NAME,
    game: {
      url: process.env.REACT_APP_GAME_URL,
      link: process.env.REACT_APP_GAME_CAMPAIGN_LINK
    }
  },
  facebook: {
    appId: process.env.REACT_APP_FACEBOOK_APP_ID
  },
  recaptcha: {
    siteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
    disableValidation: process.env.REACT_APP_DISABLE_RECAPTCHA_VALIDATION,
    siteKeyV3: process.env.REACT_APP_RECAPTCHA_V3_SITE_KEY
  },
  apple: {
    clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
    redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URI
  }
};

const Portal = {
  Gascaribe: 'GDC',
  Gasguajira: 'GDG',
  Efigas: 'EFG'
};

export { Portal, config };
