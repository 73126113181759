/* eslint-disable max-nested-callbacks */
/* eslint-disable array-callback-return */
import React, { useState, useCallback, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Grid,
  Typography,
  Divider,
  useMediaQuery,
  Button,
  useTheme
} from '@material-ui/core';

import BackIcon from '../../../Components/CustomIcons/BackIcon';

import DebtCardHeader from '../Components/DebtCardHeader';
import ContractDebtInfoDialog from '../../../Components/Dialogs/ContractDebtInfoDialog';
import ContractDebtDialogContent from '../Components/ContractDebtDialogContent';
import GenerationStep from '../../Duplicates/DuplicateSteps/GenerationStep/GenerationStep';

import { numberWithDots } from '../../../Utils/Format/MoneyFormat';
import { renderContractTypeDescription } from '../../Contracts/contract_enums';
import { formatAddress } from '../../../Utils/Format/Address';
import { ROUTE_NAMES } from '../../../Routes/Routes';
import { history } from '../../../Routes/history';
import { getCurrentDebtData } from '../helpers/helpers';

import clsx from 'clsx';
import ModalProgress from '../../../Components/Progress/Modal/ModalProgress';
import { GenerateDuplicateCouponAPI } from '../../../API/Coupons/CouponsAPI';
import { ALERT_TYPE } from '../../../Components/Alerts/alert_enums';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';
import { InvoicePeriod } from '../../../Enums/invoices';
import { extractErrorMessage } from '../../../Utils/Errors/Errors';
import ButtonWithIcon from '../../../Components/Buttons/ButtonWithIcon';
import DebtPartialPaymentDialog from '../DebtPartialPayment/DebtPartialPaymentDialog';
import { Company } from '../../../Configs/general';
import { GeneratePartialPaymentCouponAPI } from '../../../API/Debts/DebtsAPI';
import { GetInvoicesAPI } from '../../../API/Invoices/InvoicesAPI';

const ContractCurrentDebtCard = props => {
  const {
    contract,
    title,
    banner,
    debtsData = {},
    negotiationsData = null,
    authToken,
    canRefinance,
    disableRefinancing,
    punishedProductIds,
    brillaDebtValueToPay
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  // * CONTEXT HOOKS
  const setAlert = useContext(AlertsDispatchContext);

  //* STATE HOOKS
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [
    openDebtPartialPaymentDialog,
    setOpenDebtPartialPaymentDialog
  ] = useState(false);
  const [loading, setLoading] = useState(false);
  const [couponData, setCouponData] = useState(null);
  const [invoicesData, setInvoicesData] = useState(null);
  //* OTHER HOOKS
  const isMediumScreenSize = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );
  const isSmallScreenSize = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  // * CALLBACKS
  const onHelpClick = useCallback(() => {
    setHelpDialogOpen(true);
  }, []);

  const goToRefinancing = useCallback(
    event => {
      event.preventDefault();

      history.push(ROUTE_NAMES.refinancingCurrent, {
        selectedContract: contract,
        data: getCurrentDebtData(debtsData, negotiationsData),
        punishedIds: punishedProductIds
      });
    },
    [contract, debtsData, negotiationsData, punishedProductIds]
  );

  const goToPayments = useCallback(
    async event => {
      if (invoicesData && invoicesData.length) {
        if (debtsData.currentValue === invoicesData[0].couponValue) {
          history.push(ROUTE_NAMES.payments, {
            type: 'contract',
            value: contract.id
          });
          return;
        }
      }
      const origin = {
        componentId: event.currentTarget.id,
        url: ROUTE_NAMES.currentDebtSummary
      };
      event.preventDefault();
      setLoading(true);
      const response = await GenerateDuplicateCouponAPI(
        contract.id,
        InvoicePeriod.Current,
        authToken,
        origin
      );

      if (response.success) {
        const duplicatedCoupon = response.data.data;

        setCouponData({
          data: {
            ...duplicatedCoupon,
            period: InvoicePeriod.Current,
            isCurrentDebt: true
          }
        });

        setLoading(false);
      } else {
        setLoading(false);
        setAlert({
          type: ALERT_TYPE.ERROR,
          message: extractErrorMessage(response).message
        });
      }
    },
    [authToken, contract.id, setAlert, invoicesData, debtsData.currentValue]
  );

  const handleClose = useCallback(() => {
    setCouponData(null);
  }, [setCouponData]);

  const canPayBrilla = brillaDebtValueToPay > 0;

  const contractDialogContent = () => (
    <ContractDebtDialogContent
      data={debtsData}
      contract={contract}
      contractAddress={formatAddress(contract, true)}
      contractType={renderContractTypeDescription(contract.type)}
    />
  );
  const onClosePartialDebts = useCallback(() => {
    setOpenDebtPartialPaymentDialog(false);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const invoicesResponse = await GetInvoicesAPI(
        contract.id,
        null,
        authToken
      );
      if (invoicesResponse.success) {
        const responseData = invoicesResponse.data.data;
        setInvoicesData(responseData);
      } else {
        setInvoicesData(null);
      }
    };
    fetchData();
  }, [contract.id, authToken]);

  return (
    <>
      {openDebtPartialPaymentDialog && (
        <DebtPartialPaymentDialog
          open={openDebtPartialPaymentDialog}
          onClose={onClosePartialDebts}
          requestCallback={GeneratePartialPaymentCouponAPI}
          paymentData={{
            contractId: contract.id,
            maxValue: brillaDebtValueToPay
          }}
          description={
            <Typography className={classes.dialogDescription}>
              Ingresa el valor que deseas abonar. Recuerda que este cupón se
              aplicará a tu deuda actual de Brilla (servicios financieros).
            </Typography>
          }
        />
      )}
      {loading && <ModalProgress message="Generando cupón..." />}
      {couponData && (
        <GenerationStep
          title={'Cupón de pago para tu deuda actual'}
          open={true}
          step={couponData}
          onClose={handleClose}
        />
      )}
      <div
        className={classes.card}
        style={{ backgroundImage: `url(${banner})` }}
      >
        <ContractDebtInfoDialog
          open={helpDialogOpen && isMediumScreenSize}
          title={`Información ${Company.contractConjugation.regular.singular.contraction}`}
          onClose={() => setHelpDialogOpen(false)}
          content={contractDialogContent}
        />

        <DebtCardHeader
          data={debtsData}
          title={title}
          onHelpClick={onHelpClick}
          currentSummary
        />
        {isMediumScreenSize && (
          <Grid container justify="flex-end" alignItems="center">
            <Grid item xs={12}>
              <Typography className={clsx(classes.label, classes.alignRight)}>
                Saldo total
              </Typography>
              <Typography
                className={clsx(
                  classes.contractData,
                  classes.total,
                  classes.alignRight
                )}
              >
                {numberWithDots(debtsData.currentValue)}
              </Typography>
            </Grid>
          </Grid>
        )}

        {!isMediumScreenSize && (
          <Grid container direction="row" alignItems="baseline">
            <Grid item xs={12} sm={6} md={4}>
              <Typography className={classes.label}>
                {Company.contractConjugation.capitalized.singular.associate} a
                la deuda
              </Typography>
              <Typography className={classes.contractData}>
                {contract.alias} - {contract.id}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={5}
              className={classes.contractAddress}
            >
              <Typography className={classes.label}>
                Dirección{' '}
                {Company.contractConjugation.regular.singular.contraction}
              </Typography>
              <Typography className={classes.contractData}>
                {formatAddress(contract, true)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <Typography className={clsx(classes.label, classes.alignRight)}>
                Saldo total
              </Typography>
              <Typography
                className={clsx(
                  classes.contractData,
                  classes.total,
                  classes.alignRight
                )}
              >
                {numberWithDots(debtsData.currentValue)}
              </Typography>
            </Grid>
          </Grid>
        )}
        <div>
          <Divider className={classes.divider} />
          {isSmallScreenSize ? (
            <Grid
              item
              container
              justify="space-between"
              className={classes.buttonContainer}
            >
              {canRefinance && (
                <Grid item xs={12} className={classes.buttonContainerItem}>
                  <Button
                    fullWidth
                    className={clsx(
                      classes.button,
                      classes.secondaryActionButton
                    )}
                    onClick={goToRefinancing}
                    disabled={disableRefinancing}
                  >
                    Acuerdo de pago
                  </Button>
                </Grid>
              )}
              {canPayBrilla && (
                <Grid item xs={12} className={classes.buttonContainerItem}>
                  <Button
                    fullWidth
                    id="to-pay-debt"
                    className={clsx(classes.button, classes.mainActionButton)}
                    onClick={() => setOpenDebtPartialPaymentDialog(true)}
                  >
                    Abonar a Brilla
                  </Button>
                </Grid>
              )}
              <Grid item xs={12} className={classes.buttonContainerItem}>
                <Button
                  id="MyDebts_CurrentDebtDetail_payment_button_mobile"
                  fullWidth
                  className={clsx(classes.button, classes.mainActionButton)}
                  color="primary"
                  size="small"
                  variant="outlined"
                  onClick={goToPayments}
                >
                  Pagar deuda
                  <BackIcon size={16} className={classes.arrowIcon} />
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              justify="space-between"
              wrap="nowrap"
              alignItems="center"
            >
              {!isMediumScreenSize && (
                <Grid item sm={3} className={classes.contractTypeContainer}>
                  <Typography className={classes.label}>
                    {`Tipo de ${
                      Company.contractConjugation.regular.singular.main
                    }: ${renderContractTypeDescription(contract.type)}`}
                  </Typography>
                </Grid>
              )}

              <Grid
                item
                container
                justify="flex-end"
                sm={isMediumScreenSize ? 12 : 9}
                className={classes.buttonContainer}
              >
                {canRefinance && (
                  <Grid item className={classes.buttonContainerItem}>
                    <Button
                      className={clsx(
                        classes.button,
                        classes.secondaryActionButton
                      )}
                      size="small"
                      onClick={goToRefinancing}
                      disabled={disableRefinancing}
                    >
                      Acuerdo de pago
                    </Button>
                  </Grid>
                )}
                {canPayBrilla && (
                  <Grid item className={classes.buttonContainerItem}>
                    <ButtonWithIcon
                      id="Debts_current_partial_payment_button"
                      className={clsx(classes.button, classes.mainActionButton)}
                      onClick={() => setOpenDebtPartialPaymentDialog(true)}
                      buttonText="Abonar a Brilla"
                      hideBadge={true}
                    >
                      <BackIcon size={16} className={classes.arrowIcon} />
                    </ButtonWithIcon>
                  </Grid>
                )}
                <Grid
                  item
                  className={clsx(
                    classes.buttonContainerItem,
                    classes.paddingLeft
                  )}
                >
                  <ButtonWithIcon
                    id="MyDebts_CurrentDebtDetail_payment_button"
                    className={clsx(classes.button, classes.mainActionButton)}
                    onClick={goToPayments}
                    buttonText="Pagar deuda"
                    hideBadge={true}
                  >
                    <BackIcon size={16} className={classes.arrowIcon} />
                  </ButtonWithIcon>
                </Grid>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: theme.custom.borderRadius,
    backgroundColor: theme.palette.background.cardDark,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: 'white',
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      padding: theme.spacing(2, 2, 3, 2)
    }
  },
  label: {
    fontSize: 12,
    fontWeight: 500
  },
  contractData: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.2,
    textTransform: 'capitalize'
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.divider,
    margin: theme.spacing(3, 0, 3, 0)
  },
  total: {
    fontSize: 20
  },
  alignRight: {
    textAlign: 'right'
  },
  button: {
    height: 40,
    width: '100%',
    fontSize: 12,
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontWeight: 600,
    color: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.white}`,
    borderRadius: theme.custom.borderRadius,
    padding: theme.spacing(1.5, 2),
    boxShadow: theme.custom.boxShadow,
    '&:hover': {
      filter: 'brightness(0.9)'
    }
  },
  mainActionButton: {
    color: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.contrastText} !important`,
    marginRight: 0
  },
  secondaryActionButton: {
    /* For Safari 9 to 16.3 */
    '-webkit-backdrop-filter': 'blur(5px)',
    backdropFilter: 'blur(5px)',
    marginRight: theme.spacing(0),
    '&:hover': {
      backgroundColor: 'rgba(255,255,255, 0.1)'
    },
    '&:disabled': {
      boxShadow: 'none',
      border: 'none',
      color: 'rgba(0, 0, 0, 0.26)',
      backgroundColor: 'rgba(0, 0, 0, 0.12)'
    }
  },
  arrowIcon: {
    transform: 'rotate(180deg)',
    fill: theme.palette.primary.main,
    marginLeft: theme.spacing(0.5)
  },
  contractAddress: {
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      paddingRight: theme.spacing(1)
    }
  },
  buttonContainer: {
    gap: `${theme.spacing(2)}px`
  },
  buttonContainerItem: {
    flexGrow: 1,
    flexBasis: 140,
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      maxWidth: 148
    }
  },
  contractTypeContainer: {
    paddingRight: theme.spacing()
  },
  paddingLeft: {
    paddingLeft: theme.spacing()
  }
}));

export default ContractCurrentDebtCard;
