import React, { Fragment, useState, useContext, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Breadcrumbs,
  Link,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { Link as RouterLink } from 'react-router-dom';
import _get from 'lodash/get';
import clsx from 'clsx';

import PersonalDataStep from '../Steps/PersonalDataStep';
import PaymentPlanStep from '../Steps/DeferredDebt/PaymentPlanStep';
import TermsStep from '../Steps/TermsStep';
import SummaryStep from '../Steps/SummaryStep';
import WaitStep from '../Steps/WaitForRequest';
import Stepper from '../../../../Components/Stepper/Stepper';
import FooterStepper from '../../../../Components/Stepper/FooterStepper';
import DeferredSidebar from '../../Components/Deferred/DeferredSidebar';
import ConceptsSummaryCard from '../../Components/ConceptsSummaryCard';

import { FORM_ID, REFINANCE_DEFERRED_STEPS } from '../Refinance_enums';

import { StepperProvider, Step } from '../../../../Contexts/StepperContext';
import { AlertsDispatchContext } from '../../../../Contexts/AlertsContext';
import {
  ContractSelectedContext,
  ContractsContext
} from '../../../../Contexts/ContractsContext';
import { UserContext } from '../../../../Contexts/UserContext';

import { ROUTE_NAMES } from '../../../../Routes/Routes';
import { history } from '../../../../Routes/history';

import { isProductTypeFinancingServices } from '../../myDebts_enums';

const DeferredDebtRefinancingGDC = props => {
  const { location: locationParams = { state: null } } = props;

  const classes = useStyles();
  const params = locationParams.state;

  // * CONTEXTS
  const contracts = useContext(ContractsContext);
  const selectedContract = useContext(ContractSelectedContext);
  const currentUser = useContext(UserContext);
  const setAlert = useContext(AlertsDispatchContext);

  const authToken = _get(currentUser, 'token');

  // * STATE HOOKS
  const [canSubmit, setCanSubmit] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [disableBack, setDisableBack] = useState(false);
  const [nextButtonText, setNextButtonText] = useState('Siguiente');
  const [goBack, setGoBack] = useState({});
  const [paymentPlan, setPaymentPlan] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [openSidebarDialog, setOpenSidebarDialog] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(true);

  // * OTHER HOOKS
  const theme = useTheme();
  const isMobileSize = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  const isMediumScreenSize = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  // * GLOBAL VARS
  const addedDeferreds = (params && params.addedDeferreds) || [];
  const conceptItemsCount = addedDeferreds && addedDeferreds.length;
  const totalAmount = addedDeferreds.reduce(
    (acc, item) => item.pendingValue + acc,
    0
  );
  const hasFinancingServices = addedDeferreds.some(deferred =>
    isProductTypeFinancingServices(deferred.productTypeId)
  );

  useEffect(() => {
    if (!authToken) {
      history.replace('/');
      return;
    }
    if (!contracts || contracts.length === 0) {
      history.replace(ROUTE_NAMES.contracts);
      return;
    }

    if (
      !params ||
      (params.selectedContract &&
        params.selectedContract.id !== selectedContract.id)
    ) {
      history.replace(ROUTE_NAMES.debtStatus);
      return;
    }
  }, [contracts, selectedContract, authToken, params]);

  const openSidebarDialogHandler = () => {
    setOpenSidebarDialog(true);
  };

  const closeSidebarDialogHandler = () => {
    setOpenSidebarDialog(false);
  };

  if (!params) {
    return <Fragment></Fragment>;
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <div
          className={clsx(classes.contentContainer, classes.centerContent, {
            [classes.mobilePadding]: isMediumScreenSize
          })}
        >
          <div className={clsx(classes.container, classes.contentContainer)}>
            <Breadcrumbs
              aria-label="breadcrumb"
              className={classes.breadcrumbs}
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Link
                className={classes.link}
                color="textPrimary"
                component={RouterLink}
                to="/"
                underline="always"
              >
                Inicio{' '}
              </Link>
              <Link
                className={classes.link}
                color="textPrimary"
                component={RouterLink}
                to="/contracts/debt"
                underline="always"
              >
                Mis deudas{' '}
              </Link>
              <Link
                className={classes.link}
                color="textPrimary"
                component={RouterLink}
                to={ROUTE_NAMES.deferredDebtSummary}
                underline="always"
              >
                Deuda financiada{' '}
              </Link>

              <Typography color="primary">Refinanciar deuda</Typography>
            </Breadcrumbs>
          </div>
          <div
            className={clsx(classes.stepperContainer, classes.centerContent)}
          >
            <Stepper
              steps={REFINANCE_DEFERRED_STEPS}
              currentStep={currentStep}
            />
          </div>

          {isMobileSize && (
            <div
              className={clsx(
                classes.innerContentContainer,
                classes.centerContent
              )}
            >
              <ConceptsSummaryCard
                itemsCount={conceptItemsCount}
                totalAmount={totalAmount}
                title="Items para financiar"
                action="financiar"
                onDetailsClick={openSidebarDialogHandler}
              />
            </div>
          )}

          <StepperProvider>
            <Step>
              <div
                className={clsx(
                  classes.innerContentContainer,
                  classes.centerContent
                )}
              >
                <PersonalDataStep
                  formId={FORM_ID}
                  setCanSubmit={setCanSubmit}
                  setGoBack={setGoBack}
                  setCurrentStep={setCurrentStep}
                  setNextButtonText={setNextButtonText}
                  setAlert={setAlert}
                  addedDeferreds={addedDeferreds}
                  paramsSelectedContract={params.selectedContract}
                  fromDeferredDebt={params.fromDeferredDebt}
                  isMobileSize={isMobileSize}
                  setSidebarVisible={setSidebarVisible}
                  selectedContract={selectedContract}
                />
              </div>
            </Step>
            <Step>
              <PaymentPlanStep
                formId={FORM_ID}
                setCanSubmit={setCanSubmit}
                setDisableBack={setDisableBack}
                setGoBack={setGoBack}
                setAlert={setAlert}
                setCurrentStep={setCurrentStep}
                setNextButtonText={setNextButtonText}
                addedDeferreds={addedDeferreds}
                setPaymentPlan={setPaymentPlan}
                paymentPlan={paymentPlan}
                setFormErrors={setFormErrors}
                isMobileSize={isMobileSize}
                setSidebarVisible={setSidebarVisible}
                selectedContract={selectedContract}
              />
            </Step>
            <Step>
              <TermsStep
                formId={FORM_ID}
                setCanSubmit={setCanSubmit}
                setGoBack={setGoBack}
                setCurrentStep={setCurrentStep}
                setNextButtonText={setNextButtonText}
                hasFinancingServices={hasFinancingServices}
                setSidebarVisible={setSidebarVisible}
                selectedContract={selectedContract}
              />
            </Step>
            <Step>
              <SummaryStep
                formId={FORM_ID}
                canSubmit={canSubmit}
                setCanSubmit={setCanSubmit}
                setGoBack={setGoBack}
                setCurrentStep={setCurrentStep}
                setNextButtonText={setNextButtonText}
                setAlert={setAlert}
                setSidebarVisible={setSidebarVisible}
                selectedContract={selectedContract}
              />
            </Step>
            <Step>
              <WaitStep
                formId={FORM_ID}
                setCanSubmit={setCanSubmit}
                setGoBack={setGoBack}
                setCurrentStep={setCurrentStep}
                setNextButtonText={setNextButtonText}
                setAlert={setAlert}
                canSubmit={canSubmit}
                setSidebarVisible={setSidebarVisible}
                selectedContract={selectedContract}
              />
            </Step>
          </StepperProvider>
        </div>

        {sidebarVisible && (
          <DeferredSidebar
            paymentPlan={paymentPlan}
            addedDeferreds={addedDeferreds}
            selectedContract={params.selectedContract}
            title="Items para financiar"
            errors={formErrors}
            isMobileSize={isMobileSize}
            openDialog={openSidebarDialog}
            onCloseDialog={closeSidebarDialogHandler}
            forPayment
          />
        )}

        <FooterStepper
          targetForm={FORM_ID}
          disabledNext={!canSubmit}
          disabledBack={disableBack}
          goBack={goBack}
          nextButtonText={nextButtonText}
        />
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: `calc(${
      theme.custom.footerHeight.stepper
    }px + ${theme.spacing(5)}px)`
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2)
  },
  breadcrumbs: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium,
    '& *': { fontSize: 'inherit' }
  },
  stepperContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(3)
    }
  },
  container: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3)
    }
  },
  contentContainer: {
    maxWidth: 768
  },
  innerContentContainer: {
    maxWidth: 566
  },
  centerContent: {
    margin: '0 auto'
  },
  link: {
    color: theme.palette.text.greyDark
  },
  mobilePadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

export default DeferredDebtRefinancingGDC;
