import axios from 'axios';
import { config } from '../../Configs';

export const GetCategoriesAPI = async () => {
  try {
    const response = await axios.get(`${config.api.url}/cms/categories`);
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};
export const GetArticlesAPI = async () => {
  try {
    const response = await axios.get(`${config.api.url}/cms/articles`);
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GetArticleAPI = async id => {
  try {
    const response = await axios.get(`${config.api.url}/cms/articles/${id}`);
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GetArticleVotesAPI = async id => {
  try {
    const response = await axios.get(
      `${config.api.url}/cms/articles/votes/${id}`
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const PostArticleVote = async (id, vote) => {
  try {
    const response = await axios.post(
      `${config.api.url}/cms/articles/votes/${id}`,
      { vote }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};
