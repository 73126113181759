import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {
  Typography,
  Card,
  Checkbox,
  FormControlLabel,
  Grid
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { UserContext } from '../../Contexts/UserContext';
import { AlertsDispatchContext } from '../../Contexts/AlertsContext';
import * as Yup from 'yup';
import clsx from 'clsx';
import TextInput from '../../Components/Inputs/TextInput';
import BaseButton from '../../Components/Buttons/BaseButton';
import CompositeSelectInput from '../../Components/Inputs/CompositeSelectInput';
import { SignupAPI } from '../../API/UserAPI';
import {
  extractErrorMessage,
  extractFormErrorsYup
} from '../../Utils/Errors/Errors';
import Recaptcha from '../../Components/Captcha/Recaptcha';
import ModalProgress from '../../Components/Progress/Modal/ModalProgress';
import ShowPassword from '../../Components/Adornments/ShowPassword';
import { ROUTE_NAMES } from '../../Routes/Routes';
import { TERMS, TERMS_ROUTES } from '../Information/Information/Information';
import { isPrintableAscii } from '../../Utils/Misc/String';
import { config } from '../../Configs';
import { PrivacyPoliciesLink } from '../../Configs/Links';

const IDENTIFICATION_OPTIONS = [
  { label: 'CC', value: 'CC' },
  { label: 'PP', value: 'PP' },
  { label: 'CE', value: 'CE' },
  { label: 'TI', value: 'TI' }
];

const IDENTIFICATION_OPTIONS_BIG = [
  { label: 'Cédula', value: 'CC' },
  { label: 'Pasaporte', value: 'PP' },
  { label: 'Cédula de extranjería', value: 'CE' },
  { label: 'Tarjeta de identidad', value: 'TI' }
];

const signupSchema = Yup.object({
  firstName: Yup.string()
    .trim()
    .max(60, 'Debe tener 60 letras o menos')
    .required('Ingresa tu nombre'),
  lastName: Yup.string()
    .trim()
    .max(60, 'Debe tener 60 letras o menos')
    .required('Ingresa tus apellidos'),
  identification: Yup.mixed().when('identificationType', {
    is: 'PP',
    then: Yup.string()
      .trim()
      .matches(/^[0-9a-zA-Z]*$/, 'Ingresa un número de pasaporte válido'),
    otherwise: Yup.number()
      .typeError('Ingresa un número de identificación válido')
      .positive('Ingresa un número de identificación válido')
      .nullable()
      .transform((value, originalValue) => {
        if (originalValue.trim() === '') {
          return null;
        }

        return value;
      })
  }),
  identificationType: Yup.string().oneOf(
    ['CC', 'PP', 'CE', 'TI'],
    'Debes escoger una opción correcta'
  ),
  email: Yup.string()
    .trim()
    .lowercase()
    .email('Debes ingresar un correo válido')
    .test(
      'is-ascii',
      'Este campo no puede contener tildes ni caracteres especiales',
      isPrintableAscii
    )
    .required('Ingresa un correo'),
  password: Yup.string()
    .trim()
    .required('Debes ingresar una contraseña')
    .min(8, 'Debe tener mínimo 8 caracteres'),
  confirmPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref('password')], 'Las contraseñas deben coincidir'),
  terms: Yup.bool().oneOf([true], 'Debes aceptar los términos y condiciones')
});

const EMPTY_VALUES = {
  firstName: null,
  lastName: null,
  identification: null,
  identificationType: null,
  email: null,
  password: null,
  confirmPassword: null,
  terms: null
};

// eslint-disable-next-line complexity
const Signup = ({ history, width }) => {
  // * OTHER HOOKS
  const classes = useStyles();

  const inputRef = useRef(null);

  // * STATE HOOKS
  const currentUser = useContext(UserContext);
  const setAlert = useContext(AlertsDispatchContext);

  const [passwordShown, setShowPassword] = useState(false);
  const [confirmPasswordShown, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [identification, setIdentification] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [terms, setTerms] = useState(false);
  const [errors, setErrors] = useState(EMPTY_VALUES);
  const [dirty, setDirty] = useState(EMPTY_VALUES);
  const [blur, setBlur] = useState(EMPTY_VALUES);
  const [captcha, setCaptcha] = useState(null);
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const [canSubmit, setCanSubmit] = useState(false);

  const recaptchaRef = useRef(null);

  const { watch, control } = useForm({
    mode: 'onChange'
  });

  const identificationType = watch().identificationType;
  // * FUNCTIONS
  useEffect(() => {
    if (currentUser) {
      history.replace('/');
    }
  }, [currentUser, history]);

  useEffect(() => {
    try {
      signupSchema.validateSync(
        {
          firstName,
          lastName,
          identification,
          identificationType,
          email,
          password,
          confirmPassword,
          terms
        },
        { abortEarly: false }
      );
      setErrors({});
      setCanSubmit(true);
    } catch (err) {
      setCanSubmit(false);
      setErrors(extractFormErrorsYup(err));
    }
  }, [
    firstName,
    lastName,
    identification,
    identificationType,
    email,
    password,
    confirmPassword,
    terms
  ]);

  const togglePasswordShow = useCallback(() => {
    setShowPassword(!passwordShown);
  }, [passwordShown]);

  const toggleConfirmPasswordShow = useCallback(() => {
    setShowConfirmPassword(!confirmPasswordShown);
  }, [confirmPasswordShown]);

  const onChangeFirstName = useCallback(event => {
    setDirty(d => ({ ...d, firstName: true }));
    setFirstName(event.target.value);
  }, []);

  const onChangeLastName = useCallback(event => {
    setDirty(d => ({ ...d, lastName: true }));
    setLastName(event.target.value);
  }, []);

  const onChangeIdentification = useCallback(event => {
    setDirty(d => ({ ...d, identification: true }));
    setIdentification(event.target.value);
  }, []);

  const onChangeEmail = useCallback(event => {
    setDirty(d => ({ ...d, email: true }));
    setEmail(event.target.value);
  }, []);

  const onChangePassword = useCallback(event => {
    setDirty(d => ({ ...d, password: true }));
    setPassword(event.target.value);
  }, []);

  const onChangeConfirmPassword = useCallback(event => {
    setDirty(d => ({ ...d, confirmPassword: true }));
    setConfirmPassword(event.target.value);
  }, []);

  const onChangeTerms = useCallback(() => {
    setDirty(d => ({ ...d, terms: true }));
    setTerms(!terms);
  }, [terms]);

  const onFieldBlur = useCallback(field => {
    const blurMap = b => ({ ...b, [field]: true });
    return () => setBlur(blurMap);
  }, []);

  const onCaptchaResolved = useCallback(token => {
    setCaptcha(token);
  }, []);

  const onCaptchaExpired = useCallback(() => {
    setCaptcha(null);
  }, []);

  const onCaptchaLoaded = useCallback(() => {
    setTimeout(() => {
      setLoadingCaptcha(false);
    }, 1000);
  }, []);

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      setLoading(true);
      const response = await SignupAPI({
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        identification: identification.trim(),
        identificationType: identificationType.trim(),
        email: email.trim(),
        password: password.trim(),
        captcha
      });
      if (!response.success) {
        const error = extractErrorMessage(response);
        if (error.key === 'base') {
          setAlert({
            type: 'error',
            message: error.message
          });
        } else {
          setErrors({ [error.key]: error.message });
        }
        setCanSubmit(false);
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
          setCaptcha(null);
        }
        setLoading(false);
        return;
      }
      history.replace(ROUTE_NAMES.confirmEmail, { email });
    },
    [
      firstName,
      lastName,
      identification,
      identificationType,
      email,
      password,
      captcha,
      history,
      recaptchaRef,
      setAlert
    ]
  );

  const isMobileSize = isWidthDown('xs', width);

  return (
    <Fragment>
      {loadingCaptcha && <ModalProgress message={'Cargando'} />}
      <form
        id="Signup_form_container"
        onSubmit={handleSubmit}
        className={clsx([classes.root])}
      >
        <div className={classes.cardContainer}>
          <Card
            className={clsx([
              classes.mainCard,
              { [classes.captchaLoading]: loadingCaptcha }
            ])}
          >
            <Typography variant="h2" className={classes.title}>
              Registro
            </Typography>
            <div className={classes.formContainer}>
              <Typography variant="subtitle2" className={classes.subtitle}>
                Ingresa tus datos personales
              </Typography>
              <Grid container direction="row" className={classes.section}>
                <Grid item xs={12} sm={6} className={classes.inputContainer}>
                  <TextInput
                    id="Signup_input_firstName"
                    inputRef={inputRef}
                    className={
                      dirty.firstName &&
                      blur.firstName &&
                      Boolean(errors.firstName)
                        ? classes.inputError
                        : classes.input
                    }
                    label="Nombres"
                    value={firstName}
                    onChange={onChangeFirstName}
                    onBlur={onFieldBlur('firstName')}
                    required={true}
                    autoFocus
                    helperText={
                      dirty.firstName && blur.firstName && errors.firstName
                    }
                    error={
                      dirty.firstName &&
                      blur.firstName &&
                      Boolean(errors.firstName)
                    }
                    margin="none"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.inputContainer}>
                  <TextInput
                    id="Signup_input_lastName"
                    className={
                      dirty.lastName &&
                      blur.lastName &&
                      Boolean(errors.lastName)
                        ? classes.inputError
                        : classes.input
                    }
                    label="Apellidos"
                    value={lastName}
                    onChange={onChangeLastName}
                    onBlur={onFieldBlur('lastName')}
                    required={true}
                    helperText={
                      dirty.lastName && blur.lastName && errors.lastName
                    }
                    error={
                      dirty.lastName &&
                      blur.lastName &&
                      Boolean(errors.lastName)
                    }
                    margin="none"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} className={classes.inputContainer}>
                  <CompositeSelectInput
                    inputId="Signup_input_identification"
                    selectId="Signup_input_identificationType"
                    className={
                      dirty.identification &&
                      blur.identification &&
                      Boolean(errors.identification)
                        ? classes.inputError
                        : classes.input
                    }
                    options={
                      isMobileSize
                        ? IDENTIFICATION_OPTIONS
                        : IDENTIFICATION_OPTIONS_BIG
                    }
                    TextInputProps={{
                      label: 'Identificación',
                      value: identification,
                      onChange: onChangeIdentification,
                      onBlur: onFieldBlur('identification'),
                      fullWidth: true,
                      margin: 'none'
                    }}
                    SelectInputProps={{
                      name: 'identificationType',
                      defaultValue: 'CC',
                      control
                    }}
                    error={
                      dirty.identification &&
                      blur.identification &&
                      Boolean(errors.identification)
                    }
                    helperText={
                      dirty.identification &&
                      blur.identification &&
                      errors.identification
                    }
                  />
                </Grid>
              </Grid>
              <Typography variant="subtitle2" className={classes.subtitle}>
                Ingresa tus datos de usuario
              </Typography>
              <Grid container direction="row" className={classes.section}>
                <Grid item xs={12} className={classes.inputContainer}>
                  <TextInput
                    id="Signup_input_email"
                    className={
                      dirty.email && blur.email && Boolean(errors.email)
                        ? classes.inputError
                        : classes.input
                    }
                    label="Correo electrónico"
                    value={email}
                    onChange={onChangeEmail}
                    onBlur={onFieldBlur('email')}
                    required={true}
                    helperText={dirty.email && blur.email && errors.email}
                    error={dirty.email && blur.email && Boolean(errors.email)}
                    margin="none"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.inputContainer}>
                  <TextInput
                    id="Signup_input_password"
                    className={
                      dirty.password &&
                      blur.password &&
                      Boolean(errors.password)
                        ? classes.inputError
                        : classes.input
                    }
                    type={passwordShown ? 'text' : 'password'}
                    label="Contraseña"
                    value={password}
                    onChange={onChangePassword}
                    onBlur={onFieldBlur('password')}
                    required={true}
                    InputProps={{
                      endAdornment: (
                        <ShowPassword
                          passwordShown={passwordShown}
                          togglePasswordShow={togglePasswordShow}
                        />
                      )
                    }}
                    helperText={
                      dirty.password && blur.password && errors.password
                    }
                    error={
                      dirty.password &&
                      blur.password &&
                      Boolean(errors.password)
                    }
                    margin="none"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.inputContainer}>
                  <TextInput
                    id="Signup_input_confirmPassword"
                    className={
                      dirty.confirmPassword &&
                      blur.confirmPassword &&
                      Boolean(errors.confirmPassword)
                        ? classes.inputError
                        : classes.input
                    }
                    type={confirmPasswordShown ? 'text' : 'password'}
                    label="Confirmar contraseña"
                    value={confirmPassword}
                    onChange={onChangeConfirmPassword}
                    required={true}
                    onBlur={onFieldBlur('confirmPassword')}
                    InputProps={{
                      endAdornment: (
                        <ShowPassword
                          passwordShown={confirmPasswordShown}
                          togglePasswordShow={toggleConfirmPasswordShow}
                        />
                      )
                    }}
                    helperText={
                      dirty.confirmPassword &&
                      blur.confirmPassword &&
                      errors.confirmPassword
                    }
                    error={
                      dirty.confirmPassword &&
                      blur.confirmPassword &&
                      Boolean(errors.confirmPassword)
                    }
                    margin="none"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </div>
            <Grid container direction="row" className={classes.termsContainer}>
              <Grid
                item
                xs={12}
                sm={6}
                className={classes.inputContainer}
                style={{ display: 'flex' }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      id="Signup_input_terms"
                      checked={terms}
                      onClick={onChangeTerms}
                      icon={
                        <CheckBoxOutlineBlankIcon
                          fontSize="small"
                          color="primary"
                        />
                      }
                      checkedIcon={
                        <CheckBoxIcon fontSize="small" color="primary" />
                      }
                      disabled={Boolean(currentUser)}
                      value="checkedI"
                    />
                  }
                  label={
                    <Typography className={classes.termsText}>
                      He leído y acepto los{' '}
                      <a
                        className={classes.link}
                        href={`${ROUTE_NAMES.information}/${
                          TERMS_ROUTES[TERMS.introduction]
                        }`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        términos y condiciones
                      </a>{' '}
                      de uso, la{' '}
                      <a href={PrivacyPoliciesLink}>
                        política y aviso de privacidad
                      </a>{' '}
                      y autorizo el tratamiento de mis datos personales.
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.inputContainer}>
                <Recaptcha
                  captchaRef={recaptchaRef}
                  referenceEl={inputRef}
                  heightScale={0.9}
                  locale={'es'}
                  sitekey={config.recaptcha.siteKey}
                  onResolved={onCaptchaResolved}
                  onExpired={onCaptchaExpired}
                  onLoaded={onCaptchaLoaded}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justify="center"
              className={classes.buttonContainer}
            >
              <Grid item sm={12} xs={12}>
                <BaseButton
                  id="Signup_button_submit"
                  loading={loading}
                  disabled={!canSubmit || !Boolean(captcha)}
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  Regístrate
                </BaseButton>
              </Grid>
            </Grid>
          </Card>
        </div>
      </form>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    backgroundColor: theme.palette.background.cardDark,
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
    flexDirection: 'column',
    height: '100%'
  },
  link: {
    color: '#424242',
    fontWeight: 500
  },
  cardContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: '100%'
    }
  },
  mainCard: {
    maxWidth: '800px',
    width: '800px',
    backgroundColor: theme.palette.common.white,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      margin: [[theme.spacing(4), theme.spacing(2)]],
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6)
    }
  },
  section: {
    width: '100%'
  },
  title: {
    margin: [[0, 0, theme.spacing(4), theme.spacing()]],
    [theme.breakpoints.down('lg')]: {
      margin: [[0, 0, theme.spacing(3), theme.spacing()]]
    }
  },
  subtitle: {
    margin: [[0, 0, theme.spacing(2), theme.spacing()]]
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  inputContainer: {
    padding: [[0, theme.spacing(), 0, theme.spacing()]]
  },
  input: {
    marginBottom: 31
  },
  inputError: {
    marginBottom: 14
  },
  spacer: {
    heigth: '100%',
    margin: [[0, theme.spacing(8), 0, theme.spacing(8)]],
    border: '1px solid',
    borderColor: theme.palette.common.black,
    opacity: 0.15,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  selector: {
    width: '30%'
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  footer: {
    backgroundColor: theme.palette.background.dark,
    justifyContent: 'flex-end',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 10
  },
  captchaLoading: {
    visibility: 'hidden'
  },
  termsText: {
    fontSize: 11,
    [theme.breakpoints.up('sm')]: {
      fontSize: 12
    }
  },
  termsContainer: {
    padding: [[0, 0, 33, 0]],
    [theme.breakpoints.up('sm')]: {
      padding: [[0, 0, theme.spacing(3), 0]]
    }
  },
  buttonContainer: {
    paddingRight: theme.spacing(),
    paddingLeft: theme.spacing()
  },
  termsControl: {
    marginTop: theme.spacing(1)
  },
  termsError: {
    marginLeft: 36,
    marginTop: theme.spacing(0.5),
    fontSize: 12
  }
}));

export default withWidth()(Signup);
