import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useLayoutEffect
} from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Fuse from 'fuse.js';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import SimpleDivider from '../../../Components/Dividers/SimpleDivider';
import Title from '../../../Components/Labels/Title';

import CategoryButton from '../../../Components/Buttons/CategoryButton';
import FullSizeProgress from '../../../Components/Progress/FullSize/FullSizeProgress';

import SearchInput from '../../../Components/Inputs/SearchInput';
import { GetCategoriesAPI, GetArticlesAPI } from '../../../API/Faq/FaqAPI';
import FaqActionButton from '../../../Components/Buttons/FaqActionButton';
import NoFuzzyResult from '../../../Components/CustomIcons/IcNoFuzzyResult';
import AlertFullView from '../../../Components/Alerts/AlertFullView';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import WhatsAppIcon from '../../../Components/CustomIcons/WhatsappIcon';
import { OpenNewTab, WhatsAppHelpLinkHandler } from '../../../Utils/Misc/Links';
import PhonesDialog from '../../../Components/Dialogs/PhonesDialog/PhonesDialog';
import { ROUTE_NAMES } from '../../../Routes/Routes';
import { history } from '../../../Routes/history';
import { normalizeURI } from '../../../Utils/Format/URI';

const faqBanner = require('../../../Assets/images/faq_banner.jpg');

const CloserToYouLink = () => {
  OpenNewTab('/closer/support');
};

const faqSchema = yup.object({
  searchBar: yup
    .string()
    .trim()
    .required('*Requerido')
});

const FaqGDC = () => {
  const classes = useStyles();

  // * STATE HOOKS
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [articles, setArticles] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [fuzzyList, setFuzzyList] = useState([]);
  const [userSearch, setUserSearch] = useState('');

  // * FUNCTIONS

  const handleClose = useCallback(() => {
    setOpenDialog(false);
  }, [setOpenDialog]);

  const { control, watch } = useForm({
    validationSchema: faqSchema,
    mode: 'onChange'
  });

  useEffect(() => {
    setLoading(true);
    const getCategoriesAndArticlesRequest = async () => {
      const categoriesResponse = await GetCategoriesAPI();
      if (!categoriesResponse.success) {
        history.replace('/');
        return;
      }

      setCategories(categoriesResponse.data.data);

      const articlesResponse = await GetArticlesAPI();

      if (!articlesResponse.success) {
        history.replace('/');
        return;
      }

      const allArticles = articlesResponse.data.data;
      const filteredArticles =
        allArticles && allArticles.filter(art => art.categoria && art);

      setArticles(filteredArticles);
      setLoading(false);
    };

    getCategoriesAndArticlesRequest();
  }, []);

  const onChangeUserSearch = useCallback(event => {
    setUserSearch(event.target.value);
  }, []);

  const searchSelection = watch().searchBar;

  useLayoutEffect(() => {
    if (searchSelection) {
      const selectedArticle = articles.find(
        art => normalizeURI(art.titulo) === normalizeURI(searchSelection)
      );

      if (!selectedArticle) {
        return;
      }

      goToArticleInfo(selectedArticle);
    }
  }, [articles, searchSelection]);

  useEffect(() => {
    if (!loading && userSearch) {
      const options = {
        includeScore: true,
        keys: [
          { name: 'titulo', weight: 1 },
          { name: 'categoria.nombre', weight: 0.8 }
        ]
      };

      const fuse = new Fuse(articles, options);
      const result = fuse.search(userSearch);
      const filteredResult = result.filter(art => art.score <= 0.6);

      setFuzzyList(filteredResult.slice(0, 8));
    }
  }, [loading, articles, userSearch]);

  const goToCategoryInfo = cat => {
    const categoryInfo = `${cat.id}-${normalizeURI(cat.nombre)}`;
    history.push(`${ROUTE_NAMES.faq}/categories/${categoryInfo}`);
  };

  const goToArticleInfo = art => {
    const articleInfo = `${art.id}-${normalizeURI(art.titulo)}`;
    history.push(`${ROUTE_NAMES.faq}/articles/${articleInfo}`);
  };

  const filteredCategories =
    categories &&
    categories.filter(
      cat => cat.nombre !== 'Top Preguntas' && cat.articulos.length !== 0
    );

  const topArticles =
    articles &&
    articles.filter(
      art => art.categoria && art.categoria.nombre === 'Top Preguntas'
    );

  const renderContent = useCallback(() => {
    if (loading) {
      return;
    }

    if (userSearch && userSearch !== '') {
      return (
        <Fragment>
          <Grid container className={classes.searchResultContainer}>
            <Title
              className={classes.searchText}
              text="Resultados de Búsqueda"
            />
            <Grid container className={classes.fuzzyContainer}>
              {fuzzyList.length > 0 ? (
                <Grid container>
                  {fuzzyList.map((art, i) => (
                    <Fragment key={i}>
                      <Typography
                        onClick={() => goToArticleInfo(art.item)}
                        className={classes.articlesList}
                      >
                        {art.item.titulo}
                      </Typography>
                      <SimpleDivider withoutMargin />
                    </Fragment>
                  ))}
                </Grid>
              ) : (
                <Fragment>
                  <AlertFullView icon={NoFuzzyResult} />
                  <Typography className={classes.noFuzzyResulText}>
                    No se encontraron resultados <br /> para tu búsqueda.
                  </Typography>
                </Fragment>
              )}
            </Grid>
          </Grid>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Grid container className={classes.categoryContainer}>
          <Title
            className={classes.categoryText}
            text="Elige una de nuestras categorías"
          />
          <Grid container className={classes.categoriesContainer}>
            {filteredCategories.map((cat, i) => (
              <CategoryButton
                onClick={() => goToCategoryInfo(cat)}
                className={classes.buttonStyle}
                key={i}
              >
                {cat.nombre}
              </CategoryButton>
            ))}
          </Grid>
        </Grid>
        {topArticles.length > 0 && (
          <Grid container className={classes.topArticlesContainer}>
            <Title className={classes.categoryText} text="Top de Preguntas" />
            <Grid container>
              {topArticles.map((art, i) => (
                <Fragment key={i}>
                  <Typography
                    onClick={() => goToArticleInfo(art)}
                    className={classes.articlesList}
                  >
                    <span className={classes.articlesNumber}>{i + 1}. </span>{' '}
                    {art.titulo}
                  </Typography>
                  <SimpleDivider withoutMargin />
                </Fragment>
              ))}
            </Grid>
          </Grid>
        )}
      </Fragment>
    );
  }, [
    classes,
    filteredCategories,
    fuzzyList,
    loading,
    topArticles,
    userSearch
  ]);

  if (loading) {
    return <FullSizeProgress />;
  }

  return (
    <div className={classes.root}>
      <Fragment>
        <PhonesDialog open={openDialog} onClose={handleClose} />
        <div
          id="card-header-content"
          style={{ backgroundImage: `url(${faqBanner || ''})` }}
          className={classes.cardHeader}
        >
          <Grid container className={classes.bannerContent}>
            <Title
              className={classes.bannerText}
              text="¿En qué podemos ayudarte?"
            />
            <Grid item md={6} sm={8} xs={12} className={classes.searchInput}>
              <SearchInput
                name="searchBar"
                control={control}
                options={articles.map(art => art.titulo)}
                defaultValue=""
                placeholder="Buscar"
                onInputChange={onChangeUserSearch}
                TextInputProps={{
                  value: 'userSearch',
                  required: true,
                  fullWidth: true
                }}
              />
            </Grid>
          </Grid>
        </div>
        <Container maxWidth="md">
          {renderContent()}
          <Grid container spacing={2} className={classes.bottomContainer}>
            <Grid item className={classes.fullWidth} sm={4}>
              <FaqActionButton
                text={'Atención por chat'}
                className={classes.iconButton}
                onClick={WhatsAppHelpLinkHandler}
                icon={<WhatsAppIcon className={classes.iconStyleWhatsApp} />}
              />
            </Grid>
            <Grid item className={classes.fullWidth} sm={4}>
              <FaqActionButton
                text={'Puntos de atención'}
                className={classes.iconButton}
                onClick={CloserToYouLink}
                icon={<LocationOnIcon className={classes.iconStyle} />}
              />
            </Grid>
            <Grid item className={classes.fullWidth} sm={4}>
              <FaqActionButton
                text={'Llámanos'}
                onClick={() => setOpenDialog(true)}
                className={classes.iconButton}
                icon={<PhoneIcon className={classes.iconStyle} />}
              />
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  bannerContent: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center'
  },
  categoryContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  fuzzyContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: theme.spacing(4)
  },
  topArticlesContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8)
  },
  bannerImage: {
    visibility: 'hidden',
    height: 'inherit',
    objectFit: 'cover',
    zIndex: '-1',
    position: 'absolute'
  },
  categoriesContainer: {
    justifyContent: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2)
  },
  cardHeader: {
    display: 'flex',
    color: theme.palette.text.grey,
    paddingTop: theme.spacing(0),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    height: '35vh',
    minHeight: 200
  },
  bannerRoot: {
    borderRadius: 0
  },
  bottomContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex'
  },
  optionTile: {
    display: 'flex',
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center'
  },
  searchInput: {
    width: '100%',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      padding: 0
    }
  },
  buttonStyle: {
    margin: [[theme.spacing(1), theme.spacing(2), theme.spacing(1), 0]],
    borderRadius: '30px',
    backgroundColor: 'white',
    borderColor: '#183F8F',
    borderStyle: 'solid',
    borderWidth: 1,
    color: '#183F8F',
    '&:hover': {
      backgroundColor: '#F0F5FD'
    }
  },
  iconButton: {
    backgroundColor: '#F8F8F8',
    color: '#183F8F',
    fontWeight: 'normal',
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16
    },
    '&:hover': {
      backgroundColor: '#183F8F',
      color: 'white',
      fontWeight: 500
    }
  },
  bannerText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 22,
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: 26
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    }
  },
  categoryText: {
    textAlign: 'center',
    color: '#2F2F2F',
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 18
    }
  },
  searchText: {
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 18
    }
  },
  searchResultContainer: {
    textAlign: 'left',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  fullWidth: {
    width: '100%'
  },
  articlesList: {
    fontSize: 14,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    color: '#707070',
    [theme.breakpoints.up('sm')]: {
      fontSize: 16
    },
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
      fontWeight: 500
    }
  },
  articlesNumber: {
    color: '#183F8F !important'
  },
  noFuzzyResulText: {
    color: '#707070',
    textAlign: 'center',
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16
    },
    marginTop: -theme.spacing(6.25)
  },
  iconStyle: {
    fill: 'currentcolor',
    fontSize: '40px !important'
  },
  iconStyleWhatsApp: {
    fill: 'currentcolor',
    marginRight: 4,
    fontSize: '40px !important'
  },
  breadcrumbs: {
    marginBottom: 16
  },
  breadcrumbsLink: {
    fontSize: 12,
    color: '#2F2F2F',
    '&:hover': {
      fontWeight: 500
    }
  },
  breadcrumbsText: {
    fontSize: 12,
    color: '#2F2F2F',
    textDecoration: 'underline'
  }
}));

export default withWidth()(FaqGDC);
