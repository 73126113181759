import * as React from 'react';

function SvgIcDonationsHeart(props) {
  return (
    <svg
      id="ic_donations_header"
      width={props.size || 20}
      height={props.size || 20}
      viewBox="0 0 56 56"
      xmlSpace="preserve"
      fill="#104eb2"
      {...props}
    >
      <g
        data-name="Grupo 800"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      >
        <path d="M41.683 3.153c-9.165 0-13.68 9.035-13.68 9.035s-4.521-9.035-13.686-9.035A13.646 13.646 0 00.894 16.82C.739 32.253 13.138 43.231 26.729 52.453a2.259 2.259 0 002.541 0c13.59-9.224 25.989-20.2 25.835-35.636A13.646 13.646 0 0041.683 3.153z" />
        <path d="M31.246 15.788L19.238 30.633h7.01l-1.726 9.487a.129.129 0 00.128.152h0a.129.129 0 00.1-.052l12.009-14.844h-7.01l1.734-9.488a.134.134 0 00-.134-.151h0a.133.133 0 00-.107.055z" />
      </g>
      <path data-name="Rect\xE1ngulo 243" fill="none" d="M0 0h56v56H0z" />
    </svg>
  );
}

export default SvgIcDonationsHeart;
