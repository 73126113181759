import React, { Fragment, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import BaseButton from '../Buttons/BaseButton';
import BaseDialog from './BaseDialog';
import { SlideUpTransition } from '../Transitions/Transitions';
import RibbonIcon from '../CustomIcons/RibbonIcon';
import { InsurancePlan, InsurancePlans } from '../../Enums/insurances';
import clsx from 'clsx';
import { numberWithDots } from '../../Utils/Format/MoneyFormat';
import DownloadIcon from '../CustomIcons/DownloadIcon';
import PractiseguroPDF from '../../Assets/docs/practiseguro-clausulas.pdf';
import { downloadAsset } from '../../Utils/Misc/Assets';

const InsuranceDetailDialog = props => {
  const { onClose, open, title, adquire, adquired, planPrice } = props;

  const classes = useStyles();

  // * STATES
  const handlerAdquire = useCallback(() => {
    if (!adquired) {
      adquire();
    }
    onClose();
  }, [adquire, adquired, onClose]);

  const silverPlan = InsurancePlans[InsurancePlan.Silver];

  //* COMPONENTS
  const renderContent = useCallback(() => {
    return (
      <Grid container>
        <Grid
          item
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.margin}
        >
          <Grid item>
            <RibbonIcon size={32} />
          </Grid>
          <Grid item>
            <Typography className={clsx(classes.alignCenter, classes.bold)}>
              {silverPlan.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid
            container
            direction="column"
            justify="center"
            className={classes.detailContainer}
          >
            <Grid item>
              <Typography>
                <span className={clsx(classes.elipse)}>• </span>
                Edad mínima de ingreso: 18 años.
              </Typography>
            </Grid>

            <Grid item>
              <Typography>
                <span className={clsx(classes.elipse)}>• </span>
                Edad máxima de ingreso: 65 años.
              </Typography>
            </Grid>

            <Grid item>
              <Typography>
                <span className={clsx(classes.elipse)}>• </span>
                Cobertura: muerte por cualquier causa; excluido el suicidio el
                primer año de vigencia de la póliza.
              </Typography>
            </Grid>

            <Grid item>
              <Typography className={clsx(classes.detail)}>
                <span className={clsx(classes.elipse)}>• </span>Actualmente la
                tarifa de este plan es de{' '}
                <span className={clsx(classes.bold)}>
                  {numberWithDots(planPrice)}
                </span>{' '}
                y paga{' '}
                <span className={clsx(classes.bold)}>
                  {numberWithDots(silverPlan.coverageRange)}
                </span>
                .
              </Typography>
            </Grid>

            <Grid item className={classes.buttonItemContainer}>
              <BaseButton
                variant="text"
                size="small"
                className={classes.conditionsButton}
                startIcon={<DownloadIcon width={24} height={24} />}
                onClick={() =>
                  downloadAsset('Practiseguro - Clausulas.pdf', PractiseguroPDF)
                }
              >
                Ver condiciones
              </BaseButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }, [classes, planPrice, silverPlan.coverageRange, silverPlan.name]);

  const renderActions = useCallback(() => {
    return (
      <Fragment>
        <BaseButton
          onClick={onClose}
          variant="outlined"
          color="primary"
          size="small"
        >
          Cancelar
        </BaseButton>
        <BaseButton color="primary" size="small" onClick={handlerAdquire}>
          Adquirir seguro
        </BaseButton>
      </Fragment>
    );
  }, [onClose, handlerAdquire]);

  const isMobileSize = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  return (
    <Fragment>
      <BaseDialog
        open={open}
        handleClose={onClose}
        title={title}
        actions={renderActions}
        content={renderContent}
        fullScreen={isMobileSize}
        TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
        disableBackdropClick={true}
        paperClass={isMobileSize ? undefined : classes.paperClass}
      />
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  valueText: {
    fontSize: 14,
    marginTop: theme.spacing(4)
  },
  text: {
    fontSize: 14,
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2),
    width: '100%'
  },
  title: {
    fontSize: 14,
    marginBottom: theme.spacing(2)
  },
  optionsContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(2)
  },
  textSmall: {
    fontSize: 10,
    color: theme.palette.text.blackLight,
    marginTop: theme.spacing()
  },
  rightButton: {
    marginLeft: theme.spacing(3)
  },
  linkTextSmall: {
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.primary.dark
    }
  },
  cardButton: {
    position: 'absolute',
    bottom: theme.spacing(3.4)
  },
  elipse: {
    fontSize: 24,
    color: theme.typography.color.black,
    textAlign: 'center'
  },
  detail: {
    fontSize: 13,
    color: theme.typography.color.black,
    display: 'inline-block',
    marginRight: theme.spacing(0.5)
  },
  detailsSubtitle: {
    color: theme.typography.color.black,
    fontSize: 11
  },
  bold: {
    fontSize: 14,
    fontWeight: 600,
    display: 'inline-block'
  },
  margin: {
    marginBottom: theme.spacing(2)
  },
  alignCenter: {
    textAlign: 'center'
  },
  alignRight: {
    textAlign: 'right'
  },
  paddingBottom: {
    paddingBottom: theme.spacing(1)
  },
  image: {
    height: 32,
    width: 'auto',
    marginBottom: theme.spacing(2)
  },
  buttonItemContainer: {
    textAlign: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      textAlign: 'left'
    }
  },
  conditionsButton: {
    backgroundColor: theme.palette.primary.light,
    marginTop: theme.spacing(2)
  },
  paperClass: {
    width: 400
  }
}));

export default InsuranceDetailDialog;
