import React from 'react';
import { portal, Portal } from '../../../../Configs/general';

import GenerationStepGDC from './GenerationStep.GDC';
import GenerationStepGDG from './GenerationStep.GDG';

const GenerationStep = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <GenerationStepGDC {...props} />;
    case Portal.Gasguajira:
      return <GenerationStepGDG {...props} />;
    case Portal.Efigas:
      return <GenerationStepGDC {...props} />;
    default:
      return <></>;
  }
};

export default GenerationStep;
