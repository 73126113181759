import React from 'react';
import { portal, Portal } from '../../../../Configs/general';

import LogoHeaderGDC from './LogoHeader.GDC';
import LogoHeaderGDG from './LogoHeader.GDG';
import LogoHeaderEFG from './LogoHeader.EFG';
import LogoHeaderAAA from './LogoHeader.AAA';

const LogoHeader = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <LogoHeaderGDC {...props} />;
    case Portal.Gasguajira:
      return <LogoHeaderGDG {...props} />;
    case Portal.Efigas:
      return <LogoHeaderEFG {...props} />;
    case Portal.TripleA:
      return <LogoHeaderAAA {...props} />;
    default:
      return <></>;
  }
};

export default LogoHeader;
