import React from 'react';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ProgressCircle from '../Circle/CircleProgress';

const ModalProgress = props => {
  const { message = 'Cargando', id, modalProgressClass } = props;
  const classes = useStyles();
  return (
    <div className={clsx(classes.modalProgress, modalProgressClass)}>
      <div id={id} className={classes.containerProgress}>
        <ProgressCircle size={80} className={'unselectable'} />
        <Typography className={classes.textProgress}>{message}</Typography>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  modalProgress: {
    zIndex: 9,
    position: 'fixed',
    display: 'flex',
    flex: 1,
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  containerProgress: {
    width: 200,
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.98,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: theme.custom.borderRadius,
    backgroundColor: theme.palette.common.white
  },
  textProgress: {
    fontSize: 18,
    lineHeight: 1.22,
    color: theme.palette.common.black,
    paddingTop: theme.spacing(3),
    textAlign: 'center'
  }
}));

export default ModalProgress;
