import React from 'react';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import BannerCorrectIcon from '../CustomIcons/BannerCorrectIcon';
import BannerWarningIcon from '../CustomIcons/BannerWarningIcon';
import BannerRotateWarningIcon from '../CustomIcons/BannerRotateWarningIcon';

export const StatusBannerType = {
  Correct: 'correct',
  Warning: 'warning',
  RotateWarning: 'RotateWarning'
};

const StatusBanner = props => {
  const {
    message,
    subMessage,
    fullPage = false,
    type = StatusBannerType.Correct,
    className,
    customClasses = {}
  } = props;

  const classes = useStyles();

  const containerClass = fullPage
    ? classes.container
    : classes.containerReduced;

  const iconBanner = () => {
    switch (type) {
      case StatusBannerType.Correct:
        return (
          <BannerCorrectIcon
            className={clsx(classes.icon, customClasses.icon)}
          />
        );
      case StatusBannerType.Warning:
        return (
          <BannerWarningIcon
            className={clsx(classes.icon, customClasses.icon)}
          />
        );
      case StatusBannerType.RotateWarning:
        return (
          <BannerRotateWarningIcon
            className={clsx(classes.icon, customClasses.icon)}
          />
        );
      default:
        return (
          <BannerCorrectIcon
            className={clsx(classes.icon, customClasses.icon)}
          />
        );
    }
  };

  return (
    <div className={clsx(containerClass, className)}>
      <div className={classes.content}>
        {iconBanner()}
        <Typography
          variant={'h1'}
          className={clsx(classes.message, customClasses.message)}
        >
          {message}
        </Typography>
        <Typography className={classes.subMessage}>{subMessage}</Typography>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    padding: [[0, theme.spacing(2.5)]],
    width: '100%',
    minHeight: 'calc(100vh - 56px)',
    display: 'flex',
    marginTop: theme.spacing(6),
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vh - 64px)',
      padding: 0,
      marginTop: theme.spacing(17.5)
    }
  },
  containerReduced: {
    padding: [[0, theme.spacing(2.5)]],
    width: '100%',
    display: 'flex',
    marginTop: theme.spacing(6),
    justifyContent: 'center'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  icon: {
    marginBottom: theme.spacing(),
    transform: 'scale(0.85)',
    [theme.breakpoints.up('sm')]: {
      transform: 'scale(1)',
      marginBottom: theme.spacing(3)
    }
  },
  message: {
    marginBottom: theme.spacing(2),
    fontSize: '1em',
    textAlign: 'center',
    lineHeight: 1.19,
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(3),
      fontSize: '1.25em'
    }
  },
  subMessage: {
    marginBottom: theme.spacing(4),
    fontSize: '0.85em',
    textAlign: 'center',
    lineHeight: 1.19,
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(5),
      fontSize: '1em'
    }
  }
}));

export default withWidth()(StatusBanner);
