import * as React from 'react';
const DigitalInvoiceActivationIcon = ({ width = 35, height = 35, ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 700 700" {...rest}>
    <g
      style={{
        stroke: '#333',
        strokeWidth: 0.00052156,
        strokeDasharray: 'none',
        strokeOpacity: 1
      }}
    >
      <path
        d="M36.789 15.931H13.612c-.696 0-1.393 1.125-1.393 1.945v30.272c0 .929 1.155.999 1.393.999H30.69c.161 0 .316-.06.43-.164l7.107-6.307a.562.562 0 0 0 .122-.165c.009-.017.014-.034.02-.052a.49.49 0 0 0 .03-.144c0-.013.007-.104.007-.036V40.99h-.05a16.892 16.892 0 0 1-.133 0c-.02-.001.171-.002.15-.004h-.258l-.072.003-.069.001H37.757h.3-.169a.481.481 0 0 0-.075.004.07.07 0 0 1-.01-.001v1.284H31.91c-1.01 0-1.368.195-1.368 1.124v5.045H14.172c-.337 0-1.17-.25-1.17-.56V17.612c0-.31.567-.98.904-.98h22.883c.336 0 1.21.67 1.21.98v7.112h.015l.017-.001H38.07a.517.517 0 0 1 .038.002h.134l.015.002h.142-.582H37.985l.025.002h.05l.035.001h.109l.022-.001a.83.83 0 0 0 .023 0l.028-.001H38.418l.02-.001h.069l.02-.001H38.569l.017-.001h.033v-7.113c0-.929-.82-1.682-1.83-1.682zm1.079 8.808h.116-.101zm.34.003h.011zM31.908 42.84h5.242l-5.929 5.137-.019-4.873c-.001-.31.37-.264.706-.264z"
        style={{
          opacity: 1,
          fill: '#333',
          fillOpacity: 1,
          stroke: '#333',
          strokeWidth: 0.00053168,
          strokeDasharray: 'none',
          strokeDashoffset: 0,
          strokeOpacity: 0.5
        }}
        transform="matrix(17.0422 0 0 17.71588 -99.963 -242.37)"
      />
      <path
        fill="#333"
        stroke="none"
        d="M19.711 27.143h-2.44c-.336 0-.61-.25-.61-.56 0-.748-1.22-.748-1.22 0 0 .929.82 1.681 1.83 1.681h.61v.561c0 .748 1.22.748 1.22 0v-.56h.61c1.01 0 1.83-.753 1.83-1.682V25.46c0-.928-.82-1.681-1.83-1.681h-2.44c-.336 0-.61-.251-.61-.56v-1.122c0-.31.274-.56.61-.56h2.44c.337 0 .61.25.61.56 0 .747 1.22.747 1.22 0 0-.929-.82-1.682-1.83-1.682h-.61v-.56c0-.748-1.22-.748-1.22 0v.56h-.61c-1.01 0-1.83.753-1.83 1.682v1.121c0 .929.82 1.682 1.83 1.682h2.44c.337 0 .61.25.61.56v1.122c0 .31-.273.56-.61.56zm4.88-5.342h9.758c.813 0 .813-.824 0-.824h-9.758c-.814 0-.814.824 0 .824zm0 4.522h9.758c.813 0 .813-.862 0-.862h-9.758c-.814 0-.814.862 0 .862zm-.116 4.522h6.05c.813 0 .992-.899.179-.899H24.59c-.814 0-.654.899-.116.899zm-8.423 4.447h11.62c.814 0 .903-.861.09-.861h-11.71c-.813 0-.813.861 0 .861zm11.576 4.448c.813 0 .723-.824-.09-.824H16.052c-.813 0-.813.824 0 .824z"
        style={{
          fill: '#333',
          fillOpacity: 1,
          stroke: '#333',
          strokeWidth: 0,
          strokeDasharray: 'none',
          strokeDashoffset: 0,
          strokeOpacity: 0.5
        }}
        transform="matrix(17.0422 0 0 17.71588 -99.963 -242.37)"
      />
    </g>
    <path
      d="M657.176 235.232c-1.297.104-2.67-.25-3.912.22-1.667.817-3.341 1.623-5.08 2.284-3.547 1.367-7.116 2.676-10.604 4.193-21.013 8.575-42.164 16.797-63.213 25.276-45.025 17.978-90.034 36.043-135.024 54.073-1.558.705-3.253 1.182-4.652 2.189-.78.587-.854 1.595-.776 2.486-.044 1.02.013 2.038.087 3.055 3.599 2.682 7.885 4.148 11.83 6.212 24.709 11.945 49.37 23.974 74.033 36.01 2.538 1.383 4.281 3.838 5.397 6.448 5.11 10.645 10.284 21.26 15.39 31.908 5 10.167 9.89 20.366 14.839 30.56 3.19 6.458 6.315 12.951 9.616 19.35.596.977 1.385 2.168 2.625 2.238 1.217.01 2.434.013 3.65-.025 1.947-2.34 2.915-5.26 3.852-8.105 1.347-3.587 2.861-7.11 4.264-10.677 12.515-31.177 24.91-62.402 37.394-93.591 14.113-35.321 28.22-70.646 42.35-105.96.646-1.205 1.068-2.55.911-3.928-.01-.878.1-1.826-.101-2.657-.736-.843-1.669-1.692-2.876-1.559zm-27.014 18.961c-1.232 2.683-2.197 5.497-3.623 8.084-1.376 1.98-3.34 3.441-4.953 5.214-31.23 31.27-62.442 62.559-93.671 93.829-1.946 1.797-5.069 2.1-7.318.692-20.38-9.98-40.803-19.87-61.232-29.752-2.179-.914-4.446-1.888-6.014-3.73-.696-.638-1.65-1.495-1.199-2.544.46-1.179 1.884-1.222 2.863-1.736 22.945-9.188 45.878-18.407 68.84-27.555a16835.782 16835.782 0 0 0 55.77-22.358c16.843-6.722 33.693-13.427 50.537-20.144zm3.404 30.243c-12.72 31.777-25.31 63.603-38.055 95.372a35982.677 35982.677 0 0 0-25.747 64.434c-1.675-1.378-3.629-2.524-4.877-4.339-.897-1.456-1.29-3.149-2.096-4.654-2.163-4.427-4.367-8.832-6.525-13.262-7.677-15.613-15.307-31.251-22.776-46.965-.95-2.19-1.094-4.961.489-6.905 1.9-2.63 4.461-4.672 6.655-7.04 30.014-30.024 60.008-60.07 90.055-90.06 2.63-2.313 6.178-2.983 9.327-4.307.887-.523.76-.057.395.642l-6.845 17.084z"
      style={{
        stroke: '#333',
        strokeWidth: 0,
        strokeDasharray: 'none',
        strokeOpacity: 1
      }}
    />
  </svg>
);
export default DigitalInvoiceActivationIcon;
