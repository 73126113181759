import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '../CustomIcons/CloseIcon';
import SimpleDivider from '../Dividers/SimpleDivider';
import clsx from 'clsx';

export default function BrillaVisitDialog(props) {
  const {
    id,
    textTitleId,
    buttonCloseId,
    open = true,
    handleClose,
    title = 'Título',
    content,
    contentSize,
    actions,
    loading = false,
    fullScreen = false,
    ...rest
  } = props;
  const classes = useStyles();
  return (
    <Dialog
      id={id}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: clsx({
          [classes.paperFullScreen]: fullScreen,
          [classes.paper]: !fullScreen
        })
      }}
      fullScreen={fullScreen}
      {...rest}
    >
      <DialogTitle id={textTitleId} disableTypography className={classes.title}>
        <Typography className={classes.titleText}>{title}</Typography>
        <IconButton
          id={buttonCloseId}
          size="small"
          aria-label="cerrar diálogo"
          onClick={handleClose}
          className={classes.titleButton}
        >
          <CloseIcon size={15} className={classes.titleIcon} />
        </IconButton>
      </DialogTitle>
      {loading && <LinearProgress color="secondary" />}
      <SimpleDivider withoutMargin />
      <DialogContent
        className={clsx({
          [classes.contentLoading]: loading,
          [classes.contentLoaded]: !loading,
          [classes.smallContent]: contentSize === 'small'
        })}
      >
        {content && content()}
      </DialogContent>
      {actions && (
        <Fragment>
          <SimpleDivider withoutMargin />
          <DialogActions className={classes.actions}>{actions()}</DialogActions>
        </Fragment>
      )}
    </Dialog>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: theme.custom.borderRadius,
    overflowX: 'hidden'
  },
  paperFullscreen: {
    borderRadius: 0,
    overflowX: 'hidden'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 70,
    backgroundColor: theme.palette.background.cardDark,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2)
  },
  titleText: {
    fontSize: 16,
    fontWeight: 500
  },
  titleButton: {
    padding: theme.spacing(1.5)
  },
  titleIcon: {
    fill: theme.palette.text.primary
  },
  contentLoading: {
    minHeight: 200,
    paddingTop: theme.spacing(4) - 4,
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  contentLoaded: {
    minHeight: 200,
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  smallContent: {
    width: 488
  },
  actions: {
    padding: theme.spacing(2)
  },
  actionButton: {}
}));
