import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import LikeIcon from '../CustomIcons/IcLikeButton';
import DislikeIcon from '../CustomIcons/IcDislikeButton';
const LikeDislikeButton = props => {
  const { className = '', handleClick, isBadChecked, isGoodChecked } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root)}>
      <Button
        id="good-button"
        size="small"
        variant="contained"
        className={clsx(classes.buttonBase, className, {
          [classes.changeBackground]: Boolean(isGoodChecked)
        })}
        classes={{
          startIcon: classes.startIcon
        }}
        startIcon={<LikeIcon className={classes.fillButton} />}
        onClick={() => handleClick('like')}
      />
      <Button
        id="bad-button"
        size="small"
        variant="contained"
        className={clsx(classes.buttonBase, className, {
          [classes.changeBackground]: Boolean(isBadChecked)
        })}
        classes={{
          startIcon: classes.startIcon
        }}
        onClick={() => handleClick('dislike')}
        startIcon={<DislikeIcon className={classes.fillButton} />}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  buttonBase: {
    borderRadius: 4,
    boxShadow: 'none',
    width: 69,
    height: 69,
    marginRight: theme.spacing(2)
  },
  startIcon: {
    margin: '0px !important'
  },
  fillButton: {
    fill: 'currentcolor'
  },
  changeBackground: {
    backgroundColor: '#183F8F !important',
    color: 'white !important'
  }
}));

export default LikeDislikeButton;
