import moment from 'moment-timezone';

export const getFirstBillingValue = productsFinancingData => {
  // 1+G/1+i
  return productsFinancingData.reduce((prev, current) => {
    const { interestRate, billings, totalFinancingValue } = current;

    const denominator = Math.pow(1 + interestRate / 100, billings);
    const numerator = Math.pow(1 + interestRate / 100, billings);

    const wholeOperation =
      denominator - 1
        ? totalFinancingValue *
          ((numerator * interestRate) / 100 / (denominator - 1))
        : 0;

    return prev + Math.trunc(wholeOperation);
  }, 0);
};

export const getPaymentPlanDetail = productsFinancingData => {
  const totalBillings = productsFinancingData[0].billings;

  const products = productsFinancingData.map(product => {
    const now = moment().tz('America/Bogota');
    const { interestRate, billings, totalFinancingValue } = product;
    let totalCapital = 0;
    let totalInterest = 0;
    let pendingBalance = totalFinancingValue;

    const rows = [];
    const monthlyInstallment = getFirstBillingValue([
      { interestRate, billings, totalFinancingValue }
    ]);

    for (let i = 0; i < billings; i++) {
      const interest = Math.floor(pendingBalance * (interestRate / 100));
      totalInterest = interest + totalInterest;

      const capital = monthlyInstallment - interest;
      totalCapital = capital + totalCapital;

      pendingBalance = Math.floor(pendingBalance - capital);

      rows.push({
        period: now.add(1, 'months').format('YYYY - MMM'),
        capital,
        interest,
        total: monthlyInstallment,
        pendingBalance
      });
    }
    if (billings > 0) {
      rows[billings - 1].capital += rows[billings - 1].pendingBalance;
      rows[billings - 1].total += rows[billings - 1].pendingBalance;
      totalCapital += rows[billings - 1].pendingBalance;
      rows[billings - 1].pendingBalance = 0;
    }
    return {
      rows,
      totalInterest,
      totalCapital,
      totalPayment: totalCapital + totalInterest
    };
  });

  return products.reduce(
    (prev, current) => {
      const rows = current.rows.map((row, idx) => {
        return {
          ...row,
          capital: prev.rows[idx].capital + row.capital,
          interest: prev.rows[idx].interest + row.interest,
          total: prev.rows[idx].total + row.total,
          pendingBalance: prev.rows[idx].pendingBalance + row.pendingBalance
        };
      });

      return {
        totalInterest: prev.totalInterest + current.totalInterest,
        totalCapital: prev.totalCapital + current.totalCapital,
        totalPayment: prev.totalPayment + current.totalPayment,
        rows
      };
    },
    {
      totalInterest: 0,
      totalCapital: 0,
      totalPayment: 0,
      rows: new Array(totalBillings).fill({
        capital: 0,
        interest: 0,
        total: 0,
        pendingBalance: 0
      })
    }
  );
};
