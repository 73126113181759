import axios from 'axios';

export const GetPoliciesAPI = async (
  token,
  contractId,
  identification,
  productId
) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/brilla/insurances/policies/${contractId}`,
      {
        headers: {
          Authorization: token
        },
        params: {
          identification,
          productId
        }
      }
    );
    return {
      success: true,
      data: response.data.data
    };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GetAcquiredInsurancesAPI = async (
  token,
  contractId,
  identification
) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/brilla/insurances/acquiredInsurances/${contractId}`,
      {
        headers: {
          Authorization: token
        },
        params: {
          identification
        }
      }
    );
    return {
      success: true,
      data: response.data.data
    };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GetInsurancePlanPricesAPI = async (authToken = null) => {
  try {
    const configObj = {};
    if (authToken !== null) {
      configObj.headers = { Authorization: authToken };
    }

    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/brilla/insurances/planPrices/`,
      configObj
    );
    return {
      success: true,
      data: response.data.data
    };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const ValidateApplicantIdentificationAPI = async (
  token,
  identificationType,
  identification
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_DOMAIN}/brilla/insurances/validateIdentification`,
      {
        identificationType,
        identification
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
    return {
      success: true,
      data: response.data.data
    };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const ValidateIdentificationsAPI = async (
  token,
  identifications,
  captcha
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_DOMAIN}/brilla/insurances/insuredsIdentityValidation`,
      {
        identifications,
        'g-recaptcha-response': captcha
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
    return {
      success: true,
      data: response.data
    };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GetRelationshipOptionsAPI = async token => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/brilla/insurances/relationshipOptions`,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return {
      success: true,
      ...response.data.data
    };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GetAppointmentsAPI = async (token, contractId, identification) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/brilla/insurances/appointment/${contractId}`,
      {
        params: {
          identification
        },
        headers: {
          Authorization: token
        }
      }
    );
    return {
      success: true,
      ...response.data.data
    };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const VerifyContractEligibilityAPI = async (
  token,
  contractId,
  type,
  value
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_DOMAIN}/brilla/insurances/contractEligibility`,
      {
        contractId,
        type,
        value
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
    return {
      success: true,
      ...response.data.data
    };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const CreateInsuranceApplicationAPI = async (token, policyData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_DOMAIN}/brilla/insurances/insuranceApplication`,
      {
        policyData
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
    return {
      success: true,
      data: response.data.data
    };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const RequestInsuranceAPI = async (token, policyData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_DOMAIN}/brilla/insurances/request-insurance`,
      {
        policyData
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
    return {
      success: true,
      data: response.data
    };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const MakeAppointmentAPI = async (
  token,
  insuredIdentification,
  appointmentData
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_DOMAIN}/brilla/insurances/makeAppointment`,
      {
        appointmentData,
        insuredIdentification
      },
      {
        headers: {
          Authorization: token
        }
      }
    );
    return {
      success: true,
      data: response.data.data
    };
  } catch (err) {
    return { success: false, error: err };
  }
};
