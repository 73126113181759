import React, { useCallback } from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import MarkerWrapper from './MarkerWrapper';
import InfoWindowData from './InfoWindowContent';
import { config } from '../../../Configs';

const marker = require('../../../Assets/icons/marker_active.svg');
const altMarker = require('../../../Assets/icons/marker_inactive.svg');
const altMarkerTransparent = require('../../../Assets/icons/marker_inactive_transparent.svg');

const googleMapsLibraries = ['geometry', 'drawing', 'places'];

const MapComponent = props => {
  const classes = useStyles();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: config.google.maps.key,
    libraries: googleMapsLibraries,
    language: 'es'
  });

  const onMarkerDragEnd = useCallback(
    coord => {
      props.MarkerDragEnd(coord.latLng);
    },
    [props]
  );

  const locationMarker = {
    url: altMarker,
    scaledSize: { width: 24, height: 34 }
  };

  const currentLocationMarker = {
    url: marker,
    scaledSize: { width: 24, height: 34 }
  };

  return (
    isLoaded && (
      <GoogleMap
        mapContainerClassName={classes.mapSize}
        onZoomChanged={() => {
          props.updateZoomLevel();
        }}
        onLoad={props.onMapLoaded}
        zoom={props.zoom}
        center={props.location}
        options={{
          disableDefaultUI: false,
          draggableCursor: `url(${altMarkerTransparent}) 12 34, crosshair`,
          zoomControl: props.zoomVisible,
          zoomControlOptions: { position: props.isMobile ? 4 : 6 },
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false
        }}
        clickableIcons={false}
        onClick={props.selectInfoWindow}
        onDragEnd={props.collapseFeedback}
      >
        <Tooltip title="Ir a mi ubicación" placement="right" leaveDelay={100}>
          <Button
            variant="contained"
            className={classes.myLocationButton}
            onClick={props.handleMyLocationClick}
          >
            <MyLocationIcon className={classes.myLocationIcon} />
          </Button>
        </Tooltip>
        <Marker
          icon={locationMarker}
          defaultZIndex={1000}
          position={props.location}
          draggable={true}
          onDragEnd={coord => onMarkerDragEnd(coord)}
        />
        {props.currentLocation && (
          <Marker
            icon={currentLocationMarker}
            defaultZIndex={1000}
            position={props.currentLocation}
          />
        )}
        {props.markers.map((m, i) => (
          <MarkerWrapper
            selectedMarker={props.selectedMarker}
            showInfoWindow={i === props.selectedMarker}
            selectInfoWindow={props.selectInfoWindow}
            key={i}
            position={{ lat: m.lat, lng: m.lng }}
            type={m.type}
            opening={m.opening_hours}
            index={i}
            iwcomponent={
              <InfoWindowData
                name={m.name}
                address={m.address}
                placeId={m.placeID}
                mapRef={props.mapRef}
                photo={m.photo}
                hours={m.openingHours}
              />
            }
          />
        ))}
      </GoogleMap>
    )
  );
};

const useStyles = makeStyles(theme => ({
  myLocationButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.greyDark,
    padding: theme.spacing(),
    position: 'absolute',
    minWidth: 0,
    left: theme.spacing(1.25),
    top: theme.spacing(1),
    zIndex: 10,
    width: 40,
    height: 40,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary
    },
    [theme.breakpoints.down(400)]: {
      top: theme.spacing(7)
    }
  },
  myLocationIcon: {
    color: 'inherit',
    width: '0.8em',
    height: '0.8em'
  },
  mapSize: {
    width: '100%',
    height: 'calc(100vh - 128px)'
  }
}));

export default MapComponent;
