import moment from 'moment';

export const DEFAULT_PARAMS = {
  modal: true
};

const PROCEDURE_TYPE = {
  NewGasService: 'Solicitud de nuevo servicio de Gas Natural',
  ReplenishmentResourceDenial:
    'Presentar recurso de reposición y en subsidio de apelación sobre la negación del servicio de Gas Natural',
  Feasibility: 'Solicitud de factibilidad - Constructores',
  OtherRequest: 'Otras solicitudes'
};

const IDENTIFICATION_TYPES = {
  CC: 'CC',
  PP: 'PP',
  CE: 'CE'
};

export const FORM_ID = 'Pqr_service_form';

export const PQR_STEPS = [
  {
    label: 'Datos del servicio'
  },
  {
    label: 'Información de contacto'
  },
  {
    label: 'Detalle de la solicitud'
  }
];

export const PROCEDURE_TYPE_OPTIONS = [
  { value: 1, label: PROCEDURE_TYPE.NewGasService },
  {
    value: 2,
    label: PROCEDURE_TYPE.ReplenishmentResourceDenial
  },
  { value: 3, label: PROCEDURE_TYPE.Feasibility },
  { value: 4, label: PROCEDURE_TYPE.OtherRequest }
];

export const getProcedureTypeText = value => {
  switch (value) {
    case 1:
      return PROCEDURE_TYPE.NewGasService;
    case 2:
      return PROCEDURE_TYPE.ReplenishmentResourceDenial;
    case 3:
      return PROCEDURE_TYPE.Feasibility;
    case 4:
      return PROCEDURE_TYPE.OtherRequest;
    default:
      return 'Trámite no válido';
  }
};

export const isEmailNotificationDisabled = () => {
  /** Request notifications (actually request response) can be via email or not.
   * But because of COVID-19, the only available way of send a notification should
   * be via email at least until an especific date, thus being 2021-05-31
   */
  const ENABLE_EMAIL_NOTIFICATION_DATE = moment(
    '2021-05-31',
    'YYYY-MM-DD'
  ).utc();

  const now = moment();

  return now.diff(ENABLE_EMAIL_NOTIFICATION_DATE, 'seconds') < 0;
};

export const IDENTIFICATION_OPTIONS = [
  { label: 'Cédula de ciudadanía', value: IDENTIFICATION_TYPES.CC },
  { label: 'Pasaporte', value: IDENTIFICATION_TYPES.PP },
  { label: 'Cédula de extranjería', value: IDENTIFICATION_TYPES.CE }
];

export const EMAIL_RESPONSE = {
  YES: '1',
  NO: '0'
};
