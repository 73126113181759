import React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import TransactionLabel from '../../Labels/TransactionLabel';
import { floatWithDots } from '../../../Utils/Format/MoneyFormat';

const TransactionCardGDG = props => {
  const classes = useStyles();
  const { transaction } = props;

  const companyName = 'Gases De La Guajira S.A. E.S.P.';
  const companyNIT = '8921150366';

  return (
    <div className={clsx(classes.root)}>
      <div className={clsx(classes.content, classes.header)}>
        <TransactionLabel
          title={'Empresa'}
          description={companyName}
          classes={{
            title: classes.invoiceDetailTitle,
            description: classes.invoiceDetailDescription
          }}
        />
        <TransactionLabel
          title={'NIT'}
          description={companyNIT}
          classes={{
            root: classes.invoiceNit,
            title: classes.invoiceDetailTitle,
            description: classes.invoiceDetailDescription
          }}
        />
        <TransactionLabel
          title={'Total'}
          description={floatWithDots(transaction.value)}
          classes={{
            root: classes.invoicePrice,
            title: classes.invoiceDetailTitle,
            description: classes.invoiceDetailDescription
          }}
        />
      </div>
      <div className={clsx(classes.content, classes.body)}>
        <TransactionLabel
          title={'Descripción'}
          description={transaction.description}
          classes={{
            root: classes.labelRoot
          }}
        />
        <TransactionLabel
          title={'Banco'}
          description={transaction.bankName}
          classes={{
            root: classes.labelRoot
          }}
        />
        {transaction.donationValue > 0 && (
          <TransactionLabel
            title={'Valor de donación'}
            description={floatWithDots(transaction.donationValue)}
            classes={{
              root: classes.labelRoot
            }}
          />
        )}
        <TransactionLabel
          title={'Moneda'}
          description={'Peso colombiano COP'}
          classes={{
            root: classes.labelRoot
          }}
        />
        <TransactionLabel
          title={'Fecha  de la transacción'}
          description={moment(transaction.date).format('DD/MM/YYYY HH:mm')}
          classes={{
            root: classes.labelRoot
          }}
        />
        <TransactionLabel
          title={'Número de la transacción'}
          description={transaction.transactionNumber || '-'}
          classes={{
            root: classes.labelRoot
          }}
        />
        <TransactionLabel
          title={'Referencia transacción'}
          description={transaction.reference || '-'}
          classes={{
            root: classes.labelRoot
          }}
        />
        <TransactionLabel
          title={'Referencia pedido'}
          description={transaction.orderId || '-'}
          classes={{
            root: classes.labelRoot
          }}
        />
        <TransactionLabel
          title={'IP origen de la transacción'}
          description={transaction.ip || '-'}
          classes={{
            root: classes.labelRoot
          }}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    border: 'solid 1px #dbdbdb',
    marginBottom: theme.spacing(3)
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      padding: [[theme.spacing(4), theme.spacing(3)]]
    }
  },
  header: {
    height: '100%',
    backgroundColor: '#f0f6ff',
    alignContent: 'space-between',
    paddingBottom: theme.spacing(2)
  },
  body: {
    height: '100%',
    alignContent: 'space-between'
  },
  invoiceNit: {
    width: '50%',
    [theme.breakpoints.up('sm')]: {
      width: '33.333%'
    }
  },
  invoicePrice: {
    textAlign: 'right',
    width: '50%',
    [theme.breakpoints.up('sm')]: {
      width: '33.333%',
      textAlign: 'left'
    }
  },
  invoiceDetailTitle: {
    fontSize: 11,
    [theme.breakpoints.up('xm')]: {
      fontSize: 12
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 11
    }
  },
  invoiceDetailDescription: {
    fontSize: 18,
    [theme.breakpoints.up('xm')]: {
      fontSize: 20
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 18
    }
  },
  labelRoot: {
    marginBottom: '15px'
  }
}));

export default TransactionCardGDG;
