import * as React from 'react';

function SvgWithActiveReq(props) {
  return (
    <svg width={323.59} height={214.975} {...props}>
      <g data-name="Grupo 710" transform="translate(-478.001 -206.371)">
        <path
          data-name="Trazado 186"
          d="M506.217 282.803h-15.265a13.014 13.014 0 01-12.951-12.989v-2.687a13.006 13.006 0 0112.951-12.951h15.228a12.982 12.982 0 0112.951 12.951v2.687a12.958 12.958 0 01-12.914 12.989z"
          fill="#ef8e38"
          opacity={0.1}
        />
        <path
          data-name="Trazado 187"
          d="M788.64 401.788h-15.228a12.982 12.982 0 01-12.951-12.951v-2.687a12.982 12.982 0 0112.951-12.951h15.228a12.982 12.982 0 0112.951 12.951v2.687a13.006 13.006 0 01-12.951 12.951z"
          fill="#ef8e38"
          opacity={0.1}
        />
        <path
          data-name="Trazado 188"
          d="M761.361 312.213h-32.177a94.079 94.079 0 00-152.128-58h-32.471a14.32 14.32 0 00-14.295 14.295 14.386 14.386 0 0011.906 14.108h23.14a15.642 15.642 0 0115.6 15.6v1.754a15.642 15.642 0 01-15.6 15.6h-46.28a14.32 14.32 0 00-14.295 14.297 14.32 14.32 0 0014.295 14.295h24.67a94.072 94.072 0 00150.635 57.1 13.933 13.933 0 003.77.523h36.43a14.32 14.32 0 0014.295-14.295 14.32 14.32 0 00-14.295-14.29h-21.5a15.308 15.308 0 01-15.265-15.265v-2.015a15.308 15.308 0 0115.265-15.267h34.524a13.147 13.147 0 012.463.224h11.234a14.32 14.32 0 0014.295-14.295 14.249 14.249 0 00-14.216-14.369z"
          fill="#ef8e38"
          opacity={0.1}
        />
        <circle
          data-name="Elipse 149"
          cx={5.76}
          cy={5.76}
          r={5.76}
          transform="translate(553.879 208.871)"
          fill="none"
          stroke="#ef8e38"
          strokeMiterlimit={10}
          strokeWidth={5}
        />
        <circle
          data-name="Elipse 150"
          cx={8.203}
          cy={8.203}
          r={8.203}
          transform="translate(693.684 217.249)"
          fill="none"
          stroke="#ef8e38"
          strokeMiterlimit={10}
          strokeWidth={5}
        />
        <circle
          data-name="Elipse 151"
          cx={5.76}
          cy={5.76}
          r={5.76}
          transform="translate(731.254 292.867)"
          fill="none"
          stroke="#ef8e38"
          strokeMiterlimit={10}
          strokeWidth={5}
        />
        <path
          data-name="Trazado 189"
          d="M760.838 254.338h-8.9v-8.9a1.92 1.92 0 10-3.84 0v8.9h-8.9a1.92 1.92 0 100 3.84h8.9v8.9a1.92 1.92 0 103.84 0v-8.9h8.9a1.937 1.937 0 001.92-1.92 1.909 1.909 0 00-1.92-1.92z"
          fill="#108dc7"
        />
        <path
          data-name="Trazado 190"
          d="M563.522 303.906h-8.9v-8.9a1.92 1.92 0 10-3.84 0v8.9h-8.946a1.92 1.92 0 100 3.84h8.9v8.9a1.92 1.92 0 103.84 0v-8.9h8.9a1.937 1.937 0 001.92-1.92 1.872 1.872 0 00-1.874-1.92z"
          fill="#108dc7"
        />
        <path
          data-name="Trazado 191"
          d="M777.681 327.557l-4.887-4.887 4.887-4.887a1.481 1.481 0 10-2.095-2.094l-4.887 4.887-4.887-4.887a1.481 1.481 0 10-2.094 2.094l4.887 4.887-4.887 4.887a1.481 1.481 0 002.094 2.094l4.887-4.887 4.887 4.887a1.5 1.5 0 002.095 0 1.426 1.426 0 000-2.094z"
          fill="#108dc7"
        />
        <g data-name="Grupo 692">
          <g data-name="Grupo 691">
            <path
              data-name="Trazado 700"
              d="M603.463 293.804a2.027 2.027 0 10.595 1.434 2.042 2.042 0 00-.595-1.434z"
              fill="#ef8e38"
            />
          </g>
        </g>
        <g data-name="Grupo 694">
          <g data-name="Grupo 693">
            <path
              data-name="Trazado 701"
              d="M679.928 259.332h-41.046a2.029 2.029 0 100 4.057h41.046a2.031 2.031 0 012.029 2.029v91.693a2.031 2.031 0 01-2.029 2.029h-73.842a2.031 2.031 0 01-2.029-2.029v-54.299a2.029 2.029 0 10-4.057 0v54.3a6.093 6.093 0 006.086 6.086h73.842a6.093 6.093 0 006.086-6.086v-91.694a6.093 6.093 0 00-6.086-6.086z"
              fill="#ef8e38"
            />
          </g>
        </g>
        <g data-name="Grupo 696">
          <g data-name="Grupo 695">
            <path
              data-name="Trazado 702"
              d="M643.006 267.446a27.305 27.305 0 00-4.123.313 2.029 2.029 0 10.615 4.01 23.2 23.2 0 013.508-.266 22.91 22.91 0 0117.533 37.674 20.325 20.325 0 00-10.59-8.862 9.909 9.909 0 003-7.1v-1.983a9.94 9.94 0 10-19.88 0v1.978a9.909 9.909 0 003 7.1 20.329 20.329 0 00-10.612 8.9 22.849 22.849 0 01-5.058-10.964 2.029 2.029 0 00-4 .671 26.983 26.983 0 1026.607-31.471zm-5.883 23.786a5.883 5.883 0 1111.766 0v1.978a5.883 5.883 0 11-11.766 0zm5.883 26.114a22.9 22.9 0 01-14.544-5.169 16.226 16.226 0 0129.07-.034 22.817 22.817 0 01-14.526 5.203z"
              fill="#ef8e38"
            />
          </g>
        </g>
        <g data-name="Grupo 698">
          <g data-name="Grupo 697">
            <path
              data-name="Trazado 703"
              d="M661.742 340.069h-28.806a2.029 2.029 0 100 4.057h28.806a2.029 2.029 0 100-4.057z"
              fill="#ef8e38"
            />
          </g>
        </g>
        <g data-name="Grupo 700">
          <g data-name="Grupo 699">
            <path
              data-name="Trazado 704"
              d="M625.702 329.305a2.027 2.027 0 10.594 1.434 2.043 2.043 0 00-.594-1.434z"
              fill="#ef8e38"
            />
          </g>
        </g>
        <g data-name="Grupo 702">
          <g data-name="Grupo 701">
            <path
              data-name="Trazado 705"
              d="M625.702 340.665a2.027 2.027 0 10.594 1.434 2.04 2.04 0 00-.594-1.434z"
              fill="#ef8e38"
            />
          </g>
        </g>
        <g data-name="Grupo 704">
          <g data-name="Grupo 703">
            <path
              data-name="Trazado 706"
              d="M661.742 328.709h-28.806a2.029 2.029 0 100 4.057h28.806a2.029 2.029 0 000-4.057z"
              fill="#ef8e38"
            />
          </g>
        </g>
        <g data-name="Grupo 706">
          <g data-name="Grupo 705">
            <path
              data-name="Trazado 707"
              d="M623.862 271.023a2.029 2.029 0 00-2.869 0l-6.607 6.605-2.258-2.258a2.029 2.029 0 00-2.869 2.869l3.69 3.689a2.029 2.029 0 002.869 0l8.041-8.041a2.029 2.029 0 00.003-2.864z"
              fill="#ef8e38"
            />
          </g>
        </g>
        <g data-name="Grupo 708">
          <g data-name="Grupo 707">
            <path
              data-name="Trazado 708"
              d="M616.635 259.332a16.635 16.635 0 1016.634 16.635 16.653 16.653 0 00-16.634-16.635zm0 29.212a12.577 12.577 0 1112.577-12.577 12.592 12.592 0 01-12.577 12.577z"
              fill="#ef8e38"
            />
          </g>
        </g>
        <g data-name="Grupo 709">
          <g data-name="Grupo 230">
            <g data-name="Grupo 229">
              <path
                data-name="Trazado 195"
                d="M594.568 379.587h111.943c2.8 0 2.8-4.339 0-4.339H594.568c-2.8 0-2.8 4.339 0 4.339z"
                fill="#ef8e38"
              />
            </g>
          </g>
          <g data-name="Grupo 232">
            <g data-name="Grupo 231">
              <path
                data-name="Trazado 196"
                d="M720.974 379.587h7.953c2.8 0 2.8-4.339 0-4.339h-7.954c-2.8 0-2.8 4.339 0 4.339z"
                fill="#ef8e38"
              />
            </g>
          </g>
          <g data-name="Grupo 234">
            <g data-name="Grupo 233">
              <path
                data-name="Trazado 197"
                d="M556.097 379.587h24.973c2.8 0 2.8-4.339 0-4.339h-24.973c-2.8 0-2.8 4.339 0 4.339z"
                fill="#ef8e38"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgWithActiveReq;
