import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import { FORM_STATE } from '../fixes/ganaLoco_enums';

const GANALOCO_IMAGE = require('../../../Assets/images/ganaloco-final-image.png');

export const AlertImage = props => {
  const classes = useStyles();

  const { formState } = props;

  return (
    <div
      className={clsx({
        [classes.alertImage]: formState !== FORM_STATE.AWAITING
      })}
      style={{
        backgroundImage: `url(${GANALOCO_IMAGE})`
      }}
    ></div>
  );
};

const useStyles = makeStyles(theme => ({
  alertImage: {
    width: '100%',
    height: theme.spacing(25),
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: theme.spacing(0, 0, 3, 0),
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(30),
      margin: theme.spacing(0, 0, 5, 0)
    }
  }
}));

export default AlertImage;
