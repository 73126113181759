import React from 'react';

const ProfileIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 20}
      height={props.size || 20}
      viewBox="0 0 56 56"
      {...props}
    >
      <g
        data-name="Elipse 10"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
      >
        <circle cx={28} cy={28} r={28} stroke="none" />
        <circle cx={28} cy={28} r={27.25} />
      </g>
      <path
        data-name="Trazado 679"
        d="M36.977 22.501c-.384 5.176-4.308 9.4-8.615 9.4s-8.238-4.221-8.615-9.4a8.648 8.648 0 1117.23 0z"
      />
      <path
        data-name="Trazado 680"
        d="M28.363 36.616c-7.418 0-14.994 4.092-16.387 11.817l-.157 1.1 11.216 5.832 12.15-.429c.975 0 9.686-5.569 9.518-6.5-1.394-7.728-8.919-11.82-16.34-11.82z"
      />
    </svg>
  );
};

export default ProfileIcon;
