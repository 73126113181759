import React, { useContext, useEffect, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Typography, Grid } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import EditIcon from '@material-ui/icons/Edit';
import clsx from 'clsx';

import { UserContext } from '../../../../../../Contexts/UserContext';
import {
  SetCurrentStepIndexContext,
  StepperDataContext,
  CurrentStepIndexContext
} from '../../../../../../Contexts/StepperContext';
import { CreateInsuranceApplicationAPI } from '../../../../../../API/BrillaInsurances/BrillaInsurancesAPI';
import { extractErrorMessage } from '../../../../../../Utils/Errors/Errors';
import { history } from '../../../../../../Routes/history';
import { ROUTE_NAMES } from '../../../../../../Routes/Routes';
import {
  InsuranceAcquisitionStep,
  InsurancePlan,
  InsuranceProcess,
  SummarySectionPanel
} from '../../../../../../Enums/insurances';

import SweetAlert from '../../../../../../Components/Alerts/SweetAlert';
import ModalProgress from '../../../../../../Components/Progress/Modal/ModalProgress';
import Accordion from '../../../../../../Components/Accordion/Accordion';
import Title from '../../../../../../Components/Labels/Title';
import BeneficiariesList from './BeneficiariesList';
import InsuranceSelectedPlanCard from '../../../../../../Components/Cards/InsuranceSelectedPlanCard';
import SimpleDivider from '../../../../../../Components/Dividers/SimpleDivider';
import { Company } from '../../../../../../Configs/general';
import moment from 'moment';

const SummaryStep = props => {
  const {
    formId,
    setCanSubmit,
    setGoBack,
    setCurrentStep: setVisualStepperIndex,
    setNextButtonText,
    setAlert,
    planId
  } = props;

  const classes = useStyles();

  const isGoldPlanSelected = planId === InsurancePlan.Gold;

  const [loading, setLoading] = useState(false);
  const [openPanel, setOpenPanel] = useState({
    [SummarySectionPanel.Contract]: false,
    [SummarySectionPanel.Plan]: false,
    [SummarySectionPanel.Form]: false
  });

  // * CONTEXTS
  const currentStepIndex = useContext(CurrentStepIndexContext);
  const setCurrentStep = useContext(SetCurrentStepIndexContext);
  const stepperData = useContext(StepperDataContext);
  const currentUser = useContext(UserContext);

  const {
    planSelected,
    beneficiaries,
    insuredData,
    contract,
    selectedPlanId,
    realNames
  } = stepperData;

  const onBackward = useCallback(() => {
    setCurrentStep(currentStep => currentStep - 1);
  }, [setCurrentStep]);

  const jumpTo = useCallback(
    step => {
      setCurrentStep(step);
    },
    [setCurrentStep]
  );

  const { handleSubmit } = useForm();

  const goNext = useCallback(async () => {
    setLoading(true);
    setCanSubmit(false);

    const firstInsured = {
      firstName: insuredData.firstName,
      lastName: insuredData.lastName,
      identificationType: insuredData.identificationType,
      identification: insuredData.identification.toString(),
      dateOfBirth: moment(insuredData.dateOfBirth).toISOString(),
      expeditionDate: moment(insuredData.expeditionDate).toISOString(),
      expeditionPlace: insuredData.expeditionPlace,
      phone: insuredData.phone.toString(),
      phoneCountryCode: insuredData.phoneCountryCode,
      gender: insuredData.gender,
      email: insuredData.email,
      realNames: realNames[0]
    };

    const insureds = [firstInsured];

    if (isGoldPlanSelected) {
      const secondInsured = {
        firstName: insuredData.secondInsuredFirstName,
        lastName: insuredData.secondInsuredLastName,
        identificationType: insuredData.secondInsuredIdentificationType,
        identification: insuredData.secondInsuredIdentification.toString(),
        dateOfBirth: moment(insuredData.secondInsuredDateOfBirth).toISOString(),
        expeditionDate: moment(
          insuredData.secondInsuredExpeditionDate
        ).toISOString(),
        expeditionPlace: insuredData.secondInsuredExpeditionPlace,
        phone: insuredData.secondInsuredPhone.toString(),
        phoneCountryCode: insuredData.secondInsuredPhoneCountryCode,
        gender: insuredData.secondInsuredGender,
        email: insuredData.secondInsuredEmail,
        realNames: realNames[1]
      };
      insureds.push(secondInsured);
    }

    const response = await CreateInsuranceApplicationAPI(currentUser.token, {
      insureds,
      plan: selectedPlanId,
      product: 18,
      value: planSelected.monthlyPay,
      beneficiaries,
      contractId: contract.id
    });

    if (response.success) {
      history.push(ROUTE_NAMES.insurancesHome, {
        confirmation: {
          applicationId: response.data.id,
          process: InsuranceProcess.Policy
        },
        applicationId: response.data.id,
        contract,
        selectedPlanId
      });
      setLoading(false);
      setCanSubmit(true);
    } else {
      setLoading(false);
      setCanSubmit(true);
      setAlert({
        type: 'error',
        message: extractErrorMessage(response).message
      });
    }
  }, [
    contract,
    setAlert,
    selectedPlanId,
    beneficiaries,
    insuredData,
    currentUser,
    setCanSubmit,
    isGoldPlanSelected,
    realNames,
    planSelected.monthlyPay
  ]);

  useEffect(() => {
    setNextButtonText('Solicitar');
    setVisualStepperIndex(currentStepIndex);
    setCanSubmit(true);
    setGoBack({
      action: onBackward
    });
  }, [
    setGoBack,
    onBackward,
    setVisualStepperIndex,
    currentStepIndex,
    setNextButtonText,
    setCanSubmit
  ]);

  const handleOpenPanel = panel => {
    setOpenPanel(value => ({ ...value, [panel]: !value[panel] }));
  };

  return (
    <div className={classes.root}>
      {loading && <ModalProgress message="Solicitando" />}
      <Title
        title="Resumen de solicitud"
        className={classes.summaryStepTitle}
      />
      <Typography paragraph className={classes.summaryDescription}>
        Verifica que la información de la solicitud sea correcta. En caso de que
        no sea correcta, puedes regresar a los pasos anteriores y{' '}
        <strong className={classes.emphasis}>modificar los datos</strong>
      </Typography>
      <form id={formId} onSubmit={handleSubmit(goNext)}>
        <Grid container className={classes.sectionsContainer}>
          <Accordion
            title={Company.contractConjugation.capitalized.singular.selected}
            open={openPanel[SummarySectionPanel.Contract]}
            setOpen={() => handleOpenPanel(SummarySectionPanel.Contract)}
            actions={[
              {
                Icon: EditIcon,
                onClick: () => jumpTo(InsuranceAcquisitionStep.Contract)
              }
            ]}
          >
            <Grid container className={classes.dropdownGrid}>
              <Grid container item xs={12} sm={2} direction="column">
                <Typography className={classes.subtitle}>
                  Número{' '}
                  {Company.contractConjugation.regular.singular.contraction}
                </Typography>
                <Typography className={classes.data}>{contract.id}</Typography>
              </Grid>
              <Grid container item xs={12} sm={5} direction="column">
                <Typography className={classes.subtitle}>
                  Nombre del titular
                </Typography>
                <Typography className={classes.data}>
                  {contract.holder.name} {contract.holder.lastName}
                </Typography>
              </Grid>
              <Grid container item xs={12} sm={5} direction="column">
                <Typography className={classes.subtitle}>
                  Dirección del predio
                </Typography>
                <Typography className={classes.data}>
                  {contract.city}, {contract.department} {contract.address}
                </Typography>
              </Grid>
            </Grid>
          </Accordion>

          <Accordion
            title="Plan y beneficiarios"
            open={openPanel[SummarySectionPanel.Plan]}
            setOpen={() => handleOpenPanel(SummarySectionPanel.Plan)}
            actions={[
              {
                Icon: EditIcon,
                onClick: () => jumpTo(InsuranceAcquisitionStep.Plan)
              }
            ]}
          >
            <InsuranceSelectedPlanCard
              planId={selectedPlanId}
              monthlyPay={planSelected.monthlyPay}
              withActions={false}
            />
            <Grid
              item
              container
              direction="column"
              className={classes.noStyleDropdownGrid}
            >
              <Typography className={classes.payeesTitle}>
                Beneficiarios
              </Typography>
              {beneficiaries.length === 0 && (
                <SweetAlert message="No se ha seleccionado beneficiarios, se aplicaran los de ley." />
              )}
              <div
                className={clsx({
                  [classes.payeeDiv]: beneficiaries.length !== 0
                })}
              >
                <BeneficiariesList noEdit beneficiaries={beneficiaries} />
              </div>
            </Grid>
          </Accordion>

          <Accordion
            title="Formulario"
            open={openPanel[SummarySectionPanel.Form]}
            setOpen={() => handleOpenPanel(SummarySectionPanel.Form)}
            actions={[
              {
                Icon: EditIcon,
                onClick: () => jumpTo(InsuranceAcquisitionStep.Form)
              }
            ]}
          >
            <Grid className={classes.container}>
              {isGoldPlanSelected && (
                <Typography className={classes.title}>
                  Primer asegurado
                </Typography>
              )}
              <Grid container className={classes.formDataContainer}>
                <Grid container item direction="column" xs={6} md={4}>
                  <Typography className={classes.subtitle}>
                    Nombre completo
                  </Typography>
                  <Typography
                    className={classes.data}
                  >{`${insuredData.firstName} ${insuredData.lastName}`}</Typography>
                </Grid>
                <Grid container item direction="column" xs={6} md={3}>
                  <Typography className={classes.subtitle}>
                    Identificación
                  </Typography>
                  <Typography
                    className={classes.data}
                  >{`${insuredData.identificationType} ${insuredData.identification}`}</Typography>
                </Grid>
                <Grid container item direction="column" xs={6} md={3}>
                  <Typography className={classes.subtitle}>Celular</Typography>
                  <Typography className={classes.data}>
                    {`+${insuredData.phoneCountryCode} ${insuredData.phone}`}
                  </Typography>
                </Grid>
                <Grid container item direction="column" xs={6} md={2}>
                  <Typography className={classes.subtitle}>
                    Fecha de expedición
                  </Typography>
                  <Typography className={classes.data}>
                    {`${insuredData.expeditionDate}`}
                  </Typography>
                </Grid>
                <Grid container item direction="column" xs={6} md={4}>
                  <Typography className={classes.subtitle}>
                    Lugar de expedición
                  </Typography>
                  <Typography
                    className={classes.data}
                  >{`${insuredData.expeditionPlace}`}</Typography>
                </Grid>
                <Grid container item direction="column" xs={6} md={3}>
                  <Typography className={classes.subtitle}>
                    Fecha de nacimiento
                  </Typography>
                  <Typography
                    className={classes.data}
                  >{`${insuredData.dateOfBirth}`}</Typography>
                </Grid>
                <Grid container item direction="column" xs={6} md={3}>
                  <Typography className={classes.subtitle}>Sexo</Typography>
                  <Typography className={classes.data}>
                    {`${insuredData.gender}`}
                  </Typography>
                </Grid>
              </Grid>
              {isGoldPlanSelected && (
                <SimpleDivider withoutMargin className={classes.divider} />
              )}
              {isGoldPlanSelected && (
                <Grid className={classes.container}>
                  <Typography className={classes.title}>
                    Segundo asegurado
                  </Typography>
                  <Grid container className={classes.formDataContainer}>
                    <Grid container item direction="column" xs={6} md={4}>
                      <Typography className={classes.subtitle}>
                        Nombre completo
                      </Typography>
                      <Typography
                        className={classes.data}
                      >{`${insuredData.secondInsuredFirstName} ${insuredData.secondInsuredLastName}`}</Typography>
                    </Grid>
                    <Grid container item direction="column" xs={6} md={3}>
                      <Typography className={classes.subtitle}>
                        Identificación
                      </Typography>
                      <Typography
                        className={classes.data}
                      >{`${insuredData.secondInsuredIdentificationType} ${insuredData.secondInsuredIdentification}`}</Typography>
                    </Grid>
                    <Grid container item direction="column" xs={6} md={3}>
                      <Typography className={classes.subtitle}>
                        Celular
                      </Typography>
                      <Typography className={classes.data}>
                        {`+${insuredData.secondInsuredPhoneCountryCode} ${insuredData.secondInsuredPhone}`}
                      </Typography>
                    </Grid>
                    <Grid container item direction="column" xs={6} md={2}>
                      <Typography className={classes.subtitle}>
                        Fecha de expedición
                      </Typography>
                      <Typography className={classes.data}>
                        {`${insuredData.secondInsuredExpeditionDate}`}
                      </Typography>
                    </Grid>
                    <Grid container item direction="column" xs={6} md={4}>
                      <Typography className={classes.subtitle}>
                        Lugar de expedición
                      </Typography>
                      <Typography
                        className={classes.data}
                      >{`${insuredData.secondInsuredExpeditionPlace}`}</Typography>
                    </Grid>
                    <Grid container item direction="column" xs={6} md={3}>
                      <Typography className={classes.subtitle}>
                        Fecha de nacimiento
                      </Typography>
                      <Typography
                        className={classes.data}
                      >{`${insuredData.secondInsuredDateOfBirth}`}</Typography>
                    </Grid>
                    <Grid container item direction="column" xs={6} md={3}>
                      <Typography className={classes.subtitle}>Sexo</Typography>
                      <Typography className={classes.data}>
                        {`${insuredData.secondInsuredGender}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Accordion>
        </Grid>
      </form>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      '& > *:last-child': {
        marginBottom: theme.spacing(9)
      }
    }
  },
  container: {
    '& > *': {
      marginBottom: theme.spacing(1)
    }
  },
  formDataContainer: {
    '& > *': {
      paddingBottom: theme.spacing(1)
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      '& > *:nth-child(odd)': {
        paddingRight: theme.spacing(1)
      }
    }
  },
  summaryStepTitle: {
    fontSize: 16,
    fontWeight: 600,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 14
    }
  },
  summaryDescription: {
    marginBottom: theme.spacing(4)
  },
  emphasis: {
    color: theme.palette.text.greyDark
  },
  breadcrumbs: {
    fontSize: 15,
    fontWeight: theme.typography.fontWeightMedium,
    '& *': { fontSize: 'inherit' }
  },
  modalBackground: {
    backgroundColor: '#aaaaaa',
    opacity: '20%',
    height: '100%',
    width: '100%'
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.2
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.greyDark
  },
  data: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.2,
    textTransform: 'capitalize',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 13
    }
  },
  icon: {
    width: 'auto',
    height: 30,
    marginRight: theme.spacing(1)
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.greyDark
  },
  labelBold: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.greyDark
  },
  noStyleDropdownGrid: {
    padding: theme.spacing(1, 0),
    width: '100%'
  },
  dropdownGrid: {
    width: '100%',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      '& > *': {
        paddingBottom: theme.spacing(1)
      }
    }
  },
  payeesTitle: {
    fontSize: 12,
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    color: theme.palette.text.greyDark
  },
  divider: {
    margin: theme.spacing(1, 0)
  }
}));
export default SummaryStep;
