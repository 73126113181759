/* eslint-disable react/display-name */
import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useContext,
  useRef
} from 'react';
import * as Yup from 'yup';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Checkbox, FormControlLabel, useTheme } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { UserContext } from '../../Contexts/UserContext';
import { AlertsDispatchContext } from '../../Contexts/AlertsContext';

import ShowPassword from '../../Components/Adornments/ShowPassword';
import TextInput from '../../Components/Inputs/TextInput';
import SimpleDivider from '../../Components/Dividers/SimpleDivider';
import BaseButton from '../../Components/Buttons/BaseButton';
import BaseDialog from '../../Components/Dialogs/BaseDialog';
import EditableField from './EditableField';
import FullSizeProgress from '../../Components/Progress/FullSize/FullSizeProgress';
import SelectInput from '../../Components/Inputs/SelectInput';
import NumberInput from '../../Components/Inputs/NumberInput';
import FlagColombia from '../../Components/CustomIcons/Flags/FlagColombia';
import {
  extractFormErrorsYup,
  extractErrorMessage
} from '../../Utils/Errors/Errors';
import { history } from '../../Routes/history';
import { ROUTE_NAMES } from '../../Routes/Routes';
import { isPrintableAscii } from '../../Utils/Misc/String';
import { TransactionDetailAPI } from '../../API/Payments/TransactionStatusAPI';
import { TransactionSignupAPI } from '../../API/UserAPI';
import { PrivacyPoliciesLink } from '../../Configs/Links';
import { Company } from '../../Configs/general';

const IDENTIFICATION_OPTIONS = [
  { label: 'Cédula', value: 'CC' },
  { label: 'Pasaporte', value: 'PP' },
  { label: 'Cédula de extranjería', value: 'CE' },
  { label: 'Tarjeta de identidad', value: 'TI' }
];

const tempValuesSchema = Yup.object({
  firstName: Yup.string()
    .trim()
    .max(60, 'Debe tener 60 letras o menos')
    .required('Ingresa tu nombre'),
  lastName: Yup.string()
    .trim()
    .max(60, 'Debe tener 60 letras o menos')
    .required('Ingresa tus apellidos'),
  identification: Yup.mixed().when('identificationType', {
    is: 'PP',
    then: Yup.string()
      .trim()
      .matches(/^[0-9a-zA-Z]+$/, 'Ingresa un número de pasaporte válido')
      .required('Ingresa el número de tu pasaporte'),
    otherwise: Yup.number()
      .typeError('Ingresa un número de identificación válido')
      .positive('Ingresa un número de identificación válido')
      .required('Ingresa tu número de identificación')
  }),
  identificationType: Yup.string()
    .oneOf(['CC', 'PP', 'CE', 'TI'], 'Debes escoger una opción correcta')
    .required('Debes ingresar un tipo de identificación'),
  phone: Yup.string()
    .trim()
    .length(10, 'Debe tener 10 dígitos')
    .required('Ingresa un teléfono'),
  phoneCountryCode: Yup.string()
    .trim()
    .max(3, 'Debe tener máximo 3 dígitos')
    .required('Debe ser válido')
});

const submitValuesSchema = Yup.object({
  email: Yup.string()
    .trim()
    .lowercase()
    .email('Debes ingresar un correo válido')
    .test(
      'is-ascii',
      'Este campo no puede contener tildes ni caracteres especiales',
      isPrintableAscii
    )
    .required('Ingresa un correo'),
  password: Yup.string()
    .trim()
    .required('Debes ingresar una contraseña')
    .min(8, 'Debe tener mínimo 8 caracteres'),
  confirmPassword: Yup.string()
    .trim()
    .oneOf([Yup.ref('password')], 'Las contraseñas deben coincidir'),
  savedFirstName: Yup.string()
    .trim()
    .max(60)
    .required(),
  savedLastName: Yup.string()
    .trim()
    .max(60)
    .required(),
  savedIdentification: Yup.mixed().when('savedIdentificationType', {
    is: 'PP',
    then: Yup.string()
      .trim()
      .matches(/^[0-9a-zA-Z]+$/)
      .required(),
    otherwise: Yup.number()
      .typeError()
      .positive()
      .required()
  }),
  savedIdentificationType: Yup.string()
    .oneOf(['CC', 'PP', 'CE', 'TI'])
    .required(),
  savedPhone: Yup.string()
    .trim()
    .length(10)
    .required(),
  savedPhoneCountryCode: Yup.string()
    .trim()
    .max(3)
    .required(),
  terms: Yup.bool().oneOf([true])
});

const TransactionSignup = props => {
  const { transactionId } = props.match.params;
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  const mediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );
  const inputRef = useRef(null);
  const canFetchTransactionData = useRef(null);

  // * CONTEXTS
  const setAlert = useContext(AlertsDispatchContext);
  const currentUser = useContext(UserContext);

  const [fetchLoading, setFetchLoading] = useState(true);
  const [transaction, setTransaction] = useState({});

  const fetchTransaction = useCallback(async () => {
    setFetchLoading(true);
    const response = await TransactionDetailAPI(transactionId);
    setFetchLoading(false);
    if (response.success) {
      setTransaction(response.data.data);
    } else {
      history.replace('/');
    }
  }, [transactionId]);

  useEffect(() => {
    if (currentUser || !transactionId) {
      canFetchTransactionData.current = false;
      history.replace('/');
    } else {
      canFetchTransactionData.current = true;
    }
  }, [currentUser, transactionId]);

  useEffect(() => {
    if (canFetchTransactionData.current) {
      fetchTransaction();
    }
  }, [transactionId, fetchTransaction, currentUser]);

  const DEFAULT_VALUES = useMemo(() => {
    const fullName = transaction.name || '';
    const splitFullName = fullName.split(' ');
    const breakpoint = Math.floor(splitFullName.length / 2);
    let firstName, lastName;
    if (splitFullName.length <= 1) {
      firstName = splitFullName[0];
      lastName = '';
    } else {
      firstName = splitFullName.slice(0, breakpoint).join(' ');
      lastName = splitFullName.slice(breakpoint).join(' ');
    }

    return {
      email: transaction.email || '',
      password: null,
      confirmPassword: null,
      firstName,
      savedFirstName: firstName,
      lastName,
      savedLastName: lastName,
      identification: transaction.identificationNumber || '',
      savedIdentification: transaction.identificationNumber || '',
      identificationType: transaction.identificationType || 'CC',
      savedIdentificationType: transaction.identificationType || 'CC',
      phone: transaction.phone || '',
      savedPhone: transaction.phone || '',
      phoneCountryCode: transaction.phoneCountryCode || 57,
      savedPhoneCountryCode: transaction.phoneCountryCode || 57,
      terms: null
    };
  }, [transaction]);

  const EMPTY_VALUES = {
    email: null,
    password: null,
    confirmPassword: null,
    firstName: null,
    savedFirstName: null,
    lastName: null,
    savedLastName: null,
    identification: null,
    savedIdentification: null,
    identificationType: null,
    savedIdentificationType: null,
    phone: null,
    savedPhone: null,
    phoneCountryCode: null,
    savedPhoneCountryCode: null,
    terms: null
  };

  // * STATE HOOKS
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordShown, setShowPassword] = useState(false);
  const [confirmPasswordShown, setShowConfirmPassword] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [savedFirstName, setSavedFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [savedLastName, setSavedLastName] = useState('');
  const [identification, setIdentification] = useState('');
  const [savedIdentification, setSavedIdentification] = useState('');
  const [identificationType, setIdentificationType] = useState('');
  const [savedIdentificationType, setSavedIdentificationType] = useState('');
  const [phone, setPhone] = useState('');
  const [savedPhone, setSavedPhone] = useState('');
  const [phoneCountryCode, setPhoneCountryCode] = useState('');
  const [savedPhoneCountryCode, setSavedPhoneCountryCode] = useState('');
  const [terms, setTerms] = useState(false);
  const [errors, setErrors] = useState(EMPTY_VALUES);
  const [dirty, setDirty] = useState(EMPTY_VALUES);
  const [canSubmit, setCanSubmit] = useState(false);
  const [editNameOpen, setEditNameOpen] = useState(false);
  const [canEditName, setCanEditName] = useState(false);
  const [editIdOpen, setEditIdOpen] = useState(false);
  const [canEditId, setCanEditId] = useState(false);
  const [editPhoneOpen, setEditPhoneOpen] = useState(false);
  const [canEditPhone, setCanEditPhone] = useState(false);

  // * FUNCTIONS

  useEffect(() => {
    setEmail(DEFAULT_VALUES.email);
    setFirstName(DEFAULT_VALUES.firstName);
    setSavedFirstName(DEFAULT_VALUES.savedFirstName);
    setLastName(DEFAULT_VALUES.lastName);
    setSavedLastName(DEFAULT_VALUES.savedLastName);
    setIdentification(DEFAULT_VALUES.identification);
    setIdentificationType(DEFAULT_VALUES.identificationType);
    setSavedIdentification(DEFAULT_VALUES.savedIdentification);
    setSavedIdentificationType(DEFAULT_VALUES.savedIdentificationType);
    setPhone(DEFAULT_VALUES.phone);
    setSavedPhone(DEFAULT_VALUES.savedPhone);
    setPhoneCountryCode(DEFAULT_VALUES.phoneCountryCode);
    setSavedPhoneCountryCode(DEFAULT_VALUES.savedPhoneCountryCode);
  }, [DEFAULT_VALUES]);

  useEffect(() => {
    try {
      submitValuesSchema.validateSync(
        {
          email,
          password,
          confirmPassword,
          savedFirstName,
          savedLastName,
          savedIdentification,
          savedIdentificationType,
          savedPhone,
          savedPhoneCountryCode,
          terms
        },
        { abortEarly: false }
      );
      setErrors({});
      setCanSubmit(true);
    } catch (err) {
      setCanSubmit(false);
      setErrors(extractFormErrorsYup(err));
    }
  }, [
    email,
    password,
    confirmPassword,
    terms,
    savedFirstName,
    savedLastName,
    savedIdentification,
    savedIdentificationType,
    savedPhone,
    savedPhoneCountryCode
  ]);

  useEffect(() => {
    try {
      tempValuesSchema.validateSync(
        {
          firstName,
          lastName,
          identification,
          identificationType,
          phone,
          phoneCountryCode
        },
        { abortEarly: false }
      );
      setErrors({});
    } catch (err) {
      setErrors(extractFormErrorsYup(err));
    }
  }, [
    firstName,
    lastName,
    identification,
    identificationType,
    phone,
    phoneCountryCode
  ]);

  // * Edit full name functions

  useEffect(() => {
    if (
      (dirty.firstName || dirty.lastName) &&
      !errors.firstName &&
      !errors.lastName
    ) {
      setCanEditName(true);
    } else {
      setCanEditName(false);
    }
  }, [dirty.firstName, dirty.lastName, errors.firstName, errors.lastName]);

  const renderNameInputs = () => (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextInput
          id="Signup_input_firstName"
          inputRef={inputRef}
          label="Nombres"
          value={firstName}
          onChange={onChangeFirstName}
          required={true}
          helperText={dirty.firstName && errors.firstName}
          error={dirty.firstName && Boolean(errors.firstName)}
          margin="none"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput
          id="Signup_input_lastName"
          label="Apellidos"
          value={lastName}
          onChange={onChangeLastName}
          required={true}
          helperText={dirty.lastName && errors.lastName}
          error={dirty.lastName && Boolean(errors.lastName)}
          margin="none"
          fullWidth
        />
      </Grid>
    </Grid>
  );

  const handleEditNameClick = () => {
    setEditNameOpen(true);
    setFirstName(savedFirstName);
    setLastName(savedLastName);
  };

  const handleConfirmNameClick = () => {
    setEditNameOpen(false);
    setSavedFirstName(firstName);
    setSavedLastName(lastName);
    setDirty(d => ({ ...d, firstName: false, lastName: false }));
    setAlert({
      type: 'success',
      message: 'Tus nombres y apellidos han sido editados'
    });
  };

  const handleCancelNameClick = () => {
    setEditNameOpen(false);
    setDirty(d => ({ ...d, firstName: null, lastName: null }));
  };

  // * Edit identification functions

  useEffect(() => {
    if (
      (dirty.identification || dirty.identificationType) &&
      !errors.identification &&
      !errors.identificationType
    ) {
      setCanEditId(true);
    } else {
      setCanEditId(false);
    }
  }, [
    dirty.identification,
    dirty.identificationType,
    errors.identification,
    errors.identificationType
  ]);

  const handleEditIdClick = () => {
    setEditIdOpen(true);
    setIdentification(savedIdentification);
    setIdentificationType(savedIdentificationType);
  };

  const renderIdInputs = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextInput
            id="Signup_input_identification"
            label="Identificación"
            value={identification}
            autoFocus
            onChange={onChangeIdentification}
            required={true}
            helperText={dirty.identification && errors.identification}
            error={dirty.identification && Boolean(errors.identification)}
            margin="none"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectInput
            id="Signup_input_identificationType"
            label="Tipo"
            value={identificationType}
            onChange={onChangeIdentificationType}
            required={true}
            helperText={dirty.identificationType && errors.identificationType}
            error={
              dirty.identificationType && Boolean(errors.identificationType)
            }
            options={IDENTIFICATION_OPTIONS}
            margin="none"
            fullWidth
          />
        </Grid>
      </Grid>
    );
  };

  const handleConfirmIdClick = () => {
    setEditIdOpen(false);
    setSavedIdentification(identification);
    setSavedIdentificationType(identificationType);
    setDirty(d => ({
      ...d,
      identification: false,
      identificationType: false
    }));
    setAlert({
      type: 'success',
      message: 'Tu identificación ha sido editada'
    });
  };

  const handleCancelIdClick = () => {
    setEditIdOpen(false);
    setDirty(d => ({ ...d, identification: null, identificationType: null }));
  };

  // * Edit phone functions

  useEffect(() => {
    if (
      (dirty.phone || dirty.phoneCountryCode) &&
      !errors.phone &&
      !errors.phoneCountryCode
    ) {
      setCanEditPhone(true);
    } else {
      setCanEditPhone(false);
    }
  }, [
    dirty.phone,
    dirty.phoneCountryCode,
    errors.phone,
    errors.phoneCountryCode
  ]);

  const renderPhoneInputs = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <NumberInput
            id="Signup_input_phoneCountryCode"
            autoComplete="tel-country-code"
            required
            value={phoneCountryCode}
            onValueChange={onChangePhoneCountryCode}
            InputProps={{
              startAdornment: (
                <FlagColombia size={20} style={{ flexShrink: 0 }} />
              )
            }}
            format="+###"
            placeholder="57"
            fullWidth
            label="Prefijo"
            helperText={dirty.phoneCountryCode && errors.phoneCountryCode}
            error={dirty.phoneCountryCode && Boolean(errors.phoneCountryCode)}
            margin="none"
          />
        </Grid>

        <Grid item xs={8}>
          <NumberInput
            id="Signup_input_phone"
            autoComplete="tel-local"
            required
            value={phone}
            onValueChange={onChangePhone}
            fullWidth
            margin="none"
            label="Número celular"
            helperText={dirty.phone && errors.phone}
            error={dirty.phone && Boolean(errors.phone)}
          />
        </Grid>
      </Grid>
    );
  };

  const handleEditPhoneClick = () => {
    setEditPhoneOpen(true);
    setPhone(savedPhone);
    setPhoneCountryCode(savedPhoneCountryCode);
  };

  const handleConfirmPhoneClick = () => {
    setEditPhoneOpen(false);
    setSavedPhone(phone);
    setSavedPhoneCountryCode(phoneCountryCode);
    setDirty(d => ({ ...d, phone: false, phoneCountryCode: false }));
    setAlert({
      type: 'success',
      message: 'Tu teléfono ha sido editado'
    });
  };

  const handleCancelPhoneClick = () => {
    setEditPhoneOpen(false);
    setDirty(d => ({ ...d, phone: null, phoneCountryCode: null }));
  };

  // * Helpers
  const renderActions = useCallback(
    (handleConfirmClick, handleCancelClick, canEdit) => {
      return () => (
        <>
          <BaseButton
            onClick={handleCancelClick}
            variant="outlined"
            color="primary"
            size="small"
          >
            Cancelar
          </BaseButton>
          <BaseButton
            onClick={handleConfirmClick}
            color="primary"
            loading={loading}
            disabled={!canEdit}
            size="small"
            autoFocus
          >
            Editar
          </BaseButton>
        </>
      );
    },
    [loading]
  );

  const togglePasswordShow = useCallback(() => {
    setShowPassword(!passwordShown);
  }, [passwordShown]);

  const toggleConfirmPasswordShow = useCallback(() => {
    setShowConfirmPassword(!confirmPasswordShown);
  }, [confirmPasswordShown]);

  const onChangeEmail = useCallback(event => {
    setDirty(d => ({ ...d, email: true }));
    setEmail(event.target.value);
  }, []);

  const onChangePassword = useCallback(event => {
    setDirty(d => ({ ...d, password: true }));
    setPassword(event.target.value);
  }, []);

  const onChangeConfirmPassword = useCallback(event => {
    setDirty(d => ({ ...d, confirmPassword: true }));
    setConfirmPassword(event.target.value);
  }, []);

  const onChangeFirstName = useCallback(event => {
    setDirty(d => ({ ...d, firstName: true }));
    setFirstName(event.target.value);
  }, []);

  const onChangeLastName = useCallback(event => {
    setDirty(d => ({ ...d, lastName: true }));
    setLastName(event.target.value);
  }, []);

  const onChangeIdentification = useCallback(event => {
    setDirty(d => ({ ...d, identification: true }));
    setIdentification(event.target.value);
  }, []);

  const onChangeIdentificationType = useCallback(event => {
    setDirty(d => ({ ...d, identificationType: true }));
    setIdentificationType(event.target.value);
  }, []);

  const onChangePhone = useCallback(({ value }) => {
    setDirty(d => ({ ...d, phone: true }));
    setPhone(value);
  }, []);

  const onChangePhoneCountryCode = useCallback(({ value }) => {
    setDirty(d => ({ ...d, phoneCountryCode: true }));
    setPhoneCountryCode(value);
  }, []);

  const onChangeTerms = useCallback(() => {
    setDirty(d => ({ ...d, terms: true }));
    setTerms(!terms);
  }, [terms]);

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      setLoading(true);
      const response = await TransactionSignupAPI({
        firstName: savedFirstName.trim(),
        lastName: savedLastName.trim(),
        identification: savedIdentification.trim(),
        identificationType: savedIdentificationType.trim(),
        email: email.trim(),
        password: password.trim(),
        phone: savedPhone.trim(),
        phoneCountryCode: savedPhoneCountryCode.trim(),
        contractId: transaction.contractId
      });

      if (!response.success) {
        setLoading(false);
        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
        return;
      }
      history.replace(ROUTE_NAMES.login, { email });
    },
    [
      email,
      savedFirstName,
      savedLastName,
      savedIdentification,
      savedIdentificationType,
      password,
      savedPhone,
      savedPhoneCountryCode,
      transaction.contractId,
      setAlert
    ]
  );

  // eslint-disable-next-line complexity
  const renderInputs = () => {
    return (
      <Grid
        container
        spacing={mediumScreen ? 2 : 1}
        className={classes.inputsContainer}
      >
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            id="Signup_input_email"
            label="Correo electrónico"
            value={email}
            onChange={onChangeEmail}
            required={true}
            helperText={dirty.email && errors.email}
            error={dirty.email && Boolean(errors.email)}
            margin="none"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            id="Signup_input_password"
            type={passwordShown ? 'text' : 'password'}
            label="Contraseña"
            value={password}
            onChange={onChangePassword}
            required={true}
            InputProps={{
              endAdornment: (
                <ShowPassword
                  passwordShown={passwordShown}
                  togglePasswordShow={togglePasswordShow}
                />
              )
            }}
            helperText={dirty.password && errors.password}
            error={dirty.password && Boolean(errors.password)}
            margin="none"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            id="Signup_input_confirmPassword"
            type={confirmPasswordShown ? 'text' : 'password'}
            label="Confirmar contraseña"
            value={confirmPassword}
            onChange={onChangeConfirmPassword}
            required={true}
            InputProps={{
              endAdornment: (
                <ShowPassword
                  passwordShown={confirmPasswordShown}
                  togglePasswordShow={toggleConfirmPasswordShow}
                />
              )
            }}
            helperText={dirty.confirmPassword && errors.confirmPassword}
            error={dirty.confirmPassword && Boolean(errors.confirmPassword)}
            margin="none"
            fullWidth
          />
        </Grid>
      </Grid>
    );
  };

  const renderPersonalDataContainer = () => {
    return (
      <div className={classes.personalDataContainer}>
        <Typography
          className={clsx(classes.message, classes.personalDataMessage)}
        >
          Revisa tus datos personales
        </Typography>

        <Grid
          container
          className={classes.personalDataRow}
          spacing={mediumScreen ? 1 : 0}
        >
          <Grid item xs={12} sm={6} md={3}>
            <EditableField
              editable
              label="Nombres"
              value={savedFirstName}
              handleEditClick={handleEditNameClick}
              error={!savedFirstName && 'Ingresa tu nombre'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <EditableField
              editable
              label="Apellidos"
              value={savedLastName}
              handleEditClick={handleEditNameClick}
              error={!savedLastName && 'Ingresa tus apellidos'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <EditableField
              editable
              label="Identificación"
              value={`${savedIdentificationType} ${savedIdentification}`}
              handleEditClick={handleEditIdClick}
              error={!savedIdentification && 'Ingresa tu identificación'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <EditableField
              editable
              label="Teléfono"
              value={
                <>
                  +{savedPhoneCountryCode}
                  <NumberFormat
                    displayType="text"
                    value={savedPhone}
                    mask=" "
                    format=" (###) ###-####"
                  />
                </>
              }
              handleEditClick={handleEditPhoneClick}
              error={!savedPhone && 'Ingresa tu teléfono'}
            />
          </Grid>
        </Grid>

        {transaction.contractId && (
          <>
            <Typography
              className={clsx(classes.message, classes.personalDataMessage)}
            >
              Revisa los datos{' '}
              {Company.contractConjugation.regular.singular.contraction}
            </Typography>
            <Grid
              container
              className={classes.personalDataRow}
              spacing={mediumScreen ? 1 : 0}
            >
              {transaction.contractAddress && (
                <Grid item xs={12} sm={6} md={4}>
                  <EditableField
                    label={`Dirección ${Company.contractConjugation.regular.singular.contraction}`}
                    value={transaction.contractAddress}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={6} md={4}>
                <EditableField
                  label={`Número ${Company.contractConjugation.regular.singular.contraction}`}
                  value={transaction.contractId}
                />
              </Grid>
            </Grid>
          </>
        )}
      </div>
    );
  };

  const renderTerms = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            id="Signup_input_terms"
            checked={terms}
            onClick={onChangeTerms}
            icon={<CheckBoxOutlineBlankIcon fontSize="small" color="primary" />}
            checkedIcon={<CheckBoxIcon fontSize="small" color="primary" />}
            disabled={Boolean(currentUser)}
            value="checkedI"
          />
        }
        label={
          <Typography className={classes.termsText}>
            He leído y acepto los{' '}
            <a
              className={classes.link}
              href={ROUTE_NAMES.information}
              rel="noopener noreferrer"
              target="_blank"
            >
              términos y condiciones
            </a>{' '}
            de uso, la{' '}
            <a href={PrivacyPoliciesLink}>política y aviso de privacidad </a> y
            autorizo el tratamiento de mis datos personales.
          </Typography>
        }
      />
    );
  };

  return (
    <div className={classes.root}>
      {fetchLoading ? (
        <FullSizeProgress />
      ) : (
        <form onSubmit={handleSubmit} className={classes.mainContainer}>
          <BaseDialog
            handleClose={handleCancelNameClick}
            open={editNameOpen}
            content={renderNameInputs}
            contentSize="small"
            actions={renderActions(
              handleConfirmNameClick,
              handleCancelNameClick,
              canEditName
            )}
            title="Editar nombres y apellidos"
            titleStyle={classes.dialogTitle}
            contentStyle={classes.dialogContent}
            actionsStyle={classes.dialogActions}
          />

          <BaseDialog
            handleClose={handleCancelIdClick}
            open={editIdOpen}
            content={renderIdInputs}
            contentSize="small"
            actions={renderActions(
              handleConfirmIdClick,
              handleCancelIdClick,
              canEditId
            )}
            title="Editar identificación"
            titleStyle={classes.dialogTitle}
            contentStyle={classes.dialogContent}
            actionsStyle={classes.dialogActions}
          />

          <BaseDialog
            handleClose={handleCancelPhoneClick}
            open={editPhoneOpen}
            content={renderPhoneInputs}
            contentSize="small"
            actions={renderActions(
              handleConfirmPhoneClick,
              handleCancelPhoneClick,
              canEditPhone
            )}
            title="Editar teléfono"
            titleStyle={classes.dialogTitle}
            contentStyle={classes.dialogContent}
            actionsStyle={classes.dialogActions}
          />

          <Typography component="h5" variant="h5" className={classes.mainTitle}>
            ¡Estas a un paso de crear tu cuenta!
          </Typography>
          <Typography className={classes.message}>
            Completa tus datos de usuario
          </Typography>
          {renderInputs()}
          {renderPersonalDataContainer()}
          {renderTerms()}
          <SimpleDivider className={classes.divider} />
          <Grid container justify="flex-end">
            <BaseButton
              id="Signup_button_submit"
              loading={loading}
              disabled={!canSubmit}
              type="submit"
              color="primary"
              variant="contained"
              fullWidth={smallScreen}
            >
              Regístrate
            </BaseButton>
          </Grid>
        </form>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.cardDark,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      padding: `${theme.spacing(4)}px ${theme.spacing(2)}px ${theme.spacing(
        5
      )}px`
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 15
    }
  },
  mainContainer: {
    backgroundColor: 'white',
    borderRadius: theme.custom.borderRadius,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(5),
    width: '100%',
    maxWidth: 848,

    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: `${theme.spacing(4)}px ${theme.spacing(2)}px ${theme.spacing(
        12
      )}px`
    }
  },
  mainTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 20,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 16,
      marginBottom: theme.spacing(3)
    }
  },
  inputsContainer: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginBottom: theme.spacing(4)
    }
  },
  message: {
    marginBottom: theme.spacing(3),
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium
  },
  personalDataContainer: {
    padding: theme.spacing(3),
    border: '1px solid #e7e7e7',
    borderRadius: theme.custom.borderRadius,
    backgroundColor: '#f9f9f9',
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginBottom: theme.spacing(3)
    }
  },
  personalDataMessage: {
    marginBottom: theme.spacing(2)
  },
  personalDataRow: {
    marginBottom: theme.spacing(3),
    '&:last-of-type': {
      marginBottom: 0
    }
  },
  divider: {
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      margin: `${theme.spacing(4)} 0`
    }
  },
  termsText: {
    fontSize: 12
  },
  link: {
    color: '#424242',
    fontWeight: 500
  },
  dialogTitle: { padding: `${theme.spacing(2)}px ${theme.spacing(3)}px` },
  dialogContent: { padding: theme.spacing(3), minHeight: 'auto' },
  dialogActions: { padding: theme.spacing(3) }
}));

export default TransactionSignup;
