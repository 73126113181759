import React, { Fragment, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { SlideUpTransition } from '../Transitions/Transitions';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

import CloseIcon from '../CustomIcons/CloseIcon';
import ChatAgentIcon from '../CustomIcons/IcChatAgent';
import WhatsAppIcon from '../../Components/CustomIcons/WhatsappIcon';
import FaqActionButton from '../Buttons/FaqActionButton';
import { WhatsAppHelpLinkHandler } from '../../Utils/Misc/Links';
import { Card, CardContent, CardMedia, Slide } from '@material-ui/core';
import { WADialogText } from '../../Utils/enums';

function WhatsAppDialog(props) {
  const { open = true, onClose } = props;
  const classes = useStyles();

  const isMobileSize = isWidthDown('xs', props.width);

  // Added so the user can close the Card when pressing Esc
  useEffect(() => {
    const handleEsc = event => {
      if (event.keyCode === 27) {
        onClose(false);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  return (
    <Fragment>
      {isMobileSize ? (
        <Dialog
          id={'WhatsAppDialog_div'}
          open={open}
          onClose={() => onClose(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{
            paper: classes.paperFullScreen
          }}
          fullScreen={true}
          TransitionComponent={SlideUpTransition}
        >
          <DialogTitle disableTypography className={classes.titleBanner}>
            <Typography className={classes.titleText}>
              Atención en línea
            </Typography>
            <IconButton
              size="small"
              aria-label="cerrar diálogo"
              onClick={() => onClose(false)}
              className={classes.titleButton}
            >
              <CloseIcon size={10} className={classes.titleIcon} />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <ChatAgentIcon
              size={isMobileSize ? 80 : 54}
              className={classes.chatIconMargin}
            />
            <Typography className={classes.welcomeText}>
              {WADialogText.title}
            </Typography>
            <Typography className={classes.messageText}>
              {WADialogText.abstract}
            </Typography>
            <Grid container className={classes.buttonsContainer}>
              <Grid item className={classes.fullWidth} sm={12}>
                <FaqActionButton
                  text={WADialogText.buttonMessage}
                  className={classes.iconButton}
                  onClick={WhatsAppHelpLinkHandler}
                  icon={<WhatsAppIcon className={classes.iconStyleWhatsApp} />}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      ) : (
        <Fragment>
          <Slide direction="up" in={open} mountOnEnter unmountOnExit>
            <Card className={classes.cardStyling}>
              <CardMedia alt="chat_banner" className={classes.titleBanner}>
                <Typography className={classes.titleText}>
                  Atención en línea
                </Typography>
                <IconButton
                  size="small"
                  aria-label="cerrar diálogo"
                  onClick={() => onClose(false)}
                  className={classes.titleButton}
                >
                  <CloseIcon size={10} className={classes.titleIcon} />
                </IconButton>
              </CardMedia>
              <CardContent className={classes.dialogContent}>
                <ChatAgentIcon
                  size={isMobileSize ? 80 : 54}
                  className={classes.chatIconMargin}
                />
                <Typography className={classes.welcomeText}>
                  {WADialogText.title}
                </Typography>
                <Typography className={classes.messageText}>
                  {WADialogText.abstract}
                </Typography>
                <Grid container className={classes.buttonsContainer}>
                  <Grid item className={classes.fullWidth} sm={12}>
                    <FaqActionButton
                      text={WADialogText.buttonMessage}
                      className={classes.iconButton}
                      onClick={WhatsAppHelpLinkHandler}
                      icon={
                        <WhatsAppIcon className={classes.iconStyleWhatsApp} />
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Slide>
        </Fragment>
      )}
    </Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  paperFullscreen: {
    borderRadius: 0,
    overflowX: 'hidden'
  },
  cardStyling: {
    [theme.breakpoints.up('sm')]: {
      boxShadow: '0 -2px 16px rgba(0, 0, 0, 0.16)',
      width: 252,
      zIndex: 232,
      position: 'fixed',
      inset: 'inherit !important',
      right: '32px !important',
      bottom: '-10px !important'
    }
  },
  titleBanner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 52,
    [theme.breakpoints.up('sm')]: {
      height: 35
    },
    backgroundColor: '#2366C9',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  titleText: {
    color: 'white',
    fontSize: 18,
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  titleButton: {
    padding: theme.spacing(1.5),
    fill: 'white'
  },
  titleContent: {
    marginBottom: 14
  },
  titleIcon: {
    fill: 'white'
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  buttonsContainer: {
    marginTop: theme.spacing(2.5),
    marginBottom: 1
  },
  dialogButton: {
    fontWeight: 500
  },
  welcomeText: {
    marginTop: theme.spacing(1),
    color: '#2366C9',
    fontSize: 18,
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    },
    fontWeight: 1000
  },
  chatIconMargin: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2.5)
  },
  messageText: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    color: '#696A6A',
    maxWidth: '100%',
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 12
    },
    textAlign: 'center'
  },
  iconStyleWhatsApp: {
    fill: 'currentcolor',
    marginRight: 4,
    fontSize: '40px !important'
  },
  iconButton: {
    height: 64,
    backgroundColor: '#00BB2D',
    color: 'white',
    fontWeight: 500,
    fontSize: 16,
    paddingLeft: 0,
    paddingRight: 0,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
      height: 43
    },
    '&:hover': {
      opacity: 0.9,
      backgroundColor: '#00BB2D'
    }
  },
  fullWidth: {
    width: '100%'
  }
}));

export default withWidth()(WhatsAppDialog);
