import { history } from '../../Routes/history';

export const redirectOnAuthFailure = (response, target, clearUser) => {
  if (
    response.error &&
    response.error.response &&
    response.error.response.status === 401
  ) {
    history.replace(target);
    clearUser();
    return true;
  }
  return false;
};

export const extractErrorMessage = response => {
  const httpResponse = response.error.response;

  if (!httpResponse || !httpResponse.data || !httpResponse.data.errors) {
    return {
      key: 'base',
      message: 'Ocurrió un error, intente más tarde'
    };
  }
  const keys = Object.keys(httpResponse.data.errors);
  const key = keys[0];

  return {
    key,
    message: httpResponse.data.errors[key]
  };
};

export const extractFormErrorsYup = err => {
  if (err.inner) {
    return err.inner.reduce((total, next) => {
      return {
        ...total,
        [next.path]: next.message
      };
    }, {});
  }
  return {};
};
