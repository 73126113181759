import React from 'react';

const SvgIcPointMap = props => (
  <svg
    id="ic_map_pointer_svg2"
    width={props.width || 22.6}
    height={props.height || 22.6}
    viewBox="0 0 22.6 22.6"
    xmlSpace="preserve"
    fill={'#104eb2'}
    {...props}
  >
    <path
      data-name="Path 534"
      d="M15.849 16.181a.376.376 0 00-.088.747c3.957.466 6.065 1.721 6.065 2.449 0 1.021-4.008 2.446-10.536 2.446S.753 20.4.753 19.381c0-.727 2.108-1.983 6.065-2.449a.378.378 0 10-.089-.751C2.767 16.65 0 17.964 0 19.381c0 1.589 3.878 3.2 11.289 3.2s11.289-1.609 11.289-3.2c0-1.417-2.767-2.731-6.729-3.2z"
      fill="#2366c9"
    />
    <path
      data-name="Path 535"
      d="M11.222 20.605l5.773-8.338a8.222 8.222 0 00-.67-10.153 7.216 7.216 0 00-10.205 0 8.22 8.22 0 00-.679 10.142zm.067-16.09A2.634 2.634 0 118.655 7.15a2.637 2.637 0 012.634-2.634z"
      fill="#2366c9"
    />
  </svg>
);

export default SvgIcPointMap;
