export const SignInMethod = {
  Password: 'password',
  Social: 'social'
};

export const isUserLoggedInWithPassword = user => {
  return user && user.signInMethod === SignInMethod.Password;
};

export const isUserLoggedInWithSocial = user => {
  return user && user.signInMethod === SignInMethod.Social;
};
