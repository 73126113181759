import React, { Fragment, useState, useCallback, useContext } from 'react';
import { Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { GetSingleContractAPI } from '../../../../API/Contracts/ContractsAPI';
import { extractErrorMessage } from '../../../../Utils/Errors/Errors';

import { AlertsDispatchContext } from '../../../../Contexts/AlertsContext';

import { SlideUpTransition } from '../../../../Components/Transitions/Transitions';
import BaseDialog from '../../../../Components/Dialogs/BaseDialog';
import BaseButton from '../../../../Components/Buttons/BaseButton';
import { DigitalInvoiceDeactivationStepId } from '../ContractDigitalInvoiceDeactivation';
import { Company } from '../../../../Configs/general';

import _get from 'lodash/get';

const ContractStep = props => {
  const { open, title, onClose, setNextModal, step, currentUser } = props;

  const contractId = step.data.contractId || '';
  const contractAlias = step.data.contractAlias || '';

  const authToken = _get(currentUser, 'token');

  // * STATE HOOKS

  // * CONTEXT HOOKS
  const setAlert = useContext(AlertsDispatchContext);
  const [loading, setLoading] = useState(false);
  // * OTHER HOOKS
  const classes = useStyles();

  const isMobileSize = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();

      if (loading) {
        return false;
      }

      setLoading(true);

      const response = await GetSingleContractAPI(contractId, authToken);
      if (response.success) {
        setLoading(false);
        setNextModal(previousValue => ({
          ...previousValue,
          type: DigitalInvoiceDeactivationStepId.Validation,
          data: {
            ...previousValue.data,
            contractId,
            contractAlias,
            contract: { ...response.data.data }
          }
        }));
      } else {
        setLoading(false);
        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
      }
    },
    [contractId, loading, setAlert, setNextModal, authToken, contractAlias]
  );

  //* COMPONENTS

  const renderActions = useCallback(() => {
    return (
      <Fragment>
        <BaseButton
          onClick={onClose}
          variant="outlined"
          color="primary"
          size="small"
        >
          Cancelar
        </BaseButton>
        <BaseButton
          color="primary"
          size="small"
          disabled={loading}
          onClick={handleSubmit}
        >
          Consultar
        </BaseButton>
      </Fragment>
    );
  }, [handleSubmit, onClose, loading]);

  const renderContent = useCallback(() => {
    return (
      <div id="ContractStep_div_container" className={classes.content}>
        <Typography className={classes.text}>
          Iniciaremos el proceso* de desactivación de factura digital para el
          siguiente {Company.contractConjugation.regular.singular.main}:
        </Typography>
        <Typography className={classes.contract}>
          <span className={classes.bold}>
            {Company.contractConjugation.capitalized.singular.main}:
          </span>{' '}
          {contractId} ({contractAlias})
        </Typography>
        <Typography className={classes.textSmall}>
          *Para el proceso validaremos tu número de teléfono y tu identificación
        </Typography>
      </div>
    );
  }, [contractId, contractAlias, classes]);

  // * RENDER
  return (
    <BaseDialog
      open={open}
      loading={loading}
      handleClose={onClose}
      title={title}
      actions={renderActions}
      content={renderContent}
      fullScreen={isMobileSize}
      contentSize="small"
      TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
    />
  );
};

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  text: {
    fontSize: 14,
    color: theme.palette.text.primary,
    width: '100%'
  },
  contract: {
    marginTop: theme.spacing(3)
  },
  bold: {
    fontWeight: 600
  },
  textSmall: {
    fontSize: 10,
    color: theme.palette.text.blackLight,
    marginTop: theme.spacing(),
    width: '100%'
  }
}));

export default ContractStep;
