import React from 'react';

const SvgIcBrilla = props => (
  <svg
    id="ic_brilla_svg__img_contract"
    width={props.size || 149}
    height={props.size || 149}
    viewBox="0 0 149 149"
    xmlSpace="preserve"
    fill={'#fff'}
    {...props}
  >
    <defs>
      <linearGradient
        id="ic_brilla_svg__linear-gradient"
        x1={0.017}
        x2={1}
        y1={0.014}
        y2={0.99}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#fe685e" />
        <stop offset={1} stopColor="#e81c3a" />
      </linearGradient>
    </defs>
    <path
      id="ic_brilla_svg__Path_373"
      d="M6 0h137a6 6 0 016 6v137a6 6 0 01-6 6H6a6 6 0 01-6-6V6a6 6 0 016-6z"
      data-name="Path 373"
      fill="url(#ic_brilla_svg__linear-gradient)"
    />
    <path
      id="ic_brilla_svg__Path_549"
      d="M21.329 163.72c0-4.958.019-9.917-.018-14.875-.007-.92.206-1.285 1.212-1.272 4.059.053 8.12.013 12.179.024a13.361 13.361 0 017.075 1.752 8.079 8.079 0 013.03 10.239 6.336 6.336 0 01-2.868 2.952c-.231.121-.54.189-.552.51-.012.342.325.4.55.5 5.268 2.158 5.481 8.652 3.282 12.044-1.58 2.437-4.006 3.494-6.741 4.009a18.752 18.752 0 01-3.478.254H22.72c-1.389 0-1.39-.007-1.39-1.353l-.001-14.784zm11.189 9.688c1.032 0 2.065.034 3.094-.008a3.268 3.268 0 003.367-2.559 3.313 3.313 0 00-2.993-4.21 57.348 57.348 0 00-6.68-.167c-.521-.015-.588.284-.586.7.008 1.864.018 3.727-.01 5.591-.008.55.22.675.712.663 1.033-.027 2.065-.01 3.096-.01zm-.255-19.354c-1.065 0-1.964.013-2.862-.006-.441-.009-.687.113-.683.607.013 1.6.007 3.194.006 4.791 0 .332.058.6.484.583 2.157-.079 4.331.235 6.469-.194a2.684 2.684 0 002.4-2.852 2.788 2.788 0 00-2.5-2.789 21.023 21.023 0 00-3.314-.14z"
      className="ic_brilla_svg__cls-2"
      data-name="Path 549"
      transform="translate(.331 -88.742)"
    />
    <path
      id="ic_brilla_svg__Path_550"
      d="M409.55 202.856c0 2.263-.028 4.526.014 6.788.016.83-.268 1.152-1.1 1.082a11.3 11.3 0 00-1.9 0c-1.007.085-1.824-.026-2.2-1.178-.216-.657-.762-.546-1.218-.194-3.068 2.362-6.415 2.09-9.759.882-2.779-1-4.107-3.344-4.04-6.561a6.077 6.077 0 014.305-5.753 14.617 14.617 0 014.9-.782c.932-.011 1.864.019 2.795-.01.4-.013.976.234 1.174-.2.214-.47-.147-.971-.442-1.365a3.919 3.919 0 00-2.737-1.467 10.782 10.782 0 00-4.717.542c-1.7.514-1.7.508-2.192-1.153-.34-1.147-.7-2.289-1.028-3.438-.126-.436-.173-.835.424-1.025a19.68 19.68 0 0110.9-.728c4.495 1.108 6.811 4.042 6.818 8.672q.007 2.943.003 5.888zm-9.67-1.153a8.431 8.431 0 00-2.546.19 1.616 1.616 0 00-1.2 1.438 1.7 1.7 0 00.936 1.732 3.812 3.812 0 005.478-2.653c.071-.472.049-.762-.558-.717-.623.047-1.257.007-2.11.007z"
      className="ic_brilla_svg__cls-2"
      data-name="Path 550"
      transform="translate(-282.18 -119.638)"
    />
    <path
      id="ic_brilla_svg__Path_551"
      d="M285.569 157.543v-12.478c0-1.363.012-1.376 1.413-1.378h4.592c1.257 0 1.308.052 1.309 1.286v24.157c0 .333 0 .666.02 1s.222.653.535.607c1.044-.153 1.174.627 1.436 1.294.449 1.145.879 2.3 1.355 3.431.293.7.166 1.092-.6 1.354a7.335 7.335 0 01-10.057-7.1c-.011-4.059 0-8.119 0-12.178z"
      className="ic_brilla_svg__cls-2"
      data-name="Path 551"
      transform="translate(-202.517 -85.758)"
    />
    <path
      id="ic_brilla_svg__Path_552"
      d="M339.372 157.618c0-4.256.029-8.513-.021-12.769-.012-1 .336-1.267 1.273-1.232 1.594.06 3.193.055 4.787 0 .907-.031 1.215.277 1.211 1.2-.03 8.047-.019 16.095-.02 24.142 0 .9-.181 1.913 1.279 1.8.309-.025.4.323.495.561.567 1.419 1.107 2.85 1.686 4.264.256.624 0 .9-.556 1.129a7.3 7.3 0 01-10.133-6.725c-.011-4.123-.001-8.246-.001-12.37z"
      className="ic_brilla_svg__cls-2"
      data-name="Path 552"
      transform="translate(-243.801 -85.705)"
    />
    <path
      id="ic_brilla_svg__Path_553"
      d="M142.511 199.791c0-3.329.029-6.658-.018-9.986-.013-.944.343-1.28 1.229-1.186a1.875 1.875 0 00.5 0 3.169 3.169 0 013.883 2.052c.355.862.857.8 1.476.127a8.564 8.564 0 016.359-2.644c.742-.022 1.019.231 1 .969-.032 1.5-.03 3 0 4.493.012.644-.159.933-.878 1-3.47.332-5.04 1.782-5.729 5.185a50.221 50.221 0 00-.569 9.848c-.006 1.4-.008 1.4-1.457 1.406-1.531 0-3.063-.032-4.593.013-.881.026-1.233-.237-1.218-1.191.053-3.361.021-6.724.021-10.086z"
      className="ic_brilla_svg__cls-2"
      data-name="Path 553"
      transform="translate(-92.69 -119.892)"
    />
    <path
      id="ic_brilla_svg__Path_554"
      d="M218.277 193.329c3.433 2.645 6.732 2.65 10.015.158.308.206.179.5.179.736.009 6.555-.006 13.109.024 19.664 0 .871-.272 1.185-1.143 1.16a79.726 79.726 0 00-4.89 0c-.893.03-1.224-.235-1.215-1.183.044-4.591-.007-9.183.042-13.774.01-.935-.276-1.167-1.171-1.144-1.913.05-1.851.265-1.842-1.8.006-1.194.001-2.39.001-3.817z"
      className="ic_brilla_svg__cls-2"
      data-name="Path 554"
      transform="translate(-150.861 -123.866)"
    />
    <path
      id="ic_brilla_svg__Path_555"
      d="M219.7 153.884a6.21 6.21 0 116.3-6.212 6.176 6.176 0 01-6.3 6.212z"
      data-name="Path 555"
      transform="translate(-147.257 -84.056)"
      fill="#fead00"
    />
  </svg>
);

export default SvgIcBrilla;
