import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import RequestIcon from './RequestIcon';
import { renderType } from './enums';

const RequestType = ({ type, withIcon = true }) => {
  const classes = useStyles();
  return (
    <div className={classes.typeContainer}>
      {withIcon && <RequestIcon type={type} />}

      <Typography component="h6" variant="h6" className={classes.typeText}>
        {renderType(type)}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles(() => ({
  typeContainer: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  typeText: {
    fontSize: '1em',
    lineHeight: 1.3
  }
}));

export default RequestType;
