import * as React from 'react';

function CrownIcon(props) {
  return (
    <svg
      id="ic-crown"
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || '24'}
      height={props.size || '24'}
      viewBox="0 0 32 32"
    >
      <path
        id="Trazado_672"
        data-name="Trazado 672"
        d="M0,0H32V32H0Z"
        fill="none"
      />
      <path
        id="Trazado_673"
        data-name="Trazado 673"
        d="M18,6l6.667,10L33,9.333,29.667,26H6.333L3,9.333,11.333,16Z"
        transform="translate(-2)"
        fill="none"
        stroke="#fcc52c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
}

export default CrownIcon;
