import React, { Fragment, useState, useEffect, useContext } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  LinearProgress,
  Typography,
  Grid,
  Divider,
  useMediaQuery
} from '@material-ui/core';

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import TextSnippetIcon from '@material-ui/icons/Description';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import BaseDialog from '../../Components/Dialogs/BaseDialog';
import BaseButton from '../../Components/Buttons/BaseButton';

import { GetPoliciesAPI } from '../../API/BrillaInsurances/BrillaInsurancesAPI';
import { UserContext } from '../../Contexts/UserContext';
import { downloadAsset } from '../../Utils/Misc/Assets';
import PractiseguroPDF from '../../Assets/docs/practiseguro-clausulas.pdf';

import clsx from 'clsx';

const InsurancePoliciesQueryDialog = props => {
  const { open, handleClose, contractId, identification } = props;

  const classes = useStyles();
  const isMobileSize = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  const currentUser = useContext(UserContext);

  const [showActions, setShowActions] = useState(true);
  const [title, setTitle] = useState('Consultar pólizas');
  const [checkPolicies, setCheckPolicies] = useState(false);

  const [querying, setQuerying] = useState(true);
  const [policyData, setPolicyData] = useState({});

  const changeStep = () => {
    setShowActions(false);
    setCheckPolicies(true);
  };
  useEffect(() => {
    const fetchData = async () => {
      setTitle('Consultando pólizas');
      setQuerying(true);
      const response = await GetPoliciesAPI(
        currentUser.token,
        contractId,
        identification,
        18
      );
      setQuerying(false);
      setTitle('Estado de las pólizas');
      const responseData = response.data;
      if (responseData) {
        setPolicyData(responseData[0]);
      } else {
        setPolicyData({});
      }
    };

    if (open && checkPolicies) {
      fetchData();
    }
    if (!open && checkPolicies) {
      setTitle('Consultar pólizas');
      setQuerying(false);
      setCheckPolicies(false);
      setPolicyData({});
      setShowActions(true);
    }
  }, [open, checkPolicies, contractId, identification, currentUser]);
  const renderContent = () => {
    return checkPolicies ? (
      <Fragment>
        {querying ? (
          <div>
            <LinearProgress />
            <Typography className={clsx(classes.content, classes.text)}>
              Estamos consultando tus pólizas. En caso de no encontrar tu póliza
              te sugerimos comunicarte con el área de soporte
            </Typography>
          </div>
        ) : (
          <div>
            {Object.keys(policyData).length === 0 && (
              <Typography className={clsx(classes.content, classes.text)}>
                Póliza no encontrada. Te sugerimos comunicarte con el área de
                soporte
              </Typography>
            )}
            {Object.keys(policyData).length > 0 && (
              <Grid className={classes.content}>
                <Typography className={classes.text}>
                  Para descargar tu póliza da click en el botón de “Descargar”
                </Typography>
                <Grid>
                  <PolicyElement
                    name={`poliza - ${policyData.idPoliza}`}
                    urlDownload={policyData.urlPoliza}
                  />
                </Grid>
                <Divider className={classes.divider} />
                <Typography className={classes.text}>
                  ¿No encuentras la póliza que buscabas? Te sugerimos
                  comunicarte con el área de soporte
                </Typography>
              </Grid>
            )}
          </div>
        )}
        <Grid container justify="center" className={classes.buttonContainer}>
          <Grid item xs={12} className={classes.contactButton}>
            <BaseButton
              color="default"
              variant="text"
              size="small"
              className={classes.whatsAppButton}
              startIcon={<WhatsAppIcon />}
              fullWidth={isMobileSize}
            >
              Escríbenos
            </BaseButton>
          </Grid>
          <Grid item xs={12} className={classes.contactButton}>
            <BaseButton
              color="default"
              variant="text"
              size="small"
              className={classes.callUsButton}
              startIcon={<LocalPhoneIcon />}
              fullWidth={isMobileSize}
            >
              Llámanos
            </BaseButton>
          </Grid>
        </Grid>
      </Fragment>
    ) : (
      <Typography className={clsx(classes.content, classes.text)}>
        ¿Estas seguro que deseas consultar tus pólizas?.
      </Typography>
    );
  };
  const renderActions = () => {
    return (
      <Fragment>
        <BaseButton
          onClick={handleClose}
          variant="outlined"
          color="primary"
          size="small"
        >
          No, cancelar
        </BaseButton>
        <BaseButton onClick={() => changeStep()} color="primary" size="small">
          Sí, consultar
        </BaseButton>
      </Fragment>
    );
  };
  return (
    <BaseDialog
      open={open}
      handleClose={handleClose}
      title={title}
      content={renderContent}
      actions={showActions && renderActions}
      contentStyle={classes.dialogContent}
      fullScreen={isMobileSize}
    />
  );
};
const PolicyElement = props => {
  const { name = '', urlDownload } = props;
  const classes = useStyles();

  const downloadFile = () => {
    const a = document.createElement('a');
    a.href = urlDownload;
    a.download = `${name}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  return (
    <Grid
      container
      item
      direction="row"
      wrap="nowrap"
      justify="space-between"
      className={classes.policy}
    >
      <Grid container item direction="row" wrap="nowrap" alignItems="center">
        <TextSnippetIcon />
        <Typography className={clsx(classes.policyNameText, classes.text)}>
          {name}
        </Typography>
      </Grid>
      <Grid item>
        <BaseButton
          color="default"
          variant="text"
          size="small"
          className={classes.downloadPolicyButton}
          startIcon={<CloudDownloadIcon />}
          onClick={
            urlDownload === 'No existe ninguna imagen relacionada a esta poliza'
              ? () =>
                  downloadAsset('Practiseguro - Clausulas.pdf', PractiseguroPDF)
              : () => downloadFile()
          }
        >
          Descargar
        </BaseButton>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  dialogContent: {
    minHeight: 0,
    padding: 0,
    width: 480,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      width: '100%'
    }
  },
  content: {
    padding: theme.spacing(3)
  },
  text: {
    fontSize: 14
  },
  policy: {
    margin: theme.spacing(2, 0)
  },
  policyNameText: {
    color: theme.palette.text.disabled,
    fontWeight: 600,
    marginLeft: theme.spacing(1)
  },
  buttonContainer: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: theme.spacing(0, 2)
    }
  },
  contactButton: {
    margin: theme.spacing(1),
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      flexBasis: 'auto'
    }
  },
  whatsAppButton: {
    color: '#18CC48',
    backgroundColor: '#D6FFE1'
  },
  callUsButton: {
    color: '#104EB2',
    backgroundColor: '#E4EBF6'
  },
  downloadPolicyButton: {
    backgroundColor: '#E4EBF6'
  },
  divider: {
    marginBottom: theme.spacing(1)
  }
}));

export default InsurancePoliciesQueryDialog;
