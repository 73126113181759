/* eslint-disable complexity */
import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';

import BaseButton from '../../Components/Buttons/BaseButton';
import Game from '../Game/Game';

const Slide = ({
  title,
  description,
  extern,
  url,
  image,
  imageMobile,
  game,
  button,
  className,
  contractId = null
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  // * STATE HOOKS
  const [openGame, setOpenGame] = useState(false);

  const coloredButton = () => {
    let colored = {};
    if (button.buttonColor === 'blue' && button.buttonVariant === 'outlined') {
      colored = classes.buttonBlue;
    }
    if (button.buttonColor === 'red' && button.buttonVariant === 'outlined') {
      colored = classes.buttonRed;
    }
    if (button.buttonColor === 'white' && button.buttonVariant === 'outlined') {
      colored = classes.buttonWhite;
    }
    if (
      button.buttonColor === 'orange' &&
      button.buttonVariant === 'outlined'
    ) {
      colored = classes.buttonOrange;
    }
    if (button.buttonColor === 'black' && button.buttonVariant === 'outlined') {
      colored = classes.buttonBlack;
    }

    if (button.buttonColor === 'blue' && button.buttonVariant === 'contained') {
      colored = classes.buttonBlueContained;
    }
    if (button.buttonColor === 'red' && button.buttonVariant === 'contained') {
      colored = classes.buttonRedContained;
    }
    if (
      button.buttonColor === 'white' &&
      button.buttonVariant === 'contained'
    ) {
      colored = classes.buttonWhiteContained;
    }
    if (
      button.buttonColor === 'orange' &&
      button.buttonVariant === 'contained'
    ) {
      colored = classes.buttonOrangeContained;
    }
    if (
      button.buttonColor === 'black' &&
      button.buttonVariant === 'contained'
    ) {
      colored = classes.buttonBlackContained;
    }
    return colored;
  };

  const handleButtonClick = () => {
    if (game && contractId) {
      window.location.href = `/contracts/${contractId}&fd?utm_source=portalweb&utm_medium=web-banner&utm_campaign=factura-digital-promo-1`;
    } else if (extern) {
      window.open(url);
    } else {
      window.location.href = url;
    }
  };

  const isClickable = () => (url || game) && !button;

  return (
    <article
      onClick={isClickable() ? () => handleButtonClick() : undefined}
      style={{
        backgroundImage: `url(${mobileScreen ? imageMobile : image})`
      }}
      className={clsx(
        classes.slideContainer,
        isClickable() && classes.cursorPointer,
        className
      )}
    >
      {title && (
        <Typography className={classes.slideTitle} component="h3">
          {title}
        </Typography>
      )}
      {description && (
        <Typography className={classes.slideDescription}>
          {description}
        </Typography>
      )}
      {(url || game) && button && (
        <BaseButton
          variant={button.buttonVariant || 'outlined'}
          onClick={handleButtonClick}
          className={clsx(classes.slideButton, coloredButton())}
        >
          {button.text}
        </BaseButton>
      )}
      {game && openGame && <Game setOpenGame={setOpenGame} />}
    </article>
  );
};

const useStyles = makeStyles(theme => ({
  slideContainer: {
    padding: '2em 3.5em 2.5em',
    color: theme.palette.common.white,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    boxSizing: 'border-box',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      padding: '1.5em 1.5em 2.5em'
    },
    minHeight: 180
  },
  slideTitle: {
    fontSize: '1.25em',
    fontWeight: 600,
    width: '100%'
  },
  slideDescription: {
    fontWeight: 500,
    fontSize: '0.9em',
    marginTop: theme.spacing(),
    width: '100%',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.up('sm')]: {
      width: '70%'
    }
  },
  slideButton: {
    fontSize: '0.9em',
    padding: '0.5em 1em',
    lineHeight: theme.typography.subtitle1.lineHeight,
    minHeight: 'unset',
    marginTop: theme.spacing(),
    textTransform: 'none',
    '&:hover': {
      filter: 'brightness(95%)'
    }
  },
  buttonOrange: {
    color: 'rgb(247, 147, 30)',
    borderColor: 'rgba(247, 147, 30, 0.7)',
    '&:hover': {
      border: '1px solid rgb(247, 147, 30) !important',
      backgroundColor: 'rgba(247, 147, 30, 0.1) !important'
    }
  },
  buttonBlack: {
    color: 'rgb(0, 0, 0)',
    borderColor: 'rgba(0, 0, 0, 0.7)',
    '&:hover': {
      border: '1px solid rgb(0, 0, 0) !important',
      backgroundColor: 'rgba(0, 0, 0, 0.1) !important'
    }
  },
  buttonRed: {
    color: 'rgb(253, 99, 91)',
    borderColor: 'rgba(253, 99, 91, 0.7)',
    '&:hover': {
      border: '1px solid rgb(253, 99, 91) !important',
      backgroundColor: 'rgba(253, 99, 91, 0.1) !important'
    }
  },
  buttonWhite: {
    color: 'rgb(0, 0, 0)',
    borderColor: 'rgba(255, 255, 255, 0.7)',
    '&:hover': {
      border: '1px solid rgb(255, 255, 255) !important',
      backgroundColor: 'rgba(255, 255, 255, 0.1) !important'
    }
  },
  buttonBlue: {
    color: 'rgb(16, 78, 178)',
    borderColor: 'rgba(16, 78, 178, 0.7)',
    '&:hover': {
      border: '1px solid rgb(16, 78, 178) !important',
      backgroundColor: 'rgba(16, 78, 178, 0.1) !important'
    }
  },
  buttonOrangeContained: {
    color: 'white',
    backgroundColor: 'rgba(247, 147, 30, 1)',
    '&:hover': {
      boxShadow: theme.custom.boxShadow,
      backgroundColor: 'rgba(247, 147, 30, 0.9) !important'
    }
  },
  buttonBlackContained: {
    color: 'white',
    backgroundColor: 'rgb(0, 0, 0, 1) !important',
    '&:hover': {
      boxShadow: theme.custom.boxShadow,
      backgroundColor: 'rgba(0, 0, 0, 0.9) !important'
    }
  },
  buttonRedContained: {
    color: 'white',
    backgroundColor: 'rgba(253, 99, 91, 1) !important',
    '&:hover': {
      boxShadow: theme.custom.boxShadow,
      backgroundColor: 'rgba(253, 99, 91, 0.9) !important'
    }
  },
  buttonWhiteContained: {
    color: 'black',
    backgroundColor: 'rgba(255, 255, 255, 1) !important',
    '&:hover': {
      boxShadow: theme.custom.boxShadow,
      backgroundColor: 'rgba(255, 255, 255, 0.9) !important'
    }
  },
  buttonBlueContained: {
    color: 'white',
    backgroundColor: 'rgba(16, 78, 178, 1) !important',
    '&:hover': {
      boxShadow: theme.custom.boxShadow,
      backgroundColor: 'rgba(16, 78, 178, 0.9) !important'
    }
  },
  cursorPointer: {
    cursor: 'pointer'
  }
}));

export default Slide;
