import React, { Fragment, useState, useContext, useCallback } from 'react';
import clsx from 'clsx';

import _get from 'lodash/get';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

import { history } from '../../Routes/history';

import { UserContext, UserDispatchContext } from '../../Contexts/UserContext';
import { AlertsDispatchContext } from '../../Contexts/AlertsContext';

import ModalProgress from '../../Components/Progress/Modal/ModalProgress';
import UserIcon from '../../Components/CustomIcons/UserIcon';
import PasswordDialog from './PasswordDialog';
import SelectInput from '../../Components/Inputs/SelectInput';
import MyProfileUserInfo from './MyProfile.userInfo';
import MyProfileLinkedAccounts from './MyProfileLinkedAccounts/MyProfile.linkedAccounts';
import MyProfileUserPassword from './MyProfile.userPassword';
import { deserializeToken } from '../../Utils/JWT/token';
import LoginDialog from '../Login/LoginDialog';

const HeaderBanner = require('../../Assets/images/profile_banner.jpg');

const SELECT_OPTIONS = [
  {
    label: 'Datos de la cuenta',
    value: 0
  },
  {
    label: 'Cuentas vinculadas',
    value: 1
  },
  {
    label: 'Privacidad y seguridad',
    value: 2
  }
];

const Profile = ({ width }) => {
  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const setAlert = useContext(AlertsDispatchContext);
  const authToken = currentUser && _get(currentUser, 'token');

  // * STATE HOOKS
  const [passwordDialog, setPasswordDialog] = useState(null);
  const [selectedItem, setSelectedItem] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isFacebookLoading, setIsFacebookLoading] = useState(true);
  const [isAppleLoading, setIsAppleLoading] = useState(false);

  // * OTHER HOOKS
  const classes = useStyles();

  // * FUNCTIONS
  const handleCloseLoginDialog = useCallback(() => {
    history.replace('/');
  }, []);

  if (!currentUser) {
    return <LoginDialog open={true} setOpen={handleCloseLoginDialog} />;
  }

  const selectContent = menuItem => {
    switch (menuItem) {
      case 0:
        return (
          <MyProfileUserInfo
            currentUser={currentUser}
            setAlert={setAlert}
            setCurrentUser={setCurrentUser}
            setLoading={setLoading}
            deserializeToken={deserializeToken}
            setPasswordDialog={setPasswordDialog}
          />
        );
      case 1:
        return (
          <MyProfileLinkedAccounts
            currentUser={currentUser}
            setLoading={setLoading}
            authToken={authToken}
            setAlert={setAlert}
            deserializeToken={deserializeToken}
            setCurrentUser={setCurrentUser}
            setPasswordDialog={setPasswordDialog}
            setSelectedItem={setSelectedItem}
            isFacebookLoading={isFacebookLoading}
            setIsFacebookLoading={setIsFacebookLoading}
            setIsAppleLoading={setIsAppleLoading}
          />
        );
      case 2:
        return (
          <MyProfileUserPassword
            currentUser={currentUser}
            setSelectedItem={setSelectedItem}
            setLoading={setLoading}
            setAlert={setAlert}
            setCurrentUser={setCurrentUser}
            deserializeToken={deserializeToken}
            setPasswordDialog={setPasswordDialog}
          />
        );
      default:
        return <Fragment />;
    }
  };

  return (
    <div className={classes.root}>
      {(loading || isAppleLoading) && (
        <ModalProgress
          id={'Profile_progress_loading'}
          message={isAppleLoading ? 'Desvinculando...' : 'Guardando...'}
        />
      )}
      {passwordDialog && (
        <PasswordDialog
          title={passwordDialog.title}
          open={Boolean(passwordDialog)}
          setDialog={setPasswordDialog}
          setAlert={setAlert}
          requestCallback={passwordDialog.requestCallback}
          successCallback={passwordDialog.successCallback}
        />
      )}
      <Container maxWidth="md" className={classes.container}>
        <Grid container className={classes.mainContainer}>
          {isWidthUp('sm', width) ? (
            <Fragment>
              <div
                id="card-header-content"
                style={{ backgroundImage: `url(${HeaderBanner || ''})` }}
                className={classes.cardHeader}
              >
                <Grid container className={classes.bannerContent}>
                  <UserIcon fill="#fff" className={classes.headerIcon} />
                  <div className={classes.headerTextContainer}>
                    <Typography className={classes.myProfileText}>
                      Mi perfil
                    </Typography>
                    <Typography className={classes.myProfileTextInfo}>
                      Configura y verifica tus datos de contacto.
                    </Typography>
                  </div>
                </Grid>
              </div>
              <Grid container spacing={2}>
                <Grid item className={classes.sideMenu}>
                  <Typography className={classes.editProfileText}>
                    Editar Perfil
                  </Typography>
                  <Typography
                    onClick={() => setSelectedItem(0)}
                    className={clsx(classes.menuItemsText, {
                      [classes.menuItemSelected]: selectedItem === 0
                    })}
                  >
                    Datos de la cuenta
                  </Typography>
                  <Typography
                    onClick={() => setSelectedItem(1)}
                    className={clsx(classes.menuItemsText, {
                      [classes.menuItemSelected]: selectedItem === 1
                    })}
                  >
                    Cuentas vinculadas
                  </Typography>
                  <Typography
                    onClick={() => setSelectedItem(2)}
                    className={clsx(classes.menuItemsText, {
                      [classes.menuItemSelected]: selectedItem === 2
                    })}
                  >
                    Privacidad y seguridad
                  </Typography>
                </Grid>
                <Grid item className={classes.formContainer}>
                  {selectContent(selectedItem)}
                </Grid>
              </Grid>
            </Fragment>
          ) : (
            <Fragment>
              <div
                id="card-header-content"
                style={{ backgroundImage: `url(${HeaderBanner || ''})` }}
                className={classes.cardHeader}
              >
                <Grid container className={classes.bannerContent}>
                  <div className={classes.headerTextContainer}>
                    <Typography className={classes.myProfileText}>
                      Mi perfil
                    </Typography>
                    <Typography className={classes.myProfileTextInfo}>
                      Configura y verifica tus datos <br /> de contacto.
                    </Typography>
                  </div>
                </Grid>
              </div>
              <Grid item sm={12} className={classes.inputFieldContainer}>
                <SelectInput
                  value={selectedItem}
                  onChange={e => setSelectedItem(e.target.value)}
                  fullWidth
                  InputProps={{
                    classes: {
                      input: classes.selectText
                    }
                  }}
                  options={SELECT_OPTIONS}
                />
              </Grid>
              <Grid item className={classes.formContainer}>
                {selectContent(selectedItem)}
              </Grid>
            </Fragment>
          )}
        </Grid>
      </Container>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(6)
  },
  mainContainer: {
    maxWidth: 750,
    paddingBottom: theme.spacing(6)
  },
  myProfileText: {
    fontSize: 20,
    fontWeight: 500
  },
  myProfileTextInfo: {
    fontSize: 16
  },
  content: {
    overflowY: 'scroll',
    height: '100%',
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      overflow: 'visible'
    }
  },
  sideMenu: {
    display: 'flex',
    flexDirection: 'column',
    width: '32%'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '65%'
    }
  },
  editProfileText: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: theme.spacing(3),
    color: '#333333'
  },
  menuItemsText: {
    fontSize: 14,
    marginBottom: theme.spacing(1),
    color: '#333333',
    cursor: 'pointer',
    '&:hover': {
      color: '#104EB2'
    }
  },
  menuItemSelected: {
    color: '#104EB2',
    fontWeight: 700
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.custom.borderRadius,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.1)'
  },
  cardHeader: {
    display: 'flex',
    paddingTop: theme.spacing(0),
    color: 'white',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    height: 110,
    borderRadius: 5,
    width: '100%',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(6)
    }
  },
  bannerContent: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'left',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5)
    }
  },
  headerIcon: {
    flexShrink: 0,
    width: 50,
    height: 50,
    marginRight: theme.spacing(3)
  },
  inputFieldContainer: {
    width: '100%',
    marginBottom: theme.spacing(3)
  },
  selectText: {
    fontSize: 14,
    fontWeight: 700
  }
}));

export default withWidth()(Profile);
