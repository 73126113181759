import React from 'react';
import { portal, Portal } from '../../../../Configs/general';

import ValidationStepGDC from './ValidationStep.GDC';
import ValidationStepGDG from './ValidationStep.GDG';

const ValidationStep = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <ValidationStepGDC {...props} />;
    case Portal.Gasguajira:
      return <ValidationStepGDG {...props} />;
    case Portal.Efigas:
      return <ValidationStepGDC {...props} />;
    default:
      return <></>;
  }
};

export default ValidationStep;
