import React from 'react';
import { portal, Portal } from '../../../../Configs/general';

import DeferredDebtRefinancingGDC from './DeferredDebtRefinancing.GDC';

const DeferredDebtRefinancing = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <DeferredDebtRefinancingGDC {...props} />;
    default:
      return <></>;
  }
};

export default DeferredDebtRefinancing;
