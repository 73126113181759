import React from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import { makeStyles } from '@material-ui/core/styles';
import DuplicateIcon from '../../../Components/CustomIcons/DuplicateIcon';
import RevisionIcon from '../../../Components/CustomIcons/RevisionIcon';
import OwnershipChangeIcon from '../../../Components/CustomIcons/OwnershipChangeIcon';
import BrillaVisitIcon from '../../../Components/CustomIcons/BrillaVisitIcon';
import DeferredIcon from '../../../Components/CustomIcons/DeferredIcon';
import DigitalInvoiceDisableIcon from '../../../Components/CustomIcons/DigitalInvoiceDisableIcon';
import DigitalInvoiceActivationIcon from '../../../Components/CustomIcons/DigitalInvoiceActivationIcon';
import DebtCertificateIcon from '../../../Components/CustomIcons/DebtCertificateIcon';
import { requestTypes } from './enums';
import PartialPaymentIcon from '../../../Components/CustomIcons/PartialPaymentIcon';
import SubrogationIcon from '../../../Components/CustomIcons/SubrogationIcon';
import InsuranceIcon from '../../../Components/CustomIcons/InsuranceIcon';

const RequestIcon = ({ type, className }) => {
  const classes = useStyles();
  const theme = useTheme();

  const iconsConfig = {
    className
  };
  const renderIcon = () => {
    switch (type) {
      case requestTypes.brillaVisit:
        return <BrillaVisitIcon {...iconsConfig} />;
      case requestTypes.couponDuplicate:
        return <DuplicateIcon {...iconsConfig} />;
      case requestTypes.ownershipChange:
        return (
          <OwnershipChangeIcon
            {...iconsConfig}
            checkFill={theme.palette.background.cardDark}
          />
        );
      case requestTypes.dischargeDeferred:
        return (
          <DeferredIcon
            {...iconsConfig}
            clockFill={theme.palette.background.cardDark}
          />
        );
      case requestTypes.periodicRevision:
        return (
          <RevisionIcon
            {...iconsConfig}
            checkFill={theme.palette.background.cardDark}
          />
        );
      case requestTypes.scheduledRevision:
        return (
          <RevisionIcon
            {...iconsConfig}
            checkFill={theme.palette.background.cardDark}
          />
        );
      case requestTypes.debtNegotiation:
      case requestTypes.changeConditions:
        return (
          <DeferredIcon
            {...iconsConfig}
            clockFill={theme.palette.background.cardDark}
          />
        );
      case requestTypes.debtCertificate:
        return (
          <DebtCertificateIcon
            {...iconsConfig}
            clockFill={theme.palette.background.cardDark}
          />
        );
      case requestTypes.partialPayment:
      case requestTypes.deferredPartialPayment:
        return <PartialPaymentIcon {...iconsConfig} />;
      case requestTypes.digitalInvoiceDisable:
        return (
          <DigitalInvoiceDisableIcon
            {...iconsConfig}
            checkFill={theme.palette.background.cardDark}
          />
        );
      case requestTypes.digitalInvoiceActivation:
        return (
          <DigitalInvoiceActivationIcon
            {...iconsConfig}
            checkFill={theme.palette.background.cardDark}
          />
        );
      case requestTypes.subrogation:
        return (
          <SubrogationIcon
            {...iconsConfig}
            checkFill={theme.palette.background.cardDark}
          />
        );
      case requestTypes.brillaInsurance:
        return (
          <InsuranceIcon
            {...iconsConfig}
            checkFill={theme.palette.background.cardDark}
          />
        );
      case requestTypes.deferredWithCurrent:
        return (
          <DeferredIcon
            {...iconsConfig}
            clockFill={theme.palette.background.cardDark}
          />
        );
      default:
        return;
    }
  };
  return <div className={classes.typeIcon}>{renderIcon()}</div>;
};

const useStyles = makeStyles(theme => ({
  typeIcon: {
    marginRight: theme.spacing(1.5),
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export default RequestIcon;
