/* eslint-disable complexity */
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Title from '../../../Components/Labels/Title';
import {
  FormControlLabel,
  Grow,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  useMediaQuery
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { moneyWithDots } from '../../../Utils/Format/MoneyFormat';
import {
  MINIMUM_PARTIAL_PAYMENT_AMOUNT,
  PAYMENT_VALUE_OPTION,
  TYPE_OF_PAYMENTS,
  getCouponValue
} from '../payment_enums';
import * as yup from 'yup';
import NumberFormat from 'react-number-format';
import { Check } from '@material-ui/icons';
import clsx from 'clsx';
import SweetAlert from '../../../Components/Alerts/SweetAlert';
import {
  getProductPaymentRatio,
  getProductTypeName
} from '../../Debts/myDebts_enums';
import { joinNames } from '../../../Utils/Format/Names';

const SCHEMA_DEFAULT_VALUES = { partialValue: '' };

const getSchema = maximumAmount => {
  return yup.object({
    partialValue: yup
      .number('El valor no es válido')
      .test(
        'min',
        `Mínimo ${moneyWithDots(MINIMUM_PARTIAL_PAYMENT_AMOUNT)}`,
        val => {
          return !isNaN(val) && val >= MINIMUM_PARTIAL_PAYMENT_AMOUNT;
        }
      )
      .test('max', 'El valor excede el máximo', val => {
        return !isNaN(val) && val <= maximumAmount;
      })
  });
};

const isValidPartialValue = (partialValue, maximumAmount) => {
  return (
    !isNaN(partialValue) &&
    partialValue >= MINIMUM_PARTIAL_PAYMENT_AMOUNT &&
    partialValue <= maximumAmount
  );
};

const getRemainingValues = (partialValue, maximumAmount) => {
  let remainingValueText = '';

  if (isValidPartialValue(partialValue, maximumAmount)) {
    const remainingValue = maximumAmount - partialValue;
    remainingValueText = `Saldo restante ${moneyWithDots(remainingValue)}`;
  }

  return remainingValueText;
};

const getAlertDetailMessage = (total, products, partialPayment) => {
  if (products.length === 1) {
    const [product] = products;
    const productTypeName = getProductTypeName(product.productTypeId);
    const proportionalPayment = moneyWithDots(partialPayment);
    const productValueMessage = `${productTypeName} en ${proportionalPayment}`;

    return `El valor se abonará a la deuda de ${productValueMessage}.`;
  }

  const productsDetails = getProductPaymentRatio(
    total,
    products,
    partialPayment
  );

  const productValueMessages = productsDetails.map(product => {
    const productTypeName = getProductTypeName(product.productTypeId);
    const proportionalPayment = moneyWithDots(product.proportionalValue);

    return `${productTypeName} en ${proportionalPayment}`;
  });

  const joinedMessages = joinNames(productValueMessages, ', ', ' y ');

  return `El valor a abonar se distribuirá a la deuda de ${joinedMessages}.`;
};

const PartialPaymentOptions = props => {
  const {
    payment,
    setPayment,
    setMethodSelected,
    paymentValueOption,
    setPaymentValueOption
  } = props;

  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const [disabledCheckButton, setDisabledCheckButton] = useState(true);
  const sweetAlertRef = useRef();

  const { canBePaidPartially, debtData = {}, type } = payment;
  const { currentDebtData, totalCurrentDebtValue } = debtData;

  const isInvoicePayment = type === TYPE_OF_PAYMENTS.contract;
  const couponValue = getCouponValue(payment);

  const partiallyPayable = canBePaidPartially && !isNaN(totalCurrentDebtValue);
  const isCurrentDebtValueEqualToCouponValue =
    !isNaN(totalCurrentDebtValue) && couponValue === totalCurrentDebtValue;

  let showTotalValue = true;
  let showCurrentDebtOption = false;
  let showPartialOption = false;

  if (isInvoicePayment) {
    showCurrentDebtOption =
      partiallyPayable && !isCurrentDebtValueEqualToCouponValue;
    showTotalValue = !showCurrentDebtOption;
    showPartialOption = partiallyPayable;
  } else {
    showPartialOption =
      partiallyPayable && couponValue <= totalCurrentDebtValue;
  }

  const showInput =
    showPartialOption && paymentValueOption === PAYMENT_VALUE_OPTION.Partial;

  const {
    control,
    watch,
    errors,
    handleSubmit,
    formState: { isValid }
  } = useForm({
    validationSchema: getSchema(totalCurrentDebtValue),
    defaultValues: SCHEMA_DEFAULT_VALUES,
    mode: 'onChange'
  });

  const { partialValue } = watch(['partialValue']);

  const showSweetAlert = showInput && isValid && partialValue;

  let sweetAlertMessage = '';
  if (showSweetAlert) {
    sweetAlertMessage = getAlertDetailMessage(
      totalCurrentDebtValue,
      currentDebtData,
      partialValue
    );
  }

  useEffect(() => {
    if (sweetAlertRef.current && showSweetAlert) {
      sweetAlertRef.current.scrollIntoView();
    }
  }, [showSweetAlert]);

  // Partial payment option
  useEffect(() => {
    if (!canBePaidPartially) {
      return;
    }

    if (paymentValueOption !== PAYMENT_VALUE_OPTION.Partial) {
      return;
    }

    if (!isValid) {
      setMethodSelected('');
      return;
    }

    if (isValid && !payment.partialData) {
      setDisabledCheckButton(false);
      return;
    }

    if (isValid && payment.partialData) {
      const { partialPayment } = payment.partialData;
      if (partialPayment !== partialValue) {
        setDisabledCheckButton(false);
        setMethodSelected('');
        setPayment([{ ...payment, partialData: null }]);
      }
    }
  }, [
    paymentValueOption,
    setMethodSelected,
    isValid,
    partialValue,
    payment.partialData,
    payment,
    setPayment,
    canBePaidPartially
  ]);

  // Current debt payment option
  useEffect(() => {
    if (!canBePaidPartially) {
      return;
    }

    if (paymentValueOption !== PAYMENT_VALUE_OPTION.CurrentDebt) {
      return;
    }

    const isThereDataOfOtherOption =
      payment.partialData &&
      payment.partialData.paymentValueOption !==
        PAYMENT_VALUE_OPTION.CurrentDebt;

    if (
      (!isCurrentDebtValueEqualToCouponValue && !payment.partialData) ||
      (!isCurrentDebtValueEqualToCouponValue && isThereDataOfOtherOption)
    ) {
      const products = getProductPaymentRatio(
        totalCurrentDebtValue,
        currentDebtData,
        totalCurrentDebtValue
      );

      const body = {
        totalCurrentDebtValue,
        partialPayment: totalCurrentDebtValue,
        productsDetails: products
      };

      setPayment([
        {
          ...payment,
          partialData: {
            paymentValueOption,
            ...body
          }
        }
      ]);
    }

    if (
      (isCurrentDebtValueEqualToCouponValue && !payment.partialData) ||
      (isCurrentDebtValueEqualToCouponValue && isThereDataOfOtherOption)
    ) {
      setPayment([
        {
          ...payment,
          partialData: {
            paymentValueOption,
            couponId: payment.detail.couponId
          }
        }
      ]);
      return;
    }
  }, [
    couponValue,
    currentDebtData,
    partialValue,
    payment,
    paymentValueOption,
    setPayment,
    totalCurrentDebtValue,
    isCurrentDebtValueEqualToCouponValue,
    canBePaidPartially
  ]);

  const onSubmit = async () => {
    setDisabledCheckButton(true);

    if (partialValue === couponValue) {
      setPayment([
        {
          ...payment,
          partialData: {
            paymentValueOption,
            couponId: payment.detail.couponId,
            partialPayment: couponValue
          }
        }
      ]);
      return;
    }

    const products = getProductPaymentRatio(
      totalCurrentDebtValue,
      currentDebtData,
      partialValue
    );

    const body = {
      totalCurrentDebtValue,
      partialPayment: partialValue,
      productsDetails: products
    };

    setPayment([
      {
        ...payment,
        partialData: {
          paymentValueOption,
          ...body
        }
      }
    ]);
  };

  const handlePaymentOptionChange = e => {
    setMethodSelected('');
    if (payment.canBePaidPartially) {
      setPayment([{ ...payment, partialData: null }]);
    }
    setPaymentValueOption(e.target.value);
  };

  const renderRadioGroup = () => {
    return (
      <RadioGroup
        row={!isSmallScreen}
        className={classes.radioGroup}
        onChange={handlePaymentOptionChange}
        value={paymentValueOption}
        defaultValue={paymentValueOption}
      >
        {showTotalValue && (
          <FormControlLabel
            value={PAYMENT_VALUE_OPTION.TotalCoupon}
            control={<Radio color="primary" />}
            label={
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    className={clsx(
                      classes.mainLabelText,
                      classes.labelWithTooltip
                    )}
                  >
                    {payment.type === TYPE_OF_PAYMENTS.contract
                      ? 'Total factura'
                      : 'Total cupón'}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.totalAmountText}>
                    {moneyWithDots(couponValue)}
                  </Typography>
                </Grid>
              </Grid>
            }
          />
        )}
        {showCurrentDebtOption && (
          <FormControlLabel
            value={PAYMENT_VALUE_OPTION.CurrentDebt}
            control={<Radio color="primary" />}
            label={
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    className={clsx(
                      classes.mainLabelText,
                      classes.labelWithTooltip
                    )}
                  >
                    Deuda actual
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.totalAmountText}>
                    {moneyWithDots(totalCurrentDebtValue)}
                  </Typography>
                </Grid>
              </Grid>
            }
          />
        )}
        {showPartialOption && (
          <FormControlLabel
            value={PAYMENT_VALUE_OPTION.Partial}
            control={<Radio color="primary" />}
            label={
              <Grid container direction="column">
                <Typography className={classes.mainLabelText}>
                  Otro valor
                </Typography>
                <Typography className={classes.minimumAmountText}>
                  {isSmallScreen ? 'Mínimo' : 'Monto mínimo'}{' '}
                  {moneyWithDots(MINIMUM_PARTIAL_PAYMENT_AMOUNT)}
                </Typography>
              </Grid>
            }
          />
        )}
      </RadioGroup>
    );
  };

  return (
    <div
      className={clsx({
        [classes.paddingBottom]:
          paymentValueOption === PAYMENT_VALUE_OPTION.Partial &&
          isSmallScreen &&
          !Boolean(payment.partialData)
      })}
    >
      <Title text={'Selecciona el monto a pagar'} className={classes.title} />
      {showPartialOption && isInvoicePayment && (
        <Typography paragraph>
          Si has realizado un abono anteriormente, el valor de tu deuda actual
          puede ser diferente al valor de la factura. En caso de realizar un
          pago por un valor distinto al de la factura, se generará un cupón con
          el nuevo valor.
        </Typography>
      )}
      <form
        id={'partial_payment_options_form'}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container justify="space-between" className={classes.container}>
          <Grid item xs={12} sm={6}>
            {renderRadioGroup()}
          </Grid>

          <Grid item>
            {showInput && (
              <Grow in>
                <Grid
                  container
                  className={clsx(classes.rootInputValueContainer)}
                >
                  <Grid item className={classes.inputContainer}>
                    <Controller
                      as={
                        <NumberFormat
                          FormHelperTextProps={{
                            classes: { root: classes.inputValueHelperText }
                          }}
                          customInput={TextField}
                          type="tel"
                          prefix="$ "
                          thousandSeparator="."
                          allowNegative={false}
                          decimalSeparator={false}
                          autoFocus
                          fullWidth
                          isAllowed={({ floatValue }) =>
                            floatValue <= 999999999 || !floatValue
                          }
                          label={'Monto'}
                          variant="outlined"
                          onBlur={() => {}}
                          error={errors && Boolean(errors.partialValue)}
                          helperText={
                            errors && errors.partialValue
                              ? errors.partialValue.message
                              : getRemainingValues(
                                  partialValue,
                                  totalCurrentDebtValue
                                )
                          }
                          defaultValue={0}
                        />
                      }
                      onChangeName="onValueChange"
                      onChange={values => {
                        if (values[0]) {
                          return values[0].floatValue;
                        }
                        return 0;
                      }}
                      control={control}
                      name={'partialValue'}
                    />
                  </Grid>
                  <Grid item className={classes.buttonContainer}>
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={onSubmit}
                      className={clsx(classes.checkButton, {
                        [classes.checkButtonDisabled]:
                          !isValid || disabledCheckButton
                      })}
                      disabled={!isValid || disabledCheckButton}
                    >
                      <Check />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grow>
            )}
          </Grid>
        </Grid>
      </form>
      {showSweetAlert && (
        <Grow in>
          <div className={classes.sweetAlert} ref={sweetAlertRef}>
            <SweetAlert
              id="Payments_partial_payment_sweet_alert"
              noIcon={isSmallScreen}
              message={
                <>
                  {sweetAlertMessage}
                  <br />
                  Realizar abonos parciales no elimina la causal de suspensión
                  de su servicio de gas, ni el cobro de intereses de mora, en
                  caso de que no se complete el pago de los saldos pendientes
                  antes de la generación de su siguiente factura.
                </>
              }
            />
          </div>
        </Grow>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  radioGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    '& > *': {
      marginRight: theme.spacing(2.5)
    },
    '& > *:last-child': {
      marginRight: 0
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        marginBottom: theme.spacing(1)
      }
    }
  },
  minimumAmountText: {
    fontSize: 10,
    fontWeight: 600,
    color: theme.typography.color.grey
  },
  totalAmountText: {
    fontSize: 14,
    fontWeight: 700
  },
  mainLabelText: {
    fontSize: 14
  },
  labelWithTooltip: {
    display: 'flex'
  },
  tooltip: {
    marginLeft: theme.spacing(0.5)
  },
  container: {
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      flexDirection: 'column'
    }
  },
  inputContainer: {
    flexGrow: 1,
    paddingRight: theme.spacing(2),
    minWidth: 300,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      minWidth: 'auto',
      flexGrow: 2,
      maxWidth: 467
    }
  },
  checkButton: {
    height: 52,
    width: 52,
    border: '2px solid #104EB2',
    borderRadius: theme.custom.borderRadius
  },
  checkButtonDisabled: {
    borderColor: 'rgba(0,0,0,0.26)',
    backgroundColor: 'transparent'
  },
  inputValueHelperText: {
    fontSize: 11,
    textAlign: 'right',
    marginRight: 0
  },
  sweetAlert: {
    marginTop: theme.spacing(2)
  },
  paddingBottom: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingBottom: theme.spacing(10)
    }
  },
  rootInputValueContainer: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      justifyContent: 'flex-start',
      marginTop: theme.spacing(1)
    }
  },
  buttonContainer: {
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      flexGrow: 1
    }
  },
  title: {
    fontSize: 14,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 16
    }
  }
}));

export default PartialPaymentOptions;
