/* eslint-disable complexity */
import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useContext
} from 'react';
import _get from 'lodash/get';
import {
  Container,
  Checkbox,
  FormControlLabel,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckFilledIcon from '@material-ui/icons/CheckCircleRounded';
import PendingIcon from '@material-ui/icons/WatchLaterRounded';
import CanceledIcon from '@material-ui/icons/RemoveCircleRounded';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import {
  UserContext,
  UserDispatchContext
} from '../../../../Contexts/UserContext';
import { ContractsContext } from '../../../../Contexts/ContractsContext';
import { AlertsDispatchContext } from '../../../../Contexts/AlertsContext';
import {
  GoogleClientContext,
  GoogleClientDispatchContext
} from '../../../../Contexts/GoogleClientContext';

import { history } from '../../../../Routes/history';
import Title from '../../../../Components/Labels/Title';
import TransactionCard from '../../../../Components/Cards/TransactionCard/TransactionCard';
import TransactionErrors from '../TransactionErrors';
import TransactionActions from '../TransactionAction/TransactionActions';
import { TransactionStatusAPI } from '../../../../API/Payments/TransactionStatusAPI';
import { GetSingleContractAPI } from '../../../../API/Contracts/ContractsAPI';
import { GoogleSignUpAPI } from '../../../../API/UserAPI';
import { GoogleLoginAPI } from '../../../../API/SocialUserAPI';
import FullSizeProgress from '../../../../Components/Progress/FullSize/FullSizeProgress';
import {
  TRANSACTION_STATUS,
  TRANSACTION_TYPES,
  LinkToDonationPDF
} from '../../payment_enums';
import Stepper from '../../../../Components/Stepper/Stepper';
import FooterPayment from '../../FooterPayment';
import SweetAlert from '../../../../Components/Alerts/SweetAlert';
import CallToActionDialog from '../../../../Components/Dialogs/CallToActionDialog';
import PayBillsIcon from '../../../../Components/CustomIcons/PayBillsIcon';
import ContractAssociationIcon from '../../../../Components/CustomIcons/ContractAssociationIcon';
import ContractAssociationDialog from '../../../Contracts/ContractAssociationDialog/ContractAssociationDialog';
import ConfirmationDialog from '../../../../Components/Dialogs/ConfirmationDialog';
import TransactionModalFooter from './TransactionModalFooter';
import LoginDialog from '../../../Login/LoginDialog';

import { extractErrorMessage } from '../../../../Utils/Errors/Errors';
import {
  TERMS,
  TERMS_ROUTES
} from '../../../Information/Information/Information';
import { ROUTE_NAMES } from '../../../../Routes/Routes';
import { PrivacyPoliciesLink } from '../../../../Configs/Links';
import { Company } from '../../../../Configs/general';
import { updateUser } from '../../../../Utils/User/Actions';

const TRANSACTION_REFRESH_TIMEOUT = 15000;

const TransactionEFG = props => {
  const { transactionId } = props.match.params;
  const {
    state = { from: null, price: null, insuranceRequestId: null }
  } = props.location;

  const { price, insuranceRequestId } = state;

  const steps = [
    {
      label: 'Referencia de pago'
    },
    {
      label: 'Método de pago'
    },
    {
      label: 'Transacción',
      action:
        state && state.from && state.from.includes(ROUTE_NAMES.transactions)
          ? {
              text: 'Volver',
              onCall: () => history.go(-1)
            }
          : { text: 'Finalizar', onCall: () => history.push('/') }
    }
  ];

  const donationSteps = [
    {
      label: 'Comparto mi energía'
    },
    {
      label: 'Detalle de pago'
    },
    {
      label: 'Transacción',
      action:
        state && state.from === ROUTE_NAMES.transactions
          ? { text: 'Volver', onCall: () => history.push(state.from) }
          : { text: 'Finalizar', onCall: () => history.push('/') }
    }
  ];

  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const contracts = useContext(ContractsContext);
  const setAlert = useContext(AlertsDispatchContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const googleData = useContext(GoogleClientContext);
  const setGoogleData = useContext(GoogleClientDispatchContext);

  const authToken = _get(currentUser, 'token');

  // * STATE HOOKS;
  const [loading, setLoading] = useState(true);
  const [contractLoading, setContractLoading] = useState(false);
  const [terms, setTerms] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [timer, setTimer] = useState(null);
  const [registerDialogOpen, setRegisterDialogOpen] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [newUserParams, setNewUserParams] = useState({});
  const [associationSuggestionOpen, setAssociationSuggestionOpen] = useState(
    false
  );
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [associationDialogOpen, setAssociationDialogOpen] = useState(false);
  const [contract, setContract] = useState(null);

  // * OTHER HOOKS
  const classes = useStyles();

  // * FUNCTIONS
  const isAssociated = useCallback(
    contractId => {
      return contracts.some(c => c.id === contractId);
    },
    [contracts]
  );

  const onChangeTerms = useCallback(() => {
    setTerms(!terms);
  }, [terms]);

  const fetchContract = useCallback(
    async contractId => {
      setContractLoading(true);
      const response = await GetSingleContractAPI(contractId, authToken);
      setContractLoading(false);
      if (response.success) {
        setContract(response.data.data);
        setAssociationDialogOpen(true);
      } else {
        setAssociationDialogOpen(false);
      }
    },
    [authToken]
  );
  const fetchTransaction = useCallback(async () => {
    setLoading(true);
    const response = await TransactionStatusAPI(transactionId);
    setLoading(false);
    if (response.success) {
      const transactionData = response.data.data;
      setTransaction(transactionData);

      if (!authToken && transactionData) {
        setRegisterDialogOpen(true);
      } else {
        setRegisterDialogOpen(false);
      }

      if (
        authToken &&
        transactionData &&
        transactionData.contractId &&
        !isAssociated(transactionData.contractId)
      ) {
        setAssociationSuggestionOpen(true);
      } else {
        setAssociationSuggestionOpen(false);
        setAssociationDialogOpen(false);
      }
    } else {
      history.replace('/');
    }
  }, [transactionId, authToken, isAssociated]);

  const handleGoogleLogin = useCallback(
    async data => {
      const { email, givenName, familyName, encodedToken } = data;

      setGoogleLoading(true);

      const authResponse = await GoogleLoginAPI(encodedToken);

      if (!authResponse.success) {
        setAlert({
          type: 'error',
          message: extractErrorMessage(authResponse).message
        });
        setGoogleData(null);
        setGoogleLoading(false);
        return;
      }

      const responseData = authResponse.data.data;
      const userType = responseData.userType;

      if (userType === 'new') {
        const params = {
          firstName: givenName.trim(),
          lastName: familyName.trim(),
          email: email.trim(),
          idToken: encodedToken,
          contractId: transaction ? transaction.contractId : null
        };

        setConfirmationDialog(true);
        setNewUserParams({ google: params });
        setGoogleData(null);
        return;
      }

      const { token, appToken } = responseData;

      if (userType === 'google') {
        setGoogleData(null);
        updateUser(setCurrentUser, token, appToken);
      }
    },
    [setAlert, setCurrentUser, setGoogleData, transaction]
  );

  const handleTermsDialog = useCallback(
    async closed => {
      if (closed) {
        setAlert({
          type: 'info',
          message:
            'Se ha cerrado la ventana de términos y condiciones. Intenta nuevamente'
        });
        setLoading(false);
        setTerms(false);
        return;
      }

      let signUpResponse;

      if (newUserParams.google) {
        signUpResponse = await GoogleSignUpAPI(newUserParams.google);
      }

      if (!signUpResponse.success) {
        setAlert({
          type: 'error',
          message: extractErrorMessage(signUpResponse).message
        });
        setLoading(false);
        return;
      }

      const { token, appToken } = signUpResponse.data.data;

      updateUser(setCurrentUser, token, appToken);

      return { unmounting: true, closeDialog: false };
    },
    [newUserParams, setAlert, setCurrentUser]
  );

  const goUserAuth = useCallback(() => {
    if (transaction.registeredUser) {
      setOpenLoginDialog(true);
      return;
    }

    history.push(`/completar-registro/${transactionId}`);
  }, [transaction, transactionId]);

  useEffect(() => {
    if (googleData) {
      handleGoogleLogin(googleData);
    }
  }, [googleData, handleGoogleLogin]);

  useEffect(() => {
    fetchTransaction();
  }, [transactionId, fetchTransaction]);

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  useEffect(() => {
    if (!transaction) {
      return;
    }

    if (transaction.status !== TRANSACTION_STATUS.Pending) {
      return;
    }

    const timeout = setTimeout(() => {
      fetchTransaction();
    }, TRANSACTION_REFRESH_TIMEOUT);

    setTimer(timeout);
  }, [transaction, fetchTransaction]);

  // * FUNCTIONS
  const renderStatus = () => {
    if (transaction.status === TRANSACTION_STATUS.Pending) {
      return (
        <Fragment>
          <PendingIcon
            className={`${classes.iconBase} ${classes.iconWarning}`}
          />
          <Title text={'Transacción pendiente'} />
          <Typography className={classes.helpText}>
            Esta página se actualizará automáticamente
          </Typography>
        </Fragment>
      );
    }
    if (transaction.status === TRANSACTION_STATUS.Approved) {
      return (
        <Fragment>
          <CheckFilledIcon
            className={`${classes.iconBase} ${classes.iconSuccess}`}
          />
          <Title text={'Transacción exitosa'} />
        </Fragment>
      );
    }
    if (transaction.status === TRANSACTION_STATUS.Rejected) {
      return (
        <Fragment>
          <CanceledIcon
            className={`${classes.iconBase} ${classes.iconError}`}
          />
          <Title text={'Transacción rechazada'} />
        </Fragment>
      );
    }
  };

  return (
    <Fragment>
      <div className={classes.root}>
        {transaction && (
          <Container maxWidth="md" className={classes.stepperContainer}>
            <Stepper
              steps={
                transaction.type === TRANSACTION_TYPES.DonationCOVID19
                  ? donationSteps
                  : steps
              }
              currentStep={2}
            />
          </Container>
        )}
        {transaction && (
          <Container
            maxWidth="md"
            className={classes.innerRoot}
            id="transaction_to_download"
          >
            <Fragment>
              <div className={classes.titleContainer}>{renderStatus()}</div>
              {transaction.status === TRANSACTION_STATUS.Approved &&
                transaction.donationValue > 0 && (
                  <SweetAlert
                    id="Transaction_div_donation_success"
                    type="success"
                    noIcon
                    classes={{
                      root: classes.donationAlert
                    }}
                    message={
                      <Fragment>
                        <Typography className={classes.donationMessage}>
                          <span className={classes.donationTitle}>
                            ¡Gracias!
                          </span>{' '}
                          Con tu aporte voluntario “Comparto mi energía”,
                          usuarios de los servicios de energía y gas natural,
                          recibirán alivios para el pago de sus facturas, según
                          lo establecido en el{' '}
                          <span
                            className={classes.donationLink}
                            onClick={LinkToDonationPDF}
                          >
                            Decreto 517 de 2020
                          </span>{' '}
                          del Ministerio de Minas y Energía.
                        </Typography>
                      </Fragment>
                    }
                    onClick={() => null}
                  />
                )}
              <TransactionCard
                transaction={transaction}
                insuranceRequestId={insuranceRequestId}
                insurancePrice={price}
              />
              <TransactionActions
                transaction={transaction}
                transactionId={transactionId}
              />
              {transaction.status !== TRANSACTION_STATUS.Approved && (
                <TransactionErrors transaction={transaction} />
              )}
            </Fragment>
          </Container>
        )}
        <FooterPayment currentStep={steps[2]} disabledNext={false} />
      </div>
      {confirmationDialog && (
        <ConfirmationDialog
          open={confirmationDialog}
          title={'Términos y condiciones'}
          content={
            <FormControlLabel
              control={
                <Checkbox
                  id="Login_dialog_terms"
                  checked={terms}
                  onClick={onChangeTerms}
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  disabled={Boolean(currentUser)}
                  value="checkedI"
                />
              }
              label={
                <Typography className={classes.termsText}>
                  He leído y acepto los{' '}
                  <a
                    className={classes.link}
                    href={`${ROUTE_NAMES.information}/${
                      TERMS_ROUTES[TERMS.introduction]
                    }`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    términos y condiciones
                  </a>{' '}
                  de uso, la{' '}
                  <a href={PrivacyPoliciesLink}>
                    política y aviso de privacidad
                  </a>{' '}
                  y autorizo el tratamiento de mis datos personales.
                </Typography>
              }
            />
          }
          cancelButtonText="Cancelar"
          submitButtonText="Continuar"
          disabled={!terms}
          setDialog={setConfirmationDialog}
          requestCallback={handleTermsDialog}
        />
      )}
      {registerDialogOpen && (
        <CallToActionDialog
          open={registerDialogOpen}
          icon={PayBillsIcon}
          title={
            <>
              ¡Paga tus facturas en
              <br />
              menos tiempo!
            </>
          }
          message="Accede a nuestro portal y no tendrás que volver a ingresar tus datos."
          handleClose={() => {
            setRegisterDialogOpen(false);
          }}
          footer={
            <TransactionModalFooter
              onClick={goUserAuth}
              loading={googleLoading}
            />
          }
        />
      )}
      {openLoginDialog && (
        <LoginDialog
          open={openLoginDialog}
          setOpen={setOpenLoginDialog}
          hideSocialButtons={true}
        />
      )}

      {associationSuggestionOpen && !associationDialogOpen && transaction && (
        <CallToActionDialog
          open={associationSuggestionOpen}
          icon={ContractAssociationIcon}
          title={`¡Asocia ${Company.contractConjugation.regular.singular.demonstrative} y gestiónalo fácilmente!`}
          message={`Asocia ${Company.contractConjugation.regular.singular.demonstrative} a tu cuenta y así ahorrarás tiempo en tus próximas transacciones`}
          confirmButtonText={`Asociar ${Company.contractConjugation.regular.singular.main}`}
          handleConfirmClick={() => {
            setAssociationSuggestionOpen(false);
            fetchContract(transaction.contractId);
          }}
          handleClose={() => {
            setAssociationSuggestionOpen(false);
          }}
        />
      )}

      {associationDialogOpen && (
        <ContractAssociationDialog
          open={associationDialogOpen}
          handleClose={() => {
            setAssociationDialogOpen(false);
          }}
          contract={contract}
          setAlert={setAlert}
        />
      )}
      {(loading || contractLoading) &&
        !registerDialogOpen &&
        !associationSuggestionOpen &&
        !associationDialogOpen && <FullSizeProgress />}
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    marginBottom: theme.custom.footerHeight.stepper
  },
  innerRoot: {
    justifyContent: 'space-around',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginBottom: theme.spacing(11),
    // * Responsive
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing()
    }
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  iconBase: {
    fontSize: 24,
    marginRight: theme.spacing()
  },
  iconSuccess: {
    color: theme.palette.success.main
  },
  iconError: {
    color: theme.palette.error.main
  },
  iconWarning: {
    color: theme.palette.warning.main
  },
  stepperContainer: {
    // * Mobile
    [theme.breakpoints.up('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.up('xm')]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5)
    },
    // * Web
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing()
    }
  },
  helpText: {
    fontSize: 10,
    marginLeft: 10,
    marginTop: 4
  },
  donationAlert: {
    marginBottom: theme.spacing(2)
  },
  donationTitle: {
    fontWeight: 600,
    fontSize: 14
  },
  donationMessage: {
    fontWeight: 500,
    fontSize: 14,
    textAlign: 'justify'
  },
  donationLink: {
    fontWeight: 500,
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.primary.main
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  signUpContainer: {
    marginTop: theme.spacing(3)
  },
  bottomText: {
    marginTop: theme.spacing(1),
    fontSize: 14,
    textAlign: 'center'
  },
  signUpText: {
    fontSize: 14,
    color: theme.palette.primary.dark,
    fontWeight: 500,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}));

export default TransactionEFG;
