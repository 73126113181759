import React, { Fragment } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BaseButton from '../../../Components/Buttons/BaseButton';
import SimpleDivider from '../../../Components/Dividers/SimpleDivider';
import SweetAlert from '../../../Components/Alerts/SweetAlert';
import { floatWithDots } from '../../../Utils/Format/MoneyFormat';
import { OpenNewTab } from '../../../Utils/Misc/Links';
import { BrillaFinancingLink } from '../../../Configs/Links';
import { Company } from '../../../Configs/general';
import ExtraQuotaDetail from './ExtraQuotaDetail';

const QuotaDetailGDG = props => {
  const { extraQuota, quota, contractAlias, contractId, onBrillaVisit } = props;

  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.quotaDetailContainer}>
        <div className={classes.quotaValueContainer}>
          <Typography className={classes.quotaValueTitle}>
            Cupo disponible
          </Typography>
          <Typography className={classes.quotaValue}>
            {floatWithDots(quota)} COP
          </Typography>
        </div>
      </div>
      {Boolean(extraQuota.length) && (
        <ExtraQuotaDetail extraQuota={extraQuota} />
      )}
      <SimpleDivider withoutMargin classes={classes.preReasonsDivider} />
      <Grid container alignItems="center" className={classes.buttonContainer}>
        <Grid
          item
          xs={12}
          alignItems="center"
          className={classes.buttonContainerItem}
        >
          <BaseButton
            fullWidth
            color="primary"
            variant="outlined"
            className={classes.button}
            onClick={onBrillaVisit}
          >
            Comprar con mi cupo
          </BaseButton>
        </Grid>
        <Grid item xs={12} className={classes.buttonContainerItem}>
          <BaseButton
            fullWidth
            color="primary"
            variant="outlined"
            className={classes.button}
            onClick={() => OpenNewTab(BrillaFinancingLink)}
          >
            ¿Dónde financiar?
          </BaseButton>
        </Grid>
      </Grid>
      <SweetAlert
        classes={{
          root: classes.sweetAlert
        }}
        message={`El cupo brilla disponible y que se muestra en pantalla, pertenece a "${contractAlias}" con número de ${Company.contractConjugation.regular.singular.main} ${contractId}.`}
      />
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  preReasonsDivider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  quotaDetailContainer: {
    display: 'flex',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1.5),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start'
    }
  },
  quotaValueContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  quotaValue: {
    fontSize: '1.4em',
    fontWeight: 500
  },
  quotaValueTitle: {
    fontSize: '1em',
    fontWeight: 500,
    color: theme.palette.text.greyDark
  },
  button: {
    alignSelf: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center'
    },
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  sweetAlert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  buttonContainer: {
    gap: `${theme.spacing(2)}px`
  },
  buttonContainerItem: {
    flexGrow: 1,
    flexBasis: 190
  }
}));

export default QuotaDetailGDG;
