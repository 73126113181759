import React from 'react';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorStateIcon from '../CustomIcons/ErrorStateIcon';

const ErrorState = props => {
  const {
    messageTitle,
    subMessage,
    fullPage = false,
    type = 'error',
    className
  } = props;

  const classes = useStyles();

  const containerClass = fullPage
    ? classes.container
    : classes.containerReduced;

  const iconBanner = () => {
    switch (type) {
      default:
        return <ErrorStateIcon className={classes.icon} />;
    }
  };

  return (
    <div className={clsx(containerClass, className)}>
      <div className={classes.content}>
        {iconBanner()}
        {messageTitle && (
          <Typography variant={'h1'} className={classes.message}>
            {messageTitle}
          </Typography>
        )}
        {subMessage && (
          <Typography className={classes.subMessage}>{subMessage}</Typography>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    padding: [[0, theme.spacing(2.5)]],
    width: '100%',
    minHeight: 'calc(100vh - 56px)',
    display: 'flex',
    marginTop: theme.spacing(3),
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vh - 64px)',
      padding: 0,
      marginTop: theme.spacing(17.5)
    }
  },
  containerReduced: {
    padding: [[0, theme.spacing(2.5)]],
    width: '100%',
    display: 'flex',
    marginTop: theme.spacing(0),
    justifyContent: 'center'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  icon: {
    marginBottom: theme.spacing(),
    transform: 'scale(0.8    5)',
    [theme.breakpoints.up('sm')]: {
      transform: 'scale(1)',
      marginBottom: theme.spacing(2)
    }
  },
  message: {
    marginBottom: theme.spacing(1),
    fontSize: '1em',
    textAlign: 'center',
    lineHeight: 1.19,
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(1),
      fontSize: '1.25em'
    }
  },
  subMessage: {
    marginBottom: theme.spacing(3),
    fontSize: '0.85em',
    textAlign: 'center',
    lineHeight: 1.19,
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
      fontSize: '1em'
    }
  }
}));

export default ErrorState;
