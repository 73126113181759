import axios from 'axios';

export const GetVersionAPI = async () => {
  try {
    const date = new Date();
    const time = date.getTime();
    const response = await axios.get(
      `${window.location.origin}/VERSION.txt?t=${time}`
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};
