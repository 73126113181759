import axios from 'axios';
import { config } from '../../Configs';

export const GetPlacesAPI = async (lat, lng) => {
  try {
    const response = await axios.get(`${config.api.url}/places/${lat}/${lng}`, {
      lat,
      lng
    });
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GetPlacesLocationsAPI = async () => {
  try {
    const response = await axios.get(`${config.api.url}/places/locations`);
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};
