import React, { useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import PqrIcon from '../../../../Components/CustomIcons/CustomerService';
import HomeIcon from '../../../../Components/CustomIcons/HomeIcon';
import ContractsIcon from '../../../../Components/CustomIcons/ContractsIcon';
import TransactionsIcon from '../../../../Components/CustomIcons/TransactionsIcon';
import UserIcon from '../../../../Components/CustomIcons/UserIcon';
import LogoutIcon from '../../../../Components/CustomIcons/IcLogout';

import SimpleDivider from '../../../../Components/Dividers/SimpleDivider';

import { UserContext } from '../../../../Contexts/UserContext';

import { firstNameAndLastName } from '../../../../Utils/Format/Names';

import { history } from '../../../../Routes/history';
import { ROUTE_NAMES } from '../../../../Routes/Routes';
import { Company } from '../../../../Configs/general';

const StyledMenuItem = withStyles(theme => ({
  root: {
    minWidth: 180,
    paddingTop: 14,
    paddingBottom: 14,
    paddingLeft: 26,
    paddingRight: 26,
    '&:hover': {
      color: '#3C77CF',
      backgroundColor: 'white !important'
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.lighter
    }
  },
  selected: {
    color: '#3C77CF',
    backgroundColor: `${theme.palette.primary.lighter} !important`,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.lighter} !important`
    },
    '&:focus': {
      backgroundColor: `${theme.palette.primary.lighter} !important`
    }
  }
}))(MenuItem);

const MobileDrawerEFG = ({
  open,
  setDrawer,
  logout,
  setPqrDialogOpen = null
}) => {
  // * CONTEXTS
  const currentUser = useContext(UserContext);

  // * OTHER HOOKS
  const classes = useStyles();

  // * FUNCTIONS

  return (
    <SwipeableDrawer
      anchor="right"
      classes={{ root: classes.root, paper: classes.paperRoot }}
      open={open}
      onClose={() => setDrawer(false)}
      onOpen={() => setDrawer(true)}
    >
      <Grid container className={classes.header}>
        <Grid item>
          <Grid container direction="column">
            <Typography className={classes.headerTitle}>Bienvenido</Typography>
            <Typography className={classes.headerSubtitle}>
              {firstNameAndLastName(
                currentUser,
                'firstName',
                'lastName',
                'capitalize'
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.divider}>
        <SimpleDivider withoutMargin />
      </div>
      <StyledMenuItem
        id={'drawer_button_home'}
        TouchRippleProps={{ classes: { child: classes.rippleStyle } }}
        onClick={() => {
          history.push(ROUTE_NAMES.home);
          setDrawer(false);
        }}
        selected={window.location.pathname === ROUTE_NAMES.home}
      >
        <div className={classes.menuItemSelectIcon}>
          <HomeIcon size={18} className={classes.itemImage} />
        </div>
        <div className={classes.itemTextPrimary}>Inicio</div>
      </StyledMenuItem>
      <StyledMenuItem
        id={'drawer_button_contracts'}
        TouchRippleProps={{ classes: { child: classes.rippleStyle } }}
        onClick={() => {
          history.push(ROUTE_NAMES.contracts);
          setDrawer(false);
        }}
        selected={
          window.location.pathname === ROUTE_NAMES.associate ||
          window.location.pathname === ROUTE_NAMES.contracts
        }
      >
        <div className={classes.menuItemSelectIcon}>
          <ContractsIcon size={18} className={classes.itemImage} />
        </div>
        <div className={classes.itemTextPrimary}>
          Mis {Company.contractConjugation.regular.plural.main}
        </div>
      </StyledMenuItem>
      <StyledMenuItem
        id={'drawer_button_transactions'}
        TouchRippleProps={{ classes: { child: classes.rippleStyle } }}
        onClick={() => {
          history.push(ROUTE_NAMES.transactions);
          setDrawer(false);
        }}
        selected={window.location.pathname === ROUTE_NAMES.transactions}
      >
        <div className={classes.menuItemSelectIcon}>
          <TransactionsIcon size={18} className={classes.itemImage} />
        </div>
        <div className={classes.itemTextPrimary}>Mis transacciones</div>
      </StyledMenuItem>
      <StyledMenuItem
        id={'drawer_button_pqr'}
        TouchRippleProps={{ classes: { child: classes.rippleStyle } }}
        onClick={() => {
          if (setPqrDialogOpen) {
            setPqrDialogOpen(true);
          }

          setDrawer(false);
        }}
        selected={window.location.pathname === ROUTE_NAMES.pqr}
      >
        <div className={classes.menuItemSelectIcon}>
          <PqrIcon size={18} className={classes.itemImage} />
        </div>
        <div className={classes.itemTextPrimary}>PQR</div>
      </StyledMenuItem>
      <StyledMenuItem
        id={'drawer_button_profile'}
        TouchRippleProps={{ classes: { child: classes.rippleStyle } }}
        onClick={() => {
          history.push(ROUTE_NAMES.profile);
          setDrawer(false);
        }}
        selected={window.location.pathname === ROUTE_NAMES.profile}
      >
        <div className={classes.menuItemSelectIcon}>
          <UserIcon size={18} className={classes.itemImage} />
        </div>
        <div className={classes.itemTextPrimary}>Mi perfil</div>
      </StyledMenuItem>
      <StyledMenuItem
        id={'drawer_button_profile'}
        TouchRippleProps={{ classes: { child: classes.rippleStyle } }}
        onClick={logout}
      >
        <div className={classes.menuItemSelectIcon}>
          <LogoutIcon size={18} className={classes.itemImage} />
        </div>
        <div className={classes.itemTextPrimary}>Cerrar sesión</div>
      </StyledMenuItem>
    </SwipeableDrawer>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: '1120 !important'
  },
  paperRoot: {
    top: 64,
    minWidth: 248,
    height: 'calc(100vh - 64px)'
  },
  header: {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 24
  },
  headerTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 1.4
  },
  headerSubtitle: {
    fontSize: 16,
    fontWeight: 400,
    textTransform: 'capitalize'
  },
  points: {
    fontSize: 15,
    fontWeight: 500,
    marginLeft: 9
  },
  divider: {
    margin: [[16, 8, 0]]
  },
  rippleStyle: {
    backgroundColor: theme.palette.primary.main
  },
  itemImage: {
    fill: 'currentColor'
  },
  menuItemSelectIcon: {
    display: 'flex',
    width: 45,
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  itemTextPrimary: {
    fontSize: 14,
    lineHeight: 1.4
  }
}));

export default MobileDrawerEFG;
