import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useCallback
} from 'react';
import clsx from 'clsx';
import { history } from '../../../Routes/history';
import { ROUTE_NAMES } from '../../../Routes/Routes';
import { makeStyles } from '@material-ui/core/styles';
import {
  UserContext,
  UserDispatchContext
} from '../../../Contexts/UserContext';
import {
  ContractsContext,
  ContractSelectedContext
} from '../../../Contexts/ContractsContext';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';
import {
  redirectOnAuthFailure,
  extractErrorMessage
} from '../../../Utils/Errors/Errors';
import { Container, Typography, Grid } from '@material-ui/core';
import InvoiceCard from '../../../Components/Cards/InvoiceCard/InvoiceCard';
import { GetInvoicesAPI } from '../../../API/Invoices/InvoicesAPI';
import { formatAddress } from '../../../Utils/Format/Address';
import { fullName } from '../../../Utils/Format/Names';
import { OpenNewTab } from '../../../Utils/Misc/Links';
import FlatButton from '../../../Components/Buttons/FlatButton';
import BackIcon from '../../../Components/CustomIcons/BackIcon';
import EyeIcon from '../../../Components/CustomIcons/EyeIcon';
import Skeleton from '@material-ui/lab/Skeleton';
import _get from 'lodash/get';
import { Company } from '../../../Configs/general';
import { logoutUser } from '../../../Utils/User/Actions';

const InvoiceHistoryGDG = () => {
  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const authToken = _get(currentUser, 'token');
  const contracts = useContext(ContractsContext);

  const selectedContract = useContext(ContractSelectedContext);

  const setAlert = useContext(AlertsDispatchContext);

  const [invoices, setInvoices] = useState(null);
  const [invoiceLoading, setInvoiceLoading] = useState(true);

  const classes = useStyles();

  useEffect(() => {
    if (!authToken) {
      history.replace('/');
      return;
    }

    if (!contracts || contracts.length === 0) {
      history.replace(ROUTE_NAMES.contracts);
      return;
    }

    let ignoreRequest = false;

    const fetchData = async () => {
      setInvoiceLoading(true);
      const contractId = selectedContract.id;
      const response = await GetInvoicesAPI(contractId, null, authToken);

      if (ignoreRequest) {
        return;
      }

      if (response.success) {
        setInvoices(response.data.data);
        setInvoiceLoading(false);
      } else {
        if (
          redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
        ) {
          return;
        }

        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
      }
    };
    fetchData();

    return () => {
      ignoreRequest = true;
    };
  }, [authToken, contracts, selectedContract, setCurrentUser, setAlert]);

  const downloadInvoice = useCallback(
    idx => {
      OpenNewTab(invoices[idx].url);
    },
    [invoices]
  );

  const payInvoice = useCallback(() => {
    history.push(ROUTE_NAMES.payments, {
      type: 'contract',
      value: selectedContract.id,
      currentStep: 1,
      disabledNext: false,
      payment: [{ type: 'contract', detail: invoices[0] }]
    });
  }, [selectedContract, invoices]);

  const renderDownloadInvoice = useCallback(
    (idx, hasUrl) => {
      if (hasUrl) {
        return (
          <FlatButton
            color="primary"
            className={classes.actionButton}
            onClick={() => downloadInvoice(idx)}
          >
            Ver factura
            <EyeIcon size={16} className={classes.iconLeftStyle} />
          </FlatButton>
        );
      }
    },
    [classes.iconLeftStyle, classes.actionButton, downloadInvoice]
  );

  const renderInvoices = useCallback(() => {
    if (!invoices || invoiceLoading) {
      return (
        <Fragment>
          <InvoiceCard className={classes.mainInvoiceCard}></InvoiceCard>
          <Grid container className={classes.invoiceActions}>
            <Skeleton className={classes.invoiceActionsSkeleton} />
            <Skeleton className={classes.invoiceActionsSkeleton} />
          </Grid>
        </Fragment>
      );
    }

    return (
      <Fragment>
        {invoices.map((invoice, idx) => {
          const hasUrl =
            Boolean(invoice.url) && invoice.url.includes('watermark');
          const canPayInvoice = !invoice.isPaid && !invoice.isPending;
          return (
            <Fragment key={idx}>
              <InvoiceCard
                className={clsx({
                  [classes.mainInvoiceCard]: idx === 0 || hasUrl,
                  [classes.invoiceCard]: idx !== 0 && !hasUrl
                })}
                invoiceDetails={invoice}
              />
              <Grid
                container
                className={clsx({
                  [classes.invoiceActions]: true,
                  [classes.noTopBorder]: !canPayInvoice && !hasUrl
                })}
              >
                <Grid item>{renderDownloadInvoice(idx, hasUrl)}</Grid>
                {canPayInvoice && idx === 0 && (
                  <Grid item>
                    <FlatButton
                      color="primary"
                      className={classes.actionButton}
                      onClick={payInvoice}
                    >
                      Pagar factura
                      <BackIcon size={16} className={classes.iconRightStyle} />
                    </FlatButton>
                  </Grid>
                )}
              </Grid>
            </Fragment>
          );
        })}
      </Fragment>
    );
  }, [invoices, classes, payInvoice, invoiceLoading, renderDownloadInvoice]);

  if (!contracts || contracts.length === 0 || !selectedContract) {
    return <Fragment></Fragment>;
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.innerRoot}>
          <Typography className={classes.contractsTitle}>
            Información de{' '}
            {Company.contractConjugation.regular.singular.article}
          </Typography>
          <div className={classes.contractCard}>
            <div className={classes.contractInfo}>
              <Typography className={classes.subtitle}>
                {Company.contractConjugation.capitalized.singular.associate}
              </Typography>
              <Typography className={classes.title}>
                {selectedContract.alias} - {selectedContract.id}
              </Typography>
            </div>
            <div className={classes.paddedContractInfo}>
              <Typography className={classes.subtitle}>
                Nombre del titular
              </Typography>
              <Typography
                className={`${classes.title} ${classes.capitalizeText}`}
              >
                {fullName(
                  selectedContract,
                  'holder.name',
                  'holder.lastName',
                  true
                )}
              </Typography>
            </div>
            <div className={classes.contractInfo}>
              <Typography className={classes.subtitle}>
                Dirección del predio
              </Typography>
              <Typography
                className={`${classes.title} ${classes.capitalizeText}`}
              >
                {formatAddress(selectedContract, true)}
              </Typography>
            </div>
          </div>
          <Typography className={classes.contractsTitle}>
            Histórico de facturas
          </Typography>
          {renderInvoices()}
        </Container>
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  innerRoot: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.background.cardDark
    },
    paddingBottom: theme.spacing(4)
  },
  contractsTitle: {
    fontSize: 16,
    fontWeight: 500,
    marginTop: theme.spacing(5)
  },
  title: {
    fontSize: 16,
    fontWeight: 500
  },
  capitalizeText: {
    textTransform: 'capitalize'
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 500,
    whiteSpace: 'nowrap'
  },
  contractCard: {
    marginTop: 31,
    border: 'solid 1px #dbdbdb',
    borderRadius: theme.custom.borderRadius,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-around',
    backgroundColor: theme.palette.background.cardDark,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 30,
    paddingBottom: 30,
    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.background.default,
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingLeft: 14,
      paddingTop: 24,
      paddingBottom: 32,
      paddingRight: 0,
      boxSizing: 'content-box'
    }
  },
  paddedContractInfo: {
    paddingRight: 12,
    paddingLeft: 12,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      paddingLeft: 0,
      paddingBottom: 13,
      paddingTop: 13
    }
  },
  mainInvoiceCard: {
    marginTop: theme.spacing(4),
    borderBottom: 'none',
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.background.default
    }
  },
  mainInvoiceCardLoading: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.background.default
    }
  },
  invoiceCard: {
    backgroundColor: theme.palette.background.default,
    border: 'none',
    [theme.breakpoints.up('sm')]: {
      borderBottom: 'solid 1px #dbdbdb',
      '&:last-child': {
        borderBottom: 'none'
      },
      borderRadius: 0
    },
    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.background.default,
      border: 'none',
      marginTop: 12
    }
  },
  invoiceActions: {
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    border: 'solid 1px #dbdbdb',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      backgroundColor: theme.palette.background.cardDark
    }
  },
  actionButton: {
    fontWeight: 500,
    fontSize: 12,
    color: theme.palette.common.black,
    textTransform: 'none',
    paddingTop: theme.spacing(1.875),
    paddingBottom: theme.spacing(1.875),
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  iconRightStyle: {
    transform: 'rotate(180deg)',
    fill: theme.palette.primary.main,
    marginLeft: theme.spacing()
  },
  iconLeftStyle: {
    fill: theme.palette.primary.main,
    marginLeft: theme.spacing()
  },
  invoiceActionsSkeleton: {
    width: '25%'
  },
  noTopBorder: {
    borderTop: 'none'
  }
}));

export default InvoiceHistoryGDG;
