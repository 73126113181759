import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { Controller } from 'react-hook-form';

const ControlledCheckboxInput = props => {
  const {
    name,
    value,
    control,
    icon = <CheckBoxOutlineBlankIcon fontSize="small" />,
    checkedIcon = <CheckBoxIcon fontSize="small" />,
    ...rest
  } = props;

  return (
    <Controller
      as={<Checkbox />}
      name={name}
      type="checkbox"
      value={value}
      control={control}
      icon={icon}
      checkedIcon={checkedIcon}
      {...rest}
    />
  );
};

export default ControlledCheckboxInput;
