import React from 'react';

const selectInputDefaultMessage = 'Seleccione una opción';

export const MAIN_BANNER_TITLE = 'Formulario de registro';

export const GENDER_LABEL_LIST = [
  {
    value: 0,
    label: selectInputDefaultMessage
  },
  {
    value: 'M',
    label: 'Masculino'
  },
  {
    value: 'F',
    label: 'Femenino'
  },
  {
    value: 'X',
    label: 'Prefiero no decirlo'
  }
];

export const GENDER_LIST = ['M', 'F', 'X'];

export const PRODUCT_LIST = [
  {
    value: -1,
    label: selectInputDefaultMessage
  },
  {
    value: -2,
    label: 'Otro'
  }
];

export const CIVIL_STATUS_LABEL_LIST = [
  {
    value: 0,
    label: selectInputDefaultMessage
  },
  {
    value: 'Soltero',
    label: 'Soltero(a)'
  },
  {
    value: 'Casado',
    label: 'Casado(a)'
  },
  {
    value: 'Unión Libre',
    label: 'Unión Libre'
  },
  {
    value: 'Separado',
    label: 'Separado(a)'
  },
  {
    value: 'Viudo',
    label: 'Viudo(a)'
  }
];

export const CIVIL_STATUS_LIST = [
  'Soltero',
  'Casado',
  'Unión Libre',
  'Separado',
  'Viudo'
];

export const FORM_ID = 'gana_loco_form';

export const FORM_STATE = {
  AWAITING: 'AWAITING',
  LATE_PARTICIPATION: 0,
  ALREADY_WON: 1,
  NEW_WINNER: 2
};

export const MAIN_FINAL_TEXT = {
  [FORM_STATE.NEW_WINNER]: <>¡Eres un feliz ganador!</>,
  [FORM_STATE.ALREADY_WON]: <>¡Ya estás participando en Gana loco!</>,
  [FORM_STATE.LATE_PARTICIPATION]: <>¡Ya estás participando en Gana loco!</>
};

export const STREET_LABEL_LIST = [
  {
    value: 0,
    label: 'Seleccione'
  },
  {
    value: 'CL',
    label: 'Calle'
  },
  {
    value: 'KR',
    label: 'Carrera'
  },
  {
    value: 'DG',
    label: 'Diagonal'
  },
  {
    value: 'TV',
    label: 'Transversal'
  },
  {
    value: 'KM',
    label: 'Kilometro'
  }
];

export const STREET_LIST = ['CL', 'KR', 'DG', 'TV', 'KM'];
