import React, { Fragment, forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import BaseButton from '../../../../Components/Buttons/BaseButton';

import { OpenNewTab } from '../../../../Utils/Misc/Links';

import { MAIN_FINAL_TEXT, FORM_STATE } from '../../fixes/ganaLoco_enums';
import { secondarySuccessText } from '../../helpers/helpers';
import { GanaLocoCampaignLink } from '../../../../Configs/Links';

export const Success = props => {
  const classes = useStyles();

  const { formState, userId } = props;

  const LinkComponent = forwardRef((buttonProps, _) => (
    <NavLink to={'/'} {...buttonProps} replace />
  ));
  LinkComponent.displayName = 'notfoundLink';

  return (
    <Fragment>
      <Box className={classes.mainMessageContainer}>
        <Typography
          variant="body2"
          component="h2"
          className={classes.mainMessage}
        >
          {MAIN_FINAL_TEXT[formState]}
        </Typography>
      </Box>
      <Box className={classes.secondaryMessageContainer}>
        <Typography
          variant="body2"
          component="h6"
          className={classes.secondaryMessage}
        >
          {secondarySuccessText(formState, userId)}
        </Typography>
      </Box>
      <Box className={classes.termsAndConditionsContainer}>
        <Typography
          variant="body2"
          component="p"
          className={classes.termsAndConditionsMessage}
        >
          Ver términos y condiciones{' '}
          <strong onClick={() => OpenNewTab(GanaLocoCampaignLink)}>aquí</strong>
        </Typography>
      </Box>
      <Box className={classes.backButtonContainer}>
        <BaseButton
          color="primary"
          variant="outlined"
          fullWidth={true}
          component={LinkComponent}
          className={clsx(classes.backButton, {
            [classes.backButtonWinner]: formState === FORM_STATE.NEW_WINNER
          })}
        >
          Regresa al inicio
        </BaseButton>
      </Box>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  mainMessageContainer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      maxHeight: theme.spacing(8),
      padding: theme.spacing(0, 6),
      margin: theme.spacing(0, 11, 3, 11)
    }
  },
  mainMessage: {
    color: '#3C41DB',
    fontSize: 20,
    fontWeight: 700,
    [theme.breakpoints.up('sm')]: {
      fontSize: 24
    }
  },
  secondaryMessageContainer: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    margin: theme.spacing(0, 3, 2, 3),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 11, 2, 11)
    }
  },
  secondaryMessage: {
    color: '#333333',
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16
    }
  },
  termsAndConditionsContainer: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(4)
    }
  },
  termsAndConditionsMessage: {
    fontSize: 12,
    '& > strong': {
      fontWeight: 'bold',
      color: '#2A61BA',
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  backButtonContainer: {
    margin: theme.spacing(0, 'auto'),
    maxWidth: theme.spacing(34),
    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.spacing(19)
    }
  },
  backButton: {
    border: '1px solid #104EB2',
    fontWeight: 600,
    margin: '66px 0 32px 0',
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 0, 10, 0),
      padding: theme.spacing(2)
    }
  },
  backButtonWinner: {
    margin: '98px 0 32px 0',
    [theme.breakpoints.up('sm')]: {
      margin: '0 0 94px 0'
    }
  }
}));

export default Success;
