import React, { useCallback, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import RequestDialog from '../../../Components/Dialogs/RequestDialog';

const CellphoneStep = props => {
  const {
    step,
    setNextModal,
    onClose,
    open,
    title,
    phoneValidated = false
  } = props;

  const [visible, setVisible] = useState(open);

  const classes = useStyles();

  const data = step.data;
  const contractId = data.contractId;

  const handleGoBack = useCallback(
    event => {
      event.preventDefault();
      setNextModal({
        type: 'validation',
        data: { contractId, ...step.data }
      });
      setVisible(false);
    },
    [setNextModal, contractId, step, setVisible]
  );

  const handleSubmit = useCallback(async () => {
    setNextModal({
      type: 'invoices',
      data: { ...step.data }
    });
    return { unmounting: true, closeDialog: true };
  }, [step, setNextModal]);

  return (
    <RequestDialog
      open={visible}
      setDialog={setVisible}
      beforeClosing={onClose}
      requestTitle={title}
      requestCallback={handleSubmit}
      withoutObservation={true}
      backButton={{
        text: 'Atrás',
        handle: handleGoBack
      }}
      submitButtonText={'Continuar'}
      disableBackdropClick={true}
      requestNode={
        <Typography paragraph className={classes.text}>
          {`Para solicitar la generación del cupón de pago es necesario ${
            phoneValidated ? 'confirmar' : 'validar'
          } tu
          número de celular`}
        </Typography>
      }
      contentSize="small"
    />
  );
};

const useStyles = makeStyles(theme => ({
  text: {
    textAlign: 'center',
    fontSize: 14,
    color: theme.palette.text.primary,
    width: '100%',
    maxWidth: '100%',
    marginBottom: 0
  }
}));

export default CellphoneStep;
