/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { Controller } from 'react-hook-form';
import SearchIcon from '../Adornments/SearchIcon';

const SearchInput = props => {
  const classes = useStyles();
  const {
    id,
    options,
    name,
    className = '',
    control,
    defaultValue,
    placeholder,
    TextInputProps,
    onInputChange,
    startAdornment = <SearchIcon />
  } = props;

  return (
    <Controller
      as={
        <Autocomplete
          className={className}
          freeSolo
          id={id}
          ListboxProps={{
            style: { maxHeight: 165 }
          }}
          disableClearable
          options={options}
          onInputChange={onInputChange}
          classes={{
            clearIndicator: classes.clearIndicator
          }}
          renderInput={params => (
            <TextField
              {...params}
              placeholder={placeholder}
              margin="none"
              {...TextInputProps}
              InputProps={{
                ...params.InputProps,
                startAdornment,
                disableUnderline: true,
                classes: {
                  root: classes.root,
                  input: classes.input
                }
              }}
              InputLabelProps={{
                className: classes.label
              }}
            />
          )}
        />
      }
      onChange={([, data]) => data}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 58,
    backgroundColor: 'white',
    borderRadius: '30px'
  },
  input: {
    height: 'inherit',
    fontSize: 16,
    marginRight: theme.spacing(3)
  },
  label: {
    textOverflow: 'ellipsis'
  },
  clearIndicator: {
    display: 'none'
  }
}));

export default SearchInput;
