import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import GanaLocoGDC from './GanaLoco.GDC';

const GanaLoco = () => {
  switch (portal) {
    case Portal.Gascaribe:
      return <GanaLocoGDC />;
    default:
      return <></>;
  }
};

export default GanaLoco;
