import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Typography } from '@material-ui/core';

import { OpenNewTab } from '../../Utils/Misc/Links';

const IEChecker = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  /**
   * Checks if client is running in
   * Internet Explorer 11 or lower versions
   */
  const isUsingIE = window.navigator.userAgent.match(/(MSIE|Trident)/);

  if (!isUsingIE) {
    return <Fragment></Fragment>;
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      open={open}
      onClose={() => setOpen(false)}
      onClick={() => OpenNewTab('https://www.google.com/chrome/')}
    >
      <SnackbarContent
        className={classes.content}
        message={
          <Typography>
            Para una mejor experiencia en la aplicación, te recomendamos usar{' '}
            <strong>Google Chrome</strong>.
          </Typography>
        }
      />
    </Snackbar>
  );
};

const useStyles = makeStyles(theme => ({
  content: {
    fontSize: 15,
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main
  }
}));

export default IEChecker;
