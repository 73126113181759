import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';

const InsuranceProductSkeleton = () => {
  const classes = useStyles();

  return (
    <div className={classes.card}>
      <Grid container direction="column" justify="space-between">
        <Grid container item justify="flex-start" alignItems="baseline">
          <Grid container item justify="space-between">
            <Grid item className={clsx(classes.margin)}>
              <Skeleton variant="rect" height={64} width={150} />
            </Grid>
            <Grid item>
              <Skeleton variant="rect" height={21} width={76} />
            </Grid>
          </Grid>

          <Grid container item direction="column" justify="space-between">
            <Grid item>
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    borderRadius: theme.custom.borderRadius,
    border: `1px solid ${theme.palette.background.border}`,
    position: 'relative',
    padding: theme.spacing(2),
    height: '100%',
    width: '100%',
    minHeight: 230,
    '& > *': {
      marginBottom: theme.spacing(1.5),
      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  margin: {
    marginBottom: theme.spacing(1)
  }
}));

export default InsuranceProductSkeleton;
