import React, {
  Fragment,
  useContext,
  useEffect,
  useCallback,
  useState,
  useMemo
} from 'react';
import { Grid, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import ModalProgress from '../../../../../Components/Progress/Modal/ModalProgress';
import SimpleDivider from '../../../../../Components/Dividers/SimpleDivider';
import ProductAccordion from '../../../Components/Deferred/ProductAccordion';
import Title from '../../../../../Components/Labels/Title';
import ContractSimpleCard from '../../../Cards/ContractSimpleCard';
import myDebtsBanner from '../../../../../Assets/images/my-debts-banner.png';

import { UserContext } from '../../../../../Contexts/UserContext';
import { ContractSelectedContext } from '../../../../../Contexts/ContractsContext';
import {
  SetCurrentStepIndexContext,
  StepperDataContext,
  StepperDataDispatchContext,
  CurrentStepIndexContext
} from '../../../../../Contexts/StepperContext';

import { numberWithDots } from '../../../../../Utils/Format/MoneyFormat';
import { extractErrorMessage } from '../../../../../Utils/Errors/Errors';

import { GetChangeConditionsPlanAPI } from '../../../../../API/Debts/DebtsAPI';

import { requestTypes } from '../../../../Requests/Utils/enums';
import {
  getPaymentPlanDetail,
  getFirstBillingValue
} from '../../../../../Utils/Financing/Financing';
import InstallmentsSelector from '../../../Components/InstallmentsSelector';
import { Company } from '../../../../../Configs/general';

const PaymentPlanStep = props => {
  const {
    formId,
    setCanSubmit,
    setDisableBack,
    setGoBack,
    setAlert,
    setCurrentStep: setVisualStepperIndex,
    setNextButtonText,
    addedDeferreds,
    setPaymentPlan,
    paymentPlan,
    setFormErrors,
    isMobileSize
  } = props;

  const classes = useStyles();

  // * CONTEXTS
  const currentStepIndex = useContext(CurrentStepIndexContext);
  const setCurrentStep = useContext(SetCurrentStepIndexContext);
  const setData = useContext(StepperDataDispatchContext);
  const stepperData = useContext(StepperDataContext);
  const selectedContract = useContext(ContractSelectedContext);
  const currentUser = useContext(UserContext);

  // * STATE HOOKS
  const [loading, setLoading] = useState(false);
  const [shape, setShape] = useState(stepperData.validationShape || {});
  const [schema, setSchema] = useState(yup.object(shape));

  useEffect(() => {
    setSchema(yup.object(shape));
  }, [shape]);

  const {
    register,
    watch,
    getValues,
    errors,
    setValue,
    handleSubmit,
    control,
    formState: { isValid }
  } = useForm({
    validationSchema: schema,
    submitFocusError: true,
    mode: 'onChange',
    defaultValues: {
      numberOfInstallments: stepperData.numberOfInstallments || 0,
      ...stepperData
    }
  });

  const isReallyValid = isValid || Object.keys(errors).length === 0;

  setFormErrors(errors);
  const watchForm = watch(['initialInstallmentValue', 'numberOfInstallments']);

  const productIds = useMemo(
    () => [...new Set(addedDeferreds.map(d => d.productId))],
    [addedDeferreds]
  );

  const products = useMemo(() => {
    const createFindCallback = productId => {
      return deferred => deferred.productId === productId;
    };
    return productIds.map(productId => {
      const item = addedDeferreds.find(createFindCallback(productId));
      const financingValue = addedDeferreds
        .filter(d => d.productId === productId)
        .reduce((sum, current) => sum + current.pendingValue, 0);
      return {
        productId: item.productId,
        productTypeDescription: item.productTypeDescription,
        financingValue
      };
    });
  }, [addedDeferreds, productIds]);

  const totalDeferreds = addedDeferreds.reduce(
    (sum, current) => sum + current.pendingValue,
    0
  );

  const totalFinanceValue =
    totalDeferreds - (watchForm.initialInstallmentValue || 0);

  useEffect(() => {
    setPaymentPlan(p => ({
      ...p,
      numberOfInstallments: watchForm.numberOfInstallments
    }));
  }, [watchForm.numberOfInstallments, setPaymentPlan]);

  useEffect(() => {
    setPaymentPlan(p => ({
      ...p,
      initialInstallmentValue: watchForm.initialInstallmentValue
    }));
  }, [watchForm.initialInstallmentValue, setPaymentPlan]);

  const handleBillingsNumber = action => {
    let numberOfInstallments = watchForm.numberOfInstallments;
    switch (action) {
      case 'plus':
        if (numberOfInstallments < paymentPlan.maxInstallments) {
          numberOfInstallments++;
          setValue('numberOfInstallments', numberOfInstallments);
        }
        break;

      case 'minus':
        if (numberOfInstallments > paymentPlan.minInstallments) {
          numberOfInstallments--;
          setValue('numberOfInstallments', numberOfInstallments);
        }
        break;

      default:
        break;
    }
  };

  const goNext = values => {
    const productsFinancingData = products.map(({ financingValue }) => {
      return {
        interestRate: paymentPlan.monthlyInterestRate,
        billings: watchForm.numberOfInstallments,
        totalFinancingValue:
          financingValue - (watchForm.initialInstallmentValue || 0)
      };
    });

    const { totalCapital, totalInterest, totalPayment } = getPaymentPlanDetail(
      productsFinancingData
    );

    const monthlyInstallment = getFirstBillingValue(productsFinancingData);

    const createFilterCallback = productId => {
      return deferred => deferred.productId === productId;
    };

    const mapCallback = deferred => deferred.deferredId;

    setData(data => ({
      ...data,
      ...values,
      interestRate: paymentPlan.monthlyInterestRate,
      financingPlanId: paymentPlan.financingPlanId,
      totalCapital,
      totalInterest,
      totalPayment,
      monthlyInstallment,
      totalFinanceValue,
      totalInitialInstallmentValue: watchForm.initialInstallmentValue,
      products: products.map(product => {
        const deferredIds = addedDeferreds
          .filter(createFilterCallback(product.productId))
          .map(mapCallback);
        return {
          productId: product.productId,
          initialInstallmentValue: watchForm.initialInstallmentValue,
          financingPlanId: paymentPlan.financingPlanId,
          interestRate: paymentPlan.monthlyInterestRate,
          productType: product.productTypeDescription,
          deferredIds
        };
      }),
      requestType: requestTypes.changeConditions
    }));
    setCurrentStep(step => step + 1);
  };

  const onBackward = useCallback(() => {
    setData(data => ({ ...data, ...getValues() }));
    setCurrentStep(currentStep => currentStep - 1);
  }, [getValues, setCurrentStep, setData]);

  const getInitialInstallmentValidation = ({ min, max }) => {
    return yup
      .number('Debe ser un número')
      .min(0, 'Debe ser mayor a 0')
      .test(
        'value',
        `Debe ser $0 o al menos ${min === 0 ? '$100' : numberWithDots(min)}`,
        val => {
          return min === 0 ? val === 0 || val >= 100 : val >= min;
        }
      )
      .test('value', `Debe ser máximo ${numberWithDots(max)}`, val => {
        return val <= max;
      })
      .required('Ingresa un valor');
  };

  const getInstallmentsValidation = ({ min, max }) => {
    return yup
      .number()
      .test('value', `Debe ser al menos ${min}`, val => {
        return val >= min;
      })
      .test('value', `Debe ser máximo ${max}`, val => {
        return val <= max;
      })
      .required('Ingresa el número de cuotas');
  };

  const processData = useCallback(
    data => {
      const totalProduct = addedDeferreds.reduce(
        (sum, current) => sum + current.pendingValue,
        0
      );
      return {
        ...data,
        minInitialInstallment: data.initialInstallmentValue,
        totalProduct,
        monthlyInterestRate: parseFloat(
          (data.annualInterestRate / 12).toFixed(2)
        )
      };
    },
    [addedDeferreds]
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const data = {
        productId: productIds[0],
        financingValue: products[0].financingValue
      };
      const response = await GetChangeConditionsPlanAPI(
        currentUser.token,
        selectedContract.id,
        data
      );
      if (response.success) {
        const responseData = response.data.data;
        const processedData = processData(responseData);
        setPaymentPlan(p => ({ ...p, ...processedData }));
        const validationShape = {
          numberOfInstallments: getInstallmentsValidation({
            min: processedData.minInstallments,
            max: processedData.maxInstallments
          }),
          initialInstallmentValue: getInitialInstallmentValidation({
            min: processedData.minInitialInstallment,
            max: processedData.totalProduct
          })
        };
        setShape(validationShape);
        const usuryRate = Number.parseFloat(
          responseData.usuryRate / 12
        ).toFixed(2);
        setData(d => ({
          ...d,
          paymentPlan: processedData,
          usuryRate,
          validationShape
        }));
        setLoading(false);
      } else {
        setLoading(false);
        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
        setCurrentStep(step => step - 1);
      }
    };
    if (!stepperData.paymentPlan) {
      fetchData();
    }
  }, [
    currentUser.token,
    setAlert,
    selectedContract.id,
    setCurrentStep,
    processData,
    setPaymentPlan,
    products,
    productIds,
    stepperData.paymentPlan,
    setData
  ]);

  useEffect(() => {
    setDisableBack(loading);
  }, [loading, setDisableBack]);

  useEffect(() => {
    setCanSubmit(isReallyValid && !loading);
  }, [isReallyValid, setCanSubmit, loading]);

  useEffect(() => {
    setNextButtonText('Siguiente');
    setVisualStepperIndex(currentStepIndex);
    setGoBack({
      action: onBackward
    });
  }, [
    setGoBack,
    onBackward,
    setVisualStepperIndex,
    currentStepIndex,
    setNextButtonText
  ]);

  const renderProducts = () => {
    return (
      products.length > 0 && (
        <div className={classes.productsContainer}>
          {products.map((p, idx) => {
            return (
              <ProductAccordion
                key={idx}
                typeDescription={p.productTypeDescription}
                id={p.productId}
                minimunInstallment={paymentPlan.initialInstallmentValue}
                monthlyInterestRate={paymentPlan.monthlyInterestRate}
                deferreds={addedDeferreds}
                totalDeferreds={p.financingValue}
                total={paymentPlan.totalProduct}
                control={control}
                errors={errors}
                inputName="initialInstallmentValue"
                setAlert={setAlert}
                initialInstallmentInputLabel="Valor cuota inicial (Opcional)"
              />
            );
          })}
        </div>
      )
    );
  };

  if (!currentUser) {
    return <Fragment></Fragment>;
  }

  return (
    <div className={classes.container}>
      <Title title="Financiación de la deuda" className={classes.title} />
      <ContractSimpleCard
        banner={myDebtsBanner}
        contract={selectedContract}
        title={`Información ${Company.contractConjugation.regular.singular.contraction}`}
      />

      {loading ? (
        <ModalProgress message="Consultando plan" />
      ) : (
        <form id={formId} onSubmit={handleSubmit(goNext)}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.subtitleContainer}
          >
            <Grid
              item
              xs={12}
              md={6}
              className={classes.balanceToRefinanceTextContainer}
            >
              <Typography className={classes.subtitle}>
                Saldos a financiar
              </Typography>
              <Typography className={classes.helperText}>
                Ingresa el número de cuotas para financiar la deuda
              </Typography>
            </Grid>
            <Grid item container xs={12} md={6} justify="flex-end">
              <Grid item xs={12}>
                <InstallmentsSelector
                  register={register}
                  control={control}
                  handleBillingsNumber={handleBillingsNumber}
                  error={errors && Boolean(errors.numberOfInstallments)}
                  inputName="numberOfInstallments"
                  fullWidth={isMobileSize}
                  customClasses={{
                    container: classes.customQuotaInputContainer
                  }}
                  helperText={
                    errors &&
                    errors.numberOfInstallments &&
                    errors.numberOfInstallments.message
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          {renderProducts()}
          <SimpleDivider className={classes.divider} />
          <div className={classes.totalWrapper}>
            <Typography className={classes.label}>Saldo total</Typography>
            <Typography className={classes.total}>
              {numberWithDots(totalDeferreds)}
            </Typography>
          </div>
        </form>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(5)
  },
  productsContainer: {
    '& > *': {
      marginBottom: theme.spacing(3)
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3)
    }
  },
  title: {
    fontSize: 16,
    fontWeight: 600
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 600
  },
  subtitleContainer: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  installmentsSelector: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      padding: theme.spacing(2, 2, 1, 2),
      margin: '0 auto'
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: theme.spacing(2, 0, 1, 0),
      margin: '0 auto'
    }
  },
  helperText: {
    fontSize: 12,
    color: theme.palette.text.greyDark,
    fontWeight: 600,
    margin: 0,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginTop: theme.spacing(1)
    }
  },
  quotaInput: {
    maxWidth: 128,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      maxWidth: '100%'
    }
  },
  customQuotaInputContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  balanceToRefinanceTextContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingBottom: theme.spacing(1)
    }
  },
  divider: {
    marginBottom: theme.spacing(2)
  },
  totalWrapper: {
    marginRight: theme.spacing(6.25),
    textAlign: 'right',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginRight: theme.spacing(2)
    }
  },
  label: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.greyDark,
    lineHeight: 1.2
  },
  total: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: 1
  },
  formHelperText: {
    margin: theme.spacing(0.25, 0, 0),
    fontSize: 11,
    position: 'absolute',
    top: '100%'
  },
  quotaIcons: {
    fontSize: 32,
    color: theme.palette.primary.main,
    padding: theme.spacing(0.25),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 3
  },
  plusIcon: {
    marginLeft: theme.spacing(2)
  },
  minusIcon: {
    marginRight: theme.spacing(2)
  }
}));

export default PaymentPlanStep;
