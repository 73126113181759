import axios from 'axios';
import { config } from '../../Configs';

export const RevisionAPI = async (token, contractId) => {
  try {
    const response = await axios.get(
      `${config.api.url}/revision/${contractId}`,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const RevisionHistoryAPI = async (
  token = null,
  contractId,
  recaptchaToken = null
) => {
  try {
    const configObj = {
      headers: {
        Authorization: token
      },
      params: { 'g-recaptcha-response': recaptchaToken }
    };

    const response = await axios.get(
      `${config.api.url}/revision/history/${contractId}`,
      configObj
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const RevisionRequestAPI = async (
  token = null,
  data,
  recaptchaToken = null
) => {
  try {
    const configObj = {
      headers: {
        Authorization: token
      },
      params: { 'g-recaptcha-response': recaptchaToken }
    };
    const response = await axios.post(
      `${config.api.url}/revision/${data.contractId}`,
      data,
      configObj
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const AgendaAPI = async (
  token = null,
  contractId,
  recaptchaToken = null
) => {
  try {
    const configObj = {
      headers: {
        Authorization: token
      },
      params: { 'g-recaptcha-response': recaptchaToken }
    };

    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/revision/schedule/agenda/${contractId}`,
      configObj
    );
    return { success: true, ...response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const RevisionOrderInfoAPI = async (
  token = null,
  contractId,
  recaptchaToken = null
) => {
  try {
    const configObj = {
      headers: {
        Authorization: token
      },
      params: { 'g-recaptcha-response': recaptchaToken }
    };

    const response = await axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/revision/schedule/order/${contractId}`,
      configObj
    );
    return { success: true, ...response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const RevisionScheduleAPI = async (
  token = null,
  data,
  recaptchaToken = null
) => {
  try {
    const configObj = {
      headers: {
        Authorization: token
      },
      params: { 'g-recaptcha-response': recaptchaToken }
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API_DOMAIN}/revision/schedule`,
      data,
      configObj
    );
    return { success: true, ...response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const UpdateRevisionScheduleAPI = async (
  token = null,
  appointmentId,
  data,
  recaptchaToken = null
) => {
  try {
    const configObj = {
      headers: {
        Authorization: token
      },
      params: { 'g-recaptcha-response': recaptchaToken }
    };

    const response = await axios.put(
      `${process.env.REACT_APP_API_DOMAIN}/revision/schedule/${appointmentId}`,
      data,
      configObj
    );
    return { success: true, ...response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const CancelRevisionScheduleAPI = async (
  token = null,
  appointmentId,
  data,
  recaptchaToken = null
) => {
  try {
    const configObj = {
      headers: {
        Authorization: token
      },
      params: { 'g-recaptcha-response': recaptchaToken }
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API_DOMAIN}/revision/schedule/cancel/${appointmentId}`,
      data,
      configObj
    );
    return { success: true, ...response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};
