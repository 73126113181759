import React from 'react';
import { portal, Portal } from '../../../../Configs/general';

import WebFooterGDC from './WebFooter.GDC';
import WebFooterGDG from './WebFooter.GDG';

const WebFooter = () => {
  switch (portal) {
    case Portal.Gascaribe:
      return <WebFooterGDC />;
    case Portal.Gasguajira:
      return <WebFooterGDG />;
    case Portal.Efigas:
      return <WebFooterGDC />;
    case Portal.TripleA:
      return <WebFooterGDG />;
    default:
      return <></>;
  }
};

export default WebFooter;
