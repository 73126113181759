import React from 'react';
import { portal, Portal } from '../../Configs/general';

import VerifyVisitGDC from './VerifyVisit.GDC';

const VerifyVisit = () => {
  switch (portal) {
    case Portal.Gascaribe:
      return <VerifyVisitGDC />;
    case Portal.Efigas:
      return <VerifyVisitGDC />;
    default:
      return <></>;
  }
};

export default VerifyVisit;
