import * as React from 'react';

function SvgPointsFireBadge(props) {
  return (
    <svg
      id="icon__fireBadge_1"
      width={props.size || 27}
      height={props.size || 28}
      viewBox="0 0 27 28"
      xmlSpace="preserve"
      {...props}
    >
      <g data-name="Group 628" transform="translate(-904 -192)">
        <ellipse
          data-name="Ellipse 179"
          cx={13.5}
          cy={14}
          rx={13.5}
          ry={14}
          transform="translate(904 192)"
          fill="#75a849"
        />
        <path
          data-name="Path 556"
          d="M918.029 215.364a6.924 6.924 0 01-6.944-6.94 6.522 6.522 0 01.77-3.4 6.9 6.9 0 00.549-5.419c-.233-.828.177-1.244.978-1.041a3.732 3.732 0 011.862 1.026c.415.443.539.25.737-.185a7.472 7.472 0 013.068-3.492 2.561 2.561 0 01.825-.331c.465-.078.833.176.745.676-.457 2.609.814 4.633 1.979 6.7a13.589 13.589 0 011.8 3.938c.852 4.176-2.324 8.421-6.369 8.468zm-3.966-14.9c-.01 2.825-.008 2.826-1.165 5.558a5.908 5.908 0 00-.357 3.246 4.254 4.254 0 00.982 2.323c.05-.068.1-.1.1-.138a5.657 5.657 0 012.269-4.759 2.733 2.733 0 00.941-1.3c.257-.895.72-1.047 1.411-.434 2.189 1.943 3.577 4.277 3.123 7.551a6.02 6.02 0 001.772-3.653 7.209 7.209 0 00-1.389-4.634 18.174 18.174 0 01-2.387-5.16 5.774 5.774 0 00-.3-1.378 5.65 5.65 0 00-2.3 4.157c-.006.421.011.862-.476.992-.525.14-.714-.277-.9-.682a3.626 3.626 0 00-1.322-1.694zm6.056 10.654a7.01 7.01 0 00-1.754-4.125c-.358-.424-.551-.406-.879.03a12.738 12.738 0 01-1.173 1.21 4.746 4.746 0 00-1.1 1.545 3.169 3.169 0 00.518 3.352 2.524 2.524 0 002.795.6 2.649 2.649 0 001.595-2.617z"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default SvgPointsFireBadge;
