import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import { renderStatus } from './enums';

const RequestStatus = ({ status, withLabel = true }) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('750'));
  const classes = useStyles();

  const { statusText, statusColor } = renderStatus(status);

  return (
    <Grid
      item
      container
      direction={smallScreen ? 'row' : 'column'}
      spacing={smallScreen ? 0 : 1}
    >
      {withLabel && (
        <Grid item>
          <Typography className={classes.statusLabel}>Estado</Typography>
        </Grid>
      )}

      <Grid item>
        <Grid container alignItems="center">
          <span
            className={classes.status}
            style={{ backgroundColor: statusColor }}
          ></span>
          <Typography>{statusText}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  statusLabel: {
    marginRight: theme.spacing(1)
  },
  status: {
    borderRadius: '50%',
    width: 10,
    height: 10,
    display: 'inline-block',
    marginRight: theme.spacing(1),
    [theme.breakpoints.down(750)]: {
      marginLeft: 0
    }
  }
}));

export default RequestStatus;
