import React, { useState, useCallback } from 'react';
import { useTheme } from '@material-ui/styles';
import { Grid, Typography, useMediaQuery, makeStyles } from '@material-ui/core';

import { renderContractTypeDescription } from '../../Contracts/contract_enums';
import { formatAddress } from '../../../Utils/Format/Address';

import ContractDebtInfoDialog from '../../../Components/Dialogs/ContractDebtInfoDialog';
import ContractDebtDialogContent from '../Components/ContractDebtDialogContent';
import SeeMoreButton from '../Components/SeeMoreButton';
import { Company } from '../../../Configs/general';

const ContractSimpleCard = props => {
  const { contract, title, banner, data = {} } = props;

  const classes = useStyles();
  const theme = useTheme();

  // * OTHER HOOKS
  const mediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  //* STATE HOOKS
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);

  //* CALLBACKS
  const onHelpClick = useCallback(() => {
    setHelpDialogOpen(true);
  }, []);

  const contractDialogContent = () => {
    return (
      <ContractDebtDialogContent
        data={data}
        contract={contract}
        contractAddress={formatAddress(contract, true)}
        contractType={renderContractTypeDescription(contract.type)}
      />
    );
  };

  return (
    <div className={classes.card} style={{ backgroundImage: `url(${banner})` }}>
      <ContractDebtInfoDialog
        open={helpDialogOpen && mediumScreen}
        title={`Información ${Company.contractConjugation.regular.singular.contraction}`}
        onClose={() => setHelpDialogOpen(false)}
        content={contractDialogContent}
      />

      <Grid container justify="space-between" alignItems="center">
        <Grid container item xs={8} md={12} direction="column">
          <Grid item>
            <Typography className={classes.title}>{title}</Typography>
          </Grid>
          {mediumScreen && (
            <Grid item>
              <Typography className={classes.contractData}>
                {contract.alias} - {contract.id}
              </Typography>
            </Grid>
          )}
        </Grid>
        {mediumScreen && (
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            <SeeMoreButton
              onClick={onHelpClick}
              iconClasses={classes.seeMoreIcon}
              buttonClasses={classes.seeMoreButton}
            />
          </Grid>
        )}
      </Grid>

      {!mediumScreen && (
        <Grid container direction="row" alignItems="baseline">
          <Grid item md={4}>
            <Typography className={classes.label}>
              {Company.contractConjugation.capitalized.singular.associate} a la
              deuda
            </Typography>
            <Typography className={classes.contractData}>
              {contract.alias} - {contract.id}
            </Typography>
          </Grid>
          <Grid item md={5}>
            <Typography className={classes.label}>
              Dirección{' '}
              {Company.contractConjugation.regular.singular.contraction}
            </Typography>
            <Typography className={classes.contractData}>
              {formatAddress(contract, true)}
            </Typography>
          </Grid>
          <Grid item md={3}>
            <Typography className={classes.label}>
              Tipo de {Company.contractConjugation.regular.singular.main}
            </Typography>
            <Typography className={classes.contractData}>
              {renderContractTypeDescription(contract.type)}
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: theme.custom.borderRadius,
    backgroundColor: theme.palette.background.cardDark,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: 'white',
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      padding: theme.spacing(2, 2, 3, 2)
    }
  },
  label: {
    fontSize: 12,
    fontWeight: 500
  },
  contractData: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.2,
    textTransform: 'capitalize'
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.divider,
    margin: theme.spacing(2, 0, 2, 0)
  },
  total: {
    fontSize: 20
  },
  alignRight: {
    textAlign: 'right'
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: 11,
      fontWeight: 'normal',
      marginBottom: theme.spacing(1)
    }
  },
  seeMoreIcon: {
    fontSize: 24,
    color: theme.palette.primary.lighter,
    marginLeft: theme.spacing(1)
  },
  seeMoreButton: {
    fontSize: 12,
    color: theme.palette.primary.lighter
  }
}));

export default ContractSimpleCard;
