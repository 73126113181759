import React from 'react';
import { portal, Portal } from '../../../../Configs/general';

import EditContractGDC from './EditContract.GDC';
import EditContractGDG from './EditContract.GDG';
import EditContractEFG from './EditContract.EFG';

const EditContract = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <EditContractGDC {...props} />;
    case Portal.TripleA:
    case Portal.Gasguajira:
      return <EditContractGDG {...props} />;
    case Portal.Efigas:
      return <EditContractEFG {...props} />;
    default:
      return <></>;
  }
};

export default EditContract;
