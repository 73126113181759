import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import InvoiceHistoryGDC from './InvoiceHistory.GDC';
import InvoiceHistoryGDG from './InvoiceHistory.GDG';

const InvoiceHistory = () => {
  switch (portal) {
    case Portal.Gascaribe:
    case Portal.TripleA:
      return <InvoiceHistoryGDC />;
    case Portal.Gasguajira:
      return <InvoiceHistoryGDG />;
    case Portal.Efigas:
      return <InvoiceHistoryGDC />;
    default:
      return <></>;
  }
};

export default InvoiceHistory;
