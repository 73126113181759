import React, { useCallback } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader
} from '@material-ui/core';

import SvgPhone from '../CustomIcons/PhoneIcon';
import { Company } from '../../Configs/general';

const PhoneList = () => {
  const classes = useStyles();

  const onClickPhoneNumber = useCallback(phone => {
    return () => window.open(`tel:${phone}`);
  }, []);

  return (
    <div className={clsx(classes.section)}>
      {Company.attentionLine.map(({ label, number }, i) => (
        <List
          key={i}
          className={classes.line}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              className={clsx(classes.subtitle, classes.subtitlePhone)}
            >
              {label}
            </ListSubheader>
          }
        >
          {number.map(({ text, phone }, id) => (
            <ListItem
              button
              onClick={onClickPhoneNumber(phone)}
              className={classes.itemPhone}
              key={id}
            >
              <ListItemText primary={text} />
              <ListItemIcon className={classes.iconPhone}>
                <SvgPhone width={25} height={25} />
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      ))}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  section: {
    backgroundColor: '#ffffff',
    [theme.breakpoints.between(theme.breakpoints.values.sm, 1400)]: {
      height: 'calc((calc(100vh - 128px)) - (calc(100vh - 50px)/2))',
      overflowY: 'scroll',
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        borderRadius: '10px',
        border: '2px solid #ffffff'
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: '10px',
        backgroundColor: '#ffffff'
      },
      '&::-webkit-scrollbar': {
        '-webkit-appearance': 'none'
      },
      '&::-webkit-scrollbar:vertical': {
        width: '8px'
      },
      '&::-webkit-scrollbar:horizontal': {
        height: '8px'
      }
    }
  },
  subtitle: {
    fontSize: 10,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#747b87'
  },
  line: {
    display: 'flex',
    flexDirection: 'column',
    margin: [[0, theme.spacing(2)]],
    borderBottom: '1px solid #e7e7e7',
    '&:last-child': {
      borderBottom: 'none'
    }
  },
  subtitlePhone: {
    padding: [[8, 0, 0, 0]],
    position: 'relative'
  },
  itemPhone: {
    padding: [[8, 2]]
  },
  iconPhone: {
    justifyContent: 'flex-end'
  }
}));

export default PhoneList;
