import React from 'react';

const OwnershipChangeIcon = ({
  width = 35,
  height = 35,
  checkFill = '#fff',
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 56 56"
      {...rest}
    >
      <g transform="translate(0 0)">
        <g
          transform="translate(6.657 5.378)"
          fill="none"
          stroke="#333"
          strokeWidth="1"
        >
          <rect width="41.154" height="50.622" rx="4" stroke="none" />
          <rect
            x="0.5"
            y="0.5"
            width="40.154"
            height="49.622"
            rx="3.5"
            fill="none"
          />
        </g>
        <g transform="translate(17.2 15.809)">
          <path
            d="M58.376,47.982a10.253,10.253,0,1,0,10.108,10.11A10.266,10.266,0,0,0,58.376,47.982Zm6.231,16.129a.2.2,0,0,1-.3-.016,6.125,6.125,0,0,0-1.594-1.458,8.648,8.648,0,0,0-8.953,0,6.123,6.123,0,0,0-1.594,1.458.2.2,0,0,1-.3.016,8.641,8.641,0,0,1-2.3-5.729,8.675,8.675,0,1,1,17.349-.148A8.641,8.641,0,0,1,64.607,64.111Z"
            transform="translate(-47.981 -47.981)"
            fill="#333"
          />
          <path
            d="M191.362,144a3.257,3.257,0,0,0-3.341,3.586,3.345,3.345,0,1,0,6.682,0,3.311,3.311,0,0,0-.871-2.554A3.347,3.347,0,0,0,191.362,144Z"
            transform="translate(-181.11 -139.268)"
            fill="#333"
          />
        </g>
        <line
          x2="21.786"
          transform="translate(16.559 43.069)"
          fill="none"
          stroke="#333"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <line
          x2="21.786"
          transform="translate(16.559 48.774)"
          fill="none"
          stroke="#333"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <g transform="translate(37.104)">
          <path
            d="M79.378,71.689a7.689,7.689,0,1,0-7.689,7.689A7.691,7.691,0,0,0,79.378,71.689Z"
            transform="translate(-63.4 -63.4)"
            fill={checkFill}
          />
          <path
            d="M71.689,63.4A8.289,8.289,0,1,1,63.4,71.689,8.3,8.3,0,0,1,71.689,63.4Zm0,15.378A7.089,7.089,0,1,0,64.6,71.689,7.1,7.1,0,0,0,71.689,78.778Z"
            transform="translate(-63.4 -63.4)"
            fill="#333"
          />
          <path
            d="M167.689,176l-5.382,6.408L160,179.845"
            transform="translate(-155.555 -170.915)"
            fill="rgba(219,174,174,0)"
          />
          <path
            d="M162.307,183.008a.6.6,0,0,1-.446-.2l-2.307-2.563a.6.6,0,0,1,.892-.8l1.845,2.05,4.939-5.879a.6.6,0,1,1,.919.772l-5.382,6.408a.6.6,0,0,1-.449.214Z"
            transform="translate(-155.555 -170.915)"
            fill="#333"
          />
        </g>
        <rect
          width="55.4"
          height="55.4"
          transform="translate(0 0.6)"
          fill="none"
        />
      </g>
    </svg>
  );
};

export default OwnershipChangeIcon;
