import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import LogoGasesTwoLine from '../../../../Components/CustomIcons/LogoGasesTwoLine';
const logoFlame = require('../../../../Assets/images/gases_logo_flame.png');

const LogoHeaderGDC = props => {
  // * OTHER HOOKS
  const classes = useStyles();

  // * FUNCTIONS
  const screen = props.width;
  const renderLogo = () => {
    if (screen === 'xs' || screen === 'xm' || screen === 'sm') {
      return (
        <img src={logoFlame} alt={'flame'} className={classes.imageSmall} />
      );
    }

    return (
      <Fragment>
        <LogoGasesTwoLine className={classes.logo} />
      </Fragment>
    );
  };

  return <a href={'/'}>{renderLogo()}</a>;
};

const useStyles = makeStyles(theme => ({
  root: {
    width: 'auto',
    justifyContent: 'flex-start'
  },
  imageSmall: {
    cursor: 'pointer',
    width: 40
  },
  logo: {
    cursor: 'pointer',
    width: '128.3px',
    height: 56
  },
  biglogo: {
    cursor: 'pointer',
    width: '128.3px',
    height: 56
  },
  imageText: {
    marginTop: theme.spacing(0.5),
    height: '42px',
    width: '180px'
  },
  imageFlame: {
    height: '50px',
    width: '40px'
  }
}));

export default withWidth()(LogoHeaderGDC);
