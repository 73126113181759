const getCompanyCompactSlogan = slogan => {
  const words = slogan.split(' ');
  const capitalizedWords = words.map(
    word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

  return capitalizedWords.join('');
};

export { getCompanyCompactSlogan };
