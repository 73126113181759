import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import NewPaymentCardGDC from './NewPaymentCard.GDC';
import NewPaymentCardGDG from './NewPaymentCard.GDG';

const NewPaymentCard = props => {
  switch (portal) {
    case Portal.Gascaribe:
    case Portal.TripleA:
      return <NewPaymentCardGDC {...props} />;
    case Portal.Gasguajira:
      return <NewPaymentCardGDG {...props} />;
    case Portal.Efigas:
      return <NewPaymentCardGDC {...props} />;
    default:
      return <></>;
  }
};

export default NewPaymentCard;
