import React, { useContext, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import BackIcon from '../../Components/CustomIcons/BackIcon';

import BaseButton from '../../Components/Buttons/BaseButton';
import FlatButton from '../../Components/Buttons/FlatButton';

import { FooterDispatchContext } from '../../Contexts/FooterContext';

const FooterStepper = props => {
  // * MAP PROPS
  const {
    id,
    currentStep = {},
    disabledNext = false,
    disabledBack = false
  } = props;

  // * CONTEXTS
  const setFooterVisible = useContext(FooterDispatchContext);

  // All views with a Stepper Footer can't have another footer in the background
  useLayoutEffect(() => {
    setFooterVisible(false);
    return () => {
      setFooterVisible(true);
    };
  }, [setFooterVisible]);

  const classes = useStyles();

  return (
    <Toolbar id={id} className={classes.footerRoot} disableGutters>
      <Container maxWidth="md" className={classes.container}>
        <Grid container>
          <Grid item xs={6} className={classes.leftItem}>
            {!disabledBack && currentStep.onBack && (
              <FlatButton
                onClick={currentStep.onBack}
                disabled={disabledBack}
                color="primary"
                className={classes.buttonBack}
              >
                <BackIcon size={26} className={classes.backIcon} />
                Regresar
              </FlatButton>
            )}
          </Grid>
          <Grid item xs={6} className={classes.rightItem}>
            <BaseButton
              id="nextStep"
              onClick={currentStep.action.onCall}
              disabled={disabledNext}
              color="primary"
              variant="contained"
            >
              {currentStep.action.text || '{Siguiente}'}
            </BaseButton>
          </Grid>
        </Grid>
      </Container>
    </Toolbar>
  );
};

const useStyles = makeStyles(theme => ({
  footerRoot: {
    zIndex: 10,
    position: 'fixed',
    display: 'flex',
    textAlign: 'right',
    height: theme.custom.footerHeight.stepper,
    width: '100%',
    bottom: 0,
    boxShadow: '0px 2px 10px -1px rgba(0,0,0,0.2)',
    backgroundColor: theme.palette.background.dark
  },
  container: {
    textAlign: 'right',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  leftItem: {
    textAlign: 'left'
  },
  rightItem: {
    textAlign: 'right'
  },
  buttonBack: {
    paddingTop: theme.spacing(1.875),
    paddingBottom: theme.spacing(1.875)
  },
  backIcon: {
    fill: 'currentColor',
    marginRight: theme.spacing(1)
  }
}));

export default FooterStepper;
