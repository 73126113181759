import axios from 'axios';
import { config } from '../../Configs';

export const GenerateCampaignGameTokenAPI = async (
  token,
  contractId,
  campaign
) => {
  try {
    const response = await axios.post(
      `${config.api.url}/points/generate`,
      { contractId, campaign },
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};
