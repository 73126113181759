import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { Router, Route } from 'react-router-dom';
import { history } from './Routes/history';

import { theme } from './Configs/Theme';

import Header from './Views/Layout/Header/Header';
import Footer from './Views/Layout/Footer/Footer';
import AlertFullView from './Components/Alerts/AlertFullView';
import MaintenancePageIcon from './Components/CustomIcons/MaintenancePageIcon';

function MaintenanceApp({ message }) {
  const classes = useStyles();

  useEffect(() => {
    return history.listen(() => window.location.reload(true));
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Router history={history}>
        <Route
          path="/"
          component={() => {
            return (
              <div className={classes.container}>
                <Header />
                <div className={classes.appContainer}>
                  <AlertFullView icon={MaintenancePageIcon} title={message} />
                </div>
                <Footer />
              </div>
            );
          }}
        />
      </Router>
    </MuiThemeProvider>
  );
}

const useStyles = makeStyles({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  appContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  }
});

export default MaintenanceApp;
