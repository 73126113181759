import * as React from 'react';
const InvoicePartialPaymentIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={90}
    height={90}
    fill="none"
    viewBox="0 0 96 96"
    {...props}
  >
    <g clipPath="url(#InvoicePartialPayment_svg__a)">
      <path
        fill="url(#InvoicePartialPayment_svg__b)"
        d="M0 4a4 4 0 0 1 4-4h88a4 4 0 0 1 4 4v88a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z"
      />
      <path
        fill="#fff"
        d="M72.975 52.06a5.11 5.11 0 0 0-4.46-.883L56.188 54A5.94 5.94 0 0 0 57 51a6 6 0 0 0-6-6H38.485a6.95 6.95 0 0 0-4.95 2.05L27.585 53H20a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h26q.127 0 .25-.03l16-4q.077-.02.15-.05l9.705-4.135.055-.025a5.15 5.15 0 0 0 .823-8.7zM19 66V56a1 1 0 0 1 1-1h7v12h-7a1 1 0 0 1-1-1m52.283-7.042L61.68 63.05 45.877 67H29V54.415l5.95-5.95A4.96 4.96 0 0 1 38.485 47H51a4 4 0 1 1 0 8h-7a1 1 0 0 0 0 2h8a1 1 0 0 0 .225-.025l16.75-3.852h.04a3.15 3.15 0 0 1 2.25 5.845zM57 39c.738.002 1.472-.1 2.182-.3a8 8 0 1 0 5.638-9.395A8 8 0 1 0 57 39m16-2a6 6 0 1 1-12 0 6 6 0 0 1 12 0M57 25a6 6 0 0 1 5.935 5.115A8 8 0 0 0 59 36.655a5.998 5.998 0 0 1-7.795-7.23A6 6 0 0 1 57 25"
      />
      <path
        fill="#FCC52C"
        d="m63.315-13 46.669 46.669L98.67 44.983 52-1.687z"
      />
      <path
        fill="#333"
        d="m75.56 6.316.912.912-4.95 4.95-.757-.757.623-6.08-3.352 3.351-.912-.912 4.95-4.95.756.757-.622 6.08zm3.751 6.1.884.883-3.776 3.776-.841-.841.48-.481c-.629.064-1.258-.198-1.746-.686-.969-.969-1.096-2.156.042-3.295l2.157-2.157.884.884-2.036 2.037c-.686.686-.686 1.336-.128 1.895.615.615 1.414.65 2.171-.106zm3.682 7.5c-.07.07-.184.17-.276.247L79.754 17.2c-.445.658-.34 1.471.319 2.129.424.424.89.622 1.435.615l-.07 1.018c-.743.064-1.486-.255-2.136-.905-1.266-1.266-1.273-2.9-.149-4.023 1.125-1.125 2.758-1.104 3.882.02 1.103 1.104 1.139 2.68-.042 3.862m-.665-3.154c-.573-.573-1.357-.622-1.994-.156l2.143 2.143c.474-.615.431-1.407-.149-1.987m6.041 4.711.849.849-5.396 2.157-.912-.913 2.157-5.395.92.92-1.627 3.973zm-.831 6.82c-1.16-1.16-1.202-2.814-.07-3.945 1.13-1.132 2.778-1.082 3.938.077 1.174 1.174 1.216 2.815.085 3.946s-2.78 1.096-3.953-.078m.757-.756c.643.643 1.584.664 2.305-.057s.7-1.662.056-2.305c-.636-.637-1.577-.658-2.298.063s-.7 1.662-.063 2.299"
      />
    </g>
    <defs>
      <linearGradient
        id="InvoicePartialPayment_svg__b"
        x1={48}
        x2={48}
        y1={0}
        y2={96}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A54CF3" />
        <stop offset={1} stopColor="#3C41DB" />
      </linearGradient>
      <clipPath id="InvoicePartialPayment_svg__a">
        <path
          fill="#fff"
          d="M0 4a4 4 0 0 1 4-4h88a4 4 0 0 1 4 4v88a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4z"
        />
      </clipPath>
    </defs>
  </svg>
);
export default InvoicePartialPaymentIcon;
