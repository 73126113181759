import { CARD_STATUS } from '../payment_enums';

export const getTotalAmount = payments => {
  const totalAmount = payments.reduce(
    (value, payment) => Number(value) + Number(payment.detail.couponValue),
    0
  );
  return totalAmount;
};

export const updateItem = (
  oldPayload,
  targetKey,
  filterKey,
  filterValue,
  targetStatus,
  newBody
) => {
  const filteredValues = oldPayload[targetKey].filter(
    item => String(item.detail[filterKey]) !== String(filterValue)
  );
  newBody.status = CARD_STATUS[targetStatus.toUpperCase()];
  return {
    ...oldPayload,
    loading: filteredValues,
    [targetStatus]: [...oldPayload[targetStatus], newBody]
  };
};

export const updatePayment = (oldPayments, transactionType, newPayload) => {
  switch (transactionType) {
    case 'coupon':
      return [
        ...oldPayments,
        {
          type: transactionType,
          status: CARD_STATUS.TOPAY,
          detail: {
            ...newPayload,
            couponId: newPayload.id,
            couponValue: newPayload.value
          }
        }
      ];

    case 'contract':
      return [
        ...oldPayments,
        {
          type: transactionType,
          status: CARD_STATUS.TOPAY,
          detail: newPayload
        }
      ];

    default:
      return oldPayments;
  }
};
