import * as React from 'react';
const DigitalInvoiceDisableIcon = ({ width = 35, height = 35, ...rest }) => (
  <svg width={width} height={height} viewBox="0 0 700 700" {...rest}>
    <g
      style={{
        stroke: '#333',
        strokeWidth: 0.00052156,
        strokeDasharray: 'none',
        strokeOpacity: 1
      }}
    >
      <path
        d="M36.789 15.931H13.612c-.696 0-1.393 1.125-1.393 1.945v30.272c0 .929 1.155.999 1.393.999H30.69c.161 0 .316-.06.43-.164l7.107-6.307a.562.562 0 0 0 .122-.165c.009-.017.014-.034.02-.052a.49.49 0 0 0 .03-.144c0-.013.007-.104.007-.036V40.99h-.05a16.892 16.892 0 0 1-.133 0c-.02-.001.171-.002.15-.004h-.258l-.072.003-.069.001H37.757h.3-.169a.481.481 0 0 0-.075.004.07.07 0 0 1-.01-.001v1.284H31.91c-1.01 0-1.368.195-1.368 1.124v5.045H14.172c-.337 0-1.17-.25-1.17-.56V17.612c0-.31.567-.98.904-.98h22.883c.336 0 1.21.67 1.21.98v7.112h.015l.017-.001H38.07a.517.517 0 0 1 .038.002h.134l.015.002h.142-.582H37.985l.025.002h.05l.035.001h.109l.022-.001a.83.83 0 0 0 .023 0l.028-.001H38.418l.02-.001h.069l.02-.001H38.569l.017-.001h.033v-7.113c0-.929-.82-1.682-1.83-1.682zm1.079 8.808h.116-.101zm.34.003h.011zM31.908 42.84h5.242l-5.929 5.137-.019-4.873c-.001-.31.37-.264.706-.264z"
        style={{
          opacity: 1,
          fill: '#333',
          fillOpacity: 1,
          stroke: '#333',
          strokeWidth: 0.00053168,
          strokeDasharray: 'none',
          strokeDashoffset: 0,
          strokeOpacity: 0.5
        }}
        transform="matrix(17.0422 0 0 17.71588 -99.963 -242.37)"
      />
      <path
        fill="#231f20"
        stroke="none"
        d="M19.711 27.143h-2.44c-.336 0-.61-.25-.61-.56 0-.748-1.22-.748-1.22 0 0 .929.82 1.681 1.83 1.681h.61v.561c0 .748 1.22.748 1.22 0v-.56h.61c1.01 0 1.83-.753 1.83-1.682V25.46c0-.928-.82-1.681-1.83-1.681h-2.44c-.336 0-.61-.251-.61-.56v-1.122c0-.31.274-.56.61-.56h2.44c.337 0 .61.25.61.56 0 .747 1.22.747 1.22 0 0-.929-.82-1.682-1.83-1.682h-.61v-.56c0-.748-1.22-.748-1.22 0v.56h-.61c-1.01 0-1.83.753-1.83 1.682v1.121c0 .929.82 1.682 1.83 1.682h2.44c.337 0 .61.25.61.56v1.122c0 .31-.273.56-.61.56zm4.88-5.342h9.758c.813 0 .813-.824 0-.824h-9.758c-.814 0-.814.824 0 .824zm0 4.522h9.758c.813 0 .813-.862 0-.862h-9.758c-.814 0-.814.862 0 .862zm-.116 4.522h6.05c.813 0 .992-.899.179-.899H24.59c-.814 0-.654.899-.116.899zm-8.423 4.447h11.62c.814 0 .903-.861.09-.861h-11.71c-.813 0-.813.861 0 .861zm11.576 4.448c.813 0 .723-.824-.09-.824H16.052c-.813 0-.813.824 0 .824z"
        style={{
          fill: '#333',
          fillOpacity: 1,
          stroke: '#333',
          strokeWidth: 0,
          strokeDasharray: 'none',
          strokeDashoffset: 0,
          strokeOpacity: 0.5
        }}
        transform="matrix(17.0422 0 0 17.71588 -99.963 -242.37)"
      />
    </g>
    <g
      style={{
        stroke: '#000',
        strokeWidth: 0.03931069,
        strokeDasharray: 'none',
        strokeOpacity: 1,
        fill: '#000',
        fillOpacity: 1,
        strokeDashoffset: 0
      }}
    >
      <path
        d="M574.613 673.858c-8.473 0-15.328-7.572-15.35-16.903l-.927-412.03c-.021-9.332 6.876-18.055 15.35-18.055 8.473 0 15.328 13.325 15.35 22.656l.927 407.429c.021 9.331-6.876 16.903-15.35 16.903zM1170.59 673.858c-8.474 0-15.35-7.572-15.35-16.903V226.516c0-9.33 6.876-16.903 15.35-16.903 8.473 0 15.35 7.572 15.35 16.903v430.439c0 9.331-6.877 16.903-15.35 16.903z"
        style={{
          stroke: '#333',
          strokeWidth: 0.03931069,
          strokeDasharray: 'none',
          strokeOpacity: 1,
          fill: '#333',
          fillOpacity: 1,
          strokeDashoffset: 0
        }}
        transform="matrix(.34023 0 0 .28786 274.357 251.167)"
      />
      <path
        d="M1160.55 239.284 908.335 16.673c-20.834-18.588-50.649-18.588-71.483 0l-252.199 222.65c-24.591 21.712-60.494 17.357-80.19-9.724-19.689-27.081-15.76-66.62 8.83-88.308l304.342-268.67c32.05-28.559 77.852-28.559 109.902 0l304.342 268.67c24.591 21.683 28.547 61.198 8.857 88.279-19.635 27.027-55.694 31.438-80.184 9.724zM836.852-100.915l-304.34 268.572c-11.347 10.034-13.17 28.282-4.06 40.777 9.112 12.495 25.682 14.503 37.028 4.47L817.643-9.747c32.05-28.592 77.88-28.592 109.903 0l252.164 222.65c11.366 10.003 27.936 7.995 37.027-4.5 9.084-12.495 7.26-30.742-4.058-40.746L908.338-101.03c-20.889-18.474-50.63-18.444-71.483.113zM833.06 673.858c-8.473 0-15.35-7.572-15.35-16.903V369.732H673.983v287.242c0 9.331-6.876 16.904-15.35 16.904-8.473 0-15.35-7.573-15.35-16.904l-.014-304.154c0-9.331 6.877-16.904 15.35-16.904H833.08c8.474 0 15.35 7.573 15.35 16.904v304.154c.006 9.309-6.87 16.881-15.37 16.881z"
        style={{
          stroke: '#333',
          strokeWidth: 0.03931069,
          strokeDasharray: 'none',
          strokeOpacity: 1,
          fill: '#333',
          fillOpacity: 1,
          strokeDashoffset: 0
        }}
        transform="matrix(.34023 0 0 .28786 274.357 251.167)"
      />
      <path
        d="M872.601 250.243c-44.026 0-79.703-39.289-79.703-87.773 0-48.485 35.677-87.773 79.703-87.773 44.027 0 79.704 39.288 79.704 87.773-.048 48.432-35.705 87.69-79.703 87.773zm0-141.656c-27.052 0-48.976 24.144-48.976 53.935 0 29.791 21.924 53.935 48.977 53.935 27.052 0 48.976-24.144 48.976-53.935v-.03c0-29.791-21.924-53.966-48.976-53.989zM714.455 541.814c-8.474 0-15.35-7.572-15.35-16.903v-40.045c0-9.331 6.876-16.903 15.35-16.903 8.473 0 15.35 7.572 15.35 16.903v40.045c.006 9.331-6.849 16.903-15.35 16.903zM1094.105 521.81H905.228c-8.474 0-15.35-7.572-15.35-16.903V352.824c0-9.331 6.876-16.904 15.35-16.904h188.877c8.473 0 15.35 7.573 15.35 16.904v152.052c0 9.362-6.876 16.934-15.35 16.934zm-173.55-33.845h158.193V369.699H920.575Z"
        style={{
          stroke: '#333',
          strokeWidth: 0.03931069,
          strokeDasharray: 'none',
          strokeOpacity: 1,
          fill: '#333',
          fillOpacity: 1,
          strokeDashoffset: 0
        }}
        transform="matrix(.34023 0 0 .28786 274.357 251.167)"
      />
      <path
        d="M999.666 521.81c-8.473 0-15.35-7.572-15.35-16.903V352.824c0-9.331 6.877-16.904 15.35-16.904 8.474 0 15.35 7.573 15.35 16.904v152.052c0 9.362-6.876 16.934-15.35 16.934z"
        style={{
          stroke: '#333',
          strokeWidth: 0.03931069,
          strokeDasharray: 'none',
          strokeOpacity: 1,
          fill: '#333',
          fillOpacity: 1,
          strokeDashoffset: 0
        }}
        transform="matrix(.34023 0 0 .28786 274.357 251.167)"
      />
      <path
        d="M1094.105 445.777H905.228c-8.474 0-15.35-7.572-15.35-16.904 0-9.331 6.876-16.904 15.35-16.904h188.877c8.473 0 15.35 7.573 15.35 16.904 0 9.332-6.876 16.904-15.35 16.904zM1221.38 751.825c-8.473 0-15.35-7.573-15.35-16.904v-61.063H539.187v61.063c0 9.331-6.876 16.904-15.35 16.904-8.473 0-15.35-7.573-15.35-16.904v-77.966c0-9.332 6.877-16.904 15.35-16.904h697.558c8.474 0 15.35 7.572 15.35 16.904v77.966c0 9.331-6.876 16.904-15.377 16.904zM1136.735 78.58c-8.474 0-15.35-7.572-15.35-16.904V-79.067c-.048-19.759-14.589-35.74-32.509-35.8-17.941.052-32.454 16.065-32.508 35.8l-1.856 49.307c-.351 9.32-6.876 16.903-15.35 16.903-8.473 0-15.7-7.582-15.35-16.903l1.856-49.307c1.446-38.41 28.321-69.639 63.236-69.639 34.915 0 63.236 31.189 63.236 69.639V61.676c-.048 9.332-6.904 16.904-15.404 16.904z"
        style={{
          stroke: '#333',
          strokeWidth: 0.03931069,
          strokeDasharray: 'none',
          strokeOpacity: 1,
          fill: '#333',
          fillOpacity: 1,
          strokeDashoffset: 0
        }}
        transform="matrix(.34023 0 0 .28786 274.357 251.167)"
      />
      <path
        d="M1218.918 772.436H520.684c-7.754 0-14.046-6.93-14.046-15.468 0-8.54 6.292-15.469 14.046-15.469h698.25c7.754 0 14.047 6.93 14.047 15.469-.02 8.539-6.312 15.468-14.066 15.468z"
        style={{
          stroke: '#333',
          strokeWidth: 0.03931069,
          strokeDasharray: 'none',
          strokeOpacity: 1,
          fill: '#333',
          fillOpacity: 1,
          strokeDashoffset: 0
        }}
        transform="matrix(.34023 0 0 .28786 274.357 251.167)"
      />
    </g>
  </svg>
);
export default DigitalInvoiceDisableIcon;
