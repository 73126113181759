import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState
} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid, Grow, Typography } from '@material-ui/core';
import * as yup from 'yup';
import moment from 'moment';

import RibbonIcon from '../CustomIcons/RibbonIcon';
import BaseSwitch from '../Switches/BaseSwitch';
import TextInput from '../Inputs/TextInput';
import InformationCircle from '../CustomIcons/InformationCircle';
import FlatButton from '../Buttons/FlatButton';
import BackIcon from '../../Components/CustomIcons/BackIcon';
import InsuranceDetailDialog from '../Dialogs/InsuranceDetailDialog';

import { numberWithDots } from '../../Utils/Format/MoneyFormat';
import ControlledSelectInput from '../Controlled/ControlledSelect';
import { useForm } from 'react-hook-form';

const insuranceDataSchema = yup.object({
  dateOfBirth: yup
    .date()
    .max(new Date(), 'Fecha no valida')
    .typeError('La fecha  debe ser válida')
    .required('Elija una fecha valida')
    .test('minAge', 'Debe ser mayor de edad', val => {
      return val && moment(val).isSameOrBefore(moment().subtract(18, 'y'));
    })
    .test('maxAge', 'Debe ser menor de 65 años', val => {
      return val && moment(val).isSameOrAfter(moment().subtract(65, 'y'));
    }),
  expeditionDate: yup
    .date()
    .max(new Date(), 'Fecha no valida')
    .typeError('La fecha  debe ser válida')
    .required('Elija una fecha valida'),
  gender: yup.string().required('Elija un sexo')
});

const defaultValues = {
  gender: '',
  dateOfBirth: '',
  expeditionDate: ''
};

const InsuranceAcquisitionCard = props => {
  const {
    setDisabledNext,
    price,
    setInsuranceInfo,
    setShowPlanInfo,
    showPlanInfo
  } = props;

  const classes = useStyles();

  // * STATES

  const [openInsuranceDetail, setOpenInsuranceDetail] = useState(false);

  const { register, watch, errors, control, setValue } = useForm({
    validationSchema: insuranceDataSchema,
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues
  });

  const { dateOfBirth, expeditionDate, gender } = watch([
    'dateOfBirth',
    'expeditionDate',
    'gender'
  ]);

  const scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  useLayoutEffect(() => {
    if (showPlanInfo) {
      scrollToBottom();
    }
  }, [showPlanInfo]);

  const handlePlanInfo = useCallback(() => {
    setShowPlanInfo(prev => !prev);
  }, [setShowPlanInfo]);

  const SELECT_OPTIONS = [
    {
      label: 'Masculino',
      value: 'M'
    },
    { label: 'Femenino', value: 'F' }
  ];

  useEffect(() => {
    if (showPlanInfo && price) {
      if (gender && dateOfBirth && expeditionDate) {
        setInsuranceInfo({
          gender,
          dateOfBirth,
          expeditionDate,
          price
        });
        return;
      }
    }
  }, [
    showPlanInfo,
    price,
    dateOfBirth,
    expeditionDate,
    gender,
    setInsuranceInfo
  ]);

  useEffect(() => {
    if (!showPlanInfo) {
      setValue(Object.values(defaultValues));
      setDisabledNext(false);
      return;
    }

    if (!gender || !dateOfBirth || !expeditionDate) {
      setDisabledNext(true);
      return;
    }
    try {
      insuranceDataSchema.validateSync(
        { gender, dateOfBirth, expeditionDate },
        { abortEarly: false }
      );
      setDisabledNext(false);
    } catch (err) {
      setDisabledNext(false);
    }
  }, [
    showPlanInfo,
    price,
    dateOfBirth,
    expeditionDate,
    gender,
    setDisabledNext,
    setValue
  ]);

  const handleOpenInsuranceDetail = useCallback(() => {
    setOpenInsuranceDetail(open => !open);
  }, [setOpenInsuranceDetail]);

  return (
    <Grid className={classes.root}>
      <Grow in>
        <Grid className={classes.card}>
          <Grid className={classes.insuranceSection}>
            <Typography className={classes.sectionTitle}>
              Asegura tu vida aquí
            </Typography>
            <Grid className={classes.insuranceContent}>
              <Grid className={classes.insuranceLogo}>
                <RibbonIcon></RibbonIcon>
              </Grid>
              <Grid className={classes.insuranceActions}>
                <Grid className={classes.insuranceActivation}>
                  <Typography className={classes.data}>
                    Activa nuestro Plan Silver
                  </Typography>

                  <BaseSwitch
                    disabled={!price}
                    checked={showPlanInfo}
                    handleToggle={handlePlanInfo}
                    controlLabelClass={classes.switch}
                    thumClass={classes.thum}
                  />
                </Grid>
                <FlatButton
                  disabled={!price}
                  className={classes.viewMoreText}
                  onClick={handleOpenInsuranceDetail}
                >
                  Ver detalle
                  <BackIcon size={16} className={classes.viewMoreIcon} />
                </FlatButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.valueSection}>
            <Typography className={classes.label}>Valor total (COP)</Typography>
            <Typography className={classes.data}>
              {price ? numberWithDots(price) : '-'} / Mensual
            </Typography>
            <Grid className={classes.valueInfo}>
              <Typography className={classes.label}>
                En caso de ser aprobado, este valor se incluirá en tu factura a
                partir del siguiente mes
              </Typography>
              <InformationCircle className={classes.infoIcon} />
            </Grid>
          </Grid>
        </Grid>
      </Grow>
      {openInsuranceDetail && (
        <InsuranceDetailDialog
          title="Seguro de vida"
          open={true}
          onClose={handleOpenInsuranceDetail}
          adquire={handlePlanInfo}
          adquired={showPlanInfo}
          planPrice={price}
        />
      )}
      {showPlanInfo && (
        <form className={classes.planInfo}>
          <TextInput
            label="Fecha de nacimiento"
            name="dateOfBirth"
            type="date"
            inputRef={register}
            className={
              errors && Boolean(errors.dateOfBirth)
                ? classes.inputError
                : classes.input
            }
            fullWidth={true}
            InputLabelProps={{ shrink: true }}
            error={Boolean(errors.dateOfBirth)}
            helperText={errors.dateOfBirth && errors.dateOfBirth.message}
          />
          <TextInput
            label="Fecha de expedición identificación"
            name="expeditionDate"
            type="date"
            inputRef={register}
            className={
              errors && Boolean(errors.expeditionDate)
                ? classes.inputError
                : classes.input
            }
            fullWidth={true}
            InputLabelProps={{ shrink: true }}
            error={Boolean(errors.expeditionDate)}
            helperText={errors.expeditionDate && errors.expeditionDate.message}
          />
          <ControlledSelectInput
            name="gender"
            control={control}
            error={Boolean(errors.gender)}
            helperText={errors.gender}
            className={
              errors && Boolean(errors.gender)
                ? classes.inputError
                : classes.input
            }
            label={'Género'}
            fullWidth
            options={SELECT_OPTIONS}
          />
        </form>
      )}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginBottom: theme.spacing(12.5)
    }
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: theme.custom.borderRadius,
    backgroundColor: theme.palette.background.accordionLighter,
    border: `1px solid ${theme.palette.background.border}`,
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'column',
      gap: '10px',
      padding: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      '&:last-child': {
        marginBottom: theme.spacing(6)
      }
    }
  },
  insuranceSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  insuranceContent: {
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    rowGap: '8px',
    columnGap: '12px',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      columnGap: '8px',
      alignSelf: 'flex-start'
    }
  },
  backIcon: {
    fill: 'currentColor',
    marginRight: theme.spacing(1)
  },
  insuranceActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  insuranceActivation: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      gap: '8px'
    }
  },
  switch: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      margin: '0px'
    }
  },
  thum: {
    width: '25px',
    height: '25px',
    marginTop: '-3px',
    border: '1px #747b87 solid'
  },
  viewMoreText: {
    fontWeight: 600,
    gap: '8px',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 12
    }
  },
  viewMoreIcon: {
    height: 16,
    width: 16,
    transform: 'scaleX(-1)'
  },
  valueSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '8px',
    justifyContent: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      textAlign: 'right'
    }
  },
  valueInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      justifyContent: 'flex-start',
      textAlign: 'left'
    }
  },
  infoIcon: {
    width: '16px',
    height: '16px',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      display: 'none'
    }
  },
  insuranceLogo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '18px',
    backgroundColor: theme.palette.secondary.disabled,
    padding: theme.spacing(1)
  },
  planInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 0, 0),
    gap: '32px',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'column',
      gap: '8px',
      '&:last-child': {
        marginBottom: theme.spacing(5)
      }
    }
  },
  label: {
    fontSize: 10,
    fontWeight: 'bold',
    color: theme.palette.text.greyDark
  },
  data: {
    fontSize: 16,
    fontWeight: 600,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 12
    }
  },
  total: {
    fontSize: 18,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 16
    }
  },
  button: {
    fontSize: 13,
    textTransform: 'capitalize',
    marginRight: theme.spacing(1)
  },
  margin: {
    marginBottom: theme.spacing(3)
  },
  input: {
    marginBottom: 17
  },
  inputError: {
    marginBottom: 0
  },
  sectionTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.typography.color.default,
    marginLeft: '48px',
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
      marginLeft: '54px'
    }
  }
}));

export default InsuranceAcquisitionCard;
