import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import StarIcon from '@material-ui/icons/Star';

const TimelineElement = props => {
  const {
    title,
    subtitle,
    icon: Icon = StarIcon,
    className,
    color = 'rgb(33, 150, 243)',
    contentStyle,
    contentArrowStyle,
    iconStyle,
    noBorder,
    children,
    theme,
    ...rest
  } = props;

  //* HOOKS
  const classes = useStyles();
  const containerStyle = {
    background: '#fff',
    color: '#333333',
    border: `solid 1px ${theme.palette.color.paleGrey}`,
    borderTop: `3px solid ${color}`,
    boxShadow: `0 2px 6px 0 ${theme.palette.background.transparentLighter}`,
    ...contentStyle
  };

  const arrowStyle = {
    display: 'none',
    ...contentArrowStyle
  };

  const iconContentStyle = {
    background: `${color}`,
    color: '#fff',
    boxShadow: `0 0 6px 3px ${theme.palette.color.paleGrey}`,
    ...iconStyle
  };

  //* RENDER
  if (noBorder) {
    return (
      <VerticalTimelineElement
        className={clsx([classes.timelineElement, className])}
        contentStyle={{
          border: 'none',
          boxShadow: 'none',
          padding: 0,
          ...contentStyle
        }}
        contentArrowStyle={arrowStyle}
        iconStyle={iconContentStyle}
        icon={<Icon />}
        {...rest}
      >
        {children}
        {!children && (
          <Grid container direction="column">
            <div className={classes.noBorderTitle}>{title || 'Título'}</div>
            <div className={classes.noBorderSubtitle}>
              {subtitle || 'Subtítulo'}
            </div>
          </Grid>
        )}
      </VerticalTimelineElement>
    );
  }
  return (
    <VerticalTimelineElement
      className={clsx([classes.timelineElement, className])}
      contentStyle={containerStyle}
      contentArrowStyle={arrowStyle}
      iconStyle={iconContentStyle}
      date="2019"
      icon={<Icon />}
      {...rest}
    >
      {title && (
        <h4
          className={clsx([
            'vertical-timeline-element-title',
            classes.timelineTitle
          ])}
        >
          {title}
        </h4>
      )}
      {subtitle && (
        <h5
          className={clsx([
            'vertical-timeline-element-subtitle',
            classes.timelineSubtitle
          ])}
        >
          {subtitle}
        </h5>
      )}
      {children}
    </VerticalTimelineElement>
  );
};

const useStyles = makeStyles(theme => ({
  timelineElement: {
    margin: [[theme.spacing(3), 0]],
    [theme.breakpoints.up('sm')]: {
      margin: [[theme.spacing(4), 0]]
    }
  },
  timelineTitle: {
    fontSize: '15px !important',
    fontWeight: 600,
    color: theme.typography.color.primary
  },
  timelineSubtitle: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.typography.color.second,
    textTransform: 'capitalize'
  },
  noBorderTitle: {
    fontWeight: 600
  },
  noBorderSubtitle: {
    fontSize: 13,
    fontWeight: 600,
    color: theme.typography.color.second,
    textTransform: 'capitalize'
  }
}));

export default withTheme(TimelineElement);
