import React, { useState } from 'react';
import clsx from 'clsx';
import uuid from 'uuid/v4';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #E7E7E7',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.07)'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={props.anchorOrigin}
    transformOrigin={props.transformOrigin}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    minWidth: 180,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.primary.lighter
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.lighter
    }
  },
  selected: {
    backgroundColor: `${theme.palette.primary.lighter} !important`,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.lighter} !important`
    },
    '&:focus': {
      backgroundColor: `${theme.palette.primary.lighter} !important`
    }
  }
}))(MenuItem);

const MenuButton = props => {
  const {
    selectable = false,
    initialSelected = null,
    color = 'primary',
    variant = 'contained',
    loading,
    disabled,
    className,
    onClick = () => null,
    options = [],
    otherOptions = null,
    transformOrigin = {
      vertical: 'top',
      horizontal: 'right'
    },
    anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'right'
    },
    ...rest
  } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = useState(initialSelected);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    onClick();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <ButtonBase
        id={'MenuButton_button_headerButton'}
        aria-controls="customized-menu"
        aria-haspopup="true"
        disableRipple
        disabled={loading || disabled}
        variant={variant}
        color={color}
        className={clsx(classes.buttonBase, className)}
        onClick={handleClick}
        {...rest}
      >
        {props.children || props.text || '{Button}'}
      </ButtonBase>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={transformOrigin}
        anchorOrigin={anchorOrigin}
      >
        {!selectable &&
          options.map((obj, idx) => (
            <MenuItem
              id={`MenuButton_not_selectable_${idx}`}
              key={`${uuid()}`}
              onClick={() => {
                obj.onClick();
                handleClose();
              }}
              TouchRippleProps={{ classes: { child: classes.rippleStyle } }}
              className={classes.menuItemNoSelect}
            >
              {obj.label || `Item ${idx}`}
            </MenuItem>
          ))}
        {selectable &&
          options.map((obj, idx) => (
            <StyledMenuItem
              id={`MenuButton_selectable_${idx}`}
              key={`${uuid()}`}
              TouchRippleProps={{ classes: { child: classes.rippleStyle } }}
              name={obj.id}
              value={obj.id}
              onClick={() => {
                setSelected(obj);
                obj.onClick();
                handleClose();
              }}
              selected={selected.id === obj.id}
            >
              <div className={classes.menuItemSelectIcon}>
                {obj.image && obj.image({ size: 28 })}
              </div>
              <div className={classes.itemTextPrimary}>
                {obj.label || `Item ${idx}`}
                <br />
                <div className={classes.itemTextSecondary}>{`# ${obj.id}`}</div>
              </div>
            </StyledMenuItem>
          ))}
        {otherOptions &&
          otherOptions.map((obj, idx) => (
            <MenuItem
              id={`MenuButton_otherOptions_${idx}`}
              key={`${uuid()}`}
              onClick={() => {
                obj.onClick();
                handleClose();
              }}
              TouchRippleProps={{ classes: { child: classes.rippleStyle } }}
              className={classes.menuItemNoSelect}
            >
              {obj.image && (
                <div className={classes.menuItemSelectIcon}>
                  {obj.image({ size: 20, className: classes.imageOther })}
                </div>
              )}
              <Typography className={classes.itemTextPrimary}>
                {obj.label || `Item ${idx}`}
              </Typography>
            </MenuItem>
          ))}
      </StyledMenu>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  buttonBase: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    lineHeight: 1.29,
    color: theme.palette.text.greyDark,
    '&:hover': {
      color: theme.palette.color.primary,
      fill: theme.palette.color.primary
    }
  },
  menuListNoSelect: {
    paddingTop: 0,
    paddinBottom: 0
  },
  menuItemNoSelect: {
    fontSize: 14,
    minWidth: 120,
    '&:hover': {
      backgroundColor: theme.palette.primary.lighter
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.lighter
    }
  },
  menuItemText: {
    fontSize: 14
  },
  menuItemSelectIcon: {
    display: 'flex',
    width: 40,
    justifyContent: 'flex-start',
    alignItems: 'center'
    // paddingRight: theme.spacing(2)
  },
  rippleStyle: {
    backgroundColor: theme.palette.primary.main
  },
  imageOther: {
    marginLeft: theme.spacing(0.5)
  },
  itemTextPrimary: {
    fontSize: 14,
    lineHeight: 1.4
  },
  itemTextSecondary: {
    fontSize: 12,
    lineHeight: 1.2,
    color: theme.palette.text.grey
    // marginLeft: theme.spacing()
  }
}));

export default MenuButton;
