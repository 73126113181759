import React, { Fragment } from 'react';
import MobileStepper from './MobileStepper';
import WebStepper from './WebStepper';

const Stepper = props => {
  const {
    steps,
    stepperLabel,
    currentStep,
    stepperBarClass,
    stepperTextClass
  } = props;

  return (
    <Fragment>
      <WebStepper steps={steps} currentStep={currentStep} />
      <MobileStepper
        steps={steps}
        stepperLabel={stepperLabel}
        currentStep={currentStep}
        stepperBarClass={stepperBarClass}
        stepperTextClass={stepperTextClass}
      />
    </Fragment>
  );
};

export default Stepper;
