import React, { useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import LogoHeader from './LogoHeader/LogoHeader';
import AuthHeader from './AuthHeader/AuthHeader';
import NoAuthHeader from './NoAuthHeader';
import { UserContext } from '../../../Contexts/UserContext';
import { CircularMenuContext } from '../../../Contexts/CircularMenuContext';

const Header = () => {
  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const menu = useContext(CircularMenuContext);

  // * OTHER HOOKS
  const classes = useStyles();

  // * FUNCTIONS

  return (
    <AppBar
      position="sticky"
      color={'inherit'}
      className={clsx({
        [classes.appBar]: !menu.backdrop,
        [classes.appBarCovered]: menu.backdrop
      })}
    >
      <Toolbar classes={{ root: classes.rootToolbar }}>
        <LogoHeader />
        {currentUser && <AuthHeader />}
        {!currentUser && <NoAuthHeader />}
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles(() => ({
  rootToolbar: {
    minHeight: 64
  },
  appBar: {
    boxShadow: '0 2px 2px -1px rgba(0,0,0,0.2)',
    zIndex: '1150 !important'
  },
  appBarCovered: {
    boxShadow: '0 2px 2px -1px rgba(0,0,0,0.2)',
    zIndex: '1100 !important'
  }
}));

export default Header;
