import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SimpleDivider from '../../../Components/Dividers/SimpleDivider';
import { capitalizeFirstLetter } from '../../../Utils/Format/Names';
import { floatWithDots } from '../../../Utils/Format/MoneyFormat';

const ExtraQuotaDetail = props => {
  const { extraQuota } = props;

  // * OTHER HOOKS
  const classes = useStyles();

  return (
    <div>
      <SimpleDivider withoutMargin classes={classes.divider} />
      <Typography className={classes.extraQuotaValueTitle}>
        Extra cupos disponibles
      </Typography>
      <div className={classes.container}>
        {extraQuota.map(item => (
          <div key={item.id} className={classes.extraQuotaItemDivider}>
            <Grid container alignItems="center">
              <Grid item xs={12} sm={8}>
                <Typography className={classes.extraQuotaItemTitle}>
                  {capitalizeFirstLetter(item.name)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography className={classes.extraQuotaValue}>
                  {floatWithDots(item.value)} COP
                </Typography>
              </Grid>
            </Grid>
          </div>
        ))}
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    maxHeight: 200,
    overflowY: 'auto'
  },
  divider: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  extraQuotaValueTitle: {
    fontSize: '1em',
    fontWeight: 500,
    color: theme.palette.text.greyDark,
    marginBottom: theme.spacing(1)
  },
  extraQuotaValue: {
    fontSize: '1em',
    fontWeight: 500,
    textAlign: 'end',
    color: theme.palette.text.greyDark,
    [theme.breakpoints.down('xs')]: {
      marginRight: 6,
      marginTop: theme.spacing(0.6)
    }
  },
  extraQuotaItemTitle: {
    fontSize: '0.9em',
    fontWeight: 400,
    color: theme.palette.text.grey
  },
  extraQuotaItemDivider: {
    marginTop: theme.spacing(0.6),
    marginBottom: theme.spacing(0.6)
  }
}));

export default ExtraQuotaDetail;
