import { useState, useEffect, useCallback } from 'react';

const DEFAULT_SECONDS = 120;

const useCountdown = (countdownSeconds = DEFAULT_SECONDS) => {
  const [countdown, setCountdown] = useState(countdownSeconds);
  const [currentInterval, setCurrentInterval] = useState(null);
  const [timeFormatCountdown, setTimeFormatCountdown] = useState('0:00');
  const [countdownFinished, setCountdownFinished] = useState(false);

  useEffect(() => {
    return () => {
      clearInterval(currentInterval);
    };
  }, [currentInterval]);

  useEffect(() => {
    if (countdown <= 0) {
      setCountdownFinished(true);
    } else {
      setCountdownFinished(false);
    }
  }, [countdown]);

  useEffect(() => {
    if (countdown <= 0) {
      clearInterval(currentInterval);
      setCurrentInterval(null);
    }
  }, [countdown, currentInterval]);

  useEffect(() => {
    const minutes = Math.floor(countdown / 60);
    let seconds = countdown - minutes * 60;
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    setTimeFormatCountdown(`${minutes}:${seconds}`);

    if (`${minutes}:${seconds}` === '0:00') {
      setTimeFormatCountdown('Reenviar correo');
    }
  }, [countdown]);

  const startCountdown = useCallback(
    (resetSeconds = countdownSeconds) => {
      setCountdown(resetSeconds);
      const backward = c => {
        return c >= 1 ? c - 1 : c;
      };
      const interval = setInterval(() => {
        setCountdown(backward);
      }, 1000);
      setCurrentInterval(interval);
    },
    [countdownSeconds]
  );

  return { startCountdown, countdown, timeFormatCountdown, countdownFinished };
};

export default useCountdown;
