import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useCallback
} from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import SweetAlert from '../../../Components/Alerts/SweetAlert';
import TextInput from '../../../Components/Inputs/TextInput';
import SimpleDivider from '../../../Components/Dividers/SimpleDivider';
import ControlledYesNoButton from '../../../Components/Controlled/ControlledYesNoButton';
import ControlledSelectInput from '../../../Components/Controlled/ControlledSelect';
import ControlledCheckbox from '../../../Components/Controlled/ControlledCheckbox';
import BaseDialog from '../../../Components/Dialogs/BaseDialog';
import BaseButton from '../../../Components/Buttons/BaseButton';
import FlatButton from '../../../Components/Buttons/FlatButton';

import { UserContext } from '../../../Contexts/UserContext';
import {
  SetCurrentStepIndexContext,
  StepperDataContext,
  StepperDataDispatchContext,
  CurrentStepIndexContext
} from '../../../Contexts/StepperContext';

import { IDENTIFICATION_OPTIONS, EMAIL_RESPONSE } from '../Pqr_enums';
import { ROUTE_NAMES } from '../../../Routes/Routes';
import { TERMS, TERMS_ROUTES } from '../../Information/Information/Information';
import { fullName } from '../../../Utils/Format/Names';
import { useForm, ErrorMessage } from 'react-hook-form';
import * as yup from 'yup';
import { PrivacyPoliciesLink } from '../../../Configs/Links';

const schema = yup.object({
  authorizeEmailNotification: yup
    .string()
    .oneOf(['0', '1'])
    .required(),
  notificationAddress: yup.mixed().when('authorizeEmailNotification', {
    is: '0',
    then: yup
      .string()
      .max(100, 'Máximo 100 carácteres')
      .required('Ingresa la dirección')
  }),
  notificationAddressState: yup.mixed().when('authorizeEmailNotification', {
    is: '0',
    then: yup
      .string()
      .max(40, 'Máximo 40 carácteres')
      .required('Ingresa el departamento')
  }),
  notificationAddressCity: yup.mixed().when('authorizeEmailNotification', {
    is: '0',
    then: yup
      .string()
      .max(40, 'Máximo 40 carácteres')
      .required('Ingresa el municipio')
  }),
  email: yup
    .string()
    .max(100, 'Máximo 100 carácteres')
    .email('El correo debe de ser válido')
    .lowercase()
    .required('Ingresa el correo'),
  validateEmail: yup
    .string()
    .lowercase()
    .oneOf([yup.ref('email')], 'El correo debe ser el mismo')
    .required('Ingresa el correo nuevamente'),
  fullName: yup
    .string()
    .max(150, 'Máximo 150 carácteres')
    .required('Ingresa el nombre completo'),
  identificationType: yup
    .string()
    .oneOf(IDENTIFICATION_OPTIONS.map(idType => idType.value)),
  identification: yup.mixed().when('identificationType', {
    is: 'PP',
    then: yup
      .string()
      .trim()
      .max(15, 'Máximo 15 carácteres en el pasaporte')
      .matches(/^[0-9a-zA-Z]+$/, 'Ingresa un número de pasaporte válido')
      .required('Ingresa el número de tu pasaporte'),
    otherwise: yup
      .number()
      .typeError('Ingresa un número de identificación válido')
      .positive('Ingresa un número de identificación válido')
      .test('integer', 'El número de identificación debe ser válido', val => {
        return val % 1 === 0;
      })
      .required('Ingresa tu número de identificación')
  }),
  phone: yup
    .number()
    .typeError('El número de teléfono debe ser válido')
    .positive('El número de teléfono debe ser válido')
    .test('integer', 'El número de teléfono debe ser válido', val => {
      return val % 1 === 0;
    })
    .test('length', 'El número de teléfono debe ser de 7 o 10 dígitos', val => {
      return val.toString().length === 7 || val.toString().length === 10;
    })
    .required('Ingresa el número de teléfono'),
  terms: yup.boolean().oneOf([true], 'Este campo es requerido.'),
  habeasData: yup.boolean().oneOf([true], 'Este campo es requerido.')
});

const ContactStep = props => {
  const {
    formId,
    setGoBack,
    setCurrentStep: setVisualStepperIndex,
    setNextButtonText
  } = props;

  const classes = useStyles();

  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('xs'));

  const currentStepIndex = useContext(CurrentStepIndexContext);
  const setCurrentStep = useContext(SetCurrentStepIndexContext);
  const setData = useContext(StepperDataDispatchContext);
  const stepperData = useContext(StepperDataContext);

  const currentUser = useContext(UserContext);

  const fullname = currentUser
    ? `${fullName(currentUser, 'firstName', 'lastName')}`
    : '';

  const [openDialog, setOpenDialog] = useState(false);

  const {
    register,
    watch,
    getValues,
    setValue,
    errors,
    control,
    handleSubmit
  } = useForm({
    validationSchema: schema,
    submitFocusError: true,
    defaultValues: {
      terms: false,
      habeasData: false,
      authorizeEmailNotification: EMAIL_RESPONSE.YES,
      email: currentUser ? currentUser.email : '',
      fullName: fullname,
      identificationType: 'CC',
      identification: currentUser ? currentUser.identification : '',
      phone: currentUser ? currentUser.phone : '',
      ...stepperData
    }
  });

  const emailNotification = Boolean(
    parseInt(watch('authorizeEmailNotification'), 10)
  );

  const onForward = values => {
    setData(data => ({ ...data, ...values }));
    setCurrentStep(step => step + 1);
  };

  const onBackward = useCallback(() => {
    setData(data => ({ ...data, ...getValues() }));
    setCurrentStep(currentStep => currentStep - 1);
  }, [getValues, setCurrentStep, setData]);

  const handleCopyPaste = evt => {
    evt.preventDefault();
  };

  useEffect(() => {
    setNextButtonText('Siguiente');
    setVisualStepperIndex(currentStepIndex);
    setGoBack({
      action: onBackward
    });
  }, [
    setGoBack,
    onBackward,
    setVisualStepperIndex,
    currentStepIndex,
    setNextButtonText
  ]);

  const handleCheckEmailNotification = choice => {
    switch (choice) {
      case EMAIL_RESPONSE.YES:
        setValue('authorizeEmailNotification', EMAIL_RESPONSE.YES);
        break;

      case EMAIL_RESPONSE.NO:
        setValue('authorizeEmailNotification', EMAIL_RESPONSE.NO);
        break;

      default:
        break;
    }
    return setOpenDialog(false);
  };

  const renderDialogActions = () => {
    return (
      <Fragment>
        <FlatButton
          className={classes.actionButton}
          onClick={() => handleCheckEmailNotification(EMAIL_RESPONSE.NO)}
        >
          No autorizo
        </FlatButton>
        <BaseButton
          className={classes.actionButton}
          onClick={() => handleCheckEmailNotification(EMAIL_RESPONSE.YES)}
        >
          Autorizo notificación electrónica
        </BaseButton>
      </Fragment>
    );
  };

  const renderDialogContent = useCallback(() => {
    return (
      <>
        <Typography paragraph className={classes.dialogText}>
          Autorizando el medio de respuesta por{' '}
          <span className={classes.textBold}>correo electrónico</span> tendrás
          los siguientes beneficios:
        </Typography>
        <Typography paragraph className={classes.dialogText}>
          <span className={classes.textBold}>• </span>Recibirás la respuesta a
          tu petición más rápido
        </Typography>
        <Typography paragraph className={classes.dialogText}>
          <span className={classes.textBold}>• </span>Evitarás el desplazamiento
          a nuestras oficinas para notificación personal
        </Typography>
        <Typography paragraph className={classes.dialogText}>
          <span className={classes.textBold}>• </span>Contribuirás al medio
          ambiente evitando el uso de papel
        </Typography>
      </>
    );
  }, [classes.dialogText, classes.textBold]);

  const onChangeHandler = ([_, value]) => {
    if (value === EMAIL_RESPONSE.NO) {
      setOpenDialog(true);
      return EMAIL_RESPONSE.YES;
    }
    setValue('authorizeEmailNotification', value, true);
    return value;
  };

  return (
    <div className={classes.container}>
      {openDialog && (
        <BaseDialog
          handleClose={() => setOpenDialog(false)}
          open={openDialog}
          title="¿Estás seguro?"
          actions={renderDialogActions}
          content={renderDialogContent}
          contentSize="small"
          actionsStyle={classes.baseDialogActions}
        />
      )}
      <Typography className={classes.title}>Información de contacto</Typography>
      <form id={formId} onSubmit={handleSubmit(onForward)}>
        <div className={classes.itemContainer}>
          <Typography className={classes.itemText}>
            ¿Autorizas que el medio de respuesta sea por correo electrónico?
          </Typography>
          <ControlledYesNoButton
            name="authorizeEmailNotification"
            control={control}
            onChange={onChangeHandler}
          />
        </div>
        <SimpleDivider />
        <Grid container>
          {!emailNotification && (
            <Fragment>
              <Grid item xs={12}>
                <SweetAlert
                  id="Pqr_Contact_alert"
                  type="info"
                  noIcon={isMobileSize}
                  message={
                    <Fragment>
                      Tu correo electrónico es necesario para enviarte el número
                      de radicado y toda la información registrada en la
                      solicitud
                    </Fragment>
                  }
                  classes={{
                    message: classes.sweetAlertText
                  }}
                />
              </Grid>
              <Grid item sm={4} xs={12} className={classes.inputContainerItem}>
                <TextInput
                  label="Dirección de notificación"
                  fullWidth
                  inputProps={{ maxLength: 100 }}
                  inputRef={register}
                  name="notificationAddress"
                  helperText={
                    errors.notificationAddress &&
                    errors.notificationAddress.message
                  }
                  error={Boolean(errors.notificationAddress)}
                />
              </Grid>
              <Grid item sm={4} xs={12} className={classes.inputContainerItem}>
                <TextInput
                  label="Departamento de notificación"
                  fullWidth
                  inputProps={{ maxLength: 40 }}
                  inputRef={register}
                  name="notificationAddressState"
                  helperText={
                    errors.notificationAddressState &&
                    errors.notificationAddressState.message
                  }
                  error={Boolean(errors.notificationAddressState)}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <TextInput
                  label="Municipio de notificación"
                  fullWidth
                  inputProps={{ maxLength: 40 }}
                  inputRef={register}
                  name="notificationAddressCity"
                  helperText={
                    errors.notificationAddressCity &&
                    errors.notificationAddressCity.message
                  }
                  error={Boolean(errors.notificationAddressCity)}
                />
              </Grid>
            </Fragment>
          )}
          <Grid item sm={4} xs={12} className={classes.inputContainerItem}>
            <TextInput
              label="Correo"
              inputProps={{ maxLength: 100 }}
              inputRef={register}
              fullWidth
              name="email"
              helperText={errors.email && errors.email.message}
              error={Boolean(errors.email)}
              onCut={handleCopyPaste}
              onCopy={handleCopyPaste}
              onPaste={handleCopyPaste}
            />
          </Grid>
          <Grid item sm={4} xs={12} className={classes.inputContainerItem}>
            <TextInput
              label="Validar correo"
              inputRef={register}
              fullWidth
              name="validateEmail"
              helperText={errors.validateEmail && errors.validateEmail.message}
              error={Boolean(errors.validateEmail)}
              inputProps={{
                autoComplete: 'new-password',
                maxLength: 100
              }}
              onCut={handleCopyPaste}
              onCopy={handleCopyPaste}
              onPaste={handleCopyPaste}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextInput
              label="Nombre completo"
              inputProps={{ maxLength: 150 }}
              inputRef={register}
              fullWidth
              name="fullName"
              helperText={errors.fullName && errors.fullName.message}
              error={Boolean(errors.fullName)}
            />
          </Grid>
          <Grid item sm={4} xs={12} className={classes.inputContainerItem}>
            <ControlledSelectInput
              label="Tipo de identificación"
              name="identificationType"
              control={control}
              options={IDENTIFICATION_OPTIONS}
              fullWidth
            />
          </Grid>
          <Grid item sm={4} xs={12} className={classes.inputContainerItem}>
            <TextInput
              label="Identificación"
              inputRef={register}
              fullWidth
              inputProps={{ maxLength: 15 }}
              name="identification"
              helperText={
                errors.identification && errors.identification.message
              }
              error={Boolean(errors.identification)}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextInput
              label="Teléfono"
              type="tel"
              inputRef={register}
              fullWidth
              inputProps={{ maxLength: 10 }}
              name="phone"
              helperText={errors.phone && errors.phone.message}
              error={Boolean(errors.phone)}
            />
          </Grid>
          <Grid item xs={12} className={classes.termsContainer}>
            <FormControlLabel
              control={
                <ControlledCheckbox
                  id="Pqr_input_terms"
                  name="terms"
                  control={control}
                />
              }
              label={
                <Typography className={classes.termsText}>
                  He leído y acepto los{' '}
                  <a
                    className={classes.link}
                    href={`${ROUTE_NAMES.information}/${
                      TERMS_ROUTES[TERMS.introduction]
                    }`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    términos y condiciones
                  </a>
                </Typography>
              }
            />
            <ErrorMessage
              errors={errors}
              name="terms"
              message={errors.terms}
              as={<Typography color="error" />}
            />
          </Grid>
          <Grid item xs={12} className={classes.habeasDataContainer}>
            <FormControlLabel
              control={
                <ControlledCheckbox
                  id="Pqr_input_terms"
                  name="habeasData"
                  control={control}
                />
              }
              label={
                <Typography className={classes.termsText}>
                  He leído, acepto y autorizo el{' '}
                  <a
                    className={classes.link}
                    href={PrivacyPoliciesLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    tratamiento de mis datos personales
                  </a>
                </Typography>
              }
            />
            <ErrorMessage
              errors={errors}
              name="habeasData"
              message={errors.habeasData}
              as={<Typography color="error" />}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(6)
  },
  title: {
    fontSize: 18,
    fontWeight: 700
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2)
  },
  itemText: {
    fontSize: 16,
    textAlign: 'center',
    paddingRight: theme.spacing(),
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      textAlign: 'left'
    }
  },
  inputContainerItem: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2)
    }
  },
  termsText: {
    fontSize: 11,
    [theme.breakpoints.up('sm')]: {
      fontSize: 12
    }
  },
  link: {
    color: '#424242',
    fontWeight: 500
  },
  termsContainer: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing()
    }
  },
  sweetAlertText: {
    fontSize: 14
  },
  habeasDataContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing()
    }
  },
  dialogText: {
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  textBold: {
    fontWeight: 600
  },
  baseDialogActions: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: theme.spacing(2)
    },
    justifyContent: 'space-between',
    padding: theme.spacing(2, 4)
  },
  actionButton: {
    '&:nth-child(2)': {
      margin: 0
    },
    [theme.breakpoints.down('sm')]: {
      '&:first-child': {
        marginTop: theme.spacing(2),
        order: 1
      },
      '&:nth-child(2)': {
        order: 0
      }
    }
  }
}));

export default ContactStep;
