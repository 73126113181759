import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import ContractAssociationGDC from './ContractAssociation.GDC';
import ContractAssociationGDG from './ContractAssociation.GDG';
import ContractAssociationEFG from './ContractAssociation.EFG';

const ContractAssociation = () => {
  switch (portal) {
    case Portal.Gascaribe:
      return <ContractAssociationGDC />;
    case Portal.TripleA:
    case Portal.Gasguajira:
      return <ContractAssociationGDG />;
    case Portal.Efigas:
      return <ContractAssociationEFG />;
    default:
      return <></>;
  }
};

export default ContractAssociation;
