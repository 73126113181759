import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import InvoiceCardGDC from './InvoiceCard.GDC';
import InvoiceCardGDG from './InvoiceCard.GDG';

const InvoiceCard = props => {
  switch (portal) {
    case Portal.Gascaribe:
    case Portal.TripleA:
      return <InvoiceCardGDC {...props} />;
    case Portal.Gasguajira:
      return <InvoiceCardGDG {...props} />;
    case Portal.Efigas:
      return <InvoiceCardGDC {...props} />;
    default:
      return <></>;
  }
};

export default InvoiceCard;
