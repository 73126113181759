import React, { useState, useCallback } from 'react';
import { Grid, Typography, Divider, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Title from '../../Components/Labels/Title';
import BaseButton from '../../Components/Buttons/BaseButton';
import SchedulingDialog from '../../Components/Dialogs/SchedulingDialog';
import SweetAlert from '../../Components/Alerts/SweetAlert';
import BaseDialog from '../../Components/Dialogs/BaseDialog';
import ModalProgress from '../../Components/Progress/Modal/ModalProgress';

import {
  CancelRevisionScheduleAPI,
  UpdateRevisionScheduleAPI,
  RevisionScheduleAPI
} from '../../API/Revision/RevisionAPI';
import {
  extractErrorMessage,
  redirectOnAuthFailure
} from '../../Utils/Errors/Errors';

import moment from 'moment-timezone';
import clsx from 'clsx';
import { getRecaptchaToken } from '../../Utils/Recaptcha';
import { RecaptchaAction } from '../../Enums/recaptcha';
import { logoutUser } from '../../Utils/User/Actions';

const PeriodicRevisionHeader = props => {
  const {
    orderData,
    loading,
    authToken,
    setAlert,
    setRefresh,
    selectedContract,
    setCurrentUser,
    linkToMoreInfo,
    currentUser
  } = props;
  const classes = useStyles();

  const [schedulingDialog, setSchedulingDialog] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);

  const { orderId, scheduledRevision, contractor } = orderData || {};

  const today = moment().tz('America/Bogota');
  const appointmentDate =
    scheduledRevision &&
    moment(scheduledRevision.scheduledDate).tz('America/Bogota');

  const isAppointmentDateToday =
    scheduledRevision && appointmentDate.isSame(today, 'day');

  const cancelScheduledRevision = useCallback(async () => {
    setConfirmationDialog(false);
    setLoadingRequest(true);

    const recaptchaToken = authToken
      ? null
      : await getRecaptchaToken(RecaptchaAction.PeriodicRevisionCancelSchedule);
    const response = await CancelRevisionScheduleAPI(
      authToken,
      scheduledRevision.id,
      { contractId: selectedContract.id },
      recaptchaToken
    );

    if (response.success) {
      setLoadingRequest(false);
      setAlert({
        type: 'success',
        message: `Se ha cancelado el agendamiento con solicitud N° ${scheduledRevision.requestId}`
      });
      setRefresh(value => value + 1);
    } else {
      setLoadingRequest(false);
      const error = extractErrorMessage(response);
      if (error.key === 'base') {
        setAlert({
          type: 'error',
          message: error.message
        });
      }
    }
  }, [authToken, setAlert, setRefresh, scheduledRevision, selectedContract]);

  const revisionSchedulingRequest = useCallback(
    async data => {
      const body = {
        ...data,
        orderId,
        contractId: selectedContract.id
      };

      const isEdit = Boolean(scheduledRevision);

      const recaptchaAction = isEdit
        ? RecaptchaAction.PeriodicRevisionUpdateSchedule
        : RecaptchaAction.PeriodicRevisionSchedule;

      const recaptchaToken = authToken
        ? null
        : await getRecaptchaToken(recaptchaAction);

      const response = isEdit
        ? await UpdateRevisionScheduleAPI(
            authToken,
            scheduledRevision.id,
            body,
            recaptchaToken
          )
        : await RevisionScheduleAPI(authToken, body, recaptchaToken);

      if (response.success) {
        setAlert({
          type: 'success',
          message: isEdit
            ? 'Revisión agendada ha sido actualizada con éxito'
            : 'Revisión agendada con éxito'
        });
        setRefresh(value => value + 1);
      } else {
        if (
          redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
        ) {
          return;
        }
        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
      }
    },
    [
      authToken,
      selectedContract,
      setCurrentUser,
      setAlert,
      scheduledRevision,
      orderId,
      setRefresh
    ]
  );

  const handleCloseConfirmationDialog = () => {
    setConfirmationDialog(false);
  };

  const handleCancelActionClick = () => {
    setConfirmationDialog(true);
  };

  const handleEditActionClick = () => {
    setSchedulingDialog(true);
  };

  const renderActions = () => {
    return (
      <>
        <BaseButton
          id="ConfirmationDialog_button_cancel"
          onClick={handleCloseConfirmationDialog}
          variant="outlined"
          color="primary"
          size="small"
        >
          No
        </BaseButton>
        <BaseButton
          onClick={cancelScheduledRevision}
          id="ConfirmationDialog_button_submit"
          type="submit"
          color="primary"
          size="small"
        >
          Sí
        </BaseButton>
      </>
    );
  };

  const renderDialogContent = useCallback(() => {
    return (
      <Typography className={classes.confirmationText}>
        A continuación se cancelará el agendamiento de la revisión, está seguro?
      </Typography>
    );
  }, [classes.confirmationText]);

  return (
    <>
      {loadingRequest && <ModalProgress message="Cancelando tu agendamiento" />}
      {confirmationDialog && (
        <BaseDialog
          handleClose={handleCloseConfirmationDialog}
          open={confirmationDialog}
          title="Cancelar Agendamiento"
          actions={renderActions}
          content={renderDialogContent}
          contentStyle={classes.dialogContent}
          contentSize="small"
        />
      )}
      {schedulingDialog && (
        <SchedulingDialog
          open={schedulingDialog}
          setDialog={setSchedulingDialog}
          requestCallback={revisionSchedulingRequest}
          authToken={authToken}
          selectedContract={selectedContract}
          agendaData={scheduledRevision}
          isEdit={Boolean(scheduledRevision)}
          linkToMoreInfo={linkToMoreInfo}
          contractor={contractor}
          currentUser={currentUser}
        />
      )}
      <Grid container className={classes.headerContainer} alignItems="center">
        <Grid item sm={10} xs={9} className={classes.headerItemLeft}>
          <Title title="Revisión periódica" />
        </Grid>
        <Grid
          item
          sm={2}
          xs={3}
          className={clsx({
            [classes.headerItemRightLoading]: loading,
            [classes.headerItemRight]: !loading
          })}
        >
          {loading && <CircularProgress size={24} />}
          {!loading && orderId && !scheduledRevision && (
            <BaseButton
              className={classes.noCertificateButton}
              color="primary"
              size="small"
              onClick={() => {
                setSchedulingDialog(true);
              }}
            >
              Agendar Revisión
            </BaseButton>
          )}
        </Grid>
      </Grid>
      <Divider className={classes.separator} />
      {!loading && orderId && scheduledRevision && (
        <Grid container className={classes.separator}>
          <Grid item sm={12}>
            {!isAppointmentDateToday && (
              <SweetAlert
                id="revision_div_periodic_message"
                type="info"
                message={
                  <>
                    Actualmente tienes una revisión agendada para el dia{' '}
                    <strong>
                      {moment(scheduledRevision.scheduledDate).format(
                        'DD/MM/YYYY'
                      )}
                    </strong>{' '}
                    con solicitud{' '}
                    <strong>{`N°${scheduledRevision.requestId}`}</strong>. Si
                    deseas puedes cancelarla o editarla.
                  </>
                }
                hasCancelAction
                cancelActionOnClick={handleCancelActionClick}
                editActionOnClick={handleEditActionClick}
              />
            )}
            {isAppointmentDateToday && (
              <SweetAlert
                id="revision_div_periodic_message"
                type="info"
                message={
                  <>
                    ¡Tu visita de revisión periódica es hoy! Recuerda que en el
                    transcurso de la <strong>{scheduledRevision.shift}</strong>{' '}
                    un contratista del organismo <strong>{contractor}</strong>{' '}
                    se acercará a tu hogar a realizar la visita
                  </>
                }
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  separator: { marginBottom: 30 },
  titleSecond: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing()
  },
  infoTitle: {
    fontSize: 15,
    fontWeight: 500,
    marginBottom: theme.spacing()
  },
  infoText: {
    fontSize: 14,
    textAlign: 'justify'
  },
  textBold: {
    fontWeight: 500
  },
  textCaps: {
    textTransform: 'capitalize'
  },
  noCertificateButton: {},
  dialogContent: {
    minHeight: 'unset'
  },
  headerContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3.5)
  },
  headerItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerItemLeft: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  headerItemRightLoading: {
    display: 'flex',
    justifyContent: 'center'
  },
  headerItemRight: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

export default PeriodicRevisionHeader;
