import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useCallback
} from 'react';
import _get from 'lodash/get';
import clsx from 'clsx';
import {
  Grid,
  Container,
  Typography,
  GridList,
  GridListTile,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import InvoiceIconButton from '../../../Components/CustomIcons/IcButtonMyInvoices';
import FolderIcon from '../../../Components/CustomIcons/FolderIcon';
import PaymentsIcon from '../../../Components/CustomIcons/PaymentsIcon';
import BackIcon from '../../../Components/CustomIcons/BackIcon';
import EyeIcon from '../../../Components/CustomIcons/EyeIcon';
import PhoneValidation from '../../Profile/PhoneValidation';

import { history } from '../../../Routes/history';
import { ROUTE_NAMES } from '../../../Routes/Routes';

import SweetAlert from '../../../Components/Alerts/SweetAlert';
import InvoiceCard from '../../../Components/Cards/InvoiceCard/InvoiceCard';
import ImageButton from '../../../Components/Buttons/ImageButton';
import FlatButton from '../../../Components/Buttons/FlatButton';
import {
  UserContext,
  UserDispatchContext
} from '../../../Contexts/UserContext';
import {
  ContractsContext,
  ContractSelectedContext
} from '../../../Contexts/ContractsContext';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';
import {
  redirectOnAuthFailure,
  extractErrorMessage
} from '../../../Utils/Errors/Errors';
import { fullName } from '../../../Utils/Format/Names';
import { OpenNewTab } from '../../../Utils/Misc/Links';
import { HEADER_MAP_ICON } from '../../Layout/Header/header_enums';

import { GetInvoicesAPI } from '../../../API/Invoices/InvoicesAPI';

import NoContractBanner from '../Banners/NoContractBanner';
import { InvoiceUrlLink } from '../../../Configs/Links';
import { Company } from '../../../Configs/general';
import { logoutUser } from '../../../Utils/User/Actions';

const AuthHomeAAA = () => {
  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const authToken = _get(currentUser, 'token');

  const contracts = useContext(ContractsContext);
  const selectedContract = useContext(ContractSelectedContext);
  const setAlert = useContext(AlertsDispatchContext);

  // * STATE HOOKS
  const [invoice, setInvoice] = useState(null);
  const [invoiceLoading, setInvoiceLoading] = useState(true);
  const [phoneUpdate, setPhoneUpdate] = useState(false);

  // * OTHER HOOKS
  const classes = useStyles();
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmallSize = useMediaQuery('(max-width:450px)');
  const isMediumSize = useMediaQuery('(max-width:650px)');

  useEffect(() => {
    if (!selectedContract) {
      return;
    }

    let ignoreRequest = false;

    const fetchData = async () => {
      setInvoiceLoading(true);
      const contractId = selectedContract.id;
      const response = await GetInvoicesAPI(contractId, '-', authToken);

      if (ignoreRequest) {
        return;
      }

      if (response.success) {
        setInvoice(response.data.data[0]);
        setInvoiceLoading(false);
      } else {
        if (
          redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
        ) {
          return;
        }

        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
      }
    };
    fetchData();
    return () => {
      ignoreRequest = true;
    };
  }, [selectedContract, authToken, setCurrentUser, setAlert]);

  const downloadInvoice = useCallback(() => {
    OpenNewTab(InvoiceUrlLink);
  }, []);

  // * FUNCTIONS

  const _renderHomeTitle = () => {
    if (contracts.length === 0) {
      return (
        <Grid
          container
          className={clsx(classes.cardTitle, classes.cardTitleNoContract)}
          onClick={() => {
            history.push(ROUTE_NAMES.associate);
          }}
        >
          <Grid className={classes.contractTitle}>
            <FolderIcon size={20} />
            <Typography className={classes.cardTitleText}>
              Asociar {Company.contractConjugation.regular.plural.main}
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid container className={classes.cardTitle}>
        <Grid className={classes.contractTitle}>
          {HEADER_MAP_ICON[selectedContract.icon]({
            size: 20,
            className: classes.iconBlue
          })}
          {selectedContract && (
            <Typography className={classes.cardTitleText}>
              {!isMobileSize &&
                `${Company.contractConjugation.capitalized.singular.main} - `}
              <strong>{selectedContract.alias}</strong>
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  };

  const _actionRedirect = () => {
    if (!invoice) {
      return;
    }
    history.push(ROUTE_NAMES.payments, {
      type: 'contract',
      value: selectedContract.id,
      currentStep: 1,
      disabledNext: false,
      payment: [{ type: 'contract', detail: invoice }]
    });
  };

  const _renderDownloadInvoice = () => {
    const hasUrl = Boolean(invoice.url) && invoice.url.includes('watermark');

    if (hasUrl) {
      return (
        <FlatButton
          id="AuthHome_button_downloadInvoice"
          color="primary"
          className={classes.actionButton}
          onClick={downloadInvoice}
        >
          Ver factura
          <EyeIcon size={16} className={classes.iconLeftStyle} />
        </FlatButton>
      );
    }
  };

  const _renderHomeBanner = () => {
    if (contracts.length === 0) {
      return (
        <NoContractBanner
          contractCompanyReferer={
            Company.contractConjugation.regular.singular.undefinedArticle
          }
        />
      );
    }

    return (
      <Fragment>
        <InvoiceCard
          invoiceDetails={invoiceLoading ? null : invoice}
          className={classes.invoiceCard}
        />
        {!invoiceLoading && invoice && !invoice.isPaid && !invoice.isPending && (
          <Grid container className={classes.invoiceActions}>
            <Fragment>
              <Grid item>{_renderDownloadInvoice()}</Grid>
              <Grid item>
                <FlatButton
                  id="AuthHome_button_actionRedirect"
                  color="primary"
                  className={classes.actionButton}
                  onClick={_actionRedirect}
                >
                  Pagar factura
                  <BackIcon size={16} className={classes.iconRightStyle} />
                </FlatButton>
              </Grid>
            </Fragment>
          </Grid>
        )}
      </Fragment>
    );
  };

  const deviceColumns = useCallback(() => {
    if (isSmallSize) {
      return 2;
    }
    if (isMediumSize) {
      return 3;
    }
    return 3;
  }, [isSmallSize, isMediumSize]);

  const _renderHomeOptions = () => {
    if (contracts.length === 0) {
      return (
        <GridList cellHeight="auto" spacing={16} cols={deviceColumns()}>
          <GridListTile classes={{ tile: classes.optionTile }}>
            <ImageButton
              id="AuthHome_button_payments"
              icon={PaymentsIcon}
              title={'Pagos'}
              onClick={() =>
                history.push(ROUTE_NAMES.payments, {
                  type: 'contract',
                  value: ''
                })
              }
            />
          </GridListTile>
        </GridList>
      );
    }
    return (
      <GridList cellHeight="auto" spacing={16} cols={deviceColumns()}>
        <GridListTile classes={{ tile: classes.optionTile }}>
          <ImageButton
            id="AuthHome_button_payments"
            icon={PaymentsIcon}
            title={'Pagos'}
            aria-label="Pagos"
            onClick={() =>
              history.push(ROUTE_NAMES.payments, {
                type: 'contract',
                value: selectedContract.id
              })
            }
          />
        </GridListTile>
        <GridListTile classes={{ tile: classes.optionTile }}>
          <ImageButton
            id="AuthHome_button_invoices"
            icon={InvoiceIconButton}
            title={'Mis facturas'}
            aria-label="Mis facturas"
            onClick={() => history.push(ROUTE_NAMES.invoices)}
          />
        </GridListTile>
      </GridList>
    );
  };

  const onUpdatePhone = useCallback(
    async event => {
      event.preventDefault();
      setPhoneUpdate({ submit: true });
    },
    [setPhoneUpdate]
  );

  const _renderPhoneValidation = useCallback(() => {
    if (!currentUser.phone || currentUser.phoneValidated) {
      return;
    }

    return (
      <Fragment>
        <SweetAlert
          id="AuthHome_div_phoneValidation_info"
          classes={{
            root: classes.sweetAlert,
            message: classes.sweetAlertText
          }}
          message={
            <Fragment>
              Presiona <strong>aquí</strong> para validar tu número celular (+
              {currentUser.phoneCountryCode} {currentUser.phone})
            </Fragment>
          }
          onClick={onUpdatePhone}
        />
        <PhoneValidation
          setAlert={setAlert}
          phoneUpdate={phoneUpdate}
          setPhoneUpdate={setPhoneUpdate}
          phone={currentUser.phone}
          phoneCountryCode={currentUser.phoneCountryCode}
        />
      </Fragment>
    );
  }, [classes, currentUser, onUpdatePhone, phoneUpdate, setAlert]);

  const _renderCompleteUserRegister = useCallback(() => {
    if (currentUser.identification) {
      return;
    }

    return (
      <Fragment>
        <SweetAlert
          id="AuthHome_div_userRegister_info"
          classes={{
            root: classes.sweetAlert,
            message: classes.sweetAlertText
          }}
          message={
            <Fragment>
              Presiona <strong>aquí</strong> para completar tu perfil y
              aprovechar todas las funcionalidades que tenemos para ti.
            </Fragment>
          }
          onClick={() => history.push(ROUTE_NAMES.profile, true)}
        />
      </Fragment>
    );
  }, [classes, currentUser]);

  return (
    <Container
      id="AuthHome_div_container"
      maxWidth="sm"
      className={classes.authContainer}
    >
      <Typography id="AuthHome_text_welcome" className={classes.titleText}>
        Bienvenido,{' '}
        <strong>{fullName(currentUser, 'firstName', 'lastName', true)}</strong>
      </Typography>

      {_renderPhoneValidation()}
      {_renderCompleteUserRegister()}
      <Grid container className={classes.homeCardContainer}>
        <Grid item>{_renderHomeTitle()}</Grid>
        <Grid item>{_renderHomeBanner()}</Grid>
        <Grid item className={classes.optionsContainer}>
          {_renderHomeOptions()}
        </Grid>
      </Grid>
    </Container>
  );
};

const useStyles = makeStyles(theme => ({
  authContainer: {
    maxWidth: '700px',
    marginTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  titleText: {
    fontSize: '1em',
    textTransform: 'capitalize',
    marginBottom: theme.spacing(2)
  },
  authCloserGrid: {
    display: 'flex',
    boxSizing: 'border-box',
    margin: [[theme.spacing(4), theme.spacing(2)]]
  },
  mapIcon: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(2)
  },
  authCloserText: {
    fontSize: '1em'
  },
  link: {
    color: '#424242',
    fontWeight: 500,
    textDecoration: 'none'
  },
  homeCardContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    borderRadius: '6px',
    border: 'solid 1px #e7e7e7',
    backgroundColor: theme.palette.background.default,
    overflow: 'hidden'
  },
  cardTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2)
  },
  cardTitleNoContract: {
    cursor: 'pointer',
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    }
  },
  contractTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  pointsTitle: {
    fontSize: 11,
    fontWeight: 'bold',
    textAlign: 'end',
    marginRight: theme.spacing(),
    [theme.breakpoints.up('sm')]: {
      fontSize: 12
    }
  },
  points: {
    fontSize: 13,
    lineHeight: 1,
    textAlign: 'end',
    marginRight: theme.spacing(),
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  cardTitleText: {
    fontSize: 14,
    marginLeft: theme.spacing(),
    [theme.breakpoints.up('sm')]: {
      fontSize: 15
    }
  },
  invoiceCard: {
    border: '0',
    borderRadius: 0,
    borderTop: 'solid 1px #e7e7e7',
    borderBottom: 'solid 1px #e7e7e7'
  },
  invoiceActions: {
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: theme.palette.background.cardDark,
    borderBottom: 'solid 1px #e7e7e7',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    }
  },
  actionButton: {
    fontWeight: 500,
    fontSize: 12,
    color: theme.palette.common.black,
    textTransform: 'none',
    paddingTop: theme.spacing(1.875),
    paddingBottom: theme.spacing(1.875),
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  iconRightStyle: {
    transform: 'rotate(180deg)',
    fill: theme.palette.primary.main,
    marginLeft: theme.spacing()
  },
  iconLeftStyle: {
    fill: theme.palette.primary.main,
    marginLeft: theme.spacing()
  },
  invoiceActionsSkeleton: {
    width: '25%'
  },
  optionsContainer: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(3.5)
  },
  optionTile: {
    display: 'flex',
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center'
  },
  optionButton: {
    borderRadius: '12px',
    position: 'relative',
    /* For Safari 3.1 to 6.0 */
    '-webkit-transition-duration': '0.3s',
    transitionDuration: '0.3s',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    '&:hover': {
      boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
      transform: 'scale(1.08)'
    }
  },
  closerButton: {
    cursor: 'pointer',
    fontWeight: 600,
    verticalAlign: 'unset',
    display: 'unset',
    '&:hover': {
      color: theme.palette.color.primary
    }
  },
  sweetAlert: {
    marginBottom: 16,
    cursor: 'pointer'
  },
  sweetAlertText: {
    fontSize: 14
  }
}));

export default AuthHomeAAA;
