import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Breadcrumbs,
  Container,
  Link,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import _get from 'lodash/get';

import { UserContext } from '../../../../Contexts/UserContext';
import { AlertsDispatchContext } from '../../../../Contexts/AlertsContext';

import InsuranceDescriptionCard from '../../../../Components/Cards/InsuranceDescriptionCard';
import InsurancePlanDetailCard from '../../../../Components/Cards/InsurancePlanDetailCard';
import LoginDialog from '../../../Login/LoginDialog';
import ModalProgress from '../../../../Components/Progress/Modal/ModalProgress';
import Accordion from '../../../../Components/Accordion/Accordion';
import SweetAlert from '../../../../Components/Alerts/SweetAlert';

import {
  PractiSeguroProduct,
  InsurancePlans,
  InsurancePlanIcon,
  FAQPanel,
  InsurancePlan
} from '../../../../Enums/insurances';
import { ROUTE_NAMES } from '../../../../Routes/Routes';
import { history } from '../../../../Routes/history';
import { extractErrorMessage } from '../../../../Utils/Errors/Errors';
import { ALERT_TYPE } from '../../../../Components/Alerts/alert_enums';

import { GetInsurancePlanPricesAPI } from '../../../../API/BrillaInsurances/BrillaInsurancesAPI';

const PractiSeguroDetails = () => {
  const classes = useStyles();

  // * CONTEXTS
  const setAlert = useContext(AlertsDispatchContext);
  const currentUser = useContext(UserContext);
  const authToken = _get(currentUser, 'token');

  const [loading, setLoading] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [showsAlert, setShowsAlert] = useState(false);
  const [openPanel, setOpenPanel] = useState({
    [FAQPanel.Adquisition]: false,
    [FAQPanel.StartOfCoverage]: false,
    [FAQPanel.RequiredDocuments]: false
  });
  const [insuranceData, setInsuranceData] = useState({
    plans: InsurancePlans,
    prices: null
  });

  const handleOpenPanel = panel => {
    setOpenPanel(value => ({ ...value, [panel]: !value[panel] }));
  };

  const acquireInsurance = useCallback(
    (planId = InsurancePlan.Silver) => {
      if (currentUser) {
        history.push(ROUTE_NAMES.acquireInsurance, {
          planId,
          plans: insuranceData.plans,
          product: {
            ...PractiSeguroProduct,
            monthlyPay: insuranceData.plans[planId].monthlyPay
          }
        });
      } else {
        setOpenLoginDialog(true);
      }
    },
    [currentUser, insuranceData.plans]
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await GetInsurancePlanPricesAPI(authToken);

      if (response.success) {
        const planPricesData = response.data;

        if (!planPricesData.valid) {
          return;
        }

        const plansWithUpdatedPrices = planPricesData.prices.reduce(
          (plans, { id, price }) => ({
            ...plans,
            [id]: { ...InsurancePlans[id], monthlyPay: price }
          }),
          {}
        );

        setInsuranceData(prevState => ({
          ...prevState,
          plans: plansWithUpdatedPrices,
          prices: planPricesData.prices
        }));

        setLoading(false);
        setShowsAlert(false);
      } else {
        setShowsAlert(true);
        setLoading(false);
        setAlert({
          type: ALERT_TYPE.ERROR,
          message: extractErrorMessage(response).message
        });
      }
    };

    fetchData();
  }, [authToken, setAlert]);

  const minimumPlanPrice = insuranceData.plans[InsurancePlan.Silver].monthlyPay;

  return (
    <Fragment>
      {loading && <ModalProgress message="Consultando" />}
      {openLoginDialog && (
        <LoginDialog open={openLoginDialog} setOpen={setOpenLoginDialog} />
      )}
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          <Breadcrumbs
            aria-label="breadcrumb"
            className={classes.breadcrumbs}
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              color="primary"
              component={RouterLink}
              to="/"
              underline="always"
            >
              Inicio{' '}
            </Link>
            <Link
              color="primary"
              component={RouterLink}
              to="/seguros"
              underline="always"
            >
              Seguros{' '}
            </Link>
            <Typography color="textPrimary">Practi Seguro</Typography>
          </Breadcrumbs>
          {showsAlert && (
            <SweetAlert
              iconSize={20}
              type={ALERT_TYPE.WARNING}
              message={
                'No es posible adquirir un seguro en estos momentos. Por favor, inténtalo más tarde.'
              }
            />
          )}
          <InsuranceDescriptionCard
            description={PractiSeguroProduct.description}
            price={minimumPlanPrice}
            coverage={PractiSeguroProduct.coverage}
            isLoggedIn={Boolean(currentUser)}
            handleLogin={() => setOpenLoginDialog(true)}
            handleAcquisition={() => acquireInsurance()}
            disabledButton={!insuranceData.prices}
          />
          <Typography className={clsx(classes.title)}>
            Selecciona el plan que más se adapte para tí
          </Typography>
          <Grid container className={classes.plansContainer}>
            {Object.values(insuranceData.plans).map(plan => {
              return (
                <InsurancePlanDetailCard
                  key={plan.id}
                  PlanIcon={InsurancePlanIcon[plan.id]}
                  planCoverageRange={plan.coverageRange}
                  planMonthlyPay={plan.monthlyPay}
                  planInsuredNumber={plan.insuredNumber}
                  planName={plan.name}
                  buttonText="Adquirir seguro"
                  handleClick={() => acquireInsurance(plan.id)}
                  disabledButton={!insuranceData.prices}
                />
              );
            })}
          </Grid>
          <div className={clsx(classes.card)}>
            <Grid container>
              <Grid item>
                <Typography className={clsx(classes.subtitleBold)}>
                  Edades
                </Typography>
              </Grid>
              <Grid container item justify="space-between">
                <Grid item>
                  <Typography className={clsx(classes.subtitle)}>
                    Mínima de ingreso
                  </Typography>
                  <Typography className={clsx(classes.title)}>
                    {`${PractiSeguroProduct.minAge} año${
                      PractiSeguroProduct.maxAge > 1 ? 's' : ''
                    }`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={clsx(classes.subtitle)}>
                    Máxima de ingreso
                  </Typography>
                  <Typography className={clsx(classes.title)}>
                    {`${PractiSeguroProduct.maxAge} año${
                      PractiSeguroProduct.maxAge > 1 ? 's' : ''
                    }`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={clsx(classes.subtitle)}>
                    Edad de permanencia
                  </Typography>
                  <Typography className={clsx(classes.title)}>
                    {PractiSeguroProduct.permanenceAge === -1
                      ? 'Ilimitada'
                      : `${PractiSeguroProduct.permanenceAge} año${
                          PractiSeguroProduct.permanenceAge > 1 ? 's' : ''
                        }`}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <Typography className={clsx(classes.title)}>
            Preguntas frecuentes
          </Typography>
          <Grid container>
            <Accordion
              title="¿Cómo puede adquirir el Seguro de Vida Practiseguro?"
              open={openPanel[FAQPanel.Adquisition]}
              setOpen={() => handleOpenPanel(FAQPanel.Adquisition)}
              childContainerClass={classes.faqChildContainer}
            >
              <Typography>
                Debe habitar en el inmueble donde se presta el servicio de gas y
                tener autorización expresa para el cargue en la factura
                (solicitud de seguro).
              </Typography>
            </Accordion>
            <Accordion
              title="¿Desde cuándo cubre el Seguro de Vida Practiseguro?"
              open={openPanel[FAQPanel.StartOfCoverage]}
              setOpen={() => handleOpenPanel(FAQPanel.StartOfCoverage)}
              childContainerClass={classes.faqChildContainer}
            >
              <Typography>
                Desde las 00:00 horas del día siguiente al diligenciamiento de
                la Solicitud Certificado del Seguro.
              </Typography>
            </Accordion>
            <Accordion
              title="¿Qué documentos se deben presentar en caso de una reclamación?"
              open={openPanel[FAQPanel.RequiredDocuments]}
              setOpen={() => handleOpenPanel(FAQPanel.RequiredDocuments)}
              childContainerClass={classes.faqChildContainer}
            >
              <List className={clsx(classes.listType)}>
                <ListItem className={clsx(classes.listItemType)}>
                  <ListItemText primary="Fotocopia de la cédula del Asegurado." />
                  <ListItemSecondaryAction />
                </ListItem>
                <ListItem className={clsx(classes.listItemType)}>
                  <ListItemText primary="Registro civil de defunción." />
                  <ListItemSecondaryAction />
                </ListItem>
                <ListItem className={clsx(classes.listItemType)}>
                  <ListItemText primary="Última factura de gas debidamente pagada." />
                  <ListItemSecondaryAction />
                </ListItem>
                <ListItem className={clsx(classes.listItemType)}>
                  <ListItemText primary="Documento de identidad de los beneficiarios." />
                  <ListItemSecondaryAction />
                </ListItem>
                <ListItem className={clsx(classes.listItemType)}>
                  <ListItemText primary="Historia clínica completa. " />
                  <ListItemSecondaryAction />
                </ListItem>
              </List>
            </Accordion>
          </Grid>
        </Container>
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    overflowY: 'auto',
    overflowX: 'hidden',
    flex: 1,
    '& > :last-child': {
      marginBottom: theme.spacing(10)
    }
  },
  card: {
    borderRadius: theme.custom.borderRadius,
    border: `1px solid ${theme.palette.background.border}`,
    position: 'relative',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(1.5)
  },
  container: {
    flex: 1,
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    overflowY: 'auto',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginTop: theme.spacing(3)
    },
    '& > *': {
      marginBottom: theme.spacing(3)
    },
    '& > *:last-child': {
      marginBottom: 0
    }
  },
  breadcrumbs: {
    fontSize: 15,
    fontWeight: theme.typography.fontWeightMedium,
    '& *': { fontSize: 'inherit' }
  },
  modalBackground: {
    backgroundColor: '#aaaaaa',
    opacity: '20%',
    height: '100%',
    width: '100%'
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.2
  },
  subtitle: {
    fontSize: 12,
    color: theme.palette.text.greyDark
  },
  subtitleBold: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.text.greyDark
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.greyDark
  },
  labelBold: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.greyDark
  },
  listType: {
    listStyleType: 'disc'
  },
  listItemType: { display: 'list-item' },
  faqChildContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(6),
    borderBottom: '1px solid #D8D8D8',
    width: '100%'
  },
  plansContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      flexDirection: 'column'
    }
  }
}));

export default PractiSeguroDetails;
