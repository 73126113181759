import React, { Fragment } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';

import TextInput from '../../../../Components/Inputs/TextInput';
import ControlledSelectInput from '../../../../Components/Controlled/ControlledSelect';
import { PROCEDURE_TYPE_OPTIONS } from '../../Pqr_enums';

const UserWithContract = props => {
  const { register, errors, loading, control } = props;

  const classes = useStyles();

  return (
    <Fragment>
      <Grid item sm={4} xs={12} className={classes.inputContainerItem}>
        <ControlledSelectInput
          label="Tipo de trámite"
          name="procedureType"
          control={control}
          options={PROCEDURE_TYPE_OPTIONS}
          fullWidth
        />
      </Grid>
      <Grid item sm={4} xs={12} className={classes.inputContainerItem}>
        <TextInput
          label="Ciudad"
          inputRef={register}
          fullWidth
          disabled={loading}
          variant={'outlined'}
          name="location"
          helperText={errors.location && errors.location.message}
          error={Boolean(errors.location)}
        />
      </Grid>
      <Grid item sm={4} xs={12} className={classes.inputContainerItem}>
        <TextInput
          label="Dirección"
          fullWidth
          disabled={loading}
          variant={'outlined'}
          inputRef={register}
          name="address"
          helperText={errors.address && errors.address.message}
          error={Boolean(errors.address)}
        />
      </Grid>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  inputContainerItem: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2)
    }
  }
}));

export default UserWithContract;
