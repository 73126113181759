import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import HelpButtonIcon from '../../CustomIcons/HelpButtonIcon';
import CloseIcon from '../../CustomIcons/CloseIcon';
import PhoneIcon from '@material-ui/icons/Phone';
import {
  Backdrop,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import PhonesDialog from '../../Dialogs/PhonesDialog/PhonesDialog';
import {
  CircularMenuContext,
  CircularMenuDispatchContext
} from '../../../Contexts/CircularMenuContext';

const CircularMenuGDG = props => {
  const classes = useStyles();
  const [openPhonesDialog, setOpenPhonesDialog] = useState(false);
  const { footerVisible, openChatDialog } = props;

  const menu = useContext(CircularMenuContext);
  const setMenu = useContext(CircularMenuDispatchContext);

  const handleClick = useCallback(() => {
    document.getElementById('circularMenu').classList.toggle('active');
    if (!menu.open) {
      setMenu({ open: true, backdrop: false });
      return;
    }

    setMenu({ open: false, backdrop: false });
  }, [menu, setMenu]);

  // Added so the user can close the Menu when pressing Esc
  useEffect(() => {
    const handleEsc = event => {
      if (
        event.keyCode === 27 &&
        menu.open &&
        document.getElementById('circularMenu')
      ) {
        handleClick();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [menu, handleClick]);

  return (
    <Fragment>
      <PhonesDialog
        open={openPhonesDialog}
        onClose={() => setOpenPhonesDialog(false)}
      />
      {!openChatDialog && (
        <Fragment>
          {menu.backdrop && (
            <Backdrop
              classes={{ root: classes.backdrop }}
              open={true}
              onClick={handleClick}
            />
          )}
          <div
            id="circularMenu"
            className={clsx('circular-menu', classes.circularMenu, {
              [classes.noFooterVisible]: !footerVisible
            })}
          >
            <IconButton
              disableRipple={true}
              className={clsx('floating-btn', classes.floatingButton)}
              onClick={handleClick}
            >
              <HelpButtonIcon
                className={classes.helpButtonIcon}
                id="help-button-icon"
              />
              <CloseIcon
                size={18}
                id="close-icon"
                className={classes.closeIcon}
              />
            </IconButton>
            <menu className="items-wrapper">
              <div className="menu-item">
                <Grid
                  onClick={() => setOpenPhonesDialog(true)}
                  className={classes.menuItem}
                >
                  <Typography className={classes.itemText}>
                    Atención <br /> telefónica
                  </Typography>
                  <IconButton className={classes.iconButton}>
                    <PhoneIcon className={classes.iconStyle} />
                  </IconButton>
                </Grid>
              </div>
            </menu>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  circularMenu: {
    zIndex: 1101,
    bottom: 80,
    right: 32,
    [theme.breakpoints.up('sm')]: {
      right: 40
    }
  },
  noFooterVisible: {
    bottom: 100
  },
  menuItem: {
    display: 'flex',
    color: 'white',
    alignItems: 'center',
    marginTop: 80,
    marginRight: 55,
    '&:hover': {
      cursor: 'pointer',
      fontWeight: 500
    }
  },
  itemText: {
    color: 'currentcolor',
    textTransform: 'none',
    fontSize: 14,
    textAlign: 'right',
    paddingRight: 4,
    fontWeight: 'inherit'
  },
  iconStyle: {
    fontSize: 20,
    fill: 'currentcolor'
  },
  iconButton: {
    width: 30,
    height: 30,
    backgroundColor: '#1D3D90',
    borderRadius: '75%',
    color: 'white',
    '&:hover': {
      backgroundColor: '#1D3D90'
    },
    '&.MuiIconButton-root': {
      padding: 6
    }
  },
  closeIcon: {
    position: 'relative',
    right: 10,
    fill: 'white'
  },
  helpButtonIcon: {
    position: 'relative',
    left: 12
  },
  floatingButton: {
    '&:hover': {
      backgroundColor: '#1D3D90 !important'
    }
  },
  whatsAppIconSize: {
    height: 0
  },
  backdrop: {
    zIndex: 1100
  }
}));

export default CircularMenuGDG;
