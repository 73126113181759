import React from 'react';
import { portal, Portal } from '../../../../Configs/general';

import CurrentDebtRefinancingGDC from './CurrentDebtRefinancing.GDC';

const CurrentDebtRefinancing = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <CurrentDebtRefinancingGDC {...props} />;
    default:
      return <></>;
  }
};

export default CurrentDebtRefinancing;
