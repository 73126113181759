import React, {
  Fragment,
  useCallback,
  useState,
  useContext,
  useEffect
} from 'react';
import withWidth from '@material-ui/core/withWidth';

import { UpdateUserAPI } from '../../API/UserAPI';

import { UserContext, UserDispatchContext } from '../../Contexts/UserContext';

import PasswordDialog from './PasswordDialog';
import PhoneVerificationDialog from './PhoneVerificationDialog';
import { extractErrorMessage } from '../../Utils/Errors/Errors';
import { updateUser } from '../../Utils/User/Actions';
import { isUserLoggedInWithSocial } from '../../Enums/users';

const PhoneValidation = ({
  setAlert,
  phoneUpdate,
  setPhoneUpdate,
  phone,
  phoneCountryCode,
  setPhone,
  setErrors,
  setPhoneCountryCode,
  requestCallback = null,
  successCallback = null
}) => {
  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);

  // * STATE HOOKS
  const [passwordDialog, setPasswordDialog] = useState(null);
  const [phoneVerificationDialog, setPhoneVerificationDialog] = useState(null);
  const [currentPassword, setCurrentPassword] = useState(null);

  // * FUNCTIONS

  const handlePasswordDialog = useCallback(
    isDialogOpen => {
      setPhoneUpdate({ submit: isDialogOpen });
      setPasswordDialog(isDialogOpen);
    },
    [setPasswordDialog, setPhoneUpdate]
  );

  const handleVerificationDialog = useCallback(
    async (isDialogOpen, wasSuccessful) => {
      setPhoneUpdate({
        submit: isDialogOpen,
        successfulPassword: wasSuccessful
      });
      setPhoneVerificationDialog(isDialogOpen);
    },
    [setPhoneVerificationDialog, setPhoneUpdate]
  );

  const onConfirmPhoneUpdate = useCallback(
    async modalPassword => {
      const response = await UpdateUserAPI(currentUser.token, currentUser.id, {
        phoneCountryCode,
        phone,
        currentPassword: modalPassword
      });
      setCurrentPassword(modalPassword);
      return response;
    },
    [phoneCountryCode, phone, currentUser]
  );

  const onSuccessConfirmPhoneUpdate = useCallback(() => {
    setCurrentUser(user => ({
      ...user,
      phone,
      phoneCountryCode,
      phoneValidated: false
    }));
    handleVerificationDialog(true, true);
    if (setPhone && setPhoneCountryCode) {
      setPhone('');
      setPhoneCountryCode('');
    }
  }, [
    setCurrentUser,
    handleVerificationDialog,
    setPhone,
    setPhoneCountryCode,
    phone,
    phoneCountryCode
  ]);

  useEffect(() => {
    if (!phoneUpdate.submit || phoneUpdate.successfulPassword) {
      return;
    }

    let ignoreRequest = false;

    const fetchData = async () => {
      if (ignoreRequest) {
        return;
      }
      const response = await UpdateUserAPI(currentUser.token, currentUser.id, {
        phoneCountryCode,
        phone
      });

      if (!response.success) {
        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
        return;
      }
      const { token, appToken } = response.data.data;
      updateUser(null, token, appToken);
      setPhoneVerificationDialog(true);
    };

    let modalTitle = 'Validar';
    if (!currentUser.phone) {
      modalTitle = 'Agregar';
    } else if (currentUser.phoneValidated) {
      modalTitle = 'Actualizar';
    }

    if (isUserLoggedInWithSocial(currentUser)) {
      fetchData();
    } else {
      setPasswordDialog({
        title: `${modalTitle} número celular`,
        requestCallback: onConfirmPhoneUpdate,
        successCallback: onSuccessConfirmPhoneUpdate
      });
    }
    return () => {
      ignoreRequest = true;
    };
  }, [
    currentUser,
    phoneUpdate,
    onConfirmPhoneUpdate,
    onSuccessConfirmPhoneUpdate,
    phone,
    phoneCountryCode,
    setAlert
  ]);

  if (!currentUser || !phoneUpdate.submit) {
    return <Fragment></Fragment>;
  }

  return (
    <Fragment>
      {passwordDialog && (
        <PasswordDialog
          title={passwordDialog.title}
          open={Boolean(passwordDialog)}
          setDialog={handlePasswordDialog}
          setErrors={setErrors}
          setAlert={setAlert}
          requestCallback={passwordDialog.requestCallback}
          successCallback={passwordDialog.successCallback}
        />
      )}
      {phoneVerificationDialog && (
        <PhoneVerificationDialog
          title="Validar número celular"
          open={phoneVerificationDialog}
          formPhone={phone}
          formPhoneCountryCode={phoneCountryCode}
          setDialog={handleVerificationDialog}
          requestCallback={requestCallback}
          successCallback={successCallback}
          setAlert={setAlert}
          currentPassword={currentPassword}
        />
      )}
    </Fragment>
  );
};

export default withWidth()(PhoneValidation);
