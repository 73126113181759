import React, {
  Fragment,
  useState,
  useContext,
  useCallback,
  useEffect
} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';
import * as yup from 'yup';
import { UserDispatchContext } from '../../Contexts/UserContext';

import ShowPassword from '../../Components/Adornments/ShowPassword';
import BaseButton from '../../Components/Buttons/BaseButton';
import BaseDialog from '../../Components/Dialogs/BaseDialog';
import TextInput from '../../Components/Inputs/TextInput';
import { SlideUpTransition } from '../../Components/Transitions/Transitions';

import {
  redirectOnAuthFailure,
  extractErrorMessage
} from '../../Utils/Errors/Errors';
import { logoutUser } from '../../Utils/User/Actions';

const passwordSchema = yup.object({
  password: yup
    .string()
    .trim()
    .required('Ingresa tu contraseña')
});

const PasswordDialog = props => {
  // * CONTEXTS
  const {
    open,
    setDialog,
    setAlert,
    setErrors,
    title,
    requestCallback,
    successCallback,
    errorCallback
  } = props;
  const setCurrentUser = useContext(UserDispatchContext);
  const [password, setPassword] = useState('');
  const [passwordShown, setShowPassword] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [errors, setPasswordError] = useState({});
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const confirmPassword = useCallback(
    async event => {
      event.preventDefault();

      if (!canSubmit || loading) {
        return false;
      }

      setLoading(true);
      const response = await requestCallback(password);
      setLoading(false);
      if (response.success) {
        setDialog(false);
        if (successCallback) {
          successCallback(response);
        }
        return;
      }

      if (
        redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
      ) {
        return;
      }

      const error = extractErrorMessage(response);
      switch (error.key) {
        case 'password':
          setPasswordError({
            [error.key]: error.message
          });
          break;
        case 'phone':
        case 'email':
          if (setErrors) {
            setErrors({
              [error.key]: error.message
            });
          } else {
            setAlert({
              type: 'error',
              message: error.message
            });
          }
          setDialog(false);
          break;
        case 'identification':
          setAlert({
            type: 'error',
            message: error.message
          });
          setDialog(false);
          break;
        default:
          setAlert({
            type: 'error',
            message: error.message
          });
          break;
      }

      if (errorCallback) {
        errorCallback();
      }
    },
    [
      setAlert,
      setErrors,
      setDialog,
      password,
      canSubmit,
      requestCallback,
      errorCallback,
      successCallback,
      setCurrentUser,
      loading
    ]
  );

  useEffect(() => {
    if (!password) {
      setCanSubmit(false);
      return;
    }

    try {
      passwordSchema.validateSync({ password });
      setPasswordError({});
      setCanSubmit(true);
    } catch (err) {
      setCanSubmit(false);
      setPasswordError({
        [err.path]: err.errors[0]
      });
    }
  }, [password]);

  const handleClose = useCallback(() => {
    setDialog(false);
  }, [setDialog]);

  const togglePasswordShow = useCallback(() => {
    setShowPassword(!passwordShown);
  }, [passwordShown]);

  const onChangePassword = useCallback(event => {
    setPassword(event.target.value);
    setPasswordError(errs => ({ ...errs, password: null }));
  }, []);

  const renderActions = () => {
    return (
      <Fragment>
        <BaseButton
          id="PasswordDialog_button_cancel"
          onClick={handleClose}
          variant="outlined"
          color="primary"
          size="small"
          disabled={loading}
        >
          Cancelar
        </BaseButton>
        <BaseButton
          onClick={confirmPassword}
          id="PasswordDialog_button_submit"
          type="submit"
          color="primary"
          size="small"
          form="confirmPasswordForm"
          autoFocus
          disabled={!canSubmit || loading}
        >
          Confirmar
        </BaseButton>
      </Fragment>
    );
  };

  const renderContent = () => {
    return (
      <div id="PasswordDialog_div_container" className={classes.content}>
        <Typography
          id="PasswordDialog_text_currentPassword"
          className={classes.text}
        >
          Ingresa tu contraseña actual para poder continuar
        </Typography>
        <form onSubmit={confirmPassword} id="confirmPasswordForm">
          <TextInput
            id="PasswordDialog_input_currentPassword"
            autoFocus
            value={password}
            onChange={onChangePassword}
            margin="none"
            className={classes.passwordField}
            label="Contraseña"
            type={passwordShown ? 'text' : 'password'}
            required={true}
            error={Boolean(errors.password)}
            helperText={errors.password}
            InputProps={{
              endAdornment: (
                <ShowPassword
                  fontSize="small"
                  passwordShown={passwordShown}
                  togglePasswordShow={togglePasswordShow}
                />
              ),
              inputProps: {
                autoComplete: 'off'
              }
            }}
          />
        </form>
      </div>
    );
  };

  const isMobileSize = isWidthDown('sm', props.width);
  return (
    <Fragment>
      <BaseDialog
        open={open}
        handleClose={handleClose}
        title={title}
        actions={renderActions}
        content={renderContent}
        fullScreen={isMobileSize}
        TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
        contentSize={isMobileSize ? undefined : 'small'}
      />
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 14,
    color: theme.palette.text.primary
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  passwordField: {
    width: 250,
    marginTop: theme.spacing(4)
  }
}));

export default withWidth()(PasswordDialog);
