import React, {
  useRef,
  useCallback,
  useState,
  useContext,
  useEffect,
  useMemo
} from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import withWidth from '@material-ui/core/withWidth';
import { Container, Typography, Grid, InputAdornment } from '@material-ui/core';

import Recaptcha from '../../../../../../Components/Captcha/Recaptcha';
import ModalProgress from '../../../../../../Components/Progress/Modal/ModalProgress';
import BaseButton from '../../../../../../Components/Buttons/BaseButton';
import QuestionMark from '../../../../../../Components/Adornments/QuestionMark';
import InvoiceHelpDialog from '../../../../../../Components/Dialogs/InvoiceHelpDialog/InvoiceHelpDialog';
import ContractStatusCard from '../../../../../../Components/Cards/ContractInsuranceStatusCard';
import ControlledComboboxInput from '../../../../../../Components/Controlled/ControlledComboboxInput';
import CircleProgress from '../../../../../../Components/Progress/Circle/CircleProgress';

import {
  UserContext,
  UserDispatchContext
} from '../../../../../../Contexts/UserContext';
import {
  SetCurrentStepIndexContext,
  StepperDataDispatchContext,
  CurrentStepIndexContext,
  StepperDataContext
} from '../../../../../../Contexts/StepperContext';
import { ContractsContext } from '../../../../../../Contexts/ContractsContext';

import { GetSingleContractAPI } from '../../../../../../API/Contracts/ContractsAPI';
import { VerifyContractEligibilityAPI } from '../../../../../../API/BrillaInsurances/BrillaInsurancesAPI';

import {
  redirectOnAuthFailure,
  extractErrorMessage
} from '../../../../../../Utils/Errors/Errors';

import {
  DEFAULT_SEARCH_VALUE,
  InsuranceProductType
} from '../../../../../../Enums/insurances';
import { history } from '../../../../../../Routes/history';
import { config } from '../../../../../../Configs';
import { Company } from '../../../../../../Configs/general';
import { HELP_TEXT } from '../../../../../../Utils/enums';
import { InvoiceHelpType } from '../../../../../../Enums/invoices';
import { logoutUser } from '../../../../../../Utils/User/Actions';

const schema = yup.object({
  contract: yup
    .object({
      id: yup
        .number()
        .required(
          `Ingrese el número de ${Company.contractConjugation.regular.singular.main}`
        )
        .typeError(
          `El número de ${Company.contractConjugation.regular.singular.main} debe ser válido`
        )
        .transform((value, originalValue) => {
          return originalValue === '' ? undefined : value;
        }),
      label: yup.string()
    })
    .required(
      `Ingrese el número de ${Company.contractConjugation.regular.singular.main}`
    )
});

const ContractValidation = props => {
  const {
    formId,
    setCanSubmit,
    setGoBack,
    setCurrentStep: setVisualStepperIndex,
    setNextButtonText,
    setAlert
  } = props;

  const classes = useStyles();

  const currentStepIndex = useContext(CurrentStepIndexContext);
  const setCurrentStep = useContext(SetCurrentStepIndexContext);
  const setData = useContext(StepperDataDispatchContext);
  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const stepperData = useContext(StepperDataContext);
  const userContracts = useContext(ContractsContext);

  const CONTRACT_OPTIONS = useMemo(() => {
    return userContracts
      ? userContracts.map(({ id, alias }) => {
          return { id, label: alias };
        })
      : [];
  }, [userContracts]);

  const [contractOptions, setContractOptions] = useState(CONTRACT_OPTIONS);
  const [loading, setLoading] = useState(false);
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [contractToValidate, setContractToValidate] = useState(null);
  const [invalidContractReasons, setInvalidContractReasons] = useState([]);

  const recaptchaRef = useRef(null);
  const inputRef = useRef(null);

  const { handleSubmit, setValue, watch, errors, control } = useForm({
    validationSchema: schema,
    mode: 'onSubmit',
    defaultValues: {
      contract: stepperData.contract || DEFAULT_SEARCH_VALUE
    }
  });

  const onBackward = useCallback(() => {
    history.goBack();
  }, []);

  useEffect(() => {
    setNextButtonText('Continuar');
    setVisualStepperIndex(currentStepIndex);
    setCanSubmit(false);
    setGoBack({
      action: onBackward
    });
  }, [
    setGoBack,
    onBackward,
    setVisualStepperIndex,
    currentStepIndex,
    setNextButtonText,
    setCanSubmit
  ]);

  const resetData = useCallback(() => {
    setContractToValidate(null);
    setInvalidContractReasons([]);
    setLoading(true);
    setCanSubmit(false);
  }, [setCanSubmit]);

  const contractInputChangeHandler = useCallback(
    event => {
      if (event) {
        const contractId = event.target.value
          ? parseInt(event.target.value, 10)
          : '';

        setValue('contract', {
          id: contractId,
          label: ''
        });

        const filteredContracts = CONTRACT_OPTIONS.filter(contractOption =>
          String(contractOption.id).includes(contractId)
        );

        setContractOptions(filteredContracts);
      }
    },
    [setValue, CONTRACT_OPTIONS]
  );

  const contractValidation = useCallback(
    async contract => {
      const response = await VerifyContractEligibilityAPI(
        currentUser.token,
        contract.id,
        InsuranceProductType.Life,
        18100
      );
      if (response.success) {
        const validatedContract = response;

        setCanSubmit(validatedContract.valid);
        setContractToValidate({ ...contract, ...validatedContract });

        if (!validatedContract.valid) {
          setInvalidContractReasons([validatedContract.message]);
        }
      } else {
        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
        setCanSubmit(false);
      }
      setLoading(false);
    },
    [setContractToValidate, currentUser, setCanSubmit, setAlert]
  );

  const getContractData = useCallback(
    async values => {
      const contractId = values.contract.id.toString();
      const response = await GetSingleContractAPI(
        contractId,
        currentUser.token,
        captcha
      );
      resetData();

      if (response.success) {
        contractValidation(response.data.data);
      } else {
        if (
          redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
        ) {
          return;
        }

        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
        setLoading(false);
      }
    },
    [
      resetData,
      currentUser,
      setAlert,
      contractValidation,
      setCurrentUser,
      captcha
    ]
  );

  const onCaptchaResolved = useCallback(
    token => {
      setCaptcha(token);
    },
    [setCaptcha]
  );

  const onCaptchaExpired = useCallback(() => {
    setCaptcha(null);
  }, [setCaptcha]);

  const onCaptchaLoaded = useCallback(() => {
    setLoadingCaptcha(false);
  }, [setLoadingCaptcha]);

  const onHelpClick = useCallback(() => {
    setHelpDialogOpen(true);
  }, []);

  const checkKeyDown = event => {
    //Pressing enter on an input field bypassed this form thus key down verification is necessary without changing the behaviour of the FooterStepper to fix this issue.
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const goNext = useCallback(() => {
    setData(data => ({ ...data, contract: contractToValidate }));
    setCurrentStep(step => step + 1);
  }, [setCurrentStep, setData, contractToValidate]);

  const watchForm = watch(['contract']);

  const canQuery =
    Boolean(watchForm.contract) && !loading && !loadingCaptcha && captcha;

  return (
    <Container className={classes.root}>
      {loading && <ModalProgress message="Consultando" />}
      <InvoiceHelpDialog
        open={helpDialogOpen}
        type={InvoiceHelpType.Contract}
        onClose={() => setHelpDialogOpen(false)}
        title={HELP_TEXT.contract}
      />
      <Grid container direction="column">
        <Typography className={clsx(classes.title)}>
          Valida {Company.contractConjugation.regular.singular.article}
        </Typography>
        <Typography className={clsx(classes.subtitle)}>
          Ingresa {Company.contractConjugation.regular.singular.article} que
          deseas asociar al seguro
        </Typography>
      </Grid>
      <form
        id={formId}
        onSubmit={handleSubmit(goNext)}
        className={clsx(classes.form)}
        onKeyDown={e => checkKeyDown(e)}
      >
        <Grid container direction="row" className={classes.formContainer}>
          <Grid item xs={12} sm={5} className={classes.inputContainer}>
            <ControlledComboboxInput
              id="input_contract"
              name="contract"
              control={control}
              options={contractOptions}
              defaultValue={DEFAULT_SEARCH_VALUE}
              getOptionLabel={option => String(option.id)}
              renderOption={option => (
                <div className={classes.optionItemContainer}>
                  {option.id}
                  <span className={classes.optionItemAlias}>
                    {option.label}
                  </span>
                </div>
              )}
              onInputChange={contractInputChangeHandler}
              disabled={loading}
              TextInputProps={{
                label: `Número de ${Company.contractConjugation.regular.singular.main}`,
                type: 'number',
                InputProps: {
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      classes={{ positionEnd: classes.positionEnd }}
                    >
                      {loading && <CircleProgress />}
                      {!loading && <QuestionMark onClick={onHelpClick} />}
                    </InputAdornment>
                  )
                },
                error: Boolean(errors.contract),
                helperText: errors.contract && errors.contract.message
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            className={classes.recaptchaContainer}
            innerRef={inputRef}
          >
            <Recaptcha
              captchaRef={recaptchaRef}
              referenceEl={inputRef}
              heightScale={0.75}
              sitekey={config.recaptcha.siteKey}
              locale={'es'}
              onResolved={onCaptchaResolved}
              onExpired={onCaptchaExpired}
              onLoaded={onCaptchaLoaded}
            />
          </Grid>
          <Grid item xs={12} sm={3} className={classes.queryButtonContainer}>
            <BaseButton
              id="QueryPayment_button_submit"
              onClick={handleSubmit(getContractData)}
              disabled={!canQuery}
              fullWidth
              color="primary"
              variant="outlined"
              className={classes.queryButton}
            >
              Consultar
            </BaseButton>
          </Grid>
        </Grid>
      </form>
      {contractToValidate && (
        <ContractStatusCard
          contract={contractToValidate}
          reasons={invalidContractReasons}
        />
      )}
    </Container>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    '& > *:last-child': {
      marginBottom: theme.spacing(10)
    }
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.2,
    marginBottom: theme.spacing(1)
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.greyDark,
    marginBottom: theme.spacing(2.75)
  },
  form: { marginBottom: theme.spacing(3) },
  formContainer: {
    maxWidth: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingBottom: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.background.border}`
  },
  inputContainer: {
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      paddingRight: theme.spacing(2)
    }
  },
  recaptchaContainer: {
    padding: 0,
    [`@media (max-width:${theme.breakpoints.values.sm - 1}px)`]: {
      marginBottom: 16
    }
  },
  resultTitle: {
    marginTop: theme.spacing(3)
  },
  optionItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  optionItemAlias: {
    color: '#B8B8B8',
    fontSize: 12
  },
  queryButtonContainer: {
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      paddingLeft: theme.spacing(2),
      paddingTop: 15,
      marginTop: 0
    }
  },
  queryButton: {
    minHeight: 58,
    lineHeight: 'inherit'
  }
}));

export default withWidth()(ContractValidation);
