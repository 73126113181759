import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid, Typography } from '@material-ui/core';

import clsx from 'clsx';
import BaseButton from '../Buttons/BaseButton';
import { numberWithDots } from '../../Utils/Format/MoneyFormat';

const InsurancePlanDetailCard = props => {
  const {
    PlanIcon,
    planName,
    planMonthlyPay,
    planCoverageRange,
    planInsuredNumber,
    buttonText,
    handleClick,
    disabledButton
  } = props;
  const classes = useStyles();

  return (
    <Grid item className={clsx(classes.gradientBorder, classes.card)}>
      <Grid container>
        <Grid
          item
          container
          direction="column"
          justify="center"
          alignItems="center"
          className={classes.margin}
        >
          <Grid item>
            <PlanIcon size={32} />
          </Grid>
          <Grid item>
            <Typography className={clsx(classes.alignCenter, classes.bold)}>
              {planName}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid
            container
            direction="column"
            justify="center"
            className={classes.detailContainer}
          >
            <Grid item>
              <Typography>
                <span className={clsx(classes.elipse)}>• </span>
                {`${planInsuredNumber} asegurado${
                  planInsuredNumber > 1 ? 's' : ''
                }`}
              </Typography>
            </Grid>
            {planCoverageRange.map((detail, index, array) => {
              return (
                <Grid item key={`Detail ${index}`}>
                  <Typography className={clsx(classes.detail)}>
                    <span className={clsx(classes.elipse)}>• </span>Cobertura de{' '}
                    <span className={clsx(classes.bold)}>
                      {numberWithDots(detail)}
                    </span>
                  </Typography>

                  {array.length > 1 && (
                    <Typography className={clsx(classes.detailsSubtitle)}>
                      (Asegurado {index + 1})
                    </Typography>
                  )}
                </Grid>
              );
            })}
            <Grid item>
              <Typography className={clsx(classes.detail)}>
                <span className={clsx(classes.elipse)}>• </span>Prima mensual de{' '}
                <span className={clsx(classes.bold)}>
                  {numberWithDots(planMonthlyPay)}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="flex-end">
        <BaseButton
          size="small"
          onClick={handleClick}
          className={clsx(classes.cardButton)}
          disabled={disabledButton}
        >
          <Typography>{buttonText}</Typography>
        </BaseButton>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: theme.custom.borderRadius,
    position: 'relative',
    paddingBottom: theme.spacing(3.4),
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: 280,
    height: 370,
    boxShadow: theme.custom.boxShadow,
    '&:nth-child(even)': {
      height: 400
    },
    transition: 'transform .2s',
    '&:hover': {
      transform: 'scale(1.05)'
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.between(
      theme.breakpoints.values.sm,
      theme.breakpoints.values.md
    )]: {
      width: '100%',
      maxWidth: 320,
      '&:nth-child(even)': {
        height: 370
      },

      '& > *:last-child': {
        marginBottom: 0
      }
    }
  },
  detailContainer: {
    padding: theme.spacing(0, 2)
  },
  cardButton: {
    position: 'absolute',
    bottom: theme.spacing(3.4)
  },
  elipse: {
    fontSize: 24,
    color: theme.typography.color.black,
    textAlign: 'center'
  },
  detail: {
    fontSize: 13,
    color: theme.typography.color.black,
    display: 'inline-block',
    marginRight: theme.spacing(0.5)
  },
  detailsSubtitle: {
    color: theme.typography.color.black,
    fontSize: 11
  },
  bold: {
    fontSize: 14,
    fontWeight: 600,
    display: 'inline-block'
  },
  margin: {
    marginBottom: theme.spacing(2)
  },
  alignCenter: {
    textAlign: 'center'
  },
  alignRight: {
    textAlign: 'right'
  },
  paddingBottom: {
    paddingBottom: theme.spacing(1)
  },
  image: {
    height: 32,
    width: 'auto',
    marginBottom: theme.spacing(2)
  }
}));

export default InsurancePlanDetailCard;
