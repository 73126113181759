/* eslint-disable no-use-before-define */
import React from 'react';
import { Controller } from 'react-hook-form';
import ComboBoxInput from '../Inputs/ComboBoxInput';

const ControlledComboboxInput = props => {
  const {
    id,
    options,
    name,
    control,
    defaultValue,
    TextInputProps = {},
    onChange = ([, data]) => data,
    ...rest
  } = props;

  return (
    <Controller
      as={
        <ComboBoxInput
          id={id}
          options={options}
          TextInputProps={TextInputProps}
        />
      }
      name={name}
      control={control}
      defaultValue={defaultValue}
      onChange={onChange}
      {...rest}
    />
  );
};

export default ControlledComboboxInput;
