import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
  Fragment,
  useRef
} from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import TextInput from '../../../Components/Inputs/TextInput';
import TextAreaInput from '../../../Components/Inputs/TextAreaInput';
import FileInput from '../../../Components/Inputs/FileInput';
import FileGridList from '../../../Components/Inputs/File/FileGridList';
import SweetAlert from '../../../Components/Alerts/SweetAlert';

import {
  SetCurrentStepIndexContext,
  StepperDataContext,
  StepperDataDispatchContext,
  CurrentStepIndexContext,
  StepperSubmitContext
} from '../../../Contexts/StepperContext';

import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import clsx from 'clsx';

const VALID_FILE_EXTENSIONS = ['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png'];

const fileSchema = yup.array().of(
  yup.object({
    size: yup
      .number()
      // eslint-disable-next-line no-template-curly-in-string
      .test('fileSize', '${path}->Tamaño máximo: 10 MB', value => {
        return value <= 10240000;
      }),
    name: yup.string(),
    type: yup.string(),
    extension: yup
      .string()
      // eslint-disable-next-line no-template-curly-in-string
      .test('extension', '${path}->Archivo no permitido', extension =>
        VALID_FILE_EXTENSIONS.includes(extension)
      ),
    file: yup.mixed()
  })
);

const schema = yup.object({
  subject: yup
    .string()
    .max(250, 'Máximo 250 carácteres')
    .test(
      'no-double-quotes',
      'El asunto no debe contener comillas dobles (")',
      value => !value.match(/"/)
    )
    .required('Ingrese el asunto'),
  description: yup
    .string()
    .max(2000, 'Máximo 2000 carácteres')
    .required('Ingrese la descripción')
});

const convertBytesToMegaBytes = bytes =>
  Math.round((bytes / 1000000) * 100) / 100;

const MAX_TOTAL_FILE_SIZE = 50;

const DetailStep = props => {
  const {
    formId,
    setGoBack,
    setCurrentStep: setVisualStepperIndex,
    setNextButtonText
  } = props;

  const classes = useStyles();

  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('xs'));

  const currentStepIndex = useContext(CurrentStepIndexContext);
  const setCurrentStep = useContext(SetCurrentStepIndexContext);
  const setData = useContext(StepperDataDispatchContext);
  const stepperData = useContext(StepperDataContext);
  const submit = useContext(StepperSubmitContext);

  const fileInputRef = useRef(null);
  const fileInputId = 'Pqr_detail_fileinput';
  const fileInputButton = document.getElementById(`${fileInputId}_button`);

  const { register, getValues, errors, handleSubmit } = useForm({
    validationSchema: schema,
    defaultValues: {
      ...stepperData
    }
  });

  const initialFiles = stepperData.attachments || [];

  const [files, setFiles] = useState(initialFiles);
  const [totalSize, setTotalSize] = useState(
    convertBytesToMegaBytes(
      initialFiles.reduce((total, file) => total + file.size, 0)
    )
  );

  const onForward = useCallback(
    values => {
      const fileErrors = files.filter(file => file.error);

      if (fileErrors.length === 0 && totalSize <= MAX_TOTAL_FILE_SIZE) {
        setData(data => ({ ...data, ...values, attachments: files }));
        if (submit) {
          submit({ ...stepperData, ...values, attachments: files });
        }
      } else {
        fileInputButton.scrollIntoView();
      }
    },
    [files, stepperData, setData, submit, totalSize, fileInputButton]
  );

  const onBackward = useCallback(() => {
    setData(data => ({
      ...data,
      ...getValues(),
      attachments: files
    }));
    setCurrentStep(currentStep => currentStep - 1);
  }, [getValues, setCurrentStep, setData, files]);

  const handleDeleteFile = useCallback(
    fileIndex => {
      const filteredFiles = files.filter((_file, index) => index !== fileIndex);

      const totalFilesSize = filteredFiles.reduce(
        (total, file) => total + file.size,
        0
      );

      setFiles(filteredFiles);
      setTotalSize(convertBytesToMegaBytes(totalFilesSize));
      fileInputRef.current.value = '';
    },
    [files, fileInputRef]
  );

  const parseFiles = (currentFiles, incomingFiles) => {
    const preparedFiles = incomingFiles.map(file => {
      const { name, size, type } = file;

      const extension = name
        .split('.')
        .pop()
        .toLowerCase();

      return { name, size, type, extension, file, error: false };
    });

    const parsedFiles = currentFiles.slice();

    for (let i = 0; i < preparedFiles.length; ++i) {
      let found = false;
      for (let j = 0; j < currentFiles.length; ++j) {
        if (currentFiles[j].name === preparedFiles[i].name) {
          parsedFiles[i] = currentFiles[j];
          found = true;
          break;
        }
      }

      if (!found) {
        parsedFiles.push(preparedFiles[i]);
      }
    }

    try {
      fileSchema.validateSync(parsedFiles, {
        abortEarly: false
      });
    } catch (err) {
      for (const errorMessage of err.errors) {
        const [identifier, message] = errorMessage.split('->');
        const index = identifier.split('.')[0];

        const idx = parseInt(index.substr(1, index.length - 1), 10);

        parsedFiles[idx].error = true;
        parsedFiles[idx].errorMessage = message;
      }
    }

    const sortedParsedFiles = parsedFiles.slice();

    sortedParsedFiles.sort((a, b) => b.error - a.error);

    return sortedParsedFiles;
  };

  const handleChangeFile = useCallback(
    e => {
      const inputFiles = Array.from(e.target.files);

      const parsedFiles = parseFiles(files, inputFiles);

      const totalFilesSize = parsedFiles.reduce(
        (total, file) => total + file.size,
        0
      );

      setFiles(parsedFiles);
      setTotalSize(convertBytesToMegaBytes(totalFilesSize));
    },
    [files]
  );

  useEffect(() => {
    setNextButtonText('Enviar');
    setVisualStepperIndex(currentStepIndex);
    setGoBack({
      action: onBackward
    });
  }, [
    setGoBack,
    onBackward,
    setVisualStepperIndex,
    currentStepIndex,
    setNextButtonText,
    register
  ]);

  return (
    <div
      className={clsx(classes.container, {
        [classes.filelistActive]: files && files.length > 0
      })}
    >
      <Typography className={classes.title}>Detalle de la solicitud</Typography>
      <form id={formId} onSubmit={handleSubmit(onForward)}>
        <Grid container className={classes.inputContainer}>
          <Grid item xs={12}>
            <TextInput
              label="Asunto"
              inputRef={register}
              inputProps={{ maxLength: 250 }}
              fullWidth
              name="subject"
              helperText={errors.subject && errors.subject.message}
              error={Boolean(errors.subject)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextAreaInput
              id="Pqr_detail_textareainput"
              ref={register}
              margin="normal"
              label="Detalle su solicitud"
              name="description"
              rowsMax={10}
              maxLength={2000}
              helperText={errors.description && errors.description.message}
              error={Boolean(errors.description)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.title}>Anexos (Opcional)</Typography>
          </Grid>

          <Grid item sm="auto" xs={12} className={classes.inputContainerItem}>
            <FileInput
              id={fileInputId}
              ref={fileInputRef}
              name="attachments"
              accept={VALID_FILE_EXTENSIONS.map(ext => `.${ext}`).join(',')}
              text="Adjuntar archivos"
              inputProps={{
                onChange: handleChangeFile
              }}
              helperText={
                <span className={classes.noWrap}>{totalSize} MB/50 MB</span>
              }
              error={totalSize > MAX_TOTAL_FILE_SIZE}
              disabled={totalSize > MAX_TOTAL_FILE_SIZE}
              buttonProps={{
                color: 'default'
              }}
              multiple
              iconVisible
            />
          </Grid>
          <Grid
            item
            sm="auto"
            xs={12}
            className={clsx(
              classes.inputContainerItem,
              classes.sweetAlertContainer
            )}
          >
            <SweetAlert
              id="Pqr_Contact_alert"
              type="info"
              noIcon={isMobileSize}
              message={
                <Fragment>
                  Archivos permitidos:{' '}
                  {VALID_FILE_EXTENSIONS.map(ext => ` .${ext}`)}
                </Fragment>
              }
              classes={{
                root: classes.sweetAlert,
                message: classes.sweetAlertText
              }}
            />
          </Grid>
          {files && files.length > 0 && (
            <FileGridList
              id="Pqr_detail_filelist"
              files={files}
              onDeleteFile={handleDeleteFile}
              className={clsx(classes.inputContainerItem, classes.filelistItem)}
            />
          )}
        </Grid>
      </form>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4)
  },
  title: {
    fontSize: 18,
    fontWeight: 700
  },
  inputContainer: {
    marginTop: theme.spacing()
  },
  inputContainerItem: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing()
  },
  filelistItem: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing()
    }
  },
  filelistActive: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 58
    }
  },
  fileButtonText: {
    color: '#626262'
  },
  sweetAlertContainer: {
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2)
    }
  },
  sweetAlert: {
    [theme.breakpoints.up('sm')]: {
      height: 58
    }
  },
  sweetAlertText: {
    fontSize: 14
  },
  noWrap: {
    whiteSpace: 'nowrap'
  }
}));

export default DetailStep;
