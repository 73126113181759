import React, { Fragment, useContext, useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Breadcrumbs, Container, Link, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
import _get from 'lodash/get';

import ContractDebtCard from '../Cards/ContractDebtCard';
import CurrentDebtCard from '../Cards/CurrentDebtCard/CurrentDebtCard';
import DeferredDebtCard from '../Cards/DeferredDebtCard/DeferredDebtCard';
import PunishedDebtCard from '../Cards/PunishedDebtCard/DeferredDebtCard';
import ModalProgress from '../../../Components/Progress/Modal/ModalProgress';

import {
  ContractSelectedContext,
  ContractsContext
} from '../../../Contexts/ContractsContext';
import {
  UserContext,
  UserDispatchContext
} from '../../../Contexts/UserContext';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';

import {
  redirectOnAuthFailure,
  extractErrorMessage
} from '../../../Utils/Errors/Errors';

import { ROUTE_NAMES } from '../../../Routes/Routes';
import { history } from '../../../Routes/history';
import {
  GetContractDebtsAPI,
  GetDebtNegotiationPlanAPI
} from '../../../API/Debts/DebtsAPI';
import { GetInvoicesAPI } from '../../../API/Invoices/InvoicesAPI';

import myDebtsBanner from '../../../Assets/images/my-debts-banner.png';
import SweetAlert from '../../../Components/Alerts/SweetAlert';

import { getCurrentDebtData } from '../helpers/helpers';
import { ALERT_TYPE } from '../../../Components/Alerts/alert_enums';
import { Company, PortalCheck } from '../../../Configs/general';
import { getIsBillingPeriod } from '../helpers/helpers';
import { logoutUser } from '../../../Utils/User/Actions';

const CARD_TYPES = {
  current: 'current',
  deferred: 'deferred',
  punished: 'punished'
};

const MyDebtsGDC = () => {
  const classes = useStyles();

  // * CONTEXTS
  const contracts = useContext(ContractsContext);
  const selectedContract = useContext(ContractSelectedContext);
  const currentUser = useContext(UserContext);
  const setAlert = useContext(AlertsDispatchContext);
  const setCurrentUser = useContext(UserDispatchContext);

  const authToken = _get(currentUser, 'token');

  // * STATE HOOKS
  const [debtsData, setDebtsData] = useState(null);
  const [invoicesData, setInvoicesData] = useState(null);
  const [negotiationData, setNegotiationData] = useState(null);
  const [loading, setLoading] = useState(false);

  // * GLOBAL VARS
  const currentDebtData =
    debtsData && getCurrentDebtData(debtsData, negotiationData);

  const punishedData = debtsData && debtsData.punishedData;

  const punishedProductIds =
    punishedData &&
    punishedData.products.map(punishedProduct => punishedProduct.productId);

  const isBillingPeriod =
    debtsData &&
    getIsBillingPeriod(debtsData.daysUntilBilling, debtsData.billingDate);

  // * FUNCTIONS
  useEffect(() => {
    if (!authToken) {
      history.replace('/');
    }

    if (!contracts || !contracts.length) {
      history.replace(ROUTE_NAMES.contracts);
    }

    if (!selectedContract) {
      return;
    }

    const fetchData = async () => {
      setLoading(true);

      const [
        debtsResponse,
        invoicesResponse,
        negotiationResponse
      ] = await Promise.all([
        GetContractDebtsAPI(authToken, selectedContract.id),
        GetInvoicesAPI(selectedContract.id, null, authToken),
        GetDebtNegotiationPlanAPI(authToken, selectedContract.id)
      ]);

      if (invoicesResponse.success) {
        const responseData = invoicesResponse.data.data;
        setInvoicesData(responseData);
      } else {
        setInvoicesData(null);
      }

      if (negotiationResponse.success) {
        const responseData = negotiationResponse.data.data;

        const usuryRate = Number.parseFloat(
          responseData.usuryRate / 12
        ).toFixed(2);

        setNegotiationData({ ...responseData, usuryRate });
      } else {
        setNegotiationData(null);
      }

      if (debtsResponse.success) {
        const responseData = debtsResponse.data.data;

        setDebtsData(responseData);
        setLoading(false);
      } else {
        setDebtsData(null);
        setLoading(false);

        if (
          redirectOnAuthFailure(debtsResponse, '/', () =>
            logoutUser(setCurrentUser)
          )
        ) {
          return;
        }

        setAlert({
          type: 'error',
          message: extractErrorMessage(debtsResponse).message
        });
      }
    };

    fetchData();
  }, [authToken, contracts, selectedContract, setAlert, setCurrentUser]);

  const getCard = type => {
    switch (type) {
      case CARD_TYPES.current:
        return (
          <CurrentDebtCard
            debts={currentDebtData}
            invoices={invoicesData}
            selectedContract={selectedContract}
            authToken={authToken}
            punishedProductIds={punishedProductIds}
            isBillingPeriod={isBillingPeriod}
          />
        );
      case CARD_TYPES.deferred:
        return (
          <DeferredDebtCard
            debts={debtsData}
            selectedContract={selectedContract}
            isBillingPeriod={isBillingPeriod}
          />
        );
      case CARD_TYPES.punished:
        return (
          <PunishedDebtCard
            debts={currentDebtData}
            selectedContract={selectedContract}
            isBillingPeriod={isBillingPeriod}
            negotiationData={negotiationData}
            punishedProductIds={punishedProductIds}
          />
        );
      default:
        return <></>;
    }
  };

  const getCardsOrder = () => {
    const debtsWithBalance = [];
    const debtsWithoutBalance = [];

    const { currentValue, deferredValue, punishedValue } = debtsData;

    if (currentValue === 0) {
      debtsWithoutBalance.push(getCard(CARD_TYPES.current));
    } else {
      debtsWithBalance.push(getCard(CARD_TYPES.current));
    }

    if (deferredValue === 0) {
      debtsWithoutBalance.push(getCard(CARD_TYPES.deferred));
    } else {
      debtsWithBalance.push(getCard(CARD_TYPES.deferred));
    }

    if (punishedValue > 0) {
      debtsWithBalance.push(getCard(CARD_TYPES.punished));
    }

    return debtsWithBalance.concat(debtsWithoutBalance);
  };

  if (loading) {
    return <ModalProgress message="Consultando tus deudas" />;
  }

  if (!debtsData) {
    return <Fragment />;
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <Container className={classes.container}>
          <Breadcrumbs
            aria-label="breadcrumb"
            className={classes.breadcrumbs}
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              color="textPrimary"
              component={RouterLink}
              to="/"
              underline="always"
            >
              Inicio{' '}
            </Link>
            <Typography color="primary" className={classes.breadcrumbs}>
              Mis deudas
            </Typography>
          </Breadcrumbs>

          {negotiationData && !isBillingPeriod && !PortalCheck.isEfigas && (
            <SweetAlert
              type={ALERT_TYPE.INFO}
              message={
                <>
                  Puedes realizar un <b>acuerdo de pago</b> para tu{' '}
                  {Company.contractConjugation.regular.singular.main}{' '}
                  <b>
                    {selectedContract.id} ({selectedContract.alias})
                  </b>
                  .
                </>
              }
              hasAction
              actionText="Ir a acuerdo de pago"
              actionOnClick={() =>
                history.push(ROUTE_NAMES.refinancingCurrent, {
                  selectedContract,
                  data: currentDebtData,
                  punishedIds: punishedProductIds
                })
              }
            />
          )}

          {isBillingPeriod && (
            <SweetAlert
              iconSize={20}
              type={ALERT_TYPE.WARNING}
              classes={{
                root: classes.sweetAlert,
                message: classes.sweetAlertText,
                messageContainer: classes.sweetAlertMessageContainer,
                icon: classes.sweetAlertIcon
              }}
              message={
                <>
                  <b>
                    Tu {Company.contractConjugation.regular.singular.main} se
                    encuentra en periodo de facturación, por lo que solo puedes
                    realizar las siguientes acciones:
                    <br />
                  </b>
                  - Descargar comprobante del estado de cuenta <br />
                  - Pagar deuda actual <br />
                  - Ver detalle de tu deuda actual o financiada <br />
                  - Abonar a Brilla <br />
                </>
              }
            />
          )}

          <ContractDebtCard
            contract={selectedContract}
            title="Mis deudas"
            banner={myDebtsBanner}
            data={getCurrentDebtData(debtsData, negotiationData)}
          />
          {getCardsOrder().map((card, index) => (
            <div key={index}>{card}</div>
          ))}
        </Container>
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  container: {
    flex: 1,
    maxWidth: 816,
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginTop: theme.spacing(3)
    },
    '& > *': {
      marginBottom: theme.spacing(3)
    },
    '& > *:last-child': {
      marginBottom: 0,
      [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        marginBottom: theme.spacing(5)
      }
    }
  },
  breadcrumbs: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium,
    '& > *': { fontSize: 'inherit' }
  },
  modalBackground: {
    backgroundColor: '#aaaaaa',
    opacity: '20%',
    height: '100%',
    width: '100%'
  },
  sweetAlertText: {
    fontSize: 12
  },
  sweetAlertMessageContainer: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  sweetAlertIcon: {
    marginTop: theme.spacing(0.5)
  }
}));

export default MyDebtsGDC;
