import React from 'react';

const SubrogationIcon = ({ width = 35, height = 35, ...rest }) => {
  return (
    <svg
      id="ic-subrogacion-gas-2"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 56 56"
      {...rest}
    >
      <path
        id="Sustracción_6"
        data-name="Sustracción 6"
        d="M32,45.334H5.334A5.34,5.34,0,0,1,0,40V5.334A5.34,5.34,0,0,1,5.334,0H32a5.339,5.339,0,0,1,5.333,5.334V24.083A10.809,10.809,0,0,0,36,24V5.334a4,4,0,0,0-4-4H5.334a4,4,0,0,0-4,4V40a4,4,0,0,0,4,4h25.5a10.586,10.586,0,0,0,2.816,1.073A5.318,5.318,0,0,1,32,45.334Z"
        transform="translate(5 5.011)"
        fill="#333"
      />
      <rect
        id="Rectángulo_251"
        data-name="Rectángulo 251"
        width="56"
        height="56"
        fill="none"
      />
      <path
        id="Línea_7"
        data-name="Línea 7"
        d="M21.5.833H.167A.667.667,0,1,1,.167-.5H21.5a.667.667,0,1,1,0,1.333Z"
        transform="translate(13.5 16.845)"
        fill="#333"
      />
      <path
        id="Línea_8"
        data-name="Línea 8"
        d="M16.167.833h-16A.667.667,0,1,1,.167-.5h16a.667.667,0,1,1,0,1.333Z"
        transform="translate(13.5 27.512)"
        fill="#333"
      />
      <path
        id="Línea_9"
        data-name="Línea 9"
        d="M10.833.833H.167A.667.667,0,1,1,.167-.5H10.833a.667.667,0,1,1,0,1.333Z"
        transform="translate(13.5 38.178)"
        fill="#333"
      />
      <g
        id="Elipse_211"
        data-name="Elipse 211"
        transform="translate(30 28)"
        fill="#fff"
        stroke="#333"
        strokeWidth="1"
      >
        <circle cx="13" cy="13" r="13" stroke="none" />
        <circle cx="13" cy="13" r="12.5" fill="none" />
      </g>
      <g
        id="Grupo_806"
        data-name="Grupo 806"
        transform="translate(21.52 32.578)"
      >
        <path
          id="Trazado_483"
          data-name="Trazado 483"
          d="M-268.507,38.184a6.275,6.275,0,0,1-6.435-6.129,5.54,5.54,0,0,1,.714-3,5.839,5.839,0,0,0,.509-4.785c-.216-.731.164-1.1.906-.919a3.51,3.51,0,0,1,1.725.906c.384.391.5.221.683-.163a6.711,6.711,0,0,1,2.843-3.084,2.442,2.442,0,0,1,.765-.292c.431-.069.772.155.691.6-.424,2.3.755,4.092,1.834,5.919a11.744,11.744,0,0,1,1.67,3.477A6.29,6.29,0,0,1-268.507,38.184Zm-3.676-13.161a8.167,8.167,0,0,1-1.08,4.908,4.99,4.99,0,0,0-.331,2.867,3.668,3.668,0,0,0,.91,2.051c.047-.06.093-.092.092-.122a4.912,4.912,0,0,1,2.1-4.2,2.424,2.424,0,0,0,.873-1.145c.239-.79.667-.925,1.307-.383,2.029,1.716,3.315,3.777,2.894,6.669a5.221,5.221,0,0,0,1.642-3.226,6.157,6.157,0,0,0-1.287-4.092,15.711,15.711,0,0,1-2.212-4.557,4.889,4.889,0,0,0-.278-1.217,4.936,4.936,0,0,0-2.13,3.671c-.005.372.01.762-.441.876-.486.124-.662-.244-.835-.6A3.229,3.229,0,0,0-272.183,25.024Zm5.613,9.408a6.044,6.044,0,0,0-1.625-3.643c-.332-.374-.51-.359-.815.026a11.5,11.5,0,0,1-1.087,1.069,4.2,4.2,0,0,0-1.023,1.365,2.7,2.7,0,0,0,.48,2.96,2.421,2.421,0,0,0,2.59.527A2.332,2.332,0,0,0-266.57,34.432Z"
          transform="translate(290.285 -20.707)"
          fill="#333"
        />
      </g>
    </svg>
  );
};

export default SubrogationIcon;
