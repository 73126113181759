import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import FlatButton from './FlatButton';
import { Grid } from '@material-ui/core';

const ButtonWithImage = props => {
  const classes = useStyles();
  const { id, title, image, isSvg = false, onClick, selected = false } = props;

  return (
    <FlatButton
      id={id}
      color="primary"
      className={clsx(classes.flatButton, {
        [classes.buttonSelected]: selected
      })}
      disableRipple={false}
      onClick={onClick}
    >
      <Grid
        container
        alignItems="center"
        className={clsx(classes.contentContainer, {
          [classes.containerWithSvg]: isSvg
        })}
      >
        <Grid item className={classes.imageContainer}>
          {isSvg ? (
            image
          ) : (
            <img src={image || ''} alt={id} className={classes.image} />
          )}
        </Grid>
        <Grid item className={classes.buttonTextContainer}>
          <Typography className={clsx(classes.text, classes.alignCenter)}>
            {title}
          </Typography>
        </Grid>
      </Grid>
    </FlatButton>
  );
};

const useStyles = makeStyles(theme => ({
  flatButton: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.custom.borderRadius,
    color: theme.palette.common.black,
    width: 277,
    height: 67,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      width: 100,
      height: 'auto'
    }
  },
  contentContainer: {
    height: 66,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'column',
      width: 100,
      height: 'auto'
    }
  },
  containerWithSvg: {
    height: 65,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      height: 'auto'
    }
  },
  image: {
    height: 64,
    width: 64,
    borderRadius: '6px 0 0 6px',
    marginTop: 1,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      height: 98,
      width: 98,
      padding: theme.spacing(0.5)
    }
  },
  text: {
    textAlign: 'left',
    padding: theme.spacing(2, 1),
    fontSize: 12,
    fontWeight: 500,
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 10,
      padding: theme.spacing(1, 0.5)
    }
  },
  buttonSelected: {
    backgroundColor: '#F8FBFF',
    borderColor: theme.palette.common.black
  },
  imgSelected: {
    borderTop: `1px solid ${theme.palette.common.black}`,
    borderBottom: `1px solid ${theme.palette.common.black}`
  },
  imageContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      height: 98
    }
  },
  buttonTextContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      width: '100%',
      borderTop: `1px solid ${theme.palette.divider}`
    }
  },
  alignCenter: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      textAlign: 'center'
    }
  }
}));
export default ButtonWithImage;
