/* eslint-disable complexity */
import React, {
  useState,
  useContext,
  useCallback,
  useMemo,
  useEffect,
  useLayoutEffect,
  useRef
} from 'react';
import * as yup from 'yup';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import FullSizeProgress from '../../../Components/Progress/FullSize/FullSizeProgress';
import ModalProgress from '../../../Components/Progress/Modal/ModalProgress';
import Title from '../../../Components/Labels/Title';
import TextInput from '../../../Components/Inputs/TextInput';
import BaseButton from '../../../Components/Buttons/BaseButton';
import YesNoButton from '../../../Components/Buttons/YesNoButton';

import QuestionMark from '../../../Components/Adornments/QuestionMark';
import InvoiceHelpDialog from '../../../Components/Dialogs/InvoiceHelpDialog/InvoiceHelpDialog';

import FooterPayment from '../../Payment/FooterPayment';
import ContractCard from '../../../Components/Cards/ContractCard';

import { history } from '../../../Routes/history';
import {
  UserContext,
  UserDispatchContext
} from '../../../Contexts/UserContext';
import {
  CONTRACT_RELATIONSHIPS,
  ChallengeStatus,
  DEFAULT_ALIASES,
  INVOICE_CONTRACTS,
  formatAnswerDataBody
} from '../contract_enums';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';
import { HELP_TEXT } from '../../../Utils/enums';

// * API
import {
  GetContractChallengeAPI,
  GetSingleContractAPI,
  PostAssociateContract,
  ValidateContractChallengeAnswer
} from '../../../API/Contracts/ContractsAPI';
import {
  redirectOnAuthFailure,
  extractErrorMessage
} from '../../../Utils/Errors/Errors';
import {
  ContractsContext,
  ContractsRefreshContext
} from '../../../Contexts/ContractsContext';
import RequestDialog from '../../../Components/Dialogs/RequestDialog';
import RecaptchaDisclaimer from '../../../Components/Captcha/RecaptchaDisclaimer';
import { getRecaptchaToken } from '../../../Utils/Recaptcha';
import { RecaptchaAction } from '../../../Enums/recaptcha';
import ContractAssociationForm from './ContractAssociationForm/ContractAssociationForm';
import { Company } from '../../../Configs/general';
import { DigitalInvoiceThanksVideoLink } from '../../../Configs/Links';
import { OpenNewTab } from '../../../Utils/Misc/Links';
import SelectInput from '../../../Components/Inputs/SelectInput';
import ContractChallenge from '../ContractAssociation/ContractChallenge/ContractChallenge';
import { ALERT_TYPE } from '../../../Components/Alerts/alert_enums';
import VerticalLinearStepper from '../../../Components/Stepper/Vertical/VerticalLinearStepper';
import { Grow } from '@material-ui/core';
import { InvoiceHelpType } from '../../../Enums/invoices';
import { logoutUser } from '../../../Utils/User/Actions';
import ContractAssociationNoCheckedModalGDC from './ContractAssociationNoCheckedModal.GDC';

const associateSchema = yup.object({
  aliasName: yup
    .string()
    .trim()
    .max(12, 'Debe tener 12 letras o menos.')
    .required('Ingresa un alias'),
  aliasIcon: yup.number().required('Selecciona un ícono'),
  referredCode: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z0-9]*$/, 'El campo debe ser alfanumérico')
    .max(50, 'Debe tener 50 caracteres o menos.'),
  contractRelationship: yup
    .string()
    .trim()
    .oneOf([...CONTRACT_RELATIONSHIPS.map(({ value }) => value)])
    .required('Selecciona la relación con el predio')
});

const DEFAULT_CONTRACT_CHALLENGES = {
  challengeId: null,
  tasks: [],
  type: null,
  status: null,
  answers: []
};

const AssociationStep = {
  Query: 0,
  Validation: 1,
  Preferences: 2
};

const AssociationLabelSteps = {
  [AssociationStep.Query]: 'Consulta tu contrato',
  [AssociationStep.Validation]: 'Valida tu contrato',
  [AssociationStep.Preferences]: 'Elige tus preferencias'
};

const ContractAssociationGDC = () => {
  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const refreshContracts = useContext(ContractsRefreshContext);
  const setAlert = useContext(AlertsDispatchContext);
  const userContracts = useContext(ContractsContext);

  // * STATE HOOKS
  const [aliasIcon, setAliasIcon] = useState(1);
  const [aliasName, setAliasName] = useState('');
  const [contractRelationship, setContractRelationship] = useState('');
  const [sendInvoice, setSendInvoice] = useState(true);
  const [digitalInvoice, setDigitalInvoice] = useState();
  const [digitalChannels, setDigitalChannels] = useState({
    email: true,
    whatsapp: false,
    sms: false
  });
  const [referredCode, setReferredCode] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [number, setNumber] = useState('');
  const [loadingContract, setLoadingContract] = useState(false);
  const [contract, setContract] = useState(null);
  const [isContract, setIsContract] = useState(false);
  const [helpTypeDialog, setHelpTypeDialog] = useState(null);
  const [needsPhoneValidation, setNeedsPhoneValidation] = useState(false);
  const [openPhoneValidationDialog, setOpenPhoneValidationDialog] = useState(
    false
  );
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const [phoneActionText, setPhoneActionText] = useState('Validar');
  const [contractChallenge, setContractChallenge] = useState(
    DEFAULT_CONTRACT_CHALLENGES
  );
  const [loadingAnswers, setLoadingAnswers] = useState(false);
  const [canSubmitAnswers, setCanSubmitAnswers] = useState(false);
  const [noCheckedDataModal, setNoCheckedDataModal] = useState(false);
  const [challengeAnswers, setChallengeAnswers] = useState(null);
  const [activeStep, setActiveStep] = useState(AssociationStep.Query);

  // * OTHER HOOKS
  const classes = useStyles();
  const isContractRef = useRef(null);

  const allowedContractType =
    contract && INVOICE_CONTRACTS.includes(contract.type);

  // * LAYOUT EFFECTS
  useLayoutEffect(() => {
    // Scroll to "is this your contract" Toggle button
    if (contract) {
      isContractRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [contract]);

  useEffect(() => {
    if (!currentUser) {
      history.replace('/');
      return;
    }
  }, [currentUser]);

  useEffect(() => {
    if (!isContract) {
      setCanSubmit(false);
      return;
    }

    try {
      associateSchema.validateSync({
        aliasName,
        aliasIcon,
        referredCode,
        contractRelationship
      });
      setErrors({});
      if (digitalInvoice) {
        setCanSubmit(Boolean(digitalInvoice));
      }
    } catch (err) {
      setCanSubmit(false);
      setErrors({
        [err.path]: err.errors[0]
      });
    }
  }, [
    isContract,
    aliasName,
    aliasIcon,
    sendInvoice,
    digitalInvoice,
    setDigitalInvoice,
    referredCode,
    contractRelationship
  ]);

  useEffect(() => {
    if (contract) {
      if (INVOICE_CONTRACTS.includes(contract.type)) {
        setAliasIcon(contract.type);
        setAliasName(DEFAULT_ALIASES[contract.type]);
      } else {
        setAliasIcon(4);
        setAliasName('Especial');
      }
    }
  }, [contract]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    if (!currentUser.phone && !currentUser.phoneCountryCode) {
      setPhoneActionText('Agregar');
      return;
    }

    if (!currentUser.phoneValidated) {
      setPhoneActionText('Confirmar');
    }
  }, [currentUser]);

  const onChangeAliasName = useCallback(value => {
    setAliasName(value);
    setErrors(errs => ({ ...errs, aliasName: null }));
  }, []);

  const onChangeReferredCode = useCallback(value => {
    setReferredCode(value);
    setErrors(errs => ({ ...errs, referredCode: null }));
  }, []);

  const resetDigitalInvoiceForm = useCallback(() => {
    setSendInvoice(true);
    setDigitalChannels({ email: true, whatsapp: false, sms: false });
    setReferredCode('');
    setDigitalInvoice();
  }, []);

  // * QUERY SINGLE CONTRACT
  const _resetQueryData = useCallback(() => {
    setContract(null);
    setLoadingContract(false);
    setIsContract(false);
    resetDigitalInvoiceForm();
  }, [resetDigitalInvoiceForm]);

  const resetChallenge = useCallback(
    (challenge = true) => {
      if (challenge) {
        setContractChallenge(DEFAULT_CONTRACT_CHALLENGES);
      }
      setChallengeAnswers(null);
      setCanSubmitAnswers(false);
    },
    [setContractChallenge, setChallengeAnswers, setCanSubmitAnswers]
  );

  const _handleQuery = useCallback(
    async event => {
      event.preventDefault();
      _resetQueryData();

      if (userContracts.find(({ id }) => id === parseInt(number, 10))) {
        setAlert({
          type: 'error',
          message: `${Company.contractConjugation.capitalized.singular.article} ya está asociado a tu cuenta`
        });
        return;
      }

      setLoadingContract(true);
      resetChallenge();
      const recaptchaToken = await getRecaptchaToken(
        RecaptchaAction.AssociateContractGetChallenge
      );

      const challengeResponse = await GetContractChallengeAPI(
        {
          contractId: Number(number),
          contractRelationship
        },
        currentUser.token,
        recaptchaToken
      );

      if (challengeResponse.success) {
        const { tasks, type, challengeId } = challengeResponse.data.data;
        if (Array.isArray(tasks) && tasks.length) {
          setContractChallenge({
            challengeId,
            tasks,
            type,
            status: ChallengeStatus.ToValidate
          });
        }
      } else {
        setLoadingContract(false);
        if (
          redirectOnAuthFailure(challengeResponse, '/', () =>
            logoutUser(setCurrentUser)
          )
        ) {
          return;
        }

        setAlert({
          type: 'error',
          message: extractErrorMessage(challengeResponse).message
        });
        return;
      }

      const response = await GetSingleContractAPI(number, currentUser.token);
      if (response.success) {
        const contractData = response.data.data;
        setContract(contractData);
      } else {
        setLoadingContract(false);
        if (
          redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
        ) {
          return;
        }

        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
      }
      setLoadingContract(false);
    },
    [
      _resetQueryData,
      currentUser,
      number,
      setCurrentUser,
      setAlert,
      userContracts,
      contractRelationship,
      resetChallenge
    ]
  );

  // * ASSOCIATE CONTRACT
  const _handleAssociate = useCallback(async () => {
    setLoading(true);
    setCanSubmit(false);

    const contractHasDigitalInvoice = contract.digitalInvoice;

    // If the contract has an active digital invoice,
    // the user's response is taken as sendInvoice.
    // If it does not have it active, sendInvoice will
    // be the same as digitalInvoice.

    const realSendInvoice = contractHasDigitalInvoice
      ? sendInvoice
      : digitalInvoice;

    const realDigitalInvoice = contractHasDigitalInvoice || digitalInvoice;

    const digitalInvoiceSettings = {
      digitalInvoice: realDigitalInvoice,
      sendInvoice: realSendInvoice,
      sendEmailInvoice: realSendInvoice,
      sendWhatsAppInvoice: realSendInvoice && digitalChannels.whatsapp,
      sendSmsInvoice: realSendInvoice && digitalChannels.sms
    };

    // When the contract to associate is not an
    // allowed one for digital invoice, it sets
    // every digital invoice setting to false
    if (!allowedContractType) {
      for (const key of Object.keys(digitalInvoiceSettings)) {
        digitalInvoiceSettings[key] = false;
      }
    }

    const newAssociation = {
      ...digitalInvoiceSettings,
      alias: aliasName.trim(),
      icon: aliasIcon,
      referredCode,
      origin: {
        url: window.location.pathname
      },
      contractRelationship
    };

    if (contractChallenge.status === ChallengeStatus.Validated) {
      const { challengeId, type } = contractChallenge;

      const questions = formatAnswerDataBody(challengeAnswers, true);

      newAssociation.challengeData = {
        challengeId,
        questions,
        type
      };
    }

    const recaptchaToken = await getRecaptchaToken(
      RecaptchaAction.AssociateContract
    );

    const response = await PostAssociateContract(
      currentUser.token,
      number,
      newAssociation,
      recaptchaToken
    );

    if (response.success) {
      if (digitalInvoice || realSendInvoice) {
        const isSafari = /^((?!chrome|android).)*safari/i.test(
          navigator.userAgent
        );
        if (isSafari) {
          window.location.href = DigitalInvoiceThanksVideoLink;
        } else {
          OpenNewTab(DigitalInvoiceThanksVideoLink);
        }
      }
      setAlert({
        type: 'success',
        message: `Se ha asociado ${
          Company.contractConjugation.regular.singular.article
        } "${aliasName.trim()}"`
      });
      refreshContracts();
      history.push('/');
      return { unmounting: true };
    }
    if (
      redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
    ) {
      return { unmounting: true };
    }
    setAlert({
      type: 'error',
      message: extractErrorMessage(response).message
    });
    setLoading(false);
    return { unmounting: false, closeDialog: true };
  }, [
    currentUser,
    number,
    aliasName,
    aliasIcon,
    refreshContracts,
    setCurrentUser,
    setAlert,
    digitalInvoice,
    digitalChannels,
    sendInvoice,
    contract,
    referredCode,
    allowedContractType,
    contractChallenge,
    challengeAnswers,
    contractRelationship
  ]);

  // OTHER FUNCTIONS
  const closePhoneDialog = useCallback(() => {
    setOpenPhoneValidationDialog(false);
  }, []);

  const contractYesChecked = useCallback(() => {
    setIsContract(true);
    const nextStep =
      contractChallenge.status === ChallengeStatus.ToValidate
        ? AssociationStep.Validation
        : AssociationStep.Preferences;
    setActiveStep(nextStep);
  }, [contractChallenge.status]);

  const controlNoCheckedModal = useCallback(
    value => {
      setNoCheckedDataModal(value || !noCheckedDataModal);
    },
    [noCheckedDataModal, setNoCheckedDataModal]
  );

  const contractNoChecked = useCallback(() => {
    if (isContract) {
      setIsContract(false);
      resetDigitalInvoiceForm();
    }
    controlNoCheckedModal(true);
  }, [isContract, resetDigitalInvoiceForm, controlNoCheckedModal]);

  const onHelpClick = useCallback(type => {
    setHelpTypeDialog(type);
  }, []);

  const onSubmitAssociate = useCallback(() => {
    if (needsPhoneValidation) {
      setOpenPhoneValidationDialog(true);
      return;
    }

    _handleAssociate();
  }, [needsPhoneValidation, _handleAssociate]);

  const handleChallengeUserAnswers = useCallback(async () => {
    setLoadingAnswers(true);

    const recaptchaToken = await getRecaptchaToken(
      RecaptchaAction.AssociateContractCheckChallenge
    );

    const { challengeId } = contractChallenge;
    const challengeData = formatAnswerDataBody(challengeAnswers);

    const response = await ValidateContractChallengeAnswer(
      challengeId,
      { contractId: contract.id, challengeAnswers: challengeData },
      currentUser.token,
      recaptchaToken
    );

    if (response.success) {
      const { valid } = response.data.data;
      if (valid) {
        setActiveStep(AssociationStep.Preferences);
        setContractChallenge(c => ({
          ...c,
          status: ChallengeStatus.Validated,
          answers: challengeAnswers
        }));
        setAlert({
          type: ALERT_TYPE.SUCCESS,
          message: 'La respuesta es correcta, procede con la asociación'
        });
      } else {
        setAlert({
          type: ALERT_TYPE.ERROR,
          message: 'La respuesta es incorrecta',
          onClose: () => onHelpClick(InvoiceHelpType.Invoice)
        });
      }
      setLoadingAnswers(false);
    } else {
      setAlert({
        type: ALERT_TYPE.ERROR,
        message: extractErrorMessage(response).message
      });
      setLoadingAnswers(false);
      return;
    }

    setCanSubmitAnswers(false);
    setLoadingAnswers(false);
  }, [
    contract,
    currentUser,
    setAlert,
    contractChallenge,
    challengeAnswers,
    setCanSubmitAnswers,
    onHelpClick
  ]);

  const stepperSteps = useMemo(() => {
    const usedSteps = [
      {
        label: `Asociar ${Company.contractConjugation.regular.singular.newUndefinedArticle}`,
        onBack: () => history.goBack(),
        action: { text: 'Asociar', onCall: onSubmitAssociate }
      }
    ];

    return usedSteps;
  }, [onSubmitAssociate]);

  const disableQueryButton =
    loading || loadingCaptcha || !number || !contractRelationship;

  const renderQueryContractStep = useCallback(() => {
    return (
      <>
        <form onSubmit={_handleQuery}>
          <Grid container className={classes.inputsContainer}>
            <Grid item sm={4} xs={12}>
              <TextInput
                autoFocus
                fullWidth
                value={number}
                onChange={e => setNumber(e.target.value)}
                disabled={loading}
                label={`Número de ${Company.contractConjugation.regular.singular.main}`}
                InputProps={{
                  endAdornment: (
                    <QuestionMark
                      onClick={() => onHelpClick(InvoiceHelpType.Contract)}
                    />
                  ),
                  inputProps: {
                    type: 'number',
                    inputMode: 'numeric'
                  }
                }}
                required={true}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <SelectInput
                className={classes.contractRelationshipInput}
                value={contractRelationship}
                onChange={e => setContractRelationship(e.target.value)}
                disabled={loading}
                label={'Relación con el predio*'}
                options={CONTRACT_RELATIONSHIPS}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <BaseButton
                disabled={disableQueryButton}
                fullWidth={true}
                color="primary"
                variant="outlined"
                type="submit"
                className={classes.button}
              >
                Consultar
              </BaseButton>
            </Grid>
          </Grid>
          <div className={classes.disclaimerContainer}>
            <RecaptchaDisclaimer
              loading={loadingCaptcha}
              setLoading={setLoadingCaptcha}
            />
          </div>
        </form>
        <ContractCard
          data={contract}
          className={classes.contract}
          loading={loadingContract}
        />
        {contract && (
          <Grow in>
            <div className={classes.itemContainer}>
              <Typography className={classes.itemText} ref={isContractRef}>
                ¿La información visualizada{' '}
                {Company.contractConjugation.regular.singular.contraction} es la
                correcta?
              </Typography>
              <YesNoButton
                checked={isContract}
                variant="outlined"
                yesChecked={contractYesChecked}
                noChecked={contractNoChecked}
                color="primary"
                small="small"
              />
            </div>
          </Grow>
        )}
      </>
    );
  }, [
    _handleQuery,
    classes.button,
    classes.contract,
    classes.contractRelationshipInput,
    classes.disclaimerContainer,
    classes.inputsContainer,
    classes.itemContainer,
    classes.itemText,
    contract,
    contractNoChecked,
    contractRelationship,
    contractYesChecked,
    disableQueryButton,
    isContract,
    loading,
    loadingCaptcha,
    loadingContract,
    number,
    onHelpClick
  ]);

  const renderValidationStep = useCallback(() => {
    if (!contract) {
      return <></>;
    }
    return (
      <ContractChallenge
        tasks={contractChallenge.tasks}
        challengeId={contractChallenge.challengeId}
        type={contractChallenge.type}
        contractId={contract.id}
        handleAnswers={handleChallengeUserAnswers}
        canSubmitAnswers={canSubmitAnswers}
        setCanSubmitAnswers={setCanSubmitAnswers}
        setContractChallenge={setContractChallenge}
        setChallengeAnswers={setChallengeAnswers}
        challengeAnswers={challengeAnswers}
        contract={contract}
        setInvoiceHelp={onHelpClick}
      />
    );
  }, [
    contractChallenge.challengeId,
    contractChallenge.tasks,
    contractChallenge.type,
    contract,
    canSubmitAnswers,
    challengeAnswers,
    handleChallengeUserAnswers,
    onHelpClick
  ]);

  const preferencesStep = useCallback(() => {
    if (!contract || !isContract) {
      return <></>;
    }

    return (
      <ContractAssociationForm
        currentUser={currentUser}
        contract={contract}
        sendInvoice={sendInvoice}
        setSendInvoice={setSendInvoice}
        isDigitalInvoice={digitalInvoice}
        setIsDigitalInvoice={setDigitalInvoice}
        digitalChannels={digitalChannels}
        setDigitalChannels={setDigitalChannels}
        aliasName={aliasName}
        setAliasName={onChangeAliasName}
        aliasIcon={aliasIcon}
        setAliasIcon={setAliasIcon}
        errors={errors}
        needsPhoneValidation={needsPhoneValidation}
        setNeedsPhoneValidation={setNeedsPhoneValidation}
        setCanSubmit={setCanSubmit}
        referredCode={referredCode}
        setReferredCode={onChangeReferredCode}
        referredCodeEnable
        allowedContractType={allowedContractType}
      />
    );
  }, [
    aliasIcon,
    aliasName,
    allowedContractType,
    contract,
    currentUser,
    digitalChannels,
    digitalInvoice,
    errors,
    isContract,
    needsPhoneValidation,
    onChangeAliasName,
    onChangeReferredCode,
    referredCode,
    sendInvoice
  ]);

  const verticalSteps = useMemo(() => {
    const steps = [
      {
        step: AssociationStep.Query,
        label: AssociationLabelSteps[AssociationStep.Query],
        content: renderQueryContractStep()
      },
      {
        step: AssociationStep.Validation,
        label: AssociationLabelSteps[AssociationStep.Validation],
        content: renderValidationStep(),
        handleNext: () => handleChallengeUserAnswers(),
        handleBack: () => {
          setIsContract(false);
          setActiveStep(AssociationStep.Query);
        },
        nextLabel: 'Enviar',
        disabledNext: !canSubmitAnswers || loadingAnswers
      },
      {
        step: AssociationStep.Preferences,
        label: AssociationLabelSteps[AssociationStep.Preferences],
        content: preferencesStep(),
        handleBack: () => {
          setIsContract(false);
          setActiveStep(AssociationStep.Query);
          resetDigitalInvoiceForm();
        }
      }
    ];

    return steps;
  }, [
    renderQueryContractStep,
    renderValidationStep,
    preferencesStep,
    handleChallengeUserAnswers,
    canSubmitAnswers,
    loadingAnswers,
    resetDigitalInvoiceForm
  ]);

  return (
    <div className={classes.root}>
      {loadingAnswers && <ModalProgress message="Validando tus respuestas" />}
      {openPhoneValidationDialog && (
        <RequestDialog
          open={openPhoneValidationDialog}
          setDialog={setOpenPhoneValidationDialog}
          beforeClosing={closePhoneDialog}
          requestTitle={`${phoneActionText} número celular`}
          requestCallback={_handleAssociate}
          withoutObservation={true}
          backButton={{
            text: 'Cancelar',
            handle: closePhoneDialog
          }}
          submitButtonText={'Continuar'}
          requestNode={
            <Typography paragraph className={classes.text}>
              {`Para llevar a cabo esta acción es necesario ${phoneActionText.toLowerCase()} tu número de celular`}
            </Typography>
          }
          contentSize="small"
        />
      )}
      <InvoiceHelpDialog
        open={Boolean(helpTypeDialog)}
        type={helpTypeDialog}
        onClose={() => setHelpTypeDialog(null)}
        title={
          helpTypeDialog === InvoiceHelpType.Invoice
            ? HELP_TEXT.invoice
            : HELP_TEXT.contract
        }
      />
      {loading && <FullSizeProgress />}
      {loadingContract && <ModalProgress />}
      <Container maxWidth="md">
        <Title
          text={`Asociar ${Company.contractConjugation.regular.singular.newUndefinedArticle}`}
        />
        <VerticalLinearStepper steps={verticalSteps} activeStep={activeStep} />
      </Container>
      <FooterPayment currentStep={stepperSteps[0]} disabledNext={!canSubmit} />
      <ContractAssociationNoCheckedModalGDC
        open={noCheckedDataModal}
        controlNoCheckedModal={controlNoCheckedModal}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingTop: theme.spacing(3),
    marginBottom: theme.custom.footerHeight.stepper,
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(6)
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6)
    }
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      '& > *:first-child': {
        paddingRight: theme.spacing(2)
      },
      '& > *:last-child': {
        paddingLeft: theme.spacing(2)
      }
    }
  },
  button: {
    marginTop: theme.spacing(2),
    paddingTop: 20,
    paddingBottom: 20
  },
  contractContainer: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
      paddingLeft: theme.spacing(2)
    }
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      flexDirection: 'row'
    }
  },
  itemText: {
    fontSize: 14,
    fontWeight: 500,
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      paddingBottom: 0
    }
  },
  itemDivider: {
    marginTop: 0,
    marginBottom: 0
  },
  disclaimerContainer: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing()
  },
  contractRelationshipInput: {
    width: '100%'
  }
}));

export default ContractAssociationGDC;
