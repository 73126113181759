import React, { Fragment } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { Container, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

const Connector = withStyles(
  theme => ({
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)'
    },
    active: {
      '& $line': {
        borderColor: theme.palette.primary.main
      }
    },
    completed: {
      '& $line': {
        borderColor: theme.palette.primary.main
      }
    },
    line: {
      borderTopWidth: 2,
      borderRadius: 1
    }
  }),
  { withTheme: true }
)(StepConnector);

const WebStepper = props => {
  const { steps, currentStep } = props;
  const classes = useStyles();

  const numSteps = steps.length;

  if (currentStep > numSteps) {
    console.error('Current step is greater than the number of steps');
  }

  return (
    <Fragment>
      <Container className={classes.root}>
        <Stepper
          alternativeLabel
          connector={<Connector />}
          activeStep={currentStep}
          className={classes.stepper}
        >
          {steps.map(step => {
            return (
              <Step key={step.label}>
                <StepLabel
                  StepIconProps={{
                    classes: {
                      root: classes.icon
                    }
                  }}
                  classes={{
                    label: classes.stepLabel,
                    active: classes.activeStepLabel,
                    completed: classes.activeStepLabel
                  }}
                >
                  <Typography className={classes.stepLabelText}>
                    {step.label}
                  </Typography>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Container>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'space-around',
    paddingLeft: 0,
    paddingRight: 0,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  stepper: {
    backgroundColor: 'transparent',
    width: '100%'
  },
  stepLabelText: {
    fontSize: '12px',
    fontWeight: 500,
    textTransform: 'uppercase'
  },
  stepLabel: {
    marginTop: '8px !important'
  },
  activeStepLabel: {
    color: '#104eb2 !important'
  },
  icon: {
    width: '22px',
    height: '22px'
  }
}));

export default WebStepper;
