import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo
} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import _get from 'lodash/get';
import clsx from 'clsx';

import GameContext from '../../Contexts/GameContext';
import { ContractSelectedContext } from '../../Contexts/ContractsContext';
import { UserContext } from '../../Contexts/UserContext';

import StatusBanner from '../Banner/StatusBanner';
import BaseDialog from '../Dialogs/BaseDialog';
import GameIframe from './GameIframe';
import { Message } from './Message';

import { GenerateCampaignGameTokenAPI } from '../../API/Points/PointsAPI';
import { history } from '../../Routes/history';
import { config } from '../../Configs';
import { Company } from '../../Configs/general';

const Game = ({ setOpenGame }) => {
  // * HOOKS
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const extraSmallScreen = useMediaQuery(theme.breakpoints.down('500'));
  const selectedContract = useContext(ContractSelectedContext);
  const currentUser = useContext(UserContext);
  const setGameLoading = useContext(GameContext);

  // * STATE HOOKS
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState(config.campaign.game.url);
  const [showGame, setShowGame] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [dialogTitle, setDialogTitle] = useState('');

  const authToken = _get(currentUser, 'token');
  const contractId = _get(selectedContract, 'id');
  const messages = useMemo(
    () => ({
      errorTitle: 'Lo sentimos',
      generalError: 'Ocurrió un error, intentalo más tarde.',
      screenSize: (
        <>
          <StatusBanner
            message={
              <>
                La pantalla de tu dispositivo debe estar en posición{' '}
                <strong>horizontal</strong>. Recuerda que debes tener activada
                la rotación de pantalla.
              </>
            }
            type="rotateWarning"
          />
        </>
      ),
      notAssociatedTitle: `Asocia ${Company.contractConjugation.regular.singular.undefinedArticle}`,
      notAssociatedContract: (
        <>
          Aún no cuentas con{' '}
          {Company.contractConjugation.regular.plural.undefinedArticle}, para
          hacerlo haz clic{' '}
          <span
            onClick={() => {
              history.push('/associate');
            }}
            className={classes.link}
          >
            aquí
          </span>
          .
        </>
      ),
      authTitle: 'Inicia sesión',
      authRequired: (
        <>
          Debes{' '}
          <span
            onClick={() => {
              history.push('/login');
            }}
            className={classes.link}
          >
            iniciar sesión
          </span>{' '}
          para jugar.
        </>
      )
    }),
    [classes]
  );

  const handleClick = useCallback(async () => {
    setLoading(true);
    if (!contractId) {
      setDialogOpen(true);
      setUpWarning(messages.notAssociatedContract, messages.notAssociatedTitle);
      return;
    }

    setGameLoading(true);
    const response = await GenerateCampaignGameTokenAPI(
      authToken,
      contractId,
      config.campaign.name
    );
    setGameLoading(false);
    setDialogOpen(true);
    if (response.success) {
      const token = response.data.data.token;

      if (token) {
        setUrl(`${config.campaign.game.url}?jwt=${token}`);
        openGame();
      }
    } else {
      setDialogTitle(messages.errorTitle);
      let message;
      let title;

      try {
        const errorData = response.error.response.data;
        const baseError = errorData.errors.base;
        if (baseError) {
          switch (baseError) {
            case 'Autenticación inválida':
              message = messages.authRequired;
              title = messages.authTitle;
              break;
            default:
              message = messages.generalError;
              title = messages.errorTitle;
              break;
          }
        } else {
          message = messages.generalError;
          title = messages.errorTitle;
        }
      } catch (error) {
        message = messages.generalError;
        title = messages.errorTitle;
      }
      setUpWarning(message, title);
    }
  }, [authToken, contractId, messages, setGameLoading]);

  useEffect(() => {
    handleClick();
  }, [handleClick]);

  /**
   * Set up warning messages
   *
   * @param {string} message Message to display
   * @param {string} title Title to display
   */
  const setUpWarning = (message, title) => {
    setLoading(false);
    setShowWarning(true);
    setWarningMessage(message);
    setDialogTitle(title);
  };

  const openGame = () => {
    setLoading(true);
    setShowWarning(false);
    setShowGame(true);
    setDialogTitle('');
  };

  // handle Dialog close
  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleDialogExited = () => {
    setOpenGame(false);
    setShowWarning(false);
    setLoading(false);
    setShowGame(false);
  };

  return (
    <>
      <BaseDialog
        open={dialogOpen}
        fullScreen={smallScreen && !showWarning}
        handleClose={handleClose}
        onExited={handleDialogExited}
        disableBackdropClick
        maxWidth={showWarning && 'xs'}
        classes={{
          paper: clsx(classes.paper, smallScreen && classes.fullWidth)
        }}
        title={dialogTitle}
        loading={loading}
        content={() => (
          <>
            {!showWarning && (
              <Message
                text={messages.screenSize}
                visible={extraSmallScreen}
                absolute
              />
            )}
            {showGame && (
              <GameIframe loading={loading} setLoading={setLoading} url={url} />
            )}
            {showWarning && !showGame && <Message text={warningMessage} />}
          </>
        )}
        contentStyle={clsx(classes.content, showGame && classes.gameContent)}
        titleStyle={clsx(classes.title, showGame && classes.gameTitle)}
      />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    width: '80%'
  },

  fullWidth: {
    width: '100%'
  },

  gameDialog: {
    position: 'relative',
    width: '100%',
    overflowY: 'hidden'
  },

  gameContent: {
    padding: theme.spacing(1),
    height: '100%',
    width: '100%'
  },

  content: {
    minHeight: 'auto'
  },

  title: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(3)
    }
  },

  gameTitle: {
    zIndex: 4,
    top: 0,
    left: 0,
    position: 'absolute',
    minHeight: 'auto',
    padding: 0,
    backgroundColor: 'transparent',
    '& button': {
      backgroundColor: 'white',
      padding: theme.spacing(0.75)
    },
    '& button:hover': {
      backgroundColor: 'white'
    }
  },

  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'underline'
  }
}));

export default Game;
