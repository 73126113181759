import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import OvenLogoAnimationProgress from '../../../../Components/Progress/Animation/OvenLogoAnimationProgress';
import BaseDialog from '../../../../Components/Dialogs/BaseDialog';
import BaseButton from '../../../../Components/Buttons/BaseButton';

import {
  CurrentStepIndexContext,
  StepperDataContext
} from '../../../../Contexts/StepperContext';
import {
  CircularMenuContext,
  CircularMenuDispatchContext
} from '../../../../Contexts/CircularMenuContext';
import { UserContext } from '../../../../Contexts/UserContext';
import {
  CreateChangeConditionsRequestAPI,
  CreateDebtNegotiationRequestAPI
} from '../../../../API/Debts/DebtsAPI';

import { GetRequestAPI } from '../../../../API/Requests/requestsAPI';

import { history } from '../../../../Routes/history';
import { ROUTE_NAMES } from '../../../../Routes/Routes';
import { requestStatus, requestTypes } from '../../../Requests/Utils/enums';
import { extractErrorMessage } from '../../../../Utils/Errors/Errors';

import { useForm } from 'react-hook-form';
import _get from 'lodash/get';

const REQUEST_REFRESH_TIMEOUT = 5000;

const WaitForRequest = props => {
  const {
    formId,
    canSubmit,
    setCanSubmit,
    setGoBack,
    setCurrentStep: setVisualStepperIndex,
    setNextButtonText,
    setAlert,
    setSidebarVisible
  } = props;
  const classes = useStyles();

  const currentStepIndex = useContext(CurrentStepIndexContext);
  const stepperData = useContext(StepperDataContext);
  const currentUser = useContext(UserContext);

  const menu = useContext(CircularMenuContext);
  const setMenu = useContext(CircularMenuDispatchContext);

  const [openDialog, setOpenDialog] = useState(false);
  const [requestId, setRequestId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [interval, setRequestInterval] = useState(null);

  const authToken = _get(currentUser, 'token');

  const { handleSubmit } = useForm({
    defaultValues: {
      ...stepperData
    }
  });

  const onForward = useCallback(() => {
    if (canSubmit && requestId) {
      setOpenDialog(true);
      return;
    }
  }, [canSubmit, requestId]);

  const handleClose = useCallback(() => {
    setOpenDialog(false);
    if (stepperData.initialInstallmentValue > 0) {
      history.push(ROUTE_NAMES.payments, {
        type: 'financing',
        value: stepperData.contractId,
        currentStep: 1,
        disabledNext: false,
        payment: [
          {
            type: 'financing',
            detail: {
              contractId: stepperData.contractId,
              contractAddress: stepperData.contractAddress,
              contractCity: stepperData.contractCity,
              requestType: stepperData.requestType,
              totalInitialInstallmentValue: stepperData.initialInstallmentValue,
              requestId
            }
          }
        ]
      });
    } else {
      history.push(ROUTE_NAMES.debtStatus);
    }
  }, [setOpenDialog, stepperData, requestId]);

  const renderRequestInfo = useCallback(() => {
    switch (stepperData.requestType) {
      case requestTypes.debtNegotiation:
        return {
          firstName: stepperData.firstName,
          lastName: stepperData.lastName,
          identificationType: stepperData.identificationType,
          identification: stepperData.identification,
          contractId: stepperData.contractId,
          phone: stepperData.phone,
          phoneCountryCode: stepperData.phoneCountryCode,
          totalFinanceValue: stepperData.totalFinanceValue,
          totalDiscountValue: stepperData.totalDiscountValue,
          totalInitialInstallmentValue: stepperData.initialInstallmentValue,
          products: stepperData.products,
          usuryRate: stepperData.usuryRate
        };
      case requestTypes.changeConditions:
        return {
          firstName: stepperData.firstName,
          lastName: stepperData.lastName,
          identificationType: stepperData.identificationType,
          identification: stepperData.identification,
          contractId: stepperData.contractId,
          phone: stepperData.phone,
          phoneCountryCode: stepperData.phoneCountryCode,
          totalFinanceValue: stepperData.totalFinanceValue,
          totalInitialInstallmentValue: stepperData.initialInstallmentValue,
          installments: stepperData.numberOfInstallments,
          products: stepperData.products
        };
      default:
        return {};
    }
  }, [stepperData]);

  const createDebtNegotiationRequest = useCallback(async () => {
    setLoading(true);
    setCanSubmit(false);

    const data = renderRequestInfo();
    const response = await CreateDebtNegotiationRequestAPI(authToken, data);
    if (response.success) {
      const responseData = response.data.data;
      setRequestId(responseData.requestId);
    } else {
      setLoading(false);
      setAlert({
        type: 'error',
        message: extractErrorMessage(response).message
      });
    }
  }, [renderRequestInfo, authToken, setAlert, setCanSubmit]);

  const createChangeConditionsRequest = useCallback(async () => {
    setLoading(true);
    setCanSubmit(false);

    const data = renderRequestInfo();
    const response = await CreateChangeConditionsRequestAPI(authToken, data);
    if (response.success) {
      const responseData = response.data.data;
      setRequestId(responseData.requestId);
      setLoading(false);
      setOpenDialog(true);
    } else {
      setLoading(false);
      setAlert({
        type: 'error',
        message: extractErrorMessage(response).message
      });
    }
  }, [renderRequestInfo, authToken, setAlert, setCanSubmit]);

  const loadingStatus = useCallback(() => {
    if (loading) {
      return 'loading';
    }

    if (!loading && requestId) {
      return 'success';
    }

    return 'error';
  }, [loading, requestId]);

  const openHelpCenter = useCallback(() => {
    if (!menu.open) {
      document.getElementById('circularMenu').classList.toggle('active');
    }

    setMenu({ open: true, backdrop: true });
  }, [menu, setMenu]);

  // Props setters
  useEffect(() => {
    setCanSubmit(false);
    setSidebarVisible(false);

    if (stepperData.initialInstallmentValue > 0) {
      setNextButtonText('Ir a pagar');
    } else {
      setNextButtonText('Finalizar');
    }

    setVisualStepperIndex(currentStepIndex);
    setGoBack({
      action: null
    });
  }, [
    setGoBack,
    setVisualStepperIndex,
    currentStepIndex,
    setNextButtonText,
    setCanSubmit,
    setSidebarVisible,
    stepperData.initialInstallmentValue
  ]);

  // Create financing request
  useEffect(() => {
    switch (stepperData.requestType) {
      case requestTypes.changeConditions:
        createChangeConditionsRequest();
        break;
      case requestTypes.debtNegotiation: {
        createDebtNegotiationRequest();
        break;
      }
      default:
        return;
    }
  }, [
    stepperData.requestType,
    createChangeConditionsRequest,
    createDebtNegotiationRequest
  ]);

  // Fetch request data
  useEffect(() => {
    if (stepperData.requestType !== requestTypes.debtNegotiation) {
      return;
    }

    if (!requestId) {
      return;
    }

    const fetchData = async () => {
      const response = await GetRequestAPI(authToken, requestId);

      if (response.success) {
        const responseData = response.data.data.data.request;
        if (responseData.status === requestStatus.inProcess) {
          setLoading(false);
          setCanSubmit(true);
        }
      }
    };

    const fetchRequestInterval = setInterval(() => {
      fetchData();
    }, REQUEST_REFRESH_TIMEOUT);

    setRequestInterval(fetchRequestInterval);
  }, [authToken, requestId, stepperData.requestType, setCanSubmit]);

  // clear timeout
  useEffect(() => {
    if (!loading & canSubmit & interval) {
      clearInterval(interval);
      setRequestInterval(null);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
        setRequestInterval(null);
      }
    };
  }, [loading, canSubmit, interval]);

  const renderStatusMessage = useCallback(() => {
    const hasPayment = stepperData.initialInstallmentValue > 0;

    if (loading) {
      return (
        <Typography className={classes.message}>
          Por favor, no cierres esta ventana. Estamos trabajando en tu
          solicitud. Tan pronto esté lista, podrás continuar{' '}
          {hasPayment ? 'para realizar el pago' : 'con el proceso'}.
        </Typography>
      );
    }

    if (!loading && requestId) {
      const buttonText = hasPayment ? 'Ir a pagar' : 'Finalizar';

      return (
        <Typography className={classes.message}>
          ¡Hemos finalizado con tu solicitud! Haz clic en el botón{' '}
          <strong>{buttonText}</strong> para continuar.
        </Typography>
      );
    }

    return (
      <Typography className={classes.message}>
        ¡Lo sentimos! No hemos podido procesar tu solicitud. Intenta nuevamente
        más tarde o comunicate en nuestros{' '}
        <strong className={classes.atentionCenter} onClick={openHelpCenter}>
          canales de atención
        </strong>
        .
      </Typography>
    );
  }, [
    loading,
    requestId,
    stepperData.initialInstallmentValue,
    openHelpCenter,
    classes.message,
    classes.atentionCenter
  ]);

  const renderDialogActions = () => {
    return (
      <>
        <BaseButton onClick={handleClose}>Entendido</BaseButton>
      </>
    );
  };

  const renderDialogContent = useCallback(() => {
    return (
      <>
        <Typography className={classes.text}>
          Se ha generado la solicitud No. <b>{requestId}</b>
          {stepperData.initialInstallmentValue <= 0 &&
            ' y ha sido atendida satisfactoriamente.'}
          {stepperData.initialInstallmentValue > 0 &&
            '. La solicitud será atendida después de que se realice el pago y este sea aprobado.'}
        </Typography>
        {stepperData.initialInstallmentValue > 0 && (
          <Typography className={classes.footNote}>
            * Solo tienes <strong>1 hora</strong> para realizar el pago. De lo
            contrario la solicitud se anulará y tendrás que realizar todo el
            proceso nuevamente.
          </Typography>
        )}
      </>
    );
  }, [
    classes.text,
    requestId,
    stepperData.initialInstallmentValue,
    classes.footNote
  ]);

  return (
    <>
      {openDialog && (
        <BaseDialog
          handleClose={handleClose}
          open={openDialog}
          title="Estimado cliente"
          actions={renderDialogActions}
          content={renderDialogContent}
          contentStyle={classes.dialogContent}
          contentSize="small"
        />
      )}
      <form id={formId} onSubmit={handleSubmit(onForward)}>
        <Grid container alignItems="center" justify="center" direction="column">
          <Grid item className={classes.animationContainer}>
            <OvenLogoAnimationProgress size={240} status={loadingStatus()} />
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderStatusMessage()}
          </Grid>
        </Grid>
      </form>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  animationContainer: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2)
    }
  },
  message: {
    fontSize: 14,
    color: theme.typography.color.grey,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 'initial',
      marginBottom: theme.spacing(6)
    }
  },
  text: {
    fontSize: 14,
    color: theme.palette.text.primary
  },
  dialogContent: {
    minHeight: 'unset'
  },
  footNote: {
    marginTop: theme.spacing(2),
    fontSize: 12,
    lineHeight: 1.2,
    color: theme.palette.text.greyDark
  },
  atentionCenter: {
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

export default WaitForRequest;
