import React, { useState, useContext, useCallback, useRef } from 'react';
import clsx from 'clsx';
import { Container, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import VisitCard from '../../Components/Cards/VisitCard';
import BaseButton from '../../Components/Buttons/BaseButton';
import TextInput from '../../Components/Inputs/TextInput';
import ErrorState from '../../Components/EmptyState/ErrorState';
import {
  GetOrderAPI,
  GetOrderPDFAPI
} from '../../API/VerifyVisit/VerifyVisitAPI';
import ModalProgress from '../../Components/Progress/Modal/ModalProgress';
import { AlertsDispatchContext } from '../../Contexts/AlertsContext';
import { extractErrorMessage } from '../../Utils/Errors/Errors';
import Recaptcha from '../../Components/Captcha/Recaptcha';
import { UserContext } from '../../Contexts/UserContext';
import QuestionMark from '../../Components/Adornments/QuestionMark';
import { HELP_TEXT } from '../../Utils/enums';
import InvoiceHelpDialog from '../../Components/Dialogs/InvoiceHelpDialog/InvoiceHelpDialog';
import SimpleDivider from '../../Components/Dividers/SimpleDivider';
import { config } from '../../Configs';
import {
  CircularMenuContext,
  CircularMenuDispatchContext
} from '../../Contexts/CircularMenuContext';
import { Company } from '../../Configs/general';
import { OpenNewTab } from '../../Utils/Misc/Links';
import { InvoiceHelpType } from '../../Enums/invoices';

const VerifyVisitGDC = props => {
  // * STATE HOOKS
  const [isAnswerVisible, setIsAnswerVisible] = useState(false);
  const [found, setFound] = useState(false);
  const [isOrderValid, setIsOrderValid] = useState(false);
  const [isContractValid, setIsContractValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [fetchedData, setFetchedData] = useState({});
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);

  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const setAlert = useContext(AlertsDispatchContext);
  const menu = useContext(CircularMenuContext);
  const setMenu = useContext(CircularMenuDispatchContext);

  const recaptchaRef = useRef(null);
  const inputRef = useRef(null);

  const canSubmit =
    !isFetching &&
    !loadingCaptcha &&
    isOrderValid &&
    isContractValid &&
    captcha;
  const userToken = currentUser ? currentUser.token : null;
  const isMobileSize = isWidthDown('xs', props.width);

  const onHelpClick = useCallback(() => {
    setHelpDialogOpen(true);
  }, []);

  const fetchData = useCallback(async (id, contractId, captchaValue, token) => {
    setIsFetching(true);
    const response = await GetOrderAPI({
      orderId: id,
      captchaValue,
      contractId,
      token
    });

    if (response.success) {
      setFound(true);
      setFetchedData(response.data.data);
      setIsFetching(false);
      setIsAnswerVisible(true);
    } else if (
      response.error.response &&
      response.error.response.data.status === 'Not Found'
    ) {
      setErrorMessage(response.error.response.data.errors.base);
      setFound(false);
      setIsFetching(false);
      setIsAnswerVisible(true);
    } else {
      setIsFetching(false);
    }
    return response;
  }, []);

  const onCaptchaLoaded = useCallback(() => {
    setTimeout(() => {
      setLoadingCaptcha(false);
    }, 2500);
  }, [setLoadingCaptcha]);

  const onCaptchaResolved = useCallback(
    token => {
      setCaptcha(token);
    },
    [setCaptcha]
  );

  const onCaptchaExpired = useCallback(() => {
    setCaptcha(null);
  }, [setCaptcha]);

  const orderSchema = yup.object({
    orderId: yup
      .number()
      .required('Ingrese el número de orden')
      .typeError('Número de orden inválido')
      .test('integer', 'Número de orden inválido', val => {
        return val % 1 === 0;
      })
      .test(
        'len',
        'Formato de orden inválido',
        val => !val || (val && val.toString().length < 17)
      )
      .transform((value, originalValue) => {
        return originalValue === '' ? undefined : value;
      })
  });

  const contractSchema = yup.object({
    contractId: yup
      .number()
      .required(
        `Ingrese el número de ${Company.contractConjugation.regular.singular.main}`
      )
      .test(
        'integer',
        `Número de ${Company.contractConjugation.regular.singular.main} inválido`,
        val => {
          return val % 1 === 0;
        }
      )
  });

  const { register, getValues, control, handleSubmit } = useForm();

  const validation = field => {
    switch (field) {
      case 'orderId': {
        try {
          orderSchema.validateSync({ orderId: getValues(field) });
          setIsOrderValid(true);
        } catch (err) {
          setIsOrderValid(false);
        }
        break;
      }
      case 'contractId': {
        try {
          contractSchema.validateSync({ contractId: getValues(field) });
          setIsContractValid(true);
        } catch (err) {
          setIsContractValid(false);
        }
        break;
      }
      default:
        break;
    }
  };

  const handleClick = useCallback(async () => {
    const captchaValue = captcha;
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
      setCaptcha(null);
    }
    const response = await fetchData(
      parseInt(getValues('orderId'), 10),
      parseInt(getValues('contractId'), 10),
      captchaValue,
      userToken
    );
    if (!response.success && response.error.response.request.status !== 404) {
      setAlert({
        type: 'error',
        message: extractErrorMessage(response).message
      });
    }
  }, [setAlert, fetchData, getValues, captcha, userToken]);

  const handleChannelText = () => (
    <>
      Si aún no has logrado aclarar el estado de tu orden consulta en nuestros{' '}
      <span className={classes.attentionText} onClick={openHelp}>
        canales de atención
      </span>
    </>
  );

  const openHelp = useCallback(() => {
    if (!menu.open) {
      document.getElementById('circularMenu').classList.toggle('active');
    }

    setMenu({ open: true, backdrop: true });
  }, [menu, setMenu]);

  const downloadPDF = useCallback(async () => {
    const { orderId } = getValues();
    setIsFetching(true);
    const response = await GetOrderPDFAPI({
      orderId,
      userToken
    });

    if (response.success) {
      OpenNewTab(response.data.pdf);
      setIsFetching(false);
      return;
    }

    setAlert({
      type: 'error',
      message: extractErrorMessage(response).message
    });
    setIsFetching(false);
  }, [getValues, userToken, setAlert]);

  const classes = useStyles();

  return (
    <Container
      id="VerifyVisit_div_container"
      maxWidth="md"
      className={classes.main}
    >
      {loadingCaptcha && (
        <ModalProgress
          id={'QueryPayment_progress_loadingCaptcha'}
          message={'Cargando'}
        />
      )}
      <InvoiceHelpDialog
        open={helpDialogOpen}
        type={InvoiceHelpType.Contract}
        onClose={() => setHelpDialogOpen(false)}
        title={HELP_TEXT.contract}
      />
      <Typography
        variant="h5"
        className={clsx(classes.title, classes.text, classes.subText)}
      >
        Validación orden de visita
      </Typography>
      <Typography variant="h6" className={clsx(classes.text, classes.subText)}>
        Para obtener información de tu visita deberás contar con tu número de{' '}
        {Company.contractConjugation.regular.singular.main} y pedirle a nuestros
        técnicos el No. de orden e ingresarlos a continuación:
      </Typography>
      <form
        id={'order'}
        onSubmit={handleSubmit(handleClick)}
        className={clsx(classes.container, {
          [classes.captchaLoading]: loadingCaptcha
        })}
      >
        <Grid container direction="column">
          <Grid
            container
            item
            xs={12}
            justify="space-between"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              sm={4}
              className={clsx(classes.inputContainer, {
                [classes.consultInput]: !isMobileSize
              })}
            >
              <TextInput
                label="Número de orden*"
                inputRef={register}
                innerRef={inputRef}
                fullWidth
                disabled={isFetching}
                variant={'outlined'}
                control={control}
                onChange={() => validation('orderId')}
                name="orderId"
                InputProps={{
                  inputProps: {
                    inputMode: 'numeric'
                  }
                }}
                type="number"
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              className={clsx(classes.inputContainer, {
                [classes.consultInput]: !isMobileSize
              })}
            >
              <TextInput
                label={`Número de ${Company.contractConjugation.regular.singular.main}*`}
                id="QueryOrder_input_contractNumber"
                inputRef={register}
                innerRef={inputRef}
                fullWidth
                disabled={isFetching}
                variant={'outlined'}
                control={control}
                onChange={() => validation('contractId')}
                name="contractId"
                InputProps={{
                  endAdornment: <QuestionMark onClick={onHelpClick} />,
                  inputProps: {
                    inputMode: 'numeric'
                  }
                }}
                type="number"
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              className={clsx([
                classes.inputContainer,
                classes.recaptchaContainer,
                classes.consultInput
              ])}
            >
              <Recaptcha
                captchaRef={recaptchaRef}
                referenceEl={inputRef}
                locale={'es'}
                sitekey={config.recaptcha.siteKey}
                onResolved={onCaptchaResolved}
                onExpired={onCaptchaExpired}
                onLoaded={onCaptchaLoaded}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.consultContainer}>
            <BaseButton
              id="QueryOrder_button_submit"
              type="submit"
              fullWidth={isMobileSize}
              color="primary"
              variant="outlined"
              disabled={!canSubmit}
            >
              Consultar
            </BaseButton>
          </Grid>
          <Grid item>
            <SimpleDivider withoutMargin />
          </Grid>
        </Grid>
      </form>
      {isFetching && <ModalProgress />}
      {isAnswerVisible && (
        <div className={classes.space}>
          {found ? (
            <>
              <VisitCard
                className={classes.space}
                assignmentDate={moment(fetchedData.assignedDate).format(
                  'DD/MM/YYYY'
                )}
                contract={fetchedData.contractId}
                orderId={fetchedData.orderId}
                contractor={fetchedData.contratista}
                orderStatus={fetchedData.orderStatus}
                taskDescription={fetchedData.taskDescription}
                taskTypeId={fetchedData.taskTypeId}
                externalData={fetchedData.externalOrderData}
                isMobileSize={isMobileSize}
              />
              {fetchedData.supportPdfDownloadEnabled && (
                <Grid container direction="column">
                  <Grid item xs={12} className={classes.pdfButton}>
                    <BaseButton
                      id="QueryOrder_button_download_pdf"
                      fullWidth={isMobileSize}
                      color="primary"
                      size="small"
                      onClick={downloadPDF}
                    >
                      Descargar PDF
                    </BaseButton>
                  </Grid>
                </Grid>
              )}
            </>
          ) : (
            <ErrorState
              messageTitle={errorMessage}
              subMessage={handleChannelText()}
            />
          )}
        </div>
      )}
    </Container>
  );
};

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginTop: '5vh'
  },
  text: {
    textAlign: 'left',
    lineHeight: 1.43,
    fontWeight: 400,
    padding: theme.spacing(0, 2)
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'flex-start'
  },
  title: {
    // * Responsive (with banner)
    fontWeight: 'bold',
    [theme.breakpoints.up('xs')]: {
      fontSize: '1em',
      paddingTop: 0
    },
    [theme.breakpoints.up('xm')]: {
      fontSize: '1.25em',
      paddingTop: 0
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 28,
      paddingTop: 0
    }
  },
  subText: {
    // * Responsive
    [theme.breakpoints.up('xs')]: {
      fontSize: '0.75em'
    },
    [theme.breakpoints.up('xm')]: {
      fontSize: '0.83em'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1em'
    },
    marginBottom: theme.spacing(3),
    textDecoration: 'none'
  },
  search: {
    marginBottom: theme.spacing(3)
  },
  attentionText: {
    color: theme.palette.primary.dark,
    cursor: 'pointer',
    fontWeight: 500,
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  orderNumber: {
    fontWeight: 500
  },
  cardMargin: {
    marginBottom: theme.spacing(0)
  },
  space: {
    width: '100%',
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(8),
      padding: 0
    }
  },
  consultInput: {
    padding: theme.spacing(2)
  },
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start',
    paddingBottom: theme.spacing(2)
  },
  containerGrid: {
    marginBottom: theme.spacing(2)
  },
  button: {
    height: '58px',
    marginTop: theme.spacing(2)
  },
  buttonMobile: {
    height: '58px',
    marginBottom: theme.spacing(9)
  },
  captchaLoading: {
    visibility: 'hidden'
  },
  inputContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginTop: theme.spacing(),
      padding: [[0, theme.spacing(2)]]
    }
  },
  recaptchaContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginBottom: theme.spacing(3)
    }
  },
  consultContainer: {
    textAlign: 'end',
    padding: theme.spacing(2, 2, 3, 2),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingTop: '0 !important',
      paddingBottom: '0 !important'
    }
  },
  pdfButton: {
    textAlign: 'end',
    paddingTop: theme.spacing(2)
  }
}));

export default withWidth()(VerifyVisitGDC);
