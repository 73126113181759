import React, { useCallback, useContext, useState, useEffect } from 'react';
import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  Typography
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import OptionDivider from '../../Components/Dividers/OptionDivider';
import ConfirmationDialog from '../../Components/Dialogs/ConfirmationDialog';
import SocialButtons from '../../Components/Buttons/SocialButtons';

import { AlertsDispatchContext } from '../../Contexts/AlertsContext';
import { UserContext, UserDispatchContext } from '../../Contexts/UserContext';

import { ROUTE_NAMES } from '../../Routes/Routes';
import { TERMS, TERMS_ROUTES } from '../Information/Information/Information';
import {
  AppleLoginAPI,
  RevokeAppleTokenAPI,
  GoogleLoginAPI
} from '../../API/SocialUserAPI';
import {
  AppleSignUpAPI,
  FacebookSignUpAPI,
  GoogleSignUpAPI
} from '../../API/UserAPI';
import { extractErrorMessage } from '../../Utils/Errors/Errors';

import { history } from '../../Routes/history';
import {
  GoogleClientContext,
  GoogleClientDispatchContext
} from '../../Contexts/GoogleClientContext';

import {
  AppleClientContext,
  AppleClientDispatchContext
} from '../../Contexts/AppleClientContext';

import { PrivacyPoliciesLink } from '../../Configs/Links';
import {
  getLocalAppleUserData,
  removeLocalAppleUserData
} from '../../Enums/socialUser';
import { updateUser } from '../../Utils/User/Actions';

const SocialLoginButtons = props => {
  const {
    closeDialog = null,
    hideSocialButtons = false,
    loading,
    loadingApple,
    setLoadingApple,
    loadingGoogle,
    setLoadingGoogle,
    hideGoogleButton = false,
    hideAppleButton = false,
    isUpdateUserPath = false,
    setTokenGoogle
  } = props;

  const classes = useStyles();

  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const setAlert = useContext(AlertsDispatchContext);
  const googleData = useContext(GoogleClientContext);
  const setGoogleData = useContext(GoogleClientDispatchContext);
  const appleData = useContext(AppleClientContext);
  const setAppleData = useContext(AppleClientDispatchContext);

  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [terms, setTerms] = useState(false);
  const [newUserParams, setNewUserParams] = useState({});

  const handleGoogleLogin = useCallback(
    async data => {
      const { email, givenName, familyName, encodedToken } = data;

      if (isUpdateUserPath) {
        const params = {
          firstName: givenName.trim(),
          lastName: familyName.trim(),
          email: email.trim(),
          idToken: encodedToken
        };

        setConfirmationDialog(true);
        setNewUserParams({ google: params });
        setGoogleData(null);
        return;
      }

      setLoadingGoogle(true);

      const authResponse = await GoogleLoginAPI(encodedToken);

      if (!authResponse.success) {
        setAlert({
          type: 'error',
          message: extractErrorMessage(authResponse).message
        });
        setGoogleData(null);
        setLoadingGoogle(false);
        return;
      }

      const responseData = authResponse.data.data;
      const userType = responseData.userType;

      if (userType === 'new') {
        const params = {
          firstName: givenName.trim(),
          lastName: familyName.trim(),
          email: email.trim(),
          idToken: encodedToken
        };

        setConfirmationDialog(true);
        setNewUserParams({ google: params });
        setGoogleData(null);
        return;
      }

      const { token, appToken } = responseData;

      if (userType === 'google') {
        setGoogleData(null);
        updateUser(setCurrentUser, token, appToken);
        if (!closeDialog) {
          history.replace('/');
        }
      }
    },
    [
      setAlert,
      setCurrentUser,
      closeDialog,
      setGoogleData,
      isUpdateUserPath,
      setLoadingGoogle
    ]
  );

  const handleAppleLogin = useCallback(
    async data => {
      const {
        tokenId: sub,
        email,
        givenName,
        familyName,
        encodedToken,
        code
      } = data;

      setLoadingApple(true);

      const authResponse = await AppleLoginAPI({
        idToken: encodedToken,
        code,
        firstName: givenName,
        lastName: familyName
      });

      if (!authResponse.success) {
        setAlert({
          type: 'error',
          message: extractErrorMessage(authResponse).message
        });
        setAppleData(null);
        setLoadingApple(false);
        return;
      }

      const responseData = authResponse.data.data;
      const userType = responseData.userType;

      if (userType === 'new') {
        const params = {
          firstName: givenName,
          lastName: familyName,
          email: email.trim(),
          idToken: encodedToken,
          sub,
          code
        };

        setConfirmationDialog(true);
        setNewUserParams({ apple: params });
        setAppleData(null);
        return;
      }

      const { token, appToken } = responseData;

      if (userType === 'apple') {
        setAppleData(null);
        updateUser(setCurrentUser, token, appToken);
        if (!closeDialog) {
          history.replace('/');
        }
      }
    },
    [setAlert, setCurrentUser, closeDialog, setAppleData, setLoadingApple]
  );

  const onChangeTerms = useCallback(() => {
    setTerms(!terms);
  }, [terms]);

  const handleTermsDialog = useCallback(
    async closed => {
      if (closed) {
        setAlert({
          type: 'info',
          message:
            'Se ha cerrado la ventana de términos y condiciones. Intenta nuevamente'
        });
        setLoadingGoogle(false);
        setTerms(false);

        if (!hideAppleButton) {
          setLoadingApple(false);
        }

        if (newUserParams.apple) {
          const { idToken, code } = newUserParams.apple;
          await RevokeAppleTokenAPI({
            idToken,
            code
          });
        }

        return;
      }

      let signUpResponse;

      if (newUserParams.google) {
        const { idToken } = newUserParams.google;
        signUpResponse = isUpdateUserPath
          ? await GoogleLoginAPI(idToken, null, true)
          : await GoogleSignUpAPI(newUserParams.google);
      } else if (newUserParams.apple) {
        const { sub, ...restUserData } = newUserParams.apple;
        const localAppleUserData = getLocalAppleUserData(sub);

        let firstName = localAppleUserData ? localAppleUserData.firstName : '';
        let lastName = localAppleUserData ? localAppleUserData.lastName : '';

        if (restUserData.firstName && restUserData.lastName) {
          firstName = restUserData.firstName;
          lastName = restUserData.lastName;
        }

        const newAppleUserParams = {
          firstName,
          lastName,
          email: restUserData.email,
          idToken: restUserData.idToken
        };

        signUpResponse = await AppleSignUpAPI(newAppleUserParams);

        if (localAppleUserData) {
          removeLocalAppleUserData(sub);
        }
      } else if (newUserParams.facebook) {
        signUpResponse = await FacebookSignUpAPI(newUserParams.facebook);
      }

      if (!signUpResponse.success) {
        setAlert({
          type: 'error',
          message: extractErrorMessage(signUpResponse).message
        });
        setLoadingGoogle(false);
        if (!hideAppleButton) {
          setLoadingApple(false);
        }
        return { unmounting: false, closeDialog: true };
      }

      const { token, appToken } = signUpResponse.data.data;

      if (isUpdateUserPath) {
        setTokenGoogle(token);
        setGoogleData(null);
      }

      updateUser(setCurrentUser, token, appToken);

      if (!closeDialog || isUpdateUserPath) {
        history.replace('/');
      }
      return { unmounting: true, closeDialog: false };
    },
    [
      hideAppleButton,
      newUserParams,
      setAlert,
      setCurrentUser,
      closeDialog,
      isUpdateUserPath,
      setLoadingGoogle,
      setLoadingApple,
      setTokenGoogle,
      setGoogleData
    ]
  );

  useEffect(() => {
    if (googleData) {
      handleGoogleLogin(googleData);
    }
  }, [googleData, handleGoogleLogin]);

  useEffect(() => {
    if (appleData) {
      handleAppleLogin(appleData);
    }
  }, [appleData, handleAppleLogin]);

  return (
    <>
      {confirmationDialog && (
        <ConfirmationDialog
          open={confirmationDialog}
          title={'Términos y condiciones'}
          content={
            <FormControlLabel
              control={
                <Checkbox
                  id="Login_dialog_terms"
                  checked={terms}
                  onClick={onChangeTerms}
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  disabled={Boolean(currentUser)}
                  value="checkedI"
                />
              }
              label={
                <Typography className={classes.termsText}>
                  He leído y acepto los{' '}
                  <a
                    className={classes.link}
                    href={`${ROUTE_NAMES.information}/${
                      TERMS_ROUTES[TERMS.introduction]
                    }`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    términos y condiciones
                  </a>{' '}
                  de uso, la{' '}
                  <a href={PrivacyPoliciesLink}>
                    política y aviso de privacidad
                  </a>{' '}
                  y autorizo el tratamiento de mis datos personales.
                </Typography>
              }
            />
          }
          cancelButtonText="Cancelar"
          submitButtonText="Continuar"
          disabled={!terms}
          setDialog={setConfirmationDialog}
          requestCallback={handleTermsDialog}
        />
      )}
      {!hideSocialButtons && (
        <>
          <SocialButtons
            googleLoading={loadingGoogle}
            appleLoading={loadingApple}
            emailLoading={loading}
            hideGoogleButton={hideGoogleButton}
            hideAppleButton={hideAppleButton}
          />
          <OptionDivider />
        </>
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: 40,
    marginBottom: 20,
    fontSize: 24
  },
  link: {
    color: '#424242',
    fontWeight: 500
  },
  termsText: {
    fontSize: 11,
    [theme.breakpoints.up('sm')]: {
      fontSize: 12
    }
  }
}));

export default SocialLoginButtons;
