import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { SlideUpTransition } from '../../Components/Transitions/Transitions';
import BaseDialog from '../../Components/Dialogs/BaseDialog';

const PinHelp = require('../../Assets/images/certificate_pin_help.png');

function CertificatePinHelpDialog(props) {
  const { open = true, onClose, title = 'Título' } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState(true);

  const onImageLoaded = useCallback(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
  }, []);

  const renderContent = () => {
    return (
      <Fragment>
        <img
          className={classes.image}
          src={PinHelp}
          alt={'Pin Help'}
          onLoad={onImageLoaded}
        />
      </Fragment>
    );
  };

  const isMobileSize = isWidthDown('xs', props.width);
  return (
    <BaseDialog
      open={open}
      loading={loading}
      handleClose={onClose}
      title={title}
      actions={() => null}
      content={renderContent}
      fullScreen={isMobileSize}
      TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
    />
  );
}

const useStyles = makeStyles(theme => ({
  image: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 536,
      height: 236
    }
  }
}));

export default withWidth()(CertificatePinHelpDialog);
