import React, { createContext, useState } from 'react';

export const FooterContext = createContext(null);
export const FooterDispatchContext = createContext(null);

export const FooterProvider = ({ children }) => {
  const [footerVisible, dispatch] = useState(true);

  return (
    <FooterContext.Provider value={footerVisible}>
      <FooterDispatchContext.Provider value={dispatch}>
        {children}
      </FooterDispatchContext.Provider>
    </FooterContext.Provider>
  );
};
