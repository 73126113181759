import React from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import BaseDialog from '../../Components/Dialogs/BaseDialog';
import BaseButton from '../../Components/Buttons/BaseButton';
import { SlideUpTransition } from '../../Components/Transitions/Transitions';

import clsx from 'clsx';
import { Company } from '../../Configs/general';

const PqrSubmitDialog = props => {
  const { open, onClose, data } = props;

  const {
    id,
    service = {},
    location,
    address,
    subject,
    authorizeEmailNotification,
    email,
    notificationAddress
  } = data;

  const classes = useStyles();
  const theme = useTheme();

  const isMobileSize = useMediaQuery(theme.breakpoints.down('xs'));

  const submittedPqrContent = () => {
    return (
      <div id="Pqr_submit_dialog" className={classes.content}>
        <Typography className={classes.dialogText}>
          Hemos generado el número de radicado #
          <span className={classes.dialogBold}>{id}</span> para la solicitud:
        </Typography>
        <Grid container className={classes.detailContainer}>
          <Grid
            item
            sm={3}
            xs={12}
            className={clsx(classes.gridItem, classes.dialogBold)}
          >
            Asunto
          </Grid>
          <Grid item sm={9} xs={12} className={classes.gridItem}>
            {subject}
          </Grid>
          <Grid
            item
            sm={3}
            xs={12}
            className={clsx(classes.gridItem, classes.dialogBold)}
          >
            {service.type === 'contrato'
              ? Company.contractConjugation.capitalized.singular.main
              : 'Tipo de trámite'}
          </Grid>
          <Grid item sm={9} xs={12} className={classes.gridItem}>
            {service.value}
          </Grid>
          <Grid
            item
            sm={3}
            xs={12}
            className={clsx(classes.gridItem, classes.dialogBold)}
          >
            Ciudad
          </Grid>
          <Grid item sm={9} xs={12} className={classes.gridItem}>
            {location}
          </Grid>
          <Grid
            item
            sm={3}
            xs={12}
            className={clsx(classes.gridItem, classes.dialogBold)}
          >
            Dirección
          </Grid>
          <Grid item sm={9} xs={12} className={classes.gridItem}>
            {address}
          </Grid>
          <Grid
            item
            sm={3}
            xs={12}
            className={clsx(classes.gridItem, classes.dialogBold)}
          >
            Medio de respuesta
          </Grid>
          <Grid
            item
            sm={9}
            xs={12}
            className={clsx(classes.gridItem, classes.notificationMedium)}
          >
            <span>
              {authorizeEmailNotification
                ? 'Correo electrónico:'
                : 'Dirección física:'}
            </span>
            <span>
              {authorizeEmailNotification ? email : notificationAddress}
            </span>
          </Grid>
        </Grid>
        <div className={classes.optionsContainer}>
          <BaseButton color="secondary" onClick={onClose}>
            Entendido
          </BaseButton>
        </div>
        <Typography paragraph className={classes.reminderText}>
          Hemos enviado a tu correo la solicitud creada. Recuerda que la
          solicitud tiene 15 días hábiles para ser respondida. Si la solicitud
          se realizó un día no hábil, el tiempo empieza en el siguiente día
          hábil.
        </Typography>
      </div>
    );
  };

  return (
    <BaseDialog
      open={open}
      handleClose={onClose}
      title="PQR enviada"
      actions={null}
      content={submittedPqrContent}
      fullScreen={isMobileSize}
      contentSize="small"
      TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
      disableBackdropClick={true}
    />
  );
};

const useStyles = makeStyles(theme => ({
  dialogText: {
    fontSize: 14,
    textAlign: 'center',
    color: theme.palette.text.primary,
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  dialogBold: {
    fontWeight: 700
  },
  optionsContainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    WebkitFlexWrap: 'wrap',
    msFlexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: theme.spacing(2)
  },
  detailContainer: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.background.border,
    borderRadius: theme.custom.borderRadius,
    backgroundColor: theme.palette.background.cardDark,
    padding: theme.spacing(),
    marginBottom: theme.spacing(2),
    alignItems: 'center'
  },
  gridItem: {
    fontSize: 14,
    padding: theme.spacing(1),
    wordBreak: 'break-word'
  },
  reminderText: {
    fontSize: 11,
    color: '#0000008A',
    marginTop: theme.spacing(),
    textAlign: 'center'
  },
  notificationMedium: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

export default PqrSubmitDialog;
