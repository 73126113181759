import React from 'react';

const PayBillsIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 380.739}
      height={props.height || 252}
      viewBox="0 0 380.739 252"
      {...props}
    >
      <g transform="translate(-529.63 -340)">
        <path
          d="M862.794 463.593H824.94a110.694 110.694 0 00-179-68.243h-38.202a16.849 16.849 0 00-16.819 16.819 16.926 16.926 0 0014.009 16.6h27.227a18.4 18.4 0 0118.356 18.357v2.064a18.4 18.4 0 01-18.356 18.356H577.7a16.849 16.849 0 00-16.819 16.819 16.849 16.849 0 0016.819 16.819h29.028a110.686 110.686 0 00177.239 67.189 16.393 16.393 0 004.435.615h42.861a16.849 16.849 0 0016.818-16.819 16.849 16.849 0 00-16.819-16.819h-25.294a18.011 18.011 0 01-17.961-17.961v-2.371a18.011 18.011 0 0117.961-17.961h40.621a15.47 15.47 0 012.9.263h13.218a16.849 16.849 0 0016.819-16.819 16.765 16.765 0 00-16.732-16.908zM562.83 428.989h-17.962a15.312 15.312 0 01-15.238-15.282v-3.162a15.3 15.3 0 0115.238-15.238h17.918a15.275 15.275 0 0115.238 15.238v3.162a15.246 15.246 0 01-15.194 15.282zM895.132 568.989h-17.918a15.275 15.275 0 01-15.238-15.239v-3.162a15.275 15.275 0 0115.238-15.238h17.917a15.275 15.275 0 0115.238 15.238v3.162a15.3 15.3 0 01-15.237 15.239z"
          fill="#eef9fb"
        />
        <circle
          cx={6.777}
          cy={6.777}
          transform="translate(618.91 342)"
          fill="none"
          stroke="#63d8f1"
          strokeMiterlimit={10}
          strokeWidth={4}
          r={6.777}
        />
        <circle
          cx={6.777}
          cy={6.777}
          transform="translate(827.611 440.831)"
          fill="none"
          stroke="#63d8f1"
          strokeMiterlimit={10}
          strokeWidth={4}
          r={6.777}
        />
        <path
          d="M630.256 453.821h-10.475v-10.474a2.259 2.259 0 10-4.518 0v10.474H604.74a2.259 2.259 0 100 4.518h10.474v10.474a2.259 2.259 0 004.518 0v-10.474h10.474a2.279 2.279 0 002.259-2.259 2.2 2.2 0 00-2.209-2.259zM882.238 481.647l-5.75-5.75 5.75-5.75a1.743 1.743 0 10-2.464-2.464l-5.75 5.75-5.75-5.75a1.743 1.743 0 10-2.464 2.464l5.75 5.75-5.75 5.75a1.743 1.743 0 002.464 2.464l5.75-5.75 5.75 5.75a1.767 1.767 0 002.464 0 1.678 1.678 0 000-2.464zM650.213 538.159h131.713c3.29 0 3.29-5.105 0-5.105H650.213c-3.29 0-3.29 5.105 0 5.105zM798.945 538.159h9.359c3.29 0 3.29-5.105 0-5.105h-9.359c-3.29 0-3.29 5.105 0 5.105zM604.948 538.159h29.383c3.29 0 3.29-5.105 0-5.105h-29.383c-3.29 0-3.29 5.105 0 5.105z"
          fill="#63d8f1"
        />
        <g fill="none" stroke="#104eb2" strokeLinejoin="round" strokeWidth={4}>
          <path d="M677.204 483.865v-57.113l6.346 3.173 6.346-3.173 6.334 3.173 6.419-3.173 6.284 3.173 6.3-3.173 6.332 3.173 6.4-3.173 6.348 3.173 6.35-3.173v44.421" />
          <path d="M740.663 471.173v22.211a15.865 15.865 0 01-15.865 15.865h0a15.865 15.865 0 01-15.865-15.865v-9.519h-53.94a3.145 3.145 0 00-3.172 3.173c0 12.692 1.337 22.211 15.865 22.211h57.113" />
          <path
            d="M689.896 445.79h38.075m-25.384 15.865h25.384"
            strokeLinecap="round"
          />
        </g>
        <g transform="translate(-68.681 174)">
          <circle
            cx={30}
            cy={30}
            r={30}
            transform="translate(789 289)"
            fill="#eef9fb"
          />
          <g
            transform="translate(716.532 223)"
            fill="rgba(0,0,0,0)"
            stroke="#104eb2"
            strokeWidth={4}
          >
            <path
              d="M102.468 92.426V82.213M102.468 74.043V72m-15.83 7.66l-1.532-1.532"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <circle
              cx={4.085}
              cy={4.085}
              r={4.085}
              transform="translate(98.383 93.447)"
              strokeMiterlimit={10}
            />
            <path
              d="M102.468 75.064a22.468 22.468 0 1022.468 22.468 22.468 22.468 0 00-22.468-22.468z"
              strokeMiterlimit={10}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PayBillsIcon;
