import React from 'react';
import { portal, Portal } from '../../../../Configs/general';

import DrawerGDC from './Drawer.GDC';
import DrawerGDG from './Drawer.GDG';

const Drawer = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <DrawerGDC {...props} />;
    case Portal.Gasguajira:
      return <DrawerGDG {...props} />;
    case Portal.Efigas:
      return <DrawerGDG {...props} />;
    case Portal.TripleA:
      return <DrawerGDG {...props} />;
    default:
      return <></>;
  }
};

export default Drawer;
