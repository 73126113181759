import * as React from 'react';

function SvgIcSiniestro(props) {
  return (
    <svg
      id="ic-siniestro"
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '32'}
      height={props.height || '32'}
      viewBox={`0 0 ${props.width || '32'} ${props.height || '32'}`}
      {...props}
    >
      <g id="Grupo_801" data-name="Grupo 801">
        <path
          id="Trazado_692"
          data-name="Trazado 692"
          d="M0,0H32V32H0Z"
          fill="none"
        />
        <line
          id="Línea_12"
          data-name="Línea 12"
          x2="24"
          transform="translate(4 28)"
          fill="none"
          stroke={props.color || '#fff'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Trazado_693"
          data-name="Trazado 693"
          d="M5,27V5.667A2.667,2.667,0,0,1,7.667,3H21a2.667,2.667,0,0,1,2.667,2.667V27"
          transform="translate(1.667 1)"
          fill="none"
          stroke={props.color || '#fff'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Trazado_694"
          data-name="Trazado 694"
          d="M9,23V17.667A2.667,2.667,0,0,1,11.667,15h2.667A2.667,2.667,0,0,1,17,17.667V23"
          transform="translate(3 5)"
          fill="none"
          stroke={props.color || '#fff'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          id="Línea_13"
          data-name="Línea 13"
          x2="5.333"
          transform="translate(13.333 12)"
          fill="none"
          stroke={props.color || '#fff'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <line
          id="Línea_14"
          data-name="Línea 14"
          y2="5.333"
          transform="translate(16 9.333)"
          fill="none"
          stroke={props.color || '#fff'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
}

export default SvgIcSiniestro;
