import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import RequestGDC from './Request.GDC';

const Request = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <RequestGDC {...props} />;
    case Portal.Efigas:
      return <RequestGDC {...props} />;
    default:
      return <></>;
  }
};

export default Request;
