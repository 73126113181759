import React, { createContext, useEffect, useState } from 'react';
import { config } from '../Configs';
import { deserializeToken } from '../Utils/JWT/token';
export const GoogleClientContext = createContext(null);
export const GoogleClientDispatchContext = createContext(null);
export const GoogleClientInitializedContext = createContext(null);

export const GoogleClientProvider = ({ children }) => {
  const [data, dispatch] = useState(null);
  const [currentInterval, setCurrentInterval] = useState(null);
  const [isGoogleInitialized, setIsGoogleInitialized] = useState(false);

  const handleCredentialResponse = response => {
    const encodedToken = response.credential;

    const decodedToken = deserializeToken(encodedToken);

    const {
      sub: tokenId,
      email,
      given_name: givenName = '',
      family_name: familyName = ''
    } = decodedToken;

    dispatch({
      encodedToken,
      tokenId,
      email,
      givenName,
      familyName
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.google) {
        setIsGoogleInitialized(true);
        window.google.accounts.id.initialize({
          // eslint-disable-next-line camelcase
          client_id: config.google.clientId,
          callback: handleCredentialResponse
        });
      } else {
        setIsGoogleInitialized(false);
      }
    }, 1000);

    setCurrentInterval(interval);
  }, []);

  useEffect(() => {
    if (isGoogleInitialized) {
      clearInterval(currentInterval);
      setCurrentInterval(null);
    }
  }, [isGoogleInitialized, currentInterval]);

  return (
    <GoogleClientContext.Provider value={data}>
      <GoogleClientDispatchContext.Provider value={dispatch}>
        <GoogleClientInitializedContext.Provider value={isGoogleInitialized}>
          {children}
        </GoogleClientInitializedContext.Provider>
      </GoogleClientDispatchContext.Provider>
    </GoogleClientContext.Provider>
  );
};
