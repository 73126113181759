import React, { useCallback, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HomeIcon from '../../../../Components/CustomIcons/HomeIcon';
import InfoIcon from '../../../../Components/CustomIcons/InfoIcon';
import ContractsIcon from '../../../../Components/CustomIcons/ContractsIcon';
import UserIcon from '../../../../Components/CustomIcons/UserIcon';
import RequestsIcon from '../../../../Components/CustomIcons/RequestsIcon';
import TransactionsIcon from '../../../../Components/CustomIcons/TransactionsIcon';
import PqrIcon from '../../../../Components/CustomIcons/CustomerService';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuIcon from '@material-ui/icons/Menu';
import LogoutIcon from '../../../../Components/CustomIcons/IcLogout';
import FlatButton from '../../../../Components/Buttons/FlatButton';
import MenuButton from '../../../../Components/Buttons/MenuButton';
import PQRHelpDialog from '../../../../Components/Dialogs/PQRHelpDialog';

import {
  redirectOnAuthFailure,
  extractErrorMessage
} from '../../../../Utils/Errors/Errors';
import { history } from '../../../../Routes/history';
import { ROUTE_NAMES } from '../../../../Routes/Routes';
import { HEADER_MAP_ICON } from '../header_enums';

import { UserDispatchContext } from '../../../../Contexts/UserContext';
import {
  ContractsContext,
  ContractSelectedContext,
  ContractSetSelected
} from '../../../../Contexts/ContractsContext';
import { UserContext } from '../../../../Contexts/UserContext';
import { AlertsDispatchContext } from '../../../../Contexts/AlertsContext';

import { PutSelectContract } from '../../../../API/Contracts/ContractsAPI';

import Drawer from '../Drawer/Drawer';
import MobileDrawer from '../MobileDrawer/MobileDrawer';
import { logoutUser } from '../../../../Utils/User/Actions';

const AuthHeaderEFG = () => {
  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const userContracts = useContext(ContractsContext);
  const selectedContract = useContext(ContractSelectedContext);
  const setSelected = useContext(ContractSetSelected);
  const setAlert = useContext(AlertsDispatchContext);

  const [drawer, setDrawer] = useState(false);
  const [openPqrDialog, setPqrDialogOpen] = useState(false);
  // * OTHER HOOKS
  const classes = useStyles();

  const NAVIGATION_OPTIONS = [
    {
      id: ROUTE_NAMES.contracts,
      label: 'Mis contratos',
      image: ContractsIcon,
      onClick: () => history.push(ROUTE_NAMES.contracts)
    },
    {
      id: ROUTE_NAMES.requests,
      label: 'Mis solicitudes',
      image: RequestsIcon,
      onClick: () => history.push(ROUTE_NAMES.requests)
    },
    {
      id: ROUTE_NAMES.transactions,
      label: 'Mis transacciones',
      image: TransactionsIcon,
      onClick: () => history.push(ROUTE_NAMES.transactions)
    },
    {
      id: ROUTE_NAMES.pqr,
      label: 'PQR',
      image: PqrIcon,
      onClick: () => setPqrDialogOpen(true)
    },
    {
      id: ROUTE_NAMES.profile,
      label: 'Mi perfil',
      image: UserIcon,
      onClick: () => history.push(ROUTE_NAMES.profile)
    }
  ];

  // * FUNCTIONS

  const pqrClickHandler = () => {
    setPqrDialogOpen(false);
    history.push(ROUTE_NAMES.pqr, { modal: false });
  };

  const logout = useCallback(() => {
    logoutUser(setCurrentUser);

    history.push('/');
  }, [setCurrentUser]);

  const toggleDrawer = useCallback(() => {
    setDrawer(!drawer);
  }, [drawer]);

  const selectContract = useCallback(
    async contract => {
      setSelected(contract);
      const response = await PutSelectContract(currentUser.token, contract.id);
      if (response.success) {
        // no success message, its all good
      } else {
        if (
          redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
        ) {
          return;
        }
        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
      }
    },
    [setSelected, currentUser, setCurrentUser, setAlert]
  );

  return (
    <div className={classes.root}>
      <PQRHelpDialog
        open={openPqrDialog}
        onClose={() => setPqrDialogOpen(false)}
        onPqrButtonClick={pqrClickHandler}
      />
      <div
        className={`${classes.buttonContainer} ${classes.buttonLargeScreen}`}
      >
        <FlatButton
          id="AuthHeader_button_home"
          color="primary"
          className={
            window.location.pathname === '/'
              ? classes.menuButtonLocation
              : classes.menuButton
          }
          onClick={() => history.push('/')}
        >
          <HomeIcon size={18} className={classes.iconCurrentColor} />
          Inicio
        </FlatButton>
      </div>
      <div className={classes.buttonContainer}>
        {selectedContract ? (
          <MenuButton
            selectable
            color="primary"
            className={classes.menuButton}
            key={selectedContract.id}
            options={userContracts.map(contr => {
              return {
                id: contr.id,
                label: contr.alias,
                image: HEADER_MAP_ICON[contr.icon],
                onClick: () => selectContract(contr)
              };
            })}
            otherOptions={[
              {
                id: -1,
                label: 'Asociar nuevo contrato',
                image: HEADER_MAP_ICON.plus,
                onClick: () => history.push(ROUTE_NAMES.associate)
              }
            ]}
            initialSelected={selectedContract}
            onClick={() => {
              setDrawer(false);
            }}
          >
            {HEADER_MAP_ICON[selectedContract.icon]({
              size: 20,
              className: classes.iconBlue
            })}
            {selectedContract.alias}
            <ArrowDownIcon className={classes.iconArrow} />
          </MenuButton>
        ) : (
          <FlatButton
            id="AuthHeader_button_no_contract"
            color="primary"
            className={
              window.location.pathname === ROUTE_NAMES.associate
                ? classes.menuButtonLocation
                : classes.menuButton
            }
            onClick={() => history.push(ROUTE_NAMES.associate)}
          >
            <InfoIcon size={18} className={classes.iconBlue} />
            Sin contrato
          </FlatButton>
        )}
      </div>
      <div
        className={`${classes.buttonContainer} ${classes.buttonSmallScreen}`}
      >
        <FlatButton onClick={toggleDrawer}>
          <MenuIcon size={24} />
        </FlatButton>
        <MobileDrawer
          open={drawer}
          setDrawer={setDrawer}
          logout={logout}
          setPqrDialogOpen={setPqrDialogOpen}
        />
      </div>
      <div
        className={`${classes.buttonContainer} ${classes.buttonLargeScreen}`}
      >
        <Drawer
          color="primary"
          className={classes.menuButton}
          options={NAVIGATION_OPTIONS}
          otherOptions={[
            {
              id: -1,
              label: 'Cerrar sesión',
              image: LogoutIcon,
              onClick: logout
            }
          ]}
        >
          <UserIcon size={20} className={classes.iconBlue} />
          {currentUser.firstName}
          <ArrowDownIcon className={classes.iconArrow} />
        </Drawer>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  buttonContainer: {
    marginLeft: theme.spacing(),
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
      marginLeft: theme.spacing(2)
    }
  },
  buttonLargeScreen: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    }
  },
  buttonSmallScreen: {
    display: 'flex',
    marginLeft: 16,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  menuButton: {
    color: theme.palette.text.main,
    height: theme.custom.headerHeight
  },
  menuButtonLocation: {
    color: theme.palette.color.primary,
    fill: theme.palette.color.primary
  },
  iconCurrentColor: {
    fill: 'currentColor',
    marginRight: theme.spacing()
  },
  iconBlue: {
    fill: theme.palette.primary.main,
    color: theme.palette.primary.main,
    marginRight: theme.spacing()
  },
  iconArrow: {
    fontSize: '1.3rem'
  },
  focusVisible: {}
}));

export default AuthHeaderEFG;
