import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import InvoiceHelpDialogGDC from './InvoiceHelpDialog.GDC';
import InvoiceHelpDialogGDG from './InvoiceHelpDialog.GDG';
import InvoiceHelpDialogEFG from './InvoiceHelpDialog.EFG';

const InvoiceHelpDialog = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <InvoiceHelpDialogGDC {...props} />;
    case Portal.Gasguajira:
      return <InvoiceHelpDialogGDG {...props} />;
    case Portal.Efigas:
      return <InvoiceHelpDialogEFG {...props} />;
    default:
      return <></>;
  }
};

export default InvoiceHelpDialog;
