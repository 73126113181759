/* eslint-disable no-inline-comments */
import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import {
  Container,
  Typography,
  GridList,
  GridListTile
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HelpOutline from '@material-ui/icons/HelpOutline';
import { IconButton } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';

import { history } from '../../../Routes/history';
import { ROUTE_NAMES } from '../../../Routes/Routes';

import ImageButton from '../../../Components/Buttons/ImageButton';
import InvoiceHelpDialog from '../../../Components/Dialogs/InvoiceHelpDialog/InvoiceHelpDialog';
import { HELP_TEXT } from '../../../Utils/enums';
import ContractButton from '../../../Components/CustomIcons/IcContractButton';
import CouponButton from '../../../Components/CustomIcons/IcCouponButton';

import { Company } from '../../../Configs/general';

const NoAuthHomeAAA = ({ width }) => {
  // * STATE HOOKS
  const [helpDialogType, setHelpDialogType] = useState(null);

  // * OTHER HOOKS
  const classes = useStyles();

  // * FUNCTIONS

  const _handleRoute = method => {
    history.push(ROUTE_NAMES.payments, {
      type: method,
      value: ''
    });
  };

  const _goToSignup = () => {
    history.push(ROUTE_NAMES.login);
  };

  const renderButtonText = (text, type) => {
    return (
      <Fragment>
        {text}
        <IconButton
          onClick={() => setHelpDialogType(type)}
          className={classes.helpIcon}
          aria-label="help"
        >
          <HelpOutline fontSize="small" />
        </IconButton>
      </Fragment>
    );
  };

  const deviceColumns = () => {
    switch (width) {
      case 'xs':
      case 'xm':
        return 2;
      case 'sm':
        return 3;
      default:
        return 4;
    }
  };

  return (
    <Container
      id="NoAuthHome_div_container"
      maxWidth="md"
      className={classes.homeContainer}
    >
      <InvoiceHelpDialog
        open={Boolean(helpDialogType)}
        type={helpDialogType}
        onClose={() => setHelpDialogType(null)}
        title={HELP_TEXT[helpDialogType]}
      />
      <Typography variant="h2" className={clsx(classes.text, classes.title)}>
        Portal de servicio al cliente de{' '}
        <span className={classes.titleCompany}>{Company.name}.</span>
      </Typography>
      <Container maxWidth="sm" className={classes.textAreaContainer}>
        <Typography
          variant="h2"
          className={clsx(classes.text, classes.welcome)}
        >
          ¡Bienvenido!
        </Typography>
        <Typography
          variant="h6"
          className={clsx(classes.text, classes.subText)}
        >
          Para realizar los trámites de tu servicio de agua, de forma fácil y
          segura.{' '}
          <span className={classes.loginText} onClick={_goToSignup}>
            Iniciar sesión
          </span>
        </Typography>
      </Container>
      <GridList
        cellHeight={225}
        cols={deviceColumns()}
        classes={{ root: classes.optionsContainer }}
      >
        <GridListTile classes={{ tile: classes.optionTile }}>
          <ImageButton
            id="NoAuthHome_button_coupon"
            icon={CouponButton}
            size="l"
            title={renderButtonText('Paga con tu número de cupón', 'coupon')}
            aria-label="paga_con_tu_cupón"
            onClick={() => _handleRoute('coupon')}
          />
        </GridListTile>
        <GridListTile classes={{ tile: classes.optionTile }}>
          <ImageButton
            id="NoAuthHome_button_contract"
            icon={ContractButton}
            size="l"
            title={renderButtonText(
              `Paga con tu número de ${Company.contractConjugation.regular.singular.main}`,
              'contract'
            )}
            aria-label="paga_con_tu_contrato"
            onClick={() => _handleRoute('contract')}
          />
        </GridListTile>
      </GridList>
    </Container>
  );
};

const useStyles = makeStyles(theme => ({
  homeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '5vh'
  },
  text: {
    textAlign: 'center',
    lineHeight: 1.43
  },
  title: {
    // * Responsive (with banner)
    [theme.breakpoints.up('xs')]: {
      fontSize: '1em',
      paddingTop: 0 // (30)
    },
    [theme.breakpoints.up('xm')]: {
      fontSize: '1.25em',
      paddingTop: 0 // (35)
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 28,
      paddingTop: 10 // 40
    }
  },
  textAreaContainer: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('xm')]: {
      marginTop: theme.spacing(3)
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(4)
    }
  },
  welcome: {
    // * Responsive
    [theme.breakpoints.up('xs')]: {
      fontSize: '2em'
    },
    [theme.breakpoints.up('xm')]: {
      fontSize: '2.25em'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '3em'
    }
  },
  titleCompany: {
    fontWeight: 'bold'
  },
  subText: {
    color: theme.palette.color.default,
    // * Responsive
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(2.5),
      fontSize: '0.75em'
    },
    [theme.breakpoints.up('xm')]: {
      marginTop: theme.spacing(3),
      fontSize: '0.83em'
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(4),
      fontSize: '1em',
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5)
    }
  },
  loginText: {
    color: theme.palette.primary.dark,
    cursor: 'pointer',
    fontWeight: 600,
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  selectPayment: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    height: 200,
    // * Responsive
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(3),
      fontSize: '0.75em'
    },
    [theme.breakpoints.up('xm')]: {
      marginTop: theme.spacing(3.5),
      marginLeft: theme.spacing(1)
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(4)
    }
  },
  helpIcon: {
    padding: 0,
    marginLeft: 5
  },
  optionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 250,
    width: '100%',
    marginTop: '38px !important',
    marginBottom: '38px !important',
    overflowY: 'unset !important',
    [theme.breakpoints.up('xs')]: {
      marginTop: '20px !important'
    },
    [theme.breakpoints.up('xm')]: {
      marginTop: '28px !important'
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: '38px !important'
    }
  },
  optionTile: {
    display: 'flex',
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(4.5),
    paddingRight: theme.spacing(4.5)
  }
}));

export default withWidth()(NoAuthHomeAAA);
