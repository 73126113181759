import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import TransactionCardGDC from './TransactionCard.GDC';
import TransactionCardGDG from './TransactionCard.GDG';

const TransactionCard = props => {
  switch (portal) {
    case Portal.Gascaribe:
    case Portal.TripleA:
      return <TransactionCardGDC {...props} />;
    case Portal.Gasguajira:
      return <TransactionCardGDG {...props} />;
    case Portal.Efigas:
      return <TransactionCardGDC {...props} />;
    default:
      return <></>;
  }
};

export default TransactionCard;
