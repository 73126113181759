import React from 'react';
import { Grid, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { numberWithDots } from '../../../Utils/Format/MoneyFormat';
import { Company } from '../../../Configs/general';

function ContractDebtDialogContent({ data, ...props }) {
  const { contract, contractAddress, contractType } = props;
  const { deferredSummary } = props;
  const { gasPendingBalance, financingServicesBalance } = props;

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} sm={12} md={3}>
          <Typography className={classes.label}>
            {Company.contractConjugation.capitalized.singular.associate} a la
            deuda
          </Typography>
          <Typography className={classes.contractData}>
            {contract.alias} - {contract.id}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Typography className={classes.label}>
            Dirección {Company.contractConjugation.regular.singular.contraction}
          </Typography>
          <Typography className={classes.contractData}>
            {contractAddress}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Typography className={classes.label}>
            Tipo de {Company.contractConjugation.regular.singular.main}
          </Typography>
          <Typography className={classes.contractData}>
            {contractType}
          </Typography>
        </Grid>
      </Grid>

      {deferredSummary && (
        <>
          <Divider className={classes.divider} />
          <Grid item container spacing={1} alignItems="flex-start">
            <Grid item xs={12} md={3}>
              <Typography className={classes.label}>Próximo pago</Typography>
              <Typography className={classes.contractData}>
                {data.deferredValue !== 0 &&
                  data.deferredNextPayment &&
                  `${numberWithDots(data.deferredNextPayment.value)} `}
                {data.deferredNextPayment &&
                  data.deferredNextPayment.date &&
                  `(${data.deferredNextPayment.date})`}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography className={classes.label}>
                Saldo por facturar Servicios financieros
              </Typography>
              <Typography className={classes.contractData}>
                {financingServicesBalance}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography className={classes.label}>
                Saldo por facturar Gas
              </Typography>
              <Typography className={classes.contractData}>
                {gasPendingBalance}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 12,
    fontWeight: 500
  },
  contractData: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.2,
    textTransform: 'capitalize',
    marginBottom: theme.spacing(2)
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.divider,
    margin: theme.spacing(2, 0, 2, 0)
  }
}));

export default ContractDebtDialogContent;
