import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useMemo,
  useContext
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SvgConfirmEmail from '../../Components/CustomIcons/ConfirmEmailIcon';
import { Typography } from '@material-ui/core';
import BaseButton from '../../Components/Buttons/BaseButton';
import { ResendEmailValidation } from '../../API/UserAPI';
import { UserContext } from '../../Contexts/UserContext';
import { ROUTE_NAMES } from '../../Routes/Routes';

const RESEND_FLAG_SECONDS_DELAY = 120;

const ConfirmEmail = ({ history, location }) => {
  // * OTHER HOOKS
  const classes = useStyles();

  const currentUser = useContext(UserContext);

  const [counter, setCounter] = useState(RESEND_FLAG_SECONDS_DELAY);
  const [canResend, setCanResend] = useState(false);
  const [timer, setTimer] = useState(null);

  const resetResendFlag = useCallback(() => {
    const countdown = c => c - 1;
    const interval = setInterval(() => {
      setCounter(countdown);
    }, 1000);
    setTimer(interval);
  }, []);

  useEffect(() => {
    if (currentUser) {
      history.replace('/');
    }
  }, [currentUser, history]);

  useEffect(() => {
    return () => clearInterval(timer);
  }, [timer]);

  useEffect(() => {
    if (counter <= 0) {
      clearInterval(timer);
      setTimer(null);
      setCanResend(true);
    }
  }, [counter, timer]);

  useEffect(() => {
    if (!location.state) {
      history.replace('/');
      return;
    }
    resetResendFlag();
  }, [history, location, resetResendFlag]);

  const handleClick = useCallback(async () => {
    const response = await ResendEmailValidation(location.state.email);
    setCounter(RESEND_FLAG_SECONDS_DELAY);
    if (response.success) {
      setCanResend(false);
      resetResendFlag();
    } else {
      setCanResend(true);
    }
  }, [location, resetResendFlag]);

  const buttonChildren = useMemo(() => {
    if (counter > 0) {
      const minutes = Math.floor(counter / 60);
      let seconds = counter - minutes * 60;
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      return `${minutes}:${seconds}`;
    }
    return 'Reenviar correo';
  }, [counter]);

  if (currentUser || !location.state) {
    return <Fragment></Fragment>;
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <SvgConfirmEmail height={250} width={400} />
        <Typography className={classes.item} variant="subtitle1">
          <strong>Revisa tu correo electrónico</strong>
        </Typography>
        <Typography className={classes.item} variant="subtitle1">
          Hemos enviado un enlace a{' '}
          <span className={classes.email}>{location.state.email}</span> para
          activar tu cuenta
        </Typography>
        <BaseButton
          className={classes.button}
          disabled={!canResend || counter > 0}
          onClick={handleClick}
        >
          {buttonChildren}
        </BaseButton>
        {!canResend && (
          <Typography className={classes.item} variant="caption">
            Si el correo no te ha llegado en 2 minutos, puedes volver a enviar
            la verificación
          </Typography>
        )}
        <Typography
          className={classes.incorrectEmail}
          onClick={() =>
            history.push(ROUTE_NAMES.changeEmail, {
              email: location.state.email
            })
          }
        >
          ¿No es tu correo?
        </Typography>
      </div>
    </React.Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0)
    }
  },
  item: {
    margin: '0.2em 0'
  },
  button: {
    margin: '0.8em 0',
    minWidth: 200
  },
  email: {
    fontWeight: 500,
    fontStyle: 'italic'
  },
  incorrectEmail: {
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    color: theme.palette.text.main,
    paddingTop: 8,
    fontWeight: 500,
    '&:hover': {
      fontWeight: 600
    }
  }
}));

export default ConfirmEmail;
