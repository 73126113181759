import React from 'react';
import './circle.style.css';

const ProgressCircle = props => {
  const { size = 32 } = props;

  return (
    <svg
      className="spinner"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="path"
        fill="none"
        strokeWidth="6"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      ></circle>
    </svg>
  );
};

export default ProgressCircle;
