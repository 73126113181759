import React, { Fragment } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BaseButton from '../../../Components/Buttons/BaseButton';
import SimpleDivider from '../../../Components/Dividers/SimpleDivider';
import CanceledIcon from '@material-ui/icons/RemoveCircleRounded';
import { BrillaFinancingLink } from '../../../Configs/Links';
import { OpenNewTab } from '../../../Utils/Misc/Links';

const NoQuotaDetailGDG = props => {
  const { reasons } = props;

  // * OTHER HOOKS
  const classes = useStyles();

  const renderReasons = () => {
    return (
      <Fragment>
        <SimpleDivider withoutMargin classes={classes.preReasonsDivider} />
        <Typography className={classes.reasonsTitle}>
          Razones por las cual no posee cupo brilla
        </Typography>
        <SimpleDivider withoutMargin classes={classes.reasonsDivider} />
        <Fragment>
          {reasons.map((reason, i) => (
            <Fragment key={i}>
              <p className={classes.reason}>{reason}</p>
              {i < reasons.length - 1 && (
                <SimpleDivider classes={classes.internalReasonDivider} />
              )}
            </Fragment>
          ))}
        </Fragment>
      </Fragment>
    );
  };
  // * FUNCTIONS
  return (
    <div className={classes.noQuotaDetailContainer}>
      <div>
        <Typography className={classes.noQuotaTitle}>
          <CanceledIcon className={classes.noQuotaIcon} /> Sin cupo disponible
        </Typography>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className={classes.buttonContainerItem}
        >
          <Grid className={classes.buttonContainerItem}>
            <BaseButton
              fullWidth
              color="primary"
              variant="outlined"
              onClick={() => OpenNewTab(BrillaFinancingLink)}
            >
              ¿Qué puedo financiar?
            </BaseButton>
          </Grid>
        </Grid>
      </div>
      {renderReasons()}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  reasonsTitle: {
    fontWeight: 500,
    fontSize: 13
  },
  preReasonsDivider: {
    marginBottom: '4.7%',
    marginTop: '4.7%'
  },
  reasonsDivider: {
    borderColor: 'rgb(185, 185, 185)'
  },
  reason: {
    fontSize: 11,
    textAlign: 'left',
    paddingLeft: 5,
    marginBottom: 12
  },
  internalReasonDivider: {
    marginTop: 0,
    marginBottom: theme.spacing(1)
  },
  noQuotaDetailContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      alignItems: 'flex-start'
    }
  },
  noQuotaTitle: {
    fontSize: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  noQuotaIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.error.main
  },
  buttonContainerItem: {
    marginTop: theme.spacing(1),
    direction: 'row',
    flexGrow: 1,
    flexBasis: 190,
    gap: `${theme.spacing(2)}px`
  }
}));

export default NoQuotaDetailGDG;
