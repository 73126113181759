import * as React from 'react';

const SvgWhatsapp = props => {
  return (
    <svg
      width={props.size || 30.974}
      height={props.size || 30.975}
      viewBox={props.viewBox || '0 0 30.974 30.975'}
      fill={'#183F8F'}
      {...props}
    >
      <path
        data-name="Exclusi\xF3n 1"
        d="M15.491 30.975a15.375 15.375 0 01-8.519-2.56l-5.954 1.9 1.931-5.753A15.322 15.322 0 010 15.487 15.5 15.5 0 0115.484 0h.007a15.488 15.488 0 010 30.975zM9.656 7.084a2.249 2.249 0 00-1.584.555l-.041.042a4.953 4.953 0 00-1.542 3.728 8.568 8.568 0 001.817 4.642l.022.03.1.138a18.378 18.378 0 007.614 6.672 13.179 13.179 0 004.537 1.268 4.04 4.04 0 00.888-.1A4.374 4.374 0 0024.5 21.87a3.883 3.883 0 00.266-2.148c-.088-.153-.3-.252-.609-.4-.08-.038-.165-.079-.257-.125-.548-.274-2.678-1.324-3.085-1.466a1.121 1.121 0 00-.393-.079.864.864 0 00-.718.416l-.18.253a15.063 15.063 0 01-1.029 1.35.876.876 0 01-.648.262 1.175 1.175 0 01-.445-.09l-.138-.056a11.387 11.387 0 01-3.492-2.187 13.684 13.684 0 01-2.513-3.122.715.715 0 01.169-.949l.013-.015c.132-.164.263-.3.39-.436.092-.1.188-.2.284-.311l.039-.045a2.5 2.5 0 00.462-.665.92.92 0 00-.066-.839c-.077-.161-.546-1.3-.959-2.3l-.433-1.049c-.295-.706-.514-.745-.984-.765h-.069a4.693 4.693 0 00-.449-.02z"
      />
    </svg>
  );
};

export default SvgWhatsapp;
