import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useCallback
} from 'react';
import {
  Grid,
  MenuItem,
  Container,
  useMediaQuery,
  Typography,
  Box
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import moment from 'moment';

import { UserContext } from '../../../Contexts/UserContext';
import { ContractsContext } from '../../../Contexts/ContractsContext';

import Title from '../../../Components/Labels/Title';
import RequestList from '../TabViews/RequestList';
import BaseFilter from '../../../Components/Filters/BaseFilter';
import FilterInput from '../../../Components/Inputs/FilterInput';
import SimpleDivider from '../../../Components/Dividers/SimpleDivider';
import { history } from '../../../Routes/history';
import { requestTypes, renderType, requestStatus } from '../Utils/enums';
import { Company } from '../../../Configs/general';
import LoginDialog from '../../Login/LoginDialog';
import TextInput from '../../../Components/Inputs/TextInput';
import CleanFilters from '../../../Components/Filters/CleanFilters';

const defaultValues = {
  contractId: '',
  type: '',
  status: '',
  requestId: '',
  startDate: '',
  endDate: ''
};

const showClearFilters = filters => {
  const res = Boolean(
    filters.type ||
      filters.contractId ||
      filters.status ||
      filters.requestId ||
      filters.startDate ||
      filters.endDate
  );

  return res;
};

const RequestsGDC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const mediumScreen = useMediaQuery(
    theme.breakpoints.between(750, theme.breakpoints.values.md)
  );
  const smallScreen = useMediaQuery(theme.breakpoints.down(750));

  // * STATE HOOKS
  const [filterValues, setFilterValues] = useState(defaultValues);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [page, setPage] = useState(1);
  // States for mobile filters
  const [mobileFiltersValues, setMobileFiltersValues] = useState({
    ...defaultValues
  });
  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const contracts = useContext(ContractsContext);

  useEffect(() => {
    let validFilters = {};

    Object.keys(filterValues).forEach(key => {
      if (filterValues[key]) {
        validFilters = { ...validFilters, [key]: filterValues[key] };
      }
    });
    setSelectedFilters(validFilters);
    setPage(1);
  }, [filterValues]);

  const resetField = field => {
    if (smallScreen) {
      setMobileFiltersValues(prev => ({
        ...prev,
        [field]: defaultValues[field]
      }));
    } else {
      setFilterValues(prev => ({ ...prev, [field]: defaultValues[field] }));
    }
  };

  const setFilterValue = ({ target: { name, value } }) => {
    setFilterValues({ ...filterValues, [name]: value });
    setPage(1);
  };

  const handleCloseMobileFilters = setOpenInnerState => {
    // If the user return without clicking "Filtrar", filter values must be reset
    setMobileFiltersValues(defaultValues);
    setOpenInnerState(false);
  };

  const renderContracts = () => {
    if (contracts && contracts.length > 0) {
      return contracts.map(contract => ({
        label: contract.alias,
        value: contract.id
      }));
    }

    return [];
  };

  const renderTypes = () => {
    return Object.keys(requestTypes).map(key => ({
      label: renderType(requestTypes[key]),
      value: requestTypes[key]
    }));
  };

  const renderStatusOptions = () => {
    return [
      {
        label: 'Pendiente',
        value: 'pending'
      },
      { label: 'Atendida', value: requestStatus.attended },
      { label: 'Rechazada', value: requestStatus.cancelled }
    ];
  };

  const clearFilters = () => {
    setFilterValues(defaultValues);
    setMobileFiltersValues(defaultValues);
    setPage(1);
  };

  // Triggers when "Filtrar" button is pushed (Dialog)
  const handleSetMobileFilters = setOpenInnerState => {
    if (setOpenInnerState) {
      setFilterValues({ ...mobileFiltersValues });
      setMobileFiltersValues(defaultValues);
      setPage(1);
      setOpenInnerState(false);
      return;
    }
  };

  const handleOpenMobileFilters = setOpenInnerState => {
    setOpenInnerState(true);
    setMobileFiltersValues(filterValues);
  };

  const handleMobileFilters = ({ target: { name, value } }) => {
    setMobileFiltersValues(prev => ({ ...prev, [name]: value }));
  };

  const handleGeneralInputChange = event => {
    if (smallScreen) {
      handleMobileFilters(event);
    } else {
      setFilterValue(event);
    }
  };

  const filterInputValues = smallScreen ? mobileFiltersValues : filterValues;

  const handleCloseLoginDialog = useCallback(() => {
    history.replace('/');
  }, []);

  const renderClearFilters = () => (
    <CleanFilters className={classes.clearFilters} onClick={clearFilters} />
  );

  const renderFilters = () => {
    return (
      <Grid container spacing={2} className={classes.filterContainer}>
        <Grid item className={classes.inputContainer}>
          <FilterInput
            name="contractId"
            value={filterInputValues.contractId}
            clearable={filterInputValues.contractId}
            disabled={renderContracts().length === 0}
            renderOption={option => (
              <MenuItem
                disableRipple
                className={classes.optionItemContainer}
                value={option.value}
                key={option.value}
              >
                <span className={classes.optionItemValue}>{option.value}</span>
                <span className={classes.optionItemAlias}>{option.label}</span>
              </MenuItem>
            )}
            handleClear={() => {
              resetField('contractId');
            }}
            onChange={handleGeneralInputChange}
            options={renderContracts()}
            label={Company.contractConjugation.capitalized.singular.main}
            fullWidth
          />
        </Grid>

        <Grid item className={classes.inputContainer}>
          <FilterInput
            clearable={filterInputValues.type}
            handleClear={() => {
              resetField('type');
            }}
            name="type"
            value={filterInputValues.type}
            onChange={handleGeneralInputChange}
            options={renderTypes()}
            label={mediumScreen ? 'Tipo' : 'Tipo de solicitud'}
            fullWidth
          />
        </Grid>

        <Grid item className={classes.inputContainer}>
          <FilterInput
            clearable={filterInputValues.status}
            handleClear={() => {
              resetField('status');
            }}
            name="status"
            value={filterInputValues.status}
            onChange={handleGeneralInputChange}
            options={renderStatusOptions()}
            label={mediumScreen ? 'Estado' : 'Estado de solicitud'}
            fullWidth
          />
        </Grid>

        <Grid item className={classes.inputContainer}>
          <TextInput
            label="No. de Solicitud"
            type="number"
            fullWidth
            inputProps={{ maxLength: 12 }}
            className={classes.inputText}
            name="requestId"
            value={filterInputValues.requestId}
            onChange={handleGeneralInputChange}
          />
        </Grid>

        <Grid item className={classes.inputContainer}>
          <TextInput
            fullWidth
            className={classes.inputText}
            label="Fecha inicial"
            inputProps={{
              maxLength: 20,
              max:
                filterInputValues.endDate &&
                moment(filterInputValues.endDate).isValid()
                  ? moment(filterInputValues.endDate).format('YYYY-MM-DD')
                  : moment()
                      .utcOffset(-5)
                      .format('YYYY-MM-DD')
            }}
            type="date"
            name="startDate"
            InputLabelProps={{ shrink: true }}
            value={filterInputValues.startDate}
            onChange={handleGeneralInputChange}
          />
        </Grid>

        <Grid item className={classes.inputContainer}>
          <TextInput
            fullWidth
            className={classes.inputText}
            label="Fecha final"
            inputProps={{
              maxLength: 20,
              max: moment()
                .utcOffset(-5)
                .format('YYYY-MM-DD'),
              min:
                filterInputValues.startDate &&
                moment(filterInputValues.startDate).isValid()
                  ? moment(filterInputValues.startDate).format('YYYY-MM-DD')
                  : null
            }}
            type="date"
            name="endDate"
            InputLabelProps={{ shrink: true }}
            value={filterInputValues.endDate}
            onChange={handleGeneralInputChange}
          />
        </Grid>
      </Grid>
    );
  };

  if (!currentUser) {
    return <LoginDialog open={true} setOpen={handleCloseLoginDialog} />;
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.innerRoot}>
          <Title
            id="RequestTitle"
            text={'Mis solicitudes'}
            className={classes.requestsTitle}
          />
          {!smallScreen && (
            <Typography className={classes.filterTitle}>Filtros</Typography>
          )}
          {showClearFilters(filterValues) &&
            smallScreen &&
            renderClearFilters()}
          <Box display="flex" className={classes.filtersRoot}>
            <Box flexGrow={1} className={classes.filtersContainer}>
              <BaseFilter
                filter
                className={classes.filters}
                filters={renderFilters}
                clearFilters={clearFilters}
                showClearFilters={showClearFilters(filterValues)}
                mobileBreakpoint={750}
                disableClearOutside
                fullScreenModal={false}
                handleOpenMobileFilters={handleOpenMobileFilters}
                handleSetMobileFilters={handleSetMobileFilters}
                handleCloseMobileFilters={handleCloseMobileFilters}
                avoidSpacing
                filterButtonOn={showClearFilters(
                  smallScreen ? mobileFiltersValues : filterValues
                )}
              />
            </Box>
            {showClearFilters(filterValues) &&
              !smallScreen &&
              renderClearFilters()}
          </Box>
          <SimpleDivider className={classes.simpleDivider} />
          <RequestList
            page={page}
            selectedFilters={selectedFilters}
            setPage={setPage}
          />
        </Container>
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  innerRoot: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(3)
  },
  requestsTitle: {
    margin: theme.spacing(3, 0, 2),
    [theme.breakpoints.up(750)]: {
      margin: theme.spacing(5, 0, 3)
    }
  },
  filterTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.greyDark,
    marginBottom: theme.spacing(2)
  },
  simpleDivider: {
    margin: theme.spacing(2, 0, 1),
    [theme.breakpoints.down(750)]: {
      margin: theme.spacing(1, 0)
    }
  },
  inputContainer: {
    marginBottom: theme.spacing(2),
    '&:last-of-type': {
      marginBottom: 0
    },
    [theme.breakpoints.up(750)]: {
      width: '25%',
      marginBottom: 0
    }
  },
  filterContainer: {
    [theme.breakpoints.down(750)]: {
      flexDirection: 'column'
    }
  },
  inputText: {
    margin: 0,
    fontSize: 18
  },
  filtersRoot: {
    alignItems: 'start',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center'
    }
  },
  filtersContainer: {
    maxWidth: 768
  },
  filters: {
    flexWrap: 'nowrap',
    '& > div': {
      flexWrap: 'nowrap'
    }
  },
  clearFilters: {
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 0, 0, 'auto'),
      paddingTop: 15
    }
  },
  optionItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  optionItemAlias: {
    color: '#B8B8B8',
    fontSize: 12
  },
  optionItemValue: {
    marginRight: theme.spacing(0.5)
  }
}));

export default RequestsGDC;
