import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { VerticalTimeline } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

const Timeline = props => {
  const { children, layout = '1-column', withLine = false, className } = props;

  //* HOOKS
  const classes = useStyles();

  //* RENDER
  return (
    <VerticalTimeline
      layout={layout}
      className={clsx([classes.timelineRoot, className])}
    >
      {withLine && <div className={classes.timelineLine} />}
      {children}
    </VerticalTimeline>
  );
};

const useStyles = makeStyles(theme => ({
  timelineRoot: {
    width: '100%',
    padding: 0,
    paddingBottom: theme.spacing(5)
  },
  timelineLine: {
    position: 'absolute',
    top: 0,
    left: 18,
    height: '100%',
    width: 4,
    background: theme.palette.color.paleGrey
  }
}));

export default Timeline;
