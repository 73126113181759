import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { history } from '../../Routes/history';

import Card from '@material-ui/core/Card';

import { ResendEmailValidation } from '../../API/UserAPI';
import { UserContext } from '../../Contexts/UserContext';
import _get from 'lodash/get';
import useCountdown from '../../Hooks/useCountdown';
import CallToActionDialog from '../../Components/Dialogs/CallToActionDialog';
import SvgConfirmEmail from '../../Components/CustomIcons/ConfirmEmailIcon';
import LoginContent from './LoginContent/LoginContent';

const Login = props => {
  const { location } = props;

  const {
    startCountdown,
    timeFormatCountdown,
    countdownFinished
  } = useCountdown();

  const initialUsername = _get(location, 'state.username', '');
  const email = _get(location, 'state.email', '');

  // * STATE HOOKS
  const currentUser = useContext(UserContext);

  const [emailConfirmationOpen, setEmailConfirmationOpen] = useState(false);
  const [canResend, setCanResend] = useState(false);

  // * OTHER HOOKS
  const classes = useStyles();

  // * FUNCTIONS
  useEffect(() => {
    if (currentUser) {
      history.replace('/');
    }
  }, [currentUser]);

  useEffect(() => {
    if (email) {
      setEmailConfirmationOpen(true);
      startCountdown();
    }
  }, [email, startCountdown]);

  useEffect(() => {
    if (countdownFinished) {
      setCanResend(true);
    }
  }, [countdownFinished]);

  const handleResendEmail = async () => {
    const response = await ResendEmailValidation(email);
    if (response.success) {
      setCanResend(false);
      startCountdown();
    } else {
      setCanResend(true);
    }
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          <Card className={classes.mainCard}>
            <LoginContent initialUsername={initialUsername} />
          </Card>
          <CallToActionDialog
            open={emailConfirmationOpen}
            icon={SvgConfirmEmail}
            message={
              '¡Has completado el proceso de registro! Por último revisa el enlace enviado al correo que acabas de ingresar para activar tu cuenta'
            }
            subMessage={
              <>
                Si el mensaje no te ha llegado en 2 minutos, puedes reenviarlo
              </>
            }
            handleConfirmClick={handleResendEmail}
            variant={'contained'}
            confirmButtonText={timeFormatCountdown}
            classNameButton={classes.buttonConfirm}
            buttonProps={{ disabled: !canResend || !countdownFinished }}
            handleClose={() => {
              setEmailConfirmationOpen(false);
            }}
          />
        </Container>
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'relative',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    backgroundColor: theme.palette.background.cardDark
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: '100%'
    }
  },
  mainCard: {
    maxHeight: 580,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    flexDirection: 'column',
    width: 346,
    borderRadius: 6,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.1)',
    paddingBottom: theme.spacing(2)
  },
  formContainer: {
    maxWidth: '80%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column'
  },
  title: {
    marginTop: 46,
    marginBottom: 20,
    fontSize: 24
  },
  googleIcon: {
    marginRight: 5
  },
  facebookIcon: {
    marginRight: 5,
    color: 'currentColor',
    fontSize: 20
  },
  facebookButton: {
    marginTop: theme.spacing(3)
  },
  logindivider: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%'
  },
  passwordField: {
    marginTop: theme.spacing(2)
  },
  forgotPassword: {
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    color: theme.palette.text.default,
    paddingTop: 8,
    '&:hover': {
      color: theme.palette.text.main
    }
  },
  button: {
    marginTop: theme.spacing(4)
  },
  googleButton: {
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: 'center'
  },
  form: {
    maxWidth: '100%'
  },
  bottomText: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    fontSize: 14,
    textAlign: 'center'
  },
  signUp: {
    fontSize: 14,
    color: theme.palette.primary.dark,
    fontWeight: 500,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  link: {
    color: '#424242',
    fontWeight: 500
  },
  countdown: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 14,
    marginTop: theme.spacing(),
    display: 'block'
  }
}));

export default Login;
