import React, { useContext, useEffect, useCallback, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Typography, Grid } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import Title from '../../../../Components/Labels/Title';
import Accordion from '../../../../Components/Accordion/Accordion';
import SimpleDivider from '../../../../Components/Dividers/SimpleDivider';
import ContractSimpleCard from '../../Cards/ContractSimpleCard';

import {
  SetCurrentStepIndexContext,
  StepperDataContext,
  StepperDataDispatchContext,
  CurrentStepIndexContext
} from '../../../../Contexts/StepperContext';

import myDebtsBanner from '../../../../Assets/images/my-debts-banner.png';
import { numberWithDots } from '../../../../Utils/Format/MoneyFormat';

import {
  RefinanceStep,
  RefinanceStepLabel,
  SummaryPanel
} from '../Refinance_enums';

import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Company } from '../../../../Configs/general';

const schema = yup.object({
  terms: yup.boolean().oneOf([true], 'Este campo es requerido.')
});

const SummaryStep = props => {
  const {
    formId,
    setCanSubmit,
    setGoBack,
    setCurrentStep: setVisualStepperIndex,
    setNextButtonText,
    selectedContract,
    setSidebarVisible
  } = props;

  const classes = useStyles();

  // * CONTEXTS
  const currentStepIndex = useContext(CurrentStepIndexContext);
  const setCurrentStep = useContext(SetCurrentStepIndexContext);
  const setData = useContext(StepperDataDispatchContext);
  const stepperData = useContext(StepperDataContext);

  // * STATE HOOKS
  const [openPanel, setOpenPanel] = useState({
    [SummaryPanel.Contract]: false,
    [SummaryPanel.PersonalData]: false
  });

  const { getValues, handleSubmit } = useForm({
    validationSchema: schema,
    submitFocusError: true,
    defaultValues: {
      terms: true,
      ...stepperData
    }
  });

  const onForward = useCallback(() => {
    setCurrentStep(step => step + 1);
  }, [setCurrentStep]);

  const onBackward = useCallback(() => {
    setData(data => ({ ...data, ...getValues() }));
    setCurrentStep(currentStep => currentStep - 1);
  }, [getValues, setCurrentStep, setData]);

  const goToStep = useCallback(
    step => {
      setCurrentStep(step);
    },
    [setCurrentStep]
  );

  const handleOpenPanel = panel => {
    setOpenPanel(value => ({ ...value, [panel]: !value[panel] }));
  };

  // Props setters
  useEffect(() => {
    setSidebarVisible(false);
    setNextButtonText('Solicitar');
    setVisualStepperIndex(currentStepIndex);
    setCanSubmit(true);
    setGoBack({
      action: onBackward
    });
  }, [
    setGoBack,
    stepperData,
    onBackward,
    setVisualStepperIndex,
    currentStepIndex,
    setNextButtonText,
    setCanSubmit,
    setSidebarVisible
  ]);

  return (
    <div className={classes.container}>
      <form id={formId} onSubmit={handleSubmit(onForward)}>
        <Title
          title="Resumen de solicitud"
          className={classes.summaryStepTitle}
        />
        <Typography paragraph className={classes.summaryDescription}>
          Verifica que la información de la solicitud sea correcta. En caso de
          que no sea correcta, puedes regresar a los pasos anteriores y{' '}
          <strong className={classes.emphasis}>modificar los datos</strong>
        </Typography>

        <Accordion
          open={openPanel[SummaryPanel.Contract]}
          setOpen={() => handleOpenPanel(SummaryPanel.Contract)}
          title={Company.contractConjugation.capitalized.singular.main}
        >
          <ContractSimpleCard
            banner={myDebtsBanner}
            contract={selectedContract}
            title={`Información ${Company.contractConjugation.regular.singular.contraction}`}
          />
        </Accordion>
        <Accordion
          open={openPanel[SummaryPanel.PersonalData]}
          setOpen={() => handleOpenPanel(SummaryPanel.PersonalData)}
          title={RefinanceStepLabel[RefinanceStep.PersonalData]}
          actions={[
            {
              Icon: EditIcon,
              onClick: () => goToStep(RefinanceStep.PersonalData)
            }
          ]}
        >
          <Grid container alignItems="center" justify="space-between">
            <Grid item xs={12} sm="auto" className={classes.itemContainer}>
              <Typography className={classes.itemContainerTitle}>
                Nombres
              </Typography>
              <Typography className={classes.itemContainerData}>
                {stepperData.firstName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm="auto" className={classes.itemContainer}>
              <Typography className={classes.itemContainerTitle}>
                Apellidos
              </Typography>
              <Typography className={classes.itemContainerData}>
                {stepperData.lastName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm="auto" className={classes.itemContainer}>
              <Typography className={classes.itemContainerTitle}>
                Identificación
              </Typography>
              <Typography className={classes.itemContainerData}>
                {stepperData.identificationType} - {stepperData.identification}
              </Typography>
            </Grid>
            <Grid item xs={12} sm="auto" className={classes.itemContainer}>
              <Typography className={classes.itemContainerTitle}>
                Fecha de expedición
              </Typography>
              <Typography className={classes.itemContainerData}>
                {stepperData.expeditionDate}
              </Typography>
            </Grid>
            <Grid item xs={12} sm="auto" className={classes.itemContainer}>
              <Typography className={classes.itemContainerTitle}>
                Número de celular
              </Typography>
              <Typography className={classes.itemContainerData}>
                +{stepperData.phoneCountryCode} {stepperData.phone}
              </Typography>
            </Grid>
          </Grid>
        </Accordion>
        <Accordion
          open={openPanel[SummaryPanel.PaymentPlan]}
          setOpen={() => handleOpenPanel(SummaryPanel.PaymentPlan)}
          title={RefinanceStepLabel[RefinanceStep.PaymentPlan]}
          actions={[
            {
              Icon: EditIcon,
              onClick: () => goToStep(RefinanceStep.PaymentPlan)
            }
          ]}
        >
          <Grid container alignItems="center" justify="space-between">
            <Grid item xs={12} sm="auto" className={classes.itemContainer}>
              <Typography className={classes.itemContainerTitle}>
                Saldo inicial
              </Typography>
              <Typography className={classes.itemContainerData}>
                {numberWithDots(stepperData.totalFinanceValue)}
              </Typography>
            </Grid>
            {stepperData.totalDiscountValue && (
              <Grid item xs={12} sm="auto" className={classes.itemContainer}>
                <Typography className={classes.itemContainerTitle}>
                  Descuento de mora
                </Typography>
                <Typography
                  className={clsx(
                    classes.itemContainerData,
                    classes.discountText
                  )}
                >
                  -{numberWithDots(stepperData.totalDiscountValue)}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} sm="auto" className={classes.itemContainer}>
              <Typography className={classes.itemContainerTitle}>
                Valor total - Cuota inicial
              </Typography>
              {stepperData.initialInstallmentValue > 0 && (
                <Typography
                  className={clsx(
                    classes.itemContainerData,
                    classes.discountText
                  )}
                >
                  -{numberWithDots(stepperData.initialInstallmentValue)}
                </Typography>
              )}
              {stepperData.initialInstallmentValue === 0 && (
                <Typography className={classes.itemContainerData}>
                  {numberWithDots(stepperData.initialInstallmentValue)}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm="auto" className={classes.itemContainer}>
              <Typography className={classes.itemContainerTitle}>
                Valor a financiar
              </Typography>
              <Typography className={classes.itemContainerData}>
                {numberWithDots(
                  stepperData.totalFinanceValue -
                    (stepperData.totalDiscountValue || 0) -
                    stepperData.initialInstallmentValue
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.productsContainer}>
              <Typography className={classes.itemContainerTitle}>
                Productos a financiar:
              </Typography>
            </Grid>
            {(stepperData.products || []).map((product, index) => (
              <Grid
                key={index}
                item
                xs={12}
                container
                alignItems="center"
                justify="space-between"
              >
                <Grid item xs={12} className={classes.dividerContainer}>
                  <SimpleDivider withoutMargin={true} />
                </Grid>
                <Grid item xs={12} sm="auto" className={classes.itemContainer}>
                  <Typography className={classes.itemContainerTitle}>
                    Producto
                  </Typography>
                  <Typography className={classes.itemContainerData}>
                    {product.productType} • {product.productId}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm="auto" className={classes.itemContainer}>
                  <Typography className={classes.itemContainerTitle}>
                    Tasa de interés (mensual)
                  </Typography>
                  <Typography className={classes.itemContainerData}>
                    {product.interestRate}%
                  </Typography>
                </Grid>
                <Grid item xs={12} sm="auto" className={classes.itemContainer}>
                  <Typography className={classes.itemContainerTitle}>
                    Valor de cuota inicial
                  </Typography>
                  <Typography className={classes.itemContainerData}>
                    {numberWithDots(product.initialInstallmentValue)}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm="auto" className={classes.itemContainer}>
                  <Typography className={classes.itemContainerTitle}>
                    Número de cuotas
                  </Typography>
                  <Typography className={classes.itemContainerData}>
                    {product.installments || stepperData.numberOfInstallments}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Accordion>
        <Accordion
          open={openPanel[SummaryPanel.Terms]}
          setOpen={() => handleOpenPanel(SummaryPanel.Terms)}
          title={RefinanceStepLabel[RefinanceStep.Terms]}
        >
          <Grid container alignItems="center" justify="space-between">
            <Grid item xs={12} className={classes.productsContainer}>
              <Typography className={classes.itemContainerTitle}>
                Aceptación de acuerdo para los siguientes productos:
              </Typography>
            </Grid>
            {(stepperData.products || []).map((product, index) => (
              <Grid
                key={index}
                item
                xs={12}
                container
                alignItems="center"
                justify="space-between"
              >
                <Grid item xs={12} className={classes.dividerContainer}>
                  <SimpleDivider withoutMargin={true} />
                </Grid>
                <Grid item xs="auto">
                  <Typography className={classes.itemContainerTitle}>
                    Producto
                  </Typography>
                  <Typography className={classes.itemContainerData}>
                    {product.productType} • {product.productId}
                  </Typography>
                </Grid>
                <Grid item xs="auto" className={classes.iconContainer}>
                  <CheckIcon className={classes.checkIcon} />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Accordion>
      </form>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(5)
  },
  summaryStepTitle: {
    fontSize: 16,
    fontWeight: 600,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 14
    }
  },
  summaryDescription: {
    marginBottom: theme.spacing(4)
  },
  emphasis: {
    color: theme.palette.text.greyDark
  },
  itemContainer: {
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  itemContainerTitle: {
    fontSize: 12,
    color: theme.typography.color.default
  },
  itemContainerData: {
    fontSize: 14,
    color: theme.palette.common.black,
    fontWeight: 600
  },
  productsContainer: {
    marginBottom: theme.spacing()
  },
  dividerContainer: {
    marginBottom: theme.spacing(2)
  },
  discountText: {
    color: theme.palette.error.main
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  checkIcon: {
    fill: theme.palette.primary.main
  }
}));

export default SummaryStep;
