import React from 'react';
import {
  Collapse,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';

const Accordion = props => {
  const {
    open,
    setOpen,
    title,
    actions = [],
    childContainerClass = {}
  } = props;

  const classes = useStyles();

  const extendHandler = () => {
    setOpen(previousValue => !previousValue);
  };

  const stopPropagation = (evt, clickHandler) => {
    evt.stopPropagation();
    clickHandler();
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        className={classes.container}
        alignItems="center"
        justify="space-between"
        onClick={e => stopPropagation(e, extendHandler)}
      >
        <Grid item xs={11}>
          <Typography className={classes.title}>{title}</Typography>
        </Grid>
        <Grid item xs={1} className={classes.actionsContainer}>
          {actions.map((action, index) => (
            <IconButton
              key={index}
              onClick={e => stopPropagation(e, action.onClick)}
              className={classes.actionButton}
            >
              <action.Icon className={classes.actionIcon} />
            </IconButton>
          ))}

          <IconButton
            onClick={e => stopPropagation(e, extendHandler)}
            className={classes.actionButton}
          >
            {open && <RemoveIcon className={classes.actionIcon} />}
            {!open && <AddIcon className={classes.actionIcon} />}
          </IconButton>
        </Grid>
      </Grid>
      <Collapse in={open}>
        <div className={clsx(classes.childContainer, childContainerClass)}>
          {props.children}
        </div>
      </Collapse>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  container: {
    backgroundColor: theme.palette.background.accordionGray,
    padding: [[theme.spacing(1.5), theme.spacing(3)]],
    borderBottomColor: theme.palette.divider,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    '&:hover': {
      backgroundColor: theme.palette.background.accordion,
      cursor: 'pointer'
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: [[theme.spacing(1.5), theme.spacing()]]
    }
  },
  title: {
    color: theme.palette.text.main,
    fontWeight: 600,
    fontSize: 12
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  actionButton: {
    padding: theme.spacing(),
    marginLeft: theme.spacing(),
    '&:first-of-type': {
      marginLeft: 0
    }
  },
  actionIcon: {
    fill: theme.palette.primary.main,
    height: theme.spacing(3),
    width: theme.spacing(3)
  },
  childContainer: {
    padding: [[theme.spacing(2), theme.spacing(3)]],
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: [[theme.spacing(2), theme.spacing()]]
    }
  }
}));

export default Accordion;
