/* eslint-disable react/display-name */
import React, {
  Fragment,
  useState,
  useContext,
  useCallback,
  useEffect
} from 'react';

import * as yup from 'yup';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, useMediaQuery } from '@material-ui/core';

import {
  UpdateContract,
  VerifyLastUserContractAPI
} from '../../../../API/Contracts/ContractsAPI';
import {
  UserContext,
  UserDispatchContext
} from '../../../../Contexts/UserContext';
import { ContractsRefreshContext } from '../../../../Contexts/ContractsContext';

import BaseButton from '../../../../Components/Buttons/BaseButton';
import BaseDialog from '../../../../Components/Dialogs/BaseDialog';
import { SlideUpTransition } from '../../../../Components/Transitions/Transitions';

import {
  redirectOnAuthFailure,
  extractErrorMessage
} from '../../../../Utils/Errors/Errors';
import RequestDialog from '../../../../Components/Dialogs/RequestDialog';
import ContractAssociationForm from '../../ContractAssociation/ContractAssociationForm/ContractAssociationForm';
import { history } from '../../../../Routes/history';
import { ROUTE_NAMES } from '../../../../Routes/Routes';
import { Company } from '../../../../Configs/general';
import ConfirmActionDialog from '../../../../Components/Dialogs/ConfirmActionDialog';
import FlatButton from '../../../../Components/Buttons/FlatButton';
import { INVOICE_CONTRACTS } from '../../contract_enums';
import { logoutUser } from '../../../../Utils/User/Actions';

const editContractSchema = yup.object({
  aliasName: yup
    .string()
    .trim()
    .max(12, 'Debe tener 12 letras o menos.')
    .required('Ingresa un alias'),
  aliasIcon: yup.number().required('Selecciona un ícono')
});

const getPhoneActionText = (userPhone, userPhoneValidated) => {
  if (!userPhone) {
    return 'Agregar';
  }

  if (!userPhoneValidated) {
    return 'Confirmar';
  }

  return 'Validar';
};

const EditContractEFG = props => {
  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const refreshContracts = useContext(ContractsRefreshContext);

  const {
    contract,
    open,
    setDialog,
    setAlert,
    editFromParams,
    activateDigitalInvoice,
    digitalInvoiceSweetAlertOnclick
  } = props;

  const {
    id: contractId,
    sendInvoice: currentSendInvoice,
    digitalInvoice: currentDigitalInvoice,
    sendEmailInvoice: currentSendEmail,
    sendSmsInvoice: currentSendSms,
    sendWhatsAppInvoice: currentSendWhatsApp,
    alias: currentAlias,
    icon: currentIcon
  } = contract;

  const allowedContractType =
    contract && INVOICE_CONTRACTS.includes(contract.type);

  // * STATE HOOKS
  const [aliasName, setAliasName] = useState(currentAlias);
  const [aliasIcon, setAliasIcon] = useState(currentIcon);
  const [sendInvoice, setSendInvoice] = useState(
    currentSendInvoice || activateDigitalInvoice
  );
  const [digitalInvoice, setDigitalInvoice] = useState(
    currentDigitalInvoice || activateDigitalInvoice
  );
  const [digitalChannels, setDigitalChannels] = useState({
    email: currentSendEmail || true,
    whatsapp: currentSendWhatsApp || false,
    sms: currentSendSms || false
  });
  const [canSubmit, setCanSubmit] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [needsPhoneValidation, setNeedsPhoneValidation] = useState(false);
  const [openPhoneValidationDialog, setOpenPhoneValidationDialog] = useState(
    false
  );
  const [openVerifyOnlyUserDialog, setOpenVerifyOnlyUserDialog] = useState(
    false
  );

  // * OTHER HOOKS
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  const phoneActionText =
    currentUser &&
    getPhoneActionText(currentUser.phone, currentUser.phoneValidated);

  // validate contract schema
  useEffect(() => {
    try {
      editContractSchema.validateSync({ aliasName, aliasIcon });
      setErrors({});
      setCanSubmit(true);
    } catch (err) {
      setCanSubmit(false);
      setErrors({
        [err.path]: err.errors[0]
      });
    }
  }, [aliasName, aliasIcon, sendInvoice, digitalInvoice]);

  // * FUNCTIONS
  const editContractAPI = useCallback(async () => {
    setLoading(true);

    // If the contract has an active digital invoice,
    // the user's response is taken as sendInvoice.
    // If it does not have it active, sendInvoice will
    // be the same as digitalInvoice.

    const realSendInvoice = currentDigitalInvoice
      ? sendInvoice
      : digitalInvoice;

    const realDigitalInvoice = currentDigitalInvoice || digitalInvoice;

    const digitalInvoiceSettings = {
      digitalInvoice: openVerifyOnlyUserDialog
        ? digitalInvoice
        : realDigitalInvoice,
      sendInvoice: realSendInvoice,
      sendEmailInvoice: realSendInvoice,
      sendWhatsAppInvoice: false,
      sendSmsInvoice: realSendInvoice && digitalChannels.sms
    };

    // When the contract to associate is not an
    // allowed one for digital invoice, it sets
    // every digital invoice setting to false
    if (!allowedContractType) {
      for (const key of Object.keys(digitalInvoiceSettings)) {
        digitalInvoiceSettings[key] = false;
      }
    }

    const editedAssociation = {
      ...digitalInvoiceSettings,
      alias: aliasName.trim(),
      icon: aliasIcon,
      origin: {
        url: window.location.pathname
      }
    };

    const response = await UpdateContract(
      currentUser.token,
      contractId,
      editedAssociation
    );
    setLoading(false);
    if (response.success) {
      setDialog(false);
      setAlert({
        type: 'success',
        message: `Se ha actualizado ${Company.contractConjugation.regular.singular.article} "${aliasName}"`
      });

      refreshContracts();

      if (editFromParams) {
        history.push(ROUTE_NAMES.contracts);
      }

      return { unmounting: true };
    }
    if (
      redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
    ) {
      return { unmounting: true };
    }
    setAlert({
      type: 'error',
      message: extractErrorMessage(response).message
    });
    return { unmounting: false, closeDialog: true };
  }, [
    currentUser,
    contractId,
    aliasIcon,
    aliasName,
    refreshContracts,
    setAlert,
    setDialog,
    setCurrentUser,
    digitalInvoice,
    digitalChannels,
    sendInvoice,
    editFromParams,
    currentDigitalInvoice,
    openVerifyOnlyUserDialog,
    allowedContractType
  ]);

  const handleClose = useCallback(() => {
    if (editFromParams) {
      history.push(ROUTE_NAMES.contracts);
    }

    setDialog(false);
  }, [setDialog, editFromParams]);

  const closePhoneDialog = useCallback(() => {
    setOpenPhoneValidationDialog(false);
  }, []);

  const closeVerifyOnlyUserDialog = useCallback(() => {
    setOpenVerifyOnlyUserDialog(false);
  }, []);

  const onChangeAliasName = useCallback(value => {
    setAliasName(value);
    setErrors(errs => ({ ...errs, aliasName: null }));
  }, []);

  const onSubmitEdit = useCallback(() => {
    if (needsPhoneValidation) {
      setOpenPhoneValidationDialog(true);
      return;
    }
    editContractAPI();
  }, [needsPhoneValidation, editContractAPI]);

  const onSubmit = useCallback(async () => {
    const response = await VerifyLastUserContractAPI(
      currentUser.token,
      contractId,
      {
        userId: currentUser.id
      }
    );

    if (!response.success) {
      setAlert({
        type: 'error',
        message: extractErrorMessage(response).message
      });
      return;
    }

    const { isLastUser } = response.data.data;

    if (needsPhoneValidation) {
      setOpenPhoneValidationDialog(true);
      return;
    }

    if (!sendInvoice && !digitalInvoice && isLastUser) {
      setOpenVerifyOnlyUserDialog(true);
    } else {
      editContractAPI();
    }
  }, [
    needsPhoneValidation,
    editContractAPI,
    contractId,
    currentUser.id,
    currentUser.token,
    digitalInvoice,
    sendInvoice,
    setAlert
  ]);

  // if i send acceptText to renderAction callback
  // is when is activated the modal to confirm
  // deactivate sendInvoice and deactivate digital invoice
  const renderActions = useCallback(
    (handleConfirmClick, acceptText) => {
      if (acceptText) {
        return () => (
          <Fragment>
            <FlatButton
              onClick={handleConfirmClick}
              disabled={!canSubmit || loading}
            >
              Desactivar
            </FlatButton>
            <BaseButton onClick={closeVerifyOnlyUserDialog}>
              Mantener activas
            </BaseButton>
          </Fragment>
        );
      }
      return () => (
        <Fragment>
          <BaseButton
            onClick={handleClose}
            variant="outlined"
            color="primary"
            size="small"
          >
            Cancelar
          </BaseButton>
          <BaseButton
            onClick={handleConfirmClick}
            type="submit"
            form="editContractForm"
            color="primary"
            disabled={!canSubmit || loading}
            size="small"
            autoFocus
          >
            Guardar
          </BaseButton>
        </Fragment>
      );
    },
    [canSubmit, handleClose, loading, closeVerifyOnlyUserDialog]
  );
  const renderContent = () => {
    return (
      <div className={classes.content}>
        <ContractAssociationForm
          dialogMode
          editMode
          currentUser={currentUser}
          contract={contract}
          sendInvoice={sendInvoice}
          setSendInvoice={setSendInvoice}
          isDigitalInvoice={digitalInvoice}
          setIsDigitalInvoice={setDigitalInvoice}
          digitalChannels={digitalChannels}
          setDigitalChannels={setDigitalChannels}
          aliasName={aliasName}
          setAliasName={onChangeAliasName}
          aliasIcon={aliasIcon}
          setAliasIcon={setAliasIcon}
          errors={errors}
          needsPhoneValidation={needsPhoneValidation}
          setNeedsPhoneValidation={setNeedsPhoneValidation}
          activateMode={activateDigitalInvoice}
          digitalInvoiceSweetAlertOnclick={digitalInvoiceSweetAlertOnclick}
          setCanSubmit={setCanSubmit}
          actions={renderActions(onSubmit)}
          allowedContractType={allowedContractType}
        />
      </div>
    );
  };
  const renderVerifyDialog = () => {
    return (
      <Typography paragraph className={classes.text}>
        {currentUser.firstName}, al desactivar las notificaciones del{' '}
        {Company.contractConjugation.regular.singular.main}{' '}
        <b>
          {aliasName} {contractId}
        </b>
        , el envío de tu factura pasará de factura digital a factura física.
        Esto ocurre porque eres el único usuario con notificaciones activas de
        factura digital. ¿Estás seguro que quieres desactivar las notificaciones
        de tu factura?
      </Typography>
    );
  };

  return (
    <Fragment>
      {openPhoneValidationDialog && (
        <RequestDialog
          open={openPhoneValidationDialog}
          setDialog={setOpenPhoneValidationDialog}
          beforeClosing={closePhoneDialog}
          requestTitle={`${phoneActionText} número celular`}
          requestCallback={editContractAPI}
          withoutObservation={true}
          backButton={{
            text: 'Cancelar',
            handle: closePhoneDialog
          }}
          submitButtonText={'Continuar'}
          requestNode={
            <Typography paragraph className={classes.text}>
              {`Para llevar a cabo esta acción es necesario ${phoneActionText.toLowerCase()} tu número de celular`}
            </Typography>
          }
          contentSize="small"
        />
      )}
      {openVerifyOnlyUserDialog && (
        <ConfirmActionDialog
          open={open}
          handleClose={closeVerifyOnlyUserDialog}
          title={'Verificación de desactivación de notificaciones'}
          content={renderVerifyDialog}
          TransitionComponent={smallScreen ? SlideUpTransition : undefined}
          paperClass={classes.dialogPaper}
          actions={renderActions(onSubmitEdit, onSubmitEdit)}
          acceptText={'Desactivar'}
          actionsStyle={classes.contentDialogStyle}
        />
      )}
      <BaseDialog
        open={open}
        handleClose={handleClose}
        title={
          activateDigitalInvoice
            ? 'Activar Factura Digital'
            : `Editar ${Company.contractConjugation.regular.singular.main}`
        }
        actions={renderActions(onSubmit)}
        content={renderContent}
        fullScreen={smallScreen}
        TransitionComponent={smallScreen ? SlideUpTransition : undefined}
        paperClass={classes.dialogPaper}
        contentStyle={classes.contentStyle}
      />
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 14,
    color: theme.palette.text.primary
  },
  content: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  contentStyle: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: theme.spacing(3)
    }
  },
  contentDialogStyle: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: theme.spacing(2)
    },
    justifyContent: 'space-between',
    padding: theme.spacing(2, 4)
  },
  alias: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  aliasIcon: {
    minWidth: 67
  },
  aliasText: {
    minWidth: 150,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      minWidth: 250
    }
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      flexDirection: 'row'
    }
  },
  switchText: {
    fontSize: 14,
    paddingRight: 12,
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      paddingRight: 12
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginBottom: theme.spacing(3)
    }
  },
  dialogPaper: {
    maxWidth: 700
  }
}));

export default EditContractEFG;
