import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useContext
} from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { history } from '../../Routes/history';
import { ROUTE_NAMES } from '../../Routes/Routes';
import * as yup from 'yup';
import Card from '@material-ui/core/Card';
import TextInput from '../../Components/Inputs/TextInput';
import BaseButton from '../../Components/Buttons/BaseButton';
import ShowPassword from '../../Components/Adornments/ShowPassword';
import {
  ConfirmPasswordAPI,
  GetTokenValidationAPI,
  SetUserPasswordAPI
} from '../../API/UserAPI';
import { UserContext, UserDispatchContext } from '../../Contexts/UserContext';
import { AlertsDispatchContext } from '../../Contexts/AlertsContext';
import { extractErrorMessage } from '../../Utils/Errors/Errors';
import { deserializeToken } from '../../Utils/JWT/token';
import clsx from 'clsx';
import SocialLoginButtons from '../Login/SocialLoginButtons';
import ModalProgress from '../../Components/Progress/Modal/ModalProgress';
import { logoutUser } from '../../Utils/User/Actions';

const passwordSchema = yup.object({
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password')], 'Las contraseñas deben ser iguales')
    .required('Ingresa de nuevo la contraseña'),
  password: yup
    .string()
    .min(8, 'Debes usar al menos 8 caracteres')
    .required('Ingresa una contraseña')
});

const RecoverPassword = props => {
  const { match = { params: { token: null } } } = props;
  const { token } = match.params;
  const currentPath = `/${history.location.pathname.split('/')[1]}`;

  const { email } = deserializeToken(token);
  const isGmail = email.split('@')[1].split('.')[0] === 'gmail';

  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const setAlert = useContext(AlertsDispatchContext);

  // * STATE HOOKS
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingToken, setLoadingToken] = useState(false);
  const [errors, setErrors] = useState({
    password: null,
    passwordConfirmation: null
  });
  const [passwordShown, setShowPassword] = useState(false);
  const [confirmPasswordShown, setShowConfirmPassword] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const [tokenGoogle, setTokenGoogle] = useState(null);

  // * OTHER HOOKS
  const classes = useStyles();

  // * FUNCTIONS

  useEffect(() => {
    const fetchData = async () => {
      setLoadingToken(true);
      const response = await GetTokenValidationAPI(token);

      if (!response.success) {
        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
        setLoadingToken(false);
        history.replace(ROUTE_NAMES.home);
      }
      setLoadingToken(false);
    };

    if (currentPath === ROUTE_NAMES.updateUser) {
      fetchData();
    }
  }, [token, setAlert, currentPath]);

  useEffect(() => {
    if (currentUser && !Boolean(tokenGoogle)) {
      logoutUser(setCurrentUser);
    }
  }, [currentUser, setCurrentUser, tokenGoogle]);

  useEffect(() => {
    if (!password) {
      setCanSubmit(false);
      return;
    }

    try {
      passwordSchema.validateSync({ password, passwordConfirmation });
      setErrors({});
      setCanSubmit(true);
    } catch (err) {
      setCanSubmit(false);
      setErrors({
        [err.path]: err.errors[0]
      });
    }
  }, [password, passwordConfirmation]);

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      setLoading(true);
      const response =
        currentPath === ROUTE_NAMES.recover
          ? await ConfirmPasswordAPI(token, password)
          : await SetUserPasswordAPI(token, password);
      if (!response.success) {
        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
        setLoading(false);
        return;
      }

      setAlert({
        type: 'success',
        message: 'La contraseña se ha actualizado'
      });

      history.replace(ROUTE_NAMES.login);

      setLoading(false);
    },
    [password, token, setAlert, currentPath]
  );

  const onChangePassword = useCallback(event => {
    setPassword(event.target.value);
  }, []);

  const onChangePasswordConfirmation = useCallback(event => {
    setPasswordConfirmation(event.target.value);
  }, []);

  const togglePasswordShow = useCallback(() => {
    setShowPassword(!passwordShown);
  }, [passwordShown]);

  const toggleConfirmPasswordShow = useCallback(() => {
    setShowConfirmPassword(!confirmPasswordShown);
  }, [confirmPasswordShown]);

  return (
    <Fragment>
      {loadingToken && (
        <ModalProgress
          id={'TokenValidation_progress_loading'}
          message={'Cargando...'}
        />
      )}
      <div className={classes.root}>
        <Card className={classes.mainCard}>
          <div className={classes.formContainer}>
            <Typography
              className={clsx(classes.title, {
                [classes.titleMargin]: !isGmail,
                [classes.titleMarginGoogle]: isGmail
              })}
            >
              {currentPath === ROUTE_NAMES.recover
                ? 'Recupera tu contraseña'
                : 'Actualiza tu contraseña'}
            </Typography>
            <Typography className={classes.subtitle}>
              La contraseña debe tener al menos 8 caracteres.
            </Typography>

            <form className={classes.form} onSubmit={handleSubmit}>
              {currentPath === ROUTE_NAMES.updateUser && isGmail && (
                <SocialLoginButtons
                  loading={loading}
                  loadingGoogle={loadingGoogle}
                  setLoadingGoogle={setLoadingGoogle}
                  hideAppleButton
                  isUpdateUserPath={currentPath === ROUTE_NAMES.updateUser}
                  setTokenGoogle={setTokenGoogle}
                />
              )}
              <TextInput
                autoFocus
                value={password}
                onChange={onChangePassword}
                error={Boolean(errors.password)}
                helperText={errors.password}
                id="password"
                label="Contraseña"
                type={passwordShown ? 'text' : 'password'}
                required={true}
                InputProps={{
                  endAdornment: (
                    <ShowPassword
                      passwordShown={passwordShown}
                      togglePasswordShow={togglePasswordShow}
                    />
                  )
                }}
                fullWidth
              />

              <TextInput
                value={passwordConfirmation}
                onChange={onChangePasswordConfirmation}
                error={Boolean(errors.passwordConfirmation)}
                helperText={errors.passwordConfirmation}
                id="passwordConfirmation"
                label="Confirmar Contraseña"
                type={confirmPasswordShown ? 'text' : 'password'}
                required={true}
                InputProps={{
                  endAdornment: (
                    <ShowPassword
                      passwordShown={confirmPasswordShown}
                      togglePasswordShow={toggleConfirmPasswordShow}
                    />
                  )
                }}
                fullWidth
              />

              <BaseButton
                loading={loading}
                className={classes.button}
                disabled={!canSubmit}
                fullWidth
                id="submit"
                type="submit"
                color="primary"
                variant="contained"
              >
                Aceptar
              </BaseButton>
            </form>
          </div>
        </Card>
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.cardDark,
    justifyContent: 'center',
    alignItems: 'center'
  },
  mainCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: 346,
    borderRadius: 6,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.1)'
  },
  formContainer: {
    maxWidth: '80%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column'
  },
  title: {
    fontSize: 21,
    fontWeight: 600,
    textAlign: 'center'
  },
  subtitle: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6)
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  titleMargin: {
    paddingTop: theme.spacing(5)
  },
  titleMarginGoogle: {
    paddingTop: theme.spacing(3)
  }
}));

export default RecoverPassword;
