import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useContext
} from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as yup from 'yup';
import Card from '@material-ui/core/Card';
import SimpleDivider from '../../Components/Dividers/SimpleDivider';
import TextInput from '../../Components/Inputs/TextInput';
import BaseButton from '../../Components/Buttons/BaseButton';
import { history } from '../../Routes/history';
import { ROUTE_NAMES } from '../../Routes/Routes';
import { ForgotPasswordAPI } from '../../API/UserAPI';
import _get from 'lodash/get';
import { AlertsDispatchContext } from '../../Contexts/AlertsContext';
import RecaptchaDisclaimer from '../../Components/Captcha/RecaptchaDisclaimer';
import { RecaptchaAction } from '../../Enums/recaptcha';
import { getRecaptchaToken } from '../../Utils/Recaptcha';

const forgotSchema = yup.object({
  username: yup
    .string()
    .trim()
    .lowercase()
    .email('Debes ingresar un correo válido')
    .required('Ingresa un correo')
});

const ForgotPassword = props => {
  const { location = null } = props;

  const setAlert = useContext(AlertsDispatchContext);

  const initialUsername = _get(location, 'state.username', '');

  // * STATE HOOKS
  const [username, setUsername] = useState(initialUsername);
  const [loading, setLoading] = useState(false);
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const [errors, setErrors] = useState({
    username: null
  });
  const [canSubmit, setCanSubmit] = useState(false);

  // * OTHER HOOKS
  const classes = useStyles();

  // * FUNCTIONS
  useEffect(() => {
    if (!username) {
      setCanSubmit(false);
      return;
    }

    try {
      forgotSchema.validateSync({ username });
      setErrors({});
      setCanSubmit(true);
    } catch (err) {
      setCanSubmit(false);
      setErrors({
        [err.path]: err.errors[0]
      });
    }
  }, [username]);

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      setLoading(true);
      const recaptchaToken = await getRecaptchaToken(
        RecaptchaAction.RecoverPassword
      );

      const { success } = await ForgotPasswordAPI(username, recaptchaToken);
      if (!success) {
        setAlert({
          type: 'error',
          message: 'Ocurrió un error, intenta de nuevo'
        });
        setLoading(false);
        return;
      }

      history.push(ROUTE_NAMES.forgotPasswordEmail, { username });

      setLoading(false);
    },
    [username, setAlert]
  );

  const onChangeUsername = useCallback(event => {
    setUsername(event.target.value);
  }, []);

  return (
    <Fragment>
      <div className={classes.root}>
        <Card className={classes.mainCard}>
          <div className={classes.formContainer}>
            <Typography className={classes.title}>
              Olvidé mi contraseña
            </Typography>
            <Typography className={classes.subtitle}>
              Digita tu correo electrónico registrado para recuperar tu cuenta
            </Typography>
            <SimpleDivider />

            <form className={classes.form} onSubmit={handleSubmit}>
              <TextInput
                autoFocus
                value={username}
                onChange={onChangeUsername}
                error={Boolean(errors.username)}
                helperText={errors.username}
                id="email"
                label="Correo electrónico"
                type="email"
                required={true}
                fullWidth
              />

              <BaseButton
                loading={loading}
                className={classes.button}
                disabled={!canSubmit}
                fullWidth
                id="submit"
                type="submit"
                color="primary"
                variant="contained"
              >
                Enviar
              </BaseButton>
              <div className={classes.recaptchaContainer}>
                <RecaptchaDisclaimer
                  loading={loadingCaptcha}
                  setLoading={setLoadingCaptcha}
                />
              </div>
            </form>
          </div>
        </Card>
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.cardDark,
    justifyContent: 'center',
    alignItems: 'center'
  },
  mainCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: 346,
    borderRadius: 6,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.1)'
  },
  formContainer: {
    maxWidth: '80%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column'
  },
  title: {
    marginTop: 46,
    marginBottom: 40,
    fontSize: 24,
    textAlign: 'center'
  },
  subtitle: {
    fontSize: 12,
    textAlign: 'center'
  },
  button: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  recaptchaContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

export default ForgotPassword;
