import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Fragment } from 'react';
import { Container, Typography } from '@material-ui/core';

const OptionDivider = props => {
  const rootClasses = useStyles();
  const { classes, className, withoutMargin = false } = props;
  if (classes || className) {
    return (
      <Fragment>
        <Container className={rootClasses.container}>
          <hr
            className={`${classes || className} ${rootClasses.divider} ${
              withoutMargin ? '' : rootClasses.withMargin
            }`}
          />
          <Typography className={rootClasses.circleStyle}>o</Typography>
          <hr
            className={`${classes || className} ${rootClasses.divider} ${
              withoutMargin ? '' : rootClasses.withMargin
            }`}
          />
        </Container>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Container className={rootClasses.container}>
        <hr
          className={` ${rootClasses.divider} ${
            withoutMargin ? '' : rootClasses.withMargin
          }`}
        />
        <Typography className={rootClasses.circleStyle}>o</Typography>
        <hr
          className={` ${rootClasses.divider} ${
            withoutMargin ? '' : rootClasses.withMargin
          }`}
        />
      </Container>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  divider: {
    display: 'block',
    width: '100%',
    borderWidth: '0.5px',
    borderStyle: 'solid',
    borderColor: '#DBDBDB',
    backgroundColor: theme.palette.divider,
    margin: 0
  },
  withMargin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1)
  },
  container: {
    display: 'flex',
    maxWidth: '100%',
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    backgroundColor: 'white'
  },
  circleStyle: {
    position: 'relative',
    top: 14,
    color: '#8b8b8b',
    fontWeight: 500,
    fontSize: 13,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

export default OptionDivider;
