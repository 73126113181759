import React, { useCallback } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';

import { makeStyles } from '@material-ui/core/styles';

import { numberWithDots } from '../../../Utils/Format/MoneyFormat';
import { Grid } from '@material-ui/core';
import { ROUTE_NAMES } from '../../../Routes/Routes';
import { history } from '../../../Routes/history';

import BackIcon from '../../../Components/CustomIcons/BackIcon';
import FlatButton from '../../Buttons/FlatButton';
import { Company } from '../../../Configs/general';
import BaseTooltip from '../../Tooltip/BaseTooltip';

const InvoiceCardGDC = props => {
  const classes = useStyles();
  const {
    id,
    invoiceDetails,
    className,
    loading = false,
    showTotalTooltip = false,
    showCurrentDebtButton = false
  } = props;
  // * FUNCTIONS

  const renderLabel = contract => {
    if (contract.isPaid) {
      return (
        <Typography
          className={clsx(
            classes.labelText,
            classes.payedText,
            classes.alignRight
          )}
        >
          Pagado
        </Typography>
      );
    }
    if (contract.isPending) {
      return (
        <Typography
          className={clsx(
            classes.labelText,
            classes.pendingText,
            classes.alignRight
          )}
        >
          En proceso
        </Typography>
      );
    }
    return (
      <>
        <Typography
          className={clsx(classes.labelText, classes.alignRightTotalValue)}
        >
          Total a pagar
          {showTotalTooltip && (
            <>
              &nbsp;
              <BaseTooltip
                tooltipText={
                  'Este valor hace referencia al valor  de la factura del último mes'
                }
                showTooltipOnClick
                startWithBreakline
              />
            </>
          )}
        </Typography>
      </>
    );
  };

  const renderExpire = date => {
    let text = 'Venció';
    const dateText = moment(date).fromNow();
    if (moment(date).diff() > 0) {
      text = 'Vence';
    }

    return (
      <Typography title={moment(date).format('L')} className={classes.subText}>
        {text} {dateText}
      </Typography>
    );
  };

  const handlePayCouponButtonClick = useCallback(() => {
    history.push(ROUTE_NAMES.payments, {
      type: 'coupon'
    });
  }, []);

  if (loading) {
    return (
      <div className={clsx(classes.couponRoot, className)}>
        <div className={clsx(classes.cardHalf, classes.skeletonTop)}>
          <Skeleton className={classes.skeletonTop} />
          <div className={classes.skeletonCardBottom}>
            <Skeleton className={classes.skeletonBottom} />
            <Skeleton className={classes.skeletonBottom} />
          </div>
        </div>
      </div>
    );
  }

  if (!invoiceDetails) {
    return (
      <div className={clsx(classes.couponRoot, className)}>
        <Typography className={classes.errorTitle}>
          <span role="img" aria-label="construction">
            🚧
          </span>{' '}
          En el momento no pudimos obtener tu factura{' '}
          <span role="img" aria-label="construction">
            🚧
          </span>
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.errorBody}>
              Estamos trabajando para mejorar tu experiencia{' '}
              <span role="img" aria-label="constructio_worker">
                👷
              </span>
              .
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.errorBody}>
              Puedes seguir realizando los pagos de tu factura con tu número de
              cupón.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            className={clsx(classes.payCouponButton, classes.alignRight)}
          >
            <FlatButton
              id="InvoiceCard_button_payCouponRedirect"
              color="primary"
              className={classes.actionButton}
              onClick={handlePayCouponButtonClick}
            >
              Pagar factura
              <BackIcon size={16} className={classes.iconRightStyle} />
            </FlatButton>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div id={id} className={clsx(classes.couponRoot, className)}>
      <div className={clsx(classes.cardHalf, classes.leftHalf)}>
        <Typography className={classes.dateText}>
          {moment([invoiceDetails.year, invoiceDetails.month - 1]).format(
            'MMMM YYYY'
          )}
        </Typography>
        <div className={classes.infoContainer}>
          <Typography className={classes.subText}>
            {Company.contractConjugation.capitalized.singular.main} nº{' '}
            {invoiceDetails.contractId}
          </Typography>
          <Typography className={classes.subText}>
            Factura nº {invoiceDetails.id}
          </Typography>
          <Typography className={classes.subText}>
            Cupón nº {invoiceDetails.couponId}
          </Typography>
          {invoiceDetails.isPaid
            ? ''
            : renderExpire(invoiceDetails.expirationDate)}
        </div>
      </div>
      <div className={clsx(classes.cardHalf, classes.rightHalf)}>
        {renderLabel(invoiceDetails)}
        <Typography className={clsx(classes.valueText, classes.alignRight)}>
          {numberWithDots(invoiceDetails.couponValue)}
        </Typography>
        {showCurrentDebtButton && (
          <FlatButton
            id="InvoiceCard_button_debt_status_Redirect"
            color="primary"
            className={classes.buttonDebtsStatus}
            onClick={() => history.push(ROUTE_NAMES.debtStatus)}
          >
            Ver saldo actual
          </FlatButton>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  couponRoot: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: theme.palette.background.cardDark,
    border: 'solid 1px #dbdbdb',
    borderRadius: theme.custom.borderRadius,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [`@media (max-width:${theme.breakpoints.values.sm - 1}px)`]: {
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    [`@media (max-width:${theme.breakpoints.values.xm - 1}px)`]: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  cardHalf: {
    display: 'flex',
    flexDirection: 'column'
  },
  rightHalf: {
    flex: 0.4,
    alignSelf: 'center'
  },
  leftHalf: {
    flex: 0.6
  },
  dateText: {
    fontSize: 18,
    fontWeight: 500,
    textTransform: 'capitalize',
    color: theme.palette.text.primary,
    [`@media (max-width:${theme.breakpoints.values.sm - 1}px)`]: {
      fontSize: 16
    },
    [`@media (max-width:${theme.breakpoints.values.xm - 1}px)`]: {
      fontSize: 14
    }
  },
  valueText: {
    fontSize: 28,
    fontWeight: 500,
    color: theme.palette.text.default,
    whiteSpace: 'nowrap',
    [`@media (max-width:${theme.breakpoints.values.sm - 1}px)`]: {
      fontSize: 23
    },
    [`@media (max-width:${theme.breakpoints.values.xm - 1}px)`]: {
      fontSize: 18
    }
  },
  labelText: {
    fontSize: 14,
    fontWeight: 600,
    [`@media (max-width:${theme.breakpoints.values.sm - 1}px)`]: {
      fontSize: 13
    },
    [`@media (max-width:${theme.breakpoints.values.xm - 1}px)`]: {
      fontSize: 12
    }
  },
  payedText: {
    color: theme.palette.success.main
  },
  pendingText: {
    color: theme.palette.warning.main
  },
  infoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  subText: {
    display: 'inline',
    fontSize: 12,
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    color: theme.palette.text.greyDark,
    [`@media (max-width:${theme.breakpoints.values.sm - 1}px)`]: {
      paddingTop: theme.spacing(0.3)
    },
    [`@media (max-width:${theme.breakpoints.values.xm - 1}px)`]: {
      paddingTop: theme.spacing(0.3),
      fontSize: 10,
      paddingRight: 0
    }
  },
  alignRight: {
    textAlign: 'right'
  },
  alignRightTotalValue: {
    textAlign: 'right',
    display: 'flex',
    width: 'auto',
    alignSelf: 'end',
    alignItems: 'center',
    justifyContent: 'center'
  },
  skeletonTop: {
    width: '100%'
  },
  skeletonCardBottom: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  skeletonBottom: {
    width: '45%'
  },
  errorTitle: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.primary,
    [`@media (max-width:${theme.breakpoints.values.sm - 1}px)`]: {
      fontSize: 14
    }
  },
  errorBody: {
    fontSize: 14,
    color: theme.palette.text.primary,
    marginTop: theme.spacing()
  },
  payCouponButton: {
    marginTop: theme.spacing(2)
  },
  iconRightStyle: {
    transform: 'rotate(180deg)',
    fill: theme.palette.primary.main,
    marginLeft: theme.spacing()
  },
  actionButton: {
    fontWeight: 500,
    fontSize: 12,
    color: theme.palette.common.black,
    textTransform: 'none',
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  buttonDebtsStatus: {
    fontWeight: 500,
    fontSize: 12,
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'end',
    textDecoration: 'underline'
  }
}));

export default InvoiceCardGDC;
