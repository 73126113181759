import React, { useEffect, useState, useContext } from 'react';
import { green, blue } from '@material-ui/core/colors';
import {
  useMediaQuery,
  CircularProgress,
  makeStyles,
  Typography
} from '@material-ui/core';

import BarChart from '../../Components/Graph/BarChart';
import SweetAlert from '../../Components/Alerts/SweetAlert';

import { UserContext, UserDispatchContext } from '../../Contexts/UserContext';
import { AlertsDispatchContext } from '../../Contexts/AlertsContext';

import { GetInvoiceConsumptionAPI } from '../../API/Invoices/InvoicesAPI';

import {
  extractErrorMessage,
  redirectOnAuthFailure
} from '../../Utils/Errors/Errors';
import _get from 'lodash/get';
import { OnlineMeterLink } from '../../Configs/Links';
import { PortalCheck } from '../../Configs/general';
import clsx from 'clsx';
import { logoutUser } from '../../Utils/User/Actions';

const parseConsumptionData = consumptionData => {
  const dates = consumptionData.map(element => element.date);
  const values = consumptionData.map(element => element.consumption);
  const valuesKWH = consumptionData.map(element => element.consumptionKWH);

  return { dates, values, valuesKWH };
};

const InvoiceConsumptionGraph = props => {
  const { invoiceId } = props;

  const classes = useStyles();

  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);

  const setAlert = useContext(AlertsDispatchContext);

  const [loading, setLoading] = useState(true);
  const [consumptionData, setConsumptionData] = useState({
    dates: [],
    values: [],
    valuesKWH: []
  });
  const authToken = _get(currentUser, 'token');

  const smallScreen = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  useEffect(() => {
    let ignoreRequest = false;

    const fechData = async () => {
      setLoading(true);

      const response = await GetInvoiceConsumptionAPI(invoiceId, authToken);

      if (ignoreRequest) {
        return;
      }

      if (response.success) {
        const parsedConsumptionData = parseConsumptionData(response.data.data);

        setConsumptionData(parsedConsumptionData);
        setLoading(false);
      } else {
        setLoading(false);

        if (
          redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
        ) {
          return;
        }

        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
      }
    };

    fechData();

    return () => {
      ignoreRequest = true;
    };
  }, [authToken, setAlert, setCurrentUser, invoiceId]);

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress size={32} />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <SweetAlert
        type="info"
        message={
          <>
            <Typography className={classes.fontSizeAlert}>
              Puedes visualizar el consumo en m³ o kWh seleccionando la leyenda
              de la unidad correspondiente.
            </Typography>
            {PortalCheck.isEfigas && (
              <Typography
                className={clsx(classes.fontSizeAlert, classes.paddingMeter)}
              >
                Puedes obtener más información sobre el medidor en línea
                haciendo clic{' '}
                <a
                  href={OnlineMeterLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <strong>aquí</strong>
                </a>
              </Typography>
            )}
          </>
        }
      />
      <BarChart
        title="Consumos"
        features={consumptionData.dates}
        data={[
          {
            index: 0,
            label: 'm³',
            data: consumptionData.values,
            borderColor: blue.A700,
            backgroundColor: blue.A100,
            borderWidth: 2,
            borderSkipped: false,
            buttonStyle: classes.m3Button
          },
          {
            index: 1,
            label: 'kWh',
            data: consumptionData.valuesKWH,
            borderColor: green.A700,
            backgroundColor: green.A100,
            borderWidth: 2,
            borderSkipped: false,
            hidden: true,
            buttonStyle: classes.kwhButton
          }
        ]}
        height={smallScreen ? 400 : undefined}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 20
  },
  loadingContainer: {
    width: '100%',
    height: 150,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  m3Button: {
    borderColor: blue[100],
    backgroundColor: blue[100],
    '&:hover': {
      transition: 'all 1.2s ease',
      borderColor: `${blue.A700} !important`,
      backgroundColor: `${blue.A700} !important`
    },
    '&:disabled': {
      color: theme.palette.common.white,
      borderColor: blue.A700,
      backgroundColor: blue.A700
    }
  },
  kwhButton: {
    borderColor: green[100],
    backgroundColor: green[100],
    '&:hover': {
      borderColor: `${green.A700} !important`,
      backgroundColor: `${green.A700} !important`,
      transition: 'all 1.2s ease'
    },
    '&:disabled': {
      color: theme.palette.common.white,
      borderColor: green.A700,
      backgroundColor: green.A700
    }
  },
  fontSizeAlert: {
    fontSize: 13
  },
  paddingMeter: {
    paddingTop: 5
  }
}));

export default InvoiceConsumptionGraph;
