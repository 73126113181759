import { portal } from '../Configs/general';

export const RecaptchaAction = {
  AssociateContract: `${portal}_contract_associate`,
  AssociateContractGetChallenge: `${portal}_contract_associate_get_challenge`,
  AssociateContractCheckChallenge: `${portal}_contract_associate_check_challenge`,
  Login: `${portal}_login_user`,
  RecoverPassword: `${portal}_recover_password`,
  ValidatePartialPayments: `${portal}_payments_validate_partial`,
  CheckPartialPayments: `${portal}_payments_check_partial`,
  PartialCouponPayments: `${portal}_payments_generate_partial_coupon`,
  PeriodicRevisionHistory: `${portal}_periodic_revision_history`,
  PeriodicRevisionRequest: `${portal}_periodic_revision_request`,
  PeriodicRevisionAgenda: `${portal}_periodic_revision_agenda`,
  PeriodicRevisionOrderInfo: `${portal}_periodic_revision_order_info`,
  PeriodicRevisionSchedule: `${portal}_periodic_revision_schedule`,
  PeriodicRevisionUpdateSchedule: `${portal}_periodic_revision_update_schedule`,
  PeriodicRevisionCancelSchedule: `${portal}_periodic_revision_cancel_schedule`
};
