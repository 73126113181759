import React, { Fragment, useEffect, useContext } from 'react';
import jwtDecode from 'jwt-decode';
import { history } from '../../Routes/history';
import { ROUTE_NAMES } from '../../Routes/Routes';
import { extractErrorMessage } from '../../Utils/Errors/Errors';
import { VerifyAccountAPI } from '../../API/UserAPI';
import { AlertsDispatchContext } from '../../Contexts/AlertsContext';

const VerifyAccount = props => {
  const { match = { params: { token: null } } } = props;
  const { token } = match.params;

  const setAlert = useContext(AlertsDispatchContext);

  useEffect(() => {
    if (!token) {
      history.replace('/');
      return;
    }

    let decoded = null;
    try {
      decoded = jwtDecode(token);
    } catch (err) {
      decoded = null;
    }

    if (!decoded) {
      history.replace('/');
      return;
    }

    const verify = async () => {
      const response = await VerifyAccountAPI(token);
      if (!response.success) {
        let alert = {
          type: 'error',
          message: 'Ocurrió un error validando tu correo, intenta de nuevo'
        };

        if (
          response.error &&
          response.error.response &&
          response.error.response.status !== 401
        ) {
          alert = {
            type: 'info',
            message: extractErrorMessage(response).message
          };
        }

        setAlert(alert);
        history.replace(ROUTE_NAMES.login);
        return;
      }

      setAlert({
        type: 'success',
        message: 'Hemos validado tu correo electrónico'
      });
      history.replace(ROUTE_NAMES.login);
    };
    verify();
  }, [token, setAlert]);

  return <Fragment></Fragment>;
};

export default VerifyAccount;
