import * as React from 'react';

function SvgPaginationBack(props) {
  return (
    <svg
      width="10.207"
      height="11.414"
      viewBox="0 0 10.207 11.414"
      fill={'#2a61ba'}
      {...props}
    >
      <g id="ic-chevron-back-last" transform="translate(-805.5 -4.793)">
        <path
          id="ic-chevron-back-outline"
          d="M189,112l-5,5,5,5"
          transform="translate(626 -106.5)"
          fill="none"
          stroke={props.fill || '#2a61ba'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <line
          id="Línea_72"
          data-name="Línea 72"
          y1="10"
          transform="translate(806 5.5)"
          fill="none"
          stroke={props.fill || '#2a61ba'}
          strokeLinecap="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}

export default SvgPaginationBack;
