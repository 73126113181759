export const defaultAlertError = (requestError, dispatchAlert, key) => {
  if (requestError.response) {
    const error = requestError.response.data.errors;
    if (error) {
      dispatchAlert({
        type: 'error',
        message:
          error[key] || error.base || 'Ocurrió un error, intente más tarde'
      });
      return;
    }
  }
  dispatchAlert({
    type: 'error',
    message: 'Ocurrió un error, intenta de nuevo'
  });
};

export const defaultAlertSuccess = (requestSuccess, dispatchAlert, key) => {
  if (requestSuccess) {
    const success = requestSuccess;
    dispatchAlert({
      type: 'success',
      message:
        success.alertMessage ||
        success[key] ||
        success.message ||
        'Consulta satisfactoria'
    });
  }
};
