import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, MenuItem, CircularProgress } from '@material-ui/core';
import SvgIcCloseCircle from '../CustomIcons/IcCloseCircle';

const FilterInput = props => {
  const {
    className,
    id,
    options,
    renderOption,
    InputLabelProps,
    InputProps = {},
    SelectProps = {},
    select = true,
    loading,
    clearable,
    handleClear,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <TextField
      select={select}
      id={id}
      className={clsx(classes.root, className)}
      margin={rest.margin || 'none'}
      variant={rest.variant || 'outlined'}
      InputProps={{
        classes: {
          root: classes.root,
          input: classes.input,
          adornedEnd: classes.noPadding,
          ...InputProps.classes
        },

        endAdornment: (clearable || loading) && (
          <div
            className={clsx(
              classes.adornmentWrapper,
              classes.adornmentWrapperEnd
            )}
          >
            {clearable && (
              <SvgIcCloseCircle
                className={classes.clearIcon}
                color="primary"
                fontSize="small"
                onClick={handleClear}
              />
            )}
            {loading && <CircularProgress size={15} color="primary" />}
          </div>
        ),
        ...InputProps
      }}
      InputLabelProps={{
        ...InputLabelProps
      }}
      SelectProps={{
        classes: {
          icon: clearable || loading ? classes.hidden : undefined,
          ...SelectProps.classes
        },
        SelectDisplayProps: {
          id: `${id}-select`,
          ...SelectProps.SelectDisplayProps
        },
        ...SelectProps
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.helperText
        }
      }}
      {...rest}
    >
      {options && renderOption
        ? options.map(renderOption)
        : options.map((obj, idx) => (
            <MenuItem
              id={`SelectInput_${idx}_${id}`}
              classes={{
                root: classes.item
              }}
              key={`${idx}-${obj.label}`}
              value={obj.value}
            >
              <div className={classes.itemContainer}>
                {obj.image && obj.image({ size: 21, className: classes.image })}
                {obj.label}
              </div>
            </MenuItem>
          ))}
    </TextField>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 58,
    position: 'relative'
  },
  input: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center'
  },
  item: {
    fontSize: 14
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 0,
    whiteSpace: 'nowrap'
  },
  image: {
    fill: theme.palette.primary.main,
    marginRight: theme.spacing(1.5)
  },
  helperText: {
    fontSize: 12,
    marginTop: 5
  },
  hidden: {
    visibility: 'hidden'
  },
  clearIcon: { cursor: 'pointer', fill: theme.palette.text.greyDark },
  noPadding: {
    padding: 0
  },
  adornmentWrapper: {
    position: 'absolute',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  adornmentWrapperEnd: {
    right: 5
  }
}));

export default FilterInput;
