import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';

import Success from './Success';
import AlertImage from '../../Components/AlertImage';

const FinalView = props => {
  const classes = useStyles();
  const { formState, userId = 'xxx' } = props;

  const LinkComponent = forwardRef((buttonProps, _) => (
    <NavLink to={'/'} {...buttonProps} replace />
  ));
  LinkComponent.displayName = 'notfoundLink';

  return (
    <div className={classes.alertContainer}>
      <AlertImage formState={formState} />
      <Success formState={formState} userId={userId} />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  alertContainer: {
    paddingTop: theme.spacing(8),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(10)
    }
  }
}));

export default FinalView;
