import React, { useCallback } from 'react';

import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import BaseButton from '../Buttons/BaseButton';
import FlatButton from '../Buttons/FlatButton';
import BaseDialog from './BaseDialog';

const ConfirmActionDialog = props => {
  const {
    open,
    onClose,
    onAccept = () => {},
    onDeny = () => onClose(),
    acceptText = 'Activar',
    denyText = 'No activar',
    content = null,
    ...others
  } = props;

  const classes = useStyles();

  const onClickHandler = useCallback(
    action => {
      action();
      onClose();
    },
    [onClose]
  );

  const renderDialogActions = useCallback(() => {
    return (
      <>
        <FlatButton
          className={classes.actionButton}
          onClick={() => onClickHandler(onDeny)}
        >
          {denyText}
        </FlatButton>
        <BaseButton
          className={classes.actionButton}
          onClick={() => onClickHandler(onAccept)}
        >
          {acceptText}
        </BaseButton>
      </>
    );
  }, [
    onAccept,
    onDeny,
    classes.actionButton,
    denyText,
    acceptText,
    onClickHandler
  ]);

  const renderDialogContent = useCallback(() => {
    return (
      <>
        <Typography paragraph className={classes.dialogText}>
          Activando el envío de factura por este medio tendrás los siguientes
          beneficios:
        </Typography>
        <Typography paragraph className={classes.dialogText}>
          <span className={classes.textBold}>• </span>Recibirás más pronto tu
          factura cuando esté disponible
        </Typography>
        <Typography paragraph className={classes.dialogText}>
          <span className={classes.textBold}>• </span>Contribuirás al medio
          ambiente evitando el uso de papel
        </Typography>
      </>
    );
  }, [classes.dialogText, classes.textBold]);

  return (
    <BaseDialog
      handleClose={onClose}
      open={open}
      title="¿Estás seguro?"
      actions={renderDialogActions}
      content={content || renderDialogContent}
      contentSize="small"
      actionsStyle={classes.baseDialogActions}
      {...others}
    />
  );
};

const useStyles = makeStyles(theme => ({
  dialogText: {
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      fontSize: 14
    }
  },
  textBold: {
    fontWeight: 600
  },
  baseDialogActions: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'column',
      padding: theme.spacing(2)
    },
    justifyContent: 'space-between',
    padding: theme.spacing(2, 4)
  },
  actionButton: {
    '&:nth-child(2)': {
      margin: 0
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      '&:first-child': {
        marginTop: theme.spacing(2),
        order: 1
      },
      '&:nth-child(2)': {
        order: 0
      }
    }
  }
}));

export default ConfirmActionDialog;
