import React, { useCallback, useContext, useState, useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';

import BaseButton from '../../../Components/Buttons/BaseButton';
import TextInput from '../../../Components/Inputs/TextInput';
import ShowPassword from '../../../Components/Adornments/ShowPassword';

import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';
import { UserDispatchContext } from '../../../Contexts/UserContext';

import { ROUTE_NAMES } from '../../../Routes/Routes';
import { LoginAPI } from '../../../API/UserAPI';
import { extractErrorMessage } from '../../../Utils/Errors/Errors';

import * as yup from 'yup';
import { history } from '../../../Routes/history';
import RecaptchaDisclaimer from '../../../Components/Captcha/RecaptchaDisclaimer';
import { getRecaptchaToken } from '../../../Utils/Recaptcha';
import { RecaptchaAction } from '../../../Enums/recaptcha';
import SocialLoginButtons from '../SocialLoginButtons';
import { updateUser } from '../../../Utils/User/Actions';

const loginSchema = yup.object({
  password: yup.string().required('Ingresa una contraseña'),
  username: yup
    .string()
    .trim()
    .lowercase()
    .email('Debes ingresar un correo válido')
    .required('Ingresa un correo')
});

const LoginContentEFG = props => {
  const {
    closeDialog = null,
    initialUsername = '',
    hideSocialButtons = false
  } = props;

  const classes = useStyles();

  const isFirefox = window.navigator.userAgent.indexOf('Firefox') !== -1;

  const setCurrentUser = useContext(UserDispatchContext);
  const setAlert = useContext(AlertsDispatchContext);

  const [loading, setLoading] = useState(false);
  const [isFacebookLoading, setIsFacebookLoading] = useState(true);
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);

  // FORM states
  const [canSubmit, setCanSubmit] = useState(false);
  const [passwordShown, setShowPassword] = useState(false);
  const [username, setUsername] = useState(initialUsername);
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({
    username: null,
    password: null
  });
  const [loadingGoogle, setLoadingGoogle] = useState(false);

  const onChangeUsername = useCallback(event => {
    setUsername(event.target.value);
  }, []);

  const onChangePassword = useCallback(event => {
    setPassword(event.target.value);
  }, []);

  const togglePasswordShow = useCallback(() => {
    setShowPassword(!passwordShown);
  }, [passwordShown]);

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();

      setLoading(true);

      const recaptchaToken = await getRecaptchaToken(RecaptchaAction.Login);

      const response = await LoginAPI(username, password, recaptchaToken);
      if (!response.success) {
        const message = extractErrorMessage(response);
        if (message.message === 'No has validado tu correo') {
          history.push(ROUTE_NAMES.confirmEmail, { email: username });
          return;
        }

        setAlert({
          type: 'error',
          message: 'Email o contraseña incorrectos'
        });
        setLoading(false);
        return;
      }

      const { data: token, dataApp: appToken } = response.data;

      updateUser(setCurrentUser, token, appToken);

      if (!closeDialog) {
        history.replace('/');
      }
    },
    [username, password, setCurrentUser, setAlert, closeDialog]
  );

  const redirectToRegister = () => {
    if (closeDialog) {
      closeDialog();
    }
    history.push(ROUTE_NAMES.register);
  };

  const handleForgotPassword = () => {
    if (closeDialog) {
      closeDialog();
    }
    history.push(ROUTE_NAMES.forgot, { username });
  };

  useEffect(() => {
    if (!username) {
      setCanSubmit(false);
      return;
    }

    try {
      loginSchema.validateSync({ username, password });
      setErrors({});
      setCanSubmit(true);
    } catch (err) {
      setCanSubmit(false);
      setErrors({
        [err.path]: err.errors[0]
      });
    }
  }, [username, password]);

  useEffect(() => {
    if (isFacebookLoading) {
      /* Disable button when user is in Firefox Incognito mode
      because Facebook login doesn't work */
      if (isFirefox) {
        const disableFirefox = setTimeout(
          () => setIsFacebookLoading(false),
          20000
        );
        return () => {
          clearTimeout(disableFirefox);
        };
      }
    }
  }, [isFacebookLoading, setIsFacebookLoading, isFirefox]);

  return (
    <>
      <div className={classes.formContainer}>
        <Typography className={classes.title}>Iniciar sesión</Typography>
        <SocialLoginButtons
          loading={loading}
          closeDialog={closeDialog}
          hideSocialButtons={hideSocialButtons}
          loadingGoogle={loadingGoogle}
          setLoadingGoogle={setLoadingGoogle}
          hideAppleButton
        />
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextInput
            value={username}
            onChange={onChangeUsername}
            id="email"
            label="Correo electrónico"
            type="email"
            error={Boolean(errors.username)}
            helperText={errors.username}
            required={true}
            fullWidth
          />
          <TextInput
            value={password}
            onChange={onChangePassword}
            margin="none"
            className={classes.passwordField}
            id="password"
            label="Contraseña"
            type={passwordShown ? 'text' : 'password'}
            required={true}
            error={Boolean(errors.password)}
            helperText={errors.password}
            fullWidth
            InputProps={{
              endAdornment: (
                <ShowPassword
                  togglePasswordShow={togglePasswordShow}
                  passwordShown={passwordShown}
                />
              )
            }}
          />

          <Typography
            className={classes.forgotPassword}
            onClick={handleForgotPassword}
          >
            ¿Olvidaste tu contraseña?
          </Typography>

          <BaseButton
            loading={loading}
            progress={loading}
            className={classes.button}
            disabled={!canSubmit || loadingGoogle}
            fullWidth
            id="login"
            type="submit"
            color="primary"
            variant="contained"
          >
            Iniciar sesión
          </BaseButton>

          <Typography className={classes.bottomText}>
            ¿No tienes cuenta?{' '}
            <span
              className={classes.signUp}
              onClick={
                !loadingGoogle && !loading ? redirectToRegister : undefined
              }
            >
              Regístrate
            </span>
          </Typography>
          <div className={classes.recaptchaContainer}>
            <RecaptchaDisclaimer
              loading={loadingCaptcha}
              setLoading={setLoadingCaptcha}
            />
          </div>
        </form>
      </div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  formContainer: {
    maxWidth: '80%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column'
  },
  title: {
    marginTop: 40,
    marginBottom: 20,
    fontSize: 24
  },
  passwordField: {
    marginTop: theme.spacing(2)
  },
  forgotPassword: {
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    color: theme.palette.text.default,
    paddingTop: 8,
    '&:hover': {
      color: theme.palette.text.main
    }
  },
  button: {
    marginTop: theme.spacing(4)
  },
  form: {
    maxWidth: '100%'
  },
  bottomText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontSize: 14,
    textAlign: 'center'
  },
  signUp: {
    fontSize: 14,
    color: theme.palette.primary.dark,
    fontWeight: 500,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  recaptchaContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

export default LoginContentEFG;
