import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Prompt } from 'react-router-dom';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import TopBarProgress from '../Components/TopBarProgress';
import GanaLocoForm from '../Components/GanaLocoForm';
import { FooterDispatchContext } from '../../../Contexts/FooterContext';

import { FORM_STATE } from '../fixes/ganaLoco_enums';

const GanaLocoGDC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('xs'));

  const setShowFooter = useContext(FooterDispatchContext);
  useEffect(() => {
    setShowFooter(false);
  }, [setShowFooter]);

  const [topBarProgress, setTopBarProgress] = useState(25);
  const [formState, setFormState] = useState(FORM_STATE.AWAITING);

  return (
    <Fragment>
      <Prompt
        message={() => {
          setShowFooter(true);
          return true;
        }}
      />
      <div className={classes.root}>
        {formState !== FORM_STATE.FAILED && (
          <TopBarProgress
            isMobileSize={isMobileSize}
            topBarProgress={topBarProgress}
          />
        )}
        <Container className={classes.root} maxWidth="md">
          <GanaLocoForm
            formState={formState}
            setFormState={setFormState}
            isMobileSize={isMobileSize}
            setTopBarProgress={setTopBarProgress}
          />
        </Container>
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles({
  root: {
    padding: 0
  }
});

export default GanaLocoGDC;
