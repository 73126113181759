import React from 'react';

const SvgIcBillCoupon = props => (
  <svg
    id="icon__bill_coupon_1"
    width={props.size || 67.327}
    height={props.size || 64.1}
    viewBox="0 0 67.327 64.1"
    xmlSpace="preserve"
    {...props}
  >
    <g fill="#33333">
      <path
        data-name="Path 508"
        d="M51.588 9.556h.088a1.093 1.093 0 00.151-.011 2.438 2.438 0 012.388 2.434 1.091 1.091 0 102.182 0 4.625 4.625 0 00-3.63-4.511V6.424a1.091 1.091 0 00-2.182 0v1.1a4.618 4.618 0 001.193 9.08 2.437 2.437 0 11-2.437 2.437 1.091 1.091 0 10-2.182 0 4.626 4.626 0 003.425 4.461v1.2a1.091 1.091 0 102.182 0V23.55a4.618 4.618 0 00-.989-9.129 2.436 2.436 0 01-.191-4.864zm0 0"
      />
      <path
        data-name="Path 509"
        d="M51.841 0a15.651 15.651 0 00-14.582 10.242H10.185a1.141 1.141 0 00-1.091 1.14l-.258 38.265H1.119A1.073 1.073 0 000 50.687v4.856a8.568 8.568 0 008.181 8.534v.024h33.678v-.032c.273.01.385.032.558.032h.023a8.568 8.568 0 008.554-8.568V30.981c.273.015.561.023.844.023a15.5 15.5 0 000-31zM8.6 61.919h-.011a6.416 6.416 0 01-6.407-6.387v-3.7h31.633v3.687a8.557 8.557 0 002.526 6.063c.115.115.257.2.377.339zm40.217-6.387a6.386 6.386 0 01-6.374 6.387h-.021A6.434 6.434 0 0136 55.517v-4.83a1.007 1.007 0 00-1.041-1.04H11.018l.25-37.224h25.385a16.39 16.39 0 00-.3 3.03 15.382 15.382 0 001.317 6.242h-10.3a1.091 1.091 0 000 2.182h11.484a15.393 15.393 0 009.959 6.833zm3.028-26.709a13.305 13.305 0 1113.301-13.305 13.3 13.3 0 01-13.305 13.305zm0 0"
      />
      <path
        data-name="Path 510"
        d="M20.366 28.955a3.382 3.382 0 103.492 3.381 3.441 3.441 0 00-3.492-3.381zm0 4.579a1.2 1.2 0 111.31-1.2 1.259 1.259 0 01-1.31 1.2zm0 0"
      />
      <path
        data-name="Path 511"
        d="M26.277 32.331a1.091 1.091 0 001.091 1.091h14.543a1.091 1.091 0 100-2.182H27.368a1.091 1.091 0 00-1.091 1.091zm0 0"
      />
      <path
        data-name="Path 512"
        d="M20.366 19.47a3.383 3.383 0 103.492 3.381 3.441 3.441 0 00-3.492-3.381zm0 4.58a1.2 1.2 0 111.31-1.2 1.259 1.259 0 01-1.31 1.2zm0 0"
      />
      <path
        data-name="Path 513"
        d="M20.366 38.436a3.382 3.382 0 103.492 3.381 3.441 3.441 0 00-3.492-3.381zm0 4.579a1.2 1.2 0 111.31-1.2 1.259 1.259 0 01-1.31 1.2zm0 0"
      />
      <path
        data-name="Path 514"
        d="M41.91 40.78H27.368a1.091 1.091 0 100 2.182h14.543a1.091 1.091 0 100-2.182zm0 0"
      />
    </g>
  </svg>
);

export default SvgIcBillCoupon;
