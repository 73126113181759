import React, { useState, useCallback } from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Grid, Typography, Divider, useMediaQuery } from '@material-ui/core';

import DebtCardHeader from '../Components/DebtCardHeader';
import ContractDebtInfoDialog from '../../../Components/Dialogs/ContractDebtInfoDialog';
import ContractDebtDialogContent from '../Components/ContractDebtDialogContent';

import { numberWithDots } from '../../../Utils/Format/MoneyFormat';
import { renderContractTypeDescription } from '../../Contracts/contract_enums';
import { formatAddress } from '../../../Utils/Format/Address';

import { ProductType } from '../myDebts_enums';

import clsx from 'clsx';
import { Company } from '../../../Configs/general';

const ContractDeferredDebtCard = props => {
  const { contract, title, banner, data = {} } = props;
  const { refinancing } = props;

  const classes = useStyles();
  const theme = useTheme();

  //* STATE HOOKS
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);

  // * OTHER HOOKS
  const mediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  //* FUNCTIONS
  const getDeferredTotals = types => {
    if (data.deferreds) {
      const filteredData = data.deferreds.filter(item =>
        types.includes(item.productTypeId)
      );

      const total = filteredData.reduce(
        (sum, item) => sum + item.pendingValue,
        0
      );
      return total;
    }
  };

  //* CALLBACKS
  const onHelpClick = useCallback(() => {
    setHelpDialogOpen(true);
  }, []);

  const contractDialogContent = () => (
    <ContractDebtDialogContent
      data={data}
      contract={contract}
      contractAddress={formatAddress(contract, true)}
      contractType={renderContractTypeDescription(contract.type)}
      financingServicesBalance={numberWithDots(
        getDeferredTotals([
          ProductType.FinancingServices,
          ProductType.FinancingServicesPromigas
        ])
      )}
      gasPendingBalance={numberWithDots(getDeferredTotals([ProductType.Gas]))}
      deferredSummary
    />
  );

  return (
    <div className={classes.card} style={{ backgroundImage: `url(${banner})` }}>
      <ContractDebtInfoDialog
        open={helpDialogOpen && mediumScreen}
        title={`Información ${Company.contractConjugation.regular.singular.contraction}`}
        onClose={() => setHelpDialogOpen(false)}
        content={contractDialogContent}
        deferredSummary
      />

      <DebtCardHeader
        data={data}
        title={title}
        refinancing={refinancing}
        onHelpClick={onHelpClick}
        deferredSummary
      />

      {mediumScreen && (
        <Grid container direction="column" justify="flex-end">
          <Grid item xs={12}>
            <Typography className={clsx(classes.label, classes.alignRight)}>
              Saldo total
            </Typography>
            <Typography
              className={clsx(
                classes.contractData,
                classes.total,
                classes.alignRight
              )}
            >
              {numberWithDots(data.deferredValue)}
            </Typography>
          </Grid>
        </Grid>
      )}

      {!mediumScreen && (
        <Grid container direction="row" alignItems="baseline">
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.label}>
              {Company.contractConjugation.capitalized.singular.associate} a la
              deuda
            </Typography>
            <Typography className={classes.contractData}>
              {contract.alias} - {contract.id}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={5} className={classes.contractAddress}>
            <Typography className={classes.label}>
              Dirección{' '}
              {Company.contractConjugation.capitalized.singular.contraction}
            </Typography>
            <Typography className={classes.contractData}>
              {formatAddress(contract, true)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Typography className={classes.label}>
              Tipo de {Company.contractConjugation.regular.singular.main}
            </Typography>
            <Typography className={classes.contractData}>
              {renderContractTypeDescription(contract.type)}
            </Typography>
          </Grid>
        </Grid>
      )}

      {!mediumScreen && (
        <>
          <Divider className={classes.divider} />
          <Grid item container spacing={1} alignItems="flex-start">
            <Grid item xs={12} sm={6} md={2}>
              <Typography className={classes.label}>Siguiente pago</Typography>
              <Typography className={classes.contractData}>
                {data.deferredValue !== 0 &&
                  data.deferredNextPayment &&
                  `${numberWithDots(data.deferredNextPayment.value)} `}
                {data.deferredNextPayment &&
                  data.deferredNextPayment.date &&
                  `(${data.deferredNextPayment.date})`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <Typography className={classes.label}>
                Saldo por facturar Servicios financieros
              </Typography>
              <Typography className={classes.contractData}>
                {numberWithDots(
                  getDeferredTotals([
                    ProductType.FinancingServices,
                    ProductType.FinancingServicesPromigas
                  ])
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography className={classes.label}>
                Saldo por facturar Gas
              </Typography>
              <Typography className={classes.contractData}>
                {numberWithDots(getDeferredTotals([ProductType.Gas]))}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              className={clsx({ [classes.alignRight]: !mediumScreen })}
            >
              <Typography className={classes.label}>Saldo total</Typography>
              <Typography className={clsx(classes.contractData, classes.total)}>
                {numberWithDots(data.deferredValue)}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: theme.custom.borderRadius,
    backgroundColor: theme.palette.background.cardDark,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: 'white',
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      padding: theme.spacing(2, 2, 3, 2)
    }
  },
  label: {
    fontSize: 12,
    fontWeight: 500
  },
  contractData: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.2,
    textTransform: 'capitalize'
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.divider,
    margin: theme.spacing(2, 0, 2, 0)
  },
  total: {
    fontSize: 20,
    whiteSpace: 'nowrap'
  },
  alignRight: {
    textAlign: 'right'
  },
  contractAddress: {
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      paddingRight: theme.spacing(1)
    }
  }
}));

export default ContractDeferredDebtCard;
