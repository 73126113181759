import React from 'react';
import SelectInput from '../Inputs/SelectInput';

import { Controller } from 'react-hook-form';

const ControlledSelectInput = props => {
  const { name, control, options, ...rest } = props;

  return (
    <Controller
      as={<SelectInput options={options} />}
      name={name}
      control={control}
      {...rest}
    />
  );
};

export default ControlledSelectInput;
