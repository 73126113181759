import React from 'react';
import _capitalize from 'lodash/capitalize';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import { fullName } from '../../Utils/Format/Names';

const ContractCard = props => {
  const classes = useStyles();
  const {
    data,
    loading,
    className,
    showName = true,
    showStratum = true
  } = props;

  // * FUNCTIONS

  const _renderAddress = dataInfo => {
    if (loading) {
      return <Skeleton className={classes.skeleton} />;
    }
    let text = dataInfo ? 'Sin dirección' : null;
    if (!text) {
      return <div className={classes.placeHolderBox} />;
    }
    if (dataInfo.city) {
      text = dataInfo.city;
    }
    if (dataInfo.city && dataInfo.department) {
      text = `${text}, `;
    }
    if (dataInfo.department) {
      text = text + dataInfo.department;
    }
    if (dataInfo.address) {
      text = `${text} ${dataInfo.address}`;
    }

    return (
      <Typography className={classes.labelText}>{_capitalize(text)}</Typography>
    );
  };

  const _renderName = text => {
    if (loading) {
      return <Skeleton className={classes.skeleton} />;
    }
    if (!text) {
      return <div className={classes.placeHolderBox} />;
    }
    return (
      <Typography className={classes.labelText}>{_capitalize(text)}</Typography>
    );
  };

  const _renderStratum = (value, type) => {
    if (loading) {
      return (
        <div className={classes.labelHalfContainer}>
          <Typography className={classes.subText}>Estrato</Typography>
          <Skeleton className={classes.skeleton} />
        </div>
      );
    }
    if (!value) {
      return (
        <div className={classes.labelHalfContainer}>
          <Typography className={classes.subText}>Estrato</Typography>
          <div className={classes.placeHolderBox} />
        </div>
      );
    }

    if (type === 1) {
      return (
        <div className={classes.labelHalfContainer}>
          <Typography className={classes.subText}>Estrato</Typography>
          <Typography className={classes.labelText}>{value}</Typography>
        </div>
      );
    }

    // Leave the empty container for non-residential contracts to maintain the parent alignment
    return (
      <div className={classes.labelHalfContainer}>
        <Typography className={classes.subText}></Typography>
        <Typography className={classes.labelText}></Typography>
      </div>
    );
  };

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.labelContainer}>
        <Typography className={classes.subText}>
          Dirección del predio
        </Typography>
        {_renderAddress(data)}
      </div>
      {showName && (
        <div className={classes.labelHalfContainer}>
          <Typography className={classes.subText}>
            Nombre del titular
          </Typography>
          {_renderName(data ? fullName(data.holder, 'name', 'lastName') : null)}
        </div>
      )}
      {showStratum &&
        _renderStratum(data ? data.stratum : null, data ? data.type : null)}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.cardDark,
    border: 'solid 1px #dbdbdb',
    borderRadius: theme.custom.borderRadius,
    paddingTop: '7px',
    paddingBottom: '7px',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [`@media (max-width:${theme.breakpoints.values.sm - 1}px)`]: {
      // paddingTop: theme.spacing(2.5),
      // paddingBottom: theme.spacing(2.5),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    [`@media (max-width:${theme.breakpoints.values.xm - 1}px)`]: {
      // paddingTop: theme.spacing(1.5),
      // paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  labelContainer: {
    paddingTop: theme.spacing(0.1),
    width: '100%'
  },
  labelHalfContainer: {
    paddingTop: theme.spacing(0.1),
    width: '50%',
    '&:last-child': {
      paddingLeft: theme.spacing()
    }
  },
  labelText: {
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'capitalize',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    [theme.breakpoints.up('sm')]: {
      fontSize: 16
    }
  },
  subText: {
    display: 'inline',
    fontSize: 12
  },
  placeHolderBox: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    height: 24,
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    borderRadius: 4
  },
  skeleton: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    height: 24
  }
}));

export default ContractCard;
