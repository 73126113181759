import { WhatsAppHelpLink } from '../../Configs/Links';
import { PortalCheck } from '../../Configs/general';
import { WADefaultMessage } from '../enums';

export const OpenNewTab = url => {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.rel = 'noopener noreferrer';
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const WhatsAppHelpLinkHandler = () => {
  const chatMessage = WADefaultMessage();

  const url = PortalCheck.isGascaribe
    ? `${WhatsAppHelpLink}&text=${chatMessage}`
    : WhatsAppHelpLink;
  OpenNewTab(url);
};
