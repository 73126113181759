import React from 'react';

const SvgStateIcon = props => (
  <svg width="380.74" height="250" viewBox="0 0 274.132 182" {...props}>
    <g id="img-empty-state" transform="translate(-450.658 -230.454)">
      <path
        id="Trazado_188"
        data-name="Trazado 188"
        d="M289.077,89.036H261.822A79.7,79.7,0,0,0,132.945,39.9H105.437a12.131,12.131,0,0,0-12.11,12.11h0a12.187,12.187,0,0,0,10.086,11.952h19.6a13.251,13.251,0,0,1,13.217,13.217v1.486a13.251,13.251,0,0,1-13.217,13.217H83.81a12.131,12.131,0,0,0-12.11,12.11h0A12.131,12.131,0,0,0,83.81,116.1h20.9a79.694,79.694,0,0,0,127.612,48.376,11.8,11.8,0,0,0,3.193.443h30.86a12.131,12.131,0,0,0,12.11-12.11h0a12.131,12.131,0,0,0-12.11-12.11H248.163a12.968,12.968,0,0,1-12.932-12.932v-1.707a12.968,12.968,0,0,1,12.932-12.932H277.41a11.138,11.138,0,0,1,2.087.19h9.517a12.131,12.131,0,0,0,12.11-12.11h0A12.071,12.071,0,0,0,289.077,89.036Z"
        transform="translate(401.458 230.965)"
        fill="#eef9fb"
      />
      <path
        id="Trazado_186"
        data-name="Trazado 186"
        d="M23.9,102.551H10.972A11.025,11.025,0,0,1,0,91.548V89.272A11.018,11.018,0,0,1,10.972,78.3h12.9A11,11,0,0,1,34.844,89.272v2.277A10.977,10.977,0,0,1,23.9,102.551Z"
        transform="translate(450.658 192.535)"
        fill="#eef9fb"
      />
      <path
        id="Trazado_187"
        data-name="Trazado 187"
        d="M780.672,421.42h-12.9A11,11,0,0,1,756.8,410.448v-2.276A11,11,0,0,1,767.772,397.2h12.9a11,11,0,0,1,10.972,10.972v2.276A11.018,11.018,0,0,1,780.672,421.42Z"
        transform="translate(-66.853 -25.534)"
        fill="#eef9fb"
      />
      <ellipse
        id="Elipse_149"
        data-name="Elipse 149"
        cx="4.879"
        cy="4.879"
        rx="4.879"
        ry="4.879"
        transform="translate(514.939 232.454)"
        fill="none"
        stroke="#63d8f1"
        strokeMiterlimit="10"
        strokeWidth="4"
      />
      <ellipse
        id="Elipse_151"
        data-name="Elipse 151"
        cx="4.879"
        cy="4.879"
        rx="4.879"
        ry="4.879"
        transform="translate(665.204 303.612)"
        fill="none"
        stroke="#63d8f1"
        strokeMiterlimit="10"
        strokeWidth="4"
      />
      <path
        id="Trazado_190"
        data-name="Trazado 190"
        d="M224.6,204.967h-7.541v-7.541a1.626,1.626,0,1,0-3.253,0v7.541h-7.578a1.626,1.626,0,1,0,0,3.253h7.541v7.541a1.626,1.626,0,0,0,3.253,0V208.22h7.541a1.641,1.641,0,0,0,1.626-1.626A1.586,1.586,0,0,0,224.6,204.967Z"
        transform="translate(298.511 107.997)"
        fill="#63d8f1"
      />
      <path
        id="Trazado_191"
        data-name="Trazado 191"
        d="M728.714,257.04l-4.14-4.14,4.14-4.14a1.255,1.255,0,0,0-1.774-1.774l-4.14,4.14-4.14-4.14a1.255,1.255,0,0,0-1.774,1.774l4.14,4.14-4.14,4.14a1.255,1.255,0,0,0,1.774,1.774l4.14-4.14,4.14,4.14a1.272,1.272,0,0,0,1.774,0A1.208,1.208,0,0,0,728.714,257.04Z"
        transform="translate(-24.179 75.96)"
        fill="#63d8f1"
      />
      <g
        id="Grupo_230"
        data-name="Grupo 230"
        transform="translate(535.702 370.013)"
      >
        <g id="Grupo_229" data-name="Grupo 229">
          <path
            id="Trazado_195"
            data-name="Trazado 195"
            d="M305.427,379.076H400.26a1.839,1.839,0,0,0,0-3.676H305.427a1.839,1.839,0,0,0,0,3.676Z"
            transform="translate(-303.65 -375.4)"
            fill="#63d8f1"
          />
        </g>
      </g>
      <g
        id="Grupo_232"
        data-name="Grupo 232"
        transform="translate(642.788 370.013)"
      >
        <g id="Grupo_231" data-name="Grupo 231">
          <path
            id="Trazado_196"
            data-name="Trazado 196"
            d="M567.627,379.076h6.739a1.839,1.839,0,0,0,0-3.676h-6.739a1.839,1.839,0,0,0,0,3.676Z"
            transform="translate(-565.85 -375.4)"
            fill="#63d8f1"
          />
        </g>
      </g>
      <g
        id="Grupo_234"
        data-name="Grupo 234"
        transform="translate(503.111 370.013)"
      >
        <g id="Grupo_233" data-name="Grupo 233">
          <path
            id="Trazado_197"
            data-name="Trazado 197"
            d="M225.627,379.076h21.156a1.839,1.839,0,0,0,0-3.676H225.627a1.839,1.839,0,0,0,0,3.676Z"
            transform="translate(-223.85 -375.4)"
            fill="#63d8f1"
          />
        </g>
      </g>
      <g id="ic-transactions" transform="translate(555.243 308.114)">
        <g id="card-outline">
          <rect
            id="Rectángulo_233"
            data-name="Rectángulo 233"
            width="53.288"
            height="40.991"
            rx="4"
            transform="translate(0)"
            fill="none"
            stroke="#104eb2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Trazado_606"
            data-name="Trazado 606"
            d="M48,192h53.288M54.683,202.408h6.149v2.562H54.683Z"
            transform="translate(-48 -179.703)"
            fill="#104eb2"
            stroke="#104eb2"
            strokeLinejoin="round"
            strokeWidth="4"
          />
        </g>
        <circle
          id="Elipse_196"
          data-name="Elipse 196"
          cx="14.054"
          cy="14.054"
          r="14.054"
          transform="translate(33.964 22.838)"
          fill="#eef9fb"
        />
        <path
          id="arrow-forward-outline"
          d="M104.14,112l3.549,3.549L104.14,119.1m3.056-3.549H100"
          transform="translate(-50.933 -76.086)"
          fill="none"
          stroke="#104eb2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="arrow-forward-outline-2"
          data-name="arrow-forward-outline"
          d="M103.549,112,100,115.549l3.549,3.549m-3.056-3.549h7.193"
          transform="translate(-59.127 -79.635)"
          fill="none"
          stroke="#104eb2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <g id="search-outline" transform="translate(36.599 25.385)">
          <path
            id="Trazado_666"
            data-name="Trazado 666"
            d="M75.723,64A11.723,11.723,0,1,0,87.447,75.723,11.723,11.723,0,0,0,75.723,64Z"
            transform="translate(-64 -64)"
            fill="none"
            stroke="#104eb2"
            strokeMiterlimit="10"
            strokeWidth="3"
          />
          <path
            id="Trazado_667"
            data-name="Trazado 667"
            d="M338.29,338.29l8.187,8.187"
            transform="translate(-317.82 -317.82)"
            fill="none"
            stroke="#104eb2"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeWidth="3"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default SvgStateIcon;
