import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from '../Views/Home/Home';

import Payment from '../Views/Payment/Payment/Payment';
import Transaction from '../Views/Payment/Transaction/Transaction/Transaction';
import Information from '../Views/Information/Information/Information';
import Login from '../Views/Login/Login';
import ForgotPassword from '../Views/ForgotPassword/ForgotPassword';
import RecoverPassword from '../Views/RecoverPassword/RecoverPassword';
import Brilla from '../Views/Brilla/Brilla';
import MyContracts from '../Views/Contracts/MyContracts/MyContracts';
import ContractAssociation from '../Views/Contracts/ContractAssociation/ContractAssociation';
import InvoiceHistory from '../Views/InvoiceHistory/InvoiceHistory/InvoiceHistory';
import VerifyAccount from '../Views/VerifyAccount/VerifyAccount';
import Signup from '../Views/Signup/Signup';
import ConfirmEmail from '../Views/ConfirmEmail/ConfirmEmail';
import CorrectEmail from '../Views/CorrectEmail/CorrectEmail';
import ForgotPasswordEmail from '../Views/ForgotPassword/ForgotPasswordEmail';
import MyProfile from '../Views/Profile/MyProfile';
import TransactionSignup from '../Views/TransactionSignup/TransactionSignup';
import TransactionsList from '../Views/TransactionsList/TransactionsList';

import { ROUTE_NAMES } from './Routes';

const RoutesGDG = props => {
  const { withBoundary, NotFoundView } = props;

  return (
    <Switch>
      <Route exact path={ROUTE_NAMES.home} component={withBoundary(Home)} />
      <Route
        exact
        path={ROUTE_NAMES.payments}
        component={withBoundary(Payment)}
      />
      <Route
        exact
        path={`${ROUTE_NAMES.payContract}/:contractId`}
        component={withBoundary(Payment)}
      />
      <Route
        exact
        path={`${ROUTE_NAMES.payCoupon}/:couponId`}
        component={withBoundary(Payment)}
      />
      <Route
        exact
        path={`${ROUTE_NAMES.transaction}/:transactionId`}
        component={withBoundary(Transaction)}
      />
      <Route
        exact
        path={`${ROUTE_NAMES.information}/:panel?`}
        component={withBoundary(Information)}
      />
      <Route exact path={ROUTE_NAMES.login} component={withBoundary(Login)} />
      <Route
        exact
        path={ROUTE_NAMES.register}
        component={withBoundary(Signup)}
      />
      <Route
        exact
        path={ROUTE_NAMES.forgot}
        component={withBoundary(ForgotPassword)}
      />
      <Route
        exact
        path={`${ROUTE_NAMES.recover}/:token`}
        component={withBoundary(RecoverPassword)}
      />
      <Route
        exact
        path={`${ROUTE_NAMES.verify}/:token`}
        component={withBoundary(VerifyAccount)}
      />
      <Route
        exact
        path={ROUTE_NAMES.profile}
        component={withBoundary(MyProfile)}
      />
      <Route exact path={ROUTE_NAMES.brilla} component={withBoundary(Brilla)} />
      <Route
        exact
        path={ROUTE_NAMES.contracts}
        component={withBoundary(MyContracts)}
      />
      <Route
        exact
        path={ROUTE_NAMES.associate}
        component={withBoundary(ContractAssociation)}
      />
      <Route
        exact
        path={ROUTE_NAMES.invoices}
        component={withBoundary(InvoiceHistory)}
      />
      <Route
        exact
        path={ROUTE_NAMES.confirmEmail}
        component={withBoundary(ConfirmEmail)}
      />
      <Route
        exact
        path={ROUTE_NAMES.forgotPasswordEmail}
        component={withBoundary(ForgotPasswordEmail)}
      />
      <Route
        exact
        path={ROUTE_NAMES.changeEmail}
        component={withBoundary(CorrectEmail)}
      />
      <Route
        exact
        path={`${ROUTE_NAMES.transactionRegister}/:transactionId?`}
        component={withBoundary(TransactionSignup)}
      />
      <Route
        exact
        path={ROUTE_NAMES.transactions}
        component={withBoundary(TransactionsList)}
      />
      <Route path="*" component={withBoundary(NotFoundView)} />
    </Switch>
  );
};

export default RoutesGDG;
