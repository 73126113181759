import axios from 'axios';
import { config } from '../../Configs';

export const GetRequestAPI = async (token, requestId) => {
  try {
    const response = await axios.get(
      `${config.api.url}/requests/${requestId}`,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GetRequestsAPI = async (token, params) => {
  try {
    const response = await axios.get(`${config.api.url}/requests`, {
      headers: {
        Authorization: token
      },
      params
    });
    return { success: true, data: response };
  } catch (err) {
    return { success: false, error: err };
  }
};
