import React from 'react';

import { Controller } from 'react-hook-form';
import Calendar from '../Calendar/Calendar';

const ControlledCalendar = props => {
  const { value, name, control, tileDisabled, disabled, ...rest } = props;

  return (
    <Controller
      as={
        <Calendar
          tileDisabled={tileDisabled}
          value={value}
          disabled={disabled}
        />
      }
      name={name}
      control={control}
      {...rest}
    />
  );
};

export default ControlledCalendar;
