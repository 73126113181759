export const ALERT_TYPE = {
  SUCCESS: 'success',
  INFO: 'info',
  ERROR: 'error',
  WARNING: 'warning'
};

export const ALERT_POSITION = {
  VERTICAL: {
    top: 'top',
    bottom: 'bottom'
  },
  HORIZONTAL: {
    left: 'left',
    right: 'right',
    center: 'center'
  }
};
