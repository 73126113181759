const fileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    /* Split is used to get only the Base64 useful representation of the PDF.
    For example: "data:application/pdf;base64,JVBERi0xLjUNC..." is the output of a PDF
    converted to Base64, but the useful information is after the comma (base64,JVBERi...).
    The Data URI (information before the comma) is not important in this case, so we only
    care of what is after the comma, and the split is made to gather that information.
    */
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = error => reject(error || 'FileToBase64_e');
  });

export default fileToBase64;
