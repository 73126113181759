import * as yup from 'yup';

import { couponConcepts } from './transactions_enums';
import { TRANSACTION_STATUS } from '../../Payment/payment_enums';
import { Company } from '../../../Configs/general';

export const filterSchema = existDates => {
  const datesSchema = {
    startDate: yup.date().typeError('La fecha de incial debe ser válida'),
    finalDate: yup
      .date()
      .when(
        'startDate',
        (startDate, members) =>
          startDate && members.min(startDate, 'Fecha final inválida')
      )
  };

  return yup.object({
    ...(existDates && datesSchema),
    contract: yup
      .object({
        value: yup
          .string()
          .typeError(
            `El número de ${Company.contractConjugation.regular.singular.main} debe ser válido`
          )
          .transform((value, originalValue) => {
            return originalValue === '' ? undefined : value;
          }),
        label: yup.string().transform((value, originalValue) => {
          return originalValue === '' ? undefined : value;
        })
      })
      .required(
        `Ingrese el número de ${Company.contractConjugation.regular.singular.main}`
      ),
    concept: yup
      .string()
      .oneOf(
        [
          ...Object.values(couponConcepts).map(couponValue =>
            String(couponValue)
          ),
          ''
        ],
        'Seleccione una opción válida'
      ),
    status: yup
      .string()
      .default('')
      .oneOf(
        [
          ...Object.values(TRANSACTION_STATUS).map(statusValue =>
            String(statusValue)
          ),
          ''
        ],
        'Seleccione una opción válida'
      ),
    transactionId: yup.string()
  });
};
