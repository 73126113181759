import React, {
  Fragment,
  useState,
  useCallback,
  useMemo,
  useContext
} from 'react';
import _get from 'lodash/get';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { history } from '../../Routes/history';
import { ROUTE_NAMES } from '../../Routes/Routes';

import Stepper from '../../Components/Stepper/Stepper';
import FullSizeProgress from '../../Components/Progress/FullSize/FullSizeProgress';

import { TRANSACTION_STATUS } from '../../Views/Payment/payment_enums';
import { UserContext } from '../../Contexts/UserContext';
import { AlertsDispatchContext } from '../../Contexts/AlertsContext';

import DonationFirst from './DonationFirst';
import DonationSecond from './DonationSecond';
import FooterPayment from '../../Views/Payment/FooterPayment';
import { extractErrorMessage } from '../../Utils/Errors/Errors';

// * API
import { DonationPseAPI } from '../../API/Payments/PaymentsAPI';

const buildPayerInfo = payerInfo => {
  return {
    payer: {
      id: '1',
      fullName: payerInfo.payerFullName.trim(),
      email: payerInfo.email.trim(),
      contactPhone: payerInfo.phone.trim(),
      phoneCountryCode: payerInfo.phoneCountryCode.trim(),
      identificationType: payerInfo.typeOfIdentification.trim(),
      identificationNumber: payerInfo.identification.trim(),
      address: '-',
      city: 'Barranquilla',
      clientType: payerInfo.typeOfPerson,
      financialInstitutionCode: payerInfo.currentBank,
      financialInstitutionName: payerInfo.currentBankName
    }
  };
};

const Donation = props => {
  const { location = { state: null } } = props;
  const defaultValue = location.state ? location.state.donationValue : null;

  // * STATE HOOKS
  const currentUser = useContext(UserContext);
  const setAlert = useContext(AlertsDispatchContext);

  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [disabledNext, setDisabledNext] = useState(true);
  const [payment, setPayment] = useState(null);
  const [payerInfoData, setPayerInfoData] = useState(null);

  // * OTHER HOOKS
  const classes = useStyles();

  // * FUNCTIONS
  const handleGoForward = useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [currentStep, setCurrentStep]);

  const handleGoBack = useCallback(() => {
    setPayment(null);
    setCurrentStep(currentStep - 1);
  }, [currentStep, setCurrentStep]);

  const handlePayment = useCallback(async () => {
    if (!payment) {
      return;
    }

    // pre-request states
    setLoading(true);
    setDisabledNext(true);

    // request to api
    const body = buildPayerInfo(payerInfoData);
    const response = await DonationPseAPI(
      {
        ...body,
        product: {
          price: payment.value
        }
      },
      _get(currentUser, 'token', null)
    );
    if (response.success) {
      const transaction = response.data.data;
      if (transaction.status === TRANSACTION_STATUS.Pending) {
        window.location.href = transaction.bankUrl;
        return;
      }
      history.replace(
        `${ROUTE_NAMES.transaction}/${transaction.transactionId}`
      );
      return;
    }

    const error = extractErrorMessage(response);
    setAlert({
      type: 'error',
      message: error.message
    });

    setDisabledNext(false);
    setLoading(false);
  }, [
    payment,
    setLoading,
    setDisabledNext,
    payerInfoData,
    currentUser,
    setAlert
  ]);

  const handleSetPayment = useCallback((value, scrollRef) => {
    setPayment(value);
    if (scrollRef) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, []);

  const steps = useMemo(() => {
    const usedSteps = [
      {
        label: 'Comparto mi energía',
        action: { text: 'Siguiente', onCall: handleGoForward }
      },
      {
        label: 'Detalle de pago',
        onBack: handleGoBack,
        action: { text: 'Ir a PSE', onCall: handlePayment }
      },
      {
        label: 'Transacción'
      }
    ];
    return usedSteps;
  }, [handlePayment, handleGoForward, handleGoBack]);

  const renderCurrentStep = () => {
    if (currentStep === 0) {
      return (
        <DonationFirst
          currentStep={steps[currentStep]}
          setDisabledNext={setDisabledNext}
          handleGoForward={handleGoForward}
          setDonation={handleSetPayment}
          defaultValue={defaultValue}
        />
      );
    }
    if (currentStep === 1 && payment) {
      return (
        <DonationSecond
          currentStep={steps[currentStep]}
          setPayerInfo={setPayerInfoData}
          payment={payment}
          setDisabledNext={setDisabledNext}
        />
      );
    }
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.stepperContainer}>
          <Stepper steps={steps} currentStep={currentStep} />
        </Container>
        {renderCurrentStep()}
      </div>
      <FooterPayment
        id="Donation_Footer_div"
        currentStep={steps[currentStep]}
        disabledNext={disabledNext}
      />
      {loading && <FullSizeProgress />}
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    marginBottom: theme.custom.footerHeight.stepper
  },
  stepperContainer: {
    // * Mobile
    [theme.breakpoints.up('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.up('xm')]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5)
    },
    // * Web
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing()
    }
  }
}));

export default Donation;
