import React from 'react';

const ShareLocationIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 274.132}
      height={props.height || 182}
      viewBox="0 0 274.132 182"
      {...props}
    >
      <g transform="translate(-529.63 -340)">
        <path
          data-name="Trazado 188"
          d="M769.508 429.547h-27.255a79.7 79.7 0 00-128.877-49.136h-27.508a12.131 12.131 0 00-12.11 12.11 12.187 12.187 0 0010.086 11.952h19.6a13.251 13.251 0 0113.217 13.217v1.486a13.251 13.251 0 01-13.217 13.217h-39.203a12.131 12.131 0 00-12.11 12.11 12.131 12.131 0 0012.11 12.108h20.9a79.694 79.694 0 00127.612 48.376 11.8 11.8 0 003.193.443h30.86a12.131 12.131 0 0012.11-12.11 12.131 12.131 0 00-12.11-12.11h-18.212a12.968 12.968 0 01-12.932-12.932v-1.707a12.968 12.968 0 0112.932-12.932h29.247a11.138 11.138 0 012.087.19h9.517a12.131 12.131 0 0012.11-12.11 12.071 12.071 0 00-12.047-12.172z"
          fill="#eef9fb"
        />
        <path
          data-name="Trazado 186"
          d="M553.53 404.632h-12.928a11.025 11.025 0 01-10.972-11.003v-2.276a11.018 11.018 0 0110.972-10.972h12.9a11 11 0 0110.972 10.972v2.277a10.977 10.977 0 01-10.944 11.002z"
          fill="#eef9fb"
        />
        <path
          data-name="Trazado 187"
          d="M792.791 505.432h-12.9a11 11 0 01-10.972-10.972v-2.276a11 11 0 0110.971-10.972h12.9a11 11 0 0110.972 10.972v2.276a11.018 11.018 0 01-10.971 10.972z"
          fill="#eef9fb"
        />
        <circle
          data-name="Elipse 149"
          cx={4.879}
          cy={4.879}
          transform="translate(593.912 342)"
          fill="none"
          stroke="#63d8f1"
          strokeMiterlimit={10}
          strokeWidth={4}
          r={4.879}
        />
        <circle
          data-name="Elipse 151"
          cx={4.879}
          cy={4.879}
          transform="translate(744.176 413.158)"
          fill="none"
          stroke="#63d8f1"
          strokeMiterlimit={10}
          strokeWidth={4}
          r={4.879}
        />
        <path
          data-name="Trazado 190"
          d="M602.083 422.51h-7.541v-7.541a1.627 1.627 0 10-3.253 0v7.541h-7.578a1.627 1.627 0 100 3.253h7.541v7.541a1.627 1.627 0 003.253 0v-7.541h7.541a1.641 1.641 0 001.626-1.626 1.586 1.586 0 00-1.589-1.627z"
          fill="#63d8f1"
        />
        <path
          data-name="Trazado 191"
          d="M783.507 442.546l-4.14-4.14 4.14-4.14a1.255 1.255 0 10-1.774-1.774l-4.14 4.14-4.14-4.14a1.255 1.255 0 10-1.774 1.774l4.14 4.14-4.14 4.14a1.255 1.255 0 001.774 1.774l4.14-4.14 4.14 4.14a1.272 1.272 0 001.774 0 1.208 1.208 0 000-1.774z"
          fill="#63d8f1"
        />
        <g data-name="Grupo 230">
          <g data-name="Grupo 229">
            <path
              data-name="Trazado 195"
              d="M616.451 483.235h94.833a1.839 1.839 0 000-3.676h-94.833a1.839 1.839 0 000 3.676z"
              fill="#63d8f1"
            />
          </g>
        </g>
        <g data-name="Grupo 232">
          <g data-name="Grupo 231">
            <path
              data-name="Trazado 196"
              d="M723.537 483.235h6.739a1.839 1.839 0 000-3.676h-6.739a1.839 1.839 0 000 3.676z"
              fill="#63d8f1"
            />
          </g>
        </g>
        <g data-name="Grupo 234">
          <g data-name="Grupo 233">
            <path
              data-name="Trazado 197"
              d="M583.86 483.235h21.156a1.839 1.839 0 000-3.676H583.86a1.839 1.839 0 000 3.676z"
              fill="#63d8f1"
            />
          </g>
        </g>
        <g data-name="Grupo 904" fill="none">
          <path data-name="Trazado 687" d="M614.59 395.28h86.4v86.4h-86.4z" />
          <path
            data-name="Trazado 690"
            d="M679.39 416.88v.036"
            stroke="#104eb2"
            strokeLinecap="round"
            strokeWidth={8}
          />
          <path
            data-name="Trazado 688"
            d="M679.389 442.08l-12.6-18a14.4 14.4 0 1125.2 0l-12.6 18"
            stroke="#104eb2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4}
          />
          <path
            data-name="Trazado 689"
            d="M652.39 412.38l-5.4-2.7-21.6 10.8v46.8l21.6-10.8 21.6 10.8 21.6-10.8v-7.2"
            stroke="#104eb2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4}
          />
          <path
            data-name="L\xEDnea 86"
            stroke="#104eb2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4}
            d="M646.99 409.68v46.8"
          />
          <path
            data-name="L\xEDnea 87"
            stroke="#104eb2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={4}
            d="M668.59 449.28v18"
          />
        </g>
      </g>
    </svg>
  );
};

export default ShareLocationIcon;
