export const submitExternalForm = formId => {
  const buttonForm = document.createElement('button');
  buttonForm.type = 'submit';

  const form = document.getElementById(formId);

  // add button to target form
  form.appendChild(buttonForm);

  // trigger submit
  buttonForm.click();

  // destroy the button
  buttonForm.remove();
};
