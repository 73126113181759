import React, { createContext, useState } from 'react';

export const CircularMenuContext = createContext(null);
export const CircularMenuDispatchContext = createContext(null);

export const CircularMenuProvider = ({ children }) => {
  const [menu, dispatch] = useState({ open: false, noBackdrop: false });

  return (
    <CircularMenuContext.Provider value={menu}>
      <CircularMenuDispatchContext.Provider value={dispatch}>
        {children}
      </CircularMenuDispatchContext.Provider>
    </CircularMenuContext.Provider>
  );
};
