import axios from 'axios';
import { config } from '../../Configs';

export const BrillaAPI = async (token, contractId) => {
  try {
    const response = await axios.get(`${config.api.url}/brilla/${contractId}`, {
      headers: {
        Authorization: token
      }
    });
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const BrillaVisitAPI = async (token, contractId, data) => {
  try {
    const response = await axios.post(
      `${config.api.url}/brilla/visit/${contractId}`,
      data,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const HaveBrillaVisitRequestAPI = async (token, contractId) => {
  try {
    const response = await axios.get(
      `${config.api.url}/brilla/visit/${contractId}`,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const BrillaProductSublineAPI = async (
  token = null,
  captchaToken = null
) => {
  try {
    const configObj = {
      params: { 'g-recaptcha-response': captchaToken }
    };

    if (token) {
      configObj.headers = {
        Authorization: token
      };
    }

    const response = await axios.get(
      `${config.api.url}/brilla/visit/product/sublines`,
      configObj
    );

    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};
