import React, { Fragment, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NoAuthHome from './NoAuthHome/NoAuthHome';
import { UserContext } from '../../Contexts/UserContext';
import AuthHome from './AuthHome/AuthHome';

const Home = props => {
  const currentUser = useContext(UserContext);
  // * STATE HOOKS

  // * OTHER HOOKS
  const classes = useStyles();

  // * FUNCTIONS

  return (
    <Fragment>
      <div id="Home_div_container" className={classes.root}>
        {!currentUser && <NoAuthHome {...props} />}
        {currentUser && <AuthHome {...props} />}
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden'
  }
}));

export default Home;
