import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
const Spinner = require('../../../Assets/svg/spinner_dark.svg');

const FullSizeProgress = props => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.fullSizeProgress, props.className)}>
      <img src={Spinner} alt="loading_spinner" className={'unselectable'} />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  fullSizeProgress: {
    zIndex: 1200,
    position: 'fixed',
    display: 'flex',
    flex: 1,
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.transparentDark
  }
}));

export default FullSizeProgress;
