import axios from 'axios';
import { config } from '../../Configs';

export const PayCardAPI = async (data, authToken = null) => {
  try {
    const configObj = {};

    if (authToken !== null) {
      configObj.headers = { Authorization: authToken };
    }

    const response = await axios.post(
      `${config.api.url}/payments/card`,
      data,
      configObj
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const PayPseAPI = async (data, authToken = null) => {
  try {
    const configObj = {};

    if (authToken !== null) {
      configObj.headers = { Authorization: authToken };
    }

    const response = await axios.post(
      `${config.api.url}/payments/pse`,
      data,
      configObj
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const DonationPseAPI = async (data, authToken = null) => {
  try {
    const configObj = {};

    if (authToken !== null) {
      configObj.headers = { Authorization: authToken };
    }

    const response = await axios.post(
      `${config.api.url}/donations/pse`,
      data,
      configObj
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GetBanksListAPI = async () => {
  try {
    const response = await axios.get(`${config.api.url}/org_data`);
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const CreateGroupedPaymentAPI = async (couponsToPay, couponValue) => {
  try {
    const parsedCouponsToPay = couponsToPay.map(coupon => {
      return {
        couponId: coupon.detail.couponId,
        couponValue: coupon.detail.couponValue,
        contractId: parseInt(coupon.detail.contractId, 10)
      };
    });

    const response = await axios.post(
      `${config.api.url}/coupons/createGroupedCoupon`,
      { couponsToPay: parsedCouponsToPay, value: couponValue }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};
