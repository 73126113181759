import { useEffect } from 'react';
import { history } from './Routes/history';

import ReactGA from 'react-ga';
import { PortalCheck } from './Configs/general';

const sendPageView = location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname + location.search);
};

const GAListener = ({ trackingId, appVersion, children }) => {
  useEffect(() => {
    if (!PortalCheck.isGascaribe) {
      return;
    }
    ReactGA.initialize(trackingId);
    ReactGA.set({ appName: 'PortalGasesDelCaribe' });
    ReactGA.set({ appVersion });
    sendPageView(history.location);
    return history.listen(sendPageView);
  }, [trackingId, appVersion]);

  return children;
};

export default GAListener;
