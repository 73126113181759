import * as yup from 'yup';
import { GENDER_LIST, CIVIL_STATUS_LIST, STREET_LIST } from './ganaLoco_enums';

export const firstStepSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .max(50, 'Máximo 50 carácteres')
    .required('Ingrese su nombre'),
  lastName: yup
    .string()
    .trim()
    .max(50, 'Máximo 50 carácteres')
    .required('Ingrese su apellido'),
  identification: yup
    .string()
    .trim()
    .max(20, 'Máximo 20 carácteres')
    .matches(/^[0-9]+$/, 'Ingresa un número de identificación válido')
    .required('Ingresa tu número de identificación'),
  expeditionDate: yup
    .date()
    .typeError('La fecha de expedición debe ser válida')
    .max(new Date(), 'La fecha de expedición debe ser válida')
    .required('Ingrese la fecha de expedición'),
  phoneCountryCode: yup
    .string()
    .trim()
    .max(3, 'Debe tener máximo 3 dígitos')
    .required('Debe ser válido'),
  phone: yup
    .number()
    .typeError('El número de teléfono debe ser válido')
    .positive('El número de teléfono debe ser válido')
    .test('integer', 'El número de teléfono debe ser válido', val => {
      return val % 1 === 0;
    })
    .test('length', 'El número de teléfono debe ser de 7 o 10 dígitos', val => {
      return val.toString().length === 7 || val.toString().length === 10;
    })
    .required('Ingresa el número de teléfono'),
  email: yup
    .string()
    .lowercase()
    .max(100, 'Máximo 100 carácteres')
    .email('Dirección de correo electrónico no válida')
    .required('Ingresa el correo electrónico')
});

export const secondStepSchema = yup.object({
  birthDate: yup
    .date()
    .typeError('La fecha de nacimiento debe ser válida')
    .max(new Date(), 'La fecha de nacimiento debe ser válida')
    .required('Ingrese su fecha de nacimiento'),
  genre: yup
    .string()
    .notOneOf([0], 'Debes seleccionar una opción')
    .required('Debe ser válido')
    .oneOf(GENDER_LIST, 'Seleccione una opción válida'),
  department: yup
    .number()
    .integer()
    .notOneOf([0], 'Debes seleccionar un departamento')
    .required('Debe ser válido'),
  city: yup.mixed().when('department', {
    is: 0,
    then: yup
      .number()
      .integer()
      .notOneOf([0], 'Debes seleccionar una ciudad'),
    otherwise: yup
      .number()
      .integer()
      .required('Debe ser válido')
  }),
  mainStreet: yup
    .string()
    .required('Ingrese la vía principal')
    .oneOf(STREET_LIST, 'Seleccione una opción válida'),
  mainStreetNumber: yup
    .string()
    .trim()
    .required('Ingrese el número de la vía principal'),
  secondaryStreet: yup
    .string()
    .oneOf(STREET_LIST, 'Seleccione una opción válida')
    .required('Ingrese la vía secundaria'),
  secondaryStreetNumber: yup
    .string()
    .trim()
    .required('Ingrese el número de la vía secundaria'),
  houseNumber: yup
    .string()
    .trim()
    .required('Ingrese el número de la casa'),
  additionalInformation: yup.string().trim(),
  contractId: yup
    .string()
    .trim()
    .max(20, 'Debe tener máximo 20 dígitos')
});

export const thirdStepSchema = yup.object({
  civilStatus: yup
    .string()
    .required('Debes seleccionar una opción')
    .oneOf(CIVIL_STATUS_LIST, 'Seleccione una opción válida'),
  product: yup
    .number()
    .notOneOf([-1], 'Debes seleccionar un producto')
    .required('Debe seleccionar un producto'),
  whatProduct: yup.mixed().when('product', {
    is: -2,
    then: yup
      .string()
      .trim()
      .required('Debe ingresar cuál producto'),
    otherwise: yup.string().trim()
  })
});
