import React, { useCallback, useContext, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import {
  Grid,
  Typography,
  Divider,
  Tooltip,
  IconButton
} from '@material-ui/core';

import { ContractSelectedContext } from '../../../Contexts/ContractsContext';

import DownloadIcon from '../../../Components/CustomIcons/DownloadIcon';

import { formatAddress } from '../../../Utils/Format/Address';
import { numberWithDots } from '../../../Utils/Format/MoneyFormat';
import { saveAsPDF } from '../../../Utils/Transform/Files';
import FullSizeProgress from '../../../Components/Progress/FullSize/FullSizeProgress';
import { Company } from '../../../Configs/general';
import { capitalizeFirstLetter } from '../../../Utils/Format/Names';
import { renderCouponId } from '../../../Utils/Format/Coupons';

const associatedContractText = capitalizeFirstLetter(
  Company.contractConjugation.regular.singular.associate
);

const CouponCard = ({ initialBilling, couponId, pdf }) => {
  const classes = useStyles();

  const contract = useContext(ContractSelectedContext);

  const [loading, setLoading] = useState(false);

  const generatePdf = useCallback(() => {
    setLoading(true);
    const pdfName = `pagos_${Company.shortName.toLowerCase()}_cupon_${couponId}.pdf`;
    saveAsPDF(pdf, pdfName);
    setLoading(false);
  }, [couponId, pdf]);

  return (
    <Grid container direction="column" className={classes.card}>
      {loading && <FullSizeProgress />}
      <Grid
        container
        item
        wrap="nowrap"
        justify="space-between"
        alignItems="center"
        className={classes.cardHeader}
      >
        <Grid item>
          <Typography className={classes.title}>
            Cupón de Pago No° {renderCouponId(couponId)}
          </Typography>
        </Grid>

        <Grid item className={classes.statusWrapper}>
          <Typography className={classes.status}>Por pagar</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        className={classes.dataContainer}
        spacing={1}
        alignItems="center"
      >
        <Grid item xs={12} sm={6} md={3}>
          <Typography className={classes.label}>
            {associatedContractText}
          </Typography>
          <Typography className={classes.data}>
            {contract.alias} - {contract.id}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <Typography className={classes.label}>
            Dirección {Company.contractConjugation.regular.singular.contraction}
          </Typography>
          <Typography className={classes.data}>
            {formatAddress(contract, true)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Typography className={classes.label}>Valor cupón</Typography>
          <Typography className={classes.data}>
            {numberWithDots(initialBilling)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Typography className={clsx(classes.totalLabel, classes.alignRight)}>
            Total a pagar
          </Typography>
          <Typography
            className={clsx(
              classes.data,
              classes.totalData,
              classes.alignRight
            )}
          >
            {numberWithDots(initialBilling)}
          </Typography>
        </Grid>
      </Grid>
      <Grid item className={classes.dividerContainer}>
        <Divider />
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        justify="flex-end"
        className={classes.cardFooter}
        spacing={1}
        wrap="nowrap"
      >
        <Grid item>
          <Typography className={classes.footerText}>
            {pdf
              ? 'En caso de no poder continuar con el proceso de pago, descarga el cupón.'
              : 'El cupón agrupado generado solo puede ser pagado a través de este portal web.'}
          </Typography>
        </Grid>
        {pdf && (
          <Grid item>
            <div className={classes.downloadContainer}>
              <Tooltip
                arrow
                classes={{ tooltipPlacementBottom: classes.tooltip }}
                title="Descargar cupón"
              >
                <IconButton
                  disabled={loading}
                  className={classes.iconButton}
                  color="primary"
                  onClick={generatePdf}
                >
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  alignRight: {
    textAlign: 'right'
  },
  statusWrapper: {
    display: 'flex',
    flexShrink: 0,
    borderRadius: 3,
    backgroundColor: theme.palette.error.light,
    padding: theme.spacing(0.5, 1, 0.5, 1)
  },
  card: {
    borderRadius: theme.custom.borderRadius,
    border: `1px solid ${theme.palette.background.border}`,
    overflow: 'hidden'
  },
  cardHeader: {
    backgroundColor: theme.palette.background.dark,
    padding: theme.spacing(1.5, 2)
  },
  circle: {
    marginRight: theme.spacing()
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 12
    }
  },
  data: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.2,
    textTransform: 'capitalize'
  },
  status: {
    fontSize: 10,
    textTransform: 'none',
    color: theme.palette.error.main,
    fontWeight: 500
  },
  dataContainer: {
    padding: theme.spacing(2)
  },
  label: {
    fontSize: 11,
    fontWeight: 500,
    color: theme.palette.text.greyDark,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10
    }
  },
  totalData: {
    fontSize: 14,
    fontWeight: 600
  },
  totalLabel: {
    fontSize: 11,
    [theme.breakpoints.down('sm')]: {
      fontSize: 10
    }
  },
  dividerContainer: {
    padding: theme.spacing(0, 2)
  },
  cardFooter: {
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: theme.spacing(2)
    }
  },
  footerText: {
    fontSize: 10,
    lineHeight: 1.2,
    color: theme.palette.text.greyDark
  },
  iconButton: {
    '&:hover': {
      backgroundColor: theme.palette.primary.lighter
    }
  },
  downloadContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      backgroundColor: theme.palette.background.accordion,
      borderRadius: 4
    }
  },
  tooltip: {
    margin: theme.spacing(1.5, 0)
  }
}));

export default CouponCard;
