import React, { Fragment, useCallback, useMemo } from 'react';
import moment from 'moment-timezone';
import clsx from 'clsx';
import 'react-vertical-timeline-component/style.min.css';
import { makeStyles, withTheme } from '@material-ui/core/styles';
import _orderBy from 'lodash/orderBy';
import { Grid, Typography } from '@material-ui/core';

import EmailIcon from '@material-ui/icons/Email';
import ToolIcon from '@material-ui/icons/Build';

import CutIcon from '../../Components/CustomIcons/CutIcon';
import CertificateIcon from '../../Components/CustomIcons/CertificateIcon';
import PendingIcon from '../../Components/CustomIcons/PendingIcon';
import CheckIcon from '../../Components/CustomIcons/CheckBigIcon';

import BaseButton from '../../Components/Buttons/BaseButton';

import Timeline from '../../Components/Timeline/Timeline';
import TimeLineElement from '../../Components/Timeline/TimelineElement';

import {
  REVISION_TEXT,
  REVISION_PERIOD_TYPE,
  REVISION_MARK_TYPE
} from '../../Utils/enums';

const monthYearFormat = 'MMMM YYYY';
const INITIAL_REVISION_MONTH = 55;

const RevisionHistory = props => {
  const {
    data = {},
    openDialog,
    theme,
    disableRequestButton,
    loadingOrder
  } = props;
  // * HOOKS
  const classes = useStyles();

  // *
  const ColombiaTime = moment()
    .tz('America/Bogota')
    .format();

  const renderCardText = useCallback(
    (text, buttonText, buttonClasses) => {
      return (
        <Fragment>
          {text && <Typography className={classes.itemText}>{text}</Typography>}
          {buttonText && !disableRequestButton && (
            <Grid container justify="flex-end">
              <BaseButton
                className={clsx([classes.itemButton, buttonClasses])}
                variant="outlined"
                size="small"
                onClick={openDialog}
                loading={loadingOrder}
                progress={loadingOrder}
              >
                {buttonText}
              </BaseButton>
            </Grid>
          )}
        </Fragment>
      );
    },
    [classes, openDialog, disableRequestButton, loadingOrder]
  );

  const renderCurrentState = useCallback(
    node => {
      // Certification effective still
      let nodeTitle = 'Revisión al día';
      let nodeChild = (
        <span>
          {REVISION_TEXT.effectivePeriod} Tu certificado vence{' '}
          <span className={classes.textBold}>
            {moment(node.data.suspensionDate).fromNow()}
          </span>
          .
        </span>
      );
      const month = node.data.monthsSinceLastCert;
      let nodeSubtitle = `Hoy - Mes ${month}`;
      let nodeIcon = CheckIcon;
      // Certification recently expired
      if (node.data.period === REVISION_PERIOD_TYPE.Review) {
        nodeTitle = 'Periodo de revisión';
        nodeChild = (
          <span>
            {REVISION_TEXT.revisionPeriod} Tu certificado vence{' '}
            <span className={classes.textBold}>
              {moment(node.data.suspensionDate).fromNow()}
            </span>
            . {REVISION_TEXT.avoidSuspension}
          </span>
        );
        nodeIcon = PendingIcon;
      } else if (data.period === REVISION_PERIOD_TYPE.Suspension) {
        nodeTitle = 'SUSPENSIÓN INMEDIATA';
        nodeSubtitle = 'Hoy';
        nodeChild = (
          <span>
            La certificación de tu instalación{' '}
            <span className={classes.textBold}>se encuentra vencida</span>
            .
            <br />
            {REVISION_TEXT.suspensionPeriod} {REVISION_TEXT.avoidSuspension}
          </span>
        );
        nodeIcon = CutIcon;
      }

      // Periodic revision can be requested starting
      // from the 55 month since its last revision
      const canRequestPeriodicRevision = month >= INITIAL_REVISION_MONTH;

      return (
        <TimeLineElement
          key={'current_node'}
          title={nodeTitle}
          subtitle={nodeSubtitle}
          icon={nodeIcon}
          color={node.color}
        >
          {renderCardText(
            nodeChild,
            canRequestPeriodicRevision ? 'Solicitar Revisión' : ''
          )}
        </TimeLineElement>
      );
    },
    [data, classes, renderCardText]
  );

  const renderSuspension = useCallback(node => {
    return (
      <TimeLineElement
        key={'suspension_node'}
        title="Suspensión del servicio"
        subtitle={`${moment(node.date).format(monthYearFormat)}`}
        icon={CutIcon}
        color={node.color}
      />
    );
  }, []);

  const renderNotification = useCallback(node => {
    return (
      <TimeLineElement
        key={'notification_node'}
        title="Notificación"
        subtitle={`${moment(node.date).format(monthYearFormat)}`}
        icon={EmailIcon}
        color={node.color}
      />
    );
  }, []);

  const renderCertificate = useCallback(node => {
    const { id, reviewDate, color } = node;
    return (
      <TimeLineElement
        key={`certificate_${id}_node`}
        title={'Última certificación'}
        subtitle={`${moment(reviewDate).format(monthYearFormat)}`}
        icon={CertificateIcon}
        color={color}
      />
    );
  }, []);

  const renderMark = useCallback(
    node => {
      const { markDate, markType, color } = node;
      let markTitle = 'Identificación';
      let markColor = color;
      if (markType === REVISION_MARK_TYPE.NoCriticDefect) {
        markTitle = 'Reparación: Defecto no crítico';
        markColor = theme.palette.warning.main;
      }
      if (markType === REVISION_MARK_TYPE.CriticDefect) {
        markTitle = 'Reparación: Defecto crítico';
        markColor = theme.palette.error.main;
      }
      if (markType === REVISION_MARK_TYPE.Certification) {
        markTitle = 'Reparación: Certificado pendiente';
      }
      return (
        <TimeLineElement
          key={'mark_node'}
          title={markTitle}
          subtitle={`${moment(markDate).format(monthYearFormat)}`}
          icon={ToolIcon}
          color={markColor}
        />
      );
    },
    [theme]
  );

  const buildingHistory = useMemo(() => {
    const {
      certificates,
      notificationDate,
      suspensionDate,
      period,
      marked,
      markType
    } = data;
    const history = [];

    // Set the color of the nodes depending on the period
    let nodesColor = theme.palette.color.whiteDark;

    if (period === REVISION_PERIOD_TYPE.Effective) {
      nodesColor = theme.palette.success.main;
    }

    if (period === REVISION_PERIOD_TYPE.Review) {
      nodesColor = theme.palette.warning.main;
    }

    if (period === REVISION_PERIOD_TYPE.Suspension) {
      nodesColor = theme.palette.error.main;
    }

    // Add deadline nodes that build the history
    if (period === REVISION_PERIOD_TYPE.Suspension) {
      history.push({
        type: 'notification',
        date: notificationDate,
        node: renderNotification({
          date: notificationDate,
          color: theme.palette.error.main
        })
      });
    } else {
      history.push({
        type: 'suspension',
        date: suspensionDate,
        node: renderSuspension({
          date: suspensionDate,
          color:
            period === REVISION_PERIOD_TYPE.Effective
              ? theme.palette.color.paleGrey
              : theme.palette.error.main
        })
      });
      history.push({
        type: 'notification',
        date: notificationDate,
        node: renderNotification({
          date: notificationDate,
          color:
            period === REVISION_PERIOD_TYPE.Effective
              ? theme.palette.color.paleGrey
              : theme.palette.warning.main
        })
      });
    }
    // Create & order the certifitate nodes
    const certs = [];
    certificates.forEach(cert => {
      if (!cert.cancelDate) {
        certs.push({
          type: 'certification',
          date: cert.reviewDate,
          node: renderCertificate({
            ...cert,
            color: nodesColor
          })
        });
      }
    });

    const orderCertificates = _orderBy(certs, ['date'], 'desc');

    // Add the last valid certificate node to history
    if (orderCertificates[0]) {
      history.push(orderCertificates[0]);
    }

    // Add the current state node
    history.push({
      type: 'currently',
      date: ColombiaTime,
      node: renderCurrentState({
        data,
        color: nodesColor
      })
    });

    // Add mark node
    if (marked && markType) {
      if (
        markType === REVISION_MARK_TYPE.NoCriticDefect ||
        markType === REVISION_MARK_TYPE.Certification ||
        markType === REVISION_MARK_TYPE.CriticDefect
      ) {
        history.push({
          type: 'mark',
          date: data.markDate,
          node: renderMark({
            ...data,
            color: nodesColor
          })
        });
      }
    }

    const nodesInOrder = _orderBy(history, ['date'], 'desc');

    return nodesInOrder;
  }, [
    data,
    ColombiaTime,
    theme,
    renderCurrentState,
    renderCertificate,
    renderNotification,
    renderSuspension,
    renderMark
  ]);

  // * RENDER
  return (
    <Fragment>
      <Timeline withLine>{buildingHistory.map(h => h.node)}</Timeline>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  itemText: {
    fontSize: '14px !important',
    marginTop: theme.spacing(1.5),
    fontWeight: '400 !important'
  },
  itemButton: {
    fontSize: '14px !important',
    marginTop: 14
  },
  buttonWarning: {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.light
    }
  },
  buttonError: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.lightest
    }
  },
  timeLineFuture: {
    paddingBottom: 0
  },
  textBold: {
    fontWeight: 500
  }
}));

export default withTheme(RevisionHistory);
