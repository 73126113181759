import React, { useCallback } from 'react';

import uuid from 'uuid/v4';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

import { history } from '../../../Routes/history';
import { ROUTE_NAMES } from '../../../Routes/Routes';

import BaseButton from '../../../Components/Buttons/BaseButton';
import ContractItemMobile from './ContractItemMobile';
import { Company } from '../../../Configs/general';

const myContractBannerMobile = require('../../../Assets/images/my_contracts_banner_mobile.png');

const MyContractsMobileGDC = props => {
  const {
    contracts,
    openRemoveDialog,
    openEditDialog,
    openConfirmationDialog,
    hasPassword = false
  } = props;
  const classes = useStyles();

  const goToAssociate = useCallback(() => {
    history.push(ROUTE_NAMES.associate);
  }, []);

  return (
    <div className={classes.cardContentMobile}>
      <Card className={classes.cardBannerMobile}>
        <CardMedia
          alt={`mis_${Company.contractConjugation.regular.plural.main}_banner`}
          image={myContractBannerMobile}
          className={classes.bannerImage}
        >
          <span className={classes.bannerText}>
            Mis {Company.contractConjugation.regular.plural.associate}
          </span>
        </CardMedia>
      </Card>
      {contracts.map(contr => (
        <ContractItemMobile
          key={`${uuid()}`}
          contract={contr}
          message={Company.contractConjugation.capitalized.singular.associate}
          openRemoveDialog={
            hasPassword ? openRemoveDialog : openConfirmationDialog
          }
          openEditDialog={openEditDialog}
        />
      ))}
      <BaseButton
        className={classes.buttonMobile}
        color="primary"
        variant="outlined"
        onClick={goToAssociate}
      >
        Asociar {Company.contractConjugation.regular.singular.new}
      </BaseButton>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  cardContentMobile: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  mobileContainer: {
    height: '74vh',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  cardBannerMobile: {
    width: '100%',
    marginBottom: theme.spacing(3),
    borderRadius: theme.custom.borderRadius,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.1)'
  },
  buttonMobile: {
    marginBottom: theme.spacing(3)
    // backgroundColor: theme.palette.common.white
  },
  bannerImage: {
    height: 130,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  bannerText: {
    color: theme.palette.common.white,
    fontSize: 20,
    fontWeight: 600,
    paddingLeft: theme.spacing(5)
  }
}));

export default MyContractsMobileGDC;
