import React from 'react';
import moment from 'moment';

import {
  COLOMBIAN_DEPARTMENTS,
  COLOMBIAN_CITIES
} from '../fixes/location_enums';

import { PRODUCT_LIST, FORM_STATE } from '../fixes/ganaLoco_enums';

export const secondStepParser = ({
  birthDate: unparsedDate,
  department: departmentId,
  city: cityId,
  mainStreet,
  mainStreetNumber,
  secondaryStreet,
  secondaryStreetNumber,
  houseNumber,
  additionalInformation,
  contractId: unparsedContract,
  ...rest
}) => {
  const birthDate = moment(unparsedDate).format('YYYY-MM-DD');
  const department = COLOMBIAN_DEPARTMENTS[departmentId].label;
  const city = COLOMBIAN_CITIES[departmentId][cityId].label;

  const address = `${mainStreet} ${mainStreetNumber} ${secondaryStreet} ${secondaryStreetNumber} - ${houseNumber} ${additionalInformation}`.trim();
  const contractId = unparsedContract === '' ? undefined : unparsedContract;

  return {
    birthDate,
    department,
    city,
    address,
    contractId,
    ...rest
  };
};

export const brillaProductsParser = productsAPI => {
  const arrangedProducts = [PRODUCT_LIST[0]];

  Object.values(productsAPI).forEach((label, idx) => {
    arrangedProducts.push({ value: idx + 1, label });
  });

  arrangedProducts.push(PRODUCT_LIST[1]);

  return arrangedProducts;
};

export const secondarySuccessText = (formState, userId) => {
  switch (formState) {
    case FORM_STATE.NEW_WINNER:
      return (
        <>
          <strong>
            Quedaste inscrito automáticamente con el código {userId}
          </strong>{' '}
          para participar en el gran sorteo de cierre de campaña.
        </>
      );

    case FORM_STATE.ALREADY_WON:
      return (
        <>
          <strong>Te encuentras inscrito con el código {userId}</strong> para
          participar en el gran sorteo de cierre de campaña.
        </>
      );

    case FORM_STATE.LATE_PARTICIPATION:
      return (
        <>
          <strong>Te encuentras inscrito con el código {userId}</strong> para
          participar en el gran sorteo de cierre de campaña.
        </>
      );
    default:
      return (
        <>
          <strong>Ha ocurrido un error inesperado</strong>
        </>
      );
  }
};
