import React, { useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';

import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Card, CardActionArea, CardMedia } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '../CustomIcons/CloseIcon';
import { SlideUpTransition } from '../Transitions/Transitions';

import { ROUTE_NAMES } from '../../Routes/Routes';
import { history } from '../../Routes/history';
import { DigitalInvoiceBannerDateKey } from '../../Enums/invoices';

const DigitalInvoiceBannerDialog = props => {
  const { open = false, setOpenDialog, isLoggedIn, image } = props;
  const classes = useStyles();
  const isMobileSize = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  useEffect(() => {
    // If the logged in user interact with the banner in some way,
    // it sets the date of the event and check every time in home.
    // If has passed one hour or more, it opens the digital
    // invoice banner in home again.

    if (isLoggedIn) {
      const digitalInvoiceBannerDate = moment(
        localStorage.getItem(DigitalInvoiceBannerDateKey)
      );
      const oneHourLater = moment(digitalInvoiceBannerDate).add(1, 'hours');
      const now = moment();

      const canOpenBannerAgain =
        !digitalInvoiceBannerDate.isValid() ||
        (digitalInvoiceBannerDate.isValid() &&
          moment(now).isAfter(oneHourLater));

      if (!canOpenBannerAgain) {
        return;
      }
    }

    localStorage.removeItem(DigitalInvoiceBannerDateKey);
    setOpenDialog(true);
  }, [setOpenDialog, isLoggedIn]);

  const goTo = useCallback(() => {
    if (isLoggedIn) {
      localStorage.setItem(DigitalInvoiceBannerDateKey, moment().toISOString());
    }
    history.push(ROUTE_NAMES.contracts);
  }, [isLoggedIn]);

  const closeHandler = useCallback(() => {
    if (isLoggedIn) {
      localStorage.setItem(DigitalInvoiceBannerDateKey, moment().toISOString());
    }
    setOpenDialog(false);
  }, [setOpenDialog, isLoggedIn]);

  return (
    <Dialog
      id={'DigitalInvoiceBannerDialog_div'}
      open={open}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{
        paper: classes.paper
      }}
      TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
      disableBackdropClick
    >
      <DialogTitle disableTypography className={classes.title}>
        <Typography className={classes.titleText}>Aviso Importante</Typography>
        <IconButton
          size="small"
          aria-label="cerrar diálogo"
          onClick={closeHandler}
          className={classes.titleButton}
        >
          <CloseIcon size={15} className={classes.titleIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Card className={classes.cardRoot}>
          <CardActionArea className={classes.cardAction} onClick={goTo}>
            <CardMedia
              className={classes.media}
              image={image}
              title="Factura digital"
            />
          </CardActionArea>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: theme.custom.borderRadius,
    overflowX: 'hidden',
    width: 524,
    height: 800,
    maxWidth: 800,
    [theme.breakpoints.up(320)]: {
      width: 256,
      height: 426
    },
    [theme.breakpoints.up(375)]: {
      width: 300,
      height: 488
    },
    [theme.breakpoints.up(425)]: {
      width: 360,
      height: 572
    },
    [theme.breakpoints.up('sm')]: {
      width: 380,
      height: 600
    },
    [theme.breakpoints.up('md')]: {
      width: 524,
      height: 800
    }
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 70,
    backgroundColor: '#A54CF3',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
    background: 'linear-gradient(280deg, #3C41DB, #A54CF3)'
  },
  titleText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 600
  },
  titleButton: {
    padding: theme.spacing(1.5),
    fill: 'white'
  },
  titleContent: {
    marginBottom: 14
  },
  titleIcon: {
    fill: 'white'
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    padding: 0
  },
  cardRoot: {
    width: '100%',
    height: '100%'
  },
  media: {
    height: '100%',
    maxWidth: '100%',
    backgroundSize: 'contain'
  },
  cardAction: {
    height: '100%'
  }
}));

export default DigitalInvoiceBannerDialog;
