import axios from 'axios';
import { config } from '../../Configs';

export const GetCommunicationsAPI = async token => {
  try {
    const response = await axios.get(
      `${config.api.url}/communications/`,
      token && {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response };
  } catch (err) {
    return { success: false, error: err };
  }
};
