import { useState, useCallback } from 'react';
import { GetCommunicationsAPI } from './CommunicationsAPI';

export const useFetchCommunications = () => {
  const [loading, setLoading] = useState(true);
  const [communications, setCommunications] = useState([]);

  const getCommunications = useCallback(async token => {
    const data = await GetCommunicationsAPI(token);

    if (data.success) {
      let sortedData = data.data.data.data.sort(
        (a, b) => a.position - b.position
      );

      let gameCommunication = null;

      sortedData.forEach(communication => {
        if (communication.game) {
          gameCommunication = communication;
        }
      });

      sortedData = sortedData.filter(communication => !communication.game);

      if (token) {
        if (gameCommunication) {
          setCommunications([gameCommunication, ...sortedData]);
          setLoading(false);
          return;
        }
      }

      setCommunications(sortedData);
    }
    setLoading(false);
  }, []);

  return [communications, getCommunications, loading];
};
