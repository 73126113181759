import { ProductType } from '../myDebts_enums';

export const getCurrentDebtData = (debts, negotiations) => {
  const totalValues = debts.currents.reduce(
    (sum, current) => {
      if (current.expired) {
        return { ...sum, expiredValue: sum.expiredValue + current.value };
      }
      return { ...sum, toDateValue: sum.toDateValue + current.value };
    },
    {
      expiredValue: 0,
      toDateValue: 0
    }
  );

  return {
    ...debts,
    ...totalValues,
    negotiations
  };
};

export const getBrillaCurrentDebtValue = debtsData => {
  return debtsData.currents.reduce((acc, current) => {
    return current.productTypeId === ProductType.FinancingServices
      ? acc + current.value
      : acc;
  }, 0);
};

export const getIsBillingPeriod = (daysUntilBilling, billingDate) => {
  if (daysUntilBilling >= 0 && billingDate) {
    const noBillingPeriod = !billingDate;
    const dateNearBillingPeriod = daysUntilBilling < 2;

    return noBillingPeriod || dateNearBillingPeriod;
  }

  return false;
};
