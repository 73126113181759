import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { RemoveCircle as RemoveCircleIcon } from '@material-ui/icons';
import DeferredSidebarItemProductFinancial from './DeferredSidebarItemProductFinancial';
import clsx from 'clsx';

const DeferredSidebarItemProduct = props => {
  const {
    productId,
    productTypeDescription,
    financials,
    allowDeletion,
    removeDeferreds
  } = props;

  const classes = useStyles();

  return (
    <>
      <div className={classes.productDeferredHeader}>
        <Typography className={clsx(classes.statusTag, classes.currentStatus)}>
          Deuda financiada
        </Typography>
        <Grid
          container
          alignItems="center"
          justify="space-between"
          wrap="nowrap"
        >
          <div>
            <Typography className={classes.label}>Producto</Typography>
            <Typography className={classes.data}>
              {productTypeDescription} • {productId}
            </Typography>
          </div>
          {allowDeletion && (
            <div className={classes.removeButtonWrapper}>
              <Tooltip title="Quitar producto" placement="left">
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={() => {
                    removeDeferreds('product', productId);
                  }}
                >
                  <RemoveCircleIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </Grid>
      </div>
      {financials.map(({ financialId, total, deferreds }) => (
        <DeferredSidebarItemProductFinancial
          key={financialId}
          financialId={financialId}
          total={total}
          deferreds={deferreds}
          productTypeDescription={productTypeDescription}
          allowDeletion={allowDeletion}
          removeDeferreds={removeDeferreds}
        />
      ))}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  itemsContainer: {
    flexGrow: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: theme.spacing(0, 2),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      margin: 0
    }
  },
  productDeferredHeader: {
    padding: theme.spacing(1, 0.5, 2, 1),
    backgroundColor: theme.palette.background.accordion,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: theme.spacing(2)
    }
  },
  label: {
    fontSize: 10,
    fontWeight: 500,
    color: theme.palette.text.greyDark,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 11
    }
  },
  data: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.2,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 14
    }
  },
  removeButtonWrapper: {
    color: theme.palette.error.main
  },
  statusTag: {
    width: 104,
    fontSize: 10,
    textAlign: 'center',
    color: theme.palette.common.white,
    marginLeft: 'auto',
    borderRadius: 3
  },
  currentStatus: {
    backgroundColor: '#E05252',
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginRight: 'auto'
    }
  }
}));
export default DeferredSidebarItemProduct;
