import * as React from 'react';

function SvgHelpIcon(props) {
  return (
    <svg width={26.519} height={27.38} {...props}>
      <g data-name="Grupo 790">
        <g data-name="Grupo 776">
          <g data-name="Grupo 775">
            <path
              data-name="Trazado 752"
              d="M14.322 0H12.2a12.2 12.2 0 000 24.4h2.124a12.221 12.221 0 003.1-.4l3.138 3.136a.839.839 0 001.432-.594v-4.857a12.314 12.314 0 003.206-3.953 12.066 12.066 0 001.325-5.535A12.211 12.211 0 0014.322 0zm6.322 20.606a.839.839 0 00-.334.67v3.239l-2.054-2.053a.839.839 0 00-.84-.209 10.522 10.522 0 01-3.094.463H12.2a10.519 10.519 0 110-21.038h2.124a10.519 10.519 0 016.322 18.927z"
              fill="#fff"
            />
          </g>
        </g>
        <g data-name="Grupo 778">
          <g data-name="Grupo 777">
            <path
              data-name="Trazado 753"
              d="M17.039 9.183a3.553 3.553 0 00-7.1.25.839.839 0 101.678 0 1.875 1.875 0 012.009-1.87 1.875 1.875 0 01.277 3.7 1.588 1.588 0 00-1.247 1.554v2.016a.839.839 0 101.678 0v-1.947a3.535 3.535 0 002.705-3.703z"
              fill="#fff"
            />
          </g>
        </g>
        <g data-name="Grupo 780">
          <g data-name="Grupo 779">
            <path
              data-name="Trazado 754"
              d="M14.088 17.562a.84.84 0 10.246.593.846.846 0 00-.246-.593z"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgHelpIcon;
