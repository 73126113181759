import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import SvgIcCloseCircle from '../CustomIcons/IcCloseCircle';
import ButtonWithIcon from '../Buttons/ButtonWithIcon';

const CleanFilters = props => {
  const classes = useStyles();
  const {
    id = 'cleanFilter',
    loading,
    disabled,
    className,
    iconClass,
    ...rest
  } = props;

  return (
    <ButtonWithIcon
      id={id}
      loading={loading}
      disabled={disabled}
      className={clsx(classes.root, className)}
      textClass={clsx(classes.textClass)}
      buttonText={'Limpiar filtros'}
      {...rest}
    >
      <SvgIcCloseCircle className={clsx(classes.iconClearFilter, iconClass)} />
    </ButtonWithIcon>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: 'auto'
  },
  textClass: {
    color: theme.palette.common.black
  },
  iconClearFilter: {
    fill: theme.palette.text.greyDark
  }
}));
export default CleanFilters;
