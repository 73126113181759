import React, { useState, useCallback, useContext } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';

import { numberWithDots } from '../../Utils/Format/MoneyFormat';
import { downloadAsset } from '../../Utils/Misc/Assets';
import clsx from 'clsx';

import InsuranceAppointmentDialog from '../Dialogs/InsuranceAppointmentDialog';
import BaseButton from '../Buttons/BaseButton';
import ProductBanner from '../Banner/ProductBanner';
import SimpleDivider from '../Dividers/SimpleDivider';

import DownloadIcon from '../CustomIcons/DownloadIcon';
import CustomerServiceIcon from '../CustomIcons/CustomerService';

import PractiseguroPDF from '../../Assets/docs/practiseguro-clausulas.pdf';
import PractiSeguroLogo from '../../Assets/images/img_practiseguro@2x.png';
import PractiSeguroBanner from '../../Assets/images/practiseguro_banner.png';

import { ContractSelectedContext } from '../../Contexts/ContractsContext';
import { PractiSeguroProduct } from '../../Enums/insurances';

const InsuranceDescriptionCard = props => {
  const {
    description,
    price,
    coverage,
    isLoggedIn,
    handleLogin,
    handleAcquisition,
    disabledButton
  } = props;
  const classes = useStyles();

  const isMobileSize = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  const [openAppointmentDialog, setOpenAppointmentDialog] = useState(false);
  const selectedContract = useContext(ContractSelectedContext);

  const handleOpenAppointmentDialog = useCallback(() => {
    setOpenAppointmentDialog(true);
  }, []);

  const handleCloseAppointmentDialog = useCallback(() => {
    setOpenAppointmentDialog(false);
  }, [setOpenAppointmentDialog]);

  return (
    <div className={classes.root}>
      {isLoggedIn && (
        <InsuranceAppointmentDialog
          contractId={selectedContract && selectedContract.id}
          open={openAppointmentDialog}
          handleClose={handleCloseAppointmentDialog}
        />
      )}
      <ProductBanner
        className={classes.productBannerContainer}
        backgroundImg={PractiSeguroBanner}
        logoImage={PractiSeguroLogo}
        title={PractiSeguroProduct.title}
        buttonText="Adquirir seguro"
        handleClick={handleAcquisition}
        disabledButton={disabledButton}
        customClasses={{ productNameGrid: classes.productNameGrid }}
        showsTitleSection={!isMobileSize}
      />
      {isMobileSize && (
        <Grid item className={classes.titleContainer}>
          <Typography className={clsx(classes.title, classes.alignCenter)}>
            {PractiSeguroProduct.title}
          </Typography>
        </Grid>
      )}
      <div className={classes.card}>
        <Grid container>
          <Grid item>
            <Typography
              className={clsx(classes.label, {
                [classes.margin]: !isMobileSize
              })}
            >
              Descripción
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={clsx(classes.description)}>
              {description}
            </Typography>
          </Grid>
          <Grid item container className={clsx(classes.infoContainer)}>
            <Grid item xs={6}>
              <Typography className={classes.label}>Prima Mensual</Typography>
              <Typography className={classes.bold}>
                {numberWithDots(price)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography className={classes.label}>Coberturas</Typography>
              <Typography className={classes.bold}>{coverage}</Typography>
            </Grid>
          </Grid>
          <SimpleDivider withoutMargin />
          <Grid
            item
            container
            justify="flex-end"
            className={clsx(classes.buttonContainer)}
          >
            {isMobileSize && (
              <Grid item xs={12} className={classes.buttonItemContainer}>
                <BaseButton
                  size="small"
                  onClick={handleAcquisition}
                  disabled={disabledButton}
                  fullWidth={true}
                >
                  <Typography>Adquirir seguro</Typography>
                </BaseButton>
              </Grid>
            )}
            <Grid item xs={12} className={classes.buttonItemContainer}>
              <BaseButton
                color="default"
                variant="text"
                size="small"
                fullWidth={isMobileSize}
                className={classes.conditionsButton}
                startIcon={<DownloadIcon width={24} height={24} />}
                onClick={() =>
                  downloadAsset('Practiseguro - Clausulas.pdf', PractiseguroPDF)
                }
              >
                Ver condiciones
              </BaseButton>
            </Grid>
            <Grid item xs={12} className={classes.buttonItemContainer}>
              <BaseButton
                color="default"
                variant="text"
                size="small"
                fullWidth={isMobileSize}
                className={classes.requestAdvisoryButton}
                startIcon={<CustomerServiceIcon width={24} height={24} />}
                onClick={isLoggedIn ? handleOpenAppointmentDialog : handleLogin}
              >
                Solicitar asesoría
              </BaseButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: theme.custom.borderRadius,
    border: `1px solid ${theme.palette.background.border}`
  },
  card: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: theme.spacing(2)
    }
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    '& > *': {
      marginRight: theme.spacing(1)
    },
    '& > *:last-child': {
      marginRight: 0
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      '& > *': {
        margin: theme.spacing(1, 0)
      },
      '& > *:last-child': {
        marginBottom: 0
      }
    }
  },
  buttonItemContainer: {
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      flexBasis: 'auto'
    }
  },
  infoContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  label: {
    fontSize: 12,
    color: theme.palette.text.greyDark,
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      fontSize: 14
    }
  },
  description: {
    fontSize: 13,
    lineHeight: 1.4
  },
  bold: {
    fontSize: 14,
    fontWeight: 600
  },
  margin: {
    marginBottom: theme.spacing(1)
  },
  alignRight: {
    textAlign: 'right'
  },
  paddingBottom: {
    paddingBottom: theme.spacing(1)
  },
  image: {
    width: '70%',
    borderRadius: theme.spacing(1)
  },
  divider: {
    width: '100%'
  },
  conditionsButton: {
    backgroundColor: theme.palette.primary.light
  },
  requestAdvisoryButton: {
    backgroundColor: theme.palette.primary.lighter
  },
  productNameGrid: {
    paddingRight: theme.spacing(3)
  },
  titleContainer: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginTop: theme.spacing(2)
    }
  },
  title: {
    fontSize: 16,
    fontWeight: 600
  },
  alignCenter: {
    textAlign: 'center'
  }
}));

export default InsuranceDescriptionCard;
