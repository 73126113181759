import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export const Message = ({ text, visible = true, absolute }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.messageWrapper,
        visible && classes.visible,
        absolute && classes.absolute
      )}
    >
      {text && <div className={clsx(classes.messageText)}>{text}</div>}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  messageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.white,
    zIndex: 3,
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity 300ms ease'
  },

  messageText: {
    fontSize: theme.typography.body2.fontSize,
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.body1.fontSize
    }
  },

  absolute: {
    position: 'absolute',
    padding: theme.spacing(3)
  },

  points: {
    margin: `${theme.spacing()}px 0`,
    fontWeight: theme.typography.fontWeightMedium
  },

  button: {
    alignSelf: 'center',
    margin: `${theme.spacing()}px 0 0`
  },

  visible: {
    opacity: 1,
    visibility: 'visible'
  }
}));

export default Message;
