import axios from 'axios';
import { config } from '../../Configs';

export const VerifyContractAPI = async (token, contractId) => {
  try {
    const response = await axios.get(
      `${config.api.url}/sales/subrogation/check/${contractId}`,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const CreateSubrogationRequestAPI = async (
  token,
  subrogationRequest
) => {
  try {
    const response = await axios.post(
      `${config.api.url}/sales/subrogation`,
      subrogationRequest,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GetSignDocumentURL = async (token, data) => {
  try {
    const response = await axios.post(
      `${config.api.url}/sales/subrogation/legal-documents`,
      data,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GetSignatureStatus = async (token, processClientId) => {
  try {
    const response = await axios.get(
      `${config.api.url}/sales/signature-status/${processClientId}`,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data.data };
  } catch (err) {
    return { success: false, error: err };
  }
};
