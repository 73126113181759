import React, { Fragment, useCallback, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import BaseButton from '../../../../Components/Buttons/BaseButton';
import BaseDialog from '../../../../Components/Dialogs/BaseDialog';

import { SlideUpTransition } from '../../../../Components/Transitions/Transitions';

import { UserContext } from '../../../../Contexts/UserContext';
import { Company } from '../../../../Configs/general';

const ValidationStepGDG = props => {
  // * CONTEXTS
  const { step, setNextModal, onClose, open, title } = props;

  const currentUser = useContext(UserContext);

  const classes = useStyles();
  const data = step.data;
  const contractId = data.contractId;
  const address = data.contract.address;
  const location = data.contract.location;

  const handleGoBack = useCallback(() => {
    setNextModal({ type: 'contract', data: { contractId } });
  }, [setNextModal, contractId]);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      setNextModal({
        type: currentUser ? 'cellphone' : 'invoices',
        data: { contractId, ...step.data }
      });
    },
    [step, contractId, setNextModal, currentUser]
  );

  const renderActions = useCallback(() => {
    return (
      <Fragment>
        <BaseButton
          onClick={handleGoBack}
          variant="outlined"
          color="primary"
          size="small"
        >
          Atrás
        </BaseButton>
        <BaseButton
          onClick={handleSubmit}
          form="selectPeriodForm"
          color="primary"
          size="small"
          autoFocus
        >
          Continuar
        </BaseButton>
      </Fragment>
    );
  }, [handleSubmit, handleGoBack]);

  const renderContent = useCallback(() => {
    return (
      <div className={classes.content}>
        <Typography className={classes.text}>
          Tu dirección de residencia registrada es:
        </Typography>
        <Typography
          className={classes.valueText}
        >{`${address}, ${location}`}</Typography>
        <Typography className={classes.textSmall}>
          Si tu dirección está errada, por favor verifica el número de{' '}
          <span className={classes.contractText} onClick={handleGoBack}>
            {Company.contractConjugation.regular.singular.ingress}
          </span>
        </Typography>
      </div>
    );
  }, [address, location, classes, handleGoBack]);

  const isMobileSize = isWidthDown('xs', props.width);
  return (
    <Fragment>
      <BaseDialog
        open={open}
        handleClose={onClose}
        title={title}
        actions={renderActions}
        content={renderContent}
        fullScreen={isMobileSize}
        contentSize="small"
        TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
      />
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  text: {
    textAlign: 'center',
    fontSize: 14,
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(4),
    width: '100%'
  },
  valueText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(3),
    textAlign: 'center',
    width: '100%'
  },
  textSmall: {
    fontSize: 10,
    color: theme.palette.text.blackLight
  },
  contractText: {
    color: theme.palette.primary.dark,
    cursor: 'pointer',
    fontWeight: 600,
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}));

export default withWidth()(ValidationStepGDG);
