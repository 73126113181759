import React, { useEffect, useState } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Typography,
  Grid,
  IconButton,
  Grow,
  useMediaQuery
} from '@material-ui/core';

import TextInput from '../../../../../../Components/Inputs/TextInput';
import CompositeSelectInput from '../../../../../../Components/Inputs/CompositeSelectInput';
import SelectInput from '../../../../../../Components/Inputs/SelectInput';
import SimpleDivider from '../../../../../../Components/Dividers/SimpleDivider';

import { IDENTIFICATION_OPTIONS } from '../../../../../../Enums/insurances';
import SweetAlert from '../../../../../../Components/Alerts/SweetAlert';
import { ALERT_TYPE } from '../../../../../../Components/Alerts/alert_enums';

const BeneficiariesList = props => {
  const {
    beneficiaries,
    relationshipOptions,
    noEdit,
    editBeneficiary,
    deleteBeneficiary
  } = props;

  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      className={classes.beneficiariesContainer}
    >
      {beneficiaries.map((beneficiary, index) => {
        return (
          <Beneficiary
            key={`${index}`}
            beneficiary={beneficiary}
            deleteBeneficiary={deleteBeneficiary}
            editBeneficiary={editBeneficiary}
            index={index}
            noEdit={noEdit}
            relationshipOptions={relationshipOptions}
            beneficiaries={beneficiaries}
          />
        );
      })}
    </Grid>
  );
};

const Beneficiary = props => {
  const {
    beneficiary,
    relationshipOptions,
    index,
    noEdit,
    editBeneficiary,
    deleteBeneficiary,
    beneficiaries
  } = props;

  const classes = useStyles();
  const isMediumSize = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  const [editMode, setEditMode] = useState(false);

  const beneficiariesWithoutItself = [...beneficiaries].filter(
    (_, benefIndex) => benefIndex !== index
  );

  const schema = yup.object({
    fullName: yup.string().required('Escriba el nombre del beneficiario'),
    identification: yup
      .string()
      .test(
        'sameId',
        'El numero de identificación ya está presente en los beneficiarios',
        val => {
          return beneficiariesWithoutItself.length > 0
            ? !beneficiariesWithoutItself.some(s => s.identification === val)
            : true;
        }
      )
      .required('Escriba el número de documento del beneficiario'),
    relationship: yup
      .object({
        id: yup.number().integer(),
        nombre: yup.string()
      })
      .required('Elija un parentesco válido'),
    percentage: yup
      .number()
      .integer()
      .positive('El porcentaje debe ser positivo')
      .typeError('Porcentaje debe de tener un valor')
      .test(
        'totalPercentage',
        'La suma del porcentaje de los beneficiarios debe ser igual a 100%',
        val => {
          const percentageSum = beneficiariesWithoutItself
            .map(item => parseInt(item.percentage, 10))
            .reduce((prev, curr) => prev + curr, 0);
          return val && percentageSum + parseInt(val, 10) <= 100;
        }
      )
      .test(
        'minValue',
        'El porcentaje debe ser positivo',
        val => val && val > 0
      )
      .test(
        'maxValue',
        'El porcentaje debe ser menor a 100%',
        val => val && val <= 100
      )
      .required('Porcentaje debe de tener un valor')
  });

  const { register, control, handleSubmit, errors, reset } = useForm({
    validationSchema: schema,
    defaultValues: beneficiary,
    mode: 'onBlur'
  });

  useEffect(() => {
    reset(beneficiary);
  }, [editMode, beneficiary, reset]);

  const editSelf = values => {
    editBeneficiary(values, index);
    setEditMode(false);
  };

  const deleteSelf = () => {
    deleteBeneficiary(index);
  };
  return (
    <>
      <Grow in={true} timeout={500}>
        <div className={classes.card}>
          <div className={classes.innerContainer}>
            {editMode ? (
              <form id="editBeneficiary" className={classes.formContainer}>
                <Grid container className={classes.beneciciaryContentContainer}>
                  <Grid
                    item
                    container
                    xs={12}
                    md={11}
                    justify="space-between"
                    alignItems="center"
                    className={classes.inputEditContainer}
                  >
                    <Grid
                      item
                      xs={12}
                      md={3}
                      className={clsx(
                        classes.inputContainer,
                        classes.inputFieldContainer
                      )}
                    >
                      <TextInput
                        label="Nombre completo"
                        name="fullName"
                        defaultValue={beneficiary.fullName}
                        inputRef={register}
                        fullWidth={true}
                        error={Boolean(errors.fullName)}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      className={clsx(classes.inputContainer)}
                    >
                      <CompositeSelectInput
                        label="Identificación"
                        options={IDENTIFICATION_OPTIONS}
                        TextInputProps={{
                          label: 'Identificación',
                          name: 'identification',
                          defaultValue: beneficiary.identification,
                          type: 'number',
                          inputRef: register,
                          fullWidth: true,
                          margin: 'none'
                        }}
                        SelectInputProps={{
                          name: 'identificationType',
                          defaultValue: beneficiary.identificationType,
                          inputRef: register,
                          control
                        }}
                        error={Boolean(errors.identification)}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      className={clsx(
                        classes.inputContainer,
                        classes.inputFieldContainer
                      )}
                    >
                      <Controller
                        as={SelectInput}
                        label="Parentesco"
                        name="relationship"
                        inputRef={register}
                        fullWidth={true}
                        control={control}
                        defaultValue={beneficiary.relationship}
                        options={relationshipOptions}
                        error={Boolean(errors.relationship)}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={2}
                      className={clsx(
                        classes.inputContainer,
                        classes.inputFieldContainer
                      )}
                    >
                      <TextInput
                        label="Porcentaje"
                        name="percentage"
                        inputRef={register}
                        fullWidth={true}
                        type="number"
                        defaultValue={beneficiary.percentage}
                        error={Boolean(errors.percentage)}
                      />
                    </Grid>
                  </Grid>
                  {isMediumSize && (
                    <SimpleDivider className={classes.divider} />
                  )}
                  <Grid
                    item
                    container
                    xs={12}
                    md={1}
                    className={classes.actionButtonsContainer}
                  >
                    <IconButton
                      size="small"
                      onClick={handleSubmit(editSelf)}
                      className={classes.editActionButton}
                    >
                      <SaveIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => setEditMode(false)}
                      className={classes.removeActionButton}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </form>
            ) : (
              <Grid container className={classes.beneciciaryContentContainer}>
                <Grid
                  item
                  container
                  xs={12}
                  md={11}
                  className={classes.detailsContainer}
                >
                  <Grid item container sm={6} md={4} direction="column">
                    <Typography className={classes.label}>
                      Nombre completo
                    </Typography>
                    <Typography className={classes.data}>
                      {beneficiary.fullName}
                    </Typography>
                  </Grid>
                  <Grid item container sm={6} md={2} direction="column">
                    <Typography className={classes.label}>
                      Identificación
                    </Typography>
                    <Typography className={classes.data}>
                      {beneficiary.identification}{' '}
                      {beneficiary.identificationType}
                    </Typography>
                  </Grid>
                  <Grid item container sm={6} md={4} direction="column">
                    <Typography className={classes.label}>
                      Parentesco
                    </Typography>
                    <Typography className={classes.data}>
                      {beneficiary.relationship.nombre}
                    </Typography>
                  </Grid>
                  <Grid item container sm={6} md={2} direction="column">
                    <Typography className={classes.label}>
                      Porcentaje
                    </Typography>
                    <Typography className={classes.data}>
                      {beneficiary.percentage}%
                    </Typography>
                  </Grid>
                </Grid>
                {isMediumSize && !noEdit && (
                  <SimpleDivider className={classes.divider} />
                )}
                {!noEdit && (
                  <Grid
                    item
                    container
                    xs={12}
                    md={1}
                    className={classes.actionButtonsContainer}
                  >
                    <Grid item>
                      <IconButton
                        size="small"
                        onClick={() => setEditMode(true)}
                        className={classes.editActionButton}
                      >
                        <EditIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        size="small"
                        onClick={() => deleteSelf()}
                        className={classes.removeActionButton}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </div>
        </div>
      </Grow>
      {Object.keys(errors).length > 0 && (
        <SweetAlert
          type={ALERT_TYPE.ERROR}
          message={
            <>
              <Typography className={classes.sweetTitle}>
                Beneficiario {index + 1}
              </Typography>
              {Object.values(errors).map((error, errorIndex) => (
                <Typography
                  key={`error ${index}-${errorIndex}`}
                  className={classes.sweetText}
                >
                  <strong>-</strong> {error.message}
                </Typography>
              ))}
            </>
          }
        />
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  beneficiariesContainer: {
    '& > *': {
      marginBottom: theme.spacing(2)
    },
    '& > *:last-child': {
      marginBottom: 0
    }
  },
  formContainer: {
    width: '100%'
  },
  beneciciaryContentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'column'
    }
  },
  actionButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      justifyContent: 'space-between',
      flexDirection: 'row-reverse'
    }
  },
  detailsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxHeight: 101,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      maxHeight: '100%',
      '& > *': {
        marginBottom: theme.spacing(1)
      }
    }
  },
  card: {
    display: 'flex',
    overflow: 'hidden',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'column'
    }
  },
  innerContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.background.border}`,
    padding: theme.spacing(1, 2),
    flexGrow: 1,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: theme.spacing(2)
    }
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  inputContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginBottom: theme.spacing(1)
    }
  },
  inputFieldContainer: {
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  label: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.greyDark
  },
  data: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.2,
    textTransform: 'capitalize',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 13
    }
  },
  editActionButton: {
    color: theme.palette.primary.main
  },
  removeActionButton: {
    color: theme.palette.color.danger
  },
  sweetTitle: {
    fontSize: 14,
    fontWeight: 600
  },
  sweetText: {
    fontSize: 12
  }
}));

export default BeneficiariesList;
