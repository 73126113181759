import DiamondIcon from '../Components/CustomIcons/DiamondIcon';
import CrownIcon from '../Components/CustomIcons/CrownIcon';
import RibbonIcon from '../Components/CustomIcons/RibbonIcon';

import ExequialesBanner from '../Assets/images/banner-exequial.jpg';
import PractiseguroBanner from '../Assets/images/banner-practiseguro.jpg';
import GeneralBanner from '../Assets/images/banner-general.jpg';

export const DEFAULT_SEARCH_VALUE = { id: '', label: '' };

export const InsuranceProductType = {
  Life: 'life'
};

export const InsuranceProcess = {
  Policy: 0,
  Appointment: 1
};

export const Insurer = {
  AlfaSeguros: 7
};

export const PractiSeguroProduct = {
  product: 18,
  name: 'Practi Seguro',
  title: 'Practi Seguro - Seguro de vida',
  id: 'practiseguro',
  description:
    'Con tu seguro de vida Practiseguro, fortalece el futuro de tu familia con cómodas cuotas mensuales y la posibilidad de amparar a otro pariente menor de 65 años.',
  coverage: 'Amparo de vida',
  minAge: 18,
  maxAge: 65,
  permanenceAge: -1,
  maxMonthlyAllowance: 13913590
};

export const InsurancePlan = {
  Silver: 483,
  Gold: 484,
  Diamond: 505
};

export const InsuranceProduct = {
  Practiseguro: 18,
  Funeraria: 10
};

export const InsurancePlanIcon = {
  [InsurancePlan.Silver]: RibbonIcon,
  [InsurancePlan.Gold]: CrownIcon,
  [InsurancePlan.Diamond]: DiamondIcon
};

export const InsurancePlans = {
  [InsurancePlan.Silver]: {
    id: InsurancePlan.Silver,
    name: 'Silver - Plan 1',
    insuredNumber: 1,
    monthlyPay: '-',
    coverageRange: [7870092],
    buttonColor: '#D9FFFF'
  },
  [InsurancePlan.Gold]: {
    id: InsurancePlan.Gold,
    name: 'Gold - Plan 2',
    insuredNumber: 2,
    monthlyPay: '-',
    coverageRange: [4391400, 2195700],
    buttonColor: '#FFF8E4'
  },
  [InsurancePlan.Diamond]: {
    id: InsurancePlan.Diamond,
    name: 'Diamond - Plan 3',
    insuredNumber: 1,
    monthlyPay: '-',
    coverageRange: [13173280],
    buttonColor: '#E8F0FC'
  }
};

export const SummarySectionPanel = {
  Contract: 'contract',
  Plan: 'plan',
  Form: 'form'
};

export const FAQPanel = {
  Adquisition: 'adquisition',
  StartOfCoverage: 'start-of-coverage',
  RequiredDocuments: 'required-documents'
};

export const FORM_ID = 'Insurance_';

export const InsuranceAcquisitionStep = {
  Contract: 0,
  Plan: 1,
  Form: 2,
  Summary: 3
};

export const InsuranceAcquisitionStepLabel = {
  [InsuranceAcquisitionStep.Contract]: 'Contrato',
  [InsuranceAcquisitionStep.Plan]: 'Plan',
  [InsuranceAcquisitionStep.Form]: 'Formulario',
  [InsuranceAcquisitionStep.Summary]: 'Resumen'
};

export const INSURANCE_ACQUISITION_STEPS = [
  {
    label: InsuranceAcquisitionStepLabel[InsuranceAcquisitionStep.Contract]
  },
  {
    label: InsuranceAcquisitionStepLabel[InsuranceAcquisitionStep.Plan]
  },
  {
    label: InsuranceAcquisitionStepLabel[InsuranceAcquisitionStep.Form]
  },
  {
    label: InsuranceAcquisitionStepLabel[InsuranceAcquisitionStep.Summary]
  }
];

const IDENTIFICATION_TYPES = {
  CC: 'CC',
  PP: 'PP',
  CE: 'CE',
  TI: 'TI',
  RC: 'RC'
};

export const IDENTIFICATION_OPTIONS = [
  { label: 'C.C', value: IDENTIFICATION_TYPES.CC },
  { label: 'P.P', value: IDENTIFICATION_TYPES.PP },
  { label: 'C.E', value: IDENTIFICATION_TYPES.CE },
  { label: 'T.I', value: IDENTIFICATION_TYPES.TI },
  { label: 'R.C', value: IDENTIFICATION_TYPES.RC }
];

/**
 * Adquisición: 0,
 * Asesoría: 1, //Agendar
 * Siniestro: 2,
 * Anulación: 3
 */
export const REQUEST_TYPE = {
  Acquisition: 0,
  Appointment: 1,
  Claims: 2,
  Annulment: 3
};

export const InsuranceRequestMessage = {
  [REQUEST_TYPE.Acquisition]: id => ({
    title: '¡Muchas gracias!',
    message: `Tu solicitud de adquisición de seguro Nº ${id} ha sido registrada.`,
    subMessage:
      'Ya puedes acceder a los servicios adquiridos desde el portal de seguros.'
  }),
  [REQUEST_TYPE.Appointment]: id => ({
    message: `Tu solicitud de asesoría Nº ${id} ha sido generada.`,
    subMessage:
      'La compañía prestadora de servicios se estará comunicando contigo lo más pronto posible.'
  }),
  [REQUEST_TYPE.Claims]: id => ({
    message: `Tu siniestro para la póliza ${id} ha sido reportado.`,
    subMessage:
      'La compañía prestadora de servicios se estará comunicando contigo en los próximos 30 días.'
  }),
  [REQUEST_TYPE.Annulment]: id => ({
    message: `Tu solicitud de anulación Nº ${id} ha sido generada.`,
    subMessage:
      'La compañía prestadora de servicios se estará comunicando contigo lo más pronto posible.'
  })
};

export const INSURANCES_CARROUSEL_SLIDES = [
  {
    title: 'Asegura tu tranquilidad en todo momento',
    image: GeneralBanner,
    imageMobile: GeneralBanner,
    description:
      'Con nuestro portafolio de seguros protege a los que mas quieres.\n\n'
  },
  {
    title: 'Siéntete respaldado ante la partida de un ser querido',
    image: ExequialesBanner,
    imageMobile: ExequialesBanner,
    description:
      'Con nuestro seguro funerario tendrás una solución cuando más lo necesites.\n\n'
  },
  {
    title: 'El bienestar de tu familia es lo más importante',
    image: PractiseguroBanner,
    imageMobile: PractiseguroBanner,
    description: 'Protégelo con nuestro seguro de vida Practiseguro.\n\n'
  }
];

export const EXPEDITION_PLACES = [
  {
    label: 'Leticia - Amazonas',
    value: 'Leticia - Amazonas'
  },
  {
    label: 'El Encanto - Amazonas',
    value: 'El Encanto - Amazonas'
  },
  {
    label: 'La Chorrera - Amazonas',
    value: 'La Chorrera - Amazonas'
  },
  {
    label: 'La Pedrera - Amazonas',
    value: 'La Pedrera - Amazonas'
  },
  {
    label: 'La Victoria - Amazonas',
    value: 'La Victoria - Amazonas'
  },
  {
    label: 'Puerto Arica - Amazonas',
    value: 'Puerto Arica - Amazonas'
  },
  {
    label: 'Puerto Nariño - Amazonas',
    value: 'Puerto Nariño - Amazonas'
  },
  {
    label: 'Puerto Santander - Amazonas',
    value: 'Puerto Santander - Amazonas'
  },
  {
    label: 'Tarapacá - Amazonas',
    value: 'Tarapacá - Amazonas'
  },
  {
    label: 'Puerto Alegría - Amazonas',
    value: 'Puerto Alegría - Amazonas'
  },
  {
    label: 'Medellín - Antioquia',
    value: 'Medellín - Antioquia'
  },
  {
    label: 'Abejorral - Antioquia',
    value: 'Abejorral - Antioquia'
  },
  {
    label: 'Abriaquí - Antioquia',
    value: 'Abriaquí - Antioquia'
  },
  {
    label: 'Alejandría - Antioquia',
    value: 'Alejandría - Antioquia'
  },
  {
    label: 'Amagá - Antioquia',
    value: 'Amagá - Antioquia'
  },
  {
    label: 'Amalfi - Antioquia',
    value: 'Amalfi - Antioquia'
  },
  {
    label: 'Andes - Antioquia',
    value: 'Andes - Antioquia'
  },
  {
    label: 'Angelópolis - Antioquia',
    value: 'Angelópolis - Antioquia'
  },
  {
    label: 'Angostura - Antioquia',
    value: 'Angostura - Antioquia'
  },
  {
    label: 'Anorí - Antioquia',
    value: 'Anorí - Antioquia'
  },
  {
    label: 'Anza - Antioquia',
    value: 'Anza - Antioquia'
  },
  {
    label: 'Apartadó - Antioquia',
    value: 'Apartadó - Antioquia'
  },
  {
    label: 'Arboletes - Antioquia',
    value: 'Arboletes - Antioquia'
  },
  {
    label: 'Argelia - Antioquia',
    value: 'Argelia - Antioquia'
  },
  {
    label: 'Armenia - Antioquia',
    value: 'Armenia - Antioquia'
  },
  {
    label: 'Barbosa - Antioquia',
    value: 'Barbosa - Antioquia'
  },
  {
    label: 'Bello - Antioquia',
    value: 'Bello - Antioquia'
  },
  {
    label: 'Betania - Antioquia',
    value: 'Betania - Antioquia'
  },
  {
    label: 'Betulia - Antioquia',
    value: 'Betulia - Antioquia'
  },
  {
    label: 'Ciudad Bolívar - Antioquia',
    value: 'Ciudad Bolívar - Antioquia'
  },
  {
    label: 'Briceño - Antioquia',
    value: 'Briceño - Antioquia'
  },
  {
    label: 'Buriticá - Antioquia',
    value: 'Buriticá - Antioquia'
  },
  {
    label: 'Cáceres - Antioquia',
    value: 'Cáceres - Antioquia'
  },
  {
    label: 'Caicedo - Antioquia',
    value: 'Caicedo - Antioquia'
  },
  {
    label: 'Caldas - Antioquia',
    value: 'Caldas - Antioquia'
  },
  {
    label: 'Campamento - Antioquia',
    value: 'Campamento - Antioquia'
  },
  {
    label: 'Cañasgordas - Antioquia',
    value: 'Cañasgordas - Antioquia'
  },
  {
    label: 'Caracolí - Antioquia',
    value: 'Caracolí - Antioquia'
  },
  {
    label: 'Caramanta - Antioquia',
    value: 'Caramanta - Antioquia'
  },
  {
    label: 'Carepa - Antioquia',
    value: 'Carepa - Antioquia'
  },
  {
    label: 'Carolina - Antioquia',
    value: 'Carolina - Antioquia'
  },
  {
    label: 'Caucasia - Antioquia',
    value: 'Caucasia - Antioquia'
  },
  {
    label: 'Chigorodó - Antioquia',
    value: 'Chigorodó - Antioquia'
  },
  {
    label: 'Cisneros - Antioquia',
    value: 'Cisneros - Antioquia'
  },
  {
    label: 'Cocorná - Antioquia',
    value: 'Cocorná - Antioquia'
  },
  {
    label: 'Concepción - Antioquia',
    value: 'Concepción - Antioquia'
  },
  {
    label: 'Concordia - Antioquia',
    value: 'Concordia - Antioquia'
  },
  {
    label: 'Copacabana - Antioquia',
    value: 'Copacabana - Antioquia'
  },
  {
    label: 'Dabeiba - Antioquia',
    value: 'Dabeiba - Antioquia'
  },
  {
    label: 'Don Matías - Antioquia',
    value: 'Don Matías - Antioquia'
  },
  {
    label: 'Ebéjico - Antioquia',
    value: 'Ebéjico - Antioquia'
  },
  {
    label: 'El Bagre - Antioquia',
    value: 'El Bagre - Antioquia'
  },
  {
    label: 'Entrerrios - Antioquia',
    value: 'Entrerrios - Antioquia'
  },
  {
    label: 'Envigado - Antioquia',
    value: 'Envigado - Antioquia'
  },
  {
    label: 'Fredonia - Antioquia',
    value: 'Fredonia - Antioquia'
  },
  {
    label: 'Giraldo - Antioquia',
    value: 'Giraldo - Antioquia'
  },
  {
    label: 'Girardota - Antioquia',
    value: 'Girardota - Antioquia'
  },
  {
    label: 'Gómez Plata - Antioquia',
    value: 'Gómez Plata - Antioquia'
  },
  {
    label: 'Guadalupe - Antioquia',
    value: 'Guadalupe - Antioquia'
  },
  {
    label: 'Guarne - Antioquia',
    value: 'Guarne - Antioquia'
  },
  {
    label: 'Guatapé - Antioquia',
    value: 'Guatapé - Antioquia'
  },
  {
    label: 'Heliconia - Antioquia',
    value: 'Heliconia - Antioquia'
  },
  {
    label: 'Hispania - Antioquia',
    value: 'Hispania - Antioquia'
  },
  {
    label: 'Itagui - Antioquia',
    value: 'Itagui - Antioquia'
  },
  {
    label: 'Ituango - Antioquia',
    value: 'Ituango - Antioquia'
  },
  {
    label: 'Belmira - Antioquia',
    value: 'Belmira - Antioquia'
  },
  {
    label: 'Jericó - Antioquia',
    value: 'Jericó - Antioquia'
  },
  {
    label: 'La Ceja - Antioquia',
    value: 'La Ceja - Antioquia'
  },
  {
    label: 'La Estrella - Antioquia',
    value: 'La Estrella - Antioquia'
  },
  {
    label: 'La Pintada - Antioquia',
    value: 'La Pintada - Antioquia'
  },
  {
    label: 'La Unión - Antioquia',
    value: 'La Unión - Antioquia'
  },
  {
    label: 'Liborina - Antioquia',
    value: 'Liborina - Antioquia'
  },
  {
    label: 'Maceo - Antioquia',
    value: 'Maceo - Antioquia'
  },
  {
    label: 'Marinilla - Antioquia',
    value: 'Marinilla - Antioquia'
  },
  {
    label: 'Montebello - Antioquia',
    value: 'Montebello - Antioquia'
  },
  {
    label: 'Murindó - Antioquia',
    value: 'Murindó - Antioquia'
  },
  {
    label: 'Mutatá - Antioquia',
    value: 'Mutatá - Antioquia'
  },
  {
    label: 'Nariño - Antioquia',
    value: 'Nariño - Antioquia'
  },
  {
    label: 'Necoclí - Antioquia',
    value: 'Necoclí - Antioquia'
  },
  {
    label: 'Nechí - Antioquia',
    value: 'Nechí - Antioquia'
  },
  {
    label: 'Olaya - Antioquia',
    value: 'Olaya - Antioquia'
  },
  {
    label: 'Peñol - Antioquia',
    value: 'Peñol - Antioquia'
  },
  {
    label: 'Peque - Antioquia',
    value: 'Peque - Antioquia'
  },
  {
    label: 'Pueblorrico - Antioquia',
    value: 'Pueblorrico - Antioquia'
  },
  {
    label: 'Puerto Berrío - Antioquia',
    value: 'Puerto Berrío - Antioquia'
  },
  {
    label: 'Puerto Nare - Antioquia',
    value: 'Puerto Nare - Antioquia'
  },
  {
    label: 'Puerto Triunfo - Antioquia',
    value: 'Puerto Triunfo - Antioquia'
  },
  {
    label: 'Remedios - Antioquia',
    value: 'Remedios - Antioquia'
  },
  {
    label: 'Retiro - Antioquia',
    value: 'Retiro - Antioquia'
  },
  {
    label: 'Rionegro - Antioquia',
    value: 'Rionegro - Antioquia'
  },
  {
    label: 'Sabanalarga - Antioquia',
    value: 'Sabanalarga - Antioquia'
  },
  {
    label: 'Sabaneta - Antioquia',
    value: 'Sabaneta - Antioquia'
  },
  {
    label: 'Salgar - Antioquia',
    value: 'Salgar - Antioquia'
  },
  {
    label: 'San Francisco - Antioquia',
    value: 'San Francisco - Antioquia'
  },
  {
    label: 'San Jerónimo - Antioquia',
    value: 'San Jerónimo - Antioquia'
  },
  {
    label: 'San Luis - Antioquia',
    value: 'San Luis - Antioquia'
  },
  {
    label: 'San Pedro - Antioquia',
    value: 'San Pedro - Antioquia'
  },
  {
    label: 'San Rafael - Antioquia',
    value: 'San Rafael - Antioquia'
  },
  {
    label: 'San Roque - Antioquia',
    value: 'San Roque - Antioquia'
  },
  {
    label: 'San Vicente - Antioquia',
    value: 'San Vicente - Antioquia'
  },
  {
    label: 'Santa Bárbara - Antioquia',
    value: 'Santa Bárbara - Antioquia'
  },
  {
    label: 'Santo Domingo - Antioquia',
    value: 'Santo Domingo - Antioquia'
  },
  {
    label: 'El Santuario - Antioquia',
    value: 'El Santuario - Antioquia'
  },
  {
    label: 'Segovia - Antioquia',
    value: 'Segovia - Antioquia'
  },
  {
    label: 'Sopetrán - Antioquia',
    value: 'Sopetrán - Antioquia'
  },
  {
    label: 'Támesis - Antioquia',
    value: 'Támesis - Antioquia'
  },
  {
    label: 'Tarazá - Antioquia',
    value: 'Tarazá - Antioquia'
  },
  {
    label: 'Tarso - Antioquia',
    value: 'Tarso - Antioquia'
  },
  {
    label: 'Titiribí - Antioquia',
    value: 'Titiribí - Antioquia'
  },
  {
    label: 'Toledo - Antioquia',
    value: 'Toledo - Antioquia'
  },
  {
    label: 'Turbo - Antioquia',
    value: 'Turbo - Antioquia'
  },
  {
    label: 'Uramita - Antioquia',
    value: 'Uramita - Antioquia'
  },
  {
    label: 'Urrao - Antioquia',
    value: 'Urrao - Antioquia'
  },
  {
    label: 'Valdivia - Antioquia',
    value: 'Valdivia - Antioquia'
  },
  {
    label: 'Valparaíso - Antioquia',
    value: 'Valparaíso - Antioquia'
  },
  {
    label: 'Vegachí - Antioquia',
    value: 'Vegachí - Antioquia'
  },
  {
    label: 'Venecia - Antioquia',
    value: 'Venecia - Antioquia'
  },
  {
    label: 'Yalí - Antioquia',
    value: 'Yalí - Antioquia'
  },
  {
    label: 'Yarumal - Antioquia',
    value: 'Yarumal - Antioquia'
  },
  {
    label: 'Yolombó - Antioquia',
    value: 'Yolombó - Antioquia'
  },
  {
    label: 'Yondó - Antioquia',
    value: 'Yondó - Antioquia'
  },
  {
    label: 'Zaragoza - Antioquia',
    value: 'Zaragoza - Antioquia'
  },
  {
    label: 'San Pedro de Uraba - Antioquia',
    value: 'San Pedro de Uraba - Antioquia'
  },
  {
    label: 'Santafé de Antioquia - Antioquia',
    value: 'Santafé de Antioquia - Antioquia'
  },
  {
    label: 'Santa Rosa de Osos - Antioquia',
    value: 'Santa Rosa de Osos - Antioquia'
  },
  {
    label: 'San Andrés de Cuerquía - Antioquia',
    value: 'San Andrés de Cuerquía - Antioquia'
  },
  {
    label: 'Vigía del Fuerte - Antioquia',
    value: 'Vigía del Fuerte - Antioquia'
  },
  {
    label: 'San José de La Montaña - Antioquia',
    value: 'San José de La Montaña - Antioquia'
  },
  {
    label: 'San Juan de Urabá - Antioquia',
    value: 'San Juan de Urabá - Antioquia'
  },
  {
    label: 'El Carmen de Viboral - Antioquia',
    value: 'El Carmen de Viboral - Antioquia'
  },
  {
    label: 'San Carlos - Antioquia',
    value: 'San Carlos - Antioquia'
  },
  {
    label: 'Arauquita - Arauca',
    value: 'Arauquita - Arauca'
  },
  {
    label: 'Cravo Norte - Arauca',
    value: 'Cravo Norte - Arauca'
  },
  {
    label: 'Fortul - Arauca',
    value: 'Fortul - Arauca'
  },
  {
    label: 'Puerto Rondón - Arauca',
    value: 'Puerto Rondón - Arauca'
  },
  {
    label: 'Saravena - Arauca',
    value: 'Saravena - Arauca'
  },
  {
    label: 'Tame - Arauca',
    value: 'Tame - Arauca'
  },
  {
    label: 'Arauca - Arauca',
    value: 'Arauca - Arauca'
  },
  {
    label:
      'Providencia - Archipiélago de San Andrés, Providencia y Santa Catalina',
    value:
      'Providencia - Archipiélago de San Andrés, Providencia y Santa Catalina'
  },
  {
    label:
      'San Andrés - Archipiélago de San Andrés, Providencia y Santa Catalina',
    value:
      'San Andrés - Archipiélago de San Andrés, Providencia y Santa Catalina'
  },
  {
    label: 'Barranquilla - Atlántico',
    value: 'Barranquilla - Atlántico'
  },
  {
    label: 'Baranoa - Atlántico',
    value: 'Baranoa - Atlántico'
  },
  {
    label: 'Candelaria - Atlántico',
    value: 'Candelaria - Atlántico'
  },
  {
    label: 'Galapa - Atlántico',
    value: 'Galapa - Atlántico'
  },
  {
    label: 'Luruaco - Atlántico',
    value: 'Luruaco - Atlántico'
  },
  {
    label: 'Malambo - Atlántico',
    value: 'Malambo - Atlántico'
  },
  {
    label: 'Manatí - Atlántico',
    value: 'Manatí - Atlántico'
  },
  {
    label: 'Piojó - Atlántico',
    value: 'Piojó - Atlántico'
  },
  {
    label: 'Polonuevo - Atlántico',
    value: 'Polonuevo - Atlántico'
  },
  {
    label: 'Sabanagrande - Atlántico',
    value: 'Sabanagrande - Atlántico'
  },
  {
    label: 'Sabanalarga - Atlántico',
    value: 'Sabanalarga - Atlántico'
  },
  {
    label: 'Santa Lucía - Atlántico',
    value: 'Santa Lucía - Atlántico'
  },
  {
    label: 'Santo Tomás - Atlántico',
    value: 'Santo Tomás - Atlántico'
  },
  {
    label: 'Soledad - Atlántico',
    value: 'Soledad - Atlántico'
  },
  {
    label: 'Suan - Atlántico',
    value: 'Suan - Atlántico'
  },
  {
    label: 'Tubará - Atlántico',
    value: 'Tubará - Atlántico'
  },
  {
    label: 'Usiacurí - Atlántico',
    value: 'Usiacurí - Atlántico'
  },
  {
    label: 'Juan de Acosta - Atlántico',
    value: 'Juan de Acosta - Atlántico'
  },
  {
    label: 'Palmar de Varela - Atlántico',
    value: 'Palmar de Varela - Atlántico'
  },
  {
    label: 'Campo de La Cruz - Atlántico',
    value: 'Campo de La Cruz - Atlántico'
  },
  {
    label: 'Bogotá D.C. - Bogotá D.C.',
    value: 'Bogotá D.C. - Bogotá D.C.'
  },
  {
    label: 'Achí - Bolívar',
    value: 'Achí - Bolívar'
  },
  {
    label: 'Arenal - Bolívar',
    value: 'Arenal - Bolívar'
  },
  {
    label: 'Arjona - Bolívar',
    value: 'Arjona - Bolívar'
  },
  {
    label: 'Arroyohondo - Bolívar',
    value: 'Arroyohondo - Bolívar'
  },
  {
    label: 'Calamar - Bolívar',
    value: 'Calamar - Bolívar'
  },
  {
    label: 'Cantagallo - Bolívar',
    value: 'Cantagallo - Bolívar'
  },
  {
    label: 'Cicuco - Bolívar',
    value: 'Cicuco - Bolívar'
  },
  {
    label: 'Córdoba - Bolívar',
    value: 'Córdoba - Bolívar'
  },
  {
    label: 'Clemencia - Bolívar',
    value: 'Clemencia - Bolívar'
  },
  {
    label: 'El Guamo - Bolívar',
    value: 'El Guamo - Bolívar'
  },
  {
    label: 'Magangué - Bolívar',
    value: 'Magangué - Bolívar'
  },
  {
    label: 'Mahates - Bolívar',
    value: 'Mahates - Bolívar'
  },
  {
    label: 'Margarita - Bolívar',
    value: 'Margarita - Bolívar'
  },
  {
    label: 'Montecristo - Bolívar',
    value: 'Montecristo - Bolívar'
  },
  {
    label: 'Mompós - Bolívar',
    value: 'Mompós - Bolívar'
  },
  {
    label: 'Morales - Bolívar',
    value: 'Morales - Bolívar'
  },
  {
    label: 'Norosí - Bolívar',
    value: 'Norosí - Bolívar'
  },
  {
    label: 'Pinillos - Bolívar',
    value: 'Pinillos - Bolívar'
  },
  {
    label: 'Regidor - Bolívar',
    value: 'Regidor - Bolívar'
  },
  {
    label: 'Río Viejo - Bolívar',
    value: 'Río Viejo - Bolívar'
  },
  {
    label: 'San Estanislao - Bolívar',
    value: 'San Estanislao - Bolívar'
  },
  {
    label: 'San Fernando - Bolívar',
    value: 'San Fernando - Bolívar'
  },
  {
    label: 'San Juan Nepomuceno - Bolívar',
    value: 'San Juan Nepomuceno - Bolívar'
  },
  {
    label: 'Santa Catalina - Bolívar',
    value: 'Santa Catalina - Bolívar'
  },
  {
    label: 'Santa Rosa - Bolívar',
    value: 'Santa Rosa - Bolívar'
  },
  {
    label: 'Simití - Bolívar',
    value: 'Simití - Bolívar'
  },
  {
    label: 'Soplaviento - Bolívar',
    value: 'Soplaviento - Bolívar'
  },
  {
    label: 'Talaigua Nuevo - Bolívar',
    value: 'Talaigua Nuevo - Bolívar'
  },
  {
    label: 'Tiquisio - Bolívar',
    value: 'Tiquisio - Bolívar'
  },
  {
    label: 'Turbaco - Bolívar',
    value: 'Turbaco - Bolívar'
  },
  {
    label: 'Turbaná - Bolívar',
    value: 'Turbaná - Bolívar'
  },
  {
    label: 'Villanueva - Bolívar',
    value: 'Villanueva - Bolívar'
  },
  {
    label: 'Barranco de Loba - Bolívar',
    value: 'Barranco de Loba - Bolívar'
  },
  {
    label: 'Santa Rosa del Sur - Bolívar',
    value: 'Santa Rosa del Sur - Bolívar'
  },
  {
    label: 'Hatillo de Loba - Bolívar',
    value: 'Hatillo de Loba - Bolívar'
  },
  {
    label: 'El Carmen de Bolívar - Bolívar',
    value: 'El Carmen de Bolívar - Bolívar'
  },
  {
    label: 'San Martín de Loba - Bolívar',
    value: 'San Martín de Loba - Bolívar'
  },
  {
    label: 'Altos del Rosario - Bolívar',
    value: 'Altos del Rosario - Bolívar'
  },
  {
    label: 'San Jacinto del Cauca - Bolívar',
    value: 'San Jacinto del Cauca - Bolívar'
  },
  {
    label: 'San Pablo de Borbur - Bolívar',
    value: 'San Pablo de Borbur - Bolívar'
  },
  {
    label: 'San Jacinto - Bolívar',
    value: 'San Jacinto - Bolívar'
  },
  {
    label: 'Tununguá - Boyacá',
    value: 'Tununguá - Boyacá'
  },
  {
    label: 'Motavita - Boyacá',
    value: 'Motavita - Boyacá'
  },
  {
    label: 'Ciénega - Boyacá',
    value: 'Ciénega - Boyacá'
  },
  {
    label: 'Tunja - Boyacá',
    value: 'Tunja - Boyacá'
  },
  {
    label: 'Almeida - Boyacá',
    value: 'Almeida - Boyacá'
  },
  {
    label: 'Aquitania - Boyacá',
    value: 'Aquitania - Boyacá'
  },
  {
    label: 'Arcabuco - Boyacá',
    value: 'Arcabuco - Boyacá'
  },
  {
    label: 'Berbeo - Boyacá',
    value: 'Berbeo - Boyacá'
  },
  {
    label: 'Betéitiva - Boyacá',
    value: 'Betéitiva - Boyacá'
  },
  {
    label: 'Boavita - Boyacá',
    value: 'Boavita - Boyacá'
  },
  {
    label: 'Boyacá - Boyacá',
    value: 'Boyacá - Boyacá'
  },
  {
    label: 'Briceño - Boyacá',
    value: 'Briceño - Boyacá'
  },
  {
    label: 'Buena Vista - Boyacá',
    value: 'Buena Vista - Boyacá'
  },
  {
    label: 'Busbanzá - Boyacá',
    value: 'Busbanzá - Boyacá'
  },
  {
    label: 'Caldas - Boyacá',
    value: 'Caldas - Boyacá'
  },
  {
    label: 'Campohermoso - Boyacá',
    value: 'Campohermoso - Boyacá'
  },
  {
    label: 'Cerinza - Boyacá',
    value: 'Cerinza - Boyacá'
  },
  {
    label: 'Chinavita - Boyacá',
    value: 'Chinavita - Boyacá'
  },
  {
    label: 'Chiquinquirá - Boyacá',
    value: 'Chiquinquirá - Boyacá'
  },
  {
    label: 'Chiscas - Boyacá',
    value: 'Chiscas - Boyacá'
  },
  {
    label: 'Chita - Boyacá',
    value: 'Chita - Boyacá'
  },
  {
    label: 'Chitaraque - Boyacá',
    value: 'Chitaraque - Boyacá'
  },
  {
    label: 'Chivatá - Boyacá',
    value: 'Chivatá - Boyacá'
  },
  {
    label: 'Cómbita - Boyacá',
    value: 'Cómbita - Boyacá'
  },
  {
    label: 'Coper - Boyacá',
    value: 'Coper - Boyacá'
  },
  {
    label: 'Corrales - Boyacá',
    value: 'Corrales - Boyacá'
  },
  {
    label: 'Covarachía - Boyacá',
    value: 'Covarachía - Boyacá'
  },
  {
    label: 'Cubará - Boyacá',
    value: 'Cubará - Boyacá'
  },
  {
    label: 'Cucaita - Boyacá',
    value: 'Cucaita - Boyacá'
  },
  {
    label: 'Cuítiva - Boyacá',
    value: 'Cuítiva - Boyacá'
  },
  {
    label: 'Chíquiza - Boyacá',
    value: 'Chíquiza - Boyacá'
  },
  {
    label: 'Chivor - Boyacá',
    value: 'Chivor - Boyacá'
  },
  {
    label: 'Duitama - Boyacá',
    value: 'Duitama - Boyacá'
  },
  {
    label: 'El Cocuy - Boyacá',
    value: 'El Cocuy - Boyacá'
  },
  {
    label: 'El Espino - Boyacá',
    value: 'El Espino - Boyacá'
  },
  {
    label: 'Firavitoba - Boyacá',
    value: 'Firavitoba - Boyacá'
  },
  {
    label: 'Floresta - Boyacá',
    value: 'Floresta - Boyacá'
  },
  {
    label: 'Gachantivá - Boyacá',
    value: 'Gachantivá - Boyacá'
  },
  {
    label: 'Gameza - Boyacá',
    value: 'Gameza - Boyacá'
  },
  {
    label: 'Garagoa - Boyacá',
    value: 'Garagoa - Boyacá'
  },
  {
    label: 'Guacamayas - Boyacá',
    value: 'Guacamayas - Boyacá'
  },
  {
    label: 'Guateque - Boyacá',
    value: 'Guateque - Boyacá'
  },
  {
    label: 'Guayatá - Boyacá',
    value: 'Guayatá - Boyacá'
  },
  {
    label: 'Güicán - Boyacá',
    value: 'Güicán - Boyacá'
  },
  {
    label: 'Iza - Boyacá',
    value: 'Iza - Boyacá'
  },
  {
    label: 'Jenesano - Boyacá',
    value: 'Jenesano - Boyacá'
  },
  {
    label: 'Jericó - Boyacá',
    value: 'Jericó - Boyacá'
  },
  {
    label: 'Labranzagrande - Boyacá',
    value: 'Labranzagrande - Boyacá'
  },
  {
    label: 'La Capilla - Boyacá',
    value: 'La Capilla - Boyacá'
  },
  {
    label: 'La Victoria - Boyacá',
    value: 'La Victoria - Boyacá'
  },
  {
    label: 'Macanal - Boyacá',
    value: 'Macanal - Boyacá'
  },
  {
    label: 'Maripí - Boyacá',
    value: 'Maripí - Boyacá'
  },
  {
    label: 'Miraflores - Boyacá',
    value: 'Miraflores - Boyacá'
  },
  {
    label: 'Mongua - Boyacá',
    value: 'Mongua - Boyacá'
  },
  {
    label: 'Monguí - Boyacá',
    value: 'Monguí - Boyacá'
  },
  {
    label: 'Moniquirá - Boyacá',
    value: 'Moniquirá - Boyacá'
  },
  {
    label: 'Muzo - Boyacá',
    value: 'Muzo - Boyacá'
  },
  {
    label: 'Nobsa - Boyacá',
    value: 'Nobsa - Boyacá'
  },
  {
    label: 'Nuevo Colón - Boyacá',
    value: 'Nuevo Colón - Boyacá'
  },
  {
    label: 'Oicatá - Boyacá',
    value: 'Oicatá - Boyacá'
  },
  {
    label: 'Otanche - Boyacá',
    value: 'Otanche - Boyacá'
  },
  {
    label: 'Pachavita - Boyacá',
    value: 'Pachavita - Boyacá'
  },
  {
    label: 'Páez - Boyacá',
    value: 'Páez - Boyacá'
  },
  {
    label: 'Paipa - Boyacá',
    value: 'Paipa - Boyacá'
  },
  {
    label: 'Pajarito - Boyacá',
    value: 'Pajarito - Boyacá'
  },
  {
    label: 'Panqueba - Boyacá',
    value: 'Panqueba - Boyacá'
  },
  {
    label: 'Pauna - Boyacá',
    value: 'Pauna - Boyacá'
  },
  {
    label: 'Paya - Boyacá',
    value: 'Paya - Boyacá'
  },
  {
    label: 'Pesca - Boyacá',
    value: 'Pesca - Boyacá'
  },
  {
    label: 'Pisba - Boyacá',
    value: 'Pisba - Boyacá'
  },
  {
    label: 'Puerto Boyacá - Boyacá',
    value: 'Puerto Boyacá - Boyacá'
  },
  {
    label: 'Quípama - Boyacá',
    value: 'Quípama - Boyacá'
  },
  {
    label: 'Ramiriquí - Boyacá',
    value: 'Ramiriquí - Boyacá'
  },
  {
    label: 'Ráquira - Boyacá',
    value: 'Ráquira - Boyacá'
  },
  {
    label: 'Rondón - Boyacá',
    value: 'Rondón - Boyacá'
  },
  {
    label: 'Saboyá - Boyacá',
    value: 'Saboyá - Boyacá'
  },
  {
    label: 'Sáchica - Boyacá',
    value: 'Sáchica - Boyacá'
  },
  {
    label: 'Samacá - Boyacá',
    value: 'Samacá - Boyacá'
  },
  {
    label: 'San Eduardo - Boyacá',
    value: 'San Eduardo - Boyacá'
  },
  {
    label: 'San Mateo - Boyacá',
    value: 'San Mateo - Boyacá'
  },
  {
    label: 'Santana - Boyacá',
    value: 'Santana - Boyacá'
  },
  {
    label: 'Santa María - Boyacá',
    value: 'Santa María - Boyacá'
  },
  {
    label: 'Santa Sofía - Boyacá',
    value: 'Santa Sofía - Boyacá'
  },
  {
    label: 'Sativanorte - Boyacá',
    value: 'Sativanorte - Boyacá'
  },
  {
    label: 'Sativasur - Boyacá',
    value: 'Sativasur - Boyacá'
  },
  {
    label: 'Siachoque - Boyacá',
    value: 'Siachoque - Boyacá'
  },
  {
    label: 'Soatá - Boyacá',
    value: 'Soatá - Boyacá'
  },
  {
    label: 'Socotá - Boyacá',
    value: 'Socotá - Boyacá'
  },
  {
    label: 'Socha - Boyacá',
    value: 'Socha - Boyacá'
  },
  {
    label: 'Sogamoso - Boyacá',
    value: 'Sogamoso - Boyacá'
  },
  {
    label: 'Somondoco - Boyacá',
    value: 'Somondoco - Boyacá'
  },
  {
    label: 'Sora - Boyacá',
    value: 'Sora - Boyacá'
  },
  {
    label: 'Sotaquirá - Boyacá',
    value: 'Sotaquirá - Boyacá'
  },
  {
    label: 'Soracá - Boyacá',
    value: 'Soracá - Boyacá'
  },
  {
    label: 'Susacón - Boyacá',
    value: 'Susacón - Boyacá'
  },
  {
    label: 'Sutamarchán - Boyacá',
    value: 'Sutamarchán - Boyacá'
  },
  {
    label: 'Sutatenza - Boyacá',
    value: 'Sutatenza - Boyacá'
  },
  {
    label: 'Tasco - Boyacá',
    value: 'Tasco - Boyacá'
  },
  {
    label: 'Tenza - Boyacá',
    value: 'Tenza - Boyacá'
  },
  {
    label: 'Tibaná - Boyacá',
    value: 'Tibaná - Boyacá'
  },
  {
    label: 'Tinjacá - Boyacá',
    value: 'Tinjacá - Boyacá'
  },
  {
    label: 'Tipacoque - Boyacá',
    value: 'Tipacoque - Boyacá'
  },
  {
    label: 'Toca - Boyacá',
    value: 'Toca - Boyacá'
  },
  {
    label: 'Tópaga - Boyacá',
    value: 'Tópaga - Boyacá'
  },
  {
    label: 'Tota - Boyacá',
    value: 'Tota - Boyacá'
  },
  {
    label: 'Turmequé - Boyacá',
    value: 'Turmequé - Boyacá'
  },
  {
    label: 'Tutazá - Boyacá',
    value: 'Tutazá - Boyacá'
  },
  {
    label: 'Umbita - Boyacá',
    value: 'Umbita - Boyacá'
  },
  {
    label: 'Ventaquemada - Boyacá',
    value: 'Ventaquemada - Boyacá'
  },
  {
    label: 'Viracachá - Boyacá',
    value: 'Viracachá - Boyacá'
  },
  {
    label: 'Zetaquira - Boyacá',
    value: 'Zetaquira - Boyacá'
  },
  {
    label: 'Togüí - Boyacá',
    value: 'Togüí - Boyacá'
  },
  {
    label: 'Villa de Leyva - Boyacá',
    value: 'Villa de Leyva - Boyacá'
  },
  {
    label: 'Paz de Río - Boyacá',
    value: 'Paz de Río - Boyacá'
  },
  {
    label: 'Santa Rosa de Viterbo - Boyacá',
    value: 'Santa Rosa de Viterbo - Boyacá'
  },
  {
    label: 'San Pablo de Borbur - Boyacá',
    value: 'San Pablo de Borbur - Boyacá'
  },
  {
    label: 'San Luis de Gaceno - Boyacá',
    value: 'San Luis de Gaceno - Boyacá'
  },
  {
    label: 'San José de Pare - Boyacá',
    value: 'San José de Pare - Boyacá'
  },
  {
    label: 'San Miguel de Sema - Boyacá',
    value: 'San Miguel de Sema - Boyacá'
  },
  {
    label: 'Tuta - Boyacá',
    value: 'Tuta - Boyacá'
  },
  {
    label: 'Manizales - Caldas',
    value: 'Manizales - Caldas'
  },
  {
    label: 'Aguadas - Caldas',
    value: 'Aguadas - Caldas'
  },
  {
    label: 'Anserma - Caldas',
    value: 'Anserma - Caldas'
  },
  {
    label: 'Aranzazu - Caldas',
    value: 'Aranzazu - Caldas'
  },
  {
    label: 'Belalcázar - Caldas',
    value: 'Belalcázar - Caldas'
  },
  {
    label: 'Chinchiná - Caldas',
    value: 'Chinchiná - Caldas'
  },
  {
    label: 'Filadelfia - Caldas',
    value: 'Filadelfia - Caldas'
  },
  {
    label: 'La Dorada - Caldas',
    value: 'La Dorada - Caldas'
  },
  {
    label: 'La Merced - Caldas',
    value: 'La Merced - Caldas'
  },
  {
    label: 'Manzanares - Caldas',
    value: 'Manzanares - Caldas'
  },
  {
    label: 'Marmato - Caldas',
    value: 'Marmato - Caldas'
  },
  {
    label: 'Marulanda - Caldas',
    value: 'Marulanda - Caldas'
  },
  {
    label: 'Neira - Caldas',
    value: 'Neira - Caldas'
  },
  {
    label: 'Norcasia - Caldas',
    value: 'Norcasia - Caldas'
  },
  {
    label: 'Pácora - Caldas',
    value: 'Pácora - Caldas'
  },
  {
    label: 'Palestina - Caldas',
    value: 'Palestina - Caldas'
  },
  {
    label: 'Pensilvania - Caldas',
    value: 'Pensilvania - Caldas'
  },
  {
    label: 'Riosucio - Caldas',
    value: 'Riosucio - Caldas'
  },
  {
    label: 'Risaralda - Caldas',
    value: 'Risaralda - Caldas'
  },
  {
    label: 'Salamina - Caldas',
    value: 'Salamina - Caldas'
  },
  {
    label: 'Samaná - Caldas',
    value: 'Samaná - Caldas'
  },
  {
    label: 'San José - Caldas',
    value: 'San José - Caldas'
  },
  {
    label: 'Supía - Caldas',
    value: 'Supía - Caldas'
  },
  {
    label: 'Victoria - Caldas',
    value: 'Victoria - Caldas'
  },
  {
    label: 'Villamaría - Caldas',
    value: 'Villamaría - Caldas'
  },
  {
    label: 'Viterbo - Caldas',
    value: 'Viterbo - Caldas'
  },
  {
    label: 'Florencia - Caquetá',
    value: 'Florencia - Caquetá'
  },
  {
    label: 'Albania - Caquetá',
    value: 'Albania - Caquetá'
  },
  {
    label: 'Curillo - Caquetá',
    value: 'Curillo - Caquetá'
  },
  {
    label: 'El Doncello - Caquetá',
    value: 'El Doncello - Caquetá'
  },
  {
    label: 'El Paujil - Caquetá',
    value: 'El Paujil - Caquetá'
  },
  {
    label: 'Morelia - Caquetá',
    value: 'Morelia - Caquetá'
  },
  {
    label: 'Puerto Rico - Caquetá',
    value: 'Puerto Rico - Caquetá'
  },
  {
    label: 'Solano - Caquetá',
    value: 'Solano - Caquetá'
  },
  {
    label: 'Solita - Caquetá',
    value: 'Solita - Caquetá'
  },
  {
    label: 'Valparaíso - Caquetá',
    value: 'Valparaíso - Caquetá'
  },
  {
    label: 'San José del Fragua - Caquetá',
    value: 'San José del Fragua - Caquetá'
  },
  {
    label: 'Belén de Los Andaquies - Caquetá',
    value: 'Belén de Los Andaquies - Caquetá'
  },
  {
    label: 'Cartagena del Chairá - Caquetá',
    value: 'Cartagena del Chairá - Caquetá'
  },
  {
    label: 'Yopal - Casanare',
    value: 'Yopal - Casanare'
  },
  {
    label: 'Aguazul - Casanare',
    value: 'Aguazul - Casanare'
  },
  {
    label: 'Chámeza - Casanare',
    value: 'Chámeza - Casanare'
  },
  {
    label: 'Hato Corozal - Casanare',
    value: 'Hato Corozal - Casanare'
  },
  {
    label: 'La Salina - Casanare',
    value: 'La Salina - Casanare'
  },
  {
    label: 'Monterrey - Casanare',
    value: 'Monterrey - Casanare'
  },
  {
    label: 'Pore - Casanare',
    value: 'Pore - Casanare'
  },
  {
    label: 'Recetor - Casanare',
    value: 'Recetor - Casanare'
  },
  {
    label: 'Sabanalarga - Casanare',
    value: 'Sabanalarga - Casanare'
  },
  {
    label: 'Sácama - Casanare',
    value: 'Sácama - Casanare'
  },
  {
    label: 'Tauramena - Casanare',
    value: 'Tauramena - Casanare'
  },
  {
    label: 'Trinidad - Casanare',
    value: 'Trinidad - Casanare'
  },
  {
    label: 'Villanueva - Casanare',
    value: 'Villanueva - Casanare'
  },
  {
    label: 'San Luis de Gaceno - Casanare',
    value: 'San Luis de Gaceno - Casanare'
  },
  {
    label: 'Paz de Ariporo - Casanare',
    value: 'Paz de Ariporo - Casanare'
  },
  {
    label: 'Popayán - Cauca',
    value: 'Popayán - Cauca'
  },
  {
    label: 'Almaguer - Cauca',
    value: 'Almaguer - Cauca'
  },
  {
    label: 'Argelia - Cauca',
    value: 'Argelia - Cauca'
  },
  {
    label: 'Balboa - Cauca',
    value: 'Balboa - Cauca'
  },
  {
    label: 'Bolívar - Cauca',
    value: 'Bolívar - Cauca'
  },
  {
    label: 'Buenos Aires - Cauca',
    value: 'Buenos Aires - Cauca'
  },
  {
    label: 'Cajibío - Cauca',
    value: 'Cajibío - Cauca'
  },
  {
    label: 'Caldono - Cauca',
    value: 'Caldono - Cauca'
  },
  {
    label: 'Caloto - Cauca',
    value: 'Caloto - Cauca'
  },
  {
    label: 'Corinto - Cauca',
    value: 'Corinto - Cauca'
  },
  {
    label: 'El Tambo - Cauca',
    value: 'El Tambo - Cauca'
  },
  {
    label: 'Florencia - Cauca',
    value: 'Florencia - Cauca'
  },
  {
    label: 'Guachené - Cauca',
    value: 'Guachené - Cauca'
  },
  {
    label: 'Guapi - Cauca',
    value: 'Guapi - Cauca'
  },
  {
    label: 'Inzá - Cauca',
    value: 'Inzá - Cauca'
  },
  {
    label: 'Jambaló - Cauca',
    value: 'Jambaló - Cauca'
  },
  {
    label: 'La Sierra - Cauca',
    value: 'La Sierra - Cauca'
  },
  {
    label: 'La Vega - Cauca',
    value: 'La Vega - Cauca'
  },
  {
    label: 'López - Cauca',
    value: 'López - Cauca'
  },
  {
    label: 'Mercaderes - Cauca',
    value: 'Mercaderes - Cauca'
  },
  {
    label: 'Miranda - Cauca',
    value: 'Miranda - Cauca'
  },
  {
    label: 'Morales - Cauca',
    value: 'Morales - Cauca'
  },
  {
    label: 'Padilla - Cauca',
    value: 'Padilla - Cauca'
  },
  {
    label: 'Patía - Cauca',
    value: 'Patía - Cauca'
  },
  {
    label: 'Piamonte - Cauca',
    value: 'Piamonte - Cauca'
  },
  {
    label: 'Piendamó - Cauca',
    value: 'Piendamó - Cauca'
  },
  {
    label: 'Puerto Tejada - Cauca',
    value: 'Puerto Tejada - Cauca'
  },
  {
    label: 'Puracé - Cauca',
    value: 'Puracé - Cauca'
  },
  {
    label: 'Rosas - Cauca',
    value: 'Rosas - Cauca'
  },
  {
    label: 'Santa Rosa - Cauca',
    value: 'Santa Rosa - Cauca'
  },
  {
    label: 'Silvia - Cauca',
    value: 'Silvia - Cauca'
  },
  {
    label: 'Sotara - Cauca',
    value: 'Sotara - Cauca'
  },
  {
    label: 'Suárez - Cauca',
    value: 'Suárez - Cauca'
  },
  {
    label: 'Sucre - Cauca',
    value: 'Sucre - Cauca'
  },
  {
    label: 'Timbío - Cauca',
    value: 'Timbío - Cauca'
  },
  {
    label: 'Timbiquí - Cauca',
    value: 'Timbiquí - Cauca'
  },
  {
    label: 'Toribio - Cauca',
    value: 'Toribio - Cauca'
  },
  {
    label: 'Totoró - Cauca',
    value: 'Totoró - Cauca'
  },
  {
    label: 'Villa Rica - Cauca',
    value: 'Villa Rica - Cauca'
  },
  {
    label: 'Santander de Quilichao - Cauca',
    value: 'Santander de Quilichao - Cauca'
  },
  {
    label: 'San Sebastián - Cauca',
    value: 'San Sebastián - Cauca'
  },
  {
    label: 'Valledupar - Cesar',
    value: 'Valledupar - Cesar'
  },
  {
    label: 'Aguachica - Cesar',
    value: 'Aguachica - Cesar'
  },
  {
    label: 'Agustín Codazzi - Cesar',
    value: 'Agustín Codazzi - Cesar'
  },
  {
    label: 'Astrea - Cesar',
    value: 'Astrea - Cesar'
  },
  {
    label: 'Becerril - Cesar',
    value: 'Becerril - Cesar'
  },
  {
    label: 'Bosconia - Cesar',
    value: 'Bosconia - Cesar'
  },
  {
    label: 'Chimichagua - Cesar',
    value: 'Chimichagua - Cesar'
  },
  {
    label: 'Chiriguaná - Cesar',
    value: 'Chiriguaná - Cesar'
  },
  {
    label: 'Curumaní - Cesar',
    value: 'Curumaní - Cesar'
  },
  {
    label: 'El Copey - Cesar',
    value: 'El Copey - Cesar'
  },
  {
    label: 'El Paso - Cesar',
    value: 'El Paso - Cesar'
  },
  {
    label: 'Gamarra - Cesar',
    value: 'Gamarra - Cesar'
  },
  {
    label: 'González - Cesar',
    value: 'González - Cesar'
  },
  {
    label: 'La Gloria - Cesar',
    value: 'La Gloria - Cesar'
  },
  {
    label: 'Manaure - Cesar',
    value: 'Manaure - Cesar'
  },
  {
    label: 'Pailitas - Cesar',
    value: 'Pailitas - Cesar'
  },
  {
    label: 'Pelaya - Cesar',
    value: 'Pelaya - Cesar'
  },
  {
    label: 'Pueblo Bello - Cesar',
    value: 'Pueblo Bello - Cesar'
  },
  {
    label: 'La Paz - Cesar',
    value: 'La Paz - Cesar'
  },
  {
    label: 'San Alberto - Cesar',
    value: 'San Alberto - Cesar'
  },
  {
    label: 'San Diego - Cesar',
    value: 'San Diego - Cesar'
  },
  {
    label: 'San Martín - Cesar',
    value: 'San Martín - Cesar'
  },
  {
    label: 'Tamalameque - Cesar',
    value: 'Tamalameque - Cesar'
  },
  {
    label: 'Río de Oro - Cesar',
    value: 'Río de Oro - Cesar'
  },
  {
    label: 'La Jagua de Ibirico - Cesar',
    value: 'La Jagua de Ibirico - Cesar'
  },
  {
    label: 'Istmina - Chocó',
    value: 'Istmina - Chocó'
  },
  {
    label: 'Quibdó - Chocó',
    value: 'Quibdó - Chocó'
  },
  {
    label: 'Acandí - Chocó',
    value: 'Acandí - Chocó'
  },
  {
    label: 'Alto Baudo - Chocó',
    value: 'Alto Baudo - Chocó'
  },
  {
    label: 'Atrato - Chocó',
    value: 'Atrato - Chocó'
  },
  {
    label: 'Bagadó - Chocó',
    value: 'Bagadó - Chocó'
  },
  {
    label: 'Bahía Solano - Chocó',
    value: 'Bahía Solano - Chocó'
  },
  {
    label: 'Bajo Baudó - Chocó',
    value: 'Bajo Baudó - Chocó'
  },
  {
    label: 'Bojaya - Chocó',
    value: 'Bojaya - Chocó'
  },
  {
    label: 'Cértegui - Chocó',
    value: 'Cértegui - Chocó'
  },
  {
    label: 'Condoto - Chocó',
    value: 'Condoto - Chocó'
  },
  {
    label: 'Juradó - Chocó',
    value: 'Juradó - Chocó'
  },
  {
    label: 'Lloró - Chocó',
    value: 'Lloró - Chocó'
  },
  {
    label: 'Medio Atrato - Chocó',
    value: 'Medio Atrato - Chocó'
  },
  {
    label: 'Medio Baudó - Chocó',
    value: 'Medio Baudó - Chocó'
  },
  {
    label: 'Medio San Juan - Chocó',
    value: 'Medio San Juan - Chocó'
  },
  {
    label: 'Nóvita - Chocó',
    value: 'Nóvita - Chocó'
  },
  {
    label: 'Nuquí - Chocó',
    value: 'Nuquí - Chocó'
  },
  {
    label: 'Río Iro - Chocó',
    value: 'Río Iro - Chocó'
  },
  {
    label: 'Río Quito - Chocó',
    value: 'Río Quito - Chocó'
  },
  {
    label: 'Riosucio - Chocó',
    value: 'Riosucio - Chocó'
  },
  {
    label: 'Sipí - Chocó',
    value: 'Sipí - Chocó'
  },
  {
    label: 'Unguía - Chocó',
    value: 'Unguía - Chocó'
  },
  {
    label: 'El Litoral del San Juan - Chocó',
    value: 'El Litoral del San Juan - Chocó'
  },
  {
    label: 'El Cantón del San Pablo - Chocó',
    value: 'El Cantón del San Pablo - Chocó'
  },
  {
    label: 'El Carmen de Atrato - Chocó',
    value: 'El Carmen de Atrato - Chocó'
  },
  {
    label: 'San José del Palmar - Chocó',
    value: 'San José del Palmar - Chocó'
  },
  {
    label: 'Belén de Bajira - Chocó',
    value: 'Belén de Bajira - Chocó'
  },
  {
    label: 'Anapoima - Cundinamarca',
    value: 'Anapoima - Cundinamarca'
  },
  {
    label: 'Arbeláez - Cundinamarca',
    value: 'Arbeláez - Cundinamarca'
  },
  {
    label: 'Beltrán - Cundinamarca',
    value: 'Beltrán - Cundinamarca'
  },
  {
    label: 'Bituima - Cundinamarca',
    value: 'Bituima - Cundinamarca'
  },
  {
    label: 'Bojacá - Cundinamarca',
    value: 'Bojacá - Cundinamarca'
  },
  {
    label: 'Cabrera - Cundinamarca',
    value: 'Cabrera - Cundinamarca'
  },
  {
    label: 'Cachipay - Cundinamarca',
    value: 'Cachipay - Cundinamarca'
  },
  {
    label: 'Cajicá - Cundinamarca',
    value: 'Cajicá - Cundinamarca'
  },
  {
    label: 'Caparrapí - Cundinamarca',
    value: 'Caparrapí - Cundinamarca'
  },
  {
    label: 'Caqueza - Cundinamarca',
    value: 'Caqueza - Cundinamarca'
  },
  {
    label: 'Chaguaní - Cundinamarca',
    value: 'Chaguaní - Cundinamarca'
  },
  {
    label: 'Chipaque - Cundinamarca',
    value: 'Chipaque - Cundinamarca'
  },
  {
    label: 'Choachí - Cundinamarca',
    value: 'Choachí - Cundinamarca'
  },
  {
    label: 'Chocontá - Cundinamarca',
    value: 'Chocontá - Cundinamarca'
  },
  {
    label: 'Cogua - Cundinamarca',
    value: 'Cogua - Cundinamarca'
  },
  {
    label: 'Cota - Cundinamarca',
    value: 'Cota - Cundinamarca'
  },
  {
    label: 'Cucunubá - Cundinamarca',
    value: 'Cucunubá - Cundinamarca'
  },
  {
    label: 'El Colegio - Cundinamarca',
    value: 'El Colegio - Cundinamarca'
  },
  {
    label: 'El Rosal - Cundinamarca',
    value: 'El Rosal - Cundinamarca'
  },
  {
    label: 'Fomeque - Cundinamarca',
    value: 'Fomeque - Cundinamarca'
  },
  {
    label: 'Fosca - Cundinamarca',
    value: 'Fosca - Cundinamarca'
  },
  {
    label: 'Funza - Cundinamarca',
    value: 'Funza - Cundinamarca'
  },
  {
    label: 'Fúquene - Cundinamarca',
    value: 'Fúquene - Cundinamarca'
  },
  {
    label: 'Gachala - Cundinamarca',
    value: 'Gachala - Cundinamarca'
  },
  {
    label: 'Gachancipá - Cundinamarca',
    value: 'Gachancipá - Cundinamarca'
  },
  {
    label: 'Gachetá - Cundinamarca',
    value: 'Gachetá - Cundinamarca'
  },
  {
    label: 'Girardot - Cundinamarca',
    value: 'Girardot - Cundinamarca'
  },
  {
    label: 'Granada - Cundinamarca',
    value: 'Granada - Cundinamarca'
  },
  {
    label: 'Guachetá - Cundinamarca',
    value: 'Guachetá - Cundinamarca'
  },
  {
    label: 'Guaduas - Cundinamarca',
    value: 'Guaduas - Cundinamarca'
  },
  {
    label: 'Guasca - Cundinamarca',
    value: 'Guasca - Cundinamarca'
  },
  {
    label: 'Guataquí - Cundinamarca',
    value: 'Guataquí - Cundinamarca'
  },
  {
    label: 'Guatavita - Cundinamarca',
    value: 'Guatavita - Cundinamarca'
  },
  {
    label: 'Guayabetal - Cundinamarca',
    value: 'Guayabetal - Cundinamarca'
  },
  {
    label: 'Gutiérrez - Cundinamarca',
    value: 'Gutiérrez - Cundinamarca'
  },
  {
    label: 'Jerusalén - Cundinamarca',
    value: 'Jerusalén - Cundinamarca'
  },
  {
    label: 'Junín - Cundinamarca',
    value: 'Junín - Cundinamarca'
  },
  {
    label: 'La Calera - Cundinamarca',
    value: 'La Calera - Cundinamarca'
  },
  {
    label: 'La Mesa - Cundinamarca',
    value: 'La Mesa - Cundinamarca'
  },
  {
    label: 'La Palma - Cundinamarca',
    value: 'La Palma - Cundinamarca'
  },
  {
    label: 'La Peña - Cundinamarca',
    value: 'La Peña - Cundinamarca'
  },
  {
    label: 'La Vega - Cundinamarca',
    value: 'La Vega - Cundinamarca'
  },
  {
    label: 'Lenguazaque - Cundinamarca',
    value: 'Lenguazaque - Cundinamarca'
  },
  {
    label: 'Macheta - Cundinamarca',
    value: 'Macheta - Cundinamarca'
  },
  {
    label: 'Madrid - Cundinamarca',
    value: 'Madrid - Cundinamarca'
  },
  {
    label: 'Manta - Cundinamarca',
    value: 'Manta - Cundinamarca'
  },
  {
    label: 'Medina - Cundinamarca',
    value: 'Medina - Cundinamarca'
  },
  {
    label: 'Mosquera - Cundinamarca',
    value: 'Mosquera - Cundinamarca'
  },
  {
    label: 'Nariño - Cundinamarca',
    value: 'Nariño - Cundinamarca'
  },
  {
    label: 'Nemocón - Cundinamarca',
    value: 'Nemocón - Cundinamarca'
  },
  {
    label: 'Nilo - Cundinamarca',
    value: 'Nilo - Cundinamarca'
  },
  {
    label: 'Nimaima - Cundinamarca',
    value: 'Nimaima - Cundinamarca'
  },
  {
    label: 'Nocaima - Cundinamarca',
    value: 'Nocaima - Cundinamarca'
  },
  {
    label: 'Venecia - Cundinamarca',
    value: 'Venecia - Cundinamarca'
  },
  {
    label: 'Pacho - Cundinamarca',
    value: 'Pacho - Cundinamarca'
  },
  {
    label: 'Paime - Cundinamarca',
    value: 'Paime - Cundinamarca'
  },
  {
    label: 'Pandi - Cundinamarca',
    value: 'Pandi - Cundinamarca'
  },
  {
    label: 'Paratebueno - Cundinamarca',
    value: 'Paratebueno - Cundinamarca'
  },
  {
    label: 'Pasca - Cundinamarca',
    value: 'Pasca - Cundinamarca'
  },
  {
    label: 'Puerto Salgar - Cundinamarca',
    value: 'Puerto Salgar - Cundinamarca'
  },
  {
    label: 'Pulí - Cundinamarca',
    value: 'Pulí - Cundinamarca'
  },
  {
    label: 'Quebradanegra - Cundinamarca',
    value: 'Quebradanegra - Cundinamarca'
  },
  {
    label: 'Quetame - Cundinamarca',
    value: 'Quetame - Cundinamarca'
  },
  {
    label: 'Quipile - Cundinamarca',
    value: 'Quipile - Cundinamarca'
  },
  {
    label: 'Apulo - Cundinamarca',
    value: 'Apulo - Cundinamarca'
  },
  {
    label: 'Ricaurte - Cundinamarca',
    value: 'Ricaurte - Cundinamarca'
  },
  {
    label: 'San Bernardo - Cundinamarca',
    value: 'San Bernardo - Cundinamarca'
  },
  {
    label: 'San Cayetano - Cundinamarca',
    value: 'San Cayetano - Cundinamarca'
  },
  {
    label: 'San Francisco - Cundinamarca',
    value: 'San Francisco - Cundinamarca'
  },
  {
    label: 'Sesquilé - Cundinamarca',
    value: 'Sesquilé - Cundinamarca'
  },
  {
    label: 'Sibaté - Cundinamarca',
    value: 'Sibaté - Cundinamarca'
  },
  {
    label: 'Silvania - Cundinamarca',
    value: 'Silvania - Cundinamarca'
  },
  {
    label: 'Simijaca - Cundinamarca',
    value: 'Simijaca - Cundinamarca'
  },
  {
    label: 'Soacha - Cundinamarca',
    value: 'Soacha - Cundinamarca'
  },
  {
    label: 'Subachoque - Cundinamarca',
    value: 'Subachoque - Cundinamarca'
  },
  {
    label: 'Suesca - Cundinamarca',
    value: 'Suesca - Cundinamarca'
  },
  {
    label: 'Supatá - Cundinamarca',
    value: 'Supatá - Cundinamarca'
  },
  {
    label: 'Susa - Cundinamarca',
    value: 'Susa - Cundinamarca'
  },
  {
    label: 'Sutatausa - Cundinamarca',
    value: 'Sutatausa - Cundinamarca'
  },
  {
    label: 'Tabio - Cundinamarca',
    value: 'Tabio - Cundinamarca'
  },
  {
    label: 'Tausa - Cundinamarca',
    value: 'Tausa - Cundinamarca'
  },
  {
    label: 'Tena - Cundinamarca',
    value: 'Tena - Cundinamarca'
  },
  {
    label: 'Tenjo - Cundinamarca',
    value: 'Tenjo - Cundinamarca'
  },
  {
    label: 'Tibacuy - Cundinamarca',
    value: 'Tibacuy - Cundinamarca'
  },
  {
    label: 'Tibirita - Cundinamarca',
    value: 'Tibirita - Cundinamarca'
  },
  {
    label: 'Tocaima - Cundinamarca',
    value: 'Tocaima - Cundinamarca'
  },
  {
    label: 'Tocancipá - Cundinamarca',
    value: 'Tocancipá - Cundinamarca'
  },
  {
    label: 'Topaipí - Cundinamarca',
    value: 'Topaipí - Cundinamarca'
  },
  {
    label: 'Ubalá - Cundinamarca',
    value: 'Ubalá - Cundinamarca'
  },
  {
    label: 'Ubaque - Cundinamarca',
    value: 'Ubaque - Cundinamarca'
  },
  {
    label: 'Une - Cundinamarca',
    value: 'Une - Cundinamarca'
  },
  {
    label: 'Útica - Cundinamarca',
    value: 'Útica - Cundinamarca'
  },
  {
    label: 'Vianí - Cundinamarca',
    value: 'Vianí - Cundinamarca'
  },
  {
    label: 'Villagómez - Cundinamarca',
    value: 'Villagómez - Cundinamarca'
  },
  {
    label: 'Villapinzón - Cundinamarca',
    value: 'Villapinzón - Cundinamarca'
  },
  {
    label: 'Villeta - Cundinamarca',
    value: 'Villeta - Cundinamarca'
  },
  {
    label: 'Viotá - Cundinamarca',
    value: 'Viotá - Cundinamarca'
  },
  {
    label: 'Zipacón - Cundinamarca',
    value: 'Zipacón - Cundinamarca'
  },
  {
    label: 'San Juan de Río Seco - Cundinamarca',
    value: 'San Juan de Río Seco - Cundinamarca'
  },
  {
    label: 'Villa de San Diego de Ubate - Cundinamarca',
    value: 'Villa de San Diego de Ubate - Cundinamarca'
  },
  {
    label: 'Guayabal de Siquima - Cundinamarca',
    value: 'Guayabal de Siquima - Cundinamarca'
  },
  {
    label: 'San Antonio del Tequendama - Cundinamarca',
    value: 'San Antonio del Tequendama - Cundinamarca'
  },
  {
    label: 'Agua de Dios - Cundinamarca',
    value: 'Agua de Dios - Cundinamarca'
  },
  {
    label: 'Carmen de Carupa - Cundinamarca',
    value: 'Carmen de Carupa - Cundinamarca'
  },
  {
    label: 'Vergara - Cundinamarca',
    value: 'Vergara - Cundinamarca'
  },
  {
    label: 'San Bernardo del Viento - Córdoba',
    value: 'San Bernardo del Viento - Córdoba'
  },
  {
    label: 'Montería - Córdoba',
    value: 'Montería - Córdoba'
  },
  {
    label: 'Ayapel - Córdoba',
    value: 'Ayapel - Córdoba'
  },
  {
    label: 'Buenavista - Córdoba',
    value: 'Buenavista - Córdoba'
  },
  {
    label: 'Canalete - Córdoba',
    value: 'Canalete - Córdoba'
  },
  {
    label: 'Cereté - Córdoba',
    value: 'Cereté - Córdoba'
  },
  {
    label: 'Chimá - Córdoba',
    value: 'Chimá - Córdoba'
  },
  {
    label: 'Chinú - Córdoba',
    value: 'Chinú - Córdoba'
  },
  {
    label: 'Cotorra - Córdoba',
    value: 'Cotorra - Córdoba'
  },
  {
    label: 'Lorica - Córdoba',
    value: 'Lorica - Córdoba'
  },
  {
    label: 'Los Córdobas - Córdoba',
    value: 'Los Córdobas - Córdoba'
  },
  {
    label: 'Momil - Córdoba',
    value: 'Momil - Córdoba'
  },
  {
    label: 'Moñitos - Córdoba',
    value: 'Moñitos - Córdoba'
  },
  {
    label: 'Planeta Rica - Córdoba',
    value: 'Planeta Rica - Córdoba'
  },
  {
    label: 'Pueblo Nuevo - Córdoba',
    value: 'Pueblo Nuevo - Córdoba'
  },
  {
    label: 'Puerto Escondido - Córdoba',
    value: 'Puerto Escondido - Córdoba'
  },
  {
    label: 'Purísima - Córdoba',
    value: 'Purísima - Córdoba'
  },
  {
    label: 'Sahagún - Córdoba',
    value: 'Sahagún - Córdoba'
  },
  {
    label: 'San Andrés Sotavento - Córdoba',
    value: 'San Andrés Sotavento - Córdoba'
  },
  {
    label: 'San Antero - Córdoba',
    value: 'San Antero - Córdoba'
  },
  {
    label: 'San Pelayo - Córdoba',
    value: 'San Pelayo - Córdoba'
  },
  {
    label: 'Tierralta - Córdoba',
    value: 'Tierralta - Córdoba'
  },
  {
    label: 'Tuchín - Córdoba',
    value: 'Tuchín - Córdoba'
  },
  {
    label: 'Valencia - Córdoba',
    value: 'Valencia - Córdoba'
  },
  {
    label: 'San José de Uré - Córdoba',
    value: 'San José de Uré - Córdoba'
  },
  {
    label: 'Ciénaga de Oro - Córdoba',
    value: 'Ciénaga de Oro - Córdoba'
  },
  {
    label: 'San Carlos - Córdoba',
    value: 'San Carlos - Córdoba'
  },
  {
    label: 'Inírida - Guainía',
    value: 'Inírida - Guainía'
  },
  {
    label: 'Barranco Minas - Guainía',
    value: 'Barranco Minas - Guainía'
  },
  {
    label: 'Mapiripana - Guainía',
    value: 'Mapiripana - Guainía'
  },
  {
    label: 'San Felipe - Guainía',
    value: 'San Felipe - Guainía'
  },
  {
    label: 'Puerto Colombia - Guainía',
    value: 'Puerto Colombia - Guainía'
  },
  {
    label: 'La Guadalupe - Guainía',
    value: 'La Guadalupe - Guainía'
  },
  {
    label: 'Cacahual - Guainía',
    value: 'Cacahual - Guainía'
  },
  {
    label: 'Pana Pana - Guainía',
    value: 'Pana Pana - Guainía'
  },
  {
    label: 'Morichal - Guainía',
    value: 'Morichal - Guainía'
  },
  {
    label: 'Calamar - Guaviare',
    value: 'Calamar - Guaviare'
  },
  {
    label: 'San José del Guaviare - Guaviare',
    value: 'San José del Guaviare - Guaviare'
  },
  {
    label: 'Miraflores - Guaviare',
    value: 'Miraflores - Guaviare'
  },
  {
    label: 'El Retorno - Guaviare',
    value: 'El Retorno - Guaviare'
  },
  {
    label: 'Neiva - Huila',
    value: 'Neiva - Huila'
  },
  {
    label: 'Acevedo - Huila',
    value: 'Acevedo - Huila'
  },
  {
    label: 'Agrado - Huila',
    value: 'Agrado - Huila'
  },
  {
    label: 'Aipe - Huila',
    value: 'Aipe - Huila'
  },
  {
    label: 'Algeciras - Huila',
    value: 'Algeciras - Huila'
  },
  {
    label: 'Altamira - Huila',
    value: 'Altamira - Huila'
  },
  {
    label: 'Baraya - Huila',
    value: 'Baraya - Huila'
  },
  {
    label: 'Campoalegre - Huila',
    value: 'Campoalegre - Huila'
  },
  {
    label: 'Colombia - Huila',
    value: 'Colombia - Huila'
  },
  {
    label: 'Elías - Huila',
    value: 'Elías - Huila'
  },
  {
    label: 'Garzón - Huila',
    value: 'Garzón - Huila'
  },
  {
    label: 'Gigante - Huila',
    value: 'Gigante - Huila'
  },
  {
    label: 'Guadalupe - Huila',
    value: 'Guadalupe - Huila'
  },
  {
    label: 'Hobo - Huila',
    value: 'Hobo - Huila'
  },
  {
    label: 'Iquira - Huila',
    value: 'Iquira - Huila'
  },
  {
    label: 'Isnos - Huila',
    value: 'Isnos - Huila'
  },
  {
    label: 'La Argentina - Huila',
    value: 'La Argentina - Huila'
  },
  {
    label: 'La Plata - Huila',
    value: 'La Plata - Huila'
  },
  {
    label: 'Nátaga - Huila',
    value: 'Nátaga - Huila'
  },
  {
    label: 'Oporapa - Huila',
    value: 'Oporapa - Huila'
  },
  {
    label: 'Paicol - Huila',
    value: 'Paicol - Huila'
  },
  {
    label: 'Palermo - Huila',
    value: 'Palermo - Huila'
  },
  {
    label: 'Palestina - Huila',
    value: 'Palestina - Huila'
  },
  {
    label: 'Pital - Huila',
    value: 'Pital - Huila'
  },
  {
    label: 'Pitalito - Huila',
    value: 'Pitalito - Huila'
  },
  {
    label: 'Rivera - Huila',
    value: 'Rivera - Huila'
  },
  {
    label: 'Saladoblanco - Huila',
    value: 'Saladoblanco - Huila'
  },
  {
    label: 'Santa María - Huila',
    value: 'Santa María - Huila'
  },
  {
    label: 'Suaza - Huila',
    value: 'Suaza - Huila'
  },
  {
    label: 'Tarqui - Huila',
    value: 'Tarqui - Huila'
  },
  {
    label: 'Tesalia - Huila',
    value: 'Tesalia - Huila'
  },
  {
    label: 'Tello - Huila',
    value: 'Tello - Huila'
  },
  {
    label: 'Teruel - Huila',
    value: 'Teruel - Huila'
  },
  {
    label: 'Timaná - Huila',
    value: 'Timaná - Huila'
  },
  {
    label: 'Villavieja - Huila',
    value: 'Villavieja - Huila'
  },
  {
    label: 'Yaguará - Huila',
    value: 'Yaguará - Huila'
  },
  {
    label: 'San Agustín - Huila',
    value: 'San Agustín - Huila'
  },
  {
    label: 'Riohacha - La Guajira',
    value: 'Riohacha - La Guajira'
  },
  {
    label: 'Albania - La Guajira',
    value: 'Albania - La Guajira'
  },
  {
    label: 'Barrancas - La Guajira',
    value: 'Barrancas - La Guajira'
  },
  {
    label: 'Dibula - La Guajira',
    value: 'Dibula - La Guajira'
  },
  {
    label: 'Distracción - La Guajira',
    value: 'Distracción - La Guajira'
  },
  {
    label: 'El Molino - La Guajira',
    value: 'El Molino - La Guajira'
  },
  {
    label: 'Fonseca - La Guajira',
    value: 'Fonseca - La Guajira'
  },
  {
    label: 'Hatonuevo - La Guajira',
    value: 'Hatonuevo - La Guajira'
  },
  {
    label: 'Maicao - La Guajira',
    value: 'Maicao - La Guajira'
  },
  {
    label: 'Manaure - La Guajira',
    value: 'Manaure - La Guajira'
  },
  {
    label: 'Uribia - La Guajira',
    value: 'Uribia - La Guajira'
  },
  {
    label: 'Urumita - La Guajira',
    value: 'Urumita - La Guajira'
  },
  {
    label: 'Villanueva - La Guajira',
    value: 'Villanueva - La Guajira'
  },
  {
    label: 'La Jagua del Pilar - La Guajira',
    value: 'La Jagua del Pilar - La Guajira'
  },
  {
    label: 'San Juan del Cesar - La Guajira',
    value: 'San Juan del Cesar - La Guajira'
  },
  {
    label: 'Santa Marta - Magdalena',
    value: 'Santa Marta - Magdalena'
  },
  {
    label: 'Algarrobo - Magdalena',
    value: 'Algarrobo - Magdalena'
  },
  {
    label: 'Aracataca - Magdalena',
    value: 'Aracataca - Magdalena'
  },
  {
    label: 'Ariguaní - Magdalena',
    value: 'Ariguaní - Magdalena'
  },
  {
    label: 'Cerro San Antonio - Magdalena',
    value: 'Cerro San Antonio - Magdalena'
  },
  {
    label: 'Chivolo - Magdalena',
    value: 'Chivolo - Magdalena'
  },
  {
    label: 'Concordia - Magdalena',
    value: 'Concordia - Magdalena'
  },
  {
    label: 'El Banco - Magdalena',
    value: 'El Banco - Magdalena'
  },
  {
    label: 'El Piñon - Magdalena',
    value: 'El Piñon - Magdalena'
  },
  {
    label: 'El Retén - Magdalena',
    value: 'El Retén - Magdalena'
  },
  {
    label: 'Fundación - Magdalena',
    value: 'Fundación - Magdalena'
  },
  {
    label: 'Guamal - Magdalena',
    value: 'Guamal - Magdalena'
  },
  {
    label: 'Nueva Granada - Magdalena',
    value: 'Nueva Granada - Magdalena'
  },
  {
    label: 'Pedraza - Magdalena',
    value: 'Pedraza - Magdalena'
  },
  {
    label: 'Pivijay - Magdalena',
    value: 'Pivijay - Magdalena'
  },
  {
    label: 'Plato - Magdalena',
    value: 'Plato - Magdalena'
  },
  {
    label: 'Remolino - Magdalena',
    value: 'Remolino - Magdalena'
  },
  {
    label: 'Salamina - Magdalena',
    value: 'Salamina - Magdalena'
  },
  {
    label: 'San Zenón - Magdalena',
    value: 'San Zenón - Magdalena'
  },
  {
    label: 'Santa Ana - Magdalena',
    value: 'Santa Ana - Magdalena'
  },
  {
    label: 'Sitionuevo - Magdalena',
    value: 'Sitionuevo - Magdalena'
  },
  {
    label: 'Tenerife - Magdalena',
    value: 'Tenerife - Magdalena'
  },
  {
    label: 'Zapayán - Magdalena',
    value: 'Zapayán - Magdalena'
  },
  {
    label: 'Zona Bananera - Magdalena',
    value: 'Zona Bananera - Magdalena'
  },
  {
    label: 'San Sebastián de Buenavista - Magdalena',
    value: 'San Sebastián de Buenavista - Magdalena'
  },
  {
    label: 'Sabanas de San Angel - Magdalena',
    value: 'Sabanas de San Angel - Magdalena'
  },
  {
    label: 'Pijiño del Carmen - Magdalena',
    value: 'Pijiño del Carmen - Magdalena'
  },
  {
    label: 'Uribe - Meta',
    value: 'Uribe - Meta'
  },
  {
    label: 'Villavicencio - Meta',
    value: 'Villavicencio - Meta'
  },
  {
    label: 'Acacias - Meta',
    value: 'Acacias - Meta'
  },
  {
    label: 'Cabuyaro - Meta',
    value: 'Cabuyaro - Meta'
  },
  {
    label: 'Cubarral - Meta',
    value: 'Cubarral - Meta'
  },
  {
    label: 'Cumaral - Meta',
    value: 'Cumaral - Meta'
  },
  {
    label: 'El Calvario - Meta',
    value: 'El Calvario - Meta'
  },
  {
    label: 'El Castillo - Meta',
    value: 'El Castillo - Meta'
  },
  {
    label: 'El Dorado - Meta',
    value: 'El Dorado - Meta'
  },
  {
    label: 'Granada - Meta',
    value: 'Granada - Meta'
  },
  {
    label: 'Guamal - Meta',
    value: 'Guamal - Meta'
  },
  {
    label: 'Mapiripán - Meta',
    value: 'Mapiripán - Meta'
  },
  {
    label: 'Mesetas - Meta',
    value: 'Mesetas - Meta'
  },
  {
    label: 'La Macarena - Meta',
    value: 'La Macarena - Meta'
  },
  {
    label: 'Lejanías - Meta',
    value: 'Lejanías - Meta'
  },
  {
    label: 'Puerto Concordia - Meta',
    value: 'Puerto Concordia - Meta'
  },
  {
    label: 'Puerto Gaitán - Meta',
    value: 'Puerto Gaitán - Meta'
  },
  {
    label: 'Puerto López - Meta',
    value: 'Puerto López - Meta'
  },
  {
    label: 'Puerto Lleras - Meta',
    value: 'Puerto Lleras - Meta'
  },
  {
    label: 'Puerto Rico - Meta',
    value: 'Puerto Rico - Meta'
  },
  {
    label: 'Restrepo - Meta',
    value: 'Restrepo - Meta'
  },
  {
    label: 'San Juanito - Meta',
    value: 'San Juanito - Meta'
  },
  {
    label: 'San Martín - Meta',
    value: 'San Martín - Meta'
  },
  {
    label: 'Vista Hermosa - Meta',
    value: 'Vista Hermosa - Meta'
  },
  {
    label: 'Barranca de Upía - Meta',
    value: 'Barranca de Upía - Meta'
  },
  {
    label: 'Fuente de Oro - Meta',
    value: 'Fuente de Oro - Meta'
  },
  {
    label: 'San Carlos de Guaroa - Meta',
    value: 'San Carlos de Guaroa - Meta'
  },
  {
    label: 'San Juan de Arama - Meta',
    value: 'San Juan de Arama - Meta'
  },
  {
    label: 'Santacruz - Nariño',
    value: 'Santacruz - Nariño'
  },
  {
    label: 'Pasto - Nariño',
    value: 'Pasto - Nariño'
  },
  {
    label: 'Albán - Nariño',
    value: 'Albán - Nariño'
  },
  {
    label: 'Aldana - Nariño',
    value: 'Aldana - Nariño'
  },
  {
    label: 'Ancuyá - Nariño',
    value: 'Ancuyá - Nariño'
  },
  {
    label: 'Barbacoas - Nariño',
    value: 'Barbacoas - Nariño'
  },
  {
    label: 'Colón - Nariño',
    value: 'Colón - Nariño'
  },
  {
    label: 'Consaca - Nariño',
    value: 'Consaca - Nariño'
  },
  {
    label: 'Contadero - Nariño',
    value: 'Contadero - Nariño'
  },
  {
    label: 'Córdoba - Nariño',
    value: 'Córdoba - Nariño'
  },
  {
    label: 'Cuaspud - Nariño',
    value: 'Cuaspud - Nariño'
  },
  {
    label: 'Cumbal - Nariño',
    value: 'Cumbal - Nariño'
  },
  {
    label: 'Cumbitara - Nariño',
    value: 'Cumbitara - Nariño'
  },
  {
    label: 'El Charco - Nariño',
    value: 'El Charco - Nariño'
  },
  {
    label: 'El Peñol - Nariño',
    value: 'El Peñol - Nariño'
  },
  {
    label: 'El Rosario - Nariño',
    value: 'El Rosario - Nariño'
  },
  {
    label: 'El Tambo - Nariño',
    value: 'El Tambo - Nariño'
  },
  {
    label: 'Funes - Nariño',
    value: 'Funes - Nariño'
  },
  {
    label: 'Guachucal - Nariño',
    value: 'Guachucal - Nariño'
  },
  {
    label: 'Guaitarilla - Nariño',
    value: 'Guaitarilla - Nariño'
  },
  {
    label: 'Gualmatán - Nariño',
    value: 'Gualmatán - Nariño'
  },
  {
    label: 'Iles - Nariño',
    value: 'Iles - Nariño'
  },
  {
    label: 'Imués - Nariño',
    value: 'Imués - Nariño'
  },
  {
    label: 'Ipiales - Nariño',
    value: 'Ipiales - Nariño'
  },
  {
    label: 'La Cruz - Nariño',
    value: 'La Cruz - Nariño'
  },
  {
    label: 'La Florida - Nariño',
    value: 'La Florida - Nariño'
  },
  {
    label: 'La Llanada - Nariño',
    value: 'La Llanada - Nariño'
  },
  {
    label: 'La Tola - Nariño',
    value: 'La Tola - Nariño'
  },
  {
    label: 'La Unión - Nariño',
    value: 'La Unión - Nariño'
  },
  {
    label: 'Leiva - Nariño',
    value: 'Leiva - Nariño'
  },
  {
    label: 'Linares - Nariño',
    value: 'Linares - Nariño'
  },
  {
    label: 'Los Andes - Nariño',
    value: 'Los Andes - Nariño'
  },
  {
    label: 'Magüí - Nariño',
    value: 'Magüí - Nariño'
  },
  {
    label: 'Mallama - Nariño',
    value: 'Mallama - Nariño'
  },
  {
    label: 'Mosquera - Nariño',
    value: 'Mosquera - Nariño'
  },
  {
    label: 'Nariño - Nariño',
    value: 'Nariño - Nariño'
  },
  {
    label: 'Olaya Herrera - Nariño',
    value: 'Olaya Herrera - Nariño'
  },
  {
    label: 'Ospina - Nariño',
    value: 'Ospina - Nariño'
  },
  {
    label: 'Francisco Pizarro - Nariño',
    value: 'Francisco Pizarro - Nariño'
  },
  {
    label: 'Policarpa - Nariño',
    value: 'Policarpa - Nariño'
  },
  {
    label: 'Potosí - Nariño',
    value: 'Potosí - Nariño'
  },
  {
    label: 'Providencia - Nariño',
    value: 'Providencia - Nariño'
  },
  {
    label: 'Puerres - Nariño',
    value: 'Puerres - Nariño'
  },
  {
    label: 'Pupiales - Nariño',
    value: 'Pupiales - Nariño'
  },
  {
    label: 'Ricaurte - Nariño',
    value: 'Ricaurte - Nariño'
  },
  {
    label: 'Roberto Payán - Nariño',
    value: 'Roberto Payán - Nariño'
  },
  {
    label: 'Samaniego - Nariño',
    value: 'Samaniego - Nariño'
  },
  {
    label: 'Sandoná - Nariño',
    value: 'Sandoná - Nariño'
  },
  {
    label: 'San Bernardo - Nariño',
    value: 'San Bernardo - Nariño'
  },
  {
    label: 'San Lorenzo - Nariño',
    value: 'San Lorenzo - Nariño'
  },
  {
    label: 'San Pablo - Nariño',
    value: 'San Pablo - Nariño'
  },
  {
    label: 'Santa Bárbara - Nariño',
    value: 'Santa Bárbara - Nariño'
  },
  {
    label: 'Sapuyes - Nariño',
    value: 'Sapuyes - Nariño'
  },
  {
    label: 'Taminango - Nariño',
    value: 'Taminango - Nariño'
  },
  {
    label: 'Tangua - Nariño',
    value: 'Tangua - Nariño'
  },
  {
    label: 'Túquerres - Nariño',
    value: 'Túquerres - Nariño'
  },
  {
    label: 'Yacuanquer - Nariño',
    value: 'Yacuanquer - Nariño'
  },
  {
    label: 'San Pedro de Cartago - Nariño',
    value: 'San Pedro de Cartago - Nariño'
  },
  {
    label: 'El Tablón de Gómez - Nariño',
    value: 'El Tablón de Gómez - Nariño'
  },
  {
    label: 'Silos - Norte de Santander',
    value: 'Silos - Norte de Santander'
  },
  {
    label: 'Cácota - Norte de Santander',
    value: 'Cácota - Norte de Santander'
  },
  {
    label: 'Toledo - Norte de Santander',
    value: 'Toledo - Norte de Santander'
  },
  {
    label: 'Mutiscua - Norte de Santander',
    value: 'Mutiscua - Norte de Santander'
  },
  {
    label: 'El Zulia - Norte de Santander',
    value: 'El Zulia - Norte de Santander'
  },
  {
    label: 'Salazar - Norte de Santander',
    value: 'Salazar - Norte de Santander'
  },
  {
    label: 'Cucutilla - Norte de Santander',
    value: 'Cucutilla - Norte de Santander'
  },
  {
    label: 'Puerto Santander - Norte de Santander',
    value: 'Puerto Santander - Norte de Santander'
  },
  {
    label: 'Gramalote - Norte de Santander',
    value: 'Gramalote - Norte de Santander'
  },
  {
    label: 'El Tarra - Norte de Santander',
    value: 'El Tarra - Norte de Santander'
  },
  {
    label: 'Teorama - Norte de Santander',
    value: 'Teorama - Norte de Santander'
  },
  {
    label: 'Arboledas - Norte de Santander',
    value: 'Arboledas - Norte de Santander'
  },
  {
    label: 'Mocoa - Putumayo',
    value: 'Mocoa - Putumayo'
  },
  {
    label: 'Colón - Putumayo',
    value: 'Colón - Putumayo'
  },
  {
    label: 'Orito - Putumayo',
    value: 'Orito - Putumayo'
  },
  {
    label: 'Puerto Caicedo - Putumayo',
    value: 'Puerto Caicedo - Putumayo'
  },
  {
    label: 'Puerto Guzmán - Putumayo',
    value: 'Puerto Guzmán - Putumayo'
  },
  {
    label: 'Leguízamo - Putumayo',
    value: 'Leguízamo - Putumayo'
  },
  {
    label: 'Sibundoy - Putumayo',
    value: 'Sibundoy - Putumayo'
  },
  {
    label: 'San Francisco - Putumayo',
    value: 'San Francisco - Putumayo'
  },
  {
    label: 'San Miguel - Putumayo',
    value: 'San Miguel - Putumayo'
  },
  {
    label: 'Santiago - Putumayo',
    value: 'Santiago - Putumayo'
  },
  {
    label: 'Valle de Guamez - Putumayo',
    value: 'Valle de Guamez - Putumayo'
  },
  {
    label: 'Armenia - Quindío',
    value: 'Armenia - Quindío'
  },
  {
    label: 'Buenavista - Quindío',
    value: 'Buenavista - Quindío'
  },
  {
    label: 'Circasia - Quindío',
    value: 'Circasia - Quindío'
  },
  {
    label: 'Córdoba - Quindío',
    value: 'Córdoba - Quindío'
  },
  {
    label: 'Filandia - Quindío',
    value: 'Filandia - Quindío'
  },
  {
    label: 'La Tebaida - Quindío',
    value: 'La Tebaida - Quindío'
  },
  {
    label: 'Montenegro - Quindío',
    value: 'Montenegro - Quindío'
  },
  {
    label: 'Pijao - Quindío',
    value: 'Pijao - Quindío'
  },
  {
    label: 'Quimbaya - Quindío',
    value: 'Quimbaya - Quindío'
  },
  {
    label: 'Salento - Quindío',
    value: 'Salento - Quindío'
  },
  {
    label: 'Pereira - Risaralda',
    value: 'Pereira - Risaralda'
  },
  {
    label: 'Apía - Risaralda',
    value: 'Apía - Risaralda'
  },
  {
    label: 'Balboa - Risaralda',
    value: 'Balboa - Risaralda'
  },
  {
    label: 'Dosquebradas - Risaralda',
    value: 'Dosquebradas - Risaralda'
  },
  {
    label: 'Guática - Risaralda',
    value: 'Guática - Risaralda'
  },
  {
    label: 'La Celia - Risaralda',
    value: 'La Celia - Risaralda'
  },
  {
    label: 'La Virginia - Risaralda',
    value: 'La Virginia - Risaralda'
  },
  {
    label: 'Marsella - Risaralda',
    value: 'Marsella - Risaralda'
  },
  {
    label: 'Mistrató - Risaralda',
    value: 'Mistrató - Risaralda'
  },
  {
    label: 'Pueblo Rico - Risaralda',
    value: 'Pueblo Rico - Risaralda'
  },
  {
    label: 'Quinchía - Risaralda',
    value: 'Quinchía - Risaralda'
  },
  {
    label: 'Santuario - Risaralda',
    value: 'Santuario - Risaralda'
  },
  {
    label: 'Santa Rosa de Cabal - Risaralda',
    value: 'Santa Rosa de Cabal - Risaralda'
  },
  {
    label: 'Belén de Umbría - Risaralda',
    value: 'Belén de Umbría - Risaralda'
  },
  {
    label: 'Puerto Wilches - Santander',
    value: 'Puerto Wilches - Santander'
  },
  {
    label: 'Puerto Parra - Santander',
    value: 'Puerto Parra - Santander'
  },
  {
    label: 'Bucaramanga - Santander',
    value: 'Bucaramanga - Santander'
  },
  {
    label: 'Aguada - Santander',
    value: 'Aguada - Santander'
  },
  {
    label: 'Albania - Santander',
    value: 'Albania - Santander'
  },
  {
    label: 'Aratoca - Santander',
    value: 'Aratoca - Santander'
  },
  {
    label: 'Barbosa - Santander',
    value: 'Barbosa - Santander'
  },
  {
    label: 'Barichara - Santander',
    value: 'Barichara - Santander'
  },
  {
    label: 'Barrancabermeja - Santander',
    value: 'Barrancabermeja - Santander'
  },
  {
    label: 'Betulia - Santander',
    value: 'Betulia - Santander'
  },
  {
    label: 'Bolívar - Santander',
    value: 'Bolívar - Santander'
  },
  {
    label: 'Cabrera - Santander',
    value: 'Cabrera - Santander'
  },
  {
    label: 'California - Santander',
    value: 'California - Santander'
  },
  {
    label: 'Carcasí - Santander',
    value: 'Carcasí - Santander'
  },
  {
    label: 'Cepitá - Santander',
    value: 'Cepitá - Santander'
  },
  {
    label: 'Cerrito - Santander',
    value: 'Cerrito - Santander'
  },
  {
    label: 'Charalá - Santander',
    value: 'Charalá - Santander'
  },
  {
    label: 'Charta - Santander',
    value: 'Charta - Santander'
  },
  {
    label: 'Chipatá - Santander',
    value: 'Chipatá - Santander'
  },
  {
    label: 'Cimitarra - Santander',
    value: 'Cimitarra - Santander'
  },
  {
    label: 'Concepción - Santander',
    value: 'Concepción - Santander'
  },
  {
    label: 'Confines - Santander',
    value: 'Confines - Santander'
  },
  {
    label: 'Contratación - Santander',
    value: 'Contratación - Santander'
  },
  {
    label: 'Coromoro - Santander',
    value: 'Coromoro - Santander'
  },
  {
    label: 'Curití - Santander',
    value: 'Curití - Santander'
  },
  {
    label: 'El Guacamayo - Santander',
    value: 'El Guacamayo - Santander'
  },
  {
    label: 'El Playón - Santander',
    value: 'El Playón - Santander'
  },
  {
    label: 'Encino - Santander',
    value: 'Encino - Santander'
  },
  {
    label: 'Enciso - Santander',
    value: 'Enciso - Santander'
  },
  {
    label: 'Florián - Santander',
    value: 'Florián - Santander'
  },
  {
    label: 'Floridablanca - Santander',
    value: 'Floridablanca - Santander'
  },
  {
    label: 'Galán - Santander',
    value: 'Galán - Santander'
  },
  {
    label: 'Gambita - Santander',
    value: 'Gambita - Santander'
  },
  {
    label: 'Girón - Santander',
    value: 'Girón - Santander'
  },
  {
    label: 'Guaca - Santander',
    value: 'Guaca - Santander'
  },
  {
    label: 'Guadalupe - Santander',
    value: 'Guadalupe - Santander'
  },
  {
    label: 'Guapotá - Santander',
    value: 'Guapotá - Santander'
  },
  {
    label: 'Guavatá - Santander',
    value: 'Guavatá - Santander'
  },
  {
    label: 'Güepsa - Santander',
    value: 'Güepsa - Santander'
  },
  {
    label: 'Jesús María - Santander',
    value: 'Jesús María - Santander'
  },
  {
    label: 'Jordán - Santander',
    value: 'Jordán - Santander'
  },
  {
    label: 'La Belleza - Santander',
    value: 'La Belleza - Santander'
  },
  {
    label: 'Landázuri - Santander',
    value: 'Landázuri - Santander'
  },
  {
    label: 'La Paz - Santander',
    value: 'La Paz - Santander'
  },
  {
    label: 'Lebríja - Santander',
    value: 'Lebríja - Santander'
  },
  {
    label: 'Los Santos - Santander',
    value: 'Los Santos - Santander'
  },
  {
    label: 'Macaravita - Santander',
    value: 'Macaravita - Santander'
  },
  {
    label: 'Málaga - Santander',
    value: 'Málaga - Santander'
  },
  {
    label: 'Matanza - Santander',
    value: 'Matanza - Santander'
  },
  {
    label: 'Mogotes - Santander',
    value: 'Mogotes - Santander'
  },
  {
    label: 'Molagavita - Santander',
    value: 'Molagavita - Santander'
  },
  {
    label: 'Ocamonte - Santander',
    value: 'Ocamonte - Santander'
  },
  {
    label: 'Oiba - Santander',
    value: 'Oiba - Santander'
  },
  {
    label: 'Onzaga - Santander',
    value: 'Onzaga - Santander'
  },
  {
    label: 'Palmar - Santander',
    value: 'Palmar - Santander'
  },
  {
    label: 'Páramo - Santander',
    value: 'Páramo - Santander'
  },
  {
    label: 'Piedecuesta - Santander',
    value: 'Piedecuesta - Santander'
  },
  {
    label: 'Pinchote - Santander',
    value: 'Pinchote - Santander'
  },
  {
    label: 'Puente Nacional - Santander',
    value: 'Puente Nacional - Santander'
  },
  {
    label: 'Rionegro - Santander',
    value: 'Rionegro - Santander'
  },
  {
    label: 'San Andrés - Santander',
    value: 'San Andrés - Santander'
  },
  {
    label: 'San Gil - Santander',
    value: 'San Gil - Santander'
  },
  {
    label: 'San Joaquín - Santander',
    value: 'San Joaquín - Santander'
  },
  {
    label: 'San Miguel - Santander',
    value: 'San Miguel - Santander'
  },
  {
    label: 'Santa Bárbara - Santander',
    value: 'Santa Bárbara - Santander'
  },
  {
    label: 'Simacota - Santander',
    value: 'Simacota - Santander'
  },
  {
    label: 'Socorro - Santander',
    value: 'Socorro - Santander'
  },
  {
    label: 'Suaita - Santander',
    value: 'Suaita - Santander'
  },
  {
    label: 'Sucre - Santander',
    value: 'Sucre - Santander'
  },
  {
    label: 'Suratá - Santander',
    value: 'Suratá - Santander'
  },
  {
    label: 'Tona - Santander',
    value: 'Tona - Santander'
  },
  {
    label: 'Vélez - Santander',
    value: 'Vélez - Santander'
  },
  {
    label: 'Vetas - Santander',
    value: 'Vetas - Santander'
  },
  {
    label: 'Villanueva - Santander',
    value: 'Villanueva - Santander'
  },
  {
    label: 'Zapatoca - Santander',
    value: 'Zapatoca - Santander'
  },
  {
    label: 'Palmas del Socorro - Santander',
    value: 'Palmas del Socorro - Santander'
  },
  {
    label: 'San Vicente de Chucurí - Santander',
    value: 'San Vicente de Chucurí - Santander'
  },
  {
    label: 'San José de Miranda - Santander',
    value: 'San José de Miranda - Santander'
  },
  {
    label: 'Santa Helena del Opón - Santander',
    value: 'Santa Helena del Opón - Santander'
  },
  {
    label: 'Sabana de Torres - Santander',
    value: 'Sabana de Torres - Santander'
  },
  {
    label: 'El Carmen de Chucurí - Santander',
    value: 'El Carmen de Chucurí - Santander'
  },
  {
    label: 'Valle de San José - Santander',
    value: 'Valle de San José - Santander'
  },
  {
    label: 'San Benito - Santander',
    value: 'San Benito - Santander'
  },
  {
    label: 'Hato - Santander',
    value: 'Hato - Santander'
  },
  {
    label: 'Sincelejo - Sucre',
    value: 'Sincelejo - Sucre'
  },
  {
    label: 'Buenavista - Sucre',
    value: 'Buenavista - Sucre'
  },
  {
    label: 'Caimito - Sucre',
    value: 'Caimito - Sucre'
  },
  {
    label: 'Coloso - Sucre',
    value: 'Coloso - Sucre'
  },
  {
    label: 'Coveñas - Sucre',
    value: 'Coveñas - Sucre'
  },
  {
    label: 'Chalán - Sucre',
    value: 'Chalán - Sucre'
  },
  {
    label: 'El Roble - Sucre',
    value: 'El Roble - Sucre'
  },
  {
    label: 'Galeras - Sucre',
    value: 'Galeras - Sucre'
  },
  {
    label: 'Guaranda - Sucre',
    value: 'Guaranda - Sucre'
  },
  {
    label: 'La Unión - Sucre',
    value: 'La Unión - Sucre'
  },
  {
    label: 'Los Palmitos - Sucre',
    value: 'Los Palmitos - Sucre'
  },
  {
    label: 'Majagual - Sucre',
    value: 'Majagual - Sucre'
  },
  {
    label: 'Morroa - Sucre',
    value: 'Morroa - Sucre'
  },
  {
    label: 'Ovejas - Sucre',
    value: 'Ovejas - Sucre'
  },
  {
    label: 'Palmito - Sucre',
    value: 'Palmito - Sucre'
  },
  {
    label: 'San Benito Abad - Sucre',
    value: 'San Benito Abad - Sucre'
  },
  {
    label: 'San Marcos - Sucre',
    value: 'San Marcos - Sucre'
  },
  {
    label: 'San Onofre - Sucre',
    value: 'San Onofre - Sucre'
  },
  {
    label: 'San Pedro - Sucre',
    value: 'San Pedro - Sucre'
  },
  {
    label: 'Sucre - Sucre',
    value: 'Sucre - Sucre'
  },
  {
    label: 'Tolú Viejo - Sucre',
    value: 'Tolú Viejo - Sucre'
  },
  {
    label: 'San Luis de Sincé - Sucre',
    value: 'San Luis de Sincé - Sucre'
  },
  {
    label: 'San Juan de Betulia - Sucre',
    value: 'San Juan de Betulia - Sucre'
  },
  {
    label: 'Santiago de Tolú - Sucre',
    value: 'Santiago de Tolú - Sucre'
  },
  {
    label: 'Alpujarra - Tolima',
    value: 'Alpujarra - Tolima'
  },
  {
    label: 'Alvarado - Tolima',
    value: 'Alvarado - Tolima'
  },
  {
    label: 'Ambalema - Tolima',
    value: 'Ambalema - Tolima'
  },
  {
    label: 'Armero - Tolima',
    value: 'Armero - Tolima'
  },
  {
    label: 'Ataco - Tolima',
    value: 'Ataco - Tolima'
  },
  {
    label: 'Cajamarca - Tolima',
    value: 'Cajamarca - Tolima'
  },
  {
    label: 'Chaparral - Tolima',
    value: 'Chaparral - Tolima'
  },
  {
    label: 'Coello - Tolima',
    value: 'Coello - Tolima'
  },
  {
    label: 'Coyaima - Tolima',
    value: 'Coyaima - Tolima'
  },
  {
    label: 'Cunday - Tolima',
    value: 'Cunday - Tolima'
  },
  {
    label: 'Dolores - Tolima',
    value: 'Dolores - Tolima'
  },
  {
    label: 'Espinal - Tolima',
    value: 'Espinal - Tolima'
  },
  {
    label: 'Falan - Tolima',
    value: 'Falan - Tolima'
  },
  {
    label: 'Flandes - Tolima',
    value: 'Flandes - Tolima'
  },
  {
    label: 'Fresno - Tolima',
    value: 'Fresno - Tolima'
  },
  {
    label: 'Guamo - Tolima',
    value: 'Guamo - Tolima'
  },
  {
    label: 'Herveo - Tolima',
    value: 'Herveo - Tolima'
  },
  {
    label: 'Honda - Tolima',
    value: 'Honda - Tolima'
  },
  {
    label: 'Icononzo - Tolima',
    value: 'Icononzo - Tolima'
  },
  {
    label: 'Mariquita - Tolima',
    value: 'Mariquita - Tolima'
  },
  {
    label: 'Melgar - Tolima',
    value: 'Melgar - Tolima'
  },
  {
    label: 'Murillo - Tolima',
    value: 'Murillo - Tolima'
  },
  {
    label: 'Natagaima - Tolima',
    value: 'Natagaima - Tolima'
  },
  {
    label: 'Ortega - Tolima',
    value: 'Ortega - Tolima'
  },
  {
    label: 'Palocabildo - Tolima',
    value: 'Palocabildo - Tolima'
  },
  {
    label: 'Piedras - Tolima',
    value: 'Piedras - Tolima'
  },
  {
    label: 'Planadas - Tolima',
    value: 'Planadas - Tolima'
  },
  {
    label: 'Prado - Tolima',
    value: 'Prado - Tolima'
  },
  {
    label: 'Purificación - Tolima',
    value: 'Purificación - Tolima'
  },
  {
    label: 'Rio Blanco - Tolima',
    value: 'Rio Blanco - Tolima'
  },
  {
    label: 'Roncesvalles - Tolima',
    value: 'Roncesvalles - Tolima'
  },
  {
    label: 'Rovira - Tolima',
    value: 'Rovira - Tolima'
  },
  {
    label: 'Saldaña - Tolima',
    value: 'Saldaña - Tolima'
  },
  {
    label: 'Santa Isabel - Tolima',
    value: 'Santa Isabel - Tolima'
  },
  {
    label: 'Venadillo - Tolima',
    value: 'Venadillo - Tolima'
  },
  {
    label: 'Villahermosa - Tolima',
    value: 'Villahermosa - Tolima'
  },
  {
    label: 'Villarrica - Tolima',
    value: 'Villarrica - Tolima'
  },
  {
    label: 'Valle de San Juan - Tolima',
    value: 'Valle de San Juan - Tolima'
  },
  {
    label: 'Carmen de Apicala - Tolima',
    value: 'Carmen de Apicala - Tolima'
  },
  {
    label: 'San Luis - Tolima',
    value: 'San Luis - Tolima'
  },
  {
    label: 'San Antonio - Tolima',
    value: 'San Antonio - Tolima'
  },
  {
    label: 'El Dovio - Valle del Cauca',
    value: 'El Dovio - Valle del Cauca'
  },
  {
    label: 'Roldanillo - Valle del Cauca',
    value: 'Roldanillo - Valle del Cauca'
  },
  {
    label: 'Argelia - Valle del Cauca',
    value: 'Argelia - Valle del Cauca'
  },
  {
    label: 'Sevilla - Valle del Cauca',
    value: 'Sevilla - Valle del Cauca'
  },
  {
    label: 'Zarzal - Valle del Cauca',
    value: 'Zarzal - Valle del Cauca'
  },
  {
    label: 'El Cerrito - Valle del Cauca',
    value: 'El Cerrito - Valle del Cauca'
  },
  {
    label: 'Cartago - Valle del Cauca',
    value: 'Cartago - Valle del Cauca'
  },
  {
    label: 'Caicedonia - Valle del Cauca',
    value: 'Caicedonia - Valle del Cauca'
  },
  {
    label: 'El Cairo - Valle del Cauca',
    value: 'El Cairo - Valle del Cauca'
  },
  {
    label: 'La Unión - Valle del Cauca',
    value: 'La Unión - Valle del Cauca'
  },
  {
    label: 'Restrepo - Valle del Cauca',
    value: 'Restrepo - Valle del Cauca'
  },
  {
    label: 'Dagua - Valle del Cauca',
    value: 'Dagua - Valle del Cauca'
  },
  {
    label: 'Guacarí - Valle del Cauca',
    value: 'Guacarí - Valle del Cauca'
  },
  {
    label: 'Mitú - Vaupés',
    value: 'Mitú - Vaupés'
  },
  {
    label: 'Carurú - Vaupés',
    value: 'Carurú - Vaupés'
  },
  {
    label: 'Taraira - Vaupés',
    value: 'Taraira - Vaupés'
  },
  {
    label: 'Papunahua - Vaupés',
    value: 'Papunahua - Vaupés'
  },
  {
    label: 'Yavaraté - Vaupés',
    value: 'Yavaraté - Vaupés'
  },
  {
    label: 'Pacoa - Vaupés',
    value: 'Pacoa - Vaupés'
  },
  {
    label: 'Puerto Carreño - Vichada',
    value: 'Puerto Carreño - Vichada'
  },
  {
    label: 'La Primavera - Vichada',
    value: 'La Primavera - Vichada'
  },
  {
    label: 'Santa Rosalía - Vichada',
    value: 'Santa Rosalía - Vichada'
  },
  {
    label: 'Cumaribo - Vichada',
    value: 'Cumaribo - Vichada'
  }
];
