import React from 'react';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { SlideUpTransition } from '../Transitions/Transitions';
import BaseDialog from './BaseDialog';

const ContractDebtInfoDialog = props => {
  const { open = true, onClose, title = 'Título', content = null } = props;
  const isMobileSize = isWidthDown('sm', props.width);

  return (
    <BaseDialog
      open={open}
      handleClose={onClose}
      title={title}
      actions={() => null}
      content={content}
      fullScreen={isMobileSize}
      TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
    />
  );
};

export default withWidth()(ContractDebtInfoDialog);
