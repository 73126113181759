import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { IconButton } from '@material-ui/core';
import HelpOutline from '@material-ui/icons/HelpOutline';

export default function ViewTitle(props) {
  const classes = useStyles();

  const { text, title = 'Título', contextButton, className } = props;
  return (
    <Typography className={clsx([classes.title, className])}>
      {text || title}{' '}
      {contextButton && (
        <IconButton
          aria-label="help"
          onClick={contextButton}
          className={classes.contextButton}
        >
          <HelpOutline fontSize="small" />
        </IconButton>
      )}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 16,
    fontWeight: 500,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    letterSpacing: theme.spacing(0.08),
    color: theme.palette.text.default
  },
  contextButton: {
    padding: 2,
    marginTop: -2,
    marginLeft: -2,
    color: theme.palette.primary.alternative
  }
}));
