import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import uuid from 'uuid/v4';
import clsx from 'clsx';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Slide from './Slide';
import leftArrow from '../../Assets/icons/left_arrow.svg';
import rightArrow from '../../Assets/icons/right_arrow.svg';

import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation, Pagination, Autoplay]);

export const SlideShow = ({
  slides = [],
  speed = 700,
  autoplay = true,
  delay = 7000,
  loop = true,
  contractId = null,
  className,
  ...rest
}) => {
  const classes = useStyles();

  if (!slides.length) {
    return;
  }

  const shouldAutoplay = autoplay &&
    slides.length !== 1 && { delay, disableOnInteraction: false };

  return (
    <div className={clsx(classes.slideshowContainer, className)}>
      <Swiper
        autoHeight={false}
        navigation={slides.length !== 1}
        allowTouchMove={slides.length !== 1}
        className={classes.swiper}
        speed={speed}
        watchOverflow
        autoplay={shouldAutoplay}
        pagination={slides.length === 1 ? false : { clickable: true }}
        loop={loop}
        {...rest}
      >
        {slides.map(slide => (
          <SwiperSlide className={classes.swiperSlide} key={uuid()}>
            <Slide
              {...slide}
              contractId={contractId}
              className={clsx(slides.length === 1 && classes.swiperSlideNoNav)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  slideshowContainer: {
    borderRadius: theme.spacing(0.75),
    marginBottom: theme.spacing(2),
    overflow: 'hidden',
    fontSize: 16,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14
    }
  },
  swiper: {
    // Needed in IE
    display: 'flex',
    // Prev/Next buttons
    '& .swiper-button-prev, & .swiper-button-next': {
      backgroundColor: theme.palette.common.white,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      height: 25,
      width: 25,
      borderRadius: '50%',
      margin: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      transition: 'background-color 250ms ease',
      '&:hover': {
        backgroundColor: '#dedede'
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },

    '& .swiper-button-prev': {
      left: '1em',
      backgroundImage: `url(${leftArrow})`
    },
    '& .swiper-button-next': {
      right: '1em',
      backgroundImage: `url(${rightArrow})`
    },
    '& .swiper-button-prev::after, & .swiper-button-next::after': {
      display: 'none'
    },
    // Bullets
    '& .swiper-pagination-bullets': {
      bottom: '1em',
      left: '50%',
      transform: 'translateX(-50%)',
      width: 'auto'
    },
    '& .swiper-pagination-bullet': {
      background: theme.palette.common.white,
      opacity: 0.6,
      width: 10,
      height: 10,
      marginRight: `${theme.spacing(1)}px !important`,
      marginLeft: `${theme.spacing(1)}px !important`,
      transition: 'opacity 200ms ease',
      '&:hover': {
        opacity: 0.8
      }
    },
    '& .swiper-pagination-bullet-active': {
      opacity: 1,
      '&:hover': {
        opacity: '1 !important'
      }
    }
  },
  swiperSlide: {
    height: 'unset'
  },
  swiperSlideNoNav: {
    padding: '2em'
  }
}));

export default memo(SlideShow);
