import React from 'react';
import { portal, Portal } from '../../../../../Configs/general';

import MultiplePaymentGDC from './MultiplePayment.GDC';
import MultiplePaymentGDG from './MultiplePayment.GDG';

const MultiplePayment = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <MultiplePaymentGDC {...props} />;
    case Portal.Gasguajira:
      return <MultiplePaymentGDG {...props} />;
    case Portal.Efigas:
      return <MultiplePaymentGDC {...props} />;
    case Portal.TripleA:
      return <MultiplePaymentGDG {...props} />;
    default:
      return <></>;
  }
};

export default MultiplePayment;
