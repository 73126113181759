import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const Sidebar = ({ children, title = 'Título' }) => {
  const classes = useStyles();

  return (
    <div className={classes.sidebar}>
      <Typography className={classes.title}>{title}</Typography>
      {children}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  sidebar: {
    backgroundColor: theme.palette.common.white,
    borderRadius: `${theme.custom.borderRadius}px 0px 0px ${theme.custom.borderRadius}px`,
    boxShadow: '0px 0px 6px #0000001A',
    display: 'flex',
    flexDirection: 'column',
    height: '70vh',
    overflowY: 'hidden',
    position: 'fixed',
    right: 0,
    top: '48%',
    transform: 'translateY(-50%)',
    width: 240,
    zIndex: 5
  },
  title: {
    color: theme.palette.common.black,
    padding: theme.spacing(3, 2, 3, 2),
    fontWeight: 600,
    fontSize: 14
  }
}));

export default Sidebar;
