import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';

import FlatButton from './FlatButton';

const ButtonWithIcon = props => {
  const classes = useStyles();
  const {
    id,
    children,
    className,
    textClass,
    buttonText,
    badgeVariant,
    badgeColor = 'primary',
    hideBadge = true,
    swap,
    ...rest
  } = props;

  return (
    <FlatButton
      id={id}
      color="primary"
      className={clsx(classes.flatButton, className)}
      {...rest}
    >
      <Typography
        className={clsx(classes.text, textClass, {
          [classes.swapText]: swap
        })}
      >
        {buttonText}
      </Typography>
      <Badge
        classes={{ colorPrimary: clsx(classes.badge) }}
        invisible={hideBadge}
        variant={badgeVariant}
        color={badgeColor}
      >
        {children}
      </Badge>
    </FlatButton>
  );
};

const useStyles = makeStyles(theme => ({
  flatButton: {
    display: 'flex',
    color: theme.palette.common.black
  },
  text: {
    fontSize: 12,
    fontWeight: 500,
    margin: theme.spacing(0, 1, 0, 0)
  },
  swapText: {
    order: 2,
    margin: theme.spacing(0, 0, 0, 1)
  },
  badge: {
    backgroundColor: '#FFCE65'
  }
}));
export default ButtonWithIcon;
