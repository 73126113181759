import { makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';

import FooterStepper from '../../Components/Stepper/FooterStepper';
import Stepper from '../../Components/Stepper/Stepper';

import { Step, StepperProvider } from '../../Contexts/StepperContext';
import { UserContext } from '../../Contexts/UserContext';
import { AlertsDispatchContext } from '../../Contexts/AlertsContext';

import {
  FORM_ID,
  SUBROGATION_REQUEST_STEPS,
  SubrogationRequestStep
} from './SubrogationRequest_enums';

import PersonalDataStep from './Steps/PersonalDataStep';
import ContractStep from './Steps/ContractStep';

import clsx from 'clsx';
import _get from 'lodash/get';
import { history } from '../../Routes/history';
import SignDocumentStep from './Steps/SignDocumentStep';

const SubrogationRequest = () => {
  const classes = useStyles();

  // * CONTEXT
  const currentUser = useContext(UserContext);
  const setAlert = useContext(AlertsDispatchContext);

  const authToken = _get(currentUser, 'token');

  // * STATE HOOKS
  const [canSubmit, setCanSubmit] = useState(false);
  const [nextButtonText, setNextButtonText] = useState('Siguiente');
  const [goBack, setGoBack] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [disableBack, setDisableBack] = useState(false);
  const [warningMobile, setWarningMobile] = useState(false);

  useEffect(() => {
    if (!authToken) {
      history.replace('/');
      return;
    }
  });

  return (
    <div
      className={clsx({
        [classes.root]: currentStep !== SubrogationRequestStep.SignDocuments,
        [classes.paddingContent]:
          currentStep === SubrogationRequestStep.SignDocuments
      })}
    >
      <div className={clsx(classes.contentContainer, classes.centerContent)}>
        <div className={clsx(classes.stepperContainer, classes.centerContent)}>
          <Stepper
            steps={SUBROGATION_REQUEST_STEPS}
            currentStep={currentStep}
          />
        </div>
        <StepperProvider>
          <Step>
            <ContractStep
              formId={FORM_ID}
              setCanSubmit={setCanSubmit}
              setGoBack={setGoBack}
              setCurrentStep={setCurrentStep}
              setNextButtonText={setNextButtonText}
              setDisableBack={setDisableBack}
              setAlert={setAlert}
              authToken={authToken}
            />
          </Step>
          <Step>
            <PersonalDataStep
              formId={FORM_ID}
              setCanSubmit={setCanSubmit}
              setGoBack={setGoBack}
              setCurrentStep={setCurrentStep}
              setNextButtonText={setNextButtonText}
              setDisableBack={setDisableBack}
              setAlert={setAlert}
            />
          </Step>
          <Step>
            <SignDocumentStep
              formId={FORM_ID}
              setCanSubmit={setCanSubmit}
              setGoBack={setGoBack}
              setCurrentStep={setCurrentStep}
              setNextButtonText={setNextButtonText}
              setDisableBack={setDisableBack}
              setAlert={setAlert}
              authToken={authToken}
              setWarningMobile={setWarningMobile}
            />
          </Step>
        </StepperProvider>
      </div>
      <FooterStepper
        targetForm={FORM_ID}
        disabledNext={!canSubmit}
        disabledBack={disableBack}
        goBack={goBack}
        nextButtonText={nextButtonText}
        warning={
          warningMobile && currentStep === SubrogationRequestStep.SignDocuments
        }
        warningMessage={'Para completar tu solicitud, da clic en'}
        footerStyle={clsx({
          [classes.footer]:
            warningMobile &&
            currentStep === SubrogationRequestStep.SignDocuments
        })}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: `calc(${
      theme.custom.footerHeight.stepper
    }px + ${theme.spacing(5)}px)`
  },
  paddingContent: {
    paddingBottom: theme.custom.footerHeight.stepper
  },
  stepperContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(3)
    }
  },
  centerContent: {
    margin: '0 auto'
  },
  footer: {
    height: 75
  },
  contentContainer: {
    maxWidth: 960,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      padding: theme.spacing(2)
    }
  }
}));

export default SubrogationRequest;
