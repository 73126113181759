import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef
} from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import TextInput from '../../../Components/Inputs/TextInput';
import ControlledSelectInput from '../../../Components/Controlled/ControlledSelect';
import QuestionMark from '../../../Components/Adornments/QuestionMark';
import SimpleDivider from '../../../Components/Dividers/SimpleDivider';
import Recaptcha from '../../../Components/Captcha/Recaptcha';
import ModalProgress from '../../../Components/Progress/Modal/ModalProgress';
import SweetAlert from '../../../Components/Alerts/SweetAlert';
import InvoiceHelpDialog from '../../../Components/Dialogs/InvoiceHelpDialog/InvoiceHelpDialog';

import {
  StepperDataContext,
  StepperDataDispatchContext,
  SetCurrentStepIndexContext
} from '../../../Contexts/StepperContext';
import { UserContext } from '../../../Contexts/UserContext';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';

import { GetSingleContractAPI } from '../../../API/Contracts/ContractsAPI';
import { SubmitGanaLocoAPI } from '../../../API/Campaigns/GanaLoco/GanaLocoAPI';
import { BrillaProductSublineAPI } from '../../../API/Brilla/BrillaAPI';
import { extractErrorMessage } from '../../../Utils/Errors/Errors';

import {
  COLOMBIAN_DEPARTMENTS,
  COLOMBIAN_CITIES
} from '../fixes/location_enums';
import { GENDER_LABEL_LIST, STREET_LABEL_LIST } from '../fixes/ganaLoco_enums';
import { HELP_TEXT } from '../../../Utils/enums';
import { secondStepParser, brillaProductsParser } from '../helpers/helpers';
import { secondStepSchema } from '../fixes/schemas';
import { config } from '../../../Configs';
import { Company } from '../../../Configs/general';
import { InvoiceHelpType } from '../../../Enums/invoices';

const SecondStep = props => {
  const classes = useStyles();

  const {
    formId,
    setGoBack,
    setTopBarProgress,
    setCanSubmit,
    setNextButtonText,
    setCurrentStep: setVisualCurrentStep
  } = props;

  const [captcha, setCaptcha] = useState(null);
  const [loading, setLoading] = useState(false);
  const [validContractAlert, setValidContractAlert] = useState(false);
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);

  const setCurrentStep = useContext(SetCurrentStepIndexContext);
  const setData = useContext(StepperDataDispatchContext);
  const stepperData = useContext(StepperDataContext);
  const setAlert = useContext(AlertsDispatchContext);
  const currentUser = useContext(UserContext);

  const authToken = currentUser ? currentUser.token : null;

  const { register, watch, getValues, errors, control, handleSubmit } = useForm(
    {
      validationSchema: secondStepSchema,
      submitFocusError: true,
      defaultValues: {
        birthDate: '',
        department: 0,
        city: 0,
        genre: 0,
        contractId: '',
        mainStreet: 0,
        secondaryStreet: 0,
        ...stepperData
      }
    }
  );

  const watchForm = watch(['department', 'contractId']);

  const recaptchaRef = useRef(null);
  const inputRef = useRef(null);

  const onCaptchaResolved = useCallback(
    token => {
      setCaptcha(token);
    },
    [setCaptcha]
  );

  const onCaptchaExpired = useCallback(() => {
    setCaptcha(null);
  }, [setCaptcha]);

  const onCaptchaLoaded = useCallback(() => {
    setTimeout(() => {
      setLoadingCaptcha(false);
    }, 1000);
  }, [setLoadingCaptcha]);

  const onHelpClick = useCallback(() => {
    setHelpDialogOpen(true);
  }, []);

  const onBackward = useCallback(() => {
    setData(data => ({ ...data, ...getValues() }));
    setCurrentStep(currentStep => currentStep - 1);
    setVisualCurrentStep(currentStep => currentStep - 1);
    setTopBarProgress(25);
  }, [
    getValues,
    setData,
    setCurrentStep,
    setTopBarProgress,
    setVisualCurrentStep
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setGoBack({
      action: onBackward
    });
    setTopBarProgress(50);
  }, [setGoBack, onBackward, setTopBarProgress]);

  useEffect(() => {
    if (
      stepperData.validContract &&
      watchForm.contractId === stepperData.contractId
    ) {
      setValidContractAlert(true);
      return;
    }
    setValidContractAlert(false);
  }, [watchForm.contractId, stepperData.validContract, stepperData.contractId]);

  useEffect(() => {
    setCanSubmit(
      !loading &&
        !loadingCaptcha &&
        Boolean(captcha && captcha !== 'disable-captcha-validation')
    );
  }, [loading, loadingCaptcha, setCanSubmit, captcha]);

  const onSuccessUserData = useCallback(
    async formValues => {
      setLoading(true);
      const response = await SubmitGanaLocoAPI(
        {
          step: 2,
          data: {
            identification: stepperData.identification,
            ...secondStepParser(formValues)
          }
        },
        authToken,
        captcha
      );

      if (!response.success) {
        setLoading(false);
        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
        return;
      }

      const productsResp = await BrillaProductSublineAPI(null, captcha);

      if (!productsResp.success) {
        setLoading(false);
        setAlert({
          type: 'error',
          message: extractErrorMessage(productsResp).message
        });
        return;
      }

      setData(oldData => ({
        ...oldData,
        ...formValues,
        birthDate: moment(formValues.birthDate).format('YYYY-MM-DD'),
        brillaProducts: brillaProductsParser(productsResp.data.data)
      }));
      setLoading(false);
      setNextButtonText('Finalizar');
      setCurrentStep(step => step + 1);
      setVisualCurrentStep(step => step + 1);
    },
    [
      setData,
      setCurrentStep,
      captcha,
      setAlert,
      setNextButtonText,
      setVisualCurrentStep,
      stepperData.identification,
      authToken
    ]
  );

  const verifyUserData = useCallback(
    async values => {
      const { contractId } = secondStepParser(values);

      const contractHasChanged = stepperData.contractId !== contractId;

      if (contractId && contractHasChanged) {
        setData(oldData => ({
          ...oldData,
          validContract: false
        }));
        setLoading(true);

        const response = await GetSingleContractAPI(
          contractId,
          authToken,
          captcha
        );

        if (!response.success) {
          setLoading(false);
          setAlert({
            type: 'error',
            message: extractErrorMessage(response).message
          });
          return;
        }
        setData(oldData => ({
          ...oldData,
          validContract: true
        }));
        setLoading(false);
      }

      return onSuccessUserData(values);
    },
    [
      setAlert,
      stepperData.contractId,
      captcha,
      onSuccessUserData,
      setData,
      authToken
    ]
  );

  const onForward = data => {
    verifyUserData(data);
  };

  return (
    <>
      <InvoiceHelpDialog
        open={helpDialogOpen}
        type={InvoiceHelpType.Contract}
        onClose={() => setHelpDialogOpen(false)}
        title={HELP_TEXT.contract}
      />
      {loading && <ModalProgress message="Consultando" />}
      <Box className={classes.shrinkedBox}>
        <form id={formId} onSubmit={handleSubmit(onForward)}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} sm={6} className={classes.pairGridItem}>
              <TextInput
                className={classes.normalizedInputField}
                label="Fecha de nacimiento*"
                inputProps={{
                  maxLength: 20,
                  max: moment()
                    .utcOffset(-5)
                    .format('YYYY-MM-DD')
                }}
                fullWidth
                inputRef={register}
                type="date"
                name="birthDate"
                helperText={errors.birthDate && errors.birthDate.message}
                error={Boolean(errors.birthDate)}
                InputLabelProps={{ shrink: true }}
                margin="none"
                InputPropsClasses={{
                  root: classes.normalizedInputField
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.pairGridItem}>
              <ControlledSelectInput
                className={classes.normalizedInputField}
                label="Género*"
                name="genre"
                control={control}
                options={GENDER_LABEL_LIST}
                fullWidth
                InputPropsClasses={{
                  root: classes.normalizedInputField
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText
                  }
                }}
                error={Boolean(errors.genre)}
                helperText={errors.genre && errors.genre.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.pairGridItem}>
              <ControlledSelectInput
                className={classes.normalizedInputField}
                label="Departamento*"
                name="department"
                control={control}
                options={COLOMBIAN_DEPARTMENTS}
                fullWidth
                InputPropsClasses={{
                  root: classes.normalizedInputField
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText
                  }
                }}
                error={Boolean(errors.department)}
                helperText={errors.department && errors.department.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.pairGridItem}>
              <ControlledSelectInput
                className={classes.normalizedInputField}
                label="Ciudad*"
                name="city"
                menuListClass={classes.menuListClass}
                control={control}
                options={
                  watchForm.department === 0 || watchForm.department
                    ? COLOMBIAN_CITIES[watchForm.department]
                    : []
                }
                fullWidth
                InputPropsClasses={{
                  root: classes.normalizedInputField
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText
                  }
                }}
                error={Boolean(errors.city)}
                helperText={errors.city && errors.city.message}
              />
            </Grid>
          </Grid>
          <Box>
            <Typography
              variant="h6"
              component="h6"
              className={classes.addressTitle}
            >
              Dirección de residencia
            </Typography>
          </Box>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} sm={3} className={classes.addressItem}>
              <ControlledSelectInput
                className={classes.inputRoot}
                label="Vía principal*"
                name="mainStreet"
                control={control}
                options={STREET_LABEL_LIST}
                fullWidth
                InputPropsClasses={{
                  root: classes.normalizedInputField
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText
                  }
                }}
                error={Boolean(errors.mainStreet)}
                helperText={errors.mainStreet && errors.mainStreet.message}
              />
            </Grid>
            <Grid item xs={12} sm={2} className={classes.addressItem}>
              <TextInput
                className={classes.inputRoot}
                label="No.*"
                fullWidth
                inputProps={{ maxLength: 20 }}
                InputPropsClasses={{
                  root: classes.normalizedInputField
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText
                  }
                }}
                inputRef={register}
                name="mainStreetNumber"
                error={Boolean(errors.mainStreetNumber)}
                helperText={
                  errors.mainStreetNumber && errors.mainStreetNumber.message
                }
              />
            </Grid>
            <Grid item xs={12} sm={3} className={classes.addressItem}>
              <ControlledSelectInput
                className={classes.inputRoot}
                label="Vía secundaria*"
                name="secondaryStreet"
                control={control}
                options={STREET_LABEL_LIST}
                fullWidth
                InputPropsClasses={{
                  root: classes.normalizedInputField
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText
                  }
                }}
                error={Boolean(errors.secondaryStreet)}
                helperText={
                  errors.secondaryStreet && errors.secondaryStreet.message
                }
              />
            </Grid>
            <Grid item xs={12} sm={2} className={classes.addressItem}>
              <TextInput
                className={classes.inputRoot}
                label="No.*"
                fullWidth
                inputProps={{ maxLength: 20 }}
                InputPropsClasses={{
                  root: classes.normalizedInputField
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText
                  }
                }}
                inputRef={register}
                name="secondaryStreetNumber"
                helperText={
                  errors.secondaryStreetNumber &&
                  errors.secondaryStreetNumber.message
                }
                error={Boolean(errors.secondaryStreetNumber)}
              />
            </Grid>
            <Grid item xs={12} sm={2} className={classes.addressItem}>
              <TextInput
                className={classes.inputRoot}
                label="No.Casa*"
                fullWidth
                inputProps={{ maxLength: 20 }}
                InputPropsClasses={{
                  root: classes.normalizedInputField
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText
                  }
                }}
                inputRef={register}
                name="houseNumber"
                helperText={errors.houseNumber && errors.houseNumber.message}
                error={Boolean(errors.houseNumber)}
              />
            </Grid>
            <Grid item xs={12} sm={12} className={classes.addressItem}>
              <TextInput
                className={classes.inputRoot}
                label="Info adicional (Torre, Apto)"
                fullWidth
                inputProps={{ maxLength: 100 }}
                InputPropsClasses={{
                  root: classes.normalizedInputField
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText
                  }
                }}
                inputRef={register}
                name="additionalInformation"
                helperText={
                  errors.additionalInformation &&
                  errors.additionalInformation.message
                }
                error={Boolean(errors.additionalInformation)}
              />
            </Grid>
          </Grid>
          <SimpleDivider className={classes.simpleDivider} />
          <Grid container className={classes.lastGridContainer}>
            <Grid item xs={12} sm={6} className={classes.thirdGridItem}>
              <TextInput
                className={classes.normalizedInputField}
                InputPropsClasses={{
                  root: classes.normalizedInputField
                }}
                id="ContractStep_input_contractId"
                label={`Número de ${Company.contractConjugation.regular.singular.main}`}
                fullWidth
                InputProps={{
                  endAdornment: <QuestionMark onClick={onHelpClick} />,
                  inputProps: {
                    inputMode: 'numeric'
                  }
                }}
                inputRef={register}
                name="contractId"
                error={Boolean(errors.contractId)}
                helperText={errors.contractId && errors.contractId.message}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              ref={inputRef}
              className={classes.thirdGridItem}
            >
              <Recaptcha
                captchaRef={recaptchaRef}
                referenceEl={inputRef}
                heightScale={0.75}
                locale={'es'}
                sitekey={config.recaptcha.siteKey}
                onResolved={onCaptchaResolved}
                onExpired={onCaptchaExpired}
                onLoaded={onCaptchaLoaded}
              />
            </Grid>
            {validContractAlert && (
              <Grid item xs={12} sm={12} className={classes.sweetAlertGridItem}>
                <SweetAlert
                  type="success"
                  iconSize={24}
                  classes={{
                    root: classes.sweetAlert,
                    message: classes.sweetAlertText
                  }}
                  message={`Los datos de tu número de ${Company.contractConjugation.regular.singular.main} han sido validados correctamente.`}
                />
              </Grid>
            )}
          </Grid>
        </form>
      </Box>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  shrinkedBox: {
    width: '88.89%',
    margin: '0 auto'
  },
  gridContainer: {
    width: '100%',
    margin: 0,
    justifyContent: 'space-between'
  },
  lastGridContainer: {
    width: '100%',
    margin: 0,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  pairGridItem: {
    margin: theme.spacing(0, 0, 3.75, 0),
    [theme.breakpoints.up('sm')]: {
      '&:nth-child(odd)': {
        paddingRight: 17
      },
      '&:nth-child(even)': {
        paddingLeft: 17
      }
    }
  },
  addressItem: {
    marginBottom: 30,
    [theme.breakpoints.up('sm')]: {
      '&:nth-child(even)': {
        padding: theme.spacing(0, 1)
      },
      '&:last-child': {
        padding: 0
      }
    }
  },
  thirdGridItem: {
    margin: theme.spacing(0, 0, 3.75, 0),
    '&:last-child': {
      margin: theme.spacing(0, 0, 10, 0)
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.spacing(30),
      margin: theme.spacing(0, 0, 5, 0),
      '&:last-child': {
        margin: theme.spacing(0, 0, 5, 0)
      }
    }
  },
  normalizedInputField: {
    margin: 0,
    minHeight: 50,
    maxHeight: 50
  },
  inputRoot: {
    margin: 0
  },
  addressTitle: {
    color: theme.palette.color.default,
    margin: theme.spacing(0, 0, 3.75, 0),
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
      fontWeight: 600
    }
  },
  simpleDivider: {
    margin: theme.spacing(0, 0, 3.75, 0)
  },
  sweetAlertGridItem: {
    margin: theme.spacing(0, 0, 10, 0),
    [theme.breakpoints.up('sm')]: {
      height: theme.spacing(7),
      margin: theme.spacing(0, 0, 3.75, 0)
    }
  },
  sweetAlert: {
    opacity: 1,
    color: theme.palette.common.black,
    height: theme.spacing(7)
  },
  sweetAlertText: {
    [theme.breakpoints.up('sm')]: {
      fontSize: 12
    }
  },
  helperText: {
    fontSize: 10,
    margin: 0,
    padding: 0,
    textAlign: 'right'
  },
  menuListClass: {
    [theme.breakpoints.up('sm')]: {
      height: 225
    }
  }
}));

export default SecondStep;
