import _get from 'lodash/get';

export function fullName(entity, pathFirstName, pathLastName, lowerCase) {
  if (!entity) {
    return 'Anónimo';
  }
  const first = `${_get(entity, pathFirstName, '') || ''}`;
  const second = `${_get(entity, pathLastName, '') || ''}`;
  const name = `${first} ${second}`;
  if (lowerCase) {
    return name.toLowerCase();
  }
  return name;
}

export function firstNameAndLastName(
  entity,
  pathFirstName,
  pathLastName,
  lowerCase
) {
  if (!entity) {
    return 'Anónimo';
  }
  const first = _get(entity, pathFirstName, '') || '';
  const second = _get(entity, pathLastName, '') || '';
  const name = `${first.split(' ')[0]} ${second.split(' ')[0]}`;
  if (lowerCase) {
    return name.toLowerCase();
  }
  return name;
}

export const capitalizeFirstLetter = name => {
  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
};

export const joinNames = (names, separator = ' ', lastSeparator = null) => {
  const cleanNames = (names || []).filter(Boolean);
  const namesLength = cleanNames.length;

  const lastSeparatorToken = lastSeparator || separator;

  if (namesLength <= 2) {
    return cleanNames.join(lastSeparatorToken);
  }

  const firstNames = cleanNames.slice(0, namesLength - 1).join(separator);
  const lastOneName = cleanNames[namesLength - 1];

  return [firstNames, lastOneName].join(lastSeparatorToken);
};
