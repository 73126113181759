import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Routes from '../../Routes/Routes';
import Header from './Header/Header';
import WebFooter from './Footer/WebFooter/WebFooter';
import {
  AlertsContext,
  AlertsDispatchContext
} from '../../Contexts/AlertsContext';
import { FooterContext } from '../../Contexts/FooterContext';
import ModalAlert from '../../Components/Alerts/Modals/ModalAlert';
import CircularMenu from '../../Components/Menu/CircularMenu/CircularMenu';
import WhatsAppChatDialog from '../../Components/Dialogs/WhatsAppChatDialog';
import Toast from '../../Components/Alerts/Toast';

const Layout = () => {
  // * CONTEXTS
  const alert = useContext(AlertsContext);
  const setAlert = useContext(AlertsDispatchContext);

  const footerVisible = useContext(FooterContext);
  const [openDialog, setOpenDialog] = useState(false);

  // * OTHER HOOKS
  const classes = useStyles();

  const alertCloseHandler = () => {
    if (alert && alert.onClose) {
      alert.onClose();
    }

    setAlert(null);
  };

  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.appContainer}>
        {alert && !alert.toast && (
          <ModalAlert
            type={alert.type}
            message={alert.message}
            onClose={alertCloseHandler}
          />
        )}
        {alert && alert.toast && (
          <Toast
            type={alert.type}
            message={alert.message}
            onClose={alertCloseHandler}
            position={alert.position}
            timeout={alert.timeout}
            iconSize={alert.iconSize}
          />
        )}
        <Routes />
        {openDialog && (
          <WhatsAppChatDialog open={openDialog} onClose={setOpenDialog} />
        )}
      </div>
      <CircularMenu
        setDialog={setOpenDialog}
        openChatDialog={openDialog}
        footerVisible={footerVisible}
      />
      {footerVisible && <WebFooter />}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  appContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  logoButton: {
    cursor: 'pointer'
  },
  image: {
    marginTop: theme.spacing(0.5),
    height: '42px',
    width: '180px'
  },
  imageFlame: {
    height: '42px',
    width: '35px'
  },
  appBar: {
    boxShadow: '0 2px 2px -1px rgba(0,0,0,0.2)'
  }
}));

export default Layout;
