import React from 'react';
import { portal, Portal } from '../../../../Configs/general';

import TransactionGDC from './Transaction.GDC';
import TransactionGDG from './Transaction.GDG';
import TransactionEFG from './Transaction.EFG';

const Transaction = props => {
  switch (portal) {
    case Portal.Gascaribe:
    case Portal.TripleA:
      return <TransactionGDC {...props} />;
    case Portal.Gasguajira:
      return <TransactionGDG {...props} />;
    case Portal.Efigas:
      return <TransactionEFG {...props} />;
    default:
      return <></>;
  }
};

export default Transaction;
