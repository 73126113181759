import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import uuid from 'uuid/v4';
import clsx from 'clsx';

const ImageButton = props => {
  const classes = useStyles();
  const {
    title,
    icon: IconComponent,
    selected,
    size,
    largeText = false,
    className,
    ...other
  } = props;

  const active = () => {
    if (selected === null || selected === undefined) {
      return true;
    }
    return selected;
  };

  const buttonStyle = buttonSize => {
    switch (buttonSize) {
      case 'l':
        return classes.largeButton;
      case 'm':
        return classes.mediumButton;
      case 's':
        return classes.smallButton;
      case 'xs':
        return classes.extraSmallButton;
      default:
        return classes.noSizeButton;
    }
  };

  const iconStyle = buttonSize => {
    switch (buttonSize) {
      case 'l':
        return classes.largeIcon;
      case 'm':
        return classes.mediumIcon;
      case 's':
        return classes.smallIcon;
      case 'xs':
        return classes.extraSmallIcon;
      default:
        return classes.mediumIcon;
    }
  };

  const fontStyle = buttonSize => {
    if (largeText) {
      return classes.largeTextFont;
    }
    switch (buttonSize) {
      case 'l':
        return classes.largeFont;
      case 'm':
        return classes.mediumFont;
      case 's':
        return classes.smallFont;
      case 'xs':
        return classes.extraSmallFont;
      default:
        return classes.mediumFont;
    }
  };

  return (
    <div className={clsx(classes.root, buttonStyle(size), className)}>
      <div className={classes.buttonContainer}>
        <ButtonBase
          disableRipple={true}
          key={`${uuid()}`}
          className={clsx(classes.buttonBase, {
            [classes.selected]: selected,
            [classes.disabled]: !active()
          })}
          {...other}
        >
          {IconComponent && (
            <IconComponent
              size={75}
              className={clsx(classes.image, iconStyle(size))}
            />
          )}
        </ButtonBase>
      </div>
      {title && (
        <Typography className={clsx(classes.title, fontStyle(size))}>
          {title}
        </Typography>
      )}
    </div>
  );
};

const large = 150;
const medium = 90;
const small = 75;
const extraSmall = 50;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  buttonContainer: {},
  buttonBase: {
    borderRadius: 8,
    position: 'relative',
    /* For Safari 3.1 to 6.0 */
    '-webkit-transition-duration': '0.3s',
    transitionDuration: '0.3s',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    '&:hover': {
      boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
      transform: 'scale(1.08)'
    }
  },
  image: {
    borderRadius: 5
  },
  disabled: {
    opacity: 0.5
  },
  selected: {
    '&::before': {
      content: "' '",
      width: '100%',
      height: '100%',
      position: 'absolute',
      zIndex: 3,
      border: '2px solid #1ACE51',
      borderRadius: '6px'
    }
  },
  largeButton: {
    width: 'calc(108px + 4px)',
    marginRight: theme.spacing(5),
    '&:last-child': {
      marginRight: 0
    },
    '& div': {
      marginBottom: theme.spacing(1.25),
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(1.75)
      }
    },
    [theme.breakpoints.up('xm')]: {
      marginRight: theme.spacing(7.5)
    },
    [theme.breakpoints.up('sm')]: {
      width: `calc(${large}px + 4px)`
    }
  },
  mediumButton: {
    width: `calc(${medium}px + 4px)`,
    marginRight: `${theme.spacing(1.2)}vw`,
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(7),
      '&:nth-child(4n+4)': {
        marginRight: theme.spacing(2)
      }
    },
    '& div': {
      marginBottom: theme.spacing(1.5)
    }
  },
  smallButton: {
    width: `calc(${small}px + 4px)`,
    marginRight: theme.spacing(7.5),
    '&:last-child': {
      marginRight: 0
    },
    '& div': {
      marginBottom: theme.spacing(1.5)
    }
  },
  extraSmallButton: {
    width: `calc(${extraSmall}px + 4px)`,
    marginRight: theme.spacing(8.5),
    '&:last-child': {
      marginRight: 0
    },
    '& div': {
      marginBottom: theme.spacing(2)
    }
  },
  noSizeButton: {
    '& div': {
      marginBottom: theme.spacing(1.5)
    }
  },
  largeIcon: {
    width: 108,
    height: 108,
    [theme.breakpoints.up('xm')]: {
      width: 128,
      height: 128
    },
    [theme.breakpoints.up('sm')]: {
      width: large,
      height: large
    }
  },
  mediumIcon: {
    width: 75,
    height: 75,
    [theme.breakpoints.up('sm')]: {
      width: medium,
      height: medium
    }
  },
  smallIcon: {
    fontSize: small
  },
  extraSmallIcon: {
    fontSize: extraSmall
  },
  title: {
    textAlign: 'center'
  },
  largeFont: {
    fontSize: 11.5,
    fontWeight: 400,
    width: '110%',
    [theme.breakpoints.up('xm')]: {
      fontWeight: 500
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 15
    }
  },
  mediumFont: {
    fontSize: 12,
    fontWeight: 400,
    width: '110%',
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  largeTextFont: {
    fontSize: 12,
    fontWeight: 400,
    width: '110%'
  },
  smallFont: {
    fontSize: 12,
    fontWeight: 400,
    width: '110%'
  },
  extraSmallFont: {
    fontSize: 10,
    fontWeight: 400,
    width: '110%',
    [theme.breakpoints.up('xm')]: {
      width: '120%'
    }
  }
}));

export default ImageButton;
