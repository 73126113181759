import {
  Collapse,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import {
  RemoveCircle as RemoveCircleIcon,
  ArrowDropDown
} from '@material-ui/icons';
import clsx from 'clsx';
import { numberWithDots } from '../../../../Utils/Format/MoneyFormat';
import DeferredSidebarItemFinancialDeferred from './DeferredSidebarItemFinancialDeferred';

const DeferredSidebarItemProductFinancial = props => {
  const {
    financialId,
    total,
    deferreds,
    productTypeDescription,
    allowDeletion,
    removeDeferreds
  } = props;

  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(previous => !previous);
  };

  return (
    <>
      <div className={classes.financialHeader}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          wrap="nowrap"
          className={classes.financingTitle}
        >
          <div>
            <Typography className={classes.label}>
              No • {financialId}
            </Typography>
            <Typography className={classes.data}>
              Financiación productos {productTypeDescription}
            </Typography>
          </div>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="Ver más"
            classes={{ root: classes.iconButton }}
          >
            <ArrowDropDown />
          </IconButton>
        </Grid>
        <Grid container wrap="nowrap" alignItems="center" justify="flex-end">
          <Typography className={clsx(classes.data, classes.total)}>
            Valor total: {numberWithDots(total)}
          </Typography>
          {allowDeletion && (
            <div className={classes.removeButtonWrapper}>
              <Tooltip title="Quitar financiación" placement="left">
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={() => {
                    removeDeferreds('financial', financialId);
                  }}
                >
                  <RemoveCircleIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </Grid>
      </div>
      <Collapse in={expanded} timeout="auto">
        <Typography
          className={clsx(classes.label, classes.alignRight, classes.count)}
        >
          {deferreds.length} Concepto
          {deferreds.length > 1 ? 's' : ''}
        </Typography>
        {deferreds.map(deferred => (
          <DeferredSidebarItemFinancialDeferred
            key={deferred.deferredId}
            deferred={deferred}
            allowDeletion={allowDeletion}
            removeDeferreds={removeDeferreds}
          />
        ))}
      </Collapse>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  financialHeader: {
    backgroundColor: theme.palette.primary.lighter,
    borderBottom: `1px solid ${theme.palette.background.border}`,
    padding: theme.spacing(2, 0.5, 2, 1),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: theme.spacing(2)
    }
  },
  label: {
    fontSize: 10,
    fontWeight: 500,
    color: theme.palette.text.greyDark
  },
  data: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: 1.2,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 12
    }
  },
  removeButtonWrapper: {
    color: theme.palette.error.main
  },
  removeButton: {
    padding: theme.spacing(0.75)
  },
  financingTitle: {
    marginBottom: theme.spacing(1.5)
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  iconButton: {
    padding: 0
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  total: {
    fontWeight: 600
  },
  alignRight: {
    textAlign: 'right'
  },
  count: {
    margin: theme.spacing(1, 2, 1, 0),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 12,
      marginBottom: theme.spacing(2)
    }
  }
}));
export default DeferredSidebarItemProductFinancial;
