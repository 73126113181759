import React from 'react';

import { makeStyles, styled } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

import VisivilityIcon from '@material-ui/icons/Visibility';
import VisivilityOffIcon from '@material-ui/icons/VisibilityOff';

import clsx from 'clsx';

const CustomButton = styled(Button)({
  padding: 0
});

const SeeMoreButton = ({
  clicked,
  onClick,
  text = 'Ver más',
  iconClasses,
  buttonClasses
}) => {
  const classes = useStyles();
  return (
    <CustomButton
      onClick={onClick}
      className={clsx(classes.seeMoreButton, buttonClasses)}
    >
      {text}
      {clicked ? (
        <VisivilityOffIcon className={clsx(classes.seeMoreIcon, iconClasses)} />
      ) : (
        <VisivilityIcon className={clsx(classes.seeMoreIcon, iconClasses)} />
      )}
    </CustomButton>
  );
};

const useStyles = makeStyles(theme => ({
  seeMoreIcon: {
    marginLeft: theme.spacing(1)
  },
  seeMoreButton: {
    fontSize: 12,
    textTransform: 'none',
    color: theme.palette.primary.lighter
  }
}));

export default SeeMoreButton;
