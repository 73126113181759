import AddIcon from '../../../Components/CustomIcons/AddIcon';
import HomeIcon from '../../../Components/CustomIcons/HomeIcon';
import CommercialIcon from '../../../Components/CustomIcons/CommercialIcon';
import IndustrialIcon from '../../../Components/CustomIcons/IndustrialIcon';
import OthersContratcsIcon from '../../../Components/CustomIcons/OthersContratcsIcon';

export const HEADER_USER_OPTIONS = [
  { label: 'Mi perfil', onClick: () => null },
  { label: 'Salir', onClick: () => null }
];

export const HEADER_MAP_ICON = {
  1: HomeIcon,
  2: CommercialIcon,
  3: IndustrialIcon,
  4: OthersContratcsIcon,
  plus: AddIcon
};

export const TotalPoints = contracts => {
  if (!contracts || contracts.length === 0) {
    return 0;
  }
  let pointSum = 0;
  contracts.forEach(c => {
    pointSum = pointSum + c.points;
  });
  return pointSum;
};
