import React from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';
import Search from '@material-ui/icons/Search';

const SearchIcon = props => {
  const { onClick } = props;
  return (
    <InputAdornment position="start">
      <IconButton aria-label="search" onClick={onClick}>
        <Search fontSize="small" />
      </IconButton>
    </InputAdornment>
  );
};

export default SearchIcon;
