import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import InformationGDC from './Information.GDC';
import InformationGDG from './Information.GDG';

export const MENU = {
  terms: 'Términos y condiciones',
  help: 'Preguntas frecuentes'
};

export const TERMS = {
  introduction: 'introduction',
  objective: 'objective',
  definitions: 'definitions',
  application: 'application',
  policies: 'policies',
  usage: 'usage',
  rules: 'rules',
  ownership: 'ownership',
  privacy: 'privacy',
  ecommerce: 'ecommerce',
  responsibility: 'responsibility',
  cookies: 'cookies',
  resolves: 'resolves',
  cooperation: 'cooperation',
  signup: 'signup',
  registers: 'registers',
  publicity: 'publicity'
};

export const TERMS_ROUTES = {
  [TERMS.introduction]: 'acceso-y-uso-del-portal',
  [TERMS.objective]: 'objetivo-del-portal',
  [TERMS.definitions]: 'definiciones-del-portal',
  [TERMS.application]: 'ambito-de-aplicacion',
  [TERMS.usage]: 'condiciones-especificas-del-portal',
  [TERMS.rules]: 'obligaciones-y-prohibiciones',
  [TERMS.ownership]: 'propiedad-intelectual',
  [TERMS.privacy]: 'tratamiento-de-informacion',
  [TERMS.ecommerce]: 'comercio-electronico',
  [TERMS.responsibility]: 'responsabilidad-gascaribe',
  [TERMS.cookies]: 'manejo-de-cookies',
  [TERMS.resolves]: 'solucion-controversias',
  [TERMS.cooperation]: 'colaboracion-autoridades',
  [TERMS.signup]: 'registro-en-portal',
  [TERMS.registers]: 'registro-menores',
  [TERMS.publicity]: 'publicidad'
};

export const QUESTIONS = {
  first: 'first',
  second: 'second',
  third: 'third',
  fourth: 'fourth',
  fifth: 'fifth',
  sixth: 'sixth',
  seventh: 'seventh',
  eighth: 'eighth',
  ninth: 'ninth',
  tenth: 'tenth'
};

export const getTermsByRoute = route => {
  switch (route) {
    case TERMS_ROUTES[TERMS.introduction]:
      return TERMS.introduction;
    case TERMS_ROUTES[TERMS.objective]:
      return TERMS.objective;
    case TERMS_ROUTES[TERMS.definitions]:
      return TERMS.definitions;
    case TERMS_ROUTES[TERMS.application]:
      return TERMS.application;
    case TERMS_ROUTES[TERMS.usage]:
      return TERMS.usage;
    case TERMS_ROUTES[TERMS.rules]:
      return TERMS.rules;
    case TERMS_ROUTES[TERMS.ownership]:
      return TERMS.ownership;
    case TERMS_ROUTES[TERMS.privacy]:
      return TERMS.privacy;
    case TERMS_ROUTES[TERMS.ecommerce]:
      return TERMS.ecommerce;
    case TERMS_ROUTES[TERMS.responsibility]:
      return TERMS.responsibility;
    case TERMS_ROUTES[TERMS.cookies]:
      return TERMS.cookies;
    case TERMS_ROUTES[TERMS.resolves]:
      return TERMS.resolves;
    case TERMS_ROUTES[TERMS.cooperation]:
      return TERMS.cooperation;
    case TERMS_ROUTES[TERMS.signup]:
      return TERMS.signup;
    case TERMS_ROUTES[TERMS.registers]:
      return TERMS.registers;
    case TERMS_ROUTES[TERMS.publicity]:
      return TERMS.publicity;
    default:
      return null;
  }
};

const Information = props => {
  switch (portal) {
    case Portal.Gascaribe:
    case Portal.TripleA:
      return <InformationGDC {...props} />;
    case Portal.Gasguajira:
      return <InformationGDG {...props} />;
    case Portal.Efigas:
      return <InformationGDC {...props} />;
    default:
      return <></>;
  }
};

export default Information;
