import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef
} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import _get from 'lodash/get';
import debounce from 'lodash/debounce';

import {
  Container,
  Grid,
  Typography,
  useMediaQuery,
  IconButton,
  Tooltip,
  Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';

import { UserContext, UserDispatchContext } from '../../Contexts/UserContext';
import { AlertsDispatchContext } from '../../Contexts/AlertsContext';
import { ContractsContext } from '../../Contexts/ContractsContext';

import { history } from '../../Routes/history';

import {
  redirectOnAuthFailure,
  extractErrorMessage
} from '../../Utils/Errors/Errors';
import { saveAsPDF } from '../../Utils/Transform/Files';

import Pagination from '../../Components/Pagination/Pagination';
import Title from '../../Components/Labels/Title';
import SimpleDivider from '../../Components/Dividers/SimpleDivider';
import NoResultsState from '../../Components/EmptyState/NoResultsState';
import BaseDialog from '../../Components/Dialogs/BaseDialog';
import BaseButton from '../../Components/Buttons/BaseButton';
import BaseFilter from '../../Components/Filters/BaseFilter';
import FilterInput from '../../Components/Inputs/FilterInput.js';
import ModalProgress from '../../Components/Progress/Modal/ModalProgress';
import SweetAlert from '../../Components/Alerts/SweetAlert';
import DownloadIcon from '../../Components/CustomIcons/DownloadIcon';
import TextInput from '../../Components/Inputs/TextInput';
import TransactionListItem from './components/TransactionListItem';
import CleanFilters from '../../Components/Filters/CleanFilters.js';
import ComboBoxInput from '../../Components/Inputs/ComboBoxInput';

import {
  GetUserTransactions,
  GenerateTransactionsReport
} from '../../API/Transaction/UserTransactionAPI';

import {
  couponConcepts,
  couponConceptDescription,
  transactionStatusDescription,
  debounceInputs
} from './static/transactions_enums';
import { TRANSACTION_STATUS } from '../Payment/payment_enums';
import { validateFilters, strSearchParams2Object } from './helpers/helpers';
import { Company } from '../../Configs/general';
import LoginDialog from '../Login/LoginDialog';
import { logoutUser } from '../../Utils/User/Actions.js';

const defaultValues = {
  contract: { label: '', value: '' },
  concept: '',
  status: '',
  transactionId: '',
  startDate: '',
  finalDate: ''
};

// eslint-disable-next-line complexity
const TransactionList = () => {
  const classes = useStyles();
  const theme = useTheme();
  const parsedParams = strSearchParams2Object(window.location.search);

  // * MUI HOOKS
  const smallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  const mediumScreen = useMediaQuery(
    theme.breakpoints.between(
      theme.breakpoints.values.sm,
      theme.breakpoints.values.md
    )
  );

  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const setAlert = useContext(AlertsDispatchContext);
  const contractsContext = useContext(ContractsContext) || [];

  const initialContracts = [
    ...contractsContext,
    {
      alias: Company.contractConjugation.capitalized.plural.other,
      id: 'others'
    }
  ];

  const authToken = _get(currentUser, 'token');

  // * STATE HOOKS
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(
    window.innerWidth < theme.breakpoints.values.sm
      ? 2
      : Math.floor((window.innerHeight - 309) / 139)
  );
  const [transactions, setTransactions] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transactionsCount, setTransactionsCount] = useState(0);
  const [filterValues, setFilterValues] = useState({
    ...defaultValues,
    ...parsedParams
  });
  const [noResults, setNoResults] = useState(false);
  const [noFilteredResults, setNoFilteredResults] = useState(false);
  const [periodStartDate, setPeriodStartDate] = useState(null);
  const [periodEndDate, setPeriodEndDate] = useState(null);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [contracts, setContracts] = useState(initialContracts);
  // States for mobile filters
  const [mobileFiltersValues, setMobileFiltersValues] = useState({
    ...defaultValues,
    ...parsedParams
  });
  const [openLoginDialog, setOpenLoginDialog] = useState(true);
  const isDebounce = useRef(false);

  // * FUNCTIONS
  const filtered = useCallback(obj => {
    return (
      (obj.contract ? obj.contract.value : null) ||
      String(obj.concept) ||
      String(obj.status) ||
      obj.transactionId ||
      obj.startDate ||
      obj.finalDate
    );
  }, []);

  const getTransactions = useCallback(
    async (currentPage, currentPageSize, currentSelectedFilters) => {
      if (!authToken) {
        return;
      }
      isDebounce.current = false;
      setLoading(true);
      setNoResults(false);
      setNoFilteredResults(false);
      history.replace({
        search: new URLSearchParams(currentSelectedFilters).toString()
      });
      const body = {
        page: currentPage,
        pageSize: currentPageSize,
        filter: currentSelectedFilters
      };
      const response = await GetUserTransactions(authToken, body);
      if (response.success) {
        const transactionsArray = response.data.transactions;
        const count = response.data.transactionsCount;
        const { start, end } = response.data.period;
        if (transactionsArray.length === 0) {
          filtered(body.filter)
            ? setNoFilteredResults(true)
            : setNoResults(true);
        }
        setTransactions(transactionsArray);
        setTransactionsCount(count);
        setPeriodStartDate(start);
        setPeriodEndDate(end);
      } else {
        if (
          redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
        ) {
          return;
        }
        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
      }
      setLoading(false);
    },
    [authToken, setAlert, setCurrentUser, filtered]
  );

  const prepareRequestDebounce = useMemo(() => {
    return debounce(getTransactions, 500);
  }, [getTransactions]);

  useEffect(() => {
    const result = validateFilters(filterValues);

    if (result.success) {
      isDebounce.current
        ? prepareRequestDebounce(page, pageSize, result.validatedFilters)
        : getTransactions(page, pageSize, result.validatedFilters);
      return;
    }

    setAlert({
      type: 'error',
      message: result.message
    });

    // The event must be cleaned up from the web API to avoid the event loop tracking and execution
    return () => {
      prepareRequestDebounce.cancel();
    };
  }, [
    getTransactions,
    page,
    pageSize,
    setAlert,
    filterValues,
    prepareRequestDebounce
  ]);

  const generatePDF = useCallback(async () => {
    setLoadingPDF(true);

    const result = validateFilters(filterValues);

    if (!result.success) {
      return setAlert({
        type: 'error',
        message: result.message
      });
    }

    const body = {
      periodStartDate,
      periodEndDate,
      filter: result.validatedFilters
    };

    const response = await GenerateTransactionsReport(authToken, body);
    setLoadingPDF(false);

    if (response.success) {
      const fileName = 'Reporte_transacciones.pdf';

      saveAsPDF(response.data.data.content, fileName);
    } else {
      setAlert({
        type: 'error',
        message: extractErrorMessage(response).message
      });
    }
  }, [authToken, periodEndDate, periodStartDate, setAlert, filterValues]);

  const closeModal = () => setModalVisible(false);

  const canDownload = () => transactionsCount && !loading && !loadingPDF;

  const isValid = (value, field) => {
    switch (field) {
      case 'concept':
        return Object.values(couponConcepts).includes(value);
      case 'status':
        return Object.values(TRANSACTION_STATUS).includes(value);
      default:
        return Boolean(value);
    }
  };

  const renderContracts = useMemo(() => {
    return contracts
      ? contracts.map(contract => ({
          label: contract.alias,
          value: contract.id
        }))
      : [];
  }, [contracts]);

  const handleChangeContracts = contractId => {
    const filteredContracts = initialContracts.filter(contractOption =>
      String(contractOption.id).includes(contractId)
    );

    setContracts(filteredContracts);
  };

  const renderConcepts = () => {
    return Object.values(couponConcepts).map(concept => ({
      value: concept,
      label: couponConceptDescription(concept)
    }));
  };

  const renderStatusOptions = () => {
    return Object.values(TRANSACTION_STATUS).map(status => ({
      value: status,
      label: transactionStatusDescription[status]
    }));
  };

  const clearFilters = () => {
    setFilterValues(defaultValues);
    setMobileFiltersValues(defaultValues);
    setContracts(initialContracts);
    setPage(1);
  };

  const resetField = field => {
    if (smallScreen) {
      setMobileFiltersValues(prev => ({
        ...prev,
        [field]: defaultValues[field]
      }));
    } else {
      setFilterValues(prev => ({ ...prev, [field]: defaultValues[field] }));
    }
  };

  const setFilterValue = async event => {
    const { name, value } = event.target;
    if (debounceInputs.includes(name) && !smallScreen) {
      isDebounce.current = true;
    } else {
      isDebounce.current = false;
    }
    setFilterValues({ ...filterValues, [name]: value });
    setPage(1);
  };

  const handleOpenMobileFilters = setOpenInnerState => {
    setOpenInnerState(true);
    setMobileFiltersValues(filterValues);
  };

  // Triggers when "Filtrar" button is pushed (Dialog)
  const handleSetMobileFilters = setOpenInnerState => {
    const result = validateFilters(filterValues);

    if (result.success) {
      setFilterValues({ ...mobileFiltersValues });
      setMobileFiltersValues(defaultValues);
      setPage(1);
      setOpenInnerState(false);
      return;
    }

    setAlert({
      type: 'error',
      message: result.message
    });
  };

  // Triggered when "Volver" button is clicked (Dialog)
  const handleCloseMobileFilters = setOpenInnerState => {
    // If the user return without clicking "Filtrar", filter values must be reset
    setMobileFiltersValues(defaultValues);
    setOpenInnerState(false);
  };

  const handleMobileFilters = ({ target: { name, value } }) => {
    setMobileFiltersValues(prev => ({ ...prev, [name]: value }));
  };

  const handleGeneralInputChange = event => {
    if (smallScreen) {
      handleMobileFilters(event);
    } else {
      setFilterValue(event);
    }
  };

  const handleOpenLoginDialog = useCallback(open => {
    if (!open) {
      history.replace('/');
    }

    setOpenLoginDialog(open);
  }, []);

  const filterInputValues = smallScreen ? mobileFiltersValues : filterValues;

  const renderFilters = () => {
    return (
      <>
        <Grid item xs={12} sm={3} className={classes.filterItem}>
          <ComboBoxInput
            TextInputProps={{
              label: Company.contractConjugation.capitalized.singular.main
            }}
            name={'contract'}
            InputPropsClasses={{
              root: classes.input
            }}
            textInputClass={classes.input}
            options={[...renderContracts]}
            getOptionLabel={option => String(option.value)}
            // These two states are isolated, they should be controlled intependently
            value={filterInputValues.contract}
            inputValue={String(filterInputValues.contract.value)}
            onChange={(_, { value }) => {
              handleChangeContracts(value);
              handleGeneralInputChange({
                target: {
                  name: 'contract',
                  value: { label: '', value }
                }
              });
            }}
            onInputChange={(_, value) => {
              handleChangeContracts(value);
              handleGeneralInputChange({
                target: {
                  name: 'contract',
                  value: { label: '', value }
                }
              });
            }}
            renderOption={option => {
              return (
                <>
                  {option.value === 'others' ? (
                    Company.contractConjugation.capitalized.plural.other
                  ) : (
                    <div className={classes.optionItemContainer}>
                      {option.value}
                      <span className={classes.optionItemAlias}>
                        {option.label}
                      </span>
                    </div>
                  )}
                </>
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3} className={classes.filterItem}>
          <FilterInput
            fullWidth
            className={classes.input}
            label={'Concepto'}
            name={'concept'}
            value={filterInputValues.concept}
            onChange={handleGeneralInputChange}
            options={renderConcepts()}
            clearable={isValid(filterInputValues.concept, 'concept')}
            handleClear={() => resetField('concept')}
            InputProps={{
              classes: {
                root: classes.filterInput
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3} className={classes.filterItem}>
          <FilterInput
            fullWidth
            className={classes.input}
            label={mediumScreen ? 'Estado' : 'Estado transacción'}
            name={'status'}
            value={filterInputValues.status}
            onChange={handleGeneralInputChange}
            options={renderStatusOptions()}
            clearable={isValid(filterInputValues.status, 'status')}
            handleClear={() => resetField('status')}
            InputProps={{
              classes: {
                root: classes.filterInput
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={3} className={classes.filterItem}>
          <TextInput
            className={clsx(classes.input, classes.uncontrolledInput)}
            label="No. de transacción"
            type="number"
            fullWidth
            inputProps={{ maxLength: 12 }}
            InputPropsClasses={{
              root: classes.normalizedInputField
            }}
            name="transactionId"
            value={filterInputValues.transactionId}
            onChange={handleGeneralInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={3} className={classes.filterItem}>
          <TextInput
            fullWidth
            className={clsx(classes.input, classes.uncontrolledInput)}
            InputPropsClasses={{
              root: classes.normalizedInputField
            }}
            label="Fecha inicial"
            inputProps={{
              maxLength: 20,
              max: moment(filterValues.finalDate).isValid()
                ? moment(filterValues.finalDate).format('YYYY-MM-DD')
                : moment()
                    .utcOffset(-5)
                    .format('YYYY-MM-DD')
            }}
            type="date"
            name="startDate"
            InputLabelProps={{ shrink: true }}
            value={filterInputValues.startDate}
            onChange={handleGeneralInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={3} className={classes.filterItem}>
          <TextInput
            fullWidth
            className={clsx(classes.input, classes.uncontrolledInput)}
            InputPropsClasses={{
              root: classes.normalizedInputField
            }}
            label="Fecha final"
            inputProps={{
              maxLength: 20,
              max: moment()
                .utcOffset(-5)
                .format('YYYY-MM-DD'),
              min: moment(filterValues.startDate).isValid()
                ? moment(filterValues.startDate).format('YYYY-MM-DD')
                : null
            }}
            type="date"
            name="finalDate"
            InputLabelProps={{ shrink: true }}
            value={filterInputValues.finalDate}
            onChange={handleGeneralInputChange}
          />
        </Grid>
      </>
    );
  };

  const renderPagination = () => (
    <Pagination
      numberOfItems={transactionsCount}
      itemsPerPage={pageSize}
      setItemsPerPage={setPageSize}
      currentPage={page}
      setCurrentPage={setPage}
    />
  );

  const renderCards = useCallback(() => {
    if (transactions.length) {
      return (
        <Box my={0.75}>
          {transactions.map((v, index) => {
            return <TransactionListItem key={`item-${index}`} {...v} />;
          })}
        </Box>
      );
    }
    return (
      <NoResultsState
        iconClass={classes.noResultsIcon}
        messageTitle={'Búsqueda sin resultados'}
        subMessage={
          (noFilteredResults &&
            'No hemos encontrado transacciones que coincidan con estos criterios, limpia los filtros e intenta de nuevo') ||
          (noResults &&
            `Aún no tienes transacciones realizadas con los servicios de ${Company.name}`)
        }
      />
    );
  }, [transactions, noFilteredResults, noResults, classes.noResultsIcon]);

  const renderDialogActions = useCallback(() => {
    return (
      <Fragment>
        <BaseButton
          onClick={() => {
            setModalVisible(false);
          }}
          variant="outlined"
          color="primary"
          size="small"
          className={classes.dialogButton}
        >
          Cancelar
        </BaseButton>
        <BaseButton
          color="primary"
          size="small"
          onClick={() => {
            closeModal();
            generatePDF();
          }}
          className={classes.dialogButton}
        >
          Descargar
        </BaseButton>
      </Fragment>
    );
  }, [setModalVisible, generatePDF, classes.dialogButton]);

  const renderDialog = () => {
    return (
      <BaseDialog
        contentStyle={classes.baseDialog}
        paperClass={clsx({ [classes.downloadDialog]: smallScreen })}
        open={true}
        loading={false}
        handleClose={closeModal}
        title={
          <Box
            display="flex"
            alignItems="center"
            component="span"
            className={classes.dialogTitle}
          >
            <Box
              marginRight={1}
              display="flex"
              alignItems="center"
              justifyContent="center"
              component="span"
            >
              <DownloadIcon />
            </Box>
            Descargar reporte
          </Box>
        }
        actions={renderDialogActions}
        content={() => (
          <Typography className={classes.renderText}>
            El reporte que está a punto de generar, depende de los criterios
            seleccionados en la{' '}
            <span className={classes.mediumText}>sección de filtros.</span>
          </Typography>
        )}
        fullScreen={false}
        contentSize="small"
        disableBackdropClick={true}
        TransitionComponent={undefined}
      />
    );
  };
  if (!authToken) {
    return (
      <LoginDialog open={openLoginDialog} setOpen={handleOpenLoginDialog} />
    );
  }

  return (
    <div className={classes.root}>
      <Container
        id="div_container"
        maxWidth={false}
        className={classes.mainContainer}
      >
        <Title text={'Mis transacciones'} className={classes.title} />
        {loadingPDF && <ModalProgress message="Generando reporte" />}
        {!smallScreen && (
          <Typography className={classes.filterTitle}>Filtros</Typography>
        )}
        {filtered(filterValues) && smallScreen && (
          <CleanFilters
            className={classes.cleanFilters}
            onClick={clearFilters}
          />
        )}
        <Box display="flex" className={classes.baseFilter}>
          <Box flexGrow={1} className={classes.filtersContainer}>
            <BaseFilter
              filter
              filtersLabel={false}
              filters={renderFilters}
              clearFilters={clearFilters}
              showClearFilters={filtered(filterValues)}
              filterModalTitle="Volver"
              disableClearOutside={true}
              handleOpenMobileFilters={handleOpenMobileFilters}
              handleSetMobileFilters={handleSetMobileFilters}
              handleCloseMobileFilters={handleCloseMobileFilters}
              avoidSpacing={true}
              filterButtonOn={filtered(
                smallScreen ? mobileFiltersValues : filterValues
              )}
            />
          </Box>
          <Box ml={2}>
            <Tooltip title="Descargar extracto" placement="left">
              <div>
                <IconButton
                  disabled={!canDownload()}
                  className={classes.downloadIcon}
                  aria-label="descargar reporte"
                  color="primary"
                  onClick={() => canDownload() && setModalVisible(true)}
                >
                  <DownloadIcon />
                </IconButton>
              </div>
            </Tooltip>
          </Box>
          {filtered(filterValues) && !smallScreen && (
            <CleanFilters
              className={classes.cleanFilters}
              onClick={clearFilters}
            />
          )}
        </Box>
        <SimpleDivider className={classes.simpleDivider} />
        {loading ? (
          <ModalProgress
            id={'Transaction_progress_loadingAPI'}
            message={'Cargando'}
          />
        ) : (
          <>
            {transactions.length > 0 && renderPagination()}
            {modalVisible && renderDialog()}
            {renderCards()}
            {transactions.length > 0 &&
              (smallScreen || transactions.length > 3) &&
              renderPagination()}
          </>
        )}
        {transactions.length > 0 && (
          <SweetAlert
            classes={{ root: classes.sweetAlert }}
            message={
              <span>
                Las transacciones visualizadas corresponden a todas las
                realizadas a través de este portal web por el usuario{' '}
                <span className={classes.mediumText}>
                  {currentUser && currentUser.firstName}{' '}
                  {currentUser && currentUser.lastName}
                </span>{' '}
                y a los pagos por otros medios de los últimos 90 días.
              </span>
            }
          />
        )}
      </Container>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 2, 5, 2),
    maxWidth: 964,
    marginTop: 0,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 0, 5, 0)
    }
  },
  title: {
    color: theme.palette.common.black,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    fontSize: 18,
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      fontSize: 20,
      margin: theme.spacing(5, 0, 3, 0)
    }
  },
  sweetAlert: {
    margin: theme.spacing(2, 0, 0, 0)
  },
  simpleDivider: {
    margin: theme.spacing(3, 0, 1),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      margin: theme.spacing(1, 0)
    }
  },
  renderText: {
    fontSize: 14,
    color: '#757B86'
  },
  mediumText: {
    fontWeight: 600
  },
  baseDialog: {
    minHeight: 100
  },
  dialogTitle: {
    fontWeight: 600,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 14
    }
  },
  downloadDialog: {
    margin: theme.spacing(4, 2)
  },
  dialogButton: {
    height: 47,
    width: 86,
    '&:nth-child(2)': {
      marginLeft: theme.spacing(2)
    }
  },
  downloadIcon: {
    width: 50,
    height: 50,
    borderRadius: 4,
    padding: 0,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    },
    '&:disabled': {
      opacity: 0.4
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      width: 40,
      height: 40,
      padding: theme.spacing()
    }
  },
  filterTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.greyDark,
    marginBottom: theme.spacing(2)
  },
  filtersContainer: {
    maxWidth: 768
  },
  optionItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  optionItemAlias: {
    color: '#B8B8B8',
    fontSize: 12
  },
  input: {
    maxHeight: 50,
    margin: 0,
    padding: 0
  },
  uncontrolledInput: {
    margin: 0
  },
  filterItem: {
    height: 50,
    marginBottom: 24,
    '&:last-child': {
      marginBottom: 0
    },
    [theme.breakpoints.up('sm')]: {
      maxHeight: 50,
      '&:nth-child(even)': {
        padding: theme.spacing(0, 1, 0, 0.5)
      },
      '&:nth-child(odd)': {
        padding: theme.spacing(0, 0.5, 0, 1)
      },
      '&:first-child': {
        padding: theme.spacing(0, 1.5, 0, 0)
      },
      '&:nth-last-child(3)': {
        padding: theme.spacing(0, 0, 0, 1.5)
      },
      '&:nth-last-child(2)': {
        marginBottom: 0,
        padding: theme.spacing(0, 1.5, 0, 0)
      },
      '&:last-child': {
        padding: theme.spacing(0, 1, 0, 0.5)
      }
    }
  },
  normalizedInputField: {
    margin: 0,
    minHeight: 50,
    maxHeight: 50
  },
  noResultsIcon: {
    width: 274.13,
    height: 180
  },
  cleanFilters: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 0, 0, 'auto'),
      paddingTop: 15
    }
  },
  baseFilter: {
    alignItems: 'start',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center'
    }
  },
  filterInput: {
    maxHeight: 50
  }
}));

export default TransactionList;
