import { config } from '../../Configs';

export const getRecaptchaToken = async (action = 'no_action') => {
  if (window.grecaptcha) {
    const recaptchaToken = await window.grecaptcha.execute(
      config.recaptcha.siteKeyV3,
      {
        action
      }
    );

    return recaptchaToken;
  }

  return null;
};
