import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import NoQuotaDetailGDC from './NoQuotaDetail.GDC';
import NoQuotaDetailGDG from './NoQuotaDetail.GDG';
import NoQuotaDetailEFG from './NoQuotaDetail.EFG';

const NoQuotaDetails = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <NoQuotaDetailGDC {...props} />;
    case Portal.Gasguajira:
      return <NoQuotaDetailGDG {...props} />;
    case Portal.Efigas:
      return <NoQuotaDetailEFG {...props} />;
    default:
      return <></>;
  }
};

export default NoQuotaDetails;
