/* eslint-disable complexity */
import React, { Fragment, useEffect, useRef } from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import {
  CookiesPoliciesLink,
  HabeasDataEmail,
  PrivacyPoliciesLink,
  MainSiteUrlLink
} from '../../../Configs/Links';
import { Company } from '../../../Configs/general';

const TermsAndConditionsGDC = ({
  expanded,
  handleChange,
  displayIcon,
  classes,
  terms: TERMS,
  initial
}) => {
  const scrollRef = useRef(null);

  const filialLegalName = `${Company.name.toUpperCase()} E.S.P., S.A. (en adelante ${Company.shortName.toUpperCase()}) `;
  const filialLandingUrl = MainSiteUrlLink.split('https://')[1];

  const filialLegalShortName = ` ${Company.shortName} `;
  const filialLegalShortNameUpper = filialLegalShortName.toUpperCase();

  useEffect(() => {
    if (initial) {
      scrollRef.current.scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
  }, [initial]);

  return (
    <Fragment>
      <>
        <Accordion
          ref={expanded === TERMS.introduction ? scrollRef : null}
          square
          expanded={expanded === TERMS.introduction}
          onChange={handleChange(TERMS.introduction)}
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded
          }}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            classes={{
              root: classes.expansionPanelSummary,
              expanded: classes.expansionPanelSummaryExpanded
            }}
          >
            <Fragment>
              {displayIcon(TERMS.introduction)}
              <Typography variant="h2" className={classes.title}>
                {'Introducción a la Política de uso del Portal'}
              </Typography>
            </Fragment>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.expansionPanelDetails
            }}
          >
            <Typography className={classes.description}>
              {filialLegalName}, en condición de propietaria del portal web bajo
              el dominio{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={MainSiteUrlLink}
              >
                {filialLandingUrl}
              </a>{' '}
              y en su condición de Responsable de los datos personales que son
              aquí recolectados y tratados, procede a informar a sus grupos de
              interés la política de uso de este portal y tratamiento de datos
              personales que se realizan en este, actuando así de forma
              responsable, legítima, transparente y ética, directrices que
              habrán de regular la operación de nuestro portal, constituyéndose
              éstas en su marco regulador. <br /> <br />
              Nuestra presencia empresarial en Internet, además de adoptar
              medidas informáticas para propender por una navegación segura,
              incorpora buenas prácticas para el tratamiento seguro de la
              información, entre ellas la protección de datos personales.
              <br /> <br />
              Rogamos leer con detenimiento esta política de uso que incorpora
              las condiciones y términos de uso de este portal web (en adelante
              LA POLITICA), sea que una persona se registre, use y/o visite este
              portal web para realizar trámites y/o transacciones o sea un mero
              visitante, en tanto el carácter regulador que tiene este documento
              para las partes a las cuales se dirige. <br /> <br />
              Este portal web permite que los usuarios, clientes, prospectos y
              demás miembros de los grupos de interés puedan usar este portal
              web según las funcionalidades actuales y/o futuras que se
              incorporen en el mismo. <br /> <br />
              En cuanto a los datos personales suministrados por titulares a
              través de este portal web {filialLegalShortNameUpper} en condición
              de Responsable, expresa que se obtienen los consentimientos por
              parte de los titulares de acuerdo con las formas permitidas en el
              régimen de protección de datos personales vigentes en Colombia.{' '}
              <br /> <br />
              La no aceptación de esta Política por parte de los titulares
              impedirá el registro de los titulares en el portal y en
              consecuencia la realización de tramites, esto en la medida que la
              información solicitada y términos contenido en este documento
              constituyen el marco regulador de las relaciones a la luz de la
              autonomía de la voluntad privada. Así mismo, cualquier infracción
              a estos términos podrá acarrear como consecuencia la exclusión o
              salida del infractor dentro del sistema de información que soporta
              nuestro portal web, sin perjuicio de otras decisiones que se
              llegaren a adoptar a la luz del principio de legalidad.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          ref={expanded === TERMS.objective ? scrollRef : null}
          square
          expanded={expanded === TERMS.objective}
          onChange={handleChange(TERMS.objective)}
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded
          }}
        >
          <AccordionSummary
            aria-controls="panel2d-content"
            id="panel2d-header"
            classes={{
              root: classes.expansionPanelSummary,
              expanded: classes.expansionPanelSummaryExpanded
            }}
          >
            <Fragment>
              {displayIcon(TERMS.objective)}
              <Typography variant="h2" className={classes.title}>
                {'Objetivo del Portal'}
              </Typography>
            </Fragment>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.expansionPanelDetails
            }}
          >
            <Typography className={classes.description}>
              {filialLegalShortNameUpper} como empresa que presta servicios
              públicos de gas natural y en ejecución de su objeto social permite
              a través de este portal web fortalecer el relacionamiento con sus
              grupos de interés, en este orden de ideas este portal web
              incorpora las siguientes funcionalidades en general: <br /> <br />
              <ul>
                <li>Atención de PQR ́s </li>
                <li>Gestión de solicitudes del servicios de gas natural </li>
                <li>
                  Asociación de{' '}
                  {Company.contractConjugation.regular.plural.main}
                </li>
                <li>Pago de facturas y negociaciones de deuda</li>
                <li>Revisión de estado de cuenta</li>
                <li>Visualización de duplicados de facturas</li>
                <li>Visualización de cupo Brilla</li>
                <li>Generación de cupón de pago </li>
                <li>Y mucho más</li>
              </ul>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          ref={expanded === TERMS.definitions ? scrollRef : null}
          square
          expanded={expanded === TERMS.definitions}
          onChange={handleChange(TERMS.definitions)}
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded
          }}
        >
          <AccordionSummary
            aria-controls="panel3d-content"
            id="panel3d-header"
            classes={{
              root: classes.expansionPanelSummary,
              expanded: classes.expansionPanelSummaryExpanded
            }}
          >
            <Fragment>
              {displayIcon(TERMS.definitions)}
              <Typography variant="h2" className={classes.title}>
                {'Definiciones'}
              </Typography>
            </Fragment>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.expansionPanelDetails
            }}
          >
            <Typography className={classes.description}>
              Para efectos de contribuir a la interpretación de esta Política,
              se incorporan, sin perjuicio de otras definicionales legales y/o
              doctrinales, las siguientes definiciones:
              <br /> <br />
              <i>Aliado:</i> Es la persona con la cual existe una alianza para
              ofrecer productos, bienes y/o servicios a los usuarios; y/o que
              participa en alguna de las actividades que ejecuta{' '}
              {filialLegalShortName} en ejecución del objeto social.
              <br /> <br />
              <i>Calidad de la información:</i> Es un atributo de la información
              personal recolectada, que implica que los datos sean adecuados,
              pertinentes y no excesivos, conforme la estrategia de nuestro
              portal web.
              <br />
              <br />
              <i>Comercio Electrónico:</i> Comprende el envío, transmisión,
              recepción, almacenamiento de mensajes de datos por vía
              electrónica. Las dudas que surjan respecto de la eficacia y
              validez de los mensajes de datos y demás actividades vinculadas al
              comercio electrónico se interpretarán de conformidad con la ley
              527 de 1999.
              <br /> <br />
              <i>Consumidor:</i> Toda persona natural que adquiera un producto
              para su consumo y satisfacción de necesidades personales y/o
              familiares.
              <br /> <br />
              <i>Cookies:</i> Son componentes de software que permiten el
              almacenamiento y recuperación de datos que se utilizan en el
              equipo terminal de un usuario con la finalidad de almacenar
              información y recuperar la información ya almacenada. La dinámica
              de la tecnología da lugar a que estos componentes de software
              evolucionen e incorporen de forma permanente nuevas
              funcionalidades para estos mismos fines.
              <br /> <br />
              <i>{filialLegalShortName} :</i> Es la sociedad responsable de
              operar la plataforma informática que soporta el portal web:{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={MainSiteUrlLink}
              >
                {filialLandingUrl}
              </a>{' '}
              <br />
              <br />
              <i>Datos personales:</i> Es toda información que permite
              identificar o hacer identificable a una persona.
              <br /> <br />
              <i>Datos Sensibles:</i> Es información personal íntima relacionada
              con ideas políticas, creencias religiosas, vida sexual, origen
              racial, datos de salud, biometría, entre otros así catalogados.
              <br />
              <br />
              <i>Encargado del Tratamiento:</i> Es la persona natural o jurídica
              que de manera autónoma o conjunta, realiza tratamientos sobre la
              información de carácter personal por cuenta del responsable.
              <br />
              <br />
              <i>Finalidad:</i> Principio que consiste en el uso o fin para el
              cual se recolecta la información solicitada a los visitantes,
              usuarios y/o consumidores durante el proceso de registro, trámites
              o con posterioridad a éste. El usuario del portal, en el momento
              del registro, será informado de la finalidad a otorgarse a sus
              datos personales, sin perjuicio de las otras finalidades que sean
              puestas en su conocimiento durante un determinado tramite en este
              portal web.
              <br /> <br />
              <i>Mayor de edad:</i> Persona física mayor de dieciocho (18) años
              que tiene capacidad para contratar.
              <br /> <br />
              <i>Mensaje de Datos: </i>Información generada, enviada, recibida,
              almacenada o comunicada por medios electrónicos, ópticos o
              similares, como pudieran ser, entre otros, el Intercambio
              Electrónico de Datos (EDI), Internet, el correo electrónico, el
              telegrama, el télex o el telefax.
              <br /> <br />
              <i>Tratamiento de la Información:</i> Operaciones y procedimientos
              técnicos de carácter automatizado o no, que permiten la recogida,
              grabación, conservación, elaboración, modificación, bloqueo y
              cancelación de información de carácter personal, así como las
              cesiones de datos que resulten de comunicaciones, consultas,
              interconexiones y transferencias.
              <br /> <br />
              <i>Publicidad:</i> Es toda forma de comunicación realizada de
              forma directa o indirecta por {filialLegalShortName} , con el fin
              de informar sobre su objeto social y comunicar estrategias, oferta
              de productos, bienes, servicios o campañas publicitarias o de
              mercadeo. La publicidad podrá realizarse mediante correspondencia
              física, electrónica o al teléfono telefónico, sea en forma
              independiente y/o en alianza con terceros.
              <br /> <br />
              <i>Responsable del Tratamiento:</i> Es la persona jurídica que
              determina la finalidad, contenido o uso de la información de
              carácter personal recolectada, en este caso,{' '}
              {filialLegalShortName} .
              <br /> <br />
              <i>Sistema de información:</i> Se entenderá por tal todo programa
              informático utilizado para generar, enviar, recibir, archivar o
              procesar de cualquier forma los mensajes de datos que contienen
              información vinculada al portal web{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={MainSiteUrlLink}
              >
                {filialLandingUrl}
              </a>{' '}
              <br /> <br />
              <i>Producto:</i> Bien o servicio corporal o incorporal que ha sido
              adquirido por el consumidor a través del portal web{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={MainSiteUrlLink}
              >
                {filialLandingUrl}
              </a>{' '}
              <br /> <br />
              <i>Proveedor: </i>Es la empresa que a través de nuestro portal web
              comercializa bienes y servicios, asumiendo el control y la
              presentación de los mismos.
              <br /> <br />
              <i>Usuario del servicio público de gas:</i> Es la persona a la
              cual se presta el servicio público de gas a partir del régimen
              contractual aplicable en la materia.
              <br /> <br />
              <i>Usuario del portal web:</i> Es la persona que, una vez registre
              sus datos personales en nuestro portal web, queda habilitada para
              realizar tramites y/o actividades de acuerdo a las funcionalidades
              existentes en este.
              <br /> <br />
              <i>Visitantes:</i> Son las personas que navegan en nuestro portal
              sin necesidad de registro, haciéndolo solo a partir de la
              digitación en un explorador del nombre de dominio{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={MainSiteUrlLink}
              >
                {filialLandingUrl}
              </a>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          ref={expanded === TERMS.application ? scrollRef : null}
          square
          expanded={expanded === TERMS.application}
          onChange={handleChange(TERMS.application)}
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded
          }}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            classes={{
              root: classes.expansionPanelSummary,
              expanded: classes.expansionPanelSummaryExpanded
            }}
          >
            <Fragment>
              {displayIcon(TERMS.application)}
              <Typography variant="h2" className={classes.title}>
                Ámbito de Aplicación
              </Typography>
            </Fragment>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.expansionPanelDetails
            }}
          >
            <Typography className={classes.description}>
              Esta Política contiene los términos y condiciones de uso del
              portal, así como el acuerdo de voluntades que regula la
              realización de tramites y/o adquisición de productos, bienes o
              servicios, en un marco de legalidad y comportamiento ético por
              parte de quienes se relacionan con {filialLegalShortName} <br />{' '}
              <br />
              Así mismo regula el proceso de tratamiento de datos de las
              personas que se registren, realicen tramites, adquieran productos,
              bienes, servicios y/o visiten el portal. Así mismo,{' '}
              {filialLegalShortName} se compromete a promover que la información
              que se comunique en el portal web sea respetuosa de los derechos
              de propiedad intelectual, así como de la dignidad humana, la moral
              y las buenas costumbres. Es obligación de toda persona que
              interactúe con nuestro portal web hacer un uso honesto de la
              información a la que se acceda.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          ref={expanded === TERMS.signup ? scrollRef : null}
          square
          expanded={expanded === TERMS.signup}
          onChange={handleChange(TERMS.signup)}
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded
          }}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            classes={{
              root: classes.expansionPanelSummary,
              expanded: classes.expansionPanelSummaryExpanded
            }}
          >
            <Fragment>
              {displayIcon(TERMS.signup)}
              <Typography variant="h2" className={classes.title}>
                Registro en el portal web
              </Typography>
            </Fragment>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.expansionPanelDetails
            }}
          >
            <Typography className={classes.description}>
              La realización de tramites u otras actividades en este portal web
              requiere registro previo por parte de los usuarios con el fin de
              dar inicio a la gestión de estos. En el registro de la información
              solicitada, {filialLegalShortName} en virtud del principio de la
              buena fe, presume que los datos suministrados son veraces y son
              registrados por quien es titular de la información y tiene certeza
              de que la información corresponde con la realidad.
              <br /> <br />
              La información recolectada en este portal web deberá ser
              actualizada por el titular de la misma si se presentan cambios en
              ella, caso en el cual deberá realizar la actualización a través
              del siguiente correo: {HabeasDataEmail}. <br /> <br />
              En caso de presentarse cambios en la información suministrada por
              el titular de la información y no haberse notificado tales cambios
              a {filialLegalShortName} por este, se entiende que la información
              suministrada corresponde a la realidad y que la decisión del
              titular es mantener los datos suministrados vigentes para todos
              los efectos precontractuales, contractuales y postcontractuales.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          ref={expanded === TERMS.usage ? scrollRef : null}
          square
          expanded={expanded === TERMS.usage}
          onChange={handleChange(TERMS.usage)}
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded
          }}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            classes={{
              root: classes.expansionPanelSummary,
              expanded: classes.expansionPanelSummaryExpanded
            }}
          >
            <Fragment>
              {displayIcon(TERMS.usage)}
              <Typography variant="h2" className={classes.title}>
                Acceso a funcionalidades específicas dentro del portal web
              </Typography>
            </Fragment>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.expansionPanelDetails
            }}
          >
            <Typography className={classes.description}>
              {filialLegalShortName} , respecto de los proveedores, usuarios,
              consumidores y/o otros terceros podrá habilitar acceso a
              determinadas funcionalidades dentro de este portal web con el fin
              de facilitar el relacionamiento de estos. {filialLegalShortName} ,
              respecto de los proveedores, usuarios, consumidores y/o otros
              terceros podrá habilitar acceso a determinadas funcionalidades
              dentro de este portal web con el fin de facilitar el
              relacionamiento de estos.
              <br /> <br />
              Para el efecto, se otorgará un derecho de acceso mediante un
              nombre de usuario y una contraseña temporal que deberá cambiarse
              una vez se realice el primer ingreso. Esta contraseña podrá
              contener uno o dos factores de autenticación para el acceso al
              portal web. La nueva contraseña deberá contener letras mayúsculas
              y minúsculas, números y otros caracteres. {
                filialLegalShortName
              }{' '}
              definirá de forma autónoma las políticas de modificación de
              contraseñas las cuales son vinculantes para los proveedores,
              usuarios, consumidores, los proveedores u otros terceros.
              <br /> <br />
              {filialLegalShortName} informa que cada tercero autorizado para
              acceder a determinadas funcionalidades presentes en este portal
              web que cada uno es el único responsable del nombre de usuario y
              contraseña, los cuales tienen el carácter de información
              confidencial y restringida, motivo por el cual todo acceso al
              portal web se presume que ha sido realizado por estas personas;
              por tanto, salvo prueba el contrario, todo acceso y/o tramite se
              presume valido y realizado por el titular del nombre de usuario.
              <br /> <br />
              El portal web dispone de la funcionalidad para recuperar
              contraseña en caso de olvido. En caso de perdida de control del
              nombre de usuario y/o contraseña, por cualquier situación, es
              responsabilidad del titular del nombre de usuario y contraseña
              reportar este incidente y realizar los cambios en la contraseña
              correspondiente.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          ref={expanded === TERMS.rules ? scrollRef : null}
          square
          expanded={expanded === TERMS.rules}
          onChange={handleChange(TERMS.rules)}
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded
          }}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            classes={{
              root: classes.expansionPanelSummary,
              expanded: classes.expansionPanelSummaryExpanded
            }}
          >
            <Fragment>
              {displayIcon(TERMS.rules)}
              <Typography variant="h2" className={classes.title}>
                Obligaciones y prohibiciones
              </Typography>
            </Fragment>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.expansionPanelDetails
            }}
          >
            <Typography className={classes.description}>
              <b>Son obligaciones de {filialLegalShortName} </b>
              <br /> <br />
              <ol>
                <li>
                  Gestionar de forma segura este portal web en un marco
                  orientado a mitigar los riesgos que se identifiquen.
                </li>
                <li>
                  Gestionar los datos personales recolectados en el marco del
                  régimen de protección de datos personales vigente en Colombia
                </li>
                <li>
                  Suministrar información clara y veraz en este portal web
                </li>
                <li>
                  Cumplir esta política que contiene los términos y condiciones
                  de uso
                </li>
                <li>
                  Cumplir con las normas en materia de propiedad intelectual e
                  inmaterial
                </li>
                <li>
                  Cumplir con las normas aplicables al régimen de servicios
                  públicos vigente en Colombia
                </li>
              </ol>
              <br /> <br />
              <b>
                Obligaciones de los proveedores, usuarios, consumidores y/o
                otros terceros que usen este portal web
              </b>
              <ol>
                <li>Suministrar información veraz al registrarse</li>
                <li>
                  Mantener actualizada la información personas suministrada
                </li>
                <li>
                  Usar este portal de acuerdo con los términos y condiciones de
                  uso presentes en esta política
                </li>
                <li>
                  Dar seguridad al nombre de usuario y contraseña asignada,
                  cuando sea el caso
                </li>
                <li>
                  Usar el portal solo para las funcionalidades autorizadas
                </li>
                <li>
                  Respetar la propiedad intelectual propia de cada obra,
                  contenido y/o creaciones intelectuales presentes en el portal
                  web
                </li>
                <li>
                  Usar este portal y/o las funcionalidades de comunicación
                  habilitadas en este de forma respetuosa, ética y buenas
                  prácticas sociales.
                </li>
                <li>
                  Informar cualquier pedida de control respecto de los nombres
                  de usuarios y/o contraseñas, caso en el cual deberá informar
                  de manera inmediata una vez tenga conocimiento de tal
                  situación.
                </li>
                <li>
                  Informar cualquier situación anómala que observe, irregular o
                  mal funcionamiento que observe de este portal web
                </li>
                <li>
                  Cumplir con las medidas de seguridad que sean informadas por
                  {filialLegalShortName}{' '}
                </li>
              </ol>
              <b>Prohibiciones</b>
              <ol>
                <li>
                  No compartir con terceros el nombre de usuario y contraseña de
                  acceso al portal web, pues estos tienen carácter confidencial
                </li>
                <li>
                  No realizar actos que atenten contra la seguridad de este
                  portal web y/o de los usuarios de este portal.
                </li>
                <li>
                  No realizar actividades de ingeniería inversa a este portal
                  web
                </li>
                <li>
                  No realizar ninguna de las conductas descritas en la ley 1273
                  de 2009
                </li>
                <li>
                  No realizar actos contrarios a la propiedad intelectual y/o
                  cualquier otra norma lega
                </li>
                <li>
                  No realizar accesos desde redes públicas y/o equipos
                  personales no seguros
                </li>
                <li>Realizar conductas contrarias a la moral </li>
              </ol>
              Entienden los terceros que usen este portal y aquellos a los
              cuales se autorice el acceso a ciertas funcionalidades presentes
              en este portal web que en caso de incumplimiento de los deberes,
              obligaciones y prohibiciones aquí enunciados, según su gravedad,
              {filialLegalShortNameUpper} podrá dar lugar as las acciones
              contractuales y/o legales a que haya lugar. Lo anterior, sin
              perjuicio de las acciones que terceros afectados puedan iniciar
              contra quien incumpla lo aquí dispuesto.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          ref={expanded === TERMS.ownership ? scrollRef : null}
          square
          expanded={expanded === TERMS.ownership}
          onChange={handleChange(TERMS.ownership)}
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded
          }}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            classes={{
              root: classes.expansionPanelSummary,
              expanded: classes.expansionPanelSummaryExpanded
            }}
          >
            <Fragment>
              {displayIcon(TERMS.ownership)}
              <Typography variant="h2" className={classes.title}>
                Propiedad Intelectual
              </Typography>
            </Fragment>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.expansionPanelDetails
            }}
          >
            <Typography className={classes.description}>
              {filialLegalShortNameUpper} expresa a través de esta política su
              respeto y acatamiento a las normas de propiedad intelectual y en
              este sentido, exigirá a toda persona que use este portal web el
              respeto a la propiedad intelectual de las creaciones que estén
              presentes en este, sea que estén protegidas por el régimen de
              derechos de autor o propiedad industrial.
              <br /> <br />
              {filialLegalShortName} declara que esta en disposición de recibir
              cualquier queja relacionada con presuntas infracciones a la
              propiedad intelectual que terceros identifiquen, esto en su
              compromiso irrestricto con la protección de toda creación
              protegidas bajo el mencionado régimen. <br /> <br />{' '}
              {filialLegalShortName} , espera un trato respetuoso por parte de
              terceros que usen este portal web, accedan y/o conozcan las
              creaciones intelectuales presentes en este portal web, sea
              derechos de propiedad de terceros y/o de {filialLegalShortName}
              .
              <br /> <br />
              Por tanto, se prohíbe que terceros usen, usufructúen y/o aproveche
              cualquier creación intelectual presente en este portal web, en
              tanto que todas las presentes en este portal web están protegidas
              por la propiedad intelectual.
              <br /> <br />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          ref={expanded === TERMS.privacy ? scrollRef : null}
          square
          expanded={expanded === TERMS.privacy}
          onChange={handleChange(TERMS.privacy)}
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded
          }}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            classes={{
              root: classes.expansionPanelSummary,
              expanded: classes.expansionPanelSummaryExpanded
            }}
          >
            <Fragment>
              {displayIcon(TERMS.privacy)}
              <Typography variant="h2" className={classes.title}>
                Tratamiento de la Información Personal
              </Typography>
            </Fragment>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.expansionPanelDetails
            }}
          >
            <Typography className={classes.description}>
              <b>Política de Privacidad</b>
              <br /> <br />
              En cumplimiento del régimen de protección de datos personales
              vigente en Colombia esta organización adoptó una Política de
              privacidad la cual constituye la declaratoria frente al
              tratamiento de datos personales que recolecte y trate conforme las
              autorización que de forma expresa otorguen los titulares o los
              originados en las autorizaciones de ley. La política de privacidad
              puede ser consultada en el siguiente enlace web:{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={PrivacyPoliciesLink}
              >
                {PrivacyPoliciesLink}
              </a>
              . En este portal web, según las finalidades, se informaran a los
              titulares los avisos de privacidad que incorporan la respectiva
              autorización para el tratamiento de datos personales
              <br /> <br />
              <b>Registro en el portal web</b>
              <br /> <br />
              La necesidad de otorgar seguridad a ciertas actividades que se
              realicen en nuestro portal web exige la plena identificación de
              las personas para realizar estas, datos además necesarios para la
              gestión de los mismos y la prevención de fraudes.
              <br /> <br />
              Los datos personales que se solicitan al momento del registro de
              usuarios en nuestro portal serán protegidos de forma adecuada y su
              tratamiento se someterá a los principios aplicables a su
              naturaleza.
              <br /> <br />
              {filialLegalShortName} como titular de este portal web se reserva
              el derecho de mantener vigente el registro de un usuario cuando
              este no haya suministrado información veraz, cuando trate la
              información en forma contraria a los usos honestos que debe darse
              a ella o cuando incumpla las políticas aquí contenidas o
              cualquiera de las obligaciones, deberes y cargas que adquiere al
              momento de registrarse en el portal web.
              <br /> <br />
              {filialLegalShortName} durante el proceso de registro no
              solicitará información de tarjetas crédito ni débito; ni datos
              personales sensibles.
              <br /> <br />
              Las datos personales recolectados serán protegidos mediante
              medidas de seguridad adecuadas considerando un enfoque de riesgos.
              <br /> <br />
              <b>Derecho a la Intimidad Personal y Habeas Data</b> <br /> <br />
              El proceso de recolectar y tratar datos personales en este portal
              web respeta el derecho a la intimidad y garantiza el ejercicio del
              habeas data de quienes usan, visitan y/o se registran en este
              portal web, actividad que se realiza con sujeción a la
              Constitución Política de Colombia y prácticas internacionales en
              materia de protección de datos personales.
              <br /> <br />
              <b>Finalidad de la información recolectada</b>
              <br /> <br />
              Los datos recolectados y suministrados por las personas que se
              registran en este portal web se entienden realizados de forma
              libre, voluntaria y autónoma y tiene como finalidad facilitar la
              gestión de las actividades y/o transacciones que deban ser
              realizadas en este portal de acuerdo con las funcionalidades
              existentes en este portal web; así como para mantener informadas a
              las personas registradas sobre los tramites, prestación de los
              servicios públicos, actividades empresariales y publicitarias,
              programas dirigidos a los grupos de interés, servicios y/o
              productos complementarios a los servicios públicos que presta
              {filialLegalShortName}
              .
              <br /> <br />
              <b>Responsabilidad en el suministro de la información </b>
              <br /> <br />
              {filialLegalShortName} informa de manera debida a las personas que
              tengan interés en registrarse en nuestro portal web para realizar
              actividades y/o transacciones a través de él, que el suministro de
              los datos solicitados es de su entera responsabilidad, razón por
              la cual éstos deben ser veraces y fidedignos. Este acto propio de
              cada persona que se registre exime de cualquier responsabilidad a
              {filialLegalShortName} por la calidad de los mismos.{' '}
              {filialLegalShortName} asume de buena fe que la información
              suministrada es provista por el titular del dato, esta
              actualizada, es exacta, veraz y fidedigna.
              <br /> <br />
              Las personas que se registren serán responsables por cualquier
              dato inexacto, falso o carente de veracidad que suministre,
              circunstancia que podrá dar lugar a resarcir los perjuicios que
              llegare a causar con este comportamiento a {
                filialLegalShortName
              }{' '}
              o terceros.
              <br /> <br />
              La persona que use datos personales que no sean propios será
              responsable de las sanciones que la ley colombiana establece.
              <br /> <br />
              <b>Ejercicio del Habeas Data</b>
              <br /> <br />
              El usuario registrado en el portal web{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={MainSiteUrlLink}
              >
                {filialLandingUrl}
              </a>{' '}
              podrá ejercer su derecho de conocer, actualizar, modificar,
              oponerse y eliminar los datos personales existentes en las bases
              de datos asociadas a nuestro portal web. Este derecho podrá
              realizarlo a través del siguiente correo electrónico:
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={HabeasDataEmail}
              >
                {HabeasDataEmail}{' '}
              </a>
              <br /> <br />
              <b>Tratamiento de la Información</b>
              <br /> <br />
              {filialLegalShortName} es responsable del tratamiento de la
              información personal recolectada a través del portal web,
              responsabilidad que podrá delegar en un tercero, asegurando
              contractualmente la seguridad de la información personal entregada
              para el respectivo tratamiento conforme a una finalidad concreta.
              <br /> <br />
              Los datos e información recolectada por {filialLegalShortName} en
              sus bases de datos podrán ser objeto de análisis para fines de
              mejorar la estrategia corporativa, apoyada en herramientas de
              inteligencia de negocios, minería de datos y analítica, que
              permiten adquirir conocimientos prospectivos para fines de
              predicción, clasificación y segmentación, cuando así se considere
              necesario a la luz de un interés legítimo. El análisis de la
              información y el valor agregado que generemos a ésta se hará con
              estricto respeto a los derechos de las personas y creación de
              valor para nuestros grupos de interés.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          ref={expanded === TERMS.ecommerce ? scrollRef : null}
          square
          expanded={expanded === TERMS.ecommerce}
          onChange={handleChange(TERMS.ecommerce)}
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded
          }}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            classes={{
              root: classes.expansionPanelSummary,
              expanded: classes.expansionPanelSummaryExpanded
            }}
          >
            <Fragment>
              {displayIcon(TERMS.ecommerce)}
              <Typography variant="h2" className={classes.title}>
                Comercio Electrónico
              </Typography>
            </Fragment>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.expansionPanelDetails
            }}
          >
            <Typography className={classes.description}>
              En cumplimiento de las disposiciones colombianas sobre mensajes de
              datos según la ley 527 de 1999, se comunica que la legislación
              nacional reconoce validez a los mensajes de datos y por tanto
              ellos adquieren carácter y entidad probatoria. En consecuencia,
              entienden las personas que realizan actividades, tramites y/o
              transacciones en este portal web que mediante el cruce de mensajes
              de datos se pueden adquirir obligaciones y/o ejercer derechos,
              siendo de su resorte exclusivo el contenido, responsabilidades y
              efectos de la información generada.
              <br /> <br />
              A través de nuestro portal web podrán anunciarse enlaces o
              hipervínculos a otros portales web de personas con las cuales
              puedan existir alguna relación que pueden ser de interés de
              nuestros grupos de interés. Para efectos de prevenir fraudes
              informáticos se sugiere que el usuario digite en la URL de su
              navegador la dirección IP o nombre de dominio del sitio web que
              desea visitar, no obstante pueda realizar el acceso desde el
              portal.
              <br /> <br />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          ref={expanded === TERMS.publicity ? scrollRef : null}
          square
          expanded={expanded === TERMS.publicity}
          onChange={handleChange(TERMS.publicity)}
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded
          }}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            classes={{
              root: classes.expansionPanelSummary,
              expanded: classes.expansionPanelSummaryExpanded
            }}
          >
            <Fragment>
              {displayIcon(TERMS.publicity)}
              <Typography variant="h2" className={classes.title}>
                Publicidad
              </Typography>
            </Fragment>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.expansionPanelDetails
            }}
          >
            <Typography className={classes.description}>
              {filialLegalShortName} podrá comunicar información comercial y
              publicitaria, identificándola como tal, a los usuarios del portal
              que lo hayan autorizado al momento del registro y/o actualización
              de sus datos. La información comercial y publicitaria que se
              remita será leal e indicará las condiciones en las cuales proceda
              una oferta, rebaja, concurso o premio.
              <br /> <br />
              El mensaje de datos que trasmita información comercial o
              publicitaria en los términos aquí expresados, permitirá la opción
              de que se excluya el usuario, opción que también se tendrá en
              cualquier momento para la actualización de los datos de cada
              usuario en el registro.
              <br /> <br />
              La información comercial y publicitaria que llegue a comunicarse a
              los usuarios de nuestro portal estará en marcada en la ley, la
              moral y las buenas costumbres.
              <br /> <br />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          ref={expanded === TERMS.registers ? scrollRef : null}
          square
          expanded={expanded === TERMS.registers}
          onChange={handleChange(TERMS.registers)}
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded
          }}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            classes={{
              root: classes.expansionPanelSummary,
              expanded: classes.expansionPanelSummaryExpanded
            }}
          >
            <Fragment>
              {displayIcon(TERMS.registers)}
              <Typography variant="h2" className={classes.title}>
                Registro de Menores de Edad
              </Typography>
            </Fragment>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.expansionPanelDetails
            }}
          >
            <Typography className={classes.description}>
              {filialLegalShortName} en cumplimiento de la ley 1098 de 2006
              sobre infancia y adolescencia, y del derecho de asociación y de
              información, reconoce que los menores de edad tienen la
              posibilidad eventual de realizar ciertas actividades en este
              portal web, con las limitaciones que establezca la ley en cada
              caso. Lo anterior sin perjuicio de las decisiones que adopten sus
              padres, tutores o representantes.
              <br /> <br />
              {filialLegalShortName} asume la obligación de respetar y brindar
              las garantías para que los menores de edad, que tengan la
              condición de usuarios autorizados de nuestro portal web, puedan
              ejercer su derecho de libertad de expresión, de libre desarrollo
              de la personalidad y de información, como lo consagra la ley 1098
              de 2006.
              <br /> <br />
              En cumplimiento de la normatividad colombiana respecto de menores
              de edad (niños y adolescentes) y del proceder responsable a que
              está obligada {filialLegalShortName} para con la sociedad,
              asumimos los siguientes compromisos:
              <br /> <br />
              <ol>
                <li>
                  Excluir de nuestro sistema de información a cualquier persona
                  menor de edad, que haya afirmado tener una edad superior a
                  está, en el momento de registrarse como usuario.
                </li>
                <li>
                  Dar a conocer a las autoridades de cualquier situación
                  delictiva de la que tenga conocimiento que ponga en peligro la
                  integridad de un menor de edad. Para ello brindara toda la
                  colaboración que requieran los órganos de seguridad del
                  Estado.Dar a conocer a las autoridades de cualquier situación
                  delictiva de la que tenga conocimiento que ponga en peligro la
                  integridad de un menor de edad. Para ello brindara toda la
                  colaboración que requieran los órganos de seguridad del
                  Estado.
                </li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          ref={expanded === TERMS.responsibility ? scrollRef : null}
          square
          expanded={expanded === TERMS.responsibility}
          onChange={handleChange(TERMS.responsibility)}
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded
          }}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            classes={{
              root: classes.expansionPanelSummary,
              expanded: classes.expansionPanelSummaryExpanded
            }}
          >
            <Fragment>
              {displayIcon(TERMS.responsibility)}
              <Typography variant="h2" className={classes.title}>
                Reponsabilidad de {filialLegalShortName}{' '}
              </Typography>
            </Fragment>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.expansionPanelDetails
            }}
          >
            <Typography className={classes.description}>
              La información comunicada en este portal web esta fundada en la
              buena fe y hace referencia a aspectos generales relacionados con
              el servicio público de gas natural y servicios complementarios. En
              este orden de ideas, en caso de que una persona tenga dudas sobre
              algún aspecto particular se recomienda establecer contacto con
              {filialLegalShortName} para aclarar cualquier duda y así formar el
              consentimiento que sea necesario para cualquier actividad, tramite
              y/o transacción.
              <br /> <br />
              {filialLegalShortName} no es responsable de los daños que terceros
              lleguen a causar a las personas, así como tampoco es responsable
              de las acciones que visitantes, usuarios, proveedores, aliados y/o
              personas que se registren en este portal web realicen sin
              autorización expresa de esta organización.
              <br /> <br />
              {filialLegalShortName} en caso de tener conocimiento o ser
              informado acerca del comportamiento de personas que remitan
              mensajes de datos ofensivos, discriminatorios y/o contrarios a la
              ley, la moral y buenas costumbres procederá a bloquear
              temporalmente la cuenta de registro de esta persona, hasta tanto
              no se aclare tal situación. De reincidir en la conducta,{' '}
              {filialLegalShortName} bloqueara el acceso de forma definitiva al
              infractor.
              <br /> <br />
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          ref={expanded === TERMS.cookies ? scrollRef : null}
          square
          expanded={expanded === TERMS.cookies}
          onChange={handleChange(TERMS.cookies)}
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded
          }}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            classes={{
              root: classes.expansionPanelSummary,
              expanded: classes.expansionPanelSummaryExpanded
            }}
          >
            <Fragment>
              {displayIcon(TERMS.cookies)}
              <Typography variant="h2" className={classes.title}>
                Cookies
              </Typography>
            </Fragment>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.expansionPanelDetails
            }}
          >
            <Typography className={classes.description}>
              En relación con la política de Cookies esta puede ser consultada
              en el siguiente enlace:{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={CookiesPoliciesLink}
              >
                {CookiesPoliciesLink}
              </a>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          ref={expanded === TERMS.resolves ? scrollRef : null}
          square
          expanded={expanded === TERMS.resolves}
          onChange={handleChange(TERMS.resolves)}
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded
          }}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            classes={{
              root: classes.expansionPanelSummary,
              expanded: classes.expansionPanelSummaryExpanded
            }}
          >
            <Fragment>
              {displayIcon(TERMS.resolves)}
              <Typography variant="h2" className={classes.title}>
                Solución de Controversias
              </Typography>
            </Fragment>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.expansionPanelDetails
            }}
          >
            <Typography className={classes.description}>
              {filialLegalShortName} promoverá que las controversias que surjan
              como consecuencia de cualquier actividad, tramite y/o transacción
              realizadas a través del portal web, sean resueltas de manera
              amigable, para ello expresa la disposición de atender cualquier
              queja, petición o reclamo presentado por una persona.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          ref={expanded === TERMS.cooperation ? scrollRef : null}
          square
          expanded={expanded === TERMS.cooperation}
          onChange={handleChange(TERMS.cooperation)}
          classes={{
            root: classes.expansionPanel,
            expanded: classes.expansionPanelExpanded
          }}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            classes={{
              root: classes.expansionPanelSummary,
              expanded: classes.expansionPanelSummaryExpanded
            }}
          >
            <Fragment>
              {displayIcon(TERMS.cooperation)}
              <Typography variant="h2" className={classes.title}>
                Colaboración con Autoridades
              </Typography>
            </Fragment>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.expansionPanelDetails
            }}
          >
            <Typography className={classes.description}>
              {filialLegalShortName} expresa de manera irrevocable su compromiso
              de colaborar con las autoridades nacionales o internacionales
              cuando estas lo requieran en virtud de una solicitud expresa de
              información, evento en el cual procederá en la manera como la ley
              lo establezca, siempre y cuando ello no viole ninguna garantía
              constitucional vinculada a las libertades públicas de las
              personas. En caso de duda, {filialLegalShortName} se someterá a
              las decisiones que ordenen las autoridades colombianas.
              <br /> <br />
              En este sentido, cuando las autoridades competentes en
              cumplimiento de la ley lo soliciten y sea procedente y pertinente
              la información solicitada, {filialLegalShortName} entregará la
              requerida, advirtiendo el deber de seguridad que sobre la misma
              deben asumir estas autoridades.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </>
    </Fragment>
  );
};

export default TermsAndConditionsGDC;
