import React from 'react';

const OthersContractsIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 56}
    height={props.size || 56}
    viewBox="0 0 56 56"
    xmlSpace="preserve"
    {...props}
  >
    <rect
      id="Rectángulo_241"
      data-name="Rectángulo 241"
      width="56"
      height="56"
      fill="none"
    />
    <g id="flame-outline" transform="translate(-97.846 -40)">
      <path
        id="Trazado_767"
        data-name="Trazado 767"
        d="M112,82.574C112,70.753,127.762,61.6,124.2,48c8.389,0,24.405,12.2,24.405,34.574a18.3,18.3,0,0,1-36.608,0Z"
        transform="translate(-4.958 -6.439)"
        fill="none"
        stroke="#104eb2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        id="Trazado_768"
        data-name="Trazado 768"
        d="M208.27,256.27c0,7.336-4.068,10.169-8.135,10.169S192,263.606,192,256.27s5.084-10.931,4.068-16.27C201.406,240,208.27,248.935,208.27,256.27Z"
        transform="translate(-74.538 -172)"
        fill="none"
        stroke="#104eb2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default OthersContractsIcon;
