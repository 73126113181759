import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import CloseIcon from '../CustomIcons/CloseIcon';
import BaseButton from '../Buttons/BaseButton';
import FlatButton from '../Buttons/FlatButton';

const CallToActionDialog = ({
  handleClose,
  handleConfirmClick,
  handleDismissClick,
  confirmButtonText,
  dismissButtonText,
  icon: Icon = null,
  title,
  message,
  subMessage,
  nodeBetweenMessages,
  buttonProps,
  dismissButtonBaseText,
  dismissButtonProps,
  footer,
  disableBackdropClick = false,
  paperClass = {},
  variant = 'outlined',
  iconStyle = null,
  imageSrc = null,
  imageStyle = null,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Dialog
      disableBackdropClick={disableBackdropClick}
      onClose={handleClose}
      classes={{
        paper: clsx(classes.paper, paperClass)
      }}
      {...rest}
    >
      <DialogTitle className={classes.dialogTitle}>
        <IconButton
          id="close"
          size="small"
          aria-label="cerrar diálogo"
          onClick={handleClose}
          className={classes.titleButton}
        >
          <CloseIcon size={15} className={classes.titleIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className={classes.emptyStateContainer}
        >
          {Icon && <Icon className={clsx(classes.icon, iconStyle)} />}
          {imageSrc && (
            <img
              src={imageSrc}
              alt="call-to-action-background"
              className={clsx(classes.image, imageStyle)}
            />
          )}
          {(title || message || subMessage) && (
            <div className={classes.text}>
              {title && (
                <Typography
                  component="h6"
                  variant="h6"
                  className={classes.title}
                >
                  {title}
                </Typography>
              )}
              {message && (
                <Typography className={classes.message}>{message}</Typography>
              )}
              {nodeBetweenMessages}
              {subMessage && (
                <Typography
                  className={clsx(classes.subMessage, classes.message)}
                >
                  {subMessage}
                </Typography>
              )}
            </div>
          )}
          {footer && footer}
          {confirmButtonText && (
            <BaseButton
              className={classes.confirmButton}
              size="small"
              onClick={handleConfirmClick}
              variant={variant}
              {...buttonProps}
            >
              {confirmButtonText}
            </BaseButton>
          )}
          {dismissButtonText && (
            <FlatButton
              className={classes.dismissButton}
              onClick={handleDismissClick}
            >
              {dismissButtonText}
            </FlatButton>
          )}
          {dismissButtonBaseText && (
            <BaseButton
              className={classes.confirmButton}
              size="small"
              onClick={handleDismissClick}
              {...dismissButtonProps}
            >
              {dismissButtonBaseText}
            </BaseButton>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: theme.custom.borderRadius,
    overflowX: 'hidden',
    maxWidth: 450,
    width: '95%',
    fontSize: 16,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 15
    }
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0.6em 0.6em 0.3em'
  },
  titleButton: {
    padding: theme.spacing(1.5)
  },
  titleIcon: {
    fill: theme.palette.primary.main
  },
  dialogContent: {
    padding: '0.3em 2em 2em',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: '0.3em 1.25em 2em'
    }
  },
  icon: {
    maxWidth: '100%',
    height: 'auto'
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: theme.custom.borderRadius
  },
  title: {
    lineHeight: 1.2,
    marginTop: theme.spacing(2)
  },
  text: {
    textAlign: 'center'
  },
  message: {
    lineHeight: 1.2,
    marginTop: theme.spacing(2)
  },
  subMessage: {
    color: '#757B86',
    fontSize: '0.75em',
    marginTop: theme.spacing(1.5)
  },
  confirmButton: {
    fontSize: '0.8em',
    marginTop: theme.spacing(2),
    minWidth: '35%'
  },
  dismissButton: {
    fontSize: '0.8em',
    marginTop: theme.spacing(2),
    '&:hover': {
      color: theme.palette.text.primary
    }
  }
}));

export default CallToActionDialog;
