import React from 'react';

const BigOvenIcon = props => {
  const {
    classes = {
      animation: {
        heater: {
          left: undefined,
          right: undefined
        },
        handler: {
          stick1: undefined,
          stick2: undefined,
          stick3: undefined,
          stick4: undefined
        }
      }
    },
    ...rest
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 800}
      height={props.height || 800}
      viewBox="0 0 800 800"
      {...rest}
    >
      <g id="group-heaters">
        <path
          className={classes.animation.heater.left}
          id="heater-left"
          fill="#58595c"
          d="M 171,50
                            L 303,50
                            A 1,1 0 0 1 303,76
                            L 171,76
                            A 1,1 0 0 1 171,50
                            z"
        />
        <path
          className={classes.animation.heater.right}
          id="heater-right"
          fill="#58595c"
          d="M 495,50
                            L 627,50
                            A 1,1 0 0 1 627,76
                            L 495,76
                            A 1,1 0 0 1 495,50
                            z"
        />
      </g>

      <path
        id="rounded-rect-upper"
        fill="#f4f4f4"
        d="M 125,200
           L 125,85
           A 15,15 0 0 1 135,75
           L 665,75
           A 15,15 0 0 1 675,85
           L 675,200
           z"
      />

      <g id="group-handlers">
        <circle id="handler-shadow-1" fill="#7B7F81" cx="193" cy="144" r="38" />
        <circle id="handler-body-1" fill="#E6E7E9" cx="193" cy="140" r="38" />
        <path
          className={classes.animation.handler.stick1}
          id="handler-stick-1"
          fill="#A8A9AB"
          d="M 184,144
                            L 184,104
                            A 6,1 0 0 1 202,104
                            L 202,141
                            A 1,1 0 0 1 184,141
                            z"
        />

        <circle id="handler-shadow-2" fill="#7B7F81" cx="286" cy="144" r="38" />
        <circle id="handler-body-2" fill="#E6E7E9" cx="286" cy="140" r="38" />
        <path
          className={classes.animation.handler.stick2}
          id="handler-stick-2"
          fill="#A8A9AB"
          d="M 277,144
                            L 277,104
                            A 6,1 0 0 1 295,104
                            L 295,141
                            A 1,1 0 0 1 277,141
                            z"
        />

        <circle id="handler-shadow-3" fill="#7B7F81" cx="514" cy="144" r="38" />
        <circle id="handler-body-3" fill="#E6E7E9" cx="514" cy="140" r="38" />
        <path
          className={classes.animation.handler.stick3}
          id="handler-stick-3"
          fill="#A8A9AB"
          d="M 505,144
                            L 505,104
                            A 6,1 0 0 1 523,104
                            L 523,141
                            A 1,1 0 0 1 505,141
                            z"
        />

        <circle id="handler-shadow-4" fill="#7B7F81" cx="607" cy="144" r="38" />
        <circle id="handler-body-4" fill="#E6E7E9" cx="607" cy="140" r="38" />
        <path
          className={classes.animation.handler.stick4}
          id="handler-stick-4"
          fill="#A8A9AB"
          d="M 598,144
                            L 598,104
                            A 6,1 0 0 1 616,104
                            L 616,141
                            A 1,1 0 0 1 598,141
                            z"
        />
      </g>

      <rect
        id="line-full-width-gray"
        fill="#C6C8C7"
        x="125"
        y="200"
        width="550"
        height="6px"
      />

      <g id="group-line-upper">
        <rect
          id="line-upper-left"
          fill="white"
          x="125"
          y="206"
          width="65"
          height="6px"
        />
        <rect
          id="line-upper-center"
          fill="#818385"
          x="190"
          y="206"
          width="420"
          height="6px"
        />
        <rect
          id="line-upper-right"
          fill="white"
          x="610"
          y="206"
          width="65"
          height="6px"
        />
      </g>

      <rect
        id="body-left"
        fill="#f4f4f4"
        x="125"
        y="212"
        width="65"
        height="395px"
      />

      <g id="group-body-center">
        <rect
          id="body-center"
          fill="#bfc5c3"
          x="190"
          y="212"
          width="420"
          height="395px"
        />
        <path
          fill="#444444"
          id="body-center-handler-shadow"
          d="M 257,253
                  L 543,253
                  A 1,1 0 0 1 543,283
                  L 257,283
                  A 1,1 0 0 1 257,253
                  z"
        />
        <path
          fill="#f4f4f4"
          id="body-center-handler"
          d="M 257,240
                  L 543,240
                  A 1,1 0 0 1 543,270
                  L 257,270
                  A 1,1 0 0 1 257,240
                  z"
        />
      </g>

      <rect
        id="body-right"
        fill="#f4f4f4"
        x="610"
        y="212"
        width="65"
        height="395px"
      />

      <g d="group-line-lower">
        <rect
          id="line-lower-left"
          fill="#BFC5C3"
          x="125"
          y="607"
          width="65"
          height="6px"
        />
        <rect
          id="line-lower-center"
          fill="#444444"
          x="190"
          y="607"
          width="420"
          height="6px"
        />
        <rect
          id="line-lower-right"
          fill="#BFC5C3"
          x="610"
          y="607"
          width="65"
          height="6px"
        />
      </g>

      <rect
        id="line-full-width-white"
        fill="white"
        x="125"
        y="613"
        width="550"
        height="6px"
      />

      <path
        id="rounded-rect-lower-shadow"
        fill="#A1ADAA"
        d="M 125,728
           L 675,728
           L 675,742
           A 15,15 0 0 1 665,752
           L 135,752
           A 15,15 0 0 1 125,742
           z"
      />

      <path
        id="rounded-rect-lower"
        fill="#f4f4f4"
        d="M 125,619
           L 675,618
           L 675,728
           A 15,15 0 0 1 665,738
           L 135,738
           A 15,15 0 0 1 125,728
           z"
      />
    </svg>
  );
};

export default BigOvenIcon;
