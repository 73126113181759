import Decimal from 'decimal.js';
import _orderBy from 'lodash/orderBy';

export const ProductType = {
  Gas: 7014,
  FinancingServices: 7055,
  FinancingServicesPromigas: 7056,
  BrillaInsurancesA: 7053,
  BrillaInsurancesB: 7109
};

export const isProductTypeGas = type => type === ProductType.Gas;

export const isProductTypeFinancingServices = type =>
  [
    ProductType.FinancingServices,
    ProductType.FinancingServicesPromigas
  ].includes(type);

export const getProductTypeName = productTypeId => {
  switch (productTypeId) {
    case ProductType.Gas:
      return 'Gas';
    case ProductType.FinancingServices:
      return 'Servicios Financieros';
    case ProductType.FinancingServicesPromigas:
      return 'Servicios Financieros Promigas';
    case ProductType.BrillaInsurancesA:
    case ProductType.BrillaInsurancesB:
      return 'Brilla Seguros';
    default:
      return 'Otros';
  }
};

export const getProductPaymentRatio = (total, products, partialValue) => {
  const productsLength = products.length;

  // If there is only one product, return the total for this product
  if (productsLength === 1) {
    return [{ ...products[0], rate: 1, proportionalValue: partialValue }];
  }

  let remainingRatio = new Decimal(1);
  let remainingValue = partialValue;

  const sortedProducts = _orderBy(products, ['value'], ['desc']);
  const productsWithRate = sortedProducts.map((product, index) => {
    // Take just 3 significant digits, i.e., 0.0278903254 to 0.027
    // and round down it (second argument 1 for Decimal.ROUND_DOWN)
    let rate = new Decimal(`${product.value / total}`).toDP(3, 1);

    // Calculate the partial value that corresponds to this product
    // according to its weight in the total and take the integer part
    const proportionalValue = new Decimal(partialValue).mul(rate).floor();

    // The calculated rate is subtracted from the total to keep track,
    // the same with the partial payment amount
    remainingRatio = remainingRatio.minus(rate);
    remainingValue = remainingValue - parseInt(proportionalValue, 10);

    // The rest of the remaining rate is added to the last product,
    // which has the lowest value. This is to avoid cases in which
    // the rate of one product, or the sum of several, is 0.99
    // and the rate of the remaining product is much less than 0.01, i.e., 0.001.
    if (index === productsLength - 1) {
      rate = rate.add(remainingRatio);
    }

    return {
      ...product,
      rate: parseFloat(rate.toString()),
      proportionalValue: parseInt(proportionalValue, 10)
    };
  });

  // If for any reason, there is remaining value of partial value,
  // it will be added to the first product, which is the one with the highest value
  if (remainingValue > 0) {
    const { proportionalValue } = productsWithRate[0];

    productsWithRate[0] = {
      ...productsWithRate[0],
      proportionalValue: proportionalValue + remainingValue
    };
  }

  return productsWithRate;
};

export const ValidPartialPaymentProductTypeIds = [
  ProductType.Gas,
  ProductType.FinancingServices,
  ProductType.BrillaInsurancesA
];
