import React from 'react';

const CalendarIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 21}
    height={props.height || 21}
    viewBox="0 0 21 21"
    {...props}
  >
    <g transform="translate(-47.5 .5)">
      <rect
        data-name="Rect\xE1ngulo 1641"
        width={20}
        height={18.462}
        rx={2}
        transform="translate(48 1.538)"
        fill="none"
        stroke={props.fill || '#747b87'}
        strokeLinejoin="round"
      />
      <circle
        data-name="Elipse 200"
        cx={1.154}
        cy={1.154}
        transform="translate(58.769 7.692)"
        fill={props.fill || '#747b87'}
        r={1.154}
      />
      <circle
        data-name="Elipse 201"
        cx={1.154}
        cy={1.154}
        transform="translate(62.615 7.692)"
        fill={props.fill || '#747b87'}
        r={1.154}
      />
      <circle
        data-name="Elipse 202"
        cx={1.154}
        cy={1.154}
        transform="translate(58.769 11.538)"
        fill={props.fill || '#747b87'}
        r={1.154}
      />
      <circle
        data-name="Elipse 203"
        cx={1.154}
        cy={1.154}
        transform="translate(62.615 11.538)"
        fill={props.fill || '#747b87'}
        r={1.154}
      />
      <circle
        data-name="Elipse 204"
        cx={1.154}
        cy={1.154}
        transform="translate(51.077 11.538)"
        fill={props.fill || '#747b87'}
        r={1.154}
      />
      <circle
        data-name="Elipse 205"
        cx={1.154}
        cy={1.154}
        transform="translate(54.923 11.538)"
        fill={props.fill || '#747b87'}
        r={1.154}
      />
      <circle
        data-name="Elipse 206"
        cx={1.154}
        cy={1.154}
        transform="translate(51.077 15.385)"
        fill={props.fill || '#747b87'}
        r={1.154}
      />
      <circle
        data-name="Elipse 207"
        cx={1.154}
        cy={1.154}
        transform="translate(54.923 15.385)"
        fill={props.fill || '#747b87'}
        r={1.154}
      />
      <circle
        data-name="Elipse 208"
        cx={1.154}
        cy={1.154}
        transform="translate(58.769 15.385)"
        fill={props.fill || '#747b87'}
        r={1.154}
      />
      <path
        data-name="Trazado 661"
        d="M51.846 0v1.538M64.154 0v1.538"
        fill="none"
        stroke={props.fill || '#747b87'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        data-name="Trazado 662"
        d="M68 5.385H48"
        fill="none"
        stroke={props.fill || '#747b87'}
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export default CalendarIcon;
