import axios from 'axios';
import { config } from '../Configs';

export const GoogleLoginAPI = async (
  idToken,
  authToken = null,
  fromUpdate = false
) => {
  try {
    const configObj = {};
    if (authToken !== null) {
      configObj.headers = { Authorization: authToken };
    }
    const response = await axios.post(
      `${config.api.url}/linked_auth/google`,
      {
        idToken,
        fromUpdate
      },
      configObj
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const FacebookLoginAPI = async (idToken, authToken = null) => {
  try {
    const configObj = {};
    if (authToken !== null) {
      configObj.headers = { Authorization: authToken };
    }
    const response = await axios.post(
      `${config.api.url}/linked_auth/facebook`,
      {
        idToken
      },
      configObj
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const AppleLoginAPI = async (userData, authToken = null) => {
  try {
    const configObj = {};
    if (authToken !== null) {
      configObj.headers = { Authorization: authToken };
    }
    const response = await axios.post(
      `${config.api.url}/linked_auth/apple`,
      {
        ...userData,
        clientId: config.apple.clientId
      },
      configObj
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const RevokeAppleTokenAPI = async (userData, authToken = null) => {
  try {
    const configObj = {};
    if (authToken !== null) {
      configObj.headers = { Authorization: authToken };
    }
    const response = await axios.put(
      `${config.api.url}/linked_auth/revoke_apple_token`,
      {
        ...userData,
        clientId: config.apple.clientId
      },
      configObj
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const UnlinkAccountAPI = async (data, token) => {
  try {
    const response = await axios.put(
      `${config.api.url}/linked_auth/unlink`,
      data,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};
