import { saveAs } from 'file-saver';
import { downloadPdfOnAndroid } from '../Android/actions';
import { downloadPdfOnIOS } from '../IOS/actions';

export function base64toBlob(base64Data, contentType) {
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);
  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);
    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType || '' });
}

export function saveAsPDF(base64, name) {
  downloadPdfOnAndroid(base64, name);
  downloadPdfOnIOS(base64, name);
  /**
   * saveAs doesn't work for IE 10+ so a workaround to download
   * the file was implemented
   */
  const file = base64toBlob(base64);
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    const newBlob = new Blob([file], { type: 'application/pdf' });
    window.navigator.msSaveOrOpenBlob(newBlob, name);
  } else {
    saveAs(URL.createObjectURL(file), name);
  }
}
