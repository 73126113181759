import React, {
  Fragment,
  useState,
  useRef,
  useCallback,
  useEffect,
  useContext
} from 'react';
import _get from 'lodash/get';

import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import SimpleDivider from '../../../../../Components/Dividers/SimpleDivider';
import ModalProgress from '../../../../../Components/Progress/Modal/ModalProgress';
import BaseButton from '../../../../../Components/Buttons/BaseButton';
import BaseDialog from '../../../../../Components/Dialogs/BaseDialog';

import { AlertsDispatchContext } from '../../../../../Contexts/AlertsContext';

import IndividualLoad from '../LoadTypes/IndividualLoad/IndividualLoad';
import MultipleLoad from '../LoadTypes/MultipleLoad/MultipleLoad';
import PaymentAccordion from '../CustomComponents/PaymentAccordion';
import SimpleTabs from '../../../Tabs/CustomTabs';
import { getTotalAmount } from '../../../helpers/helpers';
import {
  INDIVIDUAL_LOAD_INITIAL_STATE,
  MULTIPLE_LOAD_INITIAL_STATE
} from '../../../payment_enums';

// * Utils
import { moneyWithDots } from '../../../../../Utils/Format/MoneyFormat';
import { COUPON_VALUES } from '../../../../../Utils/enums';
import { Company } from '../../../../../Configs/general';

const MultiplePaymentGDG = props => {
  const {
    currentStep,
    number,
    setNumber,
    type,
    setType,
    payments,
    setPayments,
    cardIndividualLoad,
    setCardIndividualLoad,
    cardMultipleLoad,
    setCardMultipleLoad,
    totalIndividualCards,
    totalMultipleLoadCards,
    totalCurrentCards,
    currentTabIndex,
    setCurrentTabIndex,
    authToken
  } = props;

  const setAlert = useContext(AlertsDispatchContext);

  // * STATE HOOKS;
  const [captcha, setCaptcha] = useState(null);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const [loading, setLoading] = useState(false);
  const [firstAccordionExpanded, setFirstAccordionExpanded] = useState(false);
  const [secondAccordionExpanded, setSecondAccordionExpanded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const inputRef = useRef(null);
  const recaptchaRef = useRef(null);
  const inputMultipleLoadRef = useRef(null);
  const recaptchaMultipleLoadRef = useRef(null);

  const canSubmitIndividual = !loading && captcha && number && type;
  const canSubmitMultiple = !loading && captcha;

  // * OTHER HOOKS
  const classes = useStyles();

  useEffect(() => {
    const totalLoadingIndividual = cardIndividualLoad.loading.length;
    const totalLoadingMultiple = cardMultipleLoad.loading.length;
    const validTransactions =
      totalLoadingIndividual + totalLoadingMultiple === 0 &&
      (cardIndividualLoad.toPay.length > 0 ||
        cardMultipleLoad.toPay.length > 0);

    const formIsEmpty = loading || !validTransactions || !type;

    if (formIsEmpty) {
      props.setDisabledNext(true);
    } else {
      // PayU does not allow us to pay coupons with such a small value (100 COP),
      // so disable the Next button when that happens.
      let canPay = false;
      if (payments.length === 1) {
        const value =
          _get(payments[0], 'detail.couponValue') ||
          _get(payments[0], 'detail.value');
        canPay = value >= COUPON_VALUES.MIN_VALUE;
      } else {
        const totalValue = getTotalAmount([
          ...cardIndividualLoad.toPay,
          ...cardMultipleLoad.toPay
        ]);
        canPay = totalValue >= COUPON_VALUES.MIN_VALUE;
      }
      props.setDisabledNext(!canPay);
      if (!canPay) {
        setAlert({
          type: 'info',
          message: `Solo se permite realizar pagos mayores a ${COUPON_VALUES.MIN_VALUE} pesos.`
        });
      }
    }
  }, [
    type,
    number,
    loading,
    payments,
    props,
    setAlert,
    cardIndividualLoad,
    cardMultipleLoad
  ]);

  const onCaptchaResolved = useCallback(
    token => {
      setCaptcha(token);
    },
    [setCaptcha]
  );

  const onCaptchaExpired = useCallback(() => {
    setCaptcha(null);
  }, [setCaptcha]);

  const onCaptchaLoaded = useCallback(() => {
    setTimeout(() => {
      setLoadingCaptcha(false);
    }, 1000);
  }, [setLoadingCaptcha]);

  const tabProps = [
    {
      id: 0,
      label: 'Carga masiva'
    },
    {
      id: 1,
      label: 'Carga individual'
    }
  ];

  const changeTabAndRemove = () => {
    setCardIndividualLoad(INDIVIDUAL_LOAD_INITIAL_STATE);
    setCardMultipleLoad(MULTIPLE_LOAD_INITIAL_STATE);
    setPayments([]);
    setCurrentTabIndex(prev => ({ ...prev, checked: prev.noChecked }));
    setOpenDialog(false);
  };

  const handleTabChange = (_event, newValue) => {
    if (totalIndividualCards.length > 0 || totalMultipleLoadCards.length > 0) {
      setOpenDialog(true);
      setCurrentTabIndex(prev => ({ ...prev, noChecked: newValue }));
      return;
    }
    setCurrentTabIndex({ noChecked: newValue, checked: newValue });
  };

  const renderDialogActions = () => {
    return (
      <Fragment>
        <BaseButton onClick={() => changeTabAndRemove()}>Entendido</BaseButton>
      </Fragment>
    );
  };

  const renderDialogContent = useCallback(() => {
    return (
      <>
        <Typography className={classes.dialogText}>
          Al cambiar de opción,{' '}
          {Company.contractConjugation.regular.plural.article} y/o cupones que
          haya agregado serán removidos
        </Typography>
      </>
    );
  }, [classes.dialogText]);

  return (
    <Fragment>
      {loadingCaptcha && (
        <ModalProgress
          id={'QueryPayment_progress_loadingCaptcha'}
          message={'Cargando'}
        />
      )}
      <Container
        maxWidth="md"
        className={`${classes.root} ${
          loadingCaptcha ? classes.captchaLoading : ''
        }`}
      >
        {loading && (
          <ModalProgress
            id={'QueryPayment_progress_loading'}
            message={'Buscando'}
          />
        )}
        {openDialog && (
          <BaseDialog
            handleClose={() => setOpenDialog(false)}
            open={openDialog}
            title="Estimado cliente"
            actions={renderDialogActions}
            content={renderDialogContent}
            contentStyle={classes.dialogContent}
            contentSize="small"
          />
        )}
        <Typography className={classes.subtitle}>
          {currentStep.subtitle}
        </Typography>
        <SimpleTabs
          tabProps={tabProps}
          currentTabIndex={currentTabIndex.checked}
          handleChange={handleTabChange}
        >
          <MultipleLoad
            recaptchaRef={recaptchaMultipleLoadRef}
            captcha={captcha}
            setCaptcha={setCaptcha}
            canSubmit={canSubmitMultiple}
            inputRef={inputMultipleLoadRef}
            onCaptchaResolved={onCaptchaResolved}
            onCaptchaExpired={onCaptchaExpired}
            onCaptchaLoaded={onCaptchaLoaded}
            loadingCaptcha={loadingCaptcha}
            cardMultipleLoad={cardMultipleLoad}
            setCardMultipleLoad={setCardMultipleLoad}
            totalCurrentCards={totalCurrentCards}
            setFirstAccordionExpanded={setFirstAccordionExpanded}
            setSecondAccordionExpanded={setSecondAccordionExpanded}
            setCaptchaToken={setCaptchaToken}
          />
          <IndividualLoad
            setDisabledNext={props.setDisabledNext}
            setCaptcha={setCaptcha}
            number={number}
            type={type}
            setNumber={setNumber}
            setType={setType}
            setPayments={setPayments}
            recaptchaRef={recaptchaRef}
            inputRef={inputRef}
            onCaptchaResolved={onCaptchaResolved}
            onCaptchaExpired={onCaptchaExpired}
            onCaptchaLoaded={onCaptchaLoaded}
            loadingCaptcha={loadingCaptcha}
            canSubmit={canSubmitIndividual}
            setLoading={setLoading}
            loading={loading}
            cardIndividualLoad={cardIndividualLoad}
            setCardIndividualLoad={setCardIndividualLoad}
            totalCurrentCards={totalCurrentCards}
            setFirstAccordionExpanded={setFirstAccordionExpanded}
            setSecondAccordionExpanded={setSecondAccordionExpanded}
            captcha={captcha}
            setCaptchaToken={setCaptchaToken}
          />
        </SimpleTabs>
        <PaymentAccordion
          containerClass={classes.accordionContainer}
          totalIndividualCards={totalIndividualCards}
          totalMultipleLoadCards={totalMultipleLoadCards}
          setCardIndividualLoad={setCardIndividualLoad}
          cardMultipleLoad={cardMultipleLoad}
          setCardMultipleLoad={setCardMultipleLoad}
          number={number}
          payments={payments}
          setPayments={setPayments}
          firstAccordionExpanded={firstAccordionExpanded}
          setFirstAccordionExpanded={setFirstAccordionExpanded}
          secondAccordionExpanded={secondAccordionExpanded}
          setSecondAccordionExpanded={setSecondAccordionExpanded}
          setCaptcha={setCaptcha}
          captchaValue={captchaToken}
          currentTabIndex={currentTabIndex.checked}
          authToken={authToken}
        />
        <SimpleDivider withoutMargin />
        <Box className={classes.amountContainer}>
          <Typography className={classes.totalAmountLabel}>
            Total a pagar
          </Typography>
          <Typography className={classes.totalAmountText}>
            {moneyWithDots(
              getTotalAmount([
                ...cardIndividualLoad.toPay,
                ...cardMultipleLoad.toPay
              ])
            )}
          </Typography>
        </Box>
      </Container>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'space-around',
    padding: 0,
    // * Responsive
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 2)
    },
    [theme.breakpoints.up('xs')]: {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing()
    }
  },
  title: {
    fontWeight: 600,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
      padding: theme.spacing(0, 2)
    }
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.color.default,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      fontSize: 11,
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(2)
    }
  },
  captchaLoading: {
    visibility: 'hidden'
  },
  amountContainer: {
    padding: theme.spacing(0, 2),
    marginBottom: 200
  },
  totalAmountLabel: {
    color: theme.palette.color.default,
    fontSize: 14,
    fontWeight: 500,
    marginTop: theme.spacing(3),
    textAlign: 'right'
  },
  totalAmountText: {
    fontSize: 24,
    fontWeight: 600,
    textAlign: 'right'
  },
  accordionContainer: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2)
    }
  },
  dialogContent: {
    minHeight: 'unset'
  },
  dialogText: {
    fontSize: 14
  }
}));

export default withWidth()(MultiplePaymentGDG);
