import axios from 'axios';
import { config } from '../../Configs';

export const GetContractsAPI = async (token, usingIE) => {
  try {
    const configObj = {};

    if (usingIE) {
      configObj.headers = { Authorization: token, Pragma: 'no-cache' };
    } else {
      configObj.headers = { Authorization: token };
    }

    const response = await axios.get(`${config.api.url}/contracts`, configObj);
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GetSingleContractAPI = async (
  id,
  token = null,
  captchaToken = null
) => {
  try {
    const configObj = {
      params: { 'g-recaptcha-response': captchaToken }
    };

    if (token) {
      configObj.headers = {
        Authorization: token
      };
    }

    const response = await axios.get(
      `${config.api.url}/contracts/${id}`,
      configObj
    );

    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const PostAssociateContract = async (token, id, data, captchaToken) => {
  try {
    const configObj = {
      headers: {
        Authorization: token
      },
      params: { 'g-recaptcha-response': captchaToken }
    };

    const response = await axios.post(
      `${config.api.url}/contracts/${id}`,
      data,
      configObj
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const PutSelectContract = async (token, id) => {
  try {
    const response = await axios.put(
      `${config.api.url}/contracts/select/${id}`,
      {},
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const DeleteContract = async (token, id, password) => {
  try {
    const response = await axios.delete(`${config.api.url}/contracts/${id}`, {
      data: {
        password
      },
      headers: {
        Authorization: token
      }
    });
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const UpdateContract = async (token, id, data) => {
  try {
    const response = await axios.put(
      `${config.api.url}/contracts/${id}`,
      data,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const ValidateOwnership = async (token, data) => {
  try {
    const response = await axios.post(
      `${config.api.url}/contracts/validateOwnership`,
      data,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const ValidateContractCSVAPI = async (
  token = null,
  files = [],
  captchaToken = null
) => {
  try {
    const configObj = {
      params: { 'g-recaptcha-response': captchaToken }
    };

    if (token) {
      configObj.headers = {
        Authorization: token
      };
    }

    const response = await axios.post(
      `${config.api.url}/contracts/validateContractCSV`,
      {
        files
      },
      configObj
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const VerifyLastUserContractAPI = async (token, contractId, data) => {
  try {
    const response = await axios.post(
      `${config.api.url}/contracts/verifyContractOnlyUser/${contractId}`,
      data,
      {
        headers: {
          authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const ActivateDigitalInvoiceContractAPI = async (
  contractId,
  data,
  authToken = null
) => {
  try {
    const configObj = {};
    if (authToken) {
      configObj.headers = {
        Authorization: authToken
      };
    }

    const response = await axios.post(
      `${config.api.url}/contracts/activate-digital-invoice-campaign/${contractId}`,
      { ...data },
      configObj
    );

    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GetContractChallengeAPI = async (
  data,
  authToken,
  captchaToken
) => {
  try {
    const configObj = {
      params: { 'g-recaptcha-response': captchaToken },
      headers: { Authorization: authToken }
    };

    const response = await axios.post(
      `${config.api.url}/contracts/challenge/get`,
      { ...data },
      configObj
    );

    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const ValidateContractChallengeAnswer = async (
  challengeId,
  data,
  authToken,
  captchaToken
) => {
  try {
    const configObj = {
      params: { 'g-recaptcha-response': captchaToken },
      headers: { Authorization: authToken }
    };

    const response = await axios.post(
      `${config.api.url}/contracts/challenge/${challengeId}`,
      data,
      configObj
    );

    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};
