const doPredicateForAllKeys = (object, predicate) => {
  if (typeof object !== 'object') {
    return predicate(object);
  }

  return Object.keys(object).reduce(
    (acc, key) => ({
      ...acc,
      [key]: doPredicateForAllKeys(object[key], predicate)
    }),
    { ...object }
  );
};

export { doPredicateForAllKeys };
