import axios from 'axios';
import moment from 'moment-timezone';
import { config } from '../../../Configs';

export const SubmitGanaLocoAPI = async (
  data,
  token = null,
  captchaToken = null
) => {
  let reason = `${config.campaign.name}`;

  // This campaign only works in May, June and July and must switch each stage based on the current date.
  const now = moment()
    .tz('America/Bogota')
    .format('YYYYMM');

  switch (now) {
    case '202205':
      reason = reason.concat('1');
      break;
    case '202206':
      reason = reason.concat('2');
      break;
    case '202207':
      reason = reason.concat('3');
      break;
    default:
      reason = reason.concat('');
      break;
  }

  try {
    const configObj = {
      params: { 'g-recaptcha-response': captchaToken }
    };

    if (token) {
      configObj.headers = {
        Authorization: token
      };
    }

    const response = await axios.post(
      `${config.api.url}/campaigns`,
      {
        reason,
        ...data
      },
      configObj
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};
