import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import PlacesGDC from './Places.GDC';

const Places = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <PlacesGDC {...props} />;
    case Portal.Efigas:
      return <PlacesGDC {...props} />;
    default:
      return <></>;
  }
};

export default Places;
