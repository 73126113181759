import React from 'react';

const SvgImgSupportSelected = props => (
  <svg
    data-name="Component 9"
    width="1em"
    height="1em"
    viewBox="0 0 50 50"
    {...props}
  >
    <defs>
      <linearGradient
        id="img_support_selected_svg__a"
        x1={0.06}
        x2={1}
        y2={1}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#81ffd8" />
        <stop offset={1} stopColor="#05b7b7" />
      </linearGradient>
    </defs>
    <g fill="url(#img_support_selected_svg__a)">
      <path d="M47.987 49H2.013A1.015 1.015 0 011 47.987V2.013C1 1.455 1.455 1 2.013 1h45.974C48.545 1 49 1.455 49 2.013v45.974c0 .558-.455 1.013-1.013 1.013z" />
      <path
        d="M2.013 2H2.01c-.003.001-.008.006-.009.013v45.978c.001.003.006.008.013.009h45.978c.003-.001.008-.006.009-.013V2.009c-.001-.003-.006-.008-.013-.009H2.013m0-2h45.974C49.099 0 50 .901 50 2.013v45.974A2.013 2.013 0 0147.987 50H2.013A2.013 2.013 0 010 47.987V2.013C0 .901.901 0 2.013 0z"
        fill="#18cc48"
      />
    </g>
    <g data-name="Group 544">
      <path
        data-name="Path 527"
        d="M36.914 33.692a22.1 22.1 0 00-6.543-2.515l.189-.259a.348.348 0 00.09-.358l-.419-1.677q.228-.322.43-.665a3.38 3.38 0 002.648-2.94 1.475 1.475 0 001.364-1.43v-3.356a1.443 1.443 0 00-1.35-1.429c-.095-2.585-1.235-7.654-8.49-7.654s-8.396 5.069-8.494 7.654a1.442 1.442 0 00-1.35 1.429v3.356a1.481 1.481 0 001.477 1.432h.671a1.364 1.364 0 00.69-.192 9.785 9.785 0 001.608 3.795l-.419 1.677a.6.6 0 00.09.358l.189.259a22.1 22.1 0 00-6.543 2.515 2.38 2.38 0 00-1.343 2.193v1.924a.423.423 0 00.447.447h25.953a.423.423 0 00.447-.447v-1.924a2.565 2.565 0 00-1.342-2.193zm-12.079-1.023l1.074 2.455h-2.165zm-1.416 3.35h2.826l.262 1.342H23.15zm6.112-6.264l.179.805-.457.628a.4.4 0 00-.08.088l-1.864 2.577-.591.811-1.206-2.714a6.493 6.493 0 003.832-2q.096-.098.187-.195zm1.747-2.783a9.684 9.684 0 00.563-1.9 1.406 1.406 0 00.573.2 2.184 2.184 0 01-1.136 1.699zm2.459-6.524v3.356a.544.544 0 01-.537.537h-.671a.544.544 0 01-.537-.537v-3.356a.544.544 0 01.537-.539h.671a.544.544 0 01.537.539zm-8.9-8.144c2.758 0 7.4.9 7.6 6.764a1.423 1.423 0 00-.53.164 7.159 7.159 0 00-14.139 0 1.4 1.4 0 00-.53-.163c.195-5.86 4.837-6.765 7.595-6.765zm-7.2 11.5a.544.544 0 01-.537.537h-.679a.544.544 0 01-.537-.537v-3.356a.544.544 0 01.537-.539h.671a.544.544 0 01.537.537zm.94-3.445a6.264 6.264 0 0112.529 0v3.132a8.281 8.281 0 01-1.01 3.979 17.892 17.892 0 01-4.807.5.448.448 0 000 .895 22.512 22.512 0 004.065-.306l-.082.082a7.5 7.5 0 01-.711.821 5.721 5.721 0 01-3.521 1.635.5.5 0 00-.2-.039.428.428 0 00-.186.04 5.059 5.059 0 01-2.219-.671 7.27 7.27 0 01-2.025-1.785l-.134-.134-.026-.009a8.244 8.244 0 01-1.674-5v-3.141zm5.582 11.592l-1.206 2.714-.591-.811-1.87-2.578-.046-.042-.491-.674.179-.805c.061.065.122.129.184.191a6.494 6.494 0 003.832 2.004zm-11.855 3.934a1.536 1.536 0 01.85-1.387 19.95 19.95 0 016.734-2.5l2.67 3.671-.339 1.7h-9.915zm25.058 1.477h-9.916l-.339-1.7 2.7-3.711a21.765 21.765 0 016.705 2.5 1.655 1.655 0 01.85 1.432v1.477z"
        fill="#fff"
      />
    </g>
  </svg>
);

export default SvgImgSupportSelected;
