import React from 'react';
import { portal, Portal } from '../../../Configs/general';
import PeriodicRevisionGDC from './PeriodicRevision.GDC';

const PeriodicRevision = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <PeriodicRevisionGDC {...props} />;
    default:
      return <></>;
  }
};

export default PeriodicRevision;
