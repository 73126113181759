import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Grid, Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import _get from 'lodash/get';

import {
  UserContext,
  UserDispatchContext
} from '../../../Contexts/UserContext';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';

import RequestCard from '../../../Components/Cards/RequestCard';
import ModalProgress from '../../../Components/Progress/Modal/ModalProgress';
import Pagination from '../../../Components/Pagination/Pagination';
import NoRequestsIcon from '../../../Components/CustomIcons/NoRequestsIcon';
import EmptyState from '../Utils/EmptyState';
import { GetRequestsAPI } from '../../../API/Requests/requestsAPI';
import {
  redirectOnAuthFailure,
  extractErrorMessage
} from '../../../Utils/Errors/Errors';
import { logoutUser } from '../../../Utils/User/Actions';
import { debounce } from 'lodash';
import moment from 'moment';

const emptyStates = {
  noResults: {
    icon: NoRequestsIcon,
    title: 'Búsqueda sin resultados',
    message: 'Aún no has realizado solicitudes en el portal'
  },
  noFilteredResults: {
    icon: NoRequestsIcon,
    title: 'Búsqueda sin resultados',
    message:
      'No hemos encontrado solicitudes que coincidan con estos criterios, limpia los filtros e intenta de nuevo'
  }
};

const RequestList = ({ selectedFilters, page, setPage }) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  // * CONTEXTS
  const setAlert = useContext(AlertsDispatchContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const currentUser = useContext(UserContext);
  const authToken = _get(currentUser, 'token');

  // * STATE HOOKS
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(
    window.innerWidth < 750
      ? Math.floor((window.innerHeight - 212) / 132)
      : Math.floor((window.innerHeight - 315) / 93)
  );
  const [requestsCount, setRequestsCount] = useState(0);
  const [noResults, setNoResults] = useState(false);
  const [noFilteredResults, setNoFilteredResults] = useState(false);
  const [requests, setRequests] = useState([]);

  const filtered = params => {
    if (params.contractId || params.sinceDate || params.type || params.status) {
      return true;
    }
    return false;
  };

  const getRequests = useMemo(
    () =>
      debounce(
        // eslint-disable-next-line no-shadow
        async (page, pageSize, selectedFilters) => {
          setLoading(true);
          setNoResults(false);
          setNoFilteredResults(false);

          const { endDate, startDate, ...rest } = selectedFilters;

          const params = {
            page: page - 1,
            pageSize,
            ...rest
          };

          if (startDate) {
            params.startDate = moment(startDate).toISOString();
          }

          if (endDate) {
            params.endDate = moment(endDate).toISOString();
          }

          const response = await GetRequestsAPI(authToken, params);

          if (response.success) {
            const requestsArray = response.data.data.data.requests;
            const total = response.data.data.data.total;

            if (requestsArray.length === 0) {
              filtered(params)
                ? setNoFilteredResults(true)
                : setNoResults(true);
            }
            setRequests(requestsArray);
            setRequestsCount(total);
          } else {
            if (
              // eslint-disable-next-line max-nested-callbacks
              redirectOnAuthFailure(response, '/', () =>
                logoutUser(setCurrentUser)
              )
            ) {
              return;
            }

            setAlert({
              type: 'error',
              message: extractErrorMessage(response).message
            });
          }
          setLoading(false);
        },
        500
      ),
    [
      authToken,
      setAlert,
      setCurrentUser,
      setLoading,
      setRequests,
      setNoResults,
      setNoFilteredResults
    ]
  );

  // * FETCH-EFFECT
  useEffect(() => {
    getRequests(page, pageSize, selectedFilters);
  }, [getRequests, page, pageSize, selectedFilters]);

  // * RENDERS

  const renderBody = () => {
    return (
      <Box my={0.75} width="100%" flexGrow={1}>
        {requests.map(request => (
          <RequestCard request={request} key={request.id} />
        ))}
      </Box>
    );
  };

  const renderPagination = () => (
    <Pagination
      numberOfItems={requestsCount}
      itemsPerPage={pageSize}
      setItemsPerPage={setPageSize}
      currentPage={page}
      setCurrentPage={setPage}
    />
  );

  return (
    <Grid container direction="column" alignItems="center">
      {(loading && <ModalProgress />) ||
        (requests.length > 0 && (
          <>
            {requests.length > 0 && renderPagination()}
            {renderBody()}
            {requests.length > 0 &&
              (smallScreen || requests.length > 3) &&
              renderPagination()}
          </>
        )) ||
        (noResults && (
          <EmptyState
            icon={emptyStates.noResults.icon}
            title={emptyStates.noResults.title}
            message={emptyStates.noResults.message}
          />
        )) ||
        (noFilteredResults && (
          <EmptyState
            icon={emptyStates.noFilteredResults.icon}
            title={emptyStates.noFilteredResults.title}
            message={emptyStates.noFilteredResults.message}
          />
        ))}
    </Grid>
  );
};

export default RequestList;
