import React, { useState, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
  Button
} from '@material-ui/core';
import clsx from 'clsx';
import _get from 'lodash/get';

import DownloadIcon from '../../../Components/CustomIcons/DownloadIcon';
import UserIcon from '../../../Components/CustomIcons/UserIcon';
import ModalProgress from '../../../Components/Progress/Modal/ModalProgress';

import { ContractSelectedContext } from '../../../Contexts/ContractsContext';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';
import { UserContext } from '../../../Contexts/UserContext';

import { renderContractTypeDescription } from '../../Contracts/contract_enums';
import { formatAddress } from '../../../Utils/Format/Address';
import { GetContractDebtsPDFAPI } from '../../../API/Debts/DebtsAPI';
import { extractErrorMessage } from '../../../Utils/Errors/Errors';
import { saveAsPDF } from '../../../Utils/Transform/Files';
import { numberWithDots } from '../../../Utils/Format/MoneyFormat';

import ContractDebtDialogContent from '../Components/ContractDebtDialogContent';
import ContractDebtInfoDialog from '../../../Components/Dialogs/ContractDebtInfoDialog';
import DebtCardHeader from '../Components/DebtCardHeader';
import { Company } from '../../../Configs/general';
import CallToActionDialog from '../../../Components/Dialogs/CallToActionDialog';
import { ROUTE_NAMES } from '../../../Routes/Routes';
import { history } from '../../../Routes/history';

const ContractDebtCard = props => {
  const { contract, title, banner, data = {} } = props;
  const { refinancing } = props;

  const classes = useStyles();
  const theme = useTheme();

  // * STATES HOOKS
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [identificationDialog, setIdentificationDialog] = useState(false);

  // * OTHER HOOKS
  const mediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  // * CONTEXTS
  const setAlert = useContext(AlertsDispatchContext);
  const selectedContract = useContext(ContractSelectedContext);
  const currentUser = useContext(UserContext);
  const authToken = _get(currentUser, 'token');

  //* GLOBAL VARS
  const totalDebt = data.currentValue + data.deferredValue || 0;

  // * FUNCTIONS
  const generatePDF = async () => {
    if (!currentUser.identification || !currentUser.identificationType) {
      setIdentificationDialog(true);
      return;
    }
    setLoadingPDF(true);
    const response = await GetContractDebtsPDFAPI(
      authToken,
      selectedContract.id
    );
    setLoadingPDF(false);

    if (response.success) {
      const fileName = `Constancia_Estado_Deuda_${selectedContract.id}.pdf`;
      const base64 = response.data.data.content;
      saveAsPDF(base64, fileName);
    } else {
      setAlert({
        type: 'error',
        message: extractErrorMessage(response).message
      });
    }
  };

  // * CALLBACKS
  const onHelpClick = useCallback(() => {
    setHelpDialogOpen(true);
  }, []);

  const onProfileClick = useCallback(() => {
    history.push(ROUTE_NAMES.profile);
  }, []);

  const onCloseDialogClick = useCallback(() => {
    setIdentificationDialog(false);
  }, []);

  const contractDialogContent = () => (
    <ContractDebtDialogContent
      data={data}
      contract={contract}
      contractAddress={formatAddress(contract, true)}
      contractType={renderContractTypeDescription(contract.type)}
    />
  );

  return (
    <div className={classes.card} style={{ backgroundImage: `url(${banner})` }}>
      <CallToActionDialog
        open={identificationDialog}
        icon={UserIcon}
        iconStyle={classes.colorIcon}
        title={'Actualiza tu número de identificación'}
        message={
          'Para descargar el certificado se debe agregar el número y el tipo de identificación.'
        }
        confirmButtonText={'Ir al perfil'}
        handleConfirmClick={onProfileClick}
        handleClose={onCloseDialogClick}
      />
      <ContractDebtInfoDialog
        open={helpDialogOpen && mediumScreen}
        title={`Información ${Company.contractConjugation.regular.singular.contraction}`}
        onClose={() => setHelpDialogOpen(false)}
        content={contractDialogContent}
      />
      {loadingPDF && <ModalProgress message="Generando certificado" />}
      <DebtCardHeader
        data={data}
        title={title}
        refinancing={refinancing}
        isMyDebts
        generatePDF={generatePDF}
        onHelpClick={onHelpClick}
      />

      {!mediumScreen && (
        <Grid container direction="row" alignItems="baseline">
          <Grid item xs={12} sm={6} md={4}>
            <Typography className={classes.label}>
              {Company.contractConjugation.capitalized.singular.associate} a la
              deuda
            </Typography>
            <Typography className={classes.contractData}>
              {contract.alias} - {contract.id}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={5} className={classes.contractAddress}>
            <Typography className={classes.label}>
              Dirección{' '}
              {Company.contractConjugation.regular.singular.contraction}
            </Typography>
            <Typography className={classes.contractData}>
              {formatAddress(contract, true)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Typography className={classes.label}>
              Tipo de {Company.contractConjugation.regular.singular.main}
            </Typography>
            <Typography className={classes.contractData}>
              {renderContractTypeDescription(contract.type)}
            </Typography>
          </Grid>
        </Grid>
      )}

      {mediumScreen && (
        <>
          <Grid container justify="flex-end" direction="column">
            <Grid item>
              <Typography className={clsx(classes.label, classes.alignRight)}>
                Saldo total
              </Typography>
              <Typography
                className={clsx(
                  classes.contractData,
                  classes.total,
                  classes.alignRight
                )}
              >
                {numberWithDots(totalDebt)}
              </Typography>
            </Grid>

            <Divider className={classes.divider} />
            <Grid item xs={12}>
              <Grid item>
                <Button
                  className={clsx(
                    classes.button,
                    classes.downloadSummaryButton
                  )}
                  color="primary"
                  size="small"
                  variant="outlined"
                  onClick={generatePDF}
                >
                  Descargar certificado
                  <DownloadIcon
                    width={18}
                    height={16}
                    fill={theme.palette.common.white}
                    className={classes.downloadIcon}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {!mediumScreen && (
        <>
          <Grid container direction="column" justify="flex-end">
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
              <Typography className={clsx(classes.label, classes.alignRight)}>
                Saldo total
              </Typography>
              <Typography
                className={clsx(
                  classes.contractData,
                  classes.total,
                  classes.alignRight
                )}
              >
                {numberWithDots(totalDebt)}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: theme.custom.borderRadius,
    backgroundColor: theme.palette.background.cardDark,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    color: 'white',
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      padding: theme.spacing(2)
    }
  },
  label: {
    fontSize: 12,
    fontWeight: 500
  },
  contractData: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.2,
    textTransform: 'capitalize'
  },
  secondaryContractTypeText: {
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      display: 'none'
    }
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.divider,
    margin: theme.spacing(2, 0, 2, 0)
  },
  total: {
    fontSize: 20
  },
  button: {
    maxWidth: 120,
    height: 40,
    fontSize: 12,
    textTransform: 'none',
    whiteSpace: 'nowrap',
    fontWeight: 600,
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    marginRight: theme.spacing(1),
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  mainActionButton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
    marginRight: 0
  },
  downloadSummaryButton: {
    width: '100%',
    maxWidth: '100%',
    color: theme.palette.common.white,
    marginRight: 0,
    padding: theme.spacing(0, 0.5, 0, 0.5)
  },
  alignRight: {
    textAlign: 'right'
  },
  arrowIcon: {
    transform: 'rotate(180deg)',
    fill: theme.palette.primary.main,
    marginLeft: theme.spacing(0.5)
  },
  downloadIcon: {
    marginLeft: theme.spacing(1)
  },
  contractAddress: {
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      paddingRight: theme.spacing(1)
    }
  },
  colorIcon: {
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main
  }
}));

export default ContractDebtCard;
