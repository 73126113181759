import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import './index.css';
import './polyfills.js';
import App from './App';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import 'moment/locale/es';
import { portal, Portal } from './Configs/general';
import { config } from './Configs';

moment.locale('es');
momentTZ.defineLocale('es', moment.localeData()._config);
momentTZ.locale('es');

const getPortalWhitelistUrls = () => {
  switch (portal) {
    case Portal.Gasguajira:
      return [
        /https:\/\/portal.gasesdelaguajira.com/,
        /https:\/\/pagosweb-testing-gdg.innovacion-gascaribe.com/
      ];
    case Portal.Gascaribe:
      return [
        /https:\/\/portal.gascaribe.com/,
        /https:\/\/pagosweb-testing.innovacion-gascaribe.com/
      ];
    case Portal.Efigas:
      return [
        /https:\/\/portal.efigas.com/,
        /https:\/\/pagosweb-testing-efg.innovacion-gascaribe.com/
      ];
    default:
      return [];
  }
};

const SentryDSN = config.sentry.dsn;
if (SentryDSN) {
  Sentry.init({
    dsn: SentryDSN,
    release: config.sentry.relaseVersion,
    whitelistUrls: getPortalWhitelistUrls(),
    ignoreErrors: [/Timeout/],
    beforeBreadcrumb(breadcrumb, hint) {
      const { category, data } = breadcrumb;
      if (category === 'xhr') {
        if (data.url.match(/VERSION.txt/)) {
          return null;
        }
      }

      if (breadcrumb.category === 'ui.click') {
        const { target } = hint.event;
        if (target.ariaLabel) {
          breadcrumb.message = target.ariaLabel;
        }
      }

      return breadcrumb;
    }
  });

  // eslint-disable-next-line dot-notation
  axios.defaults.headers.common['FrontendVersion'] =
    config.sentry.relaseVersion;
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(<App />);
