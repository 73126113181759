import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';

import { numberWithDots } from '../../../../Utils/Format/MoneyFormat';

import { ProductType } from '../../myDebts_enums';

const PunishedDebtCardEFG = ({ debts }) => {
  const classes = useStyles();

  const getPunishedProductsString = () => {
    if (debts.punishedData && debts.punishedData.products.length === 2) {
      return 'Gas, Servicios financieros';
    }

    const productText =
      debts.punishedData &&
      debts.punishedData.products[0].productTypeId === ProductType.Gas
        ? 'Gas'
        : 'Servicios financieros';

    return productText;
  };

  return (
    <div className={classes.card}>
      <div className={classes.gradientBorder} />
      <div className={classes.innerContainer}>
        <Grid container direction="column" spacing={1}>
          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid container item>
              <Grid>
                <Typography className={classes.title}>
                  Deuda castigada
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              justify="flex-end"
              spacing={1}
              zeroMinWidth={true}
              alignItems="center"
              direction="row"
            >
              <Grid item>
                <Typography
                  className={clsx(classes.statusTag, classes.punishedStatus)}
                >
                  Producto castigado
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Typography className={classes.description}>
              Deuda que cumple más de 1 año sin haber presentado pagos.
            </Typography>
          </Grid>

          <Grid item container>
            <Grid
              item
              container
              spacing={1}
              alignItems="flex-start"
              justify="space-between"
            >
              {debts.punishedData && debts.punishedData.lastBillingDate && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  className={classes.detailsItem}
                >
                  <Typography className={classes.label}>
                    {'Última factura'}
                  </Typography>
                  <Typography className={classes.data}>
                    {`${debts.punishedData.lastBillingDate}`}
                  </Typography>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={3} className={classes.detailsItem}>
                <Typography className={classes.label}>
                  {debts.punishedData && debts.punishedData.products.length > 1
                    ? 'Productos castigados'
                    : 'Producto castigado'}
                </Typography>
                <Typography className={classes.data}>
                  {getPunishedProductsString()}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={3}
                className={(classes.alignRight, classes.detailsItem)}
              >
                <Typography className={clsx(classes.totalLabel)}>
                  Saldo total
                </Typography>
                <Typography className={clsx(classes.data, classes.total)}>
                  {numberWithDots(debts.punishedValue)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: theme.spacing(1, 0, 0, 1),
    display: 'flex',
    overflow: 'hidden',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'column',
      borderRadius: theme.spacing(1, 1, 0, 0)
    }
  },
  gradientBorder: {
    width: theme.spacing(),
    background:
      'transparent linear-gradient(180deg, #F49C9C 0%, #E05252 100%) 0% 0%',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      height: theme.spacing(),
      width: '100%',
      background:
        'transparent linear-gradient(240deg, #F49C9C 0%, #E05252 100%) 0% 0%'
    }
  },
  innerContainer: {
    borderColor: theme.palette.background.border,
    borderStyle: 'solid',
    borderLeft: 'none',
    borderWidth: '1px 1px 1px 0',
    borderRadius: theme.spacing(0, 1, 1, 0),
    padding: theme.spacing(3, 3, 2, 2),
    flexGrow: 1,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      borderWidth: '0 1px 1px 1px',
      borderRadius: theme.spacing(0, 0, 1, 1),
      padding: theme.spacing(2, 1.5)
    }
  },
  detailsItem: {
    margin: theme.spacing(0.5, 0, 0.5, 0)
  },
  title: {
    fontWeight: 600,
    fontSize: 14
  },
  description: {
    lineHeight: 1.3,
    fontSize: 13,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 10
    }
  },
  label: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.greyDark,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 11
    }
  },
  data: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.2,
    textTransform: 'capitalize',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 12
    }
  },
  alignRight: {
    textAlign: 'right'
  },
  total: {
    fontSize: 20,
    textAlign: 'right',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 16
    }
  },
  totalLabel: {
    textAlign: 'right',
    fontSize: 12
  },
  arrowIcon: {
    transform: 'rotate(180deg)'
  },
  punishedStatus: {
    color: '#FE685E'
  },
  statusTag: {
    fontWeight: 600,
    fontSize: 14,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 11
    }
  }
}));

export default PunishedDebtCardEFG;
