import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import { history } from '../../../Routes/history';
import { ROUTE_NAMES } from '../../../Routes/Routes';
import HomeIcon from '../../../Components/CustomIcons/HomeIcon';
import FolderIcon from '../../../Components/CustomIcons/FolderIcon';
import DocumentIcon from '../../../Components/CustomIcons/DocumentIcon';
import UserIcon from '../../../Components/CustomIcons/UserIcon';
import { Company } from '../../../Configs/general';

const MobileFooter = props => {
  const { location } = props;
  const classes = useStyles();
  const [value, setValue] = useState(0);

  useEffect(() => {
    if (!location) {
      return;
    }
    setValue(location.pathname);
  }, [location]);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        history.push(newValue);
      }}
      showLabels={false}
      className={classes.root}
    >
      <BottomNavigationAction
        value={'/'}
        label="Inicio"
        icon={<HomeIcon size={24} className={classes.buttonIcon} />}
        classes={{
          root: classes.buttonBorderLeft,
          label: classes.buttonLabel
        }}
      />
      <BottomNavigationAction
        value={ROUTE_NAMES.contracts}
        label={Company.contractConjugation.capitalized.plural.main}
        icon={<DocumentIcon size={24} className={classes.buttonIcon} />}
        classes={{
          label: classes.buttonLabel
        }}
      />
      <BottomNavigationAction
        value={ROUTE_NAMES.invoices}
        label="Facturas"
        icon={<FolderIcon size={24} className={classes.buttonIcon} />}
        classes={{
          label: classes.buttonLabel
        }}
      />
      <BottomNavigationAction
        value={'/profile'}
        label="Perfil"
        icon={<UserIcon size={24} className={classes.buttonIcon} />}
        classes={{
          root: classes.buttonBorderRight,
          label: classes.buttonLabel
        }}
      />
    </BottomNavigation>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    height: 64,
    width: '100%',
    borderTopRightRadius: 24,
    borderTopLeftRadius: 24,
    border: `1px solid ${theme.palette.common.borderColor}`
  },
  buttonLabel: {
    fontSize: '12px !important',
    [theme.breakpoints.up('xm')]: {
      fontSize: '14px !important'
    }
  },
  buttonLabelSelected: {
    fontSize: 14
  },
  buttonBorderLeft: {
    borderTopLeftRadius: 24
  },
  buttonBorderRight: {
    borderTopRightRadius: 24
  },
  buttonIcon: {
    fill: 'currentColor'
  }
}));

export default withRouter(MobileFooter);
