import React from 'react';

const CommercialIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 56}
    height={props.size || 56}
    viewBox="0 0 56 56"
    xmlSpace="preserve"
    {...props}
  >
    <rect
      id="Rectángulo_241"
      data-name="Rectángulo 241"
      width="56"
      height="56"
      fill="none"
    />
    <path
      id="storefront-outline"
      d="M80.674,99.946V71.975m-44.93,0V99.946M73.007,48h-29.6a6.343,6.343,0,0,0-5.842,3.8L32.5,63.609a6.2,6.2,0,0,0,5.521,8.616h.234A6.737,6.737,0,0,0,44.91,65.7a6.742,6.742,0,0,0,6.653,6.522A6.582,6.582,0,0,0,58.209,65.7a6.741,6.741,0,0,0,6.652,6.522A6.588,6.588,0,0,0,71.514,65.7a6.587,6.587,0,0,0,6.652,6.522h.228a6.2,6.2,0,0,0,5.521-8.616L78.849,51.8A6.343,6.343,0,0,0,73.007,48ZM32,99.946H84.418M44.169,77.969h9.36a2.907,2.907,0,0,1,2.808,3V91.954H41.36V80.966A2.907,2.907,0,0,1,44.169,77.969ZM61.953,99.946V80.966a2.907,2.907,0,0,1,2.808-3H72.25a2.907,2.907,0,0,1,2.808,3v18.98"
      transform="translate(-30.709 -45.973)"
      fill="none"
      stroke="#104eb2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default CommercialIcon;
