import React from 'react';
import GoogleButton from './GoogleButton';
import AppleButton from './AppleButton';

const SocialButtons = ({
  googleLoading,
  appleLoading,
  emailLoading,
  hideGoogleButton = false,
  hideAppleButton = false
}) => {
  return (
    <>
      {!hideGoogleButton && (
        <GoogleButton
          loading={googleLoading}
          disabled={appleLoading || emailLoading}
        />
      )}
      {!hideAppleButton && (
        <AppleButton
          loading={appleLoading}
          disabled={googleLoading || emailLoading}
        />
      )}
    </>
  );
};

export default SocialButtons;
