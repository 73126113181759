import React from 'react';
import { portal, Portal } from '../../../../../../Configs/general';

import MultipleLoadGDC from './MultipleLoad.GDC';
import MultipleLoadGDG from './MultipleLoad.GDG';

const MultipleLoad = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <MultipleLoadGDC {...props} />;
    case Portal.Gasguajira:
      return <MultipleLoadGDG {...props} />;
    case Portal.Efigas:
      return <MultipleLoadGDC {...props} />;
    case Portal.TripleA:
      return <MultipleLoadGDC {...props} />;
    default:
      return <></>;
  }
};

export default MultipleLoad;
