import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

const TransactionLabel = ({ title, description, classes = {} }) => {
  const internalClasses = useStyles();
  return (
    <div className={clsx(internalClasses.rootBase, classes.root)}>
      <Typography className={clsx(internalClasses.rootTitle, classes.title)}>
        {title}
      </Typography>
      <Typography
        className={clsx(internalClasses.rootDescription, classes.description)}
      >
        {description}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  rootBase: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '33.333%'
    }
  },
  root: {},
  rootTitle: {
    fontSize: 11,
    fontWeight: 500,
    marginBottom: theme.spacing(0.25)
  },
  title: {},
  rootDescription: {
    fontSize: 18,
    fontWeight: 500
  },
  description: {}
}));

export default TransactionLabel;
