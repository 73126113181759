import React, {
  Fragment,
  useState,
  useContext,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react';
import * as yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';

import BaseButton from '../../Components/Buttons/BaseButton';
import BaseDialog from '../../Components/Dialogs/BaseDialog';
import TextInput from '../../Components/Inputs/TextInput';
import { SlideUpTransition } from '../../Components/Transitions/Transitions';

import {
  redirectOnAuthFailure,
  extractErrorMessage
} from '../../Utils/Errors/Errors';

import { UserContext, UserDispatchContext } from '../../Contexts/UserContext';
import { VerifyPhoneNumberAPI } from '../../API/UserAPI';
import { ResendPhoneConfirmationAPI } from '../../API/UserAPI';
import _get from 'lodash/get';
import { logoutUser, updateUser } from '../../Utils/User/Actions';

const verificationCodeSchema = yup.object({
  code: yup
    .array()
    .of(
      yup
        .number()
        .positive()
        .integer()
        .min(0)
        .max(9)
    )
    .min(4)
    .max(4)
});

const RESEND_FLAG_SECONDS_DELAY = 120;

const PhoneVerificationDialog = props => {
  // * CONTEXTS
  const {
    open,
    setDialog,
    setAlert,
    title,
    requestCallback,
    successCallback,
    currentPassword = null,
    formPhone = null,
    formPhoneCountryCode = null
  } = props;

  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const authToken = _get(currentUser, 'token');

  const [code, setCode] = useState(['', '', '', '']);
  const [canSubmit, setCanSubmit] = useState(false);
  const [counter, setCounter] = useState(RESEND_FLAG_SECONDS_DELAY);
  const [canResend, setCanResend] = useState(false);
  const [timer, setTimer] = useState(null);
  const [loading, setLoading] = useState(false);

  const phone = formPhone || currentUser.phone;
  const phoneCountryCode = formPhoneCountryCode || currentUser.phoneCountryCode;

  const codeInputs = useRef([]);

  const classes = useStyles();
  const confirmPhone = useCallback(
    async event => {
      event.preventDefault();

      if (!canSubmit || loading) {
        return false;
      }

      setLoading(true);
      const fullCode = code.join('');
      const response = await VerifyPhoneNumberAPI(authToken, fullCode);
      if (response.success) {
        if (requestCallback) {
          const updateResponse = await requestCallback(currentPassword);
          if (updateResponse.success) {
            setLoading(false);
            setDialog(false);
            successCallback(updateResponse);
            return;
          }

          setAlert({
            type: 'error',
            message: extractErrorMessage(updateResponse).message
          });
          setLoading(false);
          setDialog(false);
          return;
        }

        setAlert({
          type: 'success',
          message: 'Tu número celular fue confirmado exitosamente'
        });
        setLoading(false);
        setDialog(false);
        const { token, appToken } = response.data.data;
        updateUser(setCurrentUser, token, appToken);
        return;
      }

      if (
        redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
      ) {
        return;
      }

      setCode(['', '', '', '']);

      codeInputs.current.forEach(ref => {
        ref.value = '';
      });

      setLoading(false);
      setAlert({
        type: 'error',
        message: extractErrorMessage(response).message
      });
    },
    [
      setAlert,
      setDialog,
      canSubmit,
      setCurrentUser,
      authToken,
      code,
      loading,
      requestCallback,
      successCallback,
      currentPassword
    ]
  );

  const handleClose = useCallback(() => {
    setDialog(false);
  }, [setDialog]);

  const resetResendFlag = useCallback(() => {
    const countdown = c => c - 1;
    const interval = setInterval(() => {
      setCounter(countdown);
    }, 1000);
    setTimer(interval);
  }, []);

  const handleClick = useCallback(async () => {
    if (!canResend) {
      return;
    }

    const response = await ResendPhoneConfirmationAPI(currentUser.token);
    setCounter(RESEND_FLAG_SECONDS_DELAY);
    if (response.success) {
      setCanResend(false);
      resetResendFlag();
    } else {
      setCanResend(true);
    }
  }, [resetResendFlag, currentUser, canResend]);

  const onChangeCode = useCallback(
    e => {
      const { attributes, value } = e.target;
      const index = parseInt(attributes['data-id'].value, 10);

      setCode(c => {
        const values = [...c];
        values[index] = value;
        return values;
      });

      if (value) {
        const nextIndex = Math.min(index + 1, 3);
        codeInputs.current[nextIndex].focus();
      }
    },
    [codeInputs]
  );

  useEffect(() => {
    return () => clearInterval(timer);
  }, [timer]);

  useEffect(() => {
    if (counter <= 0) {
      clearInterval(timer);
      setTimer(null);
      setCanResend(true);
    }
  }, [counter, timer]);

  useEffect(() => {
    resetResendFlag();
  }, [resetResendFlag]);

  useEffect(() => {
    if (!code) {
      setCanSubmit(false);
      return;
    }

    try {
      verificationCodeSchema.validateSync({ code });
      setCanSubmit(true);
    } catch (err) {
      setCanSubmit(false);
    }
  }, [code]);

  const resendText = useMemo(() => {
    if (counter > 0) {
      const minutes = Math.floor(counter / 60);
      let seconds = counter - minutes * 60;
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      return `${minutes}:${seconds}`;
    }
    return 'Reenviar código';
  }, [counter]);

  const renderActions = () => {
    return (
      <Fragment>
        <BaseButton
          id="PhoneVerificationDialog_button_cancel"
          onClick={handleClose}
          variant="outlined"
          color="primary"
          size="small"
        >
          Cancelar
        </BaseButton>
        <BaseButton
          onClick={confirmPhone}
          id="PhoneVerificationDialog_button_confirm"
          type="submit"
          form="phoneVerificationForm"
          color="primary"
          size="small"
          autoFocus
          disabled={!canSubmit || loading}
        >
          Confirmar
        </BaseButton>
      </Fragment>
    );
  };

  const renderContent = () => {
    return (
      <div className={classes.content}>
        <Typography className={classes.text} variant="caption">
          Por favor ingresa el código de verificación de 4 dígitos que hemos
          enviado a tu celular{' '}
          <span className={classes.semiBold}>
            (+{phoneCountryCode}) {phone}
          </span>
          .
        </Typography>
        <div className={classes.codeContainer}>
          <form id="phoneVerificationForm" onSubmit={confirmPhone}>
            <TextInput
              type="number"
              className={classes.codeInput}
              id="PhoneVerificationDialog_input_1"
              autoFocus
              placeholder="-"
              margin="none"
              onChange={onChangeCode}
              InputProps={{
                inputProps: {
                  'data-id': '0',
                  min: 0,
                  max: 9,
                  ref: element => {
                    codeInputs.current[0] = element;
                  }
                },
                classes: { input: classes.codeLabel }
              }}
            />
            <TextInput
              type="number"
              className={classes.codeInput}
              id="PhoneVerificationDialog_input_2"
              placeholder="-"
              margin="none"
              onChange={onChangeCode}
              InputProps={{
                inputProps: {
                  'data-id': '1',
                  min: 0,
                  max: 9,
                  ref: element => {
                    codeInputs.current[1] = element;
                  }
                },
                classes: { input: classes.codeLabel }
              }}
            />
            <TextInput
              type="number"
              className={classes.codeInput}
              id="PhoneVerificationDialog_input_3"
              placeholder="-"
              margin="none"
              onChange={onChangeCode}
              InputProps={{
                inputProps: {
                  'data-id': '2',
                  min: 0,
                  max: 9,
                  ref: element => {
                    codeInputs.current[2] = element;
                  }
                },
                classes: { input: classes.codeLabel }
              }}
            />
            <TextInput
              type="number"
              className={classes.codeInput}
              id="PhoneVerificationDialog_input_4"
              placeholder="-"
              margin="none"
              onChange={onChangeCode}
              InputProps={{
                inputProps: {
                  'data-id': '3',
                  min: 0,
                  max: 9,
                  ref: element => {
                    codeInputs.current[3] = element;
                  }
                },
                classes: { input: classes.codeLabel }
              }}
            />
          </form>
        </div>
        <span className={classes.codeError}></span>
        {!canResend && (
          <Typography className={classes.subText}>
            Si el mensaje no te ha llegado en 2 minutos, puedes reenviarlo y se
            te generará otro código de verificación.
          </Typography>
        )}
        <div className={classes.actionContainer}>
          <span
            id="PhoneVerificationDialog_span_resendVerification"
            onClick={handleClick}
            className={classes.codeAction}
          >
            {resendText}
          </span>
        </div>
      </div>
    );
  };

  const isMobileSize = isWidthDown('sm', props.width);
  return (
    <Fragment>
      <BaseDialog
        id="PhoneVerificationDialog_div"
        open={open}
        disableBackdropClick
        handleClose={handleClose}
        title={title}
        actions={renderActions}
        content={renderContent}
        fullScreen={isMobileSize}
        TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
        contentSize={isMobileSize ? undefined : 'small'}
      />
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  passwordField: {
    width: 250,
    marginTop: theme.spacing(4)
  },
  codeContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: [[theme.spacing(4), 0, theme.spacing(4), 0]]
  },
  codeInput: {
    maxWidth: 70,
    margin: [[0, 2, 0, 2]],
    [theme.breakpoints.up('sm')]: {
      margin: [[0, theme.spacing(2), 0, theme.spacing(2)]]
    }
  },
  codeLabel: {
    fontSize: 32,
    fontWeight: 500,
    textAlign: 'center',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0
    },
    '-moz-appearance': 'textfield'
  },
  actionContainer: {
    textAlign: 'center',
    width: '100%',
    marginTop: theme.spacing(2)
  },
  codeAction: {
    fontSize: 16,
    color: theme.palette.primary.dark,
    fontWeight: 500,
    cursor: 'pointer',
    margin: '1em auto'
  },
  codeError: {
    color: theme.palette.error.dark
  },
  text: {
    fontSize: 14,
    color: theme.palette.text.primary,
    textAlign: 'justify'
  },
  subText: {
    fontSize: 12,
    color: theme.palette.text.primary,
    textAlign: 'center',
    marginTop: theme.spacing()
  },
  semiBold: {
    fontWeight: 500
  }
}));

export default withWidth()(PhoneVerificationDialog);
