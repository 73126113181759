import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandIcon from '@material-ui/icons/Add';
import CollapseIcon from '@material-ui/icons/Remove';
import PaymentCard from './PaymentCard/PaymentCard';
import { TAB_OPTIONS } from '../../../payment_enums';

const PaymentAccordion = props => {
  const {
    number,
    containerClass,
    setCardIndividualLoad,
    setCardMultipleLoad,
    totalIndividualCards,
    totalMultipleLoadCards,
    setPayments,
    firstAccordionExpanded,
    setFirstAccordionExpanded,
    secondAccordionExpanded,
    setSecondAccordionExpanded,
    setCaptcha,
    captchaValue,
    currentTabIndex,
    authToken
  } = props;

  const classes = useStyles();

  const displayIcon = isExpanded => {
    return isExpanded ? (
      <CollapseIcon className={classes.icon} />
    ) : (
      <ExpandIcon className={classes.icon} />
    );
  };

  return (
    <div className={clsx(classes.root, containerClass)}>
      {currentTabIndex === TAB_OPTIONS.INDIVIDUAL && (
        <Accordion
          id="firstAccordion"
          name="firstAccordion"
          className={classes.accordionContainer}
          onChange={(_event, newExpanded) => {
            setFirstAccordionExpanded(newExpanded);
          }}
          classes={{
            expanded: classes.expandedItem
          }}
          expanded={firstAccordionExpanded}
        >
          <AccordionSummary
            expandIcon={displayIcon(firstAccordionExpanded)}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              Referencias de pago agregadas ({totalIndividualCards.length || 0})
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionContent}>
            {totalIndividualCards.map(
              ({ type, detail: cardPayload, status }) => {
                return (
                  <PaymentCard
                    key={
                      (cardPayload.contractId || 0) +
                      (cardPayload.couponId || 0)
                    }
                    number={number}
                    generationDate={cardPayload.generationDate}
                    contractCity={cardPayload.contractCity}
                    contractAddress={cardPayload.contractAddress}
                    contractId={cardPayload.contractId}
                    couponId={cardPayload.couponId}
                    expirationDate={cardPayload.expirationDate}
                    couponValue={cardPayload.couponValue}
                    isPaid={cardPayload.isPaid}
                    isPending={cardPayload.isPending}
                    cardLoading={cardPayload.loading}
                    setCardPayloads={setCardIndividualLoad}
                    setPayments={setPayments}
                    setCaptcha={setCaptcha}
                    captchaValue={captchaValue}
                    cardError={cardPayload.error}
                    transactionType={type}
                    status={status}
                    authToken={authToken}
                  />
                );
              }
            )}
          </AccordionDetails>
        </Accordion>
      )}
      {currentTabIndex === TAB_OPTIONS.MULTIPLE && (
        <Accordion
          id="secondAccordion"
          name="secondAccordion"
          className={classes.accordionContainer}
          onChange={(_event, newExpanded) => {
            setSecondAccordionExpanded(newExpanded);
          }}
          classes={{
            expanded: classes.expandedItem
          }}
          expanded={secondAccordionExpanded}
        >
          <AccordionSummary
            expandIcon={displayIcon(secondAccordionExpanded)}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Referencias de pago agregadas con plantilla (
              {totalMultipleLoadCards.length || 0})
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionContent}>
            {totalMultipleLoadCards.map(
              ({ type, detail: cardPayload, status }) => {
                return (
                  <PaymentCard
                    key={
                      (cardPayload.contractId || 0) +
                      (cardPayload.couponId || 0)
                    }
                    number={cardPayload.contractId}
                    generationDate={cardPayload.generationDate}
                    contractCity={cardPayload.contractCity}
                    contractAddress={cardPayload.contractAddress}
                    contractId={cardPayload.contractId}
                    couponId={cardPayload.couponId}
                    expirationDate={cardPayload.expirationDate}
                    couponValue={cardPayload.couponValue}
                    isPaid={cardPayload.isPaid}
                    isPending={cardPayload.isPending}
                    cardLoading={cardPayload.loading}
                    setCardPayloads={setCardMultipleLoad}
                    setPayments={setPayments}
                    setCaptcha={setCaptcha}
                    captchaValue={captchaValue}
                    cardError={cardPayload.error}
                    transactionType={type}
                    status={status}
                    authToken={authToken}
                  />
                );
              }
            )}
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  heading: {
    color: theme.palette.common.black,
    fontSize: 12,
    fontWeight: theme.typography.fontWeight
  },
  accordionContainer: {
    backgroundColor: '#F8FBFF'
  },
  accordionContent: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    padding: theme.spacing(3, 2)
  },
  expandedItem: {
    fontWeight: 600,
    backgroundColor: '#F0F6FF'
  },
  icon: {
    color: theme.palette.color.primary
  }
}));
export default PaymentAccordion;
