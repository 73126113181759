import React, { Fragment, useState, useContext, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Breadcrumbs,
  Link,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import _get from 'lodash/get';
import clsx from 'clsx';

import Stepper from '../../../../Components/Stepper/Stepper';
import FooterStepper from '../../../../Components/Stepper/FooterStepper';

import PersonalDataStep from '../Steps/PersonalDataStep';
import TermsStep from '../Steps/TermsStep';
import SummaryStep from '../Steps/SummaryStep';
import DeferredSidebar from '../../Components/Deferred/DeferredSidebar';
import ConceptsSummaryCard from '../../Components/ConceptsSummaryCard';

import { FORM_ID, DEFERRED_PAYMENT_STEPS } from '../Deferred_enums';
import { StepperProvider, Step } from '../../../../Contexts/StepperContext';
import { AlertsDispatchContext } from '../../../../Contexts/AlertsContext';
import {
  ContractSelectedContext,
  ContractsContext
} from '../../../../Contexts/ContractsContext';
import { UserContext } from '../../../../Contexts/UserContext';

import PasswordDialog from '../../../Profile/PasswordDialog';

import { ROUTE_NAMES } from '../../../../Routes/Routes';
import { history } from '../../../../Routes/history';

const DeferredPaymentGDC = props => {
  const { location: locationParams = { state: null } } = props;

  const classes = useStyles();
  const params = locationParams.state;

  // * CONTEXTS
  const contracts = useContext(ContractsContext);
  const selectedContract = useContext(ContractSelectedContext);
  const currentUser = useContext(UserContext);
  const setAlert = useContext(AlertsDispatchContext);

  const authToken = _get(currentUser, 'token');

  // * STATE HOOKS
  const [canSubmit, setCanSubmit] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [passwordDialog, setPasswordDialog] = useState(null);
  const [nextButtonText, setNextButtonText] = useState('Continuar');
  const [goBack, setGoBack] = useState({});
  const [openSidebarDialog, setOpenSidebarDialog] = useState(false);

  // * OTHER HOOKS
  const theme = useTheme();
  const isMediumScreenSize = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  useEffect(() => {
    if (!params || params.selectedContract.id !== selectedContract.id) {
      history.replace(ROUTE_NAMES.debtStatus);
    }
  }, [params, selectedContract]);

  useEffect(() => {
    if (!authToken) {
      history.replace('/');
      return;
    }
    if (!contracts || contracts.length === 0) {
      history.replace(ROUTE_NAMES.contracts);
      return;
    }

    if (!params || params.selectedContract.id !== selectedContract.id) {
      history.replace(ROUTE_NAMES.debtStatus);
      return;
    }
  }, [contracts, authToken, params, selectedContract]);

  // * GLOBAL VARS
  const debtsData = params.debtsData;
  const currentDebtValue = debtsData && debtsData.currentValue;
  const conceptItemsCount = params.addedDeferreds.length;
  const totalAmount =
    params.addedDeferreds.reduce((acc, item) => item.pendingValue + acc, 0) +
    currentDebtValue;
  const invoicesData = params.invoicesData;

  const renderContent = () => {
    return (
      <Fragment>
        <Typography paragraph>
          A continuación te generaremos un cupón en el cual encontrarás la
          información del diferido que deseas pagar.
        </Typography>
        <Typography paragraph>
          <b>Importante: </b>El cupón generado tiene una{' '}
          <b>
            vigencia máxima de hasta cinco (5) días calendario. Esta fecha la
            puedes encontrar al momento de descargar el cupón de pago.
          </b>{' '}
          Si realizas el pago del diferido después de la fecha de vencimiento el
          valor se aplicará como saldo a favor de tu cuenta de gas y tendrás que
          contactar a servicio al cliente para poder aplicar el pago al diferido
          seleccionado.
        </Typography>
        <Typography paragraph>
          Recuerda que puedes pagar en cualquier momento a través de nuestro
          portal web usando el número del cupón generado, siguiendo la opción
          <b> Pagar Cupón</b> enviada en el correo o con el adjunto enviado en
          el correo para pago en puntos de atención presencial.
        </Typography>
        <Typography paragraph>
          Una vez hecho el pago te notificaremos a través de tu correo de su
          aplicación hasta 3 horas después de haberse realizado. Del mismo modo,
          se te notificará con un correo si encontramos problemas con tu pago.
        </Typography>
      </Fragment>
    );
  };

  const openSidebarDialogHandler = () => {
    setOpenSidebarDialog(true);
  };

  const closeSidebarDialogHandler = () => {
    setOpenSidebarDialog(false);
  };

  if (!params) {
    return <Fragment></Fragment>;
  }

  return (
    <Fragment>
      <div className={classes.root}>
        {passwordDialog && (
          <PasswordDialog
            title={passwordDialog.title}
            open={Boolean(passwordDialog)}
            setDialog={setPasswordDialog}
            setAlert={setAlert}
            requestCallback={passwordDialog.requestCallback}
            successCallback={passwordDialog.successCallback}
          />
        )}
        <div
          className={clsx(classes.contentContainer, classes.centerContent, {
            [classes.mobilePadding]: isMediumScreenSize
          })}
        >
          <div className={clsx(classes.container, classes.contentContainer)}>
            <Breadcrumbs
              aria-label="breadcrumb"
              className={classes.breadcrumbs}
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Link
                className={classes.link}
                color="textPrimary"
                component={RouterLink}
                to="/"
                underline="always"
              >
                Inicio{' '}
              </Link>
              <Link
                className={classes.link}
                color="textPrimary"
                component={RouterLink}
                to={ROUTE_NAMES.debtStatus}
                underline="always"
              >
                Mis deudas{' '}
              </Link>
              {!params.fromCurrentDebt && (
                <Link
                  className={classes.link}
                  color="textPrimary"
                  component={RouterLink}
                  to={ROUTE_NAMES.deferredDebtSummary}
                  underline="always"
                >
                  Deuda financiada{' '}
                </Link>
              )}
              {params.fromCurrentDebt && (
                <Link
                  className={classes.link}
                  color="textPrimary"
                  component={RouterLink}
                  to={ROUTE_NAMES.currentDebtSummary}
                  underline="always"
                >
                  Deuda actual{' '}
                </Link>
              )}
              <Typography color="primary">Pagar deuda</Typography>
            </Breadcrumbs>
          </div>
          <div
            className={clsx(
              classes.stepperContainer,
              classes.innerContentContainer,
              classes.centerContent
            )}
          >
            <Stepper steps={DEFERRED_PAYMENT_STEPS} currentStep={currentStep} />
          </div>

          {isMediumScreenSize && (
            <div
              className={clsx(
                classes.innerContentContainer,
                classes.centerContent
              )}
            >
              <ConceptsSummaryCard
                itemsCount={conceptItemsCount}
                totalAmount={totalAmount}
                title="Items para pagar"
                action="pagar"
                onDetailsClick={openSidebarDialogHandler}
              />
            </div>
          )}

          <StepperProvider
            initialStepIndex={params.initialStepIndex}
            couponId={params.couponId}
          >
            <Step>
              <div
                className={clsx(
                  classes.innerContentContainer,
                  classes.centerContent
                )}
              >
                <PersonalDataStep
                  formId={FORM_ID}
                  setCanSubmit={setCanSubmit}
                  setGoBack={setGoBack}
                  setCurrentStep={setCurrentStep}
                  setNextButtonText={setNextButtonText}
                  setAlert={setAlert}
                />
              </div>
            </Step>
            <Step>
              <TermsStep
                addedDeferreds={params.addedDeferreds}
                formId={FORM_ID}
                setCanSubmit={setCanSubmit}
                setGoBack={setGoBack}
                setCurrentStep={setCurrentStep}
                setNextButtonText={setNextButtonText}
                content={renderContent()}
                currentDebtValue={currentDebtValue}
                invoicesData={invoicesData}
              />
            </Step>
            <Step>
              <SummaryStep
                addedDeferreds={params.addedDeferreds}
                formId={FORM_ID}
                setCanSubmit={setCanSubmit}
                setGoBack={setGoBack}
                setCurrentStep={setCurrentStep}
                setNextButtonText={setNextButtonText}
                selectedContract={selectedContract}
                isMobileSize={isMediumScreenSize}
                currentDebtValue={currentDebtValue}
              />
            </Step>
          </StepperProvider>
        </div>
        <DeferredSidebar
          addedDeferreds={params.addedDeferreds}
          forPayment
          isMobileSize={isMediumScreenSize}
          openDialog={openSidebarDialog}
          onCloseDialog={closeSidebarDialogHandler}
          title="Resumen de conceptos"
          debtsData={debtsData}
        />
        <FooterStepper
          targetForm={FORM_ID}
          disabledNext={!canSubmit}
          goBack={goBack}
          nextButtonText={nextButtonText}
        />
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: `calc(${
      theme.custom.footerHeight.stepper
    }px + ${theme.spacing(9)}px)`
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2)
  },
  breadcrumbs: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium,
    '& *': { fontSize: 'inherit' }
  },
  stepperContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3)
    }
  },
  contentContainer: {
    maxWidth: 768
  },
  innerContentContainer: {
    maxWidth: 566
  },
  centerContent: {
    margin: '0 auto'
  },
  mobilePadding: {
    padding: `0px ${theme.spacing(2)}px`
  },
  container: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginTop: theme.spacing(3)
    }
  },
  link: {
    color: theme.palette.text.greyDark
  }
}));

export default DeferredPaymentGDC;
