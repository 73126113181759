import React from 'react';
import { Router, Route } from 'react-router-dom';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import GAListener from './GAListener';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { theme } from './Configs/Theme';
import { history } from './Routes/history';
import Layout from './Views/Layout/Layout';
import { UserProvider } from './Contexts/UserContext';
import { ContractsProvider } from './Contexts/ContractsContext';
import { FooterProvider } from './Contexts/FooterContext';
import Autoupdate from './Components/Autoupdate/Autoupdate';
import InternetExplorerChecker from './Components/IEChecker/IEChecker';
import ErrorBoundary from './Components/ErrorBoundary/ErrorBoundary';
import MaintenanceApp from './MaintenanceApp';
import { AlertsProvider } from './Contexts/AlertsContext';
import { FeaturesProvider } from './Contexts/FeaturesContext';
import { CircularMenuProvider } from './Contexts/CircularMenuContext';
import { GoogleClientProvider } from './Contexts/GoogleClientContext';
import { AppleClientProvider } from './Contexts/AppleClientContext';
import { config } from './Configs';
import 'react-calendar/dist/Calendar.css';
import 'react-toastify/dist/ReactToastify.css';
import CustomToastContainer from './Components/Alerts/CustomToastContainer';

function storageAvailable(type) {
  let storage;
  try {
    storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

function App() {
  if (!storageAvailable('localStorage')) {
    return (
      <MaintenanceApp message="Debes habilitar las Cookies para poder utilizar este portal." />
    );
  }

  return (
    <ErrorBoundary
      errorComponent={
        <MaintenanceApp message="Ha ocurrido un error, por favor actualiza la página." />
      }
    >
      <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <CustomToastContainer />
          <AlertsProvider>
            <CircularMenuProvider>
              <GoogleClientProvider>
                <AppleClientProvider>
                  <UserProvider>
                    <FeaturesProvider>
                      <ContractsProvider>
                        <GAListener
                          trackingId={config.google.analyticsId}
                          appVersion={config.sentry.relaseVersion}
                        >
                          <Autoupdate
                            currentVersion={config.sentry.relaseVersion}
                          />
                          <InternetExplorerChecker />

                          <FooterProvider>
                            <Router history={history}>
                              <Route
                                path="/"
                                render={renderProps => {
                                  return <Layout {...renderProps} />;
                                }}
                              />
                            </Router>
                          </FooterProvider>
                        </GAListener>
                      </ContractsProvider>
                    </FeaturesProvider>
                  </UserProvider>
                </AppleClientProvider>
              </GoogleClientProvider>
            </CircularMenuProvider>
          </AlertsProvider>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </ErrorBoundary>
  );
}

export default App;
