import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Title from '../../../Components/Labels/Title';
import ButtonWithImage from '../../../Components/Buttons/ButtonWithImage';
import { Grow } from '@material-ui/core';
import clsx from 'clsx';

const PaymentMethodOptions = props => {
  const {
    methods,
    titleText = 'Selecciona el medio de pago con el que vas a pagar',
    growIn = true
  } = props;

  const rootRef = useRef();

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, []);

  const someSelected = methods.some(({ selected }) => Boolean(selected));

  useEffect(() => {
    if (rootRef.current && someSelected) {
      rootRef.current.scrollIntoView();
    }
  }, [someSelected]);

  const classes = useStyles();

  return (
    <Grow in={growIn}>
      <div
        className={clsx({ [classes.paddingBottom]: !someSelected })}
        ref={rootRef}
      >
        <Title text={titleText} className={classes.title} />
        <Grid container className={classes.buttonsContainer}>
          {methods.map(method => {
            if (method.hidden) {
              return null;
            }
            return (
              <Grid item key={method.id}>
                <ButtonWithImage
                  id={method.id}
                  title={method.label}
                  image={method.image}
                  onClick={method.onClick}
                  isSvg={method.isSvg}
                  selected={method.selected}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    </Grow>
  );
};

const useStyles = makeStyles(theme => ({
  paddingBottom: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingBottom: theme.spacing(6)
    }
  },
  buttonsContainer: {
    '& > *': {
      marginRight: theme.spacing(4)
    },
    '& > :last-child': {
      marginRight: 0
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      '& > *': {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1)
      },
      '& > :last-child': {
        marginRight: 0
      }
    }
  },
  title: {
    fontSize: 14,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 16
    }
  }
}));

export default PaymentMethodOptions;
