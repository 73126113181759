import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import HelpGDC from './Help.GDC';
import HelpGDG from './Help.GDG';

const Help = props => {
  switch (portal) {
    case Portal.Gascaribe:
    case Portal.TripleA:
      return <HelpGDC {...props} />;
    case Portal.Gasguajira:
      return <HelpGDG {...props} />;
    case Portal.Efigas:
      return <HelpGDC {...props} />;
    default:
      return <></>;
  }
};

export default Help;
