import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import QuotaDetailGDC from './QuotaDetail.GDC';
import QuotaDetailGDG from './QuotaDetail.GDG';
import QuotaDetailEFG from './QuotaDetail.EFG';

const QuotaDetail = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <QuotaDetailGDC {...props} />;
    case Portal.Gasguajira:
      return <QuotaDetailGDG {...props} />;
    case Portal.Efigas:
      return <QuotaDetailEFG {...props} />;
    default:
      return <></>;
  }
};

export default QuotaDetail;
