import React from 'react';
import { makeStyles } from '@material-ui/core';
import BigOvenIcon from '../../CustomIcons/BigOvenIcon';
import GasesLogoIcon from '../../CustomIcons/GasesLogoIcon';
import clsx from 'clsx';

const Status = {
  Loading: 'loading',
  Success: 'success',
  Error: 'error'
};

const StatusBackgroundColor = {
  [Status.Loading]: '#EEF9FB',
  [Status.Success]: '#EEFBEE',
  [Status.Error]: '#FBEEEE'
};

const OvenLogoAnimationProgress = props => {
  const { size = 400, status = Status.Loading } = props;

  const ovenBrandRatio = 0.3;
  const circularBackgroundRatio = 1.125;

  const initialOvenSize = size;

  const brandSize = initialOvenSize * ovenBrandRatio;

  const calculatedClassProps = {
    left: (initialOvenSize - brandSize) / 2,
    bottom: brandSize,
    size: circularBackgroundRatio * size,
    backgroundColor: StatusBackgroundColor[status]
  };

  const classes = useStyles(calculatedClassProps)();

  const isLoading = status === Status.Loading;
  const isError = status === Status.Error;

  const animationStopped = isLoading ? {} : classes.animationStopped;
  const brandDisplay = isError ? classes.brandHide : {};

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <BigOvenIcon
          id="animation-progress-oven"
          width={initialOvenSize}
          height={initialOvenSize}
          classes={{
            animation: {
              heater: {
                left: clsx(
                  classes.animation,
                  classes.heaterLeft,
                  animationStopped
                ),
                right: clsx(
                  classes.animation,
                  classes.heaterRight,
                  animationStopped
                )
              },
              handler: {
                stick1: clsx(
                  classes.animation,
                  classes.handlerStick1,
                  animationStopped
                ),
                stick2: clsx(
                  classes.animation,
                  classes.handlerStick2,
                  animationStopped
                ),
                stick3: clsx(
                  classes.animation,
                  classes.handlerStick3,
                  animationStopped
                ),
                stick4: clsx(
                  classes.animation,
                  classes.handlerStick4,
                  animationStopped
                )
              }
            }
          }}
        />
        <GasesLogoIcon
          className={classes.brand}
          id="animation-progress-brand"
          width={brandSize}
          height={brandSize}
          classes={{
            animation: {
              flame: {
                bigBlue: clsx(
                  classes.animation,
                  classes.flameBigBlue,
                  animationStopped,
                  brandDisplay
                ),
                left: clsx(
                  classes.animation,
                  classes.flameLeft,
                  animationStopped,
                  brandDisplay
                ),
                right: clsx(
                  classes.animation,
                  classes.flameRight,
                  animationStopped,
                  brandDisplay
                ),
                smallBlue: clsx(
                  classes.animation,
                  classes.flameSmallBlue,
                  animationStopped,
                  brandDisplay
                )
              }
            }
          }}
        />
      </div>
    </div>
  );
};

const useStyles = props =>
  makeStyles(theme => ({
    root: {
      backgroundColor: props.backgroundColor,
      borderRadius: '50%',
      width: props.size,
      height: props.size,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transitionProperty: 'background-color',
      transitionDuration: '1s'
    },
    container: {
      position: 'relative'
    },
    brand: {
      position: 'absolute',
      left: props.left,
      bottom: props.bottom
    },
    animation: {
      animationDirection: 'alternate',
      animationIterationCount: 'infinite',
      animationTimingFunction: theme.animation.timingFunction.inOutBack
    },
    animationStopped: {
      animation: 'unset !important'
    },
    heaterLeft: {
      animationName: '$heat',
      animationDuration: '10s'
    },
    heaterRight: {
      animationName: '$heat',
      animationDuration: '10s',
      animationDelay: '10s'
    },
    handlerStick1: {
      animationName: '$rotate',
      transformOrigin: '193px 140px',
      animationDelay: '0.5s',
      animationDuration: '1.2s'
    },
    handlerStick2: {
      animationName: '$rotate',
      transformOrigin: '286px 140px',
      animationDelay: '0.5s',
      animationDuration: '1.8s'
    },
    handlerStick3: {
      animationName: '$rotate',
      transformOrigin: '514px 140px',
      animationDelay: '1.1s',
      animationDuration: '2.4s'
    },
    handlerStick4: {
      animationName: '$rotate',
      transformOrigin: '607px 140px',
      animationDelay: '1.1s',
      animationDuration: '3s'
    },
    flameSmallBlue: {
      animationDuration: '5s',
      animationName: '$flicker-4s'
    },
    flameBigBlue: {
      animationDuration: '5s',
      animationName: '$flicker-3s'
    },
    flameLeft: {
      animationDuration: '5s',
      animationName: '$flicker-2s'
    },
    flameRight: {
      animationDuration: '5s',
      animationName: '$flicker-1s'
    },
    brandHide: {
      opacity: 0
    },
    '@keyframes heat': {
      from: {
        fill: 'current'
      },
      to: {
        fill: '#763e3e'
      }
    },
    '@keyframes rotate': {
      '0%': {
        transform: 'rotate(0deg)'
      },
      '50%': {
        transform: 'rotate(-45deg)'
      },
      '100%': {
        transform: 'rotate(-90deg)'
      }
    },
    '@keyframes flicker-4s': {
      '0%': {
        filter: 'grayscale(0%)',
        opacity: 0
      },
      '20%': {
        filter: 'grayscale(0.001%)',
        opacity: 1
      }
    },
    '@keyframes flicker-3s': {
      '0%': {
        filter: 'grayscale(0%)',
        opacity: 0
      },
      '40%': {
        filter: 'grayscale(0.001%)',
        opacity: 1
      }
    },
    '@keyframes flicker-2s': {
      '0%': {
        filter: 'grayscale(0%)',
        opacity: 0
      },
      '60%': {
        filter: 'grayscale(0.001%)',
        opacity: 1
      }
    },
    '@keyframes flicker-1s': {
      '0%': {
        filter: 'grayscale(0%)',
        opacity: 0
      },
      '80%': {
        filter: 'grayscale(0.001%)',
        opacity: 1
      }
    }
  }));

export default OvenLogoAnimationProgress;
