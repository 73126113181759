import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { config } from '../../Configs';

const renderers = [];

const injectScript = (locale, nonce) => {
  window.GoogleRecaptchaLoaded = () => {
    while (renderers.length) {
      const renderer = renderers.shift();
      renderer();
    }
  };

  const script = document.createElement('script');
  script.async = true;
  script.defer = true;
  script.id = 'recaptcha';
  script.src = `https://www.google.com/recaptcha/api.js?${locale &&
    `hl=${locale}`}&onload=GoogleRecaptchaLoaded&render=explicit`;
  script.type = 'text/javascript';
  nonce && script.setAttribute('nonce', nonce);
  document.body.appendChild(script);
};

class GoogleRecaptcha extends React.Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    const {
      badge,
      locale,
      nonce,
      onExpired,
      onError,
      onLoaded,
      onResolved,
      sitekey = config.recaptcha.siteKey,
      tabindex,
      captchaRef
    } = this.props;

    if (captchaRef) {
      captchaRef.current = this;
    }

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    if (
      process.env.NODE_ENV !== 'production' ||
      Boolean(config.recaptcha.disableValidation)
    ) {
      onResolved('disable-captcha-validation');
    }

    this.callbackName = `GoogleRecaptchaResolved-${uuid()}`;
    window[this.callbackName] = onResolved;

    const loaded = () => {
      if (this.container) {
        const wrapper = document.createElement('div');
        // This wrapper must be appended to the DOM immediately before rendering
        // reCaptcha. Otherwise multiple reCaptchas will act jointly somehow.
        this.container.appendChild(wrapper);
        const recaptchaId = window.grecaptcha.render(wrapper, {
          badge,
          callback: this.callbackName,
          'error-callback': onError,
          'expired-callback': onExpired,
          sitekey,
          size: 'normal',
          tabindex
        });
        // this.execute = () => window.grecaptcha.execute(recaptchaId);
        this.reset = () => window.grecaptcha.reset(recaptchaId);
        this.getResponse = () => {
          return window.grecaptcha.getResponse(recaptchaId);
        };
        onLoaded();
      }
    };

    if (
      window.grecaptcha &&
      window.grecaptcha.render &&
      // window.grecaptcha.execute &&
      window.grecaptcha.reset &&
      window.grecaptcha.getResponse
    ) {
      loaded();
    } else {
      renderers.push(loaded);
      if (!document.querySelector('#recaptcha')) {
        injectScript(locale, nonce);
      }
    }
  }
  componentWillUnmount() {
    // while (this.container.firstChild) {
    //   this.container.removeChild(this.container.firstChild);
    // }
    // There is a chance that the reCAPTCHA API lib is not loaded yet, so check
    // before invoking reset.
    window.removeEventListener('resize', this.updateWindowDimensions);
    if (this.reset) {
      this.reset();
    }
    delete window[this.callbackName];
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    const downXM = isWidthDown('xm', this.props.width);
    const downXS = isWidthDown('xs', this.props.width);
    const referenceEl = this.props.referenceEl;
    const element = referenceEl.current;

    let calcWidth = (element.clientWidth + 1.0) / 304.0;
    const calcHeight =
      this.props.heightScale || (element.clientHeight - 1.0) / 72.0;
    let bottomMargin = 0.25 * this.state.width - 78.1;
    if (calcWidth > 1.1) {
      calcWidth = 1.1;
      bottomMargin = 16;
    }

    let ownStyle = {
      transform: `scale(${calcWidth}, ${calcHeight})`,
      transformOrigin: 'left',
      marginTop: 6
    };

    if (downXM || downXS) {
      ownStyle = {
        transform: `scale(${calcWidth})`,
        transformOrigin: 'left top',
        marginTop: 16,
        marginBottom: bottomMargin
      };
    }

    return (
      <div
        id="Recaptcha_div_container"
        ref={ref => {
          this.container = ref;
        }}
        style={{ ...ownStyle, ...this.props.style }}
      />
    );
  }
}

GoogleRecaptcha.propTypes = {
  badge: PropTypes.oneOf(['bottomright', 'bottomleft', 'inline']),
  locale: PropTypes.string,
  nonce: PropTypes.string,
  onExpired: PropTypes.func,
  onError: PropTypes.func,
  onResolved: PropTypes.func,
  onLoaded: PropTypes.func,
  sitekey: PropTypes.string.isRequired,
  style: PropTypes.object,
  tabindex: PropTypes.number
};

GoogleRecaptcha.defaultProps = {
  badge: 'bottomright',
  locale: '',
  onExpired: () => {},
  onError: () => {},
  onLoaded: () => {},
  onResolved: () => {},
  tabindex: 0
};

export default withWidth()(GoogleRecaptcha);
