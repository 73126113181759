/* eslint-disable complexity */
import React, {
  Fragment,
  useContext,
  useEffect,
  useCallback,
  useState,
  useRef
} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import moment from 'moment';

import FlagColombia from '../../../../Components/CustomIcons/Flags/FlagColombia';
import SweetAlert from '../../../../Components/Alerts/SweetAlert';
import TextInput from '../../../../Components/Inputs/TextInput';
import CompositeSelectInput from '../../../../Components/Inputs/CompositeSelectInput';
import NumberInput from '../../../../Components/Inputs/NumberInput';
import Title from '../../../../Components/Labels/Title';
import ModalProgress from '../../../../Components/Progress/Modal/ModalProgress';
import PhoneVerificationDialog from '../../../../Components/Dialogs/PhoneVerificationDialog';
import Recaptcha from '../../../../Components/Captcha/Recaptcha';

import { UserContext } from '../../../../Contexts/UserContext';
import {
  SetCurrentStepIndexContext,
  StepperDataContext,
  StepperDataDispatchContext,
  CurrentStepIndexContext
} from '../../../../Contexts/StepperContext';

import { ValidateIdentificationAPI } from '../../../../API/UserAPI';
import { ValidateOwnership } from '../../../../API/Contracts/ContractsAPI';
import { extractErrorMessage } from '../../../../Utils/Errors/Errors';

import { IDENTIFICATION_OPTIONS } from '../Refinance_enums';
import { history } from '../../../../Routes/history';
import { ROUTE_NAMES } from '../../../../Routes/Routes';
import clsx from 'clsx';
import { config } from '../../../../Configs';
import { ALERT_TYPE } from '../../../../Components/Alerts/alert_enums';
import { Company } from '../../../../Configs/general';

const schema = yup.object({
  firstName: yup
    .string()
    .max(150, 'Máximo 150 carácteres')
    .required('Ingresa los nombres'),
  lastName: yup
    .string()
    .max(150, 'Máximo 150 carácteres')
    .required('Ingresa los apellidos'),
  identificationType: yup
    .string()
    .oneOf(IDENTIFICATION_OPTIONS.map(idType => idType.value))
    .required('Elija un tipo valido'),
  identification: yup
    .number()
    .typeError('El número de identificación debe ser válido')
    .positive('El número de identificación debe ser válido')
    .test('integer', 'El número de identificación debe ser válido', val => {
      return val % 1 === 0;
    })
    .required('Ingresa el número de identificación'),
  expeditionDate: yup
    .date()
    .max(new Date(), 'Fecha no valida')
    .typeError('La fecha debe ser válida')
    .required('Elija una fecha valida'),
  phone: yup
    .string()
    .trim()
    .length(10, 'Debe tener 10 dígitos')
    .required('Ingresa un teléfono'),
  phoneCountryCode: yup
    .string()
    .trim()
    .max(3, 'Debe tener máximo 3 dígitos')
    .required('Debe ser válido'),
  terms: yup.boolean().oneOf([true], 'Este campo es requerido.')
});

const PersonalDataStep = props => {
  const {
    formId,
    setCanSubmit,
    setGoBack,
    setCurrentStep: setVisualStepperIndex,
    setNextButtonText,
    setAlert,
    addedDeferreds,
    paramsSelectedContract,
    fromDeferredDebt,
    setSidebarVisible,
    selectedContract,
    onlyHaveGasProducts
  } = props;
  const classes = useStyles();

  const currentStepIndex = useContext(CurrentStepIndexContext);
  const setCurrentStep = useContext(SetCurrentStepIndexContext);
  const setData = useContext(StepperDataDispatchContext);
  const stepperData = useContext(StepperDataContext);

  const currentUser = useContext(UserContext);

  const { register, watch, errors, control, handleSubmit } = useForm({
    validationSchema: schema,
    submitFocusError: true,
    defaultValues: {
      firstName: currentUser ? currentUser.firstName : '',
      lastName: currentUser ? currentUser.lastName : '',
      terms: false,
      identificationType:
        currentUser && currentUser.identificationType
          ? currentUser.identificationType
          : 'CC',
      identification: currentUser ? currentUser.identification : '',
      phone: currentUser ? currentUser.phone : '',
      ...stepperData
    }
  });

  const [loading, setLoading] = useState(false);
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const [submittedValues, setSubmittedValues] = useState(null);

  const recaptchaRef = useRef(null);
  const referenceRef = useRef(null);

  const watchForm = watch(['phoneCountryCode', 'phone']);

  const onCaptchaResolved = useCallback(
    token => {
      setCaptcha(token);
    },
    [setCaptcha]
  );

  const onCaptchaExpired = useCallback(() => {
    setCaptcha(null);
  }, [setCaptcha]);

  const onCaptchaLoaded = useCallback(() => {
    setTimeout(() => {
      setLoadingCaptcha(false);
    }, 1000);
  }, [setLoadingCaptcha]);

  const onSuccessUserData = useCallback(
    formValues => {
      const formattedDate = moment(formValues.expeditionDate).format(
        'YYYY-MM-DD'
      );

      setData(d => ({
        ...d,
        ...formValues,
        expeditionDate: formattedDate,
        contractId: selectedContract.id,
        contractCity: selectedContract.city,
        contractAddress: selectedContract.address
      }));
      setCurrentStep(step => step + 1);
    },
    [setData, selectedContract, setCurrentStep]
  );

  const handleOpenVerificationDialog = useCallback(
    values => {
      const {
        phone: currentPhone,
        phoneCountryCode: currentCountryCode
      } = values;
      const previousPhone = stepperData.phone;
      const previousCountryCode = stepperData.phoneCountryCode;
      const phoneHasChanged = previousPhone !== currentPhone;
      const countryCodeHasChanged = previousCountryCode !== currentCountryCode;

      if (!phoneHasChanged && !countryCodeHasChanged) {
        setSubmittedValues(null);
        onSuccessUserData(values);
        return;
      }
      setSubmittedValues(values);
      setOpenVerificationDialog(true);
    },
    [stepperData.phone, stepperData.phoneCountryCode, onSuccessUserData]
  );

  const verifyUserData = useCallback(
    async values => {
      const captchaValue = captcha;
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
        setCaptcha(null);
      }

      const { identification, identificationType, expeditionDate } = values;
      const formattedDate = moment(expeditionDate).format('YYYY-MM-DD');

      const identificationHasChanged =
        stepperData.identification !== identification;

      const expeditionDateHasChanged =
        stepperData.expeditionDate !== formattedDate;

      if (identificationHasChanged || expeditionDateHasChanged) {
        setLoading(true);

        const identificationData = {
          identification,
          identificationType,
          expeditionDate
        };

        const response = await ValidateIdentificationAPI(
          currentUser.token,
          identificationData,
          captchaValue
        );

        if (!response.success) {
          setLoading(false);
          setAlert({
            type: 'error',
            message: extractErrorMessage(response).message
          });
          return;
        }
      }

      if (!onlyHaveGasProducts && identificationHasChanged) {
        const queryData = {
          contractId: selectedContract.id,
          identification
        };

        const response = await ValidateOwnership(currentUser.token, queryData);

        if (!response.success) {
          setLoading(false);
          setAlert({
            type: 'error',
            message: extractErrorMessage(response).message
          });
          return;
        }

        const responseData = response.data.data;

        if (!responseData.isTheHolder) {
          setLoading(false);
          setAlert({
            type: 'error',
            message: `No eres el titular ${Company.contractConjugation.regular.singular.contraction}`
          });
          return;
        }

        setAlert({
          type: ALERT_TYPE.SUCCESS,
          message: 'Los datos del titular han sido validados correctamente.',
          toast: true,
          timeout: 3000
        });
      }

      setLoading(false);
      handleOpenVerificationDialog(values);
    },
    [
      currentUser.token,
      selectedContract,
      setAlert,
      stepperData.expeditionDate,
      stepperData.identification,
      handleOpenVerificationDialog,
      captcha,
      onlyHaveGasProducts
    ]
  );

  const onForward = async values => {
    await verifyUserData(values);
  };

  const onBackward = useCallback(() => {
    if (fromDeferredDebt) {
      history.replace(ROUTE_NAMES.deferredDebtSummary, {
        addedDeferreds,
        selectedContract: paramsSelectedContract
      });
    } else {
      history.goBack();
    }
  }, [addedDeferreds, fromDeferredDebt, paramsSelectedContract]);

  const handleCopyPaste = evt => {
    evt.preventDefault();
  };

  // Props setters
  useEffect(() => {
    setSidebarVisible(true);
    setNextButtonText('Siguiente');
    setVisualStepperIndex(currentStepIndex);
    setCanSubmit(true);
    setGoBack({
      action: onBackward
    });
  }, [
    setGoBack,
    onBackward,
    setVisualStepperIndex,
    currentStepIndex,
    setNextButtonText,
    setCanSubmit,
    setSidebarVisible
  ]);

  useEffect(() => {
    setCanSubmit(!loading && !loadingCaptcha && Boolean(captcha));
  }, [loading, loadingCaptcha, setCanSubmit, captcha]);

  if (!currentUser) {
    return <Fragment></Fragment>;
  }

  return (
    <div className={classes.root}>
      {loading && <ModalProgress message="Consultando" />}
      {loadingCaptcha && <ModalProgress message="Cargando" />}
      {openVerificationDialog && (
        <PhoneVerificationDialog
          open={openVerificationDialog}
          setDialog={setOpenVerificationDialog}
          setAlert={setAlert}
          title="Validar número celular"
          successCallback={() => onSuccessUserData(submittedValues)}
          formPhone={watchForm.phone}
          formPhoneCountryCode={watchForm.phoneCountryCode}
        />
      )}
      <Title title="Valida tus datos personales" className={classes.title} />

      <form id={formId} onSubmit={handleSubmit(onForward)}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}
            className={clsx(classes.item, classes.leftItem)}
          >
            <TextInput
              label="Nombres*"
              inputProps={{ maxLength: 100 }}
              inputRef={register}
              fullWidth
              name="firstName"
              error={Boolean(errors.firstName)}
              helperText={errors.firstName && errors.firstName.message}
              onCut={handleCopyPaste}
              onCopy={handleCopyPaste}
              onPaste={handleCopyPaste}
              margin="none"
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.item}>
            <TextInput
              label="Apellidos*"
              inputProps={{ maxLength: 100 }}
              inputRef={register}
              fullWidth
              name="lastName"
              error={Boolean(errors.lastName)}
              helperText={errors.lastName && errors.lastName.message}
              onCut={handleCopyPaste}
              onCopy={handleCopyPaste}
              onPaste={handleCopyPaste}
              margin="none"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className={clsx(classes.item, classes.leftItem)}
          >
            <CompositeSelectInput
              label="Identificación*"
              options={IDENTIFICATION_OPTIONS}
              TextInputProps={{
                label: 'Identificación*',
                name: 'identification',
                defaultValue: currentUser.identification || '',
                inputRef: register,
                fullWidth: true,
                margin: 'none'
              }}
              SelectInputProps={{
                name: 'identificationType',
                defaultValue: currentUser.identificationType || 'CC',
                control
              }}
              error={Boolean(errors.identification)}
              helperText={
                errors.identification && errors.identification.message
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.item}>
            <TextInput
              label="Fecha de expedición*"
              inputProps={{ maxLength: 100 }}
              fullWidth
              inputRef={register}
              type="date"
              name="expeditionDate"
              helperText={
                errors.expeditionDate && errors.expeditionDate.message
              }
              error={Boolean(errors.expeditionDate)}
              InputLabelProps={{ shrink: true }}
              margin="none"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container>
              <Grid
                item
                xs={5}
                className={clsx(classes.item, classes.prefixItem)}
              >
                <Controller
                  as={
                    <NumberInput
                      id="MyProfile_input_countryCode"
                      autoComplete="tel-country-code"
                      InputProps={{
                        startAdornment: (
                          <FlagColombia size={20} style={{ flexShrink: 0 }} />
                        )
                      }}
                      format="+###"
                      placeholder="57"
                      fullWidth
                      label="Prefijo"
                      error={Boolean(errors.phoneCountryCode)}
                      helperText={
                        errors.phoneCountryCode &&
                        errors.phoneCountryCode.message
                      }
                      margin="none"
                    />
                  }
                  control={control}
                  name="phoneCountryCode"
                  defaultValue={currentUser.phoneCountryCode || '57'}
                  onChangeName="onValueChange"
                  onChange={values => {
                    if (values[0]) {
                      return values[0].value;
                    }
                    return '';
                  }}
                />
              </Grid>
              <Grid
                item
                xs={7}
                className={clsx(classes.item, classes.leftItem)}
              >
                <Controller
                  as={
                    <NumberInput
                      id="MyProfile_input_phone"
                      autoComplete="tel-local"
                      fullWidth
                      margin="none"
                      label="Número celular"
                      error={Boolean(errors.phone)}
                      helperText={errors.phone && errors.phone.message}
                    />
                  }
                  control={control}
                  name="phone"
                  onChangeName="onValueChange"
                  onChange={values => {
                    if (values[0]) {
                      return values[0].value;
                    }
                    return '';
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            ref={referenceRef}
            className={classes.recaptchaItem}
          >
            <Recaptcha
              captchaRef={recaptchaRef}
              referenceEl={referenceRef}
              heightScale={0.75}
              locale={'es'}
              sitekey={config.recaptcha.siteKey}
              onResolved={onCaptchaResolved}
              onExpired={onCaptchaExpired}
              onLoaded={onCaptchaLoaded}
              style={{
                margin: 0,
                transformOrigin: 'left top'
              }}
            />
          </Grid>
        </Grid>
      </form>
      {!onlyHaveGasProducts && (
        <SweetAlert
          className={classes.negotiationSweetAlert}
          type={ALERT_TYPE.INFO}
          message={`Para hacer esta negociación necesitas ingresar los datos del titular ${Company.contractConjugation.regular.singular.contraction}.`}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    '&:last-child': {
      marginBottom: theme.spacing(2)
    }
  },
  title: {
    margin: theme.spacing(2, 0, 4)
  },
  item: {
    paddingBottom: theme.spacing(3)
  },
  prefixItem: {
    paddingRight: theme.spacing(2)
  },
  leftItem: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingRight: 0
    }
  },
  recaptchaItem: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingBottom: theme.spacing(2)
    }
  }
}));

export default PersonalDataStep;
