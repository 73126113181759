import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import ContractAssociationDialogGDC from './ContractAssociationDialog.GDC';
import ContractAssociationDialogGDG from './ContractAssociationDialog.GDG';
import ContractAssociationDialogEFG from './ContractAssociationDialog.EFG';

const ContractAssociationDialog = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <ContractAssociationDialogGDC {...props} />;
    case Portal.TripleA:
    case Portal.Gasguajira:
      return <ContractAssociationDialogGDG {...props} />;
    case Portal.Efigas:
      return <ContractAssociationDialogEFG {...props} />;
    default:
      return <></>;
  }
};

export default ContractAssociationDialog;
