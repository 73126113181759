import * as React from 'react';

const PartialPaymentIcon = ({
  width = 35,
  height = 35,
  stroke = 'currentColor',
  fill = 'currentColor',
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 150}
    height={height || 120.53}
    viewBox="0 0 112.5 90.397"
    stroke={stroke}
    fill={fill}
    {...rest}
  >
    <path
      d="M.478 86.378l20.934-7.63 24.47 7.537c1.396.651 3.07 1.21 4.931 1.768 1.861.465 3.629.744 5.118.93 16.933-3.628 33.774-7.164 50.707-10.793 3.35-1.302 5.21-4.93 4.187-8.373-1.023-3.443-4.745-5.49-8.374-4.56-10.141 2.327-20.283 4.56-30.424 6.793l-10.142-.652-17.678-2.884"
      fill="none"
      strokeMiterlimit={10}
      strokeWidth={2.7912299999999997}
    />
    <path
      d="M74.074 71.584c.372-.186 2.884-1.86 3.07-4.652.186-2.977-2.326-6.047-6.234-6.792-11.909-2.698-23.818-5.396-35.728-8.187-11.537 4-23.167 8.094-34.704 12.095"
      fill="none"
      strokeMiterlimit={10}
      strokeWidth={2.7912299999999997}
    />
    <path
      d="M39.462 52.51c-3.256-3.535-5.675-7.908-6.978-12.839a30.207 30.207 0 01-.93-9.49M83.006 54.186c-2.792 2.698-6.234 4.838-10.049 6.327M43.928 7.386c3.07-2.233 6.606-4.001 10.514-5.024 16.375-4.187 33.03 5.582 37.217 21.957.65 2.512.93 5.118.93 7.63"
      fill="none"
      strokeLinejoin="round"
      strokeWidth={2.7912299999999997}
    />
    <path
      d="M33.973 14.736l5.582-4.187-.93 6.885 4.187 5.676-6.978-1.024-5.583 4.187.93-6.885-4.186-5.582zM89.426 38.834l5.675-4.187-1.023 6.978 4.186 5.583-6.885-.93-5.582 4.186.93-6.978-4.187-5.583z"
      fill="none"
      strokeMiterlimit={10}
      strokeWidth={2.7912299999999997}
    />
    <path d="M61.326 46.277v-4.094c-2.326 0-4.745-.744-6.14-1.767l.93-2.699c1.489.93 3.536 1.768 5.769 1.768 2.884 0 4.745-1.675 4.745-3.908 0-2.233-1.582-3.535-4.56-4.745-4.093-1.581-6.605-3.442-6.605-6.978 0-3.35 2.326-5.861 6.048-6.513v-4.093h2.512v4c2.419.093 4.093.745 5.21 1.396l-1.024 2.698c-.837-.465-2.512-1.395-5.024-1.395-3.07 0-4.28 1.86-4.28 3.442 0 2.047 1.489 3.164 5.025 4.56 4.093 1.674 6.233 3.814 6.233 7.35 0 3.163-2.233 6.14-6.327 6.792v4.187z" />
  </svg>
);

export default PartialPaymentIcon;
