import React, { useState, useCallback } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid, Typography, Divider, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';

import EditIcon from '@material-ui/icons/Edit';

import { numberWithDots } from '../../Utils/Format/MoneyFormat';
import { downloadAsset } from '../../Utils/Misc/Assets';
import PractiseguroPDF from '../../Assets/docs/practiseguro-clausulas.pdf';

import DownloadIcon from '../../Components/CustomIcons/DownloadIcon';
import BaseButton from '../../Components/Buttons/BaseButton';

import { InsurancePlanIcon, InsurancePlans } from '../../Enums/insurances';
import SelectInsurancePlanDialog from '../Dialogs/SelectInsurancePlanDialog';

const InsuranceSelectedPlanCard = props => {
  const {
    planId,
    monthlyPay,
    withActions = true,
    plans = [],
    setPlanId = null
  } = props;

  const classes = useStyles();

  const isMobileSize = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  // * STATE HOOKS
  const [openSelectPlanDialog, setOpenSelectPlanDialog] = useState(false);

  const Icon = InsurancePlanIcon[planId];

  const handleCloseSelectPlanDialog = useCallback(() => {
    setOpenSelectPlanDialog(false);
  }, []);

  const handlePlanSelection = useCallback(
    id => {
      setPlanId(id);
      handleCloseSelectPlanDialog();
    },
    [setPlanId, handleCloseSelectPlanDialog]
  );

  return (
    <>
      {withActions && (
        <SelectInsurancePlanDialog
          open={openSelectPlanDialog}
          plans={plans}
          selectedPlan={planId}
          setSelectedPlan={handlePlanSelection}
          handleClose={handleCloseSelectPlanDialog}
        />
      )}
      <div className={classes.card}>
        <Grid container>
          <Grid container item className={classes.planSelectedContainer}>
            <Grid
              item
              container
              xs={12}
              sm={1}
              className={classes.planIconContainer}
            >
              <div className={classes.iconContainer}>
                <Icon size={36} className={classes.planIcon} />
              </div>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={4}
              className={classes.planDetailField}
            >
              <Grid item>
                <Typography className={clsx(classes.subtitle)}>
                  Plan seleccionado
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={clsx(classes.title)}>
                  {InsurancePlans[planId].name}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={4}
              className={classes.planDetailField}
            >
              <Grid item>
                <Typography className={clsx(classes.subtitle)}>
                  Asegurados
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={clsx(classes.title)}>
                  {InsurancePlans[planId].insuredNumber}{' '}
                  {InsurancePlans[planId].insuredNumber > 1
                    ? 'asegurados'
                    : 'asegurado'}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={3}
              className={classes.planDetailField}
            >
              <Grid item>
                <Typography className={clsx(classes.subtitle)}>
                  Valor mensual (COP)
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={clsx(classes.title)}>
                  {numberWithDots(monthlyPay)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {withActions && (
            <>
              <Divider className={classes.divider} />
              <Grid container item direction="row" justify="space-between">
                <Grid item xs={12} className={classes.actionButtonContainer}>
                  <BaseButton
                    color="default"
                    variant="text"
                    size="small"
                    fullWidth={isMobileSize}
                    className={classes.actionButton}
                    startIcon={<EditIcon className={classes.editIcon} />}
                    onClick={() => setOpenSelectPlanDialog(true)}
                  >
                    Cambiar plan
                  </BaseButton>
                </Grid>
                <Grid item xs={12} className={classes.actionButtonContainer}>
                  <BaseButton
                    color="default"
                    variant="text"
                    size="small"
                    fullWidth={isMobileSize}
                    className={classes.actionButton}
                    startIcon={<DownloadIcon />}
                    onClick={() =>
                      downloadAsset(
                        'Practiseguro - Clausulas.pdf',
                        PractiseguroPDF
                      )
                    }
                  >
                    Ver condiciones
                  </BaseButton>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: theme.custom.borderRadius,
    border: `1px solid ${theme.palette.background.border}`,
    position: 'relative',
    padding: theme.spacing(2, 3)
  },
  buttonContainer: {
    '& > *': {
      marginRight: theme.spacing(2)
    },
    '& > *:last-child': {
      marginRight: 0
    }
  },
  actionButtonContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      '& > *': {
        marginBottom: theme.spacing(2)
      },
      '&:last-child': {
        marginBottom: 0
      }
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      flexBasis: 'auto'
    }
  },
  label: {
    fontSize: 12,
    color: theme.palette.text.greyDark,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 13
    }
  },
  description: {
    fontSize: 13,
    lineHeight: 1.4,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 14
    }
  },
  margin: {
    marginBottom: theme.spacing(1)
  },
  alignRight: {
    textAlign: 'right'
  },
  paddingBottom: {
    paddingBottom: theme.spacing(1)
  },
  planSelectedContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginBottom: theme.spacing(1)
    }
  },
  planIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginBottom: theme.spacing(1)
    }
  },
  iconContainer: {
    height: 50,
    width: 50,
    padding: theme.spacing(1),
    [theme.breakpoints.between(
      theme.breakpoints.values.sm,
      theme.breakpoints.values.md
    )]: {
      height: 40,
      width: 40
    }
  },
  planDetailField: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'row',
      '& > *': {
        marginBottom: theme.spacing(1)
      },
      '& > *:last-child': {
        marginBottom: 0
      }
    }
  },
  editIcon: {
    fill: theme.palette.primary.main
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: '100%'
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.2,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 12
    }
  },
  subtitle: {
    fontSize: 12,
    color: theme.palette.text.greyDark
  },
  actionButton: {
    backgroundColor: theme.palette.primary.light
  }
}));

export default InsuranceSelectedPlanCard;
