import React, {
  Fragment,
  useState,
  useCallback,
  useContext,
  useRef,
  useEffect
} from 'react';
import * as yup from 'yup';
import { Grid, Grow, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  UpdateUserIdentificationAPI,
  ValidateIdentificationAPI
} from '../../../../API/UserAPI';
import { extractErrorMessage } from '../../../../Utils/Errors/Errors';

import { AlertsDispatchContext } from '../../../../Contexts/AlertsContext';

import TextInput from '../../../../Components/Inputs/TextInput';

import { SlideUpTransition } from '../../../../Components/Transitions/Transitions';
import BaseDialog from '../../../../Components/Dialogs/BaseDialog';
import BaseButton from '../../../../Components/Buttons/BaseButton';
import CompositeSelectInput from '../../../../Components/Inputs/CompositeSelectInput';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { submitExternalForm } from '../../../../Utils/Misc/Form';
import Recaptcha from '../../../../Components/Captcha/Recaptcha';
import { config } from '../../../../Configs';
import { DigitalInvoiceDeactivationStepId } from '../ContractDigitalInvoiceDeactivation';

import _get from 'lodash/get';
import { updateUser } from '../../../../Utils/User/Actions';
import { UserDispatchContext } from '../../../../Contexts/UserContext';
import SweetAlert from '../../../../Components/Alerts/SweetAlert';

const IDENTIFICATION_OPTIONS = [
  { label: 'CC', value: 'CC' },
  { label: 'PP', value: 'PP' },
  { label: 'CE', value: 'CE' },
  { label: 'TI', value: 'TI' }
];

const schema = yup.object({
  identificationType: yup
    .string()
    .oneOf(IDENTIFICATION_OPTIONS.map(idType => idType.value))
    .required('Elija un tipo valido'),
  identification: yup
    .number()
    .typeError('El número de identificación debe ser válido')
    .positive('El número de identificación debe ser válido')
    .test('integer', 'El número de identificación debe ser válido', val => {
      return val % 1 === 0;
    })
    .required('Ingresa el número de identificación'),
  expeditionDate: yup
    .date()
    .max(new Date(), 'Fecha no valida')
    .typeError('La fecha debe ser válida')
    .required('Elija una fecha valida')
});

const getUserFormDefaultValues = currentUser => {
  if (!currentUser) {
    return {
      identification: '',
      identificationType: 'CC'
    };
  }

  return {
    identification: currentUser.identification || '',
    identificationType: currentUser.identificationType || 'CC'
  };
};

const IdentificationStep = props => {
  const { open, title, onClose, setNextModal, step, currentUser } = props;

  // * CONTEXT HOOKS
  const setAlert = useContext(AlertsDispatchContext);
  const setCurrentUser = useContext(UserDispatchContext);

  // * STATE HOOKS
  const [loading, setLoading] = useState(false);

  const [captcha, setCaptcha] = useState(null);
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const [canSubmit, setCanSubmit] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(true);

  const recaptchaRef = useRef(null);
  const referenceRef = useRef(null);

  // * OTHER HOOKS
  const classes = useStyles();
  const isMobileSize = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  const formId = step.data.formId;

  const authToken = _get(currentUser, 'token');
  const userId = _get(currentUser, 'id');
  const identificationValidated = Boolean(
    _get(currentUser, 'identificationValidationDate')
  );

  const defaultValues = getUserFormDefaultValues(currentUser);

  const { register, errors, control, handleSubmit, setValue } = useForm({
    validationSchema: schema,
    submitFocusError: true,
    defaultValues: {
      ...defaultValues
    }
  });

  // handle loading and button disabled status
  useEffect(() => {
    if (identificationValidated) {
      setCanSubmit(true);
      setButtonLoading(false);

      // handle that expedition date input
      // is not shown on the form
      register('expeditionDate');

      // set date to yesterday to avoid the max
      // value constraint
      const date = new Date();
      date.setDate(date.getDate() - 1);

      // we set the value just to ignore it
      setValue('expeditionDate', date);
      return;
    }

    setCanSubmit(!loading && !loadingCaptcha && Boolean(captcha));
    setButtonLoading(loading || loadingCaptcha);
  }, [
    identificationValidated,
    loading,
    loadingCaptcha,
    captcha,
    register,
    setValue
  ]);

  // * FORM HANDLERS

  const updateUserIdentification = useCallback(
    async (identificationData, queryId) => {
      const { identification, identificationType } = identificationData;

      const response = await UpdateUserIdentificationAPI(authToken, userId, {
        identification,
        identificationType,
        queryId
      });

      if (response.success) {
        setLoading(false);
        const { token, appToken } = response.data.data;

        updateUser(setCurrentUser, token, appToken);

        setNextModal(previousValue => ({
          ...previousValue,
          type: DigitalInvoiceDeactivationStepId.Cellphone,
          data: {
            ...previousValue.data,
            identificationData: {
              ...identificationData,
              identificationValidated: true
            }
          }
        }));
      } else {
        setLoading(false);
        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
      }
    },
    [authToken, userId, setAlert, setCurrentUser, setNextModal]
  );

  const submitHandler = useCallback(
    async data => {
      const { identification, identificationType, expeditionDate } = data;

      const identificationData = {
        identification,
        identificationType,
        expeditionDate
      };

      if (identificationValidated) {
        setNextModal(previousValue => ({
          ...previousValue,
          type: DigitalInvoiceDeactivationStepId.Cellphone,
          data: {
            ...previousValue.data,
            identificationData: {
              ...identificationData,
              identificationValidated: true
            }
          }
        }));
        return;
      }

      const captchaValue = captcha;
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
        setCaptcha(null);
      }

      setLoading(true);

      const response = await ValidateIdentificationAPI(
        authToken,
        identificationData,
        captchaValue
      );

      if (response.success) {
        const { queryId } = response.data.data;

        await updateUserIdentification(identificationData, queryId);
      } else {
        setLoading(false);
        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
      }
    },
    [
      captcha,
      recaptchaRef,
      authToken,
      setAlert,
      setNextModal,
      updateUserIdentification,
      identificationValidated
    ]
  );

  const onCaptchaResolved = useCallback(
    token => {
      setCaptcha(token);
    },
    [setCaptcha]
  );

  const onCaptchaExpired = useCallback(() => {
    setCaptcha(null);
  }, [setCaptcha]);

  const onCaptchaLoaded = useCallback(() => {
    setTimeout(() => {
      setLoadingCaptcha(false);
    }, 1000);
  }, [setLoadingCaptcha]);

  //* COMPONENTS

  const renderActions = useCallback(() => {
    return (
      <Fragment>
        <BaseButton
          onClick={onClose}
          variant="outlined"
          color="primary"
          size="small"
        >
          Cancelar
        </BaseButton>
        <BaseButton
          color="primary"
          size="small"
          disabled={!canSubmit}
          loading={buttonLoading}
          progress={buttonLoading}
          onClick={() => submitExternalForm(formId)}
        >
          {identificationValidated ? 'Continuar' : 'Validar'}
        </BaseButton>
      </Fragment>
    );
  }, [onClose, formId, identificationValidated, canSubmit, buttonLoading]);

  const renderContent = useCallback(() => {
    return (
      <div id="ContractStep_div_container" className={classes.content}>
        <Typography className={classes.text}>
          Para solicitar la desactivación de factura digital es necesario
          validar tu cédula
        </Typography>
        <form id={formId} onSubmit={handleSubmit(submitHandler)}>
          <Grid container className={classes.inputContainer}>
            <Grid
              item
              xs={12}
              sm={6}
              className={clsx(classes.item, classes.leftItem)}
            >
              <CompositeSelectInput
                label="Identificación*"
                options={IDENTIFICATION_OPTIONS}
                TextInputProps={{
                  label: 'Identificación*',
                  name: 'identification',
                  inputRef: register,
                  fullWidth: true,
                  margin: 'none',
                  disabled: identificationValidated
                }}
                SelectInputProps={{
                  name: 'identificationType',
                  disabled: identificationValidated,
                  control
                }}
                error={Boolean(errors.identification)}
                helperText={
                  errors.identification && errors.identification.message
                }
              />
            </Grid>
            {!identificationValidated && (
              <Grid item xs={12} sm={6} className={classes.item}>
                <TextInput
                  label="Fecha de expedición*"
                  inputProps={{ maxLength: 100 }}
                  fullWidth
                  inputRef={register}
                  type="date"
                  name="expeditionDate"
                  helperText={
                    errors.expeditionDate && errors.expeditionDate.message
                  }
                  error={Boolean(errors.expeditionDate)}
                  InputLabelProps={{ shrink: true }}
                  margin="none"
                  disabled={identificationValidated}
                />
              </Grid>
            )}

            <Grow in={true}>
              <Grid className={classes.sweetAlert} item xs={12}>
                <SweetAlert
                  id="DeactivateDigitalInvoice_IdentificationStep_div_identificationValidation_info"
                  type={identificationValidated ? 'success' : 'info'}
                  icon={false}
                  classes={{
                    root: classes.sweetAlert,
                    message: classes.sweetAlertText
                  }}
                  message={
                    <Fragment>
                      {identificationValidated
                        ? '¡Tu identificación ya fue validada!'
                        : 'Validaremos tu número de identificación antes de continuar.'}
                    </Fragment>
                  }
                />
              </Grid>
            </Grow>
            {!identificationValidated && (
              <Grid
                item
                xs={12}
                sm={6}
                ref={referenceRef}
                className={classes.recaptchaItem}
              >
                <Recaptcha
                  captchaRef={recaptchaRef}
                  referenceEl={referenceRef}
                  heightScale={0.75}
                  locale={'es'}
                  sitekey={config.recaptcha.siteKey}
                  onResolved={onCaptchaResolved}
                  onExpired={onCaptchaExpired}
                  onLoaded={onCaptchaLoaded}
                  style={{
                    margin: 0,
                    transformOrigin: 'left top'
                  }}
                />
              </Grid>
            )}
          </Grid>
        </form>
      </div>
    );
  }, [
    handleSubmit,
    errors,
    classes,
    control,
    formId,
    onCaptchaExpired,
    onCaptchaLoaded,
    onCaptchaResolved,
    register,
    submitHandler,
    identificationValidated
  ]);

  // * RENDER
  return (
    <BaseDialog
      open={open}
      loading={loading}
      handleClose={onClose}
      title={title}
      actions={renderActions}
      content={renderContent}
      fullScreen={isMobileSize}
      contentSize="small"
      TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
    />
  );
};

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  text: {
    fontSize: 14,
    color: theme.palette.text.primary,
    width: '100%'
  },
  contractForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  contractInput: {
    width: 250,
    marginTop: theme.spacing(4)
  },
  textSmall: {
    fontSize: 10,
    color: theme.palette.text.blackLight,
    marginTop: theme.spacing()
  },
  inputContainer: {
    marginTop: theme.spacing(2),
    width: '100%',
    justifyContent: 'center'
  },
  item: {
    padding: [[0, theme.spacing()]],
    '&:nth-child(odd)': {
      paddingLeft: 0
    },
    '&:nth-child(even)': {
      paddingRight: 0
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: 0,
      marginBottom: theme.spacing(2)
    }
  },
  recaptchaItem: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginTop: 0
    }
  },
  sweetAlert: {
    marginTop: theme.spacing()
  }
}));

export default IdentificationStep;
