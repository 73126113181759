import * as React from 'react';

function SvgLike(props) {
  return (
    <svg width={36.914} height={42} fill={'#183f8f'} {...props}>
      <g data-name="Grupo 771">
        <g data-name="Grupo 770">
          <path
            data-name="Trazado 748"
            d="M35.972 26.004a3.689 3.689 0 00-2.749-6.152h-7.165a23.859 23.859 0 001.012-6.235v-1.23a4.928 4.928 0 00-4.922-4.922h-1.23a1.23 1.23 0 00-1.194.932l-.67 2.682c-.939 3.755-3.921 7.9-7.007 8.652a3.7 3.7 0 00-3.434-2.34H1.23A1.231 1.231 0 000 18.621v22.144a1.231 1.231 0 001.23 1.23h7.383a3.7 3.7 0 003.332-2.1l4.228 1.409a13.505 13.505 0 004.28.695h10.309a3.692 3.692 0 003.432-5.051 3.689 3.689 0 001.778-6.023 3.684 3.684 0 000-4.922zm-26.128 12.3a1.232 1.232 0 01-1.23 1.23H2.461V19.852h6.152a1.232 1.232 0 011.23 1.23zm20.918-11.069h2.461a1.23 1.23 0 010 2.461h-2.461a1.23 1.23 0 000 2.461h2.461a1.23 1.23 0 010 2.461h-2.461a1.23 1.23 0 000 2.461 1.23 1.23 0 010 2.461H20.454a11.05 11.05 0 01-3.5-.568l-4.654-1.55V22.196a9.915 9.915 0 005.525-3.59 17.954 17.954 0 003.612-6.931l.437-1.75h.27a2.464 2.464 0 012.461 2.461v1.23a19.471 19.471 0 01-1.178 6.235h-2.509a1.23 1.23 0 000 2.461h12.3a1.23 1.23 0 110 2.461h-2.456a1.23 1.23 0 000 2.461z"
          />
        </g>
      </g>
      <g data-name="Grupo 773">
        <g data-name="Grupo 772">
          <circle
            data-name="Elipse 207"
            cx={1}
            cy={1}
            r={1}
            transform="translate(5 35)"
          />
        </g>
      </g>
      <g data-name="Grupo 775">
        <g data-name="Grupo 774">
          <path
            data-name="Trazado 749"
            d="M23.378 0a1.23 1.23 0 00-1.23 1.23v2.543a1.23 1.23 0 102.461 0V1.23A1.23 1.23 0 0023.378 0z"
          />
        </g>
      </g>
      <g data-name="Grupo 777">
        <g data-name="Grupo 776">
          <path
            data-name="Trazado 750"
            d="M18.159 5.426l-1.74-1.74a1.23 1.23 0 00-1.74 1.74l1.74 1.74a1.23 1.23 0 001.74-1.74z"
          />
        </g>
      </g>
      <g data-name="Grupo 779">
        <g data-name="Grupo 778">
          <path
            data-name="Trazado 751"
            d="M32.079 3.684a1.23 1.23 0 00-1.74 0l-1.74 1.74a1.23 1.23 0 001.74 1.74l1.74-1.74a1.23 1.23 0 000-1.74z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgLike;
