import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const FaqActionButton = props => {
  const classes = useStyles();

  const { id, loading, disabled, className, icon, text, ...rest } = props;

  return (
    <div>
      <Button
        id={id}
        variant="contained"
        disabled={loading || disabled}
        className={clsx(classes.buttonBase, classes.buttonText, className)}
        startIcon={icon}
        {...rest}
      >
        {text}
      </Button>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  buttonBase: {
    height: 74,
    width: '100%',
    fontFamily: theme.typography.fontFamily,
    fontSize: 14,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    boxShadow: 'none'
  },
  buttonText: {
    textTransform: 'none'
  }
}));

export default FaqActionButton;
