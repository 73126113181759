import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import BrillaVisitGDC from './BrillaVisit.GDC';
import BrillaVisitGDG from './BrillaVisit.GDG';

const BrillaVisit = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <BrillaVisitGDC {...props} />;
    case Portal.Gasguajira:
      return <BrillaVisitGDG {...props} />;
    case Portal.Efigas:
      return <BrillaVisitGDC {...props} />;
    default:
      return <></>;
  }
};

export default BrillaVisit;
