import React from 'react';

const SvgComponent = props => (
  <svg
    width={props.size || 25}
    height={props.size || 25}
    viewBox="0 0 19.571 21"
    xmlSpace="preserve"
    {...props}
  >
    <g id="ic-business-outline" transform="translate(-47.5 -31.5)">
      <path
        id="Trazado_663"
        data-name="Trazado 663"
        d="M53.714,49.143V52M49.429,32H58a1.429,1.429,0,0,1,1.429,1.429V51.821A.179.179,0,0,1,59.25,52H48V33.429A1.429,1.429,0,0,1,49.429,32Zm10.714,7.143h5a1.429,1.429,0,0,1,1.429,1.429V52H59.429V39.857A.714.714,0,0,1,60.143,39.143Z"
        fill="none"
        stroke={props.fill || '#747b87'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Trazado_664"
        data-name="Trazado 664"
        d="M80.812,95.713a.714.714,0,1,1,.616-.616A.714.714,0,0,1,80.812,95.713Zm0-3.571a.714.714,0,1,1,.616-.616A.714.714,0,0,1,80.812,92.142Zm0-3.571a.714.714,0,1,1,.616-.616A.714.714,0,0,1,80.812,88.57Zm0-3.571a.714.714,0,1,1,.616-.616A.714.714,0,0,1,80.812,85Zm0-3.571a.714.714,0,1,1,.616-.616A.714.714,0,0,1,80.812,81.427Zm3.571,10.714A.714.714,0,1,1,85,91.526.714.714,0,0,1,84.383,92.142Zm0-3.571A.714.714,0,1,1,85,87.954.714.714,0,0,1,84.383,88.57Zm0-3.571A.714.714,0,1,1,85,84.383.714.714,0,0,1,84.383,85Zm0-3.571A.714.714,0,1,1,85,80.812.714.714,0,0,1,84.383,81.427Zm3.571,14.286a.714.714,0,1,1,.616-.616A.714.714,0,0,1,87.954,95.713Zm0-3.571a.714.714,0,1,1,.616-.616A.714.714,0,0,1,87.954,92.142Zm0-3.571a.714.714,0,1,1,.616-.616A.714.714,0,0,1,87.954,88.57Z"
        transform="translate(-30.576 -45.862)"
        fill={props.fill || '#747b87'}
      />
      <ellipse
        id="Elipse_209"
        data-name="Elipse 209"
        cx={0.712}
        cy={0.716}
        rx={0.712}
        ry={0.716}
        transform="translate(56.276 38.426) rotate(-45)"
        fill={props.fill || '#747b87'}
      />
      <path
        id="Trazado_665"
        data-name="Trazado 665"
        d="M240.812,81.427a.714.714,0,1,1,.616-.616A.714.714,0,0,1,240.812,81.427Zm6.336,12.863a.714.714,0,1,0,.714.714A.714.714,0,0,0,247.147,94.29Zm0-3.571a.714.714,0,1,0,.714.714A.714.714,0,0,0,247.147,90.719Zm0-3.571a.714.714,0,1,0,.714.714A.714.714,0,0,0,247.147,87.147ZM244.29,94.29A.714.714,0,1,0,245,95,.714.714,0,0,0,244.29,94.29Zm0-3.571a.714.714,0,1,0,.714.714A.714.714,0,0,0,244.29,90.719Zm0-3.571a.714.714,0,1,0,.714.714A.714.714,0,0,0,244.29,87.147Z"
        transform="translate(-183.433 -45.862)"
        fill={props.fill || '#747b87'}
      />
    </g>
  </svg>
);

export default SvgComponent;
