import * as React from 'react';

function SvgNoFuzzyResult(props) {
  return (
    <svg width={308.118} height={204.813} {...props}>
      <g data-name="Grupo 785">
        <g data-name="Grupo 710" transform="translate(0 2.5)">
          <path
            data-name="Trazado 186"
            d="M26.867 70.397H12.332A12.392 12.392 0 010 58.029v-2.558a12.384 12.384 0 0112.332-12.332h14.5a12.361 12.361 0 0112.331 12.332v2.558a12.338 12.338 0 01-12.296 12.368z"
            fill="#696a6a"
            opacity={0.1}
          />
          <path
            data-name="Trazado 187"
            d="M295.787 183.693h-14.5a12.361 12.361 0 01-12.332-12.332v-2.559a12.361 12.361 0 0112.332-12.331h14.5a12.361 12.361 0 0112.332 12.332v2.559a12.384 12.384 0 01-12.332 12.331z"
            fill="#696a6a"
            opacity={0.1}
          />
          <path
            data-name="Trazado 188"
            d="M269.811 98.401h-30.638A89.581 89.581 0 0094.318 43.174H63.4a13.635 13.635 0 00-13.612 13.612 13.7 13.7 0 0011.337 13.433h22.034a14.894 14.894 0 0114.854 14.854v1.67a14.894 14.894 0 01-14.854 14.854H39.092a13.635 13.635 0 00-13.612 13.614 13.635 13.635 0 0013.611 13.612H62.58a89.574 89.574 0 00143.433 54.37 13.267 13.267 0 003.59.5h34.688a13.635 13.635 0 0013.612-13.612 13.635 13.635 0 00-13.612-13.607H223.82a14.576 14.576 0 01-14.535-14.535v-1.919a14.576 14.576 0 0114.535-14.537h32.873a12.518 12.518 0 012.345.213h10.7a13.635 13.635 0 0013.612-13.612 13.568 13.568 0 00-13.536-13.682z"
            fill="#696a6a"
            opacity={0.1}
          />
          <circle
            data-name="Elipse 149"
            cx={5.485}
            cy={5.485}
            transform="translate(72.25)"
            fill="none"
            stroke="#183f8f"
            strokeMiterlimit={10}
            strokeWidth={5}
            r={5.485}
          />
          <circle
            data-name="Elipse 151"
            cx={5.485}
            cy={5.485}
            transform="translate(241.144 79.98)"
            fill="none"
            stroke="#183f8f"
            strokeMiterlimit={10}
            strokeWidth={5}
            r={5.485}
          />
          <path
            data-name="Trazado 189"
            d="M269.314 43.289h-8.475v-8.471a1.828 1.828 0 00-3.656 0v8.471h-8.474a1.828 1.828 0 000 3.656h8.474v8.474a1.828 1.828 0 103.656 0v-8.47h8.475a1.845 1.845 0 001.828-1.828 1.818 1.818 0 00-1.828-1.828z"
            fill="#696a6a"
          />
          <path
            data-name="Trazado 190"
            d="M81.432 90.488h-8.477v-8.474a1.828 1.828 0 00-3.656 0v8.474h-8.518a1.828 1.828 0 100 3.656h8.474v8.474a1.828 1.828 0 103.656 0v-8.47h8.475a1.844 1.844 0 001.828-1.828 1.782 1.782 0 00-1.784-1.828z"
            fill="#696a6a"
          />
          <path
            data-name="Trazado 191"
            d="M285.352 113.012l-4.653-4.653 4.653-4.653a1.41 1.41 0 00-1.995-1.994l-4.653 4.653-4.653-4.653a1.41 1.41 0 10-1.994 1.994l4.654 4.653-4.653 4.653a1.41 1.41 0 101.994 1.994l4.653-4.654 4.653 4.653a1.428 1.428 0 001.995 0 1.358 1.358 0 000-1.994z"
            fill="#696a6a"
          />
          <g data-name="Grupo 709">
            <g data-name="Grupo 230">
              <g data-name="Grupo 229">
                <path
                  data-name="Trazado 195"
                  d="M110.993 162.554h106.59c2.666 0 2.666-4.132 0-4.132h-106.59c-2.667 0-2.667 4.132 0 4.132z"
                  fill="#183f8f"
                />
              </g>
            </g>
            <g data-name="Grupo 232">
              <g data-name="Grupo 231">
                <path
                  data-name="Trazado 196"
                  d="M231.355 162.554h7.573c2.666 0 2.666-4.132 0-4.132h-7.574c-2.666 0-2.666 4.132 0 4.132z"
                  fill="#183f8f"
                />
              </g>
            </g>
            <g data-name="Grupo 234">
              <g data-name="Grupo 233">
                <path
                  data-name="Trazado 197"
                  d="M74.362 162.554h23.779c2.666 0 2.666-4.132 0-4.132H74.362c-2.667 0-2.667 4.132 0 4.132z"
                  fill="#183f8f"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Grupo 740">
          <g data-name="Grupo 739">
            <path
              data-name="Trazado 566"
              d="M161.036 97.706a15.5 15.5 0 004.8-11.223v-8.495a10.458 10.458 0 002.143-6.352v-8.882a2.172 2.172 0 00-2.172-2.172h-17.9a13.187 13.187 0 00-13.172 13.172v12.728a15.5 15.5 0 004.8 11.223 22.663 22.663 0 00-21.824 22.621v17.73a2.172 2.172 0 002.172 2.172h60.8a2.172 2.172 0 002.172-2.172v-17.73a22.664 22.664 0 00-21.819-22.62zm-13.124-32.779h15.727v6.711a6.177 6.177 0 01-6.17 6.17h-18.385v-4.052a8.838 8.838 0 018.828-8.829zm-8.829 21.557v-4.333h18.386a10.453 10.453 0 004.027-.8v5.136a11.206 11.206 0 11-22.412 0zm20.015 15.55l-8.492 14.386-8.49-14.39zm19.418 33.852h-4.887v-15.189a2.172 2.172 0 10-4.344 0v15.188h-37.354v-15.188a2.172 2.172 0 10-4.344 0v15.188h-5.524v-15.558a18.32 18.32 0 0115.172-18.022l11.5 19.5a2.172 2.172 0 003.741 0l11.441-19.392a18.325 18.325 0 0114.6 17.918v15.558z"
              fill="#696a6a"
            />
          </g>
        </g>
        <g data-name="Grupo 742">
          <g data-name="Grupo 741">
            <path
              data-name="Trazado 567"
              d="M212.749 29.036h-12.281a2.172 2.172 0 000 4.344h12.281a6.523 6.523 0 016.515 6.515v29.1a6.523 6.523 0 01-6.515 6.515h-7.917a2.172 2.172 0 00-1.535.636l-10.259 10.249v-8.718a2.172 2.172 0 00-2.172-2.172h-18.511a2.172 2.172 0 000 4.343h16.342v11.788a2.172 2.172 0 003.7 1.536l13.33-13.324h7.018a10.871 10.871 0 0010.859-10.859v-29.1a10.871 10.871 0 00-10.855-10.853z"
              fill="#183f8f"
            />
          </g>
        </g>
        <g data-name="Grupo 744">
          <g data-name="Grupo 743">
            <path
              data-name="Trazado 568"
              d="M194.087 29.672a2.172 2.172 0 00-3.707 1.535 2.172 2.172 0 103.707-1.535z"
              fill="#183f8f"
            />
          </g>
        </g>
        <g data-name="Grupo 746">
          <g data-name="Grupo 745">
            <path
              data-name="Trazado 569"
              d="M184.61 29.036h-12.26a10.871 10.871 0 00-10.859 10.859v12.048a2.172 2.172 0 004.344 0V39.895a6.523 6.523 0 016.515-6.515h12.26a2.172 2.172 0 100-4.344z"
              fill="#183f8f"
            />
          </g>
        </g>
        <g data-name="Grupo 748">
          <g data-name="Grupo 747">
            <path
              data-name="Trazado 570"
              d="M164.181 121.788h-3.04a2.172 2.172 0 000 4.344h3.04a2.172 2.172 0 000-4.344z"
              fill="#696a6a"
            />
          </g>
        </g>
        <g data-name="Grupo 750">
          <g data-name="Grupo 749">
            <path
              data-name="Trazado 571"
              d="M146.684 85.842a2.171 2.171 0 10.636 1.535 2.184 2.184 0 00-.636-1.535z"
              fill="#696a6a"
            />
          </g>
        </g>
        <g data-name="Grupo 752">
          <g data-name="Grupo 751">
            <path
              data-name="Trazado 572"
              d="M156.965 85.842a2.171 2.171 0 10.636 1.535 2.188 2.188 0 00-.636-1.535z"
              fill="#696a6a"
            />
          </g>
        </g>
        <g data-name="Grupo 754">
          <g data-name="Grupo 753">
            <path
              data-name="Trazado 573"
              d="M200.571 48.471a8.057 8.057 0 00-7.454-7.453 7.971 7.971 0 00-6.049 2.142 8.067 8.067 0 00-2.554 5.877 2.172 2.172 0 104.343 0 3.7 3.7 0 013.961-3.686 3.7 3.7 0 01.546 7.291 3.789 3.789 0 00-2.984 3.714v4.36a2.172 2.172 0 104.343 0v-3.938a8 8 0 005.848-8.307z"
              fill="#183f8f"
            />
          </g>
        </g>
        <g data-name="Grupo 756">
          <g data-name="Grupo 755">
            <path
              data-name="Trazado 574"
              d="M194.087 66.355a2.173 2.173 0 10.636 1.538 2.186 2.186 0 00-.636-1.538z"
              fill="#183f8f"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgNoFuzzyResult;
