export const formatAddress = (obj, lowerCase) => {
  if (!obj) {
    return 'Sin dirección';
  }
  let text = '';

  if (obj.city) {
    if (lowerCase) {
      text = obj.city.toLowerCase();
    } else {
      text = obj.city;
    }
  }

  if (obj.city && obj.department) {
    text = `${text}, `;
  }

  if (obj.department) {
    if (lowerCase) {
      text = text + obj.department.toLowerCase();
    } else {
      text = text + obj.department;
    }
  }

  if (obj.address) {
    if (lowerCase) {
      text = `${text} ${obj.address.toLowerCase()}`;
    } else {
      text = `${text} ${obj.address}`;
    }
  }

  return text;
};
