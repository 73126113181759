import * as React from 'react';

const SubrogationRequestIcon = props => (
  <svg
    id="img-subrogacion-gas"
    width={props.width || 90}
    height={props.height || 90}
    viewBox="0 0 90 90"
    {...props}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x2="1"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#ffc89a" />
        <stop offset="1" stopColor="#fa9c4b" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="-1.979"
        y1="-1.603"
        x2="1.388"
        y2="1.753"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#ffc797" />
        <stop offset="1" stopColor="#fa9d4d" />
      </linearGradient>
    </defs>
    <path
      id="Trazado_373"
      data-name="Trazado 373"
      d="M3.624,0H86.376A3.624,3.624,0,0,1,90,3.624V86.376A3.624,3.624,0,0,1,86.376,90H3.624A3.624,3.624,0,0,1,0,86.376V3.624A3.624,3.624,0,0,1,3.624,0Z"
      fill="url(#linear-gradient)"
    />
    <g
      id="Elipse_210"
      data-name="Elipse 210"
      transform="translate(53 43)"
      stroke="#fff"
      strokeWidth="1"
      fill="url(#linear-gradient-2)"
    >
      <circle cx="13" cy="13" r="13" stroke="none" />
      <circle cx="13" cy="13" r="12.5" fill="none" />
    </g>
    <path
      id="Sustracción_11"
      data-name="Sustracción 11"
      d="M-1045,537a23.849,23.849,0,0,1-9.342-1.886,23.92,23.92,0,0,1-7.629-5.143,23.923,23.923,0,0,1-5.143-7.629A23.85,23.85,0,0,1-1069,513a23.85,23.85,0,0,1,1.886-9.342,23.922,23.922,0,0,1,5.143-7.629,23.92,23.92,0,0,1,7.629-5.143A23.85,23.85,0,0,1-1045,489a23.953,23.953,0,0,1,16.047,6.153,23.948,23.948,0,0,1,7.8,15.138,14.038,14.038,0,0,0-1.538-.23,22.328,22.328,0,0,0-1.579-5.819,22.443,22.443,0,0,0-4.822-7.152,22.443,22.443,0,0,0-7.152-4.822A22.368,22.368,0,0,0-1045,490.5a22.361,22.361,0,0,0-8.758,1.768,22.444,22.444,0,0,0-7.152,4.822,22.442,22.442,0,0,0-4.822,7.152A22.361,22.361,0,0,0-1067.5,513a22.361,22.361,0,0,0,1.768,8.758,22.442,22.442,0,0,0,4.822,7.152,22.445,22.445,0,0,0,7.152,4.822A22.362,22.362,0,0,0-1045,535.5a22.368,22.368,0,0,0,8.758-1.768c.517-.219,1.032-.46,1.531-.716a14.143,14.143,0,0,0,1.067,1.133A24.038,24.038,0,0,1-1045,537Z"
      transform="translate(1090 -468)"
      fill="#fff"
    />
    <path
      id="Trazado_679"
      data-name="Trazado 679"
      d="M182.408,56c-.327,4.406-3.667,8-7.333,8s-7.012-3.593-7.333-8a7.361,7.361,0,1,1,14.667,0Z"
      transform="translate(-129.766 -15.683)"
      fill="#fff"
    />
    <path
      id="Sustracción_12"
      data-name="Sustracción 12"
      d="M-1059.452,515.292h0l-9.548-4.965.133-.933a11.279,11.279,0,0,1,5.052-7.5,16.224,16.224,0,0,1,8.9-2.564,16.717,16.717,0,0,1,6.866,1.45,14.151,14.151,0,0,0-.174,2.215,13.968,13.968,0,0,0,3.623,9.4,21.587,21.587,0,0,1-4.506,2.529l-10.342.365Z"
      transform="translate(1100.226 -447)"
      fill="#fff"
    />
    <g id="Grupo_487" data-name="Grupo 487" transform="translate(44.52 47.578)">
      <path
        id="Trazado_483"
        data-name="Trazado 483"
        d="M-268.507,38.184a6.275,6.275,0,0,1-6.435-6.129,5.54,5.54,0,0,1,.714-3,5.839,5.839,0,0,0,.509-4.785c-.216-.731.164-1.1.906-.919a3.51,3.51,0,0,1,1.725.906c.384.391.5.221.683-.163a6.711,6.711,0,0,1,2.843-3.084,2.442,2.442,0,0,1,.765-.292c.431-.069.772.155.691.6-.424,2.3.755,4.092,1.834,5.919a11.744,11.744,0,0,1,1.67,3.477A6.29,6.29,0,0,1-268.507,38.184Zm-3.676-13.161a8.167,8.167,0,0,1-1.08,4.908,4.99,4.99,0,0,0-.331,2.867,3.668,3.668,0,0,0,.91,2.051c.047-.06.093-.092.092-.122a4.912,4.912,0,0,1,2.1-4.2,2.424,2.424,0,0,0,.873-1.145c.239-.79.667-.925,1.307-.383,2.029,1.716,3.315,3.777,2.894,6.669a5.221,5.221,0,0,0,1.642-3.226,6.157,6.157,0,0,0-1.287-4.092,15.711,15.711,0,0,1-2.212-4.557,4.889,4.889,0,0,0-.278-1.217,4.936,4.936,0,0,0-2.13,3.671c-.005.372.01.762-.441.876-.486.124-.662-.244-.835-.6A3.229,3.229,0,0,0-272.183,25.024Zm5.613,9.408a6.044,6.044,0,0,0-1.625-3.643c-.332-.374-.51-.359-.815.026a11.5,11.5,0,0,1-1.087,1.069,4.2,4.2,0,0,0-1.023,1.365,2.7,2.7,0,0,0,.48,2.96,2.421,2.421,0,0,0,2.59.527A2.332,2.332,0,0,0-266.57,34.432Z"
        transform="translate(290.285 -20.707)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default SubrogationRequestIcon;
