import moment from 'moment';
import { TYPE_OF_PAYMENTS } from '../../Views/Payment/payment_enums';
import { renderType } from '../../Views/Requests/Utils/enums';
import { formatAddress } from './Address';

export const renderCouponId = couponId => {
  if (couponId > 0 || String(couponId).includes('P')) {
    return couponId;
  }
  const parsedCoupon = portalCouponFormat(couponId);
  return parsedCoupon;
};

export const portalCouponFormat = couponId => {
  return `P${String(couponId * -1).padStart(8, '0')}`;
};

export const getCouponCardHeader = (
  type,
  details,
  withAddress = false,
  dateFormat = 'MMMM YYYY'
) => {
  switch (type) {
    case TYPE_OF_PAYMENTS.coupon:
      return 'Cupón de pago';
    case TYPE_OF_PAYMENTS.contract: {
      const addressData = {
        city: details.contractCity,
        address: details.contractAddress
      };

      const address = withAddress ? formatAddress(addressData, true) : null;
      const expiryDate = moment([details.year, details.month - 1]).format(
        dateFormat
      );

      const content = [expiryDate, address].filter(Boolean).join(' · ');
      return content;
    }
    case TYPE_OF_PAYMENTS.financing:
      return renderType(details.requestType);
    default:
      return '';
  }
};
