import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from '../Views/Home/Home';

import { ROUTE_NAMES } from './Routes';

import Payment from '../Views/Payment/Payment/Payment';
import Transaction from '../Views/Payment/Transaction/Transaction/Transaction';
import Information from '../Views/Information/Information/Information';
import Login from '../Views/Login/Login';
import ForgotPassword from '../Views/ForgotPassword/ForgotPassword';
import RecoverPassword from '../Views/RecoverPassword/RecoverPassword';
import Brilla from '../Views/Brilla/Brilla';
import MyContracts from '../Views/Contracts/MyContracts/MyContracts';
import ContractAssociation from '../Views/Contracts/ContractAssociation/ContractAssociation';
import InvoiceHistory from '../Views/InvoiceHistory/InvoiceHistory/InvoiceHistory';
import VerifyAccount from '../Views/VerifyAccount/VerifyAccount';
import Places from '../Views/Places/Places/Places';
import Signup from '../Views/Signup/Signup';
import ConfirmEmail from '../Views/ConfirmEmail/ConfirmEmail';
import CorrectEmail from '../Views/CorrectEmail/CorrectEmail';
import ForgotPasswordEmail from '../Views/ForgotPassword/ForgotPasswordEmail';
import MyProfile from '../Views/Profile/MyProfile';
import PQR from '../Views/PQR/PQR/PQR';
import Donations from '../Views/Donations/Donation';
import PeriodicRevision from '../Views/PeriodicRevision/PeriodicRevision/PeriodicRevision';
import Debts from '../Views/Debts/MyDebts/MyDebts';
import CurrentDebtSummary from '../Views/Debts/CurrentDebtSummary/CurrentDebtSummary';
import DeferredDebtSummary from '../Views/Debts/DeferredDebtSummary/DeferredDebtSummary';
import DeferredDebtRefinancing from '../Views/Debts/Refinancing/DeferredDebtRefinancing/DeferredDebtRefinancing';
import CurrentDebtRefinancing from '../Views/Debts/Refinancing/CurrentDebtRefinancing/CurrentDebtRefinancing';
import DeferredPayment from '../Views/Debts/DeferredPayment/DeferredPayment/DeferredPayment';
import ChangeOfOwnership from '../Views/ChangeOfOwnership/ChangeOfOwnership/ChangeOfOwnership';
import Requests from '../Views/Requests/Requests/Requests';
import Request from '../Views/Requests/Request/Request';
import TransactionSignup from '../Views/TransactionSignup/TransactionSignup';
import TransactionsList from '../Views/TransactionsList/TransactionsList';
import Faq from '../Views/FAQ/Faq/Faq';
import FaqCategory from '../Views/FAQ/FaqCategory';
import FaqArticle from '../Views/FAQ/FaqArticle';
import GanaLoco from '../Views/GanaLocoForm/GanaLoco/GanaLoco';
import VerifyVisit from '../Views/VerifyVisit/VerifyVisit';
import InsurancesHome from '../Views/Insurances/InsurancesHome';
import PractiSeguroDetails from '../Views/Insurances/Products/Practiseguro/PractiseguroDetails';
import AcquireInsurance from '../Views/Insurances/Processes/Acquisition/AcquireInsurance';
import ManagePractiseguro from '../Views/Insurances/Products/Practiseguro/ManagePractiseguro';
import InProcessPage from '../Views/Insurances/Processes/InProcessPage';
import SubrogationRequest from '../Views/SubrogationRequest/SubrogationRequest';
import DigitalInvoiceActivation from '../Views/DigitalInvoice/DigitalInvoiceActivation';

const RoutesGDC = props => {
  const { withBoundary, NotFoundView } = props;
  return (
    <Switch>
      <Route exact path={ROUTE_NAMES.home} component={withBoundary(Home)} />
      <Route
        exact
        path={ROUTE_NAMES.payments}
        component={withBoundary(Payment)}
      />
      <Route
        exact
        path={`${ROUTE_NAMES.payContract}/:contractId`}
        component={withBoundary(Payment)}
      />
      <Route
        exact
        path={`${ROUTE_NAMES.payCoupon}/:couponId`}
        component={withBoundary(Payment)}
      />
      <Route
        exact
        path={`${ROUTE_NAMES.transaction}/:transactionId`}
        component={withBoundary(Transaction)}
      />
      <Route
        exact
        path={`${ROUTE_NAMES.information}/:panel?`}
        component={withBoundary(Information)}
      />
      <Route exact path={ROUTE_NAMES.login} component={withBoundary(Login)} />
      <Route
        exact
        path={ROUTE_NAMES.register}
        component={withBoundary(Signup)}
      />
      <Route
        exact
        path={ROUTE_NAMES.forgot}
        component={withBoundary(ForgotPassword)}
      />
      <Route
        exact
        path={`${ROUTE_NAMES.recover}/:token`}
        component={withBoundary(RecoverPassword)}
      />
      <Route
        exact
        path={`${ROUTE_NAMES.verify}/:token`}
        component={withBoundary(VerifyAccount)}
      />
      <Route
        exact
        path={ROUTE_NAMES.profile}
        component={withBoundary(MyProfile)}
      />
      <Route exact path={ROUTE_NAMES.brilla} component={withBoundary(Brilla)} />
      <Route
        exact
        path={ROUTE_NAMES.associate}
        component={withBoundary(ContractAssociation)}
      />
      <Route
        exact
        path={ROUTE_NAMES.invoices}
        component={withBoundary(InvoiceHistory)}
      />
      <Route
        exact
        path={ROUTE_NAMES.confirmEmail}
        component={withBoundary(ConfirmEmail)}
      />
      <Route
        exact
        path={ROUTE_NAMES.forgotPasswordEmail}
        component={withBoundary(ForgotPasswordEmail)}
      />
      <Route
        exact
        path={ROUTE_NAMES.changeEmail}
        component={withBoundary(CorrectEmail)}
      />
      <Route
        exact
        path={`${ROUTE_NAMES.transactionRegister}/:transactionId?`}
        component={withBoundary(TransactionSignup)}
      />
      <Route
        exact
        path={`${ROUTE_NAMES.map}/:option?`}
        component={withBoundary(Places)}
      />
      <Route
        exact
        path={ROUTE_NAMES.donations}
        component={withBoundary(Donations)}
      />
      <Route exact path={ROUTE_NAMES.pqr} component={withBoundary(PQR)} />
      <Route
        exact
        path={`${ROUTE_NAMES.revision}/:contractId?`}
        component={withBoundary(PeriodicRevision)}
      />
      <Route
        exact
        path={ROUTE_NAMES.ownership}
        component={withBoundary(ChangeOfOwnership)}
      />
      <Route
        exact
        path={ROUTE_NAMES.requests}
        component={withBoundary(Requests)}
      />
      <Route
        exact
        path={`${ROUTE_NAMES.requests}/:request`}
        component={withBoundary(Request)}
      />
      <Route
        exact
        path={ROUTE_NAMES.debtStatus}
        component={withBoundary(Debts)}
      />
      <Route
        exact
        path={ROUTE_NAMES.currentDebtSummary}
        component={withBoundary(CurrentDebtSummary)}
      />
      <Route
        exact
        path={ROUTE_NAMES.deferredDebtSummary}
        component={withBoundary(DeferredDebtSummary)}
      />
      <Route
        exact
        path={ROUTE_NAMES.refinancingDeferred}
        component={withBoundary(DeferredDebtRefinancing)}
      />
      <Route
        exact
        path={ROUTE_NAMES.refinancingCurrent}
        component={withBoundary(CurrentDebtRefinancing)}
      />
      <Route
        exact
        path={ROUTE_NAMES.deferredPayment}
        component={withBoundary(DeferredPayment)}
      />
      <Route
        exact
        path={`${ROUTE_NAMES.contracts}/:contractId?`}
        component={withBoundary(MyContracts)}
      />
      <Route exact path={ROUTE_NAMES.faq} component={withBoundary(Faq)} />
      <Route
        exact
        path={`${ROUTE_NAMES.faq}/categories/:categoryInfo`}
        component={withBoundary(FaqCategory)}
      />
      <Route
        exact
        path={`${ROUTE_NAMES.faq}/articles/:articleInfo`}
        component={withBoundary(FaqArticle)}
      />
      <Route
        exact
        path={`${ROUTE_NAMES.campaigns}/gana-loco`}
        component={withBoundary(GanaLoco)}
      />
      <Route
        exact
        path={ROUTE_NAMES.transactions}
        component={withBoundary(TransactionsList)}
      />
      <Route
        exact
        path={ROUTE_NAMES.verifyVisit}
        component={withBoundary(VerifyVisit)}
      />
      <Route
        exact
        path={ROUTE_NAMES.insurancesHome}
        component={withBoundary(InsurancesHome)}
      />
      <Route
        exact
        path={ROUTE_NAMES.practiseguroDetails}
        component={withBoundary(PractiSeguroDetails)}
      />
      <Route
        exact
        path={ROUTE_NAMES.acquireInsurance}
        component={withBoundary(AcquireInsurance)}
      />
      <Route
        exact
        path={ROUTE_NAMES.managePractiseguro}
        component={withBoundary(ManagePractiseguro)}
      />
      <Route
        exact
        path={ROUTE_NAMES.inProcessInsurancePage}
        component={withBoundary(InProcessPage)}
      />
      <Route
        exact
        path={ROUTE_NAMES.subrogationRequest}
        component={withBoundary(SubrogationRequest)}
      />
      <Route
        exact
        path={`${ROUTE_NAMES.digitalInvoice}/activar/:contractId?`}
        component={withBoundary(DigitalInvoiceActivation)}
      />
      <Route path="*" component={withBoundary(NotFoundView)} />
    </Switch>
  );
};

export default RoutesGDC;
