import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import capitalize from '../../../Utils/pipes/capitalize';
import { Typography, CircularProgress } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { theme } from '../../../Configs/Theme';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 500,
    overflowY: 'auto'
  },
  subheader: {
    overflowY: 'auto'
  },
  card: {
    maxWidth: 280,
    width: 280,
    maxHeight: '45vh',
    overflowY: 'auto'
  },
  header: {
    minWidth: 250
  },
  media: {
    minHeight: 120,
    minWidth: 250
  },
  hour: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  noSchedule: {
    color: theme.typography.color.lighter,
    textAlign: 'center',
    fontSize: 12
  },
  circleProgress: {
    textAlign: 'center'
  }
}));

const PlaceholderImage =
  'https://www.testingxperts.com/wp-content/uploads/2019/02/placeholder-img.jpg';

const InfoWindowData = props => {
  const classes = useStyles();
  const [place, setPlace] = useState(null);
  const [cardLoading, setCardLoading] = useState(false);
  const openingHours = props.hours || (place && place.opening_hours);
  const placePhoto =
    props.photo ||
    (place && place.photos && place.photos[0] && place.photos[0].getUrl());
  useEffect(() => {
    if (props.mapRef && props.placeId) {
      const service = new window.google.maps.places.PlacesService(props.mapRef);
      const request = {
        placeId: props.placeId,
        fields: ['photo', 'opening_hours']
      };
      setCardLoading(true);
      service.getDetails(request, (p, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setCardLoading(false);
          setPlace(p);
        } else {
          console.error('Error fetching place data: ', status);
        }
      });
    }
  }, [props.mapRef, props.placeId]);

  const mapOpeningHours = (schedule, i) => {
    const [day, range] = schedule.split(': ');
    return (
      <div key={i} className={classes.hour}>
        <Typography color="textSecondary" component="span">
          {capitalize(day)}
        </Typography>
        <Typography color="textSecondary" component="span">
          {range}
        </Typography>
      </div>
    );
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        className={classes.header}
        subheaderTypographyProps={{ className: classes.subheader }}
        titleTypographyProps={{ className: classes.title }}
        title={props.name}
        subheader={props.address}
      />
      {cardLoading && (
        <CardMedia className={classes.media}>
          <Skeleton variant="rect" width={280} height={120} />
        </CardMedia>
      )}
      {!cardLoading && (
        <CardMedia
          className={classes.media}
          image={placePhoto || PlaceholderImage}
          title={props.name}
        />
      )}
      {openingHours && (
        <CardContent>
          <Typography
            className={classes.title}
            gutterBottom
            variant="subtitle2"
          >
            Horario
          </Typography>
          {cardLoading && <CircularProgress color="secondary" />}
          {!cardLoading &&
            openingHours &&
            openingHours.weekday_text.map((schedule, i) =>
              mapOpeningHours(schedule, i)
            )}
        </CardContent>
      )}
      {!openingHours && (
        <CardContent>
          <Typography
            className={classes.title}
            gutterBottom
            variant="subtitle2"
          >
            Horario
          </Typography>
          {cardLoading && (
            <div className={classes.circleProgress}>
              <CircularProgress color="secondary" />
            </div>
          )}
          {!cardLoading && (
            <Typography className={classes.noSchedule}>
              No se encontraron horarios para este sitio.
            </Typography>
          )}
        </CardContent>
      )}
    </Card>
  );
};

export default InfoWindowData;
