import React from 'react';

const DeferredIcon = ({
  width = 35,
  height = 35,
  clockFill = '#fff',
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 56 56"
      {...rest}
    >
      <rect width="56" height="56" fill="none" />
      <g transform="translate(7.4 15.517)">
        <path
          d="M3.8,0H45.563a3.8,3.8,0,0,1,3.8,3.8V24.873a3.8,3.8,0,0,1-3.8,3.8H3.8a3.8,3.8,0,0,1-3.8-3.8V3.8A3.8,3.8,0,0,1,3.8,0ZM45.563,27.587a2.716,2.716,0,0,0,2.713-2.713V3.8a2.716,2.716,0,0,0-2.713-2.713H3.8A2.716,2.716,0,0,0,1.085,3.8V24.873A2.716,2.716,0,0,0,3.8,27.587Z"
          transform="translate(44.962 26.819) rotate(180)"
          fill="#333"
        />
        <path
          d="M9.095-.5A9.595,9.595,0,1,1-.5,9.095,9.605,9.605,0,0,1,9.095-.5Zm0,18.1A8.509,8.509,0,1,0,.585,9.095,8.519,8.519,0,0,0,9.095,17.6Z"
          transform="translate(11.186 3.474)"
          fill="#333"
        />
        <path
          d="M-1441.511,85.888v-.372a3.959,3.959,0,0,1-2.319-1.116.611.611,0,0,1-.184-.436.614.614,0,0,1,.179-.436.613.613,0,0,1,.437-.183.613.613,0,0,1,.433.178,2.735,2.735,0,0,0,1.453.748V81.652c-1.687-.205-2.889-1.233-2.889-2.5s1.2-2.3,2.889-2.506v-.384a.617.617,0,0,1,.616-.616.616.616,0,0,1,.616.616v.372a3.959,3.959,0,0,1,2.319,1.116.608.608,0,0,1,.184.435.611.611,0,0,1-.179.436.61.61,0,0,1-.437.183.61.61,0,0,1-.432-.178,2.738,2.738,0,0,0-1.454-.748v2.621c1.688.205,2.891,1.234,2.891,2.506s-1.2,2.3-2.891,2.5v.384a.616.616,0,0,1-.616.616A.617.617,0,0,1-1441.511,85.888Zm1.232-1.631c.949-.164,1.66-.688,1.66-1.257s-.71-1.1-1.66-1.259Zm-2.889-5.109c0,.57.708,1.093,1.657,1.257V77.889C-1442.46,78.053-1443.168,78.577-1443.168,79.148Z"
          transform="translate(1461.175 -68.366)"
          fill="#333"
        />
      </g>
      <g transform="translate(36.811 27.306)">
        <path
          d="M73.052,64A9.052,9.052,0,1,0,82.1,73.052,9.054,9.054,0,0,0,73.052,64Z"
          transform="translate(-63.457 -63.457)"
          fill={clockFill}
        />
        <path
          d="M73.095,63.5A9.595,9.595,0,1,1,63.5,73.095,9.605,9.605,0,0,1,73.095,63.5Zm0,18.1a8.509,8.509,0,1,0-8.509-8.509A8.519,8.519,0,0,0,73.095,81.6Z"
          transform="translate(-63.5 -63.5)"
          fill="#333"
        />
        <path
          d="M260.569,135.374h-4.526a.543.543,0,0,1-.543-.543v-6.789a.543.543,0,1,1,1.085,0v6.246h3.983a.543.543,0,1,1,0,1.085Z"
          transform="translate(-246.448 -124.483)"
          fill="#333"
        />
      </g>
    </svg>
  );
};

export default DeferredIcon;
