import React from 'react';

const RevisionIcon = ({
  width = 35,
  height = 35,
  checkFill = '#fff',
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 56 56"
      {...rest}
    >
      <path
        d="M918.918,217.9a7.81,7.81,0,0,1-7.832-7.828,7.356,7.356,0,0,1,.869-3.835,7.783,7.783,0,0,0,.619-6.112c-.263-.934.2-1.4,1.1-1.174a4.211,4.211,0,0,1,2.1,1.157c.468.5.608.282.831-.209a8.427,8.427,0,0,1,3.46-3.939,2.888,2.888,0,0,1,.931-.373c.524-.088.94.2.84.762-.515,2.943.918,5.226,2.232,7.557a15.318,15.318,0,0,1,2.03,4.442C927.063,213.055,923.481,217.843,918.918,217.9Zm-4.473-16.806c-.011,3.186-.009,3.187-1.314,6.269a6.664,6.664,0,0,0-.4,3.661,4.8,4.8,0,0,0,1.108,2.62c.056-.077.113-.113.113-.156a6.381,6.381,0,0,1,2.559-5.368,3.082,3.082,0,0,0,1.062-1.466c.29-1.009.812-1.181,1.591-.49,2.469,2.191,4.035,4.824,3.522,8.517a6.788,6.788,0,0,0,2-4.12,8.131,8.131,0,0,0-1.567-5.227,20.5,20.5,0,0,1-2.692-5.82,6.51,6.51,0,0,0-.338-1.554,6.373,6.373,0,0,0-2.594,4.689c-.007.475.012.972-.537,1.119-.592.158-.805-.312-1.015-.769a4.09,4.09,0,0,0-1.491-1.911Zm6.831,12.017a7.905,7.905,0,0,0-1.978-4.652c-.4-.478-.622-.458-.991.034a14.42,14.42,0,0,1-1.323,1.365,5.352,5.352,0,0,0-1.241,1.743,3.574,3.574,0,0,0,.584,3.781,2.847,2.847,0,0,0,3.152.677,2.988,2.988,0,0,0,1.8-2.952Z"
        transform="translate(-891.279 -173.229)"
        fill="#333"
      />
      <g transform="translate(4.282 4.079)">
        <path
          d="M4.384-.5H40.667a4.89,4.89,0,0,1,4.884,4.884V37.232a4.89,4.89,0,0,1-4.884,4.884H4.384A4.89,4.89,0,0,1-.5,37.232V4.384A4.89,4.89,0,0,1,4.384-.5ZM40.667,40.72a3.493,3.493,0,0,0,3.489-3.489V4.384A3.493,3.493,0,0,0,40.667.9H4.384A3.493,3.493,0,0,0,.9,4.384V37.232A3.493,3.493,0,0,0,4.384,40.72Z"
          transform="translate(0.5 4.633)"
          fill="#333"
        />
        <path
          d="M294.882,409.352H250.227a.7.7,0,1,1,0-1.4h44.655a.7.7,0,1,1,0,1.4Zm-8.588-8.586a.7.7,0,0,1-.7-.7v-3.436a.7.7,0,0,1,1.4,0v3.436A.7.7,0,0,1,286.294,400.765Zm-27.48,0a.7.7,0,0,1-.7-.7v-3.436a.7.7,0,1,1,1.4,0v3.436A.7.7,0,0,1,258.815,400.765Z"
          transform="translate(-249.529 -395.934)"
          fill="#333"
        />
      </g>
      <g transform="translate(38.472 36.175)">
        <path
          d="M80.746,72.373a8.373,8.373,0,1,0-8.373,8.373A8.375,8.375,0,0,0,80.746,72.373Z"
          transform="translate(-64 -64)"
          fill={checkFill}
        />
        <path
          d="M72.373,63.4A8.973,8.973,0,1,1,63.4,72.373,8.983,8.983,0,0,1,72.373,63.4Zm0,16.746A7.773,7.773,0,1,0,64.6,72.373,7.782,7.782,0,0,0,72.373,80.146Z"
          transform="translate(-64 -64)"
          fill="#333"
        />
        <path
          d="M168.373,176l-5.861,6.977L160,180.186"
          transform="translate(-155.814 -171.116)"
          fill="rgba(219,174,174,0)"
        />
        <path
          d="M162.512,183.577a.6.6,0,0,1-.446-.2l-2.512-2.791a.6.6,0,1,1,.892-.8l2.05,2.278,5.417-6.449a.6.6,0,1,1,.919.772l-5.861,6.977a.6.6,0,0,1-.449.214Z"
          transform="translate(-155.814 -171.116)"
          fill="#333"
        />
      </g>
      <rect width="56" height="56" fill="none" />
    </svg>
  );
};

export default RevisionIcon;
