import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CloseIcon from '../../CustomIcons/CloseIcon';
import SuccessIcon from '../../CustomIcons/SuccessIcon';
import InfoIcon from '../../CustomIcons/InfoIcon';
import ErrorIcon from '../../CustomIcons/ErrorIcon';
import WarningIcon from '../../CustomIcons/WarningIcon';

const ModalAlert = props => {
  const classes = useStyles();
  const { type = 'info', message, onClose } = props;

  // * FUNCTIONS
  // const handleCloseTimeout = () => {
  //   onClose();
  // };

  const renderText = usedType => {
    if (message) {
      return message;
    }

    switch (usedType) {
      case 'success':
        return 'Operación Exitosa';
      case 'info':
        return 'Esto es un mensaje informativo';
      case 'error':
      case 'warning':
        return 'Ocurrió un error';
      default:
        return '{Message}';
    }
  };

  // * RENDER
  return (
    <div className={classes.modalAlert}>
      <ClickAwayListener onClickAway={onClose}>
        <div
          className={`${classes.containerAlert} ${classes[`alert${type}`]}`}
          id={`modalAlert-${type}`}
        >
          {type === 'success' && <SuccessIcon size={80} fill="#ffff" />}
          {type === 'info' && <InfoIcon size={80} fill="#ffff" />}
          {type === 'error' && <ErrorIcon size={80} fill="#ffff" />}
          {type === 'warning' && <WarningIcon size={80} fill="#ffff" />}
          <Typography className={`${classes.textAlert} unselectable`}>
            {renderText(type)}
          </Typography>
          <div></div>
          <IconButton onClick={onClose} className={classes.closeContainer}>
            <CloseIcon size={15} className={classes.closeIcon} />
          </IconButton>
        </div>
      </ClickAwayListener>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  modalAlert: {
    zIndex: 2000,
    position: 'fixed',
    display: 'flex',
    flex: 1,
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.transparentLight
  },
  containerAlert: {
    position: 'relative',
    width: 237,
    height: 237,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: 0.9,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: theme.custom.borderRadius
  },
  [`alert${'success'}`]: {
    backgroundColor: theme.palette.success.main
  },
  [`alert${'info'}`]: {
    backgroundColor: theme.palette.primary.main
  },
  [`alert${'error'}`]: {
    backgroundColor: theme.palette.error.main
  },
  [`alert${'warning'}`]: {
    backgroundColor: theme.palette.warning.main
  },
  textAlert: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: 1.22,
    textAlign: 'center',
    color: theme.palette.common.white,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  closeContainer: {
    position: 'absolute',
    top: theme.spacing(),
    right: theme.spacing()
  },
  closeIcon: {
    fill: theme.palette.common.white
  }
}));

export default ModalAlert;
