import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { normalizeURI } from '../../Utils/Format/URI';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  link: {
    textDecoration: 'none',
    fontSize: 12,
    color: '#2F2F2F',
    '&:hover': {
      textDecoration: 'underline',
      fontWeight: 500
    }
  },
  text: {
    fontSize: 12,
    color: '#2F2F2F',
    textDecoration: 'underline'
  }
}));

const BaseBreadcrumbs = props => {
  const { breadcrumbs, classes = {}, separator, limit = 30 } = props;
  const baseClasses = useStyles();

  const previousBreadcrumbs = breadcrumbs.slice(0, breadcrumbs.length - 1);
  const currentBreadcrumb = breadcrumbs.slice(-1)[0];

  return (
    <div>
      <Fragment>
        <Breadcrumbs
          className={clsx(baseClasses.root, classes.root)}
          separator={separator}
          aria-label="breadcrumb"
        >
          {previousBreadcrumbs.map((br, idx) => (
            <div key={idx}>
              {normalizeURI(br.name).length > limit ? (
                <Tooltip title={br.name} placement="bottom">
                  <NavLink
                    className={clsx(classes.link, baseClasses.link)}
                    to={br.link}
                  >
                    {`${br.name.substring(0, limit)}...`}
                  </NavLink>
                </Tooltip>
              ) : (
                <NavLink
                  className={clsx(classes.link, baseClasses.link)}
                  to={br.link}
                >
                  {br.name}
                </NavLink>
              )}
            </div>
          ))}
          {normalizeURI(currentBreadcrumb.name).length > limit ? (
            <Tooltip title={currentBreadcrumb.name} placement="bottom">
              <Typography className={clsx(classes.text, baseClasses.text)}>
                {`${currentBreadcrumb.name.substring(0, limit)}...`}
              </Typography>
            </Tooltip>
          ) : (
            <Typography className={clsx(classes.text, baseClasses.text)}>
              {currentBreadcrumb.name}
            </Typography>
          )}
        </Breadcrumbs>
      </Fragment>
    </div>
  );
};

export default BaseBreadcrumbs;
