import React, { Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import MoreIcon from '@material-ui/icons/MoreHoriz';

import { fullName } from '../../../Utils/Format/Names';
import { formatAddress } from '../../../Utils/Format/Address';
import MenuButton from '../../../Components/Buttons/MenuButton';
import PointsIcon from '../../../Components/CustomIcons/IcPointsFireBadge';
import { getSendingMethodText } from '../../../Enums/invoices';
import clsx from 'clsx';

const ContractItemMobile = props => {
  const {
    contract,
    openRemoveDialog,
    openEditDialog,
    title,
    displayPoints = true
  } = props;

  const {
    digitalInvoice,
    sendEmailInvoice,
    sendWhatsAppInvoice,
    sendSmsInvoice
  } = contract;

  const classes = useStyles();

  const handleEditDialog = React.useCallback(() => {
    openEditDialog(contract);
  }, [contract, openEditDialog]);

  const handleRemoveDialog = React.useCallback(() => {
    openRemoveDialog(contract);
  }, [contract, openRemoveDialog]);

  const sendingInvoiceMethod = getSendingMethodText(
    sendEmailInvoice,
    sendWhatsAppInvoice,
    sendSmsInvoice
  );

  return (
    <Fragment>
      <Card direction="column" className={classes.mobileContainer}>
        <Grid container className={classes.header}>
          <Grid item xs={9}>
            <div className={classes.textContainer}>
              <Typography className={classes.label}>{title}</Typography>
              <Typography className={classes.text}>
                {`${contract.alias} - ${contract.id}`}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={3} className={classes.iconContainer}>
            <MenuButton
              color="primary"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'center'
              }}
              options={[
                { label: 'Editar', onClick: handleEditDialog },
                { label: 'Borrar', onClick: handleRemoveDialog }
              ]}
            >
              <MoreIcon />
            </MenuButton>
          </Grid>
        </Grid>
        {displayPoints && (
          <div className={classes.pointsContainer}>
            <PointsIcon />
            <Typography className={classes.pointsText}>
              {contract.points} puntos
            </Typography>
          </div>
        )}
        <div className={classes.textContainer}>
          <Typography className={classes.label}>Nombre del titular</Typography>
          <Typography className={`${classes.text} ${classes.capitalizeText}`}>
            {fullName(contract.holder, 'name', 'lastName', true)}
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography className={classes.label}>
            Dirección del predio
          </Typography>
          <Typography className={`${classes.text} ${classes.capitalizeText}`}>
            {formatAddress(contract, true)}
          </Typography>
        </div>
        <Grid item container className={classes.digitalInvoiceContainer}>
          <Typography
            className={clsx(classes.digitalInvoiceText, {
              [classes.physicalInvoiceText]: !digitalInvoice
            })}
          >
            {digitalInvoice ? 'Factura Digital' : 'Factura Física'}
          </Typography>
        </Grid>
        <div className={classes.activeChannelsContainer}>
          <Typography className={classes.activeChannelsText}>
            {sendingInvoiceMethod &&
              `Envío de factura vía ${sendingInvoiceMethod} mensual programado`}
          </Typography>
        </div>
      </Card>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  mobileContainer: {
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(3),
    borderRadius: theme.custom.borderRadius,
    border: `solid ${theme.palette.common.borderColor} 1px`,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  header: {
    display: 'flex',
    flexDirection: 'row'
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  icon: {},
  textContainer: {
    marginBottom: theme.spacing()
  },
  pointsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12
  },
  pointsText: {
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: theme.spacing()
  },
  label: {
    fontSize: 10,
    fontWeight: 500,
    color: theme.palette.text.greyDark
  },
  text: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.default
  },
  activeChannelsContainer: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing()
  },
  activeChannelsText: {
    fontSize: 10,
    color: theme.palette.text.greyDark
  },
  capitalizeText: {
    textTransform: 'capitalize'
  },
  digitalInvoiceContainer: {
    alignItems: 'center',
    fontSize: theme.spacing(1)
  },
  digitalInvoiceText: {
    minWidth: 100,
    fontWeight: 500,
    fontSize: 12,
    textAlign: 'center',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0.5),
    borderRadius: theme.custom.borderRadius
  },
  physicalInvoiceText: {
    backgroundColor: theme.palette.color.gasesGreen
  }
}));

export default ContractItemMobile;
