import React from 'react';
import { makeStyles, styled } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
  Chip
} from '@material-ui/core';

import clsx from 'clsx';

import SeeMoreButton from './SeeMoreButton';

import WarningIcon from '../../../Components/CustomIcons/DebtsWarningIcon';
import CalendarIcon from '../../../Components/CustomIcons/CalendarIcon';
import DownloadIcon from '../../../Components/CustomIcons/DownloadIcon';
import BillIcon from '../../../Components/CustomIcons/BillIcon';
import { ProductType } from '../myDebts_enums';

// * CUSTOM MUI COMPONENTS
const CustomChip = styled(Chip)({
  height: 20
});

const DebtStatusHeader = props => {
  const { isMyDebts, currentSummary, deferredSummary, refinancing } = props;
  const { data, title } = props;
  const { generatePDF, onHelpClick } = props;

  const classes = useStyles();
  const theme = useTheme();
  const mediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  const headerProductTypes = () => {
    const headerSubtitles = [];

    const areThereGasProducts = data.deferreds.some(
      item => item.productTypeId === ProductType.Gas
    );

    const areThereFinancialProducts = data.deferreds.some(item =>
      [
        ProductType.FinancingServices,
        ProductType.FinancingServicesPromigas
      ].includes(item.productTypeId)
    );

    if (areThereFinancialProducts) {
      headerSubtitles.push('Servicios Financieros');
    }

    if (areThereGasProducts) {
      headerSubtitles.push('Gas');
    }

    return headerSubtitles;
  };

  return (
    <div className={classes.headerCardContainer}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className={classes.titleCardContainer}>
          {(isMyDebts || !mediumScreen) && (
            <Box className={classes.billIcon}>
              <BillIcon />
            </Box>
          )}
          <Typography
            className={clsx(classes.title, { [classes.margin]: refinancing })}
          >
            {title}
          </Typography>
        </div>

        {mediumScreen && (
          <SeeMoreButton
            onClick={onHelpClick}
            iconClasses={classes.seeMoreIcon}
            buttonClasses={classes.seeMoreButton}
          />
        )}
      </div>

      {!mediumScreen && isMyDebts && (
        <Button
          className={clsx(classes.downloadSummaryButton)}
          color="primary"
          size="small"
          variant="outlined"
          onClick={generatePDF}
        >
          Descargar certificado
          <DownloadIcon
            width={18}
            height={16}
            fill={theme.palette.common.white}
            className={classes.downloadIcon}
          />
        </Button>
      )}

      {currentSummary &&
        data.currentValue > 0 &&
        data.billingDate &&
        data.billingDateMonth && (
          <div className={classes.debtStatusContainer}>
            <Typography
              className={clsx(classes.statusContainer, classes.billingMonth)}
            >
              {mediumScreen ? '' : 'Mes de facturación:'}
              <span className={classes.month}>{data.billingDateMonth}</span>
              <CalendarIcon
                fill={theme.palette.primary.lighter}
                className={classes.calendarIcon}
              />
            </Typography>
            {false && (
              <CustomChip
                label="Próximo a suspender"
                icon={<WarningIcon />}
                className={classes.statusChip}
              />
            )}
          </div>
        )}
      {deferredSummary && (
        <div className={classes.statusChipContainer}>
          {headerProductTypes().map(productType => (
            <Chip
              key={productType}
              label={productType}
              size="small"
              className={clsx(classes.statusChip, {
                [classes.statusChipMargin]: headerProductTypes().length > 1
              })}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  headerCardContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      flexDirection: 'column-reverse',
      '& > *': {
        width: 'inherit'
      }
    }
  },
  titleCardContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  statusContainer: {
    display: 'flex',
    color: theme.palette.text.greyDark,
    marginRight: theme.spacing(2.5),
    alignItems: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      flexDirection: 'row-reverse',
      marginRight: 0
    }
  },
  debtStatusContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      flexDirection: 'row',
      marginBottom: theme.spacing(2.5)
    }
  },
  margin: {
    marginBottom: theme.spacing(1)
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 1,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: 16
    }
  },
  label: {
    fontSize: 12,
    fontWeight: 500
  },
  billingMonth: {
    textTransform: 'none',
    fontSize: 12,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: 11
    },
    color: theme.palette.primary.lighter
  },
  month: {
    textTransform: 'capitalize',
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginLeft: theme.spacing(1)
    }
  },
  downloadSummaryButton: {
    fontSize: 11,
    textTransform: 'unset',
    width: 186,
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    color: theme.palette.common.white,
    borderColor: theme.palette.common.white,
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#FFFFFF26',
      borderColor: theme.palette.common.white
    }
  },
  downloadIcon: {
    marginLeft: theme.spacing(1)
  },
  calendarIcon: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginLeft: 0
    }
  },
  billIcon: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginRight: theme.spacing(1)
    }
  },
  statusChip: {
    fontSize: 10,
    color: theme.palette.common.white,
    backgroundColor: '#FFFFFF40',
    border: '1px solid #FFFFFF'
  },
  statusChipMargin: {
    marginLeft: theme.spacing(1)
  },
  statusChipContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginBottom: theme.spacing(2.5)
    }
  },
  seeMoreIcon: {
    fontSize: 24,
    color: theme.palette.primary.lighter,
    marginLeft: theme.spacing(1)
  },
  seeMoreButton: {
    fontSize: 12,
    color: theme.palette.primary.lighter
  }
}));

export default DebtStatusHeader;
