import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import ChangeOfOwnershipGDC from './ChangeOfOwnership.GDC';

const ChangeOfOwnership = () => {
  switch (portal) {
    case Portal.Gascaribe:
      return <ChangeOfOwnershipGDC />;
    default:
      return <></>;
  }
};

export default ChangeOfOwnership;
