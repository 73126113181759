import ContractIcon from '../../Components/CustomIcons/ContractIcon';
import BillCouponIcon from '../../Components/CustomIcons/BillCouponIcon';
import { OpenNewTab } from '../../Utils/Misc/Links';
import { ToDonationPDFLink } from '../../Configs/Links';
import { Company } from '../../Configs/general';
import _get from 'lodash/get';

export const STEPS = [];

export const PAYMENT_METHOD = {
  PSE: 'pse',
  CARD: 'card',
  NEQUI: 'nequi'
};

export const getPaymentMethodLabel = method => {
  switch (method) {
    case PAYMENT_METHOD.PSE:
      return 'PSE';
    case PAYMENT_METHOD.CARD:
      return 'Tarjeta';
    case PAYMENT_METHOD.NEQUI:
      return 'Nequi';
    default:
      return '';
  }
};

export const SELECT_OPTIONS = [
  {
    label: `Número de ${Company.contractConjugation.regular.singular.main}`,
    value: 'contract',
    image: ContractIcon
  },
  { label: 'Número de cupón', value: 'coupon', image: BillCouponIcon }
];

export const TYPE_OF_IDENT_OPTIONS = [
  { id: 1, label: 'Cédula', value: 'CC' },
  { id: 2, label: 'Pasaporte', value: 'PP' },
  {
    id: 3,
    label: 'Cédula de extranjería',
    value: 'CE'
  },
  {
    id: 4,
    label: 'Tarjeta de identidad',
    value: 'TI'
  },
  { id: 5, label: 'NIT', value: 'NIT' },
  {
    id: 3,
    label: 'Número ident. extranjero',
    value: 'DE'
  }
];

export const COUNTRY_OPTIONS = [
  {
    label: 'Colombia',
    value: 'COL'
  }
];

export const CITIES_OPTION = [
  {
    label: 'Barranquilla',
    value: 'BAQ'
  }
];

export const TYPE_OF_CUSTOMER = [
  {
    label: 'Persona natural',
    value: 'N'
  },
  {
    label: 'Persona jurídica',
    value: 'J'
  }
];

export const BANK_NAMES = [
  {
    label: 'Bancolombia',
    value: 'BANCOLOMBIA'
  },
  {
    label: 'Banco de Bogotá',
    value: 'BANCO_DE_BOGOTÁ'
  },
  {
    label: 'Davivienda',
    value: 'DAVIVIENDA'
  },
  {
    label: 'BBVA',
    value: 'BBVA'
  }
];

export const TRANSACTION_STATUS = {
  Pending: 0,
  Approved: 1,
  Rejected: 2
};

export const TRANSACTION_TYPES = {
  CouponPayment: 0,
  DonationCOVID19: 1,
  Financing: 2,
  GroupedCouponPayment: 3,
  PartialPayment: 4
};

export const MIN_DONATION_VALUE = 1500;
export const MAX_DONATION_VALUE = 500000000;
export const MAX_CARDS_NUMBER = 150;

export const LinkToDonationPDF = () => {
  OpenNewTab(ToDonationPDFLink);
};

export const TYPE_OF_PAYMENTS = {
  financing: 'financing',
  contract: 'contract',
  coupon: 'coupon',
  groupedCoupon: 'groupedCoupon',
  couponless: 'couponless',
  partialPayment: 'partialPayment'
};

export const TRANSACTION_CARD_TYPES = {
  COUPON: 'coupon',
  CONTRACT: 'contract'
};

export const CARD_STATUS = {
  LOADING: 'loading',
  PAID: 'paid',
  PENDING: 'pending',
  TOPAY: 'toPay',
  ERROR: 'error'
};

export const GROUPED_COUPON_INITIAL = 'P';

export const TAB_OPTIONS = {
  MULTIPLE: 0,
  INDIVIDUAL: 1
};

export const INDIVIDUAL_LOAD_INITIAL_STATE = {
  toPay: [],
  loading: [],
  paid: [],
  pending: [],
  error: []
};

export const MULTIPLE_LOAD_INITIAL_STATE = {
  toPay: [],
  loading: [],
  paid: [],
  pending: [],
  error: []
};

export const TABS_INITIAL_STATE = {
  noChecked: 0,
  checked: 0
};

export const INDIVIDUAL_STATE = {
  noChecked: 0,
  checked: 1
};

export const DEFAULT_BANK_VALUE = '0';

export const MINIMUM_PARTIAL_PAYMENT_AMOUNT = 20000;

export const PAYMENT_VALUE_OPTION = {
  TotalCoupon: 'total',
  Partial: 'partial',
  CurrentDebt: 'currentDebt'
};

export const getCouponValue = payment => {
  const couponValue =
    _get(payment, 'detail.couponValue') ||
    _get(payment, 'detail.value') ||
    _get(payment, 'detail.totalInitialInstallmentValue');

  return Number(couponValue);
};
