/* eslint-disable camelcase */
import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useReducer,
  useContext,
  useLayoutEffect,
  useRef
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  LinearProgress,
  Box,
  Divider,
  withWidth,
  IconButton,
  Collapse
} from '@material-ui/core';
import clsx from 'clsx';

import MapComponent from '../Map/MapComponent';
import { OPTIONS, defaultOption } from '../marker.options';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SvgImgClientSupport from '../../../Components/CustomIcons/SupportIcon';
import SvgImgPay from '../../../Components/CustomIcons/PayIcon';
import SvgImgSupportSelected from '../../../Components/CustomIcons/SupportSelectedIcon';
import SvgImgPaySelected from '../../../Components/CustomIcons/PaySelectedIcon';
import ShareLocationIcon from '../../../Components/CustomIcons/ShareLocationIcon';
import FullSizeProgress from '../../../Components/Progress/FullSize/FullSizeProgress';
import CallToActionDialog from '../../../Components/Dialogs/CallToActionDialog';
import SwipeableBottomSheet from 'react-swipeable-bottom-sheet';
import { FooterDispatchContext } from '../../../Contexts/FooterContext';
import {
  GetPlacesAPI,
  GetPlacesLocationsAPI
} from '../../../API/Places/PlaceAPI';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';
import { extractErrorMessage } from '../../../Utils/Errors/Errors';
import { Company } from '../../../Configs/general';
import PhoneList from '../../../Components/Lists/PhoneList';

const searchPlace = async (lat, lng, addMarkers) => {
  const response = await GetPlacesAPI(lat, lng);
  if (!response.success) {
    return;
  }
  const places = response.data.data;
  addMarkers(places);
};

const searchNearbyPayPlaces = (location, ref, addMarkers) => {
  return searchPlace(location.lat, location.lng, addMarkers);
};

const CLEAR_MARKERS = 'CLEAR_MARKERS';
const ADD_MARKERS = 'ADD_MARKERS';
const markersReducer = (state, action) => {
  switch (action.name) {
    case CLEAR_MARKERS:
      return { members: [], type: action.type };
    case ADD_MARKERS:
      if (action.type !== state.type) {
        return state;
      }

      return {
        ...state,
        members: [...state.members, ...action.markers]
      };
    default:
      break;
  }

  return state;
};

const DEFAULT_LOCATION = Company.location;

const PlacesGDC = ({ match, history, width }) => {
  const selectedOption = match.params.option || defaultOption;
  const classes = useStyles();

  const mapRef = useRef();

  const [markers, dispatchMarkers] = useReducer(markersReducer, {
    members: [],
    type: selectedOption
  });

  // * STATE HOOKS
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [map, setMap] = useState(null);
  const [location, setLocation] = useState(DEFAULT_LOCATION);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [locations, setLocations] = useState([]);
  const [mapLoading, setMapLoading] = useState(true);
  const [markersLoading, setMarkersLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [permissionState, setPermissionState] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [zoom, setZoom] = useState(selectedOption === 'support' ? 12 : 15);

  useEffect(() => {
    setZoom(selectedOption === 'support' ? 12 : 15);
  }, [selectedOption]);

  // * CONTEXTS
  const setFooterVisible = useContext(FooterDispatchContext);
  const setAlert = useContext(AlertsDispatchContext);

  const iconSelected = option => ({ normal, selected }) => {
    const useSelected = isMobile && selectedOption === option;
    if (useSelected) {
      return selected;
    }
    return normal;
  };

  const buttons = [
    {
      title: 'Oficinas de atención al cliente',
      value: OPTIONS.support,
      image: iconSelected(OPTIONS.support)({
        selected: SvgImgSupportSelected,
        normal: SvgImgClientSupport
      })
    },
    {
      title: 'Puntos de pago cercanos',
      value: OPTIONS.pay,
      image: iconSelected(OPTIONS.pay)({
        selected: SvgImgPaySelected,
        normal: SvgImgPay
      })
    }
  ];

  // All views with a Stepper Footer can't have another footer in the background
  useLayoutEffect(() => {
    if (isMobile) {
      setFooterVisible(false);
      return () => {
        setFooterVisible(true);
      };
    }
  }, [isMobile, setFooterVisible]);

  useEffect(() => {
    let ignoreRequest = false;

    const fetchData = async () => {
      setMarkersLoading(true);

      const response = await GetPlacesLocationsAPI();

      if (ignoreRequest) {
        return;
      }

      if (response.success) {
        setLocations(response.data.data);

        setMarkersLoading(false);
      } else {
        setMarkersLoading(false);

        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
      }
    };

    fetchData();

    return () => {
      ignoreRequest = true;
    };
  }, [setAlert]);

  useEffect(() => {
    if (width === 'xs' || width === 'xm') {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  useEffect(() => {
    const attachOnPermissionChangeHandler = async () => {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: 'geolocation'
        });

        setPermissionState(permissionStatus.state);

        permissionStatus.onchange = evt => {
          setPermissionState(evt.target.state);
        };
      } catch (err) {
        console.error(err);
      }
    };

    attachOnPermissionChangeHandler();
  }, []);

  const getCurrentPosition = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        loc => {
          const foundLocation = {
            lat: loc.coords.latitude,
            lng: loc.coords.longitude
          };
          setAlert(null);
          setDialogOpen(false);
          setCurrentLocation({
            ...foundLocation
          });
          setLocation({
            ...foundLocation
          });
        },
        () => {
          setDialogOpen(true);
          setCurrentLocation(null);
        },
        {
          enableHighAccuracy: true,
          maximumAge: 0
        }
      );
    } else {
      setAlert({
        type: 'error',
        message: 'Tu navegador no soporta geolocación'
      });
    }
  }, [setAlert]);

  useEffect(() => {
    if (permissionState && permissionState !== 'granted') {
      setDialogOpen(true);
    }
    getCurrentPosition();
  }, [setAlert, permissionState, getCurrentPosition]);

  const handleMyLocationClick = () => {
    setZoom(selectedOption === 'support' ? 12 : 15);
    getCurrentPosition();
  };

  const updateZoomLevel = () => {
    if (mapRef.current) {
      setZoom(mapRef.current.getZoom());
    }
  };

  const handleOptionChange = useCallback(
    async value => {
      setMarkersLoading(true);
      dispatchMarkers({ name: CLEAR_MARKERS, type: value });
      setSelectedMarker(null);
      if (mapRef.current) {
        mapRef.current.panTo(location);
      }
      if (value === OPTIONS.pay) {
        await searchNearbyPayPlaces(location, map, newMarkers =>
          dispatchMarkers({
            name: ADD_MARKERS,
            markers: newMarkers,
            type: value
          })
        );
      } else {
        const filterLocation = option => loc => loc.type === option;
        const assignMarkerIcon = loc => ({ ...loc, visible: false });
        const locs = locations
          .filter(filterLocation(value))
          .map(assignMarkerIcon);
        dispatchMarkers({ name: ADD_MARKERS, markers: locs, type: value });
      }
      setTimeout(() => {
        setMarkersLoading(false);
      }, 1800);
    },
    [map, location, locations]
  );

  const handleMarkerDragEnd = useCallback(ev => {
    setLocation({
      lat: ev.lat(),
      lng: ev.lng()
    });
  }, []);

  const handleOptionClick = useCallback(
    value => () => history.replace(`/closer/${value}`),
    [history]
  );

  useEffect(() => {
    if (map) {
      handleOptionChange(selectedOption);
    }
  }, [map, handleOptionChange, selectedOption]);

  const handleMapLoad = useCallback(
    ref => {
      if (!map) {
        setMapLoading(false);
      }
      setMap(ref);
      mapRef.current = ref;
    },
    [map]
  );

  const selectInfoWindow = useCallback(
    id => {
      if (expanded) {
        collapseFeedback();
      } else if (id || id === 0) {
        const { latLng } = id;
        if (latLng) {
          setLocation({ lat: latLng.lat(), lng: latLng.lng() });
        }
        setSelectedMarker(id);
      } else {
        setSelectedMarker(null);
      }
    },
    [expanded]
  );

  const renderAttentionLine = () => {
    return (
      <div
        className={clsx(classes.section, {
          [classes.scrollable]: isMobile
        })}
      >
        <Typography className={classes.title}>
          Líneas de atención al cliente
        </Typography>
        <PhoneList />
      </div>
    );
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const collapseFeedback = () => {
    setExpanded(false);
  };

  const renderSidebar = () => {
    if (isMobile) {
      return (
        <SwipeableBottomSheet
          style={{
            zIndex: 100,
            display: isMobile ? 'block' : 'none'
          }}
          bodyStyle={{
            height: '80vh',
            backgroundColor: 'transparent'
          }}
          topShadow={false}
          overflowHeight={64}
        >
          <div id="sidebar" className={classes.sidebar}>
            <div className={clsx([classes.section, { handle: isMobile }])}>
              <Typography className={classes.title} variant="h5">
                Puntos de atención
              </Typography>
              <div className={classes.buttons}>
                {buttons.map((props, i) => (
                  <div
                    key={i}
                    onClick={handleOptionClick(props.value)}
                    className={clsx(classes.buttonItem, {
                      [classes.active]: selectedOption === props.value
                    })}
                  >
                    <props.image
                      width={50}
                      height={50}
                      className={classes.buttonImage}
                    />
                    <span className={classes.buttonText}>{props.title}</span>
                  </div>
                ))}
              </div>
            </div>
            {renderAttentionLine()}
          </div>
        </SwipeableBottomSheet>
      );
    }

    return (
      <div id="sidebar" className={classes.sidebar}>
        <div className={clsx([classes.section, { handle: isMobile }])}>
          <Typography className={classes.title} variant="h5">
            Puntos de atención
          </Typography>
          <div className={classes.buttons}>
            {buttons.map((props, i) => (
              <div
                key={i}
                onClick={handleOptionClick(props.value)}
                className={clsx(classes.buttonItem, {
                  [classes.active]: selectedOption === props.value
                })}
              >
                <props.image
                  width={50}
                  height={50}
                  className={classes.buttonImage}
                />
                <span className={classes.buttonText}>{props.title}</span>
              </div>
            ))}
          </div>
        </div>
        {renderAttentionLine()}
      </div>
    );
  };

  const renderFeedBack = () =>
    ['granted', 'denied'].includes(permissionState) && (
      <div className={classes.feedback}>
        <Box
          display="flex"
          alignItems="center"
          className={classes.feedbackTitleBox}
        >
          <div
            className={clsx(
              classes.status,
              permissionState === 'granted'
                ? [classes.animation, classes.grantedStatus]
                : classes.deniedStatus
            )}
          />
          <Typography className={classes.feedbackTitle}>
            {permissionState === 'granted'
              ? 'Ubicación activada'
              : 'Ubicación desactivada'}
          </Typography>
          <IconButton
            size="small"
            className={clsx(classes.expand, expanded && classes.expanded)}
            onClick={() => setExpanded(e => !e)}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Box>

        <Collapse in={expanded}>
          <Divider className={classes.divider} />

          {permissionState === 'granted' && (
            <Typography className={classes.feedbackText}>
              El marcador rojo muestra tu ubicación actual aproximada y
              alrededor{' '}
              {(selectedOption === 'pay' &&
                'los puntos de pago más cercanos') ||
                (selectedOption === 'support' &&
                  'las oficinas de atención más cercanas')}
              .
            </Typography>
          )}

          <Typography className={classes.feedbackText}>
            También puedes mover el marcador de posición arrastrándolo o
            haciendo click en cualquier parte del mapa.
          </Typography>
        </Collapse>
      </div>
    );

  const renderDialog = () =>
    dialogOpen && (
      <CallToActionDialog
        confirmButtonText="Continuar"
        handleClose={closeDialog}
        handleConfirmClick={closeDialog}
        icon={ShareLocationIcon}
        message="Para una mejor experiencia te sugerimos que permitas el acceso a tu ubicación en el navegador."
        open={dialogOpen}
        title="Permitir acceso a tu ubicación"
      />
    );

  return (
    <Fragment>
      <div className={classes.container}>
        {renderFeedBack()}
        {renderDialog()}
        {renderSidebar()}
        <div className={classes.content}>
          {markersLoading && <LinearProgress color="secondary" />}
          {mapLoading && <FullSizeProgress />}
          {location && (
            <MapComponent
              location={location}
              currentLocation={currentLocation}
              handleMyLocationClick={handleMyLocationClick}
              collapseFeedback={collapseFeedback}
              isMobile={isMobile}
              mapRef={map}
              selectedMarker={selectedMarker}
              selectInfoWindow={selectInfoWindow}
              onMapLoaded={handleMapLoad}
              MarkerDragEnd={handleMarkerDragEnd}
              markers={markers.members}
              option={selectedOption}
              updateZoomLevel={updateZoomLevel}
              zoom={zoom}
              zoomVisible={!Boolean(isMobile)}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1.7)'
    },
    '50%': {
      transform: 'scale(1.5)'
    },
    '100%': {
      transform: 'scale(1.7)'
    }
  },
  container: {
    width: '100%',
    display: 'flex',
    height: '100%',
    position: 'relative',
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      margin: [[0, 'auto']]
    }
  },
  feedback: {
    position: 'absolute',
    right: theme.spacing(1),
    boxShadow: '0 2px 6px -1px rgba(0,0,0,0.1)',
    top: 0,
    zIndex: 20,
    maxWidth: 250,
    borderRadius: theme.spacing(0, 0, 0.5, 0.5),
    backgroundColor: '#ffffff',
    padding: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down(400)]: {
      maxWidth: '100%',
      right: 0,
      left: 0
    }
  },
  divider: { margin: theme.spacing(1.5, 0) },
  animation: {
    '&::before': {
      animation: `$pulse 1.2s infinite ${theme.transitions.easing.easeInOut}`
    }
  },
  status: {
    height: 12,
    width: 12,
    borderRadius: '50%',
    position: 'relative',
    marginRight: theme.spacing(1.5),
    transition: `background-color 250ms ${theme.transitions.easing.easeInOut}`,
    [theme.breakpoints.down(400)]: {
      width: 10,
      height: 10
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      transform: 'scale(1.7)',
      borderRadius: '50%',
      width: '100%',
      height: '100%'
    }
  },
  grantedStatus: {
    backgroundColor: 'rgba(254, 104, 94, 1)',
    '&::before': {
      backgroundColor: 'rgba(254, 104, 94, 0.2)'
    }
  },
  deniedStatus: {
    backgroundColor: 'rgba(116, 123, 135, 1)',
    '&::before': {
      backgroundColor: 'rgba(116, 123, 135, 0.2)'
    }
  },
  feedbackTitleBox: { position: 'relative' },
  feedbackTitle: {
    fontSize: 14,
    lineHeight: 1.2,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down(400)]: {
      fontSize: 13
    }
  },
  expand: {
    transform: 'rotate(0deg)',
    position: 'absolute',
    right: -8,
    top: -8,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expanded: {
    transform: 'rotate(180deg)'
  },
  feedbackText: {
    fontSize: 11,
    marginBottom: theme.spacing(),
    '&:last-of-type': {
      marginBottom: 0
    }
  },
  sidebar: {
    backgroundColor: '#f8f8f8',
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    boxShadow: '6px 0 0 0 rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    left: 0,
    minHeight: '100%',
    width: '100%',
    zIndex: 1,
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      borderRadius: 0,
      minHeight: '100%',
      width: 316,
      height: 'calc(100vh - 128px)'
    },
    [theme.breakpoints.down('xs')]: {
      backgroundColor: '#ffffff'
    }
  },
  content: {
    position: 'relative',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      flex: 3
    }
  },
  drawerHelper: {
    position: 'fixed',
    bottom: 0,
    zIndex: 1,
    height: 60,
    width: '100%',
    backgroundColor: theme.palette.common.white,
    borderRadius: 30
  },
  drawerHandler: {
    '&::before': {
      content: "''",
      position: 'absolute',
      top: 20,
      left: '30%',
      borderBottom: '3px solid #e3e3e3',
      width: '40%'
    },
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  section: {
    padding: [[theme.spacing(6), 0, 0, 0]],
    backgroundColor: '#ffffff',
    '&:first-child': {
      borderBottom: '1px solid #e7e7e7',
      borderTopLeftRadius: 30,
      borderTopRightRadius: 30
    },
    '&:last-child': {
      height: '100%',
      padding: [[theme.spacing(2), 0, theme.spacing(6), 0]]
    },
    '&:first-child::before': {
      content: "''",
      position: 'absolute',
      top: 20,
      left: '30%',
      borderBottom: '3px solid #e3e3e3',
      width: '40%'
    },
    [theme.breakpoints.up('sm')]: {
      '&:first-child': {
        borderBottom: 'none',
        borderRadius: 0
      },
      '&:first-child::before': {
        display: 'none'
      },
      padding: [[theme.spacing(3), 0, theme.spacing(2)]],
      margin: [[0, 0, theme.spacing(2)]],
      '&:last-child': {
        marginTop: theme.spacing(0),
        marginBottom: 0,
        flex: 1,
        paddingBottom: theme.spacing(2)
      }
    },
    [theme.breakpoints.between(theme.breakpoints.values.sm, 1400)]: {
      height: 'calc((calc(100vh - 128px)) - (calc(100vh - 50px)/2))'
    }
  },
  item: {
    padding: [[theme.spacing(2), 0, theme.spacing(1), 0]],
    flex: 1
  },
  buttons: {
    display: 'flex',
    padding: [[theme.spacing(2), 0]],
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  title: {
    fontWeight: 500,
    textAlign: 'left',
    color: '#333333',
    padding: [[0, theme.spacing(2)]],
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  subtitle: {
    fontSize: 10,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#747b87'
  },
  info: {
    fontSize: 14,
    fontWeight: 500,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 1.29,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: '#333333'
  },
  buttonItem: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    padding: [[theme.spacing(1), theme.spacing(2)]],
    [theme.breakpoints.up('sm')]: {
      '&:hover': {
        backgroundColor: '#f7faff'
      },
      flexDirection: 'row'
    }
  },
  active: {
    [theme.breakpoints.up('sm')]: {
      backgroundColor: '#f7faff'
    }
  },
  buttonText: {
    fontSize: 10,
    lineHeight: 1.3,
    textAlign: 'center',
    margin: [[theme.spacing(2), 0]],
    [theme.breakpoints.up('sm')]: {
      margin: [[0, theme.spacing(2)]],
      fontSize: 12,
      textAlign: 'left'
    }
  },
  line: {
    display: 'flex',
    flexDirection: 'column',
    margin: [[0, theme.spacing(2)]],
    borderBottom: '1px solid #e7e7e7'
  },
  subtitlePhone: {
    padding: [[8, 0, 0, 0]],
    position: 'relative'
  },
  itemPhone: {
    padding: [[8, 2]]
  },
  iconPhone: {
    justifyContent: 'flex-end'
  },
  scrollable: {
    height: '39vh !important',
    [theme.breakpoints.down(370)]: {
      overflowY: 'scroll'
    }
  },
  paper: {
    top: '20%',
    [theme.breakpoints.up('sm')]: {
      top: 'unset'
    }
  },
  mobileDrawer: {
    zIndex: 100
  }
}));

export default withWidth()(PlacesGDC);
