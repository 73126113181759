import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  Fragment,
  useMemo
} from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import TextInput from '../../../../../../Components/Inputs/TextInput';
import SelectInput from '../../../../../../Components/Inputs/SelectInput';
import Recaptcha from '../../../../../../Components/Captcha/Recaptcha';
import BaseButton from '../../../../../../Components/Buttons/BaseButton';
import QuestionMark from '../../../../../../Components/Adornments/QuestionMark';
import InvoiceHelpDialog from '../../../../../../Components/Dialogs/InvoiceHelpDialog/InvoiceHelpDialog';
import ModalProgress from '../../../../../../Components/Progress/Modal/ModalProgress';

import { AlertsDispatchContext } from '../../../../../../Contexts/AlertsContext';

import { ALERT_TYPE } from '../../../../../../Components/Alerts/alert_enums';
import { HELP_TEXT } from '../../../../../../Utils/enums';
import {
  SELECT_OPTIONS,
  MAX_CARDS_NUMBER,
  GROUPED_COUPON_INITIAL,
  TYPE_OF_PAYMENTS
} from '../../../../payment_enums';
import { config } from '../../../../../../Configs';
import { Company } from '../../../../../../Configs/general';
import { ContractsContext } from '../../../../../../Contexts/ContractsContext';
import ComboBoxInput from '../../../../../../Components/Inputs/ComboBoxInput';

const IndividualLoadGDC = props => {
  const {
    number,
    setNumber,
    type,
    setType,
    recaptchaRef,
    setCaptcha,
    inputRef,
    onCaptchaResolved,
    onCaptchaExpired,
    onCaptchaLoaded,
    loadingCaptcha,
    canSubmit,
    loading,
    totalCurrentCards,
    setCardIndividualLoad,
    setFirstAccordionExpanded,
    setSecondAccordionExpanded,
    captcha,
    setCaptchaToken
  } = props;

  const setAlert = useContext(AlertsDispatchContext);
  const userContracts = useContext(ContractsContext);

  const availableContractOptions = useMemo(() => {
    if (!userContracts) {
      return [];
    }

    if (userContracts && totalCurrentCards.length === 0) {
      return userContracts.map(({ id, alias }) => ({ id, label: alias }));
    }

    const selectedContracts = totalCurrentCards.map(
      card => card.detail.contractId
    );

    return userContracts
      .filter(contract => !selectedContracts.includes(contract.id))
      .map(({ id, alias }) => ({ id, label: alias }));
  }, [userContracts, totalCurrentCards]);

  // * STATE HOOKS;
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [contractOptions, setContractOptions] = useState(
    availableContractOptions
  );

  // * OTHER HOOKS
  const classes = useStyles();
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('xs'));

  // * FUNCTIONS
  const handleType = useCallback(
    e => {
      setType(e.target.value);
      setNumber('');
    },
    [setType, setNumber]
  );

  const onChangeNumber = useCallback(
    e => {
      setNumber(e.target.value);
    },
    [setNumber]
  );

  const contractInputChangeHandler = useCallback(
    (event, currentInput) => {
      if (event) {
        setNumber(currentInput);

        const contractId = event.target.value
          ? parseInt(event.target.value, 10)
          : '';

        const filteredContracts = availableContractOptions.filter(
          contractOption => String(contractOption.id).includes(contractId)
        );

        setContractOptions(filteredContracts);
      }
    },
    [setNumber, availableContractOptions]
  );

  const resetInputHelper = useCallback(() => {
    setNumber('');
  }, [setNumber]);

  const onHelpClick = useCallback(() => {
    setHelpDialogOpen(true);
  }, []);

  useEffect(() => {
    setSecondAccordionExpanded(false);
    setCaptcha(null);
    setContractOptions(availableContractOptions);
    resetInputHelper();
  }, [
    setSecondAccordionExpanded,
    setCaptcha,
    totalCurrentCards,
    loading,
    availableContractOptions,
    resetInputHelper,
    setAlert
  ]);

  const findElement = (inputArray, property, targetItem) => {
    const foundItem = inputArray.find(
      item => String(item.detail[property]) === String(targetItem)
    );
    return foundItem;
  };

  const paymentCardAddition = (actualCards, searchNumber, transactionType) => {
    if (actualCards.length >= MAX_CARDS_NUMBER) {
      setAlert({
        type: ALERT_TYPE.WARNING,
        message: 'El número máximo de elementos permitidos es 100'
      });
      return {
        newCard: false
      };
    }

    const groupedCouponInitial = new RegExp(GROUPED_COUPON_INITIAL, 'i');

    const existGroupedCoupon = actualCards.find(card => {
      if (card.detail) {
        return String(card.detail.couponId).search(groupedCouponInitial) !== -1;
      }
      return false;
    });

    if (existGroupedCoupon) {
      setAlert({
        type: ALERT_TYPE.INFO,
        message: `No puede añadir otros cupones y/o ${Company.contractConjugation.regular.plural.main} si ya ha añadido un cupón agrupado`
      });
      return {
        newCard: false
      };
    }

    if (transactionType === 'contract') {
      const existsContractId = findElement(
        actualCards,
        'contractId',
        searchNumber
      );
      if (existsContractId) {
        setAlert({
          type: ALERT_TYPE.INFO,
          message: `${Company.contractConjugation.capitalized.singular.demonstrative} ya ha sido cargado previamente`
        });
        setCaptcha(null);
        return {
          newCard: false
        };
      }
    } else {
      const isGroupedCoupon =
        String(searchNumber).search(groupedCouponInitial) !== -1;

      if (isGroupedCoupon && actualCards.length > 0) {
        setAlert({
          type: ALERT_TYPE.INFO,
          message: `No puede cargar este tipo de cupones si ya ha añadido otros cupones y/o ${Company.contractConjugation.regular.plural.main}`
        });
        return {
          newCard: false
        };
      }

      const existsCouponId = findElement(actualCards, 'couponId', searchNumber);
      if (existsCouponId) {
        setAlert({
          type: ALERT_TYPE.INFO,
          message: 'Este cupón ya ha sido cargado previamente'
        });
        setCaptcha(null);
        return {
          newCard: false
        };
      }
    }

    return {
      newCard: true,
      payload: [
        {
          status: 'loading',
          type: transactionType,
          detail: {
            [transactionType === 'contract'
              ? 'contractId'
              : 'couponId']: searchNumber,
            loading: true
          }
        }
      ]
    };
  };

  const createLoadingCard = () => {
    const { newCard, payload } = paymentCardAddition(
      totalCurrentCards,
      number,
      type
    );

    setCaptchaToken(captcha);

    recaptchaRef.current.reset();

    setCaptcha(null);

    if (newCard) {
      setAlert({
        type: ALERT_TYPE.SUCCESS,
        timeout: 5000,
        message: `${
          type === 'coupon'
            ? 'Cupón'
            : Company.contractConjugation.capitalized.singular.main
        } agregado`
      });
      setCardIndividualLoad(prev => ({
        ...prev,
        loading: [...prev.loading, ...payload]
      }));
      setFirstAccordionExpanded(true);
    }
    return;
  };

  return (
    <Fragment>
      {loadingCaptcha && (
        <ModalProgress
          id={'QueryPayment_progress_loadingCaptcha'}
          message={'Cargando'}
        />
      )}
      <InvoiceHelpDialog
        open={helpDialogOpen}
        type={type}
        onClose={() => setHelpDialogOpen(false)}
        title={HELP_TEXT[type]}
      />
      <form>
        <Grid container className={classes.formContainer}>
          <Grid item xs={12} sm={4} className={classes.inputContainer}>
            <SelectInput
              id="QueryPayment_select_paymentMethod"
              className={classes.input}
              InputPropsClasses={{
                root: classes.input
              }}
              value={type}
              onChange={handleType}
              disabled={loading}
              label={'Referencia'}
              fullWidth
              options={SELECT_OPTIONS}
            />
          </Grid>
          {type === TYPE_OF_PAYMENTS.coupon && (
            <Grid item xs={12} sm={4} className={classes.inputContainer}>
              <TextInput
                id="QueryPayment_input_contractNumber"
                className={classes.input}
                InputPropsClasses={{
                  root: classes.input
                }}
                autoFocus
                value={number}
                onChange={onChangeNumber}
                disabled={loading}
                label={`Número ${
                  type === TYPE_OF_PAYMENTS.contract
                    ? `de ${Company.contractConjugation.regular.singular.main}`
                    : 'del cupón'
                }`}
                InputProps={{
                  endAdornment: <QuestionMark onClick={onHelpClick} />,
                  inputProps: {
                    type: type === TYPE_OF_PAYMENTS.coupon ? 'text' : 'number',
                    inputMode:
                      type === TYPE_OF_PAYMENTS.coupon ? 'text' : 'numeric'
                  }
                }}
                required={true}
                fullWidth
              />
            </Grid>
          )}

          {type === TYPE_OF_PAYMENTS.contract && (
            <Grid item xs={12} sm={4} className={classes.inputContainer}>
              <ComboBoxInput
                id="QueryPayment_input_contractNumber"
                className={classes.input}
                value={number}
                textInputClass={classes.input}
                name="contract"
                inputValue={String(number)}
                options={contractOptions}
                onInputChange={contractInputChangeHandler}
                getOptionLabel={option => String(option.id)}
                TextInputProps={{
                  autoFocus: true,
                  disabled: loading,
                  label: `Número ${
                    type === TYPE_OF_PAYMENTS.contract
                      ? `de ${Company.contractConjugation.regular.singular.main}`
                      : 'del cupón'
                  }`,
                  InputProps: {
                    endAdornment: <QuestionMark onClick={onHelpClick} />,
                    type: type === TYPE_OF_PAYMENTS.coupon ? 'text' : 'number',
                    inputMode:
                      type === TYPE_OF_PAYMENTS.coupon ? 'text' : 'numeric'
                  },
                  required: true,
                  fullWidth: true
                }}
                InputPropsClasses={{
                  root: classes.input
                }}
                renderOption={option => {
                  return (
                    <>
                      <div className={classes.optionItemContainer}>
                        {option.id}
                        <span className={classes.optionItemAlias}>
                          {option.label}
                        </span>
                      </div>
                    </>
                  );
                }}
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={4}
            className={clsx([classes.recaptchaContainer])}
            ref={inputRef}
          >
            <Recaptcha
              captchaRef={recaptchaRef}
              referenceEl={inputRef}
              heightScale={0.65}
              locale={'es'}
              sitekey={config.recaptcha.siteKey}
              onResolved={onCaptchaResolved}
              onExpired={onCaptchaExpired}
              onLoaded={onCaptchaLoaded}
            />
          </Grid>
          <Grid item xs={12} className={classes.addButtonContainer}>
            <BaseButton
              id="QueryPayment_button_submit"
              className={classes.addButton}
              onClick={createLoadingCard}
              disabled={!canSubmit}
              fullWidth={isMobileSize}
              color="primary"
              variant="outlined"
            >
              Agregar
            </BaseButton>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  optionItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  optionItemAlias: {
    color: '#B8B8B8',
    fontSize: 12
  },
  addButtonContainer: {
    textAlign: 'end'
  },
  inputContainer: {
    maxHeight: 50,
    margin: theme.spacing(3.75, 0, 2, 0),
    padding: 0,
    '&:first-child': {
      paddingRight: 14
    },
    '&:nth-child(2)': {
      paddingRight: 14
    },
    '&:nth-child(3)': {
      paddingLeft: 9
    },
    [theme.breakpoints.down('xs')]: {
      '&:first-child': {
        margin: 0,
        padding: 0
      },
      '&:nth-child(2)': {
        margin: [[22, 0, 0, 0]],
        padding: 0
      }
    }
  },
  input: {
    margin: 0,
    maxHeight: 50
  },
  recaptchaContainer: {
    maxHeight: 50,
    marginTop: 10
  },
  formContainer: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4.5, 2, 0, 2)
    }
  },
  addButton: {
    fontSize: 12,
    fontWeight: 500,
    minHeight: 40,
    height: 40,
    [theme.breakpoints.down('xs')]: {
      minHeight: 50,
      marginTop: 62
    }
  },
  autocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      paddingRight: '0'
    }
  }
}));

export default IndividualLoadGDC;
