import React from 'react';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NoResultsStateIcon from '../CustomIcons/NoResultsStateIcon';

const ErrorState = props => {
  const {
    messageTitle,
    subMessage,
    fullPage = false,
    type = 'error',
    className,
    titleClass,
    subMessageClass,
    iconClass
  } = props;

  const classes = useStyles();

  const containerClass = fullPage
    ? classes.container
    : classes.containerReduced;

  const iconBanner = () => {
    switch (type) {
      default:
        return (
          <NoResultsStateIcon className={clsx([classes.icon, iconClass])} />
        );
    }
  };

  return (
    <div className={clsx(containerClass, className)}>
      <div className={classes.content}>
        {iconBanner()}
        {messageTitle && (
          <Typography
            variant={'h1'}
            className={clsx(classes.message, titleClass)}
          >
            {messageTitle}
          </Typography>
        )}
        {subMessage && (
          <Typography className={clsx(classes.subMessage, subMessageClass)}>
            {subMessage}
          </Typography>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    padding: [0, theme.spacing(2.5)],
    width: '100%',
    minHeight: 'calc(100vh - 56px)',
    display: 'flex',
    marginTop: theme.spacing(3),
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vh - 64px)',
      padding: 0,
      marginTop: theme.spacing(17.5)
    }
  },
  containerReduced: {
    padding: theme.spacing(4.5, 0, 0, 0),
    width: '100%',
    display: 'flex',
    marginTop: theme.spacing(0),
    justifyContent: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingTop: theme.spacing(5)
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 400
  },
  icon: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(3)
    }
  },
  message: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
    fontSize: theme.spacing(2),
    textAlign: 'center',
    lineHeight: 1.19,
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.spacing(2.5)
    }
  },
  subMessage: {
    marginBottom: theme.spacing(3),
    fontSize: 12,
    fontWeight: 500,
    textAlign: 'center',
    lineHeight: 1.2,
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
      fontSize: 14
    }
  }
}));

export default ErrorState;
