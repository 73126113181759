import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef
} from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import * as yup from 'yup';
import { Typography } from '@material-ui/core';
import BaseButton from '../../Components/Buttons/BaseButton';
import { ChangeEmailAPI } from '../../API/UserAPI';
import ShowPassword from '../../Components/Adornments/ShowPassword';
import { UserContext } from '../../Contexts/UserContext';
import Card from '@material-ui/core/Card';
import TextInput from '../../Components/Inputs/TextInput';
import { ROUTE_NAMES } from '../../Routes/Routes';
import { AlertsDispatchContext } from '../../Contexts/AlertsContext';
import { extractErrorMessage } from '../../Utils/Errors/Errors';
import Recaptcha from '../../Components/Captcha/Recaptcha';
import ModalProgress from '../../Components/Progress/Modal/ModalProgress';
import { config } from '../../Configs';

const correctEmailSchema = yup.object({
  password: yup.string().required('Ingresa una contraseña'),
  email: yup
    .string()
    .email('Debes ingresar un correo válido')
    .required('Ingresa un correo')
});

const CorrectEmail = ({ history, location }) => {
  // * OTHER HOOKS
  const classes = useStyles();

  const currentUser = useContext(UserContext);
  const setAlert = useContext(AlertsDispatchContext);

  const { handleSubmit, register, formState, errors } = useForm({
    validationSchema: correctEmailSchema,
    mode: 'onBlur'
  });

  const [passwordShown, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const [captcha, setCaptcha] = useState(null);

  const recaptchaRef = useRef(null);
  const inputRef = useRef(null);

  const canSubmit = formState.isValid && !loading && captcha;

  const onCaptchaResolved = useCallback(
    token => {
      setCaptcha(token);
    },
    [setCaptcha]
  );

  const onCaptchaExpired = useCallback(() => {
    setCaptcha(null);
  }, [setCaptcha]);

  const onCaptchaLoaded = useCallback(() => {
    setLoadingCaptcha(false);
  }, [setLoadingCaptcha]);

  const togglePasswordShow = useCallback(() => {
    setShowPassword(show => !show);
  }, []);

  const onSubmit = useCallback(
    async values => {
      setLoading(true);
      const response = await ChangeEmailAPI({
        email: location.state.email,
        newEmail: values.email,
        password: values.password,
        'g-recaptcha-response': captcha
      });

      if (!response.success) {
        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
        setLoading(false);
        return;
      }

      history.replace(ROUTE_NAMES.confirmEmail, {
        email: values.email
      });
      setLoading(false);
    },
    [history, location, setAlert, captcha]
  );

  useEffect(() => {
    if (!location.state || currentUser) {
      history.replace('/');
      return;
    }
  }, [history, location, currentUser]);

  if (currentUser || !location.state) {
    return <Fragment></Fragment>;
  }

  return (
    <Fragment>
      <div className={classes.root}>
        {loadingCaptcha && <ModalProgress message={'Cargando'} />}
        <Card className={classes.mainCard}>
          <div className={classes.formContainer}>
            <Typography className={classes.title}>Cambiar correo</Typography>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <TextInput
                inputRef={register}
                innerRef={inputRef}
                id="email"
                name="email"
                label="Nuevo correo electrónico"
                type="email"
                error={Boolean(errors.email)}
                helperText={errors.email && errors.email.message}
                required={true}
                fullWidth
              />
              <TextInput
                inputRef={register}
                margin="none"
                className={classes.passwordField}
                id="password"
                name="password"
                label="Contraseña"
                type={passwordShown ? 'text' : 'password'}
                required={true}
                error={Boolean(errors.password)}
                helperText={errors.password && errors.password.message}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <ShowPassword
                      togglePasswordShow={togglePasswordShow}
                      passwordShown={passwordShown}
                    />
                  )
                }}
              />
              <div>
                <Recaptcha
                  captchaRef={recaptchaRef}
                  referenceEl={inputRef}
                  locale={'es'}
                  sitekey={config.recaptcha.siteKey}
                  onResolved={onCaptchaResolved}
                  onExpired={onCaptchaExpired}
                  onLoaded={onCaptchaLoaded}
                />
              </div>
              <BaseButton
                loading={loading}
                className={classes.button}
                disabled={!canSubmit}
                fullWidth
                id="change_email"
                type="submit"
                color="primary"
                variant="contained"
              >
                Cambiar
              </BaseButton>
            </form>
          </div>
        </Card>
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.cardDark,
    justifyContent: 'center',
    alignItems: 'center'
  },
  mainCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: 346,
    borderRadius: 6,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.1)',
    paddingBottom: theme.spacing(4)
  },
  formContainer: {
    maxWidth: '80%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column'
  },
  title: {
    marginTop: 46,
    marginBottom: 40,
    fontSize: 24
  },
  passwordField: {
    marginTop: theme.spacing(2)
  },
  forgotPassword: {
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    color: theme.palette.text.main,
    paddingTop: 8,
    '&:hover': {
      fontWeight: 500
    }
  },
  button: {
    marginTop: theme.spacing(4)
  },
  form: {}
}));

export default CorrectEmail;
