import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Collapse,
  Grid,
  Typography,
  Chip,
  IconButton,
  Button,
  useMediaQuery,
  Divider
} from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import WarningIcon from '../../../../Components/CustomIcons/DebtsWarningIcon';
import CalendarIcon from '../../../../Components/CustomIcons/CalendarIcon';
import EyeIcon from '../../../../Components/CustomIcons/EyeIcon';
import Pagination from '../../../../Components/Pagination/Pagination';

import { history } from '../../../../Routes/history';
import { ROUTE_NAMES } from '../../../../Routes/Routes';
import { numberWithDots } from '../../../../Utils/Format/MoneyFormat';
import { capitalizeFirstLetter } from '../../../../Utils/Format/Names';

import clsx from 'clsx';

const DetailsAccordion = ({
  concepts = {},
  type,
  id,
  selectedContract,
  initiallyExpanded = false,
  punished = false,
  value
}) => {
  const classes = useStyles();
  const theme = useTheme();

  // * STATE HOOKS
  const [expanded, setExpanded] = useState(initiallyExpanded);
  const [toDateCurrentPage, setToDateCurrentPage] = useState(1);
  const [toDatePageSize, setToDatePageSize] = useState(4);
  const [expiredCurrentPage, setExpiredCurrentPage] = useState(1);
  const [expiredPageSize, setExpiredPageSize] = useState(4);

  // * OTHER HOOKS
  const mediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  // * FUNCTIONS
  const handleClick = (e, name) => {
    if (name === 'button') {
      history.push(ROUTE_NAMES.refinancingCurrent, {
        concepts,
        selectedContract,
        productId: id,
        productType: type,
        itemsAdded: {
          type: 'current',
          productId: id,
          productType: type,
          toDate: concepts.toDateData,
          expired: concepts.expiredData,
          toDateTotal: concepts.toDateTotal,
          expiredTotal: concepts.expiredTotal
        }
      });
      e.preventDefault();
      e.stopPropagation();
    } else {
      setExpanded(!expanded);
    }
  };

  const renderToDateConcepts = conceptsArray => {
    if (!conceptsArray[0] || punished) {
      return null;
    }
    return (
      <>
        <Pagination
          numberOfItems={conceptsArray.length}
          itemsPerPage={toDatePageSize}
          setItemsPerPage={setToDatePageSize}
          currentPage={toDateCurrentPage}
          setCurrentPage={setToDateCurrentPage}
          className={classes.pagination}
        />
        {conceptsArray
          .slice(
            toDatePageSize * (toDateCurrentPage - 1),
            toDatePageSize * (toDateCurrentPage - 1) + toDatePageSize
          )
          .map((concept, idx) => {
            return <ConceptItem concept={concept} key={idx} />;
          })}
      </>
    );
  };

  const renderExpiredConcepts = conceptsArray => {
    if (!conceptsArray[0] || punished) {
      return null;
    }
    return (
      <>
        <Pagination
          numberOfItems={conceptsArray.length}
          itemsPerPage={expiredPageSize}
          setItemsPerPage={setExpiredPageSize}
          currentPage={expiredCurrentPage}
          setCurrentPage={setExpiredCurrentPage}
          className={classes.pagination}
        />
        {conceptsArray
          .slice(
            expiredPageSize * (expiredCurrentPage - 1),
            expiredPageSize * (expiredCurrentPage - 1) + expiredPageSize
          )
          .map((concept, idx) => {
            return <ConceptItem concept={concept} key={idx} />;
          })}
      </>
    );
  };

  return (
    <div className={classes.card}>
      <div
        className={clsx({
          [classes.cardHeader]: !punished,
          [classes.cardHeaderPunished]: punished,
          [classes.cardHeaderExpanded]: expanded
        })}
        name="paper"
        onClick={e => handleClick(e, 'paper')}
      >
        <Grid item container wrap="nowrap" alignItems="center">
          <Grid container item className={classes.headerContainer}>
            <div>
              <Typography className={classes.label}>Producto</Typography>
              <Typography className={classes.data}>
                {type} • {id}
              </Typography>
            </div>

            {punished && (
              <Grid container item wrap="nowrap" xs={12} sm={6} md={5}>
                <Grid
                  container
                  item
                  direction="column"
                  justify="center"
                  alignItems="center"
                >
                  <Chip
                    className={classes.statusTag}
                    color="primary"
                    label="Producto Castigado"
                  />
                </Grid>
              </Grid>
            )}
            <Divider className={classes.divider} />
            <div className={classes.subheaderContainer}>
              {mediumScreen && (
                <Button
                  className={classes.button}
                  color="primary"
                  size="small"
                  variant="text"
                  onClick={handleClick}
                  classes={{ root: classes.buttonRoot }}
                >
                  {expanded ? 'Ocultar detalle' : 'Ver detalle'}
                  <EyeIcon
                    size={16}
                    className={classes.eyeIcon}
                    fill={theme.palette.primary.main}
                  />
                </Button>
              )}
              <div>
                <Typography
                  className={clsx(classes.totalLabel, classes.alignRight)}
                >
                  Saldo total
                </Typography>
                <Typography
                  className={clsx(
                    classes.data,
                    classes.alignRight,
                    classes.total
                  )}
                >
                  {punished
                    ? numberWithDots(value)
                    : numberWithDots(
                        concepts.toDateTotal + concepts.expiredTotal
                      )}
                </Typography>
              </div>
            </div>
          </Grid>
          {!punished && !mediumScreen && (
            <Grid item>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded
                })}
                onClick={e => handleClick(e, 'paper')}
                aria-expanded={expanded}
                aria-label="Ver más"
                size="small"
              >
                <ArrowDropDown />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </div>
      {!punished && (
        <Collapse in={expanded} timeout="auto">
          {concepts.toDateData.length > 0 && (
            <Grid container item className={classes.category}>
              <Grid container item justify="space-between" wrap="nowrap">
                <Grid container item direction="column" xs={12} sm={6} md={3}>
                  <Typography className={classes.label}>
                    Conceptos actuales
                  </Typography>
                  <Typography className={classes.cardData}>
                    {concepts.toDateData[0] ? concepts.toDateData.length : 0}
                  </Typography>
                </Grid>
                <Grid container item direction="column" xs={12} sm={6} md={3}>
                  <Typography
                    className={clsx(classes.label, classes.alignRight)}
                  >
                    Mes actual
                  </Typography>
                  <Typography
                    className={clsx(classes.data, classes.alignRight)}
                  >
                    {numberWithDots(concepts.toDateTotal)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.detailHeader}>
                <Grid item xs={8}>
                  <Typography className={classes.label}>Concepto</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    className={clsx(classes.label, classes.alignCenter)}
                  >
                    Fecha de cobro
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    className={clsx(classes.label, classes.alignRight)}
                  >
                    Valor
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {renderToDateConcepts(concepts.toDateData)}

          {concepts.expiredData.length > 0 && (
            <Grid container item className={classes.category}>
              <Grid container item justify="space-between" wrap="nowrap">
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.categoryLabel}>
                    Conceptos vencidos
                  </Typography>
                  <Typography className={classes.expiredConcepts}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <WarningIcon
                        width={16}
                        height={16}
                        fill={theme.palette.color.danger}
                        className={classes.warningIcon}
                      />
                      {concepts.expiredData.length}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    className={clsx(classes.categoryLabel, classes.alignRight)}
                  >
                    Mes vencido
                  </Typography>
                  <Typography
                    className={clsx(classes.cardData, classes.alignRight)}
                  >
                    {numberWithDots(concepts.expiredTotal)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          {renderExpiredConcepts(concepts.expiredData)}
        </Collapse>
      )}
    </div>
  );
};

const ConceptItem = ({ concept }) => {
  const classes = useStyles();

  // * OTHER HOOKS
  const theme = useTheme();
  const mediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  // * GLOBAL VARS
  const isBillDateOverdue =
    (concept.days && concept.days > 0 && concept.days <= 30) || false;
  const isBillDateLateOverdue = (concept.days && concept.days > 30) || false;

  // * FUNCTIONS
  const calendarColor = () => {
    if (isBillDateOverdue) {
      return theme.palette.warning.main;
    }
    if (isBillDateLateOverdue) {
      return theme.palette.error.main;
    }
    return theme.palette.primary.main;
  };

  const billingDateComponent = () => (
    <Grid item xs={12} md={2}>
      <div
        className={clsx(classes.billingDateContainer, {
          [classes.billingDateContainerOverdue]: isBillDateOverdue,
          [classes.billingDateContainerLateOverdue]: isBillDateLateOverdue
        })}
      >
        <Typography
          className={clsx(
            classes.billingDate,
            classes.billingDateInnerContainer,
            classes.alignCenter
          )}
        >
          <CalendarIcon
            className={classes.calendarIcon}
            fill={calendarColor()}
            width={12}
            height={12}
          />
          {capitalizeFirstLetter(concept.billingDate)}
        </Typography>
      </div>
    </Grid>
  );

  return (
    <Grid
      container
      item
      wrap="nowrap"
      className={classes.concept}
      alignItems="center"
    >
      {mediumScreen && billingDateComponent()}

      <Grid item xs={12} md={8}>
        <Typography
          className={clsx(classes.conceptItemText, classes.conceptMargin)}
        >
          {`Concepto: ${concept.conceptId}-${capitalizeFirstLetter(
            concept.conceptDescription
          )}`}
        </Typography>
      </Grid>

      {!mediumScreen && billingDateComponent()}

      <Grid item xs={12} md={2}>
        <Typography
          className={clsx(
            classes.conceptItemText,
            classes.conceptValue,
            classes.alignRight
          )}
        >
          {`Valor: ${numberWithDots(concept.currentValue)}`}
        </Typography>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: theme.custom.borderRadius,
    border: `1px solid ${theme.palette.background.border}`,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  cardHeader: {
    padding: theme.spacing(3, 2, 4, 3),
    cursor: 'pointer',
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      backgroundColor: theme.palette.background.cardDark
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      padding: theme.spacing(2)
    }
  },
  cardHeaderExpanded: {
    backgroundColor: theme.palette.background.accordion
  },
  cardHeaderPunished: {
    padding: theme.spacing(2),
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      backgroundColor: theme.palette.background.cardDark
    }
  },
  headerContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  category: {
    backgroundColor: theme.palette.background.accordionLight,
    padding: theme.spacing(2, 5, 2, 2),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      padding: theme.spacing(2)
    }
  },
  concept: {
    padding: theme.spacing(2, 5, 2, 3),
    borderTop: `1px solid ${theme.palette.background.border}`,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      padding: theme.spacing(2)
    }
  },
  conceptMargin: {
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginBottom: theme.spacing(2)
    }
  },
  expiredConcepts: {
    fontSize: 12,
    fontWeight: 600
  },
  detailHeader: {
    marginTop: theme.spacing(1.5)
  },
  label: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.greyDark,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: 10
    }
  },
  categoryLabel: {
    fontSize: 10,
    fontWeight: 500,
    color: theme.palette.text.greyDark,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: 12
    }
  },
  conceptItemText: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.primary,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: 11
    }
  },
  conceptValue: {
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontWeight: 600
    }
  },
  cardData: {
    fontSize: 12,
    fontWeight: 600
  },
  data: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.2,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: 12
    }
  },
  billingDate: {
    fontSize: 10,
    textAlign: 'right',
    fontWeight: theme.typography.h1
  },
  billingDateContainer: {
    width: 110,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(0.5),
    borderRadius: 3,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginBottom: theme.spacing(1)
    }
  },
  billingDateContainerOverdue: {
    color: theme.palette.warning.main,
    backgroundColor: theme.palette.warning.light
  },
  billingDateContainerLateOverdue: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.light
  },
  billingDateInnerContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  subheaderContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      justifyContent: 'space-between',
      width: '100%'
    }
  },
  alignCenter: {
    textAlign: 'center'
  },
  alignRight: {
    textAlign: 'right'
  },
  button: {
    fontSize: 12,
    textTransform: 'unset'
  },
  buttonRoot: {
    padding: theme.spacing(1, 1, 1, 0)
  },
  statusTag: {
    fontSize: 13,
    backgroundColor: '#aa0000',
    fontWeight: 600
  },
  warningIcon: {
    marginRight: theme.spacing(0.5)
  },
  calendarIcon: {
    marginRight: theme.spacing(0.5)
  },
  eyeIcon: {
    marginLeft: theme.spacing()
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  totalLabel: {
    fontSize: 12
  },
  total: {
    fontSize: 20,
    fontWeight: 600
  },
  pagination: {
    padding: theme.spacing(0.5, 2)
  },
  divider: {
    display: 'none',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      display: 'flex',
      margin: theme.spacing(1, 0, 1.5, 0),
      width: '100%'
    }
  }
}));

export default DetailsAccordion;
