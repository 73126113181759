import React, {
  Fragment,
  useContext,
  useCallback,
  useEffect,
  useState
} from 'react';
import {
  Box,
  Grid,
  FormControlLabel,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';

import Title from '../../../../Components/Labels/Title';
import ControlledCheckbox from '../../../../Components/Controlled/ControlledCheckbox';

import { UserContext } from '../../../../Contexts/UserContext';
import {
  SetCurrentStepIndexContext,
  StepperDataContext,
  StepperDataDispatchContext,
  CurrentStepIndexContext
} from '../../../../Contexts/StepperContext';

import { numberWithDots } from '../../../../Utils/Format/MoneyFormat';
import { requestTypes } from '../../../Requests/Utils/enums';
import SelectInput from '../../../../Components/Inputs/SelectInput';

const schema = yup.object({
  terms: yup.boolean().oneOf([true], 'Este campo es requerido.')
});

const TermsStep = props => {
  const {
    formId,
    setCanSubmit,
    setGoBack,
    setCurrentStep: setVisualStepperIndex,
    setNextButtonText,
    hasFinancingServices,
    setSidebarVisible,
    selectedContract
  } = props;

  const classes = useStyles();

  // * STATE HOOKS
  const [bottomCheck, setbottomCheck] = useState(false);
  const [checked, setChecked] = useState(false);

  // * OTHER HOOKS
  const theme = useTheme();
  const isSmallScreenSize = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  // * CONTEXTS
  const currentStepIndex = useContext(CurrentStepIndexContext);
  const setCurrentStep = useContext(SetCurrentStepIndexContext);
  const setData = useContext(StepperDataDispatchContext);
  const stepperData = useContext(StepperDataContext);
  const currentUser = useContext(UserContext);

  const isChangeConditions =
    stepperData && stepperData.requestType === requestTypes.changeConditions;

  // * STATE HOOKS
  const [currentProduct, setCurrentProduct] = useState(
    !isChangeConditions && stepperData.products[0]
  );

  const handleSelectedProduct = event => {
    const productId = event.target.value;
    const newCurrentProduct = stepperData.products.filter(
      product => product.productId === productId
    )[0];
    setCurrentProduct(newCurrentProduct);
  };

  const { register, watch, getValues, control, handleSubmit } = useForm({
    validationSchema: schema,
    submitFocusError: true,
    defaultValues: {
      terms: false,
      ...stepperData
    }
  });

  const watchForm = watch(['terms']);

  const handleScroll = useCallback(
    e => {
      if (!checked) {
        setbottomCheck(
          e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop < 2
        );
      }
      setChecked(false);
    },
    [checked, setbottomCheck]
  );

  let termsConditionsData = {};

  if (isChangeConditions) {
    termsConditionsData = {
      monthlyInstallment: stepperData.monthlyInstallment,
      totalPayment: stepperData.totalCapital,
      totalCapital: stepperData.totalCapital,
      totalInterest: 0,
      interestRate: stepperData.interestRate,
      installments: stepperData.numberOfInstallments
    };
  } else {
    termsConditionsData = {
      monthlyInstallment: currentProduct.paymentPlanDetail.monthlyInstallment,
      totalPayment: currentProduct.paymentPlanDetail.totalPayment,
      totalCapital: currentProduct.paymentPlanDetail.totalCapital,
      totalInterest: currentProduct.paymentPlanDetail.totalInterest,
      interestRate: currentProduct.interestRate,
      installments: currentProduct.installments
    };
  }

  const productOptions = stepperData.products.map(product => {
    return {
      label: `${product.productType} · ${product.productId}`,
      value: product.productId
    };
  });

  const termsCompany = hasFinancingServices
    ? 'Gases del Caribe S.A. Empresa de Servicios Públicos, y/o Promigas S.A. E.S.P.'
    : 'Gases del Caribe S.A. Empresa de Servicios Públicos';

  const renderTerms = () => {
    return (
      <Box className={classes.termsContainer} onScroll={handleScroll}>
        <Grid container className={classes.termsGrid}>
          <Typography paragraph className={classes.subtitle}>
            Condiciones generales
          </Typography>
          <Typography paragraph className={classes.termsText}>
            El suscrito (a){' '}
            {`${stepperData.firstName} ${stepperData.lastName} `}
            identificado (a) como aparece al pie de mi firma, obrando en mi
            propio nombre y representación, con domicilio en la ciudad de{' '}
            {selectedContract.city} declaro que adeudo en la actualidad a{' '}
            {termsCompany}, con domicilio principal en la ciudad de
            Barranquilla, una suma total de{' '}
            {numberWithDots(termsConditionsData.totalPayment)}.
          </Typography>
          <Typography paragraph className={classes.termsText}>
            Por Capital {numberWithDots(termsConditionsData.totalCapital)}{' '}
            <br /> <br />
            Por Intereses causados{' '}
            {numberWithDots(termsConditionsData.totalInterest)}
            <br /> <br />
            Por Otros Conceptos $ {0}
          </Typography>
          <Typography paragraph className={classes.termsText}>
            De acuerdo a lo certificado por la Superintendencia Financiera de
            Colombia en su dirección electrónica www.superfinanciera.gov.co, la
            tasa máxima legal vigente para este período por conceptos de
            financiación es de {stepperData.usuryRate}% y la tasa de intereses
            moratorios máxima legal vigente es de{' '}
            {termsConditionsData.interestRate}
            %. La tasa máxima legal de financiación y de intereses moratorios se
            ajustará cada vez que éstas sean actualizadas por la
            Superintendencia Financiera de Colombia. El valor total que se
            refinancia, será pagado en {termsConditionsData.installments} cuotas
            mensuales y sucesivas determinadas, la primera cuota tendrá un valor
            aproximado de{' '}
            {numberWithDots(termsConditionsData.monthlyInstallment)}.
          </Typography>
          <Typography paragraph className={classes.termsText}>
            En cualquier momento que el suscrito desee cancelar la totalidad de
            su deuda, este deberá pagar a Gases del Caribe S.A. Empresa de
            Servicios Públicos, todos los conceptos que le adeude hasta la fecha
            efectiva de pago.
            <br />
            <br />
            Si el suscrito incumple la obligación de pago en cualquiera de los
            plazos y montos fijados en el presente acuerdo de pago, GASCARIBE
            está facultado para iniciar inmediatamente todas las acciones de
            cobro judicial que resulten procedentes por los montos adeudados.{' '}
            <br />
            <br />
            El presente documento presta mérito ejecutivo. No se necesitará
            requerimiento judicial previo o constitución en mora para reclamar
            los derechos aquí plasmados. Si cualquier disposición fuere
            considerada ilegal, inválida o inejecutable por autoridad
            competente, las demás permanecerán en pleno efecto y vigor.
            <br />
            <br /> El presente acuerdo no constituye novación y/o alguna otra
            figura que importe extinciónde las obligaciones incorporadas en
            facturas expedidas por GASCARIBE y/o pagarés emitidos por el
            suscrito a favor de GASCARIBE. El suscrito reconoce la legalidad y
            conformidad de las facturas expedidas por GASCARIBE relacionadas en
            este acuerdo, renunciando a intentar cualquier reclamación judicial
            y/o extrajudicial en virtud de las mismas.
            <br />
            <br /> Las cuotas mensuales definitivas incluirán el valor de los
            intereses calculados sobre saldo de capital, computados a la tasa
            máxima legal vigente, sobre el entendido de que el sistema pactado
            de gradiente geométrico, sujetas a modificación por la variación de
            la tasa de interés aplicable.
            <br />
            <br /> La mora en el pago de dos cuotas consecutivas permitirá
            acelerar el cobro de la obligación, teniendo a la misma como de
            plazo vencido a partir de la fecha en la cual {termsCompany}, decida
            proceder al cobro de la totalidad del saldo adeudado. Los intereses
            moratorios se cobrarán a la tasa máxima legal.
            <br />
            <br /> En aquellas circunstancias en las que se presenten
            refinanciaciones, extensiones o ampliaciones de plazo y monto del
            crédito original, la prima y la vigencia del seguro, permanecerán de
            la misma forma en la que inicialmente fueron pactadas.
            <br />
            <br /> El (los) suscrito(s), obrando en el carácter antes indicado,
            autoriza (mos) de manera irrevocable a {termsCompany}, para
            consultar, solicitar y/o reportar a las centrales de riesgo, el
            comportamiento relativo a mi (nuestro) crédito, cuantas veces se
            requiera. Así mismo, en caso de que en el futuro {termsCompany},
            efectuaren una venta de cartera o cesión de las obligaciones a mi
            (nuestro) cargo, los efectos de la presente autorización se
            extenderán al tercero cesionario, en los mismos terminos y
            condiciones.
            <br />
            <br /> Así mismo, el (los) suscrito (s) autoriza(mos) a{' '}
            {termsCompany}, para la recopilación, uso y tratamiento de los datos
            personales contenidos en este formulario, todos aquellos
            relacionados con el crédito y aquellos que se llegaren a suministrar
            o recopilar en el futuro, para las finalidades y en los términos
            descritos en la Política de Tratamiento disponible en la página
            www.gascaribe.com, las cuales declara (mos) haber leído. Así mismo,
            el(los) suscrito(s) autoriza (mos) para que se envíe al teléfono,
            celular y/o correo electrónico que tiene registrado, citaciones,
            avisos, promociones o invitaciones comerciales, en cualquier tiempo.
          </Typography>
          <Typography paragraph className={classes.termsText}>
            Por expresa instrucción de la Superintendencia de Industria y
            Comercio, se informa a la parte deudora que durante el período de
            financiación la tasa de interés no podrá ser superior a 1.5 veces el
            interés bancario corriente que certifica la Superintendencia
            Financiera de Colombia. Cuando el interés cobrado supere dicho
            límite, el acreedor perderá todos los intereses. En tales casos, el
            consumidor podrá solicitar la inmediata devolución de las sumas que
            haya cancelado por concepto de los respectivos intereses. Se
            reputarán también como intereses las sumas que el acreedor reciba
            del deudor sin contraprestación distinta al crédito otorgado, aun
            cuando las mismas se justifiquen por concepto de honorarios,
            comisiones u otros semejantes. También se incluirán dentro de los
            intereses las sumas que el deudor pague por concepto de servicios
            vinculados directamente con el crédito, tales como costos de
            administración, estudio del crédito, papelería, cuotas de
            afiliación, etc. (artículo 68 de la Ley 45 de 1990).
          </Typography>
        </Grid>
      </Box>
    );
  };

  const goNext = values => {
    setData(data => ({ ...data, ...values }));
    setCurrentStep(step => step + 1);
  };

  const onBackward = useCallback(() => {
    setData(data => ({ ...data, ...getValues() }));
    setCurrentStep(currentStep => currentStep - 1);
  }, [getValues, setCurrentStep, setData]);

  // Props setters
  useEffect(() => {
    setSidebarVisible(true);
    setNextButtonText('Continuar');
    setVisualStepperIndex(currentStepIndex);
    setCanSubmit(false);
    setGoBack({
      action: onBackward
    });
  }, [
    setGoBack,
    onBackward,
    setVisualStepperIndex,
    currentStepIndex,
    setNextButtonText,
    setCanSubmit,
    setSidebarVisible
  ]);

  useEffect(() => {
    setCanSubmit(watchForm.terms);
  }, [watchForm, setCanSubmit]);

  if (!currentUser) {
    return <Fragment></Fragment>;
  }

  //*RENDER
  return (
    <div className={classes.container}>
      <Title
        title="Términos y condiciones"
        className={classes.termsStepTitle}
      />
      <Typography paragraph={true}>
        Por favor lea con cuidado y mucha atención los términos y condiciones
        del servicio y autorizalos en caso de estar de acuerdo
      </Typography>
      {!isChangeConditions && (
        <Grid container style={{ paddingBottom: theme.spacing(0.5) }}>
          <Grid item xs={12}>
            <Typography>
              A continuación verá las condiciones generales correspondientes al
              siguiente producto:
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              id="TermsConditions_select_termsConditionsPerProduct"
              value={currentProduct.productId}
              onChange={handleSelectedProduct}
              options={productOptions}
              label="Producto"
              fullWidth
            />
          </Grid>
        </Grid>
      )}
      <form
        id={formId}
        onSubmit={handleSubmit(goNext)}
        className={classes.formContainer}
      >
        {renderTerms()}
        <FormControlLabel
          className={classes.checkboxContainer}
          control={
            <ControlledCheckbox
              name="terms"
              control={control}
              color="primary"
              inputRef={register}
              checked={checked}
              disabled={
                isSmallScreenSize ? false : !bottomCheck && !watchForm.terms
              }
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          label={
            <Typography className={classes.checkLabelText}>
              He leído y acepto los términos y condiciones del servicio
            </Typography>
          }
        />
      </form>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(4)
  },
  subtitle: {
    fontSize: 13,
    fontWeight: 600
  },
  termsStepTitle: {
    fontSize: 16,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 14
    }
  },
  termsTitle: {
    marginTop: theme.spacing(2),
    fontSize: 14,
    fontWeight: 500
  },
  termsText: {
    fontSize: 12
  },
  checkLabelText: {
    fontSize: 12
  },
  formContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginBottom: 0,
      paddingLeft: 0
    },
    marginBottom: theme.spacing(3),
    minWidth: '60%'
  },
  checkboxContainer: {
    backgroundColor: theme.palette.background.accordionLighter,
    margin: 0,
    display: 'flex',
    padding: `${theme.spacing(0.5)}px ${theme.spacing()}px`,
    borderWidth: '0 1px 1px 1px',
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    borderRadius: `0 0 ${theme.custom.borderRadius}px ${theme.custom.borderRadius}px`,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      borderWidth: 1,
      borderRadius: 0
    }
  },
  termsContainer: {
    maxHeight: 366,
    overflowY: 'auto',
    borderStyle: 'solid',
    padding: theme.spacing(2),
    borderWidth: 1,
    borderColor: theme.palette.background.border,
    borderRadius: `${theme.custom.borderRadius}px ${theme.custom.borderRadius}px 0 0`,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      maxHeight: '100%',
      overflowY: 'hidden',
      borderRadius: 0,
      borderWidth: 0,
      borderStyle: 'none',
      borderColor: 'transparent',
      padding: `${theme.spacing(2)}px 0px 0`
    }
  },
  summaryContainer: {
    marginBottom: theme.spacing(3)
  }
}));

export default TermsStep;
