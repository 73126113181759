import _isEqual from 'lodash/isEqual';

export const compareArraysOfObjectsByProp = (arrayA, arrayB, prop = 'id') => {
  if (arrayA.length !== arrayB.length) {
    return false;
  }

  const areEqual = arrayA.every(elementA => {
    const elementB = arrayB.find(
      currentElement => currentElement[prop] === elementA[prop]
    );

    if (!elementB) {
      return false;
    }

    return _isEqual(elementA, elementB);
  });

  return areEqual;
};
