// this is to acknowledge that the user indeed finish
// its session on android app
export const logoutAndroidSession = ({ isAccountDeleted }) => {
  if (window.androidInterfaceSession) {
    window.androidInterfaceSession.onCloseSession(isAccountDeleted);
  }
};

// this is to trigger the file download on
// the android app
export const downloadPdfOnAndroid = (base64, fileName) => {
  if (window.androidInterfaceDownload) {
    window.androidInterfaceDownload.downloadBase64(
      base64,
      'application/pdf',
      fileName
    );
  }
};

// this is to update token on android app
export const updateAndroidToken = (token, appToken) => {
  if (window.androidInterfaceToken) {
    window.androidInterfaceToken.onUpdate(token, appToken);
  }
};

// function to trigger back to main menu action
export const backToAndroidWelcome = () => {
  if (window.androidInterfaceMainMenu) {
    window.androidInterfaceMainMenu.backToMenu();
  }
};
