/* eslint-disable complexity */
import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import { Grow, makeStyles } from '@material-ui/core';

import SweetAlert from '../../../../Components/Alerts/SweetAlert';

import { ALERT_TYPE } from '../../../../Components/Alerts/alert_enums';
import { Company } from '../../../../Configs/general';
import ContractAssociationFormAlias from './ContractAssociationFormAlias';
import ContractAssociationFormNotifications from './ContractAssociationFormNotifications/ContractAssociationFormNotifications';
import SimpleDivider from '../../../../Components/Dividers/SimpleDivider';

const ContractAssociationFormEFG = props => {
  const {
    dialogMode,
    editMode,
    currentUser,
    contract,
    sendInvoice,
    setSendInvoice,
    isDigitalInvoice,
    setIsDigitalInvoice,
    digitalChannels,
    setDigitalChannels,
    aliasName,
    setAliasName,
    aliasIcon,
    setAliasIcon,
    loading,
    errors,
    needsPhoneValidation,
    setNeedsPhoneValidation,
    digitalInvoiceSweetAlertOnclick: handleSweetAlertClick,
    setCanSubmit = null,
    referredCode,
    setReferredCode = null,
    referredCodeEnable = false,
    allowedContractType
  } = props;

  // * OTHER HOOKS
  const classes = useStyles();

  // * REFS
  const needsValidationRef = useRef(null);

  // * GLOBAL VARS
  const digitalAlreadyActive = contract && contract.digitalInvoice;

  const showReferredInput =
    referredCodeEnable && !digitalAlreadyActive && isDigitalInvoice;

  useEffect(() => {
    if (!allowedContractType && setCanSubmit) {
      setCanSubmit(true);
    }
  }, [allowedContractType, setCanSubmit]);

  return (
    <div className={classes.root}>
      <ContractAssociationFormAlias
        dialogMode={dialogMode}
        editMode={editMode}
        contract={contract}
        aliasName={aliasName}
        setAliasName={setAliasName}
        aliasIcon={aliasIcon}
        setAliasIcon={setAliasIcon}
        loading={loading}
        errors={errors}
      />

      <SimpleDivider withoutMargin className={classes.itemDivider} />

      {allowedContractType && (
        <ContractAssociationFormNotifications
          dialogMode={dialogMode}
          editMode={editMode}
          currentUser={currentUser}
          contract={contract}
          sendInvoice={sendInvoice}
          setSendInvoice={setSendInvoice}
          isDigitalInvoice={isDigitalInvoice}
          digitalAlreadyActive={digitalAlreadyActive}
          setIsDigitalInvoice={setIsDigitalInvoice}
          digitalChannels={digitalChannels}
          setDigitalChannels={setDigitalChannels}
          needsPhoneValidation={needsPhoneValidation}
          setNeedsPhoneValidation={setNeedsPhoneValidation}
          handleSweetAlertClick={handleSweetAlertClick}
          setCanSubmit={setCanSubmit}
          showReferredInput={showReferredInput}
          referredCodeEnable={referredCodeEnable}
          setReferredCode={setReferredCode}
          referredCode={referredCode}
          showWhatsAppChannel={false}
        />
      )}

      {needsPhoneValidation && (
        <Grow in={needsPhoneValidation}>
          <div
            className={clsx(classes.sweetAlertContainer, {
              [classes.sweetAlertContainerNoDialog]: !dialogMode
            })}
            ref={needsValidationRef}
          >
            <SweetAlert
              type={ALERT_TYPE.INFO}
              message={
                currentUser.phone ? (
                  <>
                    Al {editMode ? 'editar' : 'asociar'}{' '}
                    {Company.contractConjugation.regular.singular.demonstrative}{' '}
                    te solicitaremos validar el número (+
                    <span className={classes.textBold}>
                      {currentUser.phoneCountryCode} {currentUser.phone}
                    </span>
                    ).
                  </>
                ) : (
                  <>
                    Deberás agregar un número celular antes de asociar{' '}
                    {Company.contractConjugation.regular.singular.demonstrative}
                    .
                  </>
                )
              }
            />
          </div>
        </Grow>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginBottom: theme.spacing(5)
    }
  },
  containerDialogMode: {
    paddingTop: 0
  },
  textBold: {
    fontWeight: 600
  },
  sweetAlertContainer: {
    width: '100%'
  },
  sweetAlertContainerNoDialog: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingBottom: theme.spacing(6)
    }
  },
  itemDivider: {
    marginTop: 0,
    marginBottom: 0
  }
}));

export default ContractAssociationFormEFG;
