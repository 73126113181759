import React, { useContext, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper } from '@material-ui/core/';
import Container from '@material-ui/core/Container';

import { FooterDispatchContext } from '../../../Contexts/FooterContext';
import { numberWithDots } from '../../../Utils/Format/MoneyFormat';
import SvgIcWarning from '../../../Components/CustomIcons/DebtsWarningIcon';
import clsx from 'clsx';

const MobileFooter = props => {
  // * MAP PROPS
  const {
    itemsCount = 0,
    totalAmount = 0,
    actionText = 'Continuar',
    warning,
    warningMessage = '',
    onClick,
    disabled
  } = props;
  // * CONTEXTS
  const setFooterVisible = useContext(FooterDispatchContext);

  // Clean other footers
  useLayoutEffect(() => {
    setFooterVisible(false);
    return () => {
      setFooterVisible(true);
    };
  }, [setFooterVisible]);

  const classes = useStyles();

  const onClickHandler = disabled ? undefined : onClick;

  return (
    <Paper className={classes.root} onClick={onClickHandler}>
      {warning && (
        <Container maxWidth="md" className={classes.warningContainer}>
          <SvgIcWarning width={16} height={16} />
          <Typography>{warningMessage}</Typography>
        </Container>
      )}
      <Container
        maxWidth="md"
        className={clsx(classes.mainContainer, {
          [classes.disabled]: disabled
        })}
      >
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={3} className={classes.itemsCountContainer}>
            <Typography className={classes.itemsCountText}>
              {itemsCount}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              className={classes.text}
              style={{ textAlign: 'center' }}
            >
              {actionText}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.text} style={{ textAlign: 'right' }}>
              {numberWithDots(totalAmount)}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'fixed',
    flexDirection: 'column',
    bottom: 0,
    width: '100%',
    maxHeight: 80
  },
  mainContainer: {
    height: 56,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 600
  },
  disabled: {
    backgroundColor: '#7A9CD4',
    opacity: 0.7,
    zIndex: 1
  },
  warningContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 24,
    textAlign: 'center',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.warning.light,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`
  },
  itemsCountContainer: {
    width: 24,
    maxWidth: 24,
    height: 24,
    marginRight: 'auto',
    backgroundColor: theme.palette.common.white,
    textAlign: 'center',
    paddingTop: theme.spacing(0.5)
  },
  itemsCountText: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.text.blue
  },
  text: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.secondary.contrastText
  }
}));

export default MobileFooter;
