import React, { useLayoutEffect, useRef } from 'react';
import clsx from 'clsx';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import SelectInput from '../../../../Components/Inputs/SelectInput';
import TextInput from '../../../../Components/Inputs/TextInput';

import { ICON_SELECT_OPTIONS } from '../../contract_enums';
import { Company } from '../../../../Configs/general';

const ContractAssociationFormAlias = props => {
  const {
    dialogMode,
    editMode,
    contract,
    aliasName,
    setAliasName,
    aliasIcon,
    setAliasIcon,
    loading,
    errors
  } = props;

  // * OTHER HOOKS
  const classes = useStyles();

  // * REFS
  const rootRef = useRef(null);

  useLayoutEffect(() => {
    if (rootRef.current) {
      rootRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div className={classes.root} ref={rootRef}>
      <div
        className={clsx(classes.inputContainer, {
          [classes.inputContainerDialogMode]: dialogMode
        })}
      >
        <Grid container alignItems="center" justify="space-between">
          {!dialogMode && !editMode && (
            <Grid item xs={12} md={8}>
              <Typography className={classes.text}>
                Contrato: <strong>{contract.id}</strong>
              </Typography>
            </Grid>
          )}
          {!dialogMode && (
            <Grid item xs={12} md={8}>
              <Typography className={classes.text}>
                Agrega un alias a tu{' '}
                {Company.contractConjugation.regular.singular.main}, para que
                luego puedas identificarlo más facilmente
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={dialogMode ? 12 : 4}>
            <Grid container direction="column">
              {editMode && dialogMode && (
                <Grid item className={classes.contract}>
                  <Typography className={classes.contractText}>
                    <span style={{ fontWeight: 500 }}>
                      {Company.contractConjugation.capitalized.singular.main}:{' '}
                    </span>{' '}
                    {contract.id}
                  </Typography>
                </Grid>
              )}
              <Grid item style={{ maxWidth: '100%' }}>
                <div
                  className={clsx(classes.itemAliasContainer, {
                    [classes.aliasContainerDialogMode]: dialogMode
                  })}
                >
                  <SelectInput
                    className={classes.itemInputIcon}
                    value={aliasIcon}
                    onChange={e => setAliasIcon(e.target.value)}
                    disabled={loading}
                    label={'Icono'}
                    options={ICON_SELECT_OPTIONS}
                  />
                  <TextInput
                    id={'alias_input'}
                    className={clsx(classes.itemInputAlias, {
                      [classes.inputAliasDialogMode]: dialogMode
                    })}
                    value={aliasName}
                    error={Boolean(errors.aliasName)}
                    helperText={errors.aliasName}
                    onChange={e => setAliasName(e.target.value)}
                    disabled={loading}
                    label={`Alias ${Company.contractConjugation.regular.singular.contraction}`}
                    required={true}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  text: {
    fontSize: 14,
    fontWeight: 500,
    paddingBottom: theme.spacing(),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingBottom: theme.spacing(2),
      paddingRight: 0,
      paddingLeft: 0
    }
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'nowrap'
    }
  },
  inputContainerDialogMode: {
    paddingTop: 0
  },
  itemAliasContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      justifyContent: 'center'
    }
  },
  aliasContainerDialogMode: {
    justifyContent: 'center'
  },
  itemInputIcon: {
    minWidth: 67
  },
  itemInputAlias: {
    minWidth: 200,
    marginLeft: theme.spacing(2)
  },
  inputAliasDialogMode: {
    minWidth: 250,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      minWidth: 150
    }
  },
  contract: {
    display: 'flex',
    justifyContent: 'center'
  },
  contractText: {
    fontSize: 14,
    width: 345,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      maxWidth: 295
    }
  }
}));

export default ContractAssociationFormAlias;
