import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
  useContext
} from 'react';

import uuid from 'uuid/v4';
import { Typography, CardMedia, Card, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  UserContext,
  UserDispatchContext
} from '../../../Contexts/UserContext';
import {
  ContractsContext,
  ContractsRefreshContext
} from '../../../Contexts/ContractsContext';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';

import { history } from '../../../Routes/history';
import { ROUTE_NAMES } from '../../../Routes/Routes';

import BaseButton from '../../../Components/Buttons/BaseButton';
import SimpleDivider from '../../../Components/Dividers/SimpleDivider';
import ContractItem from './ContractItem/ContractItem';
import ContentMobile from '../MyContractsMobile/MyContractsMobile';
import AlertFullView from '../../../Components/Alerts/AlertFullView';
import NoContractIcon from '../../../Components/CustomIcons/NoContractIcon';
import RemoveContract from './RemoveContract/RemoveContract';
import EditContract from './EditContract/EditContract';
import { DeleteContract } from '../../../API/Contracts/ContractsAPI';
import {
  extractErrorMessage,
  redirectOnAuthFailure
} from '../../../Utils/Errors/Errors';
import ConfirmationDialog from '../../../Components/Dialogs/ConfirmationDialog';
import { Company } from '../../../Configs/general';
import { isUserLoggedInWithPassword } from '../../../Enums/users';
import { logoutUser } from '../../../Utils/User/Actions';

const myContractsBanner = require('../../../Assets/images/my_contracts_banner.png');

const MyContractsGDG = () => {
  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const contracts = useContext(ContractsContext);
  const setAlert = useContext(AlertsDispatchContext);
  const refreshContracts = useContext(ContractsRefreshContext);
  const classes = useStyles();

  useEffect(() => {
    if (!currentUser) {
      history.replace('/');
      return;
    }
  }, [currentUser]);

  const [contractSel, setContractSel] = useState(false);
  const [removeDialog, setRemoveDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);

  const openRemoveDialog = useCallback(contract => {
    setContractSel(contract);
    setRemoveDialog(true);
  }, []);

  const openConfirmationDialog = useCallback(contract => {
    setContractSel(contract);
    setConfirmationDialog(true);
  }, []);

  const removeContract = useCallback(
    async closed => {
      if (closed) {
        return;
      }
      const response = await DeleteContract(currentUser.token, contractSel.id);

      if (response.success) {
        setAlert({
          type: 'info',
          message: `Se ha desasociado ${Company.contractConjugation.regular.singular.article} "${contractSel.alias}"`
        });
        refreshContracts();
        return { unmounting: false, closeDialog: true };
      }

      if (
        redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
      ) {
        return { unmounting: true, closeDialog: false };
      }

      const error = extractErrorMessage(response);
      if (error.key === 'base') {
        setAlert({
          type: 'error',
          message: error.message
        });
      }
      return { unmounting: false, closeDialog: true };
    },
    [currentUser, setCurrentUser, refreshContracts, setAlert, contractSel]
  );

  const openEditDialog = useCallback(contract => {
    setContractSel(contract);
    setEditDialog(true);
  }, []);

  if (!contracts) {
    return <Fragment></Fragment>;
  }

  return (
    <Fragment>
      {confirmationDialog && (
        <ConfirmationDialog
          open={confirmationDialog}
          title={`Desasociar ${Company.contractConjugation.regular.singular.main}`}
          content={
            <Typography className={classes.confirmationText}>
              A continuación se desasociará{' '}
              {Company.contractConjugation.regular.singular.demonstrative}{' '}
              ¿Estás seguro?
            </Typography>
          }
          setDialog={setConfirmationDialog}
          requestCallback={removeContract}
        />
      )}
      {contracts.length > 0 ? (
        <Container className={classes.root}>
          {removeDialog && (
            <RemoveContract
              open={removeDialog}
              setDialog={setRemoveDialog}
              contract={contractSel}
              setAlert={setAlert}
            />
          )}
          {editDialog && (
            <EditContract
              open={editDialog}
              setDialog={setEditDialog}
              contract={contractSel}
              setAlert={setAlert}
            />
          )}
          <Card className={classes.card}>
            <CardMedia
              alt={`mis_${Company.contractConjugation.regular.plural.main}_banner`}
              image={myContractsBanner}
              className={classes.bannerImage}
            >
              <span className={classes.bannerText}>
                Mis {Company.contractConjugation.regular.plural.associate}
              </span>
            </CardMedia>
            <div className={classes.cardContent}>
              {contracts.map(contr => (
                <ContractItem
                  key={`${uuid()}`}
                  contract={contr}
                  removeContract={
                    isUserLoggedInWithPassword(currentUser)
                      ? openRemoveDialog
                      : openConfirmationDialog
                  }
                  openEditDialog={openEditDialog}
                />
              ))}
            </div>
            <SimpleDivider withoutMargin={true} />
            <div className={classes.buttonContainer}>
              <BaseButton
                color="primary"
                variant="outlined"
                onClick={() => history.push(ROUTE_NAMES.associate)}
              >
                Asociar {Company.contractConjugation.regular.singular.main}
              </BaseButton>
            </div>
          </Card>
          <ContentMobile
            contracts={contracts}
            openRemoveDialog={openRemoveDialog}
            openEditDialog={openEditDialog}
            openConfirmationDialog={openConfirmationDialog}
            hasPassword={currentUser.hasPassword}
          />
        </Container>
      ) : (
        <AlertFullView
          icon={NoContractIcon}
          title={`No tienes ${Company.contractConjugation.regular.plural.associate}`}
          subtitle="Asocia una y aprovecha al máximo los servicios que tenemos para tí"
          redirectText={`Asocia tu ${Company.contractConjugation.regular.singular.main}`}
          redirectTo={ROUTE_NAMES.associate}
        />
      )}
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    maxWidth: 1000,
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'none',
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(6),
      paddingTop: 0
    }
  },
  card: {
    display: 'none',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      maxHeight: '72vh',
      flexDirection: 'column',
      borderRadius: theme.custom.borderRadius,
      boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.1)'
    }
  },
  bannerImage: {
    minHeight: 130,
    maxHeight: 130,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  bannerText: {
    color: theme.palette.common.white,
    fontSize: 20,
    fontWeight: 600,
    paddingLeft: theme.spacing(5)
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: theme.spacing(3)
  },
  confirmationText: {
    fontSize: 14,
    color: theme.palette.text.primary,
    maxWidth: '100%'
  }
}));

export default MyContractsGDG;
