import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Divider, Grid, Typography, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';

import Pagination from '../../../../Components/Pagination/Pagination';
import BaseButton from '../../../../Components/Buttons/BaseButton';
import AddItemButton from '../AddItemButton';
import CalendarIcon from '../../../../Components/CustomIcons/CalendarIcon';
import CovidIcon from '../../../../Components/CustomIcons/CovidIcon';

import { numberWithDots } from '../../../../Utils/Format/MoneyFormat';
import { capitalizeFirstLetter } from '../../../../Utils/Format/Names';
import { ProductType } from '../../myDebts_enums';
import DebtPartialPaymentDialog from '../../DebtPartialPayment/DebtPartialPaymentDialog';
import { DeferredPartialPaymentAPI } from '../../../../API/Deferred/DeferredAPI';

const DeferredPagination = ({
  deferreds,
  addDeferreds,
  removeDeferreds,
  addedDeferreds,
  productAlreadyAdded,
  isActionDisabled
}) => {
  const classes = useStyles();

  // * STATE HOOKS
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(2);

  const theme = useTheme();

  const smallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  const mediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  const renderPages = () => {
    return deferreds
      .slice(
        pageSize * (currentPage - 1),
        pageSize * (currentPage - 1) + pageSize
      )
      .map((deferred, idx, array) => {
        return (
          <div key={deferred.deferredId}>
            <DeferredConcepts
              item={deferred}
              addedDeferreds={addedDeferreds}
              addDeferreds={addDeferreds}
              removeDeferreds={removeDeferreds}
              productAlreadyAdded={productAlreadyAdded}
              smallScreen={smallScreen}
              mediumScreen={mediumScreen}
              isActionDisabled={isActionDisabled}
            />
            {array.length > 1 && idx < array.length - 1 && (
              <Divider className={classes.divider} />
            )}
          </div>
        );
      });
  };

  return (
    <div className={classes.category}>
      <Pagination
        itemsPerPage={pageSize}
        numberOfItems={deferreds.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setItemsPerPage={setPageSize}
        className={classes.pagination}
      />
      {renderPages()}
    </div>
  );
};

const DeferredConcepts = ({
  item,
  addedDeferreds,
  addDeferreds,
  removeDeferreds,
  productAlreadyAdded,
  mediumScreen,
  isActionDisabled
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [paymentDialog, setPaymentDialog] = useState({
    open: false,
    data: null
  });

  const openDialogHandler = (deferredId, maxValue) => {
    setPaymentDialog(previousValue => ({
      ...previousValue,
      open: !previousValue.open,
      data: { deferredId, maxValue }
    }));
  };

  const closeDialogHandler = () => {
    setPaymentDialog(previousValue => ({
      ...previousValue,
      open: !previousValue.open,
      data: null
    }));
  };

  const canPartiallyPay =
    !isActionDisabled && item.productTypeId === ProductType.FinancingServices;

  const alreadyAdded = addedDeferreds.some(
    d => d.deferredId === item.deferredId
  );

  const buttonDisabled = productAlreadyAdded || isActionDisabled;

  const handleAddRemoveItem = () => {
    return alreadyAdded
      ? removeDeferreds('deferred', item.deferredId)
      : addDeferreds('deferred', item.deferredId);
  };

  return (
    <Grid container item spacing={1}>
      {paymentDialog.open && (
        <DebtPartialPaymentDialog
          open={paymentDialog.open}
          onClose={closeDialogHandler}
          requestCallback={DeferredPartialPaymentAPI}
          paymentData={paymentDialog.data}
          partialValueStepTitle="Abono a diferido"
          description={
            <Typography>
              Ingresa el valor que deseas abonar al concepto{' '}
              <strong>
                {item.conceptId} -{' '}
                {capitalizeFirstLetter(item.conceptDescription)}.
              </strong>{' '}
              Recuerda que este abono se aplicará sobre tu deuda financiada de
              Brilla (servicios financieros).
            </Typography>
          }
        />
      )}
      <Grid
        container
        item
        justify="space-between"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={9}>
          <Grid container alignItems="center" wrap="nowrap">
            <Typography className={classes.data}>
              {item.reliefCOVID19 && (
                <span>
                  <CovidIcon style={{ marginRight: theme.spacing(0.5) }} />
                </span>
              )}
              Concepto: {item.conceptId} -{' '}
              {capitalizeFirstLetter(item.conceptDescription)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} style={{ textAlign: 'right' }}>
          {mediumScreen ? (
            <AddItemButton
              isAdding={alreadyAdded}
              onClick={handleAddRemoveItem}
              disabled={buttonDisabled}
              small
            />
          ) : (
            <Typography className={clsx(classes.data, classes.alignRight)}>
              {`Valor: ${numberWithDots(item.pendingValue)}`}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction={mediumScreen ? 'column-reverse' : 'column'}
      >
        <Grid container item justify="space-between">
          <Grid item xs={12} sm={6} md={3} className={classes.marginBottom}>
            <Typography className={classes.label}>
              Valor cuota: {numberWithDots(item.installmentValue)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.marginBottom}>
            <Typography
              className={clsx(classes.label, {
                [classes.alignCenter]: !mediumScreen
              })}
            >
              Tasa interés anual: {item.deferredInterestRate}%
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.marginBottom}>
            <Typography
              className={clsx(classes.label, {
                [classes.alignCenter]: !mediumScreen
              })}
            >
              Cuotas facturadas: {item.billedInstallments}/
              {item.totalInstallments}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.marginBottom}>
            <Typography
              className={clsx(classes.label, {
                [classes.alignRight]: !mediumScreen
              })}
            >
              Saldo inicial: {numberWithDots(item.initialValue)}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          style={{ marginTop: theme.spacing(1) }}
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <div className={classes.startDateContainer}>
              <CalendarIcon className={classes.icon} />
              <Typography className={classes.startDateLabel}>
                Fecha de inicio: {item.beginDate.replaceAll('-', '/')}
              </Typography>
            </div>
          </Grid>
          <Grid item>
            {item.isPayable && !item.onClaim && canPartiallyPay && (
              <BaseButton
                size="small"
                color="primary"
                variant="text"
                disabled={buttonDisabled}
                className={classes.addButton}
                onClick={() => {
                  openDialogHandler(item.deferredId, item.pendingValue);
                }}
              >
                Abonar
              </BaseButton>
            )}
            {item.isPayable && !item.onClaim && (
              <BaseButton
                size="small"
                color="primary"
                variant="text"
                disabled={buttonDisabled}
                className={classes.addButton}
                onClick={() => {
                  alreadyAdded
                    ? removeDeferreds('deferred', item.deferredId)
                    : addDeferreds('deferred', item.deferredId);
                }}
              >
                {alreadyAdded ? 'Quitar' : 'Agregar'}
              </BaseButton>
            )}
            {item.onClaim && (
              <Typography
                className={clsx(classes.alignCenter, classes.disabledText)}
              >
                ¡En Reclamo!
              </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      {mediumScreen && (
        <Grid item container justify="flex-end">
          <Grid item>
            <Typography className={clsx(classes.data, classes.alignRight)}>
              {`Valor: ${numberWithDots(item.pendingValue)}`}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  category: {
    padding: theme.spacing(1, 2, 2, 2),
    backgroundColor: theme.palette.background.accordionLighter
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  label: {
    fontSize: 11,
    fontWeight: 500,
    color: theme.palette.text.greyDark
  },
  data: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.2,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 13
    }
  },
  alignCenter: {
    textAlign: 'center'
  },
  alignRight: {
    textAlign: 'right'
  },
  marginBottom: {
    marginBottom: theme.spacing(1)
  },
  addButton: {
    fontSize: 12,
    padding: theme.spacing(),
    transition: theme.transitions.create('color', {
      duration: '100ms'
    }),
    textDecoration: 'underline'
  },
  disabledText: {
    color: theme.palette.text.greyDark
  },
  pagination: {
    margin: theme.spacing(0.5, 0, 1)
  },
  startDateLabel: {
    fontSize: 12,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: 11
    }
  },
  startDateContainer: {
    display: 'inline-flex',
    alignItems: 'center'
  }
}));

export default DeferredPagination;
