import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import SweetAlert from '../../../../Components/Alerts/SweetAlert';
import BaseButton from '../../../../Components/Buttons/BaseButton';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { ROUTE_NAMES } from '../../../../Routes/Routes';
import { history } from '../../../../Routes/history';
import DeferredSideBarReasonsDialog from './DeferredSidebarReasonsDialog';
import { numberWithDots } from '../../../../Utils/Format/MoneyFormat';

const DeferredSideBarBottom = props => {
  const {
    total,
    isPayment,
    hasErrors,
    totalItems,
    debtsData,
    selectedContract,
    addedDeferreds,
    showButtons,
    removeCovidDeferreds,
    goToPayments,
    conditions,
    disableRefinanceButton,
    disablePaymentButton,
    goToPayDeferreds
  } = props;

  const classes = useStyles();

  // * STATE HOOKS
  const [openReasons, setOpenReasons] = useState(false);

  // * FUNCTIONS
  const handleOpenReasons = () => {
    setOpenReasons(true);
  };

  const handleCloseReasons = () => {
    setOpenReasons(false);
  };

  const getDisallowedActions = () => {
    if (disableRefinanceButton && disablePaymentButton) {
      return 'refinanciar o pagar';
    } else if (disableRefinanceButton) {
      return 'refinanciar';
    }
    return 'pagar';
  };

  const onRefinanceClick = () => {
    history.push(ROUTE_NAMES.refinancingDeferred, {
      addedDeferreds,
      selectedContract,
      fromDeferredDebt: true
    });
  };

  return (
    <div className={classes.bottomContainer}>
      <DeferredSideBarReasonsDialog
        open={openReasons}
        handleClose={handleCloseReasons}
        title={`Razones que te impiden ${getDisallowedActions()}`}
        conditions={conditions}
        removeCovidDeferreds={removeCovidDeferreds}
        goToPayments={goToPayments}
        billingDate={debtsData && debtsData.billingDate}
      />

      {total > 0 && (
        <div>
          <Typography className={clsx(classes.label, classes.alignRight)}>
            {isPayment ? 'Valor a pagar' : 'Total seleccionado'}
          </Typography>
          <Typography
            className={clsx(
              classes.data,
              classes.alignRight,
              classes.totalFinancing
            )}
          >
            {hasErrors ? '-' : numberWithDots(total)}
          </Typography>
        </div>
      )}
      {!isPayment &&
        totalItems > 0 &&
        (disableRefinanceButton || disablePaymentButton) && (
          <SweetAlert
            type="warning"
            classes={{
              root: classes.sweetAlertRoot,
              message: classes.sweetAlertMessage,
              icon: classes.sweetAlertIcon
            }}
            message={
              <>
                No puedes {getDisallowedActions()} productos o conceptos <br />
                <span
                  onClick={handleOpenReasons}
                  className={classes.sweetAlertActionText}
                >
                  Clic aquí para ver las razones
                </span>
              </>
            }
          />
        )}
      {showButtons && (
        <div>
          <Grid
            container
            justify="space-between"
            className={classes.buttonsContainer}
          >
            <Grid item xs={6} className={classes.buttonContainer}>
              <BaseButton
                className={classes.button}
                size="small"
                variant="outlined"
                disabled={disableRefinanceButton}
                onClick={onRefinanceClick}
              >
                Refinanciar
              </BaseButton>
            </Grid>
            <Grid item xs={6} className={classes.buttonContainer}>
              <BaseButton
                className={classes.button}
                size="small"
                disabled={disablePaymentButton}
                onClick={goToPayDeferreds}
              >
                Pagar
              </BaseButton>
            </Grid>
          </Grid>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 11,
    fontWeight: 500,
    color: theme.palette.text.greyDark
  },
  data: {
    fontSize: 13,
    fontWeight: 500,
    lineHeight: 1.2,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 12
    }
  },
  alignRight: {
    textAlign: 'right'
  },
  button: {
    width: '100%'
  },
  buttonContainer: {
    width: '100%',
    maxWidth: 100,
    maxHeight: 37,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      maxHeight: 40,
      maxWidth: 136
    }
  },
  bottomContainer: {
    borderTop: `1px solid ${theme.palette.background.border}`,
    padding: theme.spacing(2),
    '& > *': {
      marginBottom: theme.spacing(1),
      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  totalFinancing: {
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 20
    }
  },
  sweetAlertRoot: {
    padding: theme.spacing(0, 1)
  },
  sweetAlertMessage: {
    fontSize: 12,
    lineHeight: 1.2,
    color: theme.palette.text.default,
    fontWeight: 400
  },
  sweetAlertIcon: {
    width: 25
  },
  sweetAlertActionText: {
    fontWeight: 500,
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}));

export default DeferredSideBarBottom;
