import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { SlideUpTransition } from '../../Transitions/Transitions';
import BaseDialog from '../BaseDialog';
import { InvoiceHelpType } from '../../../Enums/invoices';

const CouponHelp = require('../../../Assets/images/invoice_coupon_help.png');
const ContractHelp = require('../../../Assets/images/invoice_contract_help.png');
const InvoiceNumberHelp = require('../../../Assets/images/invoice_number_period_help.jpg');
const SubrogationContractHelp = require('../../../Assets/images/subrogation-contract-help.png');

const HelpImage = {
  [InvoiceHelpType.Contract]: ContractHelp,
  [InvoiceHelpType.Coupon]: CouponHelp,
  [InvoiceHelpType.Subrogation]: SubrogationContractHelp,
  [InvoiceHelpType.Invoice]: InvoiceNumberHelp
};

function InvoiceHelpDialogGDC(props) {
  const { open = true, onClose, type, title = 'Título' } = props;
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [image, setImage] = useState('');

  const onImageLoaded = useCallback(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
  }, [type]);

  useEffect(() => {
    setImage(HelpImage[type] || ContractHelp);
  }, [type]);

  const renderContent = () => {
    return (
      <Fragment>
        <img
          className={classes.image}
          src={image}
          alt={`${type} help`}
          onLoad={onImageLoaded}
        />
      </Fragment>
    );
  };

  const isMobileSize = isWidthDown('xs', props.width);
  return (
    <BaseDialog
      open={open}
      loading={loading}
      handleClose={onClose}
      title={title}
      actions={() => null}
      content={renderContent}
      fullScreen={isMobileSize}
      TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
    />
  );
}

const useStyles = makeStyles(theme => ({
  image: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 536,
      height: 236
    }
  }
}));

export default withWidth()(InvoiceHelpDialogGDC);
