/* eslint-disable complexity */
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';

import { numberWithDots } from '../../../../Utils/Format/MoneyFormat';
import { ProductType } from '../../myDebts_enums';

const DeferredDebtCardEFG = ({ debts }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  /** Global vars */
  const hasDeferredDebt = debts.deferredValue > 0;

  //*FUNCTIONS

  const getTotal = type => {
    if (debts.deferreds) {
      const filteredData = debts.deferreds.filter(item =>
        type.includes(item.productTypeId)
      );

      return filteredData.reduce((total, item) => total + item.pendingValue, 0);
    }
  };

  const headerProductTypes = () => {
    const headerSubtitle = [];

    const areThereGasProducts = debts.deferreds.some(
      item => item.productTypeId === ProductType.Gas
    );

    const areThereFinancialProducts = debts.deferreds.some(item =>
      [
        ProductType.FinancingServices,
        ProductType.FinancingServicesPromigas
      ].includes(item.productTypeId)
    );

    if (areThereGasProducts) {
      headerSubtitle.push('Gas');
    }

    if (areThereFinancialProducts) {
      headerSubtitle.push('Servicios Financieros');
    }

    return headerSubtitle.join(' · ');
  };

  const showProductValueDescription = type => {
    return debts.deferreds.some(item => type.includes(item.productTypeId));
  };

  const cardHeader = (
    <Grid item>
      <Typography className={classes.monthContainer}>
        {`Productos: ${headerProductTypes()}`}
      </Typography>
    </Grid>
  );

  return (
    <div className={classes.card}>
      <div className={classes.gradientBorder} />
      <div className={classes.innerContainer}>
        <Grid container direction="column" spacing={1}>
          {hasDeferredDebt && isMediumScreen && cardHeader}
          <Grid
            item
            container
            justify="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid xs={7} container item>
              <Typography className={classes.title}>
                Deuda financiada
              </Typography>
            </Grid>
            <Grid
              item
              xs={5}
              container
              justify="flex-end"
              zeroMinWidth
              alignItems="center"
            >
              {hasDeferredDebt && !isMediumScreen && cardHeader}

              {!hasDeferredDebt && (
                <Grid item>
                  <Typography className={classes.statusTag}>
                    {isSmallScreen
                      ? 'Sin deudas'
                      : 'No tienes deudas financiadas'}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item>
            <Typography className={classes.description}>
              Valor de las financiaciones pendientes que aún no han sido
              facturadas.
            </Typography>
          </Grid>
          <Grid item container>
            <Grid
              item
              container
              alignItems="flex-start"
              justify="space-between"
            >
              <Grid item xs={6} sm={6} md={2} className={classes.detailsItem}>
                {hasDeferredDebt && (
                  <>
                    <Typography className={classes.label}>
                      Próximo pago
                    </Typography>
                    {debts.deferredNextPayment && (
                      <>
                        <Typography className={classes.data}>
                          {`${numberWithDots(debts.deferredNextPayment.value)}`}
                          {debts.deferredNextPayment.date &&
                            ` (${debts.deferredNextPayment.date})`}
                        </Typography>
                      </>
                    )}
                  </>
                )}
              </Grid>
              {hasDeferredDebt &&
                showProductValueDescription([
                  ProductType.FinancingServices,
                  ProductType.FinancingServicesPromigas
                ]) && (
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={5}
                    className={clsx(classes.detailsItem, {
                      [classes.alignRight]: isMediumScreen
                    })}
                  >
                    <Typography className={classes.label}>
                      Saldo por facturar Servicios financieros
                    </Typography>
                    <Typography className={classes.data}>
                      {numberWithDots(
                        getTotal([
                          ProductType.FinancingServices,
                          ProductType.FinancingServicesPromigas
                        ])
                      )}
                    </Typography>
                  </Grid>
                )}
              {hasDeferredDebt &&
                showProductValueDescription([ProductType.Gas]) && (
                  <Grid
                    item
                    xs={6}
                    md={3}
                    className={clsx(classes.detailsItem, {
                      [classes.alignRight]:
                        isMediumScreen &&
                        !showProductValueDescription([
                          ProductType.FinancingServices,
                          ProductType.FinancingServicesPromigas
                        ])
                    })}
                  >
                    <Typography className={clsx(classes.label)}>
                      Saldo por facturar Gas
                    </Typography>
                    <Typography className={clsx(classes.data)}>
                      {numberWithDots(getTotal([ProductType.Gas]))}
                    </Typography>
                  </Grid>
                )}
              <Grid item xs={12} md={2} className={classes.detailsItem}>
                <Typography className={clsx(classes.label, classes.totalLabel)}>
                  Saldo total
                </Typography>
                <Typography className={clsx(classes.data, classes.total)}>
                  {numberWithDots(debts.deferredValue)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: theme.spacing(1, 0, 0, 1),
    display: 'flex',
    overflow: 'hidden',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'column',
      borderRadius: theme.spacing(1, 1, 0, 0)
    }
  },
  gradientBorder: {
    width: theme.spacing(),
    background:
      'transparent linear-gradient(180deg, #F49C9C 0%, #E05252 100%) 0% 0%',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      height: theme.spacing(),
      width: '100%',
      background:
        'transparent linear-gradient(240deg, #F49C9C 0%, #E05252 100%) 0% 0%'
    }
  },
  innerContainer: {
    borderColor: theme.palette.background.border,
    borderStyle: 'solid',
    borderLeft: 'none',
    borderWidth: '1px 1px 1px 0',
    borderRadius: theme.spacing(0, 1, 1, 0),
    padding: theme.spacing(3, 3, 2, 2),
    flexGrow: 1,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      borderWidth: '0 1px 1px 1px',
      borderRadius: theme.spacing(0, 0, 1, 1),
      padding: theme.spacing(2, 1.5)
    }
  },
  detailsItem: {
    margin: theme.spacing(0.5, 0, 0.5, 0)
  },
  title: {
    fontWeight: 600,
    fontSize: 14
  },
  monthContainer: {
    color: theme.palette.text.greyDark,
    fontSize: 12
  },
  month: {
    textTransform: 'capitalize'
  },
  description: {
    lineHeight: 1.3,
    fontSize: 13,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 10
    }
  },
  label: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.greyDark,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 11
    }
  },
  data: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.2,
    textTransform: 'capitalize',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 12
    }
  },
  alignRight: {
    textAlign: 'right'
  },
  total: {
    fontSize: 20,
    textAlign: 'right',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 16
    }
  },
  totalLabel: {
    textAlign: 'right',
    fontSize: 12
  },
  button: {
    fontSize: 12,
    textTransform: 'unset',
    fontWeight: 600
  },
  statusTag: {
    fontSize: 14,
    color: '#18CC48',
    fontWeight: 600,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 11
    }
  }
}));

export default DeferredDebtCardEFG;
