import React, { useEffect, useMemo } from 'react';

import DeferredSideBarBottom from './DeferredSideBarBottom';

import { CONTRACT_TYPES } from '../../../Contracts/contract_enums';

import _isEmpty from 'lodash/isEmpty';
import DeferredSidebarPaymentPlan from './DeferredSidebarPaymentPlan';
import DeferredSidebarItems from './DeferredSidebarItems';
import Sidebar from '../Sidebar/Sidebar';

const parseAddedDeferreds = addedDeferreds => {
  const result = addedDeferreds.reduce(
    (
      obj,
      { productId, productTypeDescription, pendingValue, financialId, ...rest }
    ) => {
      const lastProduct = obj[productId] || {};
      const lastFinancials = lastProduct.financials || {};
      const lastFinancing = lastFinancials[financialId] || {};

      return {
        ...obj,
        [productId]: {
          productId,
          productTypeDescription,
          financingValue: (lastProduct.financingValue || 0) + pendingValue,
          financials: {
            ...lastFinancials,
            [financialId]: {
              financialId,
              total: (lastFinancing.total || 0) + pendingValue,
              deferreds: [
                ...(lastFinancing.deferreds || []),
                {
                  productId,
                  productTypeDescription,
                  pendingValue,
                  financialId,
                  ...rest
                }
              ]
            }
          }
        }
      };
    },
    {}
  );

  const resultArray = Object.values(result);

  const total = resultArray.reduce((prev, current) => {
    return prev + current.financingValue;
  }, 0);

  return [resultArray, total];
};

// eslint-disable-next-line complexity
const DeferredSidebar = ({
  removeDeferreds,
  removeCovidDeferreds,
  selectedContract,
  debtsData,
  requestsData,
  paymentPlan,
  allowDeletion,
  showButtons,
  forPayment,
  errors,
  addedDeferreds = [],
  title = 'Resumen',
  isMobileSize = false,
  openDialog = false,
  onCloseDialog = () => {},
  isDeferredSummary,
  setFooterHasWarning,
  setTotalDeferred,
  goToPayDeferreds,
  goToPayments
}) => {
  const [products, total] = useMemo(() => parseAddedDeferreds(addedDeferreds), [
    addedDeferreds
  ]);

  const productId = (products[0] || {}).productId;

  const existCovidDeferreds = addedDeferreds.some(d => d.reliefCOVID19);

  const conditions = {
    noItems: addedDeferreds.length === 0,
    currentDebt: debtsData && debtsData.currentValue > 0,
    noBillingPeriod: debtsData && !debtsData.billingDate,
    dateNearBillingPeriod:
      debtsData &&
      debtsData.daysUntilBilling >= 0 &&
      debtsData.daysUntilBilling < 2,
    contractType:
      selectedContract && selectedContract.type !== CONTRACT_TYPES.Residential,
    existCovidDeferreds,
    onGoingChangeConditionsRequests:
      requestsData &&
      requestsData[productId] &&
      requestsData[productId].onGoing > 0,
    moreThanTwoChangeConditions:
      requestsData &&
      requestsData[productId] &&
      requestsData[productId].total >= 2
  };

  const disableRefinanceButton =
    conditions.noItems ||
    conditions.currentDebt ||
    conditions.contractType ||
    conditions.existCovidDeferreds ||
    conditions.onGoingChangeConditionsRequests ||
    conditions.moreThanTwoChangeConditions;

  const disablePaymentButton =
    conditions.noItems ||
    conditions.noBillingPeriod ||
    conditions.dateNearBillingPeriod;

  const currentDebtValue =
    conditions.currentDebt && addedDeferreds.length > 0
      ? debtsData.currentValue
      : 0;

  const paymentPlanInitialIntallment = paymentPlan
    ? paymentPlan.initialInstallment
    : 0;

  const sidebarBottomTotal =
    total + currentDebtValue - paymentPlanInitialIntallment;

  useEffect(() => {
    if (isDeferredSummary) {
      setFooterHasWarning(
        !conditions.noItems &&
          Boolean(disablePaymentButton || disableRefinanceButton)
      );
    }
  }, [
    isDeferredSummary,
    setFooterHasWarning,
    conditions.noItems,
    disableRefinanceButton,
    disablePaymentButton
  ]);

  useEffect(() => {
    if (isDeferredSummary && addedDeferreds.length > 0) {
      setTotalDeferred(total);
    }
  }, [isDeferredSummary, setTotalDeferred, total, addedDeferreds.length]);

  const itemsContent = (
    <DeferredSidebarItems
      isEmpty={addedDeferreds.length === 0}
      products={products}
      allowDeletion={allowDeletion}
      removeDeferreds={removeDeferreds}
      currentDebt={conditions.currentDebt}
      debtsData={debtsData}
    />
  );

  const detailsContent = (
    <DeferredSidebarPaymentPlan
      title={title}
      total={total}
      paymentPlan={paymentPlan}
      errors={errors}
      products={products}
      isMobileSize={isMobileSize}
    />
  );

  const actionsContent = (
    <DeferredSideBarBottom
      total={sidebarBottomTotal}
      isPayment={forPayment}
      hasErrors={Boolean(errors)}
      totalItems={addedDeferreds.length}
      debtsData={debtsData}
      selectedContract={selectedContract}
      addedDeferreds={addedDeferreds}
      showButtons={showButtons}
      removeCovidDeferreds={removeCovidDeferreds}
      goToPayments={goToPayments}
      conditions={conditions}
      disableRefinanceButton={disableRefinanceButton}
      disablePaymentButton={disablePaymentButton}
      goToPayDeferreds={goToPayDeferreds}
    />
  );

  return (
    <Sidebar
      title={title}
      isMobileSize={isMobileSize}
      itemsContent={itemsContent}
      detailsContent={paymentPlan && !_isEmpty(paymentPlan) && detailsContent}
      actionsContent={actionsContent}
      openDialog={openDialog}
      onCloseDialog={onCloseDialog}
    />
  );
};

export default DeferredSidebar;
