import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import clsx from 'clsx';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Breadcrumbs,
  Container,
  Link,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';

import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import DeferredProducts from '../Components/Deferred/DeferredProducts';
import ModalProgress from '../../../Components/Progress/Modal/ModalProgress';
import DeferredSidebar from '../Components/Deferred/DeferredSidebar';
import SweetAlert from '../../../Components/Alerts/SweetAlert';
import MobileFooter from '../Components/MobileFooter';

import {
  ContractSelectedContext,
  ContractsContext
} from '../../../Contexts/ContractsContext';
import {
  UserContext,
  UserDispatchContext
} from '../../../Contexts/UserContext';
import {
  redirectOnAuthFailure,
  extractErrorMessage
} from '../../../Utils/Errors/Errors';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';
import { ROUTE_NAMES } from '../../../Routes/Routes';
import { history } from '../../../Routes/history';
import {
  GetContractDebtsAPI,
  GetChangeConditionsRequestsAPI
} from '../../../API/Debts/DebtsAPI';
import { GetInvoicesAPI } from '../../../API/Invoices/InvoicesAPI';

import deferredDebtBanner from '../../../Assets/images/deferred-debt-banner.png';
import ContractDeferredDebtCard from '../Cards/ContractDeferredDebtCard';
import { ALERT_TYPE } from '../../../Components/Alerts/alert_enums';
import { Company } from '../../../Configs/general';
import { getIsBillingPeriod } from '../helpers/helpers';
import DeferredPaymentOptionsDialog from '../Components/Deferred/DeferredPaymentOptionsDialog';
import { numberWithDots } from '../../../Utils/Format/MoneyFormat';
import { logoutUser } from '../../../Utils/User/Actions';

const getProductsData = deferreds => {
  if (!deferreds) {
    return [];
  }

  const result = deferreds.reduce(
    // eslint-disable-next-line complexity
    (
      obj,
      { productId, pendingValue, financialId, onClaim, isPayable, ...rest }
    ) => {
      const lastProduct = obj[productId] || {};
      const lastFinancials = lastProduct.financials || {};
      const lastFinancing = lastFinancials[financialId] || {};

      return {
        ...obj,
        [productId]: {
          productId,
          total: (lastProduct.total || 0) + pendingValue,
          count: (lastProduct.count || 0) + 1,
          countValids:
            onClaim || !isPayable
              ? lastProduct.countValids || 0
              : (lastProduct.countValids || 0) + 1,
          financials: {
            ...lastFinancials,
            [financialId]: {
              financialId,
              total: (lastFinancing.total || 0) + pendingValue,
              count: (lastFinancing.count || 0) + 1,
              countValids:
                onClaim || !isPayable
                  ? lastFinancing.countValids || 0
                  : (lastFinancing.countValids || 0) + 1,
              deferreds: [
                ...(lastFinancing.deferreds || []),
                {
                  productId,
                  pendingValue,
                  financialId,
                  onClaim,
                  isPayable,
                  ...rest
                }
              ]
            }
          }
        }
      };
    },
    {}
  );

  const products = Object.values(result);

  return products;
};

const DeferredDebtSummaryGDC = () => {
  const classes = useStyles();

  // * CONTEXTS
  const contracts = useContext(ContractsContext);
  const selectedContract = useContext(ContractSelectedContext);
  const currentUser = useContext(UserContext);
  const setAlert = useContext(AlertsDispatchContext);
  const setCurrentUser = useContext(UserDispatchContext);

  const authToken = _get(currentUser, 'token');

  // * STATE HOOKS
  const [debtsData, setDebtsData] = useState(null);
  const [invoicesData, setInvoicesData] = useState(null);
  const [requestsData, setRequestsData] = useState(null);
  const [addedDeferreds, setAddedDeferreds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openSidebarDialog, setOpenSidebarDialog] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [footerHasWarning, setFooterHasWarning] = useState(false);
  const [totalDeferred, setTotalDeferred] = useState(0);
  const [openPaymentOptions, setOpenPaymentOptions] = useState(false);

  const handleOpenPaymentOptions = () => {
    setOpenPaymentOptions(open => !open);
  };

  const theme = useTheme();
  const isMobileSize = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  // * GLOBAL VARS
  const isBillingPeriod =
    debtsData &&
    getIsBillingPeriod(debtsData.daysUntilBilling, debtsData.billingDate);
  const hasCurrentDebt = debtsData && debtsData.currentValue > 0;

  // * OTHER HOOKS
  useEffect(() => {
    if (!authToken) {
      history.replace('/');
      return;
    }

    if (!contracts || !contracts.length) {
      history.replace(ROUTE_NAMES.contracts);
      return;
    }

    setAddedDeferreds([]);
    setProductsData([]);

    const fetchData = async () => {
      setLoading(true);

      const [
        debtsResponse,
        invoicesResponse,
        requestsResponse
      ] = await Promise.all([
        GetContractDebtsAPI(authToken, selectedContract.id),
        GetInvoicesAPI(selectedContract.id, null, authToken),
        GetChangeConditionsRequestsAPI(authToken, selectedContract.id)
      ]);
      if (requestsResponse.success) {
        const responseData = requestsResponse.data.data;
        setRequestsData(responseData);
      } else {
        setRequestsData(null);
      }

      if (invoicesResponse.success) {
        const responseData = invoicesResponse.data.data;
        setInvoicesData(responseData);
      } else {
        setInvoicesData(null);
      }

      if (debtsResponse.success) {
        const responseData = debtsResponse.data.data;
        setDebtsData(responseData);
        setLoading(false);

        if (responseData.deferredValue === 0) {
          history.replace(ROUTE_NAMES.debtStatus);
        }

        setProductsData(getProductsData(responseData.deferreds));
      } else {
        setDebtsData(null);
        setLoading(false);

        if (
          redirectOnAuthFailure(debtsResponse, '/', () =>
            logoutUser(setCurrentUser)
          )
        ) {
          return;
        }

        setAlert({
          type: 'error',
          message: extractErrorMessage(debtsResponse).message
        });
      }
    };

    fetchData();
  }, [authToken, contracts, selectedContract, setAlert, setCurrentUser]);

  // * CALLBACKS
  const addDeferreds = useCallback(
    (option, id) => {
      switch (option) {
        case 'deferred': {
          const deferred = debtsData.deferreds.find(d => d.deferredId === id);

          setAddedDeferreds(previous => [...previous, deferred]);
          break;
        }
        case 'financial': {
          const financialDeferreds = debtsData.deferreds.filter(
            d => d.financialId === id && !d.onClaim && d.isPayable
          );

          setAddedDeferreds(previous => [
            // eslint-disable-next-line max-nested-callbacks
            ...previous.filter(d => d.financialId !== id),
            ...financialDeferreds
          ]);
          break;
        }
        case 'product': {
          const deferreds = debtsData.deferreds.filter(
            d => d.productId === id && !d.onClaim && d.isPayable
          );

          setAddedDeferreds([...deferreds]);
          break;
        }
        default:
          return;
      }
    },
    [debtsData]
  );

  // * FUNCTIONS
  const removeDeferreds = (option, id) => {
    let alertMessage = 'Concepto removido';
    switch (option) {
      case 'deferred': {
        setAddedDeferreds(previous =>
          previous.filter(d => d.deferredId !== id)
        );
        break;
      }
      case 'financial': {
        alertMessage = 'Financiación removida';
        setAddedDeferreds(previous =>
          previous.filter(d => d.financialId !== id)
        );
        break;
      }
      case 'product': {
        alertMessage = 'Producto removido';
        setAddedDeferreds(previous => previous.filter(d => d.productId !== id));
        break;
      }
      default:
        return;
    }

    setAlert({
      toast: true,
      type: ALERT_TYPE.SUCCESS,
      message: alertMessage
    });
  };

  const removeCovidDeferreds = () => {
    setAddedDeferreds(previous => previous.filter(d => !d.reliefCOVID19));
  };

  const openSidebarDialogHandler = () => {
    setOpenSidebarDialog(true);
  };

  const closeSidebarDialogHandler = () => {
    setOpenSidebarDialog(false);
  };

  const goToPayDeferreds = () => {
    history.push(ROUTE_NAMES.deferredPayment, {
      addedDeferreds,
      selectedContract,
      debtsData,
      invoicesData
    });
  };

  const handlePaymentButton = () => {
    if (hasCurrentDebt) {
      handleOpenPaymentOptions();
      return;
    }

    goToPayDeferreds();
  };

  const goToPayments = useCallback(
    event => {
      event.preventDefault();

      if (invoicesData && invoicesData.length > 0) {
        history.push(ROUTE_NAMES.payments, {
          type: 'contract',
          value: selectedContract.id,
          currentStep: 1,
          disabledNext: false,
          payment: [{ type: 'contract', detail: invoicesData[0] }]
        });

        return;
      }

      history.push(ROUTE_NAMES.payments, {
        type: 'contract',
        value: selectedContract.id,
        currentStep: 0
      });
    },
    [invoicesData, selectedContract.id]
  );

  if (loading) {
    return <ModalProgress message="Consultando tu deuda financiada" />;
  }

  if (!debtsData || !selectedContract) {
    return <Fragment />;
  }

  return (
    <Fragment>
      {debtsData && debtsData.currentValue > 0 && (
        <DeferredPaymentOptionsDialog
          open={openPaymentOptions}
          handleClose={handleOpenPaymentOptions}
          onClick={goToPayDeferreds}
          currentDebtValue={debtsData.currentValue}
          deferredSelectedValues={totalDeferred}
          total={totalDeferred + debtsData.currentValue}
          goToPayments={goToPayments}
        />
      )}
      <div className={classes.root}>
        <Container className={classes.container}>
          <Breadcrumbs
            aria-label="breadcrumb"
            className={classes.breadcrumbs}
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              color="textPrimary"
              component={RouterLink}
              to="/"
              underline="always"
            >
              Inicio{' '}
            </Link>
            <Link
              color="textPrimary"
              component={RouterLink}
              to="/contracts/debt"
              underline="always"
            >
              Mis deudas{' '}
            </Link>
            <Typography color="primary" className={classes.breadcrumbs}>
              Deuda financiada
            </Typography>
          </Breadcrumbs>

          {isBillingPeriod && (
            <SweetAlert
              iconSize={20}
              type="warning"
              classes={{
                root: classes.sweetAlert,
                message: classes.sweetAlertText,
                messageContainer: classes.sweetAlertMessageContainer,
                icon: classes.sweetAlertIcon
              }}
              message={
                <>
                  <b>
                    Tu {Company.contractConjugation.regular.singular.main} se
                    encuentra en periodo de facturación, por lo que solo puedes
                    realizar las siguientes acciones:
                    <br />
                  </b>
                  - Descargar comprobante del estado de cuenta <br />
                  - Ver detalle de tu deuda financiada <br />
                </>
              }
            />
          )}

          {!isBillingPeriod && hasCurrentDebt && (
            <SweetAlert
              iconSize={20}
              type="warning"
              classes={{
                root: classes.sweetAlert,
                message: classes.sweetAlertText,
                messageContainer: clsx(classes.sweetAlertMessageContainer, {
                  [classes.sweetAlertPaddingWithAction]: hasCurrentDebt
                }),
                icon: classes.sweetAlertIcon
              }}
              message={
                <>
                  <b>
                    No puedes refinanciar
                    <br />
                  </b>
                  Para hacerlo necesitas estar al día con tu deuda actual
                  <br />
                </>
              }
              hasAction
              actionText="Ver deuda actual"
              actionOnClick={() => history.push(ROUTE_NAMES.currentDebtSummary)}
            />
          )}

          {hasCurrentDebt && (
            <SweetAlert
              classes={{
                root: classes.sweetAlert,
                message: classes.sweetAlertText
              }}
              noIcon={isMobileSize}
              message={
                <Fragment>
                  Tienes una deuda actual de{' '}
                  <strong>{numberWithDots(debtsData.currentValue)}</strong> que
                  se añadirá junto a los ítems que selecciones para pagar tu
                  deuda financiada.
                </Fragment>
              }
            />
          )}

          <ContractDeferredDebtCard
            contract={selectedContract}
            data={debtsData}
            title="Deuda financiada"
            banner={deferredDebtBanner}
          />

          <Typography className={classes.title}>
            Listado de productos
          </Typography>

          {debtsData &&
            productsData.map(
              ({ productId, total, countValids, financials }, index) => {
                return (
                  <DeferredProducts
                    key={index}
                    type={debtsData.products[productId]}
                    productId={productId}
                    total={total}
                    countValids={countValids}
                    financials={Object.values(financials)}
                    addedDeferreds={addedDeferreds}
                    addDeferreds={addDeferreds}
                    removeDeferreds={removeDeferreds}
                    isActionDisabled={isBillingPeriod}
                  />
                );
              }
            )}
        </Container>

        <DeferredSidebar
          addedDeferreds={addedDeferreds}
          removeDeferreds={removeDeferreds}
          removeCovidDeferreds={removeCovidDeferreds}
          selectedContract={selectedContract}
          debtsData={debtsData}
          requestsData={requestsData}
          title="Items seleccionados"
          allowDeletion
          showButtons
          isMobileSize={isMobileSize}
          openDialog={openSidebarDialog}
          onCloseDialog={closeSidebarDialogHandler}
          setFooterHasWarning={setFooterHasWarning}
          setTotalDeferred={setTotalDeferred}
          isDeferredSummary
          goToPayments={goToPayments}
          goToPayDeferreds={handlePaymentButton}
        />

        {isMobileSize && (
          <MobileFooter
            itemsCount={addedDeferreds.length}
            totalAmount={totalDeferred}
            warningMessage="Hay conceptos que deben ser revisados"
            warning={footerHasWarning}
            disabled={_isEmpty(addedDeferreds)}
            onClick={openSidebarDialogHandler}
          />
        )}
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1,
    overflowY: 'hidden',
    overflowX: 'hidden'
  },
  container: {
    maxWidth: 816,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginTop: theme.spacing(3)
    },
    '& > *': {
      marginBottom: theme.spacing(3)
    },
    '& > *:last-child': {
      marginBottom: 0,
      [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        marginBottom: theme.spacing(20)
      }
    }
  },
  breadcrumbs: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium,
    '& > *': { fontSize: 'inherit' }
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: 14
    }
  },
  label: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.greyDark
  },
  data: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.2,
    textTransform: 'capitalize',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 13
    }
  },
  sweetAlertText: {
    fontSize: 12
  },
  sweetAlertMessageContainer: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  sweetAlertPaddingWithAction: {
    paddingBottom: 0
  },
  sweetAlertIcon: {
    marginTop: theme.spacing(0.5)
  }
}));

export default DeferredDebtSummaryGDC;
