import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.common.paper
  },
  appBar: {
    backgroundColor: 'transparent',
    color: theme.palette.color.primary,
    boxShadow: 'none',
    borderBottom: '1px solid #D8D8D8'
  },
  tab: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.common.black,
    textTransform: 'none'
  },
  indicator: {
    backgroundColor: theme.palette.color.primary
  },
  selected: {
    color: theme.palette.color.primary
  }
}));

const SimpleTabs = props => {
  const { children, tabProps, currentTabIndex, handleChange } = props;
  const classes = useStyles();

  const currentChild = children[currentTabIndex];

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={currentTabIndex}
          onChange={handleChange}
          aria-label="simple tabs example"
          classes={{
            indicator: classes.indicator
          }}
        >
          {tabProps.map(tabProp => {
            return (
              <Tab
                className={classes.tab}
                classes={{
                  root: classes.root,
                  selected: classes.selected
                }}
                key={tabProp.id}
                label={tabProp.label}
                {...a11yProps(tabProp.id)}
              />
            );
          })}
        </Tabs>
      </AppBar>
      <TabPanel value={currentTabIndex} index={currentTabIndex}>
        {currentChild}
      </TabPanel>
    </div>
  );
};

export default SimpleTabs;
