import React from 'react';

const DownloadIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 22.858}
      height={props.height || 20}
      viewBox="0 0 22.858 20"
      {...props}
    >
      <path
        d="M21.1,39.031a6.086,6.086,0,0,0-2.146-.8.714.714,0,0,1-.563-.549A7.376,7.376,0,0,0,16.2,33.817,7.035,7.035,0,0,0,11.429,32a6.613,6.613,0,0,0-6.086,3.787.717.717,0,0,1-.521.4,6.915,6.915,0,0,0-3.054,1.281A4.552,4.552,0,0,0,0,41.179c0,3.009,2.5,5.107,6.072,5.107h4.643V40.592a.728.728,0,0,1,.68-.733.714.714,0,0,1,.749.713v5.714h5.536c3.243,0,5.179-1.529,5.179-4.089A3.6,3.6,0,0,0,21.1,39.031ZM10.714,49.564,9.076,47.928a.714.714,0,1,0-1.009,1.011l2.857,2.852a.714.714,0,0,0,1.009,0l2.857-2.852a.714.714,0,1,0-1.009-1.011l-1.638,1.636V46.286H10.714Z"
        transform="translate(0 -32)"
        fill={props.fill || '#104eb2'}
      />
    </svg>
  );
};

export default DownloadIcon;
