import React, { Fragment, useContext, useState } from 'react';
import _get from 'lodash/get';
import { Container, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { UserContext } from '../../../Contexts/UserContext';

import Title from '../../../Components/Labels/Title';
import PaymentTypeSwitch from '../../../Components/Switches/BaseSwitch';
import LoginDialog from '../../Login/LoginDialog';

import IndividualPayment from './IndividualPayment/IndividualPayment';
import MultiplePayment from './MultiplePayments/MultiplePayment/MultiplePayment';

import {
  INDIVIDUAL_LOAD_INITIAL_STATE,
  MULTIPLE_LOAD_INITIAL_STATE,
  TABS_INITIAL_STATE
} from '../payment_enums';
import { useEffect } from 'react';

const PaymentOptions = props => {
  const {
    currentStepIndex,
    currentStep,
    number,
    setNumber,
    type,
    setType,
    payments,
    handleSetPayment,
    setDisabledNext,
    cardIndividualLoad,
    setCardIndividualLoad,
    cardMultipleLoad,
    setCardMultipleLoad,
    totalIndividualCards,
    totalMultipleLoadCards,
    totalCurrentCards,
    checkedMultiplePayments,
    setCheckedMultiplePayments,
    currentTabIndex,
    setCurrentTabIndex,
    isMultiplePaymentsParam,
    canPayPartially,
    setPaymentValueOption,
    setMethodSelected
  } = props;

  const classes = useStyles();

  const [openLoginDialog, setOpenLoginDialog] = useState(false);

  const currentUser = useContext(UserContext);
  const authToken = _get(currentUser, 'token');

  const handleToggle = () => {
    if (!currentUser) {
      setOpenLoginDialog(true);
      return;
    }
    setCardIndividualLoad(INDIVIDUAL_LOAD_INITIAL_STATE);
    setCardMultipleLoad(MULTIPLE_LOAD_INITIAL_STATE);
    handleSetPayment([]);
    setCurrentTabIndex(TABS_INITIAL_STATE);
    setCheckedMultiplePayments(prev => !prev);
  };

  useEffect(() => {
    if (isMultiplePaymentsParam && !currentUser) {
      setOpenLoginDialog(true);
    }
  }, [currentUser, isMultiplePaymentsParam, openLoginDialog]);

  const renderCurrentStep = () => {
    if (currentStepIndex === 0) {
      if (checkedMultiplePayments) {
        return (
          <MultiplePayment
            currentStep={currentStep}
            setDisabledNext={setDisabledNext}
            payments={payments}
            setPayments={handleSetPayment}
            number={number}
            setNumber={setNumber}
            type={type}
            setType={setType}
            cardIndividualLoad={cardIndividualLoad}
            setCardIndividualLoad={setCardIndividualLoad}
            cardMultipleLoad={cardMultipleLoad}
            setCardMultipleLoad={setCardMultipleLoad}
            totalCurrentCards={totalCurrentCards}
            totalIndividualCards={totalIndividualCards}
            totalMultipleLoadCards={totalMultipleLoadCards}
            currentTabIndex={currentTabIndex}
            setCurrentTabIndex={setCurrentTabIndex}
            authToken={authToken}
          />
        );
      }
      return (
        <IndividualPayment
          currentStep={currentStep}
          setDisabledNext={setDisabledNext}
          payment={payments[0] || null}
          setPayment={handleSetPayment}
          number={number}
          setNumber={setNumber}
          type={type}
          setType={setType}
          canPayPartially={canPayPartially}
          setPaymentValueOption={setPaymentValueOption}
          setMethodSelected={setMethodSelected}
          currentUser={currentUser}
        />
      );
    }
  };

  return (
    <Fragment>
      <LoginDialog open={openLoginDialog} setOpen={setOpenLoginDialog} />
      <Container maxWidth="md" className={`${classes.root}`}>
        <Box className={classes.mainInfoContainer}>
          <Title text={currentStep.label} className={classes.title} />
          <PaymentTypeSwitch
            checked={checkedMultiplePayments}
            handleToggle={handleToggle}
            label="Pagos múltiples"
            containerClass={classes.switchContainer}
            controlLabelClass={classes.switchControlLabel}
          />
        </Box>
      </Container>
      {renderCurrentStep()}
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 2)
    }
  },
  mainInfoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'normal',
      alignItems: 'normal'
    }
  },
  title: {
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  },
  switchContainer: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 16
    }
  },
  switchControlLabel: {
    [theme.breakpoints.up('sm')]: {
      margin: 0
    }
  }
}));

export default PaymentOptions;
