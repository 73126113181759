import React, { Fragment, useState, useContext, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import _get from 'lodash/get';

import ContractValidationStep from './Steps/Practiseguro/ContractValidationStep';
import SelectionPlanStep from './Steps/Practiseguro/InsurancePlanStep';
import PersonalDataStep from './Steps/Practiseguro/PersonalDataStep';
import SummaryStep from './Steps/Practiseguro/SummaryStep';

import Stepper from '../../../../Components/Stepper/Stepper';
import FooterStepper from '../../../../Components/Stepper/FooterStepper';

import {
  FORM_ID,
  InsuranceAcquisitionStep,
  InsurancePlan,
  INSURANCE_ACQUISITION_STEPS
} from '../../../../Enums/insurances';

import { StepperProvider, Step } from '../../../../Contexts/StepperContext';
import { AlertsDispatchContext } from '../../../../Contexts/AlertsContext';
import { UserContext } from '../../../../Contexts/UserContext';

import { ROUTE_NAMES } from '../../../../Routes/Routes';
import { history } from '../../../../Routes/history';

import { GetRelationshipOptionsAPI } from '../../../../API/BrillaInsurances/BrillaInsurancesAPI';

const AcquireInsurance = props => {
  const { location: locationParams = { state: null } } = props;

  const classes = useStyles();
  const params = locationParams.state;

  // * CONTEXTS
  const setAlert = useContext(AlertsDispatchContext);
  const currentUser = useContext(UserContext);
  const authToken = _get(currentUser, 'token');

  // * STATE HOOKS
  const [loading, setLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [currentStep, setCurrentStep] = useState(
    InsuranceAcquisitionStep.Contract
  );
  const [planId, setPlanId] = useState(
    (params && params.planId) || InsurancePlan.Silver
  );
  const [nextButtonText, setNextButtonText] = useState('Siguiente');
  const [goBack, setGoBack] = useState({});
  const [relationshipOptions, setRelationshipOptions] = useState([]);

  useEffect(() => {
    if (!params) {
      history.replace(ROUTE_NAMES.insurancesHome);
      return;
    }

    if (!authToken) {
      history.replace('/');
      return;
    }
  }, [params, authToken]);

  useEffect(() => {
    const fetchOptions = async () => {
      const response = await GetRelationshipOptionsAPI(currentUser.token);
      if (response.valid) {
        const relationships = response.relationships.map(option => {
          return { label: option.nombre, value: option };
        });
        setRelationshipOptions(relationships);
      }
    };
    if (params) {
      fetchOptions();
    }
  }, [currentUser, params]);

  if (!params) {
    return <Fragment></Fragment>;
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          <Breadcrumbs
            aria-label="breadcrumb"
            className={classes.breadcrumbs}
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              color="primary"
              component={RouterLink}
              to="/"
              underline="always"
            >
              Inicio{' '}
            </Link>
            <Link
              color="primary"
              component={RouterLink}
              to="/seguros"
              underline="always"
            >
              Seguros{' '}
            </Link>
            <Link
              color="primary"
              component={RouterLink}
              to={{
                pathname: `/seguros/${params.product.id}`,
                state: { product: params.product }
              }}
              underline="always"
            >
              {params.product.name}{' '}
            </Link>
            <Typography color="textPrimary">Adquirir seguro</Typography>
          </Breadcrumbs>
        </Container>
        <Container maxWidth="md" className={classes.stepperContainer}>
          <Stepper
            steps={INSURANCE_ACQUISITION_STEPS}
            currentStep={currentStep}
          />
        </Container>
        <Container maxWidth="md">
          <StepperProvider>
            <Step>
              <ContractValidationStep
                formId={FORM_ID}
                setCanSubmit={setCanSubmit}
                setGoBack={setGoBack}
                setCurrentStep={setCurrentStep}
                setNextButtonText={setNextButtonText}
                loading={loading}
                setLoading={setLoading}
                setAlert={setAlert}
              />
            </Step>
            <Step>
              <SelectionPlanStep
                formId={FORM_ID}
                planId={planId}
                setPlanId={setPlanId}
                setCanSubmit={setCanSubmit}
                setGoBack={setGoBack}
                setCurrentStep={setCurrentStep}
                setNextButtonText={setNextButtonText}
                loading={loading}
                setLoading={setLoading}
                setAlert={setAlert}
                plans={params.plans}
                relationshipOptions={relationshipOptions}
              />
            </Step>
            <Step>
              <PersonalDataStep
                formId={FORM_ID}
                setCanSubmit={setCanSubmit}
                setGoBack={setGoBack}
                setCurrentStep={setCurrentStep}
                setNextButtonText={setNextButtonText}
                loading={loading}
                planId={planId}
                setLoading={setLoading}
                setAlert={setAlert}
              />
            </Step>
            <Step>
              <SummaryStep
                formId={FORM_ID}
                setCanSubmit={setCanSubmit}
                setGoBack={setGoBack}
                setCurrentStep={setCurrentStep}
                setNextButtonText={setNextButtonText}
                loading={loading}
                setLoading={setLoading}
                setAlert={setAlert}
                planId={planId}
              />
            </Step>
          </StepperProvider>
        </Container>
        <FooterStepper
          targetForm={FORM_ID}
          disabledNext={!canSubmit}
          goBack={goBack}
          nextButtonText={nextButtonText}
        />
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.custom.footerHeight.stepper
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2)
  },
  currentStep: {
    marginBottom: theme.custom.footerHeight.stepper
  },
  breadcrumbs: {
    fontSize: 15,
    fontWeight: theme.typography.fontWeightMedium,
    '& *': { fontSize: 'inherit' }
  },
  stepperContainer: {
    // * Mobile
    [theme.breakpoints.up('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.up('xm')]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5)
    },
    // * Web
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing()
    }
  },
  container: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginTop: theme.spacing(3)
    }
  }
}));

export default AcquireInsurance;
