import React, {
  Fragment,
  useState,
  useCallback,
  useContext,
  useEffect
} from 'react';
import { FormControlLabel } from '@material-ui/core';
import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useForm, ErrorMessage } from 'react-hook-form';
import _get from 'lodash/get';
import * as yup from 'yup';

import Title from '../../../../Components/Labels/Title';
import ControlledCheckbox from '../../../../Components/Controlled/ControlledCheckbox';
import ModalProgress from '../../../../Components/Progress/Modal/ModalProgress';

import {
  SetCurrentStepIndexContext,
  StepperDataContext,
  StepperDataDispatchContext,
  CurrentStepIndexContext
} from '../../../../Contexts/StepperContext';
import {
  UserContext,
  UserDispatchContext
} from '../../../../Contexts/UserContext';
import { AlertsDispatchContext } from '../../../../Contexts/AlertsContext';

import { GetDischargeDeferredAPI } from '../../../../API/Deferred/DeferredAPI';
import {
  extractErrorMessage,
  redirectOnAuthFailure
} from '../../../../Utils/Errors/Errors';
import { logoutUser } from '../../../../Utils/User/Actions';

const schema = yup.object({
  terms: yup.boolean().oneOf([true], 'Este campo es requerido.')
});

// * API
const TermConditionsStep = props => {
  const {
    formId,
    addedDeferreds,
    setCanSubmit,
    setGoBack,
    setCurrentStep: setVisualStepperIndex,
    setNextButtonText,
    content = <Fragment />,
    invoicesData = null,
    currentDebtValue = 0
  } = props;

  // * STATE HOOKS
  const [checked] = useState(false);
  const [loading, setLoading] = useState(false);

  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const setAlert = useContext(AlertsDispatchContext);
  const currentStepIndex = useContext(CurrentStepIndexContext);
  const setCurrentStep = useContext(SetCurrentStepIndexContext);
  const setData = useContext(StepperDataDispatchContext);
  const stepperData = useContext(StepperDataContext);

  const authToken = _get(currentUser, 'token');

  // * OTHER HOOKS
  const classes = useStyles();

  const { getValues, handleSubmit, control, errors, watch } = useForm({
    validationSchema: schema,
    defaultValues: {
      terms: false,
      ...stepperData
    }
  });

  const watchForm = watch(['terms']);

  // * FUNCTIONS
  const onForward = useCallback(
    async values => {
      if (stepperData.couponId) {
        setCurrentStep(step => step + 1);
      } else {
        setLoading(true);
        const deferredIds = addedDeferreds.map(item => item.deferredId);

        let invoiceData = null;
        if (invoicesData && invoicesData.length) {
          const currentInvoiceData = invoicesData[0];
          if (
            !currentInvoiceData.isPaid &&
            currentInvoiceData.couponValue === currentDebtValue
          ) {
            invoiceData = {
              couponId: currentInvoiceData.couponId,
              couponValue: currentInvoiceData.couponValue
            };
          }
        }

        const response = await GetDischargeDeferredAPI(authToken, {
          deferredIds,
          currentDebtValue,
          invoiceData
        });

        if (response.success) {
          const { requestId, couponId, pdf } = response.data.data;
          setData(data => ({
            ...data,
            ...values,
            requestId,
            couponId,
            pdf
          }));

          setCurrentStep(step => step + 1);
          return;
        }
        if (
          redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
        ) {
          return;
        }

        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });

        setLoading(false);
      }
    },
    [
      authToken,
      setAlert,
      setCurrentUser,
      addedDeferreds,
      setCurrentStep,
      setData,
      stepperData,
      currentDebtValue,
      invoicesData
    ]
  );

  const onBackward = useCallback(() => {
    setData(data => ({ ...data, ...getValues() }));
    setCurrentStep(currentStep => currentStep - 1);
  }, [getValues, setCurrentStep, setData]);

  useEffect(() => {
    setNextButtonText('Continuar');
    setVisualStepperIndex(currentStepIndex);
    setGoBack({
      action: onBackward
    });
  }, [
    setGoBack,
    onBackward,
    setVisualStepperIndex,
    currentStepIndex,
    setNextButtonText
  ]);

  useEffect(() => {
    if (watchForm.terms && !loading) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [watchForm, loading, setCanSubmit]);

  return (
    <Container maxWidth="md" className={classes.root}>
      {loading && <ModalProgress message="Generando cupón" />}
      <Title title={'Términos y condiciones'} className={classes.title} />
      <Typography paragraph className={classes.checkLabelText}>
        Por favor, lea con cuidado y mucha atención los términos y condiciones
        del servicio y autorizalos en caso de estar de acuerdo.
      </Typography>
      <form
        id={formId}
        onSubmit={handleSubmit(onForward)}
        className={classes.formContainer}
      >
        <div className={classes.termsContainer}>{content}</div>
        <FormControlLabel
          className={classes.checkboxContainer}
          control={
            <ControlledCheckbox
              name="terms"
              control={control}
              checked={checked}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          label={
            <Typography className={classes.checkLabelText}>
              He leído y acepto los términos y condiciones.
            </Typography>
          }
        />
        <ErrorMessage
          errors={errors}
          name="terms"
          message={errors.terms}
          as={<Typography color="error" />}
        />
      </form>
    </Container>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'space-around',
    paddingLeft: '0px',
    paddingRight: '0px',
    paddingBottom: theme.spacing(),
    // * Responsive
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing()
    }
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 600
  },
  checkLabelText: {
    fontSize: 12,
    color: theme.palette.text.greyDark
  },
  formContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginBottom: 0,
      paddingLeft: 0
    },
    marginBottom: theme.spacing(3),
    minWidth: '60%'
  },
  checkboxContainer: {
    backgroundColor: theme.palette.background.accordionLighter,
    margin: 0,
    display: 'flex',
    padding: `${theme.spacing(0.5)}px ${theme.spacing()}px`,
    borderWidth: '0 1px 1px 1px',
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
    borderRadius: `0 0 ${theme.custom.borderRadius}px ${theme.custom.borderRadius}px`,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      borderWidth: 1,
      borderRadius: 0
    }
  },
  termsContainer: {
    maxHeight: 366,
    overflowY: 'auto',
    borderStyle: 'solid',
    padding: theme.spacing(2),
    borderWidth: 1,
    borderColor: theme.palette.background.border,
    borderRadius: `${theme.custom.borderRadius}px ${theme.custom.borderRadius}px 0 0`,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      maxHeight: '100%',
      overflowY: 'hidden',
      borderRadius: 0,
      borderWidth: 0,
      borderStyle: 'none',
      borderColor: 'transparent',
      padding: `${theme.spacing(2)}px 0px 0`
    }
  }
}));

export default TermConditionsStep;
