import React from 'react';

const IndustrialIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 56}
    height={props.size || 56}
    viewBox="0 0 56 56"
    xmlSpace="preserve"
    {...props}
  >
    <path
      id="Trazado_763"
      data-name="Trazado 763"
      d="M0,0H56V56H0Z"
      fill="none"
    />
    <path
      id="Trazado_764"
      data-name="Trazado 764"
      d="M4,46.2C7.115,33.739,9.385,21.317,9.431,9H25.724c.046,12.317,2.316,24.739,5.431,37.2"
      transform="translate(0.419 8.101)"
      fill="none"
      stroke="#104eb2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      id="Trazado_765"
      data-name="Trazado 765"
      d="M12.5,13H24.72c.068,8.1,2.428,16.418,5.431,24.8"
      transform="translate(15 16.501)"
      fill="none"
      stroke="#104eb2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      id="Trazado_766"
      data-name="Trazado 766"
      d="M9,7.1a6.307,6.307,0,0,1,10.862,0,6.307,6.307,0,0,0,10.862,0,6.307,6.307,0,0,1,10.862,0"
      transform="translate(8.996 -2.401)"
      fill="none"
      stroke="#104eb2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <line
      id="Línea_98"
      data-name="Línea 98"
      x2="51.594"
      transform="translate(1.703 54.303)"
      fill="none"
      stroke="#104eb2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default IndustrialIcon;
