import React from 'react';
import { portal, Portal } from '../../../../Configs/general';

import DeferredPaymentGDC from './DeferredPayment.GDC';

const DeferredPayment = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <DeferredPaymentGDC {...props} />;
    default:
      return <></>;
  }
};

export default DeferredPayment;
