import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import { SlideUpTransition } from '../../Transitions/Transitions';
import BaseDialog from '../BaseDialog';
import PhoneList from '../../Lists/PhoneList';

function PhonesDialog(props) {
  const { open = true, onClose } = props;
  const classes = useStyles();

  const isMobileSize = isWidthDown('xs', props.width);

  return (
    <Fragment>
      <BaseDialog
        title="Líneas de atención al cliente"
        content={PhoneList}
        open={open}
        handleClose={onClose}
        fullScreen={isMobileSize}
        contentStyle={classes.dialogContent}
        TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
      />
    </Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: theme.spacing(2)
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 70,
    backgroundColor: theme.palette.background.cardDark,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2)
  }
}));

export default withWidth()(PhonesDialog);
