import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import AuthHomeGDC from './AuthHome.GDC';
import AuthHomeGDG from './AuthHome.GDG';
import AuthHomeEFG from './AuthHome.EFG';
import AuthHomeAAA from './AuthHome.AAA';

const AuthHome = () => {
  switch (portal) {
    case Portal.Gascaribe:
      return <AuthHomeGDC />;
    case Portal.Gasguajira:
      return <AuthHomeGDG />;
    case Portal.Efigas:
      return <AuthHomeEFG />;
    case Portal.TripleA:
      return <AuthHomeAAA />;
    default:
      return <></>;
  }
};

export default AuthHome;
