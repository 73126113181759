import React from 'react';

const BackBurgerIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 50}
    height={props.size || 50}
    preserveAspectRatio="xMidYMid meet"
    fill={props.fill || 'currentColor'}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m5 13l4 4l-1.4 1.42L1.18 12L7.6 5.58L9 7l-4 4h16v2H5m16-7v2H11V6h10m0 10v2H11v-2h10Z"
    />
  </svg>
);

export default BackBurgerIcon;
