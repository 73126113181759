import React from 'react';

const SvgPhone = props => (
  <svg width="1em" height="1em" viewBox="0 0 47.918 47.917" {...props}>
    <g
      data-name="Group 584"
      transform="translate(-764.176 -2614.176)"
      fill="#104eb2"
    >
      <circle
        data-name="Ellipse 5"
        cx={23.959}
        cy={23.959}
        transform="translate(764.176 2614.176)"
        opacity={0.1}
        r={23.959}
      />
      <path
        data-name="Path 219"
        d="M801.767 2645.814l-5.932-3.956a1.732 1.732 0 00-2.319.377l-1.728 2.221a.739.739 0 01-.947.2l-.329-.181a19.865 19.865 0 01-5.177-4.066 19.946 19.946 0 01-4.067-5.177l-.18-.329a.739.739 0 01.2-.95l2.22-1.728a1.732 1.732 0 00.377-2.319l-3.959-5.933a1.723 1.723 0 00-2.323-.523l-2.477 1.491a3.5 3.5 0 00-1.6 2.07c-.893 3.255-.221 8.875 8.054 17.148 6.583 6.582 11.483 8.352 14.851 8.352a8.653 8.653 0 002.3-.3 3.5 3.5 0 002.071-1.6l1.492-2.479a1.723 1.723 0 00-.527-2.318zm-.319 1.822l-1.488 2.48a2.524 2.524 0 01-1.49 1.155c-3 .825-8.254.142-16.2-7.8s-8.626-13.196-7.801-16.201a2.528 2.528 0 011.157-1.492l2.479-1.492a.747.747 0 011.008.227l2.149 3.23 1.8 2.705a.752.752 0 01-.163 1.006l-2.217 1.732a1.709 1.709 0 00-.456 2.19l.176.32a20.72 20.72 0 004.234 5.4 20.742 20.742 0 005.4 4.233l.321.177a1.71 1.71 0 002.194-.453l1.728-2.221a.752.752 0 011.006-.163l5.941 3.959a.747.747 0 01.222 1.008z"
      />
    </g>
  </svg>
);

export default SvgPhone;
