import React, { Fragment } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import { fullName } from '../../../../Utils/Format/Names';
import { formatAddress } from '../../../../Utils/Format/Address';
import EmailIcon from '../../../../Components/CustomIcons/EmailIcon';
import TrashIcon from '../../../../Components/CustomIcons/IcTrash';
import PencilIcon from '../../../../Components/CustomIcons/IcPencil';
import PointsIcon from '../../../../Components/CustomIcons/IcPointsFireBadge';

import SimpleDivider from '../../../../Components/Dividers/SimpleDivider';
import { getSendingMethodText } from '../../../../Enums/invoices';
import { Company } from '../../../../Configs/general';

const ContractItemGDC = props => {
  const { contract, removeContract, openEditDialog } = props;

  const {
    digitalInvoice,
    sendEmailInvoice,
    sendWhatsAppInvoice,
    sendSmsInvoice
  } = contract;

  const classes = useStyles();

  const handleEditDialog = React.useCallback(() => {
    openEditDialog(contract);
  }, [contract, openEditDialog]);

  const handleRemoveContract = React.useCallback(() => {
    removeContract(contract);
  }, [contract, removeContract]);

  const sendingInvoiceMethod = getSendingMethodText(
    sendEmailInvoice,
    sendWhatsAppInvoice,
    sendSmsInvoice
  );

  return (
    <Fragment>
      <Grid container direction="row" className={classes.contractItem}>
        <Grid item xs={3} className={classes.textContainer}>
          <Typography className={classes.label}>
            {Company.contractConjugation.capitalized.singular.associate}
          </Typography>
          <Typography className={classes.text}>
            {`${contract.alias} - ${contract.id}`}
          </Typography>

          <Grid className={classes.pointsContainer}>
            <PointsIcon className={classes.noShrink} />
            <Typography className={classes.pointsText}>
              {contract.points} puntos
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={7} className={classes.textContainer}>
          <Typography className={classes.label}>Nombre del titular</Typography>
          <Typography className={`${classes.text} ${classes.capitalizeText}`}>
            {fullName(contract.holder, 'name', 'lastName', true)}
          </Typography>
          <Typography className={clsx(classes.label, classes.labelMargin)}>
            Dirección del predio
          </Typography>
          <Typography className={`${classes.text} ${classes.capitalizeText}`}>
            {formatAddress(contract, true)}
          </Typography>
          <Grid item container className={classes.digitalInvoiceContainer}>
            <Typography
              className={clsx(classes.digitalInvoiceText, {
                [classes.physicalInvoiceText]: !digitalInvoice
              })}
            >
              {digitalInvoice ? 'Factura Digital' : 'Factura Física'}
            </Typography>
          </Grid>
          {sendingInvoiceMethod && (
            <Grid
              container
              className={classes.activeChannelsContainer}
              wrap="nowrap"
            >
              <EmailIcon size={16} className={classes.noShrink} />
              <Typography className={classes.activeChannelsText}>
                {`Envío de factura vía ${sendingInvoiceMethod} mensual programado.`}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item xs={2} className={classes.actionsContainer}>
          <IconButton
            aria-label={`editar ${Company.contractConjugation.regular.singular.main}`}
            onClick={handleEditDialog}
            className={classes.icon}
          >
            <PencilIcon size={20} />
          </IconButton>
          <IconButton
            aria-label={`desasociar ${Company.contractConjugation.regular.singular.main}`}
            onClick={handleRemoveContract}
            className={classes.icon}
          >
            <TrashIcon size={20} />
          </IconButton>
        </Grid>
      </Grid>
      <SimpleDivider withoutMargin={true} className={classes.dividerLast} />
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  contractItem: {
    padding: theme.spacing(3)
  },
  actionsContainer: {
    textAlign: 'end'
  },
  textContainer: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing()
  },
  pointsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 15
  },
  pointsText: {
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: theme.spacing()
  },
  label: {
    fontSize: 11,
    fontWeight: 500,
    color: theme.palette.text.greyDark
  },
  labelMargin: {
    marginTop: 15
  },
  text: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.default
  },
  capitalizeText: {
    textTransform: 'capitalize'
  },
  activeChannelsContainer: {
    alignItems: 'center',
    marginTop: 12
  },
  activeChannelsText: {
    fontSize: 12,
    color: theme.palette.text.greyDark,
    marginLeft: theme.spacing()
  },
  icon: {
    padding: theme.spacing(),
    marginRight: theme.spacing()
  },
  noShrink: {
    flexShrink: 0
  },
  dividerLast: {
    '&:last-child': {
      display: 'none'
    }
  },
  digitalInvoiceContainer: {
    alignItems: 'center',
    marginTop: theme.spacing(1.5)
  },
  digitalInvoiceText: {
    minWidth: 100,
    fontSize: 12,
    fontWeight: 500,
    textAlign: 'center',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0.5),
    borderRadius: theme.custom.borderRadius
  },
  physicalInvoiceText: {
    backgroundColor: theme.palette.color.gasesGreen
  }
}));

export default ContractItemGDC;
