import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';
import ReactCalendar from 'react-calendar';
import clsx from 'clsx';

const Calendar = props => {
  const {
    value,
    tileDisabled,
    loading = false,
    disabled = false,
    className: customStyle,
    ...rest
  } = props;

  const classes = useStyles();

  const disabledAllTiles = () => {
    return true;
  };

  return (
    <>
      {loading && <CircularProgress className={classes.loadingIndicator} />}
      <ReactCalendar
        tileDisabled={disabled ? disabledAllTiles : tileDisabled}
        className={clsx(classes.calendar, customStyle)}
        value={value}
        {...rest}
      />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  calendar: {
    width: '100%',
    backgroundColor: 'white',
    color: '#222',
    borderRadius: '8px',
    borderColor: theme.palette.primary.main,
    fontFamily: 'Arial, Helvetica, sans-serif',
    lineHeight: '1.125em'
  },
  loadingIndicator: {
    position: 'absolute',
    top: '50%',
    left: '45%',
    width: '100%',
    height: '100%',
    zIndex: 10000000
  }
}));

export default Calendar;
