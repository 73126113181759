import React from 'react';
import { portal, Portal } from '../../../../Configs/general';

import IndividualPaymentGDC from './IndividualPayment.GDC';
import IndividualPaymentGDG from './IndividualPayment.GDG';
import IndividualPaymentEFG from './IndividualPayment.EFG';

const IndividualPayment = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <IndividualPaymentGDC {...props} />;
    case Portal.Gasguajira:
      return <IndividualPaymentGDG {...props} />;
    case Portal.Efigas:
      return <IndividualPaymentEFG {...props} />;
    case Portal.TripleA:
      return <IndividualPaymentEFG {...props} />;
    default:
      return <></>;
  }
};

export default IndividualPayment;
