import ContractIcon from '../../Components/CustomIcons/ContractIcon';

export const SubrogationRequestStep = {
  Contract: 0,
  PersonalData: 1,
  SignDocuments: 2
};

export const SubrogationRequestStepLabel = {
  [SubrogationRequestStep.Contract]: 'Contrato',
  [SubrogationRequestStep.PersonalData]: 'Datos personales',
  [SubrogationRequestStep.SignDocuments]: 'Firma de Documentos'
};

export const FORM_ID = 'Subrogation_Request_form';

export const SUBROGATION_REQUEST_STEPS = Object.values(
  SubrogationRequestStepLabel
).map(label => ({ label }));

const IDENTIFICATION_TYPES = {
  CC: 'CC',
  PP: 'PP',
  CE: 'CE'
};

export const IDENTIFICATION_OPTIONS = [
  { label: 'C.C', value: IDENTIFICATION_TYPES.CC },
  { label: 'P.P', value: IDENTIFICATION_TYPES.PP },
  { label: 'C.E', value: IDENTIFICATION_TYPES.CE }
];

export const CONTRACT_TYPE = [
  { label: 'Número de contrato', value: 0, image: ContractIcon }
];
