/* eslint-disable complexity */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  TextField,
  IconButton,
  Collapse,
  useMediaQuery
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import NumberFormat from 'react-number-format';
import clsx from 'clsx';

import Pagination from '../../../../Components/Pagination/Pagination';
import { numberWithDots } from '../../../../Utils/Format/MoneyFormat';
import { capitalizeFirstLetter } from '../../../../Utils/Format/Names';
import { history } from '../../../../Routes/history';
import { ROUTE_NAMES } from '../../../../Routes/Routes';

import SimpleDivider from '../../../../Components/Dividers/SimpleDivider';
import SeeMoreButton from '../SeeMoreButton';

const ProductAccordion = ({
  concepts = [],
  deferreds = [],
  totalConcepts,
  totalDeferreds,
  typeDescription,
  id,
  selectedContract,
  monthlyInterestRate,
  minimunInstallment,
  punished,
  total,
  control,
  errors,
  inputName,
  initialInstallmentInputLabel
}) => {
  const classes = useStyles();

  // * STATE HOOKS
  const [expanded, setExpanded] = useState(false);
  const [currentDeferredPage, setCurrentDeferredPage] = useState(1);
  const [currentConceptPage, setCurrentConceptPage] = useState(1);
  const [conceptPageSize, setConceptPageSize] = useState(4);
  const [deferredPageSize, setDeferredPageSize] = useState(4);

  // * OTHER HOOKS
  const isMobileScreenSize = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  //* FUNCTIONS
  const handleClick = (e, name) => {
    e.preventDefault();
    if (name === 'button') {
      history.push(ROUTE_NAMES.refinancingCurrent, {
        concepts,
        selectedContract,
        productId: id,
        productType: typeDescription,
        itemsAdded: {
          type: 'current',
          productId: id,
          productType: typeDescription,
          toDate: concepts.toDateData,
          expired: concepts.expiredData,
          toDateTotal: concepts.toDateTotal,
          expiredTotal: concepts.expiredTotal
        }
      });
      e.stopPropagation();
    } else {
      setExpanded(!expanded);
    }
  };

  // * RENDER
  const renderConcepts = () => {
    return concepts
      .slice(
        conceptPageSize * (currentConceptPage - 1),
        conceptPageSize * (currentConceptPage - 1) + conceptPageSize
      )
      .map((concept, idx) => {
        return (
          <ConceptItem
            concept={concept}
            key={`${idx}-${currentConceptPage}-${concept.conceptId}`}
          />
        );
      });
  };

  const renderDeferreds = () => {
    return deferreds
      .slice(
        deferredPageSize * (currentDeferredPage - 1),
        deferredPageSize * (currentDeferredPage - 1) + deferredPageSize
      )
      .map((deferred, idx) => {
        return (
          <DeferredItem
            deferred={deferred}
            key={`${idx}-${currentDeferredPage}-${deferred.conceptId}`}
          />
        );
      });
  };

  return (
    <>
      <div className={classes.card}>
        <div
          className={clsx({
            [classes.cardHeader]: !punished,
            [classes.cardHeaderPunished]: punished,
            [classes.cardHeaderExpanded]: expanded
          })}
          name="paper"
        >
          <Grid item container alignItems="center" wrap="nowrap">
            <Grid item container alignItems="center" justify="space-between">
              <Grid
                item
                xs={12}
                md={3}
                className={classes.paddingMobileHeaders}
              >
                {punished ? (
                  <Typography className={classes.punishedProductTag}>
                    Producto castigado
                  </Typography>
                ) : (
                  <Typography className={classes.label}>Producto</Typography>
                )}
                <Typography className={classes.mainLabel}>
                  {typeDescription} • {id}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                md={2}
                className={classes.paddingMobileHeaders}
              >
                <div>
                  <Typography className={classes.label}>
                    Tasa de interés
                  </Typography>
                  <Typography className={classes.mainLabel}>
                    {monthlyInterestRate}%
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={12} md={3}>
                <Controller
                  as={
                    <NumberFormat
                      customInput={TextField}
                      type="tel"
                      thousandSeparator="."
                      allowNegative={false}
                      decimalSeparator={false}
                      prefix="$ "
                      fullWidth={isMobileScreenSize}
                      isAllowed={({ floatValue }) =>
                        floatValue <= 999999999 || !floatValue
                      }
                      label={initialInstallmentInputLabel}
                      FormHelperTextProps={{
                        classes: {
                          root: classes.formHelperText
                        }
                      }}
                      size="small"
                      variant="outlined"
                      onBlur={() => {}}
                      error={errors && Boolean(errors[inputName])}
                      helperText={
                        errors && errors[inputName] && errors[inputName].message
                      }
                      margin="none"
                    />
                  }
                  className={clsx(classes.initialValueContainer, {
                    [classes.numberFormatInputLabel]:
                      !isMobileScreenSize &&
                      errors &&
                      errors.initialInstallmentValue
                  })}
                  onChangeName="onValueChange"
                  onChange={values => {
                    if (values[0]) {
                      return values[0].floatValue;
                    }
                    return 0;
                  }}
                  control={control}
                  name={inputName}
                  defaultValue={minimunInstallment}
                />
              </Grid>

              {isMobileScreenSize ? (
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={12}>
                    <SimpleDivider className={classes.divider} />
                  </Grid>
                  {!punished && (
                    <Grid item xs={6}>
                      <SeeMoreButton
                        onClick={e => handleClick(e, 'paper')}
                        text={expanded ? 'Ocultar detalle' : 'Ver detalle'}
                        clicked={expanded}
                        buttonClasses={classes.seeMoreButton}
                        iconClasses={classes.seeMoreIcon}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    xs={6}
                    direction="column"
                    justify="flex-end"
                  >
                    <Grid item>
                      <Typography
                        className={clsx(classes.label, classes.alignRight)}
                      >
                        Saldo total
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        className={clsx(
                          classes.data,
                          classes.total,
                          classes.alignRight
                        )}
                      >
                        {numberWithDots(total)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item md={3} container alignItems="center">
                  <Grid item container alignItems="center" justify="flex-end">
                    <Grid item>
                      <Typography
                        className={clsx(classes.label, classes.alignRight)}
                      >
                        Saldo total
                      </Typography>
                      <Typography
                        className={clsx(
                          classes.data,
                          classes.total,
                          classes.alignRight
                        )}
                      >
                        {numberWithDots(total)}
                      </Typography>
                    </Grid>
                    {!punished && (
                      <Grid item>
                        <IconButton
                          className={clsx(classes.expand, classes.expandIcon, {
                            [classes.expandOpen]: expanded
                          })}
                          onClick={e => handleClick(e, 'paper')}
                          aria-expanded={expanded}
                          aria-label="Ver más"
                          size="small"
                        >
                          <ArrowDropDownIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </div>
        {!punished && (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {concepts.length > 0 && (
              <>
                <Grid container item className={classes.category}>
                  <Grid
                    container
                    item
                    justify="space-between"
                    alignItems="center"
                    wrap="nowrap"
                  >
                    <div>
                      <Typography className={classes.label}>
                        Tipo de saldo
                      </Typography>
                      <Typography className={classes.subtitle}>
                        Saldo mes actual
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        className={clsx(classes.label, classes.alignRight)}
                      >
                        Mes actual
                      </Typography>
                      <Typography
                        className={clsx(classes.data, classes.alignRight)}
                      >
                        {numberWithDots(totalConcepts)}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                <Grid container className={classes.detailHeader}>
                  <Grid item xs={8} md={10}>
                    <Typography className={classes.label}>Concepto</Typography>
                  </Grid>
                  <Grid item xs={4} md={2}>
                    <Typography
                      className={clsx(classes.label, classes.alignRight)}
                    >
                      Valor
                    </Typography>
                  </Grid>
                </Grid>
                <Pagination
                  itemsPerPage={conceptPageSize}
                  numberOfItems={concepts.length}
                  currentPage={currentConceptPage}
                  setCurrentPage={setCurrentConceptPage}
                  setItemsPerPage={setConceptPageSize}
                  className={classes.pagination}
                />

                {renderConcepts()}
              </>
            )}

            {deferreds.length > 0 && (
              <>
                <Grid container item className={classes.category}>
                  <Grid
                    container
                    item
                    justify="space-between"
                    alignItems="center"
                    wrap="nowrap"
                  >
                    <div>
                      <Typography className={classes.label}>
                        Tipo de saldo
                      </Typography>
                      <Typography className={classes.subtitle}>
                        Saldo de financiaciones
                      </Typography>
                    </div>
                    <div>
                      <Typography
                        className={clsx(
                          classes.label,
                          classes.deferredBalance,
                          classes.alignRight
                        )}
                      >
                        Saldo diferido
                      </Typography>
                      <Typography
                        className={clsx(
                          classes.data,
                          classes.subtitle,
                          classes.alignRight
                        )}
                      >
                        {numberWithDots(totalDeferreds)}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>

                <Grid
                  container
                  justify="space-between"
                  className={classes.detailHeader}
                >
                  <Grid item xs={6} md={7}>
                    <Typography
                      className={clsx(classes.label, classes.labelHeader)}
                    >
                      Concepto
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <Typography
                      className={clsx(
                        classes.label,
                        classes.labelHeader,
                        classes.alignCenter,
                        classes.extraPadding
                      )}
                    >
                      Cuotas pendientes
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <Typography
                      className={clsx(
                        classes.label,
                        classes.labelHeader,
                        classes.alignRight
                      )}
                    >
                      Valor
                    </Typography>
                  </Grid>
                </Grid>

                <Pagination
                  itemsPerPage={deferredPageSize}
                  numberOfItems={deferreds.length}
                  currentPage={currentDeferredPage}
                  setCurrentPage={setCurrentDeferredPage}
                  setItemsPerPage={setDeferredPageSize}
                  className={classes.pagination}
                />
                {renderDeferreds()}
              </>
            )}
          </Collapse>
        )}
      </div>
    </>
  );
};

const ConceptItem = ({ concept }) => {
  const classes = useStyles();

  // * OTHER HOOKS
  const isMediumScreenSize = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  return (
    <div className={classes.concept}>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} md={10}>
          <Typography className={classes.itemData}>
            {concept.conceptId} •{' '}
            {capitalizeFirstLetter(concept.conceptDescription)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2}>
          <Typography
            className={clsx(
              classes.itemData,
              classes.itemValue,
              classes.alignRight
            )}
          >
            {isMediumScreenSize
              ? `Valor: ${numberWithDots(concept.balance)}`
              : numberWithDots(concept.balance)}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const DeferredItem = ({ deferred }) => {
  const classes = useStyles();

  // * OTHER HOOKS
  const isMediumScreenSize = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  const concept = (
    <Grid item xs={12} md={7} key={`concept-${deferred.conceptId}`}>
      <Typography className={classes.itemData}>
        {deferred.conceptId} •{' '}
        {capitalizeFirstLetter(deferred.conceptDescription)}
      </Typography>
    </Grid>
  );

  const pendingQuotas = (
    <Grid item xs={12} md={2} key={`pending-quotas-${deferred.conceptId}`}>
      <Typography
        className={clsx(classes.itemData, classes.pendingValuesContainer, {
          [classes.extraPadding]: !isMediumScreenSize
        })}
      >
        {isMediumScreenSize
          ? `Cuotas pendientes ${deferred.pendingInstallments}/${deferred.totalInstallments}`
          : `${deferred.pendingInstallments} de ${deferred.totalInstallments}`}
      </Typography>
    </Grid>
  );

  return (
    <div className={classes.concept}>
      <Grid container alignItems="center" justify="space-between">
        {isMediumScreenSize
          ? [pendingQuotas, concept]
          : [concept, pendingQuotas]}
        <Grid item xs={12} md={2}>
          <Typography
            className={clsx(
              classes.itemData,
              classes.itemValue,
              classes.alignRight
            )}
          >
            {isMediumScreenSize
              ? `Valor: ${numberWithDots(deferred.pendingValue)}`
              : numberWithDots(deferred.pendingValue)}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: theme.custom.borderRadius,
    border: `1px solid ${theme.palette.background.border}`,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  cardHeader: {
    padding: theme.spacing(3, 2, 3, 3),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      padding: theme.spacing(2)
    }
  },
  cardHeaderPunished: {
    padding: theme.spacing(3),
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      backgroundColor: theme.palette.background.cardDark
    }
  },
  cardContentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  category: {
    backgroundColor: theme.palette.background.accordionLight,
    padding: theme.spacing(2, 6, 1, 3),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      padding: theme.spacing(2)
    }
  },
  concept: {
    padding: theme.spacing(2, 6, 2, 3),
    borderTop: `1px solid ${theme.palette.background.border}`,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      padding: theme.spacing(2)
    }
  },
  detailHeader: {
    display: 'flex',
    whiteSpace: 'nowrap',
    border: `1px solid ${theme.palette.background.border}`,
    backgroundColor: theme.palette.background.accordionLighter,
    padding: theme.spacing(1, 6, 1, 3),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      display: 'none'
    }
  },
  initialValueContainer: {
    width: '100%',
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      margin: 0
    }
  },
  changeInitialValue: {
    color: theme.palette.color.primary,
    fontWeight: 500,
    textAlign: 'right',
    textDecoration: 'underline'
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 600
  },
  label: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.greyDark
  },
  mainLabel: {
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 12
    }
  },
  labelHeader: {
    fontWeight: 600
  },
  data: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.2,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: 13
    }
  },
  total: {
    fontSize: 20,
    fontWeight: 600
  },
  itemData: {
    fontSize: 12,
    textAlign: 'left',
    fontWeight: 500,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: 11
    }
  },
  itemValue: {
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontWeight: 600
    }
  },
  pendingValuesContainer: {
    textAlign: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      width: 136,
      marginLeft: 'auto',
      fontSize: 10,
      textAlign: 'right',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.background.accordion
    }
  },
  deferredBalance: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: 3,
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5)
  },
  punishedProductTag: {
    maxWidth: 112,
    fontSize: 10,
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.main,
    borderRadius: 3,
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(0.5)
  },
  numberInput: {
    minHeight: 'unset !important'
  },
  alignCenter: {
    textAlign: 'center'
  },
  alignRight: {
    textAlign: 'right'
  },
  button: {
    fontSize: 13,
    textTransform: 'capitalize'
  },
  statusTag: {
    fontSize: 11,
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.main,
    fontWeight: 600,
    border: '1px solid transparent',
    borderRadius: 5
  },
  expandIcon: {
    marginLeft: 0
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  cardHeaderExpanded: {
    backgroundColor: theme.palette.background.accordion
  },
  pagination: {
    padding: theme.spacing(0.5, 3)
  },
  formHelperText: {
    position: 'absolute',
    margin: 0,
    top: '100%',
    fontSize: 11
  },
  extraPadding: {
    paddingRight: theme.spacing(6.5)
  },
  seeMoreButton: {
    fontSize: 12,
    color: theme.palette.primary.main
  },
  seeMoreIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
    fontSize: 16
  },
  paddingMobileHeaders: {
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      paddingBottom: theme.spacing(1)
    }
  },
  divider: {
    marginBottom: theme.spacing(2),
    color: theme.palette.divider
  },
  dialogContentStyle: {
    padding: theme.spacing(3, 2)
  },
  dialogTitle: {
    padding: theme.spacing(3, 2)
  },
  dialogTitleText: {
    fontSize: 14,
    fontWeight: 600
  },
  dialogTitleButton: {
    padding: theme.spacing(1)
  },
  numberFormatInputLabel: {
    '& > *': {
      fontSize: 11
    }
  }
}));

export default ProductAccordion;
