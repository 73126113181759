import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import BaseButton from '../Buttons/BaseButton';

const ProductBanner = ({
  backgroundImg,
  logoImage,
  title,
  handleClick,
  buttonText,
  disabledButton,
  customClasses = {},
  showsTitleSection = true
}) => {
  const classes = useStyles();

  return (
    <div className={customClasses.root}>
      <img
        src={backgroundImg}
        className={clsx(classes.bannerImg, customClasses.bannerImg)}
        alt=""
      />
      <Grid
        container
        direction="row"
        wrap="nowrap"
        className={clsx(classes.productInfoGrid, customClasses.productInfoGrid)}
      >
        <Grid
          item
          className={clsx(
            classes.productLogoGrid,
            customClasses.productLogoGrid
          )}
        >
          <img
            src={logoImage}
            className={clsx(
              classes.productIcon,
              customClasses.productIcon,
              classes.alignCenter
            )}
            alt=""
          />
        </Grid>
        {showsTitleSection && (
          <Grid
            container
            item
            justify="space-between"
            alignItems="center"
            className={clsx(
              classes.productNameGrid,
              customClasses.productNameGrid
            )}
          >
            <Grid
              item
              className={clsx(classes.alignCenter, customClasses.align)}
            >
              <Typography className={clsx(classes.title, customClasses.title)}>
                {title}
              </Typography>
            </Grid>

            <Grid item>
              <BaseButton
                size="small"
                onClick={handleClick}
                disabled={disabledButton}
              >
                <Typography>{buttonText}</Typography>
              </BaseButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.2
  },
  description: {
    fontSize: 12,
    fontWeight: 400,
    color: theme.palette.text.greyDark
  },
  bannerImg: {
    borderTopRightRadius: theme.spacing(1),
    borderTopLeftRadius: theme.spacing(1),
    height: 120,
    objectFit: 'cover',
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  productIcon: {
    width: 90,
    height: 'auto'
  },
  productInfoGrid: {
    maxHeight: theme.spacing(5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      justifyContent: 'center'
    }
  },
  productLogoGrid: {
    width: 90,
    height: 90,
    position: 'relative',
    bottom: theme.spacing(6.2),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2),
    borderRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#fff',
    boxShadow: '0px 5px 13px 3px rgba(0,0,0,0.1)'
  },
  productNameGrid: {
    height: theme.spacing(5)
  },
  margin: {
    marginBottom: theme.spacing(1)
  },
  alignCenter: {
    textAlign: 'center'
  },
  alignRight: {
    textAlign: 'right'
  },
  image: {
    width: '70%',
    borderRadius: theme.spacing(1)
  }
}));

export default ProductBanner;
