import React from 'react';

const ContractAssociationIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 380.739}
      height={props.height || 252}
      viewBox="0 0 380.739 252"
      {...props}
    >
      <g transform="translate(-529.63 -340)">
        <path
          d="M862.794 463.593H824.94a110.694 110.694 0 00-179-68.243h-38.202a16.849 16.849 0 00-16.819 16.819 16.926 16.926 0 0014.009 16.6h27.227a18.4 18.4 0 0118.356 18.357v2.064a18.4 18.4 0 01-18.356 18.356H577.7a16.849 16.849 0 00-16.819 16.819 16.849 16.849 0 0016.819 16.819h29.028a110.686 110.686 0 00177.239 67.189 16.393 16.393 0 004.435.615h42.861a16.849 16.849 0 0016.818-16.819 16.849 16.849 0 00-16.819-16.819h-25.294a18.011 18.011 0 01-17.961-17.961v-2.371a18.011 18.011 0 0117.961-17.961h40.621a15.47 15.47 0 012.9.263h13.218a16.849 16.849 0 0016.819-16.819 16.765 16.765 0 00-16.732-16.908zM562.83 428.989h-17.962a15.312 15.312 0 01-15.238-15.282v-3.162a15.3 15.3 0 0115.238-15.238h17.918a15.275 15.275 0 0115.238 15.238v3.162a15.246 15.246 0 01-15.194 15.282zM895.132 568.989h-17.918a15.275 15.275 0 01-15.238-15.239v-3.162a15.275 15.275 0 0115.238-15.238h17.917a15.275 15.275 0 0115.238 15.238v3.162a15.3 15.3 0 01-15.237 15.239z"
          fill="#eef9fb"
        />
        <circle
          cx={6.777}
          cy={6.777}
          transform="translate(618.91 342)"
          fill="none"
          stroke="#63d8f1"
          strokeMiterlimit={10}
          strokeWidth={4}
          r={6.777}
        />
        <circle
          cx={6.777}
          cy={6.777}
          transform="translate(827.611 440.831)"
          fill="none"
          stroke="#63d8f1"
          strokeMiterlimit={10}
          strokeWidth={4}
          r={6.777}
        />
        <path
          d="M630.256 453.821h-10.475v-10.474a2.259 2.259 0 10-4.518 0v10.474H604.74a2.259 2.259 0 100 4.518h10.474v10.474a2.259 2.259 0 004.518 0v-10.474h10.474a2.279 2.279 0 002.259-2.259 2.2 2.2 0 00-2.209-2.259zM882.238 481.647l-5.75-5.75 5.75-5.75a1.743 1.743 0 10-2.464-2.464l-5.75 5.75-5.75-5.75a1.743 1.743 0 10-2.464 2.464l5.75 5.75-5.75 5.75a1.743 1.743 0 002.464 2.464l5.75-5.75 5.75 5.75a1.767 1.767 0 002.464 0 1.678 1.678 0 000-2.464zM650.213 538.159h131.713c3.29 0 3.29-5.105 0-5.105H650.213c-3.29 0-3.29 5.105 0 5.105zM798.945 538.159h9.359c3.29 0 3.29-5.105 0-5.105h-9.359c-3.29 0-3.29 5.105 0 5.105zM604.948 538.159h29.383c3.29 0 3.29-5.105 0-5.105h-29.383c-3.29 0-3.29 5.105 0 5.105z"
          fill="#63d8f1"
        />
        <g fill="none" stroke="#104eb2" strokeLinejoin="round" strokeWidth={4}>
          <path d="M754.165 462.649v41.2A10.154 10.154 0 01744.011 514h-47.384a10.154 10.154 0 01-10.154-10.154v-67.692A10.154 10.154 0 01696.627 426h20.889a6.769 6.769 0 014.784 1.981l29.883 29.883a6.769 6.769 0 011.982 4.785z" />
          <path
            d="M720.319 427.692v25.385a6.769 6.769 0 006.769 6.769h25.385m-49.077 16.923h33.846m-33.846 16.923h33.846"
            strokeLinecap="round"
          />
        </g>
        <path
          d="M750.618 467a25.988 25.988 0 11-25.988 25.988A25.988 25.988 0 01750.618 467z"
          fill="#eef9fb"
        />
        <path
          d="M751.064 487.183a6.252 6.252 0 105.607 5.607 6.252 6.252 0 00-5.607-5.607zm15.054 6.222a15.077 15.077 0 01-.149 2.031l4.416 3.461a1.056 1.056 0 01.239 1.343l-4.182 7.232a1.056 1.056 0 01-1.284.448l-4.386-1.766a1.574 1.574 0 00-1.482.171 16.068 16.068 0 01-2.1 1.225 1.557 1.557 0 00-.862 1.186l-.657 4.678a1.082 1.082 0 01-1.043.9h-8.356a1.085 1.085 0 01-1.044-.866l-.656-4.671a1.57 1.57 0 00-.879-1.194 15.171 15.171 0 01-2.1-1.228 1.563 1.563 0 00-1.476-.167l-4.385 1.765a1.056 1.056 0 01-1.281-.456l-4.178-7.225a1.055 1.055 0 01.239-1.343l3.73-2.932a1.568 1.568 0 00.586-1.375c-.035-.407-.057-.814-.057-1.221s.021-.808.057-1.206a1.563 1.563 0 00-.586-1.361l-3.735-2.931a1.056 1.056 0 01-.232-1.336l4.178-7.229a1.056 1.056 0 011.284-.448l4.386 1.766a1.574 1.574 0 001.482-.171 16.068 16.068 0 012.1-1.225 1.557 1.557 0 00.862-1.186l.662-4.677a1.082 1.082 0 011.043-.9h8.356a1.085 1.085 0 011.044.866l.656 4.671a1.57 1.57 0 00.879 1.194 15.171 15.171 0 012.1 1.228 1.563 1.563 0 001.476.167l4.385-1.765a1.056 1.056 0 011.284.447l4.178 7.229a1.055 1.055 0 01-.239 1.343l-3.733 2.931a1.568 1.568 0 00-.591 1.375c.032.4.054.811.054 1.218z"
          fill="none"
          stroke="#104eb2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={3.126}
        />
      </g>
    </svg>
  );
};

export default ContractAssociationIcon;
