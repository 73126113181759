/* eslint-disable complexity */
import React from 'react';
import { Grid } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import _chunk from 'lodash/chunk';

import { numberWithDots } from '../../../Utils/Format/MoneyFormat';
import { requestTypes, requestStatus } from './enums';
import { fullName } from '../../../Utils/Format/Names';
import { identificationTypeName } from '../../../Utils/Format/Identification';
import { Company } from '../../../Configs/general';
import _get from 'lodash/get';

const RequestInfo = ({
  contractId,
  data = { input: {}, output: {} },
  type,
  status
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const mediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );
  const smallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  const displayData = [];

  if (contractId) {
    displayData.push({
      label: Company.contractConjugation.capitalized.singular.main,
      value: contractId
    });
  }

  if (type === requestTypes.couponDuplicate) {
    if (data.output && data.output.couponId) {
      displayData.push({
        label: 'Cupón generado',
        value: data.output.couponId
      });
    }

    if (data.output && data.output.pendingValue) {
      displayData.push({
        label: 'Valor de cupón',
        value: numberWithDots(data.output.pendingValue)
      });
    }
  }

  if (
    type === requestTypes.ownershipChange &&
    status !== requestStatus.cancelled
  ) {
    if (data.input && data.input.firstName && data.input.lastName) {
      displayData.push({
        label: 'Nombre',
        value: fullName(data.input, 'firstName', 'lastName')
      });
    }

    if (
      data.input &&
      data.input.identificationType &&
      data.input.identification
    ) {
      displayData.push({
        label: 'Documento',
        value: `${data.input.identification} ${identificationTypeName(
          data.input.identificationType
        )}`
      });
    }
  }

  if (type === requestTypes.dischargeDeferred) {
    if (data.input && data.input.totalPendingValue) {
      displayData.push({
        label: 'Valor a pagar',
        value: numberWithDots(data.input.totalPendingValue)
      });
    }

    if (data.input && data.input.expiryDate) {
      displayData.push({
        label: 'Fecha límite de pago',
        value: moment(data.input.dueDate).format('DD/MM/YYYY')
      });
    }
  }

  if (type === requestTypes.debtNegotiation) {
    if (data.input && data.input.totalFinanceValue) {
      displayData.push({
        label: 'Valor a financiar',
        value: numberWithDots(data.input.totalFinanceValue)
      });
    }

    if (data.input && data.input.totalInitialInstallmentValue) {
      displayData.push({
        label: 'Cuota inicial',
        value: numberWithDots(data.input.totalInitialInstallmentValue)
      });
    }

    if (data.input && data.input.products) {
      const products = data.input.products;
      displayData.push({
        label: products.length > 1 ? 'Productos' : 'Producto',
        value: (
          <>
            {data.input.products.map((product, i) => {
              return (
                <Typography
                  key={i}
                  component="span"
                  className={classes.productItem}
                >
                  <span>●</span>
                  {product.productType} ({product.productId}) /{' '}
                  {product.interestRate}%{' '}
                  {type === requestTypes.debtNegotiation &&
                    `/ ${product.installments} ${
                      product.installments > 1 ? 'cuotas' : 'cuota'
                    }`}
                </Typography>
              );
            })}
          </>
        )
      });
    }
  }

  if (type === requestTypes.changeConditions) {
    if (data.input && data.input.financeValue) {
      displayData.push({
        label: 'Valor a financiar',
        value: numberWithDots(data.input.financeValue)
      });
    }
    if (data.input && data.input.products) {
      const product = data.input.products[0];
      displayData.push({
        label: 'Producto',
        value: `${product.productType} (${product.productId})`
      });
      displayData.push({
        label: 'Tasa de interés',
        value: `${product.interestRate}%`
      });
    }
    if (data.input && data.input.totalInitialInstallmentValue) {
      displayData.push({
        label: 'Cuota inicial',
        value: numberWithDots(data.input.totalInitialInstallmentValue)
      });
    }
    if (data.input && data.input.installments) {
      displayData.push({
        label: 'Número de cuotas',
        value: data.input.installments
      });
    }
  }

  if (type === requestTypes.scheduledRevision) {
    displayData.push(
      {
        label: 'Fecha de revisión agendada',
        value: moment(data.input.scheduledDate).format('DD/MM/YYYY')
      },
      { label: 'Jornada', value: data.input.shift },
      { label: 'Número de contacto', value: data.input.phone }
    );
  }

  if (type === requestTypes.debtCertificate) {
    if (data.output && (data.output.totalDebt || data.output.totalDebt === 0)) {
      displayData.push({
        label: 'Deuda total',
        value: numberWithDots(data.output.totalDebt)
      });
    }

    if (data.output && data.output.punishedValue) {
      displayData.push({
        label: 'Deuda castigada',
        value: numberWithDots(data.output.punishedValue)
      });
    }
  }

  if (type === requestTypes.partialPayment) {
    if (data.input && data.input.partialPayment) {
      const partialProducts = data.input.productPayments;
      const { gasPayment, financingServicesPayment } = partialProducts;
      const products = [gasPayment, financingServicesPayment].filter(Boolean);
      if (products.length) {
        displayData.push({
          label: products.length > 1 ? 'Productos' : 'Producto',
          value: products.map(({ productId }) => productId).join(', ')
        });
      }
    } else if (data.input && data.input.value) {
      if (data.output && data.output.productId) {
        displayData.push({
          label: 'Producto',
          value: `Servicios financieros (${data.output.productId})`
        });
      }
    }

    if (data.output && data.output.couponId) {
      displayData.push({
        label: 'Cupón generado',
        value: data.output.couponId
      });
    }

    const partialValue =
      _get(data, 'input.partialPayment') || _get(data, 'input.value');

    if (partialValue) {
      displayData.push({
        label: 'Valor abonado',
        value: numberWithDots(partialValue)
      });
    }
  }

  if (type === requestTypes.deferredWithCurrent) {
    if (data.input && data.input.totalPendingValue) {
      const total = data.input.totalPendingValue + data.input.currentDebtValue;
      displayData.push({
        label: 'Valor a pagar',
        value: numberWithDots(total)
      });
    }

    if (data.input && data.input.expiryDate) {
      displayData.push({
        label: 'Fecha límite de pago',
        value: moment(data.input.dueDate).format('DD/MM/YYYY')
      });
    }
  }

  const splittedData = _chunk(displayData, 3);

  return (
    <div className={classes.requestInfoContainer}>
      <Typography
        variant="h6"
        component="h6"
        className={classes.requestInfoTitle}
      >
        Información de la solicitud
      </Typography>

      <Grid container>
        {splittedData[0] && (
          <Grid item container xs={12} sm={6} md={4} justify="flex-start">
            <Grid
              item
              container
              direction="column"
              justify="space-between"
              style={{ width: 'auto' }}
            >
              {splittedData[0].map(item => {
                return (
                  <Typography
                    key={item.label}
                    className={classes.requestInfoItem}
                  >
                    <span className={classes.subtitle}>{item.label}: </span>{' '}
                    {item.date
                      ? moment(item.value).format('DD/MM/YYYY')
                      : item.value}
                  </Typography>
                );
              })}
            </Grid>
          </Grid>
        )}

        {splittedData[1] && (
          <Grid
            item
            container
            xs={12}
            sm={6}
            md={4}
            justify={smallScreen ? 'flex-start' : 'center'}
          >
            <Grid item container direction="column" style={{ width: 'auto' }}>
              {splittedData[1].map(item => {
                return (
                  <Typography
                    key={item.label}
                    className={classes.requestInfoItem}
                  >
                    <span className={classes.subtitle}>{item.label}: </span>{' '}
                    {item.date
                      ? moment(item.value).format('DD/MM/YYYY')
                      : item.value}
                  </Typography>
                );
              })}
            </Grid>
          </Grid>
        )}

        {splittedData[2] && (
          <Grid
            item
            container
            xs={12}
            sm={6}
            md={4}
            justify={mediumScreen ? 'flex-start' : 'flex-end'}
          >
            <Grid item container direction="column" style={{ width: 'auto' }}>
              {splittedData[2].map(item => {
                return (
                  <Typography
                    key={item.label}
                    className={classes.requestInfoItem}
                  >
                    <span className={classes.subtitle}>{item.label}: </span>{' '}
                    {item.date
                      ? moment(item.value).format('DD/MM/YYYY')
                      : item.value}
                  </Typography>
                );
              })}
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  requestInfoContainer: {
    [theme.breakpoints.up(750)]: {
      paddingLeft: '1em',
      paddingRight: '1em'
    }
  },
  requestInfoTitle: {
    marginBottom: '0.5em'
  },
  requestInfoItem: {
    marginBottom: '0.25em',
    display: 'inline-block'
  },
  subtitle: {
    fontWeight: theme.typography.fontWeightMedium
  },
  productItem: {
    display: 'block',
    paddingLeft: theme.spacing(1),
    fontSize: '1em',
    '& span': {
      marginRight: theme.spacing(0.5)
    }
  }
}));
export default RequestInfo;
