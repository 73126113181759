import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useCallback
} from 'react';
import _get from 'lodash/get';

import { makeStyles } from '@material-ui/core/styles';
import { history } from '../../Routes/history';
import {
  redirectOnAuthFailure,
  extractErrorMessage
} from '../../Utils/Errors/Errors';
import ImageCard from '../../Components/Cards/ImageCard';
import QuotaDetail from './QuotaDetail/QuotaDetail';
import NoQuotaDetail from './NoQuotaDetail/NoQuotaDetail';
import SkeletonQuotaDetail from './SkeletonQuotaDetail';
import BrillaVisit from './BrillaVisit/BrillaVisit';
import { UserContext, UserDispatchContext } from '../../Contexts/UserContext';
import { ContractSelectedContext } from '../../Contexts/ContractsContext';
import { BrillaAPI } from '../../API/Brilla/BrillaAPI';
import { ROUTE_NAMES } from '../../Routes/Routes';
import { AlertsDispatchContext } from '../../Contexts/AlertsContext';
import { logoutUser } from '../../Utils/User/Actions';

const brillaBanner = require('../../Assets/images/cupo-brilla-banner.png');

const mapBreachReasons = policies => {
  return policies.map(policy => policy.description);
};

const Quota = props => {
  const {
    extraQuota,
    quota,
    contractAlias,
    contractId,
    reasons,
    onBrillaVisit
  } = props;

  if (quota) {
    return (
      <QuotaDetail
        extraQuota={extraQuota}
        quota={quota}
        contractAlias={contractAlias}
        contractId={contractId}
        onBrillaVisit={onBrillaVisit}
      />
    );
  }

  return <NoQuotaDetail reasons={reasons} contractId={contractId} />;
};

const Brilla = () => {
  const [quotaData, setQuotaData] = useState(null);

  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const setAlert = useContext(AlertsDispatchContext);
  const authToken = _get(currentUser, 'token');

  const classes = useStyles();

  const selectedContract = useContext(ContractSelectedContext);

  const [brillaVisit, setBrillaVisit] = useState(null);

  const onBrillaVisit = useCallback(event => {
    event.preventDefault();
    setBrillaVisit({
      title: 'Solicita tu asesoría para crédito Brilla'
    });
  }, []);

  useEffect(() => {
    setQuotaData(null);

    if (!authToken) {
      history.replace('/');
      return;
    }

    if (!selectedContract) {
      history.replace(ROUTE_NAMES.contracts);
      return;
    }

    let ignoreRequest = false;

    const fetchData = async () => {
      const contractId = selectedContract.id;
      const response = await BrillaAPI(authToken, contractId);
      if (ignoreRequest) {
        return;
      }

      if (response.success) {
        setQuotaData(response.data.data);
      } else {
        if (
          redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
        ) {
          return;
        }

        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
      }
    };

    fetchData();
    return () => {
      ignoreRequest = true;
    };
  }, [authToken, selectedContract, setCurrentUser, setAlert]);

  const renderQuota = () => {
    if (!quotaData) {
      return <SkeletonQuotaDetail />;
    }

    return (
      <Fragment>
        <Quota
          quota={quotaData.availableQuota}
          extraQuota={quotaData.extraQuota}
          reasons={mapBreachReasons(quotaData.breachedPolicies)}
          contractAlias={selectedContract.alias}
          contractId={selectedContract.id}
          onBrillaVisit={onBrillaVisit}
        />
      </Fragment>
    );
  };

  if (!selectedContract) {
    return <Fragment></Fragment>;
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <ImageCard
          banner={brillaBanner}
          classes={{ root: classes.cardContainer }}
        >
          {renderQuota()}
        </ImageCard>
        {brillaVisit && (
          <div>
            <BrillaVisit
              title={brillaVisit.title}
              open={Boolean(brillaVisit)}
              setDialog={setBrillaVisit}
              setAlert={setAlert}
              requestCallback={brillaVisit.requestCallback}
              successCallback={brillaVisit.successCallback}
            />
          </div>
        )}
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.layout,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardContainer: {
    maxWidth: '80%',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      maxWidth: '100%'
    }
  }
}));

export default Brilla;
