import axios from 'axios';
import { config } from '../../Configs';

export const GetInvoicesAPI = async (
  contractId,
  captchaToken,
  authToken = null,
  axiosSource = null
) => {
  try {
    const configObj = {
      params: { 'g-recaptcha-response': captchaToken }
    };

    if (authToken !== null) {
      configObj.headers = { Authorization: authToken };
    }

    if (axiosSource !== null) {
      configObj.cancelToken = axiosSource.token;
    }

    const response = await axios.get(
      `${config.api.url}/invoices/${contractId}`,
      configObj
    );

    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err, isCancel: axios.isCancel(err) };
  }
};

export const GetInvoiceConsumptionAPI = async (
  invoiceId,
  authToken,
  axiosSource = null
) => {
  try {
    const configObj = { headers: { Authorization: authToken } };

    if (axiosSource !== null) {
      configObj.cancelToken = axiosSource.token;
    }

    const response = await axios.get(
      `${config.api.url}/invoices/consumption/${invoiceId}`,
      configObj
    );

    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err, isCancel: axios.isCancel(err) };
  }
};
