/* eslint-disable complexity */
import React, { useCallback } from 'react';
import clsx from 'clsx';
import { Grid, makeStyles, Typography } from '@material-ui/core';

import SweetAlert from '../../../../../Components/Alerts/SweetAlert';
import YesNoButton from '../../../../../Components/Buttons/YesNoButton';
import SimpleDivider from '../../../../../Components/Dividers/SimpleDivider';

import { ALERT_TYPE } from '../../../../../Components/Alerts/alert_enums';
import { Company } from '../../../../../Configs/general';

const ContractAssociationFormNotificationAuthorization = props => {
  const {
    editMode,
    sendInvoice,
    setSendInvoice,
    isDigitalInvoice,
    openConfirmDialogHandler,
    handleSweetAlertClick,
    digitalAlreadyActive,
    handleDigitalInvoiceConfirmation,
    checkedYesButton,
    checkedNoButton
  } = props;

  // * OTHER HOOKS
  const classes = useStyles();

  const onSweetAlertClick = useCallback(() => {
    if (handleSweetAlertClick) {
      handleSweetAlertClick();
    }
  }, [handleSweetAlertClick]);

  return (
    <div className={classes.root}>
      <div>
        {!digitalAlreadyActive && (
          <div>
            <div className={classes.itemContainer}>
              <Grid container direction="column">
                <Typography className={classes.itemText}>
                  ¿Autorizas recibir la factura de{' '}
                  {Company.contractConjugation.regular.singular.demonstrative}{' '}
                  de forma digital?
                </Typography>
                <Typography
                  className={clsx(classes.itemText, classes.descriptionText)}
                >
                  Efectuada la autorización, la factura física dejará de llegar
                  al inmueble y estará disponible mes a mes a través del medio
                  electrónico indicado. Lo anterior, de acuerdo con lo prescrito
                  en el parágrafo segundo del numeral 36 de nuestro Contrato de
                  Condiciones Uniformes.
                </Typography>
              </Grid>

              <YesNoButton
                bothInactive={!editMode}
                checkedYesButton={checkedYesButton}
                checkedNoButton={checkedNoButton}
                checked={isDigitalInvoice}
                yesChecked={() => handleDigitalInvoiceConfirmation(true)}
                noChecked={openConfirmDialogHandler}
                color="primary"
                small="small"
                disabled={digitalAlreadyActive}
              />
            </div>
          </div>
        )}

        {digitalAlreadyActive && (
          <>
            <Grid container>
              <Grid
                item
                xs={12}
                className={classes.digitalInvoiceAlertContainer}
              >
                <SweetAlert
                  type={ALERT_TYPE.INFO}
                  onClick={onSweetAlertClick}
                  message={
                    <span
                      className={clsx({
                        [classes.sweetAlertText]: Boolean(editMode)
                      })}
                    >
                      ¡
                      {
                        Company.contractConjugation.capitalized.singular
                          .demonstrative
                      }{' '}
                      ya <b>tiene activa la factura digital</b>! Si deseas
                      recibir nuevamente la factura física en la dirección del
                      predio,{' '}
                      {editMode ? (
                        <span>
                          haz <b>clic aquí</b> para iniciar el proceso de
                          desactivación de factura digital.
                        </span>
                      ) : (
                        <span>
                          finaliza la asociación{' '}
                          {
                            Company.contractConjugation.regular.singular
                              .contraction
                          }{' '}
                          y en la sección de{' '}
                          <b>
                            Mis{' '}
                            {Company.contractConjugation.regular.plural.main}
                          </b>{' '}
                          podrás editarlo y desactivar la factura digital.
                        </span>
                      )}
                    </span>
                  }
                />
              </Grid>
            </Grid>
            <SimpleDivider withoutMargin className={classes.itemDivider} />
            <div className={classes.itemContainer}>
              <Grid container direction="column">
                <Typography className={classes.itemText}>
                  ¿Quieres que te enviemos la factura de{' '}
                  {Company.contractConjugation.regular.singular.demonstrative}?
                </Typography>
                <Typography
                  className={clsx(classes.itemText, classes.descriptionText)}
                >
                  Aplica únicamente para canales digitales
                </Typography>
              </Grid>
              <YesNoButton
                checked={sendInvoice}
                yesChecked={() => setSendInvoice(true)}
                noChecked={() => openConfirmDialogHandler()}
                color="primary"
                small="small"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      flexDirection: 'row'
    }
  },
  itemText: {
    fontSize: 14,
    fontWeight: 500,
    paddingBottom: theme.spacing(),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingBottom: theme.spacing(2),
      paddingRight: 0,
      paddingLeft: 0
    }
  },
  itemDivider: {
    marginTop: 0,
    marginBottom: 0
  },
  digitalInvoiceAlertContainer: {
    paddingBottom: theme.spacing(2)
  },
  descriptionText: {
    fontSize: 12,
    fontWeight: 400
  },
  sweetAlertText: {
    cursor: 'pointer'
  }
}));

export default ContractAssociationFormNotificationAuthorization;
