import React, { Fragment } from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import MuiMobileStepper from '@material-ui/core/MobileStepper';
import { Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const MobileStepper = props => {
  const {
    steps = [],
    currentStep = 0,
    stepText = 'Paso',
    stepperBarClass,
    stepperTextClass
  } = props;
  const classes = useStyles();

  const numSteps = steps.length;

  if (currentStep > numSteps) {
    console.error('Current step is greater than the number of steps');
  }

  const progressPercent = Math.round((100 * (currentStep + 1.0)) / numSteps);

  return (
    <Fragment>
      <Container className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <MuiMobileStepper
              activeStep={currentStep}
              variant="progress"
              steps={numSteps}
              position="static"
              LinearProgressProps={{
                classes: {
                  root: classes.linearProgress,
                  barColorPrimary: classes.completedBar,
                  colorPrimary: classes.uncompletedBar
                },
                value: progressPercent
              }}
              classes={{
                root: clsx(classes.progressRoot, stepperBarClass),
                progress: classes.progressBar
              }}
            />
          </Grid>
          <Grid
            container
            justify="space-between"
            className={clsx(classes.textContainer, stepperTextClass)}
          >
            <Grid item xs={6}>
              <Typography className={classes.stepperText}>
                {steps[currentStep].label}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.stepperTextRight}>
              <Typography>
                {stepText} {currentStep + 1}/{numSteps}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'space-around',
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'block'
    }
  },
  stepperTextRight: {
    fontWeight: 500,
    textAlign: 'right'
  },
  textContainer: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing()
  },
  stepperText: {
    fontWeight: 500,
    [`@media (max-width:${theme.breakpoints.values.xm - 1}px)`]: {
      fontSize: 11
    }
  },
  linearProgress: {
    height: 12,
    borderRadius: 9
  },
  completedBar: {
    backgroundColor: theme.palette.success.main,
    borderRadius: 9
  },
  uncompletedBar: {
    backgroundColor: theme.palette.success.lighter
  },
  progressRoot: {
    backgroundColor: 'transparent'
  },
  progressBar: {
    width: '100%'
  }
}));

export default MobileStepper;
