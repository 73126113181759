import React, { Fragment, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  IconButton,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import CloseIcon from '../../Components/CustomIcons/CloseIcon';
import { SlideUpTransition } from '../../Components/Transitions/Transitions';

import LoginContent from './LoginContent/LoginContent';

const LoginDialog = props => {
  const { open, setOpen, hideSocialButtons = false } = props;

  const classes = useStyles();

  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down(500));

  const handleCloseDialog = useCallback(() => setOpen(false), [setOpen]);

  return (
    <Fragment>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="login-dialog"
        classes={{ paperScrollPaper: classes.dialogContainer }}
        fullScreen={isMobileSize}
        TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
      >
        <DialogTitle className={classes.dialogTitle}>
          <IconButton
            size="small"
            aria-label="cerrar diálogo"
            onClick={handleCloseDialog}
            className={classes.closeButton}
          >
            <CloseIcon size={15} />
          </IconButton>
        </DialogTitle>

        <LoginContent
          closeDialog={handleCloseDialog}
          hideSocialButtons={hideSocialButtons}
        />
      </Dialog>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    width: 346,
    minHeight: 531,
    maxWidth: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down(500)]: {
      width: '100%'
    }
  },
  closeButton: {
    padding: theme.spacing(1.5),
    position: 'absolute',
    top: '10px',
    right: '10px'
  },
  dialogTitle: {
    padding: 0
  }
}));

export default LoginDialog;
