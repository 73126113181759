import React from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';
import EyeIcon from '../CustomIcons/EyeIcon';

const ShowPassword = props => {
  const { togglePasswordShow, passwordShown, size = 24, ...rest } = props;
  return (
    <InputAdornment position="end" {...rest}>
      <IconButton
        aria-label="toggle password visibility"
        onClick={togglePasswordShow}
      >
        {passwordShown ? (
          <EyeIcon size={size} fill="rgba(16, 78, 178, 0.80)" />
        ) : (
          <EyeIcon size={size} fill="rgba(16, 78, 178, 0.32)" />
        )}
      </IconButton>
    </InputAdornment>
  );
};

export default ShowPassword;
