import HomeIcon from '../../Components/CustomIcons/HomeIcon';
import CommercialIcon from '../../Components/CustomIcons/CommercialIcon';
import IndustrialIcon from '../../Components/CustomIcons/IndustrialIcon';
import OthersContratcsIcon from '../../Components/CustomIcons/OthersContratcsIcon';

export const CONTRACT_TYPES = {
  Residential: 1,
  Commercial: 2,
  Industrial: 3,
  Unregulated: 6,
  ServiceStation: 7,
  SecondaryMarket: 14
};

export const INVOICE_CONTRACTS = [
  CONTRACT_TYPES.Residential,
  CONTRACT_TYPES.Commercial,
  CONTRACT_TYPES.Industrial
];

export const DEFAULT_ALIASES = {
  [CONTRACT_TYPES.Residential]: 'Casa',
  [CONTRACT_TYPES.Commercial]: 'Comercio',
  [CONTRACT_TYPES.Industrial]: 'Industria'
};

export const renderContractTypeDescription = type => {
  switch (type) {
    case CONTRACT_TYPES.Residential:
      return 'Residencial';
    case CONTRACT_TYPES.Commercial:
      return 'Comercial';
    case CONTRACT_TYPES.Industrial:
      return 'Industrial';
    default:
      return 'Otro';
  }
};

export const ICON_SELECT_OPTIONS = [
  {
    label: '',
    value: 1,
    image: HomeIcon
  },
  {
    label: '',
    value: 2,
    image: CommercialIcon
  },
  {
    label: '',
    value: 3,
    image: IndustrialIcon
  },
  {
    label: '',
    value: 4,
    image: OthersContratcsIcon
  }
];

export const CONTRACT_RELATIONSHIPS = [
  { label: 'Propietario', value: 'owner' },
  { label: 'Arrendatario', value: 'tenant' },
  { label: 'Otro', value: 'other' }
];

export const ChallengeStatus = {
  ToValidate: 'toValidate',
  Validated: 'validated'
};

export const ContractChallengeType = {
  Question: 'question',
  Gelocation: 'geolocation',
  IdentityValidation: 'identityValidation'
};

export const ChallengeQuestionType = {
  RecentInvoice: 'recent-invoice-id'
};

export const formatAnswerDataBody = (answers, metadata = false) => {
  const challengeData = answers.map(challengeAnswer => {
    const { id, answer, description } = challengeAnswer;
    const data = { id, data: { answer, description } };

    if (metadata) {
      data.metadata = challengeAnswer.metadata;
    }

    return data;
  });

  return challengeData;
};

// largest contract with bigger direction 48209612, 66329871
// largest titualr 66546899 1509353
