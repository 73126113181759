import yellow from '@material-ui/core/colors/yellow';
import lightGreen from '@material-ui/core/colors/lightGreen';
import deepOrange from '@material-ui/core/colors/deepOrange';
import blue from '@material-ui/core/colors/blue';

const requestTypes = {
  brillaVisit: 1,
  couponDuplicate: 2,
  ownershipChange: 3,
  periodicRevision: 4,
  dischargeDeferred: 5,
  debtNegotiation: 6,
  changeConditions: 7,
  scheduledRevision: 8,
  partialPayment: 9,
  debtCertificate: 10,
  digitalInvoiceActivation: 11,
  digitalInvoiceDisable: 12,
  deferredPartialPayment: 13,
  subrogation: 14,
  brillaInsurance: 15,
  deferredWithCurrent: 16
};

const renderType = type => {
  switch (type) {
    case requestTypes.brillaVisit:
      return 'Visita Brilla';
    case requestTypes.couponDuplicate:
      return 'Duplicado';
    case requestTypes.ownershipChange:
      return 'Cambio de titular';
    case requestTypes.dischargeDeferred:
      return 'Pago de diferido';
    case requestTypes.periodicRevision:
      return 'Revisión periódica';
    case requestTypes.debtNegotiation:
      return 'Negociación de deuda';
    case requestTypes.changeConditions:
      return 'Cambio de condiciones';
    case requestTypes.scheduledRevision:
      return 'Agendamiento de revisión';
    case requestTypes.partialPayment:
      return 'Abono a deuda';
    case requestTypes.debtCertificate:
      return 'Certificado de deuda';
    case requestTypes.digitalInvoiceActivation:
      return 'Activación factura digital';
    case requestTypes.digitalInvoiceDisable:
      return 'Desactivación factura digital';
    case requestTypes.deferredPartialPayment:
      return 'Abono a financiación Brilla';
    case requestTypes.subrogation:
      return 'Subrogación';
    case requestTypes.brillaInsurance:
      return 'Adquisición de seguro';
    case requestTypes.deferredWithCurrent:
      return 'Pago de diferido más corriente';
    default:
      return;
  }
};

const requestStatus = {
  registered: 1,
  inProcess: 2,
  attended: 3,
  cancelled: 4
};

const renderStatus = status => {
  switch (status) {
    case requestStatus.inProcess:
      return { statusText: 'En proceso', statusColor: yellow.A700 };
    case requestStatus.registered:
      return { statusText: 'Registrada', statusColor: lightGreen.A400 };
    case requestStatus.cancelled:
      return { statusText: 'Rechazada', statusColor: deepOrange[300] };
    case requestStatus.attended:
      return { statusText: 'Atendida', statusColor: blue[800] };
    default:
      return {};
  }
};

export { requestTypes, requestStatus, renderType, renderStatus };
