import axios from 'axios';
import { config } from '../../Configs';

export const SubmitPqrAPI = async (data, token = null) => {
  try {
    const configObj = {};

    if (token !== null) {
      configObj.headers = { Authorization: token };
    }

    const response = await axios.post(`${config.api.url}/pqr`, data, configObj);
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};
