import React from 'react';

import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import clsx from 'clsx';

function AddItemButton(props) {
  const { disabled, onClick, isAdding, small } = props;
  const classes = useStyles();
  return (
    <IconButton
      size="small"
      color="primary"
      variant="text"
      value="btn"
      onClick={onClick}
      disabled={disabled}
      className={clsx(classes.addItemButton, {
        [classes.buttonDisabled]: disabled
      })}
    >
      {isAdding ? (
        <RemoveIcon
          className={clsx(classes.addItemIcon, {
            [classes.smallAddItemIcon]: small
          })}
        />
      ) : (
        <AddIcon
          className={clsx(classes.addItemIcon, {
            [classes.smallAddItemIcon]: small
          })}
        />
      )}
    </IconButton>
  );
}

const useStyles = makeStyles(theme => ({
  addItemButton: {
    color: theme.palette.primary.main,
    padding: theme.spacing(0.5),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 3
  },
  buttonDisabled: {
    borderColor: theme.palette.text.disabled,
    color: theme.palette.text.disabled
  },
  addItemIcon: {
    fontSize: 24
  },
  smallAddItemIcon: {
    fontSize: 16
  }
}));

export default AddItemButton;
