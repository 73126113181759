import axios from 'axios';
import { config } from '../../Configs';

export const GetContractDebtsAPI = async (token, contractId) => {
  try {
    const response = await axios.get(
      `${config.api.url}/contracts/debt/${contractId}`,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GetContractDebtsPDFAPI = async (token, contractId) => {
  try {
    const response = await axios.get(
      `${config.api.url}/contracts/debt/pdf/${contractId}`,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GetDebtNegotiationPlanAPI = async (token, contractId) => {
  try {
    const response = await axios.get(
      `${config.api.url}/contracts/debt/financing/plan/negotiation/${contractId}`,
      {
        headers: {
          Authorization: token
        }
      }
    );

    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GetNegotiationPreApprovalAPI = async (token, contractId) => {
  try {
    const response = await axios.get(
      `${config.api.url}/contracts/debt/financing/preApprovalNegotiation/${contractId}`,
      {
        headers: {
          Authorization: token
        }
      }
    );

    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GetChangeConditionsPlanAPI = async (token, contractId, data) => {
  try {
    const { productId, financingValue } = data;

    const response = await axios.get(
      `${config.api.url}/contracts/debt/financing/plan/changeConditions/${contractId}`,
      {
        headers: {
          Authorization: token
        },
        params: {
          productId,
          financingValue
        }
      }
    );

    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const CreateFinancingTransactionAPI = async (token, data) => {
  try {
    const response = await axios.post(
      `${config.api.url}/contracts/debt/financing/payments/pse`,
      data,
      {
        headers: {
          Authorization: token
        }
      }
    );

    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const CreateDebtNegotiationRequestAPI = async (token, data) => {
  try {
    const response = await axios.post(
      `${config.api.url}/contracts/debt/financing/debtNegotiation`,
      data,
      {
        headers: {
          Authorization: token
        }
      }
    );

    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const CreateChangeConditionsRequestAPI = async (token, data) => {
  try {
    const response = await axios.post(
      `${config.api.url}/contracts/debt/financing/changeConditions`,
      data,
      {
        headers: {
          Authorization: token
        }
      }
    );

    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GetChangeConditionsRequestsAPI = async (token, contractId) => {
  try {
    const response = await axios.get(
      `${config.api.url}/contracts/debt/financing/changeConditions/requests/${contractId}`,
      {
        headers: {
          Authorization: token
        }
      }
    );

    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GeneratePartialPaymentCouponAPI = async (token, data) => {
  try {
    const response = await axios.post(
      `${config.api.url}/contracts/debt/partial-payment`,
      data,
      {
        headers: {
          Authorization: token
        }
      }
    );

    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const CheckPartialPaymentAPI = async (
  contractId,
  productTypeIds,
  referenceCouponId,
  token,
  captchaToken = null
) => {
  try {
    const configObj = {
      params: {
        productTypeIds,
        referenceCouponId,
        'g-recaptcha-response': captchaToken
      }
    };

    if (token !== null) {
      configObj.headers = { Authorization: token };
    }

    const response = await axios.get(
      `${config.api.url}/contracts/debt/partial-payment/check/${contractId}`,
      configObj
    );

    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GetPartialPaymentCouponAPI = async (
  contractId,
  data,
  token,
  captchaToken = null
) => {
  try {
    const configObj = {
      params: { 'g-recaptcha-response': captchaToken }
    };
    if (token !== null) {
      configObj.headers = { Authorization: token };
    }

    const response = await axios.post(
      `${config.api.url}/contracts/debt/partial-payment/coupon/${contractId}`,
      data,
      configObj
    );

    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const ValidatePartialPaymentAPI = async (
  contractId,
  referenceCouponId,
  token = null,
  captchaToken = null
) => {
  try {
    const configObj = {
      params: {
        referenceCouponId,
        'g-recaptcha-response': captchaToken
      }
    };
    if (token !== null) {
      configObj.headers = { Authorization: token };
    }

    const response = await axios.get(
      `${config.api.url}/contracts/debt/partial-payment/validate/${contractId}`,
      configObj
    );

    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};
