import React, { useState } from 'react';
import { Grid, Typography, IconButton, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import clsx from 'clsx';

import CovidIcon from '../../../Components/CustomIcons/CovidIcon';

import { numberWithDots } from '../../../Utils/Format/MoneyFormat';
import { capitalizeFirstLetter } from '../../../Utils/Format/Names';

const ProductItem = ({
  productTypeDescription,
  productId,
  concepts,
  deferreds = [],
  punished = false,
  payCurrentWithDeferreds = false
}) => {
  const classes = useStyles();

  // * STATE HOOKS
  const [conceptExpanded, setConceptExpanded] = useState(false);
  const [deferredExpanded, setDeferredExpanded] = useState(false);

  // * FUNCTIONS
  const handleConceptExpandClick = () => {
    setConceptExpanded(previousExpandedState => !previousExpandedState);
  };
  const handleDeferredExpandClick = () => {
    setDeferredExpanded(previousExpandedState => !previousExpandedState);
  };

  // * GLOBAL VARS
  const totalConceptsBalance = concepts.reduce(
    (totalBalance, currConcept) => totalBalance + currConcept.balance,
    0
  );

  const totalDeferredBalance = deferreds.reduce(
    (totalDefBalance, currDeferred) =>
      totalDefBalance + currDeferred.pendingValue,
    0
  );

  const showConcepts = !punished && !payCurrentWithDeferreds;

  return (
    <div
      className={clsx(classes.productItem, {
        [classes.punishedProductItem]: punished
      })}
    >
      <div
        className={clsx(classes.productItemHeader, {
          [classes.currentValueBorder]: payCurrentWithDeferreds
        })}
      >
        {punished || payCurrentWithDeferreds ? (
          <>
            <Typography
              className={clsx(classes.statusTag, {
                [classes.punishedStatus]: punished,
                [classes.currentStatus]: payCurrentWithDeferreds
              })}
            >
              {punished ? 'Producto castigado' : 'Deuda actual'}
            </Typography>

            {!payCurrentWithDeferreds && (
              <>
                <Typography className={classes.label}>Producto</Typography>
                <Typography className={classes.dataHeader}>
                  {productTypeDescription} • {productId}
                </Typography>
              </>
            )}

            <Typography
              className={clsx(
                classes.data,
                classes.alignRight,
                classes.totalValue
              )}
            >
              Valor total: {`${numberWithDots(totalConceptsBalance)}`}
            </Typography>
          </>
        ) : (
          <>
            <Typography className={classes.label}>Producto</Typography>
            <Typography className={classes.dataHeader}>
              {productTypeDescription} • {productId}
            </Typography>
          </>
        )}
      </div>

      {showConcepts && concepts.length > 0 && (
        <>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.productItemSubHeader}
          >
            <Grid
              item
              xs={12}
              container
              justify="space-between"
              alignItems="center"
            >
              <Typography className={classes.conceptText}>
                Conceptos actuales: {concepts.length}
              </Typography>
              <IconButton
                className={clsx(classes.expand, classes.expandIcon, {
                  [classes.expandOpen]: conceptExpanded
                })}
                onClick={handleConceptExpandClick}
                aria-expanded={conceptExpanded}
                aria-label="Ver más"
                size="small"
              >
                <ArrowDropDown />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Typography
                className={clsx(
                  classes.data,
                  classes.alignRight,
                  classes.totalValue
                )}
              >
                Valor total: {`${numberWithDots(totalConceptsBalance)}`}
              </Typography>
            </Grid>
          </Grid>
          <Collapse in={conceptExpanded} timeout="auto">
            {concepts.map((c, idx) => (
              <div key={idx} className={classes.collapseItem}>
                <Typography className={classes.label}>Concepto</Typography>
                <Typography className={clsx(classes.conceptDescription)}>
                  {c.conceptId} - {capitalizeFirstLetter(c.conceptDescription)}
                </Typography>
                <Typography className={clsx(classes.data, classes.alignRight)}>
                  Valor parcial: {numberWithDots(c.balance)}
                </Typography>
              </div>
            ))}
          </Collapse>
        </>
      )}

      {deferreds.length > 0 && (
        <>
          <div className={classes.productItemHeader}>
            <Typography
              className={clsx(classes.statusTag, classes.deferredStatus)}
            >
              Conceptos diferidos
            </Typography>
            <Typography className={classes.label}>Producto</Typography>
            <Typography className={classes.data}>
              {productTypeDescription} • {productId}
            </Typography>
          </div>
          <Grid
            justify="space-between"
            container
            alignItems="center"
            className={classes.productItemSubHeader}
          >
            <Typography className={classes.conceptText}>
              Conceptos actuales: {deferreds.length}
            </Typography>

            <IconButton
              className={clsx(classes.expand, classes.expandIcon, {
                [classes.expandOpen]: deferredExpanded
              })}
              onClick={handleDeferredExpandClick}
              aria-expanded={deferredExpanded}
              aria-label="Ver más"
              size="small"
            >
              <ArrowDropDown />
            </IconButton>

            <Grid item xs={12}>
              <Typography
                className={clsx(
                  classes.data,
                  classes.alignRight,
                  classes.totalValue
                )}
              >
                Valor total: {`${numberWithDots(totalDeferredBalance)}`}
              </Typography>
            </Grid>
          </Grid>
          <Collapse in={deferredExpanded} timeout="auto">
            {deferreds.map((d, idx) => (
              <div key={idx} className={classes.collapseItem}>
                <Typography
                  className={clsx(classes.label, classes.deferredConceptTag)}
                >
                  Concepto diferido
                </Typography>
                <div className={classes.conceptDescriptionContainer}>
                  {d.reliefCOVID19 && (
                    <CovidIcon className={classes.covidIcon} />
                  )}
                  <Typography className={classes.conceptDescription}>
                    {d.conceptId} -{' '}
                    {capitalizeFirstLetter(d.conceptDescription)}
                  </Typography>
                </div>
                <Typography className={clsx(classes.data, classes.alignRight)}>
                  Valor: {numberWithDots(d.pendingValue)}
                </Typography>
              </div>
            ))}
          </Collapse>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  alignRight: {
    textAlign: 'right'
  },
  label: {
    fontSize: 10,
    fontWeight: 500,
    color: theme.palette.text.greyDark,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: 11
    }
  },
  dataHeader: {
    fontSize: 12,
    fontWeight: 600,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: 14
    }
  },
  data: {
    fontSize: 11,
    fontWeight: 600,
    lineHeight: 1.2,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: 12
    }
  },
  totalValue: {
    margin: theme.spacing(2, 0, 1, 0),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: 12
    }
  },
  conceptDescriptionContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5)
  },
  covidIcon: {
    marginRight: theme.spacing()
  },
  conceptDescription: {
    fontSize: 11
  },
  conceptText: {
    fontSize: 11,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 12
    }
  },
  productItem: {
    '& > *': {
      borderBottom: `1px solid ${theme.palette.background.border}`,
      '&:last-child': {
        borderBottom: 'none'
      }
    }
  },
  punishedProductItem: {
    borderBottom: `1px solid ${theme.palette.background.border}`
  },
  productItemHeader: {
    padding: theme.spacing(0.5, 1),
    backgroundColor: theme.palette.background.accordion,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      padding: theme.spacing(2)
    }
  },
  productItemSubHeader: {
    padding: theme.spacing(0.5, 1),
    backgroundColor: theme.palette.background.accordionLight,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      padding: theme.spacing(2)
    }
  },
  collapseItem: {
    padding: theme.spacing(0.5, 1),
    borderBottom: `1px solid ${theme.palette.background.border}`,
    '&:last-of-type': {
      borderBottom: 'none'
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      padding: theme.spacing(2, 2, 1, 2)
    }
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  statusTag: {
    width: 104,
    fontSize: 10,
    textAlign: 'center',
    color: theme.palette.common.white,
    marginLeft: 'auto',
    borderRadius: 3
  },
  deferredConceptTag: {
    color: theme.palette.primary.main
  },
  deferredStatus: {
    backgroundColor: theme.palette.primary.main
  },
  punishedStatus: {
    backgroundColor: theme.palette.error.main,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginRight: 'auto'
    }
  },
  bottomContainer: {
    padding: theme.spacing(2),
    boxShadow: '0px -3px 2px #0000000D',
    '& > *': {
      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  currentStatus: {
    backgroundColor: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      marginRight: 'auto'
    }
  },
  currentValueBorder: {
    borderBottom: 'thin dashed white !important'
  }
}));

export default ProductItem;
