import * as React from 'react';

function SvgPaginationBack(props) {
  return (
    <svg
      width="6.207"
      height="11.414"
      viewBox="0 0 6.207 11.414"
      fill={props.fill || '#2a61ba'}
      {...props}
    >
      <path
        id="ic-chevron-back-outline"
        d="M189,112l-5,5,5,5"
        transform="translate(-183.5 -111.293)"
        fill="none"
        stroke={props.fill || '#2a61ba'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </svg>
  );
}

export default SvgPaginationBack;
