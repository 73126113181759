import React from 'react';

const RequestsIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 20}
      height={props.size || 20}
      viewBox="0 0 56 56"
      {...props}
    >
      <path
        data-name="Sustracci\xF3n 6"
        d="M37 50.345H10.334A5.34 5.34 0 015 45.011V10.345a5.34 5.34 0 015.334-5.334H37a5.339 5.339 0 015.333 5.334v18.749A10.809 10.809 0 0041 29.011V10.345a4 4 0 00-4-4H10.334a4 4 0 00-4 4v34.666a4 4 0 004 4h25.5a10.586 10.586 0 002.816 1.073 5.318 5.318 0 01-1.65.261z"
      />
      <path data-name="Rect\xE1ngulo 251" fill="none" d="M0 0h56v56H0z" />
      <path
        data-name="Uni\xF3n 1"
        d="M29 40.92a12 12 0 1112 12 12 12 0 01-12-12zm1.333 0a10.65 10.65 0 003.867 8.212.068.068 0 010-.014c.594-3.288 3.8-5.03 6.956-5.03 3.088 0 6.221 1.667 6.912 4.817a10.665 10.665 0 10-17.735-7.985zm7.155-2.842a3.593 3.593 0 013.667-4 3.613 3.613 0 013.666 4c-.163 2.2-1.832 4-3.666 4s-3.506-1.796-3.667-4z"
      />
      <path
        data-name="L\xEDnea 7"
        d="M35 17.678H13.667a.667.667 0 110-1.333H35a.667.667 0 110 1.333z"
      />
      <path
        data-name="L\xEDnea 8"
        d="M29.667 28.345h-16a.667.667 0 110-1.333h16a.667.667 0 110 1.333z"
      />
      <path
        data-name="L\xEDnea 9"
        d="M24.333 39.011H13.667a.667.667 0 110-1.333h10.666a.667.667 0 110 1.333z"
      />
    </svg>
  );
};

export default RequestsIcon;
