import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import TermsAndConditionsGDC from './TermsAndConditions.GDC';
import TermsAndConditionsGDG from './TermsAndConditions.GDG';

const TermsAndConditions = props => {
  switch (portal) {
    case Portal.Gascaribe:
    case Portal.TripleA:
      return <TermsAndConditionsGDC {...props} />;
    case Portal.Gasguajira:
      return <TermsAndConditionsGDG {...props} />;
    case Portal.Efigas:
      return <TermsAndConditionsGDC {...props} />;
    default:
      return <></>;
  }
};

export default TermsAndConditions;
