const IDENTIFICATION_TYPES = {
  CC: 'CC',
  PP: 'PP',
  CE: 'CE'
};

export const RefinanceStep = {
  PersonalData: 0,
  PaymentPlan: 1,
  Terms: 2,
  Summary: 3,
  Request: 4
};

export const RefinanceStepLabel = {
  [RefinanceStep.PersonalData]: 'Datos personales',
  [RefinanceStep.PaymentPlan]: 'Plan de pagos',
  [RefinanceStep.Terms]: 'Acuerdo',
  [RefinanceStep.Summary]: 'Resumen',
  [RefinanceStep.Request]: 'Solicitud'
};

export const SummaryPanel = {
  Contract: 'contract',
  PersonalData: 'personalData',
  PaymentPlan: 'paymentPlan',
  Terms: 'terms'
};

export const FORM_ID = 'Refinance_form';

export const REFINANCE_DEFERRED_STEPS = [
  {
    label: RefinanceStepLabel[RefinanceStep.PersonalData]
  },
  {
    label: RefinanceStepLabel[RefinanceStep.PaymentPlan]
  },
  {
    label: RefinanceStepLabel[RefinanceStep.Terms]
  },
  {
    label: RefinanceStepLabel[RefinanceStep.Summary]
  },
  {
    label: RefinanceStepLabel[RefinanceStep.Request]
  }
];

export const REFINANCE_CURRENT_STEPS = [
  {
    label: RefinanceStepLabel[RefinanceStep.PersonalData]
  },
  {
    label: RefinanceStepLabel[RefinanceStep.PaymentPlan]
  },
  {
    label: RefinanceStepLabel[RefinanceStep.Terms]
  },
  {
    label: RefinanceStepLabel[RefinanceStep.Summary]
  },
  {
    label: RefinanceStepLabel[RefinanceStep.Request]
  }
];

export const IDENTIFICATION_OPTIONS = [
  { label: 'C.C', value: IDENTIFICATION_TYPES.CC },
  { label: 'P.P', value: IDENTIFICATION_TYPES.PP },
  { label: 'C.E', value: IDENTIFICATION_TYPES.CE }
];
