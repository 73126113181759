import { TRANSACTION_STATUS } from '../../Payment/payment_enums';

export const PAGE_SIZE = 5;

export const transactionStatusDescription = {
  [TRANSACTION_STATUS.Approved]: 'Aprobado',
  [TRANSACTION_STATUS.Pending]: 'Pendiente',
  [TRANSACTION_STATUS.Rejected]: 'Rechazado'
};

export const couponConcepts = {
  Other: 0,
  Invoice: 1,
  CouponDuplicate: 2,
  DischargeDeferred: 5,
  Donation: 'donation',
  Refinancing: 'refinancing',
  OsfPaidCoupons: 'osfPaidCoupons',
  PartialPayment: 6,
  DeferredPartialPayment: 7
};

export const couponConceptDescription = concept => {
  switch (concept) {
    case couponConcepts.Other:
      return 'Cupón';
    case couponConcepts.Invoice:
      return 'Factura';
    case couponConcepts.CouponDuplicate:
      return 'Duplicado';
    case couponConcepts.DischargeDeferred:
      return 'Diferido';
    case couponConcepts.Donation:
      return 'Donación';
    case couponConcepts.Refinancing:
      return 'Financiación';
    case couponConcepts.OsfPaidCoupons:
      return 'Cupón otros medios';
    case couponConcepts.PartialPayment:
      return 'Abono a deuda';
    case couponConcepts.DeferredPartialPayment:
      return 'Abono diferido';
    default:
      return 'Cupón';
  }
};

export const debounceInputs = ['contract', 'transactionId'];
