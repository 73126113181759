import React, {
  Fragment,
  useContext,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import {
  Grid,
  Typography,
  Tabs,
  Tab,
  Box,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import BaseButton from '../Buttons/BaseButton';
import BaseDialog from '../Dialogs/BaseDialog';
import NumberInput from '../Inputs/NumberInput';
import TextInput from '../Inputs/TextInput';
import TextAreaInput from '../Inputs/TextAreaInput';
import ControlledCalendar from '../Controlled/ControlledCalendar';
import PhoneVerificationDialog from '../Dialogs/PhoneVerificationDialog';
import SweetAlert from '../Alerts/SweetAlert';
import { SlideUpTransition } from '../Transitions/Transitions';
import FlagColombia from '../CustomIcons/Flags/FlagColombia';

import { AlertsDispatchContext } from '../../Contexts/AlertsContext';

import { AgendaAPI } from '../../API/Revision/RevisionAPI';
import { submitExternalForm } from '../../Utils/Misc/Form';
import { formatAddress } from '../../Utils/Format/Address';

import { useForm, Controller } from 'react-hook-form';
import moment from 'moment-timezone';
import * as yup from 'yup';
import clsx from 'clsx';
import { Company } from '../../Configs/general';
import { getRecaptchaToken } from '../../Utils/Recaptcha';
import { RecaptchaAction } from '../../Enums/recaptcha';
import { isPrintableAscii } from '../../Utils/Misc/String';
import Recaptcha from '../Captcha/Recaptcha';
import { config } from '../../Configs';
import { ROUTE_NAMES } from '../../Routes/Routes';
import {
  TERMS,
  TERMS_ROUTES
} from '../../Views/Information/Information/Information';
import { PrivacyPoliciesLink } from '../../Configs/Links';

const clientInfoSchema = yup.object({
  phone: yup
    .string()
    .trim()
    .required('*Requerido')
    .length(10, 'Debe tener 10 dígitos'),
  phoneCountryCode: yup
    .string()
    .trim()
    .required('*Requerido'),
  fullName: yup
    .string()
    .trim()
    .max(80, 'Máximo 80 caracteres')
    .required('*Requerido'),
  observations: yup
    .string()
    .trim()
    .max(500, 'Máximo 500 caracteres')
    .optional(),
  email: yup
    .string()
    .trim()
    .lowercase()
    .email('Debes ingresar un correo válido')
    .test(
      'is-ascii',
      'Este campo no puede contener tildes ni caracteres especiales',
      isPrintableAscii
    )
});

const Shift = {
  Morning: 'mañana',
  Afternoon: 'tarde'
};

const SchedulingDialog = props => {
  const {
    open,
    setDialog,
    requestCallback,
    authToken,
    agendaData,
    isEdit,
    selectedContract,
    withoutObservation = true,
    linkToMoreInfo,
    contractor,
    currentUser
  } = props;

  // * CONTEXTS
  const setAlert = useContext(AlertsDispatchContext);

  // * HOOKS
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [agenda, setAgenda] = useState([]);
  const [availableDates, setAvailableDates] = useState([]);
  const [openVerificationDialog, setOpenVerificationDialog] = useState(false);
  const [formData, setFormData] = useState(null);
  const [agendaBoundary, setAgendaBoundary] = useState({});
  const [captcha, setCaptcha] = useState(null);
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const [terms, setTerms] = useState(false);

  const inputRef = useRef(null);
  const recaptchaRef = useRef(null);

  const incomingValues = agendaData && agendaData.data.client;

  const dateAndShift = useMemo(() => {
    if (agendaData && agendaData.scheduledDate) {
      return agendaData;
    }
  }, [agendaData]);

  const targetForm = 'SchedulingDialogForm';
  const initialShift = incomingValues ? dateAndShift.shift : Shift.Morning;

  const defaultValuesCurrentUser = currentUser
    ? {
        fullName: `${currentUser.firstName} ${currentUser.lastName}`,
        phone: currentUser.phone,
        phoneCountryCode: currentUser.phoneCountryCode,
        selectedDate: null,
        observations: '',
        email: ''
      }
    : {};

  const defaultValuesIncomingValues = incomingValues
    ? {
        fullName: incomingValues.name,
        phone: incomingValues.phone,
        phoneCountryCode: '57',
        selectedDate: moment(dateAndShift.scheduledDate)
          .tz('America/Bogota')
          .toDate(),
        observations: incomingValues.comment,
        email: incomingValues.email
      }
    : {};

  const initialValues = isEdit
    ? defaultValuesIncomingValues
    : defaultValuesCurrentUser;

  const { handleSubmit, register, errors, control, watch, setValue } = useForm({
    defaultValues: {
      shift: initialShift,
      ...initialValues
    },
    validationSchema: clientInfoSchema
  });

  const { selectedDate, shift } = watch();

  const onCaptchaResolved = useCallback(
    token => {
      setCaptcha(token);
    },
    [setCaptcha]
  );

  const onCaptchaExpired = useCallback(() => {
    setCaptcha(null);
  }, [setCaptcha]);

  const onCaptchaLoaded = useCallback(() => {
    setTimeout(() => {
      setLoadingCaptcha(false);
    }, 1000);
  }, [setLoadingCaptcha]);

  const isInvalidCaptcha =
    loadingCaptcha ||
    captcha === 'disable-captcha-validation' ||
    Boolean(!captcha);

  const onChangeTerms = useCallback(() => {
    setTerms(!terms);
  }, [terms]);

  const submitHandler = useCallback(() => {
    submitExternalForm(targetForm);
  }, []);

  const handleShiftChange = event => {
    const newValue = event[1];
    setValue('selectedDate', null);
    let shiftDates = [];
    switch (newValue) {
      case Shift.Morning: {
        shiftDates = agenda.filter(item =>
          item.jornada.includes(Shift.Morning)
        );
        break;
      }
      case Shift.Afternoon: {
        shiftDates = agenda.filter(item =>
          item.jornada.includes(Shift.Afternoon)
        );
        break;
      }
      default:
        break;
    }
    setAvailableDates(getDates(shiftDates));
    return newValue;
  };

  const canSubmit = Boolean(!loading && agenda.length > 0 && selectedDate);

  const resetCaptcha = useCallback(() => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
      setCaptcha(null);
    }
  }, []);

  // * Handle Form Submit
  const onSubmit = useCallback(
    async data => {
      if (!canSubmit) {
        return;
      }

      setFormData({
        fullName: data.fullName,
        email: data.email,
        phone: data.phone,
        phoneCountryCode: data.phoneCountryCode,
        selectedDate: data.selectedDate.toISOString(),
        observations: data.observations,
        shift: data.shift
      });

      setOpenVerificationDialog(true);
    },
    [canSubmit]
  );

  const successCallback = useCallback(async () => {
    setLoading(true);

    await requestCallback({
      ...formData
    });

    setLoading(false);
    setDialog(false);
  }, [setDialog, requestCallback, formData]);

  const handleClose = useCallback(() => {
    setDialog(false);
  }, [setDialog]);

  const getDates = availableAgenda => {
    const dates = availableAgenda.map(agendaItem => {
      return agendaItem.fecha;
    });
    return dates;
  };

  const tileDisabled = ({ date, view }) => {
    if (view === 'month') {
      return !availableDates.find(dDate => moment(dDate).isSame(date, 'day'));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const recaptchaToken = authToken
        ? null
        : await getRecaptchaToken(RecaptchaAction.PeriodicRevisionAgenda);
      const agendaResponse = await AgendaAPI(
        authToken,
        selectedContract.id,
        recaptchaToken
      );

      if (agendaResponse.success) {
        const responseData = agendaResponse.data;
        setAgenda(responseData.agenda);

        const shiftDates = responseData.agenda.filter(item =>
          item.jornada.includes(initialShift)
        );

        setAgendaBoundary({
          minDate: moment(responseData.minDate).toDate(),
          maxDate: moment(responseData.maxDate).toDate()
        });

        setAvailableDates(getDates(shiftDates));
        setLoading(false);
      } else {
        setLoading(false);
        setAlert({
          type: 'error',
          message: 'Error al obtener la agenda'
        });
      }
    };

    fetchData();
  }, [authToken, selectedContract, initialShift, setAlert]);

  const renderActions = () => {
    return (
      <Fragment>
        <BaseButton
          onClick={handleClose}
          variant="outlined"
          color="primary"
          size="small"
          disabled={loading}
        >
          Cancelar
        </BaseButton>
        <BaseButton
          onClick={submitHandler}
          type="submit"
          color="primary"
          size="small"
          form="SchedulingDialogForm"
          autoFocus
          disabled={
            !canSubmit || (!currentUser && (isInvalidCaptcha || !terms))
          }
        >
          {isEdit ? 'Editar' : 'Agendar'}
        </BaseButton>
      </Fragment>
    );
  };
  const renderContent = () => {
    return (
      <div className={classes.content}>
        <Typography className={classes.requestText} paragraph>
          Por este medio agendarás la revisión y certificación de tu instalación
          con {Company.name}. El agendamiento lo efectuará el organismo
          inspector <span className={classes.textBold}>{contractor}</span> y se
          realizará para {Company.contractConjugation.regular.singular.article}{' '}
          <span className={classes.textBold}>#{selectedContract.id}</span> con
          dirección:{' '}
          <span className={clsx([classes.textBold, classes.textCaps])}>
            {formatAddress(selectedContract, true)}
          </span>{' '}
        </Typography>
        <Typography className={classes.selectShiftText}>
          Seleccione la jornada en que desea agendar su visita:
        </Typography>
        <form
          onSubmit={handleSubmit(onSubmit)}
          id={targetForm}
          className={classes.formContainer}
        >
          <Grid container justify="center" className={classes.gridContainer}>
            <Grid item xs={12} sm={12} className={classes.tabsContainer}>
              <Box sx={{ width: '100%' }}>
                <Controller
                  as={
                    <Tabs
                      indicatorColor="primary"
                      variant="fullWidth"
                      aria-label="shift tabs"
                      centered
                    >
                      <Tab
                        label="Mañana"
                        value={Shift.Morning}
                        className={classes.tab}
                        disabled={loading}
                      />
                      <Tab
                        label="Tarde"
                        value={Shift.Afternoon}
                        className={classes.tab}
                        disabled={loading}
                      />
                    </Tabs>
                  }
                  onChange={handleShiftChange}
                  name="shift"
                  control={control}
                />
              </Box>
            </Grid>
            {!loading && agenda.length === 0 && (
              <Grid item xs={12} className={classes.noAgendaAlert}>
                <SweetAlert
                  id="SchedulingDialog_div_phoneValidation_info"
                  type="warning"
                  icon={false}
                  classes={{
                    root: classes.sweetAlert,
                    message: classes.sweetAlertText
                  }}
                  message={
                    <Fragment>
                      Lo sentimos. No hay disponibilidad para agendamientos en
                      estos momentos.
                    </Fragment>
                  }
                />
              </Grid>
            )}

            <Grid item className={classes.calendarContainer}>
              <ControlledCalendar
                tileDisabled={tileDisabled}
                control={control}
                name="selectedDate"
                loading={loading}
                disabled={loading}
                showNeighboringMonth={false}
                minDate={agendaBoundary.minDate}
                maxDate={agendaBoundary.maxDate}
                minDetail="year"
                next2Label={null}
                prev2Label={null}
              />
            </Grid>
          </Grid>
          {selectedDate && (
            <>
              <Grid
                container
                justify="center"
                className={classes.gridContainer}
              >
                <Grid item xs={12} className={classes.completeGridItem}>
                  <Typography className={classes.requestText}>
                    <span className={classes.textBold}>
                      Fecha seleccionada:{' '}
                    </span>
                    {moment(selectedDate).format('DD [de] MMMM [de] YYYY')} en
                    la {shift}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} className={classes.completeGridItem}>
                  <TextInput
                    id="SchedulingDialog_input_name"
                    inputRef={register}
                    label="Nombre Completo"
                    margin="none"
                    inputProps={{ maxLength: 80 }}
                    required
                    fullWidth
                    disabled={loading}
                    error={Boolean(errors.fullName)}
                    helperText={errors.fullName && errors.fullName.message}
                    name="fullName"
                  />
                </Grid>
                {!currentUser && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    className={classes.completeGridItem}
                  >
                    <TextInput
                      id="SchedulingDialog_input_email"
                      inputRef={register}
                      label="Correo electrónico"
                      margin="none"
                      required={!currentUser}
                      fullWidth
                      disabled={loading}
                      error={Boolean(errors.email)}
                      helperText={errors.email && errors.email.message}
                      name="email"
                    />
                  </Grid>
                )}
                <Grid container justify="center">
                  <Grid item xs={12} sm={4} className={classes.pairGridItem}>
                    <Controller
                      as={
                        <NumberInput
                          id="SchedulingDialog_input_countryCode"
                          autoComplete="tel-country-code"
                          required
                          InputProps={{
                            startAdornment: (
                              <FlagColombia
                                size={20}
                                style={{ flexShrink: 0 }}
                              />
                            ),
                            autoComplete: 'new-password'
                          }}
                          format="+###"
                          placeholder="57"
                          fullWidth
                          label="Prefijo"
                          disabled={loading}
                          error={Boolean(errors.phoneCountryCode)}
                          helperText={
                            errors.phoneCountryCode &&
                            errors.phoneCountryCode.message
                          }
                          margin="none"
                        />
                      }
                      control={control}
                      name="phoneCountryCode"
                      onChangeName="onValueChange"
                      onChange={values => {
                        if (values[0]) {
                          return values[0].value;
                        }
                        return '';
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} className={classes.pairGridItem}>
                    <Controller
                      as={
                        <NumberInput
                          id="SchedulingDialog_input_phone"
                          autoComplete="tel-local"
                          required
                          fullWidth
                          margin="none"
                          label="Número celular"
                          disabled={loading}
                          error={Boolean(errors.phone)}
                          helperText={errors.phone && errors.phone.message}
                          inputProps={{
                            autoComplete: 'new-password'
                          }}
                        />
                      }
                      control={control}
                      name="phone"
                      onChangeName="onValueChange"
                      onChange={values => {
                        if (values[0]) {
                          return values[0].value;
                        }
                        return '';
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid className={classes.completeGridItem} item xs={12}>
                  <SweetAlert
                    id="SchedulingDialog_div_phoneValidation_info"
                    icon={false}
                    classes={{
                      root: classes.sweetAlert,
                      message: classes.sweetAlertText
                    }}
                    message={
                      <Fragment>
                        Validaremos tu número celular antes de agendar tu
                        visita.
                      </Fragment>
                    }
                  />
                </Grid>
                {!withoutObservation && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    className={classes.completeGridItem}
                  >
                    <TextAreaInput
                      id="SchedulingDialog_input_observations"
                      inputRef={register}
                      multiline
                      rows={2}
                      label="Observaciones"
                      margin="none"
                      fullWidth
                      name="observations"
                      disabled={loading}
                      rowsMax={5}
                      maxLength={500}
                      helperText={
                        errors.observations && errors.observations.message
                      }
                      error={Boolean(errors.observations)}
                    />
                  </Grid>
                )}
              </Grid>
              {!currentUser && (
                <Grid
                  container
                  direction="column"
                  className={classes.termsContainer}
                >
                  <Grid item xs={12} sm={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="Signup_input_terms"
                          checked={terms}
                          onClick={onChangeTerms}
                          icon={
                            <CheckBoxOutlineBlankIcon
                              fontSize="small"
                              color="primary"
                            />
                          }
                          checkedIcon={
                            <CheckBoxIcon fontSize="small" color="primary" />
                          }
                          value="checkedI"
                        />
                      }
                      label={
                        <Typography className={classes.termsText}>
                          He leído y acepto los{' '}
                          <a
                            className={classes.link}
                            href={`${ROUTE_NAMES.information}/${
                              TERMS_ROUTES[TERMS.introduction]
                            }`}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            términos y condiciones
                          </a>{' '}
                          de uso, la{' '}
                          <a
                            href={PrivacyPoliciesLink}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            política y aviso de privacidad
                          </a>{' '}
                          y autorizo el tratamiento de mis datos personales.
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    xs={12}
                    ref={inputRef}
                    className={classes.recaptchaContainer}
                  >
                    <Recaptcha
                      captchaRef={recaptchaRef}
                      referenceEl={inputRef}
                      heightScale={0.75}
                      locale={'es'}
                      sitekey={config.recaptcha.siteKey}
                      onResolved={onCaptchaResolved}
                      onExpired={onCaptchaExpired}
                      onLoaded={onCaptchaLoaded}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </form>
        <Typography className={classes.textSmall}>
          <span>
            Recuerda que puedes realizar la revisión con otro{' '}
            <span className={classes.linkText} onClick={linkToMoreInfo}>
              Organismo de inspección acreditado
            </span>
            .
          </span>
          A través de esta solicitud únicamente podremos tramitar tu solicitud
          de revisión periódica. Peticiones, quejas o recursos diferentes a la
          revisión periódica no serán gestionadas por este medio.
        </Typography>
      </div>
    );
  };

  const isMobileSize = isWidthDown('sm', props.width);

  return (
    <Fragment>
      {openVerificationDialog && (
        <PhoneVerificationDialog
          open={openVerificationDialog}
          setDialog={setOpenVerificationDialog}
          formPhone={formData.phone}
          formPhoneCountryCode={formData.phoneCountryCode}
          title="Validar número celular"
          setAlert={setAlert}
          successCallback={successCallback}
          captchaValue={captcha}
          resetCaptcha={resetCaptcha}
        />
      )}
      <BaseDialog
        className={classes.dialogRoot}
        open={open}
        loading={loading}
        handleClose={handleClose}
        title="Agendamiento de Revisión Periódica"
        actions={renderActions}
        content={renderContent}
        fullScreen={isMobileSize}
        TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
        contentStyle={classes.dialogContent}
        disableBackdropClick={loading}
      />
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  dialogRoot: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing()
    }
  },
  text: {
    fontSize: 13,
    color: theme.palette.text.primary,
    width: '100%',
    textAlign: 'justify'
  },
  textSmall: {
    fontSize: 11,
    color: theme.palette.text.disabled,
    marginTop: theme.spacing(),
    textAlign: 'justify'
  },
  textBold: {
    fontWeight: 500
  },
  textCaps: {
    textTransform: 'capitalize'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  dialogContent: {
    paddingBottom: theme.spacing(2)
  },
  formContainer: {
    marginBottom: theme.spacing(2),
    minWidth: '60%'
  },
  sweetAlert: {
    width: '100%'
  },
  gridContainer: {
    width: '100%',
    margin: 0,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  pairGridItem: {
    margin: theme.spacing(0, 0, 3, 0),
    [theme.breakpoints.up('sm')]: {
      '&:nth-child(odd)': {
        paddingRight: theme.spacing()
      },
      '&:nth-child(even)': {
        paddingLeft: theme.spacing()
      }
    }
  },
  completeGridItem: {
    margin: theme.spacing(0, 0, 3, 0),
    '&:last-child': {
      margin: 0
    }
  },
  calendarContainer: {
    margin: theme.spacing(0, 0, 3, 0),
    width: '100%'
  },
  clickeable: {
    cursor: 'pointer'
  },
  noAgendaAlert: {
    marginBottom: theme.spacing(2)
  },
  tabsContainer: {
    marginBottom: theme.spacing(2)
  },
  requestText: {
    fontSize: 13,
    color: theme.palette.text.primary,
    width: '100%',
    textAlign: 'justify'
  },
  linkText: {
    cursor: 'pointer',
    textDecoration: 'underline',
    fontWeight: 500
  },
  selectShiftText: {
    fontSize: 13,
    color: theme.palette.text.primary,
    width: '100%',
    textAlign: 'justify',
    marginBottom: theme.spacing(2)
  },
  tab: {
    fontSize: 14
  },
  termsContainer: {
    padding: [[22, 0, 0, 0]],
    [theme.breakpoints.up('sm')]: {
      padding: [[theme.spacing(2), 0, 0, 0]]
    }
  },
  recaptchaContainer: {
    padding: 0,
    overflow: 'hidden'
  }
}));

export default withWidth()(SchedulingDialog);
