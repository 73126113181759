import React, { useCallback, useContext, useEffect, useState } from 'react';
import _get from 'lodash/get';
import {
  ContractSelectedContext,
  ContractSetSelected,
  ContractsContext
} from '../../../Contexts/ContractsContext';
import {
  UserContext,
  UserDispatchContext
} from '../../../Contexts/UserContext';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';
import {
  redirectOnAuthFailure,
  extractErrorMessage
} from '../../../Utils/Errors/Errors';
import { PutSelectContract } from '../../../API/Contracts/ContractsAPI';
import { history } from '../../../Routes/history';
import { ROUTE_NAMES } from '../../../Routes/Routes';
import PeriodicRevisionQuery from './PeriodicRevisionQuery';
import PeriodicRevisionMain from './PeriodicRevisionMain';
import { logoutUser } from '../../../Utils/User/Actions';

const PeriodicRevisionGDC = ({ match }) => {
  const currentUser = useContext(UserContext);
  const authToken = _get(currentUser, 'token');
  const setCurrentUser = useContext(UserDispatchContext);
  const contracts = useContext(ContractsContext);
  const setAlert = useContext(AlertsDispatchContext);
  const selectedContract = useContext(ContractSelectedContext);
  const setSelectedContract = useContext(ContractSetSelected);

  const [selectedContractInfo, setSelectedContractInfo] = useState(null);
  const [validatedContract, setValidatedContract] = useState(false);

  const contractIdFromParams = match && match.params && match.params.contractId;

  const onSelectContract = useCallback(
    async contract => {
      setSelectedContract(contract);
      const response = await PutSelectContract(currentUser.token, contract.id);
      if (response.success) {
        // no success message, its all good
      } else {
        if (
          redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
        ) {
          return;
        }
        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
      }
    },
    [setSelectedContract, currentUser, setCurrentUser, setAlert]
  );

  useEffect(() => {
    if (contractIdFromParams && !contractIdFromParams.match(/^[0-9]+$/)) {
      history.replace('/');
      return;
    }

    if (authToken && contractIdFromParams && contracts) {
      const contractFromParams = contracts.find(
        contract => contract.id === parseInt(contractIdFromParams, 10)
      );
      if (!contractFromParams) {
        history.replace('/');
        return;
      }
      onSelectContract(contractFromParams);
      history.replace(ROUTE_NAMES.revision);
      return;
    }

    if (!authToken && contractIdFromParams) {
      setSelectedContractInfo({ id: contractIdFromParams });
      history.replace(ROUTE_NAMES.revision);
      return;
    }

    if (authToken && !selectedContract) {
      history.replace(ROUTE_NAMES.contracts);
      return;
    }

    if (authToken && selectedContract.type === 3) {
      history.replace('/');
      return;
    }

    if (authToken && selectedContract) {
      setSelectedContractInfo(selectedContract);
      setValidatedContract(true);
      return;
    }
  }, [
    authToken,
    contractIdFromParams,
    contracts,
    onSelectContract,
    selectedContract,
    selectedContractInfo
  ]);

  if (authToken && (!contracts || contracts.length === 0)) {
    return <></>;
  }

  return (
    <>
      {!validatedContract && (
        <PeriodicRevisionQuery
          selectedContractInfo={selectedContractInfo}
          setSelectedContractInfo={setSelectedContractInfo}
          setValidatedContract={setValidatedContract}
        />
      )}
      {validatedContract && (
        <PeriodicRevisionMain selectedContractInfo={selectedContractInfo} />
      )}
    </>
  );
};

export default PeriodicRevisionGDC;
