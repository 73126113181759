import React from 'react';
import clsx from 'clsx';
import ButtonBase from '@material-ui/core/ButtonBase';
import { makeStyles } from '@material-ui/core/styles';

const FlatButton = props => {
  const classes = useStyles();
  const {
    id,
    color = 'primary',
    variant = 'contained',
    loading,
    disabled,
    className,
    disableRipple = true,
    ...rest
  } = props;

  return (
    <ButtonBase
      id={id}
      disableRipple={disableRipple}
      disabled={loading || disabled}
      variant={variant}
      color={color}
      className={clsx(classes.buttonBase, className)}
      {...rest}
    >
      {props.children || props.text || '{Button}'}
    </ButtonBase>
  );
};

const useStyles = makeStyles(theme => ({
  buttonBase: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    lineHeight: 1.29,
    color: theme.palette.text.greyDark,
    '&:hover': {
      color: theme.palette.color.primary,
      fill: theme.palette.color.primary
    }
  }
}));
export default FlatButton;
