import React from 'react';

const LogoutIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 20}
      height={props.size || 20}
      viewBox="0 0 56 56"
      {...props}
    >
      <path data-name="Rect\xE1ngulo 243" fill="none" d="M0 0h56v56H0z" />
      <path
        d="M34.704 39.974v5.987a5.8 5.8 0 01-5.587 5.987H6.771a5.8 5.8 0 01-5.587-5.988V10.04a5.8 5.8 0 015.587-5.987h21.23c3.085 0 6.7 2.681 6.7 5.987v5.987m8.939 23.947L54.814 28 43.64 16.026M16.827 28h37.99"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </svg>
  );
};

export default LogoutIcon;
