import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback
} from 'react';
import {
  Container,
  Grid,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  Typography
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import _get from 'lodash/get';
import _capitalize from 'lodash/capitalize';
import moment from 'moment';

import { UserContext } from '../../../Contexts/UserContext';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';

import Title from '../../../Components/Labels/Title';
import LinkButton from '../../../Components/Buttons/LinkButton';
import SvgPaginationBack from '../../../Components/CustomIcons/PaginationBack';
import SweetAlert from '../../../Components/Alerts/SweetAlert';
import ModalProgress from '../../../Components/Progress/Modal/ModalProgress';
import BaseButton from '../../../Components/Buttons/BaseButton';
import RequestType from '../Utils/RequestType';
import RequestStatus from '../Utils/RequestStatus';
import RequestIcon from '../Utils/RequestIcon';
import RequestInfo from '../Utils/RequestInfo';

import { GetRequestAPI } from '../../../API/Requests/requestsAPI';
import { renderStatus, requestStatus, requestTypes } from '../Utils/enums';
import { history } from '../../../Routes/history';
import { extractErrorMessage } from '../../../Utils/Errors/Errors';
import { ROUTE_NAMES } from '../../../Routes/Routes';
import LoginDialog from '../../Login/LoginDialog';

const RequestGDC = ({ match }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down(750));
  const requestId = match.params.request;

  const mounted = useRef(true);

  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const setAlert = useContext(AlertsDispatchContext);

  const authToken = _get(currentUser, 'token');

  // * STATE HOOKS
  const [loading, setLoading] = useState(true);
  const [request, setRequest] = useState({});

  const {
    id,
    contractId,
    type,
    data,
    status,
    createdAt,
    inProcessAt,
    canceledAt,
    attendedAt
  } = request;

  useEffect(() => {
    const getRequest = async () => {
      setLoading(true);
      const response = await GetRequestAPI(authToken, requestId);
      if (response.success) {
        setRequest(response.data.data.data.request);
      } else {
        history.replace('/solicitudes');
        mounted.current = false;
        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
      }

      if (mounted.current) {
        setLoading(false);
      }
    };

    if (!authToken) {
      return;
    }

    getRequest();
  }, [authToken, requestId, setAlert]);

  const getSteps = () => {
    return [
      {
        text: renderStatus(requestStatus.registered).statusText,
        date: createdAt && moment(createdAt).format('DD/MM/YYYY'),
        completed: true
      },
      status === requestStatus.cancelled
        ? {
            text: renderStatus(requestStatus.cancelled).statusText,
            date: canceledAt && moment(canceledAt).format('DD/MM/YYYY'),
            completed: true,
            error: true,
            icon: <CancelIcon color="error" />
          }
        : {
            text: renderStatus(requestStatus.inProcess).statusText,
            date:
              (status === requestStatus.inProcess ||
                status === requestStatus.attended) &&
              inProcessAt &&
              moment(inProcessAt).format('DD/MM/YYYY'),
            completed:
              status === requestStatus.inProcess ||
              status === requestStatus.attended
          },
      {
        text: renderStatus(requestStatus.attended).statusText,
        date:
          status === requestStatus.attended &&
          attendedAt &&
          moment(attendedAt).format('DD/MM/YYYY'),
        completed: status === requestStatus.attended
      }
    ];
  };

  const handleClick = () => {
    if (type === requestTypes.brillaVisit) {
      history.push(ROUTE_NAMES.brilla);
    } else if (type === requestTypes.couponDuplicate) {
      history.push(ROUTE_NAMES.home);
    } else if (type === requestTypes.ownershipChange) {
      history.push(ROUTE_NAMES.ownership);
    } else if (
      type === requestTypes.dischargeDeferred ||
      type === requestTypes.deferredWithCurrent ||
      type === requestTypes.deferredPartialPayment
    ) {
      history.push(ROUTE_NAMES.debtStatus);
    } else if (
      type === requestTypes.periodicRevision ||
      type === requestTypes.scheduledRevision
    ) {
      history.push(ROUTE_NAMES.revision);
    } else if (type === requestTypes.debtNegotiation) {
      history.push(ROUTE_NAMES.currentDebtSummary);
    } else if (type === requestTypes.changeConditions) {
      history.push(ROUTE_NAMES.deferredDebtSummary);
    } else if (type === requestTypes.brillaInsurance) {
      history.push(ROUTE_NAMES.insurancesHome);
    }
  };

  const steps = request && getSteps();

  const handleCloseLoginDialog = useCallback(() => {
    history.replace('/');
  }, []);

  if (!currentUser) {
    return <LoginDialog open={true} setOpen={handleCloseLoginDialog} />;
  }

  return (
    <Fragment>
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.innerRoot}>
          {loading ? (
            <ModalProgress />
          ) : (
            <>
              <LinkButton to="/solicitudes" className={classes.backButton}>
                <SvgPaginationBack
                  width="1em"
                  height="1em"
                  className={classes.backIcon}
                />
                <Title className={classes.title} title="Mis solicitudes" />
              </LinkButton>

              <div className={classes.requestContainer}>
                <Grid
                  container
                  wrap="nowrap"
                  alignItems="center"
                  className={classes.headerContainer}
                >
                  <Grid item>
                    <Grid container alignItems="center">
                      {mediumScreen && (
                        <RequestIcon className={classes.typeIcon} type={type} />
                      )}
                    </Grid>
                  </Grid>

                  <Grid item className={classes.headerInfo}>
                    <Grid container alignItems="center" justify="space-between">
                      <Grid item className={classes.headerInfoColumn}>
                        <Grid container alignItems="center">
                          <RequestType type={type} withIcon={!mediumScreen} />
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        container
                        justify={mediumScreen ? 'flex-start' : 'center'}
                        className={classes.headerInfoColumn}
                      >
                        <Typography>Solicitud nº {id}</Typography>
                      </Grid>

                      <Grid
                        item
                        container
                        justify={mediumScreen ? 'flex-start' : 'flex-end'}
                        className={classes.headerInfoColumn}
                      >
                        <Grid item>
                          <RequestStatus status={status} withLabel={false} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <div className={classes.stepperContainer}>
                  <Stepper
                    classes={{
                      root: classes.stepper
                    }}
                    alternativeLabel
                  >
                    {steps.map(step => (
                      <Step
                        key={step.text}
                        classes={{ horizontal: classes.horizontal }}
                        completed={step.completed}
                      >
                        <StepLabel
                          error={step.error}
                          StepIconProps={{
                            classes: {
                              root: classes.stepIcon
                            }
                          }}
                          classes={{
                            labelContainer: classes.alternativeLabel,
                            label: classes.label
                          }}
                        >
                          {step.text}
                          <div className={classes.stepDate}>{step.date}</div>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>

                  {status === requestStatus.cancelled &&
                    data.output &&
                    (data.output.failureReason ||
                      data.output.causalDescription) && (
                      <Grid container justify="center">
                        <Grid item>
                          <SweetAlert
                            noIcon
                            id="Failed_request_warning"
                            type="error"
                            classes={{
                              root: classes.sweetAlert
                            }}
                            message={
                              <Fragment>
                                <strong>Causal de rechazo:</strong>{' '}
                                {_capitalize(
                                  data.output.failureReason ||
                                    data.output.causalDescription
                                )}
                              </Fragment>
                            }
                          />
                        </Grid>
                      </Grid>
                    )}
                </div>

                <RequestInfo
                  contractId={contractId}
                  data={data}
                  type={type}
                  status={status}
                />
              </div>

              {status === requestStatus.cancelled && (
                <BaseButton
                  onClick={handleClick}
                  className={classes.button}
                  size="small"
                >
                  Solicitar de nuevo
                </BaseButton>
              )}
            </>
          )}
        </Container>
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  root: {
    position: 'relative',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  innerRoot: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(4),
    fontSize: 16,
    [theme.breakpoints.down(750)]: {
      fontSize: 15
    },
    [theme.breakpoints.down(450)]: {
      fontSize: 14
    }
  },
  backButton: {
    fontSize: '1em',
    alignSelf: 'flex-start',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(750)]: {
      marginTop: theme.spacing(5)
    }
  },
  title: { margin: 0 },
  backIcon: {
    marginRight: theme.spacing()
  },
  requestContainer: {
    animation: `$fadeIn 250ms ${theme.transitions.easing.easeInOut}`,
    backgroundColor: theme.palette.background.cardDark,
    borderRadius: theme.spacing(0.75),
    padding: '1em',
    border: `1px solid ${theme.palette.background.border}`,
    marginBottom: '0.5em',
    '&:last-of-type': {
      marginBottom: 0
    }
  },
  headerContainer: {
    paddingBottom: '1em',
    borderBottom: `1px solid ${theme.palette.background.border}`,
    [theme.breakpoints.up(750)]: {
      paddingLeft: '1em',
      paddingRight: '1em'
    }
  },
  headerInfo: {
    flexGrow: 1
  },
  typeIcon: {
    [theme.breakpoints.down(750)]: {
      width: '2.5em',
      height: '2.5em'
    }
  },
  headerInfoColumn: {
    [theme.breakpoints.up(750)]: {
      width: 'calc(4 / 12 * 100%)'
    }
  },
  // Stepper classes
  stepperContainer: {
    borderBottom: `1px solid ${theme.palette.background.border}`,
    marginBottom: '0.8em'
  },
  stepper: {
    backgroundColor: 'transparent',
    padding: '1em 0'
  },
  stepIcon: {
    width: '0.9em',
    height: '0.9em',
    [theme.breakpoints.down(450)]: {
      width: '0.7em',
      height: '0.7em'
    }
  },
  label: {
    fontWeight: `${theme.typography.fontWeightRegular} !important`,
    marginTop: '0.5em !important',
    fontSize: '0.9em',
    [theme.breakpoints.down(450)]: {
      fontSize: '0.8em'
    }
  },
  horizontal: {
    padding: 0
  },
  stepDate: {
    fontSize: '0.8em'
  },
  sweetAlert: {
    margin: '0 auto 1em',
    display: 'inline-block',
    paddingLeft: '1em',
    paddingRight: '1em'
  },
  button: {
    marginTop: theme.spacing(2),
    alignSelf: 'flex-end',
    [theme.breakpoints.down(450)]: {
      width: '100%'
    }
  }
}));

export default RequestGDC;
