import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useContext
} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid, Typography, Divider } from '@material-ui/core';
import clsx from 'clsx';

import YesNoButton from '../Buttons/YesNoButton';
import ContractAssociationDialog from '../../Views/Contracts/ContractAssociationDialog/ContractAssociationDialog';

import ValidCheckIcon from '@material-ui/icons/CheckCircleOutlined';
import InvalidCheckIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';

import {
  ContractsContext,
  ContractSelectedContext
} from '../../Contexts/ContractsContext';
import { AlertsDispatchContext } from '../../Contexts/AlertsContext';
import { Company } from '../../Configs/general';

const ContractStatusCard = props => {
  const classes = useStyles();
  const {
    contract,
    reasons = [],
    message = 'adquirir seguro',
    showAssociateContract = true,
    showReasons = true,
    infoContractMessage = null
  } = props;
  const { valid, holder } = contract;

  const userContracts = useContext(ContractsContext);
  const selectedContract = useContext(ContractSelectedContext);
  const setAlert = useContext(AlertsDispatchContext);

  const [associateContract, setAssociateContract] = useState(false);
  const [alreadyAssociated, setAlreadyAssociated] = useState(false);
  const [cardMessage, setCardMessage] = useState(false);

  const contractYesChecked = useCallback(() => {
    setAssociateContract(true);
  }, []);
  const contractNoChecked = useCallback(() => {
    setAssociateContract(false);
  }, []);

  useEffect(() => {
    const index = userContracts.findIndex(s => s.id === contract.id);
    setAlreadyAssociated(index !== -1);
  }, [contract.id, selectedContract, userContracts, alreadyAssociated]);

  useEffect(() => {
    if (infoContractMessage) {
      setCardMessage(infoContractMessage);
    } else {
      setCardMessage(`¡${Company.contractConjugation.capitalized.singular.main}
      ${valid ? '' : ' no'} apto para ${message}!`);
    }
  }, [setCardMessage, infoContractMessage, message, valid]);

  return (
    <Fragment>
      <ContractAssociationDialog
        open={associateContract}
        handleClose={contractNoChecked}
        contract={contract}
        setAlert={setAlert}
        dontRedirect
      />
      <div className={classes.card}>
        <Grid container alignItems="center" wrap="nowrap">
          {valid ? (
            <ValidCheckIcon
              className={clsx(classes.statusIcon, classes.validIcon)}
            />
          ) : (
            <InvalidCheckIcon
              className={clsx(classes.statusIcon, classes.invalidIcon)}
            />
          )}
          <Typography className={classes.data}>{cardMessage}</Typography>
        </Grid>
        {(showReasons || (!showReasons && valid)) && (
          <Divider className={classes.divider} />
        )}
        {valid ? (
          <Grid container className={classes.contractInfoContainer}>
            <Grid container item direction="column">
              <Typography className={classes.label}>
                Nombre del titular
              </Typography>
              <Typography className={classes.data}>
                {holder.name} {holder.lastName}
              </Typography>
            </Grid>
            <Grid container item direction="column">
              <Typography className={classes.label}>
                Dirección del predio
              </Typography>
              <Typography className={classes.data}>
                {contract.city}, {contract.department} {contract.address}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <>
            {showReasons && (
              <Grid>
                <Typography className={classes.margin}>
                  <strong>Razones:</strong>
                </Typography>
                <Grid container direction="row" wrap="nowrap">
                  {reasons.map(reason => {
                    return (
                      <Grid item key={`reason ${reason}`}>
                        <Typography className={clsx(classes.details)}>
                          <span className={clsx(classes.elipse)}>• </span>
                          {reason}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
          </>
        )}
      </div>
      {!alreadyAssociated && showAssociateContract && (
        <div className={classes.itemContainer}>
          <Typography className={classes.itemText}>
            ¿Desea asociar este{' '}
            {Company.contractConjugation.regular.singular.main} a su perfil?
          </Typography>
          <YesNoButton
            checked={associateContract}
            yesChecked={contractYesChecked}
            noChecked={contractNoChecked}
            color="primary"
            small="small"
          />
        </div>
      )}
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: theme.custom.borderRadius,
    backgroundColor: theme.palette.background.cardDark,
    border: `1px solid ${theme.palette.background.border}`,
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: theme.spacing(2)
    }
  },
  contractInfoContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'column',
      '& > *': {
        marginBottom: theme.spacing(1)
      },
      '&:last-child': {
        marginBottom: 0
      }
    }
  },
  elipse: {
    fontSize: 14,
    color: theme.typography.color.black
  },
  title: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.2
  },
  tooltip: {
    margin: theme.spacing(1.5, 0)
  },
  label: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.greyDark,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 13
    }
  },
  data: {
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1.2,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 14
    }
  },
  total: {
    fontSize: 18,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 16
    }
  },
  productGlosary: {
    color: theme.palette.text.greyDark
  },
  button: {
    fontSize: 13,
    textTransform: 'capitalize',
    marginRight: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  details: {
    color: theme.palette.text.greyDark,
    marginBottom: theme.spacing(2)
  },
  margin: {
    marginBottom: theme.spacing(2)
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3)
  },
  statusIcon: { marginRight: theme.spacing(1) },
  validIcon: {
    fill: theme.palette.success.main
  },
  invalidIcon: {
    fill: theme.palette.error.main
  }
}));

export default ContractStatusCard;
