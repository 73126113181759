import React, { useEffect, useCallback, useRef } from 'react';
import { GetVersionAPI } from '../../API/ApplicationAPI';
import { Typography } from '@material-ui/core';
import { toast } from 'react-toastify';

const TOAST_ID = 'auto-update-toast';

// Every 5 minutes
const AUTOUPDATE_INTERVAL = 300000;

const Autoupdate = ({ currentVersion }) => {
  const timerRef = useRef(null);

  const handleClose = useCallback(() => {
    clearInterval(timerRef.current);
    toast.dismiss(TOAST_ID);
  }, []);

  const handleClick = useCallback(() => {
    window.location.reload(true);
  }, []);

  const notify = useCallback(() => {
    const toastMsg = () => (
      <Typography>
        Realizamos algunas mejoras en el portal, por favor{' '}
        <strong>actualiza la página</strong>.
      </Typography>
    );

    toast.info(toastMsg, {
      toastId: TOAST_ID,
      onClick: handleClick,
      onClose: handleClose,
      autoClose: false
    });
  }, [handleClick, handleClose]);

  useEffect(() => {
    const getVersion = async () => {
      const response = await GetVersionAPI();
      if (!response.success) {
        return;
      }

      const version = response.data.trim();

      if (version !== currentVersion) {
        notify();
      }
    };

    timerRef.current = setInterval(() => {
      getVersion();
    }, AUTOUPDATE_INTERVAL);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [currentVersion, notify]);

  return <></>;
};

export default Autoupdate;
