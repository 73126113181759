import React, { useContext, useState, useCallback } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Divider, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { numberWithDots } from '../../Utils/Format/MoneyFormat';
import clsx from 'clsx';

import { history } from '../../Routes/history';
import { ROUTE_NAMES } from '../../Routes/Routes';

import SearchIcon from '@material-ui/icons/Search';
import DownloadIcon from '../CustomIcons/DownloadIcon';
import CustomerServiceIcon from '../CustomIcons/CustomerService';

import BaseButton from '../Buttons/BaseButton';
import InsuranceAppointmentDialog from '../Dialogs/InsuranceAppointmentDialog';
import InsurancePoliciesQueryDialog from '../../Components/Dialogs/InsurancePoliciesQueryDialog';
import ModalProgress from '../Progress/Modal/ModalProgress';
import InsuranceDetailSkeleton from './InsuranceDetailSkeleton';

import { ContractSelectedContext } from '../../Contexts/ContractsContext';
import { UserContext } from '../../Contexts/UserContext';

import PractiseguroPDF from '../../Assets/docs/practiseguro-clausulas.pdf';
import PractiSeguroLogo from '../../Assets/images/img_practiseguro@2x.png';

import { downloadAsset } from '../../Utils/Misc/Assets';
import { GetAppointmentsAPI } from '../../API/BrillaInsurances/BrillaInsurancesAPI';
import { InsurancePlanIcon, REQUEST_TYPE } from '../../Enums/insurances';
import { Company } from '../../Configs/general';

const InsuranceDetailCard = props => {
  const { product, loading: loadingCard } = props;

  // * CONTEXTS
  const selectedContract = useContext(ContractSelectedContext);
  const currentUser = useContext(UserContext);

  // * STATE HOOKS
  const [openPoliciesDialog, setOpenPoliciesDialog] = useState(false);
  const [openAppointmentDialog, setOpenAppointmentDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  // * OTHER HOOKS
  const classes = useStyles();
  const isMobileSize = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  const handleOpenAppointmentDialog = useCallback(async () => {
    setLoading(true);

    const appointmentsResponse = await GetAppointmentsAPI(
      currentUser.token,
      selectedContract.id,
      currentUser.identification
    );

    if (appointmentsResponse.success && appointmentsResponse.data) {
      setLoading(false);
      history.push(ROUTE_NAMES.inProcessInsurancePage, {
        product,
        processData: {
          data: appointmentsResponse.data,
          requestType: REQUEST_TYPE.Appointment
        },
        contractId: selectedContract.id
      });
    } else {
      setLoading(false);
      setOpenAppointmentDialog(true);
    }
  }, [
    product,
    currentUser.identification,
    currentUser.token,
    selectedContract.id
  ]);

  const handleCloseAppointmentDialog = useCallback(() => {
    setOpenAppointmentDialog(false);
  }, [setOpenAppointmentDialog]);

  const handleClosePoliciesDialog = useCallback(() => {
    setOpenPoliciesDialog(false);
  }, [setOpenPoliciesDialog]);

  if (loadingCard) {
    return <InsuranceDetailSkeleton />;
  }

  const PlanIcon = InsurancePlanIcon[product.idPlan];

  return (
    <div>
      {loading && <ModalProgress message="Consultando agendamiento" />}
      <InsuranceAppointmentDialog
        contractId={selectedContract.id}
        product={product}
        open={openAppointmentDialog}
        hasPolicy={true}
        handleClose={handleCloseAppointmentDialog}
      />
      <InsurancePoliciesQueryDialog
        contractId={selectedContract.id}
        identification={currentUser.identification}
        open={openPoliciesDialog}
        handleClose={handleClosePoliciesDialog}
      />
      <div className={clsx(classes.card)}>
        <Grid container className={classes.cardContent}>
          <Grid item>
            <div className={classes.productLogoGrid}>
              <img
                src={PractiSeguroLogo}
                alt=""
                className={classes.productLogo}
              />
            </div>
          </Grid>
          <Grid item className={classes.planInformationContainer}>
            <Grid container item>
              <Grid container item xs={12} className={classes.titleContainer}>
                <Typography className={classes.title}>
                  PractiSeguro - Seguro de vida
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={6}
                className={classes.planContainer}
              >
                <Grid item>
                  <Typography className={classes.label}>
                    Plan adquirido
                  </Typography>
                </Grid>
                <Grid item className={classes.selectedPlanContainer}>
                  <PlanIcon className={classes.planIcon} />
                  <Typography className={classes.title}>
                    {product.plan.name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={6}
                className={classes.monthlyPayContainer}
              >
                <Typography className={classes.label}>Valor mensual</Typography>
                <Typography className={classes.title}>
                  {numberWithDots(product.plan.monthlyPay)}
                </Typography>
              </Grid>
            </Grid>

            {isMobileSize ? (
              <Grid container item>
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  className={classes.optionalContainer}
                >
                  <Typography className={classes.label}>
                    Identificación
                  </Typography>
                  <Typography className={classes.title}>
                    {currentUser.identificationType}{' '}
                    {currentUser.identification}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  className={classes.optionalContainer}
                >
                  <Typography className={classes.label}>
                    {Company.contractConjugation.capitalized.singular.associate}
                  </Typography>
                  <Typography className={classes.title}>
                    {selectedContract.alias} - {selectedContract.id}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid container item>
                <Typography>
                  <span className={classes.subtitleBold}>Identificación:</span>{' '}
                  {currentUser.identificationType} {currentUser.identification}{' '}
                  •{' '}
                  <span className={classes.subtitleBold}>
                    {Company.contractConjugation.capitalized.singular.associate}
                    :
                  </span>{' '}
                  {selectedContract.alias} - {selectedContract.id}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container className={classes.actionButtonsContainer}>
          <Grid
            item
            xs={12}
            md={8}
            container
            className={classes.buttonsContainer}
          >
            <Grid item xs={12} sm={6} className={classes.buttonContainer}>
              <BaseButton
                color="default"
                variant="text"
                size="small"
                className={clsx(classes.actionButton, classes.conditionsButton)}
                startIcon={<DownloadIcon size={20} />}
                onClick={() =>
                  downloadAsset('Practiseguro - Clausulas.pdf', PractiseguroPDF)
                }
              >
                Ver condiciones
              </BaseButton>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.buttonContainer}>
              <BaseButton
                color="default"
                variant="text"
                size="small"
                className={clsx(
                  classes.actionButton,
                  classes.requestAdvisoryButton
                )}
                startIcon={<CustomerServiceIcon size={20} />}
                onClick={handleOpenAppointmentDialog}
              >
                Solicitar asesoría
              </BaseButton>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} container justify="flex-end">
            <BaseButton
              color="default"
              variant="text"
              size="small"
              className={clsx(
                classes.actionButton,
                classes.consultPolicyButton
              )}
              startIcon={<SearchIcon />}
              onClick={() => setOpenPoliciesDialog(true)}
            >
              Consultar póliza
            </BaseButton>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  card: {
    borderRadius: theme.custom.borderRadius,
    border: `1px solid ${theme.palette.background.border}`,
    position: 'relative',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: theme.spacing(3, 2)
    }
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'column'
    }
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1.2,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 14
    }
  },
  statusTitle: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.2
  },
  subtitle: {
    fontSize: 13,
    color: theme.palette.text.greyDark
  },
  subtitleBold: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.text.greyDark
  },
  label: {
    fontSize: 11,
    fontWeight: 500,
    color: theme.palette.text.greyDark
  },
  labelBold: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.greyDark
  },
  titleContainer: {
    justifyContent: 'flex-end',
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      justifyContent: 'center',
      paddingBottom: theme.spacing(2)
    }
  },
  planInformationContainer: {
    flex: 1
  },
  planContainer: {
    flexDirection: 'column',
    paddingBottom: theme.spacing(0.5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  selectedPlanContainer: {
    display: 'flex'
  },
  optionalContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      alignItems: 'flex-start',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: theme.spacing(1)
    }
  },
  monthlyPayContainer: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  contractContainer: {
    flexDirection: 'column'
  },
  productLogo: {
    width: 100,
    height: 'auto'
  },
  productLogoGrid: {
    maxWidth: 100,
    minHeight: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(2),
    boxShadow: '0px 5px 13px 3px rgba(0,0,0,0.1)',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginBottom: theme.spacing(2),
      marginRight: 'auto',
      marginLeft: 'auto',
      width: 100
    }
  },
  planIcon: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(1)
  },
  acquiredText: {
    color: theme.palette.success.main
  },
  inProcessText: {
    color: theme.palette.primary.main
  },
  actionButtonsContainer: {
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      flexDirection: 'column'
    }
  },
  buttonsContainer: {
    '& > *': {
      paddingRight: theme.spacing(2)
    },
    '& > *:last-child': {
      paddingRight: 0
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      '& > *': {
        paddingBottom: theme.spacing(2)
      }
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      '& > *': {
        paddingRight: 0
      }
    }
  },
  buttonContainer: {
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      flexBasis: 'auto'
    }
  },
  conditionsButton: {
    backgroundColor: theme.palette.primary.light
  },
  requestAdvisoryButton: {
    backgroundColor: theme.palette.primary.lighter
  },
  consultPolicyButton: {
    backgroundColor: '#F7EFFF'
  },
  actionButton: {
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      width: '100%'
    }
  }
}));

export default InsuranceDetailCard;
