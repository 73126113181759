import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';

import OptionDivider from '../../../../Components/Dividers/OptionDivider';
import SocialButtons from '../../../../Components/Buttons/SocialButtons';

const TransactionModalFooter = ({ onClick, loading }) => {
  // * OTHER HOOKS
  const classes = useStyles();

  return (
    <div className={classes.signUpContainer}>
      <SocialButtons googleLoading={loading} hideAppleButton={true} />
      <OptionDivider />
      <Typography className={classes.bottomText}>
        <span className={classes.signUpText} onClick={onClick}>
          Accede con tu correo
        </span>
      </Typography>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  signUpContainer: {
    marginTop: theme.spacing(3)
  },
  bottomText: {
    marginTop: theme.spacing(1),
    fontSize: 14,
    textAlign: 'center'
  },
  signUpText: {
    fontSize: 14,
    color: theme.palette.primary.dark,
    fontWeight: 500,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}));

export default TransactionModalFooter;
