import React, { Fragment, useRef, useCallback, useState } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';

import CircleProgress from '../../../../Components/Progress/Circle/CircleProgress';
import ControlledComboboxInput from '../../../../Components/Controlled/ControlledComboboxInput';
import BaseButton from '../../../../Components/Buttons/BaseButton';
import Recaptcha from '../../../../Components/Captcha/Recaptcha';
import SimpleDivider from '../../../../Components/Dividers/SimpleDivider';
import ContractCard from '../../../../Components/Cards/ContractCard';
import InvoiceHelpDialog from '../../../../Components/Dialogs/InvoiceHelpDialog/InvoiceHelpDialog';
import QuestionMark from '../../../../Components/Adornments/QuestionMark';
import ErrorState from '../../../../Components/EmptyState/ErrorState';

import { HELP_TEXT } from '../../../../Utils/enums';
import { config } from '../../../../Configs';
import { Company } from '../../../../Configs/general';
import { InvoiceHelpType } from '../../../../Enums/invoices';

const UserWithContract = props => {
  const {
    clickHandler,
    contract,
    errors,
    loading,
    control,
    inputChangeHandler,
    contractOptions,
    setCaptcha,
    setLoadingCaptcha,
    disableSubmit,
    contractSearched,
    authenticated
  } = props;

  const classes = useStyles();

  const DEFAULT_SEARCH_VALUE = { id: '', label: '' };

  const [helpDialogOpen, setHelpDialogOpen] = useState(false);

  const inputRef = useRef(null);
  const recaptchaRef = useRef(null);

  const onCaptchaResolved = useCallback(
    token => {
      setCaptcha(token);
    },
    [setCaptcha]
  );

  const onCaptchaExpired = useCallback(() => {
    setCaptcha(null);
  }, [setCaptcha]);

  const onCaptchaLoaded = useCallback(() => {
    setTimeout(() => {
      setLoadingCaptcha(false);
    }, 1000);
  }, [setLoadingCaptcha]);

  const onHelpClick = useCallback(() => {
    setHelpDialogOpen(true);
  }, []);

  return (
    <Fragment>
      <InvoiceHelpDialog
        open={helpDialogOpen}
        type={InvoiceHelpType.Contract}
        onClose={() => setHelpDialogOpen(false)}
        title={HELP_TEXT.contract}
      />
      <Grid item sm={5} xs={12} className={classes.inputContainerItem}>
        <ControlledComboboxInput
          id="Pqr_search_contract"
          name="contract"
          control={control}
          options={contractOptions}
          defaultValue={DEFAULT_SEARCH_VALUE}
          getOptionLabel={option => String(option.id)}
          renderOption={option => (
            <div className={classes.optionItemContainer}>
              {option.id}
              <span className={classes.optionItemAlias}>{option.label}</span>
            </div>
          )}
          onInputChange={inputChangeHandler}
          disabled={loading}
          TextInputProps={{
            label: `Número de ${Company.contractConjugation.regular.singular.main}`,
            type: 'number',
            InputProps: {
              endAdornment: (
                <InputAdornment
                  position="end"
                  classes={{ positionEnd: classes.positionEnd }}
                >
                  {loading && <CircleProgress />}
                  {!loading && <QuestionMark onClick={onHelpClick} />}
                </InputAdornment>
              )
            },
            error: Boolean(errors.contract),
            helperText:
              (errors.contract && errors.contract.message) ||
              (errors.contract &&
                errors.contract.id &&
                errors.contract.id.message)
          }}
        />
      </Grid>
      <Grid
        item
        sm={4}
        xs={12}
        ref={inputRef}
        className={classes.recaptchaContainer}
      >
        <Recaptcha
          captchaRef={recaptchaRef}
          referenceEl={inputRef}
          heightScale={0.75}
          locale={'es'}
          sitekey={config.recaptcha.siteKey}
          onResolved={onCaptchaResolved}
          onExpired={onCaptchaExpired}
          onLoaded={onCaptchaLoaded}
        />
      </Grid>
      <Grid item sm={3} xs={12} className={classes.buttonContainer}>
        <BaseButton
          color="primary"
          variant="outlined"
          className={classes.button}
          onClick={clickHandler}
          disabled={disableSubmit}
          fullWidth
        >
          Consultar
        </BaseButton>
      </Grid>
      <SimpleDivider />
      <Grid item xs={12} className={classes.contractContainer}>
        {contract && (
          <ContractCard
            data={contract}
            className={classes.contractCard}
            loading={false}
            showName={authenticated}
            showStratum={authenticated}
          />
        )}
        {!contract && contractSearched && (
          <ErrorState
            messageTitle={`No se encontró ${Company.contractConjugation.regular.singular.article}`}
            className={classes.emptyState}
          />
        )}
      </Grid>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  inputContainerItem: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(2)
    }
  },
  positionEnd: {
    marginRight: theme.spacing()
  },
  optionItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  optionItemAlias: {
    color: '#B8B8B8',
    fontSize: 12
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingTop: 15,
      marginTop: 0
    }
  },
  button: {
    minHeight: 58
  },
  recaptchaContainer: {
    padding: 0
  },
  contractCard: {
    width: '100%',
    marginBottom: theme.spacing(4)
  },
  emptyState: {
    marginTop: 0,
    marginBottom: theme.spacing(2)
  },
  contractContainer: {
    minHeight: 243
  }
}));

export default UserWithContract;
