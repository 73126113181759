import React, { Fragment, useCallback, useState } from 'react';
import { saveAsPDF } from '../../../../Utils/Transform/Files';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { isWidthUp } from '@material-ui/core/withWidth';
import BaseButton from '../../../../Components/Buttons/BaseButton';
import { history } from '../../../../Routes/history';
import { TRANSACTION_STATUS, TRANSACTION_TYPES } from '../../payment_enums';
import { TransactionPDFAPI } from '../../../../API/Payments/TransactionStatusAPI';

import { ROUTE_NAMES } from '../../../../Routes/Routes';
import ModalProgress from '../../../../Components/Progress/Modal/ModalProgress';
import { Company } from '../../../../Configs/general';

const TransactionActionsGDC = props => {
  const { transactionId, transaction = {} } = props;

  // * STATE HOOKS;
  const [loading, setLoading] = useState(false);

  // * OTHER HOOKS
  const classes = useStyles();

  // * FUNCTIONS
  const downloadPDF = useCallback(async () => {
    setLoading(true);
    const response = await TransactionPDFAPI(transactionId);
    setLoading(false);
    if (response.success) {
      const fileName = `pagos_${Company.shortName.toLowerCase()}_${transaction.reference ||
        ''}.pdf`;

      saveAsPDF(response.data.data.content, fileName);
    } else {
      console.error('Error downloading pdf', response.err);
    }
  }, [transactionId, transaction]);

  const retryPayment = useCallback(() => {
    if (transaction.type === TRANSACTION_TYPES.DonationCOVID19) {
      history.push(ROUTE_NAMES.donations, {
        donationValue: transaction.value
      });
      return;
    }
    const isValidForCouponRetry = [
      TRANSACTION_TYPES.CouponPayment,
      TRANSACTION_TYPES.PartialPayment
    ].includes(transaction.type);

    if (isValidForCouponRetry) {
      const descriptionCoupon = transaction.description.split('#')[1];
      history.push(ROUTE_NAMES.payments, {
        type: 'coupon',
        value: transaction.couponId || descriptionCoupon || 'null'
      });
      return;
    }
    history.push('/');
  }, [transaction]);

  return (
    <Fragment>
      {loading && <ModalProgress message="Generando comprobante" />}
      <Grid container className={classes.buttonContainer}>
        {transaction.status === TRANSACTION_STATUS.Rejected && (
          <Grid item className={classes.actionButton}>
            <BaseButton
              onClick={retryPayment}
              disabled={false}
              id="TransactionAction_ButtonRetry"
              fullWidth={!isWidthUp('sm', props.width)}
              color="primary"
              variant="outlined"
            >
              Reintentar transacción
            </BaseButton>
          </Grid>
        )}
        {transaction.status !== TRANSACTION_STATUS.Pending && (
          <Grid item className={classes.actionButton}>
            <BaseButton
              onClick={downloadPDF}
              disabled={loading}
              id="TransactionAction_ButtonVoucher"
              fullWidth={!isWidthUp('sm', props.width)}
              color="primary"
              variant="outlined"
            >
              {transaction.status === TRANSACTION_STATUS.Approved
                ? 'Comprobante de pago '
                : 'Descargar comprobante'}
            </BaseButton>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'space-around',
    // * Responsive
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing()
    }
  },
  buttonContainer: {
    justifyContent: 'flex-end',
    // * Mobile
    [`@media (max-width:${theme.breakpoints.values.sm - 1}px)`]: {
      paddingTop: '0 !important',
      paddingBottom: '0 !important'
    }
  },
  actionButton: {
    marginBottom: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      marginBottom: 0,
      width: 'auto'
    }
  }
}));

export default TransactionActionsGDC;
