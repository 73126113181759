import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Collapse,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  Divider,
  Button
} from '@material-ui/core';

import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import clsx from 'clsx';

import DeferredPagination from './DeferredPagination';
import BaseButton from '../../../../Components/Buttons/BaseButton';
import EyeIcon from '../../../../Components/CustomIcons/EyeIcon';
import AddItemButton from '../AddItemButton';

import { numberWithDots } from '../../../../Utils/Format/MoneyFormat';

const DeferredAccordion = ({
  type,
  financialId,
  total,
  countValids,
  deferreds,
  addedDeferreds,
  addDeferreds,
  removeDeferreds,
  productAlreadyAdded,
  isActionDisabled
}) => {
  const classes = useStyles();
  const theme = useTheme();

  // * STATE HOOKS
  const [expanded, setExpanded] = useState(false);

  // * OTHER HOOKS
  const mediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  // * GLOBAL VARS
  const addedFinancialItemsCount = addedDeferreds.filter(
    d => d.financialId === financialId
  ).length;

  const allAdded =
    addedDeferreds.length > 0 && addedFinancialItemsCount === countValids;

  const allOnClaim = countValids === 0;

  const buttonDisabled = productAlreadyAdded || allOnClaim || isActionDisabled;

  // * FUNCTIONS
  const handleClick = (e, name) => {
    if (name === 'addRemove') {
      e.preventDefault();
      e.stopPropagation();
      return allAdded
        ? removeDeferreds('financial', financialId)
        : addDeferreds('financial', financialId);
    }
    setExpanded(!expanded);
  };

  return (
    <div className={classes.rowWrapper}>
      <div
        className={clsx(classes.row, {
          [classes.borderBottom]: expanded,
          [classes.expandedRow]: expanded
        })}
        onClick={e => handleClick(e)}
      >
        <Grid container wrap="nowrap" alignItems="center">
          <Grid
            container
            item
            justify="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid container item wrap="nowrap" xs={12} sm={8} md={8}>
              <Grid container item direction="column">
                <Typography style={{ fontSize: 10 }} className={classes.label}>
                  No • {financialId}
                </Typography>
                <Typography className={classes.data}>
                  Financiación productos {type}
                </Typography>
              </Grid>
            </Grid>

            {!mediumScreen && (
              <Grid
                container
                item
                wrap="nowrap"
                alignItems="center"
                justify="flex-end"
                xs={12}
                sm={6}
                md={6}
              >
                <Grid item>
                  <BaseButton
                    size="small"
                    variant="text"
                    color="primary"
                    className={classes.addButton}
                    disabled={buttonDisabled}
                    onClick={e => {
                      handleClick(e, 'addRemove');
                    }}
                  >
                    {allAdded && !allOnClaim ? 'Quitar' : 'Agregar'}
                  </BaseButton>
                </Grid>

                <Grid item>
                  <div className={classes.totalBalanceContainer}>
                    <Typography
                      className={clsx(classes.label, classes.alignRight)}
                    >
                      Valor total
                    </Typography>
                    <Typography
                      className={clsx(
                        classes.data,
                        classes.total,
                        classes.alignRight
                      )}
                    >
                      {numberWithDots(total)}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            )}
          </Grid>

          {!mediumScreen && (
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded
              })}
              onClick={() => setExpanded(e => !e)}
              aria-expanded={expanded}
              aria-label="Ver más"
              size="small"
            >
              <ArrowDropDown />
            </IconButton>
          )}

          {mediumScreen && (
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <AddItemButton
                onClick={e => handleClick(e, 'addRemove')}
                disabled={buttonDisabled}
                isAdding={allAdded && !allOnClaim}
                small
              />
            </Grid>
          )}
        </Grid>

        {mediumScreen && (
          <Grid item container>
            <Grid item xs={12}>
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
              <Grid item container justify="space-between">
                <Grid item xs={6}>
                  <div className={classes.subheaderContainer}>
                    <Button
                      className={classes.seeMoreButton}
                      classes={{ root: classes.buttonRoot }}
                      color="primary"
                      onClick={() => setExpanded(e => !e)}
                    >
                      {expanded ? 'Ocultar detalle' : 'Ver detalle'}
                      <EyeIcon
                        size={16}
                        className={classes.eyeIcon}
                        fill={theme.palette.primary.main}
                      />
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    className={clsx(classes.label, classes.alignRight)}
                  >
                    Saldo pendiente
                  </Typography>
                  <Typography
                    className={clsx(
                      classes.data,
                      classes.total,
                      classes.alignRight
                    )}
                  >
                    {numberWithDots(total)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>

      <Collapse in={expanded} timeout="auto">
        <DeferredPagination
          id={financialId}
          deferreds={deferreds}
          addedDeferreds={addedDeferreds}
          addDeferreds={addDeferreds}
          removeDeferreds={removeDeferreds}
          productAlreadyAdded={productAlreadyAdded}
          isActionDisabled={isActionDisabled}
        />
      </Collapse>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  rowWrapper: {
    borderStyle: 'solid',
    borderColor: theme.palette.background.border,
    borderWidth: '0 1px 1px 1px',
    overflow: 'hidden',
    '&:last-of-type': {
      borderBottomLeftRadius: theme.custom.borderRadius,
      borderBottomRightRadius: theme.custom.borderRadius
    }
  },
  row: {
    padding: theme.spacing(2, 2, 2, 3),
    cursor: 'pointer',
    transition: theme.transitions.create('background-color'),
    backgroundColor: theme.palette.background.paper
  },
  expandedRow: {
    backgroundColor: theme.palette.background.cardDark
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.background.border}`
  },
  totalBalanceContainer: {
    width: 168
  },
  label: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.greyDark
  },
  data: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.2,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 11
    }
  },
  total: {
    fontSize: 16
  },
  alignRight: {
    textAlign: 'right'
  },
  buttonRoot: {
    padding: theme.spacing(1, 1, 1, 0)
  },
  addButton: {
    fontSize: 12,
    padding: theme.spacing(),
    transition: theme.transitions.create('color', {
      duration: '100ms'
    }),
    minWidth: 70,
    textDecoration: 'underline'
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  seeMoreButton: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.main,
    textTransform: 'unset',
    marginRight: 'auto'
  },
  eyeIcon: {
    marginLeft: theme.spacing()
  },
  divider: {
    margin: theme.spacing(2, 0)
  }
}));

export default DeferredAccordion;
