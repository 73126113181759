import React, { Fragment, useState, useEffect, useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import SimpleDivider from '../../Components/Dividers/SimpleDivider';
import Title from '../../Components/Labels/Title';

import FullSizeProgress from '../../Components/Progress/FullSize/FullSizeProgress';

import { GetCategoriesAPI } from '../../API/Faq/FaqAPI';
import FaqActionButton from '../../Components/Buttons/FaqActionButton';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import WhatsAppIcon from '../../Components/CustomIcons/WhatsappIcon';
import { OpenNewTab, WhatsAppHelpLinkHandler } from '../../Utils/Misc/Links';
import PhonesDialog from '../../Components/Dialogs/PhonesDialog/PhonesDialog';
import CategoryButton from '../../Components/Buttons/CategoryButton';
import { ROUTE_NAMES } from '../../Routes/Routes';
import { history } from '../../Routes/history';
import { normalizeURI } from '../../Utils/Format/URI';
import BaseBreadcrumbs from '../../Components/Breadcrumbs/BaseBreadcrumbs';

const faqBanner = require('../../Assets/images/faq_banner.jpg');

const CloserToYouLink = () => {
  OpenNewTab('/closer/support');
};

const FaqCategory = ({ match }) => {
  const classes = useStyles();

  // * STATE HOOKS
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  // * FUNCTIONS

  const goToCategoryInfo = cat => {
    history.push(
      `${ROUTE_NAMES.faq}/categories/${cat.id}-${normalizeURI(cat.nombre)}`
    );
  };

  const goToArticleInfo = art => {
    const articleInfo = `${art.id}-${normalizeURI(art.titulo)}`;
    history.push(`${ROUTE_NAMES.faq}/articles/${articleInfo}`);
  };

  const categoryInfo = match.params.categoryInfo;

  const filteredCategories =
    categories &&
    categories.filter(
      cat => cat.nombre !== category.nombre && cat.articulos.length > 0
    );

  useEffect(() => {
    setLoading(true);
    const fetchCategories = async () => {
      const categoriesResponse = await GetCategoriesAPI();
      if (!categoriesResponse.success) {
        history.replace('/');
        return;
      }

      setCategories(categoriesResponse.data.data);

      const categoryNameIndex = categoryInfo.indexOf('-');
      const selectedCategory = categoriesResponse.data.data.find(
        cat =>
          cat.id === Number(categoryInfo.split('-')[0]) &&
          normalizeURI(cat.nombre) ===
            categoryInfo.substring(categoryNameIndex + 1) &&
          cat.articulos.length > 0
      );

      if (!selectedCategory || selectedCategory.articulos.length === 0) {
        history.replace('/');
        return;
      }

      const currentBreadcrumbs = [
        { name: 'Preguntas frecuentes', link: ROUTE_NAMES.faq },
        { name: selectedCategory.nombre }
      ];

      setCategory(selectedCategory);
      setBreadcrumbs(currentBreadcrumbs);
      setLoading(false);
    };

    fetchCategories();
  }, [categoryInfo]);

  const handleClose = useCallback(() => {
    setOpenDialog(false);
  }, [setOpenDialog]);

  if (loading) {
    return <FullSizeProgress />;
  }

  const renderContent = () => {
    if (loading) {
      return;
    }

    return (
      <Fragment>
        <Grid container className={classes.articlesContainer}>
          <BaseBreadcrumbs
            classes={{
              root: classes.breadcrumbs,
              link: classes.breadcrumbsLink,
              text: classes.breadcrumbsText
            }}
            separator=">"
            breadcrumbs={breadcrumbs}
          />
          <Title
            className={classes.titleText}
            text={
              category.nombre === 'Top Preguntas'
                ? category.nombre
                : `Preguntas sobre ${category.nombre.toLowerCase()}`
            }
          />
          <Grid className={classes.articlesMargin} container>
            {category.articulos.map((art, i) => (
              <Fragment key={i}>
                <Typography
                  onClick={() => goToArticleInfo(art)}
                  className={classes.articlesList}
                >
                  <span className={classes.articlesNumber}>{i + 1}. </span>{' '}
                  {art.titulo}
                </Typography>
                <SimpleDivider withoutMargin />
              </Fragment>
            ))}
          </Grid>
        </Grid>
        <Grid container className={classes.categoryContainer}>
          <Title
            className={classes.categoryText}
            text="Otras categorías que podrían interesarte"
          />
          <Grid container className={classes.categoryAlign}>
            {filteredCategories.map((cat, i) => (
              <CategoryButton
                onClick={() => goToCategoryInfo(cat)}
                className={classes.buttonStyle}
                key={i}
              >
                {cat.nombre}
              </CategoryButton>
            ))}
          </Grid>
        </Grid>
      </Fragment>
    );
  };

  return (
    <div className={classes.root}>
      <Fragment>
        <PhonesDialog open={openDialog} onClose={handleClose} />
        <div
          id="card-header-content"
          style={{ backgroundImage: `url(${faqBanner || ''})` }}
          className={classes.cardHeader}
        >
          <Grid container className={classes.bannerContent}>
            <Title className={classes.bannerText} text={category.nombre} />
          </Grid>
        </div>
        <Container maxWidth="md">
          {renderContent()}
          <Grid container spacing={2} className={classes.bottomContainer}>
            <Grid item className={classes.fullWidth} sm={4}>
              <FaqActionButton
                text={'Atención por chat'}
                className={classes.iconButton}
                onClick={WhatsAppHelpLinkHandler}
                icon={<WhatsAppIcon className={classes.iconStyleWhatsApp} />}
              />
            </Grid>
            <Grid item className={classes.fullWidth} sm={4}>
              <FaqActionButton
                text={'Puntos de atención'}
                className={classes.iconButton}
                onClick={CloserToYouLink}
                icon={<LocationOnIcon className={classes.iconStyle} />}
              />
            </Grid>
            <Grid item className={classes.fullWidth} sm={4}>
              <FaqActionButton
                text={'Llámanos'}
                onClick={() => setOpenDialog(true)}
                className={classes.iconButton}
                icon={<PhoneIcon className={classes.iconStyle} />}
              />
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  bannerContent: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center'
  },
  categoryContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  bannerImage: {
    visibility: 'hidden',
    height: 0
  },
  categoryAlign: {
    justifyContent: 'flex-start'
  },
  cardHeader: {
    display: 'flex',
    color: theme.palette.text.grey,
    paddingTop: theme.spacing(0),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    height: '25vh',
    minHeight: 120
  },
  bottomContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex'
  },
  articlesMargin: {
    marginTop: theme.spacing(2)
  },
  buttonStyle: {
    margin: [[theme.spacing(1), theme.spacing(2), theme.spacing(1), 0]],
    borderRadius: '30px',
    backgroundColor: 'white',
    borderColor: '#183F8F',
    borderStyle: 'solid',
    borderWidth: 1,
    color: '#183F8F',
    '&:hover': {
      backgroundColor: '#F0F5FD'
    }
  },
  iconButton: {
    backgroundColor: '#F8F8F8',
    color: '#183F8F',
    fontWeight: 'normal',
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16
    },
    '&:hover': {
      backgroundColor: '#183F8F',
      color: 'white',
      fontWeight: 500
    }
  },
  titleText: {
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 18
    },
    marginBottom: 0
  },
  bannerText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 22,
    textAlign: 'center',
    textTransform: 'uppercase',
    [theme.breakpoints.up('md')]: {
      fontSize: 26
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }
  },
  articlesContainer: {
    textAlign: 'left',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  fullWidth: {
    width: '100%'
  },
  categoryText: {
    textAlign: 'left',
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 18
    },
    marginBottom: theme.spacing(3)
  },
  articlesList: {
    color: '#707070',
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16
    },
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
      fontWeight: 500
    }
  },
  articlesNumber: {
    color: '#183F8F'
  },
  iconStyle: {
    fill: 'currentcolor',
    fontSize: '40px !important'
  },
  iconStyleWhatsApp: {
    fill: 'currentcolor',
    marginRight: 4,
    fontSize: '40px !important'
  },
  breadcrumbs: {
    marginBottom: 16
  },
  breadcrumbsLink: {
    fontSize: 12,
    color: '#2F2F2F',
    '&:hover': {
      fontWeight: 500
    }
  },
  breadcrumbsText: {
    fontSize: 12,
    color: '#2F2F2F',
    textDecoration: 'underline'
  }
}));

export default withWidth()(FaqCategory);
