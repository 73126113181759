import React from 'react';

function SvgIcCouponButton(props) {
  return (
    <svg
      width={149}
      height={149}
      viewBox="0 0 149 149"
      xmlSpace="preserve"
      {...props}
    >
      <defs>
        <linearGradient
          id="ic_coupon_button_svg__a"
          x2={1}
          y2={1.031}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#4cf3b5" />
          <stop offset={1} stopColor="#cbe22a" />
        </linearGradient>
      </defs>
      <path
        d="M6 0h137a6 6 0 016 6v137a6 6 0 01-6 6H6a6 6 0 01-6-6V6a6 6 0 016-6z"
        fill="url(#ic_coupon_button_svg__a)"
      />
      <g fill="#fff">
        <path
          data-name="Path 508"
          d="M100.178 40.157c.04 0 .081.006.122.006a1.507 1.507 0 00.208-.015 3.363 3.363 0 013.295 3.358 1.505 1.505 0 003.01 0 6.381 6.381 0 00-5.008-6.223v-1.447a1.505 1.505 0 00-3.01 0v1.514a6.371 6.371 0 001.646 12.526 3.362 3.362 0 11-3.362 3.363 1.505 1.505 0 10-3.01 0 6.382 6.382 0 004.726 6.155v1.649a1.505 1.505 0 003.01 0v-1.58a6.371 6.371 0 00-1.364-12.595 3.361 3.361 0 01-.263-6.711zm0 0"
        />
        <path
          data-name="Path 509"
          d="M100.527 26.973a21.592 21.592 0 00-20.121 14.13H43.058a1.574 1.574 0 00-1.5 1.573l-.356 52.792H30.55a1.48 1.48 0 00-1.544 1.434v6.7a11.821 11.821 0 0011.286 11.773v.033h46.464v-.044c.376.014.531.044.769.044h.032a11.821 11.821 0 0011.803-11.825V69.715c.376.021.774.032 1.164.032a21.387 21.387 0 000-42.774zm-59.662 85.425h-.01a8.852 8.852 0 01-8.839-8.815v-5.106h43.642v5.087a11.806 11.806 0 003.484 8.365c.158.158.354.28.52.468zm55.485-8.815a8.811 8.811 0 01-8.794 8.812h-.029a8.876 8.876 0 01-8.86-8.832v-6.661a1.389 1.389 0 00-1.437-1.434H44.206l.345-51.355h35.022a22.612 22.612 0 00-.412 4.18 21.221 21.221 0 001.816 8.612H66.763a1.505 1.505 0 000 3.01h15.843a21.237 21.237 0 0013.74 9.425zm4.177-36.848a18.356 18.356 0 1118.357-18.352 18.355 18.355 0 01-18.357 18.355zm0 0"
        />
        <path
          data-name="Path 510"
          d="M57.103 66.92a4.666 4.666 0 104.817 4.664 4.747 4.747 0 00-4.817-4.664zm0 6.318a1.661 1.661 0 111.808-1.654 1.737 1.737 0 01-1.808 1.654zm0 0"
        />
        <path
          data-name="Path 511"
          d="M65.259 71.578a1.5 1.5 0 001.5 1.5h20.063a1.505 1.505 0 100-3.01H66.764a1.5 1.5 0 00-1.505 1.51zm0 0"
        />
        <path
          data-name="Path 512"
          d="M57.103 53.835A4.667 4.667 0 1061.92 58.5a4.748 4.748 0 00-4.817-4.665zm0 6.319a1.661 1.661 0 111.808-1.654 1.737 1.737 0 01-1.808 1.654zm0 0"
        />
        <path
          data-name="Path 513"
          d="M57.103 80.001a4.666 4.666 0 104.817 4.664 4.747 4.747 0 00-4.817-4.664zm0 6.318a1.661 1.661 0 111.808-1.654 1.737 1.737 0 01-1.808 1.655zm0 0"
        />
        <path
          data-name="Path 514"
          d="M86.827 83.236H66.764a1.505 1.505 0 100 3.01h20.063a1.505 1.505 0 100-3.01zm0 0"
        />
      </g>
    </svg>
  );
}

export default SvgIcCouponButton;
