import React, {
  Fragment,
  useCallback,
  useState,
  useContext,
  useRef,
  useEffect
} from 'react';
import clsx from 'clsx';
import _lowerCase from 'lodash/lowerCase';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';

import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';

import { moneyWithDots } from '../../../Utils/Format/MoneyFormat';
import BaseButton from '../../Buttons/BaseButton';
import Circle from '../../CustomIcons/Circle';
import ModalProgress from '../../Progress/Modal/ModalProgress';

import { saveAsPDF } from '../../../Utils/Transform/Files';

import { GetCouponPDFAPI } from '../../../API/Coupons/CouponsAPI';
import {
  getCouponCardHeader,
  renderCouponId
} from '../../../Utils/Format/Coupons';
import { Company } from '../../../Configs/general';

const NewPaymentCardGDC = props => {
  const classes = useStyles();
  const { id, details, type, scrollOnRender = false } = props;

  const setAlert = useContext(AlertsDispatchContext);

  const [loadingPDF, setLoadingPDF] = useState(false);
  const rootRef = useRef();

  useEffect(() => {
    if (scrollOnRender && rootRef.current) {
      rootRef.current.scrollIntoView();
    }
  }, [scrollOnRender]);

  const onDownloadCoupon = useCallback(
    async couponId => {
      if (!couponId) {
        return;
      }
      setLoadingPDF(true);
      const response = await GetCouponPDFAPI(couponId);
      if (!response.success) {
        setLoadingPDF(false);
        setAlert({
          type: 'error',
          message: 'Ocurrió un error, intenta de nuevo'
        });
        return;
      }
      const base64 = response.data.data.pdf;
      const pdfName = `pagos_${Company.shortName.toLowerCase()}_cupon_${couponId}.pdf`;
      saveAsPDF(base64, pdfName);
      setLoadingPDF(false);
    },
    [setAlert]
  );

  const _renderAddress = dataInfo => {
    let text = 'Sin dirección';

    if (dataInfo.city) {
      text = dataInfo.city;
    }
    if (dataInfo.address) {
      text = `${_lowerCase(text)}, ${_lowerCase(dataInfo.address)}`;
    }

    return (
      <Typography className={clsx(classes.sectionTitle, classes.capitalize)}>
        {text}
      </Typography>
    );
  };

  const isMobileSize = isWidthDown('xs', props.width);

  const renderCouponInfo = () => {
    return (
      <Fragment>
        {_renderAddress({
          city: details.contractCity,
          address: details.contractAddress
        })}
        <Typography className={classes.textTitle}>
          Cupón Nº:{' '}
          <span className={classes.textSubtitle}>
            {renderCouponId(details.couponId)}
          </span>
        </Typography>
        <Typography className={classes.textTitle}>
          {Company.contractConjugation.capitalized.singular.associate}:{' '}
          <span className={classes.textSubtitle}>
            {details.contractId || '-'}
          </span>
        </Typography>
      </Fragment>
    );
  };

  const renderContractInfo = () => {
    return (
      <Fragment>
        {_renderAddress({
          city: details.contractCity,
          address: details.contractAddress
        })}
        <Typography className={classes.textTitle}>
          {Company.contractConjugation.capitalized.singular.associate} Nº:{' '}
          <span className={classes.textSubtitle}>{details.contractId}</span>
        </Typography>
        <Typography className={classes.textTitle}>
          Cupón Nº:{' '}
          <span className={classes.textSubtitle}>{details.couponId}</span>
        </Typography>
        <Typography className={classes.textTitle}>
          Fecha vencimiento:{' '}
          <span className={classes.textSubtitle}>
            {moment(details.expirationDate).format('L')}
          </span>
        </Typography>
      </Fragment>
    );
  };

  const renderFinancingInfo = () => {
    return (
      <Fragment>
        {_renderAddress({
          city: details.contractCity,
          address: details.contractAddress
        })}
        <Typography className={classes.textTitle}>
          {Company.contractConjugation.capitalized.singular.associate} Nº:{' '}
          <span className={classes.textSubtitle}>{details.contractId}</span>
        </Typography>
      </Fragment>
    );
  };

  const renderLabel = coupon => {
    if (coupon.isPaid) {
      return (
        <Typography className={classes.headerStatusTitle}>
          {!isMobileSize && 'Estado:'}
          <Circle className={classes.headerCircle} fill={'#18cc48'} />
          <span className={classes.headerStatusText}>Pagado</span>
        </Typography>
      );
    }
    if (coupon.isPending) {
      return (
        <Typography className={classes.headerStatusTitle}>
          {!isMobileSize && 'Estado:'}
          <Circle className={classes.headerCircle} fill={'#fcc52c'} />
          <span className={classes.headerStatusText}>En proceso</span>
        </Typography>
      );
    }
    return (
      <Typography className={classes.headerStatusTitle}>
        {!isMobileSize && 'Estado:'}
        <Circle className={classes.headerCircle} fill={'rgba(12,31,44,0.38)'} />
        <span className={classes.headerStatusText}>Por pagar</span>
      </Typography>
    );
  };

  const renderHeaderTitle = () => {
    const content = getCouponCardHeader(type, details, false);

    return (
      <Typography
        className={clsx(classes.headerTitle, {
          [classes.capitalize]: type === 'contract'
        })}
      >
        {content}
      </Typography>
    );
  };

  if (!details) {
    return <Fragment />;
  }

  return (
    <div ref={rootRef}>
      {loadingPDF && <ModalProgress message="Generando documento" />}
      <Grid id={id} container className={classes.cardWithHeader}>
        <Grid container className={classes.cardHeader}>
          <Grid item xs={7} sm={6} style={{ display: 'flex' }}>
            {renderHeaderTitle()}
          </Grid>
          <Grid item xs={5} sm={6} className={classes.headerStatus}>
            {renderLabel(details)}
          </Grid>
        </Grid>
        <Grid container className={classes.cardRoot}>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" className={classes.leftSection}>
              {type === 'coupon' && renderCouponInfo()}
              {type === 'contract' && renderContractInfo()}
              {type === 'financing' && renderFinancingInfo()}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" className={classes.rightSection}>
              <Typography className={classes.sectionTitle}>
                Resumen del pago
              </Typography>
              {type === 'contract' && (
                <Typography className={classes.textSubtitle}>
                  Valor factura
                  <span
                    className={clsx([classes.textValue, classes.normalValue])}
                  >
                    {moneyWithDots(details.couponValue)}
                  </span>
                </Typography>
              )}
              {type === 'coupon' && (
                <Typography className={classes.textSubtitle}>
                  Valor cupón
                  <span
                    className={clsx([classes.textValue, classes.normalValue])}
                  >
                    {moneyWithDots(details.value)}
                  </span>
                </Typography>
              )}
              {type === 'contract' && (
                <Typography className={classes.textSubtitle}>
                  Total a pagar
                  <span
                    className={clsx([classes.textValue, classes.totalValue])}
                  >
                    {moneyWithDots(Number(details.couponValue))}
                  </span>
                </Typography>
              )}
              {type === 'coupon' && (
                <Typography className={classes.textSubtitle}>
                  Total a pagar
                  <span
                    className={clsx([classes.textValue, classes.totalValue])}
                  >
                    {moneyWithDots(Number(details.value))}
                  </span>
                </Typography>
              )}
              {type === 'financing' && (
                <Typography className={classes.textSubtitle}>
                  Total a pagar
                  <span
                    className={clsx([classes.textValue, classes.totalValue])}
                  >
                    {moneyWithDots(
                      Number(details.totalInitialInstallmentValue)
                    )}
                  </span>
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        {type === 'coupon' && details.couponId > 0 && (
          <Grow in={true} timeout={500}>
            <Grid item xs={12} className={classes.cardFooter}>
              <Typography className={classes.textFooter}>
                *si no puedes continuar con el pago electrónico, descarga el
                cupón.
              </Typography>
              <BaseButton
                color="primary"
                variant="outlined"
                size="small"
                disabled={loadingPDF}
                onClick={() => onDownloadCoupon(details.couponId || details.id)}
              >
                Descargar cupón
              </BaseButton>
            </Grid>
          </Grow>
        )}
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  cardWithHeader: {
    backgroundColor: theme.palette.background.cardDark,
    border: 'solid 1px #dbdbdb',
    borderRadius: theme.custom.borderRadius
  },
  cardRoot: {
    display: 'flex',
    flexDirection: 'row',
    padding: [[8, 8]],
    [theme.breakpoints.up('sm')]: {
      padding: [[8, 25]]
    }
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTopLeftRadius: theme.custom.borderRadius,
    borderTopRightRadius: theme.custom.borderRadius,
    backgroundColor: '#F5F5F5',
    minHeight: 42,
    padding: [[8, 20]],
    [theme.breakpoints.up('sm')]: {
      padding: [[8, 25]]
    }
  },
  headerTitle: {
    fontWeight: 500,
    alignSelf: 'center',
    color: '#333333',
    fontSize: 14,
    [theme.breakpoints.up('xm')]: {
      fontSize: 16
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 18
    }
  },
  headerStatus: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-start',
      paddingLeft: theme.spacing(4)
    }
  },
  headerStatusTitle: {
    display: 'inline',
    fontWeight: 500,
    color: theme.palette.text.main,
    textAlign: 'end',
    fontSize: 12,
    [theme.breakpoints.up('xm')]: {
      fontSize: 13
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
      textAlign: 'start'
    }
  },
  headerStatusText: {
    fontWeight: 'normal',
    color: '#696A6A'
  },
  headerCircle: {
    marginRight: 7,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 11
    }
  },
  cardFooter: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    borderTopLeftRadius: theme.custom.borderRadius,
    borderTopRightRadius: theme.custom.borderRadius,
    backgroundColor: '#F5F5F5',
    padding: [[10, 20]],
    margin: [[5, 5]]
  },
  textFooter: {
    fontWeight: 500,
    alignSelf: 'center',
    display: 'inline',
    fontSize: 12,
    color: theme.typography.color.default,
    marginBottom: theme.spacing(),
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0
    }
  },
  leftSection: {
    padding: [[8, 12]],
    paddingBottom: 12,
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.up('sm')]: {
      padding: [[12, 16, 12, 0]],
      borderRight: `1px solid ${theme.palette.divider}`,
      borderBottom: 'none'
    }
  },
  rightSection: {
    paddingTop: 12,
    paddingRight: 12,
    paddingBottom: theme.spacing(0.5),
    paddingLeft: 12,
    [theme.breakpoints.up('sm')]: {
      paddingRight: 0,
      paddingBottom: 0,
      paddingLeft: theme.spacing(4)
    }
  },
  sectionTitle: {
    marginBottom: theme.spacing(0.5),
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.typography.color.default,
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  textTitle: {
    fontWeight: 600,
    display: 'inline',
    fontSize: 12,
    marginTop: theme.spacing(0.5),
    color: theme.typography.color.default,
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  textSubtitle: {
    fontWeight: 400,
    display: 'inline',
    fontSize: 12,
    marginTop: 5,
    color: theme.typography.color.default,
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  textValue: {
    float: 'right',
    fontSize: 12,
    fontWeight: 500,
    [theme.breakpoints.up('xm')]: {
      fontSize: 14
    }
  },
  normalValue: {
    color: theme.typography.color.default
  },
  totalValue: {
    fontSize: 16,
    color: theme.typography.color.black,
    [theme.breakpoints.up('xm')]: {
      fontSize: 18
    }
  }
}));

export default withWidth()(NewPaymentCardGDC);
