import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { toast, ToastContainer } from 'react-toastify';

const CustomToastContainer = () => {
  const classes = useStyles();

  return (
    <ToastContainer
      className={({ defaultClassName }) =>
        clsx(defaultClassName, classes.toastContainer)
      }
      toastClassName={({ type, defaultClassName }) =>
        clsx(defaultClassName, classes[type])
      }
      rtl={false}
      autoClose={3000}
      pauseOnHover
      position={toast.POSITION.BOTTOM_LEFT}
      theme="colored"
      limit={3}
      pauseOnFocusLoss
      newestOnTop
    />
  );
};

const useStyles = makeStyles(theme => ({
  toastContainer: {
    zIndex: 1200,
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      bottom: '3.5em !important'
    },
    '& > .Toastify__toast:not(:last-child)': {
      marginBottom: '0.5em !important'
    }
  },
  success: {
    backgroundColor: `${theme.palette.success.main} !important`
  },
  info: {
    backgroundColor: `${theme.palette.primary.main} !important`
  },
  error: {
    backgroundColor: `${theme.palette.error.main} !important`
  },
  warning: {
    backgroundColor: `${theme.palette.warning.main} !important`
  }
}));

export default CustomToastContainer;
