import React from 'react';

const AddIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 56}
      height={props.size || 56}
      viewBox="0 0 56 56"
      xmlSpace="preserve"
      {...props}
    >
      <path data-name="Trazado 695" d="M0 0h56v56H0z" fill="none" />
      <path
        data-name="L\xEDnea 15"
        fill="none"
        stroke="#104eb2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
        d="M28 2v52"
      />
      <path
        data-name="L\xEDnea 16"
        fill="none"
        stroke="#104eb2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={5}
        d="M2 28h52"
      />
    </svg>
  );
};

export default AddIcon;
