import React from 'react';
import { portal, Portal } from '../../../../../../Configs/general';

import IndividualLoadGDC from './IndividualLoad.GDC';

const IndividualLoad = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <IndividualLoadGDC {...props} />;
    case Portal.Gasguajira:
      return <IndividualLoadGDC {...props} />;
    case Portal.Efigas:
      return <IndividualLoadGDC {...props} />;
    case Portal.TripleA:
      return <IndividualLoadGDC {...props} />;
    default:
      return <></>;
  }
};

export default IndividualLoad;
