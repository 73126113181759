import React, {
  Fragment,
  useCallback,
  useState,
  useEffect,
  useContext
} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Breadcrumbs,
  Container,
  Link,
  Typography,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  useMediaQuery
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import _get from 'lodash/get';

import { ROUTE_NAMES } from '../../../../Routes/Routes';
import { history } from '../../../../Routes/history';

import InsuranceDetailCard from '../../../../Components/Cards/InsuranceDetailCard';
import CallToActionDialog from '../../../../Components/Dialogs/CallToActionDialog';

import PqrsPNG from '../../../../Assets/images/img_pqrs@2x.png';
import SiniestroPNG from '../../../../Assets/images/img_siniestro@2x.png';

import SvgIcSupport from '../../../../Components/CustomIcons/IcSupport';
import SvgIcSiniestro from '../../../../Components/CustomIcons/IcSiniestro';
import ImageTextButton from '../../../../Components/Buttons/ImageTextButton';
import ActiveRequestIcon from '../../../../Components/CustomIcons/IcActiveRequest';

import { ContractSelectedContext } from '../../../../Contexts/ContractsContext';
import { UserContext } from '../../../../Contexts/UserContext';

import { GetAcquiredInsurancesAPI } from '../../../../API/BrillaInsurances/BrillaInsurancesAPI';

import { numberWithDots } from '../../../../Utils/Format/MoneyFormat';
import {
  InsurancePlans,
  InsuranceRequestMessage
} from '../../../../Enums/insurances';
import { ALERT_TYPE } from '../../../../Components/Alerts/alert_enums';
import { extractErrorMessage } from '../../../../Utils/Errors/Errors';
import { AlertsDispatchContext } from '../../../../Contexts/AlertsContext';
import { Company } from '../../../../Configs/general';

const ManagePractiseguro = props => {
  const { location: locationParams = { state: null } } = props;
  const params = locationParams.state;

  const classes = useStyles();
  const isMobileSize = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  // * CONTEXTS
  const selectedContract = useContext(ContractSelectedContext);
  const setAlert = useContext(AlertsDispatchContext);
  const currentUser = useContext(UserContext);
  const authToken = _get(currentUser, 'token');

  // * STATE HOOKS
  const [insuranceProduct, setInsuranceProduct] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  // * GLOBAL VARS
  const appointmentExists = params && params.confirmation && params.product;

  useEffect(() => {
    let isCancelled = false;
    const fetchData = async () => {
      setLoading(true);
      const response = await GetAcquiredInsurancesAPI(
        authToken,
        selectedContract.id,
        parseInt(currentUser.identification, 10)
      );

      if (!isCancelled) {
        if (response.success) {
          const insurancePlansData = response.data;

          if (insurancePlansData.length === 0) {
            history.replace(ROUTE_NAMES.insurancesHome);
            setLoading(false);
            return;
          }

          const [currentPlan] = insurancePlansData;

          setInsuranceProduct({
            ...currentPlan,
            plan: {
              ...InsurancePlans[currentPlan.idPlan],
              monthlyPay: currentPlan.monthlyPrice
            }
          });
          setLoading(false);
        } else {
          history.replace(ROUTE_NAMES.insurancesHome);
          setLoading(false);
          setAlert({
            type: ALERT_TYPE.ERROR,
            message: extractErrorMessage(response).message
          });
        }
      }
    };

    if (currentUser && selectedContract.id) {
      fetchData();
    }
    return () => {
      isCancelled = true;
    };
  }, [authToken, currentUser, selectedContract.id, setAlert]);

  useEffect(() => {
    if (!currentUser || !selectedContract) {
      history.replace('/');
      return;
    }
  }, [currentUser, selectedContract, insuranceProduct]);

  const handleCloseDialog = () => {
    setDialogOpen(false);
    history.replace(locationParams.pathname, { product: insuranceProduct });
  };

  useEffect(() => {
    setDialogOpen(Boolean(params && params.confirmation));
  }, [params]);

  const handleClick = useCallback(() => {
    history.push(ROUTE_NAMES.pqr);
  }, []);

  return (
    <Fragment>
      {appointmentExists && (
        <CallToActionDialog
          open={dialogOpen}
          icon={ActiveRequestIcon}
          confirmButtonText={'Entendido'}
          handleClose={handleCloseDialog}
          handleConfirmClick={handleCloseDialog}
          {...InsuranceRequestMessage[params.confirmation.process](
            params.confirmation.applicationId
          )}
          nodeBetweenMessages={
            <Typography className={classes.subtitleText}>
              {Company.contractConjugation.capitalized.singular.main}: Casa -{' '}
              {selectedContract.id} | {insuranceProduct.plan.name}
            </Typography>
          }
          fullScreen={isMobileSize}
          paperClass={classes.paperClass}
        />
      )}
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          <Breadcrumbs
            aria-label="breadcrumb"
            className={classes.breadcrumbs}
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              color="primary"
              component={RouterLink}
              to="/"
              underline="always"
            >
              Inicio{' '}
            </Link>
            <Link
              color="primary"
              component={RouterLink}
              to={ROUTE_NAMES.insurancesHome}
              underline="always"
            >
              Seguros{' '}
            </Link>
            <Typography color="textPrimary">PractiSeguro</Typography>
          </Breadcrumbs>
          <InsuranceDetailCard product={insuranceProduct} loading={loading} />
          {!loading && (
            <>
              <Typography className={classes.title}>
                Beneficios de tu plan
              </Typography>
              <Grid container item className={clsx(classes.margin)}>
                <List className={clsx(classes.listType)}>
                  <ListItem className={clsx(classes.listItemType)}>
                    <ListItemText
                      primary={`${
                        insuranceProduct.plan.insuredNumber
                      } asegurado${
                        insuranceProduct.plan.insuredNumber > 1 ? 's' : ''
                      }.`}
                    />
                    <ListItemSecondaryAction />
                  </ListItem>
                  {insuranceProduct.plan.coverageRange.map(
                    (item, index, array) => {
                      return (
                        <ListItem
                          key={`${item}`}
                          className={clsx(classes.listItemType)}
                        >
                          <ListItemText
                            primary={`Cobertura de ${numberWithDots(item)}${
                              array.length > 1
                                ? ` para el asegurado ${index + 1}.`
                                : '.'
                            }`}
                          />
                          <ListItemSecondaryAction />
                        </ListItem>
                      );
                    }
                  )}
                </List>
              </Grid>
              <Divider className={classes.divider} />
              <Typography className={classes.title}>
                Transacciones del seguro
              </Typography>
              <Grid
                container
                justify="center"
                className={classes.imageButtonsContainer}
              >
                <Grid item>
                  <ImageTextButton
                    tooltipText="Reportar siniestro"
                    text="Reportar siniestro"
                    Icon={SvgIcSiniestro}
                    backgroundImage={SiniestroPNG}
                    onClick={handleClick}
                  />
                </Grid>
                <Grid item>
                  <ImageTextButton
                    tooltipText="Crear PQR"
                    text="Crear PQR"
                    backgroundImage={PqrsPNG}
                    Icon={SvgIcSupport}
                    onClick={handleClick}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    '& > *:last-child': {
      marginBottom: theme.spacing(5)
    }
  },
  card: {
    borderRadius: theme.custom.borderRadius,
    border: `1px solid ${theme.palette.background.border}`,
    position: 'relative',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(1.5)
  },
  container: {
    flex: 1,
    marginTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginTop: theme.spacing(3)
    },
    '& > *:first-child': {
      marginBottom: theme.spacing(3)
    },
    '& > *:last-child': {
      marginBottom: 0
    }
  },
  breadcrumbs: {
    fontSize: 15,
    fontWeight: theme.typography.fontWeightMedium,
    '& *': { fontSize: 'inherit' }
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.2
  },
  subtitle: {
    fontSize: 12,
    color: theme.palette.text.greyDark
  },
  subtitleBold: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.text.greyDark
  },
  label: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.greyDark
  },
  labelBold: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.greyDark
  },
  listType: {
    listStyleType: 'disc'
  },
  listItemType: { display: 'list-item' },
  margin: {
    marginLeft: theme.spacing(4)
  },
  imageButtonsContainer: {
    marginTop: theme.spacing(3),
    '& > *': {
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      '& > *': {
        paddingRight: 0
      },
      '& > *:last-child': {
        paddingBottom: 0
      }
    }
  },
  subtitleText: {
    fontSize: 14,
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  paperClass: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      width: '100%',
      borderRadius: 0
    }
  }
}));

export default ManagePractiseguro;
