import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderLinearProgress = withStyles({
  root: {
    height: 8,
    borderRadius: 0
  },
  colorPrimary: {
    backgroundColor: '#D6FFE1'
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#18CC48'
  }
})(LinearProgress);

const TopBarProgress = props => {
  const classes = useStyles();
  const { topBarProgress } = props;

  return (
    <div className={classes.root}>
      <BorderLinearProgress variant="determinate" value={topBarProgress} />
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginBottom: 0,
    zIndex: 10,
    position: 'fixed',
    width: '100%'
  }
});

export default TopBarProgress;
