import React, { useContext, useLayoutEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Container,
  Toolbar,
  useTheme,
  Typography
} from '@material-ui/core';

import BackIcon from '../../Components/CustomIcons/BackIcon';
import BaseButton from '../../Components/Buttons/BaseButton';
import FlatButton from '../../Components/Buttons/FlatButton';

import { FooterDispatchContext } from '../../Contexts/FooterContext';
import { submitExternalForm } from '../../Utils/Misc/Form';
import clsx from 'clsx';

const FooterStepper = props => {
  // * MAP PROPS
  const {
    id,
    targetForm,
    goBack = {},
    nextButtonText = 'Siguiente',
    disabledNext = false,
    disabledBack = false,
    warning = false,
    warningMessage = '',
    footerStyle
  } = props;
  const { action: onBack, text: backButtonText = 'Regresar' } = goBack;
  const theme = useTheme();

  // * CONTEXTS
  const setFooterVisible = useContext(FooterDispatchContext);

  // All views with a Stepper Footer can't have another footer in the background
  useLayoutEffect(() => {
    setFooterVisible(false);
    return () => {
      setFooterVisible(true);
    };
  }, [setFooterVisible]);

  const classes = useStyles();

  // button property "form" doesn't work on IE 11, thus a workaround
  const submitHandler = useCallback(() => {
    submitExternalForm(targetForm);
  }, [targetForm]);

  return (
    <>
      {warning && (
        <Container maxWidth="md" className={classes.warningContainer}>
          <Typography>
            {warningMessage} <strong>{nextButtonText}</strong>
          </Typography>
        </Container>
      )}
      <Toolbar
        id={id}
        className={clsx(classes.footerRoot, footerStyle)}
        disableGutters
      >
        <Container className={classes.container}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={6} className={classes.leftItem}>
              {onBack && (
                <FlatButton
                  onClick={onBack}
                  color="primary"
                  className={classes.backButton}
                  disabled={disabledBack}
                >
                  <BackIcon
                    size={15}
                    fill={theme.palette.primary.main}
                    className={classes.backIcon}
                  />
                  {backButtonText}
                </FlatButton>
              )}
            </Grid>
            <Grid item xs={6} className={classes.rightItem}>
              <BaseButton
                id="nextStep"
                disabled={disabledNext}
                color="primary"
                variant="contained"
                onClick={submitHandler}
                className={classes.nextButton}
              >
                {nextButtonText}
              </BaseButton>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  footerRoot: {
    zIndex: 10,
    position: 'fixed',
    display: 'flex',
    textAlign: 'right',
    height: theme.custom.footerHeight.stepper,
    width: '100%',
    bottom: 0,
    boxShadow: '0px 2px 10px -1px rgba(0,0,0,0.2)',
    backgroundColor: theme.palette.background.dark
  },
  container: {
    maxWidth: 768,
    margin: '0 auto',
    textAlign: 'right',
    padding: 0,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      padding: theme.spacing(0, 2)
    }
  },
  leftItem: {
    display: 'flex',
    textAlign: 'left'
  },
  rightItem: {
    textAlign: 'right'
  },
  backButton: {
    fontSize: 12,
    color: theme.palette.text.primary
  },
  nextButton: {
    fontSize: 12,
    whiteSpace: 'nowrap'
  },
  backIcon: {
    marginRight: theme.spacing(1)
  },
  warningContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 24,
    textAlign: 'center',
    position: 'fixed',
    bottom: 75,
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.warning.light,
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`
  }
}));

export default FooterStepper;
