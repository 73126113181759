import React, { createContext, useEffect, useState } from 'react';
import { config } from '../Configs';
import {
  removeLocalExpiredAppleData,
  setLocalAppleUserData
} from '../Enums/socialUser';
import { deserializeToken } from '../Utils/JWT/token';
export const AppleClientContext = createContext(null);
export const AppleClientDispatchContext = createContext(null);
export const AppleClientInitializedContext = createContext(null);
export const AppleClientSetAppleListenerState = createContext(null);

export const AppleClientProvider = ({ children }) => {
  const [data, dispatch] = useState(null);
  const [currentInterval, setCurrentInterval] = useState(null);
  const [isAppleInitialized, setIsAppleInitialized] = useState(false);
  const [isApplePopupListenerActive, setIsApplePopupListenerActive] = useState(
    true
  );

  useEffect(() => {
    removeLocalExpiredAppleData();
  }, []);

  const handleCredentialResponse = event => {
    const { authorization, user } = event.detail;

    const { id_token: encodedToken, code } = authorization;
    const decodedToken = deserializeToken(encodedToken);
    const { sub, email } = decodedToken;

    let familyName = '';
    let givenName = '';

    if (user) {
      const { name } = user;

      givenName = name.firstName.trim();
      familyName = name.lastName.trim();

      setLocalAppleUserData({
        firstName: givenName,
        lastName: familyName,
        sub
      });
    }

    dispatch({
      encodedToken,
      tokenId: sub,
      email,
      givenName,
      familyName,
      code
    });
  };

  useEffect(() => {
    if (!isAppleInitialized) {
      return;
    }

    if (isApplePopupListenerActive) {
      document.addEventListener(
        'AppleIDSignInOnSuccess',
        handleCredentialResponse
      );
    } else {
      document.removeEventListener(
        'AppleIDSignInOnSuccess',
        handleCredentialResponse
      );
    }

    return () => {
      document.removeEventListener(
        'AppleIDSignInOnSuccess',
        handleCredentialResponse
      );
    };
  }, [isAppleInitialized, isApplePopupListenerActive]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.AppleID) {
        setIsAppleInitialized(true);
        window.AppleID.auth.init({
          clientId: config.apple.clientId,
          redirectURI: config.apple.redirectURI,
          scope: 'name email',
          usePopup: true
        });
      } else {
        setIsAppleInitialized(false);
      }
    }, 1000);

    setCurrentInterval(interval);
  }, []);

  useEffect(() => {
    if (isAppleInitialized) {
      clearInterval(currentInterval);
      setCurrentInterval(null);
    }
  }, [isAppleInitialized, currentInterval]);

  return (
    <AppleClientContext.Provider value={data}>
      <AppleClientDispatchContext.Provider value={dispatch}>
        <AppleClientInitializedContext.Provider value={isAppleInitialized}>
          <AppleClientSetAppleListenerState.Provider
            value={setIsApplePopupListenerActive}
          >
            {children}
          </AppleClientSetAppleListenerState.Provider>
        </AppleClientInitializedContext.Provider>
      </AppleClientDispatchContext.Provider>
    </AppleClientContext.Provider>
  );
};
