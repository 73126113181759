import React from 'react';
import { portal, Portal } from '../../../../Configs/general';
import DeferredDebtCardEFG from './DeferredDebtCard.EFG';
import DeferredDebtCardGDC from './DeferredDebtCard.GDC';

const DeferredDebtCard = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <DeferredDebtCardGDC {...props} />;
    case Portal.Efigas:
      return <DeferredDebtCardEFG {...props} />;
    default:
      return <></>;
  }
};

export default DeferredDebtCard;
