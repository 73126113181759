import React from 'react';
import { portal, Portal } from '../../../../Configs/general';

import ContractAssociationGDC from './ContractAssociationForm.GDC';
import ContractAssociationEFG from './ContractAssociationForm.EFG';

const ContractAssociationForm = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <ContractAssociationGDC {...props} />;
    case Portal.Efigas:
      return <ContractAssociationEFG {...props} />;
    default:
      return <></>;
  }
};

export default ContractAssociationForm;
