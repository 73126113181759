import React, {
  Fragment,
  useState,
  useContext,
  useCallback,
  useEffect
} from 'react';

import Container from '@material-ui/core/Container';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Title from '../../../Components/Labels/Title';
import PQRHelpDialog from '../../../Components/Dialogs/PQRHelpDialog';
import Stepper from '../../../Components/Stepper/Stepper';
import FooterStepper from '../../../Components/Stepper/FooterStepper';
import ModalProgress from '../../../Components/Progress/Modal/ModalProgress';

import ServiceStep from '../Steps/ServiceStep/ServiceStep';
import ContactStep from '../Steps/ContactStep';
import DetailStep from '../Steps/DetailStep';
import PqrSubmitDialog from '../PqrSubmitDialog';
import {
  DEFAULT_PARAMS,
  FORM_ID,
  PQR_STEPS,
  getProcedureTypeText
} from '../Pqr_enums';

import { StepperProvider, Step } from '../../../Contexts/StepperContext';
import { UserContext } from '../../../Contexts/UserContext';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';

import { SubmitPqrAPI } from '../../../API/PQR/PqrAPI';

import fileToBase64 from '../../../Utils/Transform/FileToBase64';
import { extractErrorMessage } from '../../../Utils/Errors/Errors';
import { ROUTE_NAMES } from '../../../Routes/Routes';
import { history } from '../../../Routes/history';

const PqrGDC = props => {
  const { location: locationParams = { state: null } } = props;

  let dialogOpen = DEFAULT_PARAMS.modal;

  if (locationParams.state) {
    dialogOpen = locationParams.state.modal;
  }

  const classes = useStyles();

  const [pqrDialogOpen, setPqrDialogOpen] = useState(dialogOpen);
  const [submittedDialog, setSubmittedDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogData, setDialogData] = useState({});

  const [canSubmit, setCanSubmit] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [nextButtonText, setNextButtonText] = useState('Siguiente');

  const [goBack, setGoBack] = useState({});

  const currentUser = useContext(UserContext);
  const setAlert = useContext(AlertsDispatchContext);

  const authToken = currentUser ? currentUser.token : null;

  const handleSubmit = useCallback(
    async data => {
      setLoading(true);

      const queryParams = new URLSearchParams(window.location.search);
      const clientType = queryParams.get('clientType');

      const {
        contractUser,
        contract,
        procedureType,
        address,
        location,
        authorizeEmailNotification,
        notificationAddress,
        notificationAddressState,
        notificationAddressCity,
        email,
        fullName,
        identification,
        identificationType,
        phone,
        subject,
        description,
        attachments: nonBase64attachments
      } = data;

      const conditionalData = {};

      if (contractUser === '1') {
        conditionalData.contractId = contract.id;
      } else {
        conditionalData.procedureType = procedureType;
      }

      if (authorizeEmailNotification === '0') {
        conditionalData.notificationAddress = notificationAddress;
        conditionalData.notificationLocation = `${notificationAddressState}, ${notificationAddressCity}`;
      }

      const attachments = [];

      for (const attachment of nonBase64attachments) {
        const { name: fileName, extension: fileExtension, file } = attachment;

        const fileContent = await fileToBase64(file);

        attachments.push({
          fileName,
          fileExtension,
          fileContent
        });
      }

      const pqrData = {
        ...conditionalData,
        address,
        location,
        authorizeEmailNotification: parseInt(authorizeEmailNotification, 10),
        email,
        fullName,
        identification,
        identificationType,
        phone,
        subject,
        description,
        attachments
      };
      if (clientType) {
        pqrData.clientType = clientType;
      }

      const response = await SubmitPqrAPI(pqrData, authToken);

      if (response.success) {
        setDialogData({
          id: response.data.data.id,
          service: {
            type: pqrData.contractId ? 'contrato' : 'trámite',
            value:
              pqrData.contractId || getProcedureTypeText(pqrData.procedureType)
          },
          address,
          location,
          subject,
          authorizeEmailNotification: pqrData.authorizeEmailNotification,
          email,
          notificationAddress: conditionalData.notificationAddress
        });
        setLoading(false);
        setSubmittedDialog(true);
      } else {
        setLoading(false);

        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
      }
    },
    [setAlert, authToken]
  );

  const closeHandler = useCallback(event => {
    event.preventDefault();
    setDialogData({});
    setSubmittedDialog(false);

    history.push(ROUTE_NAMES.home);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <div className={classes.root}>
        <PQRHelpDialog
          open={pqrDialogOpen}
          onClose={() => setPqrDialogOpen(false)}
        />
        {loading && <ModalProgress message="Enviando PQR"></ModalProgress>}
        {submittedDialog && (
          <PqrSubmitDialog
            open={submittedDialog}
            onClose={closeHandler}
            data={dialogData}
          />
        )}
        <Container maxWidth="md">
          <Title
            className={classes.title}
            text={'Peticiones, quejas y recursos (PQR)'}
          />
        </Container>
        <Container maxWidth="md" className={classes.stepperContainer}>
          <Stepper steps={PQR_STEPS} currentStep={currentStep} />
        </Container>
        <Container maxWidth="md">
          <StepperProvider onSubmit={handleSubmit}>
            <Step>
              <ServiceStep
                formId={FORM_ID}
                setCanSubmit={setCanSubmit}
                setGoBack={setGoBack}
                setCurrentStep={setCurrentStep}
                setNextButtonText={setNextButtonText}
              />
            </Step>
            <Step>
              <ContactStep
                formId={FORM_ID}
                setGoBack={setGoBack}
                setCurrentStep={setCurrentStep}
                setNextButtonText={setNextButtonText}
              />
            </Step>
            <Step>
              <DetailStep
                formId={FORM_ID}
                setGoBack={setGoBack}
                setCurrentStep={setCurrentStep}
                setNextButtonText={setNextButtonText}
              />
            </Step>
          </StepperProvider>
        </Container>
        <FooterStepper
          targetForm={FORM_ID}
          disabledNext={!canSubmit}
          goBack={goBack}
          nextButtonText={nextButtonText}
        />
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.custom.footerHeight.stepper
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2)
  },
  currentStep: {
    marginBottom: theme.custom.footerHeight.stepper
  },
  stepperContainer: {
    // * Mobile
    [theme.breakpoints.up('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    },
    [theme.breakpoints.up('xm')]: {
      paddingLeft: 0,
      paddingRight: 0,
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5)
    },
    // * Web
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing()
    }
  }
}));

export default PqrGDC;
