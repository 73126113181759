import React, { Fragment, useCallback, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import { history } from '../../../../Routes/history';
import BaseButton from '../../../../Components/Buttons/BaseButton';
import BaseDialog from '../../../../Components/Dialogs/BaseDialog';

import { SlideUpTransition } from '../../../../Components/Transitions/Transitions';
import { floatWithDots } from '../../../../Utils/Format/MoneyFormat';
import { saveAsPDF } from '../../../../Utils/Transform/Files';
import { OpenNewTab } from '../../../../Utils/Misc/Links';
import { ROUTE_NAMES } from '../../../../Routes/Routes';
import { AlertsDispatchContext } from '../../../../Contexts/AlertsContext';
import { GetDuplicateCouponPDFAPI } from '../../../../API/Coupons/CouponsAPI';
import { Company } from '../../../../Configs/general';

const GenerationStepGDG = props => {
  // * CONTEXTS
  const { step, onClose, open, title } = props;
  const setAlert = useContext(AlertsDispatchContext);

  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const data = step.data;

  const goToPayments = useCallback(
    event => {
      event.preventDefault();
      history.push(ROUTE_NAMES.payments, {
        type: 'coupon',
        value: data.couponId,
        currentStep: 0,
        disabledNext: false
      });
    },
    [data]
  );

  const _goToCloserMap = useCallback(() => {
    const baseUrl = window.location.href.substring(
      0,
      window.location.href.length - 1
    );
    OpenNewTab(`${baseUrl}${ROUTE_NAMES.map}`);
  }, []);

  const handlePDFGen = useCallback(
    async e => {
      e.preventDefault();
      setLoading(true);
      const response = await GetDuplicateCouponPDFAPI(
        data.couponId,
        data.period
      );

      setLoading(false);

      if (response.success) {
        const pdf = response.data.data.pdf;
        const pdfName = `pagos_${Company.shortName.toLowerCase()}_cupon_${
          data.couponId
        }.pdf`;
        saveAsPDF(pdf, pdfName);
      } else {
        setAlert({
          type: 'error',
          message: 'Ocurrió un error, intenta de nuevo'
        });
      }
    },
    [data, setAlert]
  );

  //* COMPONENTS
  const renderContent = useCallback(() => {
    return (
      <div id="GenerationStep_div_container" className={classes.content}>
        <Typography className={classes.text}>
          Se ha generado el cupón #{data.couponId} para tu factura del mes{' '}
          {data.period === 1 ? 'pasado ' : 'actual '}
          por un valor de <strong>{floatWithDots(data.value)}</strong>.
        </Typography>
        <div className={classes.optionsContainer}>
          <BaseButton
            color="secondary"
            size="large"
            disabled={loading}
            onClick={handlePDFGen}
          >
            Descargar PDF
          </BaseButton>
          <BaseButton
            color="secondary"
            size="large"
            onClick={goToPayments}
            className={classes.rightButton}
          >
            Pagar por PSE
          </BaseButton>
        </div>
        <Typography className={classes.textSmall}>
          Puedes descargar el cupón y pagarlo en cualquiera de nuestros{' '}
          <span className={classes.linkTextSmall} onClick={_goToCloserMap}>
            canales de pago
          </span>
        </Typography>
      </div>
    );
  }, [data, loading, handlePDFGen, goToPayments, _goToCloserMap, classes]);

  const isMobileSize = isWidthDown('xs', props.width);
  return (
    <Fragment>
      <BaseDialog
        open={open}
        loading={loading}
        handleClose={onClose}
        title={title}
        actions={null}
        content={renderContent}
        fullScreen={isMobileSize}
        contentSize="small"
        TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
        disableBackdropClick={true}
      />
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    minHeight: 200
  },
  valueText: {
    fontSize: 14,
    marginTop: theme.spacing(4)
  },
  text: {
    fontSize: 14,
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2),
    width: '100%'
  },
  title: {
    fontSize: 14,
    marginBottom: theme.spacing(2)
  },
  optionsContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(2)
  },
  textSmall: {
    fontSize: 10,
    color: theme.palette.text.blackLight,
    marginTop: theme.spacing()
  },
  rightButton: {
    marginLeft: theme.spacing(3)
  },
  linkTextSmall: {
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.secondary.dark
    }
  }
}));

export default withWidth()(GenerationStepGDG);
