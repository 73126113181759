import * as React from 'react';

const CovidIcon = ({ width = 16, height = 16, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    {...rest}
  >
    <path data-name="Trazado 787" d="M0 0h16v16H0Z" fill="none" />
    <circle
      data-name="Elipse 200"
      cx={3.333}
      cy={3.333}
      r={3.333}
      transform="translate(4.667 4.667)"
      fill="none"
      stroke="#3bd4ae"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      data-name="Trazado 788"
      d="M8 4.667V2m-.667 0h1.333"
      fill="none"
      stroke="#3bd4ae"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      data-name="Trazado 789"
      d="m10.357 5.643 1.886-1.885m-.472-.472.943.943"
      fill="none"
      stroke="#3bd4ae"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      data-name="Trazado 790"
      d="M11.333 8H14m0-.667v1.333"
      fill="none"
      stroke="#3bd4ae"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      data-name="Trazado 791"
      d="m10.357 10.357 1.885 1.886m.472-.472-.943.943"
      fill="none"
      stroke="#3bd4ae"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      data-name="Trazado 792"
      d="M8 11.333V14m.667 0H7.334"
      fill="none"
      stroke="#3bd4ae"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      data-name="Trazado 793"
      d="m5.643 10.357-1.886 1.885m.472.472-.943-.943"
      fill="none"
      stroke="#3bd4ae"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      data-name="Trazado 794"
      d="M4.667 8H2m0 .667V7.334"
      fill="none"
      stroke="#3bd4ae"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      data-name="Trazado 795"
      d="M5.643 5.643 3.758 3.757m-.472.472.943-.943"
      fill="none"
      stroke="#3bd4ae"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CovidIcon;
