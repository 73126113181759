import * as React from 'react';

function RibbonIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || '24'}
      height={props.size || '24'}
      viewBox="0 0 31.714 34"
      {...props}
    >
      <g id="ic-ribbon-outline" transform="translate(-47 -31)">
        <circle
          id="Elipse_212"
          data-name="Elipse 212"
          cx="9.143"
          cy="9.143"
          r="9.143"
          transform="translate(53.714 32)"
          fill="none"
          stroke="#69d6d6"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Trazado_670"
          data-name="Trazado 670"
          d="M54.832,227.82,48,240.119l6.2-.03a1.143,1.143,0,0,1,.987.557l3.094,5.187,6.309-13.88"
          transform="translate(0 -181.833)"
          fill="none"
          stroke="#69d6d6"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Trazado_671"
          data-name="Trazado 671"
          d="M263.9,224l6.961,12.571-6.2-.03a1.143,1.143,0,0,0-.987.557l-3.094,5.187L256,232.229"
          transform="translate(-193.143 -178.286)"
          fill="none"
          stroke="#69d6d6"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <circle
          id="Elipse_213"
          data-name="Elipse 213"
          cx="4.571"
          cy="4.571"
          r="4.571"
          transform="translate(58.286 36.571)"
          fill="none"
          stroke="#69d6d6"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export default RibbonIcon;
