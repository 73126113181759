import moment from 'moment';

// lac: login apple credential sub
export const prefix = 'lac-sub-';
export const LocalAppleUserDataKey = sub => `${prefix}${sub}`;

export const setLocalAppleUserData = ({ firstName, lastName, sub }) => {
  // In this case, iat is epoch timestamp in milliseconds
  const iat = moment().valueOf();

  const userData = JSON.stringify({
    firstName,
    lastName,
    sub,
    iat
  });

  localStorage.setItem(LocalAppleUserDataKey(sub), userData);
};

export const getLocalAppleUserData = sub => {
  const localAppleUserData = localStorage.getItem(LocalAppleUserDataKey(sub));

  if (!localAppleUserData) {
    return null;
  }

  return JSON.parse(localAppleUserData);
};

export const removeLocalAppleUserData = sub =>
  localStorage.removeItem(LocalAppleUserDataKey(sub));

export const removeLocalExpiredAppleData = (
  expireTime = 24,
  timeUnit = 'hours'
) => {
  for (let index = 0; index < localStorage.length; index++) {
    const localItemKey = localStorage.key(index);

    if (!localItemKey.startsWith(prefix)) {
      continue;
    }

    const { iat } = JSON.parse(localStorage.getItem(localItemKey));

    const now = moment();
    const expireDate = moment(iat).add(expireTime, timeUnit);

    if (now.isAfter(expireDate)) {
      localStorage.removeItem(localItemKey);
    }
  }
};
