import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid, useMediaQuery } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const InsuranceDetailSkeleton = () => {
  // * OTHER HOOKS
  const classes = useStyles();
  const isMobileSize = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  return (
    <div className={classes.card}>
      <Grid container className={classes.cardContent}>
        <Grid item>
          <div className={classes.productLogoGrid}>
            <Skeleton
              variant="rect"
              height={100}
              width={100}
              className={classes.productLogo}
            />
          </div>
        </Grid>
        <Grid item className={classes.planInformationContainer}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          {isMobileSize && (
            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: theme.custom.borderRadius,
    border: `1px solid ${theme.palette.background.border}`,
    position: 'relative',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: theme.spacing(3, 2)
    }
  },
  cardContent: {
    display: 'flex',
    minHeight: 187.75,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'column',
      minHeight: 444.43
    }
  },
  planInformationContainer: {
    flex: 1
  },
  productLogoGrid: {
    maxWidth: 100,
    minHeight: 100,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(2),
    boxShadow: '0px 5px 13px 3px rgba(0,0,0,0.1)',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginBottom: theme.spacing(2),
      marginRight: 'auto',
      marginLeft: 'auto',
      width: 100
    }
  },
  productLogo: {
    borderRadius: theme.custom.borderRadius
  }
}));

export default InsuranceDetailSkeleton;
