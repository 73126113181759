import * as React from 'react';

function InformationCircle(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || '16'}
      height={props.size || '16'}
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        id="information-circle"
        d="M64,56a8,8,0,1,0,8,8A8.009,8.009,0,0,0,64,56Zm0,3.28a1.04,1.04,0,1,1-1.04,1.04A1.04,1.04,0,0,1,64,59.28Zm1.92,9.04H62.4a.64.64,0,0,1,0-1.28h1.12V63.52h-.64a.64.64,0,0,1,0-1.28h1.28a.64.64,0,0,1,.64.64v4.16h1.12a.64.64,0,0,1,0,1.28Z"
        transform="translate(-56 -56)"
        fill="#104eb2"
      />
    </svg>
  );
}

export default InformationCircle;
