import React, { useRef } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { Controller } from 'react-hook-form';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  container: {
    flexWrap: 'nowrap'
  },
  radioRoot: {
    color: `${theme.palette.primary.main} !important`,
    minWidth: 64,
    minHeight: 40,
    fontSize: 13,
    lineHeight: 1.75,
    padding: [[theme.spacing(), theme.spacing(2)]],
    borderRadius: 4,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderWidth: 1
  },
  radioChecked: {
    color: 'white !important',
    border: 'none',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.dark} !important`
    }
  },
  radioDisabled: {
    color: `${theme.palette.primary.disabled} !important`,
    borderColor: `${theme.palette.primary.disabled} !important`
  },
  radioCheckedDisabled: {
    backgroundColor: `${theme.palette.primary.disabled} !important`
  },
  radioLeftOption: {
    marginRight: theme.spacing(2)
  }
}));

const ControlledYesNoButton = props => {
  const { name, control, inverted = false, disabled = false, ...rest } = props;

  const leftValue = inverted ? '0' : '1';
  const rightValue = inverted ? '1' : '0';
  const leftText = inverted ? 'No' : 'Si';
  const rightText = inverted ? 'Si' : 'No';

  const classes = useStyles();

  const leftRadio = useRef();

  return (
    <Controller
      as={
        <RadioGroup row={true} classes={{ root: classes.container }}>
          <Radio
            inputRef={leftRadio}
            value={leftValue}
            disabled={disabled}
            color="primary"
            classes={{
              root: clsx(classes.radioRoot, classes.radioLeftOption),
              checked: classes.radioChecked,
              disabled:
                leftRadio.current && leftRadio.current.checked
                  ? classes.radioCheckedDisabled
                  : classes.radioDisabled
            }}
            icon={leftText}
            checkedIcon={leftText}
          />
          <Radio
            value={rightValue}
            disabled={disabled}
            color="primary"
            classes={{
              root: classes.radioRoot,
              checked: classes.radioChecked,
              disabled:
                leftRadio.current && leftRadio.current.checked
                  ? classes.radioDisabled
                  : classes.radioCheckedDisabled
            }}
            icon={rightText}
            checkedIcon={rightText}
          />
        </RadioGroup>
      }
      name={name}
      control={control}
      {...rest}
    />
  );
};

export default ControlledYesNoButton;
