import React from 'react';
import { portal, Portal } from '../../../../Configs/general';
import PunishedDebtCardGDC from './PunishedDebtCard.GDC';
import PunishedDebtCardEFG from './PunishedDebtCard.EFG';

const PunishedDebtCard = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <PunishedDebtCardGDC {...props} />;
    case Portal.Efigas:
      return <PunishedDebtCardEFG {...props} />;
    default:
      return <></>;
  }
};

export default PunishedDebtCard;
