import axios from 'axios';
import { config } from '../../Configs';

export const GetUserTransactions = async (token, body) => {
  try {
    const response = await axios.post(
      `${config.api.url}/transactions/user`,
      body,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GenerateTransactionsReport = async (token, body) => {
  try {
    const response = await axios.post(
      `${config.api.url}/transactions/user/report`,
      body,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};
