/* eslint-disable complexity */
import React, {
  Fragment,
  useContext,
  useEffect,
  useCallback,
  useState
} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';

import FlagColombia from '../../../../Components/CustomIcons/Flags/FlagColombia';
import SweetAlert from '../../../../Components/Alerts/SweetAlert';
import TextInput from '../../../../Components/Inputs/TextInput';
import CompositeSelectInput from '../../../../Components/Inputs/CompositeSelectInput';
import NumberInput from '../../../../Components/Inputs/NumberInput';
import Title from '../../../../Components/Labels/Title';
import PhoneValidation from '../../../../Views/Profile/PhoneValidation';

import { UserContext } from '../../../../Contexts/UserContext';
import {
  SetCurrentStepIndexContext,
  StepperDataContext,
  StepperDataDispatchContext,
  CurrentStepIndexContext
} from '../../../../Contexts/StepperContext';

import { IDENTIFICATION_OPTIONS } from '../Deferred_enums';
import { history } from '../../../../Routes/history';

const schema = yup.object({
  firstName: yup
    .string()
    .max(150, 'Máximo 150 carácteres')
    .required('Ingresa los nombres'),
  lastName: yup
    .string()
    .max(150, 'Máximo 150 carácteres')
    .required('Ingresa los apellidos'),
  identificationType: yup
    .string()
    .oneOf(IDENTIFICATION_OPTIONS.map(idType => idType.value))
    .required('Elija un tipo valido'),
  identification: yup
    .number()
    .typeError('El número de identificación debe ser válido')
    .positive('El número de identificación debe ser válido')
    .test('integer', 'El número de identificación debe ser válido', val => {
      return val % 1 === 0;
    })
    .required('Ingresa el número de identificación'),
  phone: yup
    .number()
    .typeError('El número de teléfono debe ser válido')
    .positive('El número de teléfono debe ser válido')
    .test('integer', 'El número de teléfono debe ser válido', val => {
      return val % 1 === 0;
    })
    .test('length', 'El número de teléfono debe ser de 10 dígitos', val => {
      return val.toString().length === 10;
    })
    .required('Ingresa el número de teléfono')
});

const PersonalDataStep = props => {
  const {
    formId,
    setCanSubmit,
    setGoBack,
    setCurrentStep: setVisualStepperIndex,
    setNextButtonText,
    setAlert
  } = props;

  const classes = useStyles();

  const currentStepIndex = useContext(CurrentStepIndexContext);
  const setCurrentStep = useContext(SetCurrentStepIndexContext);
  const setData = useContext(StepperDataDispatchContext);
  const stepperData = useContext(StepperDataContext);
  const currentUser = useContext(UserContext);

  const { register, watch, getValues, errors, control, handleSubmit } = useForm(
    {
      validationSchema: schema,
      submitFocusError: true,
      defaultValues: {
        firstName: currentUser ? currentUser.firstName : '',
        lastName: currentUser ? currentUser.lastName : '',
        email: currentUser ? currentUser.email : '',
        identificationType:
          currentUser && currentUser.identificationType
            ? currentUser.identificationType
            : 'CC',
        identification: currentUser ? currentUser.identification : '',
        phone: currentUser ? currentUser.phone : '',
        ...stepperData
      }
    }
  );

  const [phoneUpdate, setPhoneUpdate] = useState(false);

  const watchForm = watch(['phoneCountryCode', 'phone']);

  const goNext = useCallback(
    values => {
      setData(data => ({ ...data, ...values }));
      setCurrentStep(step => step + 1);
    },
    [setCurrentStep, setData]
  );

  const validatePhone =
    (currentUser.phoneCountryCode &&
      currentUser.phoneCountryCode !== watchForm.phoneCountryCode) ||
    (currentUser.phone && currentUser.phone !== watchForm.phone.toString()) ||
    !currentUser.phoneValidated;

  const onForward = values => {
    if (
      currentUser.phoneCountryCode !== values.phoneCountryCode ||
      currentUser.phone !== values.phone.toString() ||
      !currentUser.phoneValidated
    ) {
      setPhoneUpdate({ submit: true });
      return;
    }

    goNext(values);
  };

  const onBackward = useCallback(() => {
    history.goBack();
  }, []);

  const requestCallback = () => {
    return {
      success: true
    };
  };

  const successCallback = () => {
    goNext(getValues());
  };

  useEffect(() => {
    setNextButtonText('Continuar');
    setVisualStepperIndex(currentStepIndex);
    setCanSubmit(true);
    setGoBack({
      action: onBackward
    });
  }, [
    setGoBack,
    onBackward,
    setVisualStepperIndex,
    currentStepIndex,
    setNextButtonText,
    setCanSubmit
  ]);

  if (!currentUser) {
    return <Fragment></Fragment>;
  }

  return (
    <div>
      {phoneUpdate && (
        <PhoneValidation
          setAlert={setAlert}
          phoneUpdate={phoneUpdate}
          setPhoneUpdate={setPhoneUpdate}
          phone={watchForm.phone}
          phoneCountryCode={watchForm.phoneCountryCode}
          requestCallback={requestCallback}
          successCallback={successCallback}
        />
      )}
      <Title title="Valida tus datos personales" className={classes.title} />

      <form id={formId} onSubmit={handleSubmit(onForward)}>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <TextInput
              label="Nombres*"
              inputProps={{ maxLength: 100 }}
              inputRef={register}
              fullWidth
              name="firstName"
              error={Boolean(errors.firstName)}
              helperText={errors.firstName && errors.firstName.message}
              margin="none"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextInput
              label="Apellidos*"
              inputProps={{ maxLength: 100 }}
              inputRef={register}
              fullWidth
              name="lastName"
              error={Boolean(errors.lastName)}
              helperText={errors.lastName && errors.lastName.message}
              margin="none"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <CompositeSelectInput
              label="Identificación*"
              options={IDENTIFICATION_OPTIONS}
              TextInputProps={{
                label: 'Identificación*',
                name: 'identification',
                defaultValue: currentUser.identification || '',
                inputRef: register,
                fullWidth: true,
                margin: 'none'
              }}
              SelectInputProps={{
                name: 'identificationType',
                defaultValue: currentUser.identificationType || 'CC',
                control
              }}
              error={Boolean(errors.identification)}
              helperText={
                errors.identification && errors.identification.message
              }
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Controller
                  as={
                    <NumberInput
                      id="MyProfile_input_countryCode"
                      autoComplete="tel-country-code"
                      required
                      InputProps={{
                        startAdornment: (
                          <FlagColombia size={20} style={{ flexShrink: 0 }} />
                        )
                      }}
                      format="+###"
                      placeholder="57"
                      fullWidth
                      label="Prefijo"
                      error={Boolean(errors.phoneCountryCode)}
                      helperText={
                        errors.phoneCountryCode &&
                        errors.phoneCountryCode.message
                      }
                      margin="none"
                    />
                  }
                  control={control}
                  name="phoneCountryCode"
                  defaultValue={currentUser.phoneCountryCode || '57'}
                  onChangeName="onValueChange"
                  onChange={values => {
                    if (values[0]) {
                      return values[0].value;
                    }
                    return '';
                  }}
                />
              </Grid>
              <Grid item xs={7}>
                <Controller
                  as={
                    <NumberInput
                      id="MyProfile_input_phone"
                      autoComplete="tel-local"
                      required
                      fullWidth
                      margin="none"
                      label="Número celular"
                      error={Boolean(errors.phone)}
                      helperText={errors.phone && errors.phone.message}
                    />
                  }
                  control={control}
                  name="phone"
                  defaultValue={currentUser.phone || ''}
                  onChangeName="onValueChange"
                  onChange={values => {
                    if (values[0]) {
                      return values[0].value;
                    }
                    return '';
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {!errors.phone && validatePhone && (
            <Grid item xs={12}>
              <SweetAlert
                type="info"
                classes={{
                  root: classes.sweetAlert,
                  message: classes.sweetAlertText
                }}
                message={
                  'Validaremos tu número de teléfono antes de continuar al siguiente paso'
                }
              />
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(4),
    fontWeight: 600
  },
  sweetAlertText: {
    fontSize: 12,
    color: theme.palette.text.primary
  },
  sweetAlert: {
    marginTop: theme.spacing(1.5),
    fontSize: 14
  },
  summaryContainer: {
    marginBottom: theme.spacing(3)
  },
  inputRoot: {
    padding: theme.spacing(2, 1.5)
  }
}));

export default PersonalDataStep;
