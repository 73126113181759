import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import NoAuthHomeGDC from './NoAuthHome.GDC';
import NoAuthHomeGDG from './NoAuthHome.GDG';
import NoAuthHomeEFG from './NoAuthHome.EFG';
import NoAuthHomeAAA from './NoAuthHome.AAA';

const NoAuthHome = () => {
  switch (portal) {
    case Portal.Gascaribe:
      return <NoAuthHomeGDC />;
    case Portal.Gasguajira:
      return <NoAuthHomeGDG />;
    case Portal.Efigas:
      return <NoAuthHomeEFG />;
    case Portal.TripleA:
      return <NoAuthHomeAAA />;
    default:
      return <></>;
  }
};

export default NoAuthHome;
