import React, { useContext, useEffect, useRef, useState } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import BaseButton from './BaseButton';
import { AppleClientInitializedContext } from '../../Contexts/AppleClientContext';
import AppleLogo from '../CustomIcons/AppleLogo';
import clsx from 'clsx';

const NativeAppleButton = props => {
  const { buttonWidth = 280, type = 'sign-in' } = props;
  const classes = useStyles();

  useEffect(() => {
    window.AppleID.auth.renderButton();
  }, [buttonWidth]);

  return (
    <div
      className={classes.appleButton}
      id="appleid-signin"
      data-type={type}
      data-color="white"
      data-border="false"
      data-border-radius="4"
    ></div>
  );
};

const AppleButton = props => {
  const classes = useStyles();

  const { loading, disabled, customContainer, type = 'sign-in' } = props;

  const isAppleInitialized = useContext(AppleClientInitializedContext);

  const buttonContainerRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(null);

  useEffect(() => {
    setButtonWidth(buttonContainerRef.current.clientWidth);
  }, []);

  return (
    <div
      className={clsx(classes.container, customContainer)}
      ref={buttonContainerRef}
    >
      {isAppleInitialized && buttonWidth && !disabled && (
        <NativeAppleButton buttonWidth={buttonWidth} type={type} />
      )}

      {(!isAppleInitialized || disabled) && (
        <BaseButton
          disabled={disabled}
          fullWidth
          id="apple-login"
          color="primary"
          variant="outlined"
        >
          <AppleLogo size={16} className={classes.appleLogo} />
          Ingresa con Apple
        </BaseButton>
      )}

      {loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress size={16} />
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    maxWidth: 275,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      maxWidth: '100%'
    },
    marginTop: theme.spacing(),
    position: 'relative'
  },
  loadingContainer: {
    position: 'absolute',
    top: 1,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  appleButton: {
    cursor: 'pointer',
    height: 40,
    '& > div': {
      width: '100% !important',
      height: '100% !important',
      border: '1px solid #dadce0 !important',
      borderRadius: '3px !important',
      '&:hover': {
        borderColor: '#d2e3fc !important'
      }
    },
    '& > div > div': {
      '&:hover': {
        backgroundColor: 'rgba(66, 133, 244, 0.04)'
      }
    },
    '& > div > div > svg': {
      height: 38,
      width: '100%'
    }
  },
  appleLogo: {
    marginRight: theme.spacing()
  }
}));

export default AppleButton;
