import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import Circle from '../CustomIcons/Circle';
import clsx from 'clsx';

const DefaultStatusText = {
  toDo: 'Por pagar',
  isDone: 'Pagado',
  isPending: 'En proceso'
};

const StatusLabel = props => {
  const {
    isDone = false,
    isPending = false,
    withStatusLabel = false,
    statusTexts = DefaultStatusText
  } = props;

  let statusText = statusTexts.toDo;
  if (isDone) {
    statusText = statusTexts.isDone;
  } else if (isPending) {
    statusText = statusTexts.isPending;
  }

  const classes = useStyles();

  return (
    <Typography className={classes.headerStatusTitle}>
      {withStatusLabel && 'Estado:'}
      <Circle
        className={clsx(classes.headerCircle, {
          [classes.toDoStatus]: !isDone && !isPending,
          [classes.doneStatus]: isDone,
          [classes.pendingStatus]: isPending
        })}
      />
      <span
        className={clsx(classes.headerStatusText, {
          [classes.toDoStatusText]: !isDone && !isPending,
          [classes.doneStatusText]: isDone,
          [classes.pendingStatusText]: isPending
        })}
      >
        {statusText}
      </span>
    </Typography>
  );
};

const useStyles = makeStyles(theme => ({
  headerStatusTitle: {
    display: 'inline',
    fontWeight: 500,
    color: theme.palette.text.main,
    textAlign: 'end',
    fontSize: 12,
    [theme.breakpoints.up('xm')]: {
      fontSize: 13
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
      textAlign: 'start'
    }
  },
  headerStatusText: {
    fontWeight: 500,
    color: '#696A6A'
  },
  headerCircle: {
    marginRight: 7,
    [theme.breakpoints.up('sm')]: {
      marginLeft: 11
    }
  },
  toDoStatusText: {
    color: 'rgba(12,31,44,0.38)'
  },
  doneStatusText: {
    color: '#18cc48'
  },
  pendingStatusText: {
    color: '#fcc52c'
  },
  toDoStatus: {
    fill: 'rgba(12,31,44,0.38)'
  },
  doneStatus: {
    fill: '#18cc48'
  },
  pendingStatus: {
    fill: '#fcc52c'
  }
}));

export default StatusLabel;
