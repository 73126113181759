import React from 'react';
import { portal, Portal } from '../../../../Configs/general';

import AuthHeaderGDC from './AuthHeader.GDC';
import AuthHeaderGDG from './AuthHeader.GDG';
import AuthHeaderEFG from './AuthHeader.EFG';
import AuthHeaderAAA from './AuthHeader.AAA';

const AuthHeader = () => {
  switch (portal) {
    case Portal.Gascaribe:
      return <AuthHeaderGDC />;
    case Portal.Gasguajira:
      return <AuthHeaderGDG />;
    case Portal.Efigas:
      return <AuthHeaderEFG />;
    case Portal.TripleA:
      return <AuthHeaderAAA />;
    default:
      return <></>;
  }
};

export default AuthHeader;
