import React from 'react';
import clsx from 'clsx';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from '@material-ui/core';

// icons
import BackIcon from '../CustomIcons/BackIcon';
import IcCloseCircle from '../CustomIcons/IcCloseCircle';

import BaseButton from '../Buttons/BaseButton';
import SimpleDivider from '../Dividers/SimpleDivider';

const BaseFilterModal = props => {
  const classes = useStyles();

  const {
    id,
    open = true,
    handleClose,
    filterModalTitle = 'Volver',
    content,
    clearFilters,
    modalClearFiltersStyles,
    filter,
    showClearFilters,
    disableClearOutside,
    filterButtonOn,
    ...rest
  } = props;

  return (
    <Dialog id={id} open={open} onClose={handleClose} fullScreen {...rest}>
      <DialogTitle disableTypography className={classes.containerTitle}>
        <IconButton
          className={classes.titleButton}
          size="small"
          aria-label="cerrar diálogo"
          onClick={handleClose}
        >
          <BackIcon size={20} className={classes.backIcon} />
        </IconButton>
        <Typography className={classes.titleText} onClick={handleClose}>
          {filterModalTitle}
        </Typography>
      </DialogTitle>
      <SimpleDivider withoutMargin />
      <DialogContent classes={{ root: classes.dialogRoot }}>
        <Grid container>
          {showClearFilters && !disableClearOutside && (
            <Grid item>
              <Typography
                className={clsx(
                  classes.textClearFilter,
                  modalClearFiltersStyles
                )}
                onClick={() => {
                  clearFilters();
                }}
              >
                Limpiar filtros
                <IcCloseCircle className={classes.iconClearFilter} />
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} className={classes.containerContent}>
            {content && content()}
          </Grid>
          <Grid item xs={12}>
            <BaseButton
              fullWidth
              disabled={!filterButtonOn}
              className={classes.buttonConsult}
              onClick={filter}
            >
              Filtrar
            </BaseButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  containerTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  titleButton: {
    fill: theme.palette.primary.main,
    marginRight: theme.spacing(),
    padding: theme.spacing()
  },
  titleText: {
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 1.2
  },
  dialogRoot: {
    padding: theme.spacing(3)
  },
  containerContent: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  textClearFilter: {
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: theme.spacing()
  },
  iconClearFilter: {
    fill: theme.palette.text.greyDark,
    marginLeft: theme.spacing()
  },
  buttonConsult: {
    fontSize: 16
  },
  backIcon: {
    fill: theme.palette.primary.main
  }
}));

export default BaseFilterModal;
