import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import MyContractsMobileGDC from './MyContractsMobile.GDC';
import MyContractsMobileGDG from './MyContractsMobile.GDG';

const MyContractsMobile = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <MyContractsMobileGDC {...props} />;
    case Portal.TripleA:
    case Portal.Gasguajira:
      return <MyContractsMobileGDG {...props} />;
    case Portal.Efigas:
      return <MyContractsMobileGDG {...props} />;
    default:
      return <></>;
  }
};

export default MyContractsMobile;
