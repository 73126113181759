import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ButtonBase from '@material-ui/core/ButtonBase';
import { history } from '../../Routes/history';

const LinkButton = props => {
  const classes = useStyles();
  const smallScreen = useMediaQuery('(min-width:600px)');
  const { text, to, id, ...other } = props;

  const handleGoTo = useCallback(() => {
    history.push(to);
  }, [to]);

  return (
    <ButtonBase
      id={id}
      disableRipple={smallScreen}
      focusVisibleClassName={classes.focusVisible}
      className={classes.button}
      onClick={handleGoTo}
      {...other}
    >
      {text || props.children || 'Button'}
    </ButtonBase>
  );
};
const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    // * Text
    fontFamily: 'Montserrat',
    textAlign: 'center',
    color: theme.palette.color.black,
    // * Responsive
    [theme.breakpoints.up('xs')]: {
      fontSize: '0.8em'
    },
    [theme.breakpoints.up('xm')]: {
      fontSize: '0.9em'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1em',
      '&:hover, &$focusVisible': {
        color: theme.palette.color.primary
      }
    }
  },
  focusVisible: {}
}));

export default LinkButton;
