import React, { forwardRef } from 'react';
import { Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import withWidth from '@material-ui/core/withWidth';
import BaseButton from '../Buttons/BaseButton';
import clsx from 'clsx';

const AlertFullView = props => {
  const {
    title,
    icon: IconComponent,
    subtitle,
    redirectTo = '/',
    redirectText,
    width,
    children,
    classes = {}
  } = props;
  const baseClasses = useStyles();

  const LinkComponent = forwardRef((buttonProps, _) => (
    <NavLink to={redirectTo} {...buttonProps} replace />
  ));
  LinkComponent.displayName = 'notfoundLink';
  return (
    <Container className={clsx(baseClasses.root, classes.root)}>
      {IconComponent && (
        <IconComponent className={clsx(baseClasses.icon, classes.icon)} />
      )}
      {title && (
        <Typography
          variant={'h1'}
          className={clsx(baseClasses.message, classes.message)}
        >
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography
          className={clsx(baseClasses.subMessage, classes.subMessage)}
        >
          {subtitle}
        </Typography>
      )}
      {redirectText && (
        <BaseButton
          color="primary"
          variant="outlined"
          fullWidth={width === 'xs'}
          component={LinkComponent}
          className={baseClasses.button}
        >
          {redirectText}
        </BaseButton>
      )}
      {children}
    </Container>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    maxWidth: 1000,
    overflow: 'auto'
  },
  icon: {
    height: 250,
    viewBox: '0 0 378.269 250.878',
    marginBottom: theme.spacing(),
    transform: 'scale(0.75)',
    [theme.breakpoints.up('xm')]: {
      transform: 'scale(0.85)',
      marginBottom: theme.spacing(3)
    },
    [theme.breakpoints.up('sm')]: {
      transform: 'scale(1)'
    }
  },
  message: {
    fontSize: '1em',
    textAlign: 'center',
    lineHeight: 1.19,
    marginTop: theme.spacing(),
    [theme.breakpoints.up('xm')]: {
      marginTop: theme.spacing(3),
      fontSize: '1.25em'
    }
  },
  subMessage: {
    marginTop: theme.spacing(2),
    fontSize: '0.85em',
    textAlign: 'center',
    lineHeight: 1.19,
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
      fontSize: '1em'
    }
  },
  button: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(5)
    }
  }
}));

export default withWidth()(AlertFullView);
