import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import SimpleDivider from '../../../Components/Dividers/SimpleDivider';

import Title from '../../../Components/Labels/Title';
import { TRANSACTION_STATUS } from '../payment_enums';

const TransactionErrors = props => {
  const { transaction = {} } = props;

  // * STATE HOOKS;

  // * OTHER HOOKS
  const classes = useStyles();

  // * FUNCTIONS
  const renderTitleError = () => {
    if (transaction.status === TRANSACTION_STATUS.Pending) {
      return <Title text="Observaciones" />;
    }
    if (transaction.status === TRANSACTION_STATUS.Rejected) {
      return <Title text="Motivo de rechazo" />;
    }
  };

  return (
    <div id="TransactionErrors_TextError" className={classes.root}>
      {renderTitleError()}
      <SimpleDivider withoutMargin />
      <Grid container direction="row">
        <Grid item xs={12} sm={10}>
          <Typography className={classes.message}>
            {transaction.message}
          </Typography>
        </Grid>
      </Grid>
      <SimpleDivider withoutMargin classes={classes.dividerHide} />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 0,
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing()
    }
  },
  message: {
    paddingLeft: theme.spacing(2),
    lineHeight: 2,
    [theme.breakpoints.up('sm')]: {
      lineHeight: 3
    }
  },
  dividerHide: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  }
}));

export default TransactionErrors;
