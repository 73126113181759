import React from 'react';

const ContractsIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 20}
      height={props.size || 20}
      viewBox="0 0 56 56"
      {...props}
    >
      <path data-name="Trazado 661" d="M0 0h56v56H0z" fill="none" />
      <g data-name="Grupo 799" fill="currentColor">
        <path
          data-name="Elipse 5"
          d="M36.271 22.722a9.1 9.1 0 11-9.1 9.1 9.107 9.107 0 019.1-9.1zm0 16.6a7.5 7.5 0 10-7.5-7.5 7.51 7.51 0 007.5 7.498z"
        />
        <path
          data-name="Trazado 662"
          d="M41.776 52.253a.8.8 0 01-.479-.16l-5.043-3.782-5.043 3.782a.8.8 0 01-1.276-.638V39.032a.8.8 0 011.6 0v10.827l4.245-3.184a.8.8 0 01.957 0l4.245 3.184V39.032a.8.8 0 011.6 0v12.423a.8.8 0 01-.8.8z"
        />
        <path
          data-name="Trazado 663"
          d="M22.45 43.897H8.647A6.254 6.254 0 012.4 37.651V10.044a6.254 6.254 0 016.247-6.247H47.3a6.254 6.254 0 016.247 6.247v27.607a6.265 6.265 0 01-3.123 5.4.725.725 0 11-.725-1.256 4.81 4.81 0 002.4-4.149V10.044a4.8 4.8 0 00-4.8-4.8H8.647a4.8 4.8 0 00-4.8 4.8v27.607a4.8 4.8 0 004.8 4.8h13.8a.726.726 0 010 1.451z"
        />
        <path
          data-name="L\xEDnea 4"
          d="M44.569 16.585H11.374a.726.726 0 110-1.451h33.2a.726.726 0 110 1.451z"
        />
        <path
          data-name="L\xEDnea 5"
          d="M20.311 24.246h-8.937a.726.726 0 110-1.451h8.937a.726.726 0 110 1.451z"
        />
        <path
          data-name="L\xEDnea 6"
          d="M16.929 32.855h-5.522a.726.726 0 110-1.451h5.522a.726.726 0 010 1.451z"
        />
      </g>
    </svg>
  );
};

export default ContractsIcon;
