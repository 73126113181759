import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import GenerationPartialPaymentStep from './Steps/GenerationPartialPaymentStep.js';
import ValuePartialPaymentStep from './Steps/ValuePartialPaymentStep.js';
import { UserContext } from '../../../Contexts/UserContext.js';
import _get from 'lodash/get';
import { GetContractDebtsAPI } from '../../../API/Debts/DebtsAPI.js';
import { getBrillaCurrentDebtValue } from '../helpers/helpers.js';

const DebtPartialPaymentDialog = props => {
  const {
    open,
    onClose,
    requestCallback,
    paymentData,
    partialValueStepTitle = 'Generación de cupón',
    description = null
  } = props;

  const { contractId, maxValue = null } = paymentData;

  const currentUser = useContext(UserContext);
  const authToken = _get(currentUser, 'token');
  const [debtsData, setDebtsData] = useState(null);
  const [currentDebtValue, setCurrentDebtValue] = useState(null);
  const [currentDebtValueExist, setCurrentDebtValueExist] = useState(false);
  const [step, setStep] = useState({
    type: 'input',
    data: { ...paymentData, currentDebtValueExist }
  });

  const setNextModal = useCallback(({ type, data }) => {
    setStep({ type, data });
  }, []);

  const currentStep = step.type;

  useEffect(() => {
    const fetchData = async () => {
      const debtsResponse = await GetContractDebtsAPI(authToken, contractId);
      if (debtsResponse.success) {
        const responseData = debtsResponse.data.data;
        setDebtsData(responseData);
      }
      setCurrentDebtValueExist(true);
    };
    if (!maxValue) {
      fetchData();
    }
  }, [authToken, paymentData, contractId, maxValue]);

  useEffect(() => {
    const value = debtsData ? getBrillaCurrentDebtValue(debtsData) : 0;
    setCurrentDebtValue(value);
  }, [debtsData]);

  useEffect(() => {
    if (!maxValue) {
      setStep({
        type: 'input',
        data: {
          ...paymentData,
          maxValue: currentDebtValue,
          currentDebtValueExist
        }
      });
    }
  }, [currentDebtValue, paymentData, currentDebtValueExist, maxValue]);

  if (!open) {
    return <Fragment></Fragment>;
  }

  return (
    <>
      {currentStep === 'input' && (
        <ValuePartialPaymentStep
          title={partialValueStepTitle}
          description={description}
          open={true}
          onClose={onClose}
          setNextModal={setNextModal}
          requestCallback={requestCallback}
          step={step}
          authToken={authToken}
        />
      )}
      {currentStep === 'generation' && (
        <GenerationPartialPaymentStep
          title="Generación de cupón"
          open={true}
          onClose={onClose}
          step={step}
          authToken={authToken}
        />
      )}
    </>
  );
};

export default DebtPartialPaymentDialog;
