import React, { Fragment, useState, useCallback, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { ContractSelectedContext } from '../../Contexts/ContractsContext';
import { UserContext } from '../../Contexts/UserContext';
import ContractStep from './DuplicateSteps/ContractStep/ContractStep';
import SelectionStep from './DuplicateSteps/SelectionStep';
import GenerationStep from './DuplicateSteps/GenerationStep/GenerationStep';
import ValidationStep from './DuplicateSteps/ValidationStep/ValidationStep';
import CellphoneStep from './DuplicateSteps/CellphoneStep';

const Duplicates = props => {
  const { open = true, onClose } = props;

  const classes = useStyles();

  const selectedContract = useContext(ContractSelectedContext);
  const currentUser = useContext(UserContext);

  const totalSteps = currentUser ? 5 : 4;

  const updatedCurrentUser = useCallback(() => {
    return currentUser;
  }, [currentUser]);

  let defaultContract = '';

  if (selectedContract) {
    defaultContract = selectedContract.id;
  }
  const [step, setStep] = useState({
    type: 'contract',
    data: { contractId: defaultContract }
  });

  const setNextModal = useCallback(({ type, data }) => {
    setStep({ type, data });
  }, []);

  if (!open) {
    return <Fragment></Fragment>;
  }

  return (
    <Fragment>
      <div className={classes.content}>
        {Boolean(step.type === 'contract') && (
          <ContractStep
            title={`Generar cupón de pago (Paso 1 de ${totalSteps})`}
            open={true}
            setNextModal={setNextModal}
            step={step}
            onClose={() => onClose()}
          />
        )}
        {Boolean(step.type === 'validation') && (
          <ValidationStep
            title={`Generar cupón de pago (Paso 2 de ${totalSteps})`}
            open={true}
            step={step}
            setNextModal={setNextModal}
            onClose={() => onClose()}
          />
        )}
        {currentUser && Boolean(step.type === 'cellphone') && (
          <CellphoneStep
            title={`${
              updatedCurrentUser().phoneValidated
                ? 'Confirmación'
                : 'Validación'
            } de celular (Paso 3 de ${totalSteps})`}
            open={true}
            step={step}
            setNextModal={setNextModal}
            onClose={() => onClose()}
            phoneValidated={updatedCurrentUser().phoneValidated}
          />
        )}
        {Boolean(step.type === 'invoices') && (
          <SelectionStep
            title={`Generar cupón de pago (Paso ${
              currentUser ? 4 : 3
            } de ${totalSteps})`}
            open={true}
            step={step}
            setNextModal={setNextModal}
            onClose={() => onClose()}
          />
        )}
        {Boolean(step.type === 'generation') && (
          <GenerationStep
            title={`Generar cupón de pago (Paso ${
              currentUser ? 5 : 4
            } de ${totalSteps})`}
            open={true}
            step={step}
            setNextModal={setNextModal}
            onClose={() => onClose(step.data.contractId)}
          />
        )}
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  content: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  text: {
    fontSize: 14,
    color: theme.palette.text.primary
  },
  passwordField: {
    width: 250,
    marginTop: theme.spacing(4)
  }
}));

export default Duplicates;
