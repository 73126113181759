import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useMemo
} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Breadcrumbs,
  Link,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import _get from 'lodash/get';
import clsx from 'clsx';

import PersonalDataStep from '../Steps/PersonalDataStep';
import PaymentPlanStep from '../Steps/CurrentDebt/PaymentPlanStep';
import TermsStep from '../Steps/TermsStep';
import SummaryStep from '../Steps/SummaryStep';
import WaitStep from '../Steps/WaitForRequest';
import Stepper from '../../../../Components/Stepper/Stepper';
import FooterStepper from '../../../../Components/Stepper/FooterStepper';
import ConceptsSummaryCard from '../../Components/ConceptsSummaryCard';

import {
  FORM_ID,
  RefinanceStep,
  REFINANCE_CURRENT_STEPS
} from '../Refinance_enums';
import { StepperProvider, Step } from '../../../../Contexts/StepperContext';
import { AlertsDispatchContext } from '../../../../Contexts/AlertsContext';
import {
  ContractSelectedContext,
  ContractsContext
} from '../../../../Contexts/ContractsContext';
import { UserContext } from '../../../../Contexts/UserContext';

import { ROUTE_NAMES } from '../../../../Routes/Routes';
import { history } from '../../../../Routes/history';
import CurrentRefinancingSidebar from '../../Components/CurrentRefinancingSidebar';
import { isProductTypeGas } from '../../myDebts_enums';

const processData = data => {
  const initialInstallmentValue = data.products.reduce(
    (sum, current) => sum + current.initialInstallmentValue,
    0
  );
  const discountValue = data.products.reduce(
    (sum, current) => sum + current.discountValue,
    0
  );

  const products = data.products.map(product => {
    const totalConcepts = product.concepts.reduce((sum, current) => {
      return sum + current.balance;
    }, 0);
    const totalDeferreds = product.deferreds.reduce((sum, current) => {
      return sum + current.pendingValue;
    }, 0);
    return {
      ...product,
      total: totalConcepts + totalDeferreds,
      totalConcepts,
      totalDeferreds,
      totalCount: product.concepts.length + product.deferreds.length,
      monthlyInterestRate: parseFloat(
        (product.annualInterestRate / 12).toFixed(2)
      )
    };
  });

  return {
    initialInstallmentValue,
    discountValue,
    usuryRate: data.usuryRate,
    products,
    conceptItemsCount: products.reduce(
      (sum, { totalCount }) => sum + totalCount,
      0
    ),
    totalConceptsBalance: products.reduce((sum, { total }) => sum + total, 0)
  };
};

const CurrentDebtRefinancingGDC = props => {
  const { location: locationParams = { state: null } } = props;
  const params = locationParams.state;

  const classes = useStyles();
  const theme = useTheme();

  // * GLOBAL VARS
  const punishedProductIds = (params && params.punishedIds) || [];

  const negotiationData = params && params.data && params.data.negotiations;

  const productsPendingBilling =
    (params && params.data && params.data.pendingBillingsPerProduct) || [];

  const includesCurrentDebtRefinancing =
    productsPendingBilling.filter(item => item.pendingBillings > 2).length > 0;

  const processedNegotiationData =
    negotiationData && processData(negotiationData);

  // * CONTEXTS
  const contracts = useContext(ContractsContext);
  const selectedContract = useContext(ContractSelectedContext);
  const currentUser = useContext(UserContext);
  const authToken = _get(currentUser, 'token');

  // * STATE HOOKS
  const [openSidebarDialog, setOpenSidebarDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [paymentPlan, setPaymentPlan] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [financingPlan] = useState(processedNegotiationData);
  const [sidebarVisible, setSidebarVisible] = useState(true);

  const [nextButtonText, setNextButtonText] = useState('Siguiente');
  const [goBack, setGoBack] = useState({});

  const setAlert = useContext(AlertsDispatchContext);

  // * OTHER HOOKS
  const isMediumScreenSize = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  const processedPaymentPlan = useMemo(
    () => ({
      ...financingPlan,
      totalProducts: financingPlan.products.reduce((sum, current) => {
        return sum + current.totalConcepts + current.totalDeferreds;
      }, 0),
      initialInstallments: paymentPlan.initialInstallments,
      numberOfInstallments: paymentPlan.numberOfInstallments,
      initialInstallmentValue: Object.values(
        paymentPlan.initialInstallments || {}
      ).reduce((prev, current) => {
        return (current || 0) + (prev || 0);
      }, 0)
    }),
    [financingPlan, paymentPlan]
  );

  const openSidebarDialogHandler = () => {
    setOpenSidebarDialog(true);
  };

  const closeSidebarDialogHandler = () => {
    setOpenSidebarDialog(false);
  };

  // Handle not "authorized" user
  useEffect(() => {
    if (!authToken) {
      history.replace('/');
      return;
    }

    if (!contracts || contracts.length === 0) {
      history.replace(ROUTE_NAMES.contracts);
      return;
    }

    if (
      !params ||
      params.selectedContract.id !== selectedContract.id ||
      !negotiationData
    ) {
      history.replace(ROUTE_NAMES.debtStatus);
    }
  }, [contracts, selectedContract, authToken, params, negotiationData]);

  if (!params) {
    return <Fragment></Fragment>;
  }

  const onlyHaveGasProducts = processedNegotiationData.products.every(product =>
    isProductTypeGas(product.productTypeId)
  );

  return (
    <Fragment>
      <div className={classes.root}>
        <div
          className={clsx(classes.contentContainer, classes.centerContent, {
            [classes.mobilePadding]: isMediumScreenSize
          })}
        >
          <div className={clsx(classes.container, classes.contentContainer)}>
            <Breadcrumbs
              aria-label="breadcrumb"
              className={classes.breadcrumbs}
              separator={<NavigateNextIcon fontSize="small" />}
            >
              <Link
                className={classes.link}
                color="textPrimary"
                component={RouterLink}
                to="/"
                underline="always"
              >
                Inicio{' '}
              </Link>
              <Link
                className={classes.link}
                color="textPrimary"
                component={RouterLink}
                to="/contracts/debt"
                underline="always"
              >
                Mis deudas{' '}
              </Link>

              {includesCurrentDebtRefinancing && (
                <Link
                  className={classes.link}
                  color="textPrimary"
                  component={RouterLink}
                  to={ROUTE_NAMES.currentDebtSummary}
                  underline="always"
                >
                  Deuda actual{' '}
                </Link>
              )}

              <Typography color="primary">Acuerdo de pago</Typography>
            </Breadcrumbs>
          </div>

          <div
            className={clsx(classes.stepperContainer, classes.centerContent)}
          >
            <Stepper
              steps={REFINANCE_CURRENT_STEPS}
              currentStep={currentStep}
            />
          </div>

          {isMediumScreenSize && sidebarVisible && (
            <div
              className={clsx(
                classes.innerContentContainer,
                classes.centerContent
              )}
            >
              <ConceptsSummaryCard
                itemsCount={financingPlan.conceptItemsCount}
                totalAmount={
                  processedPaymentPlan.initialInstallmentValue
                    ? processedPaymentPlan.totalProducts -
                      processedPaymentPlan.discountValue -
                      processedPaymentPlan.initialInstallmentValue
                    : financingPlan.totalConceptsBalance
                }
                title="Items para financiar"
                action="financiar"
                onDetailsClick={openSidebarDialogHandler}
              />
            </div>
          )}

          <StepperProvider>
            <Step>
              <div
                className={clsx(
                  classes.innerContentContainer,
                  classes.centerContent
                )}
              >
                <PersonalDataStep
                  formId={FORM_ID}
                  setCanSubmit={setCanSubmit}
                  setGoBack={setGoBack}
                  setCurrentStep={setCurrentStep}
                  setNextButtonText={setNextButtonText}
                  setAlert={setAlert}
                  setSidebarVisible={setSidebarVisible}
                  selectedContract={selectedContract}
                  onlyHaveGasProducts={onlyHaveGasProducts}
                />
              </div>
            </Step>
            <Step>
              <PaymentPlanStep
                formId={FORM_ID}
                setCanSubmit={setCanSubmit}
                setGoBack={setGoBack}
                setCurrentStep={setCurrentStep}
                setNextButtonText={setNextButtonText}
                openSidebarDialog={openSidebarDialog}
                closeSidebarDialogHandler={closeSidebarDialogHandler}
                setPaymentPlan={setPaymentPlan}
                paymentPlan={paymentPlan}
                setFormErrors={setFormErrors}
                financingPlan={financingPlan}
                punishedProductIds={punishedProductIds}
                setSidebarVisible={setSidebarVisible}
                selectedContract={selectedContract}
              />
            </Step>
            <Step>
              <TermsStep
                formId={FORM_ID}
                setCanSubmit={setCanSubmit}
                setGoBack={setGoBack}
                setCurrentStep={setCurrentStep}
                setNextButtonText={setNextButtonText}
                loading={loading}
                setLoading={setLoading}
                setAlert={setAlert}
                setSidebarVisible={setSidebarVisible}
                selectedContract={selectedContract}
              />
            </Step>
            <Step>
              <SummaryStep
                formId={FORM_ID}
                setCanSubmit={setCanSubmit}
                setGoBack={setGoBack}
                setCurrentStep={setCurrentStep}
                setNextButtonText={setNextButtonText}
                loading={loading}
                setLoading={setLoading}
                setAlert={setAlert}
                canSubmit={canSubmit}
                setSidebarVisible={setSidebarVisible}
                selectedContract={selectedContract}
              />
            </Step>
            <Step>
              <WaitStep
                formId={FORM_ID}
                setCanSubmit={setCanSubmit}
                setGoBack={setGoBack}
                setCurrentStep={setCurrentStep}
                setNextButtonText={setNextButtonText}
                loading={loading}
                setLoading={setLoading}
                setAlert={setAlert}
                canSubmit={canSubmit}
                setSidebarVisible={setSidebarVisible}
                selectedContract={selectedContract}
              />
            </Step>
          </StepperProvider>
        </div>

        {sidebarVisible && (
          <CurrentRefinancingSidebar
            punishedProductIds={punishedProductIds}
            showPaymentPlan={currentStep > RefinanceStep.PersonalData}
            errors={formErrors}
            paymentPlan={processedPaymentPlan}
            openDialog={openSidebarDialog}
            onCloseDialog={closeSidebarDialogHandler}
            isMobileSize={isMediumScreenSize}
          />
        )}

        <FooterStepper
          targetForm={FORM_ID}
          disabledNext={!canSubmit}
          goBack={goBack}
          nextButtonText={nextButtonText}
        />
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: `calc(${
      theme.custom.footerHeight.stepper
    }px + ${theme.spacing(5)}px)`
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2)
  },
  currentStep: {
    marginBottom: theme.custom.footerHeight.stepper
  },
  breadcrumbs: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium,
    '& *': { fontSize: 'inherit' }
  },
  link: {
    color: theme.palette.text.greyDark
  },
  stepperContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(3)
    }
  },
  container: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginTop: theme.spacing(3)
    }
  },
  contentContainer: {
    maxWidth: 768
  },
  innerContentContainer: {
    maxWidth: 566
  },
  centerContent: {
    margin: '0 auto'
  },
  mobilePadding: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

export default CurrentDebtRefinancingGDC;
