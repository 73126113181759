import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';

import BaseDialog from '../../../Components/Dialogs/BaseDialog';
import { getPaymentPlanDetail } from '../../../Utils/Financing/Financing';
import { numberWithDots } from '../../../Utils/Format/MoneyFormat';
import SimpleDivider from '../../../Components/Dividers/SimpleDivider';
import SelectInput from '../../../Components/Inputs/SelectInput';

const headers = {
  capital: 'Capital',
  interest: 'Interés',
  ivaInterest: 'IVA Interés',
  total: 'Total',
  pendingBalance: 'Saldo pendiente'
};

const PlanDetailsDialog = props => {
  const {
    open,
    handleClose,
    productsData,
    isMobileSize,
    productOptions,
    handleProducts,
    billings,
    currentProductId,
    multipleProducts
  } = props;
  const classes = useStyles();

  const {
    rows,
    totalInterest,
    totalCapital
    // totalPayment
  } = getPaymentPlanDetail(productsData);

  const totalPayment = totalInterest + totalCapital;
  const financingTotalValue = productsData.reduce(
    (acc, productData) => acc + productData.totalFinancingValue,
    0
  );

  const renderInfo = () => {
    return (
      <div className={classes.infoContainer}>
        <Typography
          className={clsx(classes.label, classes.totalFinancingValue)}
        >
          Saldo a financiar: {numberWithDots(financingTotalValue)}
        </Typography>
        <Grid container justify="space-between">
          <Grid item>
            <div>
              <Typography className={classes.label}>
                Número de cuotas
              </Typography>
              <Typography className={clsx(classes.countText, classes.data)}>
                {billings}
              </Typography>
            </div>
          </Grid>
          <Grid item>
            <div className={classes.alignRight}>
              <Typography className={classes.label}>
                Valor cuota mensual
              </Typography>
              <Typography className={clsx(classes.monthlyValue, classes.data)}>
                {numberWithDots((rows[0] && rows[0].total) || 0)}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  const renderInstallments = () => {
    return (
      <div className={classes.installmentsContainer}>
        {rows.map((row, index) => {
          return (
            <div
              key={`${index} - ${row.period}`}
              className={classes.installmentBox}
            >
              <Grid item container alignItems="center" wrap="nowrap">
                <Grid item xs={2}>
                  <Typography className={classes.label}>Cuota</Typography>
                  <Typography className={classes.data}>{`${index + 1} · ${
                    row.period
                  }`}</Typography>
                </Grid>
                {Object.keys(headers).map((key, i) => {
                  return (
                    <Grid
                      item
                      xs={2}
                      key={`${index}-${i}`}
                      className={clsx({
                        [classes.alignRight]: key === 'pendingBalance'
                      })}
                    >
                      <Typography className={classes.label}>
                        {headers[key]}
                      </Typography>
                      <Typography className={classes.data}>
                        {numberWithDots(Math.trunc(row[key]))}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          );
        })}
      </div>
    );
  };

  const renderMobileInstallments = () => {
    return (
      <div className={classes.installmentsContainer}>
        {rows.map((row, index) => {
          return (
            <div
              key={`${index} - ${row.period}`}
              className={classes.installmentBox}
            >
              <Grid
                container
                alignItems="center"
                wrap="nowrap"
                direction="column"
              >
                <Grid item container xs={12}>
                  <Grid item xs={6}>
                    <Typography
                      className={clsx(classes.label, classes.bigCountText)}
                    >{`Cuota ${index + 1} · ${row.period}`}</Typography>
                    <Typography className={classes.data}></Typography>
                  </Grid>
                  <Grid item xs={6} className={classes.alignRight}>
                    <Typography className={classes.label}>
                      Saldo pendiente
                    </Typography>
                    <Typography className={classes.data}>
                      {numberWithDots(row.pendingBalance)}
                    </Typography>
                  </Grid>
                </Grid>

                <SimpleDivider withoutMargin className={classes.divider} />

                <Grid item container xs={12} justify="space-between">
                  {Object.keys(headers)
                    .filter(header => header !== 'pendingBalance')
                    .map((key, i) => {
                      return (
                        <Grid
                          item
                          xs={2}
                          key={`${index}-${i}`}
                          className={clsx({
                            [classes.alignRight]: key === 'total'
                          })}
                        >
                          <Typography className={classes.label}>
                            {headers[key]}
                          </Typography>
                          <Typography className={classes.data}>
                            {numberWithDots(Math.trunc(row[key]))}
                          </Typography>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            </div>
          );
        })}
      </div>
    );
  };

  const renderTotals = () => {
    return (
      <>
        {isMobileSize ? (
          <div className={classes.totalsContainer}>
            <Grid container direction="column">
              <Grid item xs={12}>
                <Typography className={classes.totals}>
                  Total capital: {numberWithDots(totalCapital)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.totals}>
                  Total interés: {numberWithDots(totalInterest)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.totals}>
                  Total IVA interés: {numberWithDots(0)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.totals}>
                  Subtotal: {numberWithDots(totalPayment)}
                </Typography>
              </Grid>
            </Grid>
            <SimpleDivider className={classes.divider} />
            <Typography className={classes.labelTotalMobile}>
              Total a financiar
            </Typography>
            <Typography className={classes.dataTotalMobile}>
              {numberWithDots(totalPayment)}
            </Typography>
          </div>
        ) : (
          <>
            <SimpleDivider className={classes.divider} />
            <Grid
              container
              alignItems="center"
              wrap="nowrap"
              className={classes.totalsContainer}
            >
              <Grid item sm={2}>
                <Typography className={classes.totals}>Totales</Typography>
              </Grid>
              <Grid item sm={2}>
                <Typography className={classes.totals}>
                  {numberWithDots(totalCapital)}
                </Typography>
              </Grid>
              <Grid item sm={2}>
                <Typography className={classes.totals}>
                  {numberWithDots(totalInterest)}
                </Typography>
              </Grid>
              <Grid item sm={2}>
                <Typography className={classes.totals}>
                  {numberWithDots(0)}
                </Typography>
              </Grid>
              <Grid item sm={4}>
                <Typography className={clsx(classes.totals, classes.alignLeft)}>
                  {numberWithDots(totalPayment)}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  };

  const renderContent = () => (
    <div
      className={clsx(classes.container, {
        [classes.containerMultipleProducts]: multipleProducts
      })}
    >
      {multipleProducts && (
        <SelectInput
          className={classes.selectContainer}
          id="PaymentPlan_select_paymentPlanPerProduct"
          value={currentProductId}
          onChange={handleProducts}
          options={productOptions}
          label="Producto"
          fullWidth
        />
      )}
      {renderInfo()}
      {isMobileSize ? renderMobileInstallments() : renderInstallments()}
      {renderTotals()}
    </div>
  );

  return (
    <BaseDialog
      content={renderContent}
      open={open}
      handleClose={handleClose}
      title="Detalle de tu plan de pagos"
      classes={{
        paper: classes.paper
      }}
      contentStyle={classes.content}
      titleStyle={classes.dialogTitle}
      titleTextStyle={classes.dialogTitleText}
      titleButtonStyle={classes.dialogTitleButton}
      fullScreen={isMobileSize}
    />
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    maxWidth: 'unset'
  },
  content: {
    padding: 0
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 3, 3, 3),
    width: `calc(650px + ${theme.spacing(3)}px)`,
    maxHeight: 400,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      width: '100%',
      maxHeight: '100%',
      padding: 0
    }
  },
  containerMultipleProducts: {
    maxHeight: 460,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      minHeight: '100%'
    }
  },
  infoContainer: {
    padding: theme.spacing(2)
  },
  selectContainer: {
    padding: theme.spacing(0, 0.5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: theme.spacing(0, 2)
    }
  },
  installmentsContainer: {
    overflowY: 'auto',
    flexGrow: 1,
    padding: theme.spacing(1, 0.5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: theme.spacing(1, 2)
    }
  },
  installmentBox: {
    width: '100%',
    borderRadius: theme.custom.borderRadius,
    backgroundColor: '#F7F7F7',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1.25),
    '&:last-of-type': {
      marginBottom: 0
    }
  },
  label: {
    fontSize: 10,
    fontWeight: 500,
    color: theme.palette.text.greyDark,
    whiteSpace: 'nowrap'
  },
  data: {
    fontSize: 11,
    fontWeight: 600,
    lineHeight: 1.2,
    whiteSpace: 'nowrap'
  },
  alignRight: {
    textAlign: 'right'
  },
  alignLeft: {
    textAlign: 'left'
  },
  countText: {
    fontSize: 16,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 14
    }
  },
  monthlyValue: {
    fontSize: 14
  },
  totalsContainer: {
    padding: theme.spacing(1, 3, 2, 2),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      bottom: 0,
      maxHeight: 188,
      width: '100%',
      textAlign: 'right',
      padding: theme.spacing(2),
      margin: 0,
      boxShadow: '0px -4px 2px #0000000D',
      borderRadius: '8px 8px 0px 0px'
    }
  },
  totals: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.main,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 11,
      color: theme.palette.text.primary,
      fontWeight: 500,
      marginBottom: theme.spacing(1)
    }
  },
  totalFinancingValue: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginBottom: theme.spacing(1)
    }
  },
  labelTotalMobile: {
    fontSize: 12,
    color: theme.palette.text.greyDark,
    textAlign: 'right'
  },
  dataTotalMobile: {
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.primary.main,
    textAlign: 'right',
    marginBottom: theme.spacing(1.5)
  },
  bigCountText: {
    fontSize: 12,
    fontWeight: 600
  },
  divider: {
    height: 3,
    color: theme.palette.common.black,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  dialogTitle: {
    padding: theme.spacing(3, 2)
  },
  dialogTitleText: {
    fontSize: 14,
    fontWeight: 600
  },
  dialogTitleButton: {
    padding: theme.spacing(1)
  }
}));

export default PlanDetailsDialog;
