import { Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import React from 'react';
import BaseDialog from '../../../../Components/Dialogs/BaseDialog';
import { SlideUpTransition } from '../../../../Components/Transitions/Transitions';
import { numberWithDots } from '../../../../Utils/Format/MoneyFormat';
import BaseButton from '../../../../Components/Buttons/BaseButton';

const DeferredPaymentOptionsDialog = props => {
  const {
    open,
    handleClose,
    title = 'Necesitas pagar tu deuda actual',
    onClick,
    currentDebtValue,
    deferredSelectedValues,
    goToPayments
  } = props;

  const classes = useStyles();

  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('xs'));

  const renderActions = () => {
    return (
      <>
        <BaseButton
          id="ConfirmationDialog_button_cancel"
          onClick={goToPayments}
          variant="outlined"
          color="primary"
          size="small"
        >
          Pagar deuda actual
        </BaseButton>
        <BaseButton
          onClick={onClick}
          id="ConfirmationDialog_button_submit"
          type="submit"
          color="primary"
          size="small"
          autoFocus
        >
          Pagar todo
        </BaseButton>
      </>
    );
  };

  const renderReasonsContent = () => {
    const deferredValue = numberWithDots(deferredSelectedValues);
    const currentDebt = numberWithDots(currentDebtValue);
    const totalDebt = numberWithDots(deferredSelectedValues + currentDebtValue);

    return (
      <>
        <Typography paragraph>
          Para proceder con el pago de los diferidos seleccionados{' '}
          <span className={classes.totalText}>({deferredValue})</span> es
          necesario que estés al día con tu deuda actual{' '}
          <span className={classes.totalText}>({currentDebt})</span>.
        </Typography>
        <Typography paragraph>
          Teniendo en cuenta lo anterior haz clic en el botón{' '}
          <span className={classes.actionButtonText}>Pagar todo</span> para
          generar un cupón agrupado por valor de{' '}
          <span className={classes.totalText}>{totalDebt}</span> al final del
          proceso.
        </Typography>
        <Typography paragraph>
          También puedes pagar solo tu deuda actual primero haciendo clic en el
          botón{' '}
          <span className={classes.actionButtonText}>Pagar deuda actual</span>.
        </Typography>
      </>
    );
  };

  return (
    <BaseDialog
      open={open}
      handleClose={handleClose}
      title={title}
      titleStyle={classes.dialogTitleContainer}
      content={renderReasonsContent}
      contentStyle={classes.reasonsDialogContent}
      contentSize="small"
      TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
      fullScreen={isMobileSize}
      actions={renderActions}
    />
  );
};

const useStyles = makeStyles(theme => ({
  reasonsDialogContent: {
    minHeight: 'unset',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: theme.spacing(2)
    }
  },
  actionButtonText: {
    color: theme.palette.color.primary
  },
  totalText: {
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    whiteSpace: 'nowrap'
  },
  dialogTitleContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingLeft: theme.spacing(2)
    }
  }
}));

export default DeferredPaymentOptionsDialog;
