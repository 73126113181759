import React, { Fragment, useState, useEffect, useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid } from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import parse from 'html-react-parser';
import SimpleDivider from '../../Components/Dividers/SimpleDivider';
import Title from '../../Components/Labels/Title';

import FullSizeProgress from '../../Components/Progress/FullSize/FullSizeProgress';
import HelpChannelsDialog from '../../Components/Dialogs/HelpChannelsDialog';

import {
  GetArticleAPI,
  GetCategoriesAPI,
  GetArticleVotesAPI,
  PostArticleVote
} from '../../API/Faq/FaqAPI';
import FaqActionButton from '../../Components/Buttons/FaqActionButton';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import WhatsAppIcon from '../../Components/CustomIcons/WhatsappIcon';
import { OpenNewTab, WhatsAppHelpLinkHandler } from '../../Utils/Misc/Links';
import PhonesDialog from '../../Components/Dialogs/PhonesDialog/PhonesDialog';
import CategoryButton from '../../Components/Buttons/CategoryButton';
import { ROUTE_NAMES } from '../../Routes/Routes';
import { history } from '../../Routes/history';
import { normalizeURI } from '../../Utils/Format/URI';
import BaseBreadcrumbs from '../../Components/Breadcrumbs/BaseBreadcrumbs';
import LikeDislikeButton from '../../Components/Buttons/LikeDislikeButton';

const CloserToYouLink = () => {
  OpenNewTab('/closer/support');
};

const FaqArticle = ({ match }) => {
  const classes = useStyles();

  // * STATE HOOKS
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState({});
  const [article, setArticle] = useState({});
  const [openPhonesDialog, setOpenPhonesDialog] = useState(false);
  const [openChannelsDialog, setOpenChannelsDialog] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [isGoodChecked, setIsGoodChecked] = useState(null);
  const [isBadChecked, setIsBadChecked] = useState(null);
  const [articleLikes, setArticleLikes] = useState(null);
  const [articleDislikes, setArticleDislikes] = useState(null);
  const [hasVoted, setHasVoted] = useState(false);

  // * FUNCTIONS

  const goToCategoryInfo = cat => {
    history.push(
      `${ROUTE_NAMES.faq}/categories/${cat.id}-${normalizeURI(cat.nombre)}`
    );
  };

  const goToArticleInfo = art => {
    const articleInfo = `${art.id}-${normalizeURI(art.titulo)}`;
    history.push(`${ROUTE_NAMES.faq}/articles/${articleInfo}`);
  };

  /* The users can click on the buttons N times, but only the first click will make the call to the DB.
  Also, the screen will display this changes but just one time. It means, that the user will see a
  first change with the first click. And then he well see changes just to revert a first like
  or to revert a first dislike.
  */
  const handleLikeDislikeButtons = useCallback(
    async button => {
      if (button === 'like') {
        setIsGoodChecked(true);
        setIsBadChecked(false);

        if (hasVoted === 'like') {
          return;
        }

        if (hasVoted === 'dislike') {
          setArticleLikes(prev => prev + 1);
          setArticleDislikes(prev => prev - 1);
          setHasVoted('like');
          return;
        }

        await PostArticleVote(article.id, 'like');
        setHasVoted('like');
        setArticleLikes(prev => prev + 1);
        return;
      }

      setIsBadChecked(true);
      setIsGoodChecked(false);
      setOpenChannelsDialog(true);

      if (hasVoted === 'dislike') {
        return;
      }

      if (hasVoted === 'like') {
        setArticleLikes(prev => prev - 1);
        setArticleDislikes(prev => prev + 1);
        setHasVoted('dislike');
        return;
      }

      await PostArticleVote(article.id, 'dislike');
      setHasVoted('dislike');
      setArticleDislikes(prev => prev + 1);
      return;
    },
    [article.id, hasVoted]
  );

  const articleInfo = match.params.articleInfo;

  useEffect(() => {
    setLoading(true);
    setIsGoodChecked(null);
    setIsBadChecked(null);
    setHasVoted(false);
    const fetchCategoriesAndArticle = async () => {
      const categoriesResponse = await GetCategoriesAPI();
      if (!categoriesResponse.success) {
        history.replace('/');
        return;
      }

      const articleResponse = await GetArticleAPI(articleInfo.split('-')[0]);

      if (!articleResponse.success) {
        history.replace('/');
        return;
      }

      const selectedArticle = articleResponse.data.data;
      setArticle(selectedArticle);
      setCategories(categoriesResponse.data.data);

      const articleTitleIndex = articleInfo.indexOf('-');
      const selectedCategory = categoriesResponse.data.data.find(
        cat =>
          cat.id === selectedArticle.categoria.id &&
          normalizeURI(selectedArticle.titulo) ===
            articleInfo.substring(articleTitleIndex + 1)
      );

      if (!selectedCategory || selectedCategory.articulos.length === 0) {
        history.replace('/');
        return;
      }

      const currentBreadcrumbs = [
        { name: 'Preguntas frecuentes', link: ROUTE_NAMES.faq },
        {
          name: selectedCategory.nombre,
          link: `${ROUTE_NAMES.faq}/categories/${
            selectedCategory.id
          }-${normalizeURI(selectedCategory.nombre)}`
        },
        { name: selectedArticle.titulo }
      ];

      const articleVotes = await GetArticleVotesAPI(articleInfo.split('-')[0]);
      const { likes, dislikes } = articleVotes.data.data;

      setCategory(selectedCategory);
      setBreadcrumbs(currentBreadcrumbs);
      setArticleLikes(likes);
      setArticleDislikes(dislikes);
      setLoading(false);
    };

    fetchCategoriesAndArticle();
  }, [articleInfo]);

  const handleClose = useCallback(() => {
    setOpenPhonesDialog(false);
  }, [setOpenPhonesDialog]);

  if (loading) {
    return <FullSizeProgress />;
  }

  const filteredCategories = categories.filter(
    cat => cat.nombre !== category.nombre && cat.articulos.length > 0
  );

  const filteredArticles = category.articulos.filter(
    art => art.titulo !== article.titulo
  );

  const totalUserVotes = articleLikes + articleDislikes;

  //This is made so the links in the description, if exist, are opened in a new tab
  const description = article.descripcion.replace(
    /<a href/g,
    '<a target="_blank" href'
  );

  const renderContent = () => {
    if (loading) {
      return;
    }

    return (
      <Fragment>
        <Grid container className={classes.articlesContainer}>
          <BaseBreadcrumbs
            classes={{
              root: classes.breadcrumbs
            }}
            limit={30}
            separator=">"
            breadcrumbs={breadcrumbs}
          />
          <Title className={classes.titleText} text={article.titulo} />
          <Grid className={classes.articleContainer} container>
            <Grid container className={classes.descriptionContainer}>
              <div className={classes.articleContent} id="article-content">
                {parse(description)}
              </div>
            </Grid>
            <SimpleDivider withoutMargin />
            <Grid
              spacing={2}
              className={classes.likeDislikeContainer}
              container
            >
              <Grid className={classes.usefulText} item sm={6}>
                ¿Te pareció útil esta información? <br />
                <span className={classes.usersLikesText}>
                  {totalUserVotes === 0
                    ? 'Sé el primero en compartir tu opinión'
                    : `Usuarios a los que les pareció útil: ${articleLikes} de 
                  ${totalUserVotes}`}
                </span>
              </Grid>
              <Grid className={classes.likeDislikeAlign} item sm={6}>
                <LikeDislikeButton
                  handleClick={handleLikeDislikeButtons}
                  isGoodChecked={isGoodChecked}
                  isBadChecked={isBadChecked}
                  className={classes.likeDislikeButton}
                />
              </Grid>
            </Grid>
          </Grid>
          {filteredArticles.length > 0 && (
            <Fragment>
              <Title
                className={classes.articlesText}
                text="Preguntas relacionadas"
              />
              <Grid container>
                {filteredArticles.map((art, i) => (
                  <Fragment key={i}>
                    <Typography
                      onClick={() => goToArticleInfo(art)}
                      className={classes.articlesList}
                    >
                      <span className={classes.articlesNumber}>{i + 1}. </span>{' '}
                      {art.titulo}
                    </Typography>
                    <SimpleDivider withoutMargin />
                  </Fragment>
                ))}
              </Grid>
            </Fragment>
          )}
        </Grid>
        <Grid container className={classes.categoryContainer}>
          <Title
            className={classes.categoryText}
            text="Otras categorías que podrían interesarte"
          />
          <Grid container className={classes.categoryAlign}>
            {filteredCategories.map((cat, i) => (
              <CategoryButton
                onClick={() => goToCategoryInfo(cat)}
                className={classes.buttonStyle}
                key={i}
              >
                {cat.nombre}
              </CategoryButton>
            ))}
          </Grid>
        </Grid>
      </Fragment>
    );
  };

  return (
    <div className={classes.root}>
      <Fragment>
        <PhonesDialog open={openPhonesDialog} onClose={handleClose} />
        <HelpChannelsDialog
          open={openChannelsDialog}
          onClose={() => setOpenChannelsDialog(false)}
        />
        <Container maxWidth="md">
          {renderContent()}
          <Grid container spacing={2} className={classes.bottomContainer}>
            <Grid item className={classes.fullWidth} sm={4}>
              <FaqActionButton
                text={'Atención por chat'}
                className={classes.iconButton}
                onClick={WhatsAppHelpLinkHandler}
                icon={<WhatsAppIcon className={classes.iconStyleWhatsApp} />}
              />
            </Grid>
            <Grid item className={classes.fullWidth} sm={4}>
              <FaqActionButton
                text={'Puntos de atención'}
                className={classes.iconButton}
                onClick={CloserToYouLink}
                icon={<LocationOnIcon className={classes.iconStyle} />}
              />
            </Grid>
            <Grid item className={classes.fullWidth} sm={4}>
              <FaqActionButton
                text={'Llámanos'}
                onClick={() => setOpenPhonesDialog(true)}
                className={classes.iconButton}
                icon={<PhoneIcon className={classes.iconStyle} />}
              />
            </Grid>
          </Grid>
        </Container>
      </Fragment>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  bannerContent: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center'
  },
  categoryContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  categoryAlign: {
    justifyContent: 'flex-start'
  },
  bottomContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex'
  },
  buttonStyle: {
    margin: [[theme.spacing(1), theme.spacing(2), theme.spacing(1), 0]],
    borderRadius: '30px',
    backgroundColor: 'white',
    borderColor: '#183F8F',
    borderStyle: 'solid',
    borderWidth: 1,
    color: '#183F8F',
    '&:hover': {
      backgroundColor: '#F0F5FD'
    }
  },
  iconButton: {
    backgroundColor: '#F8F8F8',
    color: '#183F8F',
    fontWeight: 'normal',
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16
    },
    '&:hover': {
      backgroundColor: '#183F8F',
      color: 'white',
      fontWeight: 500
    }
  },
  titleText: {
    fontSize: 20
  },
  articlesContainer: {
    textAlign: 'left',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  fullWidth: {
    width: '100%'
  },
  articlesText: {
    textAlign: 'left',
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 18
    },
    marginBottom: 0,
    marginTop: theme.spacing(4)
  },
  categoryText: {
    textAlign: 'left',
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 18
    },
    marginBottom: theme.spacing(3)
  },
  articlesList: {
    color: '#707070',
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16
    },
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
      fontWeight: 500
    }
  },
  articlesNumber: {
    color: '#183F8F'
  },
  iconStyle: {
    fill: 'currentcolor',
    fontSize: '40px !important'
  },
  iconStyleWhatsApp: {
    fill: 'currentcolor',
    marginRight: 4,
    fontSize: '40px !important'
  },
  breadcrumbs: {
    marginBottom: 16
  },
  breadcrumbsLongText: {
    fontSize: 12,
    color: '#2F2F2F',
    textDecoration: 'underline',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '30ch',
    whiteSpace: 'nowrap'
  },
  articleContainer: {
    border: 'solid 1px #dbdbdb',
    marginBottom: theme.spacing(2)
  },
  descriptionContainer: {
    margin: 0,
    width: '100%',
    padding: theme.spacing(2)
  },
  likeDislikeContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 0,
    width: '100%',
    padding: [
      [theme.spacing(2), theme.spacing(1), theme.spacing(2), theme.spacing(1)]
    ],
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  likeDislikeAlign: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end'
    }
  },
  usefulText: {
    alignSelf: 'center',
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16
    },
    color: '#183F8F'
  },
  usersLikesText: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    fontSize: 11,
    [theme.breakpoints.up('sm')]: {
      fontSize: 12,
      justifyContent: 'flex-start'
    },
    color: '#2F2F2F'
  },
  likeDislikeButton: {
    backgroundColor: '#F8F8F8',
    color: '#183F8F',
    '&:hover': {
      backgroundColor: '#183F8F',
      color: '#F8F8F8'
    }
  },
  articleContent: {
    width: '100%'
  }
}));

export default withWidth()(FaqArticle);
