import React, { Fragment } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import StatusLabel from '../Labels/StatusLabel';

import { moneyWithDots } from '../../Utils/Format/MoneyFormat';
import { Company } from '../../Configs/general';
import {
  TYPE_OF_PAYMENTS,
  getCouponValue
} from '../../Views/Payment/payment_enums';
import { useMediaQuery } from '@material-ui/core';
import { getCouponCardHeader } from '../../Utils/Format/Coupons';
import { formatAddress } from '../../Utils/Format/Address';

const NewCouponCard = props => {
  const classes = useStyles();
  const { id, detail, type = TYPE_OF_PAYMENTS.coupon } = props;

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const isInvoiceType = type === TYPE_OF_PAYMENTS.contract;
  const couponValue = getCouponValue({ detail });

  const renderHeaderTitle = () => {
    const content = getCouponCardHeader(type, detail, !isSmallScreen);

    return (
      <Typography
        className={clsx(classes.headerTitle, {
          [classes.capitalize]: isInvoiceType
        })}
      >
        {content}
      </Typography>
    );
  };

  if (!detail) {
    return <Fragment />;
  }

  return (
    <>
      <Grid id={id} container className={classes.cardWithHeader}>
        <Grid container className={classes.cardHeader}>
          <Grid item style={{ display: 'flex' }}>
            {renderHeaderTitle()}
          </Grid>
          <Grid item className={classes.headerStatus}>
            <StatusLabel isDone={detail.isPaid} isPending={detail.isPending} />
          </Grid>
        </Grid>
        <Grid container className={classes.cardRoot}>
          {isSmallScreen && (
            <Grid item xs={12}>
              <Typography
                className={clsx(classes.textTitle, classes.capitalize)}
              >
                {formatAddress(
                  {
                    city: detail.contractCity,
                    address: detail.contractAddress
                  },
                  true
                )}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" className={classes.leftSection}>
              <Typography className={classes.textTitle}>
                Nº {Company.contractConjugation.capitalized.singular.associate}{' '}
                <span className={classes.textSubtitle}>
                  {detail.contractId}
                </span>
              </Typography>
              <Typography className={classes.textTitle}>
                Fecha de vencimiento{' '}
                <span className={classes.textSubtitle}>
                  {isInvoiceType
                    ? moment(detail.expirationDate).format('L')
                    : '-'}
                </span>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container direction="column" className={classes.rightSection}>
              <Typography className={classes.textTitle}>
                Nº Cupón{' '}
                <span className={classes.textSubtitle}>
                  {detail.couponId || '-'}
                </span>
              </Typography>
              <Typography className={classes.textTitle}>
                Valor {isInvoiceType ? 'factura' : 'cupón'}{' '}
                <span className={classes.textSubtitle}>
                  {moneyWithDots(couponValue)}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.cardFooter}>
          <Grid item>
            <Typography className={classes.textSubtitle}>
              Total a pagar
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={clsx(classes.textValue, classes.totalValue)}>
              {moneyWithDots(couponValue)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  cardWithHeader: {
    backgroundColor: theme.palette.background.cardDark,
    border: `1px solid ${theme.palette.background.border}`,
    borderRadius: theme.custom.borderRadius
  },
  cardRoot: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(3, 2)
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTopLeftRadius: theme.custom.borderRadius,
    borderTopRightRadius: theme.custom.borderRadius,
    backgroundColor: theme.palette.background.dark,
    minHeight: 42,
    padding: theme.spacing(1.5, 2)
  },
  headerTitle: {
    fontWeight: 700,
    alignSelf: 'center',
    color: theme.palette.text.main,
    fontSize: 14,
    [theme.breakpoints.up('xm')]: {
      fontSize: 12
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  headerStatus: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  cardFooter: {
    display: 'flex',
    justifyContent: 'flex-start',
    borderBottomLeftRadius: theme.custom.borderRadius,
    borderBottomRightRadius: theme.custom.borderRadius,
    backgroundColor: theme.palette.background.dark,
    minHeight: 42,
    padding: theme.spacing(1.5, 2),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      justifyContent: 'space-between'
    }
  },
  capitalize: {
    textTransform: 'capitalize'
  },
  textTitle: {
    fontWeight: 600,
    display: 'inline',
    fontSize: 12,
    marginTop: theme.spacing(0.5),
    color: theme.typography.color.default,
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  textSubtitle: {
    fontWeight: 400,
    display: 'inline',
    fontSize: 12,
    color: theme.typography.color.default,
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  textValue: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.primary,
    [theme.breakpoints.up('xm')]: {
      fontSize: 16
    }
  },
  totalValue: {
    fontWeight: 700,
    fontSize: 14,
    color: theme.typography.color.black,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16,
      paddingLeft: theme.spacing(1)
    }
  }
}));

export default withWidth()(NewCouponCard);
