import React, {
  Fragment,
  useState,
  useContext,
  useCallback,
  useEffect
} from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';

import ShowPassword from '../../../../Components/Adornments/ShowPassword';

import * as yup from 'yup';
import { DeleteContract } from '../../../../API/Contracts/ContractsAPI';
import {
  UserContext,
  UserDispatchContext
} from '../../../../Contexts/UserContext';
import { ContractsRefreshContext } from '../../../../Contexts/ContractsContext';

import BaseButton from '../../../../Components/Buttons/BaseButton';
import BaseDialog from '../../../../Components/Dialogs/BaseDialog';
import TextInput from '../../../../Components/Inputs/TextInput';
import { SlideUpTransition } from '../../../../Components/Transitions/Transitions';

import {
  redirectOnAuthFailure,
  extractErrorMessage
} from '../../../../Utils/Errors/Errors';
import { Company } from '../../../../Configs/general';
import { logoutUser } from '../../../../Utils/User/Actions';

const passwordSchema = yup.object({
  password: yup
    .string()
    .trim()
    .required('Ingresa tu contraseña')
});

const RemoveContractGDG = props => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const refreshContracts = useContext(ContractsRefreshContext);

  const { contract, open, setDialog, setAlert } = props;
  const [password, setPassword] = useState('');
  const [passwordShown, setShowPassword] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const removeContract = useCallback(
    async event => {
      event.preventDefault();

      if (!canSubmit) {
        return;
      }

      setLoading(true);
      const response = await DeleteContract(
        currentUser.token,
        contract.id,
        password
      );
      setLoading(false);
      if (response.success) {
        setDialog(false);
        setAlert({
          type: 'info',
          message: `Se ha desasociado ${Company.contractConjugation.regular.singular.article} "${contract.alias}"`
        });
        refreshContracts();
      } else {
        if (
          redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
        ) {
          return;
        }

        const error = extractErrorMessage(response);
        if (error.key === 'base') {
          setAlert({
            type: 'error',
            message: error.message
          });
        } else {
          setErrors({
            [error.key]: error.message
          });
        }
      }
    },
    [
      currentUser,
      contract,
      refreshContracts,
      setAlert,
      setDialog,
      setCurrentUser,
      password,
      canSubmit
    ]
  );

  useEffect(() => {
    if (!password) {
      setCanSubmit(false);
      return;
    }

    try {
      passwordSchema.validateSync({ password });
      setErrors({});
      setCanSubmit(true);
    } catch (err) {
      setCanSubmit(false);
      setErrors({
        [err.path]: err.errors[0]
      });
    }
  }, [password]);

  const handleClose = useCallback(() => {
    setDialog(false);
  }, [setDialog]);

  const togglePasswordShow = useCallback(() => {
    setShowPassword(!passwordShown);
  }, [passwordShown]);

  const onChangePassword = useCallback(event => {
    setPassword(event.target.value);
    setErrors(errs => ({ ...errs, password: null }));
  }, []);

  const renderActions = () => {
    return (
      <Fragment>
        <BaseButton
          onClick={handleClose}
          variant="outlined"
          color="primary"
          size="small"
        >
          Cancelar
        </BaseButton>
        <BaseButton
          onClick={removeContract}
          type="submit"
          color="primary"
          size="small"
          form="removeContractForm"
          autoFocus
          disabled={!canSubmit || loading}
        >
          Desasociar
        </BaseButton>
      </Fragment>
    );
  };

  const renderContent = () => {
    return (
      <div className={classes.content}>
        <Typography className={classes.text}>
          Para desasociar{' '}
          {Company.contractConjugation.regular.singular.undefinedArticle}, es
          necesario que ingreses tu contraseña.
        </Typography>
        <form onSubmit={removeContract} id="removeContractForm">
          <TextInput
            autoFocus
            value={password}
            onChange={onChangePassword}
            margin="none"
            className={classes.passwordField}
            id="password"
            label="Contraseña"
            type={passwordShown ? 'text' : 'password'}
            required={true}
            error={Boolean(errors.password)}
            helperText={errors.password}
            InputProps={{
              endAdornment: (
                <ShowPassword
                  togglePasswordShow={togglePasswordShow}
                  passwordShown={passwordShown}
                />
              ),
              inputProps: {
                autoComplete: 'off'
              }
            }}
          />
        </form>
      </div>
    );
  };

  return (
    <Fragment>
      <BaseDialog
        open={open}
        handleClose={handleClose}
        title={`Desasociar ${Company.contractConjugation.regular.singular.main}`}
        actions={renderActions}
        content={renderContent}
        fullScreen={smallScreen}
        TransitionComponent={smallScreen ? SlideUpTransition : undefined}
        contentSize={smallScreen ? undefined : 'small'}
      />
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 14,
    color: theme.palette.text.primary
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  passwordField: {
    width: 250,
    marginTop: theme.spacing(4)
  }
}));

export default RemoveContractGDG;
