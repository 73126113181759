import React, { Fragment, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { UserContext } from '../../../Contexts/UserContext';

import ContractStep from './Steps/ContractStep';
import CellphoneStep from './Steps/CellphoneStep';
import IdentificationStep from './Steps/IdentificationStep';
import ValidationStep from './Steps/ValidationStep';

export const DigitalInvoiceDeactivationStepId = {
  Contract: 'contract',
  Validation: 'validation',
  Identification: 'identification',
  Cellphone: 'cellphone'
};

const DigitalInvoiceDeactivationStep = {
  Contract: {
    id: DigitalInvoiceDeactivationStepId.Contract,
    step: 1
  },
  Validation: {
    id: DigitalInvoiceDeactivationStepId.Validation,
    step: 2
  },
  Identification: {
    id: DigitalInvoiceDeactivationStepId.Identification,
    step: 3
  },
  Cellphone: {
    id: DigitalInvoiceDeactivationStepId.Cellphone,
    step: 4
  }
};

const ContractDigitalInvoiceDeactivation = props => {
  const { open = false, onClose, contract: selectedContract } = props;

  const classes = useStyles();

  const currentUser = useContext(UserContext);

  const totalSteps = Object.keys(DigitalInvoiceDeactivationStep).length;
  const generalTitle = 'Desactivar factura digital';

  const contract = {
    id: '',
    alias: ''
  };

  if (selectedContract) {
    contract.id = selectedContract.id;
    contract.alias = selectedContract.alias;
  }

  const initialStep = {
    type: DigitalInvoiceDeactivationStep.Contract.id,
    data: {
      contractId: contract.id,
      contractAlias: contract.alias,
      formId: 'digital_invoice_deactivation_step_form'
    }
  };

  const [step, setStep] = useState(initialStep);

  const handleClose = () => {
    setStep(initialStep);
    onClose();
  };

  if (!open) {
    return <Fragment></Fragment>;
  }

  return (
    <Fragment>
      <div className={classes.content}>
        {step.type === DigitalInvoiceDeactivationStep.Contract.id && (
          <ContractStep
            title={`${generalTitle} (Paso ${DigitalInvoiceDeactivationStep.Contract.step} de ${totalSteps})`}
            open={true}
            step={step}
            setNextModal={setStep}
            onClose={handleClose}
            currentUser={currentUser}
          />
        )}
        {step.type === DigitalInvoiceDeactivationStep.Validation.id && (
          <ValidationStep
            title={`${generalTitle} (Paso ${DigitalInvoiceDeactivationStep.Validation.step} de ${totalSteps})`}
            open={true}
            step={step}
            setNextModal={setStep}
            onClose={handleClose}
          />
        )}
        {step.type === DigitalInvoiceDeactivationStep.Identification.id && (
          <IdentificationStep
            title={`Validación de identidad (Paso ${DigitalInvoiceDeactivationStep.Identification.step} de ${totalSteps})`}
            open={true}
            step={step}
            setNextModal={setStep}
            onClose={handleClose}
            currentUser={currentUser}
          />
        )}
        {step.type === DigitalInvoiceDeactivationStep.Cellphone.id && (
          <CellphoneStep
            title={`Validación de celular (Paso ${DigitalInvoiceDeactivationStep.Cellphone.step} de ${totalSteps})`}
            open={true}
            step={step}
            setNextModal={setStep}
            onClose={handleClose}
            currentUser={currentUser}
          />
        )}
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  content: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  text: {
    fontSize: 14,
    color: theme.palette.text.primary
  },
  passwordField: {
    width: 250,
    marginTop: theme.spacing(4)
  }
}));

export default ContractDigitalInvoiceDeactivation;
