import React from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const CategoryButton = props => {
  const classes = useStyles();
  const { id, loading, disabled, className, ...rest } = props;

  return (
    <Button
      id={id}
      disabled={loading || disabled}
      variant="contained"
      className={clsx(classes.buttonBase, classes.buttonText, className)}
      {...rest}
    >
      {props.children}
    </Button>
  );
};
const useStyles = makeStyles(theme => ({
  buttonBase: {
    fontSize: 14,
    height: 40,
    fontFamily: theme.typography.fontFamily,
    lineHeight: 1.29,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    boxShadow: 'none',
    fontWeight: 'bold'
  },
  buttonText: {
    textTransform: 'none'
  }
}));
export default CategoryButton;
