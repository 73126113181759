import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import TextInput from '../../../Components/Inputs/TextInput';
import ControlledSelectInput from '../../../Components/Controlled/ControlledSelect';
import ModalProgress from '../../../Components/Progress/Modal/ModalProgress';

import {
  StepperDataContext,
  StepperDataDispatchContext,
  SetCurrentStepIndexContext
} from '../../../Contexts/StepperContext';
import { UserContext } from '../../../Contexts/UserContext';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';

import { SubmitGanaLocoAPI } from '../../../API/Campaigns/GanaLoco/GanaLocoAPI';
import { extractErrorMessage } from '../../../Utils/Errors/Errors';

import { CIVIL_STATUS_LABEL_LIST } from '../fixes/ganaLoco_enums';
import { thirdStepSchema } from '../fixes/schemas';

const ThirdStep = props => {
  const classes = useStyles();

  const {
    formId,
    setGoBack,
    setTopBarProgress,
    setFormState,
    setNextButtonText,
    setCurrentStep: setVisualCurrentStep,
    setUserParticipationId
  } = props;

  const [loading, setLoading] = useState(false);

  const setCurrentStep = useContext(SetCurrentStepIndexContext);
  const setData = useContext(StepperDataDispatchContext);
  const stepperData = useContext(StepperDataContext);
  const setAlert = useContext(AlertsDispatchContext);
  const currentUser = useContext(UserContext);

  const authToken = currentUser ? currentUser.token : null;

  const { register, watch, getValues, errors, control, handleSubmit } = useForm(
    {
      validationSchema: thirdStepSchema,
      submitFocusError: true,
      defaultValues: {
        civilStatus: 0,
        product: stepperData.brillaProducts[0].value,
        whatProduct: '',
        ...stepperData
      }
    }
  );

  const watchForm = watch(['product']);

  const onBackward = useCallback(() => {
    setData(data => ({ ...data, ...getValues() }));
    setCurrentStep(currentStep => currentStep - 1);
    setVisualCurrentStep(currentStep => currentStep - 1);
    setTopBarProgress(50);
    setNextButtonText('Continuar');
  }, [
    getValues,
    setData,
    setCurrentStep,
    setNextButtonText,
    setTopBarProgress,
    setVisualCurrentStep
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setGoBack({
      action: onBackward
    });
    setTopBarProgress(75);
  }, [setGoBack, onBackward, setTopBarProgress]);

  const onSuccessUserData = useCallback(
    async formValues => {
      setLoading(true);
      const response = await SubmitGanaLocoAPI(
        {
          step: 3,
          data: {
            identification: stepperData.identification,
            civilStatus: formValues.civilStatus,
            product:
              formValues.product === -2
                ? formValues.whatProduct
                : stepperData.brillaProducts[formValues.product].label
          }
        },
        authToken
      );

      if (!response.success) {
        setLoading(false);
        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
        return;
      }

      setData(oldData => ({
        ...oldData,
        ...formValues
      }));
      setLoading(false);
      setCurrentStep(step => step + 1);
      setVisualCurrentStep(step => step + 1);
      setUserParticipationId(response.data.data.participationId);
      setFormState(response.data.data.status);
      setTopBarProgress(100);
    },
    [
      setData,
      setCurrentStep,
      setAlert,
      setFormState,
      setTopBarProgress,
      setUserParticipationId,
      setVisualCurrentStep,
      stepperData.brillaProducts,
      stepperData.identification,
      authToken
    ]
  );

  const onForward = data => {
    onSuccessUserData(data);
  };

  return (
    <>
      {loading && <ModalProgress message="Consultando" />}
      <Box className={classes.shrinkedBox}>
        <form id={formId} onSubmit={handleSubmit(onForward)}>
          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} sm={6} className={classes.pairGridItem}>
              <ControlledSelectInput
                className={classes.normalizedInputField}
                label="Estado civil*"
                name="civilStatus"
                control={control}
                options={CIVIL_STATUS_LABEL_LIST}
                fullWidth
                InputPropsClasses={{
                  root: classes.normalizedInputField
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText
                  }
                }}
                error={Boolean(errors.civilStatus)}
                helperText={errors.civilStatus && errors.civilStatus.message}
              />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.pairGridItem}>
              <ControlledSelectInput
                className={classes.normalizedInputField}
                label="¿Qué producto le gustaría financiar?*"
                name="product"
                control={control}
                options={stepperData.brillaProducts}
                fullWidth
                InputPropsClasses={{
                  root: classes.normalizedInputField
                }}
                FormHelperTextProps={{
                  classes: {
                    root: classes.helperText
                  }
                }}
                InputLabelProps={{
                  classes: {
                    root: classes.label
                  }
                }}
                menuListClass={classes.menuListClass}
                error={Boolean(errors.product)}
                helperText={errors.product && errors.product.message}
              />
            </Grid>
            {watchForm.product === -2 && (
              <Grid item xs={12} sm={12} className={classes.completeGridItem}>
                <TextInput
                  className={classes.normalizedInputField}
                  label="¿Cuál?"
                  fullWidth
                  inputProps={{ maxLength: 60 }}
                  InputPropsClasses={{
                    root: classes.normalizedInputField
                  }}
                  FormHelperTextProps={{
                    classes: {
                      root: classes.helperText
                    }
                  }}
                  inputRef={register}
                  name="whatProduct"
                  error={Boolean(errors.whatProduct)}
                  helperText={errors.whatProduct && errors.whatProduct.message}
                />
              </Grid>
            )}
          </Grid>
        </form>
      </Box>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  shrinkedBox: {
    width: '88.89%',
    margin: '0 auto'
  },
  gridContainer: {
    width: '100%',
    margin: 0,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  pairGridItem: {
    margin: theme.spacing(0, 0, 3.75, 0),
    [theme.breakpoints.up('sm')]: {
      maxWidth: theme.spacing(30)
    }
  },
  completeGridItem: {
    margin: theme.spacing(0, 0, 5, 0)
  },
  normalizedInputField: {
    margin: 0,
    minHeight: 50,
    maxHeight: 50
  },
  helperText: {
    fontSize: 10,
    margin: 0,
    textAlign: 'right'
  },
  menuListClass: {
    [theme.breakpoints.up('sm')]: {
      height: 225
    }
  },
  label: {
    [theme.breakpoints.up('sm')]: {
      fontSize: 12
    }
  }
}));

export default ThirdStep;
