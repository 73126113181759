import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Breadcrumbs, Container, Link, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
import _get from 'lodash/get';

import ContractCurrentDebtCard from '../Cards/ContractCurrentDebtCard';
import ModalProgress from '../../../Components/Progress/Modal/ModalProgress';
import DetailsAccordion from '../Components/Current/DetailsAccordion';

import {
  ContractSelectedContext,
  ContractsContext
} from '../../../Contexts/ContractsContext';
import {
  UserContext,
  UserDispatchContext
} from '../../../Contexts/UserContext';

import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';

import {
  redirectOnAuthFailure,
  extractErrorMessage
} from '../../../Utils/Errors/Errors';

import { ROUTE_NAMES } from '../../../Routes/Routes';
import { history } from '../../../Routes/history';
import {
  GetContractDebtsAPI,
  GetDebtNegotiationPlanAPI
} from '../../../API/Debts/DebtsAPI';

import currentDebtBanner from '../../../Assets/images/current-debt-banner.png';
import SweetAlert from '../../../Components/Alerts/SweetAlert';
import { ALERT_TYPE } from '../../../Components/Alerts/alert_enums';
import {
  getBrillaCurrentDebtValue,
  getCurrentDebtData,
  getIsBillingPeriod
} from '../helpers/helpers';
import { CONTRACT_TYPES } from '../../Contracts/contract_enums';
import { Company } from '../../../Configs/general';
import { logoutUser } from '../../../Utils/User/Actions';

const CurrentDebtSummaryGDC = () => {
  const classes = useStyles();

  // * CONTEXTS
  const contracts = useContext(ContractsContext);
  const selectedContract = useContext(ContractSelectedContext);
  const currentUser = useContext(UserContext);
  const setAlert = useContext(AlertsDispatchContext);
  const setCurrentUser = useContext(UserDispatchContext);

  const authToken = _get(currentUser, 'token');

  // * STATE HOOKS
  const [debtsData, setDebtsData] = useState(null);
  const [negotiationData, setNegotiationData] = useState(null);
  const [loading, setLoading] = useState(false);

  // * GLOBAL VARS
  const isBillingPeriod =
    debtsData &&
    getIsBillingPeriod(debtsData.daysUntilBilling, debtsData.billingDate);

  const currentDebtData =
    debtsData &&
    negotiationData &&
    getCurrentDebtData(debtsData, negotiationData);

  const punishedData = debtsData && debtsData.punishedData;
  const punishedProducts =
    punishedData && debtsData.punishedData && punishedData.products;
  const punishedProductIds = useMemo(() => {
    return punishedProducts
      ? punishedProducts.map(punishedProduct => punishedProduct.productId)
      : [];
  }, [punishedProducts]);

  const productsPendingBilling =
    (debtsData && debtsData.pendingBillingsPerProduct) || [];

  const brillaCurrentDebtValue = debtsData
    ? getBrillaCurrentDebtValue(debtsData)
    : 0;

  const applicableProducts = productsPendingBilling.filter(
    item => item.pendingBillings > 2
  );

  const canRefinance = applicableProducts.length > 0;

  const disableRefinancing = () => {
    if (selectedContract.type !== CONTRACT_TYPES.Residential) {
      return true;
    }

    if (isBillingPeriod) {
      return true;
    }

    if (!negotiationData) {
      return true;
    }

    return false;
  };

  // * FUNCTIONS
  useEffect(() => {
    if (!authToken) {
      history.replace('/');
      return;
    }

    if (!contracts || !contracts.length) {
      history.replace(ROUTE_NAMES.contracts);
      return;
    }

    const fetchData = async () => {
      setLoading(true);

      const [debtsResponse, negotiationResponse] = await Promise.all([
        GetContractDebtsAPI(authToken, selectedContract.id),
        GetDebtNegotiationPlanAPI(authToken, selectedContract.id)
      ]);

      if (negotiationResponse.success) {
        const responseData = negotiationResponse.data.data;

        const usuryRate = Number.parseFloat(
          responseData.usuryRate / 12
        ).toFixed(2);

        setNegotiationData({ ...responseData, usuryRate });
      } else {
        setNegotiationData(null);
      }

      if (debtsResponse.success) {
        const responseData = debtsResponse.data.data;
        setDebtsData(responseData);
        setLoading(false);

        if (responseData.currentValue === 0) {
          history.replace(ROUTE_NAMES.debtStatus);
        }
      } else {
        setDebtsData(null);
        setLoading(false);

        if (
          redirectOnAuthFailure(debtsResponse, '/', () =>
            logoutUser(setCurrentUser)
          )
        ) {
          return;
        }

        setAlert({
          type: 'error',
          message: extractErrorMessage(debtsResponse).message
        });
      }
    };

    fetchData();
  }, [authToken, contracts, selectedContract, setAlert, setCurrentUser]);

  const getConcepts = productId => {
    if (debtsData.currents) {
      const filteredData = debtsData.currents.filter(
        item => item.productId === productId
      );

      const toDateData = filteredData.filter(
        item => !item.expired && item.currentValue !== 0
      );
      const expiredData = filteredData.filter(
        item => item.expired && item.currentValue !== 0
      );
      return {
        data: filteredData,
        currentTotal: filteredData.reduce(
          (currentTotal, item) => item.currentValue + currentTotal,
          0
        ),
        toDateData,
        toDateTotal: toDateData.reduce(
          (total, item) => item.currentValue + total,
          0
        ),
        expiredData,
        expiredTotal: expiredData.reduce(
          (total, item) => item.currentValue + total,
          0
        )
      };
    }
  };

  const getProductsIds = () => {
    const productIds = debtsData.currents.map(concept => concept.productId);

    return [...new Set(productIds)];
  };

  const goToRefinancing = useCallback(() => {
    history.push(ROUTE_NAMES.refinancingCurrent, {
      selectedContract,
      data: currentDebtData,
      punishedIds: punishedProductIds
    });
  }, [selectedContract, currentDebtData, punishedProductIds]);

  if (loading) {
    return <ModalProgress message="Consultando tu deuda actual" />;
  }

  if (!debtsData) {
    return <Fragment />;
  }

  const renderSweetAlert = () => {
    if (isBillingPeriod) {
      return (
        <SweetAlert
          iconSize={20}
          type={ALERT_TYPE.WARNING}
          classes={{
            root: classes.sweetAlert,
            message: classes.sweetAlertText,
            messageContainer: classes.sweetAlertMessageContainer,
            icon: classes.sweetAlertIcon
          }}
          message={
            <>
              <b>
                Tu {Company.contractConjugation.regular.singular.main} se
                encuentra en periodo de facturación, por lo que solo puedes
                realizar las siguientes acciones:
                <br />
              </b>
              - Descargar comprobante del estado de cuenta <br />
              - Pagar deuda actual <br />
              - Ver detalle de tu deuda actual <br />
              - Abonar a Brilla <br />
            </>
          }
        />
      );
    }

    if (canRefinance && negotiationData) {
      return (
        <SweetAlert
          type={ALERT_TYPE.INFO}
          message={
            <>
              Puedes realizar un <b>acuerdo de pago</b> para tu{' '}
              {Company.contractConjugation.regular.singular.main}{' '}
              <b>
                {selectedContract.id} ({selectedContract.alias})
              </b>
              .
            </>
          }
          hasAction
          actionText="Ir a acuerdo de pago"
          actionOnClick={() => goToRefinancing()}
        />
      );
    }

    if (canRefinance && !negotiationData) {
      return (
        <SweetAlert
          type={ALERT_TYPE.ERROR}
          message={`No puedes realizar un acuerdo de pago para este ${Company.contractConjugation.regular.singular.main} en este momento.`}
        />
      );
    }

    return <></>;
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <Container className={classes.container}>
          <Breadcrumbs
            aria-label="breadcrumb"
            className={classes.breadcrumbs}
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              color="textPrimary"
              component={RouterLink}
              to="/"
              underline="always"
            >
              Inicio{' '}
            </Link>
            <Link
              color="textPrimary"
              component={RouterLink}
              to="/contracts/debt"
              underline="always"
            >
              Mis deudas{' '}
            </Link>
            <Typography color="primary" className={classes.breadcrumbs}>
              Deuda actual
            </Typography>
          </Breadcrumbs>

          {renderSweetAlert()}

          <ContractCurrentDebtCard
            title="Deuda actual"
            contract={selectedContract}
            debtsData={debtsData}
            negotiationsData={negotiationData}
            punishedProductIds={punishedProductIds}
            canRefinance={canRefinance}
            disableRefinancing={disableRefinancing()}
            banner={currentDebtBanner}
            authToken={authToken}
            brillaDebtValueToPay={brillaCurrentDebtValue}
          />

          <Typography className={classes.title}>
            Listado de productos
          </Typography>

          {debtsData &&
            debtsData.currents &&
            getProductsIds().map((productId, index) => {
              return (
                <DetailsAccordion
                  key={`${productId}-${index}`}
                  concepts={getConcepts(productId)}
                  type={debtsData.products[productId]}
                  id={productId}
                  selectedContract={selectedContract}
                />
              );
            })}
        </Container>
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  container: {
    maxWidth: 816,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginTop: theme.spacing(3)
    },
    '& > *': {
      marginBottom: theme.spacing(3)
    },
    '& > *:last-child': {
      [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        marginBottom: theme.spacing(10)
      }
    }
  },
  breadcrumbs: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium,
    '& > *': { fontSize: 'inherit' }
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: 14
    }
  },
  sweetAlertText: {
    fontSize: 12
  },
  sweetAlertMessageContainer: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  sweetAlertIcon: {
    marginTop: theme.spacing(0.5)
  }
}));

export default CurrentDebtSummaryGDC;
