import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import React, { useCallback, useContext, useState } from 'react';
import { DeleteUserAPI } from '../../API/UserAPI';
import BaseButton from '../../Components/Buttons/BaseButton';
import BaseDialog from '../../Components/Dialogs/BaseDialog';
import ModalProgress from '../../Components/Progress/Modal/ModalProgress';
import { SlideUpTransition } from '../../Components/Transitions/Transitions';
import { AlertsDispatchContext } from '../../Contexts/AlertsContext';
import { extractErrorMessage } from '../../Utils/Errors/Errors';
import {
  AppleClientInitializedContext,
  AppleClientSetAppleListenerState
} from '../../Contexts/AppleClientContext';
import { RevokeAppleTokenAPI } from '../../API/SocialUserAPI';

const UnlinkAppleAccountDialog = ({
  open,
  setDialog,
  currentUser,
  onSuccessDeleteAccount,
  setIsAppleLoading,
  isLoading
}) => {
  const setAlert = useContext(AlertsDispatchContext);
  const setApplePopupListener = useContext(AppleClientSetAppleListenerState);
  const isAppleInitialized = useContext(AppleClientInitializedContext);

  const [loading, setLoading] = useState(false);

  const isMobileSize = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  const handleClose = useCallback(() => {
    setDialog(false);
  }, [setDialog]);

  const handleDeleteAccount = useCallback(async () => {
    setLoading(true);

    const response = await DeleteUserAPI(currentUser.token, currentUser.id);

    if (response.success) {
      setLoading(false);
      onSuccessDeleteAccount();
    } else {
      setLoading(false);

      const { message } = extractErrorMessage(response);

      setAlert({
        type: 'error',
        message
      });
    }
  }, [currentUser, onSuccessDeleteAccount, setAlert]);

  const handleUnlinkAppleAccount = useCallback(async () => {
    setIsAppleLoading(true);
    setApplePopupListener(false);

    if (!isAppleInitialized) {
      setAlert({
        type: 'error',
        message:
          'No es posible desvincular tu cuenta en este momento. Intenta más tarde.'
      });

      setIsAppleLoading(false);
      setApplePopupListener(true);
      return;
    }

    try {
      const data = await window.AppleID.auth.signIn();

      setApplePopupListener(true);

      const { code, id_token: idToken } = data.authorization;

      const response = await RevokeAppleTokenAPI(
        { idToken, code },
        currentUser.authToken
      );

      if (response.success) {
        await handleDeleteAccount();
        setIsAppleLoading(false);
        return;
      }

      setAlert({
        type: 'error',
        message:
          'No pudimos desvincular tu cuenta de Apple. Inténtalo más tarde.'
      });
      setIsAppleLoading(false);
    } catch (err) {
      setAlert({
        type: 'info',
        message:
          'Debes iniciar sesión con tu cuenta de Apple para desvincularla. Inténtalo de nuevo.'
      });
      setApplePopupListener(true);
      setIsAppleLoading(false);
    }
  }, [
    currentUser,
    setAlert,
    setIsAppleLoading,
    setApplePopupListener,
    isAppleInitialized,
    handleDeleteAccount
  ]);

  const renderActions = () => {
    return (
      <>
        <BaseButton
          id="UnlinkAppleAccountDialog_button_cancel"
          onClick={handleClose}
          variant="outlined"
          color="primary"
          size="small"
        >
          Cancelar
        </BaseButton>
        <BaseButton
          onClick={handleUnlinkAppleAccount}
          id="UnlinkAppleAccountDialog_button_confirm"
          color="error"
          size="small"
          autoFocus
          disabled={isLoading}
        >
          Desvincular
        </BaseButton>
      </>
    );
  };

  const renderContent = () => {
    return (
      <Grid container>
        <Typography paragraph>
          Para continuar con la eliminación de tu cuenta debes desvincular tu
          cuenta de Apple.
        </Typography>
      </Grid>
    );
  };

  return (
    <>
      {loading && (
        <ModalProgress
          id="DeleteAccountDialog_loading"
          message="Eliminando tu cuenta..."
        />
      )}

      <BaseDialog
        id="DeleteAccountDialog_div"
        open={open}
        disableBackdropClick
        handleClose={handleClose}
        title="¿Estás seguro de eliminar tu cuenta?"
        actions={renderActions}
        content={renderContent}
        fullScreen={isMobileSize}
        TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
        contentSize={isMobileSize ? undefined : 'small'}
        loading={isLoading}
      />
    </>
  );
};

export default UnlinkAppleAccountDialog;
