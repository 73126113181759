/* eslint-disable complexity */
import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useCallback
} from 'react';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import ModalProgress from '../../Components/Progress/Modal/ModalProgress';
import SimpleDivider from '../../Components/Dividers/SimpleDivider';
import TextInput from '../../Components/Inputs/TextInput';
import SelectInput from '../../Components/Inputs/SelectInput';
import NumberFormatInput from '../../Components/Inputs/PhoneInput';
import Title from '../../Components/Labels/Title';

import { fullName } from '../../Utils/Format/Names';

import {
  TYPE_OF_IDENT_OPTIONS,
  TYPE_OF_CUSTOMER
} from '../Payment/payment_enums';
import { ROUTE_NAMES } from '../../Routes/Routes';
import { TERMS, TERMS_ROUTES } from '../Information/Information/Information';
import { GetBanksListAPI } from '../../API/Payments/PaymentsAPI';
import { UserContext } from '../../Contexts/UserContext';
import FlagColombia from '../../Components/CustomIcons/Flags/FlagColombia';
import { extractFormErrorsYup } from '../../Utils/Errors/Errors';
import { isPrintableAscii } from '../../Utils/Misc/String';
import { PrivacyPoliciesLink } from '../../Configs/Links';

const pseInfoSchema = yup.object({
  email: yup
    .string()
    .trim()
    .lowercase()
    .email('Debes ingresar un correo válido')
    .test(
      'is-ascii',
      'Este campo no puede contener tildes ni caracteres especiales',
      isPrintableAscii
    )
    .required('Debes ingresar un correo'),
  payerFullName: yup
    .string()
    .trim()
    .required('Debes ingresar un nombre'),
  typeOfIdentification: yup
    .string()
    .trim()
    .required('Debes seleccionar una opción válida'),
  identification: yup
    .string()
    .trim()
    .required('Debes ingresar una identificación válida'),
  phone: yup
    .string()
    .trim()
    .length(10, 'Debe tener 10 dígitos')
    .required('Debes ingresar un teléfono válido'),
  phoneCountryCode: yup
    .string()
    .trim()
    .max(3, 'Debe tener mínimo 3 dígitos')
    .required('Debes ingresar un prefijo válido'),
  currentBank: yup
    .number()
    .notOneOf([0], 'Debes seleccionar un banco')
    .required('Debes seleccionar un banco válido'),
  typeOfPerson: yup
    .string()
    .trim()
    .required('Debes seleccionar una opción válida')
});

const mapBanksList = list => {
  return list.map(bank => {
    if (bank.pseCode === '0') {
      return {
        label: 'Selecciona tu banco',
        value: bank.pseCode
      };
    }
    return {
      label: bank.description,
      value: bank.pseCode
    };
  });
};

const findBankName = (banks, code) => {
  return banks.find(bank => bank.value === code).label;
};

const PseInfoForm = props => {
  const { loading = false, setPayerInfo, setDisabledNext } = props;
  // * CONTEXTS
  const currentUser = useContext(UserContext);

  // * OTHER HOOKS
  const classes = useStyles();

  // * INPUTS
  const [email, setEmail] = useState(currentUser ? currentUser.email : '');
  const [payerFullName, setPayerFullName] = useState(
    currentUser ? `${fullName(currentUser, 'firstName', 'lastName')}` : ''
  );
  const [typeOfIdentification, setTypeOfIdentification] = useState(
    currentUser ? currentUser.identificationType : 'CC'
  );
  const [identification, setIdentification] = useState(
    currentUser ? currentUser.identification : ''
  );

  const currentUserPhone = useCallback(() => {
    if (!currentUser) {
      return '';
    }
    if (currentUser.phone) {
      return currentUser.phone;
    }
    return '';
  }, [currentUser]);

  const currentUserCountryCode = useCallback(() => {
    if (!currentUser) {
      return '57';
    }
    if (currentUser.phoneCountryCode) {
      return currentUser.phoneCountryCode;
    }
    return '57';
  }, [currentUser]);

  const [phone, setPhone] = useState(currentUserPhone);
  const [phoneCountryCode, setPhoneCountryCode] = useState(
    currentUserCountryCode
  );
  const [acceptsTerms, setAcceptsTerms] = useState(Boolean(currentUser));
  const [banks, setBanks] = useState([]);
  const [currentBank, setCurrentBank] = useState('0');
  const [typeOfPerson, setTypeOfPerson] = useState('N');
  const [banksLoading, setBanksLoading] = useState(true);
  const [errors, setErrors] = useState({});

  const onChangeBank = useCallback(event => {
    setCurrentBank(event.target.value);
  }, []);

  const onChangeEmail = useCallback(event => {
    setEmail(event.target.value);
  }, []);

  const onChangeTypeOfPerson = useCallback(event => {
    setTypeOfPerson(event.target.value);
  }, []);

  const onChangePayerFullName = useCallback(event => {
    setPayerFullName(event.target.value);
  }, []);

  const onChangeTypeOfIdentification = useCallback(event => {
    setTypeOfIdentification(event.target.value);
  }, []);

  const onChangeIdentification = useCallback(event => {
    setIdentification(event.target.value);
  }, []);

  const onChangePhone = useCallback(event => {
    setPhone(event.target.value);
  }, []);

  const onChangeCountryCode = useCallback(event => {
    setPhoneCountryCode(event.target.value);
  }, []);

  const onChangeAcceptTerms = useCallback(() => {
    if (currentUser) {
      return;
    }

    setAcceptsTerms(!acceptsTerms);
  }, [currentUser, acceptsTerms]);

  useEffect(() => {
    const fetchBanks = async () => {
      setBanksLoading(true);
      const response = await GetBanksListAPI();
      setBanksLoading(false);

      if (response.success) {
        setBanks(mapBanksList(response.data.data.banks));
      }
    };

    fetchBanks();
  }, []);

  useEffect(() => {
    if (
      !email ||
      !payerFullName ||
      !typeOfIdentification ||
      !identification ||
      !phone ||
      !phoneCountryCode ||
      !currentBank ||
      !typeOfPerson
    ) {
      setDisabledNext(true);
      return;
    }
    try {
      pseInfoSchema.validateSync(
        {
          email,
          payerFullName,
          typeOfIdentification,
          identification,
          phone,
          phoneCountryCode,
          currentBank,
          typeOfPerson
        },
        { abortEarly: false }
      );
      setErrors({});
      setDisabledNext(!acceptsTerms);
    } catch (err) {
      setDisabledNext(true);
      setErrors(extractFormErrorsYup(err));
    }
  }, [
    email,
    payerFullName,
    typeOfIdentification,
    identification,
    phone,
    phoneCountryCode,
    currentBank,
    typeOfPerson,
    acceptsTerms,
    setDisabledNext
  ]);

  useEffect(() => {
    if (!acceptsTerms || !banks || banks.length === 0) {
      setPayerInfo(null);
      return;
    }

    setPayerInfo({
      type: 'pse',
      email,
      payerFullName,
      typeOfIdentification,
      identification,
      phone,
      phoneCountryCode,
      currentBank,
      currentBankName: findBankName(banks, currentBank),
      typeOfPerson
    });
  }, [
    email,
    payerFullName,
    typeOfIdentification,
    identification,
    phone,
    phoneCountryCode,
    currentBank,
    typeOfPerson,
    acceptsTerms,
    setPayerInfo,
    banks
  ]);

  return (
    <Fragment>
      {banksLoading && <ModalProgress id="PseInfoForm_modal_loading" />}
      <form className={classes.formRoot}>
        <Title text={'Información del pago'} className={classes.pageTitle} />
        <Grid container spacing={4} className={classes.formContainer}>
          <Grid item sm={4} xs={12} className={classes.inputContainer}>
            <TextInput
              id="email"
              placeholder="correo@ejemplo.com"
              value={email}
              error={Boolean(errors.email)}
              helperText={errors.email}
              onChange={onChangeEmail}
              disabled={loading}
              label={'Correo electrónico'}
              className={
                errors && Boolean(errors.email)
                  ? classes.inputError
                  : classes.input
              }
              margin="none"
              fullWidth
            />
          </Grid>
          <Grid item sm={4} xs={12} className={classes.inputContainer}>
            <SelectInput
              id="typeOfPerson"
              value={typeOfPerson}
              error={Boolean(errors.typeOfPerson)}
              helperText={errors.typeOfPerson}
              onChange={onChangeTypeOfPerson}
              disabled={loading}
              label={'Tipo de cliente'}
              className={
                errors && Boolean(errors.typeOfPerson)
                  ? classes.inputError
                  : classes.input
              }
              margin="none"
              fullWidth
              options={TYPE_OF_CUSTOMER}
            />
          </Grid>
          <Grid item sm={4} xs={12} className={classes.inputContainer}>
            <SelectInput
              id="currentBank"
              value={currentBank}
              error={Boolean(errors.currentBank)}
              helperText={errors.currentBank}
              onChange={onChangeBank}
              disabled={loading}
              label={'Banco'}
              className={
                errors && Boolean(errors.currentBank)
                  ? classes.inputError
                  : classes.input
              }
              margin="none"
              fullWidth
              options={banks}
            />
          </Grid>
          <Grid item sm={4} xs={12} className={classes.inputContainer}>
            <TextInput
              id="payerFullName"
              value={payerFullName}
              error={Boolean(errors.payerFullName)}
              helperText={errors.payerFullName}
              onChange={onChangePayerFullName}
              disabled={loading}
              label={'Nombre del pagador'}
              className={
                errors && Boolean(errors.payerFullName)
                  ? classes.inputError
                  : classes.input
              }
              margin="none"
              fullWidth
            />
          </Grid>
          <Grid item sm={4} xs={12} className={classes.inputContainer}>
            <SelectInput
              id="typeOfIdentification"
              value={typeOfIdentification}
              error={Boolean(errors.typeOfIdentification)}
              helperText={errors.typeOfIdentification}
              onChange={onChangeTypeOfIdentification}
              disabled={loading}
              label={'Tipo de identificación'}
              className={
                errors && Boolean(errors.typeOfIdentification)
                  ? classes.inputError
                  : classes.input
              }
              margin="none"
              fullWidth
              options={TYPE_OF_IDENT_OPTIONS}
            />
          </Grid>
          <Grid item sm={4} xs={12} className={classes.inputContainer}>
            <TextInput
              id="identification"
              value={identification}
              error={Boolean(errors.identification)}
              helperText={errors.identification}
              onChange={onChangeIdentification}
              disabled={loading}
              label={'Número de identificación'}
              className={
                errors && Boolean(errors.identification)
                  ? classes.inputError
                  : classes.input
              }
              margin="none"
              fullWidth
            />
          </Grid>
          <Grid item sm={4} xs={12} className={classes.inputContainer}>
            <NumberFormatInput
              InputProps={{
                id: 'PseInfoForm_input_phoneCountryCode',
                autoComplete: 'tel-country-code',
                startAdornment: (
                  <FlagColombia size={20} style={{ flexShrink: 0 }} />
                )
              }}
              NumberInputProps={{
                format: '+###',
                type: 'tel',
                mask: ''
              }}
              placeholder="57"
              fullWidth
              label="Prefijo telefónico"
              error={Boolean(errors.phoneCountryCode)}
              helperText={errors.phoneCountryCode}
              value={phoneCountryCode}
              onChange={onChangeCountryCode}
              className={
                errors && Boolean(errors.phoneCountryCode)
                  ? classes.inputError
                  : classes.input
              }
              margin="none"
            />
          </Grid>
          <Grid item sm={4} xs={12} className={classes.inputContainer}>
            <NumberFormatInput
              id="PseInfoForm_input_phone"
              InputProps={{
                autoComplete: 'tel-local'
              }}
              mask=" "
              value={phone}
              error={Boolean(errors.phone)}
              helperText={errors.phone}
              onChange={onChangePhone}
              className={
                errors && Boolean(errors.phone)
                  ? classes.inputError
                  : classes.input
              }
              disabled={loading}
              label={'Número celular'}
              margin="none"
              fullWidth
            />
          </Grid>
          {!Boolean(currentUser) && (
            <Grid item xs={12} className={classes.termsContainer}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="acceptsTerms"
                    checked={acceptsTerms}
                    onClick={onChangeAcceptTerms}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    disabled={Boolean(currentUser)}
                    value="checkedI"
                  />
                }
                label={
                  <Typography className={classes.terms}>
                    He leído y acepto los
                    <a
                      className={classes.link}
                      href={`${ROUTE_NAMES.information}/${
                        TERMS_ROUTES[TERMS.introduction]
                      }`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      términos y condiciones
                    </a>
                    de uso, la{' '}
                    <a href={PrivacyPoliciesLink}>
                      política y aviso de privacidad
                    </a>{' '}
                    y autorizo el tratamiento de mis datos personales.
                  </Typography>
                }
              />
            </Grid>
          )}
          <SimpleDivider />
        </Grid>
      </form>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  formRoot: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3.5)
  },
  pageTitle: {
    marginTop: 0
  },
  formContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  inputContainer: {
    marginBottom: theme.spacing(1.2),
    paddingBottom: '0 !important',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(1.2),
      paddingTop: '0 !important'
    }
  },
  termsContainer: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: '0 !important',
      paddingBottom: '0 !important'
    }
  },
  terms: {
    fontSize: 14
  },
  input: {
    marginBottom: 17
  },
  inputError: {
    marginBottom: 0
  }
}));

export default PseInfoForm;
