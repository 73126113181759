import React from 'react';
import { portal, Portal } from '../../../../Configs/general';

import TransactionActionsGDC from './TransactionActions.GDC';
import TransactionActionsGDG from './TransactionActions.GDG';

const TransactionActions = props => {
  switch (portal) {
    case Portal.Gascaribe:
    case Portal.TripleA:
      return <TransactionActionsGDC {...props} />;
    case Portal.Gasguajira:
      return <TransactionActionsGDG {...props} />;
    case Portal.Efigas:
      return <TransactionActionsGDC {...props} />;
    default:
      return <></>;
  }
};

export default TransactionActions;
