import React from 'react';

const SvgBannerWarningIcon = props => (
  <svg
    width={props.width || 235}
    height={props.height || 157}
    viewBox="0 0 235 157"
    {...props}
  >
    <g data-name="Grupo 759">
      <g data-name="Grupo 753">
        <g data-name="Grupo 710" transform="translate(0 2.5)">
          <path
            data-name="Trazado 186"
            d="M20.521 53.77H9.419A9.465 9.465 0 010 44.323v-1.954a9.459 9.459 0 019.419-9.419h11.075a9.442 9.442 0 019.419 9.419v1.954a9.424 9.424 0 01-9.392 9.447z"
            fill="#696a6a"
            opacity={0.1}
          />
          <path
            data-name="Trazado 187"
            d="M225.926 140.308h-11.075a9.442 9.442 0 01-9.419-9.419v-1.954a9.442 9.442 0 019.419-9.419h11.075a9.442 9.442 0 019.419 9.419v1.954a9.459 9.459 0 01-9.419 9.419z"
            fill="#696a6a"
            opacity={0.1}
          />
          <path
            data-name="Trazado 188"
            d="M206.086 75.161h-23.4A68.423 68.423 0 0072.042 32.978H48.426a10.415 10.415 0 00-10.4 10.4 10.463 10.463 0 008.662 10.257h16.83a11.376 11.376 0 0111.344 11.346v1.276a11.376 11.376 0 01-11.346 11.346H29.862a10.415 10.415 0 00-10.4 10.4 10.415 10.415 0 0010.4 10.4h17.942a68.418 68.418 0 00109.558 41.523 10.134 10.134 0 002.742.38h26.5a10.415 10.415 0 0010.4-10.4 10.415 10.415 0 00-10.4-10.393h-15.642a11.134 11.134 0 01-11.1-11.1v-1.466a11.133 11.133 0 0111.1-11.1h25.109a9.56 9.56 0 011.791.163h8.17a10.415 10.415 0 0010.4-10.4 10.363 10.363 0 00-10.339-10.451z"
            fill="#696a6a"
            opacity={0.1}
          />
          <circle
            data-name="Elipse 149"
            cx={4.189}
            cy={4.189}
            transform="translate(55.186)"
            fill="none"
            stroke="#fcc52c"
            strokeMiterlimit={10}
            strokeWidth={5}
            r={4.189}
          />
          <circle
            data-name="Elipse 151"
            cx={4.189}
            cy={4.189}
            transform="translate(184.19 61.09)"
            fill="none"
            stroke="#fcc52c"
            strokeMiterlimit={10}
            strokeWidth={5}
            r={4.189}
          />
          <path
            data-name="Trazado 189"
            d="M205.706 33.068h-6.473v-6.47a1.4 1.4 0 00-2.793 0v6.47h-6.472a1.4 1.4 0 000 2.793h6.473v6.473a1.4 1.4 0 002.793 0v-6.473h6.473a1.409 1.409 0 001.4-1.4 1.389 1.389 0 00-1.4-1.4z"
            fill="#696a6a"
          />
          <path
            data-name="Trazado 190"
            d="M62.2 69.118h-6.469v-6.47a1.4 1.4 0 00-2.793 0v6.473h-6.507a1.4 1.4 0 100 2.793h6.473v6.473a1.4 1.4 0 002.793 0v-6.473h6.473a1.409 1.409 0 001.4-1.4 1.361 1.361 0 00-1.363-1.4z"
            fill="#696a6a"
          />
          <path
            data-name="Trazado 191"
            d="M217.956 86.32l-3.554-3.554 3.554-3.554a1.077 1.077 0 00-1.524-1.523l-3.554 3.554-3.554-3.554a1.077 1.077 0 10-1.523 1.523l3.555 3.554-3.554 3.554a1.077 1.077 0 101.523 1.523l3.554-3.554 3.554 3.554a1.091 1.091 0 001.524 0 1.037 1.037 0 000-1.523z"
            fill="#696a6a"
          />
          <g data-name="Grupo 709">
            <g data-name="Grupo 230">
              <g data-name="Grupo 229">
                <path
                  data-name="Trazado 195"
                  d="M84.779 124.162h81.416a1.579 1.579 0 000-3.156H84.779a1.579 1.579 0 000 3.156z"
                  fill="#fcc52c"
                />
              </g>
            </g>
            <g data-name="Grupo 232">
              <g data-name="Grupo 231">
                <path
                  data-name="Trazado 196"
                  d="M176.714 124.162h5.784a1.579 1.579 0 000-3.156h-5.785a1.579 1.579 0 000 3.156z"
                  fill="#fcc52c"
                />
              </g>
            </g>
            <g data-name="Grupo 234">
              <g data-name="Grupo 233">
                <path
                  data-name="Trazado 197"
                  d="M56.799 124.162h18.163a1.579 1.579 0 000-3.156H56.799a1.579 1.579 0 000 3.156z"
                  fill="#fcc52c"
                />
              </g>
            </g>
          </g>
        </g>
        <g data-name="Grupo 740">
          <g data-name="Grupo 739">
            <path
              data-name="Trazado 566"
              d="M122.957 75.22a11.841 11.841 0 003.669-8.572V60.16a7.988 7.988 0 001.637-4.852v-6.784a1.659 1.659 0 00-1.659-1.659h-13.672a10.073 10.073 0 00-10.061 10.061v9.722a11.842 11.842 0 003.669 8.572 17.311 17.311 0 00-16.67 17.282v13.543a1.659 1.659 0 001.659 1.659h46.438a1.659 1.659 0 001.659-1.659V92.502a17.311 17.311 0 00-16.669-17.282zm-10.025-25.037h12.012v5.126a4.718 4.718 0 01-4.713 4.713h-14.042v-3.1a6.751 6.751 0 016.743-6.739zm-6.743 16.466v-3.31h14.042a7.984 7.984 0 003.076-.614v3.923a8.56 8.56 0 11-17.119 0zm15.288 11.877l-6.485 10.991-6.485-10.991zm14.831 25.856h-3.733v-11.6a1.659 1.659 0 10-3.318 0v11.6h-28.532v-11.6a1.659 1.659 0 10-3.318 0v11.6h-4.22v-11.88a13.993 13.993 0 0111.59-13.769l8.786 14.891a1.659 1.659 0 002.857 0l8.737-14.811a14 14 0 0111.151 13.689v11.88z"
              fill="#696a6a"
            />
          </g>
        </g>
        <g data-name="Grupo 742">
          <g data-name="Grupo 741">
            <path
              data-name="Trazado 567"
              d="M162.457 22.768h-9.38a1.659 1.659 0 000 3.318h9.38a4.982 4.982 0 014.976 4.976v22.224a4.982 4.982 0 01-4.976 4.976h-6.047a1.659 1.659 0 00-1.173.486l-7.836 7.832v-6.659a1.659 1.659 0 00-1.659-1.659h-14.14a1.659 1.659 0 000 3.318h12.48v9a1.659 1.659 0 002.832 1.173l10.182-10.177h5.36a8.3 8.3 0 008.294-8.294v-22.22a8.3 8.3 0 00-8.293-8.294z"
              fill="#fcc52c"
            />
          </g>
        </g>
        <g data-name="Grupo 744">
          <g data-name="Grupo 743">
            <path
              data-name="Trazado 568"
              d="M148.202 23.254a1.659 1.659 0 00-2.832 1.173 1.659 1.659 0 102.832-1.173z"
              fill="#fcc52c"
            />
          </g>
        </g>
        <g data-name="Grupo 746">
          <g data-name="Grupo 745">
            <path
              data-name="Trazado 569"
              d="M140.965 22.768h-9.364a8.3 8.3 0 00-8.294 8.294v9.2a1.659 1.659 0 003.318 0v-9.2a4.982 4.982 0 014.976-4.976h9.364a1.659 1.659 0 100-3.318z"
              fill="#fcc52c"
            />
          </g>
        </g>
        <g data-name="Grupo 748">
          <g data-name="Grupo 747">
            <path
              data-name="Trazado 570"
              d="M125.359 93.614h-2.322a1.659 1.659 0 000 3.318h2.322a1.659 1.659 0 100-3.318z"
              fill="#696a6a"
            />
          </g>
        </g>
        <g data-name="Grupo 750">
          <g data-name="Grupo 749">
            <path
              data-name="Trazado 571"
              d="M111.995 66.158a1.658 1.658 0 10.486 1.173 1.669 1.669 0 00-.486-1.173z"
              fill="#696a6a"
            />
          </g>
        </g>
        <g data-name="Grupo 752">
          <g data-name="Grupo 751">
            <path
              data-name="Trazado 572"
              d="M119.847 66.158a1.658 1.658 0 10.486 1.173 1.671 1.671 0 00-.486-1.173z"
              fill="#696a6a"
            />
          </g>
        </g>
      </g>
      <g
        data-name="Grupo 754"
        transform="translate(145.235 31.412)"
        fill="#fcc52c"
      >
        <path
          data-name="Trazado 580"
          d="M.014 4.281l1.427 9.455a2.321 2.321 0 002.319 1.962 2.469 2.469 0 002.319-1.962l1.427-9.455A3.711 3.711 0 003.76 0 3.8 3.8 0 00.014 4.281z"
        />
        <circle
          data-name="Elipse 157"
          cx={2.141}
          cy={2.141}
          transform="translate(1.619 17.661)"
          r={2.141}
        />
      </g>
    </g>
  </svg>
);

export default SvgBannerWarningIcon;
