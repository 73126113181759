import React from 'react';

import { IconButton, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import CloseIcon from '../CustomIcons/CloseIcon';
import SuccessIcon from '../CustomIcons/SuccessIcon';
import InfoIcon from '../CustomIcons/InfoIcon';
import ErrorIcon from '../CustomIcons/ErrorIcon';
import WarningIcon from '../CustomIcons/WarningIcon';

import { makeStyles } from '@material-ui/styles';
import { ALERT_POSITION } from './alert_enums';

const Toast = props => {
  const {
    type,
    message,
    timeout = 2000,
    onClose = () => {},
    iconFill = '#ffff',
    iconSize = 24,
    position = {
      vertical: ALERT_POSITION.VERTICAL.top,
      horizontal: ALERT_POSITION.HORIZONTAL.center
    }
  } = props;

  const classes = useStyles();
  const standardSeverity = `standard${type.charAt(0).toUpperCase() +
    type.slice(1)}`;

  return (
    <Snackbar
      open
      onClose={onClose}
      autoHideDuration={timeout}
      action={
        <IconButton onClick={onClose}>
          <CloseIcon size={15} className={classes.closeIcon} />
        </IconButton>
      }
      anchorOrigin={position}
      classes={{
        anchorOriginTopCenter: classes.anchorOriginTopCenter
      }}
    >
      <Alert
        className={classes.root}
        classes={{
          [standardSeverity]: classes[`alert${type}`],
          message: classes.message
        }}
        style={{ opacity: 0.9 }}
        severity={type}
        iconMapping={{
          success: <SuccessIcon size={iconSize} fill={iconFill} />,
          error: <ErrorIcon size={iconSize} fill={iconFill} />,
          info: <InfoIcon size={iconSize} fill={iconFill} />,
          warning: <WarningIcon size={iconSize} fill={iconFill} />
        }}
        action={
          <IconButton onClick={onClose}>
            <CloseIcon size={15} className={classes.closeIcon} />
          </IconButton>
        }
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 4,
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  },
  anchorOriginTopCenter: {
    top: '10%',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      top: '5%'
    }
  },
  [`alert${'success'}`]: {
    backgroundColor: theme.palette.success.main
  },
  [`alert${'info'}`]: {
    backgroundColor: theme.palette.primary.main
  },
  [`alert${'error'}`]: {
    backgroundColor: theme.palette.error.main
  },
  [`alert${'warning'}`]: {
    backgroundColor: theme.palette.warning.main
  },
  message: {
    fontSize: 14,
    color: theme.palette.common.white
  },
  closeIcon: {
    fill: theme.palette.common.white
  }
}));

export default Toast;
