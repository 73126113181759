import React from 'react';
import { portal, Portal } from '../../../../Configs/general';

import MobileDrawerGDC from './MobileDrawer.GDC';
import MobileDrawerGDG from './MobileDrawer.GDG';
import MobileDrawerEFG from './MobileDrawer.EFG';

const MobileDrawer = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <MobileDrawerGDC {...props} />;
    case Portal.Gasguajira:
      return <MobileDrawerGDG {...props} />;
    case Portal.Efigas:
      return <MobileDrawerEFG {...props} />;
    case Portal.TripleA:
      return <MobileDrawerGDG {...props} />;
    default:
      return <></>;
  }
};

export default MobileDrawer;
