import React from 'react';
import { portal, Portal } from '../../../../Configs/general';

import CurrentDebtCardGDC from './CurrentDebtCard.GDC';
import CurrentDebtCardEFG from './CurrentDebtCard.EFG';

const CurrentDebtCard = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <CurrentDebtCardGDC {...props} />;
    case Portal.Efigas:
      return <CurrentDebtCardEFG {...props} />;
    default:
      return <></>;
  }
};

export default CurrentDebtCard;
