import React from 'react';
import { portal, Portal } from '../../../../../../Configs/general';

import PaymentCardGDC from './PaymentCard.GDC';
import PaymentCardGDG from './PaymentCard.GDG';

const PaymentCard = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <PaymentCardGDC {...props} />;
    case Portal.Gasguajira:
      return <PaymentCardGDG {...props} />;
    case Portal.Efigas:
      return <PaymentCardGDG {...props} />;
    case Portal.TripleA:
      return <PaymentCardGDC {...props} />;
    default:
      return <></>;
  }
};

export default PaymentCard;
