import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import Title from '../../Components/Labels/Title';
import SimpleDivider from '../../Components/Dividers/SimpleDivider';
import TextInput from '../../Components/Inputs/TextInput';
import BaseButton from '../../Components/Buttons/BaseButton';

import {
  MIN_DONATION_VALUE,
  MAX_DONATION_VALUE,
  LinkToDonationPDF
} from '../Payment/payment_enums';

const DonationFirst = props => {
  const {
    currentStep,
    setDonation,
    handleGoForward,
    defaultValue = null
  } = props;

  useEffect(() => {
    if (defaultValue) {
      setDonation({ value: defaultValue });
    }
  }, [defaultValue, setDonation]);

  // * STATE HOOKS;
  const [number, setNumber] = useState(defaultValue);
  const [errors, setErrors] = useState(null);

  // * OTHER HOOKS
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!number) {
      props.setDisabledNext(true);
      return;
    }
    if (number < MIN_DONATION_VALUE || number > MAX_DONATION_VALUE) {
      props.setDisabledNext(true);
      return;
    }
    setErrors(null);
    props.setDisabledNext(false);
  }, [number, props]);

  const handleSubmit = useCallback(
    event => {
      event.preventDefault();
      if (number < MIN_DONATION_VALUE || number > MAX_DONATION_VALUE) {
        return;
      }
      handleGoForward();
    },
    [number, handleGoForward]
  );

  const handleFocusOut = useCallback(
    event => {
      event.preventDefault();
      if (number < MIN_DONATION_VALUE) {
        setErrors({ number: 'Monto mínimo $ 1.500' });
        return;
      }
      if (number > MAX_DONATION_VALUE) {
        setErrors({ number: 'Monto máximo 500 millones' });
        return;
      }
    },
    [number]
  );

  return (
    <Fragment>
      <Container maxWidth="md" className={classes.root}>
        <Title text={currentStep.label} />
        <Typography paragraph className={classes.donationTextTitle}>
          ¡Te invitamos a aportar!
        </Typography>
        <Typography paragraph className={classes.donationText}>
          Aquí podrás realizar{' '}
          <span className={classes.donationBold}>aportes voluntarios</span> al
          programa “Comparto Mi Energía”, liderado por el Ministerio de Minas y
          Energía, dirigidos a otorgar un alivio económico al pago de los
          servicios públicos domiciliarios de energía eléctrica y gas
          combustible, según el{' '}
          <span className={classes.linkText} onClick={LinkToDonationPDF}>
            Decreto 517 de 2020
          </span>
          .
        </Typography>
        <Typography className={classes.donationText}>
          Elige entre los siguientes valores o ingresa el monto de tu aporte:
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            direction="row"
            spacing={4}
            className={classes.formContainer}
          >
            <Grid item sm={3} xs={12} className={classes.buttonContainer}>
              <BaseButton
                className={classes.donationButton}
                color="primary"
                variant="outlined"
                onClick={() => setNumber(5000)}
                fullWidth
              >
                $ 5.000
              </BaseButton>
            </Grid>
            <Grid item sm={3} xs={12} className={classes.buttonContainer}>
              <BaseButton
                className={classes.donationButton}
                color="primary"
                variant="outlined"
                onClick={() => setNumber(10000)}
                fullWidth
              >
                $ 10.000
              </BaseButton>
            </Grid>
            <Grid item sm={3} xs={12} className={classes.buttonContainer}>
              <BaseButton
                className={classes.donationButton}
                color="primary"
                variant="outlined"
                onClick={() => setNumber(20000)}
                fullWidth
              >
                $ 20.000
              </BaseButton>
            </Grid>
            <Grid item sm={3} xs={12} className={classes.inputContainer}>
              <NumberFormat
                customInput={TextInput}
                type="tel"
                thousandSeparator="."
                allowNegative={false}
                decimalSeparator={false}
                prefix="$ "
                InputProps={{
                  id: 'DonationFirst_donation_value'
                }}
                fullWidth
                label="Valor de tu aporte"
                value={number}
                className={
                  errors && Boolean(errors.number)
                    ? classes.inputError
                    : classes.input
                }
                onBlur={handleFocusOut}
                error={errors && Boolean(errors.number)}
                helperText={errors && errors.number}
                onValueChange={values => {
                  const { value } = values;
                  if (value) {
                    setNumber(value);
                    setDonation({ value });
                  } else {
                    setNumber(0);
                    setDonation({ value: 0 });
                  }
                }}
                margin="none"
              />
            </Grid>
          </Grid>
        </form>
        <SimpleDivider />
      </Container>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'space-around',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    // * Responsive
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing()
    }
  },
  consultContainer: {
    textAlign: 'end',
    // * Mobile
    [`@media (max-width:${theme.breakpoints.values.sm - 1}px)`]: {
      paddingTop: '0 !important',
      paddingBottom: '0 !important'
    }
  },
  inputContainer: {
    // * Mobile
    [`@media (max-width:${theme.breakpoints.values.sm - 1}px)`]: {
      marginTop: theme.spacing(2),
      paddingTop: '0 !important',
      paddingBottom: '0 !important'
    }
  },
  input: {
    marginBottom: theme.spacing(2)
  },
  inputError: {
    marginBottom: 0
  },
  formContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  donationTextTitle: {
    paddingTop: theme.spacing(),
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
      paddingTop: theme.spacing(2)
    }
  },
  donationText: {
    textAlign: 'justify',
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  donationBold: {
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  linkText: {
    fontWeight: 500,
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.primary.main
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  donationButton: {
    marginBottom: -theme.spacing(),
    fontSize: 16,
    color: theme.palette.primary.alternative,
    borderColor: theme.palette.primary.alternative,
    [theme.breakpoints.up('sm')]: {
      height: 59
    }
  }
}));

export default DonationFirst;
