import React, {
  Fragment,
  useState,
  useCallback,
  useContext,
  useEffect
} from 'react';
import * as yup from 'yup';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import { GetDuplicateInvoicesAPI } from '../../../../API/Coupons/CouponsAPI';
import { extractErrorMessage } from '../../../../Utils/Errors/Errors';

import { AlertsDispatchContext } from '../../../../Contexts/AlertsContext';

import TextInput from '../../../../Components/Inputs/TextInput';
import InvoiceHelpDialog from '../../../../Components/Dialogs/InvoiceHelpDialog/InvoiceHelpDialog';
import QuestionMark from '../../../../Components/Adornments/QuestionMark';

import { SlideUpTransition } from '../../../../Components/Transitions/Transitions';
import BaseDialog from '../../../../Components/Dialogs/BaseDialog';
import BaseButton from '../../../../Components/Buttons/BaseButton';
import { Company } from '../../../../Configs/general';
import { HELP_TEXT } from '../../../../Utils/enums';
import { InvoiceHelpType } from '../../../../Enums/invoices';

const contractQuerySchema = yup.object({
  contractId: yup
    .string()
    .required(
      `Ingresa ${Company.contractConjugation.regular.singular.undefinedArticle}`
    )
});

const ContractStepGDG = props => {
  const { open, title, onClose, setNextModal, step } = props;

  const defaultContract = step.data.contractId || '';

  // * STATE HOOKS
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);

  // * CONTEXT HOOKS
  const setAlert = useContext(AlertsDispatchContext);
  const [loading, setLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [contractId, setContractId] = useState(defaultContract);
  const [errors, setContractErrors] = useState({});
  // * OTHER HOOKS
  const classes = useStyles();

  const onHelpClick = useCallback(() => {
    setHelpDialogOpen(true);
  }, []);

  // * FORM HANDLERS

  const onChangeContract = useCallback(event => {
    setContractId(event.target.value);
    setContractErrors(errs => ({ ...errs, contract: null }));
  }, []);

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();

      if (!canSubmit || loading) {
        return false;
      }
      setLoading(true);
      const response = await GetDuplicateInvoicesAPI({
        contractId
      });
      if (response.success) {
        if (setNextModal) {
          setNextModal({
            type: 'validation',
            data: { contractId, ...response.data.data }
          });
        }
        setLoading(false);
        return;
      }
      const extractError = extractErrorMessage(response);
      if (extractError.key === 'contractId') {
        setContractErrors({ contractId: extractError.message });
      } else {
        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
      }
      setLoading(false);
      return;
    },
    [contractId, canSubmit, loading, setAlert, setNextModal]
  );

  useEffect(() => {
    if (!contractId) {
      setCanSubmit(false);
      return;
    }

    try {
      contractQuerySchema.validateSync({ contractId });
      setContractErrors({});
      setCanSubmit(true);
    } catch (err) {
      setCanSubmit(false);
      setContractErrors({
        [err.path]: err.errors[0]
      });
    }
  }, [contractId]);

  //* COMPONENTS

  const renderActions = useCallback(() => {
    return (
      <Fragment>
        <BaseButton
          onClick={onClose}
          variant="outlined"
          color="primary"
          size="small"
        >
          Cancelar
        </BaseButton>
        <BaseButton
          color="primary"
          size="small"
          disabled={!canSubmit}
          onClick={handleSubmit}
        >
          Consultar
        </BaseButton>
      </Fragment>
    );
  }, [canSubmit, handleSubmit, onClose]);

  const renderContent = useCallback(() => {
    return (
      <div id="ContractStep_div_container" className={classes.content}>
        <InvoiceHelpDialog
          open={helpDialogOpen}
          type={InvoiceHelpType.Contract}
          onClose={() => setHelpDialogOpen(false)}
          title={HELP_TEXT.contract}
        />
        <Typography className={classes.text}>
          Digita el número de{' '}
          {Company.contractConjugation.regular.singular.main} para consultar el
          valor de los cupones de pago que puedes generar:
        </Typography>
        <form
          onSubmit={handleSubmit}
          id="contractQueryForm"
          className={classes.contractForm}
        >
          <TextInput
            id="ContractStep_input_contractId"
            label={`Número de ${Company.contractConjugation.regular.singular.main}`}
            required={true}
            error={Boolean(errors.contractId)}
            helperText={errors.contractId}
            fullWidth
            InputProps={{
              endAdornment: <QuestionMark onClick={onHelpClick} />,
              inputProps: {
                inputMode: 'numeric'
              }
            }}
            value={contractId}
            onChange={onChangeContract}
            className={classes.contractInput}
          />
          <Typography className={classes.textSmall}>
            El costo de generación es totalmente gratuito*
          </Typography>
        </form>
      </div>
    );
  }, [
    contractId,
    onChangeContract,
    handleSubmit,
    errors,
    onHelpClick,
    helpDialogOpen,
    classes
  ]);

  // * RENDER
  const isMobileSize = isWidthDown('xs', props.width);
  return (
    <BaseDialog
      open={open}
      loading={loading}
      handleClose={onClose}
      title={title}
      actions={renderActions}
      content={renderContent}
      fullScreen={isMobileSize}
      contentSize="small"
      TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
    />
  );
};

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  text: {
    fontSize: 14,
    color: theme.palette.text.primary,
    width: '100%'
  },
  contractForm: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  contractInput: {
    width: 250,
    marginTop: theme.spacing(4)
  },
  textSmall: {
    fontSize: 10,
    color: theme.palette.text.blackLight,
    marginTop: theme.spacing()
  }
}));

export default withWidth()(ContractStepGDG);
