export function identificationTypeName(type) {
  switch (type) {
    case 'CC':
      return 'Cédula';
    case 'CE':
      return 'Cédula de extranjería';
    default:
      return 'Desconocido';
  }
}
