/* eslint-disable linebreak-style */
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SvgHeartBlockyEmpty from '../../../../Components/CustomIcons/HeartBlockyEmpty';

import LinkButton from '../../../../Components/Buttons/LinkButton';
import { ROUTE_NAMES } from '../../../../Routes/Routes';
import { MENU } from '../../../Information/Information/Information';

const WebFooterGDG = props => {
  const classes = useStyles();
  const { classes: propClasses = {} } = props;

  return (
    <Toolbar className={clsx(classes.footbar, propClasses.root)}>
      <div className={classes.company}>
        <Typography variant="h6">
          Hecho con{' '}
          <SvgHeartBlockyEmpty width="16" height="16" fill="#104eb2" />
          &nbsp;por Innovación
        </Typography>
      </div>
      <div className={classes.actions}>
        <div className={classes.buttonContainer}>
          <LinkButton
            id="WebFooter_a_terms"
            to={{
              pathname: ROUTE_NAMES.information,
              aboutProps: {
                subject: MENU.terms
              }
            }}
          >
            Términos y condiciones
          </LinkButton>
        </div>
      </div>
    </Toolbar>
  );
};

const useStyles = makeStyles(theme => ({
  footbar: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    bottom: 0,
    boxShadow: '0px 2px 10px -1px rgba(0,0,0,0.2)',
    backgroundColor: '#FFFFFF'
  },
  company: {
    flex: 1,
    color: theme.palette.color.default,
    '@media (max-width:600px)': {
      display: 'none'
    },
    [theme.breakpoints.up('lg')]: {
      flex: 2
    }
  },
  companyText: {
    display: 'inline',
    fontSize: 12
  },
  actions: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row-reverse',
    '@media (max-width:600px)': {
      flex: 1,
      justifyContent: 'space-around'
    }
  },
  buttonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // * Responsive
    [theme.breakpoints.up('sm')]: {
      maxWidth: 210
    }
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 2
  },
  mobileHide: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  mapIcon: {
    marginRight: theme.spacing()
  }
}));

export default WebFooterGDG;
