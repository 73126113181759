import React from 'react';
import { portal, Portal } from '../../../Configs/general';
import LoginContentGDC from './LoginContent.GDC';
import LoginContentEFG from './LoginContent.EFG';

const LoginContent = props => {
  switch (portal) {
    case Portal.Gascaribe:
    case Portal.TripleA:
      return <LoginContentGDC {...props} />;
    case Portal.Efigas:
    case Portal.Gasguajira:
      return <LoginContentEFG {...props} />;
    default:
      return <></>;
  }
};

export default LoginContent;
