import React from 'react';
import { Card, Button, Grid, Typography, makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import SimpleDivider from '../../../Components/Dividers/SimpleDivider';
import EyeIcon from '../../../Components/CustomIcons/EyeIcon';

import { numberWithDots } from '../../../Utils/Format/MoneyFormat';
import clsx from 'clsx';

const ConceptsSummaryCard = props => {
  const {
    onDetailsClick,
    title = 'Resumen de conceptos',
    action = 'financiar o pagar',
    itemsCount = 0,
    totalAmount = 0
  } = props;

  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card className={classes.root} onClick={onDetailsClick}>
      <Grid container direction="column">
        <Grid item container justify="space-between" alignItems="center">
          <Grid item>
            <Typography className={classes.cardTitle}>{title}</Typography>
          </Grid>
          <Grid item>
            <div className={classes.itemCountContainer}>
              <Typography className={clsx(classes.alignRight)}>
                {itemsCount} ítems
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid item container>
          <Typography>
            Clic aquí para conocer el detalle de los productos y conceptos que
            se van a {action}.
          </Typography>
        </Grid>
        <Grid item container>
          <SimpleDivider classes={classes.divider} />
        </Grid>
        <Grid item container justify="space-between" alignItems="center">
          <Grid item xs={6}>
            <Button
              className={classes.seeDetailButton}
              color="primary"
              size="small"
              variant="text"
              onClick={onDetailsClick}
            >
              Ver detalle
              <EyeIcon
                size={16}
                className={classes.eyeIcon}
                fill={theme.palette.primary.main}
              />
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Typography className={clsx(classes.label, classes.alignRight)}>
              Saldo total
            </Typography>
            <Typography className={clsx(classes.data, classes.alignRight)}>
              {numberWithDots(totalAmount)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: '0px 0px 6px #0000001A',
    padding: theme.spacing(2)
  },
  cardTitle: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.text.primary
  },
  label: {
    fontSize: 11,
    lineHeight: 1.2,
    color: theme.palette.text.greyDark
  },
  data: {
    fontSize: 16,
    fontWeight: 600,
    color: theme.palette.text.primary
  },
  alignRight: {
    textAlign: 'right'
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.divider,
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  itemCountContainer: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.main,
    padding: theme.spacing(0.5),
    borderRadius: 3
  },
  seeDetailButton: {
    textTransform: 'none',
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.main
  },
  eyeIcon: {
    marginLeft: theme.spacing(1)
  }
}));

export default ConceptsSummaryCard;
