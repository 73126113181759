import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  CurrentStepIndexContext,
  SetCurrentStepIndexContext,
  StepperDataContext,
  StepperDataDispatchContext
} from '../../../Contexts/StepperContext';
import {
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { ALERT_TYPE } from '../../../Components/Alerts/alert_enums';
import { history } from '../../../Routes/history';
import Title from '../../../Components/Labels/Title';
import {
  CreateSubrogationRequestAPI,
  GetSignDocumentURL,
  GetSignatureStatus
} from '../../../API/SubrogationRequest/SubrogationRequestAPI';
import { extractErrorMessage } from '../../../Utils/Errors/Errors';
import ModalProgress from '../../../Components/Progress/Modal/ModalProgress';
import { ROUTE_NAMES } from '../../../Routes/Routes';
import { toast } from 'react-toastify';
import ValidCheckIcon from '@material-ui/icons/CheckCircleOutlined';
import clsx from 'clsx';

const SignDocumentStep = props => {
  const {
    formId,
    setAlert,
    setGoBack,
    setCanSubmit,
    setNextButtonText,
    setCurrentStep: setVisualStepperIndex,
    authToken,
    setWarningMobile
  } = props;
  const classes = useStyles();

  const TOAST_ID = 'subrogation-help-toast';

  const stepperData = useContext(StepperDataContext);
  const currentStepIndex = useContext(CurrentStepIndexContext);
  const setCurrentStep = useContext(SetCurrentStepIndexContext);
  const setData = useContext(StepperDataDispatchContext);

  const [signDocumentURL, setSignDocumentURL] = useState('');
  const [documents, setDocuments] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSigned, setIsSigned] = useState(false);
  const [isFirst, setIsFirst] = useState(false);

  const theme = useTheme();
  const isMobileSize = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  const onSuccessDocuments = useCallback(
    async event => {
      event.preventDefault();
      setLoading(true);

      const {
        contractData,
        firstName,
        lastName,
        aniData,
        email,
        phone,
        phoneCountryCode,
        identification,
        identificationType,
        expeditionDate
      } = stepperData;

      const requestObject = {
        contractData,
        firstName,
        lastName,
        aniData,
        email,
        phone,
        phoneCountryCode,
        identification,
        identificationType,
        expeditionDate,
        signData: documents
      };
      const response = await CreateSubrogationRequestAPI(
        authToken,
        requestObject
      );

      if (response.success) {
        setAlert({
          type: ALERT_TYPE.SUCCESS,
          message: `Solicitud No. ${response.data.requestId} creada exitosamente`
        });
        setLoading(false);
        history.replace(`${ROUTE_NAMES.requests}/${response.data.requestId}`);
        return;
      }

      setAlert({
        type: ALERT_TYPE.ERROR,
        message: extractErrorMessage(response).message
      });
      setLoading(false);
    },
    [setAlert, stepperData, authToken, documents]
  );

  const onBackward = useCallback(() => {
    setCurrentStep(currentStep => currentStep - 1);
  }, [setCurrentStep]);

  const handleClose = useCallback(() => {
    toast.dismiss(TOAST_ID);
  }, []);

  const alertMessage = useCallback(() => {
    const toastMsg = () => (
      <Typography>
        Para completar tu solicitud de subrogación, es necesario que des clic en
        el botón <strong>Finalizar</strong>.
      </Typography>
    );

    toast.warn(toastMsg, {
      toastId: TOAST_ID,
      onClose: handleClose,
      closeOnClick: false,
      autoClose: false,
      onOpen: true,
      icon: false,
      theme: 'light',
      bodyStyle: { color: '#333333' }
    });
  }, [handleClose]);

  const getStatus = useCallback(
    async (load = true) => {
      setLoading(load);
      const response = await GetSignatureStatus(
        authToken,
        stepperData.documents.clientProcessId
      );

      if (response.success) {
        if (response.data.signed) {
          setIsSigned(true);
        }
        setSignDocumentURL(stepperData.signDocumentURL);
        setDocuments(stepperData.documents);
        setLoading(false);
        return;
      }

      setAlert({
        type: ALERT_TYPE.ERROR,
        message: extractErrorMessage(response).message
      });
      setLoading(false);
    },
    [setDocuments, authToken, setAlert, stepperData]
  );

  useEffect(() => {
    setVisualStepperIndex(currentStepIndex);
    setNextButtonText('Finalizar');
    setCanSubmit(Boolean(stepperData.signDocumentURL));
    setGoBack({
      action: onBackward
    });
  }, [
    setGoBack,
    onBackward,
    setVisualStepperIndex,
    currentStepIndex,
    setNextButtonText,
    setCanSubmit,
    setWarningMobile,
    isMobileSize,
    stepperData.signDocumentURL
  ]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const {
        contractData,
        firstName,
        lastName,
        aniData,
        email,
        phone,
        phoneCountryCode,
        identification,
        identificationType,
        expeditionDate
      } = stepperData;

      const requestObject = {
        contractData,
        firstName,
        lastName,
        aniData,
        email,
        phone,
        phoneCountryCode,
        identification,
        identificationType,
        expeditionDate
      };

      const response = await GetSignDocumentURL(authToken, requestObject);

      if (response.success) {
        setData(d => ({
          ...d,
          signDocumentURL: response.data.requestUrl,
          documents: response.data
        }));
        setSignDocumentURL(response.data.requestUrl);
        setDocuments(response.data);
        setCanSubmit(true);
        setLoading(false);
        return;
      }

      setAlert({
        type: ALERT_TYPE.ERROR,
        message: extractErrorMessage(response).message
      });
      setLoading(false);
    };

    if (!stepperData.signDocumentURL && !isFirst) {
      fetchData();
      setIsFirst(true);
    }
  }, [
    authToken,
    stepperData,
    setAlert,
    setDocuments,
    setCanSubmit,
    setData,
    isFirst
  ]);

  useEffect(() => {
    if (stepperData.signDocumentURL && !isFirst) {
      getStatus();
    }
  }, [stepperData, isFirst, getStatus]);

  useEffect(() => {
    if (stepperData.signDocumentURL && !isSigned) {
      const timer = setInterval(() => {
        getStatus(false);
      }, 10000);
      return () => clearInterval(timer);
    }
  }, [isSigned, getStatus, stepperData.signDocumentURL]);

  useEffect(() => {
    setWarningMobile(isMobileSize);
    if (isMobileSize) {
      toast.dismiss(TOAST_ID);
    }
  }, [setWarningMobile, isMobileSize]);

  useEffect(() => {
    return () => {
      toast.dismiss(TOAST_ID);
    };
  }, []);

  return (
    <>
      {!isMobileSize && alertMessage()}
      {loading && (
        <ModalProgress
          id={'SubrogationRequest_progress_loading'}
          message={'Cargando...'}
        />
      )}
      <Title title="Firma de documentos" className={classes.title} />
      <div className={classes.contentContainer}>
        <div className={classes.root}>
          <form id={formId} onSubmit={onSuccessDocuments}>
            {isSigned ? (
              <div className={classes.card}>
                <Grid container alignItems="center" wrap="nowrap">
                  <ValidCheckIcon
                    className={clsx(classes.statusIcon, classes.validIcon)}
                  />
                  <Typography className={classes.data}>
                    Los documentos ya fueron firmados correctamente, por favor
                    finaliza tu solicitud de subrogación.
                  </Typography>
                </Grid>
              </div>
            ) : (
              <div className={classes.contentDocument}>
                {signDocumentURL && (
                  <iframe
                    title="olimpia"
                    src={signDocumentURL}
                    className={classes.contentDocument}
                  ></iframe>
                )}
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    '&:last-child': {
      marginBottom: theme.spacing(2)
    }
  },
  contentContainer: {
    margin: '0 auto'
  },
  contentDocument: {
    width: '100%',
    height: 'calc(100vh - 200px)'
  },
  title: {
    margin: theme.spacing(2, 0, 1)
  },
  statusIcon: { marginRight: theme.spacing(1) },
  validIcon: {
    fill: theme.palette.success.main
  },
  card: {
    borderRadius: theme.custom.borderRadius,
    backgroundColor: theme.palette.background.cardDark,
    border: `1px solid ${theme.palette.background.border}`,
    position: 'relative',
    padding: theme.spacing(3),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: theme.spacing(2)
    }
  }
}));

export default SignDocumentStep;
