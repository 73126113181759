import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import ExpandIcon from '@material-ui/icons/Add';
import CollapseIcon from '@material-ui/icons/Remove';
import TermsIcon from '../../../Components/CustomIcons/TermsIcon';
import HelpIcon from '../../../Components/CustomIcons/HelpIcon';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import Help from '../Help/Help';
import _get from 'lodash/get';

import { TERMS, MENU, QUESTIONS, getTermsByRoute } from './Information';

const InformationGDC = props => {
  const subject = _get(props, 'location.aboutProps.subject');
  const selectedPanel = getTermsByRoute(props.match.params.panel) || null;

  // * STATE HOOKS
  const [selected, setSelected] = useState(MENU.terms);
  const [expanded, setExpanded] = useState(selectedPanel);

  // * OTHER HOOKS
  const classes = useStyles();

  // * FUNCTIONS
  const handleChange = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleSelected = option => {
    setSelected(option);
    option === MENU.terms
      ? setExpanded(TERMS.introduction)
      : setExpanded(QUESTIONS.first);
  };

  const displayIcon = panel => {
    return expanded === panel ? (
      <CollapseIcon className={classes.icon} />
    ) : (
      <ExpandIcon className={classes.icon} />
    );
  };

  const date = '22/04/2022';

  useEffect(() => {
    if (!subject) {
      return;
    }
    handleSelected(subject);
  }, [subject]);

  return (
    <div className={classes.root}>
      <div className={classes.banner}>
        <div className={clsx(classes.container, classes.bannerContainer)}>
          {selected === MENU.terms ? (
            <div className={classes.termsIconContainer}>
              <TermsIcon size={310} fill="#EFEFEF" />
            </div>
          ) : (
            <div className={classes.helpIconContainer}>
              <HelpIcon size={340} fill="#EFEFEF" />
            </div>
          )}
          <div className={clsx(classes.content, classes.bannerContent)}>
            <Typography className={classes.termsLastUpdatedAt}>
              Fecha de versión: {date}
            </Typography>
            <Typography variant="h1" className={classes.pageTitle}>
              {selected}
            </Typography>
          </div>
        </div>
      </div>
      <div>
        <div className={classes.container}>
          <div className={classes.sidebar}>
            <ul className={classes.list}>
              <ol
                className={clsx(classes.listItem, {
                  [classes.listItemActive]: selected === MENU.terms
                })}
                onClick={() => handleSelected(MENU.terms)}
              >
                Términos y condiciones
              </ol>
            </ul>
          </div>
          <div className={classes.content}>
            {selected === MENU.terms ? (
              <TermsAndConditions
                initial={selectedPanel}
                expanded={expanded}
                handleChange={handleChange}
                displayIcon={displayIcon}
                classes={classes}
                terms={TERMS}
              />
            ) : (
              <Help
                expanded={expanded}
                handleChange={handleChange}
                displayIcon={displayIcon}
                classes={classes}
                terms={QUESTIONS}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'auto'
  },
  pageTitle: {
    fontSize: '1.25em',
    fontWeight: 600,
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      fontSize: '2.1875em'
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(0)
    }
  },
  termsLastUpdatedAt: {
    fontWeight: 500,
    fontSize: '14px',
    alignSelf: 'flex-end',
    order: 3,
    [theme.breakpoints.up('md')]: {
      order: 0,
      marginBottom: theme.spacing(2)
    }
  },
  termsAppName: {
    fontWeight: 400,
    fontSize: '1em',
    marginBottom: theme.spacing(),
    [theme.breakpoints.up('md')]: {
      fontSize: '0.875em'
    }
  },
  container: {
    padding: [[0, theme.spacing(2.5)]],
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    },
    [theme.breakpoints.up('lg')]: {
      width: 1280,
      margin: [[0, 'auto']]
    }
  },
  sidebar: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      flex: 1,
      paddingLeft: theme.spacing(9.25)
    }
  },
  content: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      flex: 3
    }
  },
  termsIconContainer: {
    position: 'absolute',
    top: 56,
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      width: '29.5vw',
      height: 229
    },
    [theme.breakpoints.up('lg')]: {
      width: 362
    }
  },
  helpIconContainer: {
    position: 'absolute',
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      width: '29.5vw',
      height: 229
    },
    [theme.breakpoints.up('lg')]: {
      width: 362
    }
  },
  banner: {
    backgroundColor: '#F6F6F6',
    height: 229,
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3.25),
    overflow: 'hidden',
    position: 'relative'
  },
  expansionPanel: {
    boxShadow: 'none',
    borderBottom: '1px solid #e7e7e7',
    '&:last-child': {
      borderBottom: 0
    },
    [theme.breakpoints.up('sm')]: {
      'scroll-margin-top': `${theme.custom.headerHeight}px`
    }
  },
  expansionPanelExpanded: {
    margin: [0, '!important']
  },
  expansionPanelSummary: {
    minHeight: 64,
    padding: [[0, theme.spacing(0.5)]]
  },
  expansionPanelSummaryExpanded: {
    margin: [0, '!important']
  },
  expansionPanelDetails: {
    padding: [[0, theme.spacing(0.5), theme.spacing(3)]]
  },
  title: {
    color: '#333333',
    fontSize: '1.125em',
    fontWeight: 500,
    lineHeight: 1.2,
    [theme.breakpoints.up('md')]: {
      fontSize: '1.25em'
    }
  },
  description: {
    fontSize: '0.875em',
    lineHeight: 1.27,
    color: '#8a8a8a',
    [theme.breakpoints.up('md')]: {
      fontSize: '1em'
    }
  },
  message: {
    marginBottom: theme.spacing(2.5)
  },
  icon: {
    color: '#104eb2',
    display: 'inline-block',
    marginRight: theme.spacing(1.5),
    fontSize: '1.125em',
    [theme.breakpoints.up('md')]: {
      fontSize: '1.5em',
      marginRight: theme.spacing(3)
    }
  },
  list: {
    margin: 0,
    padding: 0,
    marginTop: theme.spacing()
  },
  listItem: {
    margin: 0,
    padding: 0,
    fontWeight: 500,
    fontSize: '1.1em',
    lineHeight: 1.29,
    marginBottom: theme.spacing(2.5),
    marginRight: theme.spacing(4),
    cursor: 'pointer',
    '&:last-child': {
      marginBottom: 0
    }
  },
  titleActive: {
    color: '#104eb2'
  },
  listItemActive: {
    color: '#104eb2'
  },
  bannerContainer: {
    width: 1280
    // alignItems: 'center'
  },
  bannerContent: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(5)
  },
  image: {
    width: '100%'
  }
}));

export default InformationGDC;
