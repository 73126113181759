import axios from 'axios';
import { config } from '../../Configs';

export const GetCouponAPI = async (
  couponId,
  captchaToken,
  axiosSource = null
) => {
  try {
    const configObj = {
      params: { 'g-recaptcha-response': captchaToken }
    };

    if (axiosSource !== null) {
      configObj.cancelToken = axiosSource.token;
    }

    const response = await axios.get(
      `${config.api.url}/coupons/${couponId}`,
      configObj
    );

    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err, isCancel: axios.isCancel(err) };
  }
};

export const GenerateDuplicateCouponAPI = async (
  contractId,
  period,
  authToken = null,
  origin
) => {
  try {
    const configObj = {};
    if (authToken !== null) {
      configObj.headers = { Authorization: authToken };
    }
    const response = await axios.post(
      `${config.api.url}/contracts/duplicate/${contractId}`,
      {
        period,
        origin
      },
      configObj
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GetDuplicateCouponPDFAPI = async (couponId, period) => {
  try {
    const configObj = { params: { period } };
    const response = await axios.get(
      `${config.api.url}/contracts/duplicate/pdf/${couponId}`,
      configObj
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GetDuplicateInvoicesAPI = async params => {
  try {
    const response = await axios.get(
      `${config.api.url}/contracts/duplicate/${params.contractId}`
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const GetCouponPDFAPI = async couponId => {
  try {
    const response = await axios.get(
      `${config.api.url}/coupons/pdf/${couponId}`
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};
