import React, { useState } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Banner from './Banner';
import FirstStep from '../Steps/FirstStep';
import SecondStep from '../Steps/SecondStep';
import ThirdStep from '../Steps/ThirdStep';
import FinalView from '../Steps/FinalStep/FinalView';
import { StepperProvider, Step } from '../../../Contexts/StepperContext';
import FooterStepper from '../Footer/FooterStepper';

import { FORM_ID, FORM_STATE } from '../fixes/ganaLoco_enums';

const GanaLocoForm = props => {
  const classes = useStyles();

  const { setTopBarProgress, formState, setFormState, isMobileSize } = props;
  const [nextButtonText, setNextButtonText] = useState('Continuar');
  const [canSubmit, setCanSubmit] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [goBack, setGoBack] = useState({});
  const [userParticipationId, setUserParticipationId] = useState(null);

  return (
    <div className={classes.root}>
      <Paper
        elevation={isMobileSize ? 0 : 3}
        className={clsx(classes.paperContainer, {
          [classes.paperContainerBigMargin]: !isMobileSize && currentStep === 3
        })}
      >
        {currentStep < 4 && (
          <Banner currentStep={currentStep} isMobileSize={isMobileSize} />
        )}
        {formState === FORM_STATE.AWAITING && (
          <Box className={classes.warningMessageContainer}>
            <Typography
              variant="body2"
              component="p"
              className={classes.warningText}
            >
              Campos obligatorios*
            </Typography>
          </Box>
        )}
        <StepperProvider>
          <Step>
            <FirstStep
              formId={FORM_ID}
              setGoBack={setGoBack}
              setNextButtonText={setNextButtonText}
              canSubmit={canSubmit}
              setCanSubmit={setCanSubmit}
              setCurrentStep={setCurrentStep}
            />
          </Step>
          <Step>
            <SecondStep
              formId={FORM_ID}
              setGoBack={setGoBack}
              setNextButtonText={setNextButtonText}
              setTopBarProgress={setTopBarProgress}
              setCanSubmit={setCanSubmit}
              setCurrentStep={setCurrentStep}
            />
          </Step>
          <Step>
            <ThirdStep
              formId={FORM_ID}
              setGoBack={setGoBack}
              setNextButtonText={setNextButtonText}
              setTopBarProgress={setTopBarProgress}
              setFormState={setFormState}
              setCurrentStep={setCurrentStep}
              setUserParticipationId={setUserParticipationId}
            />
          </Step>
          <Step>
            <FinalView formState={formState} userId={userParticipationId} />
          </Step>
        </StepperProvider>
        {formState === FORM_STATE.AWAITING && (
          <FooterStepper
            targetForm={FORM_ID}
            canSubmit={canSubmit}
            goBack={goBack}
            nextButtonText={nextButtonText}
            isMobileSize={isMobileSize}
          />
        )}
      </Paper>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 'auto'),
    maxWidth: theme.spacing(72)
  },
  paperContainer: {
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(4, 0, 4, 0),
      borderRadius: theme.spacing(2),
      boxShadow: '0px 0px 16px #0000001F'
    }
  },
  paperContainerBigMargin: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(20.875)
    }
  },
  warningMessageContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 25,
    marginBottom: 22,
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(4),
      marginBottom: theme.spacing(3.5)
    }
  },
  warningText: {
    color: theme.palette.color.default,
    fontSize: 11
  }
}));

export default GanaLocoForm;
