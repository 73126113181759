import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useMemo,
  useContext
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SvgConfirmEmail from '../../Components/CustomIcons/ConfirmEmailIcon';
import { Typography } from '@material-ui/core';
import BaseButton from '../../Components/Buttons/BaseButton';
import { ForgotPasswordAPI } from '../../API/UserAPI';
import { UserContext } from '../../Contexts/UserContext';
import { AlertsDispatchContext } from '../../Contexts/AlertsContext';

const RESEND_FLAG_SECONDS_DELAY = 120;

const ForgotPasswordEmail = ({ history, location }) => {
  // * OTHER HOOKS
  const classes = useStyles();

  const currentUser = useContext(UserContext);
  const setAlert = useContext(AlertsDispatchContext);

  const [counter, setCounter] = useState(RESEND_FLAG_SECONDS_DELAY);
  const [canResend, setCanResend] = useState(false);
  const [timer, setTimer] = useState(null);

  const resetResendFlag = useCallback(() => {
    const countdown = c => c - 1;
    const interval = setInterval(() => {
      setCounter(countdown);
    }, 1000);
    setTimer(interval);
  }, []);

  useEffect(() => {
    if (currentUser) {
      history.replace('/');
    }
  }, [currentUser, history]);

  useEffect(() => {
    return () => clearInterval(timer);
  }, [timer]);

  useEffect(() => {
    if (counter <= 0) {
      clearInterval(timer);
      setTimer(null);
      setCanResend(true);
    }
  }, [counter, timer]);

  useEffect(() => {
    if (!location.state) {
      history.replace('/');
      return;
    }
    resetResendFlag();
  }, [history, location, resetResendFlag]);

  const handleClick = useCallback(async () => {
    const success = await ForgotPasswordAPI(location.state.username);
    setCounter(RESEND_FLAG_SECONDS_DELAY);
    if (!success) {
      setAlert({
        type: 'error',
        message: 'Ocurrió un error, intenta de nuevo'
      });
      setCanResend(true);
      return;
    }

    setCanResend(false);
    resetResendFlag();
  }, [location, resetResendFlag, setAlert]);

  const buttonChildren = useMemo(() => {
    if (counter > 0) {
      const minutes = Math.floor(counter / 60);
      let seconds = counter - minutes * 60;
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      return `${minutes}:${seconds}`;
    }
    return 'Reenviar correo';
  }, [counter]);

  if (currentUser || !location.state) {
    return <Fragment></Fragment>;
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <SvgConfirmEmail height={250} width={400} className={classes.icon} />
        <Typography className={classes.item} variant="subtitle1">
          <strong>Revisa tu correo electrónico</strong>
        </Typography>
        <Typography className={classes.item} variant="subtitle1">
          Hemos enviado un enlace a{' '}
          <span className={classes.email}>{location.state.username}</span> para
          restablecer tu contraseña
        </Typography>
        <BaseButton
          className={classes.button}
          disabled={!canResend || counter > 0}
          onClick={handleClick}
        >
          {buttonChildren}
        </BaseButton>
        {!canResend && (
          <Typography className={classes.item} variant="caption">
            Si el correo no te ha llegado en 2 minutos, puedes volver a enviar
            el enlace
          </Typography>
        )}
      </div>
    </React.Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0)
    }
  },
  icon: {
    marginBottom: theme.spacing(),
    transform: 'scale(0.75)',
    [theme.breakpoints.up('sm')]: {
      transform: 'scale(1)',
      marginBottom: theme.spacing(2)
    }
  },
  item: {
    margin: '0.2em 0'
  },
  button: {
    margin: '0.8em 0',
    minWidth: 200
  },
  email: {
    fontWeight: 500,
    fontStyle: 'italic'
  }
}));

export default ForgotPasswordEmail;
