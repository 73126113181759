import React from 'react';
import { portal, Portal } from '../../../../Configs/general';

import ContractItemGDC from './ContractItem.GDC';
import ContractItemGDG from './ContractItem.GDG';
import ContractItemEFG from './ContractItem.EFG';

const ContractItem = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <ContractItemGDC {...props} />;
    case Portal.TripleA:
    case Portal.Gasguajira:
      return <ContractItemGDG {...props} />;
    case Portal.Efigas:
      return <ContractItemEFG {...props} />;
    default:
      return <></>;
  }
};

export default ContractItem;
