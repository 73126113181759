import React from 'react';
import { portal, Portal } from '../../../../Configs/general';
import { isPrintableAscii } from '../../../../Utils/Misc/String';
import * as yup from 'yup';

import PseInfoFormGDG from './PseInfoForm.GDG';
import PseInfoFormGDC from './PseInfoForm.GDC';
import PseInfoFormEFG from './PseInfoForm.EFG';
import { DEFAULT_BANK_VALUE } from '../../payment_enums';
import _get from 'lodash/get';
import { fullName } from '../../../../Utils/Format/Names';

export const pseInfoSchema = yup.object({
  email: yup
    .string()
    .trim()
    .lowercase()
    .email('Debes ingresar un correo válido')
    .test(
      'is-ascii',
      'Este campo no puede contener tildes ni caracteres especiales',
      isPrintableAscii
    )
    .nullable()
    .required('Debes ingresar un correo'),
  payerFullName: yup
    .string()
    .trim()
    .nullable()
    .required('Debes ingresar un nombre'),
  typeOfIdentification: yup
    .string()
    .trim()
    .nullable()
    .required('Debes seleccionar una opción válida'),
  identification: yup
    .string()
    .trim()
    .nullable()
    .required('Debes ingresar una identificación válida'),
  phone: yup
    .string()
    .trim()
    .length(10, 'Debe tener 10 dígitos')
    .nullable()
    .required('Debes ingresar un teléfono válido'),
  phoneCountryCode: yup
    .string()
    .trim()
    .max(3, 'Debe tener mínimo 3 dígitos')
    .nullable()
    .required('Debes ingresar un prefijo válido'),
  currentBank: yup
    .string()
    .notOneOf([DEFAULT_BANK_VALUE], 'Debes seleccionar un banco')
    .required('Debes seleccionar un banco válido'),
  typeOfPerson: yup
    .string()
    .trim()
    .nullable()
    .required('Debes seleccionar una opción válida')
});

export const getFormDefaultValues = currentUser => ({
  email: _get(currentUser, 'email') || '',
  payerFullName: currentUser
    ? `${fullName(currentUser, 'firstName', 'lastName')}`
    : '',
  typeOfIdentification: _get(currentUser, 'identificationType') || 'CC',
  identification: _get(currentUser, 'identification') || '',
  phone: _get(currentUser, 'phone') || '',
  phoneCountryCode: _get(currentUser, 'phoneCountryCode') || '57',
  typeOfPerson: 'N',
  currentBank: _get(currentUser, 'lastSelectedBank') || DEFAULT_BANK_VALUE
});

export const mapBanksList = list => {
  return list.map(bank => {
    if (bank.pseCode === DEFAULT_BANK_VALUE) {
      return {
        label: 'Selecciona tu banco',
        value: bank.pseCode
      };
    }
    return {
      label: bank.description,
      value: bank.pseCode
    };
  });
};

export const findBankName = (banks, code) => {
  const currentBank = banks.find(bank => bank.value === code);
  if (currentBank) {
    return currentBank.label;
  }
  return 'Selecciona tu banco';
};

const PseInfoForm = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <PseInfoFormGDC {...props} />;
    case Portal.Gasguajira:
      return <PseInfoFormGDG {...props} />;
    case Portal.Efigas:
      return <PseInfoFormEFG {...props} />;
    case Portal.TripleA:
      return <PseInfoFormGDC {...props} />;
    default:
      return <></>;
  }
};

export default PseInfoForm;
