import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import PencilIcon from '../../Components/CustomIcons/IcPencil';

const EditableField = ({ label, value, handleEditClick, editable, error }) => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.label}>{label}</Typography>
      <div className={classes.valueContainer}>
        <Typography className={classes.value}>{value}</Typography>
        {editable && (
          <IconButton
            aria-label="editar"
            className={classes.icon}
            onClick={handleEditClick && handleEditClick}
          >
            <PencilIcon size={16} />
          </IconButton>
        )}
      </div>
      {error && <span className={classes.error}>{error}</span>}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 12,
    color: '#747B87',
    fontWeight: theme.typography.fontWeightMedium
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  marginAdjustment: {
    marginTop: -5
  },
  value: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 14
  },
  icon: {
    marginLeft: theme.spacing(0.5),
    padding: theme.spacing()
  },
  error: {
    color: theme.palette.error.main,
    fontSize: 12,
    fontWeight: theme.typography.fontWeightMedium
  }
}));

export default EditableField;
