import React, { useCallback, useContext, useEffect, useState } from 'react';
import Title from '../../../Components/Labels/Title';
import {
  Grid,
  InputAdornment,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import TextInput from '../../../Components/Inputs/TextInput';
import clsx from 'clsx';
import {
  CurrentStepIndexContext,
  SetCurrentStepIndexContext,
  StepperDataContext,
  StepperDataDispatchContext
} from '../../../Contexts/StepperContext';
import * as yup from 'yup';
import InvoiceHelpDialog from '../../../Components/Dialogs/InvoiceHelpDialog/InvoiceHelpDialog';
import QuestionMark from '../../../Components/Adornments/QuestionMark';
import BaseButton from '../../../Components/Buttons/BaseButton';
import Recaptcha from '../../../Components/Captcha/Recaptcha';
import { useRef } from 'react';
import { config } from '../../../Configs';
import ContractStatusCard from '../../../Components/Cards/ContractInsuranceStatusCard';
import CircleProgress from '../../../Components/Progress/Circle/CircleProgress';
import { VerifyContractAPI } from '../../../API/SubrogationRequest/SubrogationRequestAPI';
import { extractErrorMessage } from '../../../Utils/Errors/Errors';
import ModalProgress from '../../../Components/Progress/Modal/ModalProgress';
import { Company } from '../../../Configs/general';
import { HELP_TEXT } from '../../../Utils/enums';
import { InvoiceHelpType } from '../../../Enums/invoices';

const schema = yup.object({
  contractType: yup.number(),
  contractId: yup.string().required('Ingrese el número de contrato')
});

const ContractStep = props => {
  const {
    formId,
    setCanSubmit,
    setCurrentStep: setVisualStepperIndex,
    setNextButtonText,
    setGoBack,
    authToken
  } = props;
  const classes = useStyles();

  const setCurrentStep = useContext(SetCurrentStepIndexContext);
  const currentStepIndex = useContext(CurrentStepIndexContext);
  const setData = useContext(StepperDataDispatchContext);
  const stepperData = useContext(StepperDataContext);

  const [invalidContractReasons, setInvalidContractReasons] = useState([]);
  const [contractToValidate, setContractToValidate] = useState(null);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false);
  const [loadingCaptcha, setLoadingCaptcha] = useState(true);
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const [infoContractMessage, setInfoContractMessage] = useState(null);

  const theme = useTheme();
  const isMobileSize = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  const recaptchaRef = useRef(null);
  const inputRef = useRef(null);

  const { register, errors, watch, handleSubmit } = useForm({
    validationSchema: schema,
    submitFocusError: true,
    defaultValues: {
      contractId: stepperData.contractId ? stepperData.contractId : null,
      contractType: stepperData.contractType ? stepperData.contractType : 0,
      ...stepperData
    }
  });

  const watchForm = watch(['contractId']);

  const resetData = useCallback(() => {
    setContractToValidate(null);
    setInvalidContractReasons([]);
    setCanSubmit(false);
  }, [setCanSubmit]);

  const contractValidation = useCallback(async () => {
    if (watchForm.contractId) {
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
        setCaptcha(null);
      }
      setLoading(true);
      const response = await VerifyContractAPI(authToken, watchForm.contractId);
      resetData();
      if (response.success) {
        const { valid } = response.data;
        setContractToValidate(response.data);
        setInfoContractMessage(null);
        setCanSubmit(valid);
        setLoading(false);
        return;
      }

      setContractToValidate({ contractData: null, valid: false });
      setInfoContractMessage(extractErrorMessage(response).message);
      setLoading(false);
    }
  }, [watchForm.contractId, authToken, resetData, setCanSubmit]);

  const onSuccessContract = useCallback(
    formValues => {
      setData(d => ({
        ...d,
        contractData: {
          ...contractToValidate.contractData,
          contractId: formValues.contractId
        },
        signDocumentURL: null,
        documents: null
      }));

      setCurrentStep(step => step + 1);
    },
    [setCurrentStep, setData, contractToValidate]
  );

  const onHelpClick = useCallback(() => {
    setHelpDialogOpen(true);
  }, []);

  const onCaptchaResolved = useCallback(
    token => {
      setCaptcha(token);
    },
    [setCaptcha]
  );

  const onCaptchaExpired = useCallback(() => {
    setCaptcha(null);
  }, [setCaptcha]);

  const onCaptchaLoaded = useCallback(() => {
    setLoadingCaptcha(false);
  }, [setLoadingCaptcha]);

  const checkKeyDown = useCallback(event => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }, []);

  const isValid =
    Boolean(watchForm.contractId) &&
    !loading &&
    !loadingCaptcha &&
    Boolean(captcha);

  useEffect(() => {
    setNextButtonText('Siguiente');
    setVisualStepperIndex(currentStepIndex);
    setCanSubmit(false);
    setGoBack({
      action: false
    });
  }, [
    setGoBack,
    setVisualStepperIndex,
    currentStepIndex,
    setNextButtonText,
    setCanSubmit
  ]);

  return (
    <>
      <InvoiceHelpDialog
        type={InvoiceHelpType.Subrogation}
        open={helpDialogOpen}
        onClose={() => setHelpDialogOpen(false)}
        title={HELP_TEXT.contract}
      />
      {loading && (
        <ModalProgress
          id={'Verifying_subrogation_progress_loading'}
          message={'Verificando'}
        />
      )}
      <Grid container className={classes.infoCard}>
        <Typography className={classes.infoTitle}>
          ¿Qué es la solicitud de subrogación?
        </Typography>
        <Typography className={classes.infoText} paragraph>
          Como usuario residencial proveniente de{' '}
          <strong>Constructora Bolívar </strong>puedes realizar tu solicitud de
          instalación del servicio de gas a través de esta opción para gestionar
          tu proceso, sin necesidad de acercarte o llamar a las oficinas de{' '}
          {Company.name}.
        </Typography>
      </Grid>
      <div
        className={clsx(classes.innerContentContainer, classes.centerContent)}
      >
        <div className={classes.root}>
          <Title title="Valida el contrato" className={classes.title} />
          <form
            id={formId}
            onSubmit={handleSubmit(onSuccessContract)}
            className={classes.form}
            onKeyDown={e => checkKeyDown(e)}
          >
            <Grid container className={classes.formContainer}>
              <Grid
                item
                xs={12}
                sm={5}
                className={clsx(classes.item, classes.leftItem)}
              >
                <TextInput
                  label="Número de contrato*"
                  inputRef={register}
                  fullWidth
                  name="contractId"
                  disabled={loading}
                  error={Boolean(errors.contractId)}
                  helperText={errors.contractId && errors.contractId.message}
                  margin="none"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        classes={{ positionEnd: classes.positionEnd }}
                      >
                        {loading && <CircleProgress />}
                        {!loading && <QuestionMark onClick={onHelpClick} />}
                      </InputAdornment>
                    ),
                    inputProps: {
                      type: 'number',
                      inputMode: 'numeric'
                    }
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                innerRef={inputRef}
                className={classes.inputContainer}
              >
                <Recaptcha
                  captchaRef={recaptchaRef}
                  referenceEl={inputRef}
                  sitekey={config.recaptcha.siteKey}
                  locale={'es'}
                  onResolved={onCaptchaResolved}
                  onExpired={onCaptchaExpired}
                  onLoaded={onCaptchaLoaded}
                  style={{
                    marginTop: 0,
                    transform: isMobileSize ? 'scale(1.13,1)' : 'scale(1,0.75)'
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                className={classes.queryButtonContainer}
              >
                <BaseButton
                  id="Subrogation_button_submit"
                  onClick={contractValidation}
                  disabled={!isValid}
                  fullWidth
                  color="primary"
                  variant="outlined"
                  className={classes.queryButton}
                >
                  Consultar
                </BaseButton>
              </Grid>
            </Grid>
          </form>
          {contractToValidate && (
            <ContractStatusCard
              contract={{
                ...contractToValidate.contractData,
                valid: contractToValidate.valid
              }}
              reasons={invalidContractReasons}
              message="subrogación"
              showReasons={false}
              showAssociateContract={false}
              infoContractMessage={infoContractMessage}
            />
          )}
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    '&:last-child': {
      marginBottom: theme.spacing(2)
    }
  },
  innerContentContainer: {
    maxWidth: 950
  },
  centerContent: {
    margin: '0 auto'
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.2,
    marginBottom: theme.spacing(2)
  },
  item: {
    display: 'flex',
    alignItems: 'center'
  },
  leftItem: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingRight: 0,
      paddingBottom: theme.spacing(2)
    }
  },
  queryButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing()
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      marginTop: 0
    }
  },
  queryButton: {
    minHeight: 58,
    lineHeight: 'inherit'
  },
  formContainer: {
    maxWidth: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.background.border}`
  },
  form: { marginBottom: theme.spacing(3) },
  positionEnd: {
    marginRight: theme.spacing()
  },
  inputContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginTop: theme.spacing(),
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
      display: 'flex',
      alignItems: 'center'
    }
  },
  infoCard: {
    flexWrap: 'wrap',
    backgroundColor: theme.palette.background.cardDark,
    border: 'solid 1px #dbdbdb',
    borderRadius: theme.custom.borderRadius,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(3),
    [`@media (max-width:${theme.breakpoints.values.sm - 1}px)`]: {
      paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    [`@media (max-width:${theme.breakpoints.values.xm - 1}px)`]: {
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  infoTitle: {
    fontSize: 15,
    fontWeight: 500,
    marginBottom: theme.spacing()
  },
  infoText: {
    fontSize: 14,
    textAlign: 'justify',
    marginBottom: 0
  }
}));

export default ContractStep;
