import React from 'react';
import clsx from 'clsx';
import { Switch, FormGroup, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const BaseSwitch = props => {
  const {
    checked,
    handleToggle,
    color = 'primary',
    label = '',
    containerClass,
    controlLabelClass,
    rootClass,
    thumClass,
    trackClass,
    disabled = false
  } = props;

  const classes = useStyles();

  return (
    <FormGroup className={clsx(containerClass)}>
      <FormControlLabel
        disabled={disabled}
        className={clsx(classes.controlLabel, controlLabelClass)}
        control={
          <Switch
            color={color}
            checked={checked}
            onChange={handleToggle}
            classes={{
              root: clsx(classes.switchRoot, rootClass),
              thumb: clsx(thumClass),
              track: clsx(trackClass)
            }}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};

const useStyles = makeStyles(() => ({
  controlLabel: {},
  switchRoot: {}
}));

export default BaseSwitch;
