import React, { Fragment, useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import BaseButton from '../../Components/Buttons/BaseButton';
import BaseDialog from '../../Components/Dialogs/BaseDialog';
import { SlideUpTransition } from '../../Components/Transitions/Transitions';

const ConfirmationDialog = props => {
  // * CONTEXTS
  const {
    open,
    setDialog,
    requestCallback,
    title,
    content,
    cancelButtonText = 'No',
    submitButtonText = 'Sí',
    disabled = false
  } = props;
  const classes = useStyles();

  const changeAccepted = useCallback(async () => {
    const { unmounting, closeDialog } = await requestCallback();
    if (unmounting) {
      return;
    }
    if (closeDialog) {
      setDialog(false);
    }
  }, [requestCallback, setDialog]);

  const handleClose = useCallback(() => {
    requestCallback(true);
    setDialog(false);
  }, [requestCallback, setDialog]);

  const renderActions = () => {
    return (
      <Fragment>
        <BaseButton
          id="ConfirmationDialog_button_cancel"
          onClick={handleClose}
          variant="outlined"
          color="primary"
          size="small"
        >
          {cancelButtonText}
        </BaseButton>
        <BaseButton
          onClick={changeAccepted}
          id="ConfirmationDialog_button_submit"
          type="submit"
          color="primary"
          size="small"
          autoFocus
          disabled={disabled}
        >
          {submitButtonText}
        </BaseButton>
      </Fragment>
    );
  };

  const renderContent = () => {
    return (
      <div id="ConfirmationDialog_div_container">
        <Fragment>{content}</Fragment>
      </div>
    );
  };

  const isMobileSize = isWidthDown('sm', props.width);
  return (
    <Fragment>
      <BaseDialog
        open={open}
        handleClose={handleClose}
        title={title}
        actions={renderActions}
        content={renderContent}
        contentStyle={classes.content}
        fullScreen={isMobileSize}
        TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
        contentSize={isMobileSize ? undefined : 'small'}
      />
    </Fragment>
  );
};

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'inherit',
    maxWidth: '100%'
  }
}));

export default withWidth()(ConfirmationDialog);
