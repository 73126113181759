import React from 'react';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';

const ImageCard = props => {
  const { banner, classes = {}, children } = props;

  const baseClasses = useStyles();

  return (
    <Card className={clsx(baseClasses.root, classes.root)}>
      <div
        className={clsx(baseClasses.bannerContainer, classes.bannerContainer)}
      >
        <img
          src={banner}
          alt={'banner'}
          className={clsx(baseClasses.banner, classes.banner)}
        />
      </div>
      <div
        className={clsx(baseClasses.contentContainer, classes.contentContainer)}
      >
        {children}
      </div>
    </Card>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: 6,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.1)'
  },
  bannerContainer: {
    width: '100%'
  },
  banner: {
    width: '100%'
  },
  contentContainer: {
    maxWidth: '80%',
    width: '100%'
  }
}));

export default ImageCard;
