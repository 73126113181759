import axios from 'axios';
import { config } from '../../Configs';

export const OwnershipRequestAPI = async (token, id, data) => {
  try {
    const response = await axios.post(
      `${config.api.url}/ownership/${id}`,
      data,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const HasOwnershipRequestAPI = async (token, id) => {
  try {
    const response = await axios.get(`${config.api.url}/ownership/${id}`, {
      headers: {
        Authorization: token
      }
    });
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};
