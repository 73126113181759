import React, { Fragment, useCallback, useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { SlideUpTransition } from '../Transitions/Transitions';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';

import CloseIcon from '../CustomIcons/CloseIcon';

import NoFuzzyResult from '../../Components/CustomIcons/IcNoFuzzyResult';
import { Grid } from '@material-ui/core';
import BaseButton from '../Buttons/BaseButton';
import {
  CircularMenuContext,
  CircularMenuDispatchContext
} from '../../Contexts/CircularMenuContext';

function HelpChannelsDialog(props) {
  const { open = true, onClose } = props;
  const classes = useStyles();

  const menu = useContext(CircularMenuContext);
  const setMenu = useContext(CircularMenuDispatchContext);

  const isMobileSize = isWidthDown('xs', props.width);

  const openHelpCenter = useCallback(() => {
    if (!menu.open) {
      document.getElementById('circularMenu').classList.toggle('active');
    }

    setMenu({ open: true, backdrop: true });
    onClose();
  }, [menu, setMenu, onClose]);

  return (
    <Fragment>
      <Dialog
        id={'PhonesDialog_div'}
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{
          paper: clsx({
            [classes.paperFullScreen]: isMobileSize,
            [classes.paper]: !isMobileSize
          })
        }}
        fullScreen={isMobileSize}
        TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
      >
        <DialogTitle disableTypography className={classes.title}>
          <IconButton
            size="small"
            aria-label="cerrar diálogo"
            onClick={onClose}
            className={classes.titleButton}
          >
            <CloseIcon size={15} className={classes.titleIcon} />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <NoFuzzyResult />
          <Typography className={classes.dialogSubtitle}>
            Lo sentimos
          </Typography>
          <Typography className={classes.dialogParagraph}>
            No pudimos resolver tus dudas en este momento, pero recuerda que
            tenemos otros canales donde podemos ayudarte.
          </Typography>
          <Grid container className={classes.buttonsContainer}>
            <Grid className={classes.fullWidth}>
              <BaseButton
                onClick={openHelpCenter}
                color="primary"
                variant="contained"
                fullWidth
                className={classes.channelsButton}
              >
                Ver otros canales
              </BaseButton>
            </Grid>
            <Grid className={classes.closeTextContainer}>
              <Typography className={classes.closeText} onClick={onClose}>
                Cerrar
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: theme.custom.borderRadius,
    overflowX: 'hidden'
  },
  paperFullscreen: {
    borderRadius: 0,
    overflowX: 'hidden'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minHeight: 70,
    backgroundColor: 'white',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2)
  },
  titleButton: {
    padding: theme.spacing(1.5)
  },
  titleIcon: {
    fill: theme.palette.text.primary
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 400,
    padding: '16px 32px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  dialogSubtitle: {
    fontSize: 16,
    fontWeight: 1000,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  dialogParagraph: {
    fontSize: 12,
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    maxWidth: 270
  },
  closeText: {
    fontSize: 14,
    color: '#104EB2',
    textAlign: 'center',
    fontWeight: 500,
    paddingTop: theme.spacing(3),
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
    alignContent: 'center'
  },
  fullWidth: {
    width: '100%'
  },
  channelsButton: {
    boxShadow: 'none'
  },
  closeTextContainer: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

export default withWidth()(HelpChannelsDialog);
