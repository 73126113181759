import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import MyProfileLinkedAccountsGDC from './MyProfile.linkedAccounts.GDC';
import MyProfileLinkedAccountsEFG from './MyProfile.linkedAccounts.EFG';

const LoginContent = props => {
  switch (portal) {
    case Portal.Gascaribe:
    case Portal.TripleA:
      return <MyProfileLinkedAccountsGDC {...props} />;
    case Portal.Gasguajira:
    case Portal.Efigas:
      return <MyProfileLinkedAccountsEFG {...props} />;
    default:
      return <></>;
  }
};

export default LoginContent;
