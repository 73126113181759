import { ButtonBase, Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

const GroupedButtons = props => {
  const { buttons = [], customClassName = {}, isHidden } = props;
  const classes = useStyles();

  const allButtonsHidden = buttons.every(option => option.hidden);

  if (!buttons.length || isHidden || allButtonsHidden) {
    return <></>;
  }

  return (
    <div className={clsx(classes.root, customClassName.root)}>
      {buttons.map((option, index) => {
        const {
          hidden,
          disabled = false,
          classes: customClasses = {}
        } = option;

        if (hidden) {
          return null;
        }

        return (
          <ButtonBase
            focusRipple
            key={`${option.label}-${index}`}
            className={classes.button}
            onClick={option.onClick}
            disabled={disabled}
            id={option.id}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              className={clsx(
                classes.buttonsContent,
                customClassName.buttonContent
              )}
            >
              <Grid item>
                <option.icon
                  className={clsx(classes.icon, customClasses.icon)}
                />
              </Grid>
              <Grid item>
                <Typography
                  className={clsx(classes.label, customClassName.label)}
                >
                  {option.label}
                </Typography>
              </Grid>
            </Grid>
          </ButtonBase>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    border: `1px solid ${theme.palette.common.borderColor}`,
    borderRadius: theme.custom.borderRadius
  },
  button: {
    padding: theme.spacing(1, 0.5),
    borderRadius: 0,
    backgroundColor: `${theme.palette.primary.contrastText}00`,
    color: theme.palette.primary.main,
    whiteSpace: 'break-spaces',
    height: 75
  },
  buttonContent: {
    minHeight: '100%',
    padding: theme.spacing(0, 0.5),
    [theme.breakpoints.up(350)]: {
      padding: 0,
      justifyContent: 'unset',
      minHeight: 'auto'
    }
  },
  label: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: 1.2
  },
  icon: {
    width: 20,
    height: 20,
    fill: theme.palette.primary.main,
    marginBottom: theme.spacing(0.5)
  }
}));

export default GroupedButtons;
