import React from 'react';
import { portal, Portal } from '../../Configs/general';

import DigitalInvoiceGDC from './DigitalInvoiceActivation.GDC';

const DigitalInvoiceActivation = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <DigitalInvoiceGDC {...props} />;
    default:
      return <></>;
  }
};

export default DigitalInvoiceActivation;
