import React, {
  useContext,
  useEffect,
  useCallback,
  Fragment,
  useState
} from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import CouponCard from '../CouponCard';
import Title from '../../../../Components/Labels/Title';
import SweetAlert from '../../../../Components/Alerts/SweetAlert';
import BaseDialog from '../../../../Components/Dialogs/BaseDialog';
import BaseButton from '../../../../Components/Buttons/BaseButton';

import {
  StepperDataContext,
  CurrentStepIndexContext
} from '../../../../Contexts/StepperContext';

import { ROUTE_NAMES } from '../../../../Routes/Routes';
import { history } from '../../../../Routes/history';
import { COUPON_VALUES } from '../../../../Utils/enums';
import { numberWithDots } from '../../../../Utils/Format/MoneyFormat';
import { OpenNewTab } from '../../../../Utils/Misc/Links';
import { TYPE_OF_PAYMENTS } from '../../../Payment/payment_enums';

const SummaryStep = props => {
  const classes = useStyles();

  const {
    formId,
    setCanSubmit,
    setGoBack,
    setCurrentStep: setVisualStepperIndex,
    setNextButtonText,
    addedDeferreds,
    selectedContract,
    isMobileSize,
    currentDebtValue = 0
  } = props;

  const currentStepIndex = useContext(CurrentStepIndexContext);
  const stepperData = useContext(StepperDataContext);

  const [open, setOpen] = useState(false);

  const total =
    addedDeferreds.reduce((sum, current) => sum + current.pendingValue, 0) +
    currentDebtValue;

  const goToPayments = useCallback(() => {
    history.push(ROUTE_NAMES.payments, {
      type: TYPE_OF_PAYMENTS.coupon,
      value: stepperData.couponId,
      currentStep: 1,
      disabledNext: false,
      fromDeferredPayment: true,
      addedDeferreds,
      selectedContract,
      payment: [
        {
          type: TYPE_OF_PAYMENTS.coupon,
          detail: {
            id: stepperData.couponId,
            value: total,
            couponId: stepperData.couponId,
            couponValue: total,
            contractAddress: selectedContract.address,
            contractCity: selectedContract.city,
            contractId: selectedContract.id,
            isPaid: false,
            isPending: false
          }
        }
      ]
    });
  }, [total, addedDeferreds, selectedContract, stepperData]);

  const goToCloserMap = useCallback(() => {
    OpenNewTab(`${ROUTE_NAMES.map}`);
  }, []);

  const goToMyDebts = () => history.push(ROUTE_NAMES.debtStatus);

  const renderDialogContent = useCallback(
    () => (
      <div>
        <Typography className={classes.dialogText}>
          Te recordamos que tu solicitud de pago de diferido No.{' '}
          <b>{stepperData.requestId}</b> será atendida luego de que confirmemos
          el pago.
        </Typography>
        {currentDebtValue > 0 ? (
          <Typography className={classes.textSmall}>
            Te hemos enviado un correo con la información de la solicitud. El
            cupón agrupado generado solo puede ser pagado a través de este
            portal web.
          </Typography>
        ) : (
          <Typography className={classes.textSmall}>
            Te hemos enviado un correo con la información de la solicitud y el
            cupón de pago. Puedes descargar el cupón y pagarlo en cualquiera de
            nuestros{' '}
            <span className={classes.linkTextSmall} onClick={goToCloserMap}>
              canales de pago
            </span>
          </Typography>
        )}
      </div>
    ),
    [
      classes.dialogText,
      stepperData.requestId,
      classes.textSmall,
      classes.linkTextSmall,
      goToCloserMap,
      currentDebtValue
    ]
  );

  const renderActions = useCallback(() => {
    return (
      <Fragment>
        <BaseButton
          onClick={
            total >= COUPON_VALUES.MIN_VALUE ? goToPayments : goToMyDebts
          }
          color="primary"
          size="small"
          autoFocus
        >
          Entendido
        </BaseButton>
      </Fragment>
    );
  }, [total, goToPayments]);

  const onForward = useCallback(evt => {
    evt.preventDefault();
    setOpen(true);
  }, []);

  useEffect(() => {
    setNextButtonText(
      total >= COUPON_VALUES.MIN_VALUE ? 'Pagar por PSE' : 'Finalizar'
    );
    setVisualStepperIndex(currentStepIndex);
    setCanSubmit(true);
    setGoBack({
      action: null
    });
  }, [
    setGoBack,
    setVisualStepperIndex,
    currentStepIndex,
    setNextButtonText,
    setCanSubmit,
    total
  ]);

  return (
    <div className={classes.root}>
      {open && (
        <BaseDialog
          contentSize="small"
          content={renderDialogContent}
          contentStyle={classes.dialogContent}
          title="Estado de solicitud"
          actions={renderActions}
          disableBackdropClick={true}
          handleClose={
            total >= COUPON_VALUES.MIN_VALUE ? goToPayments : goToMyDebts
          }
          fullScreen={isMobileSize}
        />
      )}
      <form id={formId} onSubmit={onForward}>
        <SweetAlert
          type="info"
          iconSize={24}
          classes={{
            root: classes.sweetAlert,
            message: classes.sweetAlertText
          }}
          message={
            <Fragment>
              Se generó tu solicitud de pago de diferido con No.{' '}
              <b>{stepperData.requestId}</b> junto al cupón que encontrarás a
              continuación.
            </Fragment>
          }
        />
        <Title title="Cupón generado" className={classes.title} />

        <Typography paragraph className={classes.checkText}>
          Verifica que la información del cupón de pago generado sea correcta
        </Typography>
        <CouponCard
          initialBilling={total}
          couponId={stepperData.couponId}
          pdf={stepperData.pdf}
        />
        {total < COUPON_VALUES.MIN_VALUE && (
          <SweetAlert
            type={'warning'}
            classes={{
              root: classes.sweetAlert,
              message: classes.sweetAlertText
            }}
            message={
              <Fragment>
                Los pagos por PSE solo están disponibles para valores de mínimo{' '}
                <b>{numberWithDots(COUPON_VALUES.MIN_VALUE)}</b> pesos.
              </Fragment>
            }
          />
        )}
      </form>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginBottom: theme.spacing(5)
    }
  },
  dialogText: {
    fontSize: 14,
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2)
  },
  sweetAlert: {
    marginTop: theme.spacing(2)
  },
  sweetAlertText: {
    fontSize: '0.8em'
  },
  textSmall: {
    fontSize: 10,
    color: theme.palette.text.blackLight,
    maxWidth: '100%'
  },
  linkTextSmall: {
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: theme.palette.secondary.dark
    }
  },
  checkText: {
    color: theme.palette.text.greyDark
  },
  title: {
    fontWeight: 600
  },
  dialogContent: {
    minHeight: 0
  }
}));

export default SummaryStep;
