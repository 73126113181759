import { Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import React from 'react';
import BaseDialog from '../../../../Components/Dialogs/BaseDialog';
import { SlideUpTransition } from '../../../../Components/Transitions/Transitions';
import { Company } from '../../../../Configs/general';

const DeferredSideBarReasonsDialog = props => {
  const {
    open,
    handleClose,
    title,
    conditions,
    removeCovidDeferreds,
    billingDate,
    goToPayments
  } = props;

  const classes = useStyles();

  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('xs'));

  const removeCovidDeferredsHandler = () => {
    removeCovidDeferreds();
    handleClose();
  };

  const renderReasonsContent = () => {
    const payReasons = [];
    const refinanceReasons = [];

    if (conditions.contractType) {
      refinanceReasons.push(
        <>
          Tu {Company.contractConjugation.regular.singular.main} no es de tipo
          residencial.
        </>
      );
    }

    if (conditions.existCovidDeferreds) {
      refinanceReasons.push(
        <>
          Al menos uno de los conceptos añadidos está relacionado con alivios
          Covid.{' '}
          <span
            className={classes.downloadText}
            onClick={removeCovidDeferredsHandler}
          >
            Haz clic aquí para eliminar estos conceptos
          </span>
          .
        </>
      );
    }

    if (conditions.currentDebt) {
      const currentDebtWarning = (
        <>
          No estás al dia con tu deuda actual. Te invitamos a realizar el{' '}
          <span className={classes.downloadText} onClick={goToPayments}>
            pago de tu factura
          </span>
          .
        </>
      );

      refinanceReasons.push(currentDebtWarning);
    }

    if (conditions.dateNearBillingPeriod) {
      const dateNearBillingPeriodWarning = (
        <>
          Tu {Company.contractConjugation.regular.singular.main} se encuentra en
          proceso de facturación. Puedes intentarlo nuevamente después del{' '}
          <b>{billingDate}</b>.
        </>
      );

      payReasons.push(dateNearBillingPeriodWarning);
      refinanceReasons.push(dateNearBillingPeriodWarning);
    }

    if (conditions.noBillingPeriod) {
      const noBillingPeriodWarning = (
        <>
          Tu {Company.contractConjugation.regular.singular.main} se encuentra en
          proceso de facturación.
        </>
      );

      payReasons.push(noBillingPeriodWarning);
      refinanceReasons.push(noBillingPeriodWarning);
    }

    if (conditions.onGoingChangeConditionsRequests) {
      refinanceReasons.push(
        <>
          El producto seleccionado tiene una solicitud de refinanciación{' '}
          <b>(cambio de condiciones)</b> en proceso.
        </>
      );
    }

    if (conditions.moreThanTwoChangeConditions) {
      refinanceReasons.push(
        <>
          El producto seleccionado ha realizado 2 o más refinanciaciones{' '}
          <b>(cambio de condiciones)</b> en los últimos 12 meses
        </>
      );
    }

    return (
      <>
        {refinanceReasons.length > 0 && (
          <>
            <Typography className={classes.reasonsSubtitle}>
              No puedes refinanciar porque ...
            </Typography>
            <ul className={classes.reasonsList}>
              {refinanceReasons.map((reason, idx) => (
                <li key={idx}>
                  <Typography>{reason}</Typography>
                </li>
              ))}
            </ul>
          </>
        )}

        {payReasons.length > 0 && (
          <>
            <Typography className={classes.reasonsSubtitle}>
              No puedes realizar el pago de tus diferidos porque ...
            </Typography>
            <ul className={classes.reasonsList}>
              {payReasons.map((reason, idx) => (
                <li key={idx}>
                  <Typography>{reason}</Typography>
                </li>
              ))}
            </ul>
          </>
        )}
      </>
    );
  };

  return (
    <BaseDialog
      open={open}
      handleClose={handleClose}
      title={title}
      titleStyle={classes.dialogTitleContainer}
      content={renderReasonsContent}
      contentStyle={classes.reasonsDialogContent}
      contentSize="small"
      TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
    />
  );
};

const useStyles = makeStyles(theme => ({
  reasonsDialogContent: {
    minHeight: 'unset',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: theme.spacing(2)
    }
  },
  downloadText: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.palette.color.primary
  },
  reasonsSubtitle: {
    fontWeight: 500,
    marginBottom: theme.spacing(1)
  },
  reasonsList: {
    paddingLeft: theme.spacing(2),
    margin: theme.spacing(0, 0, 2),
    '&:last-child': {
      marginBottom: 0
    }
  },
  dialogTitleContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      paddingLeft: theme.spacing(2)
    }
  }
}));

export default DeferredSideBarReasonsDialog;
