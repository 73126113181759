export const normalizeURI = str => {
  const from = 'ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç',
    to = 'AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc',
    mapping = {};

  for (let i = 0, j = from.length; i < j; i++) {
    mapping[from.charAt(i)] = to.charAt(i);
  }

  const ret = [];
  for (let i = 0, j = str.length; i < j; i++) {
    const c = str.charAt(i);
    if (mapping.hasOwnProperty(str.charAt(i))) {
      ret.push(mapping[c]);
    } else {
      ret.push(c);
    }
  }
  return ret
    .join('')
    .replace(/[^-A-Za-z0-9]+/g, '-')
    .toLowerCase();
};
