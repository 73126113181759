import * as React from 'react';

function SvgIcTrash(props) {
  return (
    <svg
      id="icon__trash_1"
      width={props.size || 16.271}
      height={props.size || 20}
      viewBox="0 0 16.271 20"
      xmlSpace="preserve"
      {...props}
    >
      <path
        data-name="Path 279"
        d="M8.136 17.288a.339.339 0 00.339-.339V5.763a.339.339 0 10-.678 0v11.186a.339.339 0 00.339.339z"
        fill="#747b87"
      />
      <path
        data-name="Path 280"
        d="M4.746 17.288a.339.339 0 00.339-.339V5.763a.339.339 0 10-.678 0v11.186a.339.339 0 00.339.339z"
        fill="#747b87"
      />
      <path
        data-name="Path 281"
        d="M11.525 17.288a.339.339 0 00.339-.339V5.763a.339.339 0 10-.678 0v11.186a.339.339 0 00.339.339z"
        fill="#747b87"
      />
      <path
        data-name="Path 282"
        d="M15.932 2.034h-4.761a2.694 2.694 0 00-.615-1.6A1.434 1.434 0 009.491 0H6.1a1.434 1.434 0 00-1.066.437 2.694 2.694 0 00-.615 1.6H.339a.339.339 0 000 .678h.692l.649 15.6A1.645 1.645 0 003.344 20h9.584a1.643 1.643 0 001.663-1.681l.649-15.607h.692a.339.339 0 100-.678zM5.523.909A.768.768 0 016.1.678h3.389a.768.768 0 01.579.231 2 2 0 01.42 1.125H5.1A2 2 0 015.523.909zm8.39 17.391a.984.984 0 01-.986 1.024H3.344a1 1 0 01-.986-1.031L1.709 2.712h12.853z"
        fill="#747b87"
      />
    </svg>
  );
}

export default SvgIcTrash;
