import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import PaginationBack from '../CustomIcons/PaginationGoBack';
import PaginationFirst from '../CustomIcons/PaginationFirst';
import IconButton from '@material-ui/core/IconButton';
import * as yup from 'yup';

const PaginationControlled = props => {
  const {
    width,
    loading,
    numberOfItems,
    itemsPerPage,
    setItemsPerPage,
    currentPage,
    setCurrentPage,
    disabled,
    enabledColor = '#2a61ba',
    unselectedPage = '#D8DEE8',
    selectedPage = '#333',
    className,
    label = false
  } = props;
  /* Styless */
  const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
      userSelect: 'none',
      '& .MuiPaginationItem-page': {
        color: unselectedPage,
        margin: 0,
        borderBottomStyle: 'none',
        borderBottomWidth: 1,
        borderRadius: 0,
        padding: 0
      },
      '& .Mui-selected': {
        color: selectedPage,
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        borderColor: `${enabledColor}`,
        borderRadius: 0,
        padding: 0
      },
      '& .MuiPaginationItem-ellipsis ': {
        display: 'none'
      },
      '& .MuiTypography-root': {
        color: '#747B87'
      },
      '& .MuiPagination-ul': {
        paddingLeft: 4,
        paddingRight: 4,
        marginBottom: 5
      },
      '& .MuiPagination-ul li': {
        padding: 0
      },
      '& .MuiPaginationItem-sizeSmall': {
        minWidth: 22,
        maxWidth: 22,
        minHeight: 20,
        maxHeight: 20,
        paddingLeft: 4,
        paddingRight: 4,
        fontWeight: 500
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#D8DEE8'
        },
        '&:hover fieldset': {
          borderColor: `${enabledColor}`
        },
        '&.Mui-focused fieldset': {
          borderColor: `${enabledColor}`
        }
      }
    },
    textMobile: {
      fontSize: 14
    },
    textDesktop: {
      fontSize: 12
    },
    paginationRoot: {
      display: 'flex',
      alignItems: 'center'
    },
    forwardIcon: {
      transform: 'rotate(180deg)'
    },
    disabledButton: {
      opacity: 0.38
    },
    pageItem: {
      fontWeight: 500,
      color: 'rgba(105, 106, 106, 0.6)',
      backgroundColor: 'transparent !important'
    },
    pageSelected: {
      fontWeight: 600,
      color: '#696A6A',
      backgroundColor: 'transparent !important'
    },
    numberOfItemsPerPageDesktop: {
      maxWidth: 32,
      marginLeft: 4,
      marginRight: 4,
      padding: 0,
      '& .MuiOutlinedInput-inputMarginDense': {},
      '& .MuiOutlinedInput-input': {
        padding: 2,
        textAlign: 'center'
      }
    },
    numberOfItemsPerPageMobile: {
      maxWidth: 32,
      marginRight: 4,
      padding: 0,
      '& .MuiOutlinedInput-inputMarginDense': {},
      '& .MuiOutlinedInput-input': {
        padding: 2,
        textAlign: 'center'
      }
    },
    container: {
      display: 'flex'
    },
    pageIndicator: {},
    noHover: {
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    left: { paddingRight: 0 },
    rigth: { paddingLeft: 0 },
    GoBack: { paddingLeft: 8 },
    GoForward: { paddingRight: 8 },
    inputValue: {
      margin: [[0, 4]]
    }
  }));

  const classes = useStyles();
  const isMobileSize = isWidthDown('xs', width);
  const numberOfPages = Math.ceil(numberOfItems / itemsPerPage);

  const [inputValue, setInputValue] = useState(() => {
    const lastPage = numberOfPages === currentPage;
    const itemsExceeded = numberOfItems >= itemsPerPage;

    if (lastPage && currentPage !== 1 && itemsPerPage !== 1) {
      const remainingItems = numberOfItems % itemsPerPage;
      if (remainingItems) {
        return remainingItems;
      }
      return itemsPerPage;
    }

    return itemsExceeded ? itemsPerPage : numberOfItems;
  });

  const handleOnBlur = () => {
    if (inputValue !== '') {
      setItemsPerPage(parseInt(inputValue, 10));
      setCurrentPage(1);
    }
  };

  const handleOnKeyPressed = event => {
    if (event.key === 'Enter') {
      if (inputValue !== '') {
        setItemsPerPage(parseInt(inputValue, 10));
        setCurrentPage(1);
      }
    }
  };

  const inputvalue = useCallback(
    currentPageValue => {
      if (!disabled) {
        if (numberOfPages === currentPageValue && numberOfItems !== 1) {
          if (numberOfItems % itemsPerPage === 0) {
            setInputValue(itemsPerPage);
          } else {
            setInputValue(numberOfItems % itemsPerPage);
          }
        } else {
          setInputValue(itemsPerPage);
        }
      }
    },
    [disabled, setInputValue, numberOfPages, numberOfItems, itemsPerPage]
  );

  const handleChange = useCallback(
    (_, value) => {
      inputvalue(value);
      setCurrentPage(value);
    },
    [setCurrentPage, inputvalue]
  );

  const handleInputchange = event => {
    try {
      if (event.target.value === '') {
        setInputValue('');
      } else {
        numberOfItemsPerPageSchema.validateSync({ input: event.target.value });
        setInputValue(event.target.value);
      }
    } catch (err) {
      setInputValue(inputValue);
    }
  };

  const numberOfItemsPerPageSchema = yup.object({
    input: yup
      .number()
      .typeError('Amount must be a number')
      .required('Please provide plan cost.')
      .min(1, 'Too little')
      .max(numberOfItems, 'Very costly!')
  });

  const renderItemPage = item => {
    if (!isInRange(item)) {
      return;
    }
    return (
      <PaginationItem
        classes={{
          root: classes.pageItem,
          selected: classes.pageSelected
        }}
        className={isMobileSize ? classes.textMobile : classes.textDesktop}
        {...item}
      />
    );
  };

  const handleBackwards = useCallback(() => {
    if (currentPage - 1 === 0) {
      return;
    }
    inputvalue(currentPage - 1);
    setCurrentPage(currentPage - 1);
  }, [currentPage, setCurrentPage, inputvalue]);

  const handleFirst = useCallback(() => {
    if (currentPage === 1) {
      return;
    }
    inputvalue(1);
    setCurrentPage(1);
  }, [currentPage, setCurrentPage, inputvalue]);

  const handleLast = useCallback(() => {
    if (currentPage === numberOfPages) {
      return;
    }
    inputvalue(numberOfPages);
    setCurrentPage(numberOfPages);
  }, [currentPage, setCurrentPage, numberOfPages, inputvalue]);

  const handleForward = useCallback(() => {
    if (currentPage + 1 > numberOfPages) {
      return;
    }
    inputvalue(currentPage + 1);
    setCurrentPage(currentPage + 1);
  }, [currentPage, setCurrentPage, numberOfPages, inputvalue]);

  const isInRange = item => {
    if (item.type === 'page') {
      //mobile
      if (isMobileSize) {
        if (
          currentPage <= 3 &&
          Math.abs(item.page - currentPage) >= 6 - currentPage
        ) {
          return false;
        } else if (
          numberOfPages - currentPage <= 1 &&
          Math.abs(item.page - currentPage) < 5 - (numberOfPages - currentPage)
        ) {
          return true;
        } else if (currentPage > 3 && Math.abs(item.page - currentPage) > 2) {
          return false;
        }
        return true;
      }

      //desktop
      if (
        currentPage <= 5 &&
        Math.abs(item.page - currentPage) >= 11 - currentPage
      ) {
        return false;
      } else if (
        numberOfPages - currentPage <= 4 &&
        Math.abs(item.page - currentPage) < 10 - (numberOfPages - currentPage)
      ) {
        return true;
      } else if (
        currentPage > 5 &&
        (item.page - currentPage < -4 || item.page - currentPage > 5)
      ) {
        return false;
      }
      return true;
    }
    return false;
  };

  const getCurrentItems = () => {
    const itemsExceeded = numberOfItems >= itemsPerPage;
    const lastPage = numberOfPages === currentPage;

    if (lastPage && numberOfItems !== 1) {
      return numberOfItems % itemsPerPage;
    }

    return itemsExceeded ? itemsPerPage : numberOfItems;
  };

  const renderPaginationText = () => {
    const showedItems = getCurrentItems();

    if (numberOfItems === 1 || disabled) {
      if (isMobileSize) {
        return (
          <Typography className={classes.textMobile}>
            {showedItems}
            {'/'}
            {numberOfItems}
          </Typography>
        );
      }
      return (
        <Typography className={classes.textDesktop}>
          Mostrando {showedItems} resultados de {numberOfItems}
        </Typography>
      );
    }

    if (isMobileSize) {
      return (
        <>
          {label ? (
            <Typography className={classes.inputValue}>{inputValue}</Typography>
          ) : (
            <TextField
              variant="outlined"
              size={'small'}
              className={classes.numberOfItemsPerPageMobile}
              value={inputValue}
              onChange={handleInputchange}
              onKeyPress={handleOnKeyPressed}
              onBlur={handleOnBlur}
            />
          )}
          <Typography>/ {numberOfItems}</Typography>
        </>
      );
    }

    return (
      <>
        <Typography>Mostrando</Typography>
        {label ? (
          <Typography className={classes.inputValue}>{inputValue}</Typography>
        ) : (
          <TextField
            variant="outlined"
            size={'small'}
            className={classes.numberOfItemsPerPageDesktop}
            type={'number'}
            value={inputValue}
            onChange={handleInputchange}
            onKeyPress={handleOnKeyPressed}
            onBlur={handleOnBlur}
          />
        )}
        <Typography> resultados de {numberOfItems}</Typography>
      </>
    );
  };

  return (
    <div className={clsx(classes.root, className)}>
      <div className={clsx(classes.container, classes.pageIndicator)}>
        {renderPaginationText()}
      </div>
      <div className={classes.container}>
        <IconButton
          classes={{ disabled: classes.disabledButton }}
          onClick={handleFirst}
          disableRipple
          disabled={Boolean(loading || currentPage === 1)}
          className={clsx(classes.left, classes.rigth, classes.noHover)}
        >
          <PaginationFirst fill={enabledColor} />
        </IconButton>
        <IconButton
          classes={{ disabled: classes.disabledButton }}
          onClick={handleBackwards}
          disableRipple
          disabled={Boolean(loading || currentPage - 1 === 0)}
          className={clsx(classes.left, classes.GoBack, classes.noHover)}
        >
          <PaginationBack fill={enabledColor} />
        </IconButton>
        <Pagination
          size={'small'}
          classes={{ root: classes.paginationRoot }}
          disabled={loading}
          count={numberOfPages}
          onChange={handleChange}
          hidePrevButton
          hideNextButton
          page={currentPage}
          renderItem={renderItemPage}
          showFirstButton
          showLastButton
          siblingCount={isMobileSize ? 2 : 5}
          shape={'rounded'}
        />
        <IconButton
          disableRipple
          classes={{ disabled: classes.disabledButton }}
          onClick={handleForward}
          disabled={Boolean(loading || currentPage + 1 > numberOfPages)}
          className={clsx(classes.rigth, classes.GoForward, classes.noHover)}
        >
          <PaginationBack className={classes.forwardIcon} fill={enabledColor} />
        </IconButton>
        <IconButton
          disableRipple
          classes={{ disabled: classes.disabledButton }}
          onClick={handleLast}
          disabled={Boolean(loading || currentPage === numberOfPages)}
          className={clsx(classes.left, classes.rigth, classes.noHover)}
        >
          <PaginationFirst
            className={classes.forwardIcon}
            fill={enabledColor}
          />
        </IconButton>
      </div>
    </div>
  );
};

export default withWidth()(PaginationControlled);
