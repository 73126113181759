import * as React from 'react';

const SvgIcCircleMinus = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 17}
    height={props.height || 17}
    {...props}
  >
    <g fill="none">
      <path data-name="Trazado 686" d="M.5.5h16v16H.5Z" />
      <g
        data-name="Grupo 803"
        stroke="#104eb2"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(.5 .5)"
      >
        <circle data-name="Elipse 195" cx={8} cy={8} r={8} />
        <path data-name="L\xEDnea 99" d="M5.2 8h5.6" />
      </g>
    </g>
  </svg>
);

export default SvgIcCircleMinus;
