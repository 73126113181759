import React, { useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';

import BackIcon from '../../../Components/CustomIcons/BackIcon';
import BaseButton from '../../../Components/Buttons/BaseButton';
import FlatButton from '../../../Components/Buttons/FlatButton';

import { submitExternalForm } from '../../../Utils/Misc/Form';

const FooterStepper = props => {
  // * MAP PROPS
  const {
    targetForm,
    goBack = {},
    nextButtonText = 'Siguiente',
    canSubmit = true,
    isMobileSize
  } = props;

  const { action: onBack, text: backButtonText = 'Regresar' } = goBack;

  const classes = useStyles();

  // button property "form" doesn't work on IE 11, thus a workaround
  const submitHandler = useCallback(() => {
    submitExternalForm(targetForm);
  }, [targetForm]);

  return (
    <Grid item xs={12}>
      <Toolbar
        className={isMobileSize ? classes.footerRootMobile : classes.footerRoot}
        disableGutters
      >
        <Grid container>
          <Grid item xs={6} className={classes.leftItem}>
            {onBack && (
              <FlatButton
                onClick={onBack}
                color="primary"
                className={classes.buttonBack}
              >
                <BackIcon size={15} className={classes.backIcon} />
                {backButtonText}
              </FlatButton>
            )}
          </Grid>
          <Grid item xs={6} className={classes.rightItem}>
            <BaseButton
              type="submit"
              className={classes.continueButton}
              id="nextStep"
              disabled={!canSubmit}
              color="primary"
              variant="contained"
              onClick={submitHandler}
            >
              {nextButtonText}
            </BaseButton>
          </Grid>
        </Grid>
      </Toolbar>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  footerRoot: {
    display: 'flex',
    textAlign: 'right',
    height: theme.spacing(11),
    width: '100%',
    boxShadow: '0px 2px 10px -1px rgba(0,0,0,0.2)',
    backgroundColor: '#F8F8F8',
    borderRadius: theme.spacing(0, 0, 2, 2)
  },
  footerRootMobile: {
    zIndex: 10,
    position: 'fixed',
    display: 'flex',
    textAlign: 'right',
    height: theme.custom.footerHeight.stepper,
    width: '100%',
    bottom: 0,
    boxShadow: '0px 2px 10px -1px rgba(0,0,0,0.2)',
    backgroundColor: '#F8F8F8',
    maxHeight: 79
  },
  continueButton: {
    fontSize: 12,
    fontWeight: 500,
    maxWidth: theme.spacing(11.5),
    maxHeight: 47,
    margin: theme.spacing(0, 3, 0, 0),
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
      fontWeight: 600,
      maxWidth: theme.spacing(13),
      maxHeight: 50,
      margin: theme.spacing(0, 4, 0, 0)
    }
  },
  buttonBack: {
    fontSize: 12,
    fontWeight: 600,
    color: '#333333',
    marginLeft: theme.spacing(3),
    padding: '15px 15px 15px 0',
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
      marginLeft: theme.spacing(4)
    }
  },
  helperText: {
    fontSize: 10,
    margin: 0,
    textAlign: 'right'
  },
  backIcon: {
    fill: 'currentColor',
    marginRight: theme.spacing(1)
  },
  leftItem: {
    textAlign: 'left'
  },
  rightItem: {
    textAlign: 'right'
  }
}));

export default FooterStepper;
