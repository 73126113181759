import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { RemoveCircle as RemoveCircleIcon } from '@material-ui/icons';
import { capitalizeFirstLetter } from '../../../../Utils/Format/Names';
import CovidIcon from '../../../../Components/CustomIcons/CovidIcon';
import clsx from 'clsx';
import { numberWithDots } from '../../../../Utils/Format/MoneyFormat';

const DeferredSidebarItemFinancialDeferred = props => {
  const { deferred, allowDeletion, removeDeferreds } = props;

  const {
    deferredId,
    reliefCOVID19,
    conceptId,
    conceptDescription,
    pendingValue
  } = deferred;

  const classes = useStyles();

  return (
    <div className={classes.deferredConcept}>
      <Typography className={classes.label}>Concepto</Typography>
      <div className={classes.conceptDescriptionContainer}>
        {reliefCOVID19 && <CovidIcon className={classes.covidIcon} />}
        <Typography className={classes.data}>
          {conceptId} - {capitalizeFirstLetter(conceptDescription)}
        </Typography>
      </div>
      <Grid container wrap="nowrap" justify="flex-end" alignItems="center">
        <Typography className={clsx(classes.data, classes.total)}>
          Valor parcial: {numberWithDots(pendingValue)}
        </Typography>
        {allowDeletion && (
          <div className={classes.removeButtonWrapper}>
            <Tooltip title="Quitar concepto" placement="left">
              <IconButton
                size="small"
                color="inherit"
                onClick={() => {
                  removeDeferreds('deferred', deferredId);
                }}
                className={classes.removeButton}
              >
                <RemoveCircleIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  deferredConcept: {
    borderBottom: `1px solid ${theme.palette.background.border}`,
    '&:first-of-type': {
      paddingTop: 0
    },
    padding: theme.spacing(1, 0.5, 1, 1),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: theme.spacing(2, 2, 1, 2)
    }
  },
  label: {
    fontSize: 10,
    fontWeight: 500,
    color: theme.palette.text.greyDark
  },
  data: {
    fontSize: 11,
    fontWeight: 500,
    lineHeight: 1.2
  },
  removeButtonWrapper: {
    color: theme.palette.error.main
  },
  removeButton: {
    padding: theme.spacing(0.75)
  },
  total: {
    fontWeight: 600
  },
  conceptDescriptionContainer: {
    margin: theme.spacing(0.5, 0),
    display: 'flex',
    alignItems: 'center'
  },
  covidIcon: {
    marginRight: theme.spacing()
  }
}));
export default DeferredSidebarItemFinancialDeferred;
