import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import PaymentGDC from './Payment.GDC';
import PaymentGDG from './Payment.GDG';
import PaymentEFG from './Payment.EFG';
import { TYPE_OF_PAYMENTS } from '../payment_enums';

export const DefaultParams = {
  type: TYPE_OF_PAYMENTS.contract,
  value: '',
  currentStep: 0,
  payment: [],
  disabledNext: true
};

export const buildPSERequest = (couponId, payerInfo) => {
  return {
    product: {
      couponId
    },
    payer: {
      id: '1',
      fullName: payerInfo.payerFullName.trim(),
      email: payerInfo.email.trim(),
      contactPhone: payerInfo.phone.trim(),
      phoneCountryCode: payerInfo.phoneCountryCode.trim(),
      identificationType: payerInfo.typeOfIdentification.trim(),
      identificationNumber: payerInfo.identification.trim(),
      address: '-',
      city: 'Barranquilla',
      clientType: payerInfo.typeOfPerson,
      financialInstitutionCode: payerInfo.currentBank,
      financialInstitutionName: payerInfo.currentBankName
    }
  };
};

export const buildFinancingPSERequest = (
  financingInfo,
  payerInfo,
  requestId
) => {
  return {
    product: financingInfo,
    payer: {
      id: '1',
      fullName: payerInfo.payerFullName.trim(),
      email: payerInfo.email.trim(),
      contactPhone: payerInfo.phone.trim(),
      phoneCountryCode: payerInfo.phoneCountryCode.trim(),
      identificationType: payerInfo.typeOfIdentification.trim(),
      identificationNumber: payerInfo.identification.trim(),
      address: '-',
      city: 'Barranquilla',
      clientType: payerInfo.typeOfPerson,
      financialInstitutionCode: payerInfo.currentBank,
      financialInstitutionName: payerInfo.currentBankName
    },
    requestId
  };
};

const Payment = props => {
  switch (portal) {
    case Portal.Gascaribe:
      return <PaymentGDC {...props} />;
    case Portal.Gasguajira:
      return <PaymentGDG {...props} />;
    case Portal.Efigas:
      return <PaymentEFG {...props} />;
    case Portal.TripleA:
      return <PaymentGDC {...props} />;
    default:
      return <></>;
  }
};

export default Payment;
