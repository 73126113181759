import * as React from 'react';
const AppleLogo = props => (
  <svg
    width={props.size || 128}
    height={props.size || 128}
    viewBox="0 0 128 128"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill={props.fill || '#FFF'}
      d="M78 129H1V1h128v128H78m26.996-13.492c5.398-6.64 9.388-14.016 12.063-22.163-9.552-5.418-16.337-12.53-17.002-23.674-.66-11.083 4.94-18.925 14.12-24.428-8.69-12.49-25.833-16.122-39.788-9.955-5.567 2.46-10.634 3.045-16.536.322-6.349-2.93-13.285-3.615-20.428-1.757-16.721 4.35-23.466 18.598-24.296 32.22-1.05 17.25 4.217 33.06 14.45 46.813 8.269 11.112 13.464 18.006 31.907 10.438 4.709-1.932 11.464-1.256 16.509.473 12.762 4.372 22.71 2.203 29.001-8.29M77.534 7.94c-6.543 3.572-10.012 9.638-12.369 16.26-.88 2.475-.067 5.552-.027 8.355 3.298-.355 6.896.016 9.839-1.2 9.03-3.73 18.487-16.256 15.575-28.694-4.206 1.6-8.342 3.172-13.018 5.28z"
    />
    <path d="M104.735 115.765c-6.03 10.235-15.978 12.404-28.74 8.032-5.045-1.729-11.8-2.405-16.51-.473-18.442 7.568-23.637.674-31.906-10.438-10.233-13.753-15.5-29.564-14.45-46.813.83-13.622 7.575-27.87 24.296-32.22 7.143-1.858 14.08-1.173 20.428 1.757 5.902 2.723 10.97 2.138 16.536-.322 13.955-6.167 31.098-2.535 39.788 9.955-9.18 5.503-14.78 13.345-14.12 24.428.665 11.144 7.45 18.256 17.002 23.674-2.675 8.147-6.665 15.523-12.324 22.42z" />
    <path d="M77.804 7.673c4.406-1.84 8.542-3.413 12.748-5.012 2.912 12.438-6.546 24.963-15.575 28.694-2.943 1.216-6.54.845-9.839 1.2-.04-2.803-.853-5.88.027-8.354 2.357-6.623 5.826-12.689 12.639-16.528z" />
  </svg>
);
export default AppleLogo;
