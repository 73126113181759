import axios from 'axios';
import { config } from '../../Configs';
export const GetDischargeDeferredAPI = async (token, data) => {
  try {
    const response = await axios.post(
      `${config.api.url}/contracts/debt/deferred/payments`,
      data,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};
export const GenerateCouponDeferredAPI = async (token, data) => {
  try {
    const response = await axios.post(
      `${config.api.url}/contracts/debt/deferred/pdf`,
      data,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};

export const DeferredPartialPaymentAPI = async (token, data) => {
  try {
    const response = await axios.post(
      `${config.api.url}/contracts/debt/deferred/partial-payment`,
      data,
      {
        headers: {
          Authorization: token
        }
      }
    );
    return { success: true, data: response.data };
  } catch (err) {
    return { success: false, error: err };
  }
};
