import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

const TimelineSkeleton = () => {
  const classes = useStyles();
  const timelineSimpleElement = () => {
    return (
      <Grid container direction="row" className={classes.element}>
        <Grid item sm={1} xs={2}>
          <Grid container justify="center" alignItems="center">
            <Skeleton variant="circle" width={40} height={40} />
          </Grid>
        </Grid>
        <Grid item sm={11} xs={10}>
          <Grid container direction="column" justify="center">
            <Skeleton
              height={11}
              width="40%"
              className={classes.titleSkeleton}
            />
            <Skeleton
              height={10}
              width="20%"
              className={classes.subtitleSkeleton}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const timelineBoxElement = () => {
    return (
      <Grid container direction="row" className={classes.element}>
        <Grid item sm={1} xs={2}>
          <Grid container justify="center" alignItems="center">
            <Skeleton variant="circle" width={40} height={40} />
          </Grid>
        </Grid>
        <Grid item sm={11} xs={10}>
          <Grid container direction="column" justify="center">
            <Skeleton
              height={77}
              width="100%"
              className={classes.titleSkeleton}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid container direction="column" className={classes.timelineSkeleton}>
      {timelineSimpleElement()}
      {timelineBoxElement()}
      {timelineBoxElement()}
      {timelineSimpleElement()}
      {timelineSimpleElement()}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  timelineSkeleton: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  element: {
    marginBottom: theme.spacing(3)
  },
  titleSkeleton: {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing()
  },
  subtitleSkeleton: {
    marginTop: theme.spacing(0.3),
    marginBottom: theme.spacing(0.5)
  }
}));

export default TimelineSkeleton;
