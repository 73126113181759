import React, { createContext, useState, useCallback } from 'react';

export const FeaturesContext = createContext(null);
export const FeaturesDispatchContext = createContext(null);

export const FeaturesProvider = ({ children }) => {
  const [features, setFeatures] = useState({});

  const getFeature = useCallback(
    (feature, defaultValue) => {
      if (!features.hasOwnProperty(feature)) {
        return defaultValue;
      }
      return features[feature].enabled;
    },
    [features]
  );

  return (
    <FeaturesContext.Provider value={getFeature}>
      <FeaturesDispatchContext.Provider value={setFeatures}>
        {children}
      </FeaturesDispatchContext.Provider>
    </FeaturesContext.Provider>
  );
};
