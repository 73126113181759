import React from 'react';
import { Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';

import BaseDialog from './BaseDialog';
import BaseButton from '../Buttons/BaseButton';
import InsurancePlanDetailCard from '../Cards/InsurancePlanDetailCard';
import { SlideUpTransition } from '../Transitions/Transitions';

import { InsurancePlanIcon } from '../../Enums/insurances';
const SelectInsurancePlanDialog = props => {
  const { open, plans, setSelectedPlan, selectedPlan, handleClose } = props;

  const classes = useStyles();

  const isMobileSize = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  const renderContent = () => (
    <div>
      <Typography className={clsx(classes.title)}>
        Selecciona el plan que más se adapte para tí
      </Typography>
      <Grid container justify="space-evenly">
        {Object.values(plans)
          .filter(plan => plan.id !== selectedPlan)
          .map(plan => (
            <Grid item key={plan.id}>
              <InsurancePlanDetailCard
                PlanIcon={InsurancePlanIcon[plan.id]}
                planCoverageRange={plan.coverageRange}
                planMonthlyPay={plan.monthlyPay}
                planInsuredNumber={plan.insuredNumber}
                planName={plan.name}
                buttonText="Seleccionar"
                handleClick={() => setSelectedPlan(plan.id)}
              />
            </Grid>
          ))}
      </Grid>
    </div>
  );

  const renderActions = () => (
    <BaseButton onClick={handleClose}>Cancelar</BaseButton>
  );

  return (
    <BaseDialog
      id="SelectInsurancePlanDialog_div"
      open={open}
      handleClose={handleClose}
      title="Selecciona tu plan"
      actions={renderActions}
      content={renderContent}
      fullScreen={isMobileSize}
      contentStyle={classes.contentStyle}
      paperClass={classes.paperClass}
      TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
    />
  );
};

const useStyles = makeStyles(theme => ({
  contentStyle: {
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      width: 700
    }
  },
  paperClass: {
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      maxWidth: 700
    }
  },
  title: {
    marginBottom: theme.spacing(2)
  }
}));

export default SelectInsurancePlanDialog;
