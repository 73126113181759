import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { MAIN_BANNER_TITLE } from '../fixes/ganaLoco_enums';

const BANNER_BACKGROUND = require('../../../Assets/images/banner-ganaloco.jpg');
const BANNER_BACKGROUND_MOBILE = require('../../../Assets/images/banner-ganaloco-mobile.jpg');

export const Banner = props => {
  const { totalSteps = 3, currentStep, isMobileSize } = props;
  const classes = useStyles();

  return (
    <div
      className={classes.banner}
      style={{
        backgroundImage: `url(${
          isMobileSize ? BANNER_BACKGROUND_MOBILE : BANNER_BACKGROUND
        })`
      }}
    >
      <Grid container className={classes.titlesContainer}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            component="h6"
            className={classes.stepTitle}
          >
            {currentStep} de {totalSteps}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" component="h2" className={classes.mainTitle}>
            {MAIN_BANNER_TITLE}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  banner: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: theme.spacing(12),
    margin: theme.spacing(1, 0, 3, 0),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      borderRadius: theme.spacing(2, 2, 0, 0),
      height: theme.spacing(15),
      margin: theme.spacing(0, 0, 3, 0)
    }
  },
  titlesContainer: {
    color: theme.palette.common.white,
    padding: '32px 0 0 6%',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5, 0, 0, 40)
    }
  },
  stepTitle: {
    fontWeight: 600,
    fontSize: 12
  },
  mainTitle: {
    fontWeight: 600,
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      fontSize: 20
    }
  }
}));

export default Banner;
