import React, { useContext } from 'react';
import clsx from 'clsx';
import uuid from 'uuid/v4';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import { TotalPoints } from '../header_enums';

import SimpleDivider from '../../../../Components/Dividers/SimpleDivider';

import { UserContext } from '../../../../Contexts/UserContext';
import { ContractsContext } from '../../../../Contexts/ContractsContext';

import { firstNameAndLastName } from '../../../../Utils/Format/Names';

import PointsIcon from '../../../../Components/CustomIcons/IcPointsFireBadge';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #E7E7E7',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.07)'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={props.anchorOrigin}
    transformOrigin={props.transformOrigin}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    minWidth: 180,
    paddingTop: 14,
    paddingBottom: 14,
    paddingLeft: 26,
    paddingRight: 26,
    '&:hover': {
      color: '#3C77CF',
      backgroundColor: 'white !important'
    }
  },
  selected: {
    color: '#3C77CF',
    backgroundColor: `${theme.palette.primary.lighter} !important`,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.lighter} !important`
    }
  }
}))(MenuItem);

const DrawerGDC = props => {
  const {
    color = 'primary',
    variant = 'contained',
    loading,
    disabled,
    className,
    onClick = () => null,
    options = [],
    otherOptions = null,
    transformOrigin = {
      vertical: 'top',
      horizontal: 'right'
    },
    anchorOrigin = {
      vertical: 'bottom',
      horizontal: 'right'
    },
    ...rest
  } = props;

  const classes = navigationStyles();
  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const contracts = useContext(ContractsContext);

  // * STATES
  const [anchorEl, setAnchorEl] = React.useState(null);

  // * FUNCTIONS
  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
    onClick();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <ButtonBase
        id={'MenuButton_button_headerButton'}
        aria-controls="customized-menu"
        aria-haspopup="true"
        disableRipple
        disabled={loading || disabled}
        variant={variant}
        color={color}
        className={clsx(classes.buttonBase, className, {
          [classes.buttonColored]: anchorEl
        })}
        onClick={handleOpen}
        {...rest}
      >
        {props.children || props.text || '{Button}'}
      </ButtonBase>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={transformOrigin}
        anchorOrigin={anchorOrigin}
      >
        <div className={classes.drawerHeader}>
          <Typography className={classes.greeting}>Bienvenido</Typography>
          <Typography className={classes.name}>
            {firstNameAndLastName(
              currentUser,
              'firstName',
              'lastName',
              'lowercase'
            )}
          </Typography>
          <Grid className={classes.pointsContainer}>
            <PointsIcon />
            <Typography className={classes.points}>
              {TotalPoints(contracts)} puntos
            </Typography>
          </Grid>
        </div>
        <SimpleDivider withoutMargin className={classes.divider} />
        {options.map((obj, idx) => (
          <StyledMenuItem
            id={`HeaderButton_selectable_${idx}`}
            key={`${uuid()}`}
            name={obj.id}
            value={obj.id}
            onClick={() => {
              obj.onClick();
              handleClose();
            }}
            selected={window.location.pathname === obj.id}
          >
            <div className={classes.menuItemSelectIcon}>
              {obj.image &&
                obj.image({ size: 18, className: classes.imageOther })}
            </div>
            <div className={classes.itemTextPrimary}>
              {obj.label || `Item ${idx}`}
            </div>
          </StyledMenuItem>
        ))}
        {otherOptions &&
          otherOptions.map((obj, idx) => (
            <StyledMenuItem
              id={`MenuButton_otherOptions_${idx}`}
              key={`${uuid()}`}
              onClick={() => {
                obj.onClick();
                handleClose();
              }}
              TouchRippleProps={{ classes: { child: classes.rippleStyle } }}
            >
              {obj.image && (
                <div className={classes.menuItemSelectIcon}>
                  {obj.image({ size: 18, className: classes.imageOther })}
                </div>
              )}
              <Typography className={classes.itemTextPrimary}>
                {obj.label || `Item ${idx}`}
              </Typography>
            </StyledMenuItem>
          ))}
      </StyledMenu>
    </div>
  );
};

const navigationStyles = makeStyles(theme => ({
  buttonBase: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    lineHeight: 1.29,
    color: theme.palette.text.greyDark,
    '&:hover': {
      color: theme.palette.color.primary,
      fill: theme.palette.color.primary
    }
  },
  drawerHeader: {
    outline: 'none',
    paddingTop: 18,
    paddingBottom: 18,
    paddingLeft: 26,
    paddingRight: 26
  },
  greeting: {
    fontSize: 18,
    fontWeight: 600
  },
  name: {
    fontSize: 16,
    fontWeight: 'normal',
    textTransform: 'capitalize'
  },
  points: {
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 9
  },
  pointsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2)
  },
  divider: {
    marginBottom: 8
  },
  buttonColored: {
    color: `${theme.palette.color.primary} !important`
  },
  menuListNoSelect: {
    paddingTop: 0,
    paddinBottom: 0
  },
  menuItemNoSelect: {
    fontSize: 14,
    minWidth: 120,
    '&:hover': {
      backgroundColor: theme.palette.primary.lighter
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.lighter
    }
  },
  menuItemText: {
    fontSize: 14
  },
  menuItemSelectIcon: {
    display: 'flex',
    width: 30,
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  rippleStyle: {
    backgroundColor: 'white'
  },
  imageOther: {
    fill: 'currentColor'
  },
  itemTextPrimary: {
    fontSize: 14,
    lineHeight: 1.4
  },
  itemTextSecondary: {
    fontSize: 12,
    lineHeight: 1.2,
    color: theme.palette.text.grey
  }
}));

export default DrawerGDC;
