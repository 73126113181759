import React, { useCallback, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import moment from 'moment';
import clsx from 'clsx';

import CalendarIcon from '../../../../Components/CustomIcons/CalendarIcon';
import ReceiptIcon from '../../../../Components/CustomIcons/ReceiptIcon';

import { numberWithDots } from '../../../../Utils/Format/MoneyFormat';
import { leftPad } from '../../../../Utils/Format/Number';

import GenerationStep from '../../../Duplicates/DuplicateSteps/GenerationStep/GenerationStep';
import DebtPartialPaymentDialog from '../../DebtPartialPayment/DebtPartialPaymentDialog';
import { getBrillaCurrentDebtValue } from '../../helpers/helpers';
import { GeneratePartialPaymentCouponAPI } from '../../../../API/Debts/DebtsAPI';

const CurrentDebtCardEFG = props => {
  const { debts, invoices, selectedContract } = props;

  const classes = useStyles();
  const theme = useTheme();

  // * STATE HOOKS
  const [couponData, setCouponData] = useState(null);
  const [
    openDebtPartialPaymentDialog,
    setOpenDebtPartialPaymentDialog
  ] = useState(false);

  // * OTHER HOOKS
  const isSmallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  const isMediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  // * GLOBAL VARS
  const hasDebt = debts && debts.currentValue > 0;
  const currentValue = (debts && debts.currentValue) || 0;

  const brillaCurrentDebtValue = debts ? getBrillaCurrentDebtValue(debts) : 0;

  const handleClose = useCallback(() => {
    setCouponData(null);
  }, [setCouponData]);

  return (
    <>
      {openDebtPartialPaymentDialog && (
        <DebtPartialPaymentDialog
          open={openDebtPartialPaymentDialog}
          onClose={() => setOpenDebtPartialPaymentDialog(false)}
          paymentData={{
            maxValue: brillaCurrentDebtValue,
            contractId: selectedContract.id
          }}
          requestCallback={GeneratePartialPaymentCouponAPI}
          description={
            <Typography>
              Ingresa el valor que deseas abonar. Recuerda que este cupón se
              aplicará a tu deuda que ya ha sido facturada del producto Brilla
              (servicios financieros).
            </Typography>
          }
        />
      )}
      {couponData && (
        <GenerationStep
          title={'Cupón de pago para tu deuda actual'}
          open={true}
          step={couponData}
          onClose={handleClose}
        />
      )}
      <div className={classes.card}>
        <div className={classes.gradientBorder} />
        <div className={classes.innerContainer}>
          <div
            className={clsx(classes.header, {
              [classes.rowHeader]: !hasDebt && isMediumScreen
            })}
          >
            <Typography className={classes.title}>Deuda actual</Typography>
            {!hasDebt && (
              <Typography
                className={clsx(classes.statusTag, classes.upToDateStatus, {
                  [classes.alignRight]: isSmallScreen
                })}
              >
                Estás al día
              </Typography>
            )}
            {hasDebt && debts.billingDate && debts.billingDateMonth && (
              <div className={classes.debtStatusContainer}>
                <Typography className={classes.statusContainer}>
                  {isSmallScreen ? '' : 'Mes de facturación:'}
                  <span className={classes.month}>
                    {debts.billingDateMonth}
                  </span>
                  <CalendarIcon />
                </Typography>
                {false && (
                  <Typography
                    className={clsx(
                      classes.statusTag,
                      classes.closeToSuspendStatus,
                      { [classes.alignRight]: isMediumScreen }
                    )}
                  >
                    Próximo a suspender
                  </Typography>
                )}
              </div>
            )}

            {false && (
              <div className={classes.debtStatusContainer}>
                <Typography className={classes.statusContainer}>
                  <span className={classes.upToDateText}>Saldo a favor</span>
                  <ReceiptIcon />
                </Typography>
              </div>
            )}
          </div>

          <Typography className={classes.description}>
            Valores que ya han sido facturados.
          </Typography>

          <div>
            <Grid
              item
              container
              alignItems="flex-start"
              justify="space-between"
            >
              <Grid item xs={6} sm={3} md={3} className={classes.detailsItem}>
                {invoices && invoices.length > 0 ? (
                  <>
                    <Typography className={classes.label}>
                      {hasDebt
                        ? 'Facturas pendientes'
                        : 'Mes siguiente de facturación'}
                    </Typography>
                    <Typography className={classes.data}>
                      {hasDebt
                        ? debts.pendingBillings
                        : moment(
                            `${invoices[0].year}-${leftPad(
                              invoices[0].month,
                              '0',
                              2
                            )}`
                          )
                            .add(1, 'month')
                            .format('MMMM')}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography className={classes.label}>
                      Facturas pendientes
                    </Typography>
                    <Typography className={classes.data}>0</Typography>
                  </>
                )}
              </Grid>

              <Grid
                item
                container
                xs={12}
                sm={3}
                md={3}
                justify="flex-end"
                className={classes.detailsItem}
              >
                <Grid item>
                  <Typography
                    className={clsx(classes.label, classes.totalLabel)}
                  >
                    Saldo total
                  </Typography>
                  <Typography className={clsx(classes.data, classes.total)}>
                    {numberWithDots(currentValue)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  card: {
    borderRadius: theme.spacing(1, 0, 0, 1),
    display: 'flex',
    overflow: 'hidden',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'column',
      borderRadius: theme.spacing(1, 1, 0, 0)
    }
  },
  gradientBorder: {
    width: theme.spacing(),
    background:
      'transparent linear-gradient(180deg, #17B1D6 0%, #104EB2 100%) 0% 0%',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      height: theme.spacing(),
      width: '100%',
      background:
        'transparent linear-gradient(240deg, #17B1D6 0%, #104EB2 100%) 0% 0%'
    }
  },
  innerContainer: {
    borderColor: theme.palette.background.border,
    borderStyle: 'solid',
    borderLeft: 'none',
    borderWidth: '1px 1px 1px 0',
    borderRadius: theme.spacing(0, 1, 1, 0),
    padding: theme.spacing(3, 3, 2, 2),
    flexGrow: 1,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      borderWidth: '0 1px 1px 1px',
      borderRadius: theme.spacing(0, 0, 1, 1),
      padding: theme.spacing(2, 1.5)
    }
  },
  detailsItem: {
    margin: theme.spacing(0.5, 0, 0.5, 0)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(),
    flexDirection: 'row',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'column-reverse'
    }
  },
  rowHeader: {
    alignItems: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'row'
    }
  },
  title: {
    fontWeight: 600,
    fontSize: 14
  },
  month: {
    textTransform: 'capitalize',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    fontSize: 12,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 11,
      marginLeft: theme.spacing(1),
      marginRight: 0
    }
  },
  description: {
    lineHeight: 1.3,
    fontSize: 13,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 10
    }
  },
  label: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.greyDark,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 11
    }
  },
  data: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.2,
    textTransform: 'capitalize',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 12
    }
  },
  total: {
    fontSize: 20,
    textAlign: 'right',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 16
    }
  },
  totalLabel: {
    textAlign: 'right',
    fontSize: 12
  },
  debtStatusContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginBottom: theme.spacing()
    }
  },
  statusContainer: {
    display: 'flex',
    color: theme.palette.text.greyDark,
    alignItems: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      flexDirection: 'row-reverse'
    }
  },
  alignRight: {
    textAlign: 'right'
  },
  button: {
    fontSize: 12,
    textTransform: 'unset',
    fontWeight: 600
  },
  statusTag: {
    fontWeight: 600,
    fontSize: 14,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 11
    }
  },
  closeToSuspendStatus: {
    color: '#FE685E'
  },
  upToDateStatus: {
    color: '#18CC48'
  },
  upToDateText: {
    marginRight: theme.spacing(0.5)
  }
}));

export default CurrentDebtCardEFG;
