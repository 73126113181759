import React, { Fragment, useState, useContext, useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import BaseButton from '../../../Components/Buttons/BaseButton';
import BaseDialog from '../../../Components/Dialogs/BaseDialog';
import YesNoButton from '../../../Components/Buttons/YesNoButton';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';
import { UserContext } from '../../../Contexts/UserContext';

import { GenerateDuplicateCouponAPI } from '../../../API/Coupons/CouponsAPI';
import { extractErrorMessage } from '../../../Utils/Errors/Errors';
import { SlideUpTransition } from '../../../Components/Transitions/Transitions';
import { floatWithDots } from '../../../Utils/Format/MoneyFormat';

import _get from 'lodash/get';
import { InvoicePeriod } from '../../../Enums/invoices';
import { ROUTE_NAMES } from '../../../Routes/Routes';

const SelectionStep = props => {
  // * CONTEXTS
  const { step, setNextModal, onClose, open, title } = props;

  const [period, setPeriod] = useState(InvoicePeriod.Current);
  const [loading, setLoading] = useState(false);
  const setAlert = useContext(AlertsDispatchContext);
  const currentUser = useContext(UserContext);
  const authToken = _get(currentUser, 'token');

  const classes = useStyles();

  const data = step.data;
  const contractId = data.contractId;
  const currentInvoice = data.currentInvoice;
  const previousInvoice = data.previousInvoice;

  let selectedInvoice = currentInvoice;

  if (period === InvoicePeriod.Previous) {
    selectedInvoice = previousInvoice;
  }

  const value = selectedInvoice ? selectedInvoice.pendingValue : 0;

  const canSubmit = !loading || value > 0;

  const handleGoBack = useCallback(() => {
    setNextModal({
      type: 'validation',
      data: { contractId, ...step.data }
    });
  }, [setNextModal, contractId, step]);

  const handleSubmit = useCallback(
    async e => {
      const origin = {
        componentId: e.currentTarget.id,
        url: ROUTE_NAMES.home
      };
      e.preventDefault();
      if (!canSubmit) {
        return;
      }
      setLoading(true);

      if (!selectedInvoice) {
        setAlert({
          type: 'error',
          message: 'No se encuentra una factura para ese período'
        });
        setLoading(false);
        return;
      }

      if (selectedInvoice.pendingValue <= 0) {
        setAlert({
          type: 'error',
          message: 'El período seleccionado ya está pago'
        });
        setLoading(false);
        return;
      }

      const response = await GenerateDuplicateCouponAPI(
        contractId,
        period,
        authToken,
        origin
      );
      if (response.success) {
        setNextModal({
          type: 'generation',
          data: {
            ...response.data.data,
            period,
            contractId
          }
        });
      } else {
        setAlert({
          type: 'error',
          message: extractErrorMessage(response).message
        });
      }
      setLoading(false);
    },
    [
      selectedInvoice,
      period,
      contractId,
      canSubmit,
      setAlert,
      setNextModal,
      authToken
    ]
  );

  const renderActions = useCallback(() => {
    return (
      <Fragment>
        <BaseButton
          onClick={handleGoBack}
          variant="outlined"
          color="primary"
          size="small"
        >
          Atrás
        </BaseButton>
        <BaseButton
          id="Home_GenerateCoupon_button"
          onClick={handleSubmit}
          form="selectPeriodForm"
          color="primary"
          size="small"
          disabled={!canSubmit}
          autoFocus
        >
          Generar cupón
        </BaseButton>
      </Fragment>
    );
  }, [handleSubmit, handleGoBack, canSubmit]);

  const renderContent = useCallback(() => {
    return (
      <div className={classes.content}>
        <Typography className={classes.text}>
          A continuación, elige el período de facturación al que deseas que se
          le genere el cupón de pago.
        </Typography>
        <form id="selectPeriodForm" onSubmit={handleSubmit}>
          <YesNoButton
            checked={period === InvoicePeriod.Previous}
            yesText="Mes anterior"
            noText="Mes actual"
            yesChecked={() => setPeriod(InvoicePeriod.Previous)}
            noChecked={() => setPeriod(InvoicePeriod.Current)}
            color="secondary"
            size="large"
          />
        </form>
        <Typography className={classes.valueText}>
          Valor a pagar: <b>{floatWithDots(value)}</b>
        </Typography>
      </div>
    );
  }, [value, period, handleSubmit, classes]);

  const isMobileSize = isWidthDown('xs', props.width);
  return (
    <Fragment>
      <BaseDialog
        open={open}
        handleClose={onClose}
        title={title}
        actions={renderActions}
        content={renderContent}
        fullScreen={isMobileSize}
        loading={loading}
        contentSize="small"
        TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
      />
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    minHeight: 200
  },
  valueText: {
    fontSize: 14,
    color: theme.palette.text.primary,
    marginTop: theme.spacing(4),
    textAlign: 'center'
  },
  text: {
    fontSize: 14,
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2),
    width: '100%'
  }
}));

export default withWidth()(SelectionStep);
