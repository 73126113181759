import React from 'react';
import clsx from 'clsx';
import BaseButton from '../Buttons/BaseButton';
import { makeStyles } from '@material-ui/core/styles';

const YesNoButton = props => {
  const {
    checkedYesButton,
    checkedNoButton,
    checked,
    yesChecked,
    noChecked,
    className = '',
    yesText = 'Si',
    noText = 'No',
    bothInactive = false,
    ...rest
  } = props;
  const classes = useStyles();

  const getVariantYes = () => {
    if (bothInactive) {
      return checkedYesButton ? 'contained' : 'outlined';
    }
    return checked ? 'contained' : 'outlined';
  };
  const getVariantNo = () => {
    if (bothInactive) {
      return checkedNoButton ? 'contained' : 'outlined';
    }
    return checked ? 'outlined' : 'contained';
  };
  return (
    <div className={clsx(classes.root, className)}>
      <BaseButton
        size="small"
        variant={getVariantNo()}
        className={classes.buttonNo}
        onClick={() => {
          noChecked();
        }}
        {...rest}
      >
        {noText}
      </BaseButton>
      <BaseButton
        size="small"
        variant={getVariantYes()}
        className={classes.buttonYes}
        onClick={() => {
          yesChecked();
        }}
        {...rest}
      >
        {yesText}
      </BaseButton>
    </div>
  );
};
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  buttonNo: {
    maxHeight: 40,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2)
    }
  },
  buttonYes: {
    maxHeight: 40,
    [theme.breakpoints.up('sm')]: {
      marginTop: 0
    }
  }
}));
export default YesNoButton;
