import React, { Fragment, useContext, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Breadcrumbs,
  Container,
  Grid,
  Link,
  Typography
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment-timezone';

import IcActiveRequest from '../../../Components/CustomIcons/IcActiveRequest';
import { history } from '../../../Routes/history';
import BaseButton from '../../../Components/Buttons/BaseButton';
import { ContractSelectedContext } from '../../../Contexts/ContractsContext';

import { ROUTE_NAMES } from '../../../Routes/Routes';
import { REQUEST_TYPE } from '../../../Enums/insurances';
import { Company } from '../../../Configs/general';

const InProcessPage = props => {
  const classes = useStyles();
  const { location: locationParams = { state: null } } = props;

  const params = locationParams.state;

  // * CONTEXTS
  const selectedContract = useContext(ContractSelectedContext);

  useEffect(() => {
    if (!params) {
      history.replace(ROUTE_NAMES.insurancesHome);
      return;
    }

    if (!selectedContract) {
      history.replace('/');
      return;
    }

    if (params.contractId !== selectedContract.id) {
      history.replace(ROUTE_NAMES.insurancesHome);
      return;
    }
  }, [params, selectedContract]);

  if (!params) {
    return <></>;
  }

  const saleDate = moment(params.product.fechaventa, 'DD-MM-YYYY').format(
    'DD [de] MMMM [de] YYYY'
  );

  return (
    <Fragment>
      <div className={classes.root}>
        <Container maxWidth="md" className={classes.container}>
          <Breadcrumbs
            aria-label="breadcrumb"
            className={classes.breadcrumbs}
            separator={<NavigateNextIcon fontSize="small" />}
          >
            <Link
              color="primary"
              component={RouterLink}
              to="/"
              underline="always"
            >
              Inicio{' '}
            </Link>
            <Link
              color="primary"
              component={RouterLink}
              to={ROUTE_NAMES.insurancesHome}
              underline="always"
            >
              Seguros{' '}
            </Link>
            <Link
              color="primary"
              component={RouterLink}
              to={{
                pathname: ROUTE_NAMES.managePractiseguro,
                state: { product: params.product }
              }}
              underline="always"
            >
              PractiSeguro
            </Link>
            <Typography color="textPrimary">
              Solicitar{' '}
              {params.processData.requestType === REQUEST_TYPE.Annulment
                ? 'Anulación'
                : 'Asesoría'}
            </Typography>
          </Breadcrumbs>
        </Container>
        <Container
          maxWidth="md"
          className={clsx(classes.container, classes.bodyContainer)}
        >
          <IcActiveRequest />
          <Typography className={clsx(classes.titleText)}>
            Tu solicitud de{' '}
            {params.processData.requestType === REQUEST_TYPE.Annulment
              ? 'anulación'
              : 'asesoría'}{' '}
            Nº {params.processData.data[0].id} está en proceso
          </Typography>
          <Typography className={clsx(classes.bodyText)}>
            {Company.contractConjugation.capitalized.singular.main}:{' '}
            {selectedContract.alias} - {selectedContract.id} |{' '}
            {params.product.plan.name}
          </Typography>
          <Grid item md={7}>
            {' '}
            <Typography
              className={clsx(classes.detailsText, classes.centerText)}
            >
              Ya cuentas con una solicitud para{' '}
              {params.processData.requestType === REQUEST_TYPE.Annulment
                ? 'anulación'
                : 'asesoría'}{' '}
              creada el {saleDate} con número de solicitud{' '}
              {params.processData.data[0].id}. Te invitamos a estar atento a tu
              celular y correo electrónico, para cualquier novedad.
            </Typography>
          </Grid>
          <BaseButton variant="outlined" onClick={() => history.goBack()}>
            Regresar al inicio
          </BaseButton>
        </Container>
      </div>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  breadcrumbs: {
    fontSize: 15,
    fontWeight: theme.typography.fontWeightMedium,
    marginBottom: theme.spacing(3),
    '& *': { fontSize: 'inherit' }
  },
  container: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginTop: theme.spacing(3)
    }
  },
  bodyContainer: {
    marginTop: theme.spacing(10),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginTop: theme.spacing(7),
      padding: theme.spacing(0, 4),
      marginBottom: theme.spacing(10),
      '& p': {
        textAlign: 'center'
      }
    }
  },
  centerText: {
    textAlign: 'center'
  },
  titleText: {
    fontSize: 20,
    fontWeight: 600,
    margin: theme.spacing(2, 0)
  },
  bodyText: {
    fontSize: 16,
    marginBottom: theme.spacing(2)
  },
  detailsText: {
    fontSize: 14,
    marginBottom: theme.spacing(2)
  }
}));

export default InProcessPage;
