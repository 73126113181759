import * as React from 'react';

const SvgIcWarning = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 12}
    height={props.height || 12}
    viewBox="0 0 12 12"
    {...props}
  >
    <path
      data-name="Sustracci\xF3n 10"
      d="M6 10.5A4.505 4.505 0 0 1 1.5 6 4.505 4.505 0 0 1 6 1.5 4.505 4.505 0 0 1 10.5 6 4.505 4.505 0 0 1 6 10.5Zm0-2.929a.429.429 0 0 0-.428.428.429.429 0 0 0 .428.428h.005a.429.429 0 0 0 .428-.428.429.429 0 0 0-.428-.428Zm0-3.893a.322.322 0 0 0-.321.321V6.32A.322.322 0 0 0 6 6.641a.322.322 0 0 0 .321-.321V4A.322.322 0 0 0 6 3.679Z"
      fill={props.fill || '#fcc52c'}
    />
    <path data-name="Trazado 691" d="M0 0h12v12H0Z" fill="none" />
    <path
      data-name="Elipse 13"
      d="M6 1.339a4.659 4.659 0 0 1 3.3 7.956 4.661 4.661 0 1 1-5.111-7.59A4.632 4.632 0 0 1 6 1.339Zm0 9a4.338 4.338 0 0 0 3.068-7.408 4.339 4.339 0 1 0-4.757 7.067A4.312 4.312 0 0 0 6 10.339Z"
      fill={props.fill || '#fcc52c'}
    />
  </svg>
);

export default SvgIcWarning;
