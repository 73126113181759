/* eslint-disable global-require */
import React from 'react';
import { OPTIONS } from '../marker.options';
import { Marker, InfoWindow } from '@react-google-maps/api';

const getIconImage = (type, selected) => {
  switch (type) {
    case OPTIONS.support:
      return require('../../../Assets/icons/marker_support.svg');
    case OPTIONS.pay:
      if (selected) {
        return require('../../../Assets/icons/marker_pay_selected.svg');
      }
      return require('../../../Assets/icons/marker_pay.svg');
    case OPTIONS.coupon:
      return require('../../../Assets/icons/marker_brilla.svg');
    default:
      return '';
  }
};

const getIcon = (type, selected) => {
  return {
    url: getIconImage(type, selected),
    scaledSize: { width: 40, height: 40 }
  };
};

const MarkerWrapper = props => {
  const icon = getIcon(props.type, props.showInfoWindow);
  const toggleInfoWindow = () => {
    if (props.index === props.selectedMarker) {
      props.selectInfoWindow(null);
    } else {
      props.selectInfoWindow(props.index);
    }
  };
  return (
    <Marker onClick={toggleInfoWindow} {...props} icon={icon}>
      {props.showInfoWindow && (
        <InfoWindow onCloseClick={toggleInfoWindow}>
          {props.iwcomponent}
        </InfoWindow>
      )}
    </Marker>
  );
};

export default MarkerWrapper;
