import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import EmptyState from './EmptyState';
import NoRequestsIcon from '../../../../Components/CustomIcons/NoRequestsIcon';
import DeferredSidebarItemProduct from './DeferredSidebarItemProduct';
import ProductItem from '../ProductItem';

const DeferredSidebarItems = props => {
  const {
    isEmpty,
    products,
    allowDeletion,
    removeDeferreds,
    debtsData
  } = props;

  const hasCurrentDebt = debtsData && debtsData.currentValue > 0;
  const currents = debtsData && debtsData.currents;

  const [currentProducts, setCurrentProducts] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    let temporalConcepts = null;
    const tempCurrentProducts =
      currents &&
      currents.length > 0 &&
      currents.reduce((accProducts, current) => {
        const productId = current.productId;
        const productTypeDescription = current.productTypeDescription;
        const previousConcepts =
          Object.keys(accProducts).length > 0 &&
          accProducts[productId] &&
          accProducts[productId].concepts;

        const concepts = (previousConcepts || []).concat({
          conceptId: current.conceptId,
          balance: current.currentValue,
          conceptDescription: current.conceptDescription
        });

        return {
          ...accProducts,
          [productId]: {
            productId,
            productTypeDescription,
            concepts
          }
        };
      }, {});

    if (tempCurrentProducts) {
      temporalConcepts = Object.values(tempCurrentProducts).reduce(
        ({ concepts }, product) => {
          return { concepts: concepts.concat(product.concepts) };
        }
      );
    }

    if (temporalConcepts) {
      setCurrentProducts({
        0: {
          concepts: temporalConcepts.concepts
        }
      });
    }
  }, [currents]);

  if (isEmpty) {
    return (
      <div className={classes.itemsContainer}>
        <EmptyState icon={NoRequestsIcon} message="Aún no has agregado ítems" />
      </div>
    );
  }

  return (
    <div className={classes.itemsContainer}>
      {hasCurrentDebt &&
        currentProducts &&
        Object.values(currentProducts).map((product, idx) => (
          <ProductItem key={idx} {...product} payCurrentWithDeferreds />
        ))}
      {products.map(
        ({ productId, productTypeDescription, financials: financials2 }) => (
          <DeferredSidebarItemProduct
            key={productId}
            productId={productId}
            productTypeDescription={productTypeDescription}
            financials={Object.values(financials2)}
            allowDeletion={allowDeletion}
            removeDeferreds={removeDeferreds}
          />
        )
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  itemsContainer: {
    flexGrow: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: theme.spacing(0, 1.5, 0, 2),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      margin: 0
    }
  },
  productDeferredHeader: {
    padding: theme.spacing(),
    backgroundColor: theme.palette.background.accordionLight
  },
  removeButtonWrapper: {
    color: theme.palette.error.main
  },
  removeButton: {
    padding: theme.spacing(0.75)
  },
  financialHeader: {
    padding: theme.spacing(),
    backgroundColor: theme.palette.background.cardDark,
    borderBottom: `1px solid ${theme.palette.background.border}`
  },
  financingTitle: {
    marginBottom: theme.spacing(1.5)
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  total: {
    fontSize: 12
  },
  alignRight: {
    textAlign: 'right'
  },
  count: {
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing()
  },
  deferredConcept: {
    padding: theme.spacing(0.75, 1),
    borderBottom: `1px solid ${theme.palette.background.border}`,
    '&:first-of-type': {
      paddingTop: 0
    }
  },
  conceptDescriptionContainer: {
    margin: theme.spacing(0.5, 0),
    display: 'flex',
    alignItems: 'center'
  },
  covidIcon: {
    marginRight: theme.spacing()
  }
}));
export default DeferredSidebarItems;
