import React, {
  useState,
  useCallback,
  useRef,
  useContext,
  useEffect
} from 'react';
import clsx from 'clsx';
import * as yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

import DownloadIcon from '../../../../../../Components/CustomIcons/DownloadIcon';
import FileInput from '../../../../../../Components/Inputs/FileInput';
import BaseButton from '../../../../../../Components/Buttons/BaseButton';
import Recaptcha from '../../../../../../Components/Captcha/Recaptcha';
import ModalProgress from '../../../../../../Components/Progress/Modal/ModalProgress';
import SweetAlert from '../../../../../../Components/Alerts/SweetAlert';

import { AlertsDispatchContext } from '../../../../../../Contexts/AlertsContext';
import { UserContext } from '../../../../../../Contexts/UserContext';
import { ValidateContractCSVAPI } from '../../../../../../API/Contracts/ContractsAPI';

import fileToBase64 from '../../../../../../Utils/Transform/FileToBase64';
import { ALERT_TYPE } from '../../../../../../Components/Alerts/alert_enums';
import {
  MAX_CARDS_NUMBER,
  GROUPED_COUPON_INITIAL
} from '../../../../payment_enums';
import { config } from '../../../../../../Configs';
import { Company } from '../../../../../../Configs/general';

const publicPathTemplate = './plantilla-carga-masiva.csv';

const MultipleLoadGDC = props => {
  const {
    recaptchaRef,
    captcha,
    setCaptcha,
    inputRef,
    canSubmit,
    onCaptchaResolved,
    onCaptchaExpired,
    onCaptchaLoaded,
    totalCurrentCards,
    setCardMultipleLoad,
    setFirstAccordionExpanded,
    setSecondAccordionExpanded,
    setCaptchaToken
  } = props;

  const currentUser = useContext(UserContext);
  const setAlert = useContext(AlertsDispatchContext);

  const classes = useStyles();
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('xs'));

  const [loading, setLoading] = useState(false);

  const fileInputRef = useRef(null);

  const VALID_FILE_EXTENSIONS = ['csv'];
  const MAX_TOTAL_FILE_SIZE = 2;
  const initialFiles = [];

  const fileSchema = yup.array().of(
    yup.object({
      size: yup
        .number()
        // eslint-disable-next-line no-template-curly-in-string
        .test('fileSize', '${path}->Tamaño máximo: 10 MB', value => {
          return value <= 10240000;
        }),
      name: yup.string(),
      type: yup.string(),
      extension: yup
        .string()
        // eslint-disable-next-line no-template-curly-in-string
        .test('extension', '${path}->Archivo no permitido', extension =>
          VALID_FILE_EXTENSIONS.includes(extension)
        ),
      file: yup.mixed()
    })
  );

  const convertBytesToMegaBytes = bytes => (bytes / 1000000).toFixed(2);

  const [totalSize, setTotalSize] = useState(
    convertBytesToMegaBytes(
      initialFiles.reduce((total, file) => total + file.size, 0)
    )
  );
  const [files, setFiles] = useState(initialFiles);

  useEffect(() => {
    setFirstAccordionExpanded(false);
    setCaptcha(null);
  }, [setFirstAccordionExpanded, setCaptcha]);

  const parseFiles = useCallback(
    (currentFiles, incomingFiles) => {
      const preparedFiles = incomingFiles.map(file => {
        const { name, size, type } = file;

        const extension = name
          .split('.')
          .pop()
          .toLowerCase();

        return { name, size, type, extension, file, error: false };
      });

      const parsedFiles = currentFiles.slice();

      for (let i = 0; i < preparedFiles.length; ++i) {
        let found = false;
        for (let j = 0; j < currentFiles.length; ++j) {
          if (currentFiles[j].name === preparedFiles[i].name) {
            parsedFiles[i] = currentFiles[j];
            found = true;
            break;
          }
        }

        if (!found) {
          parsedFiles.push(preparedFiles[i]);
        }
      }

      try {
        fileSchema.validateSync(parsedFiles, {
          abortEarly: false
        });
      } catch (err) {
        for (const errorMessage of err.errors) {
          const [identifier, message] = errorMessage.split('->');
          const index = identifier.split('.')[0];

          const idx = parseInt(index.substr(1, index.length - 1), 10);

          parsedFiles[idx].error = true;
          parsedFiles[idx].errorMessage = message;
        }
      }

      const sortedParsedFiles = parsedFiles.slice();

      sortedParsedFiles.sort((a, b) => b.error - a.error);

      return sortedParsedFiles;
    },
    [fileSchema]
  );

  const handleChangeFile = useCallback(
    e => {
      const inputFiles = Array.from(e.target.files);

      if (files.length > 0) {
        return setAlert({
          type: ALERT_TYPE.INFO,
          message: 'No es posible cargar más de 1 archivo'
        });
      }

      const parsedFiles = parseFiles(files, inputFiles);

      const totalFilesSize = parsedFiles.reduce(
        (total, file) => total + file.size,
        0
      );

      setFiles(parsedFiles);
      setTotalSize(convertBytesToMegaBytes(totalFilesSize));
    },
    [files, parseFiles, setAlert]
  );

  const handleCleanPath = event => {
    event.target.value = '';
  };

  const findElement = (inputArray, property, targetItem) => {
    const foundItem = inputArray.find(
      item => item.detail[property] === Number(targetItem)
    );
    return foundItem;
  };

  const paymentCardAddition = (actualCards, searchNumber, transactionType) => {
    if (actualCards.length >= MAX_CARDS_NUMBER) {
      setAlert({
        type: ALERT_TYPE.WARNING,
        message: `El número máximo de elementos permitidos es ${MAX_CARDS_NUMBER}`
      });
      return {
        newCard: false
      };
    }

    const groupedCouponInitial = new RegExp(GROUPED_COUPON_INITIAL, 'i');

    const existGroupedCoupon = actualCards.find(card => {
      if (card.detail) {
        return String(card.detail.couponId).search(groupedCouponInitial) !== -1;
      }
      return false;
    });

    if (existGroupedCoupon) {
      setAlert({
        type: ALERT_TYPE.INFO,
        message: `No puede añadir otros cupones y/o ${Company.contractConjugation.regular.plural.main} si ya ha añadido un cupón agrupado`
      });
      return {
        newCard: false
      };
    }

    if (transactionType === 'contract') {
      const existsContractId = findElement(
        actualCards,
        'contractId',
        searchNumber
      );
      if (existsContractId) {
        setAlert({
          type: ALERT_TYPE.INFO,
          message: `Uno o varios ${Company.contractConjugation.regular.plural.main} ya han sido cargados previamente`
        });
        return {
          newCard: false
        };
      }
    } else {
      const isGroupedCoupon =
        String(searchNumber).search(groupedCouponInitial) !== -1;

      if (isGroupedCoupon && actualCards.length > 0) {
        setAlert({
          type: ALERT_TYPE.INFO,
          message: `No puede cargar este tipo de cupones si ya ha añadido otros cupones y/o ${Company.contractConjugation.regular.plural.main}`
        });
        return {
          newCard: false
        };
      }

      const existsCouponId = findElement(actualCards, 'couponId', searchNumber);
      if (existsCouponId) {
        setAlert({
          type: ALERT_TYPE.INFO,
          message: 'Este cupón ya ha sido cargado previamente'
        });
        return {
          newCard: false
        };
      }
    }

    return {
      newCard: true,
      payload: [
        {
          status: 'loading',
          type: transactionType,
          detail: {
            [transactionType === 'contract'
              ? 'contractId'
              : 'couponId']: Number(searchNumber),
            loading: true
          }
        }
      ]
    };
  };

  const createLoadingCard = (actualCards, contractList, type) => {
    const addedToLoad = [];
    let finalPayload = actualCards;
    contractList.forEach(contractId => {
      const { newCard, payload } = paymentCardAddition(
        finalPayload,
        contractId,
        type
      );
      if (newCard) {
        addedToLoad.push(...payload);
        finalPayload = [...finalPayload, ...payload];
      }
    });
    setCardMultipleLoad(prev => ({
      ...prev,
      loading: [...prev.loading, ...addedToLoad]
    }));
    setFirstAccordionExpanded(false);
    setSecondAccordionExpanded(true);
    return;
  };

  const handleLoadCards = async () => {
    if (files.length < 1) {
      return setAlert({
        type: ALERT_TYPE.WARNING,
        message: 'Por favor cargue la plantilla'
      });
    }

    setLoading(true);
    recaptchaRef.current.reset();

    const attachments = [];
    const { name: fileName, extension: fileExtension, file } = files[0];
    const fileContent = await fileToBase64(file);
    attachments.push({
      fileName,
      fileExtension,
      fileContent
    });

    let token = null;
    if (currentUser) {
      token = currentUser.token;
    }

    const { success, data, error } = await ValidateContractCSVAPI(
      token,
      attachments,
      captcha
    );
    if (success) {
      setAlert({
        type: ALERT_TYPE.SUCCESS,
        timeout: 5000,
        toast: true,
        message: 'Plantilla cargada'
      });
      setCaptchaToken(null);
      setCaptcha(null);
      setLoading(false);
      createLoadingCard(totalCurrentCards, data.data.contractList, 'contract');
    } else {
      if (error.response) {
        const message = Object.values(error.response.data.errors)[0];
        setAlert({
          type: ALERT_TYPE.ERROR,
          message
        });
      } else {
        setAlert({
          type: ALERT_TYPE.ERROR
        });
      }
      setLoading(false);
    }
  };

  const handleRemoveFile = fileName => {
    setFiles(currentFiles => {
      return currentFiles.filter(file => file.name !== fileName);
    });
  };

  return (
    <>
      {loading && (
        <ModalProgress
          id={'QueryPayment_progress_loading'}
          message={'Estamos procesando tu plantilla'}
        />
      )}
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} sm={8} className={classes.gridItem}>
          <Typography className={classes.titleText}>
            Ahorra tiempo en el pago de tus{' '}
            {Company.contractConjugation.regular.plural.main}
          </Typography>
          <Typography className={classes.informationText}>
            Descarga la plantilla para realizar un único pago para múltiples
            {Company.contractConjugation.regular.plural.main} (Máx. 150)
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} className={classes.gridItem}>
          <a
            href={publicPathTemplate}
            download
            className={classes.downloadAnchor}
          >
            <Button
              className={classes.downloadTemplateButton}
              color="primary"
              size="small"
              variant="outlined"
              fullWidth={true}
            >
              Descargar plantilla
              <DownloadIcon
                width={24}
                height={24}
                className={classes.downloadIcon}
              />
            </Button>
          </a>
        </Grid>
        <Grid container wrap={isMobileSize ? 'wrap' : 'nowrap'}>
          <Grid
            item
            xs={12}
            sm={8}
            className={clsx(classes.dragAndDropContainer, {
              [classes.fileItemsAdded]: files.length > 0
            })}
          >
            <Grid container>
              {files.length > 0 &&
                files.map((file, idx) => (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    key={`${idx}-${file.name}`}
                    className={classes.fileItemContainer}
                  >
                    <Typography
                      className={classes.fileItemText}
                      color="textSecondary"
                      gutterBottom
                    >
                      {file.name}
                    </Typography>
                    <DeleteIcon
                      fontSize="small"
                      className={classes.fileItemIcon}
                      onClick={() => {
                        handleRemoveFile(file.name);
                      }}
                    />
                  </Grid>
                ))}
              <Grid item xs={12} sm={12} className={classes.fileInputContainer}>
                <FileInput
                  id={'Payments_multiplecontract_fileinput'}
                  ref={fileInputRef}
                  name="attachments"
                  accept={VALID_FILE_EXTENSIONS.map(ext => `.${ext}`).join(',')}
                  text="Cargar plantilla"
                  inputProps={{
                    onChange: handleChangeFile,
                    onClick: handleCleanPath
                  }}
                  helperText={
                    <span className={classes.noWrap}>{totalSize} MB/2 MB</span>
                  }
                  error={totalSize > MAX_TOTAL_FILE_SIZE}
                  disabled={totalSize > MAX_TOTAL_FILE_SIZE || files.length > 0}
                  buttonProps={{
                    color: 'default',
                    fullWidth: true
                  }}
                  multiple={false}
                  iconVisible
                  buttonClass={classes.attachFileButton}
                />
                <SweetAlert
                  classes={{ root: classes.sweetAlert }}
                  iconSize={25}
                  message={
                    <span>Solo se permite cargar un archivo a la vez</span>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            ref={inputRef}
            className={clsx([classes.recaptchaContainer])}
          >
            <Recaptcha
              captchaRef={recaptchaRef}
              referenceEl={inputRef}
              heightScale={0.9}
              locale={'es'}
              sitekey={config.recaptcha.siteKey}
              onResolved={onCaptchaResolved}
              onExpired={onCaptchaExpired}
              onLoaded={onCaptchaLoaded}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} className={classes.gridItem}>
          <BaseButton
            id="QueryPayment_button_submit"
            className={classes.addButton}
            onClick={handleLoadCards}
            disabled={!canSubmit}
            fullWidth={isMobileSize}
            color="primary"
            variant="outlined"
          >
            Agregar
          </BaseButton>
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  gridContainer: {
    margin: theme.spacing(3, 0),
    [theme.breakpoints.down('xs')]: {
      margin: [[0, 0, 30, 0]],
      padding: [[30, 16, 0, 16]]
    }
  },
  gridItem: {
    '&:nth-child(2)': {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    '&:last-child': {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 24
    },
    [theme.breakpoints.down('xs')]: {
      '&:nth-child(2)': {
        display: 'block',
        padding: [[20, 0, 0, 0]]
      },
      '&:last-child': {
        marginTop: 16
      }
    }
  },
  dragAndDropContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    margin: theme.spacing(3, 2.75, 0, 0),
    minHeight: 86,
    width: '100%',
    backgroundColor: '#F8F8F8',
    border: '1px dashed #DBDBDB',
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2, 0, 0, 0),
      padding: theme.spacing(1, 2)
    }
  },
  fileItemsAdded: {
    padding: theme.spacing(3, 2)
  },
  attachFileButton: {
    height: 40,
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('md')]: {
      width: 200
    }
  },
  titleText: {
    fontWeight: 600,
    fontSize: 12
  },
  informationText: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.color.default
  },
  boldText: {
    fontWeight: 600,
    '&:last-child': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  },
  downloadTemplateButton: {
    fontSize: 12,
    textTransform: 'unset',
    height: 40,
    whiteSpace: 'nowrap',
    padding: theme.spacing(1, 1.5)
  },
  downloadIcon: {
    marginLeft: theme.spacing(1)
  },
  addButton: {
    fontSize: 12,
    fontWeight: 500,
    minHeight: 40,
    height: 40
  },
  recaptchaContainer: {
    marginTop: 14,
    marginLeft: 'auto',
    paddingLeft: 0,
    [theme.breakpoints.down('xs')]: {
      margin: '6px auto 0px auto'
    }
  },
  fileItemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.dark,
    height: 40,
    margin: theme.spacing(0, 0, 2, 0),
    padding: theme.spacing(1.5, 2)
  },
  fileItemText: {
    fontWeight: 600,
    margin: 0
  },
  fileItemIcon: {
    cursor: 'pointer',
    width: 24,
    height: 24,
    color: theme.palette.color.danger
  },
  downloadAnchor: {
    textDecoration: 'none'
  },
  fileInputContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: theme.spacing(2, 0)
    }
  },
  sweetAlert: {
    height: 44,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2)
    }
  }
}));

export default MultipleLoadGDC;
