import React from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import BackIcon from '../../Components/CustomIcons/BackIcon';
import FlatButton from '../Buttons/FlatButton';
import RequestType from '../../Views/Requests/Utils/RequestType';
import RequestStatus from '../../Views/Requests/Utils/RequestStatus';
import { history } from '../../Routes/history';
import { Company } from '../../Configs/general';

const RequestCard = ({ request }) => {
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('750'));

  const { id, type, contractId, createdAt, status } = request;

  const handleRequestClick = () => {
    history.push({ pathname: `/solicitudes/${id}`, state: { request } });
  };

  return (
    <Grid container className={classes.cardContainer}>
      <Grid
        item
        container
        className={classes.typeContainer}
        alignItems="center"
      >
        <RequestType type={type} />
      </Grid>

      <Grid
        item
        container
        className={classes.detailsContainer}
        alignItems="center"
      >
        <Grid container direction="column" spacing={smallScreen ? 0 : 1}>
          <Grid item>
            <Typography>
              {Company.contractConjugation.capitalized.singular.main} nº{' '}
              {contractId}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              Fecha {moment(createdAt).format('DD/MM/YYYY')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography>Solicitud nº {id}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        container
        className={classes.statusContainer}
        alignItems="center"
      >
        <RequestStatus status={status} />
      </Grid>

      <Grid
        item
        container
        justify="flex-end"
        className={classes.buttonContainer}
      >
        <FlatButton
          id="Request_details"
          className={classes.actionButton}
          onClick={handleRequestClick}
        >
          Ver detalle
          <BackIcon size={16} className={classes.iconRightStyle} />
        </FlatButton>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  cardContainer: {
    animation: `$fadeIn 250ms ${theme.transitions.easing.easeInOut}`,
    backgroundColor: theme.palette.background.cardDark,
    borderRadius: theme.custom.borderRadius,
    marginBottom: theme.spacing(1.5),
    padding: '1em 1.5em',
    border: `1px solid ${theme.palette.background.border}`,
    position: 'relative',
    fontSize: 16,
    '&:last-of-type': {
      marginBottom: 0
    },
    [theme.breakpoints.down(750)]: {
      padding: '1em',
      fontSize: 14
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginBottom: theme.spacing()
    }
  },
  typeContainer: {
    marginBottom: theme.spacing(),
    [theme.breakpoints.up(750)]: {
      width: 'calc(4 / 12 * 100%)',
      marginBottom: 0
    }
  },
  detailsContainer: {
    order: 1,
    [theme.breakpoints.up(750)]: {
      width: 'calc(3 / 12 * 100%)',
      order: 0
    }
  },
  statusContainer: {
    [theme.breakpoints.up(750)]: {
      width: 'calc(2 / 12 * 100%)'
    }
  },
  buttonContainer: {
    [theme.breakpoints.up(750)]: {
      width: 'calc(3 / 12 * 100%)'
    }
  },
  actionButton: {
    fontWeight: 500,
    color: theme.palette.common.black,
    textTransform: 'none',
    padding: theme.spacing(),
    '&:hover': {
      color: theme.palette.primary.main
    },
    [theme.breakpoints.down(750)]: {
      position: 'absolute',
      right: theme.spacing(),
      bottom: theme.spacing(0.75)
    }
  },
  iconRightStyle: {
    transform: 'rotate(180deg)',
    fill: theme.palette.primary.main,
    marginLeft: theme.spacing()
  }
}));

export default RequestCard;
