const selectInputDefaultMessage = 'Seleccione una opción';

export const COLOMBIAN_DEPARTMENTS = [
  {
    value: 0,
    label: selectInputDefaultMessage
  },
  {
    value: 1,
    label: 'Atl\u00e1ntico'
  },
  {
    value: 2,
    label: 'Cesar'
  },
  {
    value: 3,
    label: 'Magdalena'
  }
];

export const COLOMBIAN_CITIES = [
  [{ value: 0, label: selectInputDefaultMessage }],
  [
    { value: 0, label: 'Baranoa' },
    { value: 1, label: 'Barranquilla' },
    { value: 2, label: 'Campo de la Cruz' },
    { value: 3, label: 'Candelaria' },
    { value: 4, label: 'Galapa' },
    { value: 5, label: 'Juan de Acosta' },
    { value: 6, label: 'Luruaco' },
    { value: 7, label: 'Malambo' },
    { value: 8, label: 'Manat\u00ed' },
    { value: 9, label: 'Palmar de Varela' },
    { value: 10, label: 'Pioj\u00f3' },
    { value: 11, label: 'Polonuevo' },
    { value: 12, label: 'Ponedera' },
    { value: 13, label: 'Puerto Colombia' },
    { value: 14, label: 'Repel\u00f3n' },
    { value: 15, label: 'Sabanagrande' },
    { value: 16, label: 'Sabanalarga' },
    { value: 17, label: 'Santa Luc\u00eda' },
    { value: 18, label: 'Santo Tom\u00e1s' },
    { value: 19, label: 'Soledad' },
    { value: 20, label: 'Su\u00e1n' },
    { value: 21, label: 'Tubar\u00e1' },
    { value: 22, label: 'Usiacur\u00ed' }
  ],
  [
    { value: 0, label: 'Aguachica' },
    { value: 1, label: 'Agust\u00edn Codazzi' },
    { value: 2, label: 'Astrea' },
    { value: 3, label: 'Becerril' },
    { value: 4, label: 'Bosconia' },
    { value: 5, label: 'Chimichagua' },
    { value: 6, label: 'Chiriguan\u00e1' },
    { value: 7, label: 'Curuman\u00ed' },
    { value: 8, label: 'El Copey' },
    { value: 9, label: 'El Paso' },
    { value: 10, label: 'Gamarra' },
    { value: 11, label: 'Gonz\u00e1lez' },
    { value: 12, label: 'La Gloria (Cesar)' },
    { value: 13, label: 'La Jagua de Ibirico' },
    { value: 14, label: 'La Paz' },
    { value: 15, label: 'Manaure Balc\u00f3n del Cesar' },
    { value: 16, label: 'Pailitas' },
    { value: 17, label: 'Pelaya' },
    { value: 18, label: 'Pueblo Bello' },
    { value: 19, label: 'R\u00edo de Oro' },
    { value: 20, label: 'San Alberto' },
    { value: 21, label: 'San Diego' },
    { value: 22, label: 'San Mart\u00edn' },
    { value: 23, label: 'Tamalameque' },
    { value: 24, label: 'Valledupar' }
  ],
  [
    { value: 0, label: 'Algarrobo' },
    { value: 1, label: 'Aracataca' },
    { value: 2, label: 'Ariguan\u00ed' },
    { value: 3, label: 'Cerro de San Antonio' },
    { value: 4, label: 'Chibolo' },
    { value: 5, label: 'Chibolo' },
    { value: 6, label: 'Ci\u00e9naga' },
    { value: 7, label: 'Concordia' },
    { value: 8, label: 'El Banco' },
    { value: 9, label: 'El Pi\u00f1\u00f3n' },
    { value: 10, label: 'El Ret\u00e9n' },
    { value: 11, label: 'Fundaci\u00f3n' },
    { value: 12, label: 'Guamal' },
    { value: 13, label: 'Nueva Granada' },
    { value: 14, label: 'Pedraza' },
    { value: 15, label: 'Piji\u00f1o del Carmen' },
    { value: 16, label: 'Pivijay' },
    { value: 17, label: 'Plato' },
    { value: 18, label: 'Pueblo Viejo' },
    { value: 19, label: 'Remolino' },
    { value: 20, label: 'Sabanas de San \u00c1ngel' },
    { value: 21, label: 'Salamina' },
    { value: 22, label: 'San Sebasti\u00e1n de Buenavista' },
    { value: 23, label: 'San Zen\u00f3n' },
    { value: 24, label: 'Santa Ana' },
    { value: 25, label: 'Santa B\u00e1rbara de Pinto' },
    { value: 26, label: 'Santa Marta' },
    { value: 27, label: 'Sitionuevo' },
    { value: 28, label: 'Tenerife' },
    { value: 29, label: 'Zapay\u00e1n' },
    { value: 30, label: 'Zona Bananera' }
  ]
];
