import React from 'react';

const DuplicateIcon = ({ width = 35, height = 35, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 56 56"
      {...rest}
    >
      <g transform="translate(-310.45 -379.239)">
        <rect
          width="56"
          height="56"
          transform="translate(310.45 379.239)"
          fill="none"
        />
        <g transform="translate(332.022 383.271)">
          <path
            d="M-1169.713,122.143l.282-1.581h2.344a6.446,6.446,0,0,0,6.438-6.439V104h0V85.04l-1.746.873a.8.8,0,0,1-.708,0l-2.54-1.27-2.565,1.271a.79.79,0,0,1-.7,0l-2.532-1.269-2.518,1.267a.788.788,0,0,1-.711,0l-2.512-1.267-2.571,1.27a.79.79,0,0,1-.7,0L-1183,84.644l-2.537,1.269a.793.793,0,0,1-.707,0l-1.75-.875V92.3l-1.581,1V83.76a.791.791,0,0,1,.375-.673.794.794,0,0,1,.77-.035l2.539,1.27,2.538-1.27a.787.787,0,0,1,.706,0l2.535,1.27,2.571-1.272a.791.791,0,0,1,.708,0l2.508,1.266,2.515-1.266a.787.787,0,0,1,.709,0l2.534,1.27L-1166,83.05a.8.8,0,0,1,.7,0l2.538,1.27,2.539-1.27a.781.781,0,0,1,.768.035.791.791,0,0,1,.375.673v18.92h0v11.443a8.03,8.03,0,0,1-8.019,8.021Z"
            transform="translate(1189.572 -82.968)"
            fill="#333"
            stroke="rgba(0,0,0,0)"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <path
            d="M350.636,406.461h-4.226a.791.791,0,1,1,0-1.581h4.226a.791.791,0,0,1,0,1.581Zm0-7.23h-4.226a.791.791,0,1,1,0-1.581h4.226a.791.791,0,0,1,0,1.581Z"
            transform="translate(-325.713 -387.974)"
            fill="#333"
          />
        </g>
        <g transform="translate(310.45 392.21)">
          <path
            d="M328.971,424.821H327.39V398.607a.79.79,0,0,1,1.143-.707l2.559,1.281,2.559-1.281a.789.789,0,0,1,.707,0l2.556,1.281,2.6-1.282a.789.789,0,0,1,.707,0l2.529,1.278,2.537-1.278a.787.787,0,0,1,.708,0l2.557,1.281,2.581-1.282a.8.8,0,0,1,.707,0l2.559,1.281,2.559-1.281a.792.792,0,0,1,1.145.707V419h-1.581v-19.11l-1.77.886a.794.794,0,0,1-.707,0l-2.56-1.281-2.582,1.283a.811.811,0,0,1-.705,0l-2.552-1.279-2.54,1.278a.784.784,0,0,1-.712,0l-2.532-1.279-2.594,1.283a.808.808,0,0,1-.7,0L334,399.492l-2.557,1.279a.794.794,0,0,1-.707,0l-1.768-.886Z"
            transform="translate(-315.74 -397.816)"
            fill="#333"
          />
          <path
            d="M354.314,429.468H328.088c-7.448,0-8.065-5.2-8.065-10.984a2.236,2.236,0,0,1,.667-1.6,2.122,2.122,0,0,1,1.585-.642H347.04a.789.789,0,0,1,.791.791V421.4a6.485,6.485,0,1,0,12.969,0V411.209h1.581V421.4A8.075,8.075,0,0,1,354.314,429.468Zm-32.052-11.644a.653.653,0,0,0-.658.648c0,6.514.936,9.414,6.484,9.414h21.436a8.062,8.062,0,0,1-3.275-6.485v-3.577H322.262Z"
            transform="translate(-320.023 -390.029)"
            fill="#333"
          />
          <path
            d="M349.337,412.2h-11.65a.791.791,0,0,1,0-1.581h11.65a.791.791,0,1,1,0,1.581Zm0-7.273H331.863a.791.791,0,0,1,0-1.581h17.474a.791.791,0,1,1,0,1.581Z"
            transform="translate(-313.599 -394.603)"
            fill="#333"
          />
        </g>
      </g>
    </svg>
  );
};

export default DuplicateIcon;
