import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Collapse,
  IconButton,
  useMediaQuery,
  Divider,
  Button
} from '@material-ui/core';
import clsx from 'clsx';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import EyeIcon from '../../../../Components/CustomIcons/EyeIcon';

import DeferredAccordion from './DeferredAccordion';
import BaseButton from '../../../../Components/Buttons/BaseButton';

import { numberWithDots } from '../../../../Utils/Format/MoneyFormat';
import AddItemButton from '../AddItemButton';

const DeferredProducts = ({
  type,
  productId,
  total,
  alwaysExtended = false,
  addedDeferreds,
  addDeferreds,
  removeDeferreds,
  countValids,
  financials,
  isActionDisabled
}) => {
  const classes = useStyles();
  const theme = useTheme();

  // * STATE HOOKS
  const [expanded, setExpanded] = useState(alwaysExtended);

  // * OTHER HOOKS
  const mediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  // * GLOBAL VARS
  const productAlreadyAdded = addedDeferreds.some(
    e => e.productId !== productId
  );

  const allOnClaim = countValids === 0;

  const buttonDisabled = productAlreadyAdded || allOnClaim || isActionDisabled;

  const addedDeferredsCount = addedDeferreds.filter(
    d => d.productId === productId
  ).length;

  const allAdded =
    addedDeferreds.length > 0 && addedDeferredsCount === countValids;

  // * FUNCTIONS
  const handleClick = (e, name) => {
    if (name === 'addRemove') {
      e.preventDefault();
      e.stopPropagation();
      return allAdded
        ? removeDeferreds('product', productId)
        : addDeferreds('product', productId);
    }
    setExpanded(!expanded);
  };

  return (
    <div className={classes.card}>
      <div
        className={clsx(classes.cardHeader, {
          [classes.collapsedCard]: !expanded,
          [classes.extendedCard]: expanded
        })}
        onClick={e => handleClick(e)}
      >
        <Grid container direction="column">
          <Grid item>
            <Grid item container justify="space-between" alignItems="center">
              <Grid item style={{ marginRight: 'auto' }}>
                <Typography className={classes.label}>Producto</Typography>
                <Typography className={classes.data}>
                  {type} • {productId}
                </Typography>
              </Grid>
              <Grid item>
                {!mediumScreen && (
                  <BaseButton
                    size="small"
                    color="primary"
                    variant="text"
                    className={clsx(classes.addButton, {
                      [classes.buttonDisabled]: buttonDisabled
                    })}
                    value="btn"
                    onClick={e => handleClick(e, 'addRemove')}
                    disabled={buttonDisabled}
                  >
                    {allAdded ? 'Quitar todo' : 'Agregar todo'}
                  </BaseButton>
                )}
              </Grid>
              <Grid item>
                <Grid item container alignItems="center">
                  <Grid item>
                    {mediumScreen ? (
                      <AddItemButton
                        onClick={e => handleClick(e, 'addRemove')}
                        disabled={buttonDisabled}
                        isAdding={allAdded}
                      />
                    ) : (
                      <div className={classes.totalBalanceContainer}>
                        <Typography
                          className={clsx(classes.label, classes.alignRight)}
                        >
                          Saldo por facturar
                        </Typography>
                        <Typography
                          className={clsx(
                            classes.data,
                            classes.total,
                            classes.alignRight
                          )}
                        >
                          {numberWithDots(total)}
                        </Typography>
                      </div>
                    )}
                  </Grid>
                  {!mediumScreen && (
                    <Grid item>
                      <IconButton
                        className={clsx(classes.expand, {
                          [classes.expandOpen]: expanded
                        })}
                        onClick={() => setExpanded(e => !e)}
                        aria-expanded={expanded}
                        aria-label="Ver más"
                        size="small"
                      >
                        <ArrowDropDownIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {mediumScreen && (
            <Grid item container>
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item xs={12}>
                <Grid item container justify="space-between">
                  <Grid item xs={6}>
                    <div className={classes.subheaderContainer}>
                      <Button
                        className={classes.seeMoreButton}
                        classes={{ root: classes.buttonRoot }}
                        color="primary"
                        onClick={handleClick}
                      >
                        {expanded ? 'Ocultar detalle' : 'Ver detalle'}
                        <EyeIcon
                          size={16}
                          className={classes.eyeIcon}
                          fill={theme.palette.primary.main}
                        />
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      className={clsx(classes.label, classes.alignRight)}
                    >
                      Saldo por facturar
                    </Typography>
                    <Typography
                      className={clsx(
                        classes.data,
                        classes.total,
                        classes.alignRight
                      )}
                    >
                      {numberWithDots(total)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {!mediumScreen && (
                <Grid>
                  <div className={classes.totalBalanceContainer}>
                    <Typography
                      className={clsx(classes.label, classes.alignRight)}
                    >
                      Saldo por facturar
                    </Typography>
                    <Typography
                      className={clsx(
                        classes.data,
                        classes.total,
                        classes.alignRight
                      )}
                    >
                      {numberWithDots(total)}
                    </Typography>
                  </div>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </div>

      <Collapse in={expanded} timeout="auto">
        {financials.map(
          (
            {
              financialId,
              total: totalFinancing,
              countValids: countValidsFinancing,
              deferreds
            },
            index
          ) => {
            return (
              <DeferredAccordion
                key={`${productId}-${financialId}-${index}`}
                type={type}
                financialId={financialId}
                total={totalFinancing}
                countValids={countValidsFinancing}
                deferreds={deferreds}
                addedDeferreds={addedDeferreds}
                addDeferreds={addDeferreds}
                removeDeferreds={removeDeferreds}
                productAlreadyAdded={productAlreadyAdded}
                isActionDisabled={isActionDisabled}
              />
            );
          }
        )}
      </Collapse>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column'
  },
  cardHeader: {
    padding: theme.spacing(3, 2, 3, 3),
    border: `1px solid ${theme.palette.background.border}`,
    cursor: 'pointer',
    backgroundColor: theme.palette.common.white,
    transition: theme.transitions.create('border-radius'),
    '&:hover': {
      backgroundColor: theme.palette.background.cardDark
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      padding: theme.spacing(2)
    }
  },
  totalBalanceContainer: {
    width: theme.spacing(19)
  },
  collapsedCard: {
    borderRadius: theme.custom.borderRadius
  },
  subheaderContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      justifyContent: 'space-between',
      width: '100%'
    }
  },
  extendedCard: {
    backgroundColor: theme.palette.background.accordion,
    borderTopLeftRadius: theme.custom.borderRadius,
    borderTopRightRadius: theme.custom.borderRadius
  },
  label: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.greyDark,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      fontSize: 10
    }
  },
  data: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.2,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 11
    }
  },
  total: {
    fontSize: 20,
    fontWeight: 600
  },
  alignRight: {
    textAlign: 'right'
  },
  buttonRoot: {
    padding: theme.spacing(1, 1, 1, 0)
  },
  addButton: {
    fontSize: 12,
    height: 32,
    padding: theme.spacing(1),
    transition: theme.transitions.create('color', {
      duration: '100ms'
    }),
    border: `1px solid ${theme.palette.primary.main}`
  },
  buttonDisabled: {
    borderColor: theme.palette.text.disabled,
    color: theme.palette.text.disabled
  },
  seeMoreButton: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.primary.main,
    textTransform: 'unset',
    marginRight: 'auto'
  },
  eyeIcon: {
    marginLeft: theme.spacing()
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  divider: {
    display: 'none',
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      display: 'flex',
      margin: theme.spacing(1, 0, 1.5, 0),
      width: '100%'
    }
  }
}));

export default DeferredProducts;
