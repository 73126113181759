/* eslint-disable complexity */
import React from 'react';
import clsx from 'clsx';
import _startCase from 'lodash/startCase';
import _lowerCase from 'lodash/lowerCase';

import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Divider, useMediaQuery } from '@material-ui/core';

import { history } from '../../../Routes/history';
import { ROUTE_NAMES } from '../../../Routes/Routes';

import { numberWithDots } from '../../../Utils/Format/MoneyFormat';

import BankIcon from '../../../Components/CustomIcons/BankTransactionIcon';
import CalendarIcon from '../../../Components/CustomIcons/CalendarIcon';
import BackIcon from '../../../Components/CustomIcons/BackIcon';
import ButtonWithIcon from '../../../Components/Buttons/ButtonWithIcon';

import {
  couponConceptDescription,
  couponConcepts,
  transactionStatusDescription
} from '../static/transactions_enums';
import { renderCouponId } from '../../../Utils/Format/Coupons';
import { Company } from '../../../Configs/general';
import BaseTooltip from '../../../Components/Tooltip/BaseTooltip';

const TransactionListItem = props => {
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );
  const mediumScreen = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.md)
  );

  const {
    id,
    status,
    bankName,
    date,
    transactionId,
    concept,
    couponId,
    contractId,
    contractAlias,
    totalValue,
    uuid,
    className
  } = props;

  const paymentColors = [classes.pending, classes.approved, classes.rejected];

  const paymentText = transactionStatusDescription[status];

  const paymentCssColor = paymentColors[status];

  const iconsConfig = {
    width: smallScreen ? 16 : 20,
    height: smallScreen ? 16 : 20
  };

  const handleDetailsClick = () => {
    history.push(`${ROUTE_NAMES.transaction}/${uuid}`, {
      from: `${ROUTE_NAMES.transactions}${window.location.search}`
    });
  };

  const isExternalPayment = concept === couponConcepts.OsfPaidCoupons;
  const externalPaymentHelpTooltip = () => {
    if (isExternalPayment) {
      return (
        <BaseTooltip
          className={classes.tooltip}
          tooltipText={'Este pago no fue realizado a través del portal web'}
          showTooltipOnClick
          startWithBreakline
        />
      );
    }
    return null;
  };

  return (
    <div id={id} className={clsx(classes.cardRoot, className)}>
      <Grid
        container
        justify="space-between"
        className={classes.mainInfoContainer}
      >
        <React.Fragment>
          <Grid item xs={6} sm={2} className={classes.mainInfoItem}>
            <Typography className={clsx(classes.label, classes.ellipsis)}>
              {mediumScreen ? 'Transacción' : 'Número de transacción'}
            </Typography>
            <Typography className={clsx(classes.data, classes.ellipsis)}>
              {transactionId}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            className={classes.mainInfoItem}
            style={{ minWidth: 158 }}
          >
            <Typography
              className={clsx(
                classes.label,
                classes.ellipsis,
                classes.conceptLabel
              )}
            >
              {smallScreen
                ? `Concepto: ${couponConceptDescription(concept)}`
                : 'Concepto de pago'}
            </Typography>
            {!smallScreen && (
              <Typography className={clsx(classes.data, classes.ellipsis)}>
                {couponConceptDescription(concept)}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} sm={2} className={classes.mainInfoItem}>
            <Typography className={clsx(classes.label, classes.ellipsis)}>
              {Company.contractConjugation.capitalized.singular.main}
            </Typography>
            <Typography className={clsx(classes.data, classes.ellipsis)}>
              {contractAlias && `${contractAlias} - `}
              {(contractId && `${contractId}`) || '-'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2} className={classes.mainInfoItem}>
            <Typography className={clsx(classes.label, classes.ellipsis)}>
              {mediumScreen ? 'Cupón' : 'Número de cupón'}
            </Typography>
            <Typography className={clsx(classes.data, classes.ellipsis)}>
              {couponId ? renderCouponId(couponId) : '-'}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={2}
            className={clsx(classes.alignRight, classes.mainInfoItem)}
          >
            <Typography
              className={clsx(
                classes.label,
                classes.ellipsis,
                classes.transactionLabel
              )}
            >
              {smallScreen ? 'Valor' : 'Valor de transacción'}
            </Typography>
            <Typography
              className={clsx(classes.data, classes.price, classes.ellipsis)}
            >
              {numberWithDots(totalValue)}
            </Typography>
          </Grid>
        </React.Fragment>
      </Grid>

      {smallScreen && (
        <Grid container justify="space-between">
          <Grid item container xs={7} alignItems="center" wrap="nowrap">
            <BankIcon className={classes.icon} {...iconsConfig} />
            <Typography
              className={clsx(classes.iconData, classes.ellipsis, {
                [classes.helpTooltip]: isExternalPayment
              })}
            >
              {_startCase(_lowerCase(bankName)) || '-'}
              {externalPaymentHelpTooltip()}
            </Typography>
          </Grid>
          <Grid
            item
            container
            justify="flex-end"
            xs={5}
            alignItems="center"
            wrap="nowrap"
          >
            <CalendarIcon className={classes.icon} {...iconsConfig} />
            <Typography className={clsx(classes.iconData)}>{date}</Typography>
          </Grid>
        </Grid>
      )}

      <Divider className={classes.divider} />

      <Grid container justify="flex-end">
        {!smallScreen && (
          <React.Fragment>
            <Grid item sm={3} className={classes.footerItem}>
              <Typography
                className={clsx(
                  classes.statusText,
                  paymentCssColor,
                  classes.ellipsis,
                  { [classes.mobileStatus]: smallScreen }
                )}
              >
                {paymentText}
              </Typography>
            </Grid>
            <Grid
              item
              sm={3}
              className={clsx(classes.footerItem, classes.alignRight)}
            >
              <BankIcon className={classes.icon} {...iconsConfig} />
              <Typography
                className={clsx(
                  classes.label,
                  classes.iconData,
                  classes.ellipsis,
                  { [classes.helpTooltip]: isExternalPayment }
                )}
              >
                {_startCase(_lowerCase(bankName)) || '-'}
                {externalPaymentHelpTooltip()}
              </Typography>
            </Grid>
            <Grid
              item
              sm={3}
              className={clsx(classes.footerItem, classes.alignRight)}
            >
              <CalendarIcon className={classes.icon} {...iconsConfig} />
              <Typography
                className={clsx(
                  classes.label,
                  classes.iconData,
                  classes.ellipsis
                )}
              >
                {!mediumScreen && 'Fecha:'} {date}
              </Typography>
            </Grid>
          </React.Fragment>
        )}
        <Grid item container sm={3} className={classes.footerDetails}>
          {smallScreen && (
            <Typography
              className={clsx(classes.statusText, paymentCssColor, {
                [classes.mobileStatus]: smallScreen
              })}
            >
              {paymentText}
            </Typography>
          )}
          {!isExternalPayment && (
            <ButtonWithIcon
              textClass={classes.details}
              buttonText={'Ver detalle'}
              onClick={handleDetailsClick}
            >
              <BackIcon className={classes.detailsIcon} size={16} />
            </ButtonWithIcon>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  cardRoot: {
    animation: `$fadeIn 250ms ${theme.transitions.easing.easeInOut}`,
    border: 'solid 1px #dbdbdb',
    borderRadius: theme.custom.borderRadius,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(3)
    },
    '&:last-of-type': {
      marginBottom: 0
    }
  },
  statusText: {
    fontSize: 14,
    fontWeight: 600
  },
  mobileStatus: {
    fontSize: 11,
    fontWeight: 600
  },
  alignRight: {
    textAlign: 'right'
  },
  ellipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  label: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.greyDark,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 10,
      marginBottom: theme.spacing(0.5)
    }
  },
  mediumLabel: {
    fontSize: 12,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginBottom: theme.spacing(0.5)
    }
  },
  data: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.2,
    paddingRight: theme.spacing(),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 12
    }
  },
  price: {
    fontSize: 18,
    fontWeight: 600,
    paddingRight: 0
  },
  cardFooter: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      justifyContent: 'flex-end'
    }
  },
  iconData: {
    fontWeight: 400,
    fontSize: 12,
    color: theme.palette.text.greyDark,
    lineHeight: 1.2
  },
  details: {
    color: theme.palette.common.black,
    fontWeight: 600
  },
  detailsText: {
    fontSize: 13,
    marginRight: theme.spacing(),
    fontWeight: 500
  },
  divider: {
    margin: `${theme.spacing(2)}px 0`
  },
  footerItem: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(),
    flexShrink: 0
  },
  detailsIcon: {
    transform: 'rotate(180deg)',
    fill: theme.palette.primary.main
  },
  approved: {
    color: '#22CF5C'
  },
  pending: {
    color: '#FCC52C'
  },
  rejected: {
    color: '#F86257'
  },
  mobileConcept: {
    fontSize: 12,
    fontWeight: 600
  },
  mainInfoContainer: {
    marginBottom: theme.spacing(),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2)
    }
  },
  footerDetails: {
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end'
    }
  },
  mainInfoItem: {
    [theme.breakpoints.down('xs')]: {
      '&:nth-child(1)': {
        order: 1
      },
      '&:nth-child(2)': {
        order: 0
      },
      '&:nth-child(3)': {
        order: 5
      },
      '&:nth-child(4)': {
        marginBottom: theme.spacing(1),
        order: 4
      },
      '&:nth-child(5)': {
        order: 3
      }
    }
  },
  conceptLabel: {
    [theme.breakpoints.down('xs')]: {
      color: theme.palette.common.black,
      fontSize: 12,
      fontWeight: 600,
      marginBottom: 12
    }
  },
  transactionLabel: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  },
  helpTooltip: {
    display: 'flex'
  },
  tooltip: {
    marginLeft: theme.spacing(1)
  }
}));

export default TransactionListItem;
