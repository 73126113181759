import { logoutIOSSession, updateIOSToken } from '../IOS/actions';
import { logoutAndroidSession, updateAndroidToken } from '../Android/actions';
import { PortalCheck } from '../../Configs/general';
import { deserializeToken } from '../JWT/token';
import moment from 'moment-timezone';

export const logoutUser = (setCurrentUser, isAccountDeleted = false) => {
  setCurrentUser(null);

  if (PortalCheck.isGascaribe) {
    logoutAndroidSession({ isAccountDeleted });
    logoutIOSSession({ isAccountDeleted });
  }
};

export const updateUser = (setCurrentUser, token, appToken) => {
  if (setCurrentUser) {
    setCurrentUser({ token, ...deserializeToken(token) });
  }

  if (PortalCheck.isGascaribe) {
    updateAndroidToken(token, appToken);
    updateIOSToken(token, appToken);
  }
};

//Compare if the validation date is greater than 6 months
export function doesPhoneNeedsRevalidation(phoneValidationDate) {
  const today = moment();
  const needsRevalidation =
    today.diff(phoneValidationDate, 'months', true) >= 6;
  return needsRevalidation;
}
