import React, {
  Fragment,
  useCallback,
  useState,
  useContext,
  useEffect
} from 'react';
import clsx from 'clsx';
import moment from 'moment';

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import { history } from '../../../Routes/history';
import { ROUTE_NAMES } from '../../../Routes/Routes';
import {
  BrillaVisitAPI,
  HaveBrillaVisitRequestAPI
} from '../../../API/Brilla/BrillaAPI';

import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import _get from 'lodash/get';

import BaseButton from '../../../Components/Buttons/BaseButton';
import RequestDialog from '../../../Components/Dialogs/RequestDialog';
import BrillaVisitDialog from '../../../Components/Dialogs/BrillaVisitDialog';
import { SlideUpTransition } from '../../../Components/Transitions/Transitions';
import WithRequestIcon from '../../../Components/CustomIcons/IcActiveRequest';

import {
  UserContext,
  UserDispatchContext
} from '../../../Contexts/UserContext';
import { ContractSelectedContext } from '../../../Contexts/ContractsContext';
import { AlertsDispatchContext } from '../../../Contexts/AlertsContext';
import {
  redirectOnAuthFailure,
  extractErrorMessage
} from '../../../Utils/Errors/Errors';
import { formatAddress } from '../../../Utils/Format/Address';
import { capitalizeFirstLetter } from '../../../Utils/Format/Names';

import SelectInput from '../../../Components/Inputs/SelectInput';
import AlertFullView from '../../../Components/Alerts/AlertFullView';
import FullSizeProgress from '../../../Components/Progress/FullSize/FullSizeProgress';
import CompositeSelectInput from '../../../Components/Inputs/CompositeSelectInput';
import TextInput from '../../../Components/Inputs/TextInput';
import { Company } from '../../../Configs/general';
import { logoutUser } from '../../../Utils/User/Actions';

const IDENTIFICATION_OPTIONS = [
  { label: 'CC', value: 'CC' },
  { label: 'PP', value: 'PP' },
  { label: 'CE', value: 'CE' },
  { label: 'TI', value: 'TI' }
];

const visitSchema = yup.object({
  mainCategory: yup
    .string()
    .trim()
    .required('Seleccione algún elemento'),
  category: yup.mixed().when('mainCategory', {
    is: '',
    then: yup.string().trim(),
    otherwise: yup
      .string()
      .trim()
      .required('Seleccione algún elemento')
  }),
  firstName: yup
    .string()
    .trim()
    .required('Ingresa tu nombre'),
  lastName: yup
    .string()
    .trim()
    .required('Ingresa tus apellidos'),
  identification: yup.mixed().when('identificationType', {
    is: 'PP',
    then: yup
      .string()
      .trim()
      .matches(/^[0-9a-zA-Z]+$/, 'Ingresa un número de pasaporte válido')
      .required('Ingresa tu número de pasaporte'),
    otherwise: yup
      .string()
      .trim()
      .matches(/^[0-9]+$/, 'Ingresa un número de identificación válido')
      .required('Ingresa tu número de identificación')
  }),
  identificationType: yup
    .string()
    .oneOf(['CC', 'PP', 'CE', 'TI'], 'Debes escoger una opción correcta')
    .required('Debes ingresar un tipo de identificación')
});

const BrillaVisitGDG = props => {
  const { open, setDialog, title } = props;
  // * CONTEXTS
  const currentUser = useContext(UserContext);
  const setCurrentUser = useContext(UserDispatchContext);
  const selectedContract = useContext(ContractSelectedContext);
  const setAlert = useContext(AlertsDispatchContext);
  const authToken = _get(currentUser, 'token');

  // * HOOKS
  const [infoDialog, setInfoDialog] = useState(false);
  const [itemData, setItemData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [brillaVisitData, setBrillaVisitData] = useState([]);
  const [previousRequest, setPreviousRequest] = useState(null);

  const classes = useStyles();

  const {
    handleSubmit,
    control,
    errors,
    formState,
    register,
    watch,
    setValue
  } = useForm({
    validationSchema: visitSchema,
    mode: 'onChange'
  });

  const createBrillaLines = data => {
    const parents = data.map(subline => {
      return subline.attributes.parent;
    });

    const uniqueParents = parents.reduce((last, current) => {
      const line = last.find(item => item.id === current.id);
      if (!line) {
        return last.concat([current]);
      }
      return last;
    }, []);

    const formattedParents = uniqueParents.map(line => {
      return {
        value: line.id.toString(),
        label: capitalizeFirstLetter(line.name)
      };
    });

    return formattedParents;
  };

  const createBrillaSublines = data => {
    const sublines = data.map(subline => {
      return {
        value: subline.id,
        label: capitalizeFirstLetter(subline.attributes.name),
        category: {
          value: subline.attributes.parent.id.toString(),
          label: capitalizeFirstLetter(subline.attributes.parent.name)
        }
      };
    });

    return sublines;
  };

  useEffect(() => {
    if (!authToken) {
      history.replace('/');
      return;
    }

    if (!selectedContract) {
      history.replace(ROUTE_NAMES.contracts);
      return;
    }

    let ignoreRequest = false;

    setLoading(true);

    if (ignoreRequest) {
      return;
    }

    const validateRequest = async () => {
      const response = await HaveBrillaVisitRequestAPI(
        authToken,
        selectedContract.id
      );

      setLoading(false);
      if (!response.success) {
        if (
          redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
        ) {
          return;
        }

        if (!response.error.response.data.data) {
          setAlert({
            type: 'error',
            message: 'Ocurrió un error, intente más tarde'
          });
          setDialog(false);
          return;
        }

        setBrillaVisitData(response.error.response.data.data);
        setPreviousRequest(null);
        return;
      }

      const { id, date } = response.data.data;
      const formattedDate = moment(date).format('DD-MM-YYYY');

      setPreviousRequest({ id, date: formattedDate });
    };

    validateRequest();

    return () => {
      ignoreRequest = true;
    };
  }, [selectedContract, authToken, setCurrentUser, setAlert, setDialog]);

  const canSubmitItem = formState.isValid;
  const watchForm = watch(['mainCategory', 'category']);

  const brillaLines = createBrillaLines(brillaVisitData);
  const brillaSublines = createBrillaSublines(brillaVisitData);
  const selectedSublines = brillaSublines.filter(
    item => item.category.value === watchForm.mainCategory || item.value === ''
  );

  const handleClose = useCallback(
    event => {
      event.preventDefault();
      setDialog(false);
    },
    [setDialog]
  );

  /* This useEffect is made to put the value of the category as '' everytime
  the user select a different main category */
  useEffect(() => {
    if (
      watchForm.category &&
      watchForm.category !== '' &&
      !selectedSublines.find(item => item.value === watchForm.category)
    ) {
      setValue('category', '', true);
    }
  }, [watchForm.category, selectedSublines, setValue]);

  const openInfoDialog = useCallback(
    async values => {
      const data = {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        identification: values.identification.trim(),
        identificationType: values.identificationType.trim(),
        lineName: brillaLines
          .find(item => item.value === values.mainCategory)
          .label.trim(),
        sublineName: selectedSublines
          .find(item => item.value === values.category)
          .label.trim(),
        categoryId: values.category.trim()
      };

      setInfoDialog(true);
      setItemData(data);
    },
    [selectedSublines, brillaLines]
  );

  const brillaVisitRequest = useCallback(async () => {
    const response = await BrillaVisitAPI(
      authToken,
      selectedContract.id,
      itemData
    );

    if (response.success) {
      setAlert({
        type: 'success',
        message: 'Solicitud de visita de venta de brilla generada exitosamente'
      });
      setDialog(false);
      return { unmounting: true, closeDialog: false };
    }

    if (
      redirectOnAuthFailure(response, '/', () => logoutUser(setCurrentUser))
    ) {
      return { unmounting: true, closeDialog: false };
    }

    setAlert({
      type: 'error',
      message: extractErrorMessage(response).message
    });
    return { unmounting: false, closeDialog: false };
  }, [
    authToken,
    selectedContract,
    setCurrentUser,
    setAlert,
    itemData,
    setDialog
  ]);

  if (!currentUser) {
    return <Fragment></Fragment>;
  }
  const renderRequestDialog = () => {
    if (!infoDialog) {
      return;
    }

    return (
      <RequestDialog
        open={infoDialog}
        setDialog={setInfoDialog}
        requestTitle={'Solicita tu asesoría para crédito Brilla'}
        requestCallback={brillaVisitRequest}
        withoutObservation={true}
        requestNode={
          <Fragment>
            <Typography className={classes.requestText} paragraph>
              Por este medio realizarás la solicitud de Visita de venta de
              Brilla. La solicitud se realizará para{' '}
              {Company.contractConjugation.regular.singular.article}{' '}
              <span className={classes.textBold}>#{selectedContract.id}</span>{' '}
              con dirección:{' '}
              <span className={clsx([classes.textBold, classes.textCaps])}>
                {formatAddress(selectedContract, true)}
              </span>
              .
            </Typography>
            <Typography className={classes.text}>
              Una vez realizada, se te informará por correo electrónico y
              celular el estado de tu solicitud. De igual forma, nuestros
              agentes te contactarán pronto.
            </Typography>
          </Fragment>
        }
      />
    );
  };

  const renderActions = () => {
    return (
      <Fragment>
        <BaseButton
          id="BrillaVisitDialog_button_cancel"
          onClick={handleClose}
          variant="outlined"
          color="primary"
          size="small"
        >
          Cancelar
        </BaseButton>
        {!previousRequest && (
          <BaseButton
            id="BrillaVisitDialog_button_submit"
            type="submit"
            color="primary"
            size="small"
            form="confirmVisitaForm"
            autoFocus
            onClick={handleSubmit(openInfoDialog)}
            disabled={!canSubmitItem}
          >
            Continuar
          </BaseButton>
        )}
      </Fragment>
    );
  };

  const renderContent = () => {
    return (
      <Fragment>
        <form id="confirmVisitaForm" onSubmit={handleSubmit(openInfoDialog)}>
          <Typography className={classes.lineText}>
            Selecciona la categoría del producto deseado:
          </Typography>
          <Grid container className={classes.optionRow}>
            <Controller
              as={SelectInput}
              id="mainCategory"
              label={'¿Qué deseas financiar?'}
              name="mainCategory"
              control={control}
              error={Boolean(errors.mainCategory)}
              helperText={errors.mainCategory && errors.mainCategory.message}
              margin="none"
              fullWidth
              options={brillaLines}
              defaultValue=""
            />
          </Grid>
          <Fragment>
            <Typography
              className={clsx(classes.lineText, {
                [classes.disabledText]: Boolean(!watchForm.mainCategory)
              })}
            >
              Selecciona el producto deseado:
            </Typography>
            <Grid container className={classes.optionRow}>
              <Controller
                as={
                  <SelectInput
                    className={clsx({
                      [classes.disabled]: Boolean(!watchForm.mainCategory)
                    })}
                  />
                }
                id="category"
                label={'¿Qué deseas financiar?'}
                name="category"
                control={control}
                error={Boolean(errors.category)}
                helperText={errors.category && errors.category.message}
                margin="none"
                fullWidth
                options={selectedSublines}
                defaultValue=""
                disabled={Boolean(!watchForm.mainCategory)}
              />
            </Grid>
            <Fragment>
              <Typography className={classes.lineText}>
                Ingresa tus datos personales:
              </Typography>
              <Grid container spacing={2} className={classes.fullNameInput}>
                <Grid item sm={6} className={classes.inputFieldContainer}>
                  <TextInput
                    inputRef={register}
                    id="BrillaVisit_input_firstName"
                    required
                    fullWidth
                    margin="none"
                    label="Nombres"
                    error={Boolean(errors.firstName)}
                    helperText={errors.firstName && errors.firstName.message}
                    name="firstName"
                    defaultValue={currentUser.firstName}
                  />
                </Grid>
                <Grid item sm={6} className={classes.inputFieldContainer}>
                  <TextInput
                    inputRef={register}
                    id="BrillaVisit_input_lastName"
                    required
                    fullWidth
                    margin="none"
                    label="Apellidos"
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName && errors.lastName.message}
                    name="lastName"
                    defaultValue={currentUser.lastName}
                  />
                </Grid>
              </Grid>
              <Grid item sm={12} className={classes.optionRow}>
                <CompositeSelectInput
                  inputId="BrillaVisit_input_identification"
                  selectId="BrillaVisit_input_identificationType"
                  options={IDENTIFICATION_OPTIONS}
                  fullWidth
                  TextInputProps={{
                    label: 'Identificación',
                    name: 'identification',
                    defaultValue: currentUser.identification || '',
                    required: true,
                    inputRef: register,
                    fullWidth: true,
                    margin: 'none'
                  }}
                  SelectInputProps={{
                    name: 'identificationType',
                    defaultValue: currentUser.identificationType || 'CC',
                    control
                  }}
                  error={Boolean(errors.identification)}
                  helperText={
                    errors.identification && errors.identification.message
                  }
                />
              </Grid>
            </Fragment>
          </Fragment>
        </form>
      </Fragment>
    );
  };

  const renderRequestAlert = () => {
    return (
      <AlertFullView
        classes={{
          message: classes.alertMessage,
          subMessage: classes.alertSubMessage,
          icon: classes.alertIcon
        }}
        icon={WithRequestIcon}
        title={
          <Fragment>
            Ya cuentas con una solicitud para{' '}
            <strong>visita de venta de Brilla</strong>
            . <br /> Creada el {previousRequest.date} con número de solicitud{' '}
            {previousRequest.id}.
          </Fragment>
        }
        subtitle={
          'Te invitamos a estar atento a tu celular y correo electrónico para cualquier novedad.'
        }
      />
    );
  };

  const isMobileSize = isWidthDown('sm', props.width);

  if (loading) {
    return <FullSizeProgress />;
  }

  return (
    <Fragment>
      {renderRequestDialog()}
      <BrillaVisitDialog
        open={open}
        handleClose={handleClose}
        title={previousRequest ? 'Solicitud activa' : title}
        actions={renderActions}
        content={previousRequest ? renderRequestAlert : renderContent}
        fullScreen={isMobileSize}
        TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
        contentSize={isMobileSize ? undefined : 'small'}
      />
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left'
  },
  optionRow: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  lineText: {
    fontSize: 14,
    color: theme.palette.color.common
  },
  optionWithErrors: {
    marginBottom: -17
  },
  fieldButton: {
    height: 58
  },
  sweetAlert: {
    width: '100%'
  },
  formControl: {
    width: 424
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  sweetAlertText: {
    fontSize: 12,
    fontWeight: 'bold'
  },
  helperText: {
    fontSize: 12,
    marginTop: 5
  },
  textBold: {
    fontWeight: 500
  },
  textCaps: {
    textTransform: 'capitalize'
  },
  requestText: {
    maxWidth: '100%'
  },
  text: {
    maxWidth: '100%'
  },
  disabled: {
    opacity: 0.5
  },
  disabledText: {
    opacity: 0.3
  },
  alertMessage: {
    fontSize: 16,
    marginTop: -24,
    [theme.breakpoints.up('sm')]: {
      marginTop: 0
    }
  },
  alertSubMessage: {
    fontSize: 16
  },
  alertIcon: {
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0
    }
  },
  fullNameInput: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2)
  },
  inputFieldContainer: {
    width: '100%',
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(0)
    }
  }
}));

export default withWidth()(BrillaVisitGDG);
