import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import DonationPse from './DonationPse';

const PaymentsSecondStep = props => {
  const { setPayerInfo, setDisabledNext } = props;

  // * STATE HOOKS;

  // * OTHER HOOKS
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container maxWidth="md" className={classes.root}>
      <DonationPse
        setDisabledNext={setDisabledNext}
        setPayerInfo={setPayerInfo}
      />
    </Container>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'space-around',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    // * Responsive
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing()
    }
  }
}));

export default PaymentsSecondStep;
