import React, { createContext, useState, useCallback, useEffect } from 'react';
import * as Sentry from '@sentry/browser';

import { history } from '../Routes/history';
import FullSizeProgress from '../Components/Progress/FullSize/FullSizeProgress';
import { RefreshTokenAPI } from '../API/UserAPI';
import _isFunction from 'lodash/isFunction';
import { DigitalInvoiceBannerDateKey } from '../Enums/invoices';
import { logoutUser, updateUser } from '../Utils/User/Actions';

export const UserContext = createContext(null);
export const UserDispatchContext = createContext(null);

const LocalStorageKey = 'currentUser';

export const UserProvider = ({ children }) => {
  const storedUser = localStorage.getItem(LocalStorageKey);

  const [currentUser, setCurrentUser] = useState(
    storedUser ? JSON.parse(storedUser) : null
  );

  const [loading, setLoading] = useState(true);

  const dispatch = useCallback(
    user => {
      if (_isFunction(user)) {
        setCurrentUser(curUser => {
          const newUser = user(curUser);
          if (newUser) {
            localStorage.setItem(LocalStorageKey, JSON.stringify(newUser));
          } else {
            localStorage.removeItem(LocalStorageKey);
          }
          return newUser;
        });
        return;
      }

      if (user) {
        localStorage.setItem(LocalStorageKey, JSON.stringify(user));
        updateUser(setCurrentUser, user.token, user.appToken);
      } else {
        localStorage.removeItem(LocalStorageKey);
        localStorage.removeItem(DigitalInvoiceBannerDateKey);
        logoutUser(setCurrentUser);
      }
    },
    [setCurrentUser]
  );

  useEffect(() => {
    // Refresh the login token on every page load (not on every route transition)

    // Grab the user directly from the local storage because we want this hook to
    // run once and only once upon application load. We do not want to introduce a
    // dependency on the currentUser state variable.
    const userStorage = localStorage.getItem(LocalStorageKey);
    const user = userStorage ? JSON.parse(userStorage) : null;
    if (!user) {
      setLoading(false);
      return;
    }

    const refreshToken = async () => {
      const response = await RefreshTokenAPI(user.token);

      if (response.success) {
        const { data: token, dataApp: appToken } = response.data;
        dispatch({
          token,
          appToken
        });
        setLoading(false);
        return;
      }

      dispatch(null);
      setLoading(false);
      history.replace('/');
    };

    refreshToken();
  }, [dispatch]);

  useEffect(() => {
    const listener = () => {
      const user = localStorage.getItem(LocalStorageKey);
      setCurrentUser(user ? JSON.parse(user) : null);
    };
    window.addEventListener('storage', listener);
    return () => window.removeEventListener('storage', listener);
  }, []);

  useEffect(() => {
    Sentry.configureScope(scope => {
      if (currentUser) {
        scope.setUser({ id: currentUser.id, email: currentUser.email });
      } else {
        scope.setUser(null);
      }
    });
  }, [currentUser]);

  if (loading) {
    return <FullSizeProgress />;
  }

  return (
    <UserContext.Provider value={currentUser}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
};
