import React from 'react';
import { portal, Portal } from '../../../Configs/general';

import RequestsGDC from './Requests.GDC';

const Requests = () => {
  switch (portal) {
    case Portal.Gascaribe:
      return <RequestsGDC />;
    case Portal.Efigas:
      return <RequestsGDC />;
    default:
      return <></>;
  }
};

export default Requests;
