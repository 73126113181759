import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { history } from '../../../Routes/history';
import { ROUTE_NAMES } from '../../../Routes/Routes';
import BaseButton from '../../../Components/Buttons/BaseButton';

const BannerContractLarge = require('../../../Assets/images/contract_banner@2x.png');
const BannerContractSmall = require('../../../Assets/images/contract_banner_mobile.png');

const NoContractBanner = props => {
  const { contractCompanyReferer } = props;
  // * STATE HOOKS

  // * OTHER HOOKS
  const classes = useStyles();

  return (
    <div
      id="NoContractBanner_div_bannerContainer"
      className={classes.bannerContainer}
    >
      <Typography
        id="NoContractBanner_text_bannerText"
        className={classes.bannerText}
      >
        Para disfrutar de más beneficios,{' '}
        <span className={classes.bannerTextNoBreak}>te invitamos a</span> <br />
        <span className={classes.bannerTextBold}>
          que asocies {contractCompanyReferer}
        </span>
      </Typography>
      <BaseButton
        id="NoContractBanner_button_associate"
        color="secondary"
        variant="contained"
        className={classes.bannerButton}
        onClick={() => history.push(ROUTE_NAMES.associate)}
      >
        Asociar
      </BaseButton>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  bannerContainer: {
    height: '200px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: `${theme.spacing(4)}px`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundImage: `url(${BannerContractSmall})`,
    [theme.breakpoints.up('sm')]: {
      backgroundImage: `url(${BannerContractLarge})`
    }
  },
  bannerText: {
    color: theme.palette.common.white,
    textAlign: 'end',
    fontSize: 12,
    fontWeight: 600,
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  },
  bannerTextBold: {
    fontSize: 18,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16
    }
  },
  bannerTextNoBreak: { whiteSpace: 'nowrap' },
  bannerButton: {
    fontSize: 12,
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    lineHeight: 1.29,
    boxShadow: 'none',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  }
}));

export default NoContractBanner;
