/* eslint-disable func-names */
// IE9+ does not support ChildNode.remove, we polyfill it manually.
// from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
(function(arr) {
  arr.forEach(item => {
    if (item.hasOwnProperty('remove')) {
      return;
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode === null) {
          return;
        }
        this.parentNode.removeChild(this);
      }
    });
  });
  // eslint-disable-next-line
}([Element.prototype, CharacterData.prototype, DocumentType.prototype]));

/* Workaround: The Google Translate Chrome extension uses Node.className.indexOf in
 * every element of the page when translating, this assumes that className will always
 * be a String, but SVGs have instances of SVGAnimatedString as their ClassName,
 * this causes it to throw an exception on any page that contains an SVG.
 * We manually define indexOf for the SVGAnimatedString so that we no longer get said exception.
 * See https://github.com/airbnb/lottie-web/issues/1134 and
 * https://medium.com/@amir.harel/a-b-target-classname-indexof-is-not-a-function-at-least-not-mine-8e52f7be64ca
 * for references.
 */
SVGAnimatedString.prototype.indexOf = () => -1;
