import React, { useCallback } from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  CategoryScale,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { makeStyles } from '@material-ui/styles';
import { Button, ButtonGroup, Typography } from '@material-ui/core';
import { useState } from 'react';
import clsx from 'clsx';

ChartJS.register(
  CategoryScale,
  BarElement,
  Title,
  Tooltip,
  LinearScale,
  Legend
);

/**
 *
 * @param {title} title  is the top title of the graphic
 * @param {features} features  are the references array to the bars that are graphic !Important (features.length === data.length)
 * @param {data} data  is the data array to be graphed, data model [...{ label string, data []int, backgroundColor  string}]
 * @param {height} height  this is optional, it changes the size in height of the graph
 */
const BarChart = ({ title = 'Graph', features = [], data = [], height }) => {
  const classes = useStyles();

  const [consumption, setConsumption] = useState(1);

  const graphOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top',
        onClick: (event, legendItem, legend) => {
          const index = legendItem.datasetIndex;
          const chart = legend.chart;
          chart.show(index);
          chart.hide((index + 1) % 2);
          event.native.target.style.cursor = 'default';
        },
        onHover: (event, item) => {
          event.native.target.style.cursor = item.hidden
            ? 'pointer'
            : 'default';
        },
        onLeave: event => {
          event.native.target.style.cursor = 'default';
        }
      },
      title: {
        display: true,
        text: ''
      },
      datalabels: {
        display: true,
        formatter: (value, context) =>
          `${Math.round(value)}${context.dataset.label}`,
        anchor: 'end',
        offset: -20,
        align: 'start'
      }
    }
  };

  const dataBar = {
    labels: features,
    datasets: [...data]
  };

  const onClick = useCallback(
    index => {
      setConsumption(index === 0 ? 1 : 0);
      dataBar.datasets[index].hidden = false;
      dataBar.datasets[(index + 1) % 2].hidden = true;
    },
    [dataBar.datasets]
  );

  if (features.length === 0 || data.length === 0) {
    return <></>;
  }

  return (
    <>
      <div className={classes.legend}>
        <Typography className={classes.title}>{title}</Typography>
        <ButtonGroup disableElevation color="primary">
          {data.map(info => (
            <Button
              key={`consumption-${info.index}`}
              id={`consumption-${info.label}`}
              size="small"
              disabled={info.index !== consumption}
              onClick={() => onClick(info.index)}
              className={clsx(classes.sizeButton, info.buttonStyle)}
            >
              {info.label}
            </Button>
          ))}
        </ButtonGroup>
      </div>
      <Bar
        plugins={[ChartDataLabels]}
        options={graphOptions}
        height={height}
        data={dataBar}
      />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  legend: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'inline-grid',
    paddingTop: theme.spacing()
  },
  title: {
    textAlign: 'center',
    padding: theme.spacing(),
    fontWeight: '600',
    color: theme.palette.text.greyDark
  },
  sizeButton: {
    color: theme.palette.common.white,
    textTransform: 'none',
    width: 55
  }
}));

export default BarChart;
