import * as React from 'react';

function DiamondIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || '24'}
      height={props.size || '24'}
      viewBox="0 0 24 24"
      {...props}
    >
      <g id="ic_diamond" transform="translate(-240 -112)">
        <rect
          id="Rectángulo_1655"
          data-name="Rectángulo 1655"
          width={props.size || '24'}
          height={props.size || '24'}
          transform="translate(240 112)"
          fill="none"
        />
        <g id="diamond-outline" transform="translate(209.999 67)">
          <path
            id="Trazado_667"
            data-name="Trazado 667"
            d="M32.154,54.259l9.275,12.03a.722.722,0,0,0,1.146,0l9.275-12.03a.738.738,0,0,0,.042-.837l-3.146-5.078A.724.724,0,0,0,48.13,48H35.873a.724.724,0,0,0-.616.344l-3.146,5.078A.738.738,0,0,0,32.154,54.259Zm.562-.545H51.287"
            transform="translate(0 0)"
            fill="none"
            stroke="#104eb2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Trazado_668"
            data-name="Trazado 668"
            d="M124.857,48.714l-2.143,5L118.429,48M112,48.714l2.143,5L118.429,48m0,17.857-4.286-12.143m4.286,12.143,4.286-12.143"
            transform="translate(-76.427 0)"
            fill="none"
            stroke="#104eb2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
}

export default DiamondIcon;
