import React, { Fragment } from 'react';
import {
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Link
} from '@material-ui/core';
import { MainSiteUrlLink } from '../../../Configs/Links';
import { Company } from '../../../Configs/general';

const ContractImage = require('../../../Assets/images/invoice_contract_help.png');
const CouponImage = require('../../../Assets/images/invoice_coupon_help.png');

const HelpGDC = ({
  expanded,
  handleChange,
  displayIcon,
  classes,
  terms: QUESTIONS
}) => {
  return (
    <Fragment>
      <ExpansionPanel
        square
        expanded={expanded === QUESTIONS.first}
        onChange={handleChange(QUESTIONS.first)}
        classes={{
          root: classes.expansionPanel,
          expanded: classes.expansionPanelExpanded
        }}
      >
        <ExpansionPanelSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          classes={{
            root: classes.expansionPanelSummary,
            expanded: classes.expansionPanelSummaryExpanded
          }}
        >
          <Fragment>
            {displayIcon(QUESTIONS.first)}
            <Typography variant="h2" className={classes.title}>
              {'¿Qué puedo hacer en este portal?'}
            </Typography>
          </Fragment>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{
            root: classes.expansionPanelDetails
          }}
        >
          <Typography className={classes.description}>
            {
              'El portal te permite realizar el pago de las facturas de Gases del Caribe S.A E.S.P, así como visualizarlas y programar el envío mensual por correo electrónico.'
            }
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        square
        expanded={expanded === QUESTIONS.second}
        onChange={handleChange(QUESTIONS.second)}
        classes={{
          root: classes.expansionPanel,
          expanded: classes.expansionPanelExpanded
        }}
      >
        <ExpansionPanelSummary
          aria-controls="panel2d-content"
          id="panel2d-header"
          classes={{
            root: classes.expansionPanelSummary,
            expanded: classes.expansionPanelSummaryExpanded
          }}
        >
          <Fragment>
            {displayIcon(QUESTIONS.second)}
            <Typography variant="h2" className={classes.title}>
              {'¿Debo registrarme para poder realizar el pago de mi factura?'}
            </Typography>
          </Fragment>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{
            root: classes.expansionPanelDetails
          }}
        >
          <Typography className={classes.description}>
            Puede realizar el pago de su factura sin registrarse ingresando el
            número de Cupón para Pagos o el número de{' '}
            {Company.contractConjugation.regular.singular.main}, pero no podrá
            visualizar la factura hasta no registrarse y asociar su{' '}
            {Company.contractConjugation.regular.singular.main}.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        square
        expanded={expanded === QUESTIONS.third}
        onChange={handleChange(QUESTIONS.third)}
        classes={{
          root: classes.expansionPanel,
          expanded: classes.expansionPanelExpanded
        }}
      >
        <ExpansionPanelSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          classes={{
            root: classes.expansionPanelSummary,
            expanded: classes.expansionPanelSummaryExpanded
          }}
        >
          <Fragment>
            {displayIcon(QUESTIONS.third)}
            <Typography variant="h2" className={classes.title}>
              {'¿Dónde encuentro el número del cupón de mi factura?'}
            </Typography>
          </Fragment>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{
            root: classes.expansionPanelDetails
          }}
        >
          <img
            src={CouponImage}
            alt={'coupon-help'}
            className={classes.image}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        square
        expanded={expanded === QUESTIONS.fourth}
        onChange={handleChange(QUESTIONS.fourth)}
        classes={{
          root: classes.expansionPanel,
          expanded: classes.expansionPanelExpanded
        }}
      >
        <ExpansionPanelSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          classes={{
            root: classes.expansionPanelSummary,
            expanded: classes.expansionPanelSummaryExpanded
          }}
        >
          <Fragment>
            {displayIcon(QUESTIONS.fourth)}
            <Typography variant="h2" className={classes.title}>
              ¿Dónde encuentro el número de{' '}
              {Company.contractConjugation.regular.singular.main} en mi factura?
            </Typography>
          </Fragment>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{
            root: classes.expansionPanelDetails
          }}
        >
          <img
            src={ContractImage}
            alt={'contract-help'}
            className={classes.image}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        square
        expanded={expanded === QUESTIONS.fifth}
        onChange={handleChange(QUESTIONS.fifth)}
        classes={{
          root: classes.expansionPanel,
          expanded: classes.expansionPanelExpanded
        }}
      >
        <ExpansionPanelSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          classes={{
            root: classes.expansionPanelSummary,
            expanded: classes.expansionPanelSummaryExpanded
          }}
        >
          <Fragment>
            {displayIcon(QUESTIONS.fifth)}
            <Typography variant="h2" className={classes.title}>
              {
                '¿Puedo realizar los pagos a través de cualquier medio de pago electrónico?'
              }
            </Typography>
          </Fragment>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{
            root: classes.expansionPanelDetails
          }}
        >
          <Typography className={classes.description}>
            {
              'Solo se permiten pagos a través de entidades bancarias, la cooperativa financiera Confiar, Daviplata y Nequi. No se permiten pagos por tarjetas de crédito.'
            }
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        square
        expanded={expanded === QUESTIONS.sixth}
        onChange={handleChange(QUESTIONS.sixth)}
        classes={{
          root: classes.expansionPanel,
          expanded: classes.expansionPanelExpanded
        }}
      >
        <ExpansionPanelSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          classes={{
            root: classes.expansionPanelSummary,
            expanded: classes.expansionPanelSummaryExpanded
          }}
        >
          <Fragment>
            {displayIcon(QUESTIONS.sixth)}
            <Typography variant="h2" className={classes.title}>
              {'¿Cuánto cuesta utilizar el portal?'}
            </Typography>
          </Fragment>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{
            root: classes.expansionPanelDetails
          }}
        >
          <Typography className={classes.description}>
            {
              'Gases del Caribe no cobra por acceder a este servicio y tampoco por realizar consultas o pagos.'
            }
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        square
        expanded={expanded === QUESTIONS.seventh}
        onChange={handleChange(QUESTIONS.seventh)}
        classes={{
          root: classes.expansionPanel,
          expanded: classes.expansionPanelExpanded
        }}
      >
        <ExpansionPanelSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          classes={{
            root: classes.expansionPanelSummary,
            expanded: classes.expansionPanelSummaryExpanded
          }}
        >
          <Fragment>
            {displayIcon(QUESTIONS.seventh)}
            <Typography variant="h2" className={classes.title}>
              {
                '¿Cómo puedo manifestar una inconformidad, solicitud o sugerencia?'
              }
            </Typography>
          </Fragment>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{
            root: classes.expansionPanelDetails
          }}
        >
          <Typography className={classes.description}>
            Comunícate a las líneas de atención que se encuentra especificadas
            en la pagina{' '}
            <Link
              component="button"
              className={classes.description}
              onClick={() => window.open(MainSiteUrlLink)}
            >
              www.gascaribe.com
            </Link>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        square
        expanded={expanded === QUESTIONS.eighth}
        onChange={handleChange(QUESTIONS.eighth)}
        classes={{
          root: classes.expansionPanel,
          expanded: classes.expansionPanelExpanded
        }}
      >
        <ExpansionPanelSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          classes={{
            root: classes.expansionPanelSummary,
            expanded: classes.expansionPanelSummaryExpanded
          }}
        >
          <Fragment>
            {displayIcon(QUESTIONS.eighth)}
            <Typography variant="h2" className={classes.title}>
              {
                '¿Con qué soporte puedo radicar una reclamación de una transacción realizada a través del portal?'
              }
            </Typography>
          </Fragment>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{
            root: classes.expansionPanelDetails
          }}
        >
          <Typography className={classes.description}>
            {
              'Toda transacción genera un comprobante de pago. Se enviarán a tu correo electrónico dos confirmaciones de pago correspondientes a la transacción, una corresponde a la Pasarela de Pagos (PayU) y otra de la empresa recaudadora (PSE). No olvides conservarlo, dado que es el único soporte válido de tu transacción para cualquier reclamación ante el Banco.'
            }
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        square
        expanded={expanded === QUESTIONS.ninth}
        onChange={handleChange(QUESTIONS.ninth)}
        classes={{
          root: classes.expansionPanel,
          expanded: classes.expansionPanelExpanded
        }}
      >
        <ExpansionPanelSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          classes={{
            root: classes.expansionPanelSummary,
            expanded: classes.expansionPanelSummaryExpanded
          }}
        >
          <Fragment>
            {displayIcon(QUESTIONS.ninth)}
            <Typography variant="h2" className={classes.title}>
              {
                '¿Cuánto tiempo debo esperar para poder realizar el pago de la factura?'
              }
            </Typography>
          </Fragment>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{
            root: classes.expansionPanelDetails
          }}
        >
          <Typography className={classes.description}>
            {
              'No debes esperar. Puedes realizar el pago tan pronto la factura se encuentre generada.'
            }
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel
        square
        expanded={expanded === QUESTIONS.tenth}
        onChange={handleChange(QUESTIONS.tenth)}
        classes={{
          root: classes.expansionPanel,
          expanded: classes.expansionPanelExpanded
        }}
      >
        <ExpansionPanelSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          classes={{
            root: classes.expansionPanelSummary,
            expanded: classes.expansionPanelSummaryExpanded
          }}
        >
          <Fragment>
            {displayIcon(QUESTIONS.tenth)}
            <Typography variant="h2" className={classes.title}>
              {'¿Cuánto ser tarda el sistema en reportar el pago?'}
            </Typography>
          </Fragment>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{
            root: classes.expansionPanelDetails
          }}
        >
          <Typography className={classes.description}>
            {'El reporte del pago se realiza inmediatamente.'}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Fragment>
  );
};

export default HelpGDC;
