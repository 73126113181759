import React, { useEffect, useRef, useState, useContext } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import BaseButton from './BaseButton';
import GoogleIcon from '../CustomIcons/GoogleIcon';
import { GoogleClientInitializedContext } from '../../Contexts/GoogleClientContext';

const GoogleButtonInner = props => {
  const { buttonWidth = 280, type: text } = props;

  useEffect(() => {
    window.google.accounts.id.renderButton(
      document.getElementById('google-signin-button'),
      {
        theme: 'outline',
        size: 'large',
        width: buttonWidth,
        // eslint-disable-next-line camelcase
        logo_alignment: 'center',
        text
      }
    );
  }, [buttonWidth, text]);

  return <div id="google-signin-button"></div>;
};

const GoogleButton = props => {
  const {
    className: customStyle = {},
    type = 'signin_with',
    loading = false,
    disabled
  } = props;

  const classes = useStyles();

  const googleIsInitialized = useContext(GoogleClientInitializedContext);

  const buttonContainerRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(null);

  useEffect(() => {
    setButtonWidth(buttonContainerRef.current.clientWidth);
  }, []);

  return (
    <div
      ref={buttonContainerRef}
      className={clsx(classes.container, customStyle)}
    >
      {googleIsInitialized && buttonWidth && !disabled && (
        <GoogleButtonInner buttonWidth={buttonWidth} type={type} />
      )}
      {(!googleIsInitialized || disabled) && (
        <BaseButton
          disabled={disabled}
          fullWidth
          id="google-login"
          color="primary"
          variant="outlined"
        >
          <GoogleIcon size={15} className={classes.googleIcon} />
          Ingresa con Google
        </BaseButton>
      )}
      {loading && (
        <div className={classes.loadingContainer}>
          <CircularProgress size={16} />
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    position: 'relative'
  },
  loadingContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  notInitialized: {
    color: theme.typography.color.main,
    fontSize: 12,
    padding: theme.spacing(),
    margin: 0
  },
  googleIcon: {
    marginRight: 5
  }
}));

export default GoogleButton;
