export const numberWithDots = x => {
  if (!x) {
    return '$ 0';
  }
  const withDots = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return `$ ${withDots}`;
};

export const moneyWithDots = x => {
  if (!x) {
    return '$0';
  }
  const withDots = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return `$${withDots}`;
};

export const floatWithDots = (x, precision) => {
  if (precision < 1) {
    return '$ [use valid precision]';
  }
  if (!x) {
    return '$ 00.00';
  }

  const integer = Math.trunc(x);
  const fractional = Math.trunc(10 ** precision * (x - Math.floor(x)));

  const withDots = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  if (fractional > 0) {
    return `${withDots},${fractional.toString().padStart(precision, '0')}`;
  }
  return `$ ${withDots}`;
};
