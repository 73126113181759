import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import BackIcon from '../../../../Components/CustomIcons/BackIcon';
import { numberWithDots } from '../../../../Utils/Format/MoneyFormat';
import _isEmpty from 'lodash/isEmpty';
import clsx from 'clsx';
import { getFirstBillingValue } from '../../../../Utils/Financing/Financing';
import PlanDetailsDialog from '../../Refinancing/PlanDetailsDialog';

const DeferredSidebarPaymentPlan = props => {
  const { total, paymentPlan, errors, products, isMobileSize } = props;
  const classes = useStyles();

  const [openDetails, setOpenDetails] = useState(false);

  const handleOpenDetails = () => {
    setOpenDetails(true);
  };

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  const monthlyPayment = getFirstBillingValue(
    products.map(p => ({
      billings: paymentPlan.numberOfInstallments,
      totalFinancingValue:
        p.financingValue -
        (paymentPlan ? paymentPlan.initialInstallmentValue : 0),
      interestRate: paymentPlan.monthlyInterestRate
    }))
  );

  return (
    <>
      {openDetails && (
        <PlanDetailsDialog
          billings={paymentPlan.numberOfInstallments}
          open={openDetails}
          isMobileSize={isMobileSize}
          handleClose={handleCloseDetails}
          productsData={products.map(({ financingValue }) => {
            return {
              interestRate: paymentPlan.monthlyInterestRate,
              billings: paymentPlan.numberOfInstallments,
              totalFinancingValue:
                financingValue -
                (paymentPlan ? paymentPlan.initialInstallmentValue : 0)
            };
          })}
        />
      )}
      <div className={classes.paymentPlanContainer}>
        <Grid container spacing={1} direction="column">
          <Grid item>
            <Typography className={clsx(classes.data, classes.boldText)}>
              Plan de pagos
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.label}>Saldo total</Typography>
            <Typography className={classes.data}>
              {numberWithDots(total)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.label}>Cuota inicial</Typography>
            <Typography className={classes.data}>
              {numberWithDots(paymentPlan.initialInstallmentValue)}
            </Typography>
          </Grid>
          <Grid item container justify="space-between" alignItems="center">
            <Grid item>
              <Typography className={classes.label}>
                Número de cuotas
              </Typography>
              {paymentPlan.numberOfInstallments > 0 && (
                <Typography className={classes.data}>
                  {paymentPlan.numberOfInstallments}{' '}
                  {`cuota${paymentPlan.numberOfInstallments > 1 ? 's' : ''}`}
                </Typography>
              )}
              {paymentPlan.numberOfInstallments < 1 && (
                <Typography className={classes.data}>Sin cuotas</Typography>
              )}
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="text"
                size="small"
                className={classes.planDetailsButton}
                onClick={handleOpenDetails}
                disabled={
                  !paymentPlan.numberOfInstallments ||
                  Boolean(errors && errors.numberOfInstallments)
                }
              >
                Ver detalle
                <BackIcon size={14} className={classes.iconRightStyle} />
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Typography className={classes.label}>
              Valor cuota mensual
            </Typography>
            <Typography className={classes.data}>
              {_isEmpty(errors) ? `${numberWithDots(monthlyPayment)}*` : '-'}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.footNote}>
              * La tasa de interés es variable y definida mensualmente por la
              Superfinanciera
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  paymentPlanContainer: {
    margin: theme.spacing(0, 2, 2),
    paddingTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.background.border}`,
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      borderStyle: 'none',
      padding: theme.spacing(2),
      margin: 0,
      boxShadow: '0px -4px 2px #0000000D',
      borderRadius: '8px 8px 0px 0px'
    }
  },
  data: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 1.2,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: 12
    }
  },
  boldText: {
    fontWeight: 600
  },
  label: {
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.text.greyDark
  },
  planDetailsButton: {
    fontWeight: 600,
    fontSize: 12,
    color: theme.palette.primary.main,
    textTransform: 'unset'
  },
  iconRightStyle: {
    transform: 'rotate(180deg)',
    fill: 'currentColor',
    marginLeft: theme.spacing(0.5)
  },
  footNote: {
    fontSize: 10,
    lineHeight: 1.2,
    color: theme.palette.text.greyDark
  }
}));

export default DeferredSidebarPaymentPlan;
