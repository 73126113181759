import React, { useContext, useEffect, useState } from 'react';
import FullSizeProgress from '../../Components/Progress/FullSize/FullSizeProgress';
import { history } from '../../Routes/history';
import { ROUTE_NAMES } from '../../Routes/Routes';
import { UserContext } from '../../Contexts/UserContext';
import { ActivateDigitalInvoiceContractAPI } from '../../API/Contracts/ContractsAPI';
import _get from 'lodash/get';
import { AlertsDispatchContext } from '../../Contexts/AlertsContext';
import { ALERT_TYPE } from '../../Components/Alerts/alert_enums';
import { extractErrorMessage } from '../../Utils/Errors/Errors';
import StatusBanner, {
  StatusBannerType
} from '../../Components/Banner/StatusBanner';
import { makeStyles } from '@material-ui/styles';
import BaseButton from '../../Components/Buttons/BaseButton';
import { useMediaQuery } from '@material-ui/core';
import { strSearchParams2Object } from '../TransactionsList/helpers/helpers';

const DigitalInvoice = props => {
  const { match, location } = props;

  const params = (match && match.params) || {};
  const { contractId: contractIdParam } = params;
  const parsedParams = strSearchParams2Object(window.location.search);
  const { token, utm_campaign: utmCampaign, utm_medium: utmMedium } =
    parsedParams || {};
  const [jwt, utmSourceKeyValue] = (token || '').split('?');
  const utmSource = (utmSourceKeyValue || '').split('=')[1];

  const locationState = (location && location.state) || {};
  const { activated = false, contractId: contractIdState } = locationState;

  const contractId = contractIdParam || contractIdState;

  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  const [loading, setLoading] = useState(false);
  const setAlert = useContext(AlertsDispatchContext);

  const currentUser = useContext(UserContext);
  const authToken = _get(currentUser, 'token', null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const body = {
        userToken: jwt,
        utm: {
          source: utmSource,
          medium: utmMedium,
          campaign: utmCampaign
        }
      };
      const response = await ActivateDigitalInvoiceContractAPI(
        contractId,
        body,
        authToken
      );
      setLoading(false);

      if (response.success) {
        history.replace(`${ROUTE_NAMES.digitalInvoice}/activar`, {
          activated: true,
          contractId
        });
        return;
      }

      setAlert({
        type: ALERT_TYPE.ERROR,
        message: extractErrorMessage(response).message
      });

      history.push(ROUTE_NAMES.home);
    };

    if (!activated && !contractId && !jwt) {
      history.push(ROUTE_NAMES.home);
    }

    if (!activated && contractId && jwt) {
      fetchData();
    }
  }, [
    authToken,
    contractId,
    jwt,
    setAlert,
    activated,
    utmSource,
    utmCampaign,
    utmMedium
  ]);

  if (loading) {
    return <FullSizeProgress />;
  }

  return (
    <div className={classes.root}>
      <StatusBanner
        message={<>Factura Digital activada</>}
        subMessage={
          <>
            ¡Felicitaciones! Completaste con éxito el proceso de activación de
            la Factura Digital del contrato <b>{contractId}</b>.
          </>
        }
        fullpage
        type={StatusBannerType.Correct}
        customClasses={{ icon: classes.icon, message: classes.message }}
      />
      <div className={classes.button}>
        <BaseButton
          color="primary"
          variant="outlined"
          fullWidth={isSmallScreen}
          onClick={() => {
            history.replace(ROUTE_NAMES.home);
          }}
        >
          Ir al inicio
        </BaseButton>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  button: {
    maxWidth: 200,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      maxWidth: '100%',
      padding: theme.spacing(0, 2)
    }
  },
  icon: {
    transform: 'scale(1.7)',
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      transform: 'scale(0.85)'
    }
  },
  message: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      marginTop: 0
    }
  }
}));

export default DigitalInvoice;
