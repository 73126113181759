import { Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import React, { useCallback, useContext, useState } from 'react';
import { DeleteUserAPI } from '../../API/UserAPI';
import BaseButton from '../../Components/Buttons/BaseButton';
import BaseDialog from '../../Components/Dialogs/BaseDialog';
import TextInput from '../../Components/Inputs/TextInput';
import ModalProgress from '../../Components/Progress/Modal/ModalProgress';
import { SlideUpTransition } from '../../Components/Transitions/Transitions';
import { AlertsDispatchContext } from '../../Contexts/AlertsContext';
import { extractErrorMessage } from '../../Utils/Errors/Errors';
import { isUserLoggedInWithPassword } from '../../Enums/users';

const DELETE_SENTENCE = 'Si quiero eliminar mi cuenta';

const DeleteAccountDialog = ({
  open,
  setDialog,
  setPasswordDialog,
  currentUser,
  onSuccessDeleteAccount,
  setOpenUnlinkAppleAccountDialog
}) => {
  const classes = useStyles();

  const setAlert = useContext(AlertsDispatchContext);

  const [canSubmit, setCanSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [
    openConfirmDigitalInvoiceDialog,
    setOpenConfirmDigitalInvoiceDialog
  ] = useState(false);

  const isMobileSize = useMediaQuery(theme =>
    theme.breakpoints.down(theme.breakpoints.values.sm)
  );

  const handleClose = useCallback(() => {
    setDialog(false);
  }, [setDialog]);

  const onInputChange = e => {
    const text = e.target.value;

    setCanSubmit(text.toLowerCase() === DELETE_SENTENCE.toLowerCase());
  };

  const handleCopyPaste = e => {
    e.preventDefault();
  };

  const handleDeleteAccount = useCallback(
    async password => {
      const data = {};

      if (password) {
        data.currentPassword = password;
      }
      const response = await DeleteUserAPI(
        currentUser.token,
        currentUser.id,
        data
      );

      return response;
    },
    [currentUser]
  );

  const handleDeleteAccountButtonClick = useCallback(async () => {
    const needsUnlinkAppleAccount = Boolean(
      currentUser.authenticationType.apple
    );

    if (needsUnlinkAppleAccount) {
      setOpenUnlinkAppleAccountDialog(true);
      handleClose();
      return;
    }

    if (isUserLoggedInWithPassword(currentUser)) {
      setPasswordDialog({
        title: 'Eliminar cuenta',
        requestCallback: handleDeleteAccount,
        successCallback: onSuccessDeleteAccount
      });

      return;
    }

    setLoading(true);

    const response = await handleDeleteAccount();

    if (response.success) {
      setLoading(false);
      onSuccessDeleteAccount();
    } else {
      setLoading(false);

      const { message } = extractErrorMessage(response);

      setAlert({
        type: 'error',
        message
      });
    }
  }, [
    currentUser,
    setPasswordDialog,
    handleDeleteAccount,
    onSuccessDeleteAccount,
    setAlert,
    handleClose,
    setOpenUnlinkAppleAccountDialog
  ]);

  const handleDigitalInvoiceConfirmDialog = useCallback(() => {
    setOpenConfirmDigitalInvoiceDialog(true);
    if (openConfirmDigitalInvoiceDialog) {
      handleDeleteAccountButtonClick();
    }
  }, [handleDeleteAccountButtonClick, openConfirmDigitalInvoiceDialog]);

  const handleCloseDigitalInvoiceConfirmDialog = useCallback(() => {
    setOpenConfirmDigitalInvoiceDialog(false);
  }, []);

  const renderActions = handleConfirm => {
    return (
      <>
        <BaseButton
          id="DeleteAccountDialog_button_cancel"
          onClick={
            handleConfirm ? handleClose : handleCloseDigitalInvoiceConfirmDialog
          }
          variant="outlined"
          color="primary"
          size="small"
        >
          Cancelar
        </BaseButton>
        <BaseButton
          onClick={
            handleConfirm
              ? handleDigitalInvoiceConfirmDialog
              : handleDeleteAccountButtonClick
          }
          id="DeleteAccountDialog_button_confirm"
          color="error"
          size="small"
          autoFocus
          disabled={!canSubmit}
        >
          Eliminar
        </BaseButton>
      </>
    );
  };

  const renderContent = () => {
    return (
      <Grid container>
        <Typography paragraph>
          Al eliminar tu cuenta, perderás acceso al portal web de clientes y sus
          funciones. Recuerda que puedes seguir realizando el pago de tu factura
          sin ingresar a una cuenta.
        </Typography>
        <Typography paragraph>
          Si aún estás seguro de eliminar tu cuenta, a continuación escribe la
          siguiente frase:
        </Typography>
        <Typography
          align="center"
          component="b"
          className={classes.deleteAccountSentence}
        >
          {DELETE_SENTENCE}
        </Typography>
        <TextInput
          id="DeleteAccountDialog_input"
          placeholder="Escribe la frase"
          onChange={onInputChange}
          onCut={handleCopyPaste}
          onCopy={handleCopyPaste}
          onPaste={handleCopyPaste}
          fullWidth
        />
      </Grid>
    );
  };

  const renderConfirmDigitalInvoiceDialogContent = useCallback(() => {
    return (
      <>
        <Typography paragraph className={classes.dialogText}>
          Al tener un contrato asociado a esta cuenta con factura digital activa
          debes tener presente lo siguiente:
        </Typography>
        <Typography paragraph className={classes.dialogText}>
          <span className={classes.textBold}>• </span>Si eres el único usuario
          con ese contrato asociado, este pasará automáticamente a factura
          física.
        </Typography>
        <Typography paragraph className={classes.dialogText}>
          <span className={classes.textBold}>• </span>Si eres el único usuario
          con notificaciones activas de factura digital de ese contrato, este
          pasará automáticamente a factura física.
        </Typography>
      </>
    );
  }, [classes.dialogText, classes.textBold]);

  return (
    <>
      {loading && (
        <ModalProgress
          id="DeleteAccountDialog_loading"
          message="Eliminando tu cuenta..."
        />
      )}

      <BaseDialog
        id="DeleteAccountDialog_div"
        open={open}
        disableBackdropClick
        handleClose={handleClose}
        title="¿Estás seguro de eliminar tu cuenta?"
        actions={() => renderActions(true)}
        content={renderContent}
        fullScreen={isMobileSize}
        TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
        contentSize={isMobileSize ? undefined : 'small'}
      />

      {openConfirmDigitalInvoiceDialog && (
        <BaseDialog
          open={openConfirmDigitalInvoiceDialog}
          title={'Información de posible cambio a factura física'}
          handleClose={handleCloseDigitalInvoiceConfirmDialog}
          actions={() => renderActions(false)}
          content={renderConfirmDigitalInvoiceDialogContent}
          fullScreen={isMobileSize}
          TransitionComponent={isMobileSize ? SlideUpTransition : undefined}
          contentSize={isMobileSize ? undefined : 'small'}
        />
      )}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  deleteAccountSentence: {
    fontWeight: 600,
    fontSize: 16,
    fontStyle: 'italic'
  },
  dialogText: {
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      fontSize: 14
    }
  },
  textBold: {
    fontWeight: 600
  }
}));

export default DeleteAccountDialog;
