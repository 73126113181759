import React from 'react';

const BillIcon = ({ width = 32.25, height = 32, ...rest }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 32.25 32"
    {...rest}
  >
    <path
      data-name="Sustracci\xF3n 9"
      d="M22.382 24.192H3.885a2.173 2.173 0 0 1-2.171-2.171V9.978a2.173 2.173 0 0 1 2.171-2.17H27.75a2.173 2.173 0 0 1 2.171 2.17v7.214a5.216 5.216 0 0 0-.62-.466V9.978a1.552 1.552 0 0 0-1.551-1.55H3.885a1.552 1.552 0 0 0-1.551 1.55v12.043a1.552 1.552 0 0 0 1.551 1.551h18.1a5.177 5.177 0 0 0 .4.619Zm-6.568-2.66a5.483 5.483 0 1 1 5.483-5.482 5.488 5.488 0 0 1-5.483 5.482Zm0-10.345a4.862 4.862 0 1 0 4.863 4.863 4.868 4.868 0 0 0-4.863-4.863Zm0 8.044a.352.352 0 0 1-.352-.352v-.212a2.271 2.271 0 0 1-1.325-.638.345.345 0 0 1-.1-.249.352.352 0 0 1 .6-.251 1.555 1.555 0 0 0 .831.427v-1.5c-.975-.115-1.654-.704-1.654-1.428s.679-1.314 1.651-1.432v-.22a.352.352 0 0 1 .7 0v.212a2.275 2.275 0 0 1 1.325.638.347.347 0 0 1 .1.248.352.352 0 0 1-.6.252 1.559 1.559 0 0 0-.831-.427v1.5c.972.118 1.652.707 1.652 1.432s-.679 1.313-1.652 1.431v.22a.352.352 0 0 1-.345.349Zm.355-2.723v1.44c.549-.095.948-.4.948-.718s-.403-.622-.948-.722Zm-.7-2.2c-.549.095-.947.4-.947.719s.4.624.947.718v-1.437Z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={0.5}
    />
    <g data-name="Grupo 1">
      <path data-name="Rect\xE1ngulo 250" fill="none" d="M0 0h32v32H0z" />
    </g>
    <g stroke="#fff" strokeWidth={0.5}>
      <path
        data-name="Trazado 22"
        d="M26.518 15.914a5.173 5.173 0 1 0 5.172 5.173 5.174 5.174 0 0 0-5.172-5.173Z"
        fill="none"
      />
      <path
        data-name="Trazado 22 - Contorno"
        d="M26.518 15.604a5.483 5.483 0 1 1-5.483 5.483 5.489 5.489 0 0 1 5.483-5.483Zm0 10.345a4.862 4.862 0 1 0-4.862-4.862 4.868 4.868 0 0 0 4.862 4.862Z"
        fill="#fff"
      />
      <path
        data-name="Trazado 23"
        d="M29.108 21.828h-2.59a.31.31 0 0 1-.31-.31v-3.88a.31.31 0 1 1 .62 0v3.569h2.28a.31.31 0 1 1 0 .62Z"
        fill="#fff"
        strokeLinecap="round"
      />
    </g>
  </svg>
);

export default BillIcon;
