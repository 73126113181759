import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

const SelectInput = props => {
  const {
    className,
    id,
    options,
    InputLabelProps,
    InputProps,
    InputPropsClasses = {},
    menuListClass,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <TextField
      select
      id={id}
      className={clsx(classes.root, className)}
      margin="normal"
      variant="outlined"
      InputProps={{
        classes: {
          root: classes.root,
          input: classes.input,
          ...InputPropsClasses
        },
        ...InputProps
      }}
      InputLabelProps={{
        ...InputLabelProps
      }}
      SelectProps={{
        SelectDisplayProps: {
          id: `${id}-select`
        },
        MenuProps: {
          classes: {
            list: menuListClass
          }
        }
      }}
      FormHelperTextProps={{
        classes: {
          root: classes.helperText
        }
      }}
      {...rest}
    >
      {options.map((obj, idx) => (
        <MenuItem
          id={`SelectInput_${idx}_${id}`}
          classes={{
            root: classes.item
          }}
          key={`${idx}-${obj.label}`}
          value={obj.value}
        >
          <div className={classes.itemContainer}>
            {obj.image && obj.image({ size: 21, className: classes.image })}
            {obj.label}
          </div>
        </MenuItem>
      ))}
    </TextField>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 58
  },
  input: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center'
  },
  item: {
    fontSize: 14
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 0,
    whiteSpace: 'nowrap'
  },
  image: {
    fill: theme.palette.primary.main,
    marginRight: theme.spacing(1.5),
    flexShrink: 0
  },
  helperText: {
    fontSize: 12,
    marginTop: 5
  }
}));

export default SelectInput;
